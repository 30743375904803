// import { generateGuid } from "utils/misc";
/**
 * These action creators are used with the message middleware.
 */
//id is generated in middleware

export const message = (message, period) => ({
  type: 'MESSAGE',
  message,
  // id: generateGuid(),
  period
})

export const errorMessage = (message, period) => ({
  type: 'ERROR',
  // id: generateGuid(),
  message,
  period
})

export const dismissMessage = id => ({
  type: "DISMISS_MSG",
  id
});
