import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MdComment from "react-icons/lib/md/comment";

const OrderNote = ({ note, creator, created_at }) => (
  <div className="worklist-item note">
    <span className="order-note">
      <MdComment />
      <span className="note-content">{note}</span>
    </span>
    <div className="worklist-item-meta">
      <div className="creator">{creator}</div>
      <div>{moment(created_at).format("MMMM Do YYYY, h:mm a")}</div>
    </div>
  </div>
);

OrderNote.propTypes = {
  note: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired
};

export default OrderNote;
