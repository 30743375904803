import React, { useEffect, useState } from "react";
import { Waiting, ErrorMessage } from "components/ui";
import { put } from "utils/api";
import { useFetch } from "hooks";
import { useParams, useLocation } from "react-router-dom";
import PrintPackingSlips from "./print-packing-slips";

const getOrderIds = (params, location) => {
  if (params && params.id) {
    return [params.id];
  }
  if (location && location.state) {
    return location.state.orderIds;
  }
};

const BatchPrintOrders = () => {
  const params = useParams();
  const location = useLocation();
  const orderIds = getOrderIds(params, location);

  const { fetchData, response, isFetching } = useFetch({
    apiFn: ids => put("orders/print-packing-slips", { ids }),
    defaultValue: { orders: [], status: "" }
  });
  useEffect(() => {
    fetchData(orderIds);
  }, [orderIds]);
  const [didOpenPrint, setDidOpenPrint] = useState(false);
  useEffect(() => {
    if (response?.status === "success" && !didOpenPrint) {
      setTimeout(() => {
        window.print();
        setDidOpenPrint(true);
      }, [500]);
    }
  }, [response]);
  if (isFetching) return <Waiting reason="Getting Orders Ready for Print..." />;
  return response?.status == "success" ? (
    <PrintPackingSlips orders={response.orders} />
  ) : (
    <ErrorMessage>
      An error occurred while preparing Packing Slips for print. Please try
      refreshing your browser tab. If the problem persists, try again later.
    </ErrorMessage>
  );
};

export default BatchPrintOrders;
