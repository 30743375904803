import React, { useState } from "react";
import InsuranceCompanyList from "./insurance-list";
import { HCPCEligibilityTable } from "./table";
import PropTypes from "prop-types";
import  InsuranceCompanyProvider  from "./insurance-company-provider";

export const CompanyInsurance = ({ params }) => {
  const companyGuid = params.id;
  const [bulkSelected, setBulkSelected] = useState(false);
  const [bulkSelectInsurances, setBulkSelectInsurances] = useState([]);

  return (
    <>
      <InsuranceCompanyProvider companyGuid={companyGuid}>
        <div className="page--container">
          <div className="insuranceList--container">
            <InsuranceCompanyList companyGuid={companyGuid} setBulkSelected={setBulkSelected} bulkSelectInsurances={bulkSelectInsurances} setBulkSelectInsurances={setBulkSelectInsurances} />
          </div>
          <div className="hcpcTable--container">
            <HCPCEligibilityTable bulkSelected={bulkSelected} bulkSelectInsurances={bulkSelectInsurances} />
          </div>
        </div>
      </InsuranceCompanyProvider>
    </>
  );
};

CompanyInsurance.propTypes = {
  params: PropTypes.object
};