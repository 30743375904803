import React from "react";
import PropTypes from "prop-types";
import { IoMinus, IoPlus, IoClock } from "react-icons/lib/io";

const StatusToggle = ({ style, value, toggleStatus, isUpdating, disabled }) => {
  const styles = style;
  let title = value ? "set to false" : "set to true";

  const onIClick = () => {
    if (!disabled) toggleStatus();
  };

  return (
    <span
      style={{
        marginLeft: 5,
        display: "inline-block",
        float: "right",
        width: 15
      }}
    >
      {isUpdating ? (
        <i style={{ ...styles.icon, ...styles.wait }}>
          <IoClock />
        </i>
      ) : (
        <i style={styles.icon} title={title} onClick={onIClick}>
          {value ? <IoMinus /> : <IoPlus />}
        </i>
      )}
    </span>
  );
};

StatusToggle.propTypes = {
  toggleStatus: PropTypes.func.isRequired,
  value: PropTypes.bool,
  style: PropTypes.object,
  isUpdating: PropTypes.bool,
  disabled: PropTypes.bool
};

StatusToggle.defaultProps = {
  isUpdating: false,
  disabled: false,
  style: {
    icon: {
      color: "#AAA",
      cursor: "pointer"
    },
    wait: {
      cursor: "wait"
    },
    clear: {
      marginRight: 10
    },
    container: {
      backgroundColor: "#FFF",
      border: "1px solid #DDD",
      boxShadow: "10px 10px 55px rgba(0,0,0,0.2)",
      position: "relative",
      left: 40,
      top: -20,
      width: 230,
      zIndex: 999
    }
  }
};

export default StatusToggle;
