import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

export const Tooltip = ({
  message,
  id,
  children,
  effect,
  type,
  aTagClassName,
  ...props
}) => {
  return (
    <Fragment>
      <a data-tip data-for={id} className={aTagClassName ? aTagClassName : ""}>
        {children}
      </a>
      <ReactTooltip
        className="helpful-link-tooltip"
        id={id}
        type={type}
        effect={effect}
        {...props}
      >
        <span>{message}</span>
      </ReactTooltip>
    </Fragment>
  );
};

Tooltip.defaultProps = {
  type: "light",
  place: "top",
  effect: "solid",
  className: "tooltip",
  disable: false
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.node,
  children: PropTypes.object,
  type: PropTypes.string,
  effect: PropTypes.string,
  place: PropTypes.string,
  className: PropTypes.string,
  hideOnFhir: PropTypes.bool,
  disable: PropTypes.bool,
  aTagClassName: PropTypes.string
};

export default Tooltip;
