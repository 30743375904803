import assign from "object-assign";

export function callAPI({ dispatch }) {
  return next => action => {
    const {
      type,
      callAPI,
      payload = {},
      backgrounded,
      callback,
      customMsg,
      failureCallback,
      ready = {},
      transform = x => x
    } = action;
    let customErrorMsg = action.customErrorMsg;

    if (typeof callAPI !== "function") {
      return next(action);
    }

    // form in use is waiting for a response
    // always means even a failed call is considered ready
    const { refs, always = false } = ready;
    if (refs && !backgrounded) {
      dispatch({
        type: "READY",
        refs,
        val: false
      });
    }

    dispatch(
      assign({}, payload, {
        type: `${type}_REQUEST`
      })
    );

    return callAPI().then(
      response => {
        dispatch(
          assign({}, payload, {
            response: transform(response),
            type
          })
        );

        if (refs && !backgrounded) {
          dispatch({
            type: "READY",
            refs,
            val: true
          });
        }

        dispatch({
          type: customMsg ? "MESSAGE" : `${type}_SUCCESS`,
          message: customMsg
        });
        callback && callback(dispatch, response);
      },
      error => {
        dispatch(
          assign({}, payload, {
            error,
            type: `${type}_FAILURE`
          })
        );

        if (!customErrorMsg) {
          if (error.status === 401) {
            customErrorMsg = "You have been logged out";
          } else if (error.status === 500) {
            customErrorMsg = "Something went wrong at the server.";
          }
        }

        if (always && !backgrounded) {
          dispatch({
            type: "READY",
            refs,
            val: true
          });
        }

        dispatch({
          type: customErrorMsg ? "ERROR" : "UNKNOWN_ERROR",
          message: customErrorMsg
        });
        failureCallback && failureCallback(dispatch);
      }
    );
  };
}
