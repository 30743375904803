import { createReducer } from "utils/reducer";
import * as R from "ramda";
import { Types } from "actions/phone-book";
import { createSelector } from "reselect";
import { Types as UserTypes } from "actions/user-profile";

export default createReducer(
  {},
  {
    // TODO handle error
    [Types.GET_PHONE_BOOK_USERS]: (state, action) =>
      R.pipe(
        R.propOr([], "response"),
        // flatten name
        R.map(({ name, ...rest }) => ({ ...name, ...rest })),
        R.indexBy(R.prop("id"))
      )(action),
    /* TODO not really ideal keeping coach settings in phone book reducer */
    [UserTypes.UPDATED_COACH_SETTINGS]: (
      state,
      { payload, meta: { userId } }
    ) => R.assocPath([userId], { ...R.prop(userId, state), ...payload }, state)
  }
);

const getAllVoipUsers = createSelector(
  [state => state.getIn(["phoneBook"])],
  phoneBook => phoneBook
);
const getPhoneBook = createSelector([getAllVoipUsers], R.values);

const getUserIsOutboundRestricted = createSelector(
  [getAllVoipUsers, (_, userId) => userId],
  (phoneBook, userId) => R.path([userId, "outboundCallRestricted"], phoneBook)
);

const getUserDialerInboundBlocked = createSelector(
  [getAllVoipUsers, (_, userId) => userId],
  (phoneBook, userId) => R.path([userId, "block_autodialer_incoming_calls"], phoneBook)
);

export const selectors = {
  getPhoneBook,
  getAllVoipUsers,
  getUserIsOutboundRestricted,
  getUserDialerInboundBlocked
};
