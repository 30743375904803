import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import Header from "../header";
import IoMdClose from "react-icons/lib/io/close";
import VerifyOrderProductCompatibility from "./verify-order-product-compatibility";
import ModalLink from "components/shared/modal-link";
import { QuantityInput, ZeroBilledInput } from "./inputs";
import CopyIcon from "react-icons/lib/md/content-copy";

// NOTE MedSouth wanted this sort order, backend sorts eq by hcpc by default and should keep this behavior for other companies
//      not really ideal and should probably let bakcned handle all sorting and rethink if this is actually necessary
export const sortOrderLineItemsByType = lineItems => {
  const eqOrder = [
    "Disposable Filter",
    "Nondisposable Filter",
    "Filter",
    "Tubing",
    "Water Chamber",
    "Seals",
    "Mask",
    "Mask with Headgear",
    "Headgear",
    "Chinstrap",
    "Machine",
    "Misc"
  ];
  return lineItems.sort(function (a, b) {
    return eqOrder.indexOf(a.type) - eqOrder.indexOf(b.type);
  });
};

const OrderLineItems = ({
  canChangeQuantities,
  canRemove,
  flag_generic_items,
  requestLineItemRemoval,
  requestUpdateLineItemById,
  isFetching,
  isSubmitting,
  orderId,
  orderLineItems,
  patientCurrentEquipment,
  requestedEquipment,
  uses_bonafide_api
}) => {
  const [animate, setAnimate] = useState({
    animate: false,
    index: 0
  });
  const IsNullOrEmpty = value => {
    if (typeof value === "string" && value.trim().length === 0) {
      return true;
    } else if (value === null) {
      return true;
    } else {
      return false;
    }
  };
  const CopyToClipboard = value => {
    navigator.clipboard.writeText(value);
  };
  return (
    <div>
      <Header showSpinner={isFetching || isSubmitting}>
        Equipment Requested:{" "}
        {requestedEquipment.map(({ name, hasLineItem }) => (
          <span
            className={`equipment-label ${hasLineItem ? "hasLineItem" : ""}`}
            key={name}
          >
            {name}
          </span>
        ))}
      </Header>
      <table>
        <thead>
          <tr>
            <th />
            <th />
            <th>Type</th>
            <th>Product ID</th>
            <th>Copy Id</th>
            <th>Manufacturer</th>
            <th>HCPCS</th>
            <th>Product</th>
            <th>Qty</th>
            <th>Zero Billed</th>
          </tr>
        </thead>
        <tbody>
          {(uses_bonafide_api
            ? sortOrderLineItemsByType(orderLineItems)
            : orderLineItems
          ).map((x, idx) => (
            <tr
              key={x.line_item_id}
              className={
                flag_generic_items && x.Product.includes("Generic")
                  ? "generic-item-flag"
                  : ""
              }
            >
              <td>
                {canRemove && (
                  <i
                    onClick={() => requestLineItemRemoval(x.line_item_id)}
                    className="remove-equipment-x"
                  >
                    <IoMdClose />
                  </i>
                )}
              </td>
              <td>
                <VerifyOrderProductCompatibility
                  orderEquipment={orderLineItems
                    .map(({ inventoryGUID }) => inventoryGUID)
                    .filter(lineItemGuid => lineItemGuid !== x.inventoryGUID)}
                  back_ordered={x.back_ordered}
                  exluded_hcpc={x.exluded_hcpc}
                  equipmentId={x.inventoryGUID}
                  patientEquipment={patientCurrentEquipment.map(
                    ({ item_id }) => item_id
                  )}
                  show_generic_flag={
                    flag_generic_items && x.Product.includes("Generic")
                  }
                />
              </td>
              <td>{x.type}</td>
              <td
                title={`${IsNullOrEmpty(x.company_product_id) ? "Manufacturer Id" : "Crosswalk Id"}`}
              >
                {IsNullOrEmpty(x.company_product_id)
                  ? x.id
                  : x.company_product_id}
              </td>
              {/* <td style={{color: IsNullOrEmpty(x.company_product_id) ? "black" : "red"}} >{IsNullOrEmpty(x.company_product_id) ? x.id : x.company_product_id}</td> */}
              <td
                style={{ textAlign: "center", fontSize: "20px", color: "grey" }}
                className={`${
                  animate.animate && animate.index === idx
                    ? "copied-animation"
                    : ""
                }`}
                title="Copy To Clipboard"
                onAnimationEnd={() => setAnimate({ animate: false, index: 0 })}
              >
                <CopyIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    CopyToClipboard(
                      IsNullOrEmpty(x.company_product_id)
                        ? x.id
                        : x.company_product_id
                    );
                    setAnimate({ animate: true, index: idx });
                  }}
                />
              </td>
              <td>{x.manufacturer}</td>
              <td>{x.HCPCS}</td>
              <td>
                <ModalLink
                  to={`/companies/${x.company_id}/profile/inventory/${x.company_inventory_guid}`}
                >
                  {x.Product}
                </ModalLink>
              </td>
              <td>
                <QuantityInput
                  disabled={isSubmitting || isFetching}
                  orderId={orderId}
                  line_item_id={x.line_item_id}
                  quantity={x.quantity}
                  requestUpdateLineItemById={requestUpdateLineItemById}
                />
              </td>
              <td>
                <ZeroBilledInput
                  disabled={isSubmitting || isFetching || !canChangeQuantities}
                  orderId={orderId}
                  line_item_id={x.line_item_id}
                  value={x.zeroChargeItem}
                  requestUpdateLineItemById={requestUpdateLineItemById}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default connect((state, props) => {
  const { data: orderLineItems, isFetching } = selectors.getOrderLineItems(
    state,
    props
  );
  const { data: companyInfo } = selectors.getOrderCompanyInfo(state, props);
  const { data: patientCurrentEquipment } =
    selectors.getOrderPatientEquipmentInfo(state, props);
  const isBonafideOrderConfirmed = selectors.getIsBonafideResultConfirmed(
    state,
    props
  );
  return {
    isFetching,
    uses_bonafide_api: companyInfo?.insurance_verification_type === "Bonafide",
    flag_generic_items: companyInfo?.flag_generic_items,
    isSubmitting:
      selectors.getIsSubmittingLineItemRemoval(state, props) ||
      selectors.getIsSubmittingNewLineItem(state, props),
    orderLineItems,
    patientCurrentEquipment,
    canRemove:
      selectors.getCanRemoveEquipmentRequested(state, props) &&
      !isBonafideOrderConfirmed,
    canChangeQuantities:
      selectors.getCanChangeQuantities(state, props) &&
      !isBonafideOrderConfirmed,
    requestedEquipment: selectors
      .getOrderDysfunctionEquipment(state, props)
      .map(name => ({
        name,
        hasLineItem: orderLineItems.some(({ type = "" }) =>
          type.replace(" ", "").toLowerCase().includes(name.toLowerCase())
        )
      }))
  };
}, mapDispatchToProps)(OrderLineItems);

OrderLineItems.propTypes = {
  isFetching: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  requestedEquipment: PropTypes.arrayOf(PropTypes.object),
  patientCurrentEquipment: PropTypes.arrayOf(PropTypes.object),
  uses_bonafide_api: PropTypes.bool,
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
  canChangeQuantities: PropTypes.bool,
  canRemove: PropTypes.bool,
  flag_generic_items: PropTypes.bool,
  requestLineItemRemoval: PropTypes.func.isRequired,
  requestUpdateLineItemById: PropTypes.func.isRequired
};
