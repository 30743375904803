import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { get } from "utils/api";
import { InlineWaiting } from "components/ui";

const RaPatientCmns = ({ patientId }) => {
  const {
    response: raCmnPatientCmnInfo,
    isFetching,
    fetchData: getPatientRaCmnLink
  } = useFetch({
    apiFn: patientId => get(`referral-answer/iframe-link/${patientId}`),
    defaultValue: {}
  });
  React.useEffect(() => {
    getPatientRaCmnLink(patientId);
  }, [patientId]);
  return (
    <div
      style={{
        overflow: "scroll",
        width: "100%",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        minHeight: 300,
        display: "flex",
        border: "none"
      }}
    >
      {isFetching ? (
        <div>
          <InlineWaiting />
        </div>
      ) : (
        <div>
          <iframe
            src={raCmnPatientCmnInfo.cmn_dashboard_link}
            height="100%"
            width="100%"
            style={{ height: "100%", display: "flex", flex: 1 }}
            allow="encrypted-media"
            allowFullScreen
            title="Patient Cmns"
            scrolling="yes"
            frameBorder="0"
          />
        </div>
      )}
    </div>
  );
};

RaPatientCmns.propTypes = {
  patientId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

export default RaPatientCmns;
