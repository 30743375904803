import React from "react";
import PropTypes from "prop-types";
import styler from "react-styling";
import Spinner from "components/ui/spinner";

export const Waiting = ({ reason, size, style }) => (
  <div style={{ ...S.loader, ...style }}>
    <Spinner size={size} />
    {reason && <div style={S.reason}>{reason}</div>}
  </div>
);
Waiting.defaultProps = {
  style: {}
};
Waiting.propTypes = {
  reason: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object
};

let S = styler`
  loader
    position: fixed
    left: calc(50%)
    top: 50%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  reason
    padding: 2rem
    color: #444
`;

export default Waiting;
