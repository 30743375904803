import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { PageHeader, Spinner } from "components/ui";
import { useFetch } from 'hooks';
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import useRowSelect, {
    SelectRowContext,
    RowSelectHeaderCell
  } from "hooks/useRowSelectNew";
import useAssignablePatientsTableSettings, { AssignablePatientsTableSettingsContext } from "./settings";
import AssignablePatientsTableRow from "./table-row"
import { post } from './../../utils/api';
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import { path } from "ramda";
import DateRangeHeaderColumn from "hooks/useQuery/api-query/table-header/date-range-header-filter";
import "./style.scss";

const AssignablePatients = ({ formId, closeModal }) => {

    const initialQuery = {};

    const [hasError, setHasError] = React.useState(false);
    const [error, setError] = React.useState('');
    const { query, queryDispatcher, rows, data, isFetching } =
    useApiQuery(`forms/${formId}/assignable-patients`, initialQuery, "AssignablePatients");
    const complianceCoaches = useSelector(selectors.getComplianceCoaches);

    const[contactTypesFilter, setContactTypesFilter] = React.useState([]);
    const[doctorFilter, setDoctorFilter] = React.useState([]);

    const[fetching, setFetching] = React.useState(false);

    React.useEffect(()=>{
        if(rows?.length > 0)
        {
            if(contactTypesFilter.length === 0){
                setContactTypesFilter(
                    [...new Set(rows.map(({ContactType})=> ContactType))].map((value) => ({ value, text: value }))
                )
            }
            if(doctorFilter.length === 0){
                setDoctorFilter(
                    rows.filter((row, index, self) => { return ( index === self.findIndex( (item) => item.Doctor === row.Doctor && item.NPI === row.NPI));})
                        .map(({Doctor, NPI}) => ({ value: NPI ?? Doctor, text: Doctor }))
                )
            }
        }
    },[rows])

    const {
        toggleAllRows,
        isRowSelected,
        toggleRow,
        clearSelectedRows,
        selectedRows
      } = useRowSelect(rows, "PatientId", query, row => ({
        PatientId: `${row.PatientId}`
      }));

      const tableOptions = useAssignablePatientsTableSettings();
      const {
        isColumnVisible,
        tableSettings,
        updateTableSettings,
        resetTableSettings,
        columns
      } = tableOptions;

      const updateAssignPatientsAPI = useFetch({
        apiFn: payload => post(`/forms/${formId}/assign-patients`, payload),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            setHasError(false)
            setFetching(false)
            setError(`Failed to get assignable patients: ${getApiErrorMessage(error)}`);
            errorMessage(`Failed to get assignable patients: ${getApiErrorMessage(error)}`);
        }
    })

    const assignPatients = async ()=> {
        setFetching(true);
        let patients = {PatientsIdentifiers: selectedRows?.map(p => p.PatientId)};
        if(patients.PatientsIdentifiers.length < 1 ){
            setHasError(true)
            setFetching(false)
            setError('you must select at least one register')
        }
        else{
            setHasError(false)
            const result = await updateAssignPatientsAPI.fetchData(patients);
            if(result){
                if(result.success){
                    setFetching(false);
                    closeModal()
                }else{
                    setHasError(true)
                    setFetching(false)
                    setError(`Failed to get assignable patients: ${getApiErrorMessage(result.message)}`);
                }
            }
        }
    }

    return (
        <div>
            <PageHeader title="Compliance Patient To Assign the Form" />
            <AssignablePatientsTableSettingsContext.Provider
                value={{
                columns,
                isColumnVisible,
                resetTableSettings,
                tableSettings,
                updateTableSettings
                }}
            >
                <SelectRowContext.Provider
                    value={{
                        toggleAllRows,
                        toggleRow,
                        selectedRows,
                        isRowSelected,
                        clearSelectedRows
                    }}
                >
                    {isFetching?
                            <Spinner />
                        :
                        rows && rows.length > 0 ?
                            <Table
                                query={query}
                                queryDispatcher={queryDispatcher}
                                isFetching={isFetching && fetching}
                                data={data}
                                containerClass="query-table assignable-patients-table"
                                columns={[
                                    {
                                        header: "all",
                                        field: "all",
                                        Cell: RowSelectHeaderCell,
                                        style: {width: "100"}
                                    },
                                    {
                                        header: 'Name',
                                        field: 'Name'
                                    },
                                    {
                                        header: 'Account#',
                                        field: 'AccountNumber'
                                    },
                                    {
                                        header: 'Contact Type',
                                        field: 'ContactType',
                                        filters: contactTypesFilter
                                    },
                                    {
                                        header: 'Insurance',
                                        field: 'Insurance'
                                    },
                                    {
                                        header: 'Doctor',
                                        field: 'Doctor',
                                        filters: doctorFilter
                                    },
                                    {
                                        header: 'Compliance Coach',
                                        field: 'ComplianceCoach',
                                        filters: complianceCoaches
                                    },
                                    {
                                        header: 'Company',
                                        field: 'CompanyName',
                                    },
                                    {
                                        header: 'Outreach Date',
                                        field: 'NextOutreachDate',
                                        Cell: () => (
                                            <DateRangeHeaderColumn
                                            header="Outreach Date"
                                            field="NextOutreachDate"
                                            />
                                        )
                                    },
                                    {
                                        header: 'Outreach Cycle',
                                        field: 'OutreachCycle'
                                    },
                                    {
                                        header: 'Created at',
                                        field: 'CreatedAt',
                                        Cell: () => (
                                            <DateRangeHeaderColumn
                                            header="Date Created"
                                            field="CreatedAt"
                                            />
                                        )
                                    }
                                ]}
                                rows={rows}
                                TableRow={AssignablePatientsTableRow}
                                rowKey="PatientId"
                            />
                            :
                            <div>
                                <h4 style={{color: "red"}}>the form cannot be assigned to any patient</h4>
                            </div>
                    }
                </SelectRowContext.Provider>
            </AssignablePatientsTableSettingsContext.Provider>
            <div className="assign-button">
            {hasError && <p style={{color: "red"}}>{error}</p>}
            <button disabled={fetching || (rows && rows.length < 1)} type="button" className="button" onClick={()=>{ assignPatients() }} > {fetching? <Spinner /> : "Assign"} </button>
            </div>
        </div>
    )
};

AssignablePatients.propTypes = {
    formId: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default AssignablePatients;
