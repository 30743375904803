import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";
import { message, errorMessage } from "actions/message";
import { selectors } from "reducers";
import { useSelector, useDispatch } from "react-redux";
import { useFetch } from "hooks";
import { post } from "utils/api";
import { isValidEmail } from "utils/redux-form";
import SleepgladBlacklistFormulary from "features/sleepglad/blacklist-formulary";
import PhoneNumberField from "components/forms/phone-number-field";
import TransactionCreated from "./transaction-created";
import * as Yup from "yup";

const TransactionForm = () => {
  const companies = useSelector(selectors.getFormOptionsCompany);
  const dispatch = useDispatch();

  const { response, fetchData } = useFetch({
    apiFn: payload => post(`sleepglad/invitePatient`, payload),
    defaultValue: {},
    onSuccess: () => {
      dispatch(message("Mask Fitting Scan Now Created"));
    },
    onError: () => {
      dispatch(errorMessage("Sorry, Something went wrong"));
    }
  });
  // const { response, fetchData: handleSubmit } = useFetch({
  //   apiFn: values => post("autofit/scan_now/add", values),
  //   defaultValue: null,
  //   onSuccess: () => {
  //     dispatch(message("Mask Fitting Scan Now Created"));
  //   },
  //   onError: () => {
  //     dispatch(errorMessage("Sorry, Something went wrong"));
  //   }
  // });
  return (
    <div>
      {response?.status == 200 ? (
        <div>
          <TransactionCreated {...response} />
        </div>
      ) : (
        <Formik
          initialValues={{
            customer_id: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            mask_type: "",
            notify_patient: true,
            blacklist_masks: [],
            blacklist_manufacturers: [],
            ahi: "",
            cmH2O: ""
          }}
          validationSchema={Yup.object().shape({
            customer_id: Yup.string().required("Required"),
            first_name: Yup.string().required("Required"),
            last_name: Yup.string().required("Required"),
            phone_number: Yup.string().required("Required"),
            email: Yup.string()
              .required("Required")
              .test(
                "checkValidEmail",
                "Invalid email",
                email => !isValidEmail(email)
              )
          })}
          enableReinitialize={true}
          onSubmit={async ({
            mask_type,
            blacklist_masks,
            blacklist_manufacturers,
            customer_id,
            first_name,
            last_name,
            phone_number,
            email,
            ahi,
            cmH2O
          }) => {
            // await handleSubmit({
            //   ...values,
            //   blacklist_masks: values.blacklist_masks.join("^"),
            //   blacklist_manufacturers: values.blacklist_masks.join("^")
            // });
            //
            await fetchData({
              patient_id: email,
              mask_type,
              blacklist_masks,
              blacklist_manufacturers,
              customer_id,
              ahi,
              cmH2O,
              first_name,
              last_name,
              phone_number,
              email
            });
          }}
        >
          {({ values, isSubmitting, isValid }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 10
              }}
            >
              <FormikField
                name="customer_id"
                label="Company"
                component="select"
              >
                <option />
                {companies.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: 10
                }}
              >
                <FormikField name="first_name" label="First Name" type="text" />
                <FormikField name="last_name" label="Last Name" type="text" />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: 10
                }}
              >
                <FormikField name="email" label="Email" type="text" />
                <FormikField
                  name="phone_number"
                  label="Phone Number"
                  type="tel"
                  component={PhoneNumberField}
                />
              </div>
              <SleepgladBlacklistFormulary companyId={values.customer_id} />
              <FormikField
                name="mask_type"
                label="Preferred Mask Type"
                component="select"
              >
                <option>No Preference</option>
                <option value="Full Face">Full Face</option>
                <option value="Nasal Cushion">Nasal Cushion</option>
                <option value="Nasal Pillow">Nasal Pillow</option>
              </FormikField>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: 20,
                  flex: 1
                }}
              >
                <FormikField
                  label="Apnea Hypopnea Index"
                  name="ahi"
                  type="number"
                  value={values.ahi}
                />
                <FormikField
                  label="CPAP Pressure(cmH2O)"
                  name="cmH2O"
                  type="number"
                  value={values.cmH2O}
                />
              </div>
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    marginTop: 20
                  }}
                >
                  {isSubmitting ? (
                    <InlineWaiting />
                  ) : (
                    <button
                      style={{ margin: 0 }}
                      type="submit"
                      disabled={!isValid}
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

TransactionForm.propTypes = {
  fetchMaskFittingTransactions: PropTypes.func.isRequired
};

export default TransactionForm;
