import React from "react";
import PropTypes from "prop-types";
import { ProfileCard } from "components/ui/index";
import CompanyProfileContacts from "./company-profile-contacts";
import { path } from "ramda";
import { HasPermission } from "components/shared/app-permission";
import HasRolePermission from "components/shared/has-role-permission";


const CompanyOverview = ({ company }) => (
  <div
    style={{
      display: "grid",
      gridGap: "30px",
      gridTemplateColumns: "1fr 1fr 1fr"
    }}
  >
    <HasRolePermission contractorCheck={true}>

    <HasPermission permission="orderSupplies">
      <ProfileCard
        title="Orders"
        items={{
          Total: path(["orders", "count"], company),
          Accepted: path(["orders", "accepted_count"], company),
          "Back Ordered": path(["orders", "backordered_count"], company),
          "Coach Follow Up": path(["orders", "coach_followup_count"], company),
          Completed: path(["orders", "completed_count"], company),
          Incorrect: path(["orders", "incorrect_count"], company),
          "Internal Processing": path(
            ["orders", "internal_processing_count"],
            company
            ),
            "Location Assistance": path(
              ["orders", "location_assistance_needed_count"],
              company
              ),
              New: path(["orders", "new_count"], company),
              "On Hold": path(["orders", "on_hold_count"], company),
              Pending: path(["orders", "pending_count"], company),
              "Pending Buildout": path(
                ["orders", "pending_buildout_count"],
                company
                ),
                "Pending Shipment": path(
                  ["orders", "pending_shipment_count"],
            company
            ),
            "Ready For Review": path(
              ["orders", "ready_for_review_count"],
              company
              ),
              Rejected: path(["orders", "rejected_count"], company),
              "Sent to Fulfillment": path(
                ["orders", "sent_to_fulfillment_count"],
            company
          )
        }}
        />
    </HasPermission>
    <CompanyProfileContacts />
    <ProfileCard
      title="Patients"
      items={{
        Active: path(["patients", "active"], company),
        Inactive: path(["patients", "inactive"], company),
        Eligible: path(["patients", "eligible"], company),
      }}
      />
      </HasRolePermission>
  </div>
);

CompanyOverview.propTypes = { company: PropTypes.object.isRequired };

export default CompanyOverview;
