import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getExports } from "action-creators";
import {
  startExportFetchInterval,
  endExportFetchInterval
} from "actions/exports";
import { List } from "immutable";
import ExportsTable from "components/screens/exports/exports-table.jsx";
import PageHeader from "components/ui/page-header";
import { Waiting } from "components/ui/waiting";
import { getExportsLastUpdated } from "reducers/exports";
import MdRefresh from "react-icons/lib/md/refresh";

export class Export extends Component {
  componentDidMount() {
    this.refresh();
  }

  componentWillUnmount() {
    this.props.endExportFetchInterval();
  }

  refresh = () => {
    const id = this.props.user.get("id");
    this.props.getExports(id);
    this.props.startExportFetchInterval(id);
  }

  render() {
    const {
      lastUpdated,
      exportTable
    } = this.props;

    return (
      <div>
        <PageHeader title="Exports" />
        <div style={{
               display: "flex",
               alignItems: "center"
             }}>
          <i
             title="Refresh"
             style={{
               fontSize: "20px",
               cursor: "pointer",
               marginRight: "5px"
             }}
             onClick={this.refresh}>
             <MdRefresh />
           </i>
          <div>Updated: {lastUpdated}</div>
        </div>
        {exportTable.size
          ? <ExportsTable exportTable={exportTable.get('exports').sortBy( ex => ex.get('created_date'))} />
          : <Waiting reason={"gathering your data"} />}
      </div>
    );
  }
}

Export.defaultProps = {
  exports: List([])
};

export default connect(
  state => ({
    exportTable: state.get("exports"),
    user: state.get("user"),
    lastUpdated: getExportsLastUpdated(state)
  }),
  {
    getExports,
    startExportFetchInterval,
    endExportFetchInterval
  }
)(Export);

Export.propTypes = {
  user: PropTypes.object.isRequired,
  exportTable: PropTypes.object,
  getExports: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
  endExportFetchInterval: PropTypes.func.isRequired,
  startExportFetchInterval: PropTypes.func.isRequired
};
