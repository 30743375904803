import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ExportInventory from "./export-inventory";
import { PageHeader } from "components/ui/index";
import { Waiting } from "components/ui/waiting";
import HasRolePermission from "components/shared/has-role-permission";
import { selectors } from "reducers";
import InventoryTable from "./table";
import InventorySearch from "./inventory-search";
import { useDebounce } from "hooks";
import { Link } from "react-router-dom";

const InventoryTablePage = ({ isFetchingInventory, inventoryItems }) => {
  const [searchBy, setSearchBy] = useState("product_name");
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 700);
  return (
    <div className="inventory-table-container">
      <PageHeader title="Inventory Items">
        <HasRolePermission allowedRoles={["Administrator"]}>
          <ExportInventory />
        </HasRolePermission>
        <HasRolePermission allowedRoles={["Administrator"]}>
          <div className="link-button">
            <Link
              className="button small"
              to="/inventory/new"
              style={{ margin: 0 }}
            >
              Add Inventory
            </Link>
          </div>
        </HasRolePermission>
      </PageHeader>
      {!isFetchingInventory ? (
        <div>
          <InventorySearch
            searchBy={searchBy}
            setSearchValue={setSearchValue}
            setSearchBy={setSearchBy}
            searchValue={searchValue}
          />
          <InventoryTable
            searchBy={searchBy}
            searchValue={debouncedSearchTerm}
            inventoryItems={inventoryItems}
          />
        </div>
      ) : (
        <Waiting reason="getting inventory" />
      )}
    </div>
  );
};

export default connect(state => ({
  isFetchingInventory: selectors.isFetchingInventory(state),
  inventoryItems: selectors.getInventoryItems(state)
}))(InventoryTablePage);

InventoryTablePage.propTypes = {
  inventoryItems: PropTypes.arrayOf(PropTypes.object),
  isFetchingInventory: PropTypes.bool.isRequired
};
