import React from "react";
import PropTypes from "prop-types";
import { sortBy, prop, filter } from "ramda";

const HoldReasonRow = ({ orderId, hold_reasons = [] }, i) => (
  <tr
    key={`${orderId}-2`}
    className={`hold-reason-row ${i % 2 === 0 ? "even" : "odd"}`}
  >
    <td />
    <td colSpan="11">
      <span className="order-hold-reasons-table-row">
        {sortBy(
          prop("resolved"),
          filter(r => r.holdReason, hold_reasons)
        ).map(hr => (
          <span
            key={`${hr.orderID}-${hr.holdReasonPK}`}
            className={`hold-label ${hr.resolved && "resolved"}`}
            title={hr.comments}
          >
            {hr.holdReason}
          </span>
        ))}
      </span>
    </td>
  </tr>
);

HoldReasonRow.propTypes = {
  orderId: PropTypes.string.isRequired,
  hold_reasons: PropTypes.arrayOf(PropTypes.object)
};

export default HoldReasonRow;
