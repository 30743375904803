import React from "react";
import PropTypes from "prop-types";
import CardContainer from "./card-container";
import { Link } from "react-router-dom";
import styler from "react-styling";
import IoIosInformationCircleOutline from "react-icons/lib/io/ios-information";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import HasRolePermission from "components/shared/has-role-permission";

const helpfulLinks = [
  {
    link: (
      <Link to="patients/new" style={{ color: '#4281a6'}}>
        <span title="Create Patient">Create Patient</span>
      </Link>
    ),
    toolTipText:
      "Manually add patients to the system that have not yet been imported to the platform",
  },
  {
    link: (
      <Link to="/orders/worklist" style={{ color: '#4281a6'}}>
        <span title="Worklist">Worklist</span>
      </Link>
    ),
    toolTipText:
      "Review orders with open worklist items needing completed in order to process orders",
  },
  {
    link: (
      <Link to="/exports" style={{ color: '#4281a6'}}>
        <span title="Exports">Exports</span>
      </Link>
    ),
    toolTipText: "Repository of system-generated export files",
  },
];

const makeCompanyAdminLinks = (company) => [
  {
    link: (
      <Link to={`companies/${company.id}/profile`} style={S.link}>
        <span>{company.company_name}</span>
      </Link>
    ),
    toolTipText:
      "Manually add patients to the system that have not yet been imported to the platform",
  }
];

const makeLinks = (links, showToolTip = true) => (
  <>
    {links.map((item, i) => {
      return (
        <div style={S.linkContainer} key={i}>
          <span>{item.link}</span>
          {showToolTip && <span style={S.infoCircle}>
            <Tooltip
              id={`helpful-link-tooltip-${i}`}
              className="helpful-link-tooltip"
              effect="solid"
              message={item.toolTipText}
            >
              <IoIosInformationCircleOutline size="1rem" />
            </Tooltip>
          </span>}
        </div>
      );
    })}
  </>
);

const HelpfulLinksCard = ({
  companies,
  userRole,
  classProp,
}) => {
  return (
    <>
      <HasRolePermission contractorCheck={true}>
        <CardContainer classProp={classProp} title="Helpful Links">
          <div style={S.header}>Resources</div>
          <>{makeLinks(helpfulLinks)}</>
          {companies.length > 1 
          && userRole === "CompanyAdministrator"
          &&
            (<>
              <div style={S.header}>Links to Company Profiles</div>
              <div style={S.companiesContainer}>
                  {companies.map(company => (
                    <div key={company.company_name}>
                      {makeLinks(makeCompanyAdminLinks(company), false)}
                    </div>
                  ))}
              </div>
            </>)}
        </CardContainer>
      </HasRolePermission>
    </>
  );
};

HelpfulLinksCard.propTypes = { 
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRole: PropTypes.string.isRequired,
  classProp: PropTypes.string
}

const S = styler`
  companiesContainer
    height: 65%
    overflow-y: auto
  header
    border-bottom: 1px solid #666
    margin: 1rem 0 .5rem 0
    color: #249f94;
  linkContainer
    display: flex;
    flex-direction: row;
    margin: .75rem 0
  infoCircle
    cursor: pointer
    position: relative;
    left: 0.25rem;
    bottom: 0.5rem;
  link
    color: #4281a6
`;

export default HelpfulLinksCard;
