import React from "react";
import PropTypes from "prop-types";
import { FieldArray, Field } from "formik";
import { IconButton } from "components/ui";
import MdCancel from "react-icons/lib/md/cancel";

const QuestionOptionsForm = ({ index, options }) => {
  return (
    <FieldArray
      name={`questions.${index}.options`}
      render={arrayHelpers => (
        <div
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: "#fafafa"
          }}
        >
          <ul
            style={{
              margin: 0,
              display: "list-item",
              paddingLeft: 50
            }}
          >
            {options &&
              options.length > 0 &&
              options.map((option, optIndex) => (
                <li
                  key={optIndex}
                  style={{
                    display: "list-item",
                    marginTop: 2,
                    marginBottom: 2,
                    listStyle: "disc",
                    listStyleType: "disc"
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                      gridGap: 1,
                      alignItems: "center"
                    }}
                  >
                    <Field
                      name={`questions.${index}.options.${optIndex}.option_text`}
                      type="text"
                      style={{ margin: 0 }}
                    />
                    <IconButton
                      onClick={() =>
                        arrayHelpers.remove(
                          `questions.${index}.options.${optIndex}`
                        )
                      }
                      style={{
                        color: "#e8910c",
                        padding: 3
                      }}
                      icon={<MdCancel />}
                      type="button"
                      text="Remove"
                    />
                  </div>
                </li>
              ))}
            <li
              style={{
                display: "list-item",
                marginTop: 2,
                marginBottom: 2,
                listStyle: "disc",
                listStyleType: "disc"
              }}
            >
              <button
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    option_text: "",
                    active: true
                  })
                }
                style={{
                  margin: 0,
                  padding: 5
                }}
              >
                Add Option
              </button>
            </li>
          </ul>
        </div>
      )}
    />
  );
};

QuestionOptionsForm.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option_text: PropTypes.string
    })
  ),
  index: PropTypes.number.isRequired
};

export default QuestionOptionsForm;
