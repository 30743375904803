import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  queuePatients,
  flushPatientQueue,
  invalidatePatient,
  fetchPatient
} from "actions/patient";
import Pages from "features/pages";
import PatientProfile from "components/screens/patient/patient-profile";
import PatientTitle from "./patient-title";

function BatchPatientView({
  patientIds,
  patientTitles,
  userRole,
  invalidatePatient,
  queuePatients,
  flushPatientQueue,
  fetchPatient
}) {
  useEffect(() => {
    queuePatients(patientIds);
    return () => {
      flushPatientQueue();
    };
  }, [patientIds]);
  return (
    <Pages
      showNext
      showPageNumber
      showBack={userRole !== "SleepCoach"}
      showSelect={userRole !== "SleepCoach"}
      containerStyle={{ padding: 20 }}
      pages={patientIds.map((id, index) => ({
        title:
          patientTitles?.length > 0
            ? patientTitles[index]
            : () => <PatientTitle patientId={id} index={index} />,
        view: <PatientProfile fetchWaitDuration={360000} patientId={id} />,
        onRefresh: () => {
          invalidatePatient({
            patientId: id
          });
          fetchPatient({ patientId: id });
        }
      }))}
    />
  );
}

export default connect(
  state => ({
    patientIds: selectors.getPatientBatchIds(state),
    patientTitles: selectors.getPatientBatchTitles(state),
    userRole: selectors.getUserRole(state)
  }),
  {
    queuePatients,
    flushPatientQueue,
    invalidatePatient,
    fetchPatient
  }
)(BatchPatientView);

BatchPatientView.propTypes = {
  invalidatePatient: PropTypes.func.isRequired,
  fetchPatient: PropTypes.func.isRequired,
  queuePatients: PropTypes.func.isRequired,
  flushPatientQueue: PropTypes.func.isRequired,
  patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  patientTitles: PropTypes.arrayOf(PropTypes.string),
  userRole: PropTypes.string.isRequired
};
