/* by this component video answer should be edited and saved */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import { PageHeader } from 'components/ui';
import Text from "../../components/TextComponent";
import SelectDropdown from "components/forms/select-dropdown";

const AddNewArticle = ({
  Article,
  companyOptions,
  onSave
}) => {

  const [title, changeTitle] = useState(Article.title);
  const [orderno, changeOrderNo] = useState(Article.title ? Article.orderno : 0);
  const [link, changeLink] = useState(Article.link);
  const [company, setCompany] = useState(Article.company);

  return (
    <div className="dd_root" >
      <PageHeader question='Add/Update Question Answers' />
      <div className="dd_row">
        <div className="dd_col-6">
          <Text maxLength={90} id="title" value={title} placeholder="Enter Title" onChange={({ target: { value } }) => changeTitle(value)} ></Text>
        </div>
        <div className="dd_col-6">
          <Text maxLength={90} id="link" value={link} placeholder="Enter Link" onChange={({ target: { value } }) => changeLink(value)} >
          </Text>
        </div>
        <div className="dd_col-6">
          <div className="company_container">
            Company:
            <SelectDropdown
              id="companyGUID"
              placeHolder="Choose An Option..."
              options={companyOptions}
              value={company}
              onChange={value => {
                setCompany(value);
              }}
            />
          </div>
        </div>
        <div className="dd_col-6">
        <div className="company_container">
            Order Number:
          <input max={365} min={0} type="number" id="orderno" value={orderno} placeholder="Change OrderNo" onChange={({ target: { value } }) => changeOrderNo(value)} />
          </div>
        </div>
        <div className="dd_col-12">
          <button
            onClick={() => onSave({ orderno : orderno, link, company: company, title, article_id: Article.article_id, active: Article.active })}
            disabled={((orderno === 0) || title.trim().length === 0 || link.trim().length === 0 || company.trim().length === 0)}>
            Save
         </button>
        </div>
      </div>
    </div>
  );
}

AddNewArticle.propTypes = {
  companyOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  Article: PropTypes.object,
  onSave: PropTypes.func.isRequired
}

export default connect(
  state => ({
    companyOptions: selectors.getFormOptionsCompany(state)
  })
)(AddNewArticle);
