import {
  call,
  put,
  all,
  takeEvery,
  take,
  fork,
  cancel
} from 'redux-saga/effects'
import { browserHistory } from 'browser-history'
import keyBy from 'lodash/keyBy'
import omit from 'lodash/omit'
import {
  Types,
  responseData,
  errorData,
  errorExport
} from './actions'
import { errorMessage } from 'actions/message'
import * as api from 'utils/api'
import { transformUiQuery } from 'utils/query'

const METHOD_LOOKUP = {
  GET: api.get,
  PUT: api.put,
  POST: api.post
}

const EXPORT_ERROR_MESSAGE = 'Failed to export table.'

export default function* rootSaga() {
  yield all([
    watchDataRequest(),
    watchExportRequest()
  ])
}

function* watchDataRequest() {
  let lastTasks = {}
  while (true) {
    const action = yield take(Types.REQUEST_DATA)
    //const { apiId } = action
    const { meta: { apiId } } = action
    const lastTask = lastTasks[apiId]
    if (lastTask) {
      yield cancel(lastTask)
    }
    lastTasks[apiId] = yield fork(fetchData, action)
  }
}

export function* fetchData({
  payload: {
    query,
    method,
    route,
    responseKey,
    entityKey,
    idKey
  },
  meta: { apiId }
}) {
  try {
    const response = yield call(METHOD_LOOKUP[method], route, transformUiQuery(query))
    const records = response[responseKey]
    const { count } = response.data
    yield put(responseData(apiId, {
      [entityKey]: keyBy(records, idKey)
    }, records.map(r => r[idKey]), count))
  } catch(error) {
    yield put(errorData(apiId, error))
  }
}

function* watchExportRequest() {
  yield takeEvery(Types.REQUEST_EXPORT, exportData)
}

function* exportData({
  payload: {
    query,
    method,
    route
  }
}) {
  try {
    yield call(METHOD_LOOKUP[method], route, omit(transformUiQuery(query), 'pagination'))
    browserHistory.push("/exports");
  } catch(error) {
    yield put(errorMessage(EXPORT_ERROR_MESSAGE))
    yield put(errorExport(error))
  }
}
