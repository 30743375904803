import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { googleApiLoaded } from "./actions";
import config from "./config";

const GoogleApi = ({ googleApiLoaded }) => {
  useEffect(() => {
    // this is taken directly from Google documentation:
    // https://developers.google.com/api-client-library/javascript/start/start-js
    function start() {
      // 2. Initialize the JavaScript client library.
      window.gapi.client
        .init({
          apiKey: config.API_KEY,
          clientId: config.CLIENT_ID,
          discoveryDocs: config.DISCOVERY_DOCS,
          scope: config.SCOPES
        })
        .then(
          () => {
            window.gapi.load("client:auth2", () => {
              googleApiLoaded();
            });
          },
          error => {
            //eslint-disable-next-line
            console.warn(error);
          }
        );
    }
    window.gapi.load("client", start);
  }, []);
  return null;
};

GoogleApi.propTypes = {
  googleApiLoaded: PropTypes.func.isRequired
};

export default connect(
  // state => ({ user: state.get("user") }),
  null,
  { googleApiLoaded }
)(GoogleApi);
