import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const TabLink = ({ label, path, exact }) => (
  <li key={label}>
    <NavLink activeClassName="is-active" to={path} exact={exact}>
      {label}
    </NavLink>
  </li>
);

TabLink.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  exact: PropTypes.bool
};
export default TabLink;
