import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { withRouter } from "react-router-dom";
import { getCompany, updateBranch } from "actions/company";
import PageHeader from "components/ui/page-header";
import { InlineWaiting } from "components/ui";
import BranchForm from "./branch-form";
import { getRouteParam } from "routes";

export class EditBranch extends Component {
  componentDidMount() {
    const { branch, getCompany } = this.props;
    if (!branch) {
      getCompany(getRouteParam(this.props, "id"));
    }
  }
  editBranch = formValues => {
    const { updateBranch } = this.props;
    const companyId = getRouteParam(this.props, "id");
    const branchId = getRouteParam(this.props, "bid");
    return updateBranch({ companyId, branch: formValues, branchId });
  };

  render() {
    const { branch } = this.props;

    return (
      <div>
        <PageHeader title={`Edit Branch ${branch ? branch.branch_name : ""}`} />
        {branch ? (
          <BranchForm onSubmit={this.editBranch} initialValues={branch} />
        ) : (
          <InlineWaiting />
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => ({
      branch: selectors.getCompanyBranch(state, getRouteParam(props, "bid"))
    }),
    { updateBranch, getCompany }
  )(EditBranch)
);

EditBranch.propTypes = {
  branch: PropTypes.object,
  getCompany: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  updateBranch: PropTypes.func.isRequired
};
