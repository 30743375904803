import React, { useState } from "react";
import PropTypes from "prop-types";
import Text from "../../../components/TextComponent";
import Select from "../../../components/SelectComponent";
import { PageHeader } from "components/ui";

export default function AddInfromation(props) {
    const [questionText, changeQuestion] = useState('');
    const [questionType, changeDropdown] = useState('1');
    const [isMultiChoice, showMultiChoiceOption] = useState(false);
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [option4, setOption4] = useState('');

    const question_type = [
        { text: 'Yes/No', value: 1 },
        { text: 'Multichoice', value: 2 },
        { text: 'Text', value: 3 },
        { text: 'Linkerscale', value: 4 }
    ];

    function dropdownChange({ value }) {
        changeDropdown(value);
        if (parseInt(value) === 2) {
            showMultiChoiceOption(true);
        }else{
            showMultiChoiceOption(false);
        }
    }

    function AddNewQuestion() {
        if(questionText.length === 0)
        {
            return;
        }

        if (questionType === "2") {
            if (option1.length === 0 || option2.length === 0 || option3.length === 0 || option4.length === 0) {
                return;
            }
        } 

            let optionsArray = [];
            if (questionType === "2") {
                optionsArray = [
                    {
                        "option_text": option1,
                        "active": true,
                        "option_id": null
                    },
                    {
                        "option_text": option2,
                        "active": true,
                        "option_id": null
                    },
                    {
                        "option_text": option3,
                        "active": true,
                        "option_id": null
                    },
                    {
                        "option_text": option4,
                        "active": true,
                        "option_id": null
                    }];
            }

            let question = {
                active : true,
                question_id: null,
                question_type: questionType,
                question_text: questionText,
                options: optionsArray,
            }
            props.newAddedQuestion(question)
    }

    return (
        <div className="dd_row">
            <div className="dd_col-12">
                <PageHeader title="Add/Edit Question" />
                <div className="dd_col-2"><h4>Question Text</h4></div>
                <div className="dd_col-9">
                    <Text
                        maxLength={90}
                        onChange={({ target: { value } }) => changeQuestion(value)}
                        value={questionText}
                        id="question_Text"
                        placeholder="Enter Question"
                    />
                </div>
                <div className="dd_col-2"><h4>Question Type</h4></div>
                <div className="dd_col-9">
                    <Select id="dropdown_questiontype"
                        value={questionType}
                        label="Select Product"
                        options={question_type}
                        onChange={dropdownChange}
                    />
                </div>
                {isMultiChoice && <React.Fragment>
                    <div className="dd_col-9">
                        <div className="dd_row">
                            <div className="dd_col-3">
                                <Text
                                    onChange={({ target: { value } }) => setOption1(value)}
                                    value={option1}
                                    placeholder="Enter Option 1"
                                    maxLength={40}
                                />
                            </div>
                            <div className="dd_col-3">
                                <Text
                                    onChange={({ target: { value } }) => setOption2(value)}
                                    value={option2}
                                    placeholder="Enter Option 2"
                                    maxLength={40}
                                />
                            </div>
                            <div className="dd_col-3">
                                <Text
                                    onChange={({ target: { value } }) => setOption3(value)}
                                    value={option3}
                                    placeholder="Enter Option 2"
                                    maxLength={40}
                                />
                            </div>
                            <div className="dd_col-3">
                                <Text
                                    onChange={({ target: { value } }) => setOption4(value)}
                                    value={option4}
                                    placeholder="Enter Option 2"
                                    maxLength={40}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                }
            </div>
            <div className="dd_col-12">
                <button className="dd_col-2" type="button" onClick={AddNewQuestion} >Add</button>
            </div>
        </div>
    )
}

AddInfromation.propTypes = {
  newAddedQuestion: PropTypes.func.isRequired
}
