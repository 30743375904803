import CompanyRoutes from "./companies";
import UserRoutes from "./users";
import PatientRoutes from "./patients";
import OrderRoutes from "./orders";
import MiscRoutes from "./misc-routes";
import AdminToolsRoutes from "./tools";
import InsightRoutes from "./insight-routes";
import DevDigitalRoutes from "./dd-routes";
import { path } from "ramda";

const AppRoutes = [
  ...CompanyRoutes,
  ...UserRoutes,
  ...PatientRoutes,
  ...OrderRoutes,
  ...MiscRoutes,
  ...AdminToolsRoutes,
  ...InsightRoutes,
  ...DevDigitalRoutes
];

export default AppRoutes;

export const getRouteParam = (props, key) =>
  path(["match", "params", key], props);
