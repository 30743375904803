import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { compose, withProps } from "recompose";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withHeaderFooter
} from "features/query";
import withBatch from "features/batch";
import { selectors } from "reducers";
import Table from "components/ui/impl/table";
import SendAutomation from "./send-automation";
import moment from "moment";
import DateRangeFilter from "components/shared/date-range-filter";

const LastContactedDate = ({ last_contacted }) => (
  <span
    data-tooltip
    title={moment(last_contacted).format("MMM DD, YYYY - hh:mm A")}
  >
    {last_contacted ? moment(last_contacted).format("YYYY / MM / DD") : ""}
  </span>
);
LastContactedDate.propTypes = {
  last_contacted: PropTypes.string
};
const endDate = moment().subtract(10, "day").format("YYYY-MM-DD");
const startDate = moment("Jan 1 2000").format("YYYY-MM-DD");

const PatientOutreachTable = compose(
  connect(state => ({
    sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state),
    formOfContactOptions: selectors.getFormOfContactOptions(state),
    companyOptions: selectors.getFormOptionsCompany(state)
  })),
  withQuery({
    queryId: "patient-outreach-table",
    initialQuery: {
      sort: {
        by: "last_contacted",
        direction: "asc"
      },
      filters: {
        last_contacted_end: endDate,
        last_contacted_start: startDate
      },
      pagination: {
        page: 1,
        per: 500
      }
    }
  }),
  withApi({
    apiId: "patient-outreach-table",
    api: {
      route: "patients/outreach-patients",
      responseKey: "patients",
      idKey: "ID",
      entityKey: "patientRows"
    }
  }),
  withBatch({ recordIdKey: "ID" }),
  withProps(
    ({
      markedForBatch,
      batchColumn,
      companyOptions,
      sleepCoachOptions,
      formOfContactOptions,
      requestData,
      batchClear,
      toggleEmail,
      toggleText,
      isEmailSelected,
      isTextSelected
    }) => ({
      columns: [
        batchColumn,
        {
          header: "Name",
          field: "last_name",
          //eslint-disable-next-line
          format: ({ first_name, last_name, ID }) => (
            <Link to={`/patients/${ID}`}>
              {first_name} {last_name}
            </Link>
          )
        },
        {
          header: "Last Contacted Date",
          field: "last_contacted",
          format: LastContactedDate,
          renderFilter: props => (
            <DateRangeFilter
              start_key="last_contacted_start"
              end_key="last_contacted_end"
              {...props}
            />
          )
        },
        {
          header: "Last Contacted By",
          field: "last_contacted_by_last_name",
          filter: "lastContactedByID",
          format: ({
            last_contacted_by_first_name,
            last_contacted_by_last_name
          }) =>
            `${last_contacted_by_first_name || ""} ${
              last_contacted_by_last_name || ""
            }`,
          filters: sleepCoachOptions,
          filterDownSelect: true
        },
        {
          header: "Contact Type",
          field: "contact_type",
          filters: formOfContactOptions
        },
        {
          header: "Sleep Coach",
          field: "sleep_coach_last_name",
          filter: "sleepCoachID",
          format: ({ sleep_coach_first_name, sleep_coach_last_name }) =>
            `${sleep_coach_first_name || ""} ${sleep_coach_last_name || ""}`,
          filters: sleepCoachOptions,
          filterDownSelect: true
        },
        {
          header: "Company",
          field: "company_name",
          filter: "companyID",
          format: ({ company_name }) => `${company_name}`,
          filters: companyOptions,
          filterDownSelect: true
        },
        {
          header: "Email",
          field: "email"
        }
      ],
      customDisplay: [
        //eslint-disable-next-line
        () =>
          markedForBatch.length > 0 ? (
            <span style={{ color: "white" }}>
              {markedForBatch.length} Patients Selected
            </span>
          ) : null
      ],
      customButtons: [
        {
          //eslint-disable-next-line
          component: () => (
            <SendAutomation
              key="automation-btn"
              markedForBatch={markedForBatch}
              requestData={requestData}
              batchClear={batchClear}
              toggleEmail={toggleEmail}
              toggleText={toggleText}
              isTextSelected={isTextSelected}
              isEmailSelected={isEmailSelected}
            />
          )
        }
      ]
    })
  ),
  withHeaderFooter({
    perPageOptions: [500, 1000, 3000],
    forceBatchReset: true,
    perPageLimit: 10000,
    scrollable: true,
    showCount: true
  }),
  withTableHandlers()
)(Table);

export default PatientOutreachTable;
