import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import Table from "components/ui/impl/table";
import withBatch from "features/batch";
import { withModal } from "components/ui";
import { makeTrackingUrl } from "utils/misc";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import UpdateBulkTracking from "./update-bulk-tracking";
import Header from "./header";

const Tracking = ({
  isUpdating,
  canUpdate,
  orderId,
  markedForBatch,
  records,
  openModal,
  closeModal,
  batchClear,
  batchColumn
}) => {
  React.useEffect(() => {
    batchClear();
  }, [orderId]);
  return (
    <div>
      <Header showSpinner={isUpdating}>
        <div className="bulk-update-tracking-button-container">
          <span>Tracking</span>
          {canUpdate && (
            <button
              className="no-print"
              disabled={!markedForBatch.length}
              title={`${markedForBatch.length} selected`}
              onClick={() =>
                openModal(
                  <UpdateBulkTracking
                    orderId={orderId}
                    lineItemIds={markedForBatch}
                    closeSelf={() => {
                      batchClear();
                      closeModal();
                    }}
                  />
                )
              }
            >
              Update Tracking
            </button>
          )}
        </div>
      </Header>
      <Table
        isLoading={isUpdating}
        rows={records}
        rowKey="line_item_id"
        columns={[
          /* eslint-disable react/prop-types */
          ...(canUpdate ? [{ ...batchColumn, className: "no-print" }] : []),
          {
            header: "Product ID",
            field: "id"
          },
          {
            header: "Quantity",
            field: "quantity"
          },
          {
            header: "Ship Date",
            field: "shipDate",
            format: ({ shipDate }) =>
              shipDate ? moment(shipDate).format("L") : ""
          },
          {
            header: "Carrier",
            field: "carrier"
          },
          {
            header: "Tracking Number",
            field: "tracking_number",
            format: ({ carrier, tracking_number }) => (
              <a
                href={makeTrackingUrl(carrier, tracking_number)}
                rel="noopener noreferrer"
                target="_blank"
              >
                {tracking_number}
              </a>
            )
          }
        ]}
      />
    </div>
  );
};

export default compose(
  connect((state, props) => {
    const canUpdate = selectors.getCanUpdateTracking(state, props);
    const { isFetching, data: records } = selectors.getOrderLineItems(
      state,
      props
    );
    const isUpdating =
      selectors.getIsUpdatingTracking(state, props) ||
      selectors.getIsSubmittingLineItemRemoval(state, props) ||
      selectors.getIsSubmittingNewLineItem(state, props) ||
      isFetching;
    return {
      isUpdating,
      records,
      canUpdate
    };
  }, mapDispatchToProps),
  withModal,
  withBatch({ recordIdKey: "line_item_id" })
)(Tracking);

Tracking.propTypes = {
  orderId: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool,
  canUpdate: PropTypes.bool,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  markedForBatch: PropTypes.arrayOf(PropTypes.string).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  batchClear: PropTypes.func.isRequired,
  batchColumn: PropTypes.object.isRequired
};
