import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import PerPageSelect from "./per-page-select";
import PageSelect from "./page-select";
import QueryTableContext from "../context";

const TableFooter = ({ perPageOptions }) => {
  const {
    queryDispatcher,
    data: { count, per, page }
  } = useContext(QueryTableContext);

  const setPerPage = useCallback(
    per => {
      queryDispatcher({
        type: "updatePagination",
        per
      });
    },
    [queryDispatcher]
  );

  const setPage = useCallback(
    page => {
      queryDispatcher({
        type: "updatePagination",
        page
      });
    },
    [queryDispatcher]
  );
  return (
    <div className="query-footer">
      {perPageOptions && (
        <PerPageSelect
          per={per}
          perPageOptions={perPageOptions}
          setPerPage={setPerPage}
        />
      )}
      <PageSelect page={page} per={per} setPage={setPage} count={count} />
      <div className="total-container">
        <span className="count-label">Total:</span>
        <span className="count-value">{count}</span>
      </div>
    </div>
  );
};

TableFooter.propTypes = {
  perPageOptions: PropTypes.arrayOf(PropTypes.object)
};

export default React.memo(TableFooter);
