import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ProfileCard } from "components/ui";
import { formatPhone, formatZip } from "utils/misc";
import PatientPhysicianForm from "components/physician-search/patient-physician-form";
import PureModal from "components/ui/modal/pure-modal";
import MdEdit from "react-icons/lib/md/edit";
import IconButton from "components/ui/icon-button";
import { updatePatient } from "actions/patient";

const PhysicianInformation = ({ patientId, physician, updatePatient, readOnly }) => (
  <ProfileCard
    title="Physician Information"
    customDisplay={() => (
      <PureModal
        renderTrigger={({ openModal }) => (
          <IconButton
            icon={<MdEdit />}
            text="Edit"
            onClick={openModal}
            style={{ fontSize: "12px" }}
            disabled={readOnly}
          />
        )}
        renderContent={({ closeModal }) => (
          <PatientPhysicianForm
            patientId={patientId}
            physician={physician}
            updatePatientPhysician={async physician => {
              await updatePatient({
                patientId,
                updates: {
                  physician
                }
              });
              closeModal();
            }}
          />
        )}
      />
    )}
    items={{
      Name: physician?.name,
      "Street Address": physician?.address.street_address,
      City: physician?.address.city,
      State: physician?.address.state,
      ZIP: formatZip(physician?.address.zip),
      Phone: formatPhone(physician?.phone),
      Fax: formatPhone(physician?.fax),
      "Npi #": physician?.npi
    }}
  />
);

PhysicianInformation.propTypes = {
  physician: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street_address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired
    }).isRequired,
    phone: PropTypes.string.isRequired,
    fax: PropTypes.string,
    npi: PropTypes.string
  }),
  patientId: PropTypes.string.isRequired,
  updatePatient: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
PhysicianInformation.defaultProps = {
  physician: {
    name: "",
    address: {
      street_address: "",
      city: "",
      state: "",
      zip: ""
    },
    phone: "",
    fax: "",
    npi: ""
  }
};

PhysicianInformation.defaultProps = {
  readOnly: false,
}

export default connect(null, { updatePatient })(PhysicianInformation);
