import React, { useState } from 'react'
import ComplianceActionViewTab from './compliance-action-view-tab';
import ComplianceMetricsTab from './compliance-metrics-tab';
import './styles.scss'
import PropTypes from "prop-types";

export default function ComplianceDashboard({ params }) {
  const [selectedTab, setSelectedTab] = useState("ComplianceMetrics");
  const companyId = params?.id ?? "";

  return (
    <div>
      {companyId === "" && <header className="page-header">
        <div className="page-header-title">
          <h1>
            <span className="page-title ">Compliance Dashboard</span>
          </h1>
        </div>
      </header>}
      <div>
        <ul className="compliance-dashboard-tabs" style={{ flex: "1 1 0px" }}>
          <li className={selectedTab === "ComplianceMetrics" ? "is-active" : ""} onClick={() => setSelectedTab("ComplianceMetrics")}>
            Compliance Metrics
          </li>
          {/* <li className={selectedTab === "ComplianceContacts" ? "is-active" : ""} onClick={() => setSelectedTab("ComplianceContacts")}>
            Compliance Contacts
          </li> */} {/* Tab temporarily hidden. */}
          <li className={selectedTab === "ActionView" ? "is-active" : ""} onClick={() => setSelectedTab("ActionView")}>
            Action View
          </li>
        </ul>
      </div>
      <div className="content">
        {selectedTab === "ActionView" && <ComplianceActionViewTab companyId={companyId} />}
        {/* {selectedTab === "ComplianceContacts" && <ComplianceContacts companyId={companyId} />} */} {/* Tab temporarily hidden. */}
        {selectedTab === "ComplianceMetrics" && <ComplianceMetricsTab companyId={companyId} />}
      </div>
    </div>
  )
}

ComplianceDashboard.propTypes = {
  params: PropTypes.object,
};