import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MaskBreakdown from "./mask-breakdown";
import ManufacturerBreakdown from "./manufacturer-breakdown";
import HcpcsBreakdown from "./hcpc-breakdown";

const PatientMasksOverview = ({ patientMaskCount }) => {
  const [selectedManufacturer, setSelectedManufacturer] = useState();
  const [selectedHcpcs, setSelectedHcpcs] = useState();
  useEffect(() => {
    setSelectedHcpcs();
    setSelectedManufacturer();
  }, [patientMaskCount]);
  return (
    <div className="interactive-graph-container">
      {!selectedManufacturer ? (
        <MaskBreakdown
          data={patientMaskCount}
          setSelectedManufacturer={setSelectedManufacturer}
        />
      ) : !selectedHcpcs ? (
        <ManufacturerBreakdown
          data={selectedManufacturer.HCPCS}
          setSelectedManufacturer={setSelectedManufacturer}
          selectedManufacturer={selectedManufacturer}
          setSelectedHcpcs={setSelectedHcpcs}
        />
      ) : (
        <HcpcsBreakdown
          data={selectedHcpcs.Items}
          selectedHcpcs={selectedHcpcs}
          setSelectedHcpcs={setSelectedHcpcs}
          setSelectedManufacturer={setSelectedManufacturer}
          selectedManufacturer={selectedManufacturer}
        />
      )}
    </div>
  );
};

PatientMasksOverview.propTypes = {
  patientMaskCount: PropTypes.array.isRequired
};

export default React.memo(PatientMasksOverview);
