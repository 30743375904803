import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import * as R from "ramda";
import { compose } from "recompose";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withHeaderFooter
} from "features/query";
import withBatch from "features/batch";
import Table from "components/ui/impl/table";
import styler from "react-styling";
import AudioPlayer from "components/ui/audio-player";
/* eslint-disable react/prop-types */
const formatDate = date =>
  date ? moment(date).format("MM / DD / YYYY -  h:mm:ss a ") : "";
const formatShortDate = date =>
  date ? moment(date).format("MM / DD / YYYY") : "";

const IVPatientJobsTable = compose(
  connect((state, { queryId, apiId, patientGuidForJobs }) => ({
    queryId: queryId,
    initialQuery: {
      sort: {},
      pagination: {
        per: 15,
        page: 1
      },
      filters: {
        patient_guid: [patientGuidForJobs],
        processed: ["false"]
      },
      searches: {}
    },
    apiId: apiId
  })),
  //This begins the other HOCs
  withQuery(),
  withApi({
    api: {
      route: "IVR/Work/Jobs",
      responseKey: "jobs",
      idKey: "id",
      entityKey: "ivrPatientJobs"
    }
  }),
  withBatch({ recordIdKey: "id" }),
  connect((state, { loadPatient, loadOrder }) => {
    return {
      columns: R.filter(R.is(Object), [
        {
          header: "Patient",
          field: "patientName",
          format: ({ patientGuid, patientName }) => (
            <span
              title={patientGuid}
              style={S.goToJobs}
              onClick={() => loadPatient(patientGuid)}
            >
              {patientName ? patientName : ""}
            </span>
          )
        },
        {
          header: "Order",
          field: "orderGuid",
          format: ({ orderGuid }) => (
            <span style={S.goToJobs} onClick={() => loadOrder(orderGuid)}>
              {orderGuid ? orderGuid : ""}
            </span>
          )
        },
        // {
        //   header: "Type",
        //   field: "tableToUpdate",
        //   format: ({ tableToUpdate }) => (
        //     <span style={{ whiteSpace: "nowrap" }}>
        //       {tableToUpdate && updateRequestDictionary(tableToUpdate)}
        //     </span>
        //   )
        // },
        {
          header: "Field",
          field: "fieldToUpdate",
          format: ({ fieldToUpdate }) => (
            <span style={{ whiteSpace: "nowrap" }}>
              {fieldToUpdate}
              {/* Used to translate sZip to Zip and things like that */}
              {/* {fieldToUpdate && updateRequestDictionary(fieldToUpdate)} */}
            </span>
          )
        },
        {
          header: "CallSid",
          field: "callSid"
        },
        {
          header: "Job ID",
          field: "id"
        },
        {
          header: "Created",
          field: "createdDate",
          format: ({ createdDate }) => (
            <span style={{ whiteSpace: "nowrap" }}>
              {createdDate && formatDate(createdDate)}
            </span>
          )
        },
        {
          header: "Processed Date",
          field: "processedDate",
          format: ({ processedDate }) => (
            <span style={{ whiteSpace: "nowrap" }}>
              {processedDate && formatShortDate(processedDate)}
            </span>
          )
        },
        {
          header: "Processed By",
          field: "processedByName"
        },
        {
          header: "Recording",
          field: "recordingURL",
          format: ({ recordingURL }) => (
            <span style={{ whiteSpace: "nowrap" }}>
              {recordingURL ? (
                <AudioPlayer
                  preloadAudio={"none"}
                  src={recordingURL}
                  showAllFeatures={false}
                />
              ) : (
                ""
              )}
            </span>
          )
        },
        {
          header: "Processed",
          field: "processed",
          format: ({ processed }) =>
            processed === true ? (
              <span
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px"
                }}
              >
                {"True"}
              </span>
            ) : (
              "False"
            ),
          filters: [
            {
              text: "Processed",
              value: "true"
            },
            {
              text: "Unprocessed",
              value: "false"
            }
          ]
        }
      ])
    };
  }),
  withHeaderFooter({
    perPageOptions: [15, 150, 300, 500]
  }),
  withTableHandlers()
)(Table);
IVPatientJobsTable.propTypes = {
  patientGuidForJobs: PropTypes.string,
  updateRequestDictionary: PropTypes.func,
  queryId: PropTypes.string,
  apiId: PropTypes.string
};
export default IVPatientJobsTable;
const S = styler`
  goToJobs
    cursor: pointer
    color: blue;
  worked
    opacity: 0.5
`;
