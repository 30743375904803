import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, getFormValues } from "redux-form";
import {
  compose,
  withHandlers,
  setPropTypes
} from 'recompose'
import DayPicker from 'react-day-picker/DayPicker'
import moment from 'moment'
import { requestTrackingUpdate } from 'actions/order-profile'
import carrierOptions from './carrier-options.js'
import  FieldArraySet  from 'components/ui/field-array-set'

const Error = ({ children }) => (
  <div className='bulk-error'>{children}</div>
)
Error.propTypes = {
  children: PropTypes.number
}

const DateInput = (({
  input: { value },
  handleDayClick,
  meta: { touched, error }
}) => (
  <div>
    {touched && error && <Error>{error}</Error>}
    {value && <div>{moment(value).format('LL')}</div>}
    <DayPicker
      showOutsideDays
      selectedDays={value || null}
      onDayClick={handleDayClick} />
  </div>
))
DateInput.propTypes = {
  input: PropTypes.object,
  handleDayClick: PropTypes.func.isRequired,
  meta: PropTypes.object
}

const validateNoValue = errorMessage => val => val ? undefined : errorMessage
const guessCarrierCompany = formValues => {  
  if (!formValues || !formValues.tracking_number || !formValues.tracking_number.value ){return ""}
  let val = formValues.tracking_number.value
  if (val === undefined) { return "" }
  let input = val.trim()
  let carrierGuess = "Undetermined Carrier"
  //FEDEX
  let fedEx1 = new RegExp('^[0-9]{20}$')
  let fedEx2 = new RegExp('^[0-9]{15}$')
  let fedEx3 = new RegExp('^[0-9]{12}$')  //might overlap with ups2
  let fedEx4 = new RegExp('^[0-9]{22}$')   //might overlap with usps1
  //UPS mine
  let ups1 = new RegExp('b(1Z)[0-9]{16}')
  let ups2 = new RegExp('b([0-9]{12})') //might overlap with fedex3
  let ups3 = new RegExp('b[T]{1}[0-9]{10}')
  let ups4 = new RegExp('b[0-9]{9}') //might overlap with dhl1
  let ups5 = new RegExp('b(MI)[0-9]{6}([A-z]|[0-9]){0,22}') //Mail innovation 
  //Their UPS
  let ups1b = new RegExp('^(1Z)[0-9A-Z]{16}$')
  let ups2b = new RegExp('^(T)+[0-9A-Z]{10}$')
  let ups3b = new RegExp('^[0-9]{9}$') //Might overlap with dhl1 
  let ups4b = new RegExp('^[0-9]{26}$')  //Might overlap with 
  //ONTRAC
  let ontrac = new RegExp('(C|c){1}([0-9]{14})$')
  //USPS
  let usps1 = new RegExp('^(94|93|92|94|95)[0-9]{20}$') //might overlap with fedEx4
  let usps2 = new RegExp('^(94|93|92|94|95)[0-9]{22}$')
  let usps6 = new RegExp('^(94|93|92|94|95)[0-9]{24}$') //Might overlap with ups4b
  let usps3 = new RegExp('^(70|14|23|03)[0-9]{14}$')
  let usps4 = new RegExp('^(M0|82)[0-9]{8}$')
  let usps5 = new RegExp('^([A-Z]{2})[0-9]{9}([A-Z]{2})$')
  //DHL
  let dhl1 = new RegExp('^[0-9]{9,10}$') //Might overlap with ups3b

  if (fedEx1.test(input) || fedEx2.test(input) || fedEx3.test(input) || fedEx4.test(input)) {
    if (input.length === 22 && usps1.test(input)) {
      carrierGuess = "USPS is our guess at Carrier"
    }
    else if (input.length === 12 && ups2.test(input)) {
      carrierGuess = "FEDEX or UPS is our guess at Carrier"
    }
    else {
      carrierGuess = "FEDEX is our guess at Carrier"
    }    
  }
  else if (ups1.test(input) || ups2.test(input) || ups3.test(input) || ups4.test(input) || ups5.test(input) ||
           ups1b.test(input) || ups2b.test(input) || ups3b.test(input) || ups4b.test(input) 
  ) {
    if (input.length === 9 && dhl1.test(input)) {
      carrierGuess = "DHL or UPS is our guess at Carrier"
    }
    else if (input.length === 12 && ups2.test(input)) {
      carrierGuess = "FEDEX or UPS is our guess at Carrier"
    }
    else if (input.length === 26 && usps6.test(input)) {
      carrierGuess = "USPS or UPS is our guess at Carrier"
    }
    else {
      carrierGuess = "UPS is our guess at Carrier"
    }    
  }
  else if (ontrac.test(input)) {
    carrierGuess = "ONTRAC is our guess at Carrier"
  }
  else if (usps1.test(input) || usps2.test(input) || usps3.test(input) || usps4.test(input) || usps5.test(input)) {     
      carrierGuess = "USPS is our guess at Carrier"       
  }    
  else if (dhl1.test(input)) {
    carrierGuess = "DHL or UPS is our guess at Carrier"
    }
  else {
    carrierGuess = "Undetermined Carrier: Choose Other"
  }
  return carrierGuess
}


const FIELD_OPTIONS = [ /* eslint-disable react/prop-types */
  {
    field: 'ship_date',
    text: 'Ship Date',
    fieldProps: {
      component: withHandlers({
        handleDayClick: ({ input: { onChange } }) => (day, { selected }) => {
          onChange(selected ? undefined : day)
        }
      })(DateInput),
      validate: validateNoValue('Please select a day.')
    }
  },
  {    
    field: 'tracking_number',
    text: 'Tracking Number',
    fieldProps: {
      component: (({
        input,
        meta: { touched, error }
      }) => (
        <div>
          {touched && error && <Error>{error}</Error>}
          <input type='text' {...input} />
        </div>
      )),
      validate: validateNoValue('Please supply a tracking number.')
    }
  },
  {
    field: 'carrier',
    text: 'Carrier',
    fieldProps: {
      component: (({
        input,
        meta: { touched, error }
      }) => (
        <div>
          {touched && error && <Error>{error}</Error>}
          <select {...input}>
            {carrierOptions}
          </select>
        </div>
      )),
      validate: validateNoValue('Please choose a carrier.')
    }
  }
]

const UpdateBulkTracking = ({ handleBulkSubmit, isSubmitDisabled, trackingNumberValue }) => (
  <form
    onSubmit={handleBulkSubmit}
    className='bulk-tracking-update'>
    <h3>Bulk Tracking Update</h3>  
    <FieldArraySet
      name='trackingFields'
      fieldOptions={FIELD_OPTIONS}
    />     
    <div className='submit-container'>
      <button
        type='submit'
        disabled={isSubmitDisabled}>
        Submit
      </button>
      <span className="carrierGuess">{guessCarrierCompany(trackingNumberValue)}</span>
    </div>
  </form>
)
UpdateBulkTracking.propTypes = {
  handleBulkSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  trackingNumberValue: PropTypes.object
};

const form = 'updateBulkTracking'
const getFormState = state => state.get('form')
const formatDate = d => moment(d).format('YYYY/MM/DD')
export default compose(
  setPropTypes({
    orderId: PropTypes.string.isRequired,
    lineItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    closeSelf: PropTypes.func
  }),
  reduxForm({ form, getFormState }),
  connect((state, { invalid }) => {
    const formValues = getFormValues(form, getFormState)(state)
    return {
      isSubmitDisabled: invalid,
      trackingNumberValue: formValues
    }
  }, (dispatch, { orderId, lineItemIds, handleSubmit, closeSelf }) => ({
      handleBulkSubmit: handleSubmit((trackingFields) => {
        if (!trackingFields){return ""}
        let update = {
          orderId: orderId,
          lineItemIds: lineItemIds,
          ship_date: formatDate(trackingFields.ship_date.value),
          carrier: trackingFields.carrier.value,
          tracking_number: trackingFields.tracking_number.value
        }       
      dispatch(requestTrackingUpdate(update))

      if (closeSelf) {
        closeSelf()
      }
    })
  }))
)(UpdateBulkTracking)
