import React from 'react'
import PropTypes from 'prop-types'

const Subheader = ({ children }) => (
  <h5 className='order-profile-subheader'>{children}</h5>
)

Subheader.propTypes = {
  children: PropTypes.node
}

export default Subheader
