import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CardContainer from "./card-container";
import styler from "react-styling";
import OrdersClaimedTooltip from "./orders-claimed-tooltip";
import {
  BarChart,
  ResponsiveContainer,
  Tooltip,
  Bar,
  XAxis,
  YAxis
} from "recharts";
import {
  USER_FETCH_ACTION_CREATORS,
  OFFICE_ADMINISTRATOR,
  COMPANY_ADMINISTRATOR
} from "actions/users";
import { getUsers } from "reducers";
import { connect, useDispatch } from "react-redux";
import { usePagination, useSort } from "hooks";
import {
  InlineSortControls,
  PageControls
} from "components/ui/graph-components";

const OrdersClaimedCard = props => {
  const dispatch = useDispatch();
  const { chartData } = props;

  const fetchUser = userType => {
    const [fetchUsers, invalidateUsers] = USER_FETCH_ACTION_CREATORS[userType];
    dispatch(invalidateUsers());
    dispatch(fetchUsers());
  };

  useEffect(() => {
    fetchUser(OFFICE_ADMINISTRATOR);
    fetchUser(COMPANY_ADMINISTRATOR);
  }, []);

  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "claimedOrdersLast90Days", ascending: false });
  const { curPageNum, totalPages, setPageNum, getPageData } = usePagination(
    chartData,
    25
  );
  return (
    <CardContainer
      classProp={props.classProp}
      title="Orders Claimed Last 90 Days"
    >
      <div style={S.ordersClaimedContainer}>
        <div style={S.ordersClaimedGraphControls}>
          <PageControls
            curPageNum={curPageNum}
            totalPages={totalPages}
            setPageNum={setPageNum}
            alwaysShow={false}
          />
          <InlineSortControls
            sortOptions={[
              { key: "name", text: "Name" },
              { key: "claimedOrdersLast90Days", text: "Count" }
            ]}
            setSortBy={setSortBy}
            sortBy={sortBy}
            ascending={ascending}
            toggleSortDirection={toggleSortDirection}
          />
        </div>
        <div style={S.barChartContainer}>
          <ResponsiveContainer
            width="100%"
            height={575}
            style={{ paddingTop: 10 }}
          >
            <BarChart
              width={550}
              height={600}
              data={getPageData(curPageNum, getSortedArray)}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" domain={[0, "dataMax"]} scale="pow" />
              <YAxis width={135} dataKey="name" type="category" />
              <Tooltip content={<OrdersClaimedTooltip />} offset={15} />
              <Bar
                layout="vertical"
                dataKey="claimedOrdersLast90Days"
                fill="#4281a6"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </CardContainer>
  );
};

const S = styler`
  ordersClaimedContainer
    height: 83%
  sortBar
    display: flex
    flex-direction: row
    justify-content: space-between
  sortControl
    display: flex
    flexDirection: row
    fontWeight: bold
    cursor: pointer
    width: 70%
  sortIndicator
    width: 15%
  ordersClaimedGraphControls
    border-top: 1px solid #888
    border-bottom: 1px solid #888
    margin: 0.5rem 0 .5rem 0
    padding: 10px
    color: #249f94
    display: flex
    justify-content: space-between
    flex: 1
    align-items: center
  barChartContainer
    padding-top: 30px
    overflow-y: auto
    height: 100%
  name
    padding-right: .75rem
  customTooltip
    color: black
`;

OrdersClaimedCard.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  classProp: PropTypes.string
};

export default connect(state => {
  const companyAdministrators = getUsers(state, COMPANY_ADMINISTRATOR);
  const officeAdministrators = getUsers(state, OFFICE_ADMINISTRATOR);
  return {
    chartData: [...companyAdministrators, ...officeAdministrators]
      .filter(user => !!user.active)
      .map(item => ({
        name: item.name,
        claimedOrdersLast90Days: item.claimedOrdersLast90Days
      }))
  };
})(OrdersClaimedCard);
