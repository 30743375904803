import React from "react";
import PropTypes from "prop-types";
import PureModal from "components/ui/modal/pure-modal";
import { aLink } from "utils/styles";
import { LoginHistoryModal } from "./login-history-modal";
import moment from "moment";

export const TotalTimeRow = ({ data }) => {
  const formatTime = time => {
    const hours = Math.floor(moment.duration(time).asHours());
    const minutes = Math.ceil(moment.duration(time).asMinutes()) % 60;
    return hours + "h" + minutes + "m";
  };

  return (
    <td className="total-row">
      <PureModal
        renderTrigger={({ openModal }) => (
          <div className="key-row"
            style={{...aLink, marginBottom: "3px", marginTop: "12px"}}
            onClick={() => {
              openModal();
            }}
          >
           <div>Connection: {formatTime(data?.totalConnectionTime)}</div>
           <div className="connection-color-indicator"></div>
          </div>
        )}
      >
        <LoginHistoryModal
          data={data?.connectionData || []}
          total={data?.totalConnectionTime || []}
          coach={data?.coachName}
        />
      </PureModal>
      <PureModal
        renderTrigger={({ openModal }) => (
          <div
          className="key-row"
            style={aLink}
            onClick={() => {
              openModal();
            }}
          >
           <div> AutoDialer: {formatTime(data?.totalAutoDialerTime)}</div>
            <div className="autodialer-color-indicator"></div>
          </div>
        )}
      >
        <LoginHistoryModal
          data={data?.autodialerData || []}
          total={data?.totalAutoDialerTime || []}
          coach={data.coach}
        />
      </PureModal>
    </td>
  );
};

TotalTimeRow.propTypes = {
  data: PropTypes.object.isRequired
};
