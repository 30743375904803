import React, { Component } from 'react'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'
import without from 'lodash/without'
import difference from 'lodash/difference'
import includes from 'lodash/includes'

const withBatch = ({
  clearOnNewRecords = true,
  recordIdKey
} = {}) => WrappedComponent => (
  class WithBatch extends Component {
    static displayName = `WithBatch(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
    static defaultProps = {
      clearOnNewRecords: clearOnNewRecords,
      recordIdKey
    }
    static propTypes = {
      clearOnNewRecords: PropTypes.bool.isRequired,
      records: PropTypes.arrayOf(PropTypes.object),
      recordIdKey: PropTypes.string
    }

    constructor(props) {
      super(props)
      this.state = {
        markedForBatch: []
      }
    }
    // performance optimization
    // batchColumn prop is causing unecessary rerenders!
    shouldComponentUpdate(nextProps, nextState) {  
      return (
        JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
        JSON.stringify(this.state) !== JSON.stringify(nextState)
      );
    }

    handleBatchClear = () => this.setState({ markedForBatch: [] })

    handleCheck = ({ target: { name, checked } }) => {
      this.setState(({ markedForBatch }) => ({
        markedForBatch: checked
          ? uniq([...markedForBatch, name])
          : without(markedForBatch, name)
      }))
    }

    handleMarkForBatch = (...vals) => {
      this.setState(({ markedForBatch }) => ({
        markedForBatch: uniq([...markedForBatch, ...vals])
      }))
    }

    render() {
      const {
        recordIdKey,
        records
      } = this.props
      const { markedForBatch } = this.state

      return (
        <WrappedComponent
          checkForBatch={this.handleCheck}
          markForBatch={this.handleMarkForBatch}
          batchClear={this.handleBatchClear}
          markedForBatch={this.state.markedForBatch}
          batchColumn={recordIdKey ? {
            header: 'All',
            field: 'batch-column',
            onHeaderClick: () => {
              const ids = records.map(r => r[recordIdKey])
              const currentlyUnmarked = difference(ids, markedForBatch)
              if (currentlyUnmarked.length) {
                // if any records aren't marked already, then mark all
                this.handleMarkForBatch(...ids)
              } else {
                // else if all records are marked, clear batched ids
                this.handleBatchClear()
              }
            },
            format: record => {
              const id = record[recordIdKey]
              return (
                <input
                  type='checkbox'
                  name={id}
                  checked={includes(markedForBatch, id)}
                  onChange={this.handleCheck} />
              )
            }
          } : undefined}
          {...this.props} />
      )
    }
  }
)

export default withBatch
