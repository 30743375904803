import { put, take, call } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { message } from "actions/message";
import { Types } from "features/signalR/actions";
import { Types as TeamDashboardTypes } from "actions/team-dashboard";
import { Types as UserProfileTypes } from "actions/user-profile";
import { Types as AppVersionTypes } from "actions/app-version";
import { setPhoneQueues } from "actions/phone-queues";

const handlers = {
  alert: emit => payload => {
    emit(message(payload, -1));
  },
  queueUpdate: emit => payload => {
    emit(setPhoneQueues(payload));
  },
  Clients: emit => clients => {
    emit({ type: Types.CLIENTS, payload: clients });
  },
  ClientConnected: emit => client => {
    emit({ type: Types.CLIENT_CONNECTED, payload: client });
  },
  ClientDisconnected: emit => client => {
    emit({ type: Types.CLIENT_DISCONNECTED, payload: client });
  },
  ClientInCall: emit => payload => {
    emit({ type: Types.CLIENT_UPDATED, payload });
  },
  ClientEndedCall: emit => payload => {
    emit({ type: Types.CLIENT_UPDATED, payload });
  },
  ClientStartedAutodialer: emit => payload => {
    emit({ type: Types.CLIENT_UPDATED, payload });
  },
  ClientEndedAutodialer: emit => payload => {
    emit({ type: Types.CLIENT_UPDATED, payload });
  },
  UpdatedCoachOnlineTime: emit => (coach_guid, seconds) => {
    emit({
      type: TeamDashboardTypes.UPDATED_COACH_TOTAL_TIME,
      payload: { coach_guid, seconds: Math.ceil(seconds) }
    });
  },
  UpdatedCoachQueueTime: emit => (coach_guid, seconds) => {
    emit({
      type: TeamDashboardTypes.UPDATED_COACH_QUEUE_TIME,
      payload: { coach_guid, seconds: Math.ceil(seconds) }
    });
  },
  CoachSettingsUpdated: emit => payload => {
    const { outboundCallRestricted, dialerInboundBlocked } = payload;
    emit({
      type: UserProfileTypes.USER_SETTINGS_UPDATE_EVENT,
      payload: {
        outboundCallRestricted,
        block_autodialer_incoming_calls: dialerInboundBlocked
      }
    });
  },
  AppUpdate:
    emit =>
    ({ version, environment }) => {
      emit({
        type: AppVersionTypes.CHECK_APP_VERSION_EVENT,
        version,
        environment
      });
    },
  CallEnqueued: emit => payload => {
    console.log("+ call enqueued! +");
    emit({
      type: "CALL_ENQUEUED",
      payload
    });
  },
  CallDequeued: emit => payload => {
    console.log("- call dequeued! -");
    emit({
      type: "CALL_DEQUEUED",
      payload
    });
  }
};

const eventHandler = (hub, emit) => {
  try {
    Object.keys(handlers).forEach(event => {
      hub.on(event, handlers[event](emit));
    });
  } catch (error) {
    //eslint-disable-next-line no-console
    console.error(error);
    emit({ type: Types.SIGNALR_ERROR, error });
  }
};

const createHubChannel = hub =>
  eventChannel(emit => {
    hub.onclose(() => {
      emit({ type: Types.SIGNALR_DISCONNECTED });
    });
    eventHandler(hub, emit);
    return () => {
      //TODO: do i need to unsubscribe from events?
    };
  });

export default function* watchHubEvents(hub) {
  const socketChannel = yield call(createHubChannel, hub);
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}
