import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader } from "components/ui/index";
import { createProductVideo } from "./../../Redux/Actions/videoLibrary";
import ProductVideos from "./product-videos";
import PureModal from "components/ui/modal/pure-modal";
import ProductVideoForm from "./product-video-form";
import FilterableProductSelect from "components/forms/filterable-product-select";

const VideoLibrary = ({ createProductVideo }) => {
  const [selectedManufacturer, setSelectedManufacturer] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState("");
  return (
    <div>
      <PageHeader title="Product Videos" />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          gridGap: 10
        }}
      >
        <FilterableProductSelect
          selectedManufacturer={selectedManufacturer}
          setSelectedManufacturer={setSelectedManufacturer}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
        <PureModal
          renderTrigger={({ openModal }) => (
            <button
              type="button"
              style={{ margin: 0, height: "100%" }}
              disabled={!selectedProduct}
              onClick={openModal}
            >
              Add Video
            </button>
          )}
          renderContent={({ closeModal }) => (
            <ProductVideoForm
              handleCancel={closeModal}
              onSubmit={async values => {
                await createProductVideo(selectedProduct, values);
                closeModal();
              }}
            />
          )}
        />
      </div>
      <hr />
      <ProductVideos productId={selectedProduct} />
    </div>
  );
};

export default connect(() => ({}), {
  createProductVideo
})(VideoLibrary);

VideoLibrary.propTypes = {
  createProductVideo: PropTypes.func.isRequired
};
