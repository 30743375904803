import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { get } from "utils/api";
// import Subheader from "../subheader";
import { Rows } from "components/ui";

const PhilipsOrderInfo = ({ orderId }) => {
  const { fetchData, response: phyilipsDysfunctions } = useFetch({
    apiFn: orderId => get(`orders/philips-order/${orderId}`),
    defaultValue: []
  });
  React.useEffect(() => {
    fetchData(orderId);
  }, [orderId]);
  return (
    <div>
      <h4>IVR Responses</h4>
      {/* <Subheader>Dysfunctions</Subheader> */}
      <div>
        {phyilipsDysfunctions.map(({ response, id, text }) => (
          <Rows key={id}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                justifyContent: "space-between"
              }}
            >
              <div>{text}</div>
              {response && response !== "undefined" && (
                <div
                  style={{
                    background: "#ddd",
                    borderRadius: 5,
                    padding: 5
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>{response}</span>
                </div>
              )}
            </div>
          </Rows>
        ))}
      </div>
    </div>
  );
};

PhilipsOrderInfo.propTypes = { orderId: PropTypes.string.isRequired };

export default PhilipsOrderInfo;
