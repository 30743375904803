import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";
import { colors } from "../../utils/styles";

// assumes a list of objects, with value and text for the select options
export class PopupSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  toggle() {
    this.setState({ show: !this.state.show });
  }

  render() {
    const { list, styles = {}, label, onChange } = this.props;
    const show = this.state.show;

    return (
      <label style={S.label}>
        <span style={styles} onClick={() => this.toggle()}>{label}</span>

        {show &&
          <ul style={S.ul}>
            <li
              style={S.li}
              onClick={() => {
                this.toggle();
                onChange("all");
              }}
            >
              All
            </li>

            {list.map((x, i) => (
              <li
                style={S.li}
                key={i}
                onClick={() => {
                  this.toggle();
                  onChange(x.value);
                }}
              >
                {x.text}
              </li>
            ))}
          </ul>}
      </label>
    );
  }
}

export default Radium(PopupSelect);
PopupSelect.propTypes = {
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  value: PropTypes.string,
  styles: PropTypes.object,
  label: PropTypes.string
};

const S = styler`
  label
    position: relative
    font-weight: inherit
  ul
    position: absolute
    background: #fff
    padding: 0.5rem
    border: 1px solid #C5C4C4
    box-shadow: 3px 14px 20px rgba(0, 0, 0, 0.1)
    top: 28px
    z-index: 1
  li
    cursor: pointer
    color: ${colors.primaryColor}
    font-weight: bold
    padding: 5px 10px
    :hover
      background-color: ${colors.secondaryColor}
`;
