import React from "react";
import { useFetch } from "hooks";
import { get } from "utils/api";

const SynthpopIntegrationTesting = () => {
  const initiateSynthpopCallApi = useFetch({
    apiFn: patientId =>
      get(`synthpop/initiate-call/${patientId}`).then(x => setCallSid(x))
  });

  const fetchSynthpopTaskApi = useFetch({
    apiFn: patientId =>
      get(`synthpop/fetch-task-details/${patientId}`).then(x => setTaskInfo(x))
  });

  const [patientId, setPatientId] = React.useState("");
  const [callSid, setCallSid] = React.useState(null);
  const [fetchCallSid, setFetchCallSid] = React.useState("");
  const [taskInfo, setTaskInfo] = React.useState(null);

  return (
    <div>
      <h1>Synthpop Integration Testing</h1>
      <div>
        <h3>Initiate Synthpop Call</h3>
        <input
          type="text"
          placeholder="Patient Guid"
          value={patientId}
          onChange={e => setPatientId(e.target.value)}
        />
        <button onClick={() => initiateSynthpopCallApi.fetchData(patientId)}>
          Submit
        </button>
        {callSid ? <div>Call sid: {JSON.stringify(callSid)}</div> : ""}
      </div>

      <div>
        <h3>Fetch Synthpop Task</h3>
        <input
          type="text"
          placeholder="Call Sid"
          value={fetchCallSid}
          onChange={e => setFetchCallSid(e.target.value)}
        />
        <button onClick={() => fetchSynthpopTaskApi.fetchData(fetchCallSid)}>
          Submit
        </button>
        {taskInfo ? <div>Task Info: {JSON.stringify(taskInfo)}</div> : ""}
      </div>
    </div>
  );
};

export default SynthpopIntegrationTesting;
