import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  fetchPatientContactRecords,
  invalidatePatientContactRecords
} from "actions/patient";
import { TableButton, Tooltip } from "components/ui";
import moment from "moment";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { MdCheck, MdClose } from "react-icons/lib/md";
import { IoAndroidRefresh } from "react-icons/lib/io";
import { isEmpty } from "ramda";
import { createTab } from "actions/system-tray";

const ContactsTab = ({
  patientId,
  fetchPatientContactRecords,
  invalidatePatientContactRecords,
  isFetchingContactRecords,
  contactRecords,
  createTab,
  userRole
}) => {
  useEffect(() => {
    if (isEmpty(contactRecords)) fetchPatientContactRecords({ patientId });
  }, [patientId]);
  const { rows, query, queryDispatcher } = useLocalQuery(contactRecords, {
    sort: { by: "created_at", direction: "desc" }
  });

  return (
    <div>
      <Table
        isFetching={isFetchingContactRecords}
        containerClass="query-table patient-tab-table"
        renderFooter={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              justifyContent: "space-between"
            }}
          >
            <TableButton
              onClick={() => {
                invalidatePatientContactRecords({ patientId });
                fetchPatientContactRecords({ patientId });
              }}
              disabled={isFetchingContactRecords}
            >
              <IoAndroidRefresh color="#4281a6" size={14} />
            </TableButton>
            <div className="total-container">
              <span className="count-label">Total:</span>
              <span className="count-value">{rows.length}</span>
            </div>
          </div>
        }
        TableRow={row => (
          <ContactsTableRow
            {...row}
            userRole={userRole}
            createTab={createTab}
          />
        )}
        columns={[
          {
            header: "Date",
            field: "created_at"
          },
          {
            header: "Type",
            field: "type",
            filters: [
              { text: "Phone", value: "Phone" },
              { text: "Email", value: "Email" },
              { text: "SMS", value: "SMS" }
            ]
          },
          {
            header: "Category",
            field: "category",
            filters: [
              { text: "Outgoing Call", value: "Outgoing Call" },
              { text: "Incoming Call", value: "Incoming Call" },
              { text: "Left Voicemail", value: "Left Voicemail" },
              { text: "Resupply Request", value: "Resupply Request" },
              { text: "Text Message", value: "Text Message" },
              {
                text: "Download Mobile App Request",
                value: "Download Mobile App Request"
              },
              {
                text: "Consent To Text Message Request",
                value: "Consent To Text Message Request"
              },
              {
                text: "Other",
                value: "Other"
              }
            ]
          },
          {
            header: "Success",
            field: "success",
            filters: [
              { text: "Success", value: true },
              { text: "Failed", value: false }
            ]
          },
          {
            header: "Disposition",
            field: "disposition"
          },
          {
            header: "User",
            field: "user_name"
          },
          {
            header: "Notes",
            field: "notes",
            style: { width: "40%" }
          }
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
      />
    </div>
  );
};

ContactsTab.propTypes = {
  contactRecords: PropTypes.array.isRequired,
  createTab: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  fetchPatientContactRecords: PropTypes.func.isRequired,
  invalidatePatientContactRecords: PropTypes.func.isRequired,
  isFetchingContactRecords: PropTypes.bool,
  userRole: PropTypes.string.isRequired
};

export default connect(
  (state, { patientId }) => ({
    contactRecords: selectors.getPatientContactRecords(state, patientId),
    isFetchingContactRecords: state.getIn(
      ["fetch", fetchPatientContactRecords.baseType, patientId, "isFetching"],
      false
    ),
    userRole: selectors.getUserRole(state)
  }),
  { fetchPatientContactRecords, createTab, invalidatePatientContactRecords }
)(ContactsTab);

const ContactsTableRow = ({
  // userRole,
  parent_call_sid,
  created_at,
  createTab,
  type,
  category,
  success,
  user_name,
  notes,
  call_disposition,
  call_disposition_notes,
  call_dispoition_transfer_reason
}) => {
  var message = () => {
    if (call_disposition_notes && call_dispoition_transfer_reason) {
      return `Transfer Reason: ${call_dispoition_transfer_reason} -
       Notes: ${call_disposition_notes}`;
    }
    if (call_disposition_notes) return `Notes: ${call_disposition_notes}`;
    return `Transfer Reason: ${call_dispoition_transfer_reason}`;
  };

  return (
    <tr>
      <td>
        {
          //Handling this on the back end for now
          // (userRole === "Administrator" ||
          // userRole === "ServiceAdmin" ||
          // userRole === "ContractorAdministrator") &&
          parent_call_sid ? (
            <div
              className="link"
              onClick={() => {
                createTab({
                  sid: parent_call_sid,
                  key: "contact-record",
                  type: "contact-record",
                  label: "Call Log"
                });
              }}
            >
              {moment(created_at).format("MMM DD, YYYY  hh:mm A")}
            </div>
          ) : (
            moment(created_at).format("MMM DD, YYYY  hh:mm A")
          )
        }
      </td>
      <td>{type}</td>
      <td>{category}</td>
      <td>
        {success ? (
          <MdCheck color="green" size={16} />
        ) : (
          <MdClose color="red" size={16} />
        )}
      </td>
      <td>
        {call_disposition_notes || call_dispoition_transfer_reason ? (
          <Tooltip
            id={parent_call_sid}
            // className="open-orders-tooltip"
            effect="solid"
            message={message()}
          >
            <div style={{ fontWeight: "bold" }}>{call_disposition}</div>
          </Tooltip>
        ) : (
          <div>{call_disposition}</div>

          // include thing if you hover
        )}
      </td>
      <td>{user_name}</td>
      <td
        style={{
          fontSize: 10,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "wrap"
        }}
      >
        {notes}
      </td>
    </tr>
  );
};

ContactsTableRow.propTypes = {
  // userRole: PropTypes.string.isRequired,
  createTab: PropTypes.func.isRequired,
  parent_call_sid: PropTypes.string,
  created_at: PropTypes.string,
  type: PropTypes.string,
  category: PropTypes.string,
  success: PropTypes.bool,
  user_name: PropTypes.string,
  notes: PropTypes.string,
  call_disposition: PropTypes.string,
  call_disposition_notes: PropTypes.string,
  call_dispoition_transfer_reason: PropTypes.string
};
