import React from "react";
import PropTypes from "prop-types";
import {
  InlineSortControls,
  PageControls,
  AxisLabel
} from "components/ui/graph-components";
import {
  ResponsiveContainer,
  LabelList,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from "recharts";
import { usePagination, useSort } from "hooks";

const PhysicianGraphTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div style={{ background: "white", padding: 4 }}>
        <div style={{ color: "#25a8e0" }}>Physician: {`${label}`}</div>
        <div className="">Count: {`${payload && payload[0].value}`}</div>
        <div className="">
          Insurances: {`${payload && payload[0].payload.Items.length}`}
        </div>
      </div>
    );
  }
  return null;
};

PhysicianGraphTooltip.defaultProps = {
  payload: []
};

PhysicianGraphTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

const PhysicianBreakdown = ({ data, setSelectedPhysician }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "Key", ascending: true });
  const { curPageNum, totalPages, setPageNum, getPageData } = usePagination(
    data,
    20
  );
  return (
    <React.Fragment>
      <div className="interactive-graph-header">
        <span />
        <PageControls
          curPageNum={curPageNum}
          totalPages={totalPages}
          setPageNum={setPageNum}
          alwaysShow={false}
        />
        <h4>Physicians</h4>
        <InlineSortControls
          sortOptions={[
            { key: "Key", text: "Name" },
            { key: "Count", text: "Count" }
          ]}
          setSortBy={setSortBy}
          sortBy={sortBy}
          ascending={ascending}
          toggleSortDirection={toggleSortDirection}
        />
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={getPageData(curPageNum, getSortedArray)}
          layout="vertical"
          barSize={20}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="Count"
            domain={[0, "dataMax + 5"]}
            scale="pow"
          />
          <YAxis
            type="category"
            dataKey="Key"
            width={250}
            interval={0}
            tick={
              <AxisLabel
                style={{
                  fontSize: "12px",
                  lineHeight: "18px"
                }}
              />
            }
          />
          <Tooltip content={<PhysicianGraphTooltip />} />
          <Bar
            dataKey="Count"
            fill="rgb(49, 130, 189)"
            onClick={c => {
              setSelectedPhysician(c);
            }}
            maxBarSize={50}
          >
            <LabelList dataKey="Count" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default PhysicianBreakdown;

PhysicianBreakdown.propTypes = {
  data: PropTypes.array.isRequired,
  setSelectedPhysician: PropTypes.func.isRequired
};
