import React, {useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUpcomingChat } from "components/dd_newModules/Redux/Actions/schedule";
import UpcomingChat from "./upcoming-chat.js";
import "./upcoming-chats.scss";
import { Spinner } from "components/ui";

const UpcomingChats = ({ getUpcomingChat, upcomingChatsList, isLoading, timeZone, resetTimeZoneValue}) => {
  React.useEffect(() => {
    getUpcomingChat();
  }, []);

  useEffect(() => {   
    if(timeZone){
        getUpcomingChat();
        resetTimeZoneValue();
    }
  }, [timeZone]);

  return !isLoading ? (
    <div className="upcoming-chats">
      {upcomingChatsList?.length == 0 ? (
        <div className="no-upcoming-chats-msg">
          You Have No Upcoming Chats/Demos
        </div>
      ) : (
        <div className="upcoming-chats-container">
          {upcomingChatsList?.map(data => {
            if(data.chats?.length > 0){
                return (
                    <UpcomingChat key={data.slot_id} {...data} getUpcomingChat={getUpcomingChat} />
                )
            }
          })}
        </div>
      )}
    </div>
  ) : (
    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
       <Spinner/> 
    </div>
  );
};

UpcomingChats.propTypes = {
  getUpcomingChat: PropTypes.func.isRequired,
  upcomingChatsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  resetTimeZoneValue: PropTypes.func
};

export default connect(
  state => ({
    upcomingChatsList: state.getIn(["newRootReducer"]).rdc_schedule.UpcomingChat,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  { getUpcomingChat }
)(UpcomingChats);
