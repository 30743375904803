import React from "react";
import PropTypes from "prop-types";
import HasRolePermission from "components/shared/has-role-permission";
import { ListItem, InlineWaiting } from "components/ui";
import PureModal from "components/ui/modal/pure-modal";
import { useDispatch } from "react-redux";
import {
  togglePauseCompanyIVR,
  killCompanyIVRJob,
  startCompanyIVRThread
} from "actions/company";

const IvrDetails = ({ company }) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = React.useState(false);
  return (
    <HasRolePermission allowedRoles={["Administrator"]}>
      <PureModal
        renderTrigger={({ openModal }) => (
          <button
            className="button tiny secondary"
            style={{ marginLeft: "20px" }}
            onClick={openModal}
          >
            IVR Details
          </button>
        )}
        renderContent={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 20
            }}
          >
            <ListItem
              label="number of patients to pull from ivr batch"
              style={{ foneWeight: 300 }}
              value={company.number_of_patients_to_pull_from_ivr_batch}
            />
            <ListItem
              label="number of patients to call per request"
              value={company.number_of_patients_to_call_per_request}
              style={{ foneWeight: 300 }}
            />
            <ListItem
              label="Max total threads per request"
              value={company.max_total_threads_per_request}
              style={{ foneWeight: 300 }}
            />
            <ListItem
              label="Minutes to wait between ivr batches"
              value={company.minutes_to_wait_between_ivr_batches}
              style={{ foneWeight: 300 }}
            />
            <ListItem
              label="limit patient calls for request"
              value={company.limit_patient_calls_for_request ? "Yes" : "No"}
              style={{ foneWeight: 300 }}
            />
            <ListItem
              label="Is Paused"
              style={{ foneWeight: 300 }}
              value={company.pause_ivr ? "Yes" : "No"}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flex: 1,
                paddingTop: 5
              }}
            >
              {isFetching ? (
                <InlineWaiting />
              ) : (
                <React.Fragment>
                  <button
                    style={{ margin: 0 }}
                    className="warning"
                    disabled
                    onClick={async () => {
                      try {
                        setIsFetching(true);
                        await dispatch(killCompanyIVRJob(company.id));
                      } catch (e) {
                        // handle error
                      } finally {
                        setIsFetching(false);
                      }
                    }}
                  >
                    Kill IVR Job
                  </button>
                  <button
                    style={{ margin: 0, marginLeft: 20 }}
                    className="secondary"
                    onClick={async () => {
                      try {
                        setIsFetching(true);
                        await dispatch(togglePauseCompanyIVR(company.id));
                      } finally {
                        setIsFetching(false);
                      }
                    }}
                  >
                    {company.pause_ivr ? "Un Pause IVR" : "Pause IVR"}
                  </button>
                  <button
                    style={{ margin: 0, marginLeft: 20 }}
                    onClick={async () => {
                      try {
                        setIsFetching(true);
                        dispatch(startCompanyIVRThread(company.id));
                      } finally {
                        setIsFetching(false);
                      }
                    }}
                  >
                    Start IVR
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      />
    </HasRolePermission>
  );
};

IvrDetails.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minutes_to_wait_between_ivr_batches: PropTypes.number,
    pause_ivr: PropTypes.bool,
    max_total_threads_per_request: PropTypes.number,
    number_of_patients_to_call_per_request: PropTypes.number,
    limit_patient_calls_for_request: PropTypes.bool,
    number_of_patients_to_pull_from_ivr_batch: PropTypes.number
  })
};

export default IvrDetails;
