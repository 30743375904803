import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import MdRestore from "react-icons/lib/md/restore";
import MdKeyboardArrowUp from "react-icons/lib/md/keyboard-arrow-up";
import MdKeyboardArrowDown from "react-icons/lib/md/keyboard-arrow-down";
import MdChevronRight from "react-icons/lib/md/chevron-right";
import MdChevronLeft from "react-icons/lib/md/chevron-left";
import moment from "moment";
import { InlineWaiting } from "components/ui";

class HeatMapTable extends Component {
    constructor(props) {
        super(props)
        this.state ={
            headers: [],
            rows: [],
            footerRow: [],
            headerToggles: [],
            cssFRCount: 0,
            countRow: [],
            showEstimatedIncome: false,
            startDateIndex: 0,
            endDateIndex: 0
        }        
    }

    shouldComponentUpdate(nextProps, nextState) {
        //This was put in b/c the queue polling was causing the DOM to repaint, causing any custome selects to close
        let propsDiff = JSON.stringify(nextProps) !== JSON.stringify(this.props)
        let stateDiff = JSON.stringify(nextState) !== JSON.stringify(this.state)
        let shouldUpdate = stateDiff || propsDiff
        return shouldUpdate
    }

    componentDidMount() {
        this.setStateFromProps()
    }  

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
           this.setStateFromProps()
        }
    }

    getSum(total, num) {
        return Number(total) + Number(num)
    }
    formatTitle(title) {
        let newTitle = title;        
        let replacedUunderScore = newTitle.replace(/_/g, " ")
        let arrayOfWords = replacedUunderScore.split(" ")
        let capitalizedArray = arrayOfWords.map((word) => {  
            let tbr = word.charAt(0).toUpperCase() + word.slice(1)
            return tbr
        })
        let capitalizedTitle = capitalizedArray.join(" ")        
        return capitalizedTitle
    }

    setStateFromProps() {
        const {
            showEstimatedIncome = false,
            tableData: { zoomLevel, startDate, endDate, columns, rowsHeader, footerRowEntries, footerDataType, footerRowTitle }, tableData } = this.props;     
        
        const headers = columns.slice(0);
        headers.unshift(rowsHeader); //must put titles at the front of each row array
        headers.push("Sum")

        const rows = tableData.rows.map((r) => [this.formatTitle(r.rowTitle), ...r.data, r.data?.reduce(this.getSum, 0)]) //must put titles at the front of each row array

        let footerRow;
        if (footerDataType == "money") {            
            footerRow = footerRowEntries.map((cell) =>  `$${cell}` )
            footerRow.push(`$${footerRowEntries.reduce(this.getSum).toString()}`)
        }
        else {
            footerRow = footerRowEntries.slice(0)
            footerRow.push(footerRowEntries?.reduce(this.getSum, 0).toString())
        }      
        //footerRow.push(isNaN(footerRow.reduce(this.getSum) ? "$0" : footerRow.reduce(this.getSum).toString()))
        footerRow.unshift(footerRowTitle) //must put titles at the front of each row array
        
        let countRow = [];
        for (let i = 0; i < tableData.rows[0].data.length; i++) {
            let rowTotal = 0;
            tableData.rows.map(row => { rowTotal += Number(row.data[i]) })
            countRow.push(rowTotal)
        }
        countRow.push(countRow?.reduce(this.getSum, 0))
        countRow.unshift("Counts")
        
        let startDateIndex;
        let endDateIndex;     
        switch (zoomLevel) {
            
            case "hours":                
                startDateIndex = columns.findIndex((c) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())                  
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'day', '[]')      
                    return timeIsBetweenStartAndEnd                       
                });
                endDateIndex = columns.findIndex((c, i) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'day', '[]')                     
                    let isLastIndex = i === (columns.length - 1)   
                    let nextColumnDate = moment(columns[i + 1])
                    let nextColumnIsBetweenRange = moment(nextColumnDate).isBetween(start, end, 'day', '[]')                   
                    let tbr = (timeIsBetweenStartAndEnd && isLastIndex) || (timeIsBetweenStartAndEnd && !nextColumnIsBetweenRange)
                    return tbr
                });               
                break;        
            case "days":
                startDateIndex = columns.findIndex((c) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())                 
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'day', '[]')
                    return timeIsBetweenStartAndEnd                    
                });
                endDateIndex = columns.findIndex((c, i) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'day', '[]')
                    let isLastIndex = i === columns.length - 1
                    let timeIsEnd = moment(time).isSame(end, 'day')
                    return timeIsEnd || (timeIsBetweenStartAndEnd && isLastIndex)                    
                });
                break;
            case "weeks":
                startDateIndex = columns.findIndex((c, i) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'day', '[]')
                    let nextColumnDate = moment(columns[i + 1])
                    let startIsBetweenTimeAndNextColumn = moment(start).isBetween(time, nextColumnDate, 'day', '[)')
                    let tbr =
                        timeIsBetweenStartAndEnd ||
                        startIsBetweenTimeAndNextColumn
                    return tbr                  
                });
                endDateIndex = columns.findIndex((c, i) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'day', '[]')
                    let isLastIndex = i === columns.length - 1
                    // let timeIsEnd = moment(time).isSame(end, 'day')
                    let nextColumnDate = moment(columns[i + 1])
                    let nextColumnDateForLastIndex = moment(time).add(7, "days")
                    let startIsBetweenTimeAndNextColumn = moment(start).isBetween(time, nextColumnDateForLastIndex, 'day', '[)')
                    let nextColumnIsBetweenRange = moment(nextColumnDate).isBetween(start, end, 'day', '[]')
                    let tbr =
                        (timeIsBetweenStartAndEnd && isLastIndex) ||
                        (timeIsBetweenStartAndEnd && !nextColumnIsBetweenRange) ||
                        (startIsBetweenTimeAndNextColumn && isLastIndex)
                    return tbr
                });             
                break;
            case "months":
                startDateIndex = columns.findIndex((c) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'month', '[]')
                    return timeIsBetweenStartAndEnd
                 });
                endDateIndex = columns.findIndex((c, i) => {
                    let time = moment(c.slice())
                    let start = moment(startDate.slice())
                    let end = moment(endDate.slice())
                    let timeIsBetweenStartAndEnd = moment(time).isBetween(start, end, 'month', '[]')
                    let isLastIndex = i === (columns.length - 1)
                    let nextColumnDate = moment(columns[i + 1])
                    let nextColumnIsBetweenRange = moment(nextColumnDate).isBetween(start, end, 'month', '[]')    
                    let tbr = (timeIsBetweenStartAndEnd && isLastIndex) || (timeIsBetweenStartAndEnd && !nextColumnIsBetweenRange)
                    return tbr
                 });
                break;
        }
        
        const headerToggles = headers.map(() => "")       
        const cssFRCount = headers.length //Headers determine CSS Grid rows
        this.setState({
            ...this.state,
            headers: headers,
            rows: rows,
            footerRow: footerRow,
            headerToggles: headerToggles,
            cssFRCount: cssFRCount,
            countRow: countRow,
            showEstimatedIncome: showEstimatedIncome,
            startDateIndex:  startDateIndex === -1 ? "noMatch" : (startDateIndex + 1), // +1 b/c we unshifted an index 
            endDateIndex: endDateIndex === -1 ? "noMatch" : (endDateIndex + 1) // +1 b/c we unshifted an index 
        })
    }  

    getPercentWithinRange(entryValue, row) {
        // const title = rowTitle;
        const maxRowValue = Math.max(...row);
        const minRowValue = Math.min(...row);
        const entryPercent = (entryValue - minRowValue) / (maxRowValue - minRowValue); // P = (n - m)/(M- m) Find percent within arbitrary range
        const percent = isNaN(entryPercent) ? 0 : entryPercent
        return percent
    }

    getValueStyle(entryValue, row, columnIndex, rowType)  {       
        let rV = 16;
        let gV = 71;
        let bV = 94;
        let aV = 1;     
        let valueColor = `rgba(${rV}, ${gV}, ${bV}, ${aV})`;
        
        let r = 8;
        let g = 36;
        let b = 48;
        let a = 1;  
        let color = `rgba(${r}, ${g}, ${b}, ${a})`;

        if (rowType === "topRow") {
            return {
                fontWeight: "900",
                color: color,
                textAlign: "center",             
                fontSize: "1.5em",                
            }
        }
        else if (rowType === "header") {
            if (columnIndex === 0) {
                return {
                    fontWeight: "900",
                    color: color,
                    alignSelf: "end",
                    justifySelf: "start",
                    paddingBottom: "7px",
                    paddingRight: "80px",
                    fontSize: "1.2em"
                }
            }
            else {
                return {
                    fontWeight: "900",
                    textAlign: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                    fontSize: ".75em",
                    textDecoration: "underline"
                }
            }
        }
        else if (rowType === "value") {
            
            if (columnIndex === 0) {
                return {
                    fontWeight: "900",
                    color: `rgba(${r}, ${g}, ${b}, ${1})`,                    
                    alignSelf: "center",
                    paddingTop: "2px",
                    paddingBottom: "7px",
                    paddingRight: "7px"
                }
            }
            else if (columnIndex === (row.length - 1)) {
                return {
                    fontSize: "1.25em",
                    fontWeight: "900",
                    textAlign: "center",
                    color: `rgba(${r}, ${g}, ${b}, ${1})`,
                    alignSelf: "center",
                    paddingTop: "2px",
                    paddingBottom: "7px",
                }
            }
            else {
                const rowCopy = row.slice(1, (row.length-1));     //I want to get rid of the first index, as its not a number but a title   
                const entryPercent = this.getPercentWithinRange(entryValue, rowCopy);
                aV = entryPercent;
                const backgropundColor = `rgba(${rV}, ${gV}, ${bV}, ${aV})`;
                valueColor = (aV > .3) ? "white" : valueColor
                return {
                    backgroundColor: backgropundColor,
                    color: valueColor,
                    textAlign: "center",
                    alignSelf: "center",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                    fontSize: "1.25em"
                }
            }
        }

        else if(rowType === "footer") {
            if (columnIndex === 0) {
                return {
                    cursor: "pointer",
                    fontWeight: "900",
                    color: `rgba(${r}, ${g}, ${b}, ${1})`,
                    alignSelf: "center",
                    paddingTop: "10px",
                    paddingBottom: "7px",
                    paddingRight: "7px"
                }
            }
            else {
                return {
                    fontWeight: "900",
                    color: color,
                    textAlign: "center",
                    alignSelf: "center",
                    paddingBottom: "7px",
                    fontSize: "1.25em",
                    paddingTop: "10px"
                }
            }
        }     
    }

    sortColumn (columnIndex, rows) {       
        const { headerToggles } = this.state;
        let newHeaderToggles = headerToggles;
        let sortedRows = [];

        if (columnIndex !== 0) {
            if (columnIndex === (rows[0].length -1)) {
                if (headerToggles[columnIndex] == "" || headerToggles[columnIndex] == "desc") {
                    sortedRows = rows.sort((a, b) => b[columnIndex] - a[columnIndex])
                    newHeaderToggles = newHeaderToggles.map((x, i) => i == columnIndex ? "asc" : "")
                }
                else {
                    sortedRows = rows.sort((a, b) => a[columnIndex] - b[columnIndex])
                    newHeaderToggles = newHeaderToggles.map((x, i) => i == columnIndex ? "desc" : "")
                }  
            }
            else {
                if (headerToggles[columnIndex] == "" || headerToggles[columnIndex] == "desc") {
                    sortedRows = rows.sort((a, b) => this.getPercentWithinRange(b[columnIndex], b.slice(1, (b.length - 1)), b[0]) - this.getPercentWithinRange(a[columnIndex], a.slice(1, (a.length - 1)), a[0]))
                    newHeaderToggles = newHeaderToggles.map((x, i) => i == columnIndex ? "asc" : "")
                }
                else {
                    sortedRows = rows.sort((a, b) => this.getPercentWithinRange(a[columnIndex], a.slice(1, (a.length - 1)), a[0]) - this.getPercentWithinRange(b[columnIndex], b.slice(1, (b.length - 1)), b[0]))
                    newHeaderToggles = newHeaderToggles.map((x, i) => i == columnIndex ? "desc" : "")
                }    
            }              
        }
        this.setState({
            ...this.state,
            rows: sortedRows,
            headerToggles: newHeaderToggles
        })
    }   

    getGridRows() {
        let frString = "";
        const { cssFRCount } = this.state;
        for (let i = 0; i < cssFRCount; i++){
            frString += "1fr "
        }        
        return frString
    }

    zoomDate(event, zoomDate, zoomDirection) {
        event.stopPropagation()
        const {tableData: {zoomLevel, zoomLevelsArray}, tableData } = this.props
        let currentZoomIndex = zoomLevelsArray.findIndex( level =>  level === zoomLevel);
        let nextZoomLevel =""
        if (zoomDirection == "in") {
            nextZoomLevel = (currentZoomIndex !== zoomLevel.length - 1) ? zoomLevelsArray[currentZoomIndex + 1] : zoomLevel
        }
        else {
            nextZoomLevel = currentZoomIndex !== 0 ? zoomLevelsArray[currentZoomIndex - 1] : zoomLevel
        }
              
        this.props.zoomDate(nextZoomLevel, zoomDate, tableData)
    }

    determineTopRow(zoomStartDate, zoomEndDate, zoomLevel) {
        let today = moment().format();
        let end = zoomEndDate === null ? today : zoomEndDate.slice();
        let start = zoomStartDate.slice()
        switch (zoomLevel) {
            case "years":
                return "Monthly View From " + moment(start).format('MMMM YYYY') + " - " + moment(end).format('MMMM YYYY')
            case "months":
                return "Monthly View From " + moment(start).format('MMMM YYYY') + " - " + moment(end).format('MMMM YYYY')            
            case "weeks":
                return "Weekly View From " + moment(start).format('MM/DD/YYYY') + " - " + moment(end).format('MM/DD/YYYY')
            case "days":
                return "Daily View From " + moment(start).format('MM/DD/YYYY') + " - " + moment(end).format('MM/DD/YYYY')
            case "hours":
                return "Hourly View For " + moment(start).format('MM/DD/YYYY h a') + " - " + moment(end).add(1, 'hour').format('MM/DD/YYYY h a')
        }  
    }

    determineColumnDate(date, zoomLevel) {
        let formatedDate;
        switch (zoomLevel) {
            case "years":
                formatedDate = moment(date).format('YY')
                break;
            case "months":
                formatedDate = moment(date).format('MM')
                break;
            case "weeks":
                formatedDate = moment(date).format('MM/DD')
                break;
            case "days":
                formatedDate = moment(date).format('Do')
                break;
            case "hours":
                formatedDate = moment(date).format('h a')
                 break;
        }     
        return formatedDate
    }

    estimatedIncomeToggle() {
        const showEstimatedIncome = !this.state.showEstimatedIncome
        this.setState({
            ...this.state,           
            showEstimatedIncome: showEstimatedIncome
        })
    }

    getHeaderClassName(index) {
        const { startDateIndex, endDateIndex} = this.state
        let className = "";
        if (startDateIndex !== "noMatch" && endDateIndex !== "noMatch") {
            if ((startDateIndex === endDateIndex) && (startDateIndex === index)) {
                className = "oneColumnRangeBorder"
            }
            else {
                if (index === startDateIndex) {
                    className = "startOfRangeBorder"
                }
                else if (index > startDateIndex && index < endDateIndex) {
                    className = "middleOfRangeBorder"
                }
                else if (index === endDateIndex) {
                    className = "endOfRangeBorder"
                }
                else {
                    //do nothing
                }
            }
            
        }       
        return className
    }
    restoreOriginalZoom() {
        const { tableData: { originalZoomLevel, startDate }, zoomDate, tableData } = this.props;
        zoomDate(originalZoomLevel, startDate,  tableData, true)
    }

    determineZoomButtonClass(direction, zoomLevel, zoomLevelsArray) {
        let zoomOutLimit = zoomLevelsArray[0]
        let zoomInLimit = zoomLevelsArray[zoomLevelsArray.length - 1]
        if (direction === "out") {
            if (zoomLevel === zoomOutLimit) {
                return "disabledArrowButtons"
            }
            else {
                return "zoomButtons"
            }
        }
        else {
            if (zoomLevel === zoomInLimit) {
                return "disabledArrowButtons"
            }
            else {
                return "zoomButtons"
            }
        }
    }
    
    addOrShiftDatesOneUnit(direction) {
        const { tableData: { zoomEndDate, zoomStartDate, zoomLevel, columns }, shiftDatesOneUnit, tableData } = this.props
        let newZoomEndDate = zoomEndDate.slice()
        let newZoomStartDate = zoomStartDate.slice()
        if (columns.length < 30) {
            switch (zoomLevel) {
                case "years":
                case "months":
                case "weeks":
                case "days":
                    direction === "forward"
                        ? newZoomEndDate = moment(newZoomEndDate).add(1, zoomLevel).endOf("day")
                        : newZoomStartDate = moment(newZoomStartDate).subtract(1, zoomLevel).startOf("day")
                    break;
                case "hours":
                    direction === "forward"
                        ? newZoomEndDate = moment(newZoomEndDate).add(1, zoomLevel).endOf("hour")
                        : newZoomStartDate = moment(newZoomStartDate).subtract(1, zoomLevel).startOf("hour")
                    break;
            }     
        }
        else {
            switch (zoomLevel) {
                case "years":
                case "months":
                case "weeks":
                case "days":
                    if (direction === "forward") {
                        newZoomEndDate = moment(newZoomEndDate).add(1, zoomLevel).endOf("day")
                        newZoomStartDate = moment(newZoomStartDate).add(1, zoomLevel).startOf("day")
                    }
                    else {
                        newZoomEndDate = moment(newZoomEndDate).subtract(1, zoomLevel).endOf("day")
                        newZoomStartDate = moment(newZoomStartDate).subtract(1, zoomLevel).startOf("day")
                    }
                    break;
                case "hours":
                    if (direction === "forward") {
                        newZoomEndDate = moment(newZoomEndDate).add(1, zoomLevel).endOf("hour")
                        newZoomStartDate = moment(newZoomStartDate).add(1, zoomLevel).startOf("hour")
                    }
                    else {
                        newZoomEndDate = moment(newZoomEndDate).subtract(1, zoomLevel).endOf("hour")
                        newZoomStartDate = moment(newZoomStartDate).subtract(1, zoomLevel).startOf("hour")
                    }
                    break;
            }    
        }      

        newZoomEndDate = moment.isMoment(newZoomEndDate) ? newZoomEndDate.format() : newZoomEndDate
        newZoomStartDate = moment.isMoment(newZoomStartDate) ? newZoomStartDate.format() : newZoomStartDate
        shiftDatesOneUnit(newZoomStartDate, newZoomEndDate, tableData)       
    }    

    render() {        
        const { headers, rows, footerRow, countRow, showEstimatedIncome} = this.state;      
        const { tableData: { zoomLevel, zoomStartDate, zoomEndDate, zoomLevelsArray }, userRole, isFetching } = this.props;
        const dateUInit = zoomLevel.charAt(0).toUpperCase() + zoomLevel.slice(1, (zoomLevel.length -1))
        return (
            <React.Fragment>
                <div
                  className="heat-map-header"
                  style={this.getValueStyle(null, null, null, "topRow")}
                >
                  <span
                    onClick={() => this.restoreOriginalZoom()}
                    className="heatMapRestore"
                    title="Click To Restore Original Zoom And Range"
                  >
                    {<MdRestore />}
                  </span>
                  <span
                    onClick={() => this.addOrShiftDatesOneUnit("backward")}
                    className="heatMapShifterLeft"
                    title={"Click To Move Back One " + `${dateUInit}`}
                  >
                    {<MdChevronLeft />}
                  </span>
                  <span
                    onClick={() => this.addOrShiftDatesOneUnit("forward")}
                    className="heatMapShifterRight"
                    title={"Click To Move Forward One " + `${dateUInit}`}
                  >
                    {<MdChevronRight />}
                  </span>
                  <span className="heat-map-title">{this.determineTopRow(zoomStartDate, zoomEndDate, zoomLevel)}</span>
                  {isFetching && <InlineWaiting />}
                </div>
                <div className="heatMapGrid" style={{ gridTemplateColumns: this.getGridRows(), display: "grid", margin: "10px" }}>
                    {headers.map((h, i) => (
                        <span
                            className={this.getHeaderClassName(i)}
                            style={this.getValueStyle(null, null, i, "header")}
                            onClick={() => this.sortColumn(i, rows)}
                            key={i + "header" + h}
                            title={(i !== 0 && i !== (headers.length - 1)) ? moment(h).format('LL') + " | Click To Sort" : ""} //exclude 1st and last column for format
                        >                         
                                <div style={{textAlign: "center"}}>
                                    <span
                                        className={this.determineZoomButtonClass("out", zoomLevel, zoomLevelsArray)}
                                        title={"Zoom Out Of Date"}
                                        style={{ visibility: (i !== (headers.length - 1) && (i !== 0)) ? "inherit" : "hidden"}}
                                        onClick={//acts as disabler of onClick
                                            this.determineZoomButtonClass("out", zoomLevel, zoomLevelsArray) === "zoomButtons" ?
                                                (e) => this.zoomDate(e, h, "out") : () => null
                                        }>
                                        <MdKeyboardArrowUp />                                     
                                    </span>
                                    <br />
                                    <span className="heatMapHeader">{(i !== 0 && i !== (headers.length - 1)) ? this.determineColumnDate(h, zoomLevel) : h}</span>
                                    <br />
                                    <span
                                        className={this.determineZoomButtonClass("in", zoomLevel, zoomLevelsArray)}
                                        title={"Zoom Into Date"}
                                        style={{ visibility: (i !== (headers.length - 1) && (i !== 0)) ? "inherit" : "hidden" }}
                                        onClick={//acts as disabler of onClick
                                            this.determineZoomButtonClass("in", zoomLevel, zoomLevelsArray) === "zoomButtons" ?
                                            (e) => this.zoomDate(e, h, "in") : () => null
                                        }>
                                        <MdKeyboardArrowDown />
                                    </span>
                                </div>
                        </span>
                    ))}
                    {rows.map((row) => (
                        //rows is an array of a row objects, and each row object has a data array that will be the values
                        row.map((value, i) => (
                            <span
                                style={  this.getValueStyle(value, row, i, "value") }
                                key={row + "value" + i}>
                                {value}
                            </span>
                        ))
                    ))}
                    {!showEstimatedIncome && countRow.map((c, i) => (
                        <span
                            onClick={() => userRole === "Administrator" ? this.estimatedIncomeToggle() : ""}
                            style={this.getValueStyle(null, null, i, "footer")}
                            key={c + "footer" + i}>
                            {c}
                        </span>
                    ))}
                    {showEstimatedIncome && footerRow && footerRow.map((f, i) => (
                        <span
                            onClick={() => userRole === "Administrator" ? this.estimatedIncomeToggle() : ""}
                            style={this.getValueStyle(null, null, i, "footer")}
                            key={f + "footer" + i}>
                            {f}
                        </span>
                    ))}
                </div>
            </React.Fragment>            
        );
    }
}

export default connect(state => ({
  userRole: selectors.getUserRole(state)
}))(HeatMapTable);

HeatMapTable.propTypes = {
    showEstimatedIncome: PropTypes.bool,
    zoomDate: PropTypes.func.isRequired,
    tableData: PropTypes.shape({
        id: PropTypes.string,
        rowDateType: PropTypes.string,
        footerDataType: PropTypes.string,
        entityType: PropTypes.string,
        originalZoomLevel: PropTypes.string,
        startDate: PropTypes.date,
        endDate: PropTypes.date,
        zoomStartDate: PropTypes.date,
        zoomEndDate: PropTypes.date,
        zoomLevel: PropTypes.string,
        zoomLevelsArray: PropTypes.array,
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
        rowsHeader: PropTypes.string,
        footerRowTitle: PropTypes.string,
        footerRowEntries: PropTypes.arrayOf(PropTypes.string),
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                rowTitle: PropTypes.string.isRequired,
                data: PropTypes.array.isRequired
            })
        ).isRequired   
    }).isRequired,
  shiftDatesOneUnit: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  isFetching: PropTypes.bool
};

HeatMapTable.contextTypes = {
    router: PropTypes.object
};
