import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { InlineWaiting } from "components/ui";
import { twoFactorAuthCodeSignIn } from "actions/authentication";
import AuthCodeInput from "./auth-code-input";
import { useFetch } from "hooks";
import { post } from "utils/api";
import { aLink } from "utils/styles";

const TwoFactorRequired = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = location.state?.user;
  const [authCode, setAuthCode] = React.useState("");
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { fetchData: resendMFACode, response: authCodeResendResponse } =
    useFetch({
      apiFn: user => post(`authCodeResend`, { ...user })
    });
  return (
    <React.Fragment>
      <div id="login_background"></div>
      <section
        id="log_in_screen"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100vh",
          overflow: "auto"
        }}
      >
        <div
          className="login_panel"
          style={{ display: "flex", flex: 1, flexDirection: "column" }}
        >
          <div className="loginLogo"></div>
          <div
            acceptCharset="UTF-8"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 15,
              background: "#ddd",
              borderRadius: 5,
              boxShadow:
                "0 7px 19px rgb(0 0 0 / 25%), 0 5px 5px rgb(0 0 0 / 22%)",
              transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
              padding: 10,
              width: "100%"
            }}
          >
            <h4>Two Factor Auth Required</h4>
            <div style={{ fontSize: 16, color: "#666" }}>
              <div>
                Confirmation sent to the following email: <b>{user.email}</b>
              </div>
              <div>
                Please use provided link in email or alternatively enter code
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div style={{ margin: "10px 10px" }}>
                <label style={{ color: "#666" }}>Auth Code</label>
                <AuthCodeInput
                  onChange={value => {
                    setAuthCode(value);
                  }}
                />
              </div>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              {isLoading ? (
                <InlineWaiting />
              ) : (
                <div>
                  <button
                    className="buttonLogin"
                    type="submit"
                    onClick={async () => {
                      try {
                        setIsLoading(true);
                        const email = user.email;
                        const success = await dispatch(
                          twoFactorAuthCodeSignIn({ email, authCode })
                        );
                        if (!success)
                          setError(
                            "Provided code is incorrect or no longer valid"
                          );
                        else setError("");
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                  >
                    Sign In Using Code
                  </button>
                  <div style={{ color: "#EA5C3E", fontSize: 16 }}>{error}</div>
                  {error && (
                    <button
                      onClick={() => {
                        history.push("/login");
                      }}
                      style={{ marginTop: 10 }}
                    >
                      Back to Login
                    </button>
                  )}
                </div>
              )}
            </div>
            {user.MFACellNumberVerified && user.MFACellNumber && (
              <div
                style={{
                  padding: 10,
                  border: "1px solid #ccc",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                {authCodeResendResponse == true ? (
                  <p>Please check SMS message for auth code</p>
                ) : (
                  <>
                    <p>Did not get authentication code?</p>
                    <p>
                      Click
                      <span
                        style={{ ...aLink, padding: "0px 5px" }}
                        onClick={() => {
                          resendMFACode(user);
                        }}
                      >
                        Here
                      </span>
                      to have code sent via SMS
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TwoFactorRequired;
