import React, { Component } from "react";
import PropTypes from "prop-types";
import MdArrowDropUp from "react-icons/lib/md/arrow-drop-up";
import MdArrowDropDown from "react-icons/lib/md/arrow-drop-down";

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false || props.alwaysOpen,
      headerTitle: this.getHeaderTitle(props),
      filterText: "",
      value: "",
      entered: false
    };
    this.textInput = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { listOpen } = this.state;
    setTimeout(() => {
      // prevent window ignoring event listener before component is initialized
      if (listOpen && !prevState.listOpen) {
        window.addEventListener("click", this.close);
      } else if (!listOpen && prevState.listOpen) {
        window.removeEventListener("click", this.close);
      }
    }, 1);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }
  getHeaderTitle = props => {
    const { options, value: currentValue, placeHolder } = props;
    const selectedItem = options.find(({ value }) => value === currentValue);
    return selectedItem ? selectedItem.text : placeHolder;
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.value !== this.props.value) {
      this.setState({ headerTitle: this.getHeaderTitle(nextProps) });
    } else if (nextProps.options !== this.props.options) {
      this.setState({ headerTitle: this.getHeaderTitle(nextProps) });
    }
  }

  handleFilterTextInput = ({ target }) => {
    this.setState({ filterText: target.value });
  };

  filterByText = item => {
    if (this.state.filterText.length > 0 && typeof item.text === "string") {
      return item.text
        .toLowerCase()
        .includes(this.state.filterText.toLowerCase());
    }
    return true;
  };

  close = () => {
    this.setState({
      listOpen: false,
      filterText: ""
    });
  };

  selectItem = value => {
    const { onChange } = this.props;
    onChange(value);
    this.setState({
      listOpen: false
    });
  };

  openList = () => {
    this.setState({ listOpen: true, filterText: "" }, () => {
      if (this.textInput) {
        this.textInput.focus();
      }
    });
  };

  closeList = () => {
    this.setState({ listOpen: false });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      const { options } = this.props;
      const selected = options.filter(this.filterByText).shift();
      if (selected && selected.value) {
        this.selectItem(selected.value);
      }
    }
  };

  render() {
    const {
      mainHeight,
      height,
      options,
      value: currentValue,
      alwaysOpen,
      disabled,
      //placeHolder,
      onBlur
    } = this.props;
    const { listOpen, headerTitle } = this.state;
    return (
      <div className="select-dropDown-container">
        {/* <div style={{color: "red"}}>{required && this.state.entered === true && (currentValue == null || currentValue== undefined) && (errorMessage ? errorMessage : "This field is required")}</div> */}
        <div
          style={{ height: mainHeight }}
          className="select-dropDown-header"
          onClick={() => {
             this.setState({ entered: true });
            if (!listOpen && !disabled) this.openList();
          }}
        >
          {listOpen ? (
            <input
              className="select-dropDown-header-title"
              onClick={e => e.stopPropagation()}
              value={this.state.filterText}
              onChange={this.handleFilterTextInput}
              onBlur={onBlur}
              ref={elem => (this.textInput = elem)}
              onKeyPress={this.handleKeyPress}
              disabled={disabled}
            />
          ) : (
            <div
              className={`select-dropDown-header-title ${
                disabled ? "disabled" : ""
              }`}
            >
              {headerTitle}
            </div>
          )}
          {listOpen ? <MdArrowDropUp /> : <MdArrowDropDown />}
        </div>
        {(listOpen || alwaysOpen) && (
          <ul
            className="select-dropDown-list"
            style={{ height }}
            onClick={e => e.stopPropagation()}
          >
            {options.filter(this.filterByText).map(({ value, text }) => (
              <li
                className={`select-dropDown-list-item ${
                  currentValue === value ? "active" : ""
                }`}
                key={value}
                onClick={() => this.selectItem(value)}
              >
                {text}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default SelectDropdown;

SelectDropdown.defaultProps = {
  //placeHolder: "",
  alwaysOpen: false,
  disabled: false
};

SelectDropdown.propTypes = {
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  mainHeight: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeHolder: PropTypes.string,
  value: PropTypes.any,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.any
    })
  ).isRequired,
  alwaysOpen: PropTypes.bool
};
