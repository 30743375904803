import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

const DayPickerRange = ({
  handleSubmit,
  modifiers,
  startDate,
  endDate,
  setStartDate,
  isValid,
  setEndDate,
  isFetching,
  disabled,
  showSubmit
}) => {
  const from = parseDate(startDate);
  const to = parseDate(endDate);
  const toRef = React.useRef();
  return (
    <form
      className="day-picker-range"
      style={{ gridTemplateColumns: showSubmit ? "3fr 3fr 1fr" : "1fr 1fr" }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(startDate, endDate);
      }}
    >
      <DayPickerInput
        value={from}
        placeholder="MM/DD/YYYY"
        format="MM/DD/YYYY"
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          disabledDays: { after: to },
          toMonth: to,
          modifiers,
          numberOfMonths: 2,
          onDayClick: () => toRef.current.getInput().focus()
        }}
        onDayChange={day => setStartDate(day)}
        inputProps={{ disabled }}
      />
      <DayPickerInput
        ref={toRef}
        value={to}
        placeholder="MM/DD/YYYY"
        format="MM/DD/YYYY"
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          disabledDays: { before: from },
          modifiers,
          month: from,
          fromMonth: from,
          numberOfMonths: 2
        }}
        onDayChange={setEndDate}
        inputProps={{ disabled }}
      />
      {isFetching ? (
        <InlineWaiting />
      ) : (
        showSubmit && (
          <button type="submit" disabled={!isValid}>
            Submit
          </button>
        )
      )}
    </form>
  );
};

export default DayPickerRange;

DayPickerRange.propTypes = {
  endDate: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  modifiers: PropTypes.object,
  setEndDate: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  isFetching: PropTypes.bool,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  showSubmit: PropTypes.bool
};
DayPickerRange.defaultProps = {
  modifiers: {},
  disabled: false,
  showSubmit: true
};
