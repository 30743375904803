import React, { useState } from "react";
import PropTypes from "prop-types";
import MdRefresh from "react-icons/lib/md/refresh";
import IoArrowLeftA from "react-icons/lib/io/arrow-left-a";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";

function Pages({
  showSelect,
  showBack,
  showNext,
  showPageNumber,
  pages,
  containerStyle,
  customRender,
  activePageIndex
}) {
  const [selectedPage, setSelectedPage] = useState(0);
  function handleNext() {
    setSelectedPage(selectedPage + 1);
  }
  function handleBack() {
    setSelectedPage(selectedPage - 1);
  }

  function handlePageSelect({ target }) {
    setSelectedPage(parseInt(target.value));
  }
  // NOTE: AutoDialer uses activePageIndex stored in redux and not local state
  // Autodialer also uses custom components and automatically moves to next page in redux saga
  const currentPage = Number.isInteger(activePageIndex)
    ? pages[activePageIndex]
    : pages[selectedPage];
  return (
    <div className="pages">
      <div className="page-container" style={containerStyle}>
        {currentPage?.view}
      </div>
      <div className="page-select">
        {currentPage?.onRefresh && (
          <i title="Refresh" onClick={currentPage.onRefresh}>
            <MdRefresh />
          </i>
        )}
        {showSelect && (
          <select onChange={handlePageSelect} value={selectedPage}>
            {pages.map((page, index) =>
              typeof page.title === "string" ? (
                <option key={index} value={index}>
                  {page.title}
                </option>
              ) : (
                <page.title key={index} />
              )
            )}
          </select>
        )}
        {showBack && selectedPage > 0 && (
          <i title="Back" onClick={handleBack}>
            <IoArrowLeftA />
          </i>
        )}
        {showNext && selectedPage < pages.length - 1 && (
          <i title="Next" onClick={handleNext}>
            <IoArrowRightA />
          </i>
        )}
        {customRender && customRender}
        {showPageNumber && (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              marginRight: 15,
              color: "#333"
            }}
          >
            <span>{selectedPage + 1}</span>
            <span>/</span>
            <span>{pages.length}</span>
          </div>
        )}
      </div>
    </div>
  );
}

Pages.defaultProps = {
  showBack: true,
  showNext: true,
  showSelect: true,
  containerStyle: {}
};

Pages.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      view: PropTypes.node.isRequired,
      onRefresh: PropTypes.func
    })
  ).isRequired,
  showBack: PropTypes.bool.isRequired,
  showNext: PropTypes.bool.isRequired,
  showSelect: PropTypes.bool.isRequired,
  containerStyle: PropTypes.object,
  showPageNumber: PropTypes.bool,
  customRender: PropTypes.node,
  activePageIndex: PropTypes.number
};

export default Pages;
