import React from "react";
import OrderTasks from "./order-tasks";
import PatientTasks from "./patient-tasks";
import AllTasks from "./all-tasks";
import { Switch, Route } from "react-router-dom";

const TasksTable = () => {
  return (
    <Switch>
      <Route path="/tasks/patients" component={PatientTasks} exact />
      <Route path="/tasks/orders" component={OrderTasks} exact />
      <Route path="/tasks/all" component={AllTasks} exact />
    </Switch>
  );
};

export default TasksTable;
