import { all, race, delay, take, select, put } from "redux-saga/effects";
import { selectors } from "reducers";
import { Types } from "actions/auto-dialer";
import { Types as phoneTypes } from "actions/phone";

export function* getPhoneIsInitialized() {
  const phoneReady = yield select(state => selectors.getIsPhoneReady(state));
  if (!phoneReady) {
    yield put({
      type: Types.AUTO_DIALER_SET_STATUS,
      payload: "Waiting for phone to initialize."
    });
    const { timeout } = yield race({
      timeout: delay(15000),
      ready: take(phoneTypes.TWILIO_EVENT_READY)
    });
    if (timeout) {
      throw "Phone Failed to Initialize";
    }
  }
}

export function* getHasMicrophone() {
  try {
    const { timeout, result } = yield race({
      result: navigator.mediaDevices.getUserMedia({ audio: true }),
      timeout: delay(30000)
    });
    if (timeout) {
      throw Error("timeout");
    }
    return result;
  } catch (error) {
    throw "No Microphone Detected";
  }
}

export function* dialerPreChecks() {
  yield all([getPhoneIsInitialized()]);
}
