import { receiveType } from 'actions/fetch'
import {
  makeFetchUsersType,
  USER_TYPE_INFO
} from 'actions/users'
import { combineReducers } from 'redux'
import reduce from 'lodash/reduce'

const makeIds = actionType => {
  const RECEIVE_FETCH_USER = receiveType(makeFetchUsersType(actionType))
  return (state = [], action) => {
    switch (action.type) {
    case RECEIVE_FETCH_USER:
      return action.response.ids
    default:
      return state
    }
  }
}

const makeUserReducer = actionType => combineReducers({
  ids: makeIds(actionType)
})

const users = combineReducers(reduce(USER_TYPE_INFO, (acc, { reducer, actionType }) => {
  acc[reducer] = makeUserReducer(actionType)
  return acc
}, {}))

export default users

export const getUserIds = (state, userType) => [
  state[USER_TYPE_INFO[userType].reducer].ids,
  USER_TYPE_INFO[userType].entityType
]
