import React from "react";
import { PureModal, ConfirmationDialog } from "components/ui";
import PropTypes from "prop-types";

export const AvailableCoachesConfirmationModal = ({ disabled, coachName, submit })=>{

    const confirm = (closeModal)=>{
        closeModal();
        submit();
    }

    return(
        <PureModal
            renderTrigger={({ openModal }) => (
                <button onClick={() => openModal() }
                style={{backgroundColor: "#0090bc"}}
                className={ disabled? "disabled-button" : ""}
                >Submit</button>
            )}
            renderContent={({ closeModal }) => (
                <div>
                    <ConfirmationDialog
                        cancelButtonStyles="appointment-action-button cancel-action-button"
                        confirmButtonStyles="appointment-action-button join-button"
                        continueMsg="Yes"
                        onCancel={closeModal}
                        onConfirm={() => { confirm(closeModal) }}>
                        <h3>Are you sure you want to move the selected appointments to {coachName}?</h3>
                    </ConfirmationDialog>
                </div>
            )}
        />
    )
}

AvailableCoachesConfirmationModal.propTypes = {
    disabled: PropTypes.bool,
    coachName: PropTypes.string,
    submit: PropTypes.func
}