export const Types = {
  ADD_PATIENT_VERIFICATION_ID: "ADD_PATIENT_VERIFICATION_ID",
  ADD_VERIFIED_PATIENT: "ADD_VERIFIED_PATIENT",
  ADD_READONLY_PATIENT: "ADD_READONLY_PATIENT",
  REMOVE_READONLY_PATIENT: "REMOVE_READONLY_PATIENT",
  CLEAR_PATIENT_VERIFICATION: "CLEAR_PATIENT_VERIFICATION",
  VERIFICATION_REFUSED: "VERIFICATION_REFUSED"
};

export const addPatientIdForVerification = patientId => ({
  type: Types.ADD_PATIENT_VERIFICATION_ID,
  payload: patientId
});

export const clearPatientVerification = () => ({
  type: Types.CLEAR_PATIENT_VERIFICATION
});

export const setVerifiedPatientFlag = () => ({
  type: Types.ADD_VERIFIED_PATIENT
});

export const setReadOnlyPatientFlag = () => ({
  type: Types.ADD_READONLY_PATIENT
});

export const unsetReadOnlyPatientFlag = () => ({
  type: Types.REMOVE_READONLY_PATIENT
});

export const verificationRefused = patientId => ({
  type: Types.VERIFICATION_REFUSED,
  payload: patientId
});
