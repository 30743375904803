import React, { Component } from "react";
import CopyIcon from "react-icons/lib/md/content-copy";
import PropTypes from "prop-types"

class CopyFormToClipboard extends Component {
    state = {
        animate: false
    };

    copyToClipboard = () => {
        const copyArea = document.getElementById(`copyArea-${this.props.formAssignmentId }`);
        if (document.body.createTextRange) {
          const range = document.body.createTextRange();
          range.moveToElementText(copyArea);
          range.select();
          document.execCommand("Copy");
        } else if (window.getSelection) {
          // other browser support
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(copyArea);
          selection.removeAllRanges();
          selection.addRange(range);
          document.execCommand("Copy");
        }
        this.setState({ animate: true });
      };

      render(){
        const { animate } = this.state;
        return(
            <span
                className={`copy-to-clipboard-button print-none ${
                animate ? "copied-animation" : ""
                }`}
                id="copyFormToClipboard"
                onClick={this.copyToClipboard}
                title="Copy Form To Clipboard"
                onAnimationEnd={() => this.setState({ animate: false })}
            >
                <CopyIcon size="30px" color="grey" />
                <div
                    id={`copyArea-${this.props.formAssignmentId}`}
                    contentEditable="true"
                    suppressContentEditableWarning
                    style={{
                        position: "fixed",
                        left: "-10000px",
                        top: "-10000px",
                        opacity: 0
                    }}
                >
                    <h4>PatientName: {this.props.formRef?.PatientName}</h4>
                    <br />
                    <h5>Form information:</h5>
                    <pre>Form Name: {this.props.formRef?.Name}</pre>
                    <pre>Description: {this.props.formRef?.Description}</pre>
                    <pre>Patient type: {this.props.formRef?.PatientType}</pre>
                    <pre>Answered date: {this.props.formRef?.AnsweredDate}</pre>

                    <h5>Form Questions and answers:</h5>
                    {
                        this.props.formRef?.Questions?.map((item, index) => (
                            <div key={index}>
                                <pre>Question {index + 1}: {item.Text}</pre>
                                <pre>Answer: {item.AnsweredValue}</pre>
                                <br />
                                {item.TaskActionText != null && item.TaskActionText.length > 0 &&
                                    <div>
                                        <pre>Generated task:</pre>
                                        <pre>Action: {item.TaskActionText}</pre>
                                        <pre>Status: {item.TaskStatus}</pre>
                                        <pre>Assigned to: {item.AssignedTo}</pre>
                                    </div>
                                }
                                <br />
                            </div>

                        ))
                    }
                </div>
            </span>
        )
    }
}

export default CopyFormToClipboard;

CopyFormToClipboard.propTypes = {
    formAssignmentId: PropTypes.string,
    formRef: PropTypes.object
};