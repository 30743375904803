import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ListItem, IconButton } from "components/ui";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const formatDate = date => (date ? moment(date).format("YYYY / MM / DD") : "");
const startScanNow = token => {
  const link = `https://papresupply.com/mask-fitting?token=${token}`;
  window.open(
    link,
    "_blank",
    "height=" + screen.height + ", width=" + screen.width
  );
};

const MaskFittingDetails = ({
  status,
  first_name,
  last_name,
  company,
  date_created,
  date_submitted,
  date_completed,
  masks,
  question_answers,
  scan_now,
  scan_now_token
}) => {
  const [expanded, setExpanded] = React.useState({
    patient: true,
    questionnaire: false,
    masks: true
  });
  const toggleCardExpansion = key => {
    setExpanded({ ...expanded, [key]: !expanded[key] });
  };
  return (
    <div className="mask-fitting-details">
      <h4 className="mask-fitting-status-header" style={{ paddingLeft: 10 }}>
        Mask Fitting - {status}
      </h4>
      <div className="mask-fitting-cards">
        <div className="autofit-card">
          <div
            className="autofit-card-header"
            onClick={() => toggleCardExpansion("patient")}
          >
            <div>Patient</div>
            <IconButton
              icon={
                expanded.patient ? (
                  <MdArrowDropdown size={24} />
                ) : (
                  <MdArrowUp size={24} />
                )
              }
            />
          </div>
          {expanded.patient && (
            <div className="autofit-card-body">
              <ListItem label="Name" value={`${first_name} ${last_name}`} />
              <ListItem label="Company" value={company} />
              <ListItem label="Date Created" value={formatDate(date_created)} />
              <ListItem
                label="Date Submitted"
                value={formatDate(date_submitted)}
              />
              <ListItem
                label="Date Completed"
                value={formatDate(date_completed)}
              />
            </div>
          )}
        </div>
        <div className="autofit-card">
          <div
            className="autofit-card-header"
            onClick={() => toggleCardExpansion("masks")}
          >
            <div>Recommended Mask Details</div>
            <IconButton
              icon={
                expanded.masks ? (
                  <MdArrowDropdown size={24} />
                ) : (
                  <MdArrowUp size={24} />
                )
              }
            />
          </div>
          {expanded.masks && (
            <div className="autofit-card-body">
              {masks.length > 0 ? (
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Mask</th>
                      <th>Confidence</th>
                      <th>Manufacturer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {masks.map(
                      (
                        { inventory_id, name, confidence, manufacturer },
                        index
                      ) => (
                        <tr key={inventory_id}>
                          <td>{index + 1}</td>
                          <td>{name}</td>
                          <td>{confidence}</td>
                          <td>{manufacturer}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <div>Not Available</div>
              )}
            </div>
          )}
        </div>
        {question_answers.length > 0 && (
          <div className="autofit-card">
            <div
              className="autofit-card-header"
              onClick={() => toggleCardExpansion("questionnaire")}
            >
              <div>Questionnaire</div>
              <IconButton
                icon={
                  expanded.questionnaire ? (
                    <MdArrowDropdown size={24} />
                  ) : (
                    <MdArrowUp size={24} />
                  )
                }
              />
            </div>
            {expanded.questionnaire && (
              <div className="autofit-card-body">
                {question_answers.map(({ id, answer, text }) => (
                  <ListItem
                    label={text}
                    value={answer}
                    key={id}
                    className="mask-fitting-question"
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {scan_now && scan_now_token && !date_submitted && (
          <div className="autofit-card">
            <div className="autofit-card-header">In Person Scan</div>
            <div className="autofit-card-body">
              <button onClick={() => startScanNow(scan_now_token)}>
                Start Scan
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MaskFittingDetails;

MaskFittingDetails.propTypes = {
  status: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  patient_id: PropTypes.string,
  company: PropTypes.string.isRequired,
  date_created: PropTypes.string.isRequired,
  date_completed: PropTypes.string,
  date_submitted: PropTypes.string,
  masks: PropTypes.arrayOf(PropTypes.object),
  question_answers: PropTypes.arrayOf(PropTypes.object),
  scan_now: PropTypes.bool,
  scan_now_token: PropTypes.string,
  transaction_id: PropTypes.string
};
