// import { selectors } from "./reducers";

const PREFIX = "s3/GOOGLE_API/";
const prefix = type => PREFIX + type;
const makeApiTypes = name => ({
  [`REQUEST_${name}`]: prefix(`REQUEST_${name}`),
  [`API_${name}`]: prefix(`API_${name}`),
  [`RESPONSE_${name}`]: prefix(`RESPONSE_${name}`),
  [`FAILURE_${name}`]: prefix(`FAILURE_${name}`)
});

export const Types = {
  GOOGLE_API_LOADED: prefix("SCRIPT_LOADED"),
  GOOGLE_API_INITIALIZED: prefix("INITIALIZED"),
  GOOGLE_API_AUTH_SHARE_CLIENT_LOADED: prefix("AUTH_SHARE_CLIENT_LOADED"),
  GOOGLE_AUTH_LOGGED_IN: prefix("AUTH_LOGGED_IN"),
  GOOGLE_AUTH_LOGGED_OUT: prefix("AUTH_LOGGED_OUT"),
  GOOGLE_API_SIGNIN: prefix("SIGNIN"),
  GOOGLE_API_SIGNOUT: prefix("SIGNOUT"),
  GOOGLE_API_FAILURE: prefix("FAILURE"),
  ...makeApiTypes("GET_COMPANY_FILES"),
  ...makeApiTypes("GET_FOLDER_FILES"),
  ...makeApiTypes("GET_FOLDERS"),
  ...makeApiTypes("GET_DRIVE_DATA"),
  ...makeApiTypes("ATTACH_COMPANY_FILE")
};

export const googleApiLoaded = client => ({
  type: Types.GOOGLE_API_LOADED,
  client
});

export const googleSignIn = () => ({
  type: Types.GOOGLE_API_SIGNIN
});

export const googleSignOut = () => ({
  type: Types.GOOGLE_API_SIGNOUT
});

export const getCompanyFiles = companyId => ({
  type: Types.REQUEST_GET_COMPANY_FILES,
  payload: { companyId }
});

export const fetchDriveData = () => ({
  type: Types.REQUEST_GET_DRIVE_DATA
});

export const attachCompanyFile = (companyId, fileId) => ({
  type: Types.REQUEST_ATTACH_COMPANY_FILE,
  payload: { companyId, fileId }
});

export const dettachCompanyFile = (companyId, fileId) => ({
  type: Types.REQUEST_ATTACH_COMPANY_FILE,
  payload: { companyId, fileId, detach: true }
});

export const getGoogleApiAccesToken = () => async () => {
  const isLoggedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
  if (isLoggedIn) {
    return window.gapi.auth.getToken()["access_token"];
  } else {
    const auth2 = window.gapi.auth2.getAuthInstance();
    await auth2.signIn();
    return window.gapi.auth.getToken()["access_token"];
  }
};
