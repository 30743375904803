import C from "action-types";
import { makeFetchTuple } from "actions/fetch";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import flatMap from "lodash/fp/flatMap";
import keyBy from "lodash/fp/keyBy";
import mergeAll from "lodash/fp/mergeAll";

const MANUFACTURER_ID = "GUID";

const extractEquipmentTypes = flow(
  map(tm => ({ type: tm.type })),
  keyBy("type")
);

const extractTypeManufacturers = flow(
  flatMap(tm =>
    tm.manufacturer.map(m => ({
      [tm.type + m[MANUFACTURER_ID]]: {
        type: tm.type,
        manufacturer: m[MANUFACTURER_ID]
      }
    }))
  ),
  mergeAll
);

export const [
  fetchTypeManufacturer,
  invalidateTypeManufacturer
] = makeFetchTuple({
  url: "orders/types",
  baseType: C.ORDER.TYPES_AND_MANUFACTURER,
  transform: response => ({
    entities: {
      equipmentTypes: extractEquipmentTypes(response),
      typeManufacturers: extractTypeManufacturers(response)
    }
  }),
  mapActionToKeyIn: () => []
});

