import React from "react";
import PropTypes from "prop-types";

const InventorySearch = ({
  searchBy,
  setSearchValue,
  setSearchBy,
  searchValue
}) => (
  <div className="inventory-search-container">
    <select
      value={searchBy}
      onChange={({ target }) => {
        setSearchBy(target.value);
        setSearchValue("");
      }}
    >
      <option value="product_name">Name</option>
      <option value="product_id">Product Id</option>
      <option value="crosswalk_id">Crosswalk Id</option>
      <option value="hcpcs">HCPCS</option>
    </select>
    <input
      placeholder="Search..."
      value={searchValue}
      onChange={({ target }) => {
        setSearchValue(target.value);
      }}
      className="inventory-search-input"
    />
  </div>
);

InventorySearch.propTypes = {
  searchBy: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setSearchBy: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired
};

export default InventorySearch;
