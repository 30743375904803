import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { put as putBE } from "utils/api";
import { message } from "actions/message";
import FormikField from "components/forms/formik-field";
import { Formik } from "formik";
import StatesSelect from "../forms/states-select/index";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import IoIosInformationCircleOutline from "react-icons/lib/io/ios-information";

const AddressUpdateForm = ({ patientGuidForJobs }) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);

  const updateAddress = async values => {
    dispatch(message("Updating Address..."));
    await putBE(`IVR/Work/UpdateJobs2`, values);
    //Page reloads here for some reason, need to find out why
  };

  return (
    <div>
      {!showForm ? (
        <button onClick={() => setShowForm(true)}>Change Address Form</button>
      ) : (
        <Formik
          initialValues={{
            patient_guid: patientGuidForJobs,
            order_guid: "",
            street: "",
            street_line_2: "",
            state: "",
            zip: "",
            city: "",
            // update_unprocessed_orders: true, // Going to try doing this by default for now,may be implemented later
            permanent_address_change: true
          }}
        >
          {({ values, setFieldValue }) => (
            <form>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center"
                }}
              >
                <h2>Update Order Address </h2>
                <Tooltip
                  id={`friendly_name`}
                  className="helpful-link-tooltip"
                  effect="solid"
                  message='Will update all patient orders with the status "IVR Pending Work".'
                >
                  <IoIosInformationCircleOutline size="1rem" />
                </Tooltip>
              </div>
              <label style={{ paddingRight: "2px" }}>
                Permanent Address Change
                <input
                  name="permanent_address_change"
                  type="checkbox"
                  className="orderAddressSameCheckbox"
                  checked={values.permanent_address_change}
                  onChange={() =>
                    setFieldValue(
                      "permanent_address_change",
                      !values.permanent_address_change
                    )
                  }
                />
              </label>
              <FormikField name="street" label="Street Line 1" type="text" />
              <FormikField
                name="street_line_2"
                label="Street Line 2"
                type="text"
              />
              <FormikField name="city" label="City" type="text" />
              <StatesSelect
                label="State"
                onChange={(e, state) => setFieldValue("state", state.state)}
                name={"state"}
                disabled={false}
              />
              <FormikField name="zip" label="Zip Code" type="text" />

              <button
                style={{ marginRight: "10px" }}
                onClick={() => setShowForm(false)}
              >
                Cancel
              </button>
              <button
                disabled={
                  values.city == "" ||
                  values.state == "" ||
                  values.state == "" ||
                  values.zip.length < 5
                }
                onClick={() => {
                  updateAddress(values);
                }}
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

AddressUpdateForm.propTypes = {
    patientGuidForJobs: PropTypes.string.isRequired
};

export default AddressUpdateForm;
