import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { OrderBreakdown } from "components/screens/company/charts";

const UserEquipmentSoldGraph = ({ userEquipmentSold }) => (
  <div
    style={{
      padding: "10px 0px"
    }}
  >
    <OrderBreakdown data={userEquipmentSold} />
  </div>
);

UserEquipmentSoldGraph.propTypes = {
  userEquipmentSold: PropTypes.arrayOf(PropTypes.object)
};

export default connect(state => ({
  userEquipmentSold: selectors.getUserEquipmentSoldData(state)
}))(UserEquipmentSoldGraph);
