import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { requestNoteAdd } from "actions/order-profile";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";

const AddClipboardItem = ({
  orderTaskIds,
  taskActions,
  orderId,
  canAddTask,
  createTask,
  patientId,
  isSubmittingNote,
  requestNoteAdd
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  return (
    <Formik
      initialValues={{
        orderId,
        action_id: "",
        note: ""
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          const { orderId, action_id, note } = values;
          if (action_id.length === 0 && note.trim().length == 0) return;
          if (action_id) {
            setIsSubmitting(true);
            await createTask({
              orderId,
              action_id,
              text: note,
              patientId,
              type: "Patient"
            });
          } else {
            requestNoteAdd({
              note
            });
          }
          resetForm();
        } finally {
          setIsSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {({ values }) => (
        <Form className="add-reason-select" style={{ width: "100%" }}>
          <FormikField
            name="action_id"
            component="select"
            placeholder="Worklist Item"
          >
            <option value="">Create Note</option>
            {canAddTask && (
              <React.Fragment>
                <option value="" disabled>
                  --------------- Tasks --------------
                </option>
                {taskActions
                  .filter(
                    ({ value, active, is_order_task }) =>
                      (!orderTaskIds.includes(value) && active && is_order_task)
                  )
                  .map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
              </React.Fragment>
            )}
          </FormikField>
          <FormikField
            name="note"
            placeholder={values.holdReasonToAdd ? "Comment..." : "Note..."}
            type="text"
          />
          {isSubmitting || isSubmittingNote ? (
            <InlineWaiting />
          ) : (
            <button
              type="submit"
              disabled={
                values.action_id.length === 0 && values.note.trim().length == 0
              }
            >
              {values.action_id ? "Add Task" : "Create Note"}
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

AddClipboardItem.defaultProps = {
  orderTaskIds: []
};

AddClipboardItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  isSubmittingNote: PropTypes.bool,
  requestNoteAdd: PropTypes.func.isRequired,
  canAddTask: PropTypes.bool,
  orderTaskIds: PropTypes.arrayOf(PropTypes.number),
  taskActions: PropTypes.arrayOf(PropTypes.object),
  createTask: PropTypes.func.isRequired,
  patientId: PropTypes.string
};

export default connect(
  (state, { orderId }) => ({
    isSubmittingNote: selectors.getIsSubmittingNoteAdd(state, { orderId }),
    taskActions: selectors.getTaskActionsOptions(state)
  }),
  (dispatch, { orderId }) => ({
    requestNoteAdd: (...args) => dispatch(requestNoteAdd({ orderId })(...args))
  })
)(AddClipboardItem);
