import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import useApiQuery, {
  Table,
  QueryTableContext
} from "hooks/useQuery/api-query";
import { Checkbox } from "components/ui";
import { aLink } from "utils/styles";
import { formatPhone } from "utils/misc";
import { formatDate } from "utils/dates";
import { MdSearch, MdCheckCircle } from "react-icons/lib/md";

function PatientPhoneLookup({ initialValues, setSelectedPatient }) {
  const initialQuery = {
    sort: { by: "ID", direction: "asc" },
    filters: {
      to_number: initialValues.to_number,
      from_number: initialValues.from_number
    },
    pagination: { page: 1, per: 15 },
    search_for_array: []
  };

  const { query, queryDispatcher, rows, data, isFetching } = useApiQuery(
    "patients/possiblepatients",
    initialQuery,
    "patients"
  );

  return (
    <div style={{ padding: 5 }}>
      <Table
        query={query}
        isFetching={isFetching}
        data={{ ...data, setSelectedPatient }}
        queryDispatcher={queryDispatcher}
        containerClass="query-table vmail-patients-table"
        columns={[
          {
            header: "Active",
            field: "active"
          },
          {
            header: "Name",
            field: "name"
          },
          {
            header: "DOB",
            field: "date_of_birth"
          },
          {
            header: "Account #",
            field: "account_number"
          },
          {
            header: "Phone",
            field: "phone_number"
          },
          {
            header: "Mobile",
            field: "mobile_number"
          },
          {
            header: "Insurance",
            field: "insurance"
          },
          {
            header: "Company",
            field: "company"
          },
          {
            header: "Eligibility Date",
            field: "eligibility_date"
          }
        ]}
        rows={rows}
        TableRow={TableRow}
        rowKey="id"
        perPageOptions={[
          { text: "15", value: 15 },
          { text: "50", value: 50 },
          { text: "150", value: 150 }
        ]}
      >
        <div style={{ margin: 5 }}>
          <PossilePatientForm
            initialValues={initialValues}
            queryDispatcher={queryDispatcher}
          />
        </div>
      </Table>
    </div>
  );
}

PatientPhoneLookup.defaultProps = {};

PatientPhoneLookup.propTypes = {
  setSelectedPatient: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
};

export default PatientPhoneLookup;

function PossilePatientForm({ initialValues, queryDispatcher }) {
  return (
    <Formik
      initialValues={{
        to_number: initialValues.to_number,
        from_number: initialValues.from_number,
        name: "",
        email: "",
        date_of_birth: "",
        account_number: ""
      }}
      onSubmit={async values => {
        queryDispatcher({ type: "updateFilters", payload: values });
      }}
      enableReinitialize={true}
    >
      {({ resetForm }) => (
        <Form className="patient-phone-lookup">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gridGap: 10
            }}
          >
            <FormikField name="to_number" label="Called Number" type="text" />
            <FormikField name="from_number" label="Phone Number" type="text" />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              gridGap: 10
            }}
          >
            <FormikField name="name" label="Name" type="text" />
            <FormikField
              name="date_of_birth"
              label="Date Of Birth"
              type="date"
            />
            <FormikField
              name="account_number"
              label="Account Number"
              type="text"
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gridGap: 10,
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <button
              type="button"
              onClick={() => {
                resetForm();
              }}
              className="button secondary"
            >
              Clear
            </button>
            <button type="submit">
              <MdSearch />
              Search
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

PossilePatientForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isn
};

const TableRow = React.memo(row => {
  const {
    query,
    data: { setSelectedPatient }
  } = React.useContext(QueryTableContext);
  return (
    <tr>
      <td>
        <Checkbox value={row.active} />
      </td>
      <td>
        <span
          style={aLink}
          onClick={() => {
            setSelectedPatient(row.id);
          }}
        >
          {row.first_name} {row.last_name}
        </span>
      </td>
      <td>{formatDate(row.date_of_birth)}</td>
      <td>{row.account_number}</td>
      <td>
        {row.phone && (
          <span>
            {formatPhone(row.phone)}
            {formatPhone(row.phone) ==
              formatPhone(query.filters.from_number) && (
              <span
                title="Phone number is exact match"
                style={{ paddingLeft: 3 }}
              >
                <MdCheckCircle color="green" />
              </span>
            )}
          </span>
        )}
      </td>
      <td>
        {row.cell && (
          <span>
            {formatPhone(row.cell)}
            {formatPhone(row.cell) ==
              formatPhone(query.filters.from_number) && (
              <span
                title="Phone number is exact match"
                style={{ paddingLeft: 3 }}
              >
                <MdCheckCircle color="green" />
              </span>
            )}
          </span>
        )}
      </td>
      <td>{row.insurance_company}</td>
      <td>{row.company}</td>
      <td>{formatDate(row.dateEligibleForAutomation)}</td>
    </tr>
  );
});
