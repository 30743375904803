import React, { Component } from 'react'
import DataTable from '../../../ui/data-table';
import { connect } from 'react-redux'
import "../../components/style/index.css"
import { PageHeader, ToggleSwitch, Waiting } from 'components/ui';
import PropTypes from 'prop-types';
import FillQuestionnaireContainer from "./FillQuestionnaireContainer";
import Modal from "../../components/ModalComponent";
import { getQuestionnaire, createQuestionnaire, deleteQuestionnaire, updateQuestionnaire, updateStatusQuestionnaire } from './../../Redux/Actions/questionnaire';
import { getProducts } from './../../Redux/Actions/products';
import { getServices } from './../../Redux/Actions/services';

let Styles = {
    container: {
        background: "#fff",
        border: "1px solid #DEEAEE",
        padding: '20px',
        marginBottom: '15px',
        height: "200px"
    },
    textRendering: {
        textRendering: 'optimizeLegibility!important'
    }
}

let typeStyle = {
    foo :{
        float: "left",
        width: "20px",
        height:" 20px",
        margin:" 5px",
        border: '1px solid rgba(0, 0, 0, .2)',
      },
      blue: {
        background: "#13b4ff"
      }
      ,wine: {
        background:" #ae163e"
      },
      span :{
          textAlign: "left",
          verticalAlign: "middle",
          fontSize: "large",
          display: "table-cell",
      }
}

class Questionnaire extends Component {

    static propTypes = {
        getProducts: PropTypes.func,
        getServices: PropTypes.func,
        getQuestionnaire: PropTypes.func,
        createQuestionnaire: PropTypes.func,
        updateQuestionnaire: PropTypes.func,
        deleteQuestionnaire: PropTypes.func,
        updateStatusQuestionnaire: PropTypes.func,
        Questionnaire: PropTypes.arrayOf(PropTypes.object),
        Services: PropTypes.arrayOf(PropTypes.object),
        Products: PropTypes.arrayOf(PropTypes.object),
        isLoader: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            data: [],
            selectedQestionnaire: null,
            emptyQestionnaire: {
                questionnaire_id: 0,
                title: '',
                description: '',
                questionnaire_type : '',
                product_id : '',
                service_id : ''
            },
            columns: [
                {
                    title: 'Type', header: "Type", field: qestionnaire => (
                        qestionnaire.product_id && qestionnaire.product_id.length > 0 ? <div style={{ ...typeStyle.foo,...typeStyle.blue }}></div> : <div style={{ ...typeStyle.foo,...typeStyle.wine }}></div>
                    )
                },
                { title: 'ProductName / ServiceName', header: "ProductName / ServiceName", field:  qestionnaire =>
                     qestionnaire.product_id && qestionnaire.product_id.length > 0 ? qestionnaire.product_name : qestionnaire.service_name},
                { title: "Questionnaire", header: "Questionnaire", field: (qestionnaire) => <h4 title={qestionnaire.description}>{qestionnaire.title}</h4> },
                {
                    title: "Edit", header: "Edit", field: qestionnaire => (
                        <button type="button" onClick={() => this.onLinkEdit(qestionnaire)} >Edit</button>
                    )
                },
                {
                    title: "Delete", header: "Delete", field: qestionnaire => (
                        <button disabled={!qestionnaire.active}  type="button" onClick={() => this.onDataDelete(qestionnaire.questionnaire_id)} >Delete</button>
                    )
                },
                {
                title: "Status",
                header: "Status",
                field: qestionnaire => (
                        <ToggleSwitch
                            value={qestionnaire.active}
                            onChange={checked =>
                                this.props.updateStatusQuestionnaire(qestionnaire, checked)
                            }
                        />
                    )
                }
            ],

        }
    }
    
    onLinkEdit = (data) => {
        this.setState({
            selectedQestionnaire: data
        });
        this.onClose();
    }

    onDataDelete = (qestionnaireId) => {
        let result = confirm('Are You Sure ?');
        if (result) {
            this.props.deleteQuestionnaire(qestionnaireId);
        }
    }

    onClose = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    componentDidMount() {
        this.props.getProducts();
        this.props.getServices();
        this.props.getQuestionnaire();
    }

    addNewQuestionnaire = () => {
        this.setState({
            selectedQestionnaire: this.state.emptyQestionnaire
        })
        this.onClose();
    }

    saveAll = (objCollection, questionnaireInformation) => {
        let questionnaire_type = (objCollection.optionType === "Product" ? 2 : 1);
        let requestParameter = {
            questions: questionnaireInformation,
            active: true,
            questionnaire_type: questionnaire_type,
            service_id: (objCollection.optionType === "Product" ? "" : objCollection.serviceId),
            product_id: (objCollection.optionType === "Product" ? objCollection.productId : ""),
            title: objCollection.title,
            description: objCollection.description,
            questionnaire_id : objCollection.questionnaire_id
        };
        if (objCollection.questionnaire_id === 0) {
            this.props.createQuestionnaire(requestParameter);
        } else {
            this.props.updateQuestionnaire(requestParameter);
        }

        if(questionnaireInformation && questionnaireInformation.length > 0 && objCollection && objCollection.title.length > 0 && objCollection.description.length > 0)
        {
          this.onClose();
        }
        //Save questionnair call here. this.props.createQuestionnaire();
    }

    render() {
        return (
            <div className="dd_root dd_row">
                {this.props.isLoader && <Waiting />}
                <Modal
                    onClose={this.onClose}
                    show={this.state.modalOpen}
                >
                    <FillQuestionnaireContainer saveAll={this.saveAll} Services={this.props.Services} Products={this.props.Products} questionnaire={this.state.selectedQestionnaire} />
                </Modal>
                <PageHeader title="Questionnaire" />
                <div className="dd_col-12" style={{ ...Styles.container, height: "auto", overflowY: 'auto' }}>
                    <button type="button" className="dd_col-2" style={{ margin: '10px' }} onClick={this.addNewQuestionnaire} >Add Questionnaire</button>
                    {(this.props.Questionnaire && this.props.Questionnaire.length > 0) ?
                        <div className="dd_col-12" style={{ margin: '10px' }} >
                            <div className="dd_col-2" style={{display :"table"}}>
                                <div style={{ ...typeStyle.foo, ...typeStyle.blue }}></div> <span style={{...typeStyle.span }}>ProductName</span>
                            </div>
                            <div className="dd_col-2" style={{display :"table"}}>
                                <div style={{ ...typeStyle.foo, ...typeStyle.wine }}></div> <span style={{ ...typeStyle.span }}>ServiceName</span>
                            </div>
                            <DataTable
                                columns={this.state.columns}
                                rows={this.props.Questionnaire}
                                disabledsort={true}
                            />
                        </div> : 
                        (!this.props.isLoader && <div className="dd_col-12 dd_noDataTitle" style={{ fontSize: '20px' }}>No Questionnaire Created yet</div> )
                    }
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        Products: state.getIn(["newRootReducer"]).rdc_products.Products,
        Services: state.getIn(["newRootReducer"]).rdc_services.Services,
        Questionnaire: state.getIn(["newRootReducer"]).rdc_questionnaire.Questionnaire,
        isLoader: state.getIn(["newRootReducer"]).rdc_loader.isLoader
    };
};

export default connect(mapStateToProps, { getProducts, getServices, getQuestionnaire, createQuestionnaire, deleteQuestionnaire, updateQuestionnaire, updateStatusQuestionnaire })(Questionnaire);
