import { useEffect } from "react";

function useWindowResize(callback) {
  function handleResize() {
    callback(window.innerWidth, window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
}

export default useWindowResize;
