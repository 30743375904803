import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "./reducer";

const useSignalR = (actions = [], cleanup = []) => {
  const isConnected = useSelector(selectors.getIsConnectedSignalr);
  const dispatch = useDispatch();
  const [didExecute, setDidExecute] = React.useState(false);
  React.useEffect(() => {
    /* mounted or reconnected */
    if (!didExecute && isConnected) {
      actions.forEach(action => {
        dispatch(action);
      });
    }
    setDidExecute(isConnected);
    return () => {
      if (isConnected) {
        cleanup.forEach(action => {
          dispatch(action);
        });
      }
    };
  }, [isConnected]);
};

export default useSignalR;
