import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";

const CompanyEquipmentSoldTable = ({ data }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "CompanyName", ascending: true });
  return (
    <table>
      <TableSortableHeader
        columns={[
          { text: "Company", key: "CompanyName" },
          { text: "Masks", key: "Masks_Sold" },
          { text: "Masks+Headgear", key: "Masks_with_Headgear_Sold" },
          { text: "Seals", key: "Seals_Sold" },
          { text: "Disp Filters", key: "Disp_Filters_Sold" },
          { text: "Non-Disp Filters", key: "Non_Disp_Filters_Sold" },
          { text: "Chinstraps", key: "Chinstraps_Sold" },
          { text: "Tubing", key: "Tubing_Sold" },
          { text: "Misc", key: "Misc_Sold" }
        ]}
        sortBy={sortBy}
        ascending={ascending}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      <tbody>
        {getSortedArray(data).map(d => (
          <tr key={d.CompanyName}>
            <td>{d.CompanyName}</td>
            <td>{d.Masks_Sold}</td>
            <td>{d.Masks_with_Headgear_Sold}</td>
            <td>{d.Seals_Sold}</td>
            <td>{d.Disp_Filters_Sold}</td>
            <td>{d.Non_Disp_Filters_Sold}</td>
            <td>{d.Chinstraps_Sold}</td>
            <td>{d.Tubing_Sold}</td>
            <td>{d.Misc_Sold}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

CompanyEquipmentSoldTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  data: selectors.getTeamEquipmentSold(state)
}))(CompanyEquipmentSoldTable);
