import React from "react";
import PropTypes from "prop-types";

const Ul = props => {
  return <ul style={props.style}>{props.children}</ul>;
};

Ul.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object
};

Ul.defaultProps = {
  style: {
    marginBottom: 25
  }
};

export default Ul;
