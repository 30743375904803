import { createReducer } from "utils/reducer";
import { Types } from "actions/auto-dialer";
import { createSelector } from "reselect";
import * as R from "ramda";

export const WRAPUP_TIME = 20;
export const WRAPUP_TIME_EXTENSION = 60;

export default createReducer(
  {
    active: false,
    patientIds: [],
    patientIndex: 0,
    activityCenterData: {},
    inboundCall: false,
    chatDetails: undefined,
    status: "",
    currentPatient: "",
    wrapupTime: 0,
    wrapupTimeLimit: WRAPUP_TIME,
    call_prompt: false,
    isFetchingByButton: false,
    autoDialerType: "",
    autoDialerEndReason: "",
    currentAutoDialerFiltersIndex: 0,
    currentCompany: "",
    meta: {},
    autoDialerFilters: [
      {
        team: null,
        company: null,
        medicare: false,
        newSetup: false,
        overrideEligibilityDate: false,
        cycleCount: "1",
        batchSize: "20",
        badContact: false,
        formOfContact: null,
        spanish: false,
        startTime: null,
        endTime: null
      }
    ]
  },
  {
    [Types.AUTO_DIALER_INIT]: (state, { payload, meta }) => ({
      ...state,
      active: false,
      patientIds: [...R.propOr([], "ids", payload)],
      patientIndex: 0,
      inboundCall: false,
      chatDetails: undefined,
      status: "",
      wrapupTime: 0,
      wrapupTimeLimit: WRAPUP_TIME,
      call_prompt: false,
      autoDialerType: payload.autoDialerType,
      meta
    }),
    [Types.AUTO_DIALER_STARTED]: state => ({ ...state, active: true }),
    [Types.AUTO_DIALER_RESET_PATIENTS]: state => ({
      ...state,
      patientIds: [],
      patientIndex: 0
    }),
    [Types.AUTO_DIALER_END]: state => ({
      ...state,
      active: false,
      inboundCall: false,
      chatDetails: undefined,
      status: "Completed outreach for all selected patients.",
      wrapupTime: 0,
      wrapupTimeLimit: WRAPUP_TIME
    }),
    [Types.AUTO_DIALER_ERROR]: (state, { payload }) => ({
      ...state,
      active: false,
      inboundCall: false,
      chatDetails: undefined,
      status: payload,
      wrapupTime: 0,
      wrapupTimeLimit: WRAPUP_TIME
    }),
    [Types.AUTO_DIALER_CALL_PROMPT]: (state, { payload }) => ({
      ...state,
      call_prompt: payload
    }),
    [Types.AUTO_DIALER_SET_PAGE]: (state, { payload }) => ({
      ...state,
      inboundCall: false,
      chatDetails: undefined,
      patientIndex: R.prop("pageIndex", payload),
      status: "",
      currentCompany: "",
      wrapupTime: 0
    }),
    [Types.AUTO_DIALER_SET_STATUS]: (state, { payload }) => ({
      ...state,
      status: payload
    }),
    [Types.AUTO_DIALER_SET_COMPANY]: (state, { payload }) => ({
      ...state,
      currentCompany: payload
    }),
    [Types.AUTO_DIALER_UPDATE_WRAPUP_TIME]: (state, { payload }) => ({
      ...state,
      wrapupTime: R.prop("seconds", payload)
    }),
    [Types.AUTO_DIALER_ANSWER_QUEUE]: state => ({
      ...state,
      inboundCall: true,
      status: "Proccessing Inbound Call",
      wrapupTime: 0
    }),
    [Types.AUTO_DIALER_START_TEXT_CHAT]: (state, { payload }) => ({
      ...state,
      chatDetails: payload,
      status: "Proccessing Text Chat",
      wrapupTime: 0
    }),
    [Types.AUTO_DIALER_PROCCESS_NEXT]: state => ({
      ...state,
      chatDetail: undefined,
      status: ""
    }),
    [Types.AUTO_DIALER_NEXT_PATIENT]: state => ({
      ...state,
      inboundCall: false,
      chatDetail: undefined,
      patientIndex: state.patientIndex + 1,
      status: "",
      wrapupTime: 0,
      wrapupTimeLimit: 30
    }),
    [Types.AUTO_DIALER_WRAPUP_STARTED]: (state, { payload }) => ({
      ...state,
      wrapupTime: 30,
      wrapupTimeLimit: R.prop("resetWrapupLimit", payload)
        ? 30
        : state.wrapupTimeLimit
    }),
    [Types.AUTO_DIALER_PROCCESS_NEXT]: state => ({
      ...state,
      chatDetails: undefined,
      inboundCall: false,
      wrapupTime: 0,
      placed_order: false,
      wrapupTimeLimit: WRAPUP_TIME
    }),
    [Types.AUTO_DIALER_WRAPUP_COMPLETED]: state => ({
      ...state,
      wrapupTime: 0,
      wrapupTimeLimit: WRAPUP_TIME
    }),
    [Types.AUTO_DIALER_PAUSED]: (state, {payload:{paused}}) => ({
      ...state,
      paused:paused,
      wrapupTime: WRAPUP_TIME
    }),
    [Types.AUTO_DIALER_CONTINUE]: state => ({
      ...state,
      wrapupTime: 0,
      wrapupTimeLimit: WRAPUP_TIME
    }),
    [Types.AUTO_DIALER_SET_WRAPUP_TIME_LIMIT]: (
      state,
      { payload, placed_order }
    ) => ({
      ...state,
      wrapupTimeLimit: payload,
      placed_order
    }),
    [Types.AUTO_DIALER_SET_CURRENT_PATIENT]: (state, { patientId }) =>
      R.assoc("currentPatient", patientId, state),
    [Types.AUTO_DIALER_APPEND_NEXT_BATCH]: (state, { payload }) => ({
      ...state,
      patientIds: [...state.patientIds, ...payload.ids]
    }),
    [Types.AUTO_DIALER_SET_FILTERS]: (
      state,
      { payload: { propertyName, value, index } }
    ) => ({
      ...state,
      autoDialerFilters: state.autoDialerFilters.map((x, i) => {
        return i === index
          ? { ...state.autoDialerFilters[index], [propertyName]: value }
          : x;
      })
    }),
    [Types.AUTO_DIALER_SET_FILTERS_INDEX]: (state, { payload: { index } }) => ({
      ...state,
      currentAutodialerFiltersIndex: index
    }),
    [Types.AUTO_DIALER_RECORD_END_REASON]: (
      state,
      { payload: { autoDialerEndReason } }
    ) => ({
      ...state,
      autoDialerEndReason
    }),
    [Types.AUTO_DIALER_SET_IS_FETCHING_BATCH]: (
      state,
      { payload: { isFetching } }
    ) => ({
      ...state,
      isFetchingByButton: isFetching
    })
  }
);

const getAutoDialerState = state => state.get("autoDialer");
const getIsAutoDialerEnabled = createSelector(
  [getAutoDialerState],
  R.prop("active")
);
const getAutodialerProperties = createSelector(
  [getAutoDialerState],
  R.prop("meta")
);
const getAutodialerPlacedOrder = createSelector(
  [getAutoDialerState],
  R.prop("placed_order")
);
const getAutoDialerCurrentPageIndex = createSelector(
  [getAutoDialerState],
  R.prop("patientIndex")
);
const getAutoDialerIsFetchingBatchByButton = createSelector(
  [getAutoDialerState],
  R.prop("isFetchingByButton")
);

const getAutoDialerPatientIds = createSelector(
  [getAutoDialerState],
  R.prop("patientIds")
);

const getAutoDialerCurrentPatientId = createSelector(
  [getAutoDialerCurrentPageIndex, getAutoDialerPatientIds],
  (index, patients) => patients[index]
);

const getAutoDialerStatus = createSelector(
  [getAutoDialerState],
  R.prop("status")
);
const getAutoDialerPaused = createSelector(
  [getAutoDialerState],
  R.prop("paused")
);

const getCurrentCompany = createSelector(
  [getAutoDialerState],
  R.prop("currentCompany")
);

const getAutoDialerIsInboundCall = createSelector(
  [getAutoDialerState],
  R.prop("inboundCall")
);

const getAutoDialerChatDetails = createSelector(
  [getAutoDialerState],
  R.prop("chatDetails")
);

const getAutoDialerWrapupTime = createSelector(
  [getAutoDialerState],
  R.prop("wrapupTime")
);

const getAutoDialerIsInWrapupTime = createSelector(
  [getIsAutoDialerEnabled, getAutoDialerWrapupTime],
  (isEnabled, time) => isEnabled && R.gt(time, 0)
);
const getAutoDialerWrapupLimit = createSelector(
  [getAutoDialerState],
  R.prop("wrapupTimeLimit")
);

const getAutoDialerCallPromptStatus = createSelector(
  [getAutoDialerState],
  R.prop("call_prompt")
);
const getAutoDialerCurrentPatient = createSelector(
  [getAutoDialerState],
  R.prop("currentPatient")
);

const getAutoDialerFilters = createSelector(
  [getAutoDialerState],
  R.prop("autoDialerFilters")
);
const getAutoDialerType = createSelector(
  [getAutoDialerState],
  R.prop("autoDialerType")
);
const getAutoDialerEndReason = createSelector(
  [getAutoDialerState],
  R.prop("autoDialerEndReason")
);

export const selectors = {
  getIsAutoDialerEnabled,
  getAutoDialerChatDetails,
  getAutoDialerPatientIds,
  getAutoDialerCurrentPageIndex,
  getAutoDialerCurrentPatientId,
  getAutoDialerStatus,
  getAutoDialerPaused,
  getAutoDialerIsInboundCall,
  getAutoDialerIsInWrapupTime,
  getAutoDialerWrapupTime,
  getAutoDialerWrapupLimit,
  getAutoDialerCallPromptStatus,
  getAutoDialerCurrentPatient,
  getAutoDialerFilters,
  getAutoDialerIsFetchingBatchByButton,
  getAutoDialerType,
  getAutoDialerEndReason,
  getCurrentCompany,
  getAutodialerProperties,
  getAutodialerPlacedOrder
};
