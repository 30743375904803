import React from "react";
import PropTypes from "prop-types";
import { formatPhone, normalizePhone } from "utils/redux-form";

const PhoneNumberField = ({ field, form: { setFieldValue }, ...rest }) => {
  React.useEffect(() => {
    setFieldValue(field.name, normalizePhone(field.value));
  }, [field.value]);

  const handleInputChange = ({ target: { value: newValue } }) => {
    if (newValue !== field.value) {
      setFieldValue(field.name, normalizePhone(newValue));
    }
  };
  return (
    <input
      type="tel"
      {...field}
      value={formatPhone(field.value)}
      onChange={handleInputChange}
      {...rest}
    />
  );
};

PhoneNumberField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  })
};

export default PhoneNumberField;
