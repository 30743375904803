import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "reducers/user";

export const EnvironmentBanner = () => {
  const windowHost = window.location.host;
  const user = useSelector(selectors.getUser);
  const environment = user?.environment ?? "Environment unkown";
  const environmentArray = environment.split(";");
  const warning = (windowHost.includes("sleepsolutionsandservices.com") && !environment.includes("Production Environment")) 
                || (!windowHost.includes("sleepsolutionsandservices.com") && environment.includes("Production Environment"));
  let beURL = "";
  if (process.env.BACKEND_URL) {
    beURL = "Backend URL: " + process.env.BACKEND_URL.replace("https://", "").replace("http://", "").replace("/api/", "");
  } else {
    beURL = environmentArray[1];
  }
  return (
    <>
      <span style={{fontWeight: "bold", color: "black", backgroundColor: warning ? "red" : "green"}}>{warning ? "WARNING " : ""}{environmentArray[0]}.</span>
      <span style={{fontWeight: "bold", color: "black", backgroundColor: warning ? "red" : "green"}}>{warning ? "WARNING " : ""}{beURL}.</span>
      <span style={{fontWeight: "bold", color: "black", backgroundColor: warning ? "red" : "green"}}>{warning ? "WARNING " : ""}{environmentArray[2]}.</span>
    </>
  );
};