import React from "react";
import PropTypes from "prop-types";
import AutofitTable from "./table";
import { PageHeader, PureModal } from "components/ui";
import MaskFittingCreateTransactionForm from "./transaction-form";
import { propOr, path } from "ramda";
import { put } from "utils/api";
import { useFetch, useDebounce } from "hooks";
import MdRefresh from "react-icons/lib/md/refresh";

const AutofitDashboard = ({ companyId }) => {
  const {
    response: maskFittingTransactions,
    isFetching,
    fetchData: fetchMaskFittingTransactions
  } = useFetch({
    apiFn: () => put(`autofit/dashboard?companyId=${companyId}`),
    defaultValue: [],
    transformResponse: propOr([], "results"),
    transformError: path(["response", "body", "status"])
  });
  const [searchValue, setSearchValue] = React.useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 700);
  return (
    <div>
      <PageHeader title="Mask Fittings">
        <div style={{ cursor: "pointer", marginRight: 15 }}>
          <MdRefresh
            color="grey"
            size="28px"
            onClick={() => {
              if (!isFetching) fetchMaskFittingTransactions();
            }}
          />
        </div>
        <PureModal
          renderTrigger={({ openModal }) => (
            <button onClick={openModal}>Create In Person Scan</button>
          )}
          renderContent={({ closeModal }) => (
            <MaskFittingCreateTransactionForm
              closeModal={closeModal}
              fetchMaskFittingTransactions={fetchMaskFittingTransactions}
            />
          )}
        />
      </PageHeader>
      <div
        className="autofit-search-container"
        style={{
          display: "grid",
          gridTemplateColumns: "130px 1fr",
          marginBottom: 5
        }}
      >
        <select value="name" style={{ margin: 0 }} readOnly>
          <option value="name">Patient Name</option>
        </select>
        <input
          type="text"
          style={{ margin: 0 }}
          placeholder="Search..."
          value={searchValue}
          onChange={({ target }) => setSearchValue(target.value)}
        />
      </div>
      <AutofitTable
        fetchMaskFittingTransactions={fetchMaskFittingTransactions}
        isFetching={isFetching}
        maskFittingTransactions={maskFittingTransactions}
        searchValue={debouncedSearchTerm}
      />
    </div>
  );
};

AutofitDashboard.propTypes = {
  companyId: PropTypes.string.isRequired
}

AutofitDashboard.defaultProps = {
  companyId: ""
}

export default AutofitDashboard;
