import React from "react";
import PropTypes from "prop-types";
import { Text } from "recharts";

// Wrapper component to fix label cutoff
const AxisLabel = props => <Text {...props}>{props.payload.value}</Text>;

export default AxisLabel;

AxisLabel.propTypes = {
  payload: PropTypes.shape({
    value: PropTypes.string
  })
};

AxisLabel.defaultProps = {
  payload: {}
};
