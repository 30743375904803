import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { FulfillmentInventoryContext } from "../../fulfillment-inventory/fulfillment-inventory-provider";
import { InventoryContext } from "../inventory-provider";
import { Link } from "react-router-dom";
import IoMdCheckmark from "react-icons/lib/io/checkmark";
import IoMdClose from "react-icons/lib/io/close";

const buttonStyle = (mode, active) => ({
  background: mode === active ? "rgba(66,129,166, 1)" : "rgba(66,129,166, 0.4)",
  fontWeight: mode === active ? "bold" : "normal",
});

const FulfillmentInventoryTableRow = ({
  fulfillment_name,
  crosswalk_id,
  fulfillment_id,
  master_id,
  uom,
  active
}) => {
  return (
  <tr key={master_id}>
    <td>{fulfillment_name}</td>
    <td>
      <Link to={`/${fulfillment_id}/fulfillment-inventory/${master_id}`}>{crosswalk_id ?? "NA"}</Link>
    </td>
    <td>{uom}</td>
    <td>{active ? <IoMdCheckmark /> : <IoMdClose />}</td>
  </tr>
)};

FulfillmentInventoryTableRow.propTypes = {
  fulfillment_name: PropTypes.string,
  crosswalk_id: PropTypes.string,
  fulfillment_id: PropTypes.string,
  master_id: PropTypes.string,
  uom: PropTypes.string,
  active: PropTypes.bool
};

const DMEInventoryTableRow = ({
  dme_name,
  crosswalk_id,
  autofit_eligible,
  back_ordered,
  dme_id,
  dme_active,
  xref_active,
  xref_id
}) => {
  return (
  <tr key={xref_id}>
    <td>{dme_name}</td>
    <td>
      <Link to={`/companies/${dme_id}/profile/inventory/${xref_id}`}>{crosswalk_id ?? "NA"}</Link>
    </td>
    <td>{autofit_eligible ? <IoMdCheckmark /> : <IoMdClose />}</td>
    <td>{back_ordered ? <IoMdCheckmark /> : <IoMdClose />}</td>
    <td>{dme_active ? <IoMdCheckmark /> : <IoMdClose />}</td>
    <td>{xref_active ? <IoMdCheckmark /> : <IoMdClose />}</td>
  </tr>
)};

DMEInventoryTableRow.propTypes = {
  dme_name: PropTypes.string,
  crosswalk_id: PropTypes.string,
  autofit_eligible: PropTypes.bool,
  back_ordered: PropTypes.bool,
  dme_id: PropTypes.string,
  dme_active: PropTypes.bool,
  xref_active: PropTypes.bool,
  xref_id: PropTypes.string
};

const CrosswalkTable = ({
  inventoryItem,
}) => {
  const {
    inventoryItemtemFulfillmentList,
    getInventoryItemtemFulfillmentList,
    isFetchingInventoryItemtemFulfillmentList,
  } = useContext(FulfillmentInventoryContext);

  const {
    inventoryItemtemDMEList,
    getInventoryItemtemDMEList,
    isFetchingInventoryItemtemDMEList
  } = useContext(InventoryContext);

  const [mode, setMode] = useState("dme");
  useEffect(() => {
    const itemID = inventoryItem?.id;
    getInventoryItemtemFulfillmentList({ inventoryId: itemID });
    getInventoryItemtemDMEList({ inventoryId: itemID });
  }, []);

  return (mode === "fulfillment" && !isFetchingInventoryItemtemFulfillmentList) || (mode === "dme" && !isFetchingInventoryItemtemDMEList) ? (
    <div className="table">
      <div className="header">
        <button
          style={buttonStyle(mode, "dme")}
          onClick={() => setMode("dme")}
        >
          DME Crosswalk
        </button>
        <button
          style={buttonStyle(mode, "fulfillment")}
          onClick={() => setMode("fulfillment")}
        >
          Fulfillment Crosswalk
        </button>
      </div>
      <InventoryItemsTable
        inventoryItems={mode === "fulfillment" ? inventoryItemtemFulfillmentList : inventoryItemtemDMEList}
        mode={mode}
      />
    </div>
  ) : (
    <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
    >
      <InlineWaiting reason="Fetching crosswalk" />
    </div>
  );
};

CrosswalkTable.propTypes = {
  inventoryItem: PropTypes.shape({
    id: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CrosswalkTable;

const InventoryItemsTable = ({
  inventoryItems,
  mode
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(inventoryItems, {
    sort: { by: "name", direction: "asc" },
    filters: {}
  });
  return (
    <Table
      TableRow={mode === "fulfillment" ? FulfillmentInventoryTableRow : DMEInventoryTableRow}
      containerClass="query-table inventory-table"
      columns={mode === "fulfillment" ? [
        {
          header: "Fulfillment Company",
          field: "fulfillment_name",
        },
        {
          header: "Crosswalk Id",
          field: "crosswalk_id",
        },
        {
          header: "UOM",
          field: "uom",
        },
        {
          header: "Active",
          field: "active",
          filters: [
            {
              value: true,
              text: "Yes",
            },
            {
              value: false,
              text: "No",
            },
          ],
        }
      ] : [
        {
          header: "Company",
          field: "dme_name",
        },
        {
          header: "Crosswalk Id",
          field: "crosswalk_id",
        },
        {
          header: "Autofit Eligible",
          field: "autofit_eligible",
          filters: [
            {
              value: true,
              text: "Yes",
            },
            {
              value: false,
              text: "No",
            },
          ]
        },
        {
          header: "Back Ordered",
          field: "back_ordered",
          filters: [
            {
              value: true,
              text: "Yes",
            },
            {
              value: false,
              text: "No",
            },
          ]
        },
        {
          header: "Company Active",
          field: "dme_active",
          filters: [
            {
              value: true,
              text: "Yes",
            },
            {
              value: false,
              text: "No",
            },
          ]
        },
        {
          header: "Item Active - Company Level",
          field: "xref_active",
          filters: [
            {
              value: true,
              text: "Yes",
            },
            {
              value: false,
              text: "No",
            },
          ]
        }
      ]}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={rows}
      rowKey="id"
    />
  );
};

InventoryItemsTable.propTypes = {
  mode: PropTypes.string.isRequired,
  inventoryItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};
