import { take, call, put, all } from "redux-saga/effects";
import { Types } from "actions/company";
import { message, errorMessage } from "actions/message";
import { browserHistory } from "browser-history";
import * as api from "utils/api";

function* getCompanyPatientReport(action) {
  try {
    yield all([
      call(getCompanyPatientDoctorInsuranceReport, action),
      call(getCompanyPatientMachineCount, action),
      call(getCompanyPatientMaskCount, action),
      call(getCompanyInactivationCount, action),
      call(getCompanyPatientInsuranceCount, action)
    ]);
    yield put({ type: Types.GET_COMPANY_PATIENT_REPORT_RESPONSE });
  } catch (error) {
    yield put({ type: Types.GET_COMPANY_PATIENT_REPORT_FAILURE });
    yield put(errorMessage("Sorry something went wrong."));
  }
}
function* getCompanyPatientDoctorInsuranceReport({
  payload: { companyId, start_date = null, end_date = null }
}) {
  try {
    yield put({
      type: Types.GET_COMPANY_PHYSICIAN_INSURANCE_REPORT_REQUEST
    });
    const result = yield call(
      api.get,
      `/insights/NewPatientDoctorInsuranceCounts?id=${companyId}&start_date=${start_date}&end_date=${end_date}`
    );
    yield put({
      type: Types.GET_COMPANY_PHYSICIAN_INSURANCE_REPORT_RESPONSE,
      response: result
    });
  } catch (error) {
    yield put({
      type: Types.GET_COMPANY_INSURANCE_INSURANCE_REPORT_FAILURE
    });
  }
}

function* getCompanyPatientMachineCount({
  payload: { companyId, start_date = null, end_date = null }
}) {
  try {
    yield put({
      type: Types.GET_COMPANY_PATIENTS_MACHINE_COUNT_REQUEST
    });
    const result = yield call(
      api.get,
      `/insights/NewPatientMachineCounts?id=${companyId}&start_date=${start_date}&end_date=${end_date}`
    );
    yield put({
      type: Types.GET_COMPANY_PATIENTS_MACHINE_COUNT_RESPONSE,
      response: result
    });
  } catch (error) {
    yield put({
      type: Types.GET_COMPANY_PATIENTS_MACHINE_COUNT_FAILURE
    });
  }
}

function* getCompanyPatientMaskCount({
  payload: { companyId, start_date = null, end_date = null }
}) {
  try {
    yield put({
      type: Types.GET_COMPANY_PATIENTS_MASK_COUNT_REQUEST
    });
    const result = yield call(
      api.get,
      `/insights/NewPatientMaskCounts?id=${companyId}&start_date=${start_date}&end_date=${end_date}`
    );
    yield put({
      type: Types.GET_COMPANY_PATIENTS_MASK_COUNT_RESPONSE,
      response: result
    });
  } catch (error) {
    yield put({
      type: Types.GET_COMPANY_PATIENTS_MASK_COUNT_FAILURE
    });
  }
}

function* getCompanyInactivationCount({
  payload: { companyId, start_date = null, end_date = null }
}) {
  yield put({ type: Types.GET_COMPANY_PATIENTS_INACTIVATION_COUNT_REQUEST });
  const result = yield call(
    api.get,
    `/insights/NewPatientInactivationCounts?id=${companyId}&start_date=${start_date}&end_date=${end_date}`
  );
  yield put({
    type: Types.GET_COMPANY_PATIENTS_INACTIVATION_COUNT_RESPONSE,
    response: result
  });
}

function* getCompanyPatientInsuranceCount({
  payload: { companyId, start_date = null, end_date = null }
}) {
  yield put({ type: Types.GET_COMPANY_PATIENTS_INSURANCE_COUNT_REQUEST });
  const result = yield call(
    api.get,
    `/insights/NewPatientInsuranceCounts?id=${companyId}&start_date=${start_date}&end_date=${end_date}`
  );
  yield put({
    type: Types.GET_COMPANY_PATIENTS_INSURANCE_COUNT_RESPONSE,
    response: result
  });
}

function* exportCompanyInsights({ payload }) {
  try {
    const url = `companies/export_company_insights`;
    yield call(api.post, url, payload);
    yield call(api.get, "exports");
    browserHistory.push("/exports");
  } catch (error) {
    console.error(error);
    yield put(errorMessage("An error occurred while exporting company insights."));
  }
}


function* getCompanyPatientDemographicLogs({ payload: { companyId, indexParams }}) {
  try {
    const result = yield call(
      api.put,
      `/companies/${companyId}/patient-demographic-changes`,
      indexParams
    );
    yield put({
      type: Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_RESPONSE,
      response: result
    });
  } catch (error) {
    console.log(error);
    yield put(errorMessage("An error occurred while get patient demographic logs."));
    yield put({type: Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_FAILURE});
  }
}

function* getCompanyPatientDemographicLogsCount({ payload: { companyId, startDate, endDate }}) {
  try {
    const result = yield call(
      api.get,
      `/companies/${companyId}/patient-demographic-changes-count?startDate=${startDate}&endDate=${endDate}`
    );
    yield put({
      type: Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_COUNT_RESPONSE,
      response: result
    });
  } catch (error) {
    console.log(error);
    yield put(errorMessage("An error occurred while getting the number of patient demographic logs."));
    yield put({type: Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_COUNT_FAILURE});
  }
}

function* getCompanyManufacturerWeights({ payload: { companyId }}) {
  try {
    yield put({type: Types.GET_COMPANY_MANUFACTURER_WEIGHTS_REQUEST})
    const result = yield call(
      api.get,
      `/companies/${companyId}/manufacturer_weights`
    );
    yield put({
      type: Types.GET_COMPANY_MANUFACTURER_WEIGHTS_RESPONSE,
      payload: result
    });
  } catch (error) {
    console.log(error);
    yield put(errorMessage("An error occurred while getting the autofit manufacturer weights."));
    yield put({type: Types.GET_COMPANY_MANUFACTURER_WEIGHTS_FAILURE});
  }
}

function* updateCompanyManufacturerWeights({ payload }) {
  try {
    const { companyId } = payload;
    const requestPayload = payload.weights;

    yield put({type: Types.UPDATE_COMPANY_MANUFACTURER_WEIGHTS_REQUEST, payload: requestPayload})
    yield call(
      api.put,
      `/companies/${companyId}/manufacturer_weights`,
      requestPayload
    );

    yield put({
      type: Types.UPDATE_COMPANY_MANUFACTURER_WEIGHTS_RESPONSE,
      payload: requestPayload
    });

    yield put (message("Autofit manufacturer priority updated successfully!"))
  } catch (error) {
    console.log(error);
    yield put(errorMessage("An error occurred while updating the company's autofit manufacturer weights."));
    yield put({type: Types.UPDATE_COMPANY_MANUFACTURER_WEIGHTS_FAILURE});
  }
}

function* watchGetCompanyPatientReport() {
  while (true) {
    const action = yield take(Types.GET_COMPANY_PATIENT_REPORT_REQUEST);
    yield call(getCompanyPatientReport, action);
  }
}

function* watchGetCompanyPatientDemographicLogs() {
  while (true) {
    const action = yield take(Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_REQUEST);
    yield call(getCompanyPatientDemographicLogs, action);
  }
}

function* watchGetCompanyPatientDemographicLogsCount() {
  while (true) {
    const action = yield take(Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_COUNT_REQUEST);
    yield call(getCompanyPatientDemographicLogsCount, action);
  }
}

function* watchExportCompanyInsights() {
  while (true) {
    const action = yield take(Types.REQUEST_EXPORT_COMPANY_INSIGHTS);
    yield call(exportCompanyInsights, action);
  }
}

function* watchGetCompanyManufacturerWeights() {
  while (true) {
    const action = yield take(Types.GET_COMPANY_MANUFACTURER_WEIGHTS);
    yield call(getCompanyManufacturerWeights, action);
  }
}


function* watchUpdateCompanyManufacturerWeights() {
  while (true) {
    const action = yield take(Types.UPDATE_COMPANY_MANUFACTURER_WEIGHTS);
    yield call(updateCompanyManufacturerWeights, action);
  }
}

export default function* root() {
  yield all([
      watchGetCompanyPatientReport(),
      watchGetCompanyPatientDemographicLogs(),
      watchGetCompanyPatientDemographicLogsCount(),
      watchExportCompanyInsights(),
      watchGetCompanyManufacturerWeights(),
      watchUpdateCompanyManufacturerWeights(),
  ])
}
