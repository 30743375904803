import React, { useState } from "react";
import PropTypes from "prop-types";
import ComplianceDashboardChartsFilter from "../compliance-dashboard-charts-filter";
import { useFetch } from "hooks";
import { path } from "ramda";
import { get } from "../../../../../utils/api";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import { Spinner, Tooltip } from "components/ui";
import { useDispatch } from "react-redux";
import * as msg from "actions/message";
import IoIosInfoCircleOutline from "react-icons/lib/io/ios-information";

export default function HeaderChart({
  categoryName,
  category,
  startDate,
  endDate,
  companyId,
  updateData,
  dataForCheckboxList,
  isFetching
}) {
  const dispatch = useDispatch();

  const [usingIndividualInfo, setUsingIndividualInfo] = useState(false);

  const {
    fetchData: fetchIndividualMetricsData,
    isFetching: fetchingIndividualMetricsData
  } = useFetch({
    apiFn: filters =>
      get(`compliance-dashboard/individual-percentage-categories`, filters),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(
        `Failed to get compliance dashboard metrics data: ${getApiErrorMessage(
          error
        )}`
      );
    }
  });

  const getIndividualMetricsData = async filters => {
    const complianceMetricsDataResponse = await fetchIndividualMetricsData(
      filters
    );
    if (complianceMetricsDataResponse) {
      await setUsingIndividualInfo(true);
      await updateData(complianceMetricsDataResponse);
    } else {
      dispatch(
        msg.errorMessage(`Couldn't get the data for the filtered chart`)
      );
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <h4
        style={{
          textAlign: "center",
          color: "rgb(49, 130, 189)",
          boxShadow: "0 2px 1px -1px rgba(0, 0, 0, 0.1)"
        }}
      >
        {categoryName}
        <Tooltip
          id={`single-stat-tooltip-${categoryName}`}
          className="single-stat-tooltip"
          effect="solid"
          message={
            "This graph only shows patients who are compliant. When you click any bar, details show both compliant and non-compliant patients"
          }
        >
          <IoIosInfoCircleOutline
            size="1rem"
            style={{ marginLeft: "4px", marginBottom: "6px" }}
          />
        </Tooltip>
      </h4>
      <div style={{ display: "flex" }}>
        <ComplianceDashboardChartsFilter
          items={dataForCheckboxList}
          category={category}
          categoryName={categoryName}
          filterFunction={getIndividualMetricsData}
          startDate={startDate}
          endDate={endDate}
          companyId={companyId}
          changeUsingIndividualInfo={setUsingIndividualInfo}
          usingIndividualInfo={usingIndividualInfo}
          isFetchingMetricsData={isFetching}
        />
        {fetchingIndividualMetricsData && (
          <div style={{ marginLeft: "10px" }}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}

HeaderChart.propTypes = {
  categoryName: PropTypes.string.isRequired,
  category: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  startDate: PropTypes.date,
  endDate: PropTypes.date,
  companyId: PropTypes.string,
  updateData: PropTypes.func.isRequired,
  dataForCheckboxList: PropTypes.array,
  isFetching: PropTypes.bool
};
