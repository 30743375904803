import React, { useEffect, useState } from "react";
import { convertDatesToGridSpan } from "./scheduler-helpers";
import WeekSelect from "./week-select";
import TimeSlots from "./time-slots";
import ScheduledSlot from "./scheduled-slot";
import TempScheduledSlot from "./temp-scheduled-slot";
import useAvailabilityScheduler, {
  TimeSlotContext
} from "./use-availabilityScheduler";
import Calandar from "./calendar";
import CalandarLegend from "./calendar-legend";
import CalendarHelpMenu from "./calander-help-menu";
import PropTypes from "prop-types";
import ChatTypeLegend from "./chat-type-legend";
import { CreateEditViewTimeSlotModal } from "./create-time-slot-modal";

const AvailabilityScheduler = React.memo(({timeZone, resetTimeZoneValue}) => {

  const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));

  const {
    userTimeSlots,
    getUserTimeSlots,
    createTimeSlot,
    deleteTimeSlotById,
    pendingTimeSlot,
    setPendingTimeSlot,
    week,
    selectWeek,
    selected,
    setSelected,
    editSlot,
    setEditSlot,
    gettingTimeSlots,
    creatingOrEditingTimeSlots,
    setCreatingOrEditingTimeSlots,
    editTimeSlotById
  } = useAvailabilityScheduler();

  useEffect(() => {   
    if(timeZone){
        getUserTimeSlots(week);
        resetTimeZoneValue();
    }
  }, [timeZone]);

  const [willEditTimeSlot] = useState(false)

  return (
    <TimeSlotContext.Provider
      value={{
        userTimeSlots,
        getUserTimeSlots,
        createTimeSlot,
        deleteTimeSlotById,
        editTimeSlotById,
        pendingTimeSlot,
        setPendingTimeSlot,
        week,
        selectWeek,
        selected,
        setSelected,
        editSlot,
        setEditSlot,
        gettingTimeSlots,
        creatingOrEditingTimeSlots,
        setCreatingOrEditingTimeSlots
      }}
    >
      <div className="scheduler">
        <div className="calander-header">
          <WeekSelect />
          <ChatTypeLegend />
          <CalandarLegend />
          <CalendarHelpMenu />
        </div>
        <div className="calendar-container">
          <Calandar willEditTimeSlot={willEditTimeSlot} timeZone={userLoggedIn.timezone}>
            <TimeSlots week={week} timeZone={userLoggedIn.timezone} />
            {pendingTimeSlot ? (
              <TempScheduledSlot
                {...convertDatesToGridSpan(pendingTimeSlot)}
                setEditSlot={setEditSlot}
                willEditTimeSlot={willEditTimeSlot}
                timeZone={userLoggedIn.timezone}
              />
            ) : null}
            {userTimeSlots
              .map(s => ({ ...s, ...convertDatesToGridSpan(s) }))
              .map(slot => (
                <ScheduledSlot key={slot.slot_id} slot={slot} />
              ))}
          </Calandar>
          {(editSlot && !pendingTimeSlot) && <CreateEditViewTimeSlotModal timeZone={userLoggedIn.timezone} data={editSlot} isViewing={true}/>}
        </div>
      </div>
    </TimeSlotContext.Provider>
  );
});

AvailabilityScheduler.propTypes = {
    timeZone: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    resetTimeZoneValue: PropTypes.func
}

export default AvailabilityScheduler;
