import {
  take,
  fork,
  call,
  put,
  all
} from 'redux-saga/effects'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import * as api from 'utils/api'

const METHOD_DICT = {
  GET: api.get,
  POST: api.post,
  PUT: api.put,
  DELETE: api.del
}
/*
  this way of handling api requests should ideally be phased out
  reason: already have several other methods to abstract handling api requests(don't need more!)
          seems to lack basic functionality such as error handling and isFetching status,
          seems to be used sparingly and has not been mantained for a while.
*/
export default function* watchForApiRequests() {
  while (true) {
    const action = yield take()
    if (action.payload && action.payload.apiRequest) {
      try {
        const { apiRequest } = action.payload;
        if (isPlainObject(apiRequest)) {
          yield fork(fetchApi, action.payload.apiRequest);
        } else if (isArray(apiRequest)) {
          yield all(apiRequest.map(r => fork(fetchApi, r)));
        } else {
          throw new Error(
            `Unsupported \`apiRequest\` type: ${typeof apiRequest}`
          );
        }
      } catch (error) {
        console.error("ERROR: features/api-saga");
        console.error(error);
      }
    }
  }
}

function* fetchApi({ method, route, params, type, meta }) {
  const methodFn = METHOD_DICT[method.toUpperCase()]

  if (!methodFn) {
    throw new Error(`Unsupported API method: ${method}`)
  }
  if (typeof route !== 'string') {
    throw new Error('Invalid `route` supplied: Must supply string route to API')
  }
  if (type && typeof type !== 'string') {
    throw new Error('`type` must be string as required by Redux')
  }

  try {
    const response = yield call(methodFn, route, params)
    if (type) {
      yield put({
        type,
        payload: response,
        meta
      })
    }
  } catch(error) {
    if (type) {
      yield put({
        type,
        payload: error,
        error: true,
        meta
      })
    }
  }
}
