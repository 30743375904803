import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import moment from "moment";
import Timer from "./timer";
import MdCheck from "react-icons/lib/md/check";
import { formatPercantage } from "utils/misc";
import CoachControls from "./coach-controls";
import CoachName from "./coach-name";
import { formatSeconds, getTimeEllapsedInSeconds } from "utils/dates";
import TeamDashboardContext from "../context";

const getCoachADPercentage = (
  appendCurrentValues,
  time_in_queue_seconds,
  total_time_in_seconds,
  currentUserStatus
) => {
  if (appendCurrentValues) {
    const { connectedAt, autodialerOpenedAt } = currentUserStatus;
    const totalOnlineTime =
      total_time_in_seconds + getTimeEllapsedInSeconds(connectedAt);
    const totalAdTime =
      time_in_queue_seconds + getTimeEllapsedInSeconds(autodialerOpenedAt);
    return formatPercantage(totalAdTime, totalOnlineTime, 1);
  } else
    return formatPercantage(time_in_queue_seconds, total_time_in_seconds, 1);
};

const CoachStatusRow = React.memo(
  ({
    coach_name,
    coach_guid,
    contracted_employee,
    currentUserStatus,
    total_time_in_seconds,
    time_in_queue_seconds,
    userOffshore
  }) => {
    const {
      requestedDates: { end_date }
    } = useContext(TeamDashboardContext);
    const appendCurrentValues =
      end_date && !moment(end_date, "MM/DD/YYYY").isBefore(moment(), "day");
    return (
      <tr>
        {!userOffshore && <CoachControls coach_guid={coach_guid} />}
        <CoachName
          coach_name={coach_name}
          coach_guid={coach_guid}
          contracted_employee={contracted_employee}
        />
        <td>
          {currentUserStatus.connectedAt && (
            <span
              title={`Session started at: ${moment(
                currentUserStatus.connectedAt
              ).format("hh:mm A")}`}
              style={{ paddingLeft: 5 }}
            >
              <MdCheck color="green" size={17} />
            </span>
          )}
        </td>
        <td>
          {currentUserStatus.callConnectedAt ? (
            <Timer startedAt={currentUserStatus.callConnectedAt} />
          ) : null}
        </td>
        <td>
          {currentUserStatus.autodialerOpenedAt ? (
            <Timer startedAt={currentUserStatus.autodialerOpenedAt} />
          ) : null}
        </td>
        <td>
          {appendCurrentValues && currentUserStatus.connectedAt ? (
            <span style={{ color: "green" }}>
              <Timer
                startedAt={moment(
                  moment.tz(currentUserStatus.connectedAt, "US/Central")
                )
                  .subtract(total_time_in_seconds, "seconds")
                  .toISOString()}
                formatter={formatSeconds}
                tick={1000}
              />
            </span>
          ) : (
            formatSeconds(total_time_in_seconds)
          )}
        </td>
        <td>
          {appendCurrentValues && currentUserStatus.autodialerOpenedAt ? (
            <span style={{ color: "green" }}>
              <Timer
                startedAt={moment(
                  moment.tz(currentUserStatus.autodialerOpenedAt, "US/Central")
                )
                  .subtract(time_in_queue_seconds, "seconds")
                  .toISOString()}
                formatter={formatSeconds}
                tick={1000}
              />
            </span>
          ) : (
            formatSeconds(time_in_queue_seconds)
          )}
        </td>
        <td>
          <span>
            {getCoachADPercentage(
              appendCurrentValues,
              time_in_queue_seconds,
              total_time_in_seconds,
              currentUserStatus
            )}
          </span>
        </td>
        {/* <td>
         {JSON.stringify( currentUserStatus)}
        </td> */}
      </tr>
    );
  }
);

CoachStatusRow.propTypes = {
  coach_guid: PropTypes.string.isRequired,
  coach_name: PropTypes.string.isRequired,
  contracted_employee: PropTypes.bool,
  currentUserStatus: PropTypes.shape({
    connectedAt: PropTypes.string,
    callConnectedAt: PropTypes.string,
    autodialerOpenedAt: PropTypes.string,
    endReason: PropTypes.string
  }),
  time_in_queue_seconds: PropTypes.number,
  total_time_in_seconds: PropTypes.number,
  userOffshore: PropTypes.bool
};

export default connect((state, { coach_guid }) => ({
  currentUserStatus: selectors.getCurrentUserStatus(state, coach_guid)
}))(CoachStatusRow);
