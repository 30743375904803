import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { useFetch } from "hooks";
import { put } from "utils/api";
import PatientSearchResults from "./patient-search-results";
import MdSearch from "react-icons/lib/md/search";

const PatientSearchForm = ({
  feature,
  multiple,
  setSelectedPatients,
  selectedPatients
}) => {
  const { response: patients, fetchData, isFetching } = useFetch({
    apiFn: values => put("dme_portal/search_patients", values),
    defaultValue: [],
    transformResponse: ({ patients }) => patients
  });
  React.useEffect(() => {
    setSelectedPatients(multiple ? [] : "");
  }, [patients]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 10,
        border: "1px solid #eee"
      }}
    >
      <Formik
        initialValues={{
          name: "",
          email: "",
          date_of_birth: "",
          account_number: "",
          feature
        }}
        onSubmit={async values => {
          await fetchData(values);
        }}
        enableReinitialize={true}
      >
        {({ resetForm }) => (
          <Form className="patient-search-form">
            <div>
              <FormikField name="name" label="Name" type="text" />
              <FormikField name="email" label="Email" type="text" />
            </div>
            <div>
              <FormikField
                name="date_of_birth"
                label="Date Of Birth"
                type="date"
              />
              <FormikField
                name="account_number"
                label="Account Number"
                type="text"
              />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center"
              }}
            >
              <button
                type="button"
                onClick={resetForm}
                className="button warning"
              >
                Clear
              </button>
              <button type="submit" className="secondary">
                <MdSearch />
                Search
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <PatientSearchResults
        patients={patients}
        isFetching={isFetching}
        selectedPatients={selectedPatients}
        setSelectedPatients={setSelectedPatients}
        multiple={multiple}
      />
    </div>
  );
};

PatientSearchForm.defaultProps = { feature: "", multiple: true };

PatientSearchForm.propTypes = {
  feature: PropTypes.string,
  setSelectedPatients: PropTypes.func.isRequired,
  selectedPatients: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  multiple: PropTypes.bool
};

export default PatientSearchForm;
