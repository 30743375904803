import React, { useState } from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import moment from "moment";

const EditOrderHoldReason = ({
  updateOrderHoldReason,
  reason,
  orderId,
  canEditHoldReason,
  closeModal
}) => {
  const [comments, setReasonComment] = useState(reason.comments || "");
  const [resolved, setIsResolved] = useState(reason.resolved);
  const [isUpdating, setIsUpdating] = useState(false);
  return (
    <div className="edit-reason-container">
      <div className="edit-reason-content">
        <div className="edit-reason-header">
          <div className="edit-reason-title">{reason.holdReason}</div>
          <div>
            {reason.updatedDate ? (
              <div
                style={{
                  display: "grid",
                  gridGap: 20,
                  gridTemplateColumns: "auto auto",
                  alignItems: "center"
                }}
              >
                <div style={{ fontSize: "0.85em", color: "#666" }}>
                  Last Updated
                </div>
                <div>
                  <div className="last-updated-by">{reason.updatedByName}</div>
                  {moment(reason.updatedDate).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  gridGap: 20,
                  alignItems: "center"
                }}
              >
                <div style={{ fontSize: "0.85em", color: "#666" }}>
                  Created At
                </div>
                <div>
                  <div className="last-updated-by">{reason.createdByName}</div>
                  <div>
                    {moment(reason.createdDate).format("MMMM Do YYYY, h:mm a")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <textarea
          value={comments}
          onChange={({ target }) => setReasonComment(target.value)}
        />
        <span className="edit-reason-controls">
          <label>
            Resolved
            <input
              type="checkbox"
              checked={resolved}
              disabled={!canEditHoldReason}
              onChange={({ target }) => setIsResolved(target.checked)}
            />
          </label>
          <button onClick={closeModal} className="button warning">
            Cancel
          </button>
          {isUpdating ? (
            <InlineWaiting />
          ) : (
            <button
              onClick={async () => {
                try {
                  setIsUpdating(true);
                  await updateOrderHoldReason(orderId, {
                    holdReasonPK: reason.holdReasonPK,
                    comments,
                    resolved
                  });
                  setIsUpdating(false);
                  closeModal();
                } catch (e) {
                  setIsUpdating(false);
                }
              }}
            >
              Save
            </button>
          )}
        </span>
      </div>
    </div>
  );
};

export default EditOrderHoldReason;

EditOrderHoldReason.propTypes = {
  updateOrderHoldReason: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  reason: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool,
  canEditHoldReason: PropTypes.bool
};
