import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers/user";
import SideBar from "components/ui/sidebar";
import SystemTray from "components/system-tray";
import Radium, { StyleRoot } from "radium";
import Phone from "components/phone";
import ErrorBoundary from "components/shared/error-boundary";
import HasRolePermission from "components/shared/has-role-permission";
import SignalR from "features/signalR";
import GoogleApi from "features/google-api";
import TwilioChatWindow from "components/twilio-chat";
import InActivityManager from "components/inactivity-manager";

const Phoenix = ({ canMakeCalls, canUseTextChat, children }) => {
  return (
    <StyleRoot className="root-component" id="modal-root">
      {canMakeCalls && <Phone />}
      <SideBar />
      <section role="main">
        <ErrorBoundary>{children}</ErrorBoundary>
      </section>
      <InActivityManager />
      {canUseTextChat && <TwilioChatWindow />}
      <SystemTray />
      <SignalR />
      <HasRolePermission allowedRoles={["SleepCoach", "Administrator"]}>
        <GoogleApi />
      </HasRolePermission>
    </StyleRoot>
  );
};

Phoenix.propTypes = {
  children: PropTypes.object,
  canMakeCalls: PropTypes.bool,
  canUseTextChat: PropTypes.bool
};

Phoenix.contextTypes = {
  router: PropTypes.object
};

export default connect(state => ({
  canMakeCalls: selectors.getUserCanMakeCalls(state),
  canUseTextChat: selectors.getUserIsTextChatCapable(state)
}))(Radium(Phoenix));
