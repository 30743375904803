import React from "react";
import PropTypes from "prop-types";
import { browserHistory } from "browser-history";

const ErrorMessage = ({ children, fillPage, backButton }) => (
  <div className={`error-message-container ${fillPage && "fill-page"}`}>
    <div className="error-message">{children}</div>
    {backButton && (
      <button className="back-btn" onClick={() => browserHistory.goBack()}>
        Go Back
      </button>
    )}
  </div>
);

ErrorMessage.defaultProps = {
  backButton: true,
  fillPage: true
};

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  backButton: PropTypes.bool,
  fillPage: PropTypes.bool
};

export default ErrorMessage;
