import { createContext, useEffect } from "react";
import useTableSettings, { cleanQuery } from "hooks/useTableSettings";
import isEqual from "lodash/isEqual";

export const PatientsTableSettingsContext = createContext();

const usePatientsTableSettings = (query, queryDispatcher) => {
  const columns = [
    {
      header: "all",
      field: "all",
      active: true
    },
    {
      header: "ID",
      field: "ticketNumber",
      active: true
    },
    {
      header: "Registration Date",
      field: "registrationDate",
      active: true
    },
    {
      header: "Worked",
      field: "worked",
      active: true
    },
    {
      header: "Asignee",
      field: "asignee",
      active: true
    },
    {
      header: "Assigned date",
      field: "assignedDate",
      active: true
    },
    {
      header: "Worker",
      field: "worker",
      active: true
    },
    {
      header: "Worked Date",
      field: "workedDate",
      active: true
    },
    {
      header: "Matched",
      field: "matched",
      active: true
    },
    {
      header: "Matched Date",
      field: "matchedDate",
      active: true
    },
    {
       header: "Notes",
      field: "notes",
      active: true
    },
  ];
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    visibleColumns,
    resetTableSettings
  } = useTableSettings("compliance-patients-table-settings", columns);

  // remove sorting and filters when column is removed
  useEffect(() => {
    const newQuery = cleanQuery(visibleColumns, query);
    if (!isEqual(newQuery, query)) {
      queryDispatcher({ type: "updateQuery", ...newQuery });
    }
  }, [visibleColumns, queryDispatcher]);
  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  };
};

export default usePatientsTableSettings;
