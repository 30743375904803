import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";

const AttributeItem = ({ title, children, actions, style }) => {
  return (
    <li style={S.container}>
      <span style={S.title}>{title}</span>
      <span style={{...S.description, ...style}}>{children}</span>
      {actions && <span style={S.actions}>{actions}</span>}
    </li>
  );
};

export default Radium(AttributeItem);

AttributeItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  actions: PropTypes.node,
  style: PropTypes.object
};

AttributeItem.defaultProps = {
  style: {}
}

const S = styler`
  container
    display: grid
    grid-template-columns: auto 4fr auto
    border-bottom: 1px solid #EEE
    height: 100%
    align-items: center
  title
    font-weight: bold
    display: inline-block
    min-width: 150
  actions
    display: inline-flex
    text-align: center
    align-items: center
  description
    // white-space: nowrap
    // overflow: hidden
    // text-overflow: ellipsis
`;
