import useSafeSetState from "./useSafeSetState";

/**
 * React Hook to provide local functioniality to handle api calls
 *
 * @param {Object} options
 *    apiFn: Function*
 *    defaultValue: Any
 *    initialRequest: Object
 *    transformResponse: Function -> Any
 *    transformError: Function -> String
 *    clearDataOnFetch: Boolean
 *    throwOnError: Boolean
 *    onError: Function
 *    onResponse: Function
 * @return {object}
 *    response : Any
 *    error  : Error
 *    fetchData  : Function
 *    isFetching  : Boolean
 * @example
    const { response, error, fetchData, isFetching } = useFetch(payload => put(`patients`, payload), "patients", {
      defaultValue: [],
      transformResponse: response => response.patients
    });
 */

const useFetch = ({
  apiFn,
  defaultValue,
  transformResponse,
  transformError,
  onSuccess,
  onError,
  throwOnError,
  clearDataOnFetch = true
}) => {
  const [response, setResponse] = useSafeSetState(defaultValue);
  const [isFetching, setIsFetching] = useSafeSetState(false);
  const [error, setError] = useSafeSetState();

  const fetchData = async params => {
    try {
      setIsFetching(true);
      if (clearDataOnFetch) setResponse(defaultValue);
      setError();
      const result = await apiFn(params);
      const response =
        typeof transformResponse === "function"
          ? transformResponse(result)
          : result;
      setResponse(response);
      if (typeof onSuccess === "function") onSuccess(response);
      return response;
    } catch (error) {
      if (typeof transformError === "function") {
        setError(transformError(error));
      } else setError(error);
      if (typeof onError === "function") onError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsFetching(false);
    }
  };

  return {
    response,
    error,
    fetchData,
    isFetching
  };
};

export default useFetch;
