import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUser } from "action-creators";
import UserForm from "components/screens/user-form";
import { PageHeader } from "components/ui";

const CreateUser = ({ createUser }) => (
  <div>
    <PageHeader title="Create User" />
    <UserForm
      handleSubmit={async user => {
        await createUser({
          user
        });
      }}
    />
  </div>
);

CreateUser.propTypes = {
  createUser: PropTypes.func.isRequired
};

export default connect(() => ({}), {
  createUser
})(CreateUser);
