import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ExportInvoiceIcon from "react-icons/lib/io/ios-paper";
import { requestInvoiceDetail } from "actions/order-status";
import HasRolePermission from "components/shared/has-role-permission";

const InvoiceReport = ({ query, requestInvoiceDetail }) => (
  <HasRolePermission
    allowedRoles={[
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator"
    ]}
  >
    <span
      title="Export Invoice Report(all orders)."
      className="s3-ui-custom-button"
      onClick={() => {
        requestInvoiceDetail(query);
      }}
    >
      <ExportInvoiceIcon />
    </span>
  </HasRolePermission>
);

InvoiceReport.propTypes = {
  query: PropTypes.shape({
    filters: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.string)
    })
  }).isRequired,
  requestInvoiceDetail: PropTypes.func.isRequired
};

export default connect(
  null,
  { requestInvoiceDetail }
)(InvoiceReport);
