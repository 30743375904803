import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { showLeavePatientVmailBtn } from "reducers/selectors";
import {
  autoDialerProcessNext,
  recordEndAutodialerReason
} from "actions/auto-dialer";
import { playAutomatedVoicemail } from "actions/phone";
import MdPhone from "react-icons/lib/md/phone";
import MdClose from "react-icons/lib/md/close";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";
import MdVoicemail from "react-icons/lib/md/voicemail";
import { withModal } from "components/ui";
// import HasRolePermission from "components/shared/has-role-permission";

const LeavePatientVmailButton = connect(
  state => {
    const currentPatientId = selectors.getAutoDialerCurrentPatientId(state);
    return {
      showPatientVmailButton: showLeavePatientVmailBtn(state, currentPatientId),
      currentPatientId
    };
  },
  { playAutomatedVoicemail }
)(
  ({ showPatientVmailButton, currentPatientId, playAutomatedVoicemail }) =>
    showPatientVmailButton && (
      <span
        className="tab-side-btn"
        title="Leave Automated Voicemail"
        onClick={() => {
          playAutomatedVoicemail(currentPatientId);
        }}
      >
        <MdVoicemail size={20} />
      </span>
    )
);

const ProcessNextButton = connect(
  state => ({
    patientIds: selectors.getAutoDialerPatientIds(state),
    activePageIndex: selectors.getAutoDialerCurrentPageIndex(state),
    wrapupTime: selectors.getAutoDialerWrapupTime(state)
  }),
  { autoDialerProcessNext }
)(
  ({ wrapupTime, activePageIndex, patientIds, autoDialerProcessNext }) =>
    wrapupTime > 0 &&
    activePageIndex < patientIds.length - 1 && (
      <span
        className="tab-side-btn"
        title="Proccess Next Patient"
        onClick={autoDialerProcessNext}
      >
        <IoArrowRightA size={20} />
      </span>
    )
);

const AutoDialerTabLabel = connect(state => ({
  cur: selectors.getAutoDialerCurrentPageIndex(state) + 1,
  remaining: selectors.getAutoDialerPatientIds(state).length
}))(({ cur, remaining }) => (
  <span style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
    <span>
      Patients ({cur}/{remaining})
    </span>
  </span>
));

const PatientOutreachTab = ({
  handleOpen,
  handleClose,
  isOpen,
  isActive,
  followUpOrders
  // openModal,
  // closeModal,
  // recordEndAutodialerReason
}) => (
  <React.Fragment>
    <div className={`side-btn ${followUpOrders ? "followUpOrders" : ""}`}>
      {!isOpen && <LeavePatientVmailButton />}
      {!isOpen && <ProcessNextButton />}
    </div>
    <div
      onClick={handleOpen}
      className={`system-tab auto-dialer-tab ${isOpen ? "is-open" : ""} ${
        isActive ? "is-active" : ""
      } ${followUpOrders ? "followUpOrders" : ""}`}
    >
      <span>
        <MdPhone />
      </span>
      <span className="tab-label">
        <AutoDialerTabLabel />
      </span>
      {!isOpen && handleClose && (
        <span className="close-icon" onClick={handleClose}>
          <MdClose size={18} />
        </span>
      )}
      {/* IMPORTANT: EndAutoDialerReasonModal is commented out because it was messing up coach dialer times */}
      {/* {handleClose && (
        <HasRolePermission
          allowedRoles={[
            "SleepCoach",
            "Administrator",
            "CompanyAdministrator",
            "OfficeAdministrator",
            "ComplianceTherapist",
            "OrderReviewer",
            "Therapist"
          ]}
        >
        
          <span
            className="close-icon"
            onClick={() => {
              openModal(
                <EndAutoDialerReasonModal
                  closeModal={closeModal}
                  handleClose={handleClose}
                  recordEndAutodialerReason={recordEndAutodialerReason}
                />
              );
            }}
          >
            <MdClose size={18} />
          </span>
          )
        </HasRolePermission>
      )} */}
      {/* {handleClose && ( */}
      {/*   <HasRolePermission */}
      {/*     allowedRoles={[ */}
      {/*       "SleepCoach", */}
      {/*       "Administrator", */}
      {/*       "CompanyAdministrator", */}
      {/*       "OfficeAdministrator", */}
      {/*       "ComplianceTherapist", */}
      {/*       "OrderReviewer", */}
      {/*       "Therapist" */}
      {/*     ]} */}
      {/*   > */}
      {/*     <span */}
      {/*       className="close-icon" */}
      {/*       onClick={() => { */}
      {/*         openModal( */}
      {/*           <EndAutoDialerReasonModal */}
      {/*             closeModal={closeModal} */}
      {/*             handleClose={handleClose} */}
      {/*             recordEndAutodialerReason={recordEndAutodialerReason} */}
      {/*           /> */}
      {/*         ); */}
      {/*       }} */}
      {/*     > */}
      {/*       <MdClose size={18} /> */}
      {/*     </span> */}
      {/*   </HasRolePermission> */}
      {/* )} */}
    </div>
  </React.Fragment>
);

export default withModal(
  connect(
    state => ({
      isActive: selectors.getIsAutoDialerEnabled(state),
      autoDialerEndReason: selectors.getAutoDialerEndReason(state)
    }),
    { recordEndAutodialerReason }
  )(PatientOutreachTab)
);

PatientOutreachTab.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool,
  followUpOrders: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  autoDialerEndReason: PropTypes.string,
  recordEndAutodialerReason: PropTypes.func
};

// const EndAutoDialerReasonModal = ({
//   handleClose,
//   closeModal,
//   recordEndAutodialerReason
// }) => {
//   const autoDialerEndReason = useSelector(selectors.getAutoDialerEndReason);
//   const timeLimit = 8;
//   const [time, setTime] = React.useState(timeLimit);
//   React.useEffect(() => {
//     var timerID = setInterval(() => setTime(time - 1), 1000);
//     return function cleanup() {
//       clearInterval(timerID);
//     };
//   });
//   React.useEffect(() => {
//     if (time < 1) {
//       recordEndAutodialerReason("");
//       closeModal();
//     }
//   }, [time]);
//   return (
//     <div
//       style={{
//         margin: "20px",
//         display: "grid",
//         gridTemplateColumns: "1fr",
//         justifyItems: "center"
//       }}
//     >
//       {" "}
//       <h3>Please Select Why You Are Closing The AutoDialer</h3>
//       <select
//         placeholder="Choose reason..."
//         value={autoDialerEndReason}
//         onChange={event => recordEndAutodialerReason(event.target.value)}
//       >
//         <option value="" disabled selected>
//           Select your reason...
//         </option>
//         {[
//           "Break",
//           "Going To Lunch",
//           "End Of Shift",
//           "Bathroom Break",
//           "Training",
//           "Team Lead Meeting",
//           "Voicemail/Email",
//           "Reset Filters"
//         ].map(value => (
//           <option key={value} value={value}>
//             {value}
//           </option>
//         ))}
//       </select>
//       <div>
//         {time <= timeLimit / 2 ? (
//           <h4
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr",
//               fontSize: "4em",
//               fontWeight: "800",
//               color: "red",
//               justifyItems: "center"
//             }}
//           >
//             <div>Seconds To Choose</div>
//             <div>{time}</div>
//           </h4>
//         ) : (
//           <h4
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr",
//               fontSize: "4em",
//               fontWeight: "800",
//               color: "green",
//               justifyItems: "center"
//             }}
//           >
//             <div>Seconds To Choose</div>
//             <div>{time}</div>
//           </h4>
//         )}
//       </div>
//       <div
//         style={{
//           width: "200px",
//           display: "grid",
//           gridTemplateColumns: "1fr",
//           columnGap: "10px"
//         }}
//       >
//         <button
//           title={
//             autoDialerEndReason == ""
//               ? "You must choose a reason to exit"
//               : "Stop Autodialer Now"
//           }
//           disabled={autoDialerEndReason === ""}
//           className="button warning danger"
//           onClick={handleClose}
//         >
//           Submit Reason
//         </button>
//         <button
//           onClick={() => {
//             recordEndAutodialerReason("");
//             closeModal();
//           }}
//         >
//           Continue Calling
//         </button>
//       </div>
//     </div>
//   );
// };
