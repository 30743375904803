import { call, all, put, takeEvery } from "redux-saga/effects";
import { browserHistory } from "browser-history";
import omit from 'lodash/omit'
import { responseUpdateOrder, errorUpdateOrder } from "actions/order-status";
import A from "action-types";
import { message, errorMessage } from "actions/message";
import { transformUiQuery } from 'utils/query'
import * as api from "utils/api";

export default function* rootOrderStatusSaga() {
  yield all([watchOrderUpdateRequest(), watchExportInvoiceRequest()]);
}
function* watchOrderUpdateRequest() {
  yield takeEvery(A.ORDER_STATUS.UPDATE_REQUEST, updateOrder);
}

function* watchExportInvoiceRequest() {
  yield takeEvery(A.ORDER_STATUS.REQUEST_EXPORT_INVOICE, exportInvoiceDetail);
}

function* updateOrder({ payload: { orderId, update } }) {
  try {
    const { order: responseUpdate } = yield call(
      api.put,
      `orders/${orderId}`,
      update
    );
    yield put(message("Order updated!"));
    yield put(responseUpdateOrder(orderId, responseUpdate));
  } catch (error) {
    yield put(errorMessage("Failed to update order."));
    yield put(errorUpdateOrder(orderId, error));
  }
}

export function* exportInvoiceDetail({ payload: { query } }) {
  try {
    yield call(api.put, "orders/export/invoice", omit(transformUiQuery(query), 'pagination'))
    browserHistory.push("/exports");
  } catch (error) {
    yield put(errorMessage("Failed to export invoice details."));
  }
}
