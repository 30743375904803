import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SleepCoachDashboard from "./sleep-coach-dashboard";
import { PageHeader } from "components/ui";
import AdminDashboardContainer from "components/dashboard";
import PatientsOverview from "components/dashboard/patients-overview";
import { formatDate } from "utils/dates";

const DashboardScreen = ({ user, userRole }) => (
  <div>
    <PageHeader title={"Welcome back, " + user.get("first_name")}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end", 
          color: "#666"
        }}
      >
        Last Login: {formatDate(user.get("last_login"), "MMMM Do YYYY, h:mm a")}
      </div>
    </PageHeader>
    {(userRole === "SleepCoach" || userRole === "ServiceCoach") && (
      <SleepCoachDashboard user={user} />
    )}
    {(userRole === "Therapist" || userRole === "ComplianceTherapist") && (
      <PatientsOverview />
    )}
    {(userRole === "ServiceAdmin" ||
      userRole === "CompanyAdministrator" ||
      userRole === "OfficeAdministrator" ||
      userRole === "Administrator" ||
      userRole === "ContractorAdministrator"
      ) && <AdminDashboardContainer />}
  </div>
);

DashboardScreen.propTypes = {
  user: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired
};

export default connect(state => ({
  user: state.get("user"),
  userRole: state.getIn(["user", "role"])
}))(DashboardScreen);
