/**
 * This module exports functions returning objects that represent
 * logical expressions as well as `evaluate` to evaluate the
 * expression. Use it when you need to express a truth or falsity
 * declaritively, like in a reducer.
 */

import some from 'lodash/some'
import every from 'lodash/every'
import curry from 'lodash/curry'

const AND = 'and'
export const and = (...args) => ({
  op: AND,
  args
})

const OR = 'or'
export const or = (...args) => ({
  op: OR,
  args
})

const NOT = 'not'
export const not = expr => ({
  op: NOT,
  expr
})

const EQ = 'eq'
export const eq = curry((lhs, rhs) => ({
  op: EQ,
  lhs,
  rhs
}))

const CONSTANT = 'constant'
export const constant = value => ({
  op: CONSTANT,
  value
})

const VARIABLE = 'variable'
export const variable = key => ({
  op: VARIABLE,
  key
})

/**
 * Takes an expression and a dictionary for variable lookup. Returns
 * boolean result of expression evaluation.
 */
export const evaluate = (expr, vars) => {
  const _eval = expr => {
    switch (expr.op) {
    case CONSTANT:
      return expr.value
    case VARIABLE:
      return vars[expr.key]
    case EQ:
      return _eval(expr.lhs) === _eval(expr.rhs)
    case NOT:
      return !_eval(expr.expr)
    case OR:
      return some(expr.args, _eval)
    case AND:
      return every(expr.args, _eval)
    default:
      throw new Error('Expression has unsupported op attribute')
    }
  }
  return _eval(expr, vars)
}
