import React from 'react'
import { MdPlayArrow, MdTextsms, MdVideocam } from "react-icons/lib/md";

export default function ChatTypeLegend() {
  return (
    <div className='chat-type-legend'>
        <div className='chat-type-container'>
            <MdVideocam size="20px"/>
            <p className='chat-type-text'>Video Chat</p>
        </div>
        <div className='chat-type-container'>
            <MdTextsms size="20px"/>
            <p className='chat-type-text'>Text Chat</p>
        </div>
        <div className='chat-type-container'>
            <MdPlayArrow size="20px"/>
            <p className='chat-type-text'>Product Demo</p>
        </div>
    </div>
  )
}
