import React, { Component } from 'react'
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { requestData } from "../actions";
import { selectors } from "../reducers";

const withApi = ({
  apiId: configApiId,
  api: configApi = {}
} = {}) => WrappedComponent => connect(
  () => {
    const mapStateToProps = (state, props) => {
      const apiId = props.apiId || configApiId
      return {
        records: selectors.getQueryRecords(state, apiId),
        isFetching: selectors.getIsQueryFetching(state, apiId),
        count: selectors.getQueryCount(state, apiId)
      };
    }
    return mapStateToProps
  },
  (dispatch, props) => {
    const apiId = props.apiId || configApiId
    const {
      method = 'PUT',
      route,
      responseKey,
      idKey,
      entityKey
    } = { ...configApi, ...props.api }
    return bindActionCreators({
      requestData: requestData(apiId, props.query, method, route, responseKey, entityKey, idKey)
    }, dispatch)
  }
)(
  class WithApi extends Component {

    static displayName = `WithApi(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
    static propTypes = {
      requestData: PropTypes.func.isRequired,
      query: PropTypes.object.isRequired
    }

    componentDidMount() {
      this.props.requestData()
    }

    componentDidUpdate({ query: oldQuery }) {
      const { query, requestData } = this.props
      if (query !== oldQuery) {
        requestData()
      }
    }

    render() {
      const { ...passThroughProps } = this.props
      return <WrappedComponent {...passThroughProps} />
    }
  }
)

export default withApi
