import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ExportButton } from 'components/ui'
import MdKeyboard from 'react-icons/lib/md/keyboard'
import MdUnfoldMore from 'react-icons/lib/md/unfold-more'
import MdRefresh from 'react-icons/lib/md/refresh'
import IoMdPrinter from 'react-icons/lib/io/printer'

const withHeaderFooter = (config = {}) => WrappedComponent => (
  class WithHeaderFooter extends Component {
    static displayName = `WithHeaderFooter(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
    static defaultProps = {
      perPageOptions: config.perPageOptions,
      customButtons: config.customButtons,
      customFilters: config.customFilters,
      canDataRefresh: typeof config.canDataRefresh === 'boolean' ? config.canDataRefresh : true,
      showCount: config.showCount || false,
      forceBatchReset: config.forceBatchReset,
      scrollable: config.scrollable || false,
      perPageLimit: config.perPageLimit || 5000,
      query: {},
    }
    static propTypes = {
      ...propTypes
    }
    constructor(props) {
      super(props);
      this.rowPerRef = React.createRef();
      this.state = {
        perPageMode: "select",
        currentPage: props.query.pagination.page
      };
    }


    shouldComponentUpdate(nextProps, nextState) {
      //This was put in b/c the queue polling was causing the DOM to repaint, causing any custome selects to close
      return (
        JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
        JSON.stringify(this.state) !== JSON.stringify(nextState)
      );
    }

    handleRefresh = () => {
      const { forceBatchReset, batchClear, requestData, isFetching } = this.props;
      if(isFetching) return;
      if(forceBatchReset && typeof batchClear === 'function') {
        batchClear()
        requestData()
      }
      else
        requestData()
    }

    handlePer = ({ target: { value } }) => {
      try {
        const { batchClear, updatePer, perPageLimit } = this.props;
        const rowsPerPage = Math.abs(Math.round(parseInt(value)));
        if (Number.isInteger(rowsPerPage) && rowsPerPage > 0) {
          updatePer(rowsPerPage > perPageLimit ? perPageLimit : rowsPerPage);
          if (typeof batchClear === "function") {
            batchClear();
          }
        }
      } catch (e) {
        /* handle error */
      }
    }

    handlePrevious = () => {
      const {
        updatePage,
        query: { pagination: { page } }
      } = this.props
      updatePage(page - 1)
      this.setState({ currentPage: page - 1 })
    }

    handleNext = () => {
      const {
        updatePage,
        query: { pagination: { page } }
      } = this.props
      updatePage(page + 1)
      this.setState({ currentPage: page + 1 })
    }

    handlePageSelect = (e) => {
      e.preventDefault();
      const value = parseInt(this.state.currentPage)
      this.props.updatePage(value)
    }

    handleCustomFilter = ({ target: { name, value } }) => {
      this.props.setFilters(name, [value])
    }

    togglePerPageMode = () => {
      const { updatePer, perPageOptions, batchClear } = this.props;
      this.setState(
        {
          perPageMode: this.state.perPageMode === "select" ? "input" : "select"
        },
        () => {
          if (this.state.perPageMode === "select") updatePer(perPageOptions[0]);
          if (typeof batchClear === "function") {
            batchClear();
          }
        }
      );
    }

    onPageInputChange = (e) => {
      e.preventDefault;
      const { query: { pagination }, count } = this.props;
      const pages = maxPage(count, pagination.per);
      const textValue = e.target.value;
      var regex = /^\d*$/;

      if (textValue <= pages && textValue > 0 && regex.test(textValue) || textValue === "")
        this.setState({ currentPage: textValue })
    }

    render() {
      const {
        query: {
          pagination,
          filters
        },
        perPageLimit,
        count,
        requestData,
        requestExport,
        requestPrint,
        perPageOptions,
        customButtons,
        customDisplay,
        customFilters,
        canDataRefresh,
        showCount
      } = this.props
      return (
        <div className='s3-ui-header-footer'>
          <div className='pagination-per-container'>
          <div>
            {canDataRefresh && requestData && (
              <i className="s3-ui-custom-button" title="Refresh" onClick={this.handleRefresh} >
                <MdRefresh />
              </i>
            )}
            {requestExport && <ExportButton title="Export" onClick={requestExport} />}
            {requestPrint && (
              <i className="s3-ui-custom-button" title="Print" onClick={requestPrint} >
                <IoMdPrinter />
              </i>
            )}
            {customButtons &&
                customButtons.map(({ component: RenderHeader, icon, title, onClick, display }, index) =>
                  typeof RenderHeader == "function" ? (
                    <RenderHeader key={index}/>
                  ) : typeof icon === "function" ? (
                    <i
                      key={index}
                      style={{ display: display == false ? "none" : "" }}
                      className={`s3-ui-custom-button`}
                      title={title}
                      onClick={onClick}
                    >
                      {icon()}
                    </i>
                  ) : (
                    <i
                      key={index}
                      style={{ display: display == false ? "none" : "" }}
                      title={title}
                      onClick={onClick}
                    />
                  )
              )}
          {pagination && perPageOptions && (
            <span className="per-page-options-container">
              {this.state.perPageMode === "input" ? (
                <input
                  ref={this.rowPerRef}
                  defaultValue={pagination.per}
                  type="number"
                  title={`Rows per page. limit: ${perPageLimit}`}
                  onBlur={this.handlePer}
                  onKeyDown={e => {
                    if (e.keyCode == 13) {
                      this.rowPerRef?.current.blur();
                    }
                  }}
                />
              ) : (
                <select title="Rows per page" value={pagination.per} onChange={this.handlePer}>
                  {perPageOptions.map(value => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              )}
              <span
                className="per-page-options-mode"
                onClick={this.togglePerPageMode}
                title={
                  this.state.perPageMode === "input"
                    ? `Select Per Page Options`
                    : "Input Per Page Options"
                }
              >
                {this.state.perPageMode === "select" ? <MdKeyboard /> : <MdUnfoldMore />}
              </span>
            </span>
          )}
          </div>
            {customDisplay && customDisplay.map((Render, index) => (
              <Render key={`header-custom-${index}`} queryId={this.props.queryId}/>
            ))}
            <div>
              {customFilters && filters && customFilters.map(({ options, field }) => (
                <select
                  key={field}
                  name={field}
                  value={filters[field] ? filters[field][0] : ''}
                  onChange={this.handleCustomFilter}>
                  {options.map(({ value, text }) => (
                    <option key={value} value={value}>{text}</option>
                  ))}
                </select>
              ))}
            </div>
          </div>
          <WrappedComponent {...this.props} />
          {pagination && count > 0 && (
            <div className='footer'>
              <div className='pagination-page-container'>
                {pagination.page > 1 && (
                  <div
                    className='pagination-nav-step'
                    onClick={this.handlePrevious}>
                    Previous
                  </div>
                )}
                <form className='pagination-page-range' onSubmit={this.handlePageSelect}>
                  <input
                    type="text"
                    value={this.state.currentPage}
                    onChange={this.onPageInputChange}
                    onBlur={this.handlePageSelect}
                    onClick={e => e.target.select()}
                  />
                  <div>
                    of {maxPage(count, pagination.per)}
                  </div>
                </form>
                {pagination.page < maxPage(count, pagination.per) && (
                  <div
                    className='pagination-nav-step'
                    onClick={this.handleNext}>
                    Next
                  </div>
                )}
              </div>
              {showCount && (
                <div className="total-container">
                  <span className="count-label">Count:</span>
                  <span className="count-value">{count}</span>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }
  }
)

const maxPage = (count, per) => Math.ceil(count / per)

const propTypes = {
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  customDisplay: PropTypes.arrayOf(PropTypes.func),
  customButtons: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.function,
      icon: PropTypes.func,
      title: PropTypes.string,
      onClick: PropTypes.func,
      display: PropTypes.bool
    })
  ),
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.any
        })
      )
    })
  ),
  canDataRefresh: PropTypes.bool,
  batchClear: PropTypes.func,
  count: PropTypes.number,
  forceBatchReset: PropTypes.bool,
  query: PropTypes.object,
  requestData: PropTypes.func,
  requestExport: PropTypes.func,
  requestPrint: PropTypes.func,
  updatePage: PropTypes.func,
  updatePer: PropTypes.func,
  setFilters: PropTypes.func
};

export default withHeaderFooter
