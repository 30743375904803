import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { get } from "utils/api";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { formatDate } from "utils/dates";
import RaPatientCmnsButton from "features/referral-answer/cmns/ra-patient-button";
import RaCMNLink from "features/referral-answer/cmns/ra-cmn-link";

const PatientCmnsList = ({ patientId }) => {
  const {
    response: hcpcs,
    isFetching,
    fetchData: fetchPatientCmns
  } = useFetch({
    apiFn: patientId => get(`cmns/patient/${patientId}`),
    defaultValue: []
  });
  React.useEffect(() => {
    fetchPatientCmns(patientId);
  }, [patientId]);
  const { rows, query, queryDispatcher } = useLocalQuery(hcpcs, {
    sort: {},
    filters: {}
  });
  return (
    <div>
      <Table
        query={query}
        containerClass="query-table patient-tab-table dynamic-columns"
        queryDispatcher={queryDispatcher}
        rows={rows}
        isFetching={isFetching}
        TableRow={cmn => (
          <>
            <tr
              style={
                cmn.index % 2 === 0
                  ? {
                      background: "#f9f9f9"
                    }
                  : {
                      background: "#fff"
                    }
              }
            >
              <td>{cmn.status}</td>
              <td>{cmn.type}</td>
              <td>
                <RaCMNLink
                  external_id={cmn.external_id}
                  patientId={patientId}
                  fetchPatientCmns={fetchPatientCmns}
                />
              </td>
              <td>{formatDate(cmn.start_date)}</td>
              <td>{formatDate(cmn.expiration_date)}</td>
              <td>{formatDate(cmn.audit_date)}</td>
              <td>{cmn.physician_name}</td>
              <td>{cmn.physician_npi}</td>
            </tr>
            <tr
              style={
                cmn.index % 2 === 0
                  ? {
                      background: "#f9f9f9"
                    }
                  : {
                      background: "#fff"
                    }
              }
            >
              <td colSpan="100%" style={{ paddingLeft: 15 }}>
                {cmn.hcpcs.sort().map(hcpc => (
                  <span
                    key={hcpc}
                    style={{
                      padding: 3,
                      fontSize: 12,
                      marginRight: 10,
                      border: "1px solid #ccc",
                      backgroundColor: "#ededed"
                    }}
                  >
                    {hcpc}
                  </span>
                ))}
              </td>
            </tr>
          </>
        )}
        renderFooter={
          <div className="query-footer">
            <RaPatientCmnsButton
              patientId={patientId}
              fetchPatientCmns={fetchPatientCmns}
            />
            <div className="total-container">
              <span className="count-label">Count:</span>
              <span className="count-value">{rows.length}</span>
            </div>
          </div>
        }
        rowKey="cmn_id"
        columns={[
          {
            field: "status",
            header: "Cmn Status",
            filters: [
              { text: "Active", value: "Active" },
              { text: "Pending", value: "Pending" },
              { text: "Received", value: "Received" },
              { text: "Superseded", value: "Superseded" }
            ],
            dropdownPosition: "right"
          },
          {
            field: "type",
            header: "Cmn Type"
          },
          {
            field: "Id",
            header: "Cmn ID"
          },
          {
            field: "start_date",
            header: "Start Date"
          },
          {
            field: "expiration_date",
            header: "Expiration Date"
          },
          {
            field: "audit_date",
            header: "Audit Date"
          },
          {
            field: "physician_name",
            header: "Physician Name"
          },
          {
            field: "physician_npi",
            header: "Physician NPI"
          }
        ]}
      />
    </div>
  );
};

PatientCmnsList.propTypes = { patientId: PropTypes.string.isRequired };

export default PatientCmnsList;
