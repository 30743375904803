import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import Pages from "features/pages";
import VoicemailProfile from "components/voicemail-profile";

function BatchVmailsView({ vmailIds, vmailTitles, userRole }) {
  return (
    <Pages
      showNext
      showPageNumber
      showBack={userRole !== "SleepCoach"}
      showSelect={userRole !== "SleepCoach"}
      pages={vmailIds.map((id, index) => ({
        title: `Voicemail: ${vmailTitles[index]}`,
        view: <VoicemailProfile id={id} batchView={true} />
      }))}
    />
  );
}

export default connect(state => ({
  vmailIds: selectors.getVmailBatchIds(state),
  vmailTitles: selectors.getVmailBatchTitles(state),
  userRole: selectors.getUserRole(state)
}))(BatchVmailsView);

BatchVmailsView.propTypes = {
  vmailIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  vmailTitles: PropTypes.arrayOf(PropTypes.string),
  userRole: PropTypes.string
};
