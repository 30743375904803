import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageHeader from "components/ui/page-header";
import PasswordForm from "./password-form";
import MFAPhoneNumber from "./mfa-phone-number";
import { selectors } from "reducers";
import { browserHistory } from "browser-history";

const MyProfile = ({ user }) => (
  <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
    <PageHeader title={`${user.first_name} ${user.last_name}`}>
      <button onClick={browserHistory.goBack}>Close</button>
    </PageHeader>
    <PasswordForm user={user} />
    <MFAPhoneNumber user={user} />
  </div>
);

export default connect(state => ({
  user: selectors.getUser(state)
}))(MyProfile);

MyProfile.propTypes = {
  user: PropTypes.object.isRequired
};
