import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { RowSelectCell, SelectRowContext } from "hooks/useRowSelectNew";
import GridCancelAppointmentButton from '../cancel-appointment/grid-cancel-button.jsx';
import GridRescheduleAppointmentButton from '../reschedule-appointment/grid-reschedule-button.jsx'

const ManagerViewTableRow = ({
    AppointmentDate,
    AppointmentId,
    StartTime,
    DurationInMinutes,
    CoachName,
    CoachId,
    CompanyName,
    CompanyId,
    ChatType,
    PatientName,
    PatientId,
    Topic,
    Status,
    CanReschedule,
    CanCancel,
    ChatId
}) => {

  var { cancelSelectedAppointment, rescheduleSelectedAppointment } =  useContext(SelectRowContext);

  return (
    <tr>
      <RowSelectCell id={AppointmentId}/>
      <td>{AppointmentDate}</td>
      <td>{StartTime}</td>
      <td>{DurationInMinutes}</td>
      <td>
        <Link to={`/users/${CoachId}`}>
           {CoachName}
        </Link>
      </td>
      <td>
        <Link to={`/companies/${CompanyId}`}>
           {CompanyName}
        </Link>
      </td>
      <td>{ChatType}</td>
      <td>
        {
            <Link to={`/patients/${PatientId}`}>
                {PatientName}
            </Link>
        }
      </td>
      <td>{Topic}</td>
      <td>{Status}</td>
      <td style={{paddingLeft: "40px"}}>{CanReschedule ? <GridRescheduleAppointmentButton chatId={ ChatId } chat_type={ChatType} openModal={ rescheduleSelectedAppointment }/> : null}</td>
      <td style={{paddingLeft: "25px"}}>{CanCancel ? <GridCancelAppointmentButton chatId={ ChatId } openModal={ cancelSelectedAppointment } /> : null}</td>
    </tr>
  );
};

export default ManagerViewTableRow;

ManagerViewTableRow.propTypes = {
  AppointmentDate: PropTypes.string,
  AppointmentId: PropTypes.string,
  StartTime: PropTypes.string,
  EndTime: PropTypes.string,
  CoachName: PropTypes.string,
  CoachId: PropTypes.string,
  CompanyName: PropTypes.string,
  CompanyId: PropTypes.string,
  ChatType: PropTypes.string,
  PatientName: PropTypes.string,
  PatientId: PropTypes.string,
  Topic: PropTypes.string,
  Status: PropTypes.string,
  CanReschedule: PropTypes.bool,
  CanCancel: PropTypes.bool,
  DurationInMinutes: PropTypes.number,
  ChatId: PropTypes.string
};
