import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";
import SearchableDropdown from "components/searchable-dropdown";
import PageHeader from "components/ui/page-header";
import AlertBar from "components/ui/alert_bar";
import { Waiting } from "components/ui";
import ImportResults from "../../admin-tools/import-results";
import HasRolePermission from "components/shared/has-role-permission";
import { FulfillmentInventoryContext } from "../../fulfillment-inventory/fulfillment-inventory-provider";
import { InventoryContext } from "../inventory-provider";

const InventoryImportForm = ({companies, inventoryItems, fulfillmentCompanies}) => {
  const {
    companyIsFetching,
    companyInventoryFailures,
    submitCompanyInventoryImport,
    xrefIsFetching,
    xrefInventoryFailures,
    submitCompanyXrefInventoryImport,
    masterIsFetching,
    masterInventoryFailures,
    submitMasterInventoryImport
  } = useContext(InventoryContext);

  const {
    fulfillmentIsFetching,
    fulfillmentInventoryFailures,
    submitFulfillmentInventoryImport
  } = useContext(FulfillmentInventoryContext);

  const defaultState = {
    company_id: "",
    id_check: true,
    initial_import: false,
    filesInput: null,
    filesInputKey: Date.now()
  };
  const [ state, setState ] = useState(defaultState);
  const [ failures, setFailures ] = useState([]);
  const searchableCompaniesOptions = companies?.map(x => ({ text: x.text, value: x.value, key: x.value }));
  const searchableInventoryOptions = inventoryItems?.map(x => ({ text: x.name, value: x.id, key: x.value }));

  const url = window.location.href;
  const _importerType = url?.split("/")?.pop()?.split("-")[0];
  const importerType = _importerType.charAt(0).toUpperCase() + _importerType.slice(1);
  let _warningMessage = "WARNING: ";
  if (_importerType === "company") {
    _warningMessage += "This importer is intended to be used with a file that contains crosswalk information for multiple inventory items ";
    _warningMessage += "for a single company which matches the company that is selected by the dropdown. ";
    _warningMessage += "From any company profile's inventory table view, the 'Export Inventory to Excel File' button will generate the file corresponding to this importer.";
  } else if (_importerType === "fulfillment") {
    _warningMessage += "This importer is intended to be used with a file that contains crosswalk information for a multiple inventory items ";
    _warningMessage += " for a single fulfillment company which matches the fulfillment company that is selected by the dropdown. ";
    _warningMessage += "From the fulfillment inventory table view, the 'Export {Company Name} Inventory' button will generate the file corresponding to this importer.";
  } else if (_importerType === "crosswalk") {
    _warningMessage += "This importer is intended to be used with a file that contains crosswalk information for multiple companies ";
    _warningMessage += "for a single inventory item which matches the inventory item that is selected by the dropdown. ";
    _warningMessage += "From any master inventory profile view, the 'Export Crosswalk' button will generate the file corresponding to this importer.";
  } else {
    _warningMessage += "This importer is intended to be used with a file that contains information for multiple master inventory items. ";
    _warningMessage += "From the master inventory table view, the 'Export Inventory' button will generate the file corresponding to this importer.";
  }

  const handleChange = e => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const updateState = {...state};
    updateState[name] = value;
    setState(updateState);
  };

  const handleSearchableSelectChange = value => {
    const updateState = {...state};
    updateState.company_id = value;
    setState(updateState);
  };

  let _companyName = "";
  if (_importerType === "company") {
    _companyName = state.company_id !== ""
    ? companies.find(c => c.value === state.company_id)?.text
    : "";
  } else if (_importerType === "fulfillment") {
    _companyName = state.company_id !== ""
    ? fulfillmentCompanies.find(c => c.value === state.company_id)?.text
    : "";
  } else if (_importerType === "crosswalk") {
    _companyName = state.company_id !== ""
    ? inventoryItems.find(c => c.id === state.company_id)?.name
    : "";
  } else {
    _companyName = "master";
  }

  const onInventoryImportSubmit = (e, type) => {
    e.preventDefault();

    if ((companies === undefined || companies.length === 0) && (type === "Company" || type === "Fulfillment")) {
      window.confirm("Error, please wait for companies to finish loading before beginning import.");
      return;
    }
    if (state.filesInput.files.length === 0) {
      window.confirm("Error, no file provided.");
      return;
    }
    
    let formData = new FormData();
    formData.append("company", state.company_id);
    formData.append("file", state.filesInput.files[0]);
    formData.append("idCheck", state.id_check);
    formData.append("initialImport", false);
    formData.append("headers", true);

    const { name: fileName } = state.filesInput.files[0];
    const confirmed = window.confirm(
      `Are you sure? Importing inventory for\n ${_companyName} \n File: ${fileName}`
    );
    if (confirmed) {
      if (importerType === "Company") {
        submitCompanyInventoryImport(formData).then(() => setState(defaultState));
      } else if (importerType === "Fulfillment") {
        submitFulfillmentInventoryImport(formData);
      } else if (importerType === "Crosswalk") {
        submitCompanyXrefInventoryImport(formData).then(() => setState(defaultState));
      } else {
        submitMasterInventoryImport(formData).then(() => setState(defaultState));
      }
    }
  };

  useEffect(() => {
    if (masterInventoryFailures.length > 0) {
      setFailures(masterInventoryFailures);
    } else if (companyInventoryFailures.length > 0) {
      setFailures(companyInventoryFailures);
    } else if (fulfillmentInventoryFailures.length > 0) {
      setFailures(fulfillmentInventoryFailures);
    } else if (xrefInventoryFailures.length > 0) {
      setFailures(xrefInventoryFailures);
    } else {
      setFailures([]);
    }
  }, [masterInventoryFailures, companyInventoryFailures, fulfillmentInventoryFailures, xrefInventoryFailures])

  return (
    <div>
      <PageHeader title={`${importerType} Inventory Importer`} />
      <HasRolePermission allowedRoles={["Administrator"]}>
      <form
        encType="multipart/form-data"
        onSubmit={e => onInventoryImportSubmit(e, importerType)}
        style={{
          display: "grid",
          gridTemplateColumns: "500px",
          gridGap: 10
        }}
        >
        {importerType === "Company"
          ? <SearchableDropdown
          name="company_id"
          label="Company"
          requireSearch={true}
          selected={state.company_id}
          options={searchableCompaniesOptions}
          onSelect={handleSearchableSelectChange}
          />
          : ""
        }
        {importerType === "Crosswalk"
          ? <SearchableDropdown
          name="company_id"
          label="Company"
          requireSearch={true}
          selected={state.company_id}
          options={searchableInventoryOptions}
          onSelect={handleSearchableSelectChange}
          />
          : ""
        }
        {importerType === "Fulfillment" 
          ? <SearchableDropdown
          name="company_id"
          label="Fulfillment Company"
          requireSearch={true}
          selected={state.company_id}
          options={fulfillmentCompanies}
          onSelect={handleSearchableSelectChange}
          />
          : ""
        }
        <input
          ref={input => {
            state.filesInput = input;
          }}
          name="file"
          required
          type="file"
          style={{ fontSize: "1.05rem" }}
          key={state.filesInputKey}
          />
        <label>
          {(importerType === "Fulfillment" || importerType === "Company" || importerType === "Crosswalk") ? "Crosswalk" : "Manufacturer"} ID Check
          <input
            style={{ marginLeft: "10px" }}
            name="id_check"
            type="checkbox"
            checked={state.id_check}
            onChange={handleChange}
            />
        </label>
        <input
          className="button"
          type="submit"
          value="Submit"
          disabled={(!state.company_id && importerType === "Company") || (!state.company_id && importerType === "Fulfillment") || (state.company_id && importerType === "Master") || companyIsFetching || fulfillmentIsFetching || xrefIsFetching || masterIsFetching}
          />
      </form>
      <AlertBar color="orange" style={{width: "40%", text: "bold"}}>{_warningMessage}</AlertBar>
      </HasRolePermission>    
      <ImportResults
          results={failures}
          title={`The following ${failures.length} inventory failed`}
          fields={[{ title: "Product Number", name: "productID" }]}
        />
      {(companyIsFetching || fulfillmentIsFetching || xrefIsFetching || masterIsFetching) && (
        <div style={{ margin: "100px 0px 0px 0px" }}>
          <Waiting reason={`submitting ${_companyName} inventory`} />
        </div>
      )}
    </div>
  );
};

export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state),
  fulfillmentCompanies: selectors.getFormOptionsFulfillmentCompany(state),
  inventoryItems: selectors.getInventoryItems(state),
  userId: selectors.getUserId(state),
  role: selectors.getUserRole(state)
}))(InventoryImportForm);
  
InventoryImportForm.propTypes = {
  companies: PropTypes.array.isRequired,
  inventoryItems: PropTypes.array.isRequired,
  fulfillmentCompanies: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};