import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'
import moment from 'moment'
import Table from 'components/ui/impl/table'
import { selectors } from 'reducers'
import { mapDispatchToProps } from 'actions/order-profile'
import Header from './header'

const AccessLogs = ({ isLoading, rows }) => (
  <div>
    <Header showSpinner={isLoading}>Access Logs</Header>
    <Table
      rows={rows}
      rowKey="id"
      columns={[
        {
          header: 'Accessed By',
          field: 'accessedByName',
          format: ({ accessedByName }) => (
            accessedByName || 'null'
          )
        },
        {
          header: 'Accessed Time',
          field: 'accessedDate',
          format: ({ accessedDate }) => (
            moment(accessedDate).format("MMM DD, YYYY hh:mm A")
          )
        },
        {
          header: 'Accessed From URL',
          field: 'accessedFromURL'
        },
        {
          header: 'Accessed From IP',
          field: 'accessedFromIP'
        }
      ]}
    />
  </div>
)

export default compose(
  connect((state, props) => {
    const {
      data: rows,
      isFetching: isLoading
    } = selectors.getOrderAccessLogs(state, props)
    return {
      rows,
      isLoading
    }
  }, mapDispatchToProps),
)(AccessLogs)

AccessLogs.propTypes = {
  orderId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};
