import React, { useState } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { exportCompanyInsights } from "actions/company";
import DateRange from "components/ui/date-range";
import DateRangeContext from "./date-range-context";

const CompanyExports = ({ exportCompanyInsights, params: { id } }) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeIsValid,
    // handleSubmit,
  } = React.useContext(DateRangeContext);

  const [ordersPayload, setOrdersPayload] = useState({});
  const [contactsPayload, setContactsPayload] = useState({});

  const toggleFieldInPayload = (payload, field) => {
    return { ...payload, [field]: !payload[field] };
  };

  const toggleFieldInOrdersPayload = (field) => {
    setOrdersPayload(toggleFieldInPayload(ordersPayload, field));
  };

  const toggleFieldInContactsPayload = (field) => {
    setContactsPayload(toggleFieldInPayload(contactsPayload, field));
  };

  const constructPayload = () => {
    return {
      companyId: id,
      startDate: startDate,
      endDate: endDate,
      orders: ordersPayload,
      contacts: contactsPayload,
    };
  };

  const submitForm = (event) => {
    event.preventDefault();
    exportCompanyInsights(constructPayload());
  };

  return (
    <div>
      <div className="company-order-report-date-range">
        <DateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          isValid={dateRangeIsValid}
          isFetching={false}
          showSubmit={false}
          handleSubmit={() => {}}
        />
      </div>
      <hr/>
      <form onSubmit={submitForm}>
        <div className="row">
          <div className="small-6 columns">
            <h3>Orders</h3>
            <div>
              <input
                type="checkbox"
                name="ordersOverview"
                checked={ordersPayload.overview || false}
                onChange={() => toggleFieldInOrdersPayload("overview")}
              />
              <label htmlFor="ordersOverview">Overview</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="ordersItemTypesRequested"
                checked={ordersPayload.itemTypesRequested || false}
                onChange={() => toggleFieldInOrdersPayload("itemTypesRequested")}
              />
              <label htmlFor="ordersItemTypesRequested">
                Item Types Requested
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                name="ordersStats"
                checked={ordersPayload.orderStats || false}
                onChange={() => toggleFieldInOrdersPayload("orderStats")}
              />
              <label htmlFor="ordersStats">Orders Stats</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="ordersBySleepCoach"
                checked={ordersPayload.ordersBySleepCoach || false}
                onChange={() => toggleFieldInOrdersPayload("ordersBySleepCoach")}
              />
              <label htmlFor="ordersBySleepCoach">Orders By Sleep Coach</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="ordersByContactType"
                checked={ordersPayload.ordersByContactType || false}
                onChange={() =>
                  toggleFieldInOrdersPayload("ordersByContactType")
                }
              />
              <label htmlFor="ordersByContactType">
                Orders By Contact Type
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                name="ordersByOutreachCycle"
                checked={ordersPayload.ordersByOutreachCycle || false}
                onChange={() =>
                  toggleFieldInOrdersPayload("ordersByOutreachCycle")
                }
              />
              <label htmlFor="ordersByOutreachCycle">
                Orders By Outreach Cycle
              </label>
            </div>
          </div>
          <div className="small-6 columns">
            <h3>Contacts</h3>
            <div>
              <input
                type="checkbox"
                name="contactsOverview"
                checked={contactsPayload.overview || false}
                onChange={() => toggleFieldInContactsPayload("overview")}
              />
              <label htmlFor="contactsOverview">Overview</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="contactsEmail"
                checked={contactsPayload.email || false}
                onChange={() => toggleFieldInContactsPayload("email")}
              />
              <label htmlFor="contactsEmail">Email</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="contactsSms"
                checked={contactsPayload.sms || false}
                onChange={() => toggleFieldInContactsPayload("sms")}
              />
              <label htmlFor="contactsSms">SMS</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="contactsCall"
                checked={contactsPayload.call || false}
                onChange={() => toggleFieldInContactsPayload("call")}
              />
              <label htmlFor="contactsCall">Manual Call</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="contactsVoicemail"
                checked={contactsPayload.voicemail || false}
                onChange={() => toggleFieldInContactsPayload("voicemail")}
              />
              <label htmlFor="contactsVoicemail">Voicemail</label>
            </div>
          </div>
        </div>
        <button type="submit" style={{ marginTop: "25px" }}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default connect(() => ({}), { exportCompanyInsights })(CompanyExports);

CompanyExports.propTypes = {
  exportCompanyInsights: PropTypes.func.isRequired,
  params: PropTypes.shape({ id: PropTypes.string.isRequired })
};
