import React from "react";
import PropTypes from "prop-types";
import AudioPlayer from "components/ui/audio-player";
import { get } from "utils/api";
import { useFetch } from "hooks";
import FaDownload from "react-icons/lib/fa/cloud-download";

const AWSVmailRecording = ({ id, ...props }) => {
  const { response: uri, fetchData } = useFetch({
    apiFn: crID => get(`callrecordings/${crID}/uri`),
    defaultValue: ""
  });

  React.useEffect(() => {
    fetchData(id);
  }, [id]);

  return uri ? (
    <div>
      <table>
        <tr>
          <td>
            <AudioPlayer
              preloadAudio={"none"}
              src={uri}
              showAllFeatures={false}
              {...props}
            />
          </td>
          <td>
            <a href={uri} target="_new" download="callrecording.mp3">
              <FaDownload />
            </a>
          </td>
        </tr>
      </table>
    </div>
  ) : (
    "N/A"
  );
};

export default AWSVmailRecording;

AWSVmailRecording.propTypes = {
  twilio_link: PropTypes.string,
  duration: PropTypes.number,
  id: PropTypes.string
};
