/**
 * For historical reasons, this module uses Immutable.js, as many
 * parts of the code expect it to be at time of writing. The newly
 * added selector, however, will transform it to regular JS. New code
 * should use it to be consistent with the Immutable.js phase-out.
 */
import { Types as AuthTypes } from "actions/authentication";
import { createSelector } from "reselect";
import * as R from "ramda";
import { fromJS } from "immutable";
import { isUserTokenExpired } from "utils/misc";
import { createReducer } from "utils/reducer";

export default createReducer(undefined, {
  [AuthTypes.STORE_USER_EMAIL]: (state, action) => fromJS(action.email),
  [AuthTypes.LOAD_STORED_USER]: (state, action) => {
    // don't know why it was originally coded this way to handle error in reducer
    // i believe it is also handled in saga
    if (!action.error) {
      return fromJS(action.payload.user);
    } else {
      return state;
    }
  },
  [AuthTypes.LOGIN_USER_RESPONSE]: (state, action) => {
    if (!action.error) {
      return fromJS(action.payload.user);
    } else {
      return state;
    }
  },
  SET_USER_SETTINGS: (state, { payload }) =>
    state.mergeDeep(
      R.pick(
        ["outboundCallRestricted", "block_autodialer_incoming_calls"],
        R.reject(R.isNil, payload)
      )
    ),
  USER_SETTINGS_UPDATE_EVENT: (state, { payload }) =>
    state.mergeDeep(
      R.pick(
        [
          "outboundCallRestricted",
          "block_autodialer_incoming_calls",
          "MFACellNumber",
          "MFACellNumberVerified"
        ],
        R.reject(R.isNil, payload)
      )
    )
});

const getUser = createSelector([state => state.get("user")], user =>
  user ? user.toJS() : {}
);
const getUserRole = createSelector([getUser], R.prop("role"));

const getUserOffshore = createSelector(
  [getUser],
  R.prop("contracted_employee")
);

const getIsS3User = createSelector(
  [getUserRole],
  R.anyPass([
    R.equals("SleepCoach"),
    R.equals("ComplianceCoach"),
    R.equals("Administrator")
  ])
);
//reselect selectors, without memoized selectors user selectors caused several unneccessary rerenders.
export const selectors = {
  getUser,
  getUserRole,
  getUserOffshore,
  getIsS3User,
  getUserName: createSelector(
    [getUser],
    user => `${R.prop("first_name", user)} ${R.prop("last_name", user)}`
  ),
  getUserId: createSelector([getUser], R.prop("id")),
  getUserTeam: createSelector([getUser], R.prop("team_name")),
  isUserExpired: createSelector([getUser], isUserTokenExpired),
  getUserCanMakeCalls: createSelector([getUser], R.prop("canMakeCalls")),
  getUserPhoneNumber: createSelector([getUser], R.path(["twilio", "from"])),
  getTwilioToken: createSelector([getUser], R.path(["twilio", "twilioToken"])),
  getUserAppPermissions: createSelector(
    [getUser],
    R.propOr({}, "app_permissions")
  ),
  getUserIsTextChatCapable: createSelector(
    [getUser],
    R.prop("text_chat_capable")
  ),
  getUserSpeaksSpanish: createSelector([getUser], R.prop("speaks_spanish")),
  getIsOutboundCallRestricted: createSelector(
    [getUser],
    R.prop("outboundCallRestricted")
  ),
  getIsDialerInboundBlocked: createSelector(
    [getUser],
    R.prop("block_autodialer_incoming_calls")
  ),
  getContractedEmployee: createSelector(
    [getUser],
    R.prop("contracted_employee")
  )
};
