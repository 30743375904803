import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PureModal from "components/ui/modal/pure-modal";
import { selectors } from "reducers";

const PatientActiveCheckbox = ({
  value,
  companyId,
  inactivationReasons,
  rotechInactivationReasons,
  updatePatientForm
}) => {
  const [deactivationReason, setDeactivationReason] = useState("");
  const isRotechPatient = companyId === "7e2c459648bf44b8a2690a174e1f2930";
  return (
    <label className="patient-form-input patient-form-checkbox">
      <div>Account is Active</div>
      {value ? (
        <PureModal
          renderTrigger={({ openModal }) => (
            <input
              type="checkbox"
              checked={value}
              onClick={openModal}
              readOnly
            />
          )}
          renderContent={({ closeModal }) => (
            <div style={{ padding: 5 }}>
              <h3>Deactivation Reason</h3>
              <div>
                <select
                  value={deactivationReason}
                  onChange={({ target }) => setDeactivationReason(target.value)}
                >
                  <option />
                  {isRotechPatient
                    ? rotechInactivationReasons.map(({ value, text }) => (
                        <option key={value} value={value}>
                          {text}
                        </option>
                      ))
                    : inactivationReasons.map(({ value, text }) => (
                        <option key={value} value={value}>
                          {text}
                        </option>
                      ))}
                </select>
                <button
                  disabled={!deactivationReason}
                  onClick={() => {
                    updatePatientForm("inactiveReasonId", deactivationReason);
                    updatePatientForm("active", false);
                    closeModal();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        />
      ) : (
        <input
          type="checkbox"
          checked={value || false}
          onChange={({ target }) => {
            updatePatientForm("active", target.checked);
            updatePatientForm("inactiveReasonId", "");
          }}
        />
      )}
    </label>
  );
};
PatientActiveCheckbox.propTypes = {
  value: PropTypes.bool,
  updatePatientForm: PropTypes.func.isRequired,
  companyId: PropTypes.string,
  rotechInactivationReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  inactivationReasons: PropTypes.arrayOf(PropTypes.object)
};
export default connect(state => ({
  inactivationReasons: selectors.getEnumInactivationReasons(state),
  rotechInactivationReasons: selectors.getEnumRotechInactivationReasons(state)
}))(PatientActiveCheckbox);
