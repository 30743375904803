import React from "react";
import PropTypes from "prop-types";
import MdComment from "react-icons/lib/md/comment";
import { Rows } from "components/ui";
import { formatDate } from "utils/dates";
import AddTaskNote from "features/tasks/add-task-note";

const TaskComments = ({ task, createTaskNote, patientId }) => {
  return (
    <div style={{ display: "grid", gridTemplateRows: "1fr auto" }}>
      <Rows>
        {task.notes?.map(({ id, text, created_by_name, created_at }) => (
          <div
            className="worklist-item note"
            key={id}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
              gridGap: "10px",
              fontSize: "1.15em"
            }}
          >
            <span
              className="order-note"
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
                gridGap: "10px",
                padding: "0 0px",
                margin: "0 5px",
                color: "#444"
              }}
            >
              <MdComment />
              <span
                className="note-content"
                style={{
                  padding: "0 7px",
                  marginRight: "10px",
                  color: "black"
                }}
              >
                {text}
              </span>
            </span>
            <div
              className="worklist-item-meta"
              style={{
                flex: "1 1 auto",
                fontSize: "0.8em",
                textAlign: "right",
                marginLeft: "10px",
                marginRight: "10px"
              }}
            >
              <div className="creator">{created_by_name}</div>
              <div>{formatDate(created_at, "MMMM Do YYYY, h:mm a")}</div>
            </div>
          </div>
        ))}
      </Rows>
      <div
        style={{
          padding: "0.5em"
        }}
      >
        <AddTaskNote
          task={task}
          patientId={patientId}
          createTaskNote={async values => {
            await createTaskNote(values);
          }}
        />
      </div>
    </div>
  );
};

TaskComments.propTypes = {
  task: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  createTaskNote: PropTypes.func.isRequired
};

export default TaskComments;
