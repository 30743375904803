import { fromJS } from "immutable";

const table = (state, action) => {
  if (action.response && action.response.tablePagination) {
    return state.mergeDeep(fromJS(action.response.tablePagination));
  }
  return state;
};

export default table;
