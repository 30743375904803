import React from "react";
import PropTypes from "prop-types";
import { TimeSlotContext } from "./use-availabilityScheduler";
import { gridPosTodate } from "./scheduler-helpers";
import moment from "moment";


const TIME_SLOTS = Array.from(Array(60).keys());

const TimeSlot = React.memo(({ row, col, week, timeZone }) => {
  const isBeforeToday = day => moment(day, "MM/DD/YYYY").isBefore(moment().tz(timeZone));
  const { selected } = React.useContext(TimeSlotContext);
  const active = selected.col === col;
  const isSelected = selected.row1 === row && selected.col === col;
  const disabled = isBeforeToday(gridPosTodate(row, col, week, timeZone));
  return (
    <div
      style={{
        gridRow: row + 1
      }}
      className={`time-slot ${active ? "selected" : ""} ${
        isSelected ? "initial-row" : ""
      } ${disabled ? "disabled" : ""}`}
    />
  );
});

TimeSlot.propTypes = {
  row: PropTypes.number.isRequired,
  col: PropTypes.number.isRequired,
  week: PropTypes.number.isRequired,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

const TimeSlots = React.memo(({ week, timeZone }) =>
  Array.from(Array(7).keys()).map(col =>
    TIME_SLOTS.map(row => (
      <TimeSlot key={`${col}-${row}`} row={row} col={col} week={week} timeZone={timeZone} />
    ))
  )
);

TimeSlots.propTypes = {
  startRow: PropTypes.number,
  week: PropTypes.number.isRequired,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default TimeSlots;
