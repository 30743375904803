import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IVRWorkTable from "components/ivr-control-center/ivr-work-table";
import {
  queryInit,
  updateQuery,
  setFilters,
  resetQueryApiAndParams
} from "../../features/query/actions";
import { PageHeader } from "components/ui";
import OrderProfile from "../order-profile/order-profile";
import PatientProfile from "components/screens/patient/patient-profile";
import JobsProfile from "./IVR-jobs-profile";

class IVRWorkTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      orderId: "",
      patientId: "",
      patientGuidForJobs: "",
      patientProfileTabName: ""
    };
    this.openTab = this.openTab.bind(this);
    this.loadOrder = this.loadOrder.bind(this);
    this.loadPatient = this.loadPatient.bind(this);
    this.loadJobs = this.loadJobs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeTab = this.closeTab.bind(this);
  }

  componentWillUnmount() {
    this.setState({
      tabIndex: 0,
      orderId: "",
      patientId: "",
      patientGuidForJobs: ""
    });
  }

  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  openTab(tabIndex) {
    this.setState({
      ...this.state,
      tabIndex: tabIndex
    });
  }

  loadJobs(patientGuidForJobs) {
    let queryId = "IVRPatientJobs";

    if (this.state.patientGuidForJobs == "") {
      this.setState({
        ...this.state,
        patientGuidForJobs: patientGuidForJobs,
        tabIndex: 1
      });
    } else {
      this.setState(
        {
          ...this.state,
          patientGuidForJobs: patientGuidForJobs,
          tabIndex: 1
        },
        () =>
          this.props.dispatch(
            setFilters(queryId)("patient_guid", patientGuidForJobs)
          )
      );
    }
  }

  loadOrder(orderId) {
    this.setState({
      ...this.state,
      orderId: orderId,
      tabIndex: 2
    });
  }

  loadPatient(patientId) {
    this.setState({
      ...this.state,
      patientId: patientId,
      tabIndex: 3
    });
  }

  closeTab(e, name, tab) {
    e.stopPropagation();
    let currentTab = this.state.tabIndex;
    let nextTab = currentTab == tab ? 0 : currentTab;
    this.setState({
      ...this.state,
      [name]: "",
      tabIndex: nextTab
    });
  }

  render() {
    const { tabIndex, orderId, patientId, patientGuidForJobs } = this.state;

    const { patients, ivrWorkTableInfo } = this.props;
    let patientNameForPatientProfileTab =
      patients &&
      patients.getIn([patientId]) &&
      patients.getIn([patientId, "name"]);
    let patientNameForPatientJobsTab =
      ivrWorkTableInfo &&
      ivrWorkTableInfo.getIn([patientGuidForJobs]) &&
      ivrWorkTableInfo.getIn([patientGuidForJobs, "patientName"]);

    return (
      <React.Fragment>
        <div>
          <PageHeader title="IVR Work" />
          <div className="s3Tab">
            <span
              className={tabIndex === 0 ? "s3TabLinkActive" : "s3TabLink"}
              onClick={() => this.openTab(0)}
            >
              IVR Work Table
            </span>

            {patientGuidForJobs && (
              <span
                className={tabIndex === 1 ? "s3TabLinkActive" : "s3TabLink"}
                onClick={() => this.openTab(1)}
              >
                {patientNameForPatientJobsTab
                  ? patientNameForPatientJobsTab + "'s " + "Jobs"
                  : "Patient Jobs"}
              </span>
            )}

            {orderId != "" && (
              <span
                title={orderId}
                className={tabIndex === 2 ? "s3TabLinkActive" : "s3TabLink"}
                onClick={() => this.openTab(2)}
              >
                Order Profile
                <span
                  title={"Click To Close Tab"}
                  onClick={e => this.closeTab(e, "orderId", 2)}
                >
                  {" "}
                  X
                </span>
              </span>
            )}

            {patientId != "" && (
              <span
                title={patientId}
                className={tabIndex === 3 ? "s3TabLinkActive" : "s3TabLink"}
                onClick={() => this.openTab(3)}
              >
                {patientNameForPatientProfileTab
                  ? patientNameForPatientProfileTab
                  : "Patient Profile"}
                <span
                  title={"Click To Close Tab"}
                  onClick={e => this.closeTab(e, "patientId", 3)}
                >
                  {" "}
                  X
                </span>
              </span>
            )}
          </div>
          {tabIndex === 0 && (
            <div
              className={tabIndex === 0 ? "s3TabContent" : "s3TabContentHidden"}
            >
              <IVRWorkTable
                queryId="IVRWorkByPatient"
                apiId="IVRWorkByPatient"
                loadOrder={this.loadOrder}
                loadPatient={this.loadPatient}
                loadJobs={this.loadJobs}
              />
            </div>
          )}
          {patientGuidForJobs != "" && (
            <div
              className={tabIndex === 1 ? "s3TabContent" : "s3TabContentHidden"}
            >
              <JobsProfile
                patientGuidForJobs={patientGuidForJobs}
                loadOrder={this.loadOrder}
                loadPatient={this.loadPatient}
                orderId={orderId}
              />
            </div>
          )}
          {orderId != "" && tabIndex === 2 && (
            <div
              className={tabIndex === 2 ? "s3TabContent" : "s3TabContentHidden"}
            >
              <OrderProfile orderId={this.state.orderId} />
            </div>
          )}
          {patientId != "" && tabIndex === 3 && (
            <div
              className={tabIndex === 3 ? "s3TabContent" : "s3TabContentHidden"}
            >
              <PatientProfile patientId={patientId} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    query: state.getIn(["query"]),
    patients: state.getIn(["entities", "patients"]),
    ivrWorkTableInfo: state.getIn(["entities", "ivrWork"])
  }),
  dispatch =>
    bindActionCreators(
      {
        queryInit,
        updateQuery,
        resetQueryApiAndParams,
        dispatch
      },
      dispatch
    )
)(IVRWorkTableContainer);

IVRWorkTableContainer.propTypes = {
  updateQuery: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  patients: PropTypes.object,
  ivrWorkTableInfo: PropTypes.object
};
