import { GET_SERVICE } from './../ActionTypes';

const initialState = {
    Services: []
}

const reducerServices = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE:
            return {
                ...state,
                Services: action.payload
            };
        default:
            return state;
    }
}

export default reducerServices;
