import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";
import IoIosInfoCircleOutline from "react-icons/lib/io/ios-information";
import { Tooltip } from "components/ui/tool-tip/tooltip";

export const SingleStatCard = ({ value, title, subtitle, tooltipText, classProp, isCompliantPatientsCard, onProgressBarClicked, isComplianceDashboard }) => {
  const style = isComplianceDashboard ? { justifyContent:'space-around' ,fontWeight: 'bold', color: 'rgb(134, 135, 134)'} : {};
  return (
    <>
      {isCompliantPatientsCard ? (
        <CompliantPatientCard value={value} classProp={classProp} onProgressBarClicked={onProgressBarClicked} />
      ) : (
        <div className={`${classProp}`} style={{ ...S.base, ...style}}>
          <div style={S.title}>
            <span>{title}</span>
            {tooltipText && (
              <span style={S.infoCircle}>
                <Tooltip id={`single-stat-tooltip-${title}`} className="single-stat-tooltip" effect="solid" message={tooltipText}>
                  <IoIosInfoCircleOutline size="1rem" />
                </Tooltip>
              </span>
            )}
          </div>
          <div style={S.subtitle}><span>{subtitle}</span></div>
          <div style={S.value}>
            <span title={value}>{value ? value.toLocaleString() : 0}</span>
          </div>
        </div>
      )}
    </>


  );
};

const CompliantPatientCard = ({ value, classProp, onProgressBarClicked }) => {
  const { count, countNotCompliant } = value
  const newPercentage = Math.floor((count / (count + countNotCompliant)) * 100)
  return (
    <div style={{ ...S.complianceCard }} className={`${classProp}`}>
      <div style={{ display: "flex", justifyContent: "space-between", color: "black" }}>
        <div style={{ ...S.title }}>
          <h4 style={{ ...S.ccTitle }}>Compliant Patients</h4>
          <h2 style={{ ...S.ccTitle }}>{count ? count.toLocaleString() : 0}</h2>
        </div>
        <div style={{ ...S.title }}>
          <h4 style={{ ...S.ccTitle }}>Not compliant Patients</h4>
          <h2 style={{ ...S.ccTitle }}>{countNotCompliant ? countNotCompliant.toLocaleString() : 0}</h2>
        </div>
      </div>
      {!(count == 0 && countNotCompliant == 0) &&
        <PercentageBar percentage={newPercentage} onClick={onProgressBarClicked} />
      }
    </div>


  )
}
const PercentageBar = ({ percentage, onClick }) => {
  return (
    <>
      <div style={{ width: '100%', color: "white", textAlign: "center" }}> {/*overflow: 'hidden'*/}
        <div style={{ height: '20px', backgroundColor: '#00bfff', display: 'flex', alignItems: "center" }}>

          <div onClick={() => onClick(true)} style={{ height: '20px', flex: percentage, backgroundColor: '#3182bd', cursor: 'pointer' }}>
            <span>{`${percentage}%`}</span>
          </div>

          {
            percentage < 100 &&
            <div onClick={() => onClick(false)} style={{ flex: 100 - percentage, alignItems: "center", cursor: 'pointer' }}>
              <span>{`${100 - percentage}%`}</span>
            </div>
          }

        </div>
      </div>
      {/* Color specifiers */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {["Compliant", "Not Compliant"].map((tag, i) => {
          const color = tag == "Compliant" ? "#3182bd" : "#00bfff";
          return (
            <div key={i} style={{ display: "flex", justifyContent: "space-between", paddingTop: "4px", gap: "2px", alignItems: "center" }}>
              <div style={{ backgroundColor: color, width: '10px', height: '10px' }}></div>
              <span style={{ top: "-5px" }}>{tag}</span>
            </div>
          )

        })}
      </div>
    </>

  );
}

export default Radium(SingleStatCard);

SingleStatCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.string,
  tooltipText: PropTypes.string,
  classProp: PropTypes.string,
  isCompliantPatientsCard: PropTypes.bool,
  onProgressBarClicked: PropTypes.func,
  isComplianceDashboard: PropTypes.bool,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,
  styles: PropTypes.object
};

SingleStatCard.defaultProps = {
  styles: {},
  value: 0
};

CompliantPatientCard.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    classProp: PropTypes.string,
    onProgressBarClicked: PropTypes.func
};

PercentageBar.propTypes = {
    percentage: PropTypes.number,
    onClick: PropTypes.func,
};
const S = styler`
  base
    width: 100%
    text-align: center
    border: 2px solid lightgray
    font-size: 1rem
    border-radius: 7px
    padding: 0 1rem
    backgroundColor: white
    color: rgb(102, 102, 102)
    display: flex
    flex-direction: column
    justify-content: space-between
  infoCircle
    cursor: pointer
    position: relative;
    left: 0.25rem;
    bottom: 0.5rem;
  value
    font-size: 2.8em
    display: flex
    justify-content: center
    padding: 0.5rem
    overflow: hidden
    text-overflow: ellipsis    
  subLabel
    font-size: 1.35em
    color: #f2f2f2
    display: flex
    justify-content: center
    padding: 0 5px
    overflow: hidden
    text-overflow: ellipsis
  title
    font-weight: bold;
    margin-top: 0.75rem;
    font-size: 1.5rem;
    color: rgb(134, 135, 134);
    padding: 0 0.40rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  subtitle
    margin-top: -0.15rem;
    font-size: 1.2rem;
    color: rgb(134, 135, 134);
    padding: 0 0.40rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    height: 20px
  complianceCard
    width: 100%
    text-align: center
    font-size: 1rem
    border: 2px solid lightgray
    border-radius: 7px
    padding: 0px 1em
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: space-between
    background-color:white
  ccTitle
    font-weight: bold 
    margin: 0
    color: rgb(134, 135, 134);
    text-wrap:balance    
`;
