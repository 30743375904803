import React from "react";
import useSignalr from "features/signalR/use-signalr";
import {
  getOnlineUsers,
  joinGroup,
  leaveGroup
} from "features/signalR/actions";
import { useDispatch } from "react-redux";
import { fetchCallSnapshot } from "actions/phone-dashboard";
import CurrentPhoneStats from "./current-phone-stats";
import CallInsightsChart from "./call-insights-chart";
import useDateRange from "hooks/useDateRange";
// import DateRange from "components/ui/date-range";
import { PageHeader } from "components/ui";
import { today } from "utils/dates";

const PhoneDashboard = () => {
  useSignalr(
    [joinGroup("ClientStatusListener"), getOnlineUsers()],
    [leaveGroup("ClientStatusListener")]
  );
  const dispatch = useDispatch();
  const {
    startDate,
    endDate
    // setStartDate,
    // setEndDate,
    // dateRangeIsValid
  } = useDateRange({
    startDate: today(),
    endDate: today()
  });
  const [, setIsFetching] = React.useState(false);
  const requestPhoneInsights = async (startDate, endDate) => {
    try {
      setIsFetching(true);
      await dispatch(fetchCallSnapshot(startDate, endDate));
    } finally {
      setIsFetching(false);
    }
  };
  React.useEffect(() => {
    requestPhoneInsights(startDate, endDate);
  }, []);
  return (
    <div className="phone-dashboard-container">
      <PageHeader title="Phone Insights">
        <div className="phone-dashboard-header">
          {/* <DateRange */}
          {/*   startDate={startDate} */}
          {/*   endDate={endDate} */}
          {/*   setStartDate={setStartDate} */}
          {/*   setEndDate={setEndDate} */}
          {/*   handleSubmit={(startDate, endDate) => { */}
          {/*     requestPhoneInsights(startDate, endDate); */}
          {/*   }} */}
          {/*   isValid={dateRangeIsValid} */}
          {/*   isFetching={isFetching} */}
          {/*   disabled */}
          {/* /> */}
        </div>
      </PageHeader>
      <CurrentPhoneStats />
      <CallInsightsChart />
    </div>
  );
};

export default PhoneDashboard;
