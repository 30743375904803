import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import RadioGroupField from "components/forms/radio-group-field";
import FormikField from "components/forms/formik-field";
import { STATES } from "utils/enum";
import { isRequired, isValidZip } from "utils/redux-form";
import { PatientProfileContext } from "components/profile/context";

const ShippingDetails = ({ values, setFieldValue, address }) => {
  React.useEffect(() => {
    if (values.changed_address === false) {
      setFieldValue("address", address);
    }
  }, [values.changed_address]);

  const { company_order_settings } = useContext(PatientProfileContext);
  return (
    <div>
      <h3>Shipping Information</h3>
      <div className="order-form-prompt">
        <Field
          component={RadioGroupField}
          name="changed_address"
          label="My address has changed or what we have on file is incorrect."
          value={values.changed_address}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 10
        }}
      >
        <FormikField
          label="Street Address"
          name="address.street_address"
          type="text"
          validate={isRequired}
          disabled={!values.changed_address}
        />
        <FormikField
          label="Street Address Line Two"
          name="address.street_address2"
          type="text"
          disabled={!values.changed_address}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridGap: 10
        }}
      >
        <FormikField
          label="City"
          name="address.city"
          type="text"
          validate={isRequired}
          disabled={!values.changed_address}
        />
        <FormikField
          label="State"
          name="address.state"
          component="select"
          validate={isRequired}
          disabled={!values.changed_address}
        >
          <option />
          {STATES.map(({ value, text }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </FormikField>
        <FormikField
          label="Zip"
          name="address.zip"
          type="text"
          validate={v => isRequired(v) || isValidZip(v)}
          disabled={!values.changed_address}
        />
      </div>
      <div>
        {values.changed_address && (
          <Field
            component={RadioGroupField}
            name="address_change_type"
            label=""
            value={values.address_change_type}
            options={[
              { value: "P", text: "Permanent Address Change" },
              { value: "T", text: "Temporary Address Change" }
            ]}
          />
        )}
      </div>
      <hr />
      {company_order_settings.office_pickup && (
        <Field
          component={RadioGroupField}
          name="office_pickup"
          label="Patient would like to pick up their order from the office:"
          value={values.changed_address}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
        />
      )}
      {company_order_settings.person_placing_order && (
        <Field
          component={RadioGroupField}
          name="ordered_by"
          label="Person placing this order is a:"
          value={values.ordered_by}
          options={[
            { value: "patient", text: "Patient" },
            { value: "caregiver", text: "Caregiver" }
          ]}
        />
      )}
      {values.ordered_by === "caregiver" && (
        <div className="order-form-section">
          <FormikField
            name="caregiver_name"
            label="Name of caregiver placing this order:"
            type="text"
            validate={v =>
              values.ordered_by === "caregiver" ? isRequired(v) : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

export default ShippingDetails;

ShippingDetails.propTypes = {
  values: PropTypes.shape({
    address: PropTypes.object,
    changed_address: PropTypes.bool,
    address_change_type: PropTypes.string,
    ordered_by: PropTypes.string
  }),
  setFieldValue: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired
};
