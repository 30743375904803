import React from "react";
import PropTypes from "prop-types";
import MdPriorityHigh from "react-icons/lib/md/priority-high";

const PriorityBtn = ({ togglePriority, high_priority }) => (
  <span
    className={`copy-to-clipboard-button print-none`}
    title={`${high_priority ? "High" : "Low"} Priority`}
    onClick={togglePriority}
  >
    <MdPriorityHigh size="30px" color={high_priority ? "red " : "grey"} />
  </span>
);

PriorityBtn.propTypes = {
  togglePriority: PropTypes.func.isRequired,
  high_priority: PropTypes.bool
};

export default PriorityBtn;
