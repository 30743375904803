import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import * as R from 'ramda'
import { compose } from 'recompose'
import {
  withQuery,
  withTableHandlers,
  withApi,
  withHeaderFooter
} from 'features/query'
import withBatch from 'features/batch'
import { selectors } from 'reducers'
import Table from 'components/ui/impl/table'
import VmailRecording from "./vmail-recording";
import { formatPhone } from "utils/misc";
import styler from "react-styling";
import Icons from "./icons";

const formatDate = date => date ? moment(date).format('MM / DD / YYYY -  h:mm:ss a ') : '';

const VoicemailTable = compose(
  connect((state, { queryId, apiId}) => ({
    queryId: queryId,
    initialQuery: {
      sort: {
        by: 'vmID',
        direction: 'desc'
      },
      pagination: {
        per: 15,
        page: 1
      },
      filters: {flags: "d"},
      searches: {}
    },
    apiId: apiId,   
  })),
  //This begins the other HOCs
  withQuery(),
  withApi({
    api: {
      route: 'voicemails',
      responseKey: 'voicemails',
      idKey: 'id',
      entityKey: 'voicemailRows'
    }    
  }),  
  withBatch({ recordIdKey: 'id' }), 
  connect((state, {
    batchColumn,
    markedForBatch,
    batchClear, 
    onAdminSelectChange,
    user,
    team_names,
    filteredSleepCoaches,
    vmailQuery,
    query,
    clearAllSearches
  }) => {
    //get all state from parent passed down as props
    const isUserAdmin = user.get("role") == "Administrator";
    const filteredVoipAdmins = selectors.getPhoneBook(state).filter(user => user.type == "Administrator");
    const voipAdminsOptions = filteredVoipAdmins.map((user) => ({ text: `${user.first_name} ${user.last_name}`, value: user.id }))
    
    return ({    
      columns: R.filter(R.is(Object), [ /* eslint-disable react/prop-types */
        isUserAdmin && batchColumn,
        {
          header: 'Audio',
          field: 'twilio_link',
          format: ({ id }) => <VmailRecording  id={id} />
        },
        {
          header: 'Flags',
          field: 'flags',
          format: ({ listened_to, worked, assigned ,sleepCoachAssigned, worked_by, listened_by }) => (            
            <React.Fragment>            
              <span
                style={{ paddingRight: 5, display: listened_to ? "" : "none" }}
                title={"Listened By " +
                  listened_by.first_name + " " +
                  listened_by.last_name}>
                <Icons.Headset
                  size={"14"}
                  color={"green"} />
              </span>
              <span
                style={{ display: assigned ? "" : "none" }}
                title={"Assigned To " +
                  sleepCoachAssigned.first_name + " " +
                  sleepCoachAssigned.last_name}>
                <Icons.Person
                  size={"14"}
                  color={"green"} />
              </span>
              <span
                style={{ display: worked ? "" : "none" }}
                title={"Worked By " +
                  worked_by.first_name + " " +
                  worked_by.last_name}>
                <Icons.Hammer
                  size={"14"}
                  color={"green"} />
              </span>
            </React.Fragment>
      ),
          filters: [         
            {
              text: 'Listened',
              value: 'a'
            }, 
            {
              text: 'Unlistened',
              value: 'b'
            },
            {
              text: 'Worked',
              value: 'c'
            },
            {
              text: 'Not Worked',
              value: 'd'
            },
            {
              text: 'Assigned',
              value: 'e'
            },
            {
              text: 'Not Assigned',
              value: 'f'
            }            
          ],
        },
        {
          header: 'ID',
          field: 'vmID',
          format: ({ vmID, id }) => (
            <Link
              title={"Click Here To Go To Voicemail Profile"}
              style={S.link}              
              to={`/phones/voicemails/${id}`}>
              {vmID}
            </Link>
          ),
          search: {
            by: "vmID",
            value: ""
          }
        },
        {
          header: 'Date',
          field: 'created_at',
          format: ({ created_at }) => (created_at && formatDate(created_at))
        },
        {
          header: 'Team',
          field: 'calledCompanyTeam',
          format: ({ calledCompanyTeam }) => (calledCompanyTeam && calledCompanyTeam.replace('_', ' ')),
          filters:
            team_names.map((team) =>
            {
              let name = team.name.replace("_", " ");
              return (
                {
                  text: name,
                  value: team.name
                }
              )            
            }
            )         
        },
        {
          header: 'To',
          field: 'to_number',          
          format: ({ to_number, calledCompanyName }) => (
            to_number && (/[a-bA-Z]/.test(to_number) == true ?
                <span title={formatPhone(to_number)}>{formatPhone(to_number)}</span>
              : calledCompanyName ?
                  <span title={formatPhone(to_number)} > { calledCompanyName }</span>
                : <span title={formatPhone(to_number)}>{formatPhone(to_number)}</span>
            )),
        },
        {
          header: 'From',
          field: 'from_number',
          format: ({ from_number }) => (from_number && formatPhone(from_number)),
        },
        {
          header: 'Assignee',
          field: 'sleepCoachAssigned',
          format: ({ sleepCoachAssigned }) => (sleepCoachAssigned.name),
          filters: voipAdminsOptions.concat(selectors.getFormOptionsSleepCoachWithUserFirst(state)),
          search: {
            placeholder: "Search...",
            by: "sleepCoachAssigned",
            value: ""
          }
        },
        {
          header: 'Worker',
          field: 'worked_by',
          format: ({ worked_by }) => (worked_by.name),
          filters: voipAdminsOptions.concat(selectors.getFormOptionsSleepCoachWithUserFirst(state)),
          search: {
            placeholder: "Search...",
            by: "worked_by",
            value: ""
          }
        },
      ]),
      customDisplay: [         
          () => <select
            style={{ display: isUserAdmin ? "" : "none"}}
            title={"Team List To Filter Down Sleep Coach List"}
            className="small-2"
            name="team"
          onChange={(e) => onAdminSelectChange(e, markedForBatch, null, null)}
            value={vmailQuery.get("team")}
          >
            <optgroup label="Filter By Team">
              <option value="all">All Teams</option>
              {team_names.map((team, i) => {
                let name = team.name.replace("_", " ")
                return (
                  <option
                    key={i + "teams"}
                    value={team.name}>
                    {name}
                  </option>
                )
              })}
            </optgroup>
          </select>,
          () => <select
            title= "Sleep Coaches Assignment/Mark as"
            style={{ display: isUserAdmin ? "" : "none" }}
            className="small-3"
            name="sleep_coach"
            onChange={(e) => onAdminSelectChange(e, markedForBatch, {              
              apiId: 'voicemails',
              query: query,
              method: 'PUT',
              route: 'voicemails',
              responseKey: 'voicemails',
              entityKey: 'voicemailRows',
              idKey: 'id'
            }, batchClear)}
          >
            <option value ="">---Please Make Selection---</option>
            {<optgroup label="Mark Voicmail(s) as: ">              
              <option value="markAsUnlistenedAndUnworked" style={{ color: "red" }} title="Marks Voicemail(s) As Unlistened and Unworked">Reset Voicemail(s)</option>
              <option value="markAsListened" style={{ color: "green" }} title="Marks Voicemail As Listened Only (For Vociemails With 0s Durations)">Listened</option>
              <option value="markAsListenedAndWorked" style={{ color: "green" }} title="Marks Voicemail(s) As Listened And Worked (For Voicemails With Errors)">Worked</option>
            </optgroup>}
            {(filteredSleepCoaches.length > 1) && <optgroup label="Assign SleepCoaches">
              {filteredSleepCoaches && filteredSleepCoaches.map((sleepCoach, i) => (
                <option
                  key={i + "sleepCoaches"}
                  value={sleepCoach.id}>
                  {sleepCoach.first_name + " " + sleepCoach.last_name}
                </option>
              ))}
            </optgroup>}
          </select>,         
          () => markedForBatch.length > 0 ? <span style={{ color: 'white' }}>{markedForBatch.length} Voicemails Selected</span> : null,
        ],
          customButtons: [
           //create a clear all searches button
            {
              // icon: () => <IoMdCloseCircle />,//'ion-close-circle',
              title: `Clear All Searches`,
              onClick: () => {
                clearAllSearches();
                //not sure if the reducer is right
              }
            },
          ]
  })}),
  withHeaderFooter({
    perPageOptions: [15, 150, 300, 500],
    forceBatchReset: true
  }),
  withTableHandlers()
)(Table)

VoicemailTable.propTypes = {
  assignmentModeToggle: PropTypes.func,
  onAdminSelectChange: PropTypes.func,
  markVmailAs: PropTypes.func,
  assignVmailToSleepCoach: PropTypes.func,
  queryWillUpdate: PropTypes.func,
  team_names: PropTypes.array,
  filteredSleepCoaches: PropTypes.array,
  canAssign: PropTypes.bool,
  user: PropTypes.object,
  unSeenVMCount: PropTypes.number,
  query: PropTypes.object,
  allSleepCoaches: PropTypes.object
}

export default VoicemailTable

const S = styler`
  offsetComp
    right: 20
    bottom: 20
    left: 20
  td
    white-space: nowrap
  link
    display: table-cell
  worked
    opacity: 0.5
  leftFilter
    margin-right: 5
`;
