import React from "react";
import * as R from "ramda";

export const makeSortFn = sort => {
  const by = R.propOr("", "by", sort);
  const ascending = R.prop("ascending", sort);
  return R.sortWith([
    ascending
      ? R.ascend(R.pathOr("", by.split(".")))
      : R.descend(R.pathOr("", by.split(".")))
  ]);
};

const useSort = ({ by: initialSortBy, ascending: initialSortDirection }) => {
  const [sortBy, setSortBy] = React.useState(initialSortBy);
  const [ascending, setIsSortAscending] = React.useState(initialSortDirection);
  const sortFn = React.useCallback(makeSortFn({ by: sortBy, ascending }), [
    sortBy,
    ascending
  ]);

  return {
    sortBy,
    ascending,
    setSortBy: by => {
      setSortBy(by);
      setIsSortAscending(initialSortDirection);
    },
    toggleSortDirection: () => setIsSortAscending(() => !ascending),
    getSortedArray: sortFn
  };
};

export default useSort;
