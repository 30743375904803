import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";
import * as R from "ramda";
import { Types } from "actions/system-tray";

const initialState = {
  tabs: [],
  open_tab: ""
};

const systemTray = createReducer(initialState, {
  [Types.CREATE_TAB]: (state, { payload: { tab }, meta: { start_open } }) => ({
    ...state,
    tabs: [...state.tabs.filter(t => t.key !== tab.key), tab],
    open_tab: start_open ? tab.key : state.open_tab
  }),
  [Types.DESTROY_TAB]: (state, { payload: { key } }) => ({
    ...state,
    tabs: state.tabs.filter(t => t.key !== key),
  }),
  [Types.CLOSE_TAB]: state => ({
    ...state,
    open_tab: ""
  }),
  [Types.OPEN_TAB]: (state, { payload: { key } }) => ({
    ...state,
    open_tab: key
  })
});

export default systemTray;

export const selectors = {
  getTabs: createSelector(
    [state => state.getIn(["system-tray"])],
    R.propOr([], "tabs")
  ),
  getOpenTab: createSelector(
    [state => state.getIn(["system-tray"])],
    R.prop("open_tab")
  )
};
