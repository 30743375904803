import React, { Component } from "react";
import SelectInput from "components/forms/select-input";
import TextInput from "components/forms/text-input";
import { capitalize, deSnakeCase } from "utils/misc";
import { browserHistory } from "browser-history";
import uniqueId from "lodash/uniqueId";

export class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      filter: "patients",
      subFilter: "account_number",
      filterOptions: {
        patients: [
          "account_number",
          "name",
          "date_of_birth",
          "phone",
          "email",
          "insurance"
        ],
        orders: [
          "order_number",
          "company",
          "patient",
          "sleep_coach",
          "admin",
          "status",
          "submitted_date",
          "last_updated"
        ]
      }
    };
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value });
  }

  handleFilterChange({ target }) {
    this.setState({
      filter: target.value,
      subFilter: this.state.filterOptions[target.value][0]
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let subFilter = this.state.subFilter;
    let searchText = this.state.searchText.trim();

    if (searchText && searchText.length > 0) {
      this.setState({ searchText: "" });
      if (
        subFilter === "submitted_date" ||
        subFilter === "last_updated" ||
        subFilter === "updated" ||
        subFilter === "created" ||
        subFilter === "date_of_birth"
      ) {
        searchText = searchText.replace(/\//g, "-");
      }

      searchText = searchText.replace(/[#$%]/g, "");
      if (this.state.filter === "patients") {
        const searchId = uniqueId();
        browserHistory.push({
          pathname: "/patients",
          state: { status: "", searchFor: subFilter, searchText, searchId },
          key: searchId
        });
      } else
        browserHistory.push(
          `/search/${this.state.filter}/${subFilter}/${searchText}`
        );
    }
  }

  handleSearchFilter() {
    const filterOptions = Object.keys(this.state.filterOptions);
    return filterOptions.map(function (e) {
      return { value: e, text: deSnakeCase(capitalize(e)) };
    });
  }

  handleSearchSubFilter() {
    const subFilterOptions = this.state.filterOptions[this.state.filter].map(
      v => {
        return { value: v, text: deSnakeCase(capitalize(v)) };
      }
    );
    return subFilterOptions;
  }

  render() {
    return (
      <div className="search">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="search-input-field">
            <TextInput
              name="searchText"
              value={this.state.searchText}
              placeholder="Search"
              onChange={this.handleChange.bind(this)}
            />
          </div>
          <div>
            <div className="row">
              <div className="small-5 columns search-filter-field">
                <SelectInput
                  name="filter"
                  blankFirstOption={false}
                  value={this.state.filter}
                  options={this.handleSearchFilter()}
                  onChange={this.handleFilterChange.bind(this)}
                />
              </div>
              <div className="small-7 columns search-filter-field">
                <SelectInput
                  name="subFilter"
                  blankFirstOption={false}
                  value={this.state.subFilter}
                  options={this.handleSearchSubFilter()}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default SearchBox;
