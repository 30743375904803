import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { pipe } from "ramda";

const PatientTitle = ({ index, patientName }) => (
  <option value={index}>{patientName}</option>
);

PatientTitle.propTypes = {
  index: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
  patientName: PropTypes.string
};

const getPatientName = createSelector(
  [(state, patientId) => state.getIn(["entities", "patientRows", patientId])],
  patient =>
    patient
      ? pipe(({ first_name, last_name }) => `${first_name} ${last_name}`)(
          patient.toJS()
        )
      : ""
);

export default connect((state, { patientId }) => ({
  patientName: getPatientName(state, patientId)
}))(PatientTitle);
