import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { selectors } from "reducers";
import MdMinus from 'react-icons/lib/md/remove'
import {
  compose,
  setPropTypes,
  branch,
  renderNothing,
  withHandlers
} from 'recompose'
import * as R from 'ramda'

const ESCAPE_CODE = 27
const getModalRoot = () => document.getElementById('modal-root')

const Window = compose(
  setPropTypes({
    onEscape: PropTypes.func,
    onXClick: PropTypes.func,
    large: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    chatIsOpen: PropTypes.bool
  }),
  branch(R.prop('disabled'), renderNothing),
  withHandlers({
    handleKeyDown: ({ onEscape }) => event => {
      if (onEscape && event.keyCode === ESCAPE_CODE) {
        onEscape(event)
      }
    }
  }),
  connect( state => ({ 
    chatIsOpen: selectors.getChatIsOpen(state)
  }))
)(class Window extends Component {
  static propTypes = {
      children: PropTypes.node,
      onXClick: PropTypes.func,
      large: PropTypes.bool,
      handleKeyDown: PropTypes.func,
      hidden: PropTypes.bool,
      minimize: PropTypes.bool,
      chatIsOpen: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.el.className = 'modal-window'
  }

  componentDidMount() {
    getModalRoot().appendChild(this.el)
    this.container.focus()
  }

  componentWillUnmount() {
    getModalRoot().removeChild(this.el)
  }

  setContainer = node => {
    this.container = node
  }

  render() {
    const {
      children,
      onXClick,
      large,
      handleKeyDown,
      hidden,
      minimize,
      chatIsOpen
    } = this.props
    return ReactDOM.createPortal(
      <Fragment>
        <div className='modal-window-overlay' style={{ display: hidden ? 'none': 'block'}} />
        <div
          tabIndex='0'
          ref={this.setContainer}
          onKeyDown={handleKeyDown}
          className={`modal-window-content-container${large ? ' large' : ''} ${chatIsOpen ? "chat-active" : ""}`}
          style={{ display: hidden ? 'none': 'block' }}
        >
          <div className="modal-status-bar">
            { minimize ? (
                <div className="close-modal-x">
                  <MdMinus size={28} onClick={onXClick} color="black" />
                </div>
              ) : (
                <div className="close-modal-x" onClick={onXClick}>
                  &#x2715;
                </div>
            )}
          </div>
          <div className='modal-window-content'>
            {children}
          </div>
        </div>
      </Fragment>,
      this.el
    )
  }
})

export default Window
