import React from "react";
import PropTypes from "prop-types";


const ChangeLogTableRow = ({
    date_changed,
    changed_by,
    column_changed,
    old_value,
    new_value,
    origin
}) => {
    return (
        <tr>
          <td>{changed_by}</td>
          <td>{column_changed}</td>
          <td>{old_value}</td>
          <td>{new_value}</td>
          <td>{date_changed}</td>
          <td>{origin}</td>
        </tr>
      )
};
export default React.memo(ChangeLogTableRow);

ChangeLogTableRow.propTypes = {
  date_changed: PropTypes.string.isRequired,
  changed_by: PropTypes.string.isRequired,
  column_changed: PropTypes.string.isRequired,
  old_value: PropTypes.string.isRequired,
  new_value: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
};
