import React from "react";
import PropTypes from "prop-types";
import PatientsTable from "./patients-table";
import CoachStats from "./coach-stats";
import CoachOrdersTable from "./coach-dashboard-orders/table";
import ScDashboardTableSelect from "./table-select";
import { connect } from "react-redux";
import { selectors } from "reducers";
import AutodialerFilter from "./autodialer-filter";
import ActivityCenter from "./activity-center";
import PhoneNumberPrompt from "components/two-factor-auth/phone-number-prompt";

const SleepCoachDashboard = ({ userId, userRole, userTeam, user }) => {
  const [patientsToView, setPatientsToView] =
    React.useState("eligible_patients");
  return (
    userRole != "ContractorAdministrator" && (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <PhoneNumberPrompt user={user} />
        <CoachStats />
        <ScDashboardTableSelect
          patientsToView={patientsToView}
          setPatientsToView={setPatientsToView}
        />
        {patientsToView === "activity_center" && <ActivityCenter />}
        {patientsToView === "eligible_patients" && <AutodialerFilter />}
        {patientsToView === "legacy_autodialer" && (
          <PatientsTable
            initialQuery={{
              sort: {
                by: "last_name",
                direction: "asc"
              },
              pagination: {
                page: 1,
                per: 500
              },
              filters: {}
            }}
          />
        )}
        {patientsToView === "patients_with_backordered_orders" && (
          <CoachOrdersTable
            orderStatus="backordered"
            queryId="follow-up-orders-table"
            rowKey="id"
            initialQuery={{
              sort: {
                by: "submitted",
                direction: "desc"
              },
              pagination: {
                page: 1,
                per: 25
              },
              filters: {
                status: ["backordered"],
                order: ["all"],
                sleep_coach_assignment:
                  userRole == "SleepCoach" ? [userId] : [],
                team_name:
                  userRole == "SleepCoach"
                    ? [`${userTeam}^${user.team_id}`]
                    : []
              }
            }}
            apiId="follow-up-orders-table"
          />
        )}
        {patientsToView === "patients_with_followup_orders" && (
          <CoachOrdersTable
            orderStatus="follow_up"
            queryId="follow-up-orders-table"
            rowKey="id"
            initialQuery={{
              sort: {
                by: "submitted",
                direction: "desc"
              },
              pagination: {
                page: 1,
                per: 25
              },
              filters: {
                status: ["follow_up"],
                order: ["all"],
                sleep_coach_assignment:
                  userRole == "SleepCoach" ? [userId] : [],
                team_name:
                  userRole == "SleepCoach"
                    ? [`${userTeam}^${user.team_id}`]
                    : []
              }
            }}
            apiId="follow-up-orders-table"
          />
        )}
        {patientsToView === "patients_with_new_orders" && (
          <CoachOrdersTable
            orderStatus="new"
            queryId="follow-up-orders-table"
            rowKey="id"
            initialQuery={{
              sort: {
                by: "submitted",
                direction: "desc"
              },
              pagination: {
                page: 1,
                per: 25
              },
              filters: {
                // TODO  double check status
                status: ["new"],
                sleep_coach_assignment:
                  userRole == "SleepCoach" ? [userId] : [],
                team_name: userRole == "SleepCoach" ? [userTeam] : []
              }
            }}
            apiId="follow-up-orders-table"
          />
        )}
      </div>
    )
  );
};

export default connect(state => ({
  userId: selectors.getUserId(state),
  userTeam: selectors.getUserTeam(state),
  userRole: selectors.getUserRole(state),
  user: selectors.getUser(state)
}))(SleepCoachDashboard);

SleepCoachDashboard.propTypes = {
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userTeam: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};
