import {
  select,
  all,
  delay,
  takeLatest,
  actionChannel,
  cancel,
  race,
  fork,
  call,
  take,
  put
} from "redux-saga/effects";
import { Types } from "features/signalR/actions";
import { selectors } from "reducers";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Types as AuthTypes } from "actions/authentication";
import { isLogoutResponse, isUnauthorizedResponse } from "sagas/authentication";
import watchHubEvents from "./hub-events";
import watchClientEvents from "./client-events";
import lifetimeHandler from "./lifetime-handler";
import { SignalrHost, AppVersion } from "environment";
import * as Sentry from "@sentry/react";

const Reconnect_Delay = 15000;

export function* connect() {
  yield put({ type: Types.SIGNALR_CONNECTING });
  try {
    const { authentication_token } = yield select(selectors.getUser);
    if (!authentication_token) {
      throw "Not Logged In";
    }
    const hub = new HubConnectionBuilder()
      .withUrl(`${SignalrHost}/phoenix_hub?AppVersion=${AppVersion}`, {
        accessTokenFactory: () => authentication_token
      })
      .configureLogging(LogLevel.Error)
      .build();
    yield hub.start();
    yield put({ type: Types.SIGNALR_CONNECTED });
    return hub;
  } catch (error) {
    yield put({ type: Types.SIGNALR_CONNECTION_ERROR, error });
    throw Error(error);
  }
}

function* signalrInit() {
  try {
    const hub = yield call(connect);
    const hubTasks = yield all([
      fork(watchHubEvents, hub),
      fork(watchInvokeMethod, hub),
      fork(watchClientEvents)
    ]);
    const { error } = yield race({
      error: take(Types.SIGNALR_DISCONNECTED),
      stop: take([
        Types.SIGNALR_DISCONNECT,
        isLogoutResponse,
        isUnauthorizedResponse,
        AuthTypes.LOGOUT_USER_INACTIVE,
        AuthTypes.LOGIN_USER_RESPONSE
      ])
    });
    yield cancel([...hubTasks]);
    if (error) {
      yield delay(Reconnect_Delay);
      yield put({ type: Types.SIGNALR_CONNECT });
    } else {
      yield hub.stop();
    }
  } catch (error) {
    if (error.message !== "Not Logged In") {
      yield delay(Reconnect_Delay);
      yield put({ type: Types.SIGNALR_CONNECT });
    }
  }
}

export function* watchInvokeMethod(hub) {
  const requestChan = yield actionChannel(Types.SIGNALR_INVOKE_METHOD);
  while (true) {
    const { method, args = [], success, failed } = yield take(requestChan);
    try {
      const result = yield hub.invoke(method, ...args);
      if (success) yield put(success(result));
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag("Signalr", "invokeMethod");
        Sentry.captureException(error);
      });
      console.error(error.message);
      if (failed) yield put(failed(error));
    }
  }
}

function* rootSignalrSaga() {
  yield all([
    takeLatest(Types.SIGNALR_CONNECT, signalrInit),
    call(lifetimeHandler)
  ]);
}

export default rootSignalrSaga;
