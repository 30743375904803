import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUser, updateUser } from "action-creators";
import { PageHeader, Waiting } from "components/ui";
import UserForm from "components/screens/user-form";
import { withRouter } from "react-router-dom";

const getUserName = user =>
  user ? `${user.get("first_name") || ""} ${user.get("last_name") || ""}` : "";

const EditUser = ({ userId, getUser, user, updateUser }) => {
  const [isFetchingUser, setIsFetchingUser] = React.useState(false);
  const requestUserProfile = async () => {
    try {
      setIsFetchingUser(true);
      await getUser(userId);
    } finally {
      setIsFetchingUser(false);
    }
  };
  React.useEffect(() => {
    requestUserProfile();
  }, [userId]);

  return (
    <div>
      {isFetchingUser ? (
        <Waiting />
      ) : (
        <div>
          <PageHeader title={`Edit User: ${getUserName(user)}`} />
          <UserForm
          user={user ? user.toJS() : {}}
          handleSubmit={async user => {
            await updateUser({
              id: userId,
              user
            });
          }}
          />
        </div>
      )}
    </div>
  );
};

EditUser.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  userName: PropTypes.string
};

export default withRouter(
  connect(
    (state, { match: { params } }) => ({
      userId: params.id,
      user: state.getIn(["users", "profile"])
    }),
    {
      getUser,
      updateUser
    }
  )(EditUser)
);
