import { take, call, put, all } from "redux-saga/effects";
import * as api from "utils/api";
import A from "action-types";
import { errorMessage, message } from "actions/message";

export default function* root() {
    yield all([
        watchGetAllIVRQuestions(), 
        watchCreateIVRQuestion(),
        watchUpdateIVRQuestions(),
        watchToggleIVRQuestions()
    ])
}
function* watchGetAllIVRQuestions() {
    while (true) {
        const action = yield take(A.IVR.QUESTIONS.GET_ALL)
        yield call(getAllIVRQuestions, action)
    }
}

function* watchCreateIVRQuestion() {
    while (true) {
        const action = yield take(A.IVR.QUESTIONS.CREATE_QUESTION)
        yield call(createIVRQuestion, action)
    }
}

function* watchUpdateIVRQuestions() {
    while (true) {
        const action = yield take(A.IVR.QUESTIONS.UPDATE_QUESTION)
        yield call(updateIVRQuestion, action)
    }
}
function* watchToggleIVRQuestions() {
    while (true) {
        const action = yield take(A.IVR.QUESTIONS.TOGGLE_QUESTION)
        yield call(toggleIVRQuestion, action)
    }
}

export function* getAllIVRQuestions() {    
    try {       
       let response = yield call(api.get, "IVR/OrderFormQuestion/GetAll")      
        yield put(
            {
                type: A.IVR.QUESTIONS.RESPONSE_GET_ALL,
                response
            }
        )   
        //yield put(message("Success! Got Your Question"))
    } catch (payload) {
        yield put(errorMessage("Sorry something went wrong."));
    }
}

export function* createIVRQuestion(action) {
    try {
        yield call(api.post, "IVR/OrderFormQuestion/Create", action.payload)
        yield put(message("Success! Recorded Your Question"))
        let response = yield call(api.get, "IVR/OrderFormQuestion/GetAll")
        yield put(
            {
                type: A.IVR.QUESTIONS.RESPONSE_GET_ALL,
                response
            }
        )
        
    } catch (payload) {
        yield put(errorMessage("Sorry something went wrong."));
    }
}

export function* updateIVRQuestion(action) {
    try {
        yield call(api.put, "IVR/OrderFormQuestion/Update", action.payload)
        yield put(message("Success! Updated Your Question"))
        let response = yield call(api.get, "IVR/OrderFormQuestion/GetAll")      
        yield put(
            {
                type: A.IVR.QUESTIONS.RESPONSE_GET_ALL,
                response
            }
        )        
    } catch (payload) {
        yield put(errorMessage("Sorry something went wrong."));
    }
}

export function* toggleIVRQuestion(action) {
    const { payload:{id } } = action
    try {
        yield call(api.put, `IVR/OrderFormQuestion/Toggle/${id}`)
        yield put(message("Success! Toggled Your Question"))
        let response = yield call(api.get, "IVR/OrderFormQuestion/GetAll")      
        yield put(
            {
                type: A.IVR.QUESTIONS.RESPONSE_GET_ALL,
                response
            }
        )        
    } catch (payload) {
        yield put(errorMessage("Sorry something went wrong."));
    }
}
