import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFAQ, updateFAQ } from "./../../Redux/Actions/faq";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import PureModal from "components/ui/modal/pure-modal";
import ProductFAQsForm from "./product-faqs-form";
import BoolCheckbox from "components/ui/bool-checkbox";
import EditLink from "components/ui/edit-link";

const ProductFAQs = ({
  getFAQ,
  updateFAQ,
  productId,
  Questions,
  isLoading
}) => {
  React.useEffect(() => {
    if (productId) getFAQ(productId);
  }, [productId]);

  const { rows, query, queryDispatcher, count } = useLocalQuery(
    productId ? Questions : [],
    {
      sort: { by: "created_date", direction: "asc" },
      filters: {},
      pagination: { page: 1, per: 100 }
    }
  );
  return (
    <div>
      <Table
        containerClass="query-table"
        rowKey="faq_id"
        isFetching={isLoading}
        columns={[
          {
            header: "Question",
            field: "question"
          },
          {
            header: "Answer",
            field: "answer"
          },
          {
            header: "Active",
            field: "active",
            filters: [
              { text: "Yes", value: true },
              { text: "No", value: false }
            ]
          },
          {
            field: "",
            header: " "
          }
        ]}
        rows={rows}
        query={query}
        count={count}
        queryDispatcher={queryDispatcher}
        TableRow={({ question, answer, active, faq_id }) => (
          <tr>
            <td>{question}</td>
            <td>{answer}</td>
            <td>
              <BoolCheckbox value={active} />
            </td>
            <td>
              <PureModal
                renderTrigger={({ openModal }) => (
                  <EditLink onClick={openModal} showIcon={false} text="edit" />
                )}
                renderContent={({ closeModal }) => (
                  <ProductFAQsForm
                    initialValues={{ question, answer, active }}
                    handleCancel={closeModal}
                    handleSubmit={async values => {
                      await updateFAQ(productId, { faq_id, ...values });
                    }}
                  />
                )}
              />
            </td>
          </tr>
        )}
      />
    </div>
  );
};

ProductFAQs.propTypes = {
  getFAQ: PropTypes.func.isRequired,
  updateFAQ: PropTypes.func.isRequired,
  productId: PropTypes.string,
  Questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool
};

export default connect(
  state => ({
    Questions: state.getIn(["newRootReducer"]).rdc_faq.Questions,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  { getFAQ, updateFAQ }
)(ProductFAQs);
