import {
  delay,
  takeLatest,
  take,
  race,
  put,
  all
} from 'redux-saga/effects'
import A from 'action-types'
import {
  EXPORTS_START_FETCH_INTERVAL,
  EXPORTS_END_FETCH_INTERVAL,
  exportsLastUpdated
} from 'actions/exports'
import { getExports } from 'action-creators'

export default function* rootExportsSaga() {
  yield all([
    watchStartInterval(),
    putExportLastUpdated()
  ])
}

function* watchStartInterval() {
  yield takeLatest(EXPORTS_START_FETCH_INTERVAL, exportFetchInterval)
}

function* exportFetchInterval({ payload: { id, delayTime } }) {
  while (true) {
    const { end } = yield race({
      timeout: delay(delayTime),
      end: take(EXPORTS_END_FETCH_INTERVAL)
    })
    if (end) break
    yield put(getExports(id))
  }
}

function* putExportLastUpdated() {
  while (true) {
    const { response } = yield take(A.EXPORTS.GET)
    // the `call-api` middleware will dispatch multiple actions of
    // this type (although not all make it to the store); check that a
    // `response` key exists to ensure we're taking the right one
    if (response) {
      yield put(exportsLastUpdated(new Date()))
    }
  }
}
