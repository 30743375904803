import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "components/ui/icon-button";
import MdPhone from "react-icons/lib/md/phone";

const secondsToString = totalSeconds => {
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;
  return `${minutes}:${seconds >= 10 ? seconds : "0" + seconds}`;
};

const secondsToColor = totalSeconds => {
  if (totalSeconds === 0) {
    return "#ccc";
  } else if (totalSeconds > 0 && totalSeconds < 60) return "black";
  else if (totalSeconds > 60 && totalSeconds < 300) return "rgb(116,73,6)";
  else return "rgb(128,33,27)";
};

const Queue = ({ queue, answer, phoneIsReady }) => {
  const [time, updateTime] = useState(0);

  useEffect(() => {
    const tick = () => {
      if (time !== 0) updateTime(time + 1);
    };
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, [time, updateTime]);

  useEffect(() => {
    if (queue.average_wait_time !== time) {
      updateTime(queue.average_wait_time);
    }
    return () => {};
    // eslint-disable-next-line
  }, [queue]);

  return (
    <div className="queue" key={queue.friendly_name}>
      <div className="queue-header-row">
        <span className="team-name-cell">
          <IconButton
            disabled={!queue.current_size && !queue.average_wait_time > 0 || !phoneIsReady}
            icon={<MdPhone style={{ marginRight: "5px" }} />}
            style={{ color: "rgb(49, 130, 189)", padding: 5 }}
            text="Answer"
            onClick={answer}
          />
          {queue.friendly_name}
        </span>
        <span style={{ color: !queue.current_size ? "#ccc" : "black" }}>
          {queue.current_size}
        </span>
        <span style={{ color: secondsToColor(queue.average_wait_time) }}>
          {secondsToString(time)}
        </span>
        <span />
      </div>
    </div>
  );
};

Queue.propTypes = {
  queue: PropTypes.object.isRequired,
  answer: PropTypes.func.isRequired,
  phoneIsReady: PropTypes.bool
};

export default Queue;
