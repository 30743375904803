export const ENUM_LOAD = 'ENUM_LOAD'
export const enumLoad = payload => ({
  type: ENUM_LOAD,
  payload
})

export const ENUM_FORM_ATTRIBUTES = 'ENUM_FORM_ATTRIBUTES'
export const enumFormAttributes = entities => ({
  type: ENUM_FORM_ATTRIBUTES,
  payload: { entities }
})

export const ENUM_FETCH_ERROR = 'ENUM_FETCH_ERROR'
export const enumFetchError = payload => ({
  type: ENUM_FETCH_ERROR,
  error: true,
  payload
})
