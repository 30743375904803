import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { fetchPatientAccessLogs } from "actions/patient";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { isEmpty } from "ramda";

const AccessLogs = ({
  patientId,
  accessLogs,
  isFetchingAccessLogs,
  fetchPatientAccessLogs
}) => {
  useEffect(() => {
    if (isEmpty(accessLogs)) fetchPatientAccessLogs({ patientId });
  }, [patientId]);
  const { rows, query, queryDispatcher } = useLocalQuery(accessLogs, {
    sort: { by: "accessedDate", direction: "desc" }
  });
  return (
    <div>
      <Table
        isFetching={isFetchingAccessLogs}
        containerClass="query-table patient-tab-table"
        TableRow={AccessedLogsTableRow}
        columns={[
          {
            header: "Time",
            field: "accessedDate",
            style: { width: "20%" }
          },
          {
            header: "User",
            field: "accessedByName",
            style: { width: "30%" }
          },
          {
            header: "IP Address",
            field: "accessedFromIP",
            style: { width: "20%" }
          },
          {
            header: "Url",
            field: "accessedFromURL",
            style: { width: "30%" }
          }
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
      />
    </div>
  );
};

export default connect(
  (state, { patientId }) => {
    const isFetchingAccessLogs = state.getIn(
      ["fetch", fetchPatientAccessLogs.baseType, patientId, "isFetching"],
      false
    );
    return {
      accessLogs: selectors.getPatientAccessLogs(state, patientId),
      isFetchingAccessLogs
    };
  },
  { fetchPatientAccessLogs }
)(AccessLogs);

AccessLogs.propTypes = {
  patientId: PropTypes.string.isRequired,
  fetchPatientAccessLogs: PropTypes.func.isRequired,
  accessLogs: PropTypes.arrayOf(PropTypes.object),
  isFetchingAccessLogs: PropTypes.bool
};

const AccessedLogsTableRow = ({
  accessedDate,
  accessedByName,
  accessedFromIP,
  accessedFromURL
}) => (
  <tr>
    <td>{moment(accessedDate).format("MMM DD, YYYY hh:mm A")}</td>
    <td>{accessedByName}</td>
    <td>{accessedFromIP}</td>
    <td>{accessedFromURL}</td>
  </tr>
);
AccessedLogsTableRow.propTypes = {
  accessedDate: PropTypes.string,
  accessedByName: PropTypes.string,
  accessedFromIP: PropTypes.string,
  accessedFromURL: PropTypes.string
};
