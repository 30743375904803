import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MdSettings from "react-icons/lib/md/settings";
import MdRemoveCircle from "react-icons/lib/md/remove-circle";
import MdPerson from "react-icons/lib/md/person";
import MdGroup from "react-icons/lib/md/group";

const EmailGroup = ({
  currentEmailGroupID,
  emailGroup,
  updateEmailGroupName,
  deleteEmailGroupByID,
  currentEmailGroupAddressID,
  setCurrentEmailGroupAddressID,
  setCreateNewEmailGroupAddressState
 
}) => {
  const [editable, setEditable] = useState(false);
  const [groupNameEditState, setGroupNameEditState] = useState("");

  useEffect(() => {
    setEditable(false)
    setGroupNameEditState(emailGroup.group_name);
  }, [currentEmailGroupID]);
  
  const getAccessibleCompanyNames = companies => {
    var tbr = "";
    if (companies) {
      for (let index = 0; index < companies.length; index++) {
        if (index == companies.length - 1) {
          tbr += ` or ${companies[index].company_name}`;
        } else {
          tbr += ` ${companies[index].company_name},`;
        }
      }
    }

    return tbr;
  };

  return (
    <div className="emailGroupInfo emailGroupDropShadow">
      <div style={{ margin: "10px" }}>
        <span
          className="editButton"
          title="Edit Email Group"
          onClick={() => {
            setEditable(!editable);
          }}
          style={{
            position: "relative",
            top: "4px",
            right: "-97%",
            cursor: "pointer",
            height: "auto",
            width: "10px"
          }}
        >
          <MdSettings />
        </span>

        <div className="center" style={{ fontSize: "2.5em", color: "#02b4a6" }}>
          {!editable ? (
            <span>{emailGroup.group_name}</span>
          ) : (
            <div>
              <input
                className="inplaceInputEdit"
                onChange={e => {
                  setGroupNameEditState(e.target.value);
                }}
                onBlur={() => {
                  updateEmailGroupName(
                    emailGroup.email_group_id,
                    groupNameEditState
                  );
                  setEditable(false);
                }}
                value={groupNameEditState}
              />
              <span
                title={"Delete Email Group"}
                className="deleteEmailGroupButton"
                onClick={() => {
                  deleteEmailGroupByID(emailGroup.email_group_id);
                }}
              >
                <MdRemoveCircle />
              </span>
            </div>
          )}
        </div>
        <div
          className="center"
          style={{ marginTop: "5px", marginBottom: "-37px" }}
        >
          <span
            style={{
              position: "relative",
              left: "-14px",
              fontSize: "2em",
              marginTop: "5px",
              color: "grey"
            }}
          >
            Addresses
          </span>
          <span
            title={"Create A New Address"}
            onClick={() => {
              setCreateNewEmailGroupAddressState(true);
            }}
            className="center addItemButton"
            style={{
              position: "relative",
              top: "-35px",
              left: "52px",
              fontSize: "27px"
            }}
          >
            +
          </span>
        </div>
        {emailGroup.addresses.map((a, i) => (
          <React.Fragment key={i}>
            <div
              onClick={() =>
                setCurrentEmailGroupAddressID(a.email_group_member_id)
              }
              title={
                a.person
                  ? `Click To Open ${
                      a.first_name + " " + a.last_name
                    }. This is a single user`
                  : `Click To Open ${a.member_name}. This is a group email`
              }
              className={
                currentEmailGroupAddressID != a.email_group_member_id
                  ? "emailGroupAddressRow emailGroupDropShadow"
                  : "emailGroupAddressRowSelected emailGroupDropShadow"
              }
            >
              <div>
                <span
                  style={{
                    position: "relative",
                    top: "-2px"
                  }}
                >
                  {a.person ? (
                    <MdPerson />
                  ) : (
                    <span className="emailGroupIcon">
                      <MdGroup />
                    </span>
                  )}
                </span>
                <span
                  style={{
                    paddingLeft: "5px", 
                    fontWeight: a.is_S3_user ?  "bolder" : "normal" , 
                    color: a.can_email_from_profile ? "green" : "red"           
                  }}
                >
                  {a.person ? a.first_name + " " + a.last_name : a.member_name}
                </span>
              </div>              
            </div>
          </React.Fragment>
        ))}

        <div
          style={{ minHeight: "100px" }}
          title={
            emailGroup.accessible_companies &&
            emailGroup.accessible_companies.length > 0
              ? `This group can only view patients from within ${getAccessibleCompanyNames(
                  emailGroup.accessible_companies
                )}`
              : "Warning! This group cannot be sent any patient data!"
          }
        >
          <div className="center" style={{ marginTop: "40px", color: "grey" }}>
            {"Group's Company Access"}
          </div>
          {!emailGroup.accessible_companies ||
          emailGroup.accessible_companies.length < 1 ? (
            <div className="center">
              <div className="center" style={{ color: "red", fontSize: "2em" }}>
                Warning!
              </div>
              <div
                className="center"
                style={{ color: "red", fontSize: "1.5em" }}
              >
                This Email Group cannot view any patient data
              </div>
            </div>
          ) : (
            <>
          <div className="emailGroup-Companies">        
                  {emailGroup.accessible_companies.map((a, i) => (
                  <React.Fragment key={i}>
                    <div
                      className="center"
                      style={{ padding: "5px", fontSize: "1.1em" }}
                    >
                      {a.company_name}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
EmailGroup.propTypes = {
  currentEmailGroupID: PropTypes.string.isRequired,
  emailGroup: PropTypes.object,
  updateEmailGroupName: PropTypes.func.isRequired,
  deleteEmailGroupByID: PropTypes.func.isRequired,
  currentEmailGroupAddressID: PropTypes.string,
  setCurrentEmailGroupAddressID: PropTypes.func.isRequired,
  setCreateNewEmailGroupAddressState: PropTypes.func.isRequired
};

export default EmailGroup;
