import React, { Component } from "react";
import { connect } from "react-redux";
import { PageHeader, Waiting, ToggleSwitch } from "components/ui";
import DataTable from "../../../ui/data-table";
import Modal from "../../components/ModalComponent";
import AddNewArticle from "./addNew";
import PropTypes from "prop-types";
import {
  updateScheduleDays,
  getArticles,
  createArticles,
  updateArticles,
  updateStatusArticles,
  updateScheduleType,
} from "./../../Redux/Actions/recurrearticles";
import { errorMessage } from "actions/message";
import { isValidUrl } from "utils/form-validation";

let Styles = {
  container: {
    background: "#fff",
    border: "1px solid #DEEAEE",
    padding: "20px",
    marginBottom: "15px",
  },
  textRendering: {
    textRendering: "optimizeLegibility!important",
  },
};

class RecurreArticles extends Component {
  static propTypes = {
    errorMessage: PropTypes.func,
    getArticles: PropTypes.func,
    createArticles: PropTypes.func,
    updateScheduleDays: PropTypes.func,
    updateArticles: PropTypes.func,
    updateStatusArticles: PropTypes.func,
    updateScheduleType: PropTypes.func,
    ScheduleDays: PropTypes.arrayOf(PropTypes.object),
    ScheduleType: PropTypes.string,
    Articles: PropTypes.arrayOf(PropTypes.object),
    isLoader: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.drpScheduleTypeRef = React.createRef();
    this.state = {
      isFetch: false,
      modalOpen: false,
      articlesData: [],
      scheduleDays: props.ScheduleDays,
      scheduleType: props.ScheduleType,
      selectedArticle: null,
      emptyArticle: {
        article_id: 0,
        link: "",
        title: "",
        active: true,
        company: ""
      },
      columns: [
        {
          title: "Order No",
          header: "Order No",
          field: (article) => <span>{article.orderno}</span>,
        },
        {
          title: "Article Title",
          header: "Article Title",
          field: (article) =>
            article.link.length > 0 ? (
              <a href={article.link} target="blank">
                {article.title}
              </a>
            ) : (
              <>{article.title}</>
            ),
        },
        {
          title: "Edit",
          header: "Edit",
          field: (article) => (
            <button type="button" onClick={() => this.onArticleEdit(article)}>
              Edit
            </button>
          ),
        },
        {
          title: "Active",
          header: "Active",
          field: (article) => (
            <ToggleSwitch
              onChange={checked => this.props.updateStatusArticles(article, checked)}
              value={article.active}
            />
          ),
        },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.Articles &&
      props.Articles.length > 0 &&
      props.Articles !== state.articlesData
    ) {
      let scheduleType = state.scheduleType;
      if (props.ScheduleType !== state.scheduleType) {
        scheduleType = props.ScheduleType;
      }

      let scheduleDays = state.scheduleDays;
      if (props.ScheduleDays !== state.scheduleDays) {
        scheduleDays = props.ScheduleDays;
      }

      return {
        articlesData: props.Articles,
        scheduleType: scheduleType,
        scheduleDays: scheduleDays,
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getArticles();
  }

  addNewQuestion = () => {
    this.setState({
      selectedArticle: this.state.emptyArticle,
    });
    this.onClose();
  };

  onClose = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  onDataSave = (data) => {
    if (data.link.length === 0) {
      this.props.errorMessage("Link field required!");
    } else if (data.title.length === 0) {
      this.props.errorMessage("Title field required!");
    } else if (data.company.length === 0) {
      this.props.errorMessage("Company field required!");
    } else {
      if (data.link.length > 0) {
        if (isValidUrl(data.link)) {
          if (data && data.article_id === 0) {
            this.props.createArticles(data);
          } else {
            this.props.updateArticles(data);
          }
          this.onClose();
        } else {
          this.props.errorMessage("Enter Valid Link");
        }
      }
    }
  };

  onArticleEdit = (data) => {
    this.setState({
      selectedArticle: data,
    });
    this.onClose();
  };

  handleChange = (e) => {
    this.setState({
      scheduleDays: e.target.value,
    });
  };

  updateScheduleInfo = () => {
    this.props.updateScheduleType(this.drpScheduleTypeRef?.current?.value);
  };

  updateScheduleDays = () => {
    this.props.updateScheduleDays(this.state.scheduleDays);
  };

  render() {
    return (
      <div className="dd_root dd_row">
        {this.props.isLoader && <Waiting />}
        <PageHeader title="Recurring Articles" />
        <Modal onClose={this.onClose} show={this.state.modalOpen}>
          <AddNewArticle
            onSave={this.onDataSave}
            Article={this.state.selectedArticle}
          />
        </Modal>
        <div className="dd_row" style={Styles.container}>
          <div className="dd_col-12">
            <h1
              style={{
                float: "left!important",
                fontSize: "24px",
                color: "#000",
                fontWeight: 400,
                width: "100%",
                marginBottom: "10px",
              }}
            >
              Configuration Settings
            </h1>
          </div>
          <div className="dd_col-6" style={Styles.container}>
            <h3>Schedule Type</h3>
            <div style={{ display: "flex" }}>
              <select ref={this.drpScheduleTypeRef}>
                <option
                  key="1"
                  selected={this.state.scheduleType === "1"}
                  value="1"
                >
                  Weekly
                </option>
                <option
                  key="2"
                  selected={this.state.scheduleType === "2"}
                  value="2"
                >
                  Monthly
                </option>
              </select>
              <button
                onClick={this.updateScheduleInfo}
                className="dd_col-2"
                style={{ marginLeft: "20px" }}
                type="button"
              >
                Update
              </button>
            </div>
          </div>
          <div className="dd_col-6" style={Styles.container}>
            <h3>Days</h3>
            <div style={{ display: "flex" }}>
              <input
                max={365}
                min={0}
                value={this.state.scheduleDays}
                maxLength={3}
                onChange={this.handleChange}
                type="number"
                placeholder="Enter Days"
              />
              <button
                onClick={this.updateScheduleDays}
                className="dd_col-2"
                style={{ marginLeft: "20px" }}
                type="button"
              >
                Update
              </button>
            </div>
          </div>
        </div>
        <div
          className="dd_col-12"
          style={{ ...Styles.container, height: "auto", overflowY: "auto" }}
        >
          <button
            type="button"
            className="dd_col-2"
            style={{ margin: "10px" }}
            onClick={this.addNewQuestion}
          >
            Add Article
          </button>
          {this.state.articlesData && this.state.articlesData.length > 0 ? (
            <div className="dd_col-12" style={{ margin: "10px" }}>
              <DataTable
                columns={this.state.columns}
                rows={this.state.articlesData}
                disabledsort={true}
              />
            </div>
          ) : (
            !this.props.isLoader && (
              <div
                className="dd_col-12 dd_noDataTitle"
                style={{ fontSize: "20px" }}
              >
                No Articles Created yet
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Articles: state.getIn(["newRootReducer"]).rdc_rarticles.Articles,
    ScheduleType: state.getIn(["newRootReducer"]).rdc_rarticles.ScheduleType,
    ScheduleDays: state.getIn(["newRootReducer"]).rdc_rarticles.ScheduleDays,
    isLoader: state.getIn(["newRootReducer"]).rdc_loader.isLoader,
  };
};

export default connect(mapStateToProps, {
  updateScheduleDays,
  errorMessage,
  getArticles,
  createArticles,
  updateArticles,
  updateStatusArticles,
  updateScheduleType,
})(RecurreArticles);
