import React from "react";
import PropTypes from "prop-types";

const MaskFittingQuestionAnsweres = ({ question_answers }) => {
  return (
    <div>
      {question_answers.map(({ id, answer, text }) => (
        <div key={id} className="mask-fitting-question-entry">
          <div className="mask-fitting-question-text">{text}</div>
          <div className="mask-fitting-question-answer">{answer}</div>
        </div>
      ))}
    </div>
  );
};

MaskFittingQuestionAnsweres.propTypes = {
  question_answers: PropTypes.arrayOf(PropTypes.object)
};

export default MaskFittingQuestionAnsweres;
