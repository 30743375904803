import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useOutsideClick from "hooks/useOutsideClick";
import useKeyboardEvent from "hooks/useKeyboardEvent";

const SearchableDropdown = ({
  options,
  selected,
  placeholder,
  disabled,
  fontPercent,
  fontColor,
  onSelect,
  contentStyle,
  style,
  requireSearch
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [focusedOption, setFocusedOption] = useState(
    options.find(x => x.value === selected)
  );
  const [filter, setFilter] = useState(
    selected ? options.find(x => x.value == selected || x.key == selected)?.text ?? "" : ""
  );
  const searchableDropdownRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    if (selected){
      setFilter(options.find(x => x.value == selected || x.key == selected)?.text ?? "");
    }
    else if(!showDropdown){
      setFilter(options.find(x => x.value == selected || x.key == selected)?.text ?? "");
    }
  }, [selected, options, showDropdown]);

  const [filterLength, setFilterLength] = useState(0);
  useEffect(() => {
    setFilterLength(filter.length)
  }, [filter]);

  useEffect(() => {
    const getFilteredOptions = () => {
      return options.filter(option =>
        option.text.toLowerCase().includes(filter.toLowerCase())
      );
    };
    if (filter && showDropdown) {
      const filteredOptions = getFilteredOptions();
      if (!filteredOptions.includes(focusedOption)) setFocusedOption(null);
      setFilteredOptions(filteredOptions);
    } else {
      setFilteredOptions(options);
    }
  }, [filter, options]);

  useEffect(() => {
    if (showDropdown) updateScroll();
  }, [filteredOptions, showDropdown]);

  const updateScroll = offset => {
    const dropdown = dropdownRef.current;
    if (dropdown && focusedOption) {
      const focusedIndex = Math.max(
        filteredOptions.indexOf(focusedOption) - 1 + offset,
        0
      );

      const children = Array.from(dropdown.children);

      const pixelsFromTop = children
        .slice(0, focusedIndex)
        .reduce((total, item) => {
          return total + item.clientHeight;
        }, 0);

      const lastItem = children[children.length - 1];
      let clientHeight = lastItem ? lastItem.clientHeight : 0;

      if (pixelsFromTop < dropdown.scrollTop + clientHeight) {
        dropdown.scrollTop = pixelsFromTop - clientHeight;
      } else if (
        pixelsFromTop >
        dropdown.scrollTop + dropdown.clientHeight - clientHeight * 3
      ) {
        dropdown.scrollTop =
          pixelsFromTop - dropdown.clientHeight + clientHeight * 3;
      }
    }
  };

  const onOptionSelect = option => {
    if (requireSearch && filterLength < 3) {
      setShowDropdown(false);
    } else {
      setShowDropdown(false);
      setFilter(option.text);
      onSelect(option.value, option.key);
    }
  };

  const onInputClick = e => {
    setShowDropdown(!showDropdown);
    e.target.setSelectionRange(0, e.target.value.length);
  };

  const onInputChange = e => {
    setFilter(e.target.value);
    setShowDropdown(true);
  };

  useOutsideClick(searchableDropdownRef, () => {
    setShowDropdown(false);
    setFilterLength(0);
  });

  useKeyboardEvent("Enter", () => {
    if (focusedOption && showDropdown) {
      onOptionSelect(focusedOption);
    }
  });

  useKeyboardEvent("ArrowDown", () => {
    if (focusedOption && showDropdown) {
      const focusedOptionIndex = filteredOptions.indexOf(focusedOption);
      if (focusedOptionIndex + 1 < filteredOptions.length)
        setFocusedOption(filteredOptions[focusedOptionIndex + 1]);
    } else if (filteredOptions.length > 0) {
      setFocusedOption(filteredOptions[0]);
    }
    updateScroll(2);
  });

  useKeyboardEvent("ArrowUp", () => {
    if (focusedOption && showDropdown) {
      const focusedOptionIndex = filteredOptions.indexOf(focusedOption);
      if (focusedOptionIndex - 1 >= 0)
        setFocusedOption(filteredOptions[focusedOptionIndex - 1]);
      updateScroll(-1);
    }
  });

  return (
    <div className="searchable-dropdown" ref={searchableDropdownRef}>
      <div className="searchable-dropdown-content" style={style}>
        <input
          type="text"
          style={{ fontSize: fontPercent, color: fontColor }}
          value={filter}
          placeholder={placeholder}
          onClick={onInputClick}
          onChange={onInputChange}
          disabled={disabled}
        />
        {showDropdown && (
          <div
            className="searchable-dropdown-items-container"
            style={{ fontSize: fontPercent, ...contentStyle }}
            ref={dropdownRef}
          >
            {filteredOptions.map(option => (
              <div
                key={option.value}
                className={
                  focusedOption === option
                    ? "searchable-dropdown-item searchable-dropdown-item-focused"
                    : "searchable-dropdown-item"
                }
                onClick={() => requireSearch && filterLength < 3 ? null : onOptionSelect(option)}
                onMouseMove={() => requireSearch && filterLength < 3 ? null : setFocusedOption(option)}
              >
                {option.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

SearchableDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  fontPercent: PropTypes.string,
  fontColor: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  contentStyle: PropTypes.object,
  style: PropTypes.object,
  requireSearch: PropTypes.bool
};

SearchableDropdown.defaultProps = {
  disabled: false,
  contentStyle: {},
  style: {}
};

export default SearchableDropdown;
