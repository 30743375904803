import * as R from "ramda";
import { getEquipmentQuestions } from "./options";

/* should have alteast one dysfunction for each requested equipment */
export const getIsValidDysfunctionAnswers = formValues => {
  if (
    !(
      (
        (formValues.primary_insurance?.requires_dysfunctions ?? true) ||
        (formValues.secondary_insurance?.requires_dysfunctions ?? false)
      ) // Chances are there might not be a secondary insurance
    )
  ) {
    return true;
  }
  const requiredQuestions = R.pipe(
    R.map(equipment =>
      equipment == "nondisposable filter" || equipment == "disposable filter"
        ? "filter"
        : equipment
    ),
    R.uniq,
    R.map(getEquipmentQuestions),
    R.flatten
  )(R.propOr([], "requested_equipment", formValues));

  const hasOneFaultForEachEquipment = data => questions =>
    questions.some(({ key }) => data[key] === true);

  return R.pipe(
    R.groupBy(R.prop("equipment")),
    R.mapObjIndexed(hasOneFaultForEachEquipment(formValues)),
    R.values,
    R.all(R.equals(true))
  )(requiredQuestions);
};

/*
  require atlest one requested equipment
  if user is sleep coach require reason_for_no_mask if no mask is selected
*/
export const validateChooseSuppliesForm = ({ values }, userRole) => {
  const hasNoMaskReason =
    values.requested_equipment.includes("mask") || !!values.reason_for_no_mask;
  if (userRole === "SleepCoach")
    return values.requested_equipment.length > 0 && hasNoMaskReason;
  else return values.requested_equipment.length > 0;
};

export const validateAdditionalInfoForm = ({ errors, values }) => {
  const answeredLastSawPhysician = !R.has("saw_physician_last", errors);
  const hasDysfunctionForEveryEquipment = getIsValidDysfunctionAnswers(values);
  const hasValidPhysicianInfo = !R.has("physician", errors);
  const answeredChangedInsurnace = !R.has("changed_insurance", errors);
  return (
    answeredChangedInsurnace &&
    answeredLastSawPhysician &&
    hasDysfunctionForEveryEquipment &&
    hasValidPhysicianInfo
  );
};

// TODO should really validate only this form and display other errors somewhere
export const validateShippingDetails = ({ errors }) => !!R.isEmpty(errors);
