import React, { useState, useEffect, useMemo } from 'react';
import { PageHeader, Spinner } from "components/ui";
import { useDropzone } from 'react-dropzone';
import { MdFileUpload } from 'react-icons/lib/md';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, thumbsContainer, thumb, thumbInner, toggleStyle } from './styles';
import { IoEdit, IoIosArrowDown, IoIosArrowUp, IoIosTrash } from 'react-icons/lib/io';
import { useFetch } from 'hooks';
import { path } from "ramda";
import { put, del } from 'utils/api';
import { getApiErrorMessage } from "utils/misc";
import * as msg from "actions/message"; import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

export default function OnboardingTile({ data, index, saveTile, companyId, getTiles, fetchingCreateTile, removeTile }) {
    const dispatch = useDispatch();
    const tileRequired = { value: "Onboarding must have at least one tile", color: 'red' };

    const tileHeader = "Tile " + (index + 1);
    const [tileTitle, setTileTitle] = useState(data.Title);
    const [tileDesc, setTileDesc] = useState(data.Description);
    const [file, setFile] = useState([]);
    const [editTile, setEditTile] = useState(data.TileId == 0);
    const [toggleTile, setToggleTile] = useState(data.TileId == 0);
    const [error, setError] = useState(false);

    const { fetchData: fetchEditTile, isFetching: fetchingEditTile } = useFetch({
        apiFn: (body) => put(`companies/${companyId}/onboarding/${data.TileId}`, body),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            dispatch(msg.errorMessage(`Failed to save tile: ${getApiErrorMessage(error)}`))
        }
    });

    const { fetchData: fetchDeleteTile, isFetching: fetchingDeleteTile } = useFetch({
        apiFn: (body) => del(`companies/${companyId}/onboarding/${data.TileId}`, body),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            dispatch(msg.errorMessage(`Failed to delete tile: ${getApiErrorMessage(error)}`))
        }
    });

    const { getRootProps: getRootTileProps, getInputProps: getInputTileProps, isDragActive: isDragTileActive, isFocused: isFocusedTile, isDragAccept: isDragAcceptTile, isDragReject: isDragRejectTile } = useDropzone({
        accept: { "application/pdf": [".pdf"], "video/*": [".mp4"] },
        onDrop: async acceptedFiles => {
            const file = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            setFile(file[0]);
        }
    })

    const styleTile = useMemo(() => ({
        ...baseStyle,
        ...(isFocusedTile ? focusedStyle : {}),
        ...(isDragAcceptTile ? acceptStyle : {}),
        ...(isDragRejectTile ? rejectStyle : {})
    }), [
        isFocusedTile,
        isDragAcceptTile,
        isDragRejectTile
    ]);

    const thumbs = () => (
        <div style={thumb} key={index}>
            <div style={thumbInner}>
                <p style={{ margin: "0px" }}>{file.name}</p>
            </div>
        </div>
    );

    const save = async () => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", tileTitle);
        formData.append("description", tileDesc);

        setEditTile(false);

        if (data.TileId)
            await fetchEditTile(formData);
        else {

            await saveTile(formData);
            data.Description = tileDesc;
            data.Title = tileTitle;
            setEditTile(false);
            setToggleTile(false)
            setError(false);

        }

        dispatch(msg.message("Tile saved successfully"));
        await getTiles();
    }

    const deleteTile = async () => {

        if (data.TileId) {
            await fetchDeleteTile();
            await getTiles();
        }
        else {
            removeTile(index);
        }
        dispatch(msg.message("Tile removed successfully"));
    }


    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => URL.revokeObjectURL(file.preview);
    }, []);

    const validateTileBeforeSubmit = () => {
        if (tileTitle == "" || (file.length == 0 && data.TileId == 0))
            setError(true);
        else
            setError(false);
    }

    useEffect(() => {
        validateTileBeforeSubmit();
    }, [tileTitle, file]);


    useEffect(() => {
        setTileDesc(data.Description);
        setTileTitle(data.Title);

        if (data.TileId != 0) {
            setToggleTile(false);
            setEditTile(false);
            setError(false);
        }
    }, [data])

    return (
        <div>
            <PageHeader title={tileHeader}>
                { index == 0 && data.TileId == 0 && <span style={{ color: tileRequired.color }} > {tileRequired.value} </span>}
                {
                    !toggleTile ?
                        <IoIosArrowDown size={'20px'} style={toggleStyle} onClick={() => setToggleTile(!toggleTile)} />
                        :
                        <IoIosArrowUp
                            size={'20px'}
                            style={toggleStyle}
                            onClick={() => {
                                if (index == 0 && data.TileId == 0)
                                    return;

                                setToggleTile(!toggleTile);
                                setEditTile(false);
                            }}
                        />
                }
                {
                    index > 0 && !fetchingDeleteTile && !fetchingEditTile &&
                    <IoIosTrash size={'20px'} style={{ cursor: 'pointer', color: "red" }} onClick={async () => { await deleteTile() }} />
                }
                {
                    fetchingEditTile || fetchingDeleteTile || fetchingCreateTile || fetchingDeleteTile ?
                        <Spinner />
                        :
                        <IoEdit
                            size={'20px'}
                            style={toggleStyle}
                            onClick={() => {
                                if (index == 0 && data.TileId == 0)
                                    return;

                                setToggleTile(true);
                                setEditTile(!editTile);
                            }}
                        />
                }
            </PageHeader>
            {
                toggleTile &&
                <div>
                    <div>
                        <p>Title</p>
                        <input
                            type="text"
                            value={tileTitle}
                            maxLength="40"
                            onChange={(e) => setTileTitle(e.target.value)}
                            disabled={!editTile}
                        />
                    </div>
                    <div>
                        <p>Description</p>
                        <textarea
                            style={{ resize: "none" }}
                            maxLength="150"
                            value={tileDesc}
                            onChange={(e) => setTileDesc(e.target.value)}
                            disabled={!editTile}
                        >
                        </textarea>
                    </div>
                    <div>
                        <p>Your file</p>
                        {editTile && <div {...getRootTileProps({ style: styleTile })}>
                            <input key={index} {...getInputTileProps()} disabled={!editTile} />
                            <MdFileUpload size={"20px"} />
                            {
                                isDragTileActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>Drag and drop some files here, or click to select files</p>
                            }
                            <aside style={thumbsContainer}>
                                {thumbs()}
                            </aside>
                        </div>}

                    </div>

                    {
                        !editTile &&
                        <div style={{ fontSize: '13px' }}>
                            <div style={thumb} key={index}>
                                <div style={thumbInner}>
                                    <u><a href={data.FileUrl} style={{ margin: "0px" }}>{data.FileUrl}</a></u>
                                </div>
                            </div>
                        </div>
                    }

                    <br />
                    {
                        editTile &&
                        <>
                            {error && <p style={{ color: 'red' }}> Title and file are required</p>}
                            {data.TileId != 0 && <p style={{ color: 'rgb(232, 145, 12)', fontWeight: 'bold' }}> File upload is optional. To keep existing file, do not select a new one before submitting.</p>}
                            <button
                                style={{ marginRight: '10px' }}
                                onClick={async () => await save()}
                                disabled={error}
                            >
                                Save {tileHeader}
                            </button>
                            <button
                                className="button warning"
                                onClick={() => {
                                    setTileTitle(data.Title);
                                    setTileDesc(data.Description);
                                    setEditTile(false);

                                    if (data.TileId == 0)
                                        removeTile(index);
                                }}
                                disabled={index == 0 && data.TileId == 0}
                            >
                                Cancel
                            </button>
                        </>
                    }

                </div>
            }
        </div>
    )
}

OnboardingTile.propTypes = {
    data: PropTypes.object, 
    index: PropTypes.number, 
    saveTile: PropTypes.func, 
    companyId: PropTypes.string, 
    getTiles: PropTypes.func, 
    fetchingCreateTile: PropTypes.bool, 
    removeTile: PropTypes.func
}