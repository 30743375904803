import React, { useState } from "react";
import { useFetch } from "hooks";
import { path } from "ramda";
import moment from "moment";
import PropTypes from "prop-types";
import { IconButton, PureModal, Spinner } from "components/ui";
import { get, put } from "utils/api";
import "./../tabsStyles.css";
import AnswerPatientForm from "./answer-form";
import AddPatientForm from "./add-form";
import { Link } from "react-router-dom";
import { MdPageview, MdEdit, MdAdd } from "react-icons/lib/md";
import SendEmailIcon from "react-icons/lib/md/send";
import { useDispatch } from "react-redux";
import * as msg from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import UpdateFormDates from "./update-form-start-date";
import { IoIosTrash } from "react-icons/lib/io";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import ModalUnassignForm from "./modal-unassign-form";
import FormResponseInformation from "components/screens/forms/form-response-profile";

export default function FormsData({
  patientId,
  patientTypeData,
  isInsideModal,
  patientInfo
}) {
  const dispatch = useDispatch();

  const { fetchData: fetchFormsInfo, isFetching: fetchingFormsInfo } = useFetch(
    {
      apiFn: value => get(`patients/${patientId}/forms-by-patient/${value}`),
      defaultValue: null,
      transformError: path(["response", "body", "status"])
    }
  );

  const { fetchData: sendFormEmail, isFetching: sendingFormEmail } = useFetch({
    apiFn: () => put(`patients/${patientId}/send-form-reminder-email`),
    defaultValue: null,
    transformError: path(["response", "body", "status"]),
    onSuccess: () => dispatch(msg.message(`Email reminder sent successfully`)),
    onError: error =>
      dispatch(
        msg.errorMessage(
          `Failed to send form email: ${getApiErrorMessage(error)}`
        )
      )
  });

  const [forms, setForms] = useState([]);
  const [optionValue, setOptionValue] = useState("All");
  const [onDemandSelectedFormId, setOnDemandSelectedFormId] = useState(null);

  async function setFormsData(value) {
    const FormsInfo = await fetchFormsInfo(value);
    if (FormsInfo && FormsInfo.length > 0) {
      setForms(FormsInfo);
    } else {
      setForms([]);
    }
  }

  async function triggerEmailSending() {
    await sendFormEmail();
  }

  React.useEffect(() => {
    setFormsData(optionValue);
  }, [patientId, patientTypeData]);

  React.useEffect(() => {
    if (!onDemandSelectedFormId) return;

    const button = document.getElementById("onDemandAssignment_AutomaticTrigger");
    if (button) {
      button.click();
    }
  }, [onDemandSelectedFormId]);

  const onOptionChange = e => {
    setForms([]);
    setFormsData(e.currentTarget.value);
    setOptionValue(e.currentTarget.id);
  };

  return (
    <div className="special-fields">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "10fr 1fr",
          gridGap: 10,
          alignItems: "left"
        }}
      >
        <fieldset style={{ fontSize: "1.0em" }}>
          <legend>Select an option:</legend>
          <input
            type="radio"
            id="All"
            name="answerStatus"
            value="null"
            onChange={onOptionChange}
            checked={optionValue === "All"}
          />
          <label htmlFor="All">All</label>
          <input
            type="radio"
            id="Answered"
            name="answerStatus"
            value="true"
            onChange={onOptionChange}
            checked={optionValue === "Answered"}
          />
          <label htmlFor="Answered">Answered</label>
          <input
            type="radio"
            id="Unanswered"
            name="answerStatus"
            value="false"
            onChange={onOptionChange}
            checked={optionValue === "Unanswered"}
          />
          <label htmlFor="Unanswered">Unanswered</label>
        </fieldset>
      </div>
      {patientInfo.compliance_met == null && patientInfo.active && (
        <div style={{ float: "right", display: "flex", flexDirection:"column",gap:"0.5em", width: "100px" }}>
          <PureModal
            renderTrigger={({ openModal }) => (
              <IconButton
                icon={<MdAdd />}
                text="Add"
                onClick={openModal}
                style={{ fontSize: "12px", float: "right" }}
              />
            )}
            renderContent={({ closeModal }) => (
              <AddPatientForm
                patientId={patientId}
                onDemand={false}
                closeModal={() => {
                  closeModal();
                  setFormsData(null);
                  setOptionValue("All");
                }}
              />
            )}
          />
          <PureModal
            renderTrigger={({ openModal }) => (
              <IconButton
                icon={<MdAdd />}
                text="Add On Demand"
                onClick={openModal}
                style={{ fontSize: "12px", float: "right" }}
              />
            )}
            renderContent={({ closeModal }) => (
              <AddPatientForm
                patientId={patientId}
                onDemand={true}
                closeModal={formId => {
                  setOnDemandSelectedFormId(formId);
                  closeModal();
                }}
              />
            )}
          />
        </div>
      )}
      {!fetchingFormsInfo ? (
        forms.length ? (
          <div>
            <table className="large-12">
            <thead>
                <tr>
                  <th>Form Type</th>
                  <th>Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Answer Date</th>
                  <th>Is Active</th>
                  <th>Fill out</th>
                  <th>View</th>
                  <th>Send Reminder</th>
                  <th> </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {forms.map((form, index) => (
                  <tr key={form.FormId + 'index' + index}>
                    <td>{form.PatientStage} {form.IsOnDemand && " - OD"}</td>
                    <td>{form.Name}</td>
                    <td>
                      <span
                        data-tooltip
                        tabIndex={form.FormId}
                        title={form.StartDate}
                      >
                        {moment(form.StartDate).format("LL")}
                      </span>
                    </td>
                    <td>
                      <span
                        data-tooltip
                        tabIndex={form.FormId}
                        title={form.EndDate}
                      >
                        {moment(form.EndDate).format("LL")}
                      </span>
                    </td>
                    <td>
                      {form.Answered ? (
                        <span
                          data-tooltip
                          tabIndex={form.FormId}
                          title={
                            form.Answered
                              ? moment(form.Answered).format(
                                "MMM DD, YYYY -  hh:mm A"
                              )
                              : "N/A"
                          }
                        >
                          {moment(form.Answered).format("LL")}
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{form.IsAvailable ? "Yes" : "No"}</td>
                    <td>
                      <PureModal
                        renderTrigger={({ openModal }) => (
                          <IconButton
                            icon={<MdEdit />}
                            onClick={openModal}
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            disabled={!form.IsAvailable || form.Answered}
                          />
                        )}
                        renderContent={({ closeModal }) => (
                          <AnswerPatientForm
                            patientId={patientId}
                            formId={form.FormId}
                            closeModal={() => {
                              closeModal();
                              setFormsData(true);
                              setOptionValue("Answered");
                            }}
                          />
                        )}
                      />
                    </td>

                    <td>
                      {isInsideModal ? (
                        <PureModal
                          windowProps={{ biggerMaxWidth: true }}
                          renderTrigger={({ openModal }) => (
                            <MdPageview
                              color={"blue"}
                              style={
                                form.Answered
                                  ? { marginRight: "10px", cursor: "pointer" }
                                  : {
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    color: "gray"
                                  }
                              }
                              onClick={() => (form.Answered ? openModal() : {})}
                            />
                          )}
                          renderContent={({ closeModal }) => (
                            <FormResponseInformation
                              modalPatientId={patientId}
                              modalFormAssignmentId={form.FormAssignmentId}
                              closeModal={() => {
                                closeModal();
                              }}
                              isModal={true}
                            />
                          )}
                        />
                      ) : form.Answered ? (
                        <Link
                          to={{
                            pathname: `/patients/${patientId}/answered-form/${form.FormAssignmentId}`,
                            state: { from: "PatientProfile" }
                          }}
                        >
                          <MdPageview
                            color={"blue"}
                            style={{ marginRight: "10px", cursor: "pointer" }}
                          />
                        </Link>
                      ) : (
                        <MdPageview
                          color={"gray"}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                        />
                      )}
                    </td>
                    <td>
                      <span
                        data-tooltip
                        tabIndex={form.FormId}
                        title="Send form reminder E-mail"
                        style={
                          form.IsAvailable && !sendingFormEmail
                            ? { cursor: "pointer", color: "blue" }
                            : { pointerEvents: "none", opacity: "0.5" }
                        }
                        onClick={() => triggerEmailSending()}
                      >
                        <SendEmailIcon size={14} />
                      </span>
                    </td>
                    <td>
                      <UpdateFormDates
                        patientId={patientId}
                        formId={form.FormId}
                        isEnabled={form.CanBeRescheduled}
                        startDate={form.StartDate}
                        onSubmit={() => setFormsData(optionValue)}
                      />
                    </td>
                    <td>
                      <span>
                        <div style={{ float: "right" }}>
                          {form.CanBeUnassigned && (
                            <PureModal
                              renderTrigger={({ openModal }) => (
                                <Tooltip
                                  id={`single-stat-tooltip-patient-forms`}
                                  className="single-stat-tooltip"
                                  effect="solid"
                                  message={"Un-Assign form"}
                                >
                                  <IoIosTrash
                                    style={{
                                      color: "red",
                                      fontSize: "1.5em",
                                      cursor: "pointer"
                                    }}
                                    onClick={openModal}
                                  />
                                </Tooltip>
                              )}
                              renderContent={({ closeModal }) => (
                                <ModalUnassignForm
                                  patientId={patientId}
                                  formId={form.FormId}
                                  onSubmitAction={() =>
                                    setFormsData(optionValue)
                                  }
                                  closeModal={() => closeModal()}
                                />
                              )}
                            />
                          )}
                        </div>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
              <PureModal
                renderTrigger={({ openModal }) => (
                  <IconButton
                    icon={<MdEdit />}
                    onClick={() => openModal()}
                    style={{ display: "none" }}
                    id="onDemandAssignment_AutomaticTrigger"
                  />
                )}
                onClose={() => {setOnDemandSelectedFormId(null);}}
                renderContent={({ closeModal }) => (
                  <AnswerPatientForm
                    patientId={patientId}
                    formId={onDemandSelectedFormId}
                    closeModal={() => {
                      closeModal();
                      setFormsData(true);
                      setOptionValue("Answered");
                      setOnDemandSelectedFormId(null);
                    }}
                  />
                )}
              />
            </table>
          </div>
        ) : (
          <p>No forms found for this patient.</p>
        )
      ) : (
        <Spinner />
      )}
    </div>
  );
}

FormsData.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientTypeData: PropTypes.object,
  isInsideModal: PropTypes.bool,
  patientInfo: PropTypes.object
};
