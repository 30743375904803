import React from "react";
import PropTypes from "prop-types";
import { formatPhone } from "utils/misc";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import VoicemailRecording from "components/screens/vmail-table/vmail-recording";

function VoicemailControls({ vmail, markAsWorkedAPI, markAsListenedAPI }) {
  const userId = useSelector(selectors.getUserId);
  return (
    <div
      style={{
        flex: 1,
        border: "1px solid #DDD",
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        background: "white",
        height: 100
      }}
    >
      <h1
        style={{
          fontSize: "1.8em",
          paddingLeft: 10,
          fontWeight: 300,
          color: "#666"
        }}
      >
        Voicemail from {formatPhone(vmail.from_number)}
      </h1>
      <h1
        style={{
          fontSize: "1.8em",
          paddingLeft: 10,
          fontWeight: 300,
          color: "#666"
        }}
      >
        To: {formatPhone(vmail.to_number)}
      </h1>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: 5,
          height: 100,
          display: "flex",
          gridColumn: "3",
          gridRow: "1 / 3",
          flex: 1
        }}
      >
        {!vmail.listened_to ? (
          <div />
        ) : (
          <button
            title={
              vmail.listened_to
                ? "Click To Mark As Worked"
                : "Voicemail Must First Be Heard Before Marked As Worked"
            }
            onClick={async () => {
              await markAsWorkedAPI.fetchData({ userId, id: vmail.id });
            }}
            className="workedButton"
            disabled={!vmail.listened_to || markAsWorkedAPI.isFetching}
          >
            Work
          </button>
        )}
      </div>
      <div style={{ position: "relative", height: 50 }}>
        <VoicemailRecording
          id={vmail.id}
          showAllFeatures={true}
          autoPlay={false}
          onTimeUpdate={async (currentTime, duration, override) => {
            if (vmail.listened_to != true || override) {
              //We want to trigger an api update to the read field in the db
              //when the audio player plays past a certain point, could be percent of duration or max seconds
              let percentNeeded = 0.1;
              let maxSeconds = 10;
              let currentPercent = currentTime / duration;
              if (
                duration == maxSeconds ||
                (currentPercent >= percentNeeded &&
                  !markAsListenedAPI.isFetching) || override
              ) {
                await markAsListenedAPI.fetchData({ userId, id: vmail.id });
              }
            }
          }}
          preloadAudio="metadata"
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>
          <span title="This Is Who Listened First">Listener: </span>
          {`${vmail.listened_by?.first_name ?? ""}  ${
            vmail.listened_by?.last_name ?? ""
          }`}
        </strong>
        <strong>
          <span title="This Is Who Worked The Voicemail">Worker: </span>
          {`${vmail.worked_by?.first_name ?? ""} ${
            vmail.worked_by?.last_name ?? ""
          }`}
        </strong>
      </div>
    </div>
  );
}
VoicemailControls.defaultProps = { vmail: {} };
VoicemailControls.propTypes = {
  vmail: PropTypes.object,
  markAsListened: PropTypes.func.isRequired,
  markAsWorkedAPI: PropTypes.object.isRequired,
  markAsListenedAPI: PropTypes.object.isRequired
};

export default VoicemailControls;
