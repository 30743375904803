import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { AlertBar, PureModal } from "components/ui";
import moment from "moment";
import BlackListCorrector from "components/profile/blacklist_corrector";
import PhilipsRecallBanner from "./philips-recall";
import { updatePatient } from "actions/patient";
import { useDispatch } from "react-redux";
import { PatientProfileContext } from "./context";

const SpecialInstruction = ({ instruction, index }) => (
  <div className="special-instruction-row">
    <span className="index-number">
      <strong>{index + 1}.</strong>
    </span>
    <span>{instruction.text}</span>
    <span
      className="created-by"
      title={`Created: ${moment(instruction.entered).format("ll")}`}
    >
      <div>{instruction.entered_by} </div>
      <div>{instruction.entered_by_type}</div>
    </span>
  </div>
);

SpecialInstruction.propTypes = {
  instruction: PropTypes.object.isRequired,
  index: PropTypes.number
};

const PatientAlerts = ({
  patientInfo,
  patientId,
  fetchingInfo,
  isCompliant
}) => {
  const dispatch = useDispatch();
  const [complexIsCompliant, setComplexIsCompliant] = useState(false);
  const { company_order_settings } = useContext(PatientProfileContext);

  useEffect(() => {
    if (isCompliant !== undefined && isCompliant !== null)
      setComplexIsCompliant(isCompliant);
    else if (patientInfo) setComplexIsCompliant(patientInfo?.compliance_met);
  }, [isCompliant, patientInfo]);

  /* function getStatusClassName(active, onHold) {
    if (active) {
        if(onHold){
            return "patient-on-hold"
        }
      return "patient-active"
    } else if (status == 2) {
      return "patient-on-hold"
    } else if (status == 3) {
      return "patient-on-hold"
    } else if (status == 4) {
      return "patient-inactive"
    } else {
      return "patient-active"
    }
  }*/

  function getStatusClassName(active, onHold) {
    if (active) {
      if (onHold) {
        return "patient-on-hold";
      } else {
        return "patient-active";
      }
    } else {
      return "patient-inactive";
    }
  }

  const stages = { resupply: 1, compliance: 2 };

  function getStageText(status) {
    if (status == stages.compliance) {
      return "Compliance";
    } else if (status == stages.resupply) {
      return "Resupply";
    } else {
      return "";
    }
  }

  function dateDiff(setupDate) {
    let todaysDate = new Date();
    let amountOfDays = Math.floor(
      (todaysDate - new Date(setupDate)) / (1000 * 60 * 60 * 24)
    );
    if (amountOfDays) {
      return `Day ${amountOfDays}`;
    } else {
      return "Days from set up date not available";
    }
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        {
          <div
            className={`patient-status-banner ${getStatusClassName(
              patientInfo?.active,
              patientInfo?.on_hold
            )}`}
          >
            <h6 className="status-container">
              {getStageText(patientInfo.stage_id)}{" "}
              {patientInfo?.setup_date &&
              patientInfo?.stage_id == stages.compliance &&
              (patientInfo?.active || patientInfo?.on_hold)
                ? `/ ${dateDiff(patientInfo?.setup_date)}`
                : ""}{" "}
              {patientInfo?.stage_id == stages.compliance &&
              complexIsCompliant &&
              patientInfo?.active &&
              !patientInfo?.on_hold
                ? "/ Patient is Compliant"
                : ""}
            </h6>
            {patientInfo?.deactivationReasons?.length > 0 &&
              !patientInfo?.active && (
                <h6 className="status-container">
                  Inactivation Reason:{" "}
                  {patientInfo?.deactivationReasons[0]?.text}
                </h6>
              )}
          </div>
        }
      </div>
      {patientInfo.valid_email === false && (
        <AlertBar color="brown">
          <strong>Invalid Email</strong>
        </AlertBar>
      )}
      {company_order_settings.last_day_to_order_message && (
        <AlertBar color="brown">
          <strong>{company_order_settings.last_day_to_order_message}</strong>
        </AlertBar>
      )}
      {patientInfo.retricted_numbers &&
        patientInfo.retricted_numbers.length > 0 && (
          <PureModal
            renderTrigger={({ openModal }) => (
              <AlertBar color="black">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    openModal();
                  }}
                >
                  <strong>Patient Has Blacklisted Numbers</strong>
                </div>
              </AlertBar>
            )}
          >
            <BlackListCorrector
              patientId={patientId}
              patientInfo={patientInfo}
            />
          </PureModal>
        )}
      {patientInfo.special_instructions && (
        <AlertBar>
          <h4>
            <strong>Special Instructions:</strong>
          </h4>
          {patientInfo.special_instructions.map((instruction, i) => (
            <SpecialInstruction
              key={instruction.value}
              instruction={instruction}
              index={i}
            />
          ))}
        </AlertBar>
      )}
      {patientInfo.opt_in_philips_recall && !fetchingInfo && (
        <PhilipsRecallBanner patientId={patientId} patientInfo={patientInfo} />
      )}
      {patientInfo.automation_failed && (
        <div className="automationFailedAlertBox">
          <h4 style={{ color: "white", fontSize: "1.5em", fontWeight: "800" }}>
            This is a Technology Patient whose automation has failed. You will
            need to get one of the following pieces of patient information while
            on the call
          </h4>
          <ul style={{ listStyleType: "disc", padding: "10px" }}>
            {!patientInfo.valid_email && <li>A Valid Email</li>}
            {!patientInfo.valid_primary_number_for_texting && (
              <li>A Textable Primary Number</li>
            )}
            {!patientInfo.valid_secondary_number_for_texting && (
              <li>A Textable Secondary Number</li>
            )}
          </ul>
          <button
            onClick={() =>
              dispatch(
                updatePatient({
                  patientId,
                  updates: { automation_failed: false }
                })
              )
            }
          >
            Reset Automation Failure
          </button>
        </div>
      )}
    </React.Fragment>
  );
};
PatientAlerts.propTypes = {
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  fetchingInfo: PropTypes.bool,
  isCompliant: PropTypes.bool
};

export default PatientAlerts;
