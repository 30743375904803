import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import PropTypes from 'prop-types'
import moment from 'moment'
import * as R from "ramda";

const makeToolTipKey = ({ key }) => {
  if (key === "totalActivation") {
    return 'Total Activation'
  } else if (key === "totalDeactivation") {
    return 'Total Deactivation'
  } else {
    return key
  }
}

const CustomTooltip = (props) => {
  const sortByActivationCounts = R.sortBy(R.prop('value'))
  const { payload = [], active, label, reasons } = props
  const keyValues = payload && payload[0] ? Object.keys(payload[0].payload) : []
  const keyValueArray = keyValues && keyValues.map(curr => {
    return { key: curr, value: payload[0].payload[curr] }
  })
  const sortedKeyValueArray = sortByActivationCounts(keyValueArray).reverse()
  if (active) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'rgba(211, 211, 211, 0.3)', borderRadius: '5px', padding: '0.25rem', color: 'black', }}>
        <div>
          <strong>{moment(label).format('MMM YYYY')}</strong>
        </div>
        {sortedKeyValueArray.map((obj) => {
          if ((reasons[obj.key] && reasons[obj.key].checked) && obj.key !== 'name') {
            return (
              <div key={obj.key} style={{ color: 'black' }}>
                {`${makeToolTipKey({ key: obj.key })}: ${obj.value}`}
              </div>
            )
          }
        })}
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.bool,
  label: PropTypes.string,
  reasons: PropTypes.object
};

class ActivationReportGraph extends PureComponent {
  state = { reasons: {} }

  componentDidMount() {
    const { groupByReason } = this.props
    this.setState({ reasons: groupByReason })
  }

  handleChange = ({ target }) => {
    const { reasons } = this.state
    const reasonToUpdate = reasons[target.name]
    const updatedReason = { ...reasonToUpdate, checked: !reasons[target.name].checked }
    const updatedReasonToMerge = { [target.name]: updatedReason }
    const updatedReasons = { ...reasons, ...updatedReasonToMerge }
    this.setState({ reasons: updatedReasons })
  }

  render() {
    const { activationReport } = this.props
    const { reasons } = this.state
    return (
      <>
        <LineChart
          width={800}
          height={450}
          data={activationReport.monthByMonthData}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip reasons={reasons} />} />
          {Object.keys(reasons).filter(key => reasons[key].checked).map((key) => {
            return <Line isAnimationActive={false} key={key} type="monotone" dataKey={key} stroke={reasons[key].color || "#2b4059"} />
          })}
        </LineChart>
        <div className='activation-report_graph_legend_container'>
          {Object.keys(reasons).map(key => {
            return (
              <div key={key}>
                <input type='checkbox' id={key} checked={reasons[key].checked} onChange={this.handleChange} name={key}></input>
                <label style={{ color: reasons[key].color }} htmlFor={key}>{key}</label>
              </div>
            )
          })}
        </div>
      </>
    );
  }
}

export default ActivationReportGraph;

ActivationReportGraph.propTypes = {
  activationReport: PropTypes.shape({
    monthByMonthData: PropTypes.array,
    reasons: PropTypes.array
  }),
  active: PropTypes.bool,
  groupByReason: PropTypes.object,
  label: PropTypes.string,
  payload: PropTypes.arrayOf(PropTypes.object),
  reasons: PropTypes.object
};
