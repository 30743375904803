import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'

const IVRUncompletedCallsTableRow = ({ job_date, patient_name, patient_id, company_name, company_id, reason }) => {
    return (
        <tr key={job_date + patient_name}>
            <td> {job_date} </td>
            <td>
                <Link to={`/patients/${patient_id}`}>
                    {patient_name}
                </Link>
            </td>
            <td>
                <Link to={`/companies/${company_id}`}>
                    {company_name}
                </Link></td>
            <td> {reason} </td>
        </tr>
    );
}

IVRUncompletedCallsTableRow.propTypes = {
    job_date: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    patient_id: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    company_id: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired
};

export default IVRUncompletedCallsTableRow;