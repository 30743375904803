import { GET_SERVICE } from './../ActionTypes';
import API from "./../../utils/axios";
import { hideLoader } from "./common";
import { errorMessage } from "./../../../../actions/message";

export const getServices = () => dispatch => {
    const url = "/dme_portal/services";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res && res.data && res.data.services) {
                const filterObj = res.data.services.map((data) => {
                    return {label: data.name,value: data.id};
                });
                return dispatch({
                    type: GET_SERVICE,
                    payload:filterObj
                });
            }
        }else{
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_SERVICE,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err);
        dispatch(hideLoader());
        return dispatch({
            type: GET_SERVICE,
            payload: []
        });
    })
}

