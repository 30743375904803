import React, { Component } from "react";
import PropTypes from "prop-types";
import { ChartHeader } from "components/ui/index";
import Radium from "radium";
import styler from "react-styling";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from "react-tooltip";
import { FaGlobe } from "react-icons/lib/fa";

class OrdersBySleepCoach extends Component {
  render() {
    const cs = this.props.stats;
    const max =
      (cs &&
        cs[0] &&
        Math.max(
          ...cs.map(function (x) {
            return x.count;
          })
        )) ||
      0;

    const { onExport } = this.props;
    return (
      <div style={S.container}>
        <ReactTooltip />
        {onExport && (
          <div
            className="stat-export-icon"
            data-tip="Export"
            onClick={onExport}
          >
            <FaFileExcelO />
          </div>
        )}
        <ChartHeader title="Orders by Sleep Coach" />

        {cs.map(coach => (
          <div key={coach.name} style={S.block}>
            <div style={S.name}>
              {coach.name + coach.contracted_employee ? <FaGlobe /> : ""}
            </div>
            FaGlobe
            <div style={S.bar}>
              <div
                style={{ ...S.orders, width: `${(coach.count / max) * 100}%` }}
              >
                {coach.count}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Radium(OrdersBySleepCoach);

OrdersBySleepCoach.propTypes = {
  stats: PropTypes.array,
  onExport: PropTypes.func.isRequired
};

const S = styler`
  container
    border: 1px solid #DDD
    padding: 16px 20px 20px 20px
    position: relative
  block
    margin-bottom: 10
  name
    font-size: 1.2em
    font-weight: bold
    margin-bottom: 2
  bar
    background-color: #eee
  orders
    background-color: rgb(49, 130, 189)
    padding: 5px 20px;
    margin-right: 20px
    font-size: 1.12em
    color: #fff
    display: inline-block
`;
