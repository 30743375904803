import { post } from "utils/api";
import { errorMessage } from "actions/message";

export const Types = {
  REQUEST_COMPANIES_TABLE: "REQUEST_COMPANIES_TABLE",
  COMPANIES_TABLE_RESPONSE: "GET_COMPANIES_SUCESS",
  COMPANIES_TABLE_FAILURE: "COMPANIES_TABLE_FAILURE",
  REQUEST_EXPORT_COMPANY_TABLE: "REQUEST_EXPORT_COMPANY_TABLE",
  RESPONSE_EXPORT_COMPANY_TABLE: "RESPONSE_EXPORT_COMPANY_TABLE",
  CLEAR_COMPANY_TABLE: "CLEAR_COMPANY_TABLE"
};

export const fetchCompanyTable = (start_date, end_date, patientType) => async dispatch => {
  try {
    dispatch({ type: Types.REQUEST_COMPANIES_TABLE });
    const response = await post(`companies-table`, { start_date, end_date, patientType });
    dispatch({ type: Types.COMPANIES_TABLE_RESPONSE, response });
  } catch (error) {
    dispatch({ type: Types.COMPANIES_TABLE_FAILURE, error });
    dispatch(
      errorMessage("Something went wrong, Failed to get list of companies.")
    );
  }
};

export const clearCompanyTable = () => async dispatch => {
  try {
    dispatch({ type: Types.CLEAR_COMPANY_TABLE });
  } catch (error) {
    dispatch(errorMessage("Something went wrong!"));
  }
};


export const exportCompanyTable = (
  filteredRows,
  startDate,
  endDate,
  visibleColumns
) => ({
  type: Types.REQUEST_EXPORT_COMPANY_TABLE,
  payload: { filteredRows, startDate, endDate, visibleColumns }
});
