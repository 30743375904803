import React from "react";
import { post } from "utils/api";
import { sortInsurances } from "features/bonafide/helpers";
import { useDispatch } from "react-redux";
import {
  invalidatePatientProfileInfo,
  fetchPatientProfileInfo
} from "actions/patient";

const useBonafide = ({ patientId, uses_bonafide_api }) => {
  const dispatch = useDispatch();
  const [bonafidePatientInfo, setBonafidePatientInfo] = React.useState({
    companyInsurances: [],
    lmns: [],
    insurances: [],
    priors: [],
    patientId
  });
  const [errors, setErrors] = React.useState({
    lmns: null,
    insurances: null,
    companyInsurances: null,
    priors: null
  });
  const [patientUpdatesSent, setPatientUpdatesSent] = React.useState(null);

  const [isFetching, setIsFetching] = React.useState(null);
  React.useEffect(() => {
    if (uses_bonafide_api && patientId) getPatientInfo(patientId);
  }, [patientId, uses_bonafide_api]);
  async function getPatientInfo() {
    try {
      setIsFetching(true);
      const {
        companyInsurancesResponse,
        lmnResponse,
        guarantorResponse,
        priorAuthResponse,
        patientUpdated
      } = await post("bonafide/patient-info", {
        patientId
      });
      const patientInfo = {
        companyInsurances:
          companyInsurancesResponse.status === "success"
            ? companyInsurancesResponse.insurances
            : [],
        lmns: lmnResponse.status === "success" ? lmnResponse.LMNs : [],
        insurances:
          guarantorResponse.status === "success"
            ? sortInsurances(guarantorResponse.insurances)
            : [{ insurance: "", memberno: "" }],
        priors:
          priorAuthResponse.status === "success" ? priorAuthResponse.priors : []
      };
      setErrors({
        companyInsurances:
          companyInsurancesResponse.status !== "success"
            ? companyInsurancesResponse.errorMsg
            : null,
        lmns: lmnResponse.status !== "success" ? lmnResponse.errorMsg : null,
        insurances:
          guarantorResponse.status !== "success"
            ? guarantorResponse.errorMsg
            : null,
        priors:
          priorAuthResponse.status !== "success"
            ? priorAuthResponse.errorMsg
            : null
      });
      setBonafidePatientInfo({ ...patientInfo, errors, patientId });
      if (patientUpdated) {
        dispatch(invalidatePatientProfileInfo({ patientId }));
        dispatch(fetchPatientProfileInfo({ patientId }));
      }
    } finally {
      setIsFetching(false);
    }
  }

  async function updatePatientInfo(values) {
    try {
      if ((uses_bonafide_api === true && patientId, !isFetching)) {
        if (values.changed_insurance || values.changed_physician) {
          const updates = {
            insurances: values.bonafide_insurances.map(
              ({ insurance, memberno }) => {
                const bonafideInsurance =
                  bonafidePatientInfo.companyInsurances.find(
                    ins => ins.insurance === insurance
                  );
                return {
                  insurance,
                  memberno,
                  insuranceseq: bonafideInsurance.insuranceseq
                };
              }
            ),
            physicianNpi: values.physician.npi
          };
          if (
            uses_bonafide_api &&
            JSON.stringify(updates) !== patientUpdatesSent
          ) {
            const { response } = await post(
              `bonafide/update-patient-insurance/${patientId}`,
              updates
            );
            if (response && response.status === "success") {
              setPatientUpdatesSent(JSON.stringify(updates));
              alert(
                "Successfully sent updated patient info to external system"
              );
            }
          }
        }
      }
    } catch (error) {
      alert("Error Sending Updated patient info", error.message);
    }
  }

  return {
    bonafidePatientInfo,
    updatePatientInfo,
    errors,
    isFetching
  };
};

export default useBonafide;

export const BonafidePatientContext = React.createContext();
