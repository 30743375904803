import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import moment from "moment";

const AccessLogsPanel = ({ logs, isFetchingLogs }) => (
  <div className="logs-page">
    {!isFetchingLogs ? (
      <div className="log-container">
        <table>
          <thead>
            <tr>
              <th>Accessed By</th>
              <th>Accessed Date</th>
              <th style={{ width: 350 }}>Accessed From Url</th>
              <th>Accessed From IP</th>
            </tr>
          </thead>
          <tbody>
            {logs.map(log => (
              <tr key={log.id}>
                <td>{log.accessedByName || ""}</td>
                <td>
                  {moment(log.accessedDate).format("MMM DD, YYYY hh:mm A")}
                </td>
                <td>{log.accessedFromURL}</td>
                <td>{log.accessedFromIP}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="logs-waiting-container">
        <InlineWaiting />
      </div>
    )}
  </div>
);

AccessLogsPanel.propTypes = {
  logs: PropTypes.array.isRequired,
  isFetchingLogs: PropTypes.bool
};

export default AccessLogsPanel;
