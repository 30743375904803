import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
const AllocationCompanyTable = ({ data }) => {
  const { ascending, sortBy, getSortedArray, toggleSortDirection, setSortBy } =
    useSort({ by: "CompanyName", ascending: true });

  const [isMinimized, setMinimized] = useState(false);

  return (
    <div className="block">
      <div className="block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Allocation Statistics
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <table>
            <TableSortableHeader
              columns={[
                { key: "CompanyName", text: "Company" },
                {
                  key: "PatientsAssigned",
                  text: "Assigned To Coach"
                },
                {
                  key: "PatientsOutreached",
                  text: "Attempted Outreach"
                },
                { key: "CallGoal", text: "Call Goal" },
                {
                  key: "TotalCompanyPatientsInQueue",
                  text: "Today's Eligible Patients"
                },
                { key: "TotalActivePatients", text: "Total Active Patients" }
              ]}
              sortBy={sortBy}
              ascending={ascending}
              setSortBy={setSortBy}
              toggleSortDirection={toggleSortDirection}
            />
            <tbody>
              {getSortedArray(data).map(d => (
                <tr key={d.CompanyName}>
                  <td>{d.CompanyName}</td>
                  <td>{d.PatientsAssigned}</td>
                  <td>{d.PatientsOutreached}</td>
                  <td>{parseInt(d.CallGoal)}</td>
                  <td>{d.TotalCompanyPatientsInQueue}</td>
                  <td>{d.TotalActivePatients}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

AllocationCompanyTable.defaultProps = {
  data: []
};
AllocationCompanyTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  data: selectors.getPatientsAllocated(state)
}))(AllocationCompanyTable);
