import { Types as AuthTypes } from "actions/authentication";
import { createSelector } from "reselect";
import { createReducer } from "utils/reducer";
import { prop } from "ramda";

export default createReducer(
  { error: "", isSubmitting: false },
  {
    [AuthTypes.LOGIN_USER_REQUEST]: state => ({
      ...state,
      error: "",
      isSubmitting: true
    }),
    [AuthTypes.LOGIN_USER_RESPONSE]: state => ({
      ...state,
      error: "",
      isSubmitting: false
    }),
    [AuthTypes.LOGIN_USER_FAILED]: (state, { message }) => ({
      ...state,
      isSubmitting: false,
      error: message || "An unknown error occurred. Please try again later."
    }),
    [AuthTypes.LOGOUT_USER_INACTIVE]: state => ({
      ...state,
      error: "You were logged out due to inactivity."
    }),
    [AuthTypes.TWO_FACTOR_AUTH_REQUIRED]: state => ({
      ...state,
      error: "",
      isSubmitting: false
    }),
    [AuthTypes.TWO_FACTOR_AUTH_APPROVED]: state => ({
      ...state,
      error: "",
      isSubmitting: false
    }),
    [AuthTypes.TWO_FACTOR_AUTH_LOGIN]: state => ({
      ...state,
      error: "",
      isSubmitting: false
    })
  }
);

export const selectors = {
  getLoginError: createSelector([state => state.get("login")], prop("error")),
  getIsLoginSubmitting: createSelector(
    [state => state.get("login")],
    prop("isSubmitting")
  )
};
