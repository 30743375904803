import { call, put, takeEvery } from "redux-saga/effects";
import { message, errorMessage } from "actions/message";
import { Types } from "actions/fulfillment-status";
import * as api from "utils/api";
import { pathOr } from "ramda";

export default function* watchUpdateFulfillmentStatus() {
  yield takeEvery(
    Types.REQUEST_FULFILLMENT_STATUS_UPDATE,
    updateFulfillmentStatus
  );
}

function* updateFulfillmentStatus({ payload: { ids, status } }) {
  try {
    const { successes, failures } = yield call(
      api.put,
      "fulfillment/update_status",
      { ids, status }
    );
    yield put({
      type: Types.RESPONSE_FULFILLMENT_STATUS_UPDATE,
      payload: {
        successes,
        failures
      }
    });
    if (failures && failures.length > 0) {
      yield put(
        errorMessage(
          `Failed to update the following orders: ${failures
            .map(o => o.id)
            .join(",")}`,
          -1
        )
      );
    }
    yield put(
      message(
        `Successfully updated ${successes.length} out of ${ids.length} orders.`,
        -1
      )
    );
  } catch (error) {
    const errorToDisplay = pathOr("", ["response", "body", "status"], error);
    yield put(
      errorMessage(
        `Update Fulfillment: Something went wrong. ${errorToDisplay}`,
        -1
      )
    );
    yield put({ type: Types.FULFILLMENT_STATUS_UPDATE_ERROR, error });
  }
}
