import React from "react";
import { IoCalendar } from "react-icons/lib/io";
import PropTypes from "prop-types";

export default function GridRescheduleAppointmentButton({ chatId, chat_type, openModal }){

    const onClick = () =>{
        openModal(chatId, chat_type);
    }

    return (
        <div onClick={ onClick.bind(null) }>
            <IoCalendar color={"blue"} style={{cursor: "pointer"}}/>
        </div>
    )
}

GridRescheduleAppointmentButton.propTypes = {
    chatId: PropTypes.string,
    chat_type: PropTypes.string,
    openModal: PropTypes.func
}