import React from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";

export const HasAppPermission = React.memo(
  ({ userAppPermissions, permission, userRole, children }) => {
    if (
      userRole === "Administrator" ||
      userRole === "SleepCoach" ||
      userRole === "ComplianceCoach" ||
      userAppPermissions[permission]
    ) {
      return <React.Fragment>{children}</React.Fragment>;
    }
    return null;
  }
);

HasAppPermission.defaultProps = {
  allowedPermissions: []
};

HasAppPermission.propTypes = {
  userAppPermissions: PropTypes.object.isRequired,
  permission: PropTypes.string,
  userRole: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default connect(state => ({
  userAppPermissions: selectors.getUserAppPermissions(state),
  userRole: selectors.getUserRole(state)
}))(HasAppPermission);
