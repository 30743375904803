import React from "react";
import PropTypes from "prop-types";
import "./card.scss";

const Card = ({ children, style, className, ...rest }) => (
  <div className={`ui-card ${className}`} style={style} {...rest}>
    {children}
  </div>
);

Card.defaultProps = { style: {}, className: "" };

Card.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string
};

export default Card;
