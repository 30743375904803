import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { updateFilters } from "features/query/actions";
import { propOr } from "ramda";
import isEqual from "lodash/isEqual";
import { serializeDateFilter, parseDateFilter } from "hooks/useQuery/helpers";

const NewDateRangeFilter = ({ filters, updateFilters, queryId, field, selectStyles }) => {
  const value = filters[field];
  const parsedValue = parseDateFilter(value);
  const [tmpFilter, setTmpFilter] = React.useState({
    ...parsedValue,
    mode: propOr("before", "mode", parsedValue)
  });

  let filterRef = React.useRef({});

  useEffect(() => {
    filterRef.current = tmpFilter;
  }, [tmpFilter]);

  const applyFilters = () => {
    const queryValue = filterRef.current.date ? filterRef.current : {};
    if (
      queryValue.mode === "beforeafter" &&
      (!queryValue.date2 || !queryValue.date)
    ) {
      return;
    } else if (!isEqual(parsedValue, queryValue)) {
      updateFilters(queryId, {
        ...filters,
        [field]: serializeDateFilter(
          queryValue.mode == "beforeafter" && queryValue.date2 < queryValue.date // swap dates if date2 < date
            ? {
                ...queryValue,
                date: queryValue.date2,
                date2: queryValue.date
              }
            : queryValue
        )
      });
    }
  };

  useEffect(() => {
    return () => {
      applyFilters();
    };
  }, []);

  return (
    <div className="dropdown-content">

    
    <ul className="dropdown-list" onClick={e => e.stopPropagation()}>
      <li
        className="dropdown-command"
        onClick={() => {
          setTmpFilter({ mode: "before" });
        }}
      >
        Clear
      </li>
      <li style={{ padding: 5, paddingTop: 15 }}>
        <select
          value={tmpFilter.mode}
          onChange={({ target }) => {
            setTmpFilter(({ date, date2 }) => ({
              mode: target.value,
              date,
              date2: target.value === "beforeafter" ? date2 : ""
            }));
          }}
          style={ selectStyles ? { ...selectStyles, margin: 0 } : { margin: 0 }}
        >
          <option value="before">Before</option>
          <option value="after">After</option>
          <option value="beforeafter">Between</option>
        </select>
      </li>
      <li style={{ padding: 5 }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <input
            type="date"
            value={tmpFilter.date ?? ""}
            onChange={({ target }) => {
              setTmpFilter(x => ({ ...x, date: target.value }));
            }}
            style={{ margin: 0, marginTop: 10 }}
          />
          {tmpFilter.mode === "beforeafter" && (
            <>
              <span style={{ padding: 3 }}>And</span>
              <input
                type="date"
                style={{ margin: 0 }}
                value={tmpFilter.date2 ?? ""}
                onChange={({ target }) => {
                  setTmpFilter(x => ({ ...x, date2: target.value }));
                }}
              />
            </>
          )}
        </div>
      </li>
      </ul>
      </div>
  );
};

export default connect(
  (state, props) => ({
    filters: selectors.getQueryFilters(state, props.queryId)
  }),
  {
    updateFilters
  }
)(NewDateRangeFilter);

NewDateRangeFilter.propTypes = {
  queryId: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  selectStyles: PropTypes.object
};
