import React from "react";
import { useLocation } from "react-router-dom";
import { PageHeader } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import useRowSelect, { SelectRowContext } from "hooks/useRowSelectNew";
import { useOrdersTable } from "./use-table";
import OrdersStatusTableControls from "./table-controls";

const OrderStatusTable = () => {
  const location = useLocation();

  const orderStatus = location?.state?.status ?? "new";
  const statusText = displayOrderStatusText(orderStatus);
  const initialQuery = {
    sort: { by: "submitted", direction: "desc" },
    filters: { status: orderStatus, order: "all" },
    pagination: { page: 1, per: 150 }
  };
  const { tableRows, tableColumns } = useOrdersTable(orderStatus);
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("orders", initialQuery, "orders");
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "id", query, row => ({
    invoice_number: row.invoice_number
  }));
  React.useEffect(() => {
    queryDispatcher({
      type: "updateQuery",
      ...initialQuery
    });
  }, [orderStatus]);
  return (
    <div key={`${orderStatus}`} className="order-status-table">
      <PageHeader title={`${statusText} Orders`} />
      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <Table
          columns={tableColumns}
          rows={rows}
          query={query}
          data={data}
          queryDispatcher={queryDispatcher}
          isFetching={isFetching}
          fetchData={fetchData}
          containerClass="query-table orders-table static-columns"
          TableRow={row => (
            <tr className={`task-row ${row.index % 2 === 0 ? "even" : "odd"}`}>
              {tableRows.map((Row, key) => (
                <Row {...row} key={key} />
              ))}
            </tr>
          )}
          rowKey="id"
          perPageOptions={[
            { text: "50", value: 50 },
            { text: "150", value: 150 },
            { text: "500", value: 500 }
          ]}
          Controls={
            <OrdersStatusTableControls
              query={query}
              selectedRows={selectedRows}
              queryDispatcher={queryDispatcher}
              clearSelectedRows={clearSelectedRows}
              isFetching={isFetching}
              refreshData={() => {
                fetchData(query);
              }}
            />
          }
        />
      </SelectRowContext.Provider>
    </div>
  );
};

function displayOrderStatusText(status) {
  switch (status) {
    case "new":
      return "New";
    case "completed":
      return "Completed";
    case "delayed":
      return "Delayed";
    case "pending":
      return "Pending Confirmation";
    case "ready":
      return "Accepted";
    case "follow_up":
      return "Coach Follow Up";
    case "on_hold":
      return "On Hold";
    case "incorrect":
      return "Incorrect";
    case "billed":
      return "Billed";
    case "rejected":
      return "Rejected";
    case "pending_buildout":
      return "Pending Buildout";
    case "location_assistance_needed":
      return "Location Assistance Needed";
    case "integration_follow_up":
      return "Integration Follow Up";
    case "ready_for_review":
      return "Ready for Review";
    case "internal_processing":
      return "Internal Processing";
    case "voided":
      return "Voided";
    case "accepted":
      return "Accepted";
    case "backordered":
      return "Backordered";
    case "pending_fulfillment":
      return "Pending Fulfillment";
    case "pending_shipment":
      return "Pending Shipment";
    case "order_loading":
      return "Order Loading";
    default:
      return status;
  }
}

export default OrderStatusTable;
