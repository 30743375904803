import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectors } from "reducers";
import moment from 'moment'
import * as R from 'ramda'
import { compose, withProps } from 'recompose'
import {
    withQuery,
    withTableHandlers,
    withApi,
    withHeaderFooter
} from 'features/query'
import withBatch from 'features/batch'
import Table from 'components/ui/impl/table'
import MdCheck from "react-icons/lib/md/check";
import MdClose from "react-icons/lib/md/close";

/* eslint-disable react/prop-types */

const formatDate = date => date ? moment(date).format('MM/DD/YYYY h:mm:ss a') : '';

const IVRWorkTable = compose(
    connect((state, { queryId, apiId }) => ({
        queryId: queryId,
        initialQuery: {
            sort: {
              by: 'lastUpdatedPatientUpdateRequestDate',
              direction: "desc"
            },
            pagination: {
                per: 15,
                page: 1
            },
            filters: {
              teamName: selectors.getUserRole(state) === "SleepCoach" ? [selectors.getUserTeam(state)] : [],
              allJobsAreCompleted: [false]
            },
            searches: {}
        },
        apiId: apiId,
        teams: selectors.getTeamNameOptions(state),
        companies: selectors.getFormOptionsCompany(state),
        sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state)
    })), 
    //This begins the other HOCs
    withQuery(),
    withApi({
        api: {
            route: 'IVR/Work',
            responseKey: 'patients',
            idKey: 'patientGuid',
            entityKey: 'ivrWork'
        }
    }),
    withBatch({ recordIdKey: 'id' }),
    withProps(({ teams, loadJobs, loadPatient, companies, sleepCoachOptions }) =>
    ({
            columns: R.filter(R.is(Object), [
                {
                    header: "Link",
                    key: 'loadJobs',
                    format: ({ patientGuid }) => (
                      <span
                        style={{
                          cursor: "pointer",
                          color: "blue"
                        }}
                        onClick={() => loadJobs(patientGuid)}
                      >
                        Go To Jobs
                      </span>
                    )
                },
                {
                    header: 'Patient',
                    field: 'patientName',
                    format: ({ patientGuid, patientName }) => (
                      <span
                        title={patientGuid}
                        style={{
                          cursor: "pointer",
                          color: "blue"
                        }}
                        onClick={() => loadPatient(patientGuid)}
                      >
                        {patientName ? patientName : ""}
                      </span>
                    )
                },
                {
                    header: 'Created Date',
                    field: 'lastestUpdateRequestCreatedDate',
                    format: ({ lastestUpdateRequestCreatedDate }) => formatDate(lastestUpdateRequestCreatedDate)
                }, 
                {
                    header: 'Last Updated',
                    field: 'lastUpdatedPatientUpdateRequestDate',
                    format: ({ lastUpdatedPatientUpdateRequestDate }) => formatDate(lastUpdatedPatientUpdateRequestDate)
                }, 
                {
                  field: "companyName",
                  header: "Company",
                  filter: "companyGuid",
                  filters: companies,
                  filterDownSelect: true
                },
                {
                  field: "teamName",
                  header: "Team",
                  filter: "teamName",
                  filters: teams
                },
                {
                  field: "assigned_sleep_coach_name",
                  header: "Sleep Coach",
                  filter: "assigned_sleep_coach_id",
                  filterDownSelect: true,
                  filters: sleepCoachOptions
                },
                {
                    header: 'Unprocessed Count',
                    field: 'unprocessedJobs',
                    format: ({ unprocessedJobs }) => unprocessedJobs ? unprocessedJobs : 0,
                },
                {
                    header: 'Completed',
                    field: "allJobsAreCompleted",
                    filters: [
                        {
                            text: 'Yes',
                            value: true
                        },
                        {
                            text: 'No',
                            value: false
                        }
                    ],                   
                    format: ({ unprocessedJobs }) => (
                      <span>
                        {unprocessedJobs > 0 ? (
                          <MdClose color="red" size={18} />
                        ) : (
                          <MdCheck color="green" size={18} />
                        )}
                      </span>
                    )
                },
            ])
    })),
    withHeaderFooter({
        perPageOptions: [15, 150, 300, 500],
    }),
    withTableHandlers()
)(Table)

IVRWorkTable.propTypes = {
    loadJobs : PropTypes.func.isRequired,
    loadOrder: PropTypes.func.isRequired,
    loadPatient: PropTypes.func.isRequired,
    queryId: PropTypes.string.isRequired,
    apiId: PropTypes.string.isRequired,
}

export default IVRWorkTable
