import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  searchPhysicians,
  invalidatePhysicianSearch
} from "actions/physician-search";
import { STATES } from "utils/enum";
import { isValidPhone, isValidZip, validateNpiNumber } from "utils/redux-form";
import { InlineWaiting, IconButton } from "components/ui";
import PhysicianSearchResults from "components/physician-search/physician-search-results";
import FaEraser from "react-icons/lib/fa/eraser";
import FaStethoscope from "react-icons/lib/fa/stethoscope";
import MdRedo from "react-icons/lib/md/redo";
import MdSearch from "react-icons/lib/md/search";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import PhoneNumberField from "components/forms/phone-number-field";
import { omit, propOr } from "ramda";

const PatientPhysicianForm = ({
  isUpdatingPatient,
  initialValues,
  isFetchingPhysicians,
  searchPhysicians,
  updatePatientPhysician,
  physician
}) => {
  const [first_name = "", last_name = ""] = propOr("", "name", physician).split(
    " "
  );
  React.useEffect(() => {
    return () => {
      invalidatePhysicianSearch();
    };
  }, []);
  return (
    <Formik
      initialValues={
        initialValues
          ? initialValues
          : {
              first_name,
              last_name,
              address: propOr(
                {
                  street_address: "",
                  city: "",
                  state: ""
                },
                "address",
                physician
              ),
              npi: propOr("", "npi", physician),
              fax: propOr("", "fax", physician),
              phone: propOr("", "phone", physician)
            }
      }
      onSubmit={values => {
        const name = `${values.first_name} ${values.last_name}`;
        updatePatientPhysician({ ...values, name });
      }}
    >
      {({ values, setValues, isValid }) => (
        <div className="patient-physician">
          <div className="physician-search-header">
            <h3>
              <FaStethoscope /> &nbsp; Physician NPI Lookup
            </h3>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridGap: 20
              }}
            >
              <span
                className="clear-form"
                onClick={() => {
                  setValues({
                    first_name,
                    last_name,
                    address: propOr(
                      {
                        street_address: "",
                        city: "",
                        state: ""
                      },
                      "address",
                      physician
                    ),
                    npi: propOr("", "npi", physician),
                    fax: propOr("", "fax", physician),
                    phone: propOr("", "phone", physician)
                  });
                }}
              >
                <MdRedo /> Reset
              </span>
              <span
                className="clear-form"
                onClick={() => {
                  setValues({
                    first_name: "",
                    last_name: "",
                    address: {
                      street_address: "",
                      city: "",
                      zip: "",
                      state: ""
                    },
                    npi: "",
                    fax: "",
                    phone: ""
                  });
                }}
              >
                <FaEraser /> Clear
              </span>
            </div>
          </div>
          <Form className="patient-physician-form">
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  gridGap: 10
                }}
              >
                <FormikField
                  label="NPI Number"
                  name="npi"
                  type="number"
                  validate={validateNpiNumber}
                />
                <IconButton
                  type="button"
                  onClick={() => searchPhysicians({ npi: values.npi })}
                  title="Lookup Physician by NPI #"
                  style={{
                    height: 33,
                    width: 40,
                    alignSelf: "center"
                  }}
                  icon={<MdSearch />}
                />
              </div>
            </div>
            <div className="name-fields">
              <FormikField label="First Name" name="first_name" type="text" />
              <FormikField label="Last Name" name="last_name" type="text" />
            </div>
            <div>
              <FormikField
                label="Street Address"
                name="address.street_address"
                type="text"
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  gridGap: 5
                }}
              >
                <FormikField label="City" name="address.city" type="text" />
                <FormikField
                  label="State"
                  name="address.state"
                  component="select"
                >
                  <option />
                  {STATES.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </FormikField>
                <FormikField
                  label="Zip Code"
                  name="address.zip"
                  type="text"
                  validate={isValidZip}
                />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: 20
              }}
            >
              <FormikField
                label="Phone Number"
                name="phone"
                type="tel"
                component={PhoneNumberField}
                validate={isValidPhone}
              />
              <FormikField
                label="Fax Number"
                name="fax"
                type="tel"
                component={PhoneNumberField}
                validate={isValidPhone}
              />
            </div>
            <div className="form-actions">
              {isUpdatingPatient ? (
                <InlineWaiting />
              ) : (
                <button
                  className="submit-button"
                  type="submit"
                  disabled={!isValid}
                >
                  Save
                </button>
              )}
              {isFetchingPhysicians ? (
                <InlineWaiting />
              ) : (
                <button
                  onClick={() => {
                    searchPhysicians(omit(["npi"], values));
                  }}
                  type="button"
                >
                  Search
                </button>
              )}
            </div>
            <PhysicianSearchResults
              handlePhysicianSelect={values => {
                setValues(values);
              }}
            />
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default connect(
  (state, { patientId }) => ({
    isFetchingPhysicians: selectors.getIsFetchingPhysicians(state),
    isUpdatingPatient: state.getIn([
      "fetch",
      "UPDATE_PATIENT",
      patientId,
      "isFetching"
    ]),
    physicians: selectors.getPhysicianSearchResults(state)
  }),
  { searchPhysicians, invalidatePhysicianSearch }
)(PatientPhysicianForm);

PatientPhysicianForm.propTypes = {
  isUpdatingPatient: PropTypes.bool,
  physician: PropTypes.object.isRequired,
  searchPhysicians: PropTypes.func.isRequired,
  isFetchingPhysicians: PropTypes.bool,
  invalidatePhysicianSearch: PropTypes.func.isRequired,
  updatePatientPhysician: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

PatientPhysicianForm.defaultProps = {
  physician: {
    name: "",
    address: { street_address: "", city: "", state: "" },
    npi: "",
    fax: "",
    phone: ""
  }
};
