import React, { Component } from 'react'
import PropTypes from 'prop-types';
import includes from 'lodash/includes'
import without from 'lodash/without'

const withTableHandlers = (config = {}) => WrappedComponent => (
  class WithTableHandlers extends Component {
    static propTypes = {
      updateSortBy: PropTypes.func.isRequired,
      toggleSortDirection: PropTypes.func.isRequired,
      addFilter: PropTypes.func.isRequired,
      removeFilter: PropTypes.func.isRequired,
      setFilters: PropTypes.func.isRequired,
      clearFilters: PropTypes.func.isRequired,
      addSearch: PropTypes.func.isRequired,
      removeSearch: PropTypes.func,
      query: PropTypes.object,
      columns: PropTypes.arrayOf(PropTypes.object),
      records: PropTypes.arrayOf(PropTypes.object),
      isFetching: PropTypes.bool,
      forceBatchReset: PropTypes.bool,
      batchClear: PropTypes.func
    }
    static displayName = `WithTableHandlers(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
    static defaultProps = {
      columns: config.columns,
      forceBatchReset: config.forceBatchReset
    }    

    handleHeaderClick = ({ sort, field }) => {
      const sortField = sort || field
      if (sortField) {
        const { query: { sort: { by } } } = this.props
        if (by !== sortField) {
          this.props.updateSortBy(sortField)
        } else {
          this.props.toggleSortDirection(sortField)
        }
      }
      this.handleBatchReset()
    }

    handleBatchReset = () => {
      const { forceBatchReset, batchClear } = this.props;
      if(forceBatchReset && typeof batchClear === 'function') {
        batchClear()
      }
    }

    handleAddFilter = ({ value }, { filter, field }) => {
      this.props.addFilter(filter || field, value)
      this.handleBatchReset()
    }

    handleRemoveFilter = ({ value }, { filter, field }) => {
      this.props.removeFilter(filter || field, value)
      this.handleBatchReset()
    }

    handleClearFilters = ({ filter, field }) => {
      this.props.clearFilters(filter || field)
      this.handleBatchReset()
    }

    handleInvertFilters = ({ filter, field, filters: filterOptions }) => {
      const {
        query: { filters: queryFilters },
        setFilters
      } = this.props
      const filterKey = filter || field
      const activeFilters = queryFilters[filterKey] || []
      const filterOptionIds = filterOptions.map(({ value }) => value)
      const filtersToSet = without(filterOptionIds, ...activeFilters)
      setFilters(filterKey, filtersToSet)
      this.handleBatchReset()
    }

    handleAddSearch = ( value , { search, field }) => {
      this.props.addSearch((search.by || field), value)
      this.handleBatchReset()
    }

    handleRemoveSearch = ({ search, field }) => {
      this.props.removeSearch((search.by || field))
      this.handleBatchReset()
    }

    render() {
      const {
        query: {
          sort,
          filters
        },
        records,
        isFetching
      } = this.props
      const {
        columns = [],
        ...passThroughProps
      } = this.props
      const passedColumns = columns.map(c => ({
        ...c,
        sorted: sort && (c.sort || c.field) === sort.by ? sort.direction : undefined,
        filters: filters && c.filters && c.filters.map(f => ({
          ...f,
          active: includes(filters[c.filter || c.field], f.value),
        })),
        search: c.search &&   
        {      
          by: c.search.by ? c.search.by : c.field,
          value: "",
          placeholder: c.search.placeholder ? c.search.placeholder : "Search..."
        }
      }))
      return (
        <WrappedComponent
          columns={passedColumns}
          rows={records}
          isLoading={isFetching}
          onHeaderClick={this.handleHeaderClick}
          onAddFilter={this.handleAddFilter}
          onRemoveFilter={this.handleRemoveFilter}
          onClearFilters={this.handleClearFilters}
          onInvertFilters={this.handleInvertFilters}
          onAddSearch={this.handleAddSearch}
          onRemoveSearch={this.handleRemoveSearch}
          {...passThroughProps} />
      )
    }
  }
)

export default withTableHandlers
