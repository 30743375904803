import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const CompanyOrders = ({ data, minimizeAll }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "CompanyName", ascending: true });
  const [isMinimized, setMinimized] = useState(false);
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  return (
    <div className="block orders">
      <div className="block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Orders
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <table>
            <TableSortableHeader
              columns={[
                { key: "CompanyName", text: "Company" },
                { key: "accepted_orders_count", text: "Accepted" },
                { key: "backordered_orders_count", text: "Backordered" },
                { key: "completed_orders_count", text: "Completed" },
                { key: "coach_followup_count", text: "Coach Follow-Up" },
                { key: "incorrect_orders_count", text: "Incorrect" },
                {
                  key: "internal_processing_orders_count",
                  text: "Internal Processing"
                },
                { key: "new_orders_count", text: "New" },
                { key: "on_hold_orders_count", text: "On Hold" },
                {
                  key: "pending_fullfillment_orders_count",
                  text: "Pending Fulfillment"
                },
                { key: "pending_orders_count", text: "Pending" },
                {
                  key: "pending_shipment_orders_count",
                  text: "Pending Shipment"
                },
                {
                  key: "pending_buildout_orders_count",
                  text: "Pending Buildout"
                },
                {
                  key: "location_assistance_needed_orders_count",
                  text: "Location Assistance"
                },
                { key: "submitted_orders_count", text: "Submitted" },
                { key: "rejected_orders_count", text: "Rejected" },
                { key: "non_rejected_orders", text: "Non-Rejected" }
              ]}
              sortBy={sortBy}
              ascending={ascending}
              setSortBy={setSortBy}
              toggleSortDirection={toggleSortDirection}
            />
            <tbody>
              {getSortedArray(data).map(d => (
                <tr key={d.CompanyName}>
                  <td>{d.CompanyName}</td>
                  <td>{d.accepted_orders_count}</td>
                  <td>{d.backordered_orders_count}</td>
                  <td>{d.completed_orders_count}</td>
                  <td>{d.coach_followup_count}</td>
                  <td>{d.incorrect_orders_count}</td>
                  <td>{d.internal_processing_orders_count}</td>
                  <td>{d.new_orders_count}</td>
                  <td>{d.on_hold_orders_count}</td>
                  <td>{d.pending_fullfillment_orders_count}</td>
                  <td>{d.pending_orders_count}</td>
                  <td>{d.pending_shipment_orders_count}</td>
                  <td>{d.pending_buildout_orders_count}</td>
                  <td>{d.location_assistance_needed_orders_count}</td>
                  <td>{d.submitted_orders_count}</td>
                  <td>{d.rejected_orders_count}</td>
                  <td>{d.non_rejected_orders}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

CompanyOrders.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool
};

export default connect(state => ({
  data: selectors.getTeamOrders(state)
}))(CompanyOrders);
