import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { setFilter, initFilters } from "actions/inventory";
import InlineWaiting from "components/ui/inline-waiting";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import ProductSelect from "./product-select";

const AddEquipmentForm = ({
  setFilter,
  filterState,
  withQuantity,
  equipmentTypeOptions,
  manufacturerOptions,
  manufacturerEquipment,
  handleSubmit,
  isSubmitting,
  current_equip,
  validateComponent,
  patientId,
  initFilters,
  initialFilter,
  disabled
}) => {
  React.useEffect(() => {
    initFilters({
      ...initialFilter,
      compatible_equip: current_equip
    });
  }, []);

  const validateNumber = value => {
      let errorMessage;

      if (value < 0)
          errorMessage = 'the field must be greater than zero';
      return errorMessage;
  }
  return (
    <Formik
      initialValues={{
        id: "",
        equipmentTypeId: "",
        manufacturerId: "",
        addQuantity: ""
      }}
      validate={values => {
        let errors = {};
        if (!values.id) {
          errors.id = "Required";
        }
        if (withQuantity && !values.addQuantity) {
          errors.addQuantity = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { resetForm }) => {
        if (values.addQuantity > 9) {
          if (
            window.confirm(
              `Are you sure you want to set quantity to ${values.addQuantity} for this item?`
            )
          ) {
            handleSubmit(values);
            resetForm();
          }
        } else {
          handleSubmit(values);
          resetForm();
        }
      }}
    >
      {({ values, handleChange, handleBlur, setFieldValue, isValid }) => (
        <Form>
          <div className="add-equipment-form">
            <label className="compatible-equip-checkbox">
              <span>Compatible With Patients Current Equipment</span>
              <input
                type="checkbox"
                checked={!!filterState.compatibleEquipment}
                onChange={e => {
                  setFilter("compatibleEquipment", e.target.checked);
                  setFieldValue("id", "");
                }}
              />
            </label>
            <div className="filter-selections">
              <FormikField
                name="equipmentTypeId"
                label="Equipment Type"
                component="select"
                onChange={e => {
                  handleChange(e);
                  setFilter("product_type", e.target.value);
                  // setFilter("manufacturer_id", "");
                  // setFieldValue("manufacturerId", "");
                  setFieldValue("id", "");
                }}
              >
                <option />
                {equipmentTypeOptions.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
              <FormikField
                name="manufacturerId"
                label="Manufacturer"
                component="select"
                onChange={e => {
                  handleChange(e);
                  setFilter("manufacturer_id", e.target.value);
                  setFieldValue("id", "");
                }}
              >
                <option />
                {manufacturerOptions
                  .filter(
                    m =>
                      values.equipmentTypeId &&
                      {}.hasOwnProperty.call(
                        manufacturerEquipment,
                        `${values.equipmentTypeId}${m.value}`
                      )
                  )
                  .map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
              </FormikField>
            </div>
            <div className="product-select-row">
              <ProductSelect
                name="id"
                label="Product *"
                filter={{ ...filterState, compatible_equip: current_equip }}
                onChange={handleChange}
                onBlur={handleBlur}
                patientId={patientId}
              />
              {withQuantity && (
                <FormikField
                  name="addQuantity"
                  label="Quantity *"
                  type="number"
                  validate={validateNumber}
                />
              )}
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <button
                  className="submit-button"
                  type="submit"
                  disabled={disabled || !isValid}
                >
                  Add Equipment
                </button>
              )}
            </div>
            {validateComponent && validateComponent({ ...values })}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default connect(
  state => ({
    filterState: selectors.getInventoryFilterState(state),
    equipmentTypeOptions: selectors.getEquipmentTypeOptions(state),
    manufacturerOptions: selectors.getManufacturerOptions(state),
    manufacturerEquipment: selectors.getManufacturerEquipment(state)
  }),
  { initFilters, setFilter }
)(AddEquipmentForm);

AddEquipmentForm.propTypes = {
  current_equip: PropTypes.arrayOf(PropTypes.string),
  equipmentTypeOptions: PropTypes.array.isRequired,
  filterState: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initFilters: PropTypes.func.isRequired,
  initialFilter: PropTypes.object,
  isSubmitting: PropTypes.bool,
  manufacturerEquipment: PropTypes.object.isRequired,
  validateComponent: PropTypes.func,
  manufacturerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  withQuantity: PropTypes.bool,
  patientId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

AddEquipmentForm.defaultProps = {
  initialFilter: {},
  disabled: false
};
