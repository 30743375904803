import React, { Component } from "react";
import PropTypes from "prop-types";
import { browserHistory } from "browser-history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import A from "action-types";
import * as api from "utils/api";
import { formatPhone } from "utils/misc";
import Radium from "radium";
import styler from "react-styling";
// import AudioPlayer from "../../ui/audio-player/index";
import VmailRecording from "components/screens/vmail-table/vmail-recording";
import moment from "moment";
import Icons from "./icons";

export function setToWorkedVMail(userID, id, currentVMGuid) {  
  return {
    type: A.VMAIL.WORKED,//changed to worked at some point
    callAPI: () => api.put(`voicemails/${id}`, { id: userID, worked: true }),
    ready: { refs: ["vmails", "profileReady"] },
    payload: { currentVMGuid}
    
  };
}

export function listenedToVMail(userID, id, currentVMGuid) {
  return {
    type: A.VMAIL.LISTENED_TO,
    callAPI: () => api.put(`voicemails/${id}/listened`, { id: userID, listenedTo: true }),    
    payload: { currentVMGuid}
  };
}

class VMailProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listenedToVMailCalled: false,
      settingVmailToWorked: false
    };
  } 
  
  handleReadVoicemail(currentTime, duration, userID, id, vmail, currentVMGuid) {
    if (vmail.get("listened_to") != true) {
      //We want to trigger an api update to the read field in the db 
      //when the audio player plays past a certain point, could be percent of duration or max seconds
      let percentNeeded = .1;
      let maxSeconds = 10;      
      let currentPercent = currentTime / duration;
      if (duration == maxSeconds || currentPercent >= percentNeeded) {
        if (this.state.listenedToVMailCalled === false) {
          this.setState({
            ...this.state,
            listenedToVMailCalled: true
          }, () => { this.props.listenedToVMail(userID, id, currentVMGuid) })
        }
      }      
    }    
  }
  render() {
    const { vmail, setToWorkedVMail, id, user, orderedVmailIdsArray } = this.props;
    const closeButton = { path: `/phones/voicemails`, value: "Close VoiceMail" };
    const userID = user.getIn(["id"]);

    //This handles the forward and backward progress buttons for the profile, they use an ordered array in the query state in redux, 
    //b/c the voicemailRows were out of order from the network call in the entities section of redux.
    //Guids are passed to parent to handle loading the next profile from Redux state
    const lastIndexForAPICall = orderedVmailIdsArray.length - 1;
    const currentVMIndex = orderedVmailIdsArray.findIndex(x => x === id);    
    const nextVMId = (currentVMIndex != lastIndexForAPICall) ? orderedVmailIdsArray[currentVMIndex + 1] : false;
    const previousVMId = (currentVMIndex != 0) ? orderedVmailIdsArray[currentVMIndex - 1] : false;  
    
    const addressConcat = vmail.getIn(["from_address", "city"])
      + ", " + vmail.getIn(["from_address", "state"])
      + " " + vmail.getIn(["from_address", "zip"]);    
    const fontSize = "35px";
    
    return (
      <div style={{height: "10px"}}>
        <header style={S.header}>
          <div className="row">
            <div style={S.titleDiv} className="small-7 columns">
              <div title={"ID: " + vmail.get("vmID") + " | " + "Created at: " + moment(vmail.getIn("created_at")).format("MM / DD / YYYY h:mm a")}>
                  <h1 style={S.h1}>
                  {`Voicemail from ${formatPhone(vmail.getIn(["from_number"]))}`}           
                </h1>                
                </div>
            </div>
            
            <div className="small-3 columns" style={S.button}>            
              <button
                title={"Previous Voicemail In Table"}
                disabled={!previousVMId}
                className="prevIcon" style={{ visibility: !previousVMId ? "hidden" : "visible" }}
                onClick={() => {
                  this.props.loadNewVM(previousVMId)
                  browserHistory.push({
                    pathname: `/phones/voicemails/${previousVMId}`
                  });
                }
                }>                
                <Icons.ArrowBack                   
                  size={fontSize}                  
                  icon='ios-arrow-back'
                />
              </button>
                      
              <button
                title={"Next Voicemail In Table"}
                disabled={!nextVMId}
                className="nextIcon"
                style={{ visibility: !nextVMId ? "hidden" : "visible" }}
                onClick={() => {
                  this.props.loadNewVM(nextVMId);
                  browserHistory.push({
                    pathname: `/phones/voicemails/${nextVMId}`
                  })
                }
                }>                
                <Icons.ArrowForward                  
                    size={fontSize}
                  />
              </button>
              
              <button className="closeIcon" onClick={() => browserHistory.push({
                pathname: closeButton.path
              })}
                title="Close Voicemail">                
                  <Icons.Close
                    size={fontSize}
                  />                
              </button>             
           </div>           
          </div>
        </header>
        
        <span
          className="audioPlayer">
          <VmailRecording
            showAllFeatures={true}
            id={vmail.getIn(["id"])}
            autoPlay={false}
            onTimeUpdate={(currentTime, duration) =>
              this.handleReadVoicemail(currentTime, duration, userID, id, vmail, vmail.get("id"))}
          />
          {/* <AudioPlayer */}
          {/*   showAllFeatures={true} */}
          {/*   src={vmail.getIn(["twilio_link"]) && vmail.getIn(["twilio_link"])} */}
          {/*   autoPlay={false} */}
          {/*   onTimeUpdate={(currentTime, duration) => */}
          {/*     this.handleReadVoicemail(currentTime, duration, userID, id, vmail, vmail.get("id"))} */}
          {/* /> */}
        </span>
        
        <span className="vmToNumber" style={{ visibility: vmail.getIn(["to_number"]) == null ? "hidden" : "visible" }}>
          To: {vmail.getIn(["to_number"])}
        </span>
          
        <span className="vmAdddress"
          style={{ visibility: addressConcat.includes("null") ? "hidden" : "visible" }}
        >
          {addressConcat}
        </span>

        <div className="workedInfo">
            <button
              title={
                vmail.getIn(["listened_to"])
                  ? "Click To Mark As Worked"
                  : "Voicemail Must First Be Heard Before Marked As Worked"
              }
              disabled={
                (!vmail.getIn(["listened_to"]) &&
                  vmail.getIn(["twilio_link"]) &&
                  vmail.getIn(["duration"]) > 2) ||
                this.state.settingVmailToWorked
              }
              style={{ visibility: vmail.getIn(["worked"]) ? "hidden" : "visible" }}
              onClick={async () => {
                try {
                  this.setState({ ...this.state, settingVmailToWorked: true });
                  await setToWorkedVMail(userID, id, vmail.get("id"));
                } catch (e) {
                  /* handle error */
                } finally {
                  this.setState({ ...this.state, settingVmailToWorked: false });
                }
              }}
              className="workedVMButton"
            >
              Worked
            </button>
            <p className="listenedBy"
            style={{ visibility: vmail.getIn(["listened_to"]) ? "visible" : "hidden" }}>
            <strong>
              <span title="This Is Who Listened First">Listener: </span>
              {vmail.getIn(["listened_by", "first_name"]) + " "
                + vmail.getIn(["listened_by", "last_name"])}
            </strong>
          </p>

          <p className="workedBy"
            style={{ visibility: vmail.getIn(["worked"]) ? "visible" : "hidden" }}>
            <strong>
              <span title="This Is Who Worked The Voicemail">Worker: </span>
                {vmail.getIn(["worked_by", "first_name"]) + " "
                + vmail.getIn(["worked_by", "last_name"])}
            </strong>
          </p>          
        </div> 
      </div>
      )
  }
}

export default connect(
  state => ({       
    vmailTable: state.getIn(["entities", "voicemailRows"]),
    orderedVmailIdsArray: state.getIn(["query"]).apiById.voicemails.ids
  }),
  dispatch =>
    bindActionCreators(
      {        
        setToWorkedVMail,
        listenedToVMail
      },
      dispatch
    )
)(Radium(VMailProfile));

VMailProfile.propTypes = {  
  loadNewVM: PropTypes.func,
  vmailTable: PropTypes.object.isRequired,
  ready: PropTypes.bool,
  user: PropTypes.object,
  id: PropTypes.string.isRequired,
  vmail: PropTypes.object.isRequired,
  setToWorkedVMail: PropTypes.func.isRequired,
  listenedToVMail: PropTypes.func.isRequired,
  orderedVmailIdsArray: PropTypes.array.isRequired
};

const S = styler`
  offsetComp
    right: 20
    bottom: 20
    left: 20
  td
    white-space: nowrap  
  
  header
    padding-bottom: 3px
    margin: 20px 15px 
    border-bottom: 1px solid #DDD
  h1
    font-size: 2.3em
    font-weight: 300
    color: #666
  button
    text-align: right
  titleDiv
    padding: 0
  count
    color: #b3b4ba
`;
