import React from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table, NonSortableHeader } from "hooks/useQuery/local-query";
import { formatDate } from "utils/dates";

const PatientSearchResults = ({
  multiple,
  patients,
  isFetching,
  selectedPatients,
  setSelectedPatients
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(patients, {});
  return (
    <Table
      isFetching={isFetching}
      containerClass="query-table patient-search-results-table"
      scrollable
      TableRow={({
        patient_name,
        email,
        company_name,
        date_of_birth,
        account_number,
        user_id
      }) => (
        <tr
          onClick={() => {
            if (multiple) {
              if (selectedPatients.includes(user_id)) {
                setSelectedPatients(
                  selectedPatients.filter(id => id !== user_id)
                );
              } else {
                setSelectedPatients([...selectedPatients, user_id]);
              }
            } else {
              if (selectedPatients === user_id) {
                setSelectedPatients("");
              } else {
                setSelectedPatients(user_id);
              }
            }
          }}
        >
          <td>
            <input
              type="checkbox"
              style={{ margin: 0 }}
              checked={
                multiple
                  ? selectedPatients.includes(user_id)
                  : selectedPatients === user_id
              }
              readOnly
            />
          </td>
          <td>{patient_name}</td>
          <td
            style={{
              maxWidth: 120,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {email}
          </td>
          <td>{formatDate(date_of_birth)}</td>
          <td>{account_number}</td>
          <td>{company_name}</td>
        </tr>
      )}
      columns={[
        {
          header: "",
          field: "controls",
          Cell: () => <NonSortableHeader text="" style={{ width: 80 }} />
        },
        {
          header: "Name",
          field: "patient_name",
          Cell: () => <NonSortableHeader text="Name" />
        },
        {
          header: "Email",
          field: "patient_email",
          Cell: () => (
            <NonSortableHeader text="Email" style={{ maxWidth: 120 }} />
          ),
          style: { width: 120, maxWidth: 120 }
        },
        {
          header: "Date Of Birth",
          field: "date_of_birth",
          Cell: () => <NonSortableHeader text="Date Of Birth" />
        },
        {
          header: "Account Number",
          field: "account_number",
          Cell: () => <NonSortableHeader text="Account Number" />
        },
        {
          header: "Company",
          field: "company_name",
          Cell: () => <NonSortableHeader text="Company" />
        }
      ]}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={rows}
      rowKey="user_id"
    />
  );
};

export default PatientSearchResults;

PatientSearchResults.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  selectedPatients: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  setSelectedPatients: PropTypes.func.isRequired,
  multiple: PropTypes.bool
};
