import React from "react";
import PatientStatus from "components/patient-status";
import PatientsTable from "components/patients-table";
import NewPatientForm from "components/patient-form/create-patient";
import EditPatientForm from "components/patient-form/edit-patient";
import PatientProfile from "components/screens/patient/patient-profile";
import PatientSearchTable from "components/screens/patient/patient-search-table";
import PatientOutreachTable from "components/patient-outreach-table";
import PatientsContacts from "components/patient-contacts";
import FormResponseInformation from "components/screens/forms/form-response-profile"
import CompliancePatientsTable from "components/compliance-patients-table/compliance-patients-table";
import { PatientUpdateByGuidScreen } from "components/patient-update-by-guid";
import { useParams } from "react-router-dom";

export default [
  {
    path: "/patients",
    component: PatientStatus,
    exact: true
  },
  {
    path: "/patients-table",
    component: PatientsTable,
    exact: true
  },
  {
    path: "/patients-compliance",
    component: CompliancePatientsTable,
    exact: true,
    allowedRoles: ["Administrator", "ServiceAdmin", "CompanyAdministrator"]
  },
  {
    path: "/patients/new",
    component: NewPatientForm,
    exact: true
  },
  {
    path: "/patients/bulk-update",
    component: PatientUpdateByGuidScreen,
    exact: true,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/patients/contacts",
    component: PatientsContacts,
    exact: true,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/patients/outreach",
    component: PatientOutreachTable,
    exact: true,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/patients/:id",
    component: PatientProfileRoute,
    exact: true
  },
  {
    path: "/patients/:id/edit",
    component: EditPatientForm,
    exact: true
  },
  {
    path: "/search/patients/:field/:value",
    component: PatientSearchTable
  }
  ,
  {
    path: "/patients/:patientId/answered-form/:formAssignmentId",
    component: FormResponseInformation
  }
];

function PatientProfileRoute() {
  const { id } = useParams();
  return <PatientProfile patientId={id} />;
}
