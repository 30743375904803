import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { PageHeader, PureModal } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { useLocation } from "react-router-dom";
import { pathOr } from "ramda";
import { useFetch } from "hooks";
import { get } from "utils/api";
import useRowSelectNew, { SelectRowContext } from "hooks/useRowSelectNew";
import { useCmnTable } from "./use-table";
import CMNTableControls from "./table-controls";
import CompanyCmns from "features/referral-answer/cmns/company-cmns";
import "./style.scss";

const CmnTable = () => {
  const location = useLocation();
  const initialFilters = pathOr({}, ["state", "filters"], location);
  const companies = useSelector(selectors.getFormOptionsCompany);
  const initialQuery = {
    sort: {},
    filters: initialFilters,
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("cmns/index", initialQuery, "cmns");
  const { fetchData: importCompanyCmns } = useFetch({
    apiFn: companyId => get(`referral-answer/import-company-cmns/${companyId}`),
    defaultValue: {}
  });
  const [selectedCompanyId, setSelectedCompanyId] = React.useState("");
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelectNew(rows, "cmn_id", query, row => ({
    ...row
  }));
  const {
    tableColumns,
    tableRows
    // tableSettings,
    // resetTableSettings,
    // updateTableSettings
  } = useCmnTable({ query, queryDispatcher });
  return (
    <div className="cmns-page">
      <PageHeader title="Patient CMNS">
        <select
          value={selectedCompanyId}
          onChange={({ target }) => setSelectedCompanyId(target.value)}
        >
          <option />
          {companies
            .filter(x => x.ra_cmn_integration)
            .map(x => (
              <option key={x.GUID} value={x.GUID}>
                {x.name}
              </option>
            ))}
        </select>
        <PureModal
          renderTrigger={({ openModal }) => (
            <button onClick={openModal} disabled={!selectedCompanyId}>
              Manage CMNS
            </button>
          )}
          windowProps={{ large: true }}
          onClose={async () => {
            await importCompanyCmns(selectedCompanyId);
            await fetchData(query);
          }}
        >
          <CompanyCmns companyId={selectedCompanyId} />
        </PureModal>
      </PageHeader>
      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <Table
          columns={tableColumns}
          rows={rows}
          query={query}
          data={data}
          queryDispatcher={queryDispatcher}
          isFetching={isFetching}
          containerClass="query-table cmn-table dynamic-columns"
          TableRow={row => (
            <tr className={`task-row ${row.index % 2 === 0 ? "even" : "odd"}`}>
              {tableRows.map((Row, key) => (
                <Row {...row} key={key} />
              ))}
            </tr>
          )}
          rowKey="cmn_id"
          perPageOptions={[
            { text: "50", value: 50 },
            { text: "150", value: 150 },
            { text: "500", value: 500 }
          ]}
          Controls={
            <CMNTableControls
              query={query}
              selectedRows={selectedRows}
              clearSelectedRows={clearSelectedRows}
              isFetching={isFetching}
              refreshData={() => {
                fetchData(query);
              }}
            />
          }
        ></Table>
      </SelectRowContext.Provider>
    </div>
  );
};

export default CmnTable;
