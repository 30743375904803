import React from "react";
import PropTypes from "prop-types";
import { formatMoney } from "accounting";
import isEmpty from "lodash/isEmpty";
import {
  getRemainingDeductible,
  getPatientIsEligible
} from "features/bonafide/helpers";

const BonafideOrderInfo = ({ response, responseBody }) => {
  const patientIsEligible = getPatientIsEligible(response.eligStatus);
  return (
    <React.Fragment>
      <div className="bonafide-errors-container">
        <div className="reason-status-text">{response.reason}</div>
        {response.errors &&
          response.errors
            .filter(e => !!e)
            .map(s => (
              <div key={s} className="bonafide-issue">
                {s}
              </div>
            ))}
      </div>
      <div className="bonafide-eq-info">
        {response && response.order && (
          <React.Fragment>
            {response.order.lineItems && (
              <TotalOOPInfo
                lineItems={response.order.lineItems}
                remainingDeductible={getRemainingDeductible(responseBody)}
                patientIsEligible={patientIsEligible}
              />
            )}
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>HCPCS</th>
                  <th>Allowable</th>
                  <th>OOP</th>
                </tr>
              </thead>
              <tbody>
                {response.order.lineItems &&
                  response.order.lineItems.map(
                    (
                      { itemName, hcpcs, allowable, patientResponsiblity },
                      index
                    ) => (
                      <tr key={`${itemName}-${index}`}>
                        <td>{itemName}</td>
                        <td>{hcpcs}</td>
                        <td>
                          {allowable === null ? null : formatMoney(allowable)}
                        </td>
                        <td>
                          {!patientIsEligible || patientResponsiblity === null
                            ? null
                            : formatMoney(patientResponsiblity)}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const TotalOOPInfo = ({
  lineItems,
  remainingDeductible,
  patientIsEligible
}) => {
  const { patientResponsiblity, allowable } = lineItems.reduce(
    (acc, { allowable, patientResponsiblity }) => ({
      allowable: parseFloat(acc.allowable) + parseFloat(allowable),
      patientResponsiblity:
        parseFloat(acc.patientResponsiblity) + parseFloat(patientResponsiblity)
    }),
    { patientResponsiblity: 0, allowable: 0 }
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        padding: 10,
        borderBottom: "1px solid #ddd"
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: 600, paddingRight: 10, fontSize: "1.05em" }}>
          Total Allowable:
        </div>
        {formatMoney(allowable)}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: 600, paddingRight: 10, fontSize: "1.05em" }}>
          Total OOP:
        </div>
        {!patientIsEligible || isNaN(patientResponsiblity) ? (
          <span style={{ color: "#888" }}>Not Verified</span>
        ) : (
          formatMoney(patientResponsiblity)
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: 600, paddingRight: 10, fontSize: "1.05em" }}>
          Deductible:
        </div>
        {!patientIsEligible || isEmpty(remainingDeductible)
          ? ""
          : formatMoney(remainingDeductible)}
      </div>
    </div>
  );
};

TotalOOPInfo.defaultProps = { lineItems: [] };
TotalOOPInfo.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.object),
  remainingDeductible: PropTypes.string,
  patientIsEligible: PropTypes.bool
};

BonafideOrderInfo.propTypes = {
  response: PropTypes.shape({
    status: PropTypes.string,
    eligStatus: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    remainingDeductible: PropTypes.string,
    order: PropTypes.shape({
      lineItems: PropTypes.arrayOf(PropTypes.object)
    }),
    reason: PropTypes.string
  }),
  responseBody: PropTypes.string,
  bonafideResult: PropTypes.object
};

BonafideOrderInfo.defaultProps = {
  response: {},
  bonafideResult: {}
};

export default BonafideOrderInfo;
