import React from "react";
import PropTypes from "prop-types";
import Spinner from "components/ui/spinner";

const InlineWaiting = ({ reason }) => (
  <div
    className="ui-inline-waiting"
    style={{
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <div style={{ marginRight: 5 }}>
      <Spinner style={{ marginRIght: 5 }} />
    </div>
    <div>{reason}</div>
  </div>
);

export default InlineWaiting;

InlineWaiting.propTypes = {
  reason: PropTypes.string
};
