import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { post } from "utils/api";
import { Spinner } from 'components/ui';
import { errorMessage } from "actions/message";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from "reducers";
import { CreateTimeSlotForCoach } from './create-time-slot-for-coach';


const AvailableCoachesCreateModal = ({ onClose, onSubmit }) => {

  const dispatch = useDispatch()
  const user = useSelector(selectors.getUser);
  const [availableCoaches, setAvailableCoaches] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState({ text: "Select Coach", value: "" })

  const { fetchData: fetchCoaches, isFetching } = useFetch({
    apiFn: () => post("dme_portal/appointments/manager/available-coaches-create", JSON.stringify([user.id]), {"content-type": "application/json"}),
    defaultValue: [],
    onError: error => {
        dispatch(errorMessage(`Failed to fetch coaches: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
    }
  });

  const getCoaches = async () =>  {
    const res = await fetchCoaches()
    if(res){
      setAvailableCoaches(res.Result)
    }
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    setSelectedCoach({ text: text, value: value });
  };

  useEffect(() => {
    getCoaches()
  },[])

  return (
    <div style={{padding: "1em"}}>
      <h4>Create a schedule for: </h4>
      <div>
          <hr style={{marginBottom: 0}} />
          {isFetching
            ? <Spinner />
            : <select value={selectedCoach.value} onChange={e => handleSelectChange(e) }>
                <option value="" >Select Coach</option>
                {availableCoaches?.map((coach) => (
                    <option key={coach.value} value={coach.value}>
                      {coach.text}
                    </option>
                ))}
            </select>
          }
      </div>
      <CreateTimeSlotForCoach selectedCoach={selectedCoach} onClose={() => {onClose(); onSubmit()}} />
    </div>
  )
}

AvailableCoachesCreateModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default AvailableCoachesCreateModal;
