import { createSelector } from "reselect";
import { combineReducers } from "redux";
import { createReducer, makeFetchReducer } from "utils/reducer";
import { Types } from "actions/heat-map";
import * as R from "ramda";

const lineItemCounts = createReducer(
  {},
  {
    [Types.RESPONSE_LINE_ITEM_COUNT_HEAT_MAP]: (_, { payload }) => payload,
    [Types.LINE_ITEM_COUNT_HEAT_MAP_ERROR]: () => ({})
  }
);

const orderStatusChangeCounts = createReducer(
  {},
  {
    [Types.RESPONSE_ORDER_STATUS_HEAT_MAP]: (_, { payload }) => payload,
    [Types.ORDER_STATUS_HEAT_MAP_ERROR]: () => ({})
  }
);

const isFetchingOrderStatusChangeCounts = makeFetchReducer(
  [
    Types.REQUEST_ORDER_STATUS_HEAT_MAP,
    Types.RESPONSE_ORDER_STATUS_HEAT_MAP,
    Types.ORDER_STATUS_HEAT_MAP_ERROR
  ],
  true
);

const isFetchingLineItemCounts = makeFetchReducer(
  [
    Types.REQUEST_LINE_ITEM_COUNT_HEAT_MAP,
    Types.RESPONSE_LINE_ITEM_COUNT_HEAT_MAP,
    Types.LINE_ITEM_COUNT_HEAT_MAP_ERROR
  ],
  true
);

export default combineReducers({
  lineItemCounts,
  orderStatusChangeCounts,
  isFetchingOrderStatusChangeCounts,
  isFetchingLineItemCounts
});

const getLineItemCountHeatMap = createSelector(
  [state => state.getIn(["heat-maps"])],
  R.prop("lineItemCounts")
);
const getOrderStatusChangeHeatMap = createSelector(
  [state => state.get("heat-maps")],
  R.prop("orderStatusChangeCounts")
);

const getIsFetchingLineItemCountHeatMap = createSelector(
  [state => state.getIn(["heat-maps"])],
  R.prop("isFetchingLineItemCounts")
);
const getIsFetchingOrderStatusChangeHeatMap = createSelector(
  [state => state.get("heat-maps")],
  R.prop("isFetchingOrderStatusChangeCounts")
);

export const selectors = {
  getLineItemCountHeatMap,
  getOrderStatusChangeHeatMap,
  getIsFetchingLineItemCountHeatMap,
  getIsFetchingOrderStatusChangeHeatMap
};
