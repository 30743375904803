import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompanyFiles } from "features/google-api/actions";
import { createTab } from "actions/system-tray";
import { InlineWaiting } from "components/ui";
import { selectors } from "reducers";
import GoogleAuthButton from "features/google-api/components/sign-in";
import HasRolePermission from "components/shared/has-role-permission";
import CompanyAttachFile from "features/google-api/components/attach-company-file";
import DetachFile from "features/google-api/components/detach-company-file";
import { aLink } from "utils/styles";

class CompanyInfoSheets extends PureComponent {
  componentDidMount() {
    this.getCompanyFiles();
  }

  componentDidUpdate(prevProps) {
    const { googleApiEnabled, isLoggedIn } = this.props;
    if (googleApiEnabled && !prevProps.googleApiEnabled) {
      this.getCompanyFiles();
    }
    if (googleApiEnabled && !prevProps.isLoggedIn && isLoggedIn) {
      this.getCompanyFiles();
    }
  }

  getCompanyFiles = () => {
    const {
      getCompanyFiles,
      company,
      isLoggedIn,
      googleApiEnabled
    } = this.props;
    if (isLoggedIn && googleApiEnabled) {
      getCompanyFiles(company.id);
    }
  };

  openFile = file => {
    const { createTab } = this.props;
    createTab({
      key: `${file.id}`,
      type: "googleFile",
      label: `${file.name}`
    });
  };

  openFileInNewWindow = file => {
    window.open(`https://docs.google.com/document/d/${file.id}`);
  };

  render() {
    const {
      googleApiIsLoggedIn,
      company_files,
      company,
      isFetching,
      googleApiEnabled
    } = this.props;
    return googleApiEnabled ? (
      <div className="company-info-sheets">
        <div className="info-sheets-header">
          <h3>Company Info Sheets</h3>
          {googleApiIsLoggedIn && (
            <React.Fragment>
              <span onClick={this.getCompanyFiles} className="drive-action" />
            </React.Fragment>
          )}
          <GoogleAuthButton />
        </div>
        {isFetching ? (
          <InlineWaiting />
        ) : (
          <table className="small-12" style={{ fontSize: 15 }}>
            <thead>
              <tr>
                <th>File</th>
                <th />
                <th />
                <th />
                <th style={{ textAlign: "right", ...aLink }}>
                  <HasRolePermission allowedRoles={["Administrator"]}>
                    <CompanyAttachFile company={company} />
                  </HasRolePermission>
                </th>
              </tr>
            </thead>
            {company_files && (
              <tbody>
                {company_files.map(file => (
                  <tr key={file.id}>
                    <td style={{ width: "60%" }}>{file.name}</td>
                    <td>
                      <HasRolePermission allowedRoles={["Administrator"]}>
                        <DetachFile companyId={company.id} file={file} />
                      </HasRolePermission>
                    </td>
                    <td
                      colSpan="2"
                      style={{ textAlign: "right", ...aLink }}
                      onClick={() => this.openFile(file)}
                    >
                      Open
                    </td>
                    <td
                      style={{ textAlign: "right", ...aLink }}
                      onClick={() => this.openFileInNewWindow(file)}
                    >
                      Open In New Window
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        )}
      </div>
    ) : null;
  }
}

CompanyInfoSheets.propTypes = {
  company: PropTypes.object.isRequired,
  googleApiIsLoggedIn: PropTypes.bool.isRequired,
  company_files: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  getCompanyFiles: PropTypes.func.isRequired,
  createTab: PropTypes.func.isRequired,
  googleApiEnabled: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool
};

export default connect(
  state => ({
    company_files: selectors.getGapiCompanyFiles(state),
    isFetching: selectors.getGapiIsFetchingCompanyFiles(state),
    googleApiIsLoggedIn: selectors.getGapiIsLoggedIn(state),
    googleApiEnabled: selectors.getGapiClientEnabled(state),
    isLoggedIn: selectors.getGapiIsLoggedIn(state)
  }),
  { getCompanyFiles, createTab }
)(CompanyInfoSheets);
