import { combineReducers } from 'redux'
import without from 'lodash/without'
import { createReducer } from 'utils/reducer'
import { Types } from "actions/order-profile";
import { propOr } from "ramda";

const lineItemIds = createReducer({}, {
  [Types.RESPONSE_LINE_ITEMS]: (
    state,
    { payload, meta: { orderId }, error }
  ) =>
    error
      ? state
      : {
          ...state,
          [orderId]: propOr([], "equipment_requested", payload).map(
            ({ line_item_id }) => line_item_id
          )
        },
  [Types.API_LINE_ITEM_REMOVAL]: (state, {
    payload: { lineItemId },
    meta: { orderId }
  }) => ({
    ...state,
    [orderId]: without(state[orderId], lineItemId)
  })
})

const orderLineItems = combineReducers({
  lineItemIds
})

export default orderLineItems

const getOrderLineItemIds = (state, { orderId }) => state.lineItemIds[orderId]

export const selectors = {
  getOrderLineItemIds
}
