import { Types } from "actions/product-faqs";
import { createReducer } from "utils/reducer";

const initialState = {
  faqs: [],
};

const productFaqs = createReducer(initialState.faqs, {
  [Types.GET_PRODUCT_FAQS]: (state, { payload }) => ([
    ...payload,
  ]),
  [Types.PUT_PRODUCT_FAQ]: (state, { payload }) => ([
    ...state.map((faq) =>
      faq.faq_id !== payload.faq_id ? faq : { ...faq, ...payload }
    ),
  ]),
});

export default productFaqs;
