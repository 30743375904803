export const Types = {
  REQUEST_FULFILLMENT_STATUS_UPDATE: "REQUEST_FULFILLMENT_STATUS_UPDATE",
  RESPONSE_FULFILLMENT_STATUS_UPDATE: "RESPONSE_FULFILLMENT_STATUS_UPDATE",
  FULFILLMENT_STATUS_UPDATE_ERROR: "RESPONSE_FULFILLMENT_STATUS_UPDATE"
};

export const requestFulfillmentStatusUpdate = (ids, status) => ({
  type: Types.REQUEST_FULFILLMENT_STATUS_UPDATE,
  payload: {
    ids,
    status
  }
});
