// import only neccessarry icons to keep bundle size small
// this should be unnessecary when updated to react-icons v3
import ArrowBack from "react-icons/lib/io/ios-arrow-back";
import ArrowForward from "react-icons/lib/io/ios-arrow-forward";
import Close from "react-icons/lib/io/ios-close";

export default {
  ArrowBack,
  ArrowForward,
  Close
};
