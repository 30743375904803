import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

const AuthCodeInput = ({ characters, allowedCharacters, onChange }) => {
  const inputsRef = useRef([]);

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  const sendResult = () => {
    const res = inputsRef.current.map(input => input.value).join("");
    onChange(res);
  };

  const handleOnChange = e => {
    if (e.target.value.match(allowedCharacters)) {
      if (e.target.nextElementSibling !== null) {
        e.target.nextElementSibling.focus();
      }
    } else {
      e.target.value = "";
    }
    sendResult();
  };

  const handleOnKeyDown = e => {
    const { key } = e;
    const target = e.target;
    if (key === "Backspace") {
      if (target.value === "" && target.previousElementSibling !== null) {
        if (target.previousElementSibling !== null) {
          target.previousElementSibling.focus();
          e.preventDefault();
        }
      } else {
        target.value = "";
      }
      sendResult();
    }
  };

  const handleOnFocus = e => {
    e.target.select();
  };

  const handleOnPaste = e => {
    //const value = e.clipboardData.getData("Text");
    const value = (event.clipboardData || window.clipboardData).getData("text");
    if (value.match(allowedCharacters)) {
      for (let i = 0; i < characters && i < value.length; i++) {
        inputsRef.current[i].value = value.charAt(i);
        if (inputsRef.current[i].nextElementSibling !== null) {
          inputsRef.current[i].nextElementSibling.focus();
        }
      }
      sendResult();
    }
    e.preventDefault();
  };

  const inputs = [];
  for (let i = 0; i < characters; i++) {
    inputs.push(
      <input
        key={i}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        onPaste={handleOnPaste}
        type={"text"}
        ref={el => (inputsRef.current[i] = el)}
        maxLength={1}
        style={{ maxWidth: 30 }}
      />
    );
  }

  return (
    <div
      style={{
        display: "grid",
        width: "auto",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gridGap: 5,
        gridTemplateColumns: `repeat(${characters}, auto)`
      }}
    >
      {inputs}
    </div>
  );
};

export default AuthCodeInput;

AuthCodeInput.defaultProps = {
  characters: 6,
  allowedCharacters: "^[A-Za-z0-9]*$"
};
AuthCodeInput.propTypes = {
  characters: PropTypes.number,
  allowedCharacters: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
