import { all, call, put } from "redux-saga/effects";
import { pullById, takeMatch } from "utils/saga";
import { message, errorMessage } from "actions/message";
import { Types, invalidateLineItems } from "actions/order-profile";
import { getApiErrorMessage } from "utils/misc";

export default function* lineItemSaga() {
  yield all([
    pullById(Types.REQUEST_LINE_ITEM_REMOVAL, "meta.orderId", removeLineItem),
    pullById(
      Types.REQUEST_NEW_LINE_ITEM_BY_ID,
      "meta.orderId",
      createNewLineItemById
    ),
    pullById(Types.REQUEST_NEW_LINE_ITEM, "meta.orderId", createNewLineItem)
  ]);
}

function* removeLineItem({ payload: { lineItemId }, meta: { orderId } }) {
  yield put({
    type: Types.API_LINE_ITEM_REMOVAL,
    payload: {
      apiRequest: {
        method: "DELETE",
        route: `orders/line_item/${lineItemId}`,
        type: Types.RESPONSE_LINE_ITEM_REMOVAL,
        meta: { orderId }
      },
      lineItemId
    },
    meta: { orderId }
  });
  yield takeMatch({
    type: Types.RESPONSE_LINE_ITEM_REMOVAL,
    meta: { orderId }
  });
  yield put(invalidateLineItems({ orderId })());
}

function* createNewLineItemById({
  payload: { create },
  meta: { orderId, isUpdate }
}) {
  if (isUpdate) {
    yield put({
      type: Types.API_NEW_LINE_ITEM_BY_ID,
      payload: {
        apiRequest: {
          method: "POST",
          route: `orders/${orderId}/update_line_item_by_id`,
          params: create,
          type: Types.RESPONSE_NEW_LINE_ITEM_BY_ID,
          meta: { orderId }
        }
      },
      meta: { orderId }
    });
  } else
    yield put({
      type: Types.API_NEW_LINE_ITEM_BY_ID,
      payload: {
        apiRequest: {
          method: "POST",
          route: `orders/${orderId}/add_line_item_by_id`,
          params: create,
          type: Types.RESPONSE_NEW_LINE_ITEM_BY_ID,
          meta: { orderId }
        }
      },
      meta: { orderId }
    });
  const { error, payload } = yield takeMatch({
    type: Types.RESPONSE_NEW_LINE_ITEM_BY_ID,
    meta: { orderId }
  });
  yield call(refresh, error, orderId, isUpdate, payload);
}

function* createNewLineItem({ payload: { create }, meta: { orderId } }) {
  yield put({
    type: Types.API_NEW_LINE_ITEM,
    payload: {
      apiRequest: {
        method: "POST",
        route: `orders/${orderId}/add_line_item`,
        params: create,
        type: Types.RESPONSE_NEW_LINE_ITEM,
        meta: { orderId }
      }
    },
    meta: { orderId }
  });
  const { error } = yield takeMatch({
    type: Types.RESPONSE_NEW_LINE_ITEM,
    meta: { orderId }
  });
  yield call(refresh, error, orderId);
}

function* refresh(error, orderId, updated = false, payload) {
  yield put(
    !error
      ? message(`Equipment ${updated ? "updated" : "added"} successfully!`)
      : errorMessage(
          getApiErrorMessage(
            payload,
            `There was a problem ${
              updated ? "updating" : "adding"
            } your equipment.`
          )
        )
  );
  yield put(invalidateLineItems({ orderId })());
}
