import { SHOW_LOADER, HIDE_LOADER } from './../ActionTypes';

const initialState = {
    isLoader: false
}

const reducerFaq = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return {
                ...state,
                isLoader: true
            };
        case HIDE_LOADER:
            return {
                ...state,
                isLoader: false
            };
        default:
            return state;
    }
}

export default reducerFaq;
