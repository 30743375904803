import moment from "moment-timezone";
import semver from "semver";
import * as R from "ramda";

export const generateGuid = () => {
  function generate(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return generate() + generate(true) + generate(true) + generate();
};

export const capitalize = (text, allWords = false) => {
  if (typeof text != "string") return text;
  if (allWords === true) {
    return text.split(" ").map(capitalize).join(" ");
  } else
    return (
      text.toLowerCase().charAt(0).toUpperCase() + text.toLowerCase().slice(1)
    );
};

export const createGuid = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export const SplitOnCamelCase = str => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const capitalizeAddress = text => capitalize(text, true);

export const snakeCase = s => {
  return s.replace(/-/g, "_");
};

export const deSnakeCase = s =>
  typeof s === "string" ? s.replace(/_/g, " ") : "";

export const manageQueryDirection = (query, oldQuery) => {
  if (
    oldQuery &&
    query.get("sort_by") &&
    oldQuery.get("sort_by") === query.get("sort_by")
  ) {
    query = query.set(
      "direction",
      oldQuery.get("direction") === "asc" ? "desc" : "asc"
    );
  } else if (oldQuery && query.get("sort_by")) {
    query = query.set("direction", "asc");
  }
  return query;
};

// some urls will need to populate the redux store if the page is
// refreshed there (eg profiles & edit forms). this is detected
// by checking for the value in question, or checking that the ids match
// (store vs url)
export const needsUpdate = (field, oldID, newID) => {
  return !field || oldID !== newID;
};
export const Contains = (stringToBeSearched, phraseToSearch) => {
  if (
    typeof stringToBeSearched != "string" ||
    typeof phraseToSearch != "string"
  ) {
    return false;
  } else {
    return stringToBeSearched.search(phraseToSearch) != -1;
  }
};

export const formatPhone = value => {
  if (!value) {
    return "";
  }
  const phoneDigits = value.replace(/[^[0-9]]/g, "");
  if (phoneDigits.length <= 3) {
    return phoneDigits;
  } else if (phoneDigits.length <= 7) {
    return `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(3)}`;
  } else if (phoneDigits.length <= 10) {
    return `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(3, 6)}-${phoneDigits.slice(6)}`;
  }
  return value;
};

export const getInitials = s => {
  if (s == undefined) {
    return "no name";
  } else {
    const names = s.split(" ");
    let initials = names.map(R.nth(0)).join("").toUpperCase();
    return initials;
  }
};

export const generateTrackingUrl = equipmentItem => {
  const carrier = equipmentItem.getIn(["shippingDetails", "carrier"]);
  const tracking_number = equipmentItem.getIn([
    "shippingDetails",
    "tracking_number"
  ]);
  return makeTrackingUrl(carrier, tracking_number);
};

export const makeTrackingUrl = (carrier, trackingNumber = "") => {
  if (typeof carrier !== "string" || !carrier) return "";
  switch (carrier.toUpperCase()) {
    case "UPS":
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
    case "USPS":
      return `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`;
    case "FEDEX":
      // return `https://www.fedex.com/Tracking?action=track&tracknumbers=${trackingNumber}`;
      return `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${trackingNumber}`;
    case "DHL":
      return `https://track.dhl-usa.com/TrackByNbr.asp?ShipmentNumber=${trackingNumber}`;
    case "ONTRAC":
      return `https://www.ontrac.com/trackingresults.asp?tracking_number=${trackingNumber}`;
    default:
      return `https://www.google.com/#q=${trackingNumber}`;
  }
};

export const isUserTokenExpired = user => {
  const expiration = user && user.expiration;
  return (
    !expiration ||
    moment
      .tz(expiration, "MM/DD/YYYY h:mm:ss a", "US/Central")
      .isBefore(moment())
  );
};

export const formatNumbersForReports = str =>
  !isNaN(str) ? parseInt(str).toLocaleString() : "";

export const formatZip = (zip = "") =>
  zip.length == 9 ? zip.substr(0, 5) + "-" + zip.substr(5) : zip;

// transform table rows to format acceptable for rechart graphing library(line chart, multi bar etc...)
export const groupRowsBy =
  (groupBy, labels = []) =>
  (data = []) => {
    const resultSet = R.indexBy(R.prop(groupBy), data);
    return R.reduce(
      (acc, { key, label }) => [
        ...acc,
        {
          name: label ? label : key,
          ...R.keys(resultSet).reduce(
            (obj, groupKey) => ({
              ...obj,
              [groupKey]: R.path([groupKey, key], resultSet)
            }),
            {}
          )
        }
      ],
      []
    )(labels);
  };

const percentToString = v => (isNaN(v) ? 0 : v) + "%";
export const formatPercantage = (value, total, decimals = 0) =>
  percentToString(R.multiply(R.divide(value, total), 100).toFixed(decimals));

export const splitWordAtCase = value =>
  value ? value.split(/(?=[A-Z])/).join(" ") : "";

export const getApiErrorMessage = (
  error,
  defaultError = "Sorry Something went wrong."
) => R.pathOr(defaultError, ["response", "body", "status"], error);

export const getApiErrorMessageCustomPathEnd = (
  error,
  pathEnd = "status",
  defaultError = "Sorry Something went wrong."
) => R.pathOr(defaultError, ["response", "body", pathEnd], error);

export const transformUserRole = user => {
  let userRole = R.prop("role", user);
  if (userRole === "CompanyAdministrator" && user?.admin_elevated) {
    userRole = "ServiceAdmin";
  }
  if (userRole === "OfficeAdministrator" && user?.admin_elevated) {
    userRole = "ServiceCoach";
  }
  return R.assoc("role", userRole, user);
};

export const formatMoney = dollarAmount => {
  try {
    if (
      dollarAmount === null ||
      dollarAmount === undefined ||
      dollarAmount === ""
    )
      return "";
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(dollarAmount);
  } catch (error) {
    return "$" + dollarAmount;
  }
};

export const formatDate = (date, format = "L") =>
  date ? moment(date).format(format) : "";

export const semanticVersionCompare = (v1, v2) => {
  try {
    if (semver.gt(v1, v2)) return semver.diff(v1, v2);
  } catch (err) {
    return null;
  }
};

export const formatAddress = ({ street_address, city, state, zip }) =>
  `${street_address}, ${city}, ${state}, ${zip}`;

export const serverDateFormat = "YYYY-MM-DDTHH:mm:ss";
export const applicationDateFormat = "M/D/YYYY";
export const applicationTimeFormat = "h:mm A";
export const applicationDateTimeFormat = "MMM D, YYYY h:mm A";

export const convertDateToLocal = (date, speaksSpanish) => {
  const lang = speaksSpanish ? "es" : "en";
  return moment
    .utc(moment.tz(date, serverDateFormat, "America/Chicago"))
    .locale(lang)
    .local();
};

export const fromToLocal = (startDate, endDate, translatedTo) =>
  `${convertDateToLocal(startDate).format(
    applicationTimeFormat
  )} ${translatedTo} ${convertDateToLocal(endDate).format(
    applicationTimeFormat
  )}`;

export const fromTo = (startDate, endDate, translatedTo) =>
  `${formatDate(startDate, applicationTimeFormat)} ${translatedTo} ${formatDate(endDate, applicationTimeFormat)}`;
