import { errorMessage, message as successMsg } from "actions/message";
import { post, put } from "utils/api";
import { getQuestionnaire } from "components/dd_newModules/Redux/Actions/questionnaire";

export const assignQuestionnaireToPatients = (
  questionnaire_id,
  patient_ids
) => async dispatch => {
  try {
    console.log(questionnaire_id, patient_ids);
    const { success, message } = await post(
      "/dme_portal/questionnaires/assignment",
      {
        patient_ids,
        questionnaire_id
      }
    );
    if (success) {
      dispatch(successMsg(message));
      return true;
    } else {
      dispatch(errorMessage(message));
    }
  } catch (error) {
    dispatch(errorMessage(error.message));
  }
};

export const createQuestionnaire = questionnaire => async dispatch => {
  try {
    const { success, message } = await post("dme_portal/questionnaires", {
      ...questionnaire,
      active: true
    });
    console.log(message);
    if (success) {
      dispatch(successMsg(message));
      dispatch(getQuestionnaire());
      return true;
    } else {
      dispatch(errorMessage(message));
    }
  } catch (error) {
    dispatch(errorMessage(error.message));
  }
};

export const updateQuestionnaire = questionnaire => async dispatch => {
  try {
    const { success, message } = await put(
      "dme_portal/questionnaires",
      questionnaire
    );
    console.log(message);
    if (success) {
      dispatch(successMsg(message));
      dispatch(getQuestionnaire());
      return true;
    } else {
      dispatch(errorMessage(message));
    }
  } catch (error) {
    dispatch(errorMessage(error.message));
  }
};
