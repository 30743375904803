import React from "react";
import PropTypes from "prop-types";
import DatePicker from "components/ui/date-picker";
import moment from "moment";

const formatDate = date => (date ? moment(date).format("ll") : null);

const DelayedPendingField = ({
  delayed_pending_date,
  requestUpdateOrder,
  fulfillment_type,
  hasPendingIvrWork
}) => (
  <div className="pending-delayed-container no-print">
    <span className="subheader-text">Delayed Date:</span>
    <span className="pending-delayed-field">
      <DatePicker
        css={{
          display: "inline-block",
          float: "right"
        }}
        modifiers={{
          disabled: { before: new Date() }
        }}
        containerCss={{ left: -200, bottom: -10 }}
        disabled={!!fulfillment_type || hasPendingIvrWork}
        onChange={day => {
          // if order has fulfillment_type can only clear date
          if(hasPendingIvrWork) return;
          if (!day) {
            requestUpdateOrder({
              delayed_pending_date: "",
              delayed_pending: false
            });
          } else {
            requestUpdateOrder({
              delayed_pending_date: moment(day).format("YYYY-MM-DD"),
              delayed_pending: true
            });
          }
        }}
      />
      <span
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        {formatDate(delayed_pending_date)}
      </span>
    </span>
  </div>
);

DelayedPendingField.propTypes = {
  delayed_pending: PropTypes.bool,
  delayed_pending_date: PropTypes.string,
  fulfillment_type: PropTypes.string,
  requestUpdateOrder: PropTypes.func.isRequired,
  hasPendingIvrWork: PropTypes.bool
};

export default DelayedPendingField;
