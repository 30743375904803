import { Types } from "actions/patient-verification";
import { createReducer } from "utils/reducer";

export default createReducer(
  {
    patientId: "",
    verified: false,
    readOnly: false
  },
  {
    [Types.ADD_PATIENT_VERIFICATION_ID]: (_, action) => ({
      patientId: action.payload,
      verified: false,
      readOnly: false
    }),
    [Types.ADD_VERIFIED_PATIENT]: state => ({
      ...state,
      readOnly: false,
      verified: true
    }),
    [Types.ADD_READONLY_PATIENT]: state => ({
      ...state,
      readOnly: true,
      verified: false
    }),
    [Types.REMOVE_READONLY_PATIENT]: state => ({
      ...state,
      readOnly: false
    }),
    [Types.CLEAR_PATIENT_VERIFICATION]: () => ({
      patientId: "",
      verified: false,
      readOnly: false
    })
  }
);

export const selectors = {
  getPatientVerification: state => state.get("patientVerification")
};
