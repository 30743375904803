import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { answerQueue, setQueueSelection } from "actions/phone";
import { browserHistory } from "browser-history";
import useSignalr from "features/signalR/use-signalr";
import { joinGroup, leaveGroup } from "features/signalR/actions";

const QueueControls = ({
  isConnected,
  queues,
  selectedQueue,
  isInAutoDialerWrapupTime,
  answeredQueueCall,
  setQueueSelection,
  phoneIsReady,
  answerQueue,
  userId
}) => {
  const queueData = queues.find(x => x.friendly_name == selectedQueue);
  useSignalr(
    [joinGroup("QueueUpdateListener")],
    [leaveGroup("QueueUpdateListener")]
  );
  return (
    <div className="queue-controls">
      <select
        className="queue-select"
        value={selectedQueue}
        onChange={({ target }) => setQueueSelection(target.value)}
      >
        <option value="" />
        {queues.map(({ friendly_name }) => (
          <option key={friendly_name} value={friendly_name}>
            {friendly_name}
          </option>
        ))}
      </select>
      <button
        className="answerQueueButton"
        disabled={
          !queueData ||
          (!queueData.current_size && !queueData.average_wait_time) ||
          !phoneIsReady ||
          isConnected ||
          isInAutoDialerWrapupTime
        }
        onClick={() => {
          answerQueue({ queue: selectedQueue, from: userId });
        }}
      >
        Answer Queue
      </button>
      {queueData && (
        <div className="queueStats">
          {queueData ? (
            <React.Fragment>
              <div>
                <strong>Waiting:</strong> {queueData.current_size}
              </div>
              <div>
                <strong>Avg Wait:</strong> {queueData.average_wait_time}s
              </div>
            </React.Fragment>
          ) : (
            "No queue selected"
          )}
        </div>
      )}
      {!!answeredQueueCall && (
        <select
          disabled={false}
          style={{ width: "13em" }}
          value={""}
          title="Here is a list of potential patients that the phone number matches"
          onChange={({ target }) =>
            browserHistory.push(`/patients/${target.value}`)
          }
        >
          <option disabled={true} value="">
            Potential Patients
          </option>
          {!!answeredQueueCall &&
            answeredQueueCall.patients.map(patient => (
              <option key={patient.guid} value={patient.guid}>
                {patient.name}
              </option>
            ))}
        </select>
      )}
    </div>
  );
};

export default connect(
  state => {
    const availableQueues = selectors
      .getTeamNameOptions(state)
      .map(t => t.text);
    return {
      isConnected: selectors.getIsPhoneConnected(state),
      queues: selectors
        .new_getPhoneQueues(state)
        .filter(q => availableQueues.includes(q.friendly_name)),
      selectedQueue: selectors.getQueueSelection(state),
      isInAutoDialerWrapupTime: selectors.getAutoDialerIsInWrapupTime(state),
      answeredQueueCall: selectors.getQueueCallToAnswer(state),
      phoneIsReady: selectors.getIsPhoneReady(state),
      userId: selectors.getUserId(state)
    };
  },
  {
    setQueueSelection,
    answerQueue
  }
)(QueueControls);

QueueControls.propTypes = {
  isConnected: PropTypes.bool,
  queues: PropTypes.arrayOf(PropTypes.object),
  selectedQueue: PropTypes.string,
  isInAutoDialerWrapupTime: PropTypes.bool,
  answeredQueueCall: PropTypes.object,
  setQueueSelection: PropTypes.func.isRequired,
  answerQueue: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  phoneIsReady: PropTypes.bool,
  availableQueues: PropTypes.arrayOf(PropTypes.string)
};
