import React from "react";
import PropTypes from "prop-types";
import { useOutsideClick } from "hooks";
import IoPrinter from "react-icons/lib/io/printer";
import ReactToPrint from "react-to-print";

const PrintFormBtn = ({ formRef }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const printButtonRef = React.useRef();
  useOutsideClick(printButtonRef, () => {
    setIsOpen(false);
  });
  return (
    <ReactToPrint
      trigger={() => (
        <div
          className="no-print print-form-button"
          onContextMenu={e => {
            e.preventDefault();
            setIsOpen(true);
          }}
          ref={printButtonRef}
          onClick={() => {
            window.print();
          }}
        >
          {isOpen ? (
            <div className="print-menu no-print">
              <div
                className="print-option"
                onClick={() => {
                  setIsOpen(false);
                  setTimeout(() => {
                    window.print();
                  }, 100);
                }}
              >
                Print
              </div>
            </div>
          ) : (
            <IoPrinter color="grey" size="28px" />
          )}
        </div>
      )}
      content={() => formRef.current}
    />
  );
};

PrintFormBtn.propTypes = {
  formRef: PropTypes.object.isRequired
};

export default PrintFormBtn;
