import React from "react";
import PropTypes from "prop-types";
import { updateLineItemById } from "actions/order-profile";
import { connect } from "react-redux";
import { lineItemsAuthIsValid } from "features/bonafide/helpers";
import HasRolePermission from "components/shared/has-role-permission";
import { formatDate } from "utils/misc";

const AuthForm = ({
  orderLineItems,
  handleCancel,
  orderId,
  currentStep,
  canOverrideOrderCreation,
  hasPendingTask,
  submitOrder,
  updateLineItemById
}) => {
  const canCreateAuth = lineItemsAuthIsValid(orderLineItems);
  return (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          padding: 5,
          gridGap: 30,
          height: "100%"
        }}
      >
        <table style={{ margin: 0 }}>
          <thead>
            <tr>
              <td>Item</td>
              <td>HCPCS</td>
              <td>Quantity</td>
              <td>Auth Number</td>
              <td>Auth Start Date</td>
              <td>Auth End Date</td>
            </tr>
          </thead>
          <tbody>
            {orderLineItems.map(item => (
              <tr key={item.line_item_id}>
                <td>{item.Product}</td>
                <td>{item.HCPCS}</td>
                <td>{item.quantity}</td>
                <td>
                  <AuthNumberInput
                    authNumber={item?.authNumber ?? ""}
                    updateLineItemById={updateLineItemById}
                    line_item_id={item.line_item_id}
                    orderId={orderId}
                  />
                </td>
                <td>
                  <AuthDateInput
                    date={formatDate(item.authStartDate, "YYYY-MM-DD")}
                    field="authStartDate"
                    updateLineItemById={updateLineItemById}
                    line_item_id={item.line_item_id}
                    orderId={orderId}
                  />
                </td>
                <td>
                  <AuthDateInput
                    date={formatDate(item.authEndDate, "YYYY-MM-DD")}
                    field="authEndDate"
                    updateLineItemById={updateLineItemById}
                    orderId={orderId}
                    line_item_id={item.line_item_id}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "grid",
            flex: 1,
            gridTemplateRows: "auto auto auto",
            alignItems: "center",
            justifyContent: "center",
            gridGap: 5,
            margin: 5
          }}
        >
          {currentStep === 2 && !hasPendingTask && (
            <React.Fragment>
              <button
                className="button"
                type="submit"
                disabled={!canCreateAuth}
                onClick={() => {
                  submitOrder();
                }}
              >
                Submit Order For Processing
              </button>
            </React.Fragment>
          )}
          {canOverrideOrderCreation && (
            <HasRolePermission
              allowedRoles={[
                "Administrator",
                "OfficeAdministrator",
                "CompanyAdministrator",
                "ServiceCoach",
                "ServiceAdmin",
                'ContractorAdministrator'
              ]}
            >
              <button
                className="button"
                type="submit"
                disabled={!canCreateAuth}
                onClick={submitOrder}
              >
                Send Order For Processing
              </button>
            </HasRolePermission>
          )}
          <span className="button warning" onClick={handleCancel}>
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
};

AuthForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  orderLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateLineItemById: PropTypes.func.isRequired,
  submitOrder: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  canOverrideOrderCreation: PropTypes.bool,
  hasPendingTask: PropTypes.bool
};

export default connect(() => ({}), {
  updateLineItemById
})(AuthForm);

const AuthNumberInput = ({
  authNumber,
  disabled,
  line_item_id,
  orderId,
  updateLineItemById
}) => {
  const [value, setValue] = React.useState(authNumber);
  React.useEffect(() => {
    setValue(authNumber);
  }, [authNumber]);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  const handleBlur = async () => {
    if (value !== authNumber) {
      setValue(authNumber);
      await updateLineItemById(orderId, line_item_id, {
        authNumber: value
      });
    }
  };

  return (
    <input
      className="auth-number-input"
      type="text"
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

AuthNumberInput.propTypes = {
  authNumber: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  line_item_id: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  updateLineItemById: PropTypes.func.isRequired
};

const AuthDateInput = ({
  line_item_id,
  disabled,
  date,
  field,
  orderId,
  updateLineItemById
}) => {
  const [value, setValue] = React.useState(date);
  React.useEffect(() => {
    setValue(date);
  }, [date]);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  const handleBlur = async () => {
    if (value !== date) {
      setValue(date);
      await updateLineItemById(orderId, line_item_id, {
        [field]: value
      });
    }
  };
  return (
    <input
      className="auth-date-input"
      type="date"
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

AuthDateInput.propTypes = {
  date: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  line_item_id: PropTypes.string.isRequired,
  updateLineItemById: PropTypes.func.isRequired
};
