import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash/fp/compose'
import mapValues from 'lodash/mapValues'
import { selectors } from '../reducers'
import {
  queryInit,
  updatePer,
  updatePage,
  updateSortBy,
  toggleSortDirection,
  addFilter,
  removeFilter,
  setFilters,
  clearFilters,
  addSearch,
  removeSearch,
  clearAllSearches
} from '../actions'

const connectQueryInit = connect(null, dispatch => bindActionCreators({
  queryInit
}, dispatch))

const initQuery = config => WrappedComponent => (
  class InitQuery extends Component {
    static displayName = `InitQuery(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
    static defaultProps = {
      queryId: config.queryId,
      initialQuery: config.initialQuery
    }
    static propTypes = {
      queryId: PropTypes.string,
      queryInit: PropTypes.func,
      initialQuery: PropTypes.shape({
        sort: PropTypes.shape({
          by: PropTypes.string,
          direction: PropTypes.oneOf(['asc', 'desc'])
        }),
        filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool]))),
        pagination: PropTypes.shape({
          per: PropTypes.number,
          page: PropTypes.number
        })
      })
    }
    constructor(props) {
      super(props)
      props.queryInit(props.queryId, props.initialQuery)
    }
    componentWillReceiveProps({ queryId: newId, queryInit, initialQuery }) {
      const currId = this.props.queryId
      if (newId !== currId) {
        queryInit(newId, initialQuery)
      }
    }
    render() {
      const { ...passThroughProps } = this.props;
      return <WrappedComponent {...passThroughProps} />
    }
  }
)

const connectQuery = queryId => connect(
  (state, props) => ({ query: selectors.getQuery(state, props.queryId || queryId) }),
  (dispatch, props) => bindActionCreators(mapValues({
    updatePer,
    updatePage,
    updateSortBy,
    toggleSortDirection,
    addFilter,
    removeFilter,
    setFilters,
    clearFilters,
    addSearch,
    removeSearch,
    clearAllSearches
  }, f => f(props.queryId || queryId)), dispatch)
)

const withQuery = ({ queryId, initialQuery } = {}) => WrappedComponent => compose(
  // render order is top-down
  connectQueryInit,
  initQuery({ queryId, initialQuery }),
  connectQuery(queryId)
)(WrappedComponent)

export default withQuery
