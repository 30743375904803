import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import { useFetch } from "hooks";
import { get } from "utils/api";

const CompanyCmns = ({ companyId }) => {
  const {
    response: raCmnCompanyCmnInfo,
    isFetching,
    fetchData: getCompanyRaCmnLink
  } = useFetch({
    apiFn: companyId => get(`connect-answer/company-cmn-link/${companyId}`),
    defaultValue: {}
  });
  React.useEffect(() => {
    getCompanyRaCmnLink(companyId);
  }, [companyId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%"
      }}
    >
      {isFetching ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <InlineWaiting />
        </div>
      ) : (
        <iframe
          src={raCmnCompanyCmnInfo?.cmn_dashboard_link}
          height="100%"
          width="100%"
          style={{ height: "70vh%", display: "flex", flex: 1 }}
          allow="encrypted-media"
          allowFullScreen
          title="Patient Cmns"
          scrolling="yes"
          frameBorder="0"
        />
      )}
    </div>
  );
};

CompanyCmns.propTypes = { companyId: PropTypes.string.isRequired };

export default CompanyCmns;
