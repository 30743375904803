import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import AddEquipment from "components/profile/add-equipment";
import { InlineWaiting } from "components/ui";
import ModalLink from "components/shared/modal-link";
import IoMdclose from "react-icons/lib/io/close";
import { deletePatientEquipmentItem } from "actions/patient";
import IoMdCheckBoxOut from "react-icons/lib/io/android-checkbox-outline";
import IoMdCheckBoxOutlineBlank from "react-icons/lib/io/android-checkbox-outline-blank";
import {
  PatientCompanyActiveEquipmentContext,
  usePatientCompanyActiveEquipment
} from "components/forms/add-equipment";

const PatientsEquipment = ({ patientId }) => {
  const [isRemovingEquipment, setIsRemovingEquipment] = React.useState(false);
  async function deletePatientEquipment(equipmentId) {
    try {
      setIsRemovingEquipment(true);
      await deletePatientEquipmentItem({
        patientId,
        equipmentId
      });
    } finally {
      setIsRemovingEquipment(false);
    }
  }
  const equipment = useSelector(state =>
    selectors.getPatientsEquipment(state, patientId)
  );
  const { isFetchingCompanyActiveEq, active_products } =
    usePatientCompanyActiveEquipment({ patientId });
  return (
    <PatientCompanyActiveEquipmentContext.Provider
      value={{
        isFetchingCompanyActiveEq,
        active_products
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <table
          style={{
            width: "100%"
          }}
        >
          <thead>
            <tr>
              <th>Type</th>
              <th>Product ID</th>
              <th>Manufacturer</th>
              <th>Style</th>
              <th>Description</th>
              <th>HCPCS</th>
              <th>Autofit Result</th>
            </tr>
          </thead>
          <tbody>
            {equipment.length === 0 && (
              <tr>
                <td colSpan="100%">
                  This patient has no equipment entries yet.
                </td>
              </tr>
            )}
            {equipment &&
              equipment.map((eq, index) => (
                <tr key={`pe-${index}`}>
                  <td>
                    {isRemovingEquipment ? (
                      <InlineWaiting />
                    ) : (
                      <span
                        style={{
                          color: "#ff0000",
                          cursor: "pointer",
                          fontSize: "1.5em"
                        }}
                        onClick={() => {
                          deletePatientEquipment(eq.id);
                        }}
                        title="Remove"
                      >
                        <i
                          style={{
                            marginRight: "10px",
                            fontSize: "12px",
                            color: "salmon"
                          }}
                        >
                          <IoMdclose />
                        </i>
                      </span>
                    )}
                    {eq.type}
                  </td>
                  <td>{eq.manufacturerProductID}</td>
                  <td>{eq.manufacturer}</td>
                  <td>{eq.product_style}</td>
                  <td>
                    <ModalLink to={`/inventory/${eq.invItem_ID}`}>
                      {eq.description}
                    </ModalLink>
                  </td>
                  <td>{eq.HPCPCs}</td>
                  <td>
                    {eq.autofit_result ? (
                      <span className="autofit-checkbox">
                        <IoMdCheckBoxOut color="green" size={20} />
                      </span>
                    ) : (
                      <span className="autofit-checkbox">
                        <IoMdCheckBoxOutlineBlank color="#ccc" size={20} />
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div style={{ padding: "5px 10px" }}>
          <AddEquipment patientId={patientId} />
        </div>
      </div>
    </PatientCompanyActiveEquipmentContext.Provider>
  );
};

PatientsEquipment.propTypes = {
  patientId: PropTypes.string.isRequired
};

export default PatientsEquipment;
