import React from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { formatDate } from "utils/misc";
import { Link } from "react-router-dom";
import { selectors } from "reducers";
import { useSelector } from "react-redux";

const NoMaskOrdersTable = ({ orders }) => {
  const { rows, query, queryDispatcher } = useLocalQuery(orders, {
    sort: { by: "created_at", direction: "desc" }
  });
  const noMaskReasonOptions = useSelector(selectors.getNoMaskReasonOptions);
  return (
    <Table
      containerClass="query-table no-mask-reason-table"
      rows={rows}
      query={query}
      queryDispatcher={queryDispatcher}
      rowKey="order_id"
      columns={[
        {
          header: "Invoice #",
          field: "invoice_number"
        },
        {
          header: "Patient",
          field: "last_name"
        },
        {
          header: "Created Date",
          field: "created_at"
        },
        {
          header: "Company",
          field: "company"
        },
        {
          header: "Status",
          field: "status"
        },
        {
          header: "Reason",
          field: "no_mask_reason",
          filters: noMaskReasonOptions.map(({ text }) => ({
            value: text,
            text: text
          }))
        }
      ]}
      TableRow={({
        order_id,
        invoice_number,
        created_at,
        first_name,
        last_name,
        company,
        patient_id,
        status,
        no_mask_reason
      }) => (
        <tr>
          <td>
            <Link to={`/orders/${order_id}`}>{invoice_number}</Link>
          </td>
          <td>
            <Link to={`/patients/${patient_id}`}>
              {first_name} {last_name}
            </Link>
          </td>
          <td>{formatDate(created_at)}</td>
          <td>{company}</td>
          <td>{status}</td>
          <td>{no_mask_reason}</td>
        </tr>
      )}
    />
  );
};

NoMaskOrdersTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default NoMaskOrdersTable;
