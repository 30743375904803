import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPatientNote, fetchPatientNotes } from "actions/patient";
import { containsNoCreditCardInfo } from "utils/redux-form";
import { pubsub } from "utils/pubsub";

const NewNote = ({ patientId, dispatch }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState();
  pubsub.subscribe("emailMessage", async message => {
    setValue(message);
  });
  const handleChange = ({ target }) => {
    setValue(target.value);
    setError(containsNoCreditCardInfo(target.value));
    if (target.value.length > 3000) {
      setError("Note cannot be longer than 3000 characters");
    }
  };
  return (
    <form
      className="new-note"
      onSubmit={e => {
        e.preventDefault();
        if (!value.trim()) {
          return;
        }
        dispatch(
          createPatientNote({
            patientId,
            noteContent: value
          })
        ).then(() => dispatch(fetchPatientNotes({ patientId })));
        setValue("");
      }}
    >
      <label>
        <span>New note</span>
        <span className="note-validation-error">{error}</span>
        <textarea value={value} onChange={handleChange} />
      </label>
      <div
        style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
      >
        <button type="submit" disabled={error}>
          Submit
        </button>

        <div
          style={{
            color: "#333",
            fontSize: 14
          }}
        >
          <span
            style={{
              paddingLeft: 20
            }}
          >
            {value.length}
          </span>
          <span> / 3000</span>
        </div>
      </div>
    </form>
  );
};

NewNote.propTypes = {
  patientId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect()(NewNote);
