import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MdClose from "react-icons/lib/md/close";

const Message = ({ type, message, n, dismiss, period }) => {
  useEffect(() => {
    if (period && period !== -1) {
      const timeout = setTimeout(() => {
        dismiss();
      }, period);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [dismiss, period]);
  return (
    <div className={`box ${type === "MESSAGE" ? "success" : "warning"}`}>
      <div className="content">
        {Array.isArray(message)
          ? message.map((msg, i) => <p key={i}>{msg}</p>)
          : message}
        {type === "unknownError" && n > 1 && `(${n})`}
      </div>
      {period === -1 && (
        <div className="dismiss" onClick={dismiss}>
          <MdClose />
        </div>
      )}
    </div>
  );
};

Message.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["MESSAGE", "ERROR", "unknownError", "UNKNOWN_ERROR"]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  n: PropTypes.number,
  dismiss: PropTypes.func.isRequired,
  period: PropTypes.number
};

export default Message;
