import React from "react";
import PropTypes from "prop-types";
import styler from "react-styling";
import Cart from "react-icons/lib/io/ios-cart";
import CardContainer from "./card-container";
import { Link } from "react-router-dom";
import { formatMoney } from "accounting";
import HasRolePermission from "components/shared/has-role-permission";

const OrdersTableCard = ({
  dashboardOrderData,
  totalOrdersCount,
  dayPicker,
  classProp
}) => {
  return (
    <CardContainer classProp={classProp}>
      <div className="order-table-container" style={S.orderContentContainer}>
        <div style={S.iconContainer}>
          <Cart
            size="3rem"
            style={{ style: { margin: "0 auto", color: "#868786;" } }}
          />
          <div style={S.title}>Orders</div>
          <div style={S.totalCount}>{`${totalOrdersCount}`}</div>
        </div>
        {dayPicker}
        <div className="order-table-header-row" style={S.orderTableHeaderRow}>
          <div className="small-6 columns">Order Status</div>
          <div style={S.valueStyle} className="small-3 columns">
            Count
          </div>
          <HasRolePermission
            allowedRoles={["Administrator", "CompanyAdministrator"]}
            renderElse={<div className="small-3 columns" />}
          >
            <div style={S.valueStyle} className="small-3 columns">
              Revenue
            </div>
          </HasRolePermission>
        </div>
        <div style={S.orderStatusContainer}>
          {dashboardOrderData.rows.map((row, i) => (
            <div key={i} className="row" style={S.orderTableRow}>
              {row.pathState ? (
                <div className="small-6 columns" style={S.link}>
                  <Link
                    to={{
                      pathname: "/orders",
                      state: { status: row.pathState }
                    }}
                  >
                    {row.title}
                  </Link>
                </div>
              ) : (
                <div className="small-6 columns">{row.title}</div>
              )}
              <div style={S.valueStyle} className="small-3 columns">
                {row.count == 0 || row.count ? row.count : ""}
              </div>
              <HasRolePermission
                allowedRoles={["Administrator", "CompanyAdministrator"]}
                renderElse={<div className="small-3 columns" />}
              >
                <div style={S.valueStyle} className="small-3 columns">
                  {row.value ? formatMoney(row.value) : ""}
                </div>
              </HasRolePermission>
            </div>
          ))}
        </div>
      </div>
    </CardContainer>
  );
};

export default OrdersTableCard;

const S = styler`
  orderContentContainer
    display: flex
    flex-direction: column
    flex: 1
    height: 100%
  orderStatusContainer
    display: flex
    flex-direction: column
    overflow-y: scroll
    flex: 1
  link
    color: #4281a6
  title
    font-weight: bold;
    margin-top: 0.5rem;
    font-size: 2rem;
    color: #868786;
  orderTableRow
    margin: .75rem 0 1rem 0
  orderTableHeaderRow
    font-weight: bold;
    margin: 1rem 0 3.1rem 0;
    font-size: 1rem;
    color: #868786;
  valueStyle
    text-align: center
    font-weight: bold
  iconContainer
    color: #868786;
    text-align: center
  totalCount
    font-size: 5rem
    color: #666
`;

OrdersTableCard.propTypes = {
  dashboardOrderData: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  totalOrdersCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dayPicker: PropTypes.object,
  classProp: PropTypes.string
};
