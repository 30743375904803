import React, { useEffect, useContext, useState } from "react";
import "./styles.css";

import PropTypes from "prop-types";
import IoMdCheckmark from "react-icons/lib/io/checkmark";

import { Table } from "hooks/useQuery/api-query";
import { MatchContext } from "./MatchContext";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import { transformUiQuery } from "utils/query";

const FirstTimeLoginTable = ({ searchFilters, ticketNumberId }) => {
  const [data, setData] = useState({});
  const [rows, setRows] = useState([]);

  const { fetchData, isFetching } = useFetch({
    apiFn: body =>
      put(
        `first-time-registration-dashboard/${ticketNumberId}/matching-patients`,
        body
      ),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: err => errorMessage(`Failed to get First time Patient: ${err}`)
  });

  const fetchRows = async body => {
    const finalBody = transformUiQuery(body);
    const res = await fetchData(finalBody);

    if (res.success) {
      setData(res.Data);
      setRows(res.Patients);
    }
  };

  useEffect(() => {
    const { firstName, lastName, phoneNumber, email, dateOfBirth } =
      searchFilters;
    const invalidQuery =
      !firstName && !lastName && !phoneNumber && !email && !dateOfBirth;

    if (!invalidQuery) {
      let queryUpdated = {
        filters: {
          first_name: firstName ? [firstName] : [],
          last_name: lastName ? [lastName] : [],
          phone_number: phoneNumber ? [phoneNumber] : [],
          ticket_number: ticketNumberId ? [ticketNumberId] : [],
          email: email ? [email] : [],
          date_of_birth: dateOfBirth ? [dateOfBirth] : []
        },
        pagination: { page: 1, per: 150 }
      };

      fetchRows(queryUpdated);
    }
  }, [searchFilters]);

  return (
    <div style={{ marginTop: "2em" }}>
      <h2 className="section-title">Possible Matches</h2>
      <div>
        {rows && (
          <Table
            query={{}}
            data={data}
            queryDispatcher={() => {}}
            isFetching={isFetching}
            containerClass="query-table patients-table dynamic-columns"
            columns={[
              {
                header: "Status",
                field: "Status"
              },
              {
                header: "Name",
                field: "Name"
              },
              {
                header: "Account Number",
                field: "AccountNumber"
              },
              {
                header: "Email",
                field: "Email"
              },
              {
                header: "Phone Number",
                field: "PhoneNumber"
              },
              {
                header: "Date of Birth",
                field: "DateOfBirth"
              },
              {
                header: "Company",
                field: "Company"
              },
              {
                header: "Stage",
                field: "Stage"
              }
            ]}
            rows={rows}
            rowKey="id"
            perPageOptions={[
              { text: "50", value: 50 },
              { text: "150", value: 150 },
              { text: "500", value: 500 }
            ]}
            TableRow={TableRow}
          />
        )}
      </div>
    </div>
  );
};

export default FirstTimeLoginTable;

FirstTimeLoginTable.propTypes = {
  searchFilters: PropTypes.shape({
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    dateOfBirth: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    ticketNumberId: PropTypes.string
  }),
  ticketNumberId: PropTypes.string
};

const TableRow = ({
  PatientId,
  Status,
  Name,
  AccountNumber,
  Email,
  PhoneNumber,
  DateOfBirth,
  Company,
  Stage,
  FirstNameMatch,
  LastNameMatch,
  AccountNumberMatch,
  EmailMatch,
  PhoneNumberMatch,
  DateOfBirthMatch
}) => {
  const { selectPatient } = useContext(MatchContext);
  return (
    <tr>
      <td>{Status}</td>
      <td
        style={{ cursor: "pointer", color: "#4281A6" }}
        title="View this patient's profile"
        onClick={() => selectPatient(PatientId)}
      >
        {FirstNameMatch && LastNameMatch && <IoMdCheckmark />} {Name}
      </td>
      <td>
        {AccountNumberMatch && <IoMdCheckmark />} {AccountNumber}{" "}
      </td>
      <td>
        {EmailMatch && <IoMdCheckmark />} {Email}{" "}
      </td>
      <td>
        {PhoneNumberMatch && <IoMdCheckmark />} {PhoneNumber}
      </td>
      <td>
        {DateOfBirthMatch && <IoMdCheckmark />} {DateOfBirth}
      </td>
      <td>{Company}</td>
      <td>{Stage}</td>
    </tr>
  );
};
TableRow.propTypes = {
  PatientId: PropTypes.string,
  Status: PropTypes.string,
  Name: PropTypes.string,
  AccountNumber: PropTypes.string,
  Email: PropTypes.string,
  PhoneNumber: PropTypes.string,
  DateOfBirth: PropTypes.string,
  Company: PropTypes.string,
  Stage: PropTypes.string,
  FirstNameMatch: PropTypes.bool,
  LastNameMatch: PropTypes.bool,
  AccountNumberMatch: PropTypes.bool,
  EmailMatch: PropTypes.bool,
  PhoneNumberMatch: PropTypes.bool,
  DateOfBirthMatch: PropTypes.bool
};
