import React from "react";
import PropTypes from "prop-types";
import IoIosInfoCircleOutline from "react-icons/lib/io/ios-information";
import { Tooltip } from "components/ui/tool-tip/tooltip";

const ComplianceDashboardActionViewCard = ({ categoryId, title, description, value, color, helpText, onCardSelected, fetchingActionViewData }) => {
    
    const selectedCardTitle = title + " " + description
    return (
    <div className="compliance-action-view-card"
        style={{ borderTopColor: color, borderBottomColor: color, cursor: 'pointer', pointerEvents: fetchingActionViewData ? "none" : undefined, opacity: fetchingActionViewData ? "0.5" : undefined }}
        onClick={() => {
            description ?
            onCardSelected(categoryId, selectedCardTitle)
            :
            onCardSelected(categoryId, title)
        }}>
        <div className="compliance-action-view-card-title">
        <div className="tile-title">
            <span>{title}</span>
            {
                description ?
                <span>{description}</span>
                :
                null
            }
        </div>
        <Tooltip
            id={`single-stat-tooltip-${description ? selectedCardTitle : title}`}
            aTagClassName="infoCircle"
            effect="solid"
            message={helpText}
        >
            <IoIosInfoCircleOutline size="1rem" />
        </Tooltip>
        </div>
        <span className="compliance-action-view-card-value">{value && value.toLocaleString()}</span>
    </div>
)};

ComplianceDashboardActionViewCard.propTypes = {
  categoryId: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  helpText: PropTypes.string,
  onCardSelected: PropTypes.func,
  fetchingActionViewData: PropTypes.bool
};

export default ComplianceDashboardActionViewCard;
