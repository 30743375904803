import React from "react";
import PropTypes from "prop-types";
import { formatPercantage, deSnakeCase, capitalize } from "utils/misc";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from "react-tooltip";
import {
  ResponsiveContainer,
  LabelList,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const displayValueAndPercent = total => result => {
  return typeof result === "number"
    ? `${result} (${formatPercantage(result, total)})`
    : result.value == 0
    ? ""
    : `${result.value} (${formatPercantage(result.value, total)})`;
};

const StatusBreakdown = ({ stats, onExport }) => {
  const chartData = stats
    .filter(c => c.name !== "TOTAL")
    .map(({ name, count }) => ({
      count,
      label: capitalize(deSnakeCase(name), true)
    }));
  const total = chartData.find(c => c.name !== "TOTAL");
  return (
    <div
      style={{
        border: "1px solid #DDD",
        padding: "16px 20px 20px 20px",
        marginBottom: "20",
        position: "relative"
      }}
    >
      <ReactTooltip />
      {onExport && (
        <div
          className="stat-export-icon"
          data-tip="Export"
          onClick={onExport}
          style={{ top: "35px", right: "15px", zIndex: "50" }}
        >
          <FaFileExcelO />
        </div>
      )}
      <header style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ fontSize: "1.4em", textTransform: "uppercase" }}>
          Order Stats
        </h3>
        <div style={{ fontSize: "1.1em" }}>
          Total: <strong>{total ? total.count : null}</strong>
        </div>
      </header>
      <ResponsiveContainer width="100%" height={700} style={{ paddingTop: 10 }}>
        <BarChart
          data={chartData}
          layout="vertical"
          margin={{
            left: 50,
            right: 50
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            type="number"
            dataKey="count"
            domain={[0, dataMax => dataMax * 1.5]}
            scale="sqrt"
          />
          <YAxis dataKey="label" type="category" />
          <Bar
            dataKey="count"
            fill="rgb(49, 130, 189)"
            isAnimationActive={false}
            maxBarSize={20}
          >
            <LabelList
              dataKey="count"
              position="right"
              content={displayValueAndPercent(total)}
            />
          </Bar>
          <Tooltip formatter={displayValueAndPercent(total)} cursor={false} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default React.memo(StatusBreakdown);

StatusBreakdown.defaultProps = {
  stats: []
};

StatusBreakdown.propTypes = {
  stats: PropTypes.array.isRequired,
  onExport: PropTypes.func
};
