import React, { useState } from "react";
import PropTypes from "prop-types";
import Text from "./../../components/TextComponent";
import AutoCompleteSelect from "./../../components/AutoCompleteSelectComponent";
import { PageHeader } from "components/ui";
import TextArea from "./../../components/TextAreaComponent";

export default function QuestionnaireInformation(props) {
    const [title, changeTitle] = useState(props.questionnaire.title);
    const [products] = useState(props.Products);
    const [services] = useState(props.Services);
    const [description, changeDescription] = useState(props.questionnaire.description);
    const [typeOfQuestionnaire, changeQuestionnaireType] = useState(props.questionnaire.questionnaire_type === "2" ? "Product" : "Service");
    const [productsDropdownOption, propductsDropdownChange] = useState((props.questionnaire.questionnaire_type === "2" && props.Products && props.Products.length > 0 && props.questionnaire.questionnaire_id) ? props.questionnaire.product_id : "");
    const [servicesDropdownOption, servicesDropdownChange] = useState((props.questionnaire.questionnaire_id !== "2" && props.Services && props.Services.length > 0 && props.questionnaire.questionnaire_id) ? props.questionnaire.service_id : "");

    function selectionTyeChange(value) {
        changeQuestionnaireType(value);
        if (value === "Product") {
            if (products && products.length > 0) {
                propductsDropdownChange(products[0].value);
                servicesDropdownChange(null);
            }
        } else {
            if (services && services.length > 0) {
                servicesDropdownChange(services[0].value);
                propductsDropdownChange(null);
            }
        }
    }

    function dropdownChange({ value }) {
        if (typeOfQuestionnaire === "Product") {
            propductsDropdownChange(value);
            servicesDropdownChange(null);
        } else {
            servicesDropdownChange(value);
            propductsDropdownChange(null);
        }
    }

    function saveQuestionnaireData() {
        let objCollection = {
            title: title,
            description: description,
            optionType: typeOfQuestionnaire,
            productId: productsDropdownOption,
            serviceId: servicesDropdownOption,
            questionnaire_id: props.questionnaire.questionnaire_id
        };
        props.saveAll(objCollection);
    }

    return (
        <div className="dd_col-12">
            <PageHeader title="Basic Information" />
            <div className="dd_row">
                <div className="dd_col-2">
                    <h4>Title</h4></div>
                <div className="dd_col-10">
                    <Text maxLength={90} id="questionnaire_title" placeholder="Enter title" onChange={({ target: { value } }) => changeTitle(value)} value={title} />
                </div>
            </div>
            <div className="dd_row">
                <div className="dd_col-2">
                    <h4>Description</h4>
                </div>
                <div className="dd_col-10">
                    <TextArea rows={3} maxLength={390} id="questionnaire_description" placeholder="Enter desscription" onChange={({ target: { value } }) => changeDescription(value)} value={description} />
                </div>
            </div>

            <div className="dd_row">

                <div className="dd_col-2">
                    <h4>Select Type</h4>
                </div>
                <div className="dd_col-10">
                    <input onClick={({ target: { value } }) => selectionTyeChange(value)} type="radio" name="QuestionnaireType" value="Product" checked={(typeOfQuestionnaire === "Product")} /> Product &nbsp;&nbsp;&nbsp;
                <input onClick={({ target: { value } }) => selectionTyeChange(value)} type="radio" name="QuestionnaireType" value="Service" checked={(typeOfQuestionnaire === "Service")} /> Service
            </div>
            </div>
            {(typeOfQuestionnaire === "Product") ?
                <div className="dd_row"><div className="dd_col-2">
                    <h4>Select Product</h4>
                </div>
                    <div className="dd_col-10">
                        <AutoCompleteSelect
                            value={productsDropdownOption}
                            label="Select Product"
                            options={products}
                            onChange={dropdownChange}
                        />
                    </div> </div> :
                <div className="dd_row">  <div className="dd_col-2"><h4>Select Service</h4></div>
                    <div className="dd_col-10">
                        <AutoCompleteSelect
                            value={servicesDropdownOption}
                            label="Select Service"
                            options={services}
                            onChange={dropdownChange}
                        />
                    </div></div>
            }
            <div className="dd_row">
                <div className="dd_col-12">
                    <button type="button" onClick={saveQuestionnaireData}>Save All</button>
                </div>
            </div>
        </div>
    )
}

QuestionnaireInformation.propTypes = { 
  questionnaire: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    questionnaire_type: PropTypes.string,
    questionnaire_id: PropTypes.number,
    product_id: PropTypes.string,
    service_id: PropTypes.string
  }).isRequired,
  Products: PropTypes.arrayOf(PropTypes.object).isRequired,
  Services: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveAll: PropTypes.func.isRequired
}
