import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";

const FormikDropdownField = ({
  name,
  label,
  labelAppend,
  style,
  className,
  inlineError,
  requiredQuestion,
  options,
  handleChange,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionToggle = (e, optionId) => {
    if (selectedOptions.includes(optionId)) {
      
      let removedValue = selectedOptions.filter(id => id !== optionId)
      setSelectedOptions(removedValue);
      handleChange(e, removedValue);
    } else {
      let addedValue = [...selectedOptions, optionId]
      setSelectedOptions(addedValue);
      handleChange(e, addedValue)
    }
  };

  function showCheckboxes() {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  }

  return (
    <span className={className} style={style}>
      <span className="form-field-header">
        {label && (
          <label
            className="form-field-label"
            style={requiredQuestion ? { marginRight: "0" } : undefined}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {requiredQuestion && (
          <label style={{ color: "red" }} htmlFor={name}>
            *
          </label>
        )}
        {labelAppend && (
          <label className="form-field-label-append" htmlFor={labelAppend}>
            {`* ${labelAppend}`}
          </label>
        )}
      </span>
      {!inlineError && (
        <span className="form-field-error">
          <ErrorMessage name={name} />
        </span>
      )}

      <Field name={name}>
        {({ field }) => (
          <div style={{ width: "100%" }} className="multiselect">
            <div
              style={{ position: "relative" }}
              className="selectBox"
              onClick={showCheckboxes}
            >
              <select
                style={{ width: "100%", fontWeight: "bold" }}
                onBlur={field.onBlur}
              >
                <option>{expanded ? "Close" : (selectedOptions.length > 0 ? "Option selected" : "Choose an option")}</option>
              </select>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0
                }}
              ></div>
            </div>
            <div
              id="checkboxes"
              style={{ display: "none", border: "1px #dadada solid" , marginTop: "-14px", padding: "12px 6px" }}
            >
              {options.map(({ FormQuestionOptionsID, Text }, index) => (
                <label
                  key={index}
                  htmlFor={`checkbox-${FormQuestionOptionsID}`}
                  style={{ display: "block", marginRight: "8px" }}
                >
                  <input
                    id={`checkbox-${FormQuestionOptionsID}`}
                    type="checkbox"
                    onChange={(e) => handleOptionToggle(e, FormQuestionOptionsID)}
                  />
                  {Text}
                </label>
              ))}
            </div>
          </div>
        )}
      </Field>

      {inlineError && (
        <span className="form-field-error-inline">
          <ErrorMessage name={name} />
        </span>
      )}
    </span>
  );
};
export default FormikDropdownField;

FormikDropdownField.propTypes = {
  name: PropTypes.string.isRequired,
  labelAppend: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  className: PropTypes.string,
  inlineError: PropTypes.bool,
  requiredQuestion: PropTypes.bool,
  handleChange: PropTypes.func,
  options: PropTypes.array,
};

FormikDropdownField.defaultProps = { style: {}, className: "form-field" };
