import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import * as R from "ramda";
import { QueryTableContext } from "hooks/useQuery/api-query";
import { updateFilters } from "hooks/useQuery/actions";
import Dropdown from "components/ui/dropdown";
import { IconButton } from "components/ui";
import MdFilterList from "react-icons/lib/md/filter-list";
const TaskOwnerFilter = ({ owners, companyUsersDme, companyUsersS3  }) => {
    const [selectedOwner, selectOwner] = useState('');
    const { query, queryDispatcher } = useContext(QueryTableContext);
    const [selectedOwnerUsers, setSelectedOwnerUsers] = useState(
        query.filters?.assigned_to ?? []
    );
    const [searchTerms, setSearchTerms] = useState("");

    const filterUsers = user => {
        return (
            user.text.toLowerCase().includes(searchTerms.toLowerCase())
        );
    };

    React.useEffect(() => {
        return () => {};
    }, []);

    return (
        <td className="query-table-header-cell-container">
            <div className="query-table-header-cell">
                <div>
                    Owner
                </div>
                <Dropdown
                trigger={
                    <IconButton
                    icon={<MdFilterList />}
                    className="button secondary icon-button column-filter-trigger"
                    />
                }
                onClose={() => {
                    query.filters?.owner ?? [];
                    queryDispatcher(
                    updateFilters({
                        ...query.filters,
                        owner: selectedOwner,
                        assigned_to: selectedOwnerUsers
                    })
                    );
                }}
                style={{ marginTop: 35 }}
                >
                <div
                    onClick={() => {
                        setSelectedOwnerUsers([]);
                    }}
                    className="filter-pane-command controls"
                >
                    Clear Selections
                </div>
                <div
                    onClick={() => {
                        const value = (selectedOwner == 'DME' ? companyUsersDme : companyUsersS3)
                            .filter(({ value }) => !selectedOwnerUsers.includes(value))
                            .map(({ value }) => value);
                            setSelectedOwnerUsers(value);
                    }}
                    className="filter-pane-command controls"
                >
                    Invert Selections
                </div>
                <div className="comany-team-select">
                    <label>Owner</label>
                    <select
                        onChange={({ target }) => { 
                            selectOwner(target.value);
                            setSelectedOwnerUsers([]);
                        }}
                        value={selectedOwner}
                    >
                        {owners.map(({ value, text }) => (
                            <option value={value} key={value}>
                                {text}
                            </option>
                        )
                    )}
                    </select>
                </div>
                <div>
                    <input
                        type="text"
                        className="searchInputBox"
                        onChange={e => {
                            setSearchTerms(e.target.value);
                        }}
                        value={searchTerms}
                        placeholder="Search By User"
                    />
                </div>
                
                {selectedOwner != '' ? 
                    (selectedOwner == 'DME' ? companyUsersDme : companyUsersS3)
                        .filter(filterUsers)
                        .map(user => (
                            <div key={user.value}>
                                <label className="filter-entry">
                                    <input
                                        type="checkbox"
                                        checked={selectedOwnerUsers.includes(user.value)}
                                        onChange={({ target }) => {
                                            if (target.checked) {
                                                setSelectedOwnerUsers(
                                                    R.uniq([user.value, ...selectedOwnerUsers])
                                                );
                                            } else {
                                                setSelectedOwnerUsers(
                                                    R.without([user.value], selectedOwnerUsers)
                                                );
                                            }
                                        }}
                                    />
                                    <span style={{ display: "flex", flex: "1 1 auto" }}>
                                        {user.text}
                                    </span>
                                </label>
                            </div>
                        )
                    )
                : ''}
                </Dropdown>
            </div>
        </td>
    );
};

export default connect(state => ({
    owners: [
        { text: "", value: "" },
        { text: "DME", value: "DME" },
        { text: "S3", value: "S3" }
    ],
    companyUsersDme: selectors.getFormOptionsCompanyUserDme(state),
    companyUsersS3: selectors.getFormOptionsCompanyUserS3(state)
}))(TaskOwnerFilter);

TaskOwnerFilter.propTypes = {
    owners: PropTypes.arrayOf(PropTypes.object),
    companyUsersDme: PropTypes.arrayOf(PropTypes.object),
    companyUsersS3: PropTypes.arrayOf(PropTypes.object),
    queryId: PropTypes.string.isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired
};
