// import { get } from "utils/api";

export const Types = {
  CHECK_APP_VERSION_LOGIN: "CHECK_APP_VERSION_LOGIN",
  CHECK_APP_VERSION_EVENT: "CHECK_APP_VERSION_EVENT",
  SET_NEW_APP_VERSIONS: "SET_NEW_APP_VERSIONS"
};
// const checkAppVersion = () => async dispatch => {
//   try {
//     const version = await get("version");
//     dispatch({ type: Types.SET_APP_VERSION, payload: version });
//     dispatch({ type: Types.CHECK_APP_VERSION, version });
//   } catch (err) {
//     console.error(err.message);
//   }
// };
