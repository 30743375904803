import {
  take,
  put,
  fork
} from 'redux-saga/effects'
import {
  message,
  errorMessage
} from 'actions/message'
import { Types, invalidateNotes, requestNotes } from "actions/order-profile";

export default function* watchRequestAddNote() {
  const tasks = {}
  while (true) {
    const action = yield take(Types.REQUEST_NOTE_ADD)
    const { meta: { orderId } } = action
    const task = tasks[orderId]
    if (!task || !task.isRunning()) {
      tasks[orderId] = yield fork(addNote, action)
    }
  }
}

function* addNote({
  payload: { note, creator_id, patient_id },
  meta: { orderId }
}) {
  yield put({
    type: Types.API_NOTE_ADD,
    payload: {
      apiRequest: {
        method: 'POST',
        route: `orders/${orderId}/notes`,
        params: { note, creator_id, patient_id },
        type: Types.RESPONSE_NOTE_ADD,
        meta: { orderId }
      }
    },
    meta: { orderId }
  })
  const { error } = yield take(Types.RESPONSE_NOTE_ADD)
  yield put(!error
            ? message('Note added!')
            : errorMessage('An error occurred while adding your note.'))
  yield put(invalidateNotes({ orderId })())
  yield put(requestNotes({ orderId })())
}
