import React from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  Tooltip
} from "recharts";
import { deSnakeCase } from "utils/misc";
import { formatTimeFromHour } from "utils/dates";
// import MdInfoOutline from "react-icons/lib/md/info-outline";

const CallInsightsChart = ({ callSnapshotReport }) => {
  const [visibleBars, setVisibleBars] = React.useState({
    outbound_calls: true,
    inbound_calls: true,
    total_calls: true,
    voicemails: true,
    orders_submitted: true
  });
  const handleLegendCheckbox = ({ target }) => {
    setVisibleBars({
      ...visibleBars,
      [target.name]: !visibleBars[target.name]
    });
  };
  return (
    <div className="call-insights-chart">
      {/* <label className="call-insights-chart-header"> */}
      {/*   <MdInfoOutline /> */}
      {/*   <span>CST Time</span> */}
      {/* </label> */}
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={callSnapshotReport}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="time_position"
            tickFormatter={time => formatTimeFromHour(time)}
          />
          <YAxis />
          {visibleBars.outbound_calls && (
            <Bar
              dataKey="outbound_calls"
              stackId="a"
              fill="#8884d8"
              barSize={15}
            />
          )}
          {visibleBars.inbound_calls && (
            <Bar
              dataKey="inbound_calls"
              stackId="a"
              fill="#82ca9d"
              barSize={15}
            />
          )}
          {visibleBars.voicemails && (
            <Bar dataKey="voicemails" fill="orange" barSize={10} />
          )}
          {visibleBars.orders_submitted && (
            <Area dataKey="orders_submitted" stroke="#8884d8" fill="#f25c58" />
          )}
          {visibleBars.total_calls && (
            <Line type="monotone" dataKey="total_calls" stroke="#85a7ba" />
          )}
          <Tooltip
            cursor={false}
            formatter={(value, name) => [value, deSnakeCase(name)]}
            labelFormatter={t =>
              `${formatTimeFromHour(t > 0 ? t - 1 : 23)} - ${formatTimeFromHour(
                t
              )}`
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className="form-of-contact-chart-legend">
        <label
          style={{
            color: "#85a7ba"
          }}
        >
          <input
            type="checkbox"
            name="total_calls"
            onChange={handleLegendCheckbox}
            checked={visibleBars.total_calls}
          />
          Total Calls
        </label>
        <label style={{ color: "#8884d8" }}>
          <input
            type="checkbox"
            name="outbound_calls"
            onChange={handleLegendCheckbox}
            checked={visibleBars.outbound_calls}
          />
          Outbound Calls
        </label>
        <label
          style={{
            color: "#82ca9d"
          }}
        >
          <input
            type="checkbox"
            name="inbound_calls"
            onChange={handleLegendCheckbox}
            checked={visibleBars.inbound_calls}
          />
          Inbound Calls
        </label>
        <label style={{ color: "orange" }}>
          <input
            type="checkbox"
            name="voicemails"
            onChange={handleLegendCheckbox}
            checked={visibleBars.voicemails}
          />
          Voicemails
        </label>
        <label
          style={{
            color: "#f25c58"
          }}
        >
          <input
            type="checkbox"
            name="orders_submitted"
            onChange={handleLegendCheckbox}
            checked={visibleBars.orders_submitted}
          />
          Orders Submitted
        </label>
      </div>
    </div>
  );
};

export default connect(state => ({
  callSnapshotReport: selectors.getCallSnapshots(state)
}))(CallInsightsChart);

CallInsightsChart.propTypes = {
  callSnapshotReport: PropTypes.arrayOf(PropTypes.object).isRequired
};
