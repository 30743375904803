import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import MultiSelectDropdown from "components/forms/multi-select-dropdown";

const HoldReasonFilter = ({ setFilters, holdReasonOptions, clearFilters }) => {
  const [filterReasons, setFilterReasons] = useState([]);
  const [filterReasonStatus, setFilterReasonStatus] = useState(
    "hold_reasons_unresolved"
  );
  return (
    <div className="table-hold-reason-filter">
      <span className="table-hold-reason-filter-label">Worklist Items</span>
      <MultiSelectDropdown
        searchable={false}
        onChange={setFilterReasons}
        onClear={() => clearFilters(filterReasonStatus)}
        onBlur={() => {
          setFilters(filterReasonStatus, filterReasons);
        }}
        onRemove={s => {
          setFilters(
            filterReasonStatus,
            filterReasons.filter(h => h !== s.value)
          );
        }}
        values={filterReasons}
        options={holdReasonOptions}
      />
      <select
        value={filterReasonStatus}
        onChange={({ target }) => {
          clearFilters(filterReasonStatus);
          setFilterReasonStatus(target.value);
          setFilters(target.value, ...filterReasons);
        }}
      >
        any_hold_reasons unresolved_hold_reasons resolved_hold_reasons
        <option value="hold_reasons_unresolved">Incomplete</option>
        <option value="hold_reasons_resolved">Completed</option>
        <option value="hold_reasons_any">Any</option>
      </select>
    </div>
  );
};

HoldReasonFilter.propTypes = {
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  holdReasonOptions: PropTypes.arrayOf(PropTypes.object)
};

export default connect(state => ({
  holdReasonOptions: selectors.getOrderHoldReasonOptions(state)
}))(HoldReasonFilter);
