import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IoMdCheckmark from "react-icons/lib/io/checkmark";
import IoMdClose from "react-icons/lib/io/close";
import { InventoryContext } from "../inventory-provider";
import { Spinner } from "components/ui";

const CompatibleItemsInventoryTableRow = ({
  id,
  product_id,
  name,
  hcpcs,
  product_type,
  manufacturer,
  avgReimbursment,
  productActive
}) => {
    const {
        deleteRelationship,
        deleteRelationshipIsFetching
    } = useContext(InventoryContext);
    const url = window.location.href;
    const idTwo = url?.split("/")?.pop();

    const data = {
        idOne: id,
        idTwo
    };

    return (
        <tr>
          <td>
            <Link to={`/inventory/${id}`}>{name}</Link>
          </td>
          <td>{product_id}</td>
          <td>{hcpcs}</td>
          <td>{product_type}</td>
          <td>{manufacturer}</td>
          <td>${avgReimbursment}</td>
          <td>{productActive ? <IoMdCheckmark /> : <IoMdClose />}</td>
          {deleteRelationshipIsFetching 
            ? <Spinner /> 
            : <td>{<IoMdClose 
                    style={{ color: "red", cursor: "pointer" }} 
                    onClick={(e) => {
                        e.preventDefault();
                        deleteRelationship(data);
                    }} />
                    }</td>}
        </tr>
      )
};
export default React.memo(CompatibleItemsInventoryTableRow);

CompatibleItemsInventoryTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  product_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hcpcs: PropTypes.string.isRequired,
  product_type: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  avgReimbursment: PropTypes.number.isRequired,
  productActive: PropTypes.bool.isRequired
};
