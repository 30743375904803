import { GET_FAQ, UPDATE_FAQ, DELETE_FAQ, UPDATE_STATUS_FAQ } from './../ActionTypes';

const initialState = {
    Questions: []
}

const reducerFaq = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAQ:
            return {
                ...state,
                Questions: action.payload
            };
        case UPDATE_FAQ:
            return {
                ...state,
                Questions: state.Questions.map(
                    (content) => content.faq_id === action.payload.faq_id ? { ...content, question: action.payload.question, answer: action.payload.answer }
                        : content)
            };
        case DELETE_FAQ:
            return {
                ...state,
                Questions: state.Questions.map(
                    (content) => content.faq_id === action.payload ? { ...content, active: false }
                        : content)
            };
        case UPDATE_STATUS_FAQ:
            return {
                ...state,
                Questions: state.Questions.map(
                    (content) => content.faq_id === action.payload.faq_id ? { ...content, active: action.payload.active }
                        : content)
            };
        default:
            return state;
    }
}

export default reducerFaq;
