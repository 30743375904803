import { Types as OrderFormTypes } from "actions/order-form";
import { Types as AuthTypes } from "actions/authentication";

export default function broadcastChannelMiddleware() {
  const channelName = "phoenix-s3";
  // When BroadcastChannel is not available, return opaque middleware
  if (!window.BroadcastChannel) {
    if (process.env.NODE_ENV === "development") {
      console.error("BroadcastChannel API is not available");
    }
    return () => next => action => next(action);
  }

  const broadcastChannel = new BroadcastChannel(channelName);

  return store => {
    // Listener, other tab actions
    broadcastChannel.addEventListener("message", messageEvent => {
      try {
        const { data } = messageEvent;
        switch (data.type) {
          case OrderFormTypes.ORDER_FORM_RESPONSE:
            store.dispatch({
              type: OrderFormTypes.ORDER_SUBMITTED_IN_OTHER_WINDOW,
              fromOtherAppInstance: true
            });
            break;
          case AuthTypes.TWO_FACTOR_AUTH_LOGIN:
            store.dispatch({
              type: AuthTypes.TWO_FACTOR_AUTH_APPROVED,
              fromOtherAppInstance: true,
              user: data.user
            });
            break;
          default:
            break;
        }
      } catch (err) {
        console.error(err);
      }
    });

    // Emitter, actions to other tabs
    return next => action => {
      switch (action.type) {
        case OrderFormTypes.ORDER_FORM_RESPONSE:
          broadcastChannel.postMessage(action);
          break;
        case AuthTypes.TWO_FACTOR_AUTH_LOGIN:
          broadcastChannel.postMessage(action);
          break;
        default:
          break;
      }
      return next(action);
    };
  };
}
