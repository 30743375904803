import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestVoipUsers } from "actions/phone-book";
import { updateCoachSettings } from "actions/user-profile";
import { selectors } from "reducers";
import MdCheck from "react-icons/lib/md/check";
import MdClose from "react-icons/lib/md/close";
import UserOutboundCallSwitch from "./outbound-call-switch";
import UserInboundCallSwitch from "./inbound-calls-switch";
import HasRolePermission from "components/shared/has-role-permission";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import compact from "lodash/compact";

const PhoneBookTable = ({
  phoneUsers,
  teams,
  userRole,
  requestVoipUsers,
  updateCoachSettings
}) => {
  useEffect(() => {
    requestVoipUsers();
  }, []);
  const { rows, query, queryDispatcher } = useLocalQuery(phoneUsers, {
    sort: { by: "last_name", direction: "asc" },
    filters: {}
  });
  return (
    <Table
      TableRow={row => (
        <PhoneBookTableRow
          userRole={userRole}
          updateCoachSettings={updateCoachSettings}
          {...row}
        />
      )}
      columns={compact([
        userRole === "Administrator" && {
          field: "online",
          header: "Online",
          filters: [
            { text: "Yes", value: true },
            { text: "No", value: false }
          ],
          dropdownPosition: "right"
        },
        {
          field: "last_name",
          header: "Name"
        },
        {
          field: "type",
          header: "User Type",
          filters: [
            { text: "SleepCoach", value: "SleepCoach" },
            { text: "Administrator", value: "Administrator" },
            { text: "Office Administrator", value: "OfficeAdministrator" },
            { text: "Company Administrator", value: "CompanyAdministrator" }
          ]
        },
        {
          field: "extension",
          header: "Extension"
        },
        {
          field: "call_group",
          header: "Team",
          filter: "call_group",
          filters: teams
        },
        (userRole === "Administrator" || userRole === "ServiceAdmin" || userRole === "ContractorAdministrator") && {
          field: "outboundCallRestricted",
          header: "Outbound Restricted",
          filters: [
            { text: "Yes", value: true },
            { text: "No", value: false }
          ]
        },
        (userRole === "Administrator" || userRole === "ServiceAdmin" || userRole === "ContractorAdministrator") && {
          field: "block_autodialer_incoming_calls",
          header: "Dialer Answer Inbound Calls",
          filters: [
            { text: "Yes", value: true },
            { text: "No", value: false }
          ]
        }
      ])}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={rows}
      rowKey="id"
    />
  );
};

export default connect(
  state => ({
    phoneUsers: selectors.getPhoneBookUsers(state),
    teams: selectors.getTeamNameOptions(state),
    userRole: selectors.getUserRole(state)
  }),
  { requestVoipUsers, updateCoachSettings }
)(PhoneBookTable);

PhoneBookTable.propTypes = {
  phoneUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestVoipUsers: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateCoachSettings: PropTypes.func.isRequired
};

const PhoneBookTableRow = ({
  id,
  online,
  first_name,
  last_name,
  type,
  extension,
  call_group,
  outboundCallRestricted,
  block_autodialer_incoming_calls,
  userRole,
  updateCoachSettings
}) => (
  <tr>
    {userRole === "Administrator" && (
      <td>{online ? <MdCheck color="green" /> : <MdClose color="red" />}</td>
    )}
    <td>
      {first_name} {last_name}
    </td>
    <td>{type}</td>
    <td>{extension}</td>
    <td>{call_group}</td>
    <HasRolePermission allowedRoles={["Administrator", "ServiceAdmin", "ContractorAdministrator"]}>
      <td>
        <UserOutboundCallSwitch
          id={id}
          updateCoachSettings={updateCoachSettings}
          outboundCallRestricted={outboundCallRestricted}
        />
      </td>
      <td>
        <UserInboundCallSwitch
          id={id}
          updateCoachSettings={updateCoachSettings}
          block_autodialer_incoming_calls={block_autodialer_incoming_calls}
        />
      </td>
    </HasRolePermission>
  </tr>
);

PhoneBookTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  online: PropTypes.bool,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  type: PropTypes.string,
  extension: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  call_group: PropTypes.string,
  updateCoachSettings: PropTypes.func.isRequired,
  outboundCallRestricted: PropTypes.bool,
  block_autodialer_incoming_calls: PropTypes.bool
};
