import React from 'react'
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { Spinner } from 'components/ui';
import { errorMessage, message } from "actions/message";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { useDispatch } from 'react-redux';


const NotesShowEditModal = ({ notes, setNote, closeModal, isWorked, ticketNumberId }) => {

  const dispatch = useDispatch()

  const { fetchData: saveNote, isFetching : savingNote } = useFetch({
    apiFn: (notes) => put(`first-time-registration-dashboard/${ticketNumberId}/notes`, JSON.stringify(notes), { "Content-Type": "application/json" }),
    defaultValue: [],
    onError: error => {
        dispatch(errorMessage(`Failed to save note: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
    },
    onSuccess: () => {
        closeModal()
        dispatch(message("Saved Note Successfully"))
    }
  });

  return (
    <div style={{padding: "1em"}}>
      <h4 style={{color: "#87d59e"}}>{isWorked ? "Note" : "Edit Note"}</h4>
      <div>
          <hr/>
          {!isWorked && <p>Max length of note: 150 characters</p>}
          <textarea 
            disabled={isWorked} 
            onChange={(e) => setNote(e.target.value)} 
            value={notes} 
            style={{resize: "none"}} 
            maxLength="150"
          >
          </textarea>
      </div>
      <div>
          {
                savingNote ?
                <Spinner/>
                :
                <button 
                    onClick={() => {isWorked ? closeModal() : saveNote(notes)}}
                    className='select-coach-for-appointment-button'
                >
                    {isWorked ? "Close" : "Close and save"}
                </button>
          }
        </div>
    </div>
  )
}

NotesShowEditModal.propTypes = {
    notes: PropTypes.string.isRequired,
    setNote: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    isWorked: PropTypes.bool.isRequired,
    ticketNumberId: PropTypes.string.isRequired
};

export default NotesShowEditModal;