import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useOutsideClick } from "hooks";

const SearchMatches = ({ items, isOpen, onItemSelect, handleClose }) => {
  const ref = useRef();
  useOutsideClick(ref, handleClose);
  if (!isOpen) return null;
  return (
    <div className="transfer-search-matches" ref={ref}>
      <div className="container">
        <div className="content">
          {items.map(user => (
            <div
              className="search-item"
              key={user.id}
              onClick={() => {
                onItemSelect(user);
              }}
            >
              {user.first_name} {user.last_name} - {user.extension}
            </div>
          ))}
        </div>
        {/* <div className="search-result-footer">Transfer to extension# or phone#</div> */}
      </div>
    </div>
  );
};
export default SearchMatches;

SearchMatches.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onItemSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func
};
