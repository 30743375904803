import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getTimeEllapsedInSeconds, formatSeconds } from "utils/dates";

const Timer = React.memo(({ startedAt, formatter, tick }) => {
  const [secondsEllapsed, setSecondsEllapsed] = useState(
    getTimeEllapsedInSeconds(startedAt)
  );

  useEffect(() => {
    setSecondsEllapsed(getTimeEllapsedInSeconds(startedAt));
  }, [startedAt]);

  useEffect(() => {
    var timerID = setInterval(() => update(), tick);
    return function cleanup() {
      clearInterval(timerID);
    };
  });
  function update() {
    setSecondsEllapsed(secondsEllapsed + tick / 1000);
  }
  return <span>{formatter(secondsEllapsed)}</span>;
});

Timer.propTypes = {
  startedAt: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  tick: PropTypes.number
};

Timer.defaultProps = {
  formatter: formatSeconds,
  tick: 1000
};

export default Timer;
