export const Types = {
  PHYSICIAN_API_REQUEST: "PHYSICIAN_API_REQUEST",
  PHYSICIAN_API_RESPONSE: "PHYSICIAN_API_RESPONSE",
  PHYSICIAN_SEARCH_INVALIDATE: "PHYSICIAN_SEARCH_INVALIDATE",
  PHYSICIAN_API_ERROR: "PHYSICIAN_API_ERROR"
};

export const searchPhysicians = formData => ({
  type: Types.PHYSICIAN_API_REQUEST,
  payload: formData
});

export const invalidatePhysicianSearch = () => ({
  type: Types.PHYSICIAN_SEARCH_INVALIDATE
});
