import React, { useState } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "components/ui/toggle-switch";

const UserInboundCallSwitch = ({
  block_autodialer_incoming_calls,
  id,
  updateCoachSettings
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  return (
    <ToggleSwitch
      value={block_autodialer_incoming_calls}
      isUpdating={isUpdating}
      onChange={async () => {
        try {
          setIsUpdating(true);
          await updateCoachSettings(id, {
            block_autodialer_incoming_calls: !block_autodialer_incoming_calls
          });
        } finally {
          setIsUpdating(false);
        }
      }}
    />
  );
};

UserInboundCallSwitch.propTypes = {
  block_autodialer_incoming_calls: PropTypes.bool,
  id: PropTypes.string.isRequired,
  updateCoachSettings: PropTypes.func.isRequired
};

export default UserInboundCallSwitch;
