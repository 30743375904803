import React, { useEffect, useState } from 'react';
import { BarChart, XAxis, YAxis, Tooltip, Bar, LabelList } from "recharts";
import { PropTypes } from 'prop-types';
import HeaderChart from './header-chart';
import moment from 'moment';

export default function ComplianceDashboardBarChart({ data, onItemSelected, startDate, endDate, companyId, isFetching }) {

  const [dataForCheckboxList, setDataForCheckboxList] = useState([])
  const [usingIndividualInfo, setUsingIndividualInfo] = useState(false)
  const [chartData, setChartData] = useState([])
  
  useEffect(() => {
    if (data) {
      const checkItems = data.GrouperOptions.map(({ Text, Value }) => {
        return { value: Value, text: Text, elementId: "item" + Value }
      });

      setChartData(data.Results);
      setDataForCheckboxList(checkItems);
    }
  }, [data])

  const updateChartData = (individualData) => {
    setUsingIndividualInfo(true);
    setChartData(individualData);
  };

  const splitString = (data) => {
    let searchedLength = 15;
    if (data?.Grouper?.Text?.length > searchedLength) {
      let aux = data?.Grouper?.Text?.slice(0, searchedLength);
      aux += "...";
      return aux;
    } else {
      return data?.Grouper?.Text;
    }
  };

  const CustomTooltip = (data) => {
    if (data.active && data.payload && data.payload.length) {
      return (
        <div className="bar-charts-custom-tooltip">
          <p className="custom-tooltip-label">{data.payload[0].payload.Grouper.Text}</p>
        </div>
      );
    }
    return null;
  };

  const renderLabel = (prop) => {
    const { x, y, width, height, index } = prop;

    const target = chartData[index];
    const offset = -50;
    const xAxis = (x + width - offset) >= 400 ? x + width : x + width - offset;
    return (
      <text x={xAxis} y={y + height - 10} fill={"#000"} textAnchor="end">
        {`${target?.Count} - ${target?.Percentage}%`}
      </text>
    );
  };

  return (
    <div>
      <HeaderChart
        categoryName={data.CategoryName}
        category={data.Category}
        startDate={startDate}
        endDate={endDate}
        companyId={companyId}
        updateData={updateChartData}
        dataForCheckboxList={dataForCheckboxList}
        isFetching={isFetching}
      />

      {
        !isFetching && chartData?.length === 0 ?
          <div>
            <h5 style={{ textAlign: "center", color: "red" }}>
              {usingIndividualInfo ?
                "No records found for the selected filters"
                : "No records found for this chart"
              }
            </h5>
          </div>
          :

          <BarChart width={400} height={300} data={chartData} layout="vertical">
            <XAxis type="number" dataKey="Percentage" domain={[0, 100]} ticks={[0, 25, 50, 75, 100]} />
            <Tooltip content={<CustomTooltip />} />
            <YAxis dataKey={splitString} type="category" name="Grouper.Text" width={115} interval={0} />
            <Bar fill="rgb(49, 130, 189)" dataKey="Percentage" barSize={30} onClick={(values) => {
              onItemSelected({ value: values.Grouper.Value, category: data.Category, filterName: values.Grouper.Text, categoryName: data.CategoryName })
            }}>
              <LabelList
                content={props => renderLabel(props)}
                position='right'
                style={{ pointerEvents: 'none' }}
                isAnimationActive={false}
              />
            </Bar>
          </BarChart>
      }
    </div>
  );
}

ComplianceDashboardBarChart.propTypes = {
  data: PropTypes.object,
  onItemSelected: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.date, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.date, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  companyId: PropTypes.string,
  isFetching: PropTypes.bool
}