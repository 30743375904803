import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ExportFulfillmentInventory from "./export-fulfillment-inventory";
import { PageHeader } from "components/ui/index";
import { Waiting } from "components/ui/waiting";
import HasRolePermission from "components/shared/has-role-permission";
import { selectors } from "reducers";
import InventoryTable from "./table";
import InventorySearch from "./fulfillment-inventory-search";
import { useDebounce } from "hooks";
import SearchableDropdown from "components/searchable-dropdown";
import { FulfillmentInventoryContext } from "../fulfillment-inventory-provider";

const FulfillmentInventoryTablePage = ({ fulfillmentCompanies }) => {
  const [searchBy, setSearchBy] = useState("product_name");
  const [searchValue, setSearchValue] = useState("");
  const [formValuesFetch, setFormValuesFetch] = useState(true);
  const debouncedSearchTerm = useDebounce(searchValue, 700);
  const {
    fulfillmentInventoryList,
    isFetchingInventoryList,
    isExportingInventory,
    getFulfillmentInventoryList,
    selectedFulfillmentCompany,
    fulfillmentInventoryExport,
    setSelectedFulfillmentCompany
  } = useContext(FulfillmentInventoryContext);

  useEffect(() => {
    getFulfillmentInventoryList()
  }, [selectedFulfillmentCompany]);

  useEffect(() => {
    if (fulfillmentCompanies.length > 0) {
      setFormValuesFetch(false);
    }
  }, [fulfillmentCompanies]);

  return (
    <div className="inventory-table-container">
      <PageHeader title="Fulfillment Inventory Items">
      {!formValuesFetch ? (
        <HasRolePermission allowedRoles={["Administrator"]}>
          <ExportFulfillmentInventory
            fulfillmentCompanies={fulfillmentCompanies}
            isFetchingInventory={isFetchingInventoryList}
            isExportingInventory={isExportingInventory}
            fulfillmentInventoryExport={fulfillmentInventoryExport}
            selectedFulfillmentCompany={selectedFulfillmentCompany}
          />
        </HasRolePermission>
        ) : (
        ""
      )}
      </PageHeader>
      <div>
      {!formValuesFetch ? (
          <SearchableDropdown
              name="selectedFulfillmentCompany"
              label="Fulfillment Company"
              requireSearch={false}
              selected={selectedFulfillmentCompany}
              options={fulfillmentCompanies}
              onSelect={(value) => setSelectedFulfillmentCompany(value)}
          />
      ) : (
        ""
      )}
        {!formValuesFetch && !isFetchingInventoryList && !isExportingInventory ? (
            <div>
                 <InventorySearch
                    searchBy={searchBy}
                    setSearchValue={setSearchValue}
                    setSearchBy={setSearchBy}
                    searchValue={searchValue}
                />
                <InventoryTable
                    searchBy={searchBy}
                    searchValue={debouncedSearchTerm}
                    inventoryItems={fulfillmentInventoryList}
                />
            </div>
        ) : (
          <Waiting reason="Fetching inventory" />
        )}
      </div>
    </div>
  );
};

export default connect(state => ({
  fulfillmentCompanies: selectors.getFormOptionsFulfillmentCompany(state)
}))(FulfillmentInventoryTablePage);

FulfillmentInventoryTablePage.propTypes = {
  fulfillmentCompanies: PropTypes.arrayOf(PropTypes.object),
};
