import React from "react";
import PropTypes from "prop-types";
import { useFormikContext, Field } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";
import RadioGroupField from "components/forms/radio-group-field";
import PhilipsRegistration from "./philips-registration";

const ConfirmationForm = ({ setCurrentStep }) => {
  const { isSubmitting, values, errors, setFieldValue } = useFormikContext();
  return (
    <div style={{ padding: "1em" }}>
      <div className="philips-recall-prompt">
        <p style={{ fontSize: "1.15rem" }}>
          Philips Healthcare is asking you to contact your physician regarding
          your therapy, as well as, talk with Philips directly about any
          questions regarding the recall you may have. This recall process is
          managed by and replacement products are provisioned from Philips, the
          manufacturer. Philips can be reached at 877-907-7508. I will be
          sending you this number after our discussion via email so you can have
          it, as well as, Philips website that gives more information about the
          recall and a place to enter your serial number to check if your
          machine is affected and if so, you will be able to register your
          machine for recall directly with them. They will be able to provide
          you ongoing updates.
        </p>
        <Field
          component={RadioGroupField}
          name="recallStatus"
          label="On today's order, would you like to:"
          className="form-field philips-radio-group"
          value={values.recallStatus}
          options={[
            {
              value: "Completed",
              text: "Move forward with placing the order for supplies"
            },
            {
              value: "Pending",
              text: "Wait on ordering supplies until you speak with your physician and philips"
            }
          ]}
        />
        <div style={{ paddingTop: 20 }}>
          <p style={{ margin: 0 }}>
            In order to receive this information I will need to get an email on
            you to be able to send you information stated above and you can
            provide to Philips as well for ongoing communication.
          </p>
          <FormikField
            type="text"
            name="philips_confirmation_email"
            label="Can I verify your email address"
            onChange={({ target }) => {
              setFieldValue("philips_confirmation_email", target.value);
              // setFieldValue("questionnaireResponses.email", target.value);
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          padding: "5px 20px",
          flex: 1,
          flexDirection: "column"
        }}
      >
        <p>
          If you have your machines serial number available, I can help you
          register your device for replacement with Philips. Or if you would
          prefer to handle the recall yourself or don&apos;t have your serial
          number available, we will send you a follow up email that includes the
          information to register your device with Philips.
        </p>
        <Field
          component={RadioGroupField}
          name="philipsDataApproval"
          label="Would you like us to submit this information?"
          className="form-field philips-checkbox"
          value={values.philipsDataApproval}
          options={[
            {
              value: true,
              text: "Yes"
            },
            {
              value: false,
              text: "No"
            }
          ]}
        />
      </div>
      {values.philipsDataApproval === true && <PhilipsRegistration />}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 10
        }}
      >
        <button
          onClick={() => {
            setCurrentStep(1);
          }}
          className="button secondary"
          type="button"
        >
          Back
        </button>
        <div>
          <span style={{ color: "red", paddingRight: 30, fontSize: 18 }}>
            {errors.serialNumber}
          </span>
          {isSubmitting ? (
            <InlineWaiting />
          ) : (
            <button
              type="submit"
              disabled={!values.accepted && values.philipsDataApproval}
            >
              {values.acceptance === "Accept" || values.acceptance === "Decline"
                ? "Submit"
                : "Save"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

ConfirmationForm.propTypes = { setCurrentStep: PropTypes.func.isRequired };

export default ConfirmationForm;
