import React from "react";
import AutoDialerStatus from "./autodialer-status";
import PatientVerificationButton from "./patient-verification-button";

const AutoDialerCustomRenderer = () => {
  return (
    <>
      <AutoDialerStatus />
      <PatientVerificationButton />
    </>
  );
};

export default AutoDialerCustomRenderer;
