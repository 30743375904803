import React, { Component } from "react";
import PropTypes from "prop-types";
import SectionHeader from "components/profile/section_header";
import { Field, reduxForm } from "redux-form";
import FormField from "components/forms/form-field";
import { STATES } from "utils/enum";
import { isValidZip } from "utils/redux-form";
import SaveIcon from "react-icons/lib/md/save";
import CancelIcon from "react-icons/lib/md/cancel";
import IconButton from "components/ui/icon-button";

const inputStyle = {
  display: "inline-block",
  width: "auto",
  height: "auto",
  padding: "0 5px",
  lineHeight: "1.6",
  margin: 0
};

class EditAddress extends Component {

  render() {
    const { handleSubmit, handleCancel, valid, pristine } = this.props;
    return (
      <div style={{ marginBottom: "25px" }}>
        <form onSubmit={handleSubmit}>
          <SectionHeader>
            Address
            <span style={{ float: "right" }}>
              <IconButton
                onClick={handleCancel}
                text="Cancel"
                style={{ color: "red" }}
                icon={<CancelIcon />}
                type="button"
              />
              <IconButton
                disabled={!valid || pristine}
                text="Save"
                style={{ color: "green" }}
                icon={<SaveIcon />}
                type="submit"
              />
            </span>
          </SectionHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Field
              label="Street Address Line 1"
              name="street_address"
              component={FormField}
              style={{ ...inputStyle, marginBottom: "5px" }}
            />
            <Field
              label="Street Address Line 2"
              name="street_address2"
              component={FormField}
              style={{ ...inputStyle, marginBottom: "5px" }}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: "5px"
              }}
            >
              <Field 
                label="City"
                name="city"
                component={FormField}
                style={inputStyle}
              />
              <Field
                label="State"
                name="state"
                as="select"
                options={STATES}
                component={FormField}
                style={inputStyle}
                nullOption
              />
              <Field
                label="Zip"
                name="zip"
                component={FormField}
                validate={[isValidZip]}
                style={inputStyle}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

EditAddress.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  initialize: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  pristine: PropTypes.bool
};

export default reduxForm({
  form: "edit-patient-address",
  getFormState: state => state.get("form")
})(EditAddress);
