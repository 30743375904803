import { Types } from "actions/ivr-jobs";
import { createReducer } from "utils/reducer";
import { propOr } from "ramda";
// import { createSelector } from "reselect";

export default createReducer([], {
  [Types.GET_IVR_JOBS_RESPONSE]: (state, action) =>
    propOr([], "response", action)
});

export const selectors = {
  getIvrJobs: state => state.get("ivr-jobs")
};
