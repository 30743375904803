import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import CoachEnteredOrdersTable from "./entered-table";
import CoachBaseOrdersTable from "./pt-base-table";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const CoachOrders = ({ data, minimizeAll }) => {
  const [mode, setMode] = useState("createdOrders");
  const [isMinimized, setMinimized] = useState(false);
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  return (
    <div className="block coach-orders">
      <div className="block-header">
        <span onClick={() => setMinimized(!isMinimized)}>
          Coach Orders
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
        <div>
          <span className="coach-orders-mode-select">
            <i
              className={mode === "createdOrders" ? "active" : "ripple"}
              onClick={() => setMode(() => "createdOrders")}
            >
              Orders Entered
            </i>
            <i
              className={mode === "patientBase" ? "active" : "ripple"}
              onClick={() => setMode(() => "patientBase")}
            >
              Patient Base
            </i>
          </span>
        </div>
      </div>
      {!isMinimized && (
        <div className="block-content">
          {mode === "createdOrders" ? (
            <CoachEnteredOrdersTable data={data} />
          ) : (
            <CoachBaseOrdersTable data={data} />
          )}
        </div>
      )}
    </div>
  );
};

CoachOrders.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool
};

export default connect(state => ({
  data: selectors.getTeamCoachInsights(state)
}))(CoachOrders);
