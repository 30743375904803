import React from "react";
import PropTypes from "prop-types";
import { MdFilterList } from "react-icons/lib/md";
import { pathOr } from "ramda";
import Dropdown from "components/ui/dropdown";
import { IconButton } from "components/ui";

const PatientTaskFilters = ({ query, queryDispatcher }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr auto auto"
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          <label
            style={{
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "center",
              fontSize: "1.15em",
              display: "inline-flex",
              paddingRight: 20
            }}
          >
            Patient Tasks
            <input
              type="radio"
              style={{ margin: "0px 10px" }}
              checked={query.filters?.type == "Patient"}
              onClick={() => {
                queryDispatcher({
                  type: "setFilter",
                  by: "type",
                  value: "Patient"
                });
              }}
            />
          </label>
        </span>
        <span>
          <label
            style={{
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "center",
              fontSize: "1.15em",
              display: "inline-flex",
              paddingRight: 20
            }}
          >
            Order Tasks
            <input
              type="radio"
              style={{ margin: "0px 10px" }}
              checked={query.filters?.type == "Order"}
              onClick={() => {
                queryDispatcher({
                  type: "setFilter",
                  by: "type",
                  value: "Order"
                });
              }}
            />
          </label>
        </span>
        <span>
          <label
            style={{
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "center",
              fontSize: "1.15em",
              display: "inline-flex",
              paddingRight: 20
            }}
          >
            All Tasks
            <input
              type="radio"
              style={{ margin: "0px 10px" }}
              checked={!query.filters?.type}
              onClick={() => {
                queryDispatcher({
                  type: "clearFilter",
                  by: "type"
                });
              }}
            />
          </label>
        </span>
      </div>
      <span style={{ display: "flex", alignItems: "center" }}>
        <TaskFilter
          header="Status"
          filterKey="status"
          filters={[
            { value: "Created", text: "Created" },
            { value: "Pending", text: "Pending" },
            { value: "Completed", text: "Completed" }
          ]}
          query={query}
          queryDispatcher={queryDispatcher}
        />
      </span>
      <span style={{ display: "flex", alignItems: "center" }}>
        <TaskFilter
          filterKey="owner"
          header="Owner"
          filters={[
            { value: "S3", text: "S3" },
            { value: "DME", text: "DME" }
          ]}
          query={query}
          queryDispatcher={queryDispatcher}
        />
      </span>
    </div>
  );
};

PatientTaskFilters.propTypes = {
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired
};

export default PatientTaskFilters;

const TaskFilter = ({ header, filterKey, filters, query, queryDispatcher }) => {
  const selected = pathOr([], ["filters", filterKey], query);
  return (
    <>
      <Dropdown
        trigger={
          <IconButton
            icon={<MdFilterList />}
            className="button secondary icon-button column-filter-trigger"
          />
        }
        position="right"
        style={{ margin: 0 }}
      >
        <ul
          className="dropdown-list"
          onClick={e => e.stopPropagation()}
          style={{ margin: 0 }}
        >
          {filters.map(({ value, text }) => (
            <li
              key={value}
              className="dropdown-item"
              onClick={() => {
                queryDispatcher({
                  type: "toggleFilter",
                  by: filterKey,
                  value
                });
              }}
            >
              <input
                type="checkbox"
                checked={selected.includes(value)}
                readOnly
              />
              <label>{text}</label>
            </li>
          ))}
        </ul>
      </Dropdown>
      <span style={{ display: "flex", cursor: "pointer", padding: 5 }}>
        <label style={{ paddingRight: 5 }}>{header}:</label>
        <span style={{ fontWeight: "bold", minWidth: 200 }}>
          {selected.join(",")}
        </span>
      </span>
    </>
  );
};
TaskFilter.propTypes = {
  header: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired
};
