import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { selectors } from "reducers";
import * as R from "ramda";
import { QueryTableContext } from "hooks/useQuery/api-query";
import { setFilter } from "hooks/useQuery/actions";
import { FaGlobe } from "react-icons/lib/fa";

export const AssignmentFilterContainer = () => {
  const { query, queryDispatcher } = React.useContext(QueryTableContext);
  const setFilters = (key, value) => {
    queryDispatcher(setFilter(key, value));
  };

  const sleepCoachOptions = useSelector(
    selectors.getFormOptionsSleepCoachWithUserFirst
  );
  const teamNameOptions = useSelector(selectors.getTeamNameOptions);
  return (
    <AssignmentFilter
      filters={query.filters}
      setFilters={setFilters}
      sleepCoachOptions={sleepCoachOptions}
      teamNameOptions={teamNameOptions}
    />
  );
};

const AssignmentFilter = ({
  sleepCoachOptions = [],
  teamNameOptions,
  filters,
  setFilters
}) => {
  const selectedTeams = R.propOr([], "team_name", filters);
  const selectedTeamsAllCoaches = R.propOr(
    [],
    "team_name_all_coaches",
    filters
  );
  const selectedSleepCoaches = R.propOr([], "sleep_coach_assignment", filters);
  const [searchResultsTeamByCoach, setSearchResultsTeamByCoach] = useState(
    sleepCoachOptions.map(sco => sco.team_name)
  );
  const [searchResultsCoach, setSearchResultsCoach] = useState(
    sleepCoachOptions.map(sco => sco.team_name)
  );
  const [searchResultsTeam, setSearchResultsTeam] = useState(
    teamNameOptions.map(tno => tno.text)
  );
  const [searchTerms, setSearchTerms] = useState("");
  useEffect(() => {
    if (searchTerms !== "") {
      setSearchResultsTeamByCoach(
        sleepCoachOptions
          .filter(sco =>
            sco.name.toLowerCase().includes(searchTerms.toLowerCase())
          )
          .map(c => c.team_name)
      );
      setSearchResultsTeam(
        teamNameOptions
          .filter(tno =>
            tno.text.toLowerCase().includes(searchTerms.toLowerCase())
          )
          .map(t => t.text)
      );
      setSearchResultsCoach(
        sleepCoachOptions.filter(sco =>
          sco.name.toLowerCase().includes(searchTerms.toLowerCase())
        )
      );
    } else {
      setSearchResultsTeamByCoach(sleepCoachOptions.map(sco => sco.team_name));
      setSearchResultsTeam(teamNameOptions.map(tno => tno.text));
      setSearchResultsCoach(sleepCoachOptions);
    }
  }, [searchTerms]);
  console.log(filters);

  return (
    <ul className="dropdown-list">
      <li
        onClick={() => {
          setFilters("team_name");
          setFilters("sleep_coach_assignment");
        }}
        className="dropdown-command"
      >
        Clear Selections
      </li>
      <li>
        <input
          type="text"
          className="filter-downselect-input"
          onChange={e => {
            setSearchTerms(e.target.value);
          }}
          placeholder="Search By Team or Coach"
        ></input>
      </li>
      {teamNameOptions.map(tno => {
        if (
          searchResultsTeam.includes(tno.text) ||
          searchResultsTeamByCoach.includes(tno.text)
        ) {
          return (
            <>
              <li
                className="dropwdown-item"
                key={tno.key}
                style={{
                  marginLeft: "2%",
                  backgroundColor: "#249c9138",
                  paddingLeft: "5px",
                  display: "grid",
                  gridTemplateColumns: ".5fr 5fr",
                  alignItems: "center"
                }}
              >
                <input
                  style={{ margin: 0 }}
                  type="checkbox"
                  checked={selectedTeams.includes(tno.key)}
                  onChange={({ target }) => {
                    if (target.checked) {
                      setFilters(
                        "team_name",
                        R.uniq(R.append(tno.key, selectedTeams))
                      );
                    } else {
                      setFilters(
                        "team_name",
                        R.without(tno.key, selectedTeams)
                      );
                      setFilters(
                        "sleep_coach_assignment",
                        R.without(
                          sleepCoachOptions
                            .filter(sco => tno.text == sco.team_name)
                            .map(sc => sc.GUID),
                          selectedSleepCoaches
                        )
                      );
                    }
                  }}
                />
                <label style={{ fontWeight: "bold" }}>{tno.text}</label>
              </li>
              <li
                className="dropwdown-item"
                key={tno.key}
                style={{
                  marginLeft: "10%",
                  paddingLeft: "5px",
                  display: "grid",
                  gridTemplateColumns: ".5fr 5fr",
                  alignItems: "center"
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedTeamsAllCoaches.includes(tno.key)}
                  onChange={({ target }) => {
                    if (target.checked) {
                      setFilters(
                        "team_name_all_coaches",
                        R.uniq(R.append(tno.key, selectedTeams))
                      );
                      const coachesToAdd = R.pipe(
                        R.filter(sco => tno.text == sco.team_name),
                        R.map(sc => sc.GUID)
                      )(sleepCoachOptions);
                      setFilters(
                        "sleep_coach_assignment",
                        R.uniq([...coachesToAdd, ...selectedSleepCoaches])
                      );
                    } else {
                      setFilters(
                        "team_name_all_coaches",
                        R.without(tno.key, selectedTeams)
                      );
                      setFilters(
                        "sleep_coach_assignment",
                        R.without(
                          sleepCoachOptions
                            .filter(sco => tno.text == sco.team_name)
                            .map(sc => sc.GUID),
                          selectedSleepCoaches
                        )
                      );
                    }
                  }}
                />
                <label>{tno.text}-All Coaches</label>
              </li>
              <>
                {searchResultsCoach
                  .filter(sco => sco.team_name == tno.text)
                  .map(sco => (
                    <li
                      key={sco.value}
                      className="dropdown-item"
                      style={{
                        marginLeft: "18%",
                        paddingLeft: "5px",
                        display: "grid",
                        gridTemplateColumns: ".5fr 5fr",
                        alignItems: "center"
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedSleepCoaches.includes(sco.GUID)}
                        onChange={({ target }) => {
                          if (target.checked) {
                            setFilters(
                              "team_name",
                              R.uniq(R.append(tno.key, selectedTeams))
                            );
                            setFilters(
                              "sleep_coach_assignment",
                              R.uniq(R.append(sco.GUID, selectedSleepCoaches))
                            );
                          } else {
                            setFilters(
                              "sleep_coach_assignment",
                              R.without([sco.GUID], selectedSleepCoaches)
                            );
                            const coaches = sleepCoachOptions
                              .filter(
                                sc =>
                                  selectedSleepCoaches.includes(sc.GUID) &&
                                  sc.GUID != sco.GUID
                              )
                              .filter(sc => sc.team_name === tno.text);
                            if (!coaches.length) {
                              setFilters(
                                "team_name",
                                R.without(tno.key, selectedTeams)
                              );
                            }
                          }
                        }}
                      />
                      <label>{sco.name}</label>{" "}
                    </li>
                  ))}
              </>
            </>
          );
        }
      })}
    </ul>
  );
};

export default connect((state, props) => ({
  sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state),
  filters: selectors.getQueryFilters(state, props.queryId),
  teamNameOptions: selectors.getTeamNameOptions(state)
}))(AssignmentFilter);

AssignmentFilter.propTypes = {
  queryId: PropTypes.string.isRequired,
  teamNameOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  sleepCoachOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};
