import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InlineWaiting } from "components/ui";
import { automatePatientOutreach } from "actions/outreach";
import { message, errorMessage } from "actions/message";
import IoIosPaperPlane from "react-icons/lib/io/paper-airplane";
import PureModal from "components/ui/modal/pure-modal";
import  BulkOutreachConfirmationModal  from "./modal";

const SendAutomation = ({
  batchClear,
  requestData,
  markedForBatch
}) => {
  const [isFetching, setFetchStatus] = useState(false);

  return isFetching ? (
    <InlineWaiting />
  ) : (
    <>
      <PureModal
        renderTrigger={({ openModal }) => (
          <button
            title="Send Automation"
            className="contact-actions"
            disabled={!markedForBatch.length > 0}
            onClick={openModal}
            
          >
            <IoIosPaperPlane size="1.2em" />
          </button>
        )}
      >
        <BulkOutreachConfirmationModal patients={markedForBatch} requestData={requestData} setFetchStatus={setFetchStatus} batchClear={batchClear}/>
      </PureModal>
    </>
  );
};

export default connect(null, {
  automatePatientOutreach,
  message,
  errorMessage
})(SendAutomation);

SendAutomation.propTypes = {
  automatePatientOutreach: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  markedForBatch: PropTypes.arrayOf(PropTypes.string).isRequired,
  batchClear: PropTypes.func.isRequired,
  requestData: PropTypes.func.isRequired,
  toggleEmail: PropTypes.func.isRequired,
  toggleText: PropTypes.func.isRequired,
  isEmailSelected: PropTypes.bool.isRequired,
  isTextSelected: PropTypes.bool.isRequired
};
