import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { equals } from "ramda";

const RadioFieldGroup = ({
  label,
  field,
  form,
  options,
  className,
  ...rest
}) => (
  <div className={className}>
    <span className="form-field-header">
      {label && <label className="form-field-label">{label}</label>}
    </span>
    <span className="form-field-error">
      <ErrorMessage name={field.name} />
    </span>
    <div style={{ display: "flex" }} className="radio-group-container">
      {options.map(opt => (
        <label
          style={{ display: "flex", alignItems: "center", padding: 5 }}
          key={opt.value}
        >
          <span style={{ paddingRight: 5 }} className="radio-group-option">
            {opt.text}
          </span>
          <input
            name={field.name}
            key={opt.value}
            value={opt.value}
            type="radio"
            className="input-field"
            checked={equals(field.value, opt.value)}
            onChange={() => {
              form.setFieldValue(field.name, opt.value);
            }}
            onBlur={field.onBlur}
            style={{ margin: 0 }}
            {...rest}
          />
        </label>
      ))}
    </div>
  </div>
);

RadioFieldGroup.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  ).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  }).isRequired,
  className: PropTypes.string
};

RadioFieldGroup.defaultProps = {
  className: "form-field"
};

export default RadioFieldGroup;
