import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { selectors } from "reducers";
import PureWindow from "components/ui/modal/pure-window";
import { EnvironmentBanner } from "./environment-banner";

const AppUpdates = ({ newVersion, currentVersion, phoneIsConnected }) => {
  const windowHost = window.location.host;
  const user = useSelector(selectors.getUser);
  const environment = user?.environment ?? "Environment unkown";
  const displayEnvironmentBanner = (windowHost.includes("sleepsolutionsandservices.com") && !environment.includes("Production Environment")) 
  || (!windowHost.includes("sleepsolutionsandservices.com"));
  
  const [modalOpen, setModalOpen] = React.useState(false);
  React.useEffect(() => {
    if (newVersion?.status == "minor" || newVersion?.status == "major")
      setModalOpen(true);
  }, [newVersion?.status]);
  return (
    <div
      style={{
        display: "contents",
        flexWrap: "wrap",
        paddingLeft: 10,
        paddingRight: 5,
        alignItems: "center",
        color: "#666"
      }}
    >
      <span style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
        Version: {currentVersion}
      </span>
      {displayEnvironmentBanner && <EnvironmentBanner />}
      {newVersion && (
        <span
          className="update-btn"
          onClick={() => {
            if (phoneIsConnected) alert("please wait for phone call to end");
            else window.location.reload(true);
          }}
        >
          Update
        </span>
      )}
      {modalOpen && !phoneIsConnected && (
        <PureWindow
          closeable={newVersion?.status !== "major"}
          onXClick={() => {
            setModalOpen(false);
          }}
        >
          <div className="app-update-prompt">
            <h3>There is a new update</h3>
            <div style={{ padding: 10, fontSize: 16, marginBottom: 20 }}>
              We aplogize for any inconvenience, but failure to update now could
              result in issues.
            </div>
            <button
              onClick={() => window.location.reload(true)}
              disabled={phoneIsConnected}
            >
              Click here to update or refresh page
            </button>
          </div>
        </PureWindow>
      )}
    </div>
  );
};

AppUpdates.propTypes = {
  newVersion: PropTypes.object,
  currentVersion: PropTypes.string,
  phoneIsConnected: PropTypes.bool
};

export default connect(state => ({
  currentVersion: selectors.getCurrentAppVersion(state),
  newVersion: selectors.getNewAppVersion(state),
  phoneIsConnected: selectors.getIsPhoneConnected(state)
}))(AppUpdates);
