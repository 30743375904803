import { take, call, put } from "redux-saga/effects";
import { browserHistory } from "browser-history";
import { Types } from "actions/companiesTable";
import { errorMessage } from "actions/message";
import * as api from "utils/api";

export function* exportCompanyTable({
  payload: { filteredRows, startDate, endDate, visibleColumns }
}) {
  try {
    const url = `companies/exports`;
    const companies = filteredRows.map(row => row.id);
    yield call(api.post, url, {
      companies,
      startDate,
      endDate,
      visibleColumns
    });
    yield put({ type: Types.RESPONSE_EXPORT_COMPANY_TABLE });
    yield call(api.get, "exports");
    browserHistory.push("/exports");
  } catch (error) {
    yield put(errorMessage("An error occurred while exporting companies."));
  }
}

export default function* watchExportCompanyTable() {
  while (true) {
    const action = yield take(Types.REQUEST_EXPORT_COMPANY_TABLE);
    yield call(exportCompanyTable, action);
  }
}
