export const Types = {
  SIGNALR_CONNECT: "SIGNALR_CONNECT",
  SIGNALR_CONNECTING: "SIGNALR_CONNECTING",
  SIGNALR_CONNECTED: "SIGNALR_CONNECTED",
  SIGNALR_DISCONNECT: "SIGNALR_DISCONNECT",
  SIGNALR_DISCONNECTED: "SIGNALR_DISCONNECTED",
  SIGNALR_CONNECTION_ERROR: "SIGNALR_CONNECTION_ERROR",
  SIGNALR_INVOKE_METHOD: "SIGNALR_INVOKE_METHOD",
  SIGNALR_ERROR: "SIGNALR_ERROR",
  CLIENT_CONNECTED: "SIGNALR/CLIENT_CONNECTED",
  CLIENT_DISCONNECTED: "SIGNALR/CLIENT_DISCONNECTED",
  CLIENTS: "SIGNALR/CLIENTS",
  CLIENT_UPDATED: "SIGNALR/CLIENT_UPDATED"
};

export const signalrConnect = () => ({
  type: Types.SIGNALR_CONNECT
});

export const signalrDisconnect = () => ({
  type: Types.SIGNALR_DISCONNECT
});

export const invokeMethod = (method, args = []) => ({
  type: Types.SIGNALR_INVOKE_METHOD,
  method,
  args
});

export const joinGroup = group => ({
  type: Types.SIGNALR_INVOKE_METHOD,
  method: "JoinGroup",
  args: [group],
  failed: error => ({ type: Types.SIGNALR_ERROR, error })
});

export const leaveGroup = group => ({
  type: Types.SIGNALR_INVOKE_METHOD,
  method: "LeaveGroup",
  args: [group],
  failed: error => ({ type: Types.SIGNALR_ERROR, error })
});

export const broadcastGroup = (group, payload) => ({
  type: Types.SIGNALR_INVOKE_METHOD,
  method: "BroadcastGroup",
  args: [group, payload]
});

export const getOnlineUsers = () => ({
  type: Types.SIGNALR_INVOKE_METHOD,
  method: "GetOnlineClients",
  success: clients => ({ type: Types.CLIENTS, payload: clients })
});
