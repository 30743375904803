import { GET_QUESTIONNAIRE, DELETE_QUESTIONNAIRE, UPDATE_STATUS_QUESTIONNAIRE, GET_QUESTIONNAIRELIST, SET_CLEAR } from './../ActionTypes';

const initialState = {
    Questionnaire: [],
    QuestionnaireList: [],
    IsCleared : false
}

const reducerQuestionnaire = (state = initialState, action) => {
    switch (action.type) {
        case GET_QUESTIONNAIRE:
            return {
                ...state,
                Questionnaire: action.payload
            };
        // case UPDATE_QUESTIONNAIRE:
        //     return {
        //         ...state,
        //         Questionnaire: state.Questionnaire.map(
        //             (content) => content.questionnaire_id === action.payload.id ? { ...content, title: action.payload.title, description: action.payload.description }
        //                 : content)
        //     };
        case UPDATE_STATUS_QUESTIONNAIRE:
            return {
                ...state,
                Questionnaire: state.Questionnaire.map(
                    (content) => content.questionnaire_id === action.payload.questionnaire_id ? { ...content, active: action.payload.active }
                        : content)
            };
        case DELETE_QUESTIONNAIRE:
            return {
                ...state,
                Questionnaire: state.Questionnaire.map(
                    (content) => content.questionnaire_id === action.payload ? { ...content,  active: false }
                        : content)
            };
        case GET_QUESTIONNAIRELIST:
                return {
                    ...state,
                    QuestionnaireList: action.payload
                };
        case SET_CLEAR:
            return {
                ...state,
                IsCleared: action.payload
            };
        default:
            return state;
    }
}

export default reducerQuestionnaire;
