import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  PatientStatistics,
  OrderStatistics,
  ContactStatistics
} from "components/ui/statistics";
import { Map, fromJS } from "immutable";

const UserInsightsOverview = ({
  patientInsights,
  orderInsights,
  contactsInsights,
  onExport
}) => (
  <div className="user-insights-overview">
    {patientInsights && <PatientStatistics insights={Map(patientInsights)} />}
    {orderInsights && <OrderStatistics insights={Map(orderInsights)} onExport={() => onExport("orderStats")} />}
    {contactsInsights && (
      <ContactStatistics insights={fromJS(contactsInsights)} onExport={() => onExport("contactStats")} />
    )}
  </div>
);

UserInsightsOverview.propTypes = {
  patientInsights: PropTypes.object,
  orderInsights: PropTypes.object,
  contactsInsights: PropTypes.object,
  onExport: PropTypes.func.isRequired
};

export default connect(state => ({
  patientInsights: selectors.getUserPatientInsights(state),
  orderInsights: selectors.getUserOrderInsights(state),
  contactsInsights: selectors.getUserContactInsights(state)
}))(UserInsightsOverview);
