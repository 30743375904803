export function put(k, v) {
  localStorage.setItem(k, v);
}

export function get(k) {
  return localStorage.getItem(k);
}

export function remove(k) {
  return localStorage.removeItem(k);
}

export function clear() {
  localStorage.clear();
}
