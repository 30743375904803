import React from "react";
import PropTypes from "prop-types";
import MdSearch from "react-icons/lib/md/search";
import MdClose from "react-icons/lib/md/close";

const CompanySearchInput = ({ companySearchText, setCompanySearchText }) => (
  <div className="company-search-field">
    <span className="search-icon">
      <MdSearch />
    </span>
    <input
      type="search"
      value={companySearchText}
      onChange={({ target }) => setCompanySearchText(target.value)}
    />
    {companySearchText && (
      <span className="clear-icon" title="Clear">
        <MdClose
          onClick={() => {
            setCompanySearchText("");
          }}
        />
      </span>
    )}
  </div>
);

CompanySearchInput.propTypes = {
  setCompanySearchText: PropTypes.func.isRequired,
  companySearchText: PropTypes.string.isRequired
};

export default CompanySearchInput;
