import React from "react";
import PropTypes from "prop-types";
import MdCheck from "react-icons/lib/md/check";
import MdClear from "react-icons/lib/md/clear";

const BoolCheckbox = ({ value }) => {
  return typeof value == "boolean" ? (
    value ? (
      <MdCheck size={18} color="green" />
    ) : (
      <MdClear color="red" size={18} />
    )
  ) : null;
};

BoolCheckbox.propTypes = { value: PropTypes.bool };

export default BoolCheckbox;
