import React from "react";
import PropTypes from "prop-types";

const DraggableTag = ({ id, text, disabled }) => {
  const onDragStart = event => {
    event.dataTransfer.setData("drag-item-id", id);
  };

  const containerClass = disabled ? "draggable-tag-disabled" : "draggable-tag";

  return (
    <div className={containerClass} draggable={true} onDragStart={onDragStart}>
      <div className="draggable-tag-handle">
        {"\u22EE"}
        {"\u22EE"}
      </div>
      <div className="draggable-tag-content">{text}</div>
    </div>
  );
};

DraggableTag.propTypes = {
  id: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default DraggableTag;
