import { fromJS } from 'immutable'
import { requestExp, receiveExp, failureExp, invalidateExp } from 'actions/fetch'

const fetch = (state, action) => {
  const { mapActionToKeyIn, type, receivedAt } = action

  if (!mapActionToKeyIn) {
    return state
  }

  const keyIn = mapActionToKeyIn(action)
  let match
  if ((match = type.match(requestExp))) {
    return state.mergeDeepIn([match[1], ...keyIn], fromJS({
      isFetching: true,
      didInvalidate: false
    }))
  }
  if ((match = type.match(receiveExp))) {
    return state.mergeDeepIn([match[1], ...keyIn], fromJS({
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt,
      didFail: false
    }))
  }
  if ((match = type.match(failureExp))) {
    return state.mergeDeepIn([match[1], ...keyIn], fromJS({
      isFetching: false,
      didInvalidate: false,
      didFail: true
    }))
  }
  if ((match = type.match(invalidateExp))) {
    return state.mergeDeepIn([match[1], ...keyIn], fromJS({
      didInvalidate: true
    }))
  }

  return state
}

export default fetch
