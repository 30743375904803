import { put } from 'utils/api'

export const Types = {
  REQUEST_LINE_ITEM_COUNT_HEAT_MAP: "REQUEST_LINE_ITEM_COUNT_HEAT_MAP",
  RESPONSE_LINE_ITEM_COUNT_HEAT_MAP: "RESPONSE_LINE_ITEM_COUNT_HEAT_MAP",
  LINE_ITEM_COUNT_HEAT_MAP_ERROR: "LINE_ITEM_COUNT_HEAT_MAP_ERROR",
  REQUEST_ORDER_STATUS_HEAT_MAP: "REQUEST_ORDER_STATUS_HEAT_MAP",
  RESPONSE_ORDER_STATUS_HEAT_MAP: "RESPONSE_ORDER_STATUS_HEAT_MAP",
  ORDER_STATUS_HEAT_MAP_ERROR: "ORDER_STATUS_HEAT_MAP_ERROR"
};

export const fetchLineItemCountHeatMap = ({ payload }) => async dispatch => {
  try {
    dispatch({ type: Types.REQUEST_LINE_ITEM_COUNT_HEAT_MAP });
    const response = await put("insights/heat_map", payload);
    dispatch({
      type: Types.RESPONSE_LINE_ITEM_COUNT_HEAT_MAP,
      payload: { ...response }
    });
  } catch (error) {
    dispatch({ type: Types.LINE_ITEM_COUNT_HEAT_MAP_ERROR, error });
  }
};

export const fetchOrderStatusChangeHeatMap = ({
  payload
}) => async dispatch => {
  try {
    dispatch({ type: Types.REQUEST_ORDER_STATUS_HEAT_MAP });
    const response = await put("insights/heat_map", payload);
    dispatch({
      type: Types.RESPONSE_ORDER_STATUS_HEAT_MAP,
      payload: { ...response }
    });
  } catch (error) {
    dispatch({ type: Types.ORDER_STATUS_HEAT_MAP_ERROR, error });
  }
};
