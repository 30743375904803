import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { browserHistory } from "browser-history";
import { reduxForm } from "redux-form";
import DayPicker from "react-day-picker/DayPicker";
import moment from "moment";
import * as R from "ramda";
import { VariableFieldSet, PageHeader, InlineWaiting } from "components/ui";
import { isValidPhone } from "utils/redux-form";
import TwilioLookupInput from "./twilio-lookup-input";
import { post } from "utils/api";

const DateInput = withHandlers({
  handleDayClick: ({ input: { onChange } }) => (day, { selected }) => {
    onChange(selected ? undefined : day);
  }
})(({ input: { value }, handleDayClick }) => (
  <div>
    <div>{value ? moment(value).format("LL") : "Please select a day."}</div>
    <DayPicker
      showOutsideDays
      selectedDays={value || null}
      onDayClick={handleDayClick}
    />
  </div>
));

const NumberInput = ({ input }) => <input type="number" {...input} />;
NumberInput.propTypes = {
  input: PropTypes.object.isRequired
};

const validateNoValue = errorMessage => val => (val ? undefined : errorMessage);

const validateTwilioLookup = (val = {}) => {
  if (typeof val == "object" && "phone" in val) {
    return !val.phone ? "Required." : isValidPhone(val.phone);
  } else if (typeof val == "object" && "companies" in val) {
    return val.companies.length > 0
      ? undefined
      : "Please select atleast 1 company.";
  } else if (typeof val == "object" && "client" in val) {
    return val.client.length > 0 ? undefined : "Please select a client.";
  } else if (typeof val == "object" && "team" in val) {
    return val.team.length > 0 ? undefined : "Please select a team.";
  }
  return undefined;
};

const FIELD_OPTIONS = [
  {
    field: "fetch_start",
    text: "Fetch Start",
    defaultValue: "",
    fieldProps: {
      component: DateInput,
      validate: validateNoValue("Please supply a fetch start.")
    }
  },
  {
    field: "fetch_end",
    text: "Fetch End",
    defaultValue: "",
    fieldProps: {
      component: DateInput,
      validate: validateNoValue("Please supply a fetch end.")
    }
  },
  {
    field: "to",
    text: "To",
    defaultValue: { clients: [] },
    filterCondition: (activeFields = []) =>
      !activeFields.some(({ field }) => field === "from"),
    fieldProps: {
      component: TwilioLookupInput,
      validate: validateTwilioLookup
    }
  },
  {
    field: "from",
    text: "From",
    defaultValue: { clients: [] },
    filterCondition: (activeFields = []) =>
      !activeFields.some(({ field }) => field === "to"),
    fieldProps: {
      component: TwilioLookupInput,
      validate: validateTwilioLookup
    }
  },
  {
    field: "limit",
    text: "Limit",
    defaultValue: "",
    fieldProps: {
      component: NumberInput,
      validate: validateNoValue("Please supply a limit.")
    }
 },
];

const ExportForm = compose(
  reduxForm({
    form: "twilioExportForm",
    getFormState: state => state.get("form")
  }),
  withStateHandlers(
    {
      exportType: "Phone",
      isSubmitting: false
    },
    {
      setExportType: () => ({ target: { value } }) => ({
        exportType: value
      }),
      setIsSubmitting: () => bool => ({ isSubmitting: bool })
    }
  ),
  withHandlers({
    handleExportSubmit: ({ handleSubmit, setIsSubmitting, exportType }) =>
      handleSubmit(async ({ exportFields }) => {
        const params =
          exportType === "Phone" && exportFields
            ? R.compose(
                R.mergeAll,
                R.map(({ field, value }) => ({ [field]: value }))
              )(exportFields)
            : undefined;
        setIsSubmitting(true);
        await post(`twilio/export/${exportType}`, params);
        setIsSubmitting(false);
        browserHistory.push("/exports");
      })
  }),
  setDisplayName("ExportForm")
)(
  ({
    exportType,
    setExportType,
    handleExportSubmit,
    invalid,
    isSubmitting
  }) => (
    <form onSubmit={handleExportSubmit} className="twilio-export-form">
      <h5>Select export type</h5>
      <div className="twilio-export-type">
        <label>
          <input
            type="radio"
            name="type"
            value="Phone"
            checked={"Phone" === exportType}
            onChange={setExportType}
          />
          Phone
        </label>
        <label>
          <input
            type="radio"
            name="type"
            value="Messages"
            checked={"Messages" === exportType}
            onChange={setExportType}
          />
          Messages
        </label>
      </div>
      {exportType === "Phone" && (
        <Fragment>
          <h5>Add additional parameters</h5>
          <VariableFieldSet
            name="exportFields"
            fieldOptions={FIELD_OPTIONS}
            validate={values => {
              if (values && values.length > 0) return undefined;
              return "Required.";
            }}
          />
        </Fragment>
      )}
      <button
        type="submit"
        disabled={invalid}
        className="twilio-export-submit-button"
      >
        {!isSubmitting ? "Submit" : <InlineWaiting />}
      </button>
    </form>
  )
);

const TwilioExport = () => (
  <div className="twilio-export">
    <PageHeader title="Twilio Export" />
    <div className="form-container">
      <ExportForm />
    </div>
  </div>
);

export default TwilioExport;
