import { combineReducers } from "redux";
import { createReducer } from "utils/reducer";
import { Types } from "actions/user-profile";
import * as R from "ramda";
import { createSelector } from "reselect";
import { deSnakeCase, capitalize } from "utils/misc";

const profile = createReducer(
  {},
  {
    [Types.REQUEST_USER_PROFILE]: () => ({}),
    [Types.USER_PROFILE_RESPONSE]: (_, { payload }) => payload,
    [Types.USER_PROFILE_ERROR]: () => ({})
  }
);

const userInsights = createReducer(
  {},
  {
    [Types.REQUEST_USER_INSIGHTS]: () => ({}),
    [Types.USER_INSIGHTS_RESPONSE]: (_, { payload }) => payload,
    [Types.USER_INSIGHTS_ERROR]: () => ({})
  }
);

const isFetching = createReducer(
  { profile: false, insights: false },
  {
    [Types.REQUEST_USER_PROFILE]: R.assoc("profile", true),
    [Types.USER_PROFILE_RESPONSE]: R.assoc("profile", false),
    [Types.USER_PROFILE_ERROR]: R.assoc("profile", false),
    [Types.REQUEST_USER_INSIGHTS]: R.assoc("insights", true),
    [Types.USER_INSIGHTS_RESPONSE]: R.assoc("insights", false),
    [Types.USER_INSIGHTS_ERROR]: R.assoc("insights", false)
  }
);

const fetchError = createReducer(
  { profile: false, insights: false },
  {
    [Types.REQUEST_USER_PROFILE]: R.assoc("profile", false),
    [Types.USER_PROFILE_RESPONSE]: R.assoc("profile", false),
    [Types.USER_PROFILE_ERROR]: R.assoc("profile", true),
    [Types.REQUEST_USER_INSIGHTS]: R.assoc("insights", false),
    [Types.USER_INSIGHTS_RESPONSE]: R.assoc("insights", false),
    [Types.USER_INSIGHTS_ERROR]: R.assoc("insights", true)
  }
);

export default combineReducers({
  profile,
  userInsights,
  fetchError,
  isFetching
});

const getUserProfileFetchErrors = createSelector(
  [state => state.get("user-profile")],
  R.prop("fetchError")
);

const getIsFetchingUserProfile = createSelector(
  [state => state.get("user-profile")],
  R.path(["isFetching", "profile"])
);
const getIsFetchingUserInsights = createSelector(
  [state => state.get("user-profile")],
  R.path(["isFetching", "insights"])
);

const getUserProfile = createSelector(
  [state => state.get("user-profile")],
  R.prop("profile")
);

const getUserAccessibleCompanies = createSelector(
  [getUserProfile],
  R.pipe(R.propOr([], "accessible_companies"), R.map(R.prop("value")))
);

const getUserProfileInsights = createSelector(
  [state => state.get("user-profile")],
  R.prop("userInsights")
);

const getUserOrderInsights = createSelector(
  [getUserProfileInsights],
  R.prop("orderInsights")
);

const getUserPatientInsights = createSelector(
  [getUserProfileInsights],
  R.prop("patientInsights")
);

const getUserContactInsights = createSelector(
  [getUserProfileInsights],
  R.prop("contactsInsights")
);

/*NOTE: dme user gets different response */
const getUserOrderStatusInsights = createSelector(
  [getUserProfileInsights],
  userInsights =>
    !Array.isArray(userInsights)
      ? {
          statuses: [],
          total: 0,
          estimated_revenue: 0
        }
      : {
          statuses: R.reject(R.prop("noShowCount"))(userInsights),
          total: R.compose(
            R.sum,
            R.map(R.prop("count")),
            R.reject(R.prop("noTotalCount"))
          )(userInsights),
          estimated_revenue: R.compose(
            R.sum,
            R.map(R.prop("revenue")),
            R.reject(R.prop("noTotalRevenue"))
          )(userInsights)
        }
);

const getUserEquipmentSoldData = createSelector(
  [getUserOrderInsights],
  orderInsights =>
    R.pipe(
      R.propOr({}, "order_status"),
      R.keys,
      R.map(key => ({
        name: capitalize(deSnakeCase(key)),
        count: R.pathOr(0, ["order_status", key], orderInsights)
      }))
    )(orderInsights)
);

export const selectors = {
  getUserProfile,
  getUserAccessibleCompanies,
  getUserProfileInsights,
  getUserProfileFetchErrors,
  getUserEquipmentSoldData,
  getUserOrderInsights,
  getUserPatientInsights,
  getUserContactInsights,
  getUserOrderStatusInsights,
  getIsFetchingUserInsights,
  getIsFetchingUserProfile
};
