import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isBoolean from 'lodash/isBoolean'
import compose from 'lodash/fp/compose'
import { selectors } from 'reducers'
import { mapDispatchToProps } from 'actions/order-profile'

const ReplacementOrder = ({
  incorrect_shipment,
  disabled,
  requestIncorrectShipmentUpdate
}) => (
  <div className='order-profile-replacement-order'>
    {isBoolean(incorrect_shipment) && (
      <label>
        <input
          type='checkbox'
          checked={incorrect_shipment}
          disabled={disabled}
          onChange={({ target: { checked } }) => (
            requestIncorrectShipmentUpdate(checked)
          )} />
        <span>This is a replacement order.</span>
      </label>
    )}
  </div>
)

ReplacementOrder.propTypes = {
  orderId: PropTypes.string.isRequired,
  incorrect_shipment: PropTypes.bool,
  disabled: PropTypes.bool,
  requestIncorrectShipmentUpdate: PropTypes.func.isRequired
}

export default compose(
  connect(
    (state, props) => {
      const { data: { incorrect_shipment } = {} } = selectors.getOrderInfo(
        state,
        props
      );
      const isUpdating = selectors.getIsUpdatingOrder(state, props);
      return {
        incorrect_shipment,
        disabled: isUpdating || incorrect_shipment === undefined
      };
    },
    mapDispatchToProps
  )
)(ReplacementOrder);
