import C from "action-types";
import { makeFetchCreator } from "actions/fetch";
import { put } from "utils/api"
import keyBy from "lodash/keyBy";

const searchFetch = makeFetchCreator({
  url: ({ table }) => `${table}`,
  baseType: C.SEARCH.PUT,
  method: put,
  transform: (response, { table, entityKey }) => ({
    entities: {
      [entityKey || table]: keyBy(response[table], "id")
    },
    searchData: response.data
  })
})

export const search = (table, entityKey) => (dispatch, getState) => {
  const query = getState().get("search").metaData

  let filterArray = []
  if (query[table].filter) {
    for (var column in query[table].filter) {
      const value = query[table].filter[column];
      if (value !== "") {
        filterArray.push({
          by: column,
          value
        });
      }
    }
  }

  return dispatch(searchFetch({
    table,
    entityKey,
    payload: {
      "sort": {
        "by": query[table].sort_by === "id" ? "ID" : query[table].sort_by,
        "direction": query[table].direction
      },
      "filter_by": filterArray,
      "pagination": {
        "page": query[table].page,
        "per": query[table].per
      },
      "search_for": {
        "by": query[table].field,
        "value": query[table].value
      }
    }
  }));
};
