import React, { useState } from "react";
import PropTypes from "prop-types";

const EmailGroupForm = ({ addNewEmailGroup, setCreateNewEmailGroupState }) => {
  const [groupNameCreateState, setGroupNameCreateState] = useState("");
  return (
    <div className="emailGroupInfo emailGroupDropShadow">
      <div style={{ margin: "10px" }}>
        <div className="center">
          <h2 style={{ color: "#02b4a6", marginTop: "10px" }}>
            Email Group Form
          </h2>
          <input
            style={{ marginTop: "20px" }}
            className="createEmailGroupInput"
            value={groupNameCreateState}
            onChange={e => {
              setGroupNameCreateState(e.target.value);
            }}
            placeholder={"Add Group Name..."}
          />
          <div
            style={{ marginTop: "20px" }}
            onClick={() => {
              addNewEmailGroup(groupNameCreateState);
            }}
            className="submitButton displayGridOne"
          >
            <span className="justifyAndAlignSelfCenter">SUBMIT</span>
          </div>
          <div
            style={{ marginTop: "20px" }}
            onClick={() => {
              setCreateNewEmailGroupState(false);
            }}
            className="submitButton displayGridOne"
          >
            <span className="justifyAndAlignSelfCenter">CANCEL</span>
          </div>
        </div>
      </div>
    </div>
  );
};
EmailGroupForm.propTypes = {
  addNewEmailGroup: PropTypes.func.isRequired,
  setCreateNewEmailGroupState: PropTypes.func.isRequired
};

export default EmailGroupForm;
