import { combineReducers } from 'redux';
import reducerLoader from './Reducers/common';
import reducerProducts from './Reducers/products';
import reducerServices from './Reducers/services';
import reducerFaq from './Reducers/faq';
import reducerVideoLibrary from './Reducers/videoLibrary';
import reducerQuestionnaire from './Reducers/questionnaire';
// import reducerDocuments from './Reducers/documents';
import reducerPatients from './Reducers/patients';
import reducerArticles from './Reducers/articles';
import reducerRecurringArticles from './Reducers/recurrearticles';
import reducerSchedule from './Reducers/schedule';
import reducerChat from './Reducers/chat';
import reducerDocumentCategory from './Reducers/documentCategory';
import reducerPDFTemplate from './Reducers/pdftemplate';

export default combineReducers({
  rdc_loader: reducerLoader,
  rdc_products: reducerProducts,
  rdc_services: reducerServices,
  rdc_faq: reducerFaq,
  rdc_videoLibrary: reducerVideoLibrary,
  rdc_questionnaire: reducerQuestionnaire,
  // rdc_documents: reducerDocuments,
  rdc_documentCategory : reducerDocumentCategory,
  rdc_patients: reducerPatients,
  rdc_articles: reducerArticles,
  rdc_rarticles: reducerRecurringArticles,
  rdc_schedule: reducerSchedule,
  rdc_chat: reducerChat,
  rdc_PDFTemplate: reducerPDFTemplate
});
