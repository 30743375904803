import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pausePhoneRecording, resumePhoneRecording } from "actions/phone";
import { selectors } from "reducers";
import MdCard from "react-icons/lib/md/credit-card";

const RecordingToggle = ({
  recordingStatus,
  pausePhoneRecording,
  resumePhoneRecording
}) => (
  <div className="recording-controls">
    {recordingStatus !== "paused" ? (
      <span onClick={pausePhoneRecording}>
        <MdCard size={24} />
        <button className="pause-recording-btn">
          <span>Take Credit Card Information</span>
        </button>
      </span>
    ) : (
      <span onClick={resumePhoneRecording}>
        <MdCard size={24} />
        <button className="resume-recording-btn">
          <span>Resume</span>
        </button>
      </span>
    )}
  </div>
);

export default connect(
  state => ({
    recordingStatus: selectors.getRecordingStatus(state)
  }),
  { pausePhoneRecording, resumePhoneRecording }
)(RecordingToggle);

RecordingToggle.propTypes = {
  recordingStatus: PropTypes.string,
  pausePhoneRecording: PropTypes.func.isRequired,
  resumePhoneRecording: PropTypes.func.isRequired
};
