import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "utils/api";
import { errorMessage } from "actions/message";
import { useFetch } from "hooks";
import { Waiting, ListItem } from "components/ui";
import AudioPlayer from "components/ui/audio-player";
import { formatPhone } from "utils/misc";
import moment from "moment";
import { path } from "ramda";
import { Link, withRouter } from "react-router-dom";
import { browserHistory } from "browser-history";

const ContactRecordProfile = ({ callSid, isModal }) => {
  const { response: call, isFetching, error, fetchData } = useFetch({
    apiFn: callSid => get(`twilio/callDetails/${callSid}`),
    transformError: path(["response", "body", "status"]),
  });
  React.useEffect(() => {
    fetchData(callSid);
  }, [callSid]);
  return (
    <div className="twilio-call-profile">
      <div className="page-header">
        <h2>
          Call Log: <span>{callSid}</span>
        </h2>
        {!isModal && (
          <button
            style={{ margin: 0, padding: 10 }}
            onClick={browserHistory.goBack}
          >
            Go Back
          </button>
        )}
      </div>
      {error && !isFetching && <div className="fetch-error">{error}</div>}
      {isFetching && <Waiting size="50px" />}
      {call && (
        <div
          className={`twilio-call-profile-content ${
            isFetching ? "is-fetching" : ""
          }`}
        >
          <div className="call-summary-container">
            <div className="call-direction-container">
              <div className="call-direction-header">
                <div className="direction-label">From</div>
                <div className="direction-info">
                  <span>{formatPhone(call.fromNumber)}</span>
                  <span>{fromLabel(call)}</span>
                </div>
              </div>
              <div className="call-direction-header">
                <div className="direction-label">To</div>
                <div className="direction-info">
                  <span>{formatPhone(call.toNumber)}</span>
                  <span>{toLabel(call)}</span>
                </div>
              </div>
              <div className="call-type-container">
                <span className="summary-label">Type:</span>
                <span>{call.type}</span>
                <span className="summary-label">Client:</span>
                <span>{clientLabel(call)}</span>
              </div>
              <div className="call-direction-details">
                <span className="summary-label">Direction:</span>
                <span>{call.direction}</span>
                <span className="summary-label">Status:</span>
                <span>{call.status}</span>
              </div>
            </div>
          </div>
          <div className="call-duration-container">
            <ListItem
              label="Hold/Ring Time"
              value={call.holdRingTime.substring(3)}
              underline
            />
            <ListItem
              label="Talk Time"
              value={call.TalkTime.substring(3)}
              underline
            />
            <ListItem
              label="Voicemail Duration"
              value={call.VMTime.substring(3)}
              underline
            />
            <ListItem
              label="Transfer Time"
              value={call.transferTime.substring(3)}
              underline
            />
            <ListItem
              label="Time In S3"
              value={call.S3Time.substring(3)}
              underline
            />
            <ListItem label="Total Time" value={call.totalTime.substring(3)} />
          </div>
          <div className="recording-container">
            <h4>Recording</h4>
            {call.recordingURL ? (
              <AudioPlayer src={call.recordingURL} />
            ) : (
              <div>No Recording</div>
            )}
          </div>
          <div className="call-time-container">
            <ListItem
              label="Start"
              value={
                call.start &&
                moment(call.start).format("MMM DD, YYYY -  hh:mm A")
              }
              underline
            />
            <ListItem
              label="Answered"
              value={
                call.answered &&
                moment(call.answered).format("MMM DD, YYYY -  hh:mm A")
              }
              underline
            />
            <ListItem
              label="End"
              value={
                call.end && moment(call.end).format("MMM DD, YYYY -  hh:mm A")
              }
              underline
            />
          </div>
        </div>
      )}
    </div>
  );
};

ContactRecordProfile.propTypes = {
  callSid: PropTypes.string.isRequired,
  errorMessage: PropTypes.func.isRequired,
  isModal: PropTypes.bool
};

export default connect(() => ({}), { errorMessage })(ContactRecordProfile);

const fromLabel = ({ direction, companyID, fromName }) =>
  direction === "outgoing" && companyID ? (
    <Link to={`/companies/${companyID}/profile`}>{fromName}</Link>
  ) : (
    fromName
  );
fromLabel.propTypes = {
  direction: PropTypes.string,
  companyID: PropTypes.string,
  fromName: PropTypes.string
};

const toLabel = ({ direction, companyID, toName }) =>
  direction === "incoming" && companyID ? (
    <Link to={`/companies/${companyID}/profile`}>{toName}</Link>
  ) : (
    toName
  );
toLabel.propTypes = {
  direction: PropTypes.string,
  companyID: PropTypes.string,
  toName: PropTypes.string
};

const clientLabel = ({ clientName, client }) =>
  client ? (
    <Link to={`/users/${client.replace("client:", "")}`}>{clientName}</Link>
  ) : (
    clientName
  );
clientLabel.propTypes = {
  clientName: PropTypes.string,
  client: PropTypes.string
};

export const ContactRecordProfileRoute = withRouter(({ match }) => (
  <ContactRecordProfile callSid={match.params.sid} />
));
