import React from 'react'
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

export const MonthlyReportPieChart = ({ data, title }) => (
  <div className='activation-report_pie-chart'>
    <div className='activation-report_pie-chart-header'>{title}</div>
    <PieChart width={375} height={225} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        fill="#82ca9d"
        label>
        { data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
      </Pie>
      <Tooltip />
    </PieChart>
  </div>
)

MonthlyReportPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, name: PropTypes.string })
  ).isRequired,
  title: PropTypes.string.isRequired
};
