import { take, call, put } from "redux-saga/effects";
import C from "action-types";
import { message, errorMessage } from "actions/message";
import * as api from "utils/api";
import * as R from "ramda";

const getApiError = error =>
  R.pathOr(error, ["response", "body", "status"], error);

function* insuranceImport({ payload }) {
  try {
    yield put({ type: C.INSURANCE.INSURANCE_IMPORT_REQUEST });
    const url = "imports/insurance";
    const response = yield call(api.post, url, payload);
    yield put({ type: C.INSURANCE.INSURANCE_IMPORT_SUCCESS });
    yield put(
      message(`Insurances submitted successfully! ${response.status}`, -1)
    );
  } catch (error) {
    const accountNumbers = R.pipe(
      R.split(":"),
      R.nth(1),
      x => (x ? R.split(",", x) : []),
      R.map(x => ({ acctNum: x.trim() }))
    )(getApiError(error));

    yield put({
      type: C.INSURANCE.INSURANCE_IMPORT_FAILURE,
      payload: accountNumbers
    });
    yield put(errorMessage(`There was a problem with the import`, -1));
  }
}

export default function* watchInsuranceImport() {
  while (true) {
    const action = yield take(C.INSURANCE.INSURANCE_IMPORT);
    yield call(insuranceImport, action);
  }
}
