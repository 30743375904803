import { errorMessage } from "actions/message";
import { post } from "utils/api";

export const Types = {
  GET_IVR_JOBS_RESPONSE: "GET_IVR_JOBS_RESPONSE",
  START_IVR_JOB_RESPONSE: "START_IVR_JOB_RESPONSE",
  KILL_IVR_JOB_RESPONSE: "KILL_IVR_JOB_RESPONSE"
};

export const fetchIvrJobs = (startDate, endDate) => async dispatch => {
  try {
    const response = await post(`ivr/IVR_Thread_Index`, {
      startDate,
      endDate
    });
    dispatch({ type: Types.GET_IVR_JOBS_RESPONSE, response });
    return [];
  } catch (error) {
    dispatch(errorMessage("Failed to get list of ivr jobs"));
  }
};
