import React from "react";
import PropTypes from "prop-types";
import { Ul, AttributeItem } from "components/lists";
import SectionHeader from "components/profile/section_header";
import { getLocalTimeFromZip } from "utils/dates";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isValidPhone, isValidEmail } from "utils/redux-form";
import useFormikAsyncValidationCache from "hooks/useFormikAsyncValidationCache";
import PhoneNumberField from "components/forms/phone-number-field";
import * as Yup from "yup";
import { MdSave, MdCancel } from "react-icons/lib/md";
import { asyncValidateUniqueEmail } from "utils/form-validation";
import IconButton from "components/ui/icon-button";
import HasAppPermission from "components/shared/has-app-permission";

const EditAccountInformation = ({
  patientInfo,
  handleCancel,
  formOfContactOptions,
  genderOptions,
  initialValues,
  updatePatient,
  patientId,
  setUpdatingPatientInformation
}) => {
  const validateUniqueEmail = useFormikAsyncValidationCache(async email => {
    return !(await asyncValidateUniqueEmail(email, patientId));
  });
  // const [outreachStart, setOutreachStart] = useState(
  //   initialValues.outreach_call_start_time
  // );

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      email: Yup.string()
        .test(
          "checkValidEmail",
          "Please enter a valid email address.",
          email => !isValidEmail(email)
        )
        .test("checkDuplEmail", "Email is already used", validateUniqueEmail),
      date_of_birth: Yup.date()
        .max(new Date(), "The Date of Birth must be before today")
        .required("Required"),
      phone_number: Yup.string()
        // .required("Required")
        .test(
          "valid-number",
          "Invalid phone number",
          val => !isValidPhone(val)
        ),
      mobile_number: Yup.string().test(
        "valid-number",
        "Invalid phone number",
        val => !isValidPhone(val)
      ),
      form_of_contact: Yup.string().when("can_email", {
        is: value => value === false,
        then: () =>
          Yup.string().test(
            "can_email_form_of_contact",
            "If Allows resupply email is unchecked, Email cannot be a form of contact.",
            val => val !== "Email"
          ),
        otherwise: () => Yup.string().required("Required")
      })
      // outreach_call_start_time: Yup.string().when("use_company_outreach", {
      //   is: value => value === false,
      //   then: () =>
      //     Yup.string().test(
      //       "valid-start-time",
      //       "If use company default outreach is unchecked, preferred call time must be set.",
      //       val => val
      //     )
      // }),
      // outreach_call_end_time: Yup.string().when("use_company_outreach", {
      //   is: value => value === false,
      //   then: () =>
      //     Yup.string()
      //       .test(
      //         "valid-end-time",
      //         "If use company default outreach is unchecked, preferred call time must be set.",
      //         val => val
      //       )
      //       .test(
      //         "validOutreachTime",
      //         "End time must be later than start time",
      //         val => val > outreachStart
      //       )
      // })
    })
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async values => {
          setUpdatingPatientInformation(true);
          await updatePatient(values);
          setUpdatingPatientInformation(false);
        }}
      >
        {({ values, isSubmitting, isInitialValid, isValid }) => (
          <Form className="inline-edit-patient-form">
            <SectionHeader>
              Patient Information
              <span style={{ float: "right" }}>
                <IconButton
                  onClick={handleCancel}
                  text="Cancel"
                  style={{ color: "red" }}
                  icon={<MdCancel />}
                  type="button"
                />
                <IconButton
                  disabled={!isValid || isInitialValid || isSubmitting}
                  text="Save"
                  style={{ color: "green" }}
                  icon={<MdSave />}
                  type="submit"
                />
              </span>
            </SectionHeader>
            <Ul>
              <HasAppPermission permission="orderSupplies">
                <AttributeItem title="Contact method:">
                  <ErrorMessage name="form_of_contact">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <Field
                    name="form_of_contact"
                    as="select"
                    options={formOfContactOptions.filter(
                      ({ active }) => active
                    )}
                    autoComplete="force-disabled"
                  >
                    <option />
                    {formOfContactOptions.map(({ text, value }) => (
                      <option key={value} value={value}>
                        {text}
                      </option>
                    ))}
                  </Field>
                </AttributeItem>
              </HasAppPermission>

              {values.form_of_contact == "Technology Only" && (
                <div className="outreach-method-statuses-container">
                  <div>What types of technology would the patient like?</div>
                  <div>
                    <AttributeItem title="Text Messages:">
                      <Field name="accepts_text" type="checkbox" />
                    </AttributeItem>

                    <AttributeItem title="Push Notifications:">
                      <Field
                        name="accepts_push_notifications"
                        type="checkbox"
                      />
                    </AttributeItem>
                    <AttributeItem title="Emails:">
                      <Field name="accepts_email" type="checkbox" />
                    </AttributeItem>
                  </div>
                </div>
              )}

              <AttributeItem title="Phone number:">
                <ErrorMessage name="phone_number">
                  {msg => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field
                  name="phone_number"
                  as="input"
                  type="tel"
                  autoComplete="force-disabled"
                  component={PhoneNumberField}
                />
              </AttributeItem>
              <AttributeItem title="Mobile number:">
                <ErrorMessage name="mobile_number">
                  {msg => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field
                  name="mobile_number"
                  as="input"
                  type="tel"
                  component={PhoneNumberField}
                  autoComplete="force-disabled"
                />
              </AttributeItem>
              <AttributeItem title="Email Address:">
                <ErrorMessage name="email">
                  {msg => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field
                  name="email"
                  type="email"
                  autoComplete="force-disabled"
                />
              </AttributeItem>
              <AttributeItem title="Date of Birth:">
                <ErrorMessage name="email">
                  {msg => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field name="date_of_birth" type="date" />
              </AttributeItem>
              <AttributeItem title="Patient Local Time:">
                <span>{getLocalTimeFromZip(patientInfo?.address?.zip)}</span>
              </AttributeItem>
              <AttributeItem title="Spanish Speaker:">
                <Field name="speaks_spanish" type="checkbox" />
              </AttributeItem>
              <AttributeItem title="Gender:">
                <ErrorMessage name="email">
                  {msg => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field name="gender" as="select" autoComplete="force-disabled">
                  <option />
                  {genderOptions.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </Field>
              </AttributeItem>
              <AttributeItem title="Setup Date:">
                <ErrorMessage name="setup_date">
                  {msg => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
                <Field name="setup_date" type="date" />
              </AttributeItem>

              {/* TODO needs to respect patients timezone */}
              {/*
              <AttributeItem title="Use Company Default Outreach Time">
                <Field
                  label="Use Company Default Outreach"
                  name="use_company_outreach"
                  type="checkbox"
                  checked={values.use_company_outreach}
                />
              </AttributeItem>
              {values.use_company_outreach == false && (
                <AttributeItem title="Preferred Call Start">
                  <Field
                    name="outreach_call_start_time"
                    as="input"
                    type="time"
                    step="3600000"
                    onInput={e => setOutreachStart(e.target.value)}
                  />
                </AttributeItem>
              )}
              {values.use_company_outreach == false && (
                <AttributeItem title="Preferred Call End">
                  <Field
                    name="outreach_call_end_time"
                    as="input"
                    type="time"
                    step="3600000"
                  />
                </AttributeItem>
              )}
            */}
            </Ul>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EditAccountInformation.propTypes = {
  patientInfo: PropTypes.object.isRequired,
  formOfContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  genderOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  updatePatient: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  setUpdatingPatientInformation: PropTypes.func.isRequired
};

export default EditAccountInformation;
