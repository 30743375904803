import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Form, Formik, Field } from "formik";
import { updateQuery } from "features/query/actions";

const PatientFilterSearch = ({ updateQuery, callFromQueue }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        account_number: "",
        DOB: "",
        from_number: callFromQueue ? callFromQueue.from : "",
        to_number: callFromQueue ? callFromQueue.to : ""
      }}
      onSubmit={values => {
        updateQuery("inbound-patient-lookup", {
          pagination: {
            per: 10,
            page: 1
          },
          sort: {
            by: "ID",
            direction: "asc"
          },
          filters: {
            from_number: [values.from_number],
            name: [values.name],
            DOB: [values.DOB],
            to_number: callFromQueue ? [callFromQueue.to] : [""],
            account_number: [values.account_number]
          }
        });
      }}
    >
      {({ resetForm, handleSubmit }) => (
        <Form className="patient-lookup-form">
          <Field name="name" placeholder="Patient's Name" />
          <Field name="DOB" placeholder="Date Of Birth - MM/DD/YYYY" />
          <Field
            name="account_number"
            placeholder="Account Number (optional)"
          />
          <Field name="from_number" placeholder="Phone Number" />
          <button
            title="Click Here To Refine Patient Search"
            type="submit"
            onClick={handleSubmit}
          >
            Search
          </button>
          <button
            title="Reset Form"
            onClick={e => {
              e.preventDefault();
              resetForm({
                name: "",
                account_number: "",
                DOB: "",
                from_number: callFromQueue ? callFromQueue.from : "",
                to_number: callFromQueue ? callFromQueue.to : ""
              });
            }}
          >
            Reset
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default connect(
  state => ({
    callFromQueue: selectors.getQueueCallToAnswer(state)
  }),
  { updateQuery }
)(PatientFilterSearch);

PatientFilterSearch.propTypes = {
  updateQuery: PropTypes.func.isRequired,
  callFromQueue: PropTypes.shape({
    to: PropTypes.string,
    from: PropTypes.string
  })
};

PatientFilterSearch.defaultProps = {
  callFromQueue: { to: "", from: "" }
};
