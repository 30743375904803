import React from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { Field, reduxForm, reset } from 'redux-form'
import compose from 'lodash/fp/compose'
import moment from 'moment'
import { requestNoteAdd } from 'actions/order-profile'
import { selectors } from 'reducers'
import { Rows } from 'components/ui'
import Header from './header'
import { containsNoCreditCardInfo } from "utils/redux-form";

const renderTextArea = ({ input, meta: { error } }) => {
  const hasError = typeof error === "string";
  return (
    <div style={{ flex: '1 1 auto', display: 'grid', gridTemplateRows: '16px 1fr' }}>
      <div style={{ color: "red", justifySelf: 'flex-end' }}>{hasError ? error : ''}</div>
      <textarea {...input} />
    </div>
  );
};
renderTextArea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const Notes = ({
  userId,
  patientId,
  isFetching,
  notes,
  canAddNote,
  handleSubmit,
  pristine,
  valid,
  resetForm,
  requestNoteAdd,
  readOnly
}) => (
  <div className='order-profile-notes'>
    <Header showSpinner={isFetching}>Order Notes</Header>
    <div className='order-profile-notes-body'>
      {!readOnly && <form
        onSubmit={handleSubmit(({ note }) => {
          requestNoteAdd({
            note,
            creator_id: userId,
            patient_id: patientId
          })
          resetForm()
        })}
        className='order-profile-add-note'>
        <Field
          name='note'
          validate={containsNoCreditCardInfo}
          component={renderTextArea} />
        <button
          disabled={pristine || !valid || !canAddNote || isFetching}
          type='submit'>
          Add note
        </button>
      </form>}
      <div className='order-profile-note-list'>
        <Rows>
          {notes.map(({ id, creator, created_at, note }) => (
            <div key={id} className='order-profile-note'>
              <div>
                <div className='creator'>{creator}</div>
                <div>{moment(created_at).format('MMMM Do YYYY, h:mm a')}</div>
              </div>
              <div>{note}</div>
            </div>
          ))}
        </Rows>
      </div>
    </div>
  </div>
)

export default compose(
  connect((state, props) => {
      const {
        data: notes,
        isFetching: isNotesFetching
      } = selectors.getOrderNotes(state, props)
      const {
        data: { patient_id: patientId } = {},
        isFetching: isPatientInfoFetching
      } = selectors.getOrderPatientInfo(state, props)
      const { id: userId } = selectors.getUser(state)
      const isSubmittingNote = selectors.getIsSubmittingNoteAdd(state, props)

      return {
        notes,
        isFetching: isNotesFetching || isPatientInfoFetching || isSubmittingNote,
        patientId,
        userId,
        canAddNote: !!patientId && !!userId,
      }
    }, (dispatch, { orderId }) => {
    const form =  `notes-${orderId}`
    return {
      form,
      requestNoteAdd: (...args) => dispatch(requestNoteAdd({ orderId })(...args)),
      resetForm: () => dispatch(reset(form))
    }
  }),
  reduxForm({ getFormState: state => state.get('form') })
)(Notes)

Notes.propTypes = {
  readOnly: PropTypes.bool,
  canAddNote: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  patientId: PropTypes.string,
  pristine: PropTypes.bool,
  requestNoteAdd: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  valid: PropTypes.bool
};
