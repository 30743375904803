import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { search } from "actions/search";
import SearchBox from "./search-box";
import NavMenu from "./nav-menu";
import Footer from "./footer";
import AppUpdates from "./app-updates";
import { openPhone } from "actions/phone";
import { requestUserLogout } from "actions/authentication";

const SideBar = ({
  user,
  search,
  searchFormAttributes,
  requestUserLogout,
  openPhone
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section
      id="sidebar"
      role="navigation"
      className={`no-print ${isOpen ? "open" : ""}`}
    >
      <div className="search-bar-container">
        <SearchBox
          search={search}
          searchFormAttributes={searchFormAttributes}
        />
        {user.get("canMakeCalls") && (
          <Footer requestUserLogout={requestUserLogout} openPhone={openPhone} />
        )}
        <NavMenu requestUserLogout={requestUserLogout} />
        <AppUpdates />
      </div>
      <div className="closed-press-area" onClick={() => setIsOpen(true)} />
      {isOpen && (
        <div
          className="sidebar-open-overlay"
          onClick={() => setIsOpen(false)}
        />
      )}
    </section>
  );
};

export default connect(
  state => ({
    searchFormAttributes: state.get("searchFormAttributes"),
    user: state.get("user")
  }),
  {
    search,
    requestUserLogout,
    openPhone
  }
)(SideBar);

SideBar.propTypes = {
  user: PropTypes.object,
  searchFormAttributes: PropTypes.object,
  search: PropTypes.func.isRequired,
  requestUserLogout: PropTypes.func.isRequired,
  openPhone: PropTypes.func.isRequired
};
