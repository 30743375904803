import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { get } from "utils/api";
import { InlineWaiting, PureModal } from "components/ui";
import { useDispatch } from "react-redux";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";

const RaCMNLink = ({ patientId, external_id, fetchPatientCmns }) => {
  const {
    response: raCmnPatientCmnInfo,
    isFetching,
    fetchData: getPatientRaCmnLink
  } = useFetch({
    apiFn: patientId => get(`connect-answer/iframe-link/${patientId}`),
    throwOnError: true,
    defaultValue: {}
  });
  const { fetchData: importPatientCmns } = useFetch({
    apiFn: patientId => get(`referral-answer/import-patient-cmns/${patientId}`),
    defaultValue: {}
  });
  const dispatch = useDispatch();
  return (
    <PureModal
      onClose={async () => {
        await importPatientCmns(patientId);
        await fetchPatientCmns(patientId);
      }}
      renderTrigger={({ openModal }) => (
        <div>
          {isFetching ? (
            <InlineWaiting />
          ) : (
            <span
              style={{
                margin: 0,
                padding: "5px 10px",
                color: "#4281A6",
                cursor: "pointer"
              }}
              onClick={async () => {
                try {
                  await getPatientRaCmnLink(patientId);
                  openModal();
                } catch (err) {
                  dispatch(errorMessage(getApiErrorMessage(err)));
                }
              }}
            >
              {external_id}
            </span>
          )}
        </div>
      )}
      windowProps={{ large: true }}
    >
      {raCmnPatientCmnInfo.cmn_dashboard_link && (
        <iframe
          src={`${raCmnPatientCmnInfo.cmn_link}&cmnId=${external_id}`}
          height="100%"
          width="100%"
          style={{ height: "100%", display: "flex", flex: 1 }}
          allow="encrypted-media"
          allowFullScreen
          title="Patient Cmns"
          scrolling="yes"
          frameBorder="0"
        />
      )}
    </PureModal>
  );
};

RaCMNLink.propTypes = {
  patientId: PropTypes.string.isRequired,
  external_id: PropTypes.string.isRequired,
  fetchPatientCmns: PropTypes.func.isRequired
};

export default RaCMNLink;
