import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { RowSelectCell } from "hooks/useRowSelectNew";
import { PatientsTableSettingsContext } from "./settings";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { formatDate } from "utils/dates";

const dateFormat = "MM / DD / YYYY -  h:mm:ss a";

const PatientsTableRow = ({
  ticketNumber,
  email,
  registrationDate,
  worked,
  asignee,
  assignedDate,
  worker,
  workedDate,
  matched,
  matchedDate,
  notes
}) => {
    const user = useSelector(selectors.getUser);
    const { isColumnVisible } = useContext(PatientsTableSettingsContext);
    function checkColumn(field) {
        return isColumnVisible({ field });
    }
    return (
        <tr>
        {checkColumn("all") && user.role == "Administrator" ? <RowSelectCell id={ticketNumber} tdStyle={{padding: "0 0 0 10px"}} labelStyle={{justifyContent: "left"}} /> : null}
        {checkColumn("ticketNumber") && (
            <td title="Search matches for this register">
            <Link to={`/first-time-patients-matches/${ticketNumber}`}>
                {ticketNumber}
            </Link>
            </td>
        )}
        {checkColumn("email") && <td>{email}</td>}
        {checkColumn("registrationDate") && <td>{formatDate(registrationDate, dateFormat)}</td>}
        {checkColumn("worked") && <td>{worked ? "Yes" : "No"}</td>}
        {checkColumn("asignee") && <td>{asignee}</td>}
        {checkColumn("assignedDate") && <td>{formatDate(assignedDate, dateFormat)}</td>}
        {checkColumn("worker") && <td>{worker}</td>}
        {checkColumn("workedDate") && <td>{formatDate(workedDate, dateFormat)}</td>}
        {checkColumn("matched") && <td>{matched ? "Yes" : "No"}</td>}
        {checkColumn("matchedDate") && <td>{formatDate(matchedDate, dateFormat)}</td>}
        {checkColumn("notes") && <td>{notes}</td>}
        </tr>
    );
};

export default PatientsTableRow;

PatientsTableRow.propTypes = {
  ticketNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  registrationDate: PropTypes.string.isRequired,
  worked: PropTypes.boolean,
  asignee: PropTypes.string,
  assignedDate: PropTypes.string,
  worker: PropTypes.string,
  workedDate: PropTypes.string,
  matched: PropTypes.boolean,
  matchedDate: PropTypes.string,
  notes: PropTypes.string
};
