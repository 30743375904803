import React from "react";
import PropTypes from 'prop-types';
import { Waiting } from "../../ui/waiting";

export const CompanyContactModal = ({companyModalType, companyBreakdown, modalTotal, isFetching}) => {
  return (
    <>
    <div className="modalContainer">
      <div className="modalTitle">
        {companyBreakdown.length ? companyBreakdown[0].company : ""}
      </div>
      <div className="breakdownContainer--listItem modalHeader">
        <div>SleepCoach</div> <div>{companyModalType}</div>
      </div>
      {isFetching ? (
        <Waiting size={"80px"} />
      ) : companyBreakdown.length ? (
        companyBreakdown.map((bd, index) => {
          return (
            <div
              key={bd.coach}
              className={
                (index + 1) % 2 === 0
                  ? "breakdownContainer--listItem"
                  : "breakdownContainer--listItem modalRow"
              }
            >
              <div>{bd.coach}:</div> <div>{bd.calls}</div>
            </div>
          );
        })
      ) : (
        <div className="breakdownContainer--listItem">No Data To Display</div>
      )}
      <div className="breakdownContainer--listItem borderTop">
        <div>Total:</div>
        <div>{modalTotal}</div>
      </div>
    </div>
    </>
  );
};

CompanyContactModal.propTypes = {
  companyModalType: PropTypes.string,
  companyBreakdown: PropTypes.arrayOf(PropTypes.object),
  modalTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFetching: PropTypes.bool
};
