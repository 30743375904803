import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { post } from "utils/api";
import { Spinner } from 'components/ui';
import { errorMessage } from "actions/message";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { AvailableCoachesConfirmationModal } from './confirmation-modal.jsx'
import { useDispatch } from 'react-redux';

const AvailableCoachesModal = ({ onClose, onSubmit, appointmentIDs }) => {

    const dispatch = useDispatch()

  const [availableCoaches, setAvailableCoaches] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState({ text: "Select Coach", value: "" })

  const { fetchData: fetchCoaches, isFetching } = useFetch({
    apiFn: () => post("dme_portal/appointments/manager/available-coaches", appointmentIDs),
    defaultValue: [],
    onError: error => {
        dispatch(errorMessage(`Failed to fetch coaches: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
    }
  });

  const { fetchData: updateCoach, isFetching: isFetchingCoach } = useFetch({
    apiFn: () => post("dme_portal/appointments/manager/move-coach", { AppointmentIds: appointmentIDs, CoachId: selectedCoach.value}),
    defaultValue: [],
    onError: error => {
      dispatch(errorMessage(`Failed to Update coach: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
    },
    onSuccess: () => {
        onClose();
        onSubmit();
    }
  });

  const getCoaches = async () =>  {
    const res = await fetchCoaches()
    if(res){
      setAvailableCoaches(res.Result)
    }
  }

  const submit = async () => {
    await updateCoach();
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    setSelectedCoach({ text: text, value: value });
  };

  useEffect(() => {
    getCoaches()
  },[])

  return (
    <div style={{padding: "1em"}}>
      <h4>Move Appointments to: </h4>
      <div>
          <hr style={{marginBottom: 0}} />
          <p style={{padding: "0px", margin: "1px"}}>New Coach</p>
          {isFetching
            ? <Spinner />
            : <select value={selectedCoach.value} onChange={e => handleSelectChange(e) }>
                <option value="" >Select Coach</option>
                {availableCoaches?.map((coach) => (
                    <option key={coach.value} value={coach.value}>
                      {coach.text}
                    </option>
                ))}
            </select>
          }
      </div>
          <div style={{display: "flex", justifyContent: "end"}}>
            {
                isFetchingCoach ?
                <Spinner/>
                :
                <AvailableCoachesConfirmationModal disabled={selectedCoach.value === ""} coachName={selectedCoach.text} submit={submit}/>
            }
          </div>
    </div>
  )
}

AvailableCoachesModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  appointmentIDs: PropTypes.array
};

export default AvailableCoachesModal;
