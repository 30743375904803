import { createSelector } from "reselect";
import { Types } from "actions/importer";
import { createReducer } from "utils/reducer";
import { assocPath, path } from "ramda";

export default createReducer(
  {
    orderImport: {
      isSubmitting: false,
      company: "",
      error: "",
      results: { patientFailList: [], orderFailList: [] }
    }
  },
  {
    [Types.START_ORDER_IMPORT]: state =>
      assocPath(["orderImport", "inProgress"], true, state),
    [Types.ORDER_IMPORT_FAILED]: (state, { error }) => ({
      ...state,
      orderImport: { inProgress: false, error: error }
    }),
    [Types.ORDER_IMPORT_COMPLETED]: (state, { payload }) => ({
      ...state,
      orderImport: { inProgress: false, error: "", results: payload }
    })
  }
);

/**
 * Import selectors
 */
const getOrderImportStatus = createSelector(
  [state => state.get("importer")],
  path(["orderImport"])
);

export const selectors = {
  getOrderImportStatus
};
