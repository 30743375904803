import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  setPropTypes,
  withStateHandlers,
  withHandlers
} from 'recompose'
import * as R from 'ramda'
import { InlineWaiting } from 'components/ui'
import { put } from 'utils/api'

const PatientFinder = ({
  handleSubmit,
  guids,
  handleInputChange,
  isFetching,
  companiesLoaded
}) => (
  <form
    onSubmit={handleSubmit}
    className='patient-finder'>
    <div className='prompt'>
      Enter Patient GUIDs for patients to find, separated by spaces.
    </div>
    <textarea
      name='guids'
      value={guids}
      onChange={handleInputChange}
      className='guid-input' />
    <button
      disabled={!guids.trim() || isFetching || !companiesLoaded}
      type='submit'
      className='submit-button'>
      {isFetching ? <InlineWaiting /> : 'Find Patients'}
    </button>
  </form>
)

export default compose(
  setPropTypes({
    onGuidResponse: PropTypes.func,
    onGuidError: PropTypes.func,
    defaultGuids: PropTypes.arrayOf(PropTypes.string)
  }),
  withStateHandlers(({ defaultGuids = [] }) => (
    { guids: defaultGuids.join('\n'), isFetching: false }
  ), {
    handleInputChange: () => ({ target: { name, value } }) => ({
      [name]: value
    }),
    setFetching: () => newState => ({ isFetching: !!newState })
  }),
  withHandlers({
    handleSubmit: ({ guids, setFetching, onGuidResponse, onGuidError }) => async event => {
      event.preventDefault()
      guids = R.compose(R.uniq, R.split(/[\s,]+/), R.trim)(guids)
      setFetching(true)
      try {
        const response = await put(
          'patients/find-patients',
          { ids: guids }
        )
        if (onGuidResponse) {
          onGuidResponse(response)
        }
      } catch(error) {
        if (onGuidError) {
          onGuidError(error)
        }
      }
      setFetching(false)
    }
  })
)(PatientFinder)

PatientFinder.propTypes = {
  guids: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  companiesLoaded: PropTypes.bool
};
