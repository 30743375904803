import React, { useState, useEffect } from "react";
import FormikField from "components/forms/formik-field";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import SearchableDropdown from "components/searchable-dropdown";
import { IconButton, InlineWaiting } from "components/ui";
import MdEdit from "react-icons/lib/md/edit";
import CancelIcon from "react-icons/lib/md/cancel";
import MdDelete from "react-icons/lib/md/delete";
import { get } from "utils/api";
import { useFetch } from "hooks";

const PatientInsurancesForm = () => {
  const { values, setFieldValue, touched, errors, setFieldTouched } =
    useFormikContext();
  const guarantorOptions = useSelector(
    selectors.getInsuranceRelationshipOptions
  );
  const [addSecondary, setAddSecondary] = useState(false);
  const toggleAddSecondary = () => {
    setAddSecondary(!addSecondary);
  };
  const {
    response: companyInsurances,
    fetchData: fetchCompanyInsurances,
    isFetching
  } = useFetch({
    apiFn: id => get(`companies/${id}/insurances`),
    transformResponse: insurances =>
      insurances
        .filter(x => x.bActive)
        .map(({ sInsuranceCompanyName, sMGInsuranceID }) => ({
          text: sInsuranceCompanyName,
          value: sMGInsuranceID
        })),
    defaultValue: []
  });
  useEffect(() => {
    if (values.company_id) fetchCompanyInsurances(values.company_id);
    return () => {};
  }, [values.company_id]);
  return (
    <fieldset>
      <legend>Insurance</legend>
      <div className="order-form-section">
        <div style={{ fontSize: "1.5em", marginBottom: "10px" }}>
          Primary:
          <span style={{ fontSize: "1.05rem", float: "right", color: "#666" }}>
            {!values.company_id && "Please Select Company"}
          </span>
        </div>
        <span className="label-container">
          <div className="form-field-label">Insurance</div>
          <span style={{ color: "red" }}>
            {touched.primary_insurance?.insurance_company_id
              ? errors.primary_insurance?.insurance_company_id
              : ""}
          </span>
        </span>
        {isFetching ? (
          <InlineWaiting />
        ) : (
          <SearchableDropdown
            disabled={!values.company_id}
            options={[{ text: "", value: "", key: "" }, ...companyInsurances]}
            selected={values.primary_insurance.insurance_company_id}
            onSelect={value => {
              setFieldTouched("primary_insurance.insurance_company_id", true);
              setFieldValue("primary_insurance.insurance_company_id", value);
            }}
          />
        )}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: "20px"
          }}
        >
          <FormikField
            name="primary_insurance.policy_number"
            label="Policy Number:"
            type="text"
          />
          <FormikField
            name="primary_insurance.group_number"
            label="Group Number:"
            type="text"
          />
          <FormikField
            name="primary_insurance.guarantor_relation"
            label="Guarantor Relation"
            component="select"
          >
            <option />
            {guarantorOptions.map(({ text, key }) => (
              <option value={text} key={key}>
                {text}
              </option>
            ))}
          </FormikField>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20px"
          }}
        >
          <FormikField
            name="primary_insurance.policy_start_date"
            label="Policy Start Date:"
            type="date"
          />
          <FormikField
            name="primary_insurance.policy_end_date"
            label="Policy End Date:"
            type="date"
          />
        </div>
      </div>
      <div>
        <div className="order-form-section">
          <div style={{ fontSize: "1.5em", marginBottom: "10px" }}>
            Secondary:
            {!addSecondary && !values.secondary_insurance?.insurance_type_id && (
              <span>
                <IconButton
                  icon={<MdEdit />}
                  text="Add"
                  style={{ fontSize: "15px", marginLeft: "20px" }}
                  onClick={toggleAddSecondary}
                />
              </span>
            )}
            {values.secondary_insurance?.insurance_type_id && (
              <IconButton
                onClick={() => {
                  setFieldValue("secondary_insurance.insurance_type_id", "");
                  setFieldValue("secondary_insurance.insurance_company_id", "");
                  setFieldValue("secondary_insurance.policy_number", "");
                  setFieldValue("secondary_insurance.group_number", "");
                  setFieldValue("secondary_insurance.policy_start_date", "");
                  setFieldValue("secondary_insurance.policy_end_date", "");
                }}
                text="Delete"
                style={{
                  color: "red",
                  fontSize: "15px",
                  marginLeft: "20px"
                }}
                icon={<MdDelete />}
                type="button"
              />
            )}
            {addSecondary && (
              <IconButton
                onClick={() => {
                  toggleAddSecondary();
                }}
                text="Cancel"
                style={{
                  color: "gray",
                  fontSize: "15px",
                  marginLeft: "20px"
                }}
                icon={<CancelIcon />}
                type="button"
              />
            )}
          </div>
          {(addSecondary || values.secondary_insurance?.insurance_type_id) && (
            <>
              <span className="label-container">
                <div className="form-field-label">Insurance</div>
              </span>
              {isFetching ? (
                <InlineWaiting />
              ) : (
                <SearchableDropdown
                  disabled={!values.company_id}
                  options={[
                    { text: "", value: "", key: "" },
                    ...companyInsurances
                  ]}
                  selected={values.secondary_insurance.insurance_company_id}
                  onSelect={value => {
                    setFieldValue(
                      "secondary_insurance.insurance_company_id",
                      value
                    );
                  }}
                />
              )}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridGap: "20px"
                }}
              >
                <FormikField
                  name="secondary_insurance.policy_number"
                  label="Policy Number:"
                  type="text"
                />
                <FormikField
                  name="secondary_insurance.group_number"
                  label="Group Number:"
                  type="text"
                />
                <FormikField
                  name="secondary_insurance.guarantor_relation"
                  label="Guarantor Relation:"
                  component="select"
                >
                  <option />
                  {guarantorOptions.map(({ text, key }) => (
                    <option value={text} key={key}>
                      {text}
                    </option>
                  ))}
                </FormikField>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "20px"
                }}
              >
                <FormikField
                  name="secondary_insurance.policy_start_date"
                  label="Policy Start Date:"
                  type="date"
                />
                <FormikField
                  name="secondary_insurance.policy_end_date"
                  label="Policy End Date:"
                  type="date"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default PatientInsurancesForm;

PatientInsurancesForm.propTypes = {};
