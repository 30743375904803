import { generateGuid } from "utils/misc";

const DEFAULT_PERIOD = 3000; /* ms */
const DEFAULT_ERROR_PERIOD = 10000; /* ms */

const messageMiddleware = () => next => action => {
  const { type, n, id, message, period, payload } = action;
  if (n !== undefined) {
    // If `n` is defined, then this action has been dispatched from
    // this middleware and does not need to be examined again.
    return next(action);
  } else if (type === "MESSAGE" || /_SUCCESS$/.test(type)) {
    next({
      type,
      id: id || generateGuid(),
      message,
      n,
      period: period || DEFAULT_PERIOD,
      payload
    });
  } else if (type === "ERROR" || type === "UNKNOWN_ERROR") {
    next({
      type,
      id: id || generateGuid(),
      message,
      n,
      period: period || DEFAULT_ERROR_PERIOD,
      payload
    });
  } else {
    return next(action);
  }
};

export default messageMiddleware;
