import { createReducer } from "utils/reducer";
import { Types } from "actions/company-inventory";

const initialState = [];

const s3Inventory = createReducer(initialState, {
  [Types.SET_COMPANY_INVENTORY]: (state, action) => {
    return action.payload.inventory
  }
});

export default s3Inventory;