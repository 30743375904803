import React from "react";
import { useDispatch } from "react-redux";
import { signalrConnect, signalrDisconnect } from "./actions";

const SignalRConnection = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(signalrConnect());
    return () => {
      dispatch(signalrDisconnect());
    };
  }, []);
  return null;
};

export default SignalRConnection;
