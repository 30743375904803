import React from "react";
import PropTypes from "prop-types";
import PureModal from "components/ui/modal/pure-modal";
import MdSettings from "react-icons/lib/md/settings";

const TableSettings = ({
  visibleColumns,
  setVisibleColumns,
  className,
  renderLables
}) => (
  <PureModal
    renderTrigger={({ openModal }) => (
      <span className="icon-button" onClick={openModal}>
        <MdSettings />
      </span>
    )}
    renderContent={({ closeModal }) => (
      <div className={`table-settings-container ${className}`}>
        <h5 className="table-settings-header">Table Columns</h5>
        {Object.keys(visibleColumns).map(option => (
          <label key={option} className="table-settings-option">
            <input
              type="checkbox"
              checked={visibleColumns[option]}
              onChange={({ target }) =>
                setVisibleColumns({
                  ...visibleColumns,
                  [option]: target.checked
                })
              }
            />
            {{}.hasOwnProperty.call(renderLables, option) ? (
              renderLables[option]
            ) : (
              <span className="option-name">{option}</span>
            )}
          </label>
        ))}
        <button onClick={closeModal}>Close</button>
      </div>
    )}
  />
);

export default TableSettings;
TableSettings.propTypes = {
  visibleColumns: PropTypes.object.isRequired,
  setVisibleColumns: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string
    })
  ),
  className: PropTypes.string,
  renderLables: PropTypes.object
};

TableSettings.defaultProps = {
  className: "",
  renderLables: {}
};
