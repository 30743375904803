import React from "react";
import { PureModal } from "components/ui";
import AvailableCoachesModal from "./available-coaches-modal.jsx";
import PropTypes from "prop-types";

export default function MoveAppointment({ onSubmit, appointments }) {
    return (
    <div>
        <PureModal
            renderTrigger={({ openModal }) => (
                <div>
                    <span
                        onClick={openModal}
                        style={{fontSize: "20px", cursor: "pointer", color: "#0090bc", top:"6px"}}
                        title="Move appointments to another Coach"
                    >
                        MOVE
                    </span>
                </div>
            )}

            renderContent={
                ({ closeModal }) =>
                    <AvailableCoachesModal
                        onClose={() => closeModal()}
                        onSubmit={() => onSubmit() }
                        appointmentIDs={ appointments }
                    />
            }
        ></PureModal>
    </div>
    )
}

MoveAppointment.propTypes = {
    onSubmit: PropTypes.func,
    appointments: PropTypes.array
}