import React from "react";
import PropTypes from "prop-types";
import PureWindow from "components/ui/modal/pure-window";
import MfaPhoneNumber from "components/screens/my-profile/mfa-phone-number";

const PhoneNumberPrompt = ({ user }) => {
  const [isOpen, setIsOpen] = React.useState(!user.MFACellNumberVerified);
  if (!isOpen) return null;
  if (user.MFACellNumberVerified || !user.MFAAuthEnabled) return null;

  return (
    <PureWindow onXClick={() => setIsOpen(false)}>
      <MfaPhoneNumber user={user} />
    </PureWindow>
  );
};

PhoneNumberPrompt.propTypes = { user: PropTypes.object.isRequired };

export default PhoneNumberPrompt;
