import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { SumBlock } from "components/ui";

const UserOrderStatuses = ({ orderStatusInsights }) => (
  <React.Fragment>
    <div
      style={{
        marginTop: "2em",
        display: "grid",
        gridTemplateColumns: " 1fr 1fr 1fr 1fr",
        gridColumnGap: "1em",
        gridRowGap: "1em",
        textTransform: "capitalize"
      }}
    >
      {orderStatusInsights.statuses.map(({ count, status }) => (
        <React.Fragment key={status}>
          <SumBlock title={status} value={count || 0} />
        </React.Fragment>
      ))}
      <SumBlock title={"Total"} value={orderStatusInsights.total} />
    </div>
    <div style={{ marginTop: "1em", marginBottom: "1em" }}>
      <SumBlock
        title={"Estimated Revenue"}
        value={
          `$${orderStatusInsights.estimated_revenue.toFixed(2)}` || "$" + 0
        }
      />
    </div>
  </React.Fragment>
);

UserOrderStatuses.propTypes = {
  orderStatusInsights: PropTypes.shape({
    statuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    total: PropTypes.number.isRequired,
    estimated_revenue: PropTypes.number.isRequired
  }).isRequired
};

export default connect(state => ({
  orderStatusInsights: selectors.getUserOrderStatusInsights(state)
}))(UserOrderStatuses);
