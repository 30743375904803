import { Types } from "actions/app-version";
import { AppEnvironment, AppVersion } from "environment";
import { semanticVersionCompare } from "utils/misc";
import { all, put, takeLatest } from "redux-saga/effects";

function* watchCheckAppVersionLogin() {
  yield takeLatest(Types.CHECK_APP_VERSION_LOGIN, function* (action) {
    const { versions } = action;
    var latestAppVersion = [];
    if (versions) {
      try {
        latestAppVersion = versions.find(
          ({ environment }) => environment == AppEnvironment
        )?.version;
      } catch (err) {
        console.error(err);
        return;
      }
    } else return;
    try {
      const status = semanticVersionCompare(latestAppVersion, AppVersion);
      console.info({
        AppVersion,
        latestAppVersion,
        status,
        login: true
      });
      if (status) {
        console.info("Updating App Version");
        window.location.reload(true);
        return;
      }
      switch (status) {
        case "patch":
          yield put({
            type: Types.SET_NEW_APP_VERSIONS,
            payload: { ...latestAppVersion, status }
          });
          break;
        case "minor":
          yield put({
            type: Types.SET_NEW_APP_VERSIONS,
            payload: { ...latestAppVersion, status }
          });
          break;
        case "major":
          yield put({
            type: Types.SET_NEW_APP_VERSIONS,
            payload: { ...latestAppVersion, status }
          });
          // window.location.reload(true);
          break;
        default:
      }
    } catch (err) {
      console.error(err.message);
    }
  });
}

function* watchCheckAppVersionEvent() {
  yield takeLatest(Types.CHECK_APP_VERSION_EVENT, function* (action) {
    const { version, environment } = action;
    if (environment !== AppEnvironment) return;
    try {
      const status = semanticVersionCompare(version, AppVersion);
      console.info({
        AppVersion,
        latestAppVersion: version,
        status,
        event: true
      });
      switch (status) {
        case "patch":
          yield put({
            type: Types.SET_NEW_APP_VERSIONS,
            payload: { version, environment, status }
          });
          break;
        case "minor":
          yield put({
            type: Types.SET_NEW_APP_VERSIONS,
            payload: { version, environment, status }
          });
          break;
        case "major":
          yield put({
            type: Types.SET_NEW_APP_VERSIONS,
            payload: { version, environment, status }
          });
          // window.location.reload(true);
          break;
        default:
      }
    } catch (err) {
      console.error(err.message);
    }
  });
}

export default function* rootAppVersionSaga() {
  yield all([watchCheckAppVersionLogin(), watchCheckAppVersionEvent()]);
}
