import React from 'react';
import PropTypes from "prop-types";
import { getChatDetails, setAPIError, setTwilioError} from './../../../Redux/Actions/chat';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import Waiting from "./../../../../../components/ui/waiting";
import TwilioVideo from "./../../../components/TwilioProgrammableVideo/VideoChat";

class VideoChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReLoader: false,
            roomName: "", //be12bc326962417aa0518135922c8457
            twilioToken: "",
            chatInitiate: false,
            members: [] 
        };
    }

    static getDerivedStateFromProps(props) {
        if (props.RoomDetails && props.RoomDetails.roomName && props.RoomDetails.roomName.length > 0) {
            return {
                roomName: props.RoomDetails.roomName,
                members : props.RoomDetails.members
            }
        }

        return null
    }

    componentDidUpdate() {
        if (!this.state.chatInitiate && this.props.RoomDetails && this.props.RoomDetails.roomName && this.state.twilioToken) {
            console.log("Chat init");
            this.setState({
                chatInitiate : true
            });
        }
    }

    componentDidMount() {
        let authDetails = JSON.parse(window.localStorage.getItem('evergreen')) && JSON.parse(window.localStorage.getItem('evergreen'));
        if (authDetails && authDetails.twilio) {
            this.setState({
                identity: authDetails.first_name + " " + authDetails.last_name,
                twilioToken: authDetails.twilio.twilioToken
            });
        }

        console.log(this.props.match.params.id);
        let slot_id = "";
        if (this.props.match.params.id) {
            slot_id = this.props.match.params.id;
            this.props.setAPIError('');
            this.props.setTwilioError('');
            this.props.getChatDetails(slot_id, 2);
            window.addEventListener("beforeunload", this.leaveRoomIfJoined);
        } else {
            this.props.setAPIError('Facing issue to fetch details.');
        }
    }

    refreshPage =() => {
        this.props.setAPIError('');
        this.props.setTwilioError('');
        this.setState({
            chatInitiate : false,
            isReLoader : true
        },() => {
            setTimeout(() => {
                this.setState({
                    chatInitiate : false,
                    isReLoader : false
                });
            }, 2000);
        });
        //location.reload();
    }

    fetchError = (errorMessage) => {
        console.log(errorMessage);
        this.props.setTwilioError(errorMessage);
    }

    render() {
        return (
            <div>
                {(this.props.isLoader || this.state.isReLoader) && <Waiting />}
                {(this.props.isLoader || this.state.isReLoader) && <h2>Please wait to configured room..</h2>}
                {this.props.errorMessage && this.props.errorMessage.length > 0 && <h2 style={{color:"red"}}>{this.props.errorMessage}</h2>}
                {this.props.twilioErrorMessage.length === 0 && this.props.errorMessage.length === 0 && this.props.RoomDetails.VideoChatInitialize && this.state.chatInitiate && <TwilioVideo fetchError={this.fetchError} roomNm={this.state.roomName} twilioToken={this.state.twilioToken}  isRemoteVisible={true} members={this.state.members} />}
                {(!this.props.isLoader && !this.state.isReLoader) && this.props.twilioErrorMessage && this.props.twilioErrorMessage.length > 0 && <h2 style={{color:"red"}}>{this.props.twilioErrorMessage}</h2>}
                {(!this.props.isLoader && !this.state.isReLoader) && this.props.twilioErrorMessage && this.props.twilioErrorMessage.length > 0 && <button onClick={this.refreshPage}>Re-Join</button>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        RoomDetails: state.getIn(["newRootReducer"]).rdc_chat.VideoChatRoomDetails,
        errorMessage: state.getIn(["newRootReducer"]).rdc_chat.errorMessage,
        twilioErrorMessage: state.getIn(["newRootReducer"]).rdc_chat.twilioErrorMessage,
        isLoader: state.getIn(["newRootReducer"]).rdc_loader.isLoader
    };
};

export default connect(mapStateToProps, { getChatDetails , setAPIError, setTwilioError})(withRouter(VideoChat));

VideoChat.propTypes = {
  RoomDetails: PropTypes.object.isRequired,
  isLoader: PropTypes.bool,
  twilioErrorMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  setTwilioError: PropTypes.func,
  setAPIError: PropTypes.func,
  match: PropTypes.object,
  getChatDetails: PropTypes.func
}
