import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const TableButton = React.memo(({ children, onClick, title, disabled }) => {
  return (
    <i
      className={`table-button ${disabled ? "disabled" : ""}`}
      title={title}
      onClick={e => {
        if (!disabled) onClick(e);
      }}
    >
      {children}
    </i>
  );
});

TableButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool
};

export default TableButton;
