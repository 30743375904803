import React, { useEffect, useState, useRef } from "react";
import { PureModal, ToggleSwitch, DatePickerInput, Spinner, InlineWaiting, ConfirmationDialog } from "components/ui";
import moment from "moment";
import { useFetch } from "hooks";
import { put, get, post } from "utils/api";
import PatientSearchResults from "components/patient-search-form-for-modal/patient-search-results";
import { propOr } from "ramda";
import { MdAddCircle, MdRemoveCircle, MdPeople, MdSchedule, MdDescription, MdClose, MdAssignment, MdSearch } from "react-icons/lib/md";
import { useDispatch } from "react-redux";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { errorMessage, message } from 'actions/message';
import PropTypes from "prop-types";

export const CreateTimeSlotForCoach = ({ selectedCoach, onClose }) => {

    const dispatch = useDispatch()

    const [schedulerOptions, setSchedulerOptions] = React.useState({
        title: "",
        description: "",
        max_number_of_invitees: 0,
        chat_type: "Undefined",
        recurring: false,
        recurrenceEndDate: moment().add(30, "days").format("MM/DD/YYYY"),
        weekdays: [false, false, false, false, false, false, false], //Monday, Tuesday, Wednesday...
        appointment_data: {
            appointment_patient_ids: [],
            appointment_topic_id: ""
        }
    });

    const [tablePatients, setTablePatients] = useState([]);

    const { response: patients, fetchData, isFetching } = useFetch({
        apiFn: values => put("dme_portal/search_patients", values),
        defaultValue: [],
        transformResponse: ({ patients }) => patients,
        onError: (error) => dispatch(errorMessage(getApiErrorMessageCustomPathEnd(error, "message")))
    });

    const {
        response: topic_categories,
        fetchData: fetchTopicCategories,
        isFetchingTopicCategories
    } = useFetch({
        apiFn: () => get("dme_portal/appointment_topic_categories"),
        defaultValue: [],
        transformResponse: propOr([], "topic_categories"),
        onSuccess: (response) => {

            setSchedulerOptions({ ...schedulerOptions, appointment_data: { appointment_patient_ids: schedulerOptions.appointment_data.appointment_patient_ids, appointment_topic_id: response[0]?.topic_id ?? "" } })

        }
    });

    const createTimeSlot = async (
        start_time,
        end_time,
        coach_id,
        { title, description, max_number_of_invitees, service_ids, product_ids, chat_type, recurring, recurrenceEndDate, weekdays, appointment_data }
    ) => {
        try {
            const patients = appointment_data.appointment_patient_ids.map(({ user_id }) => { return user_id });
            const clean_appointment_data = { appointment_topic_id: appointment_data.appointment_topic_id, appointment_patient_ids: patients }
            const { success, message: responseMsg } = await post(
                "/dme_portal/slots",
                {
                    start_time: moment(start_time).format("L LT"),
                    end_time: moment(end_time).format("L LT"),
                    coach_id,
                    title,
                    description,
                    max_number_of_invitees,
                    service_ids,
                    product_ids,
                    chat_type,
                    recurring,
                    recurrenceEndDate,
                    weekdays,
                    appointment_data: clean_appointment_data
                }
            );
            if (success) {
                dispatch(message(responseMsg));
            }
            else {
                dispatch(errorMessage(responseMsg));
            }
            return true
        } catch (err) {
            dispatch(
                errorMessage(
                    err.response?.body?.message ?? "Failed to create time slot"
                )
            );
            return false
        }
    };

    useEffect(() => {
        fetchTopicCategories()
    }, [])

    useEffect(() => {
        setTablePatients(patients);
    }, [patients])

    const [toSearchPatientsWith, setToSearchPatientsWith] = React.useState({
        name: "",
        email: "",
        date_of_birth: "",
        account_number: "",
        feature: "scheduleChat",
    });

    const inputRef = useRef(null);
    const [isSaving, setIsSaving] = useState(false)

    const [newStartDate, setNewStartDate] = useState(moment().format("MM/DD/YYYY"))
    const [newEndDate, setNewEndDate] = useState(moment().format("MM/DD/YYYY"))
    const [newStartTime, setNewStartTime] = useState("12:00")
    const [newEndTime, setNewEndTime] = useState("12:15")

    const [modalStartTime, setModalStartTime] = React.useState(`${newStartDate} ${newStartTime}`)
    const [modalEndTime, setModalEndTime] = React.useState(`${newEndDate} ${newEndTime}`)

    const [isValidDateRange, setIsValidDateRange] = useState(true)

    const [validMaximumUsers, setValidMaximumUsers] = useState(true)

    const [aMomentIsSelectedInThePast, setAMomentIsSelectedInThePast] = useState(false)

    const [switchInvitePatients, setSwitchInvitePatients] = useState(false)

    const isBeforeNow = day => moment(day, "MM/DD/YYYY HH:mm").isBefore(moment().format("MM/DD/YYYY HH:mm"));

    const weekDayToggle = (day, value) => {
        let array = [...schedulerOptions.weekdays];
        array[day] = value;
        setSchedulerOptions({
            ...schedulerOptions,
            weekdays: array
        })
    }

    const weekday = (day) => {
        let weekInitial
        switch (day) {
            case 0:
                weekInitial = "M";
                break;
            case 1:
                weekInitial = "T";
                break;
            case 2:
                weekInitial = "W";
                break;
            case 3:
                weekInitial = "T";
                break;
            case 4:
                weekInitial = "F";
                break;
            case 5:
                weekInitial = "S";
                break;
            case 6:
                weekInitial = "S";
                break;
        }
        if (schedulerOptions.weekdays[day]) {
            return (<span className="week-day-toggle on" onClick={() => weekDayToggle(day, false)}>{weekInitial}</span>);
        }
        else {
            return (<span className="week-day-toggle off" onClick={() => weekDayToggle(day, true)}>{weekInitial}</span>);
        }
    }

    useEffect(() => {
        if (newStartDate && newStartTime) {
            setModalStartTime(`${newStartDate} ${newStartTime}`)
        }
        if (newEndDate && newEndTime) {
            setModalEndTime(`${newEndDate} ${newEndTime}`)
        }
    }, [newStartDate, newEndDate, newStartTime, newEndTime])

    useEffect(() => {
        if (isBeforeNow(modalStartTime) || isBeforeNow(modalEndTime)) {
            setAMomentIsSelectedInThePast(true)
        } else {
            setAMomentIsSelectedInThePast(false)
        }
    }, [modalStartTime, modalEndTime])

    const handleSave = async (closeModal) => {

        setIsSaving(true);
        let successfulCreation = await createTimeSlot(modalStartTime, modalEndTime, selectedCoach.value, schedulerOptions);

        if (successfulCreation) {
            closeModal();
            onClose();
        }
        else {
            setIsSaving(false);
        }
    }

    const cancelButton = (closeModal) => {
        closeModal();
    }

    function getHourString(hour, minute, format) {
        return moment({ hour: hour, minute: minute }).format(format)
    }

    function generateTimeOptions(isForStartTime = false) {
        let hours = []
        for (let hour = 6; hour < 22; hour++) {
            if (hour == 21) {
                if (!isForStartTime) {
                    hours.push(<option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>{getHourString(hour, 0, 'hh:mm a')}</option>);
                }
            } else if (hour == 6) {
                isForStartTime && hours.push(<option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>{getHourString(hour, 0, 'hh:mm a')}</option>);
                hours.push(<option key={`${hour + "15"}`} value={getHourString(hour, 15, "HH:mm")}>{getHourString(hour, 15, 'hh:mm a')}</option>);
                hours.push(<option key={`${hour + "30"}`} value={getHourString(hour, 30, "HH:mm")}>{getHourString(hour, 30, 'hh:mm a')}</option>);
                hours.push(<option key={`${hour + "45"}`} value={getHourString(hour, 45, "HH:mm")}>{getHourString(hour, 45, 'hh:mm a')}</option>);
            } else {
                hours.push(<option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>{getHourString(hour, 0, 'hh:mm a')}</option>);
                hours.push(<option key={`${hour + "15"}`} value={getHourString(hour, 15, "HH:mm")}>{getHourString(hour, 15, 'hh:mm a')}</option>);
                hours.push(<option key={`${hour + "30"}`} value={getHourString(hour, 30, "HH:mm")}>{getHourString(hour, 30, 'hh:mm a')}</option>);
                hours.push(<option key={`${hour + "45"}`} value={getHourString(hour, 45, "HH:mm")}>{getHourString(hour, 45, 'hh:mm a')}</option>);
            }
        }
        return hours
    }

    function handleNewStartTime(chosenTime) {
        const plus15 = moment(chosenTime, "HH:mm").add(15, "minutes").format("HH:mm");
        setNewStartTime(chosenTime)
        setNewEndTime(plus15)
        setIsValidDateRange(moment(`${newStartDate} ${chosenTime}`).isBefore(moment(`${newEndDate} ${plus15}`)))
    }

    function handleClickOnDateInput() {
        document.getElementById("date-of-birth").showPicker()
    }

    const handleSwitchInvitePatient = () => {
        if (schedulerOptions.appointment_data.appointment_topic_id == "" && !switchInvitePatients)
            setSchedulerOptions({ ...schedulerOptions, appointment_data: { appointment_patient_ids: schedulerOptions.appointment_data.appointment_patient_ids, appointment_topic_id: topic_categories[0]?.topic_id ?? "" } })
        if (switchInvitePatients && schedulerOptions.appointment_data.appointment_patient_ids.length > 0) {
            document.getElementById("clearPatientsSelectionModal").click();
        }
        else {
            setSwitchInvitePatients(!switchInvitePatients);
            setSchedulerOptions({ ...schedulerOptions, appointment_data: { appointment_patient_ids: schedulerOptions.appointment_data.appointment_patient_ids, appointment_topic_id: "" } })
        }
    }

    const resetPatientsData = (closeModal) => {
        setSchedulerOptions({
            ...schedulerOptions,
            appointment_data: {
                appointment_patient_ids: [],
                appointment_topic_id: ""
            },
            description: '',
            recurring: false
        });
        setSwitchInvitePatients(!switchInvitePatients);
        closeModal()
    }

    return (
        <div>
            <PureModal
                windowProps={{ closeable: false }}
                maxWidth="sm"
                fullWidth
                renderTrigger={({ openModal }) => (
                    <div style={{ alignItems: "end" }}> 
                        <button className="select-coach-for-appointment-button" onClick={() => openModal()} disabled={selectedCoach.value == "" || selectedCoach.value == null}>Confirm Coach</button>
                    </div>
                )}
                renderContent={({ closeModal }) => (
                    <div className="create-time-slot-modal-container" ref={inputRef}>
                        <div>
                            <h3 className="modal-title">{"Creating an appointment for " + selectedCoach.text}</h3>
                        </div>
                        <div className="title-container">
                            <div className="input-label-container">
                                <label htmlFor="title_input" className="input-label">Title</label>
                            </div>
                            <input
                                className="text-input-styles"
                                id="title_input"
                                type="text"
                                value={schedulerOptions.title}
                                onChange={({ target }) =>
                                    setSchedulerOptions({
                                        ...schedulerOptions,
                                        title: target.value
                                    })
                                }
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ padding: "5px" }}>
                                <MdSchedule size="20px" />
                            </div>
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex" }}>
                                    <DatePickerInput
                                        placeholder={"MM/DD/YYY"}
                                        value={newStartDate}
                                        handleDayChange={(e) => {
                                            setNewStartDate(moment(e).format("MM/DD/YYYY"))
                                            setIsValidDateRange(moment(`${moment(e).format("MM/DD/YYYY")} ${newStartTime}`).isBefore(moment(`${newEndDate} ${newEndTime}`)))
                                        }}
                                        fromDate={moment().toDate()}
                                    />
                                    <select
                                        style={{ width: "7rem" }}
                                        value={newStartTime}
                                        onChange={(e) => {
                                            handleNewStartTime(e.target.value)
                                        }}
                                    >
                                        {generateTimeOptions(true)}
                                    </select>
                                    <div className="recurrent-switch-container">
                                        <p className="recurrent-options-text">Recurring:</p>
                                        <div
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <ToggleSwitch
                                                onChange={(e) => {
                                                    setSchedulerOptions({ ...schedulerOptions, recurring: e })
                                                }}
                                                value={schedulerOptions.recurring}
                                                disabled={switchInvitePatients}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <DatePickerInput
                                        placeholder={"MM/DD/YYY"}
                                        value={newEndDate}
                                        handleDayChange={(e) => {
                                            setNewEndDate(moment(e).format("MM/DD/YYYY"))
                                            setIsValidDateRange(moment(`${newStartDate} ${newStartTime}`).isBefore(moment(`${moment(e).format("MM/DD/YYYY")} ${newEndTime}`)))
                                        }}
                                        fromDate={moment().toDate()}
                                    />
                                    <select
                                        style={{ width: "7rem" }}
                                        value={newEndTime}
                                        onChange={(e) => {
                                            setNewEndTime(e.target.value)
                                            setIsValidDateRange(moment(`${newStartDate} ${newStartTime}`).isBefore(moment(`${newEndDate} ${e.target.value}`)))
                                        }}
                                    >
                                        {generateTimeOptions()}
                                    </select>
                                    {schedulerOptions.recurring ?
                                        <div className="recurrent-options-container">
                                            <p className="recurrent-options-text">Repeat on:</p>
                                            <div className="recurrent-days-container">
                                                {weekday(0)}
                                                {weekday(1)}
                                                {weekday(2)}
                                                {weekday(3)}
                                                {weekday(4)}
                                                {weekday(5)}
                                                {weekday(6)}
                                            </div>
                                            <p className="recurrent-options-text">Ends on:</p>
                                            <DatePickerInput
                                                placeholder={"MM/DD/YYY"}
                                                value={schedulerOptions.recurrenceEndDate}
                                                fromDate={moment().add(1, "days").toDate()}
                                                toDate={moment().add(3, "months").toDate()}
                                                handleDayChange={(e) => {
                                                    setSchedulerOptions({ ...schedulerOptions, recurrenceEndDate: moment(e).format("MM/DD/YYYY") })
                                                }}
                                            />
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="second-icon-container">
                                <MdAssignment size="20px" />
                            </div>
                            <div className="chat-type-and-invites-container">
                                <div style={{ flex: "1", paddingRight: "20px" }}>
                                    <div className="input-label-container">
                                        <label htmlFor="chat_type_select" className="input-label">Chat Type</label>
                                    </div>
                                    <select
                                        className="text-input-styles"
                                        id="chat_type_select"
                                        value={schedulerOptions.chat_type}
                                        onChange={({ target }) => {
                                            setSchedulerOptions({
                                                ...schedulerOptions,
                                                chat_type: target.value,
                                                max_number_of_invitees: target.value == "Product_Demo" ? 10 : target.value == "Undefined" ? 0 : 1
                                            })
                                        }
                                        }
                                        disabled={switchInvitePatients}
                                    >
                                        {switchInvitePatients ? null : <option value="Undefined">Choose Chat Type</option>}
                                        <option value="Text_Chat">Text Only Chat</option>
                                        <option value="Video_Chat">Video Chat</option>
                                        <option value="Product_Demo">Product Demo</option>
                                    </select>
                                </div>
                                <div style={{ flex: "1", paddingLeft: "20px" }}>
                                    <div className="input-label-container">
                                        <label htmlFor="maximum_users" className="input-label">Max Invites</label>
                                    </div>
                                    <input
                                        className="text-input-styles"
                                        style={(schedulerOptions.chat_type != "Product_Demo" || switchInvitePatients) ? { color: "#d1c9c9" } : undefined}
                                        type="number"
                                        id="maximum_users"
                                        value={schedulerOptions.max_number_of_invitees}
                                        onChange={({ target }) => {
                                            setSchedulerOptions({
                                                ...schedulerOptions,
                                                max_number_of_invitees: target.value
                                            })
                                            if (target.value > 10) {
                                                setValidMaximumUsers(false)
                                            } else {
                                                setValidMaximumUsers(true)
                                            }
                                        }}
                                        disabled={schedulerOptions.chat_type != "Product_Demo" || switchInvitePatients}
                                    />
                                    {
                                        schedulerOptions.max_number_of_invitees > 10 ?
                                            <label htmlFor="maximum_users" style={{ color: "#ff0000" }}>Only 10 patients or less are allowed</label>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="invite-patients-button-container">
                            <button
                                type="button"
                                onClick={() => handleSwitchInvitePatient()}
                                disabled={schedulerOptions.recurring || schedulerOptions.chat_type == "Undefined" || !schedulerOptions.chat_type}
                                className="invite-patients-button"
                            >
                                {switchInvitePatients ? <MdRemoveCircle size="20px" /> : <MdAddCircle size="20px" />}
                                Invite Patient/s
                            </button>

                        </div>
                        {
                            switchInvitePatients ?
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <div className="third-icon-container" style={{ marginRight: "20px" }}>
                                            <MdPeople size="20px" />
                                        </div>
                                        <div>
                                            <div style={{ marginBottom: "10px" }}>
                                                <p className="input-label">Assigned patients</p>
                                                <div className="selected-patients-container">
                                                    {
                                                        schedulerOptions.appointment_data.appointment_patient_ids.length > 0 &&
                                                        schedulerOptions.appointment_data.appointment_patient_ids.map((element) => {
                                                            return (
                                                                <div
                                                                    key={element.user_id}
                                                                    className="invited-patients"
                                                                    onClick={() => {
                                                                        setSchedulerOptions({ ...schedulerOptions, appointment_data: { appointment_patient_ids: schedulerOptions.appointment_data.appointment_patient_ids.filter(patient => patient.user_id !== element.user_id) } })
                                                                    }}
                                                                >
                                                                    <p style={{ margin: "0px", marginRight: "5px" }}>{element.patient_name}</p>
                                                                    <MdClose size="15px" />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="patient-search-inputs-container">
                                                <div>
                                                    <label htmlFor="name" className="input-label">Name or email</label>
                                                    <input name="name" label="Name" type="text" id="name" style={{ width: "250px" }} onChange={(e) => setToSearchPatientsWith({ ...toSearchPatientsWith, name: e.target.value })} />
                                                </div>
                                                <div>
                                                    <label htmlFor="maximum_users" className="input-label">Account Number</label>
                                                    <input name="account_number" label="Account Number" type="text" id="account_number" onChange={(e) => setToSearchPatientsWith({ ...toSearchPatientsWith, account_number: e.target.value })} />
                                                </div>
                                                <div>
                                                    <label htmlFor="date-of-birth" className="input-label">Date of birth</label>
                                                    <div onMouseDown={handleClickOnDateInput}>
                                                        <input type="date" id="date-of-birth" onChange={({ target }) => setToSearchPatientsWith({ ...toSearchPatientsWith, date_of_birth: target.value })} />
                                                    </div>
                                                </div>
                                                <button disabled={toSearchPatientsWith.name.length < 4 && toSearchPatientsWith.account_number.length < 4} onClick={async () => await fetchData(toSearchPatientsWith)} type="button" className="patient-search-button">
                                                    <MdSearch size="20px" />
                                                </button>
                                            </div>
                                            <PatientSearchResults
                                                patients={tablePatients}
                                                isFetching={isFetching}
                                                selectedPatients={schedulerOptions.appointment_data.appointment_patient_ids}
                                                setSelectedPatients={patient_id => {
                                                    setSchedulerOptions({
                                                        ...schedulerOptions,
                                                        appointment_data: {
                                                            appointment_patient_ids: patient_id,
                                                            appointment_topic_id: schedulerOptions.appointment_data.appointment_topic_id

                                                        }
                                                    });
                                                }}
                                                feature="scheduleChat"
                                                multiple={true}
                                                maxInvitees={schedulerOptions.max_number_of_invitees}

                                            />
                                        </div>
                                    </div>
                                    {isFetchingTopicCategories ? (
                                        <InlineWaiting />
                                    ) : (
                                        <div className="appointment-topic-container">
                                            <div className="third-icon-container">
                                                <MdAssignment size="20px" />
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <label htmlFor="appointment_topic" className="input-label">
                                                    Appointment Topic
                                                </label>
                                                <select
                                                    id="appointment_topic"
                                                    value={schedulerOptions.appointment_data.appointment_topic_id}
                                                    onChange={({ target }) => {
                                                        setSchedulerOptions(schedulerOptions => ({
                                                            ...schedulerOptions,
                                                            appointment_data: { appointment_patient_ids: schedulerOptions.appointment_data.appointment_patient_ids, appointment_topic_id: target.value }
                                                        }))
                                                    }}
                                                >
                                                    <option value="">Choose appointment topic</option>
                                                    {topic_categories.map(({ topic_id, topic_name }) => (
                                                        <option value={topic_id} key={topic_id}>
                                                            {topic_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )
                                    }
                                    <div style={{ display: "flex" }}>
                                        <div className="third-icon-container">
                                            <MdDescription size="20px" />
                                        </div>
                                        <div style={{ flex: "1" }}>
                                            <div className="input-label-container">
                                                <label htmlFor="description" className="input-label">Description</label>
                                            </div>
                                            <textarea
                                                id="description"
                                                value={schedulerOptions.description}
                                                onChange={({ target }) => setSchedulerOptions({ ...schedulerOptions, description: target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className="action-buttons-container">
                            <button
                                className="warning create-timeslot-button"
                                onClick={() => { cancelButton(closeModal) }}
                            >
                                {"Cancel"}
                            </button>
                            <button
                                className="create-timeslot-button"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {

                                    handleSave(closeModal, false)
                                }
                                }
                                disabled={!validMaximumUsers || !isValidDateRange || aMomentIsSelectedInThePast || (schedulerOptions.appointment_data.appointment_patient_ids.length == 0 && switchInvitePatients)}
                            >
                                Save
                            </button>
                        </div>
                        {
                            !isValidDateRange ?
                                <p className="warning-text">Start date must be set before end date</p>
                                :
                                null
                        }
                        {
                            aMomentIsSelectedInThePast ?
                                <p className="warning-text">You can´t create a schedule in the past</p>
                                :
                                null
                        }
                        {
                            isSaving ?
                                <div className="modal-spinner-container" style={{ height: "100%", width: "100%" }}>
                                    <Spinner />
                                </div>
                                :
                                null
                        }
                    </div>)
                }
            />
            <PureModal
                maxWidth="sm"
                fullWidth
                renderTrigger={({ openModal }) => (
                    <button
                        id="createAppointmentWithoutPatients"
                        className="appointment-action-button cancel-appointment-button"
                        style={{ display: "none" }}
                        onClick={() => { openModal() }}
                    >
                    </button>
                )
                }
                renderContent={({ closeModal }) => (
                    <ConfirmationDialog
                        cancelButtonStyles="appointment-action-button cancel-action-button"
                        confirmButtonStyles="appointment-action-button reschedule-button"
                        continueMsg="Yes"
                        cancelMsg="No"
                        onCancel={() => { closeModal() }}
                        onConfirm={() => handleSave(closeModal, true)}>
                        <h3 className="edit-timeslot-modal-title">Are you sure you don’t want to invite Patients to the schedule?</h3>
                    </ConfirmationDialog>
                )}
            />
            <PureModal
                maxWidth="sm"
                fullWidth
                renderTrigger={({ openModal }) => (
                    <button
                        id="clearPatientsSelectionModal"
                        className="appointment-action-button cancel-appointment-button"
                        style={{ display: "none" }}
                        onClick={() => { openModal() }}
                    >
                    </button>
                )
                }
                renderContent={({ closeModal }) => (
                    <ConfirmationDialog
                        cancelButtonStyles="appointment-action-button cancel-action-button"
                        confirmButtonStyles="appointment-action-button reschedule-button"
                        continueMsg="Yes"
                        cancelMsg="No"
                        onCancel={() => { closeModal() }}
                        onConfirm={() => resetPatientsData(closeModal)}>
                        <h3 className="edit-timeslot-modal-title">Are you sure you want to abandon your changes?</h3>
                    </ConfirmationDialog>
                )}
            />
        </div>
    );
};

CreateTimeSlotForCoach.propTypes = {
    selectedCoach: PropTypes.shape({ 
        text: PropTypes.string, 
        value: PropTypes.string }),
    onClose: PropTypes.func
};

