import React, { useEffect, useContext }  from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import ChangeLogTableRow from "../../inventory/inventory-profile/change-log-table-row";
import { FulfillmentInventoryContext } from "../fulfillment-inventory-provider";
import { InlineWaiting } from "components/ui";

const ChangeLogTable = ({
    fulfillmentId,
    inventoryId
}) => {
    const {
        fulfillmentInventoryItemtemChangeLogList,
        getFulfillmentInventoryItemtemChangeLogList,
        isFetchingFulfillmentInventoryItemtemChangeLogList
    } = useContext(FulfillmentInventoryContext);
    useEffect(() => {
        getFulfillmentInventoryItemtemChangeLogList({ fulfillmentId, inventoryId });
    }, []);

  return (
    (isFetchingFulfillmentInventoryItemtemChangeLogList) ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <InlineWaiting reason="Fetching change logs" />
      </div>
    ) : (
      
      <div className="table">
        <InventoryItemsTable
          changeLogs={fulfillmentInventoryItemtemChangeLogList ?? []}
        />
      </div>
    )
  );
};

ChangeLogTable.propTypes = {
    fulfillmentId: PropTypes.string.isRequired,
    inventoryId: PropTypes.string.isRequired
};

export default ChangeLogTable;

const InventoryItemsTable = ({
    changeLogs
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(changeLogs, {
    sort: { by: "name", direction: "asc" },
    filters: {},
  });
  return (
      <Table
        TableRow={ChangeLogTableRow}
        containerClass="query-table inventory-table"
        columns={[
            {
              header: "Changed By",
              field: "changed_by",
            },
            {
              header: "Column Changed",
              field: "column_changed",
            },
            {
              header: "Old Value",
              field: "old_value",
            },
            {
              header: "New Value",
              field: "new_value",
            },
            {
              header: "Date Changed",
              field: "date_changed",
            },
            {
                header: "Origin",
                field: "origin",
              }
          ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
        />
  );
};

InventoryItemsTable.propTypes = {
  changeLogs: PropTypes.arrayOf(PropTypes.object).isRequired
};
