import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePatient, deleteSpecialInstruction } from "actions/patient";
import { selectors } from "reducers";
import IoMdClose from "react-icons/lib/io/close";

const SpecialInstructions = ({
  special_instructions,
  patientId,
  updatePatient,
  deleteSpecialInstruction
}) => {
  const [newInstructionInput, setNewInstructionInput] = React.useState("");
  function handleSumbit(e) {
    e.preventDefault();
    updatePatient({
      patientId: patientId,
      updates: { special_instructions: newInstructionInput }
    }).then(() => setNewInstructionInput(""));
    //If we end up having conflicts with someone trying to add
    //and delete special instructions quickly right after each other,
    //maybe add the makeFetchCreator call instead so that we can make use of inline waiting spinner
  }

  async function deleteSpecialInstructionHandler(guid) {
    const confirm = global.confirm(
      "This will remove the special instructions for this patient. Do you want to continue?"
    );

    if (confirm) {
      deleteSpecialInstruction({
        guid: guid,
        patientId: patientId
      });
    }
  }

  return (
    <div>
      <h3>Previous Instructions</h3>
      <hr />
      <div style={{ paddingLeft: 15 }}>
        {special_instructions.map((instruction, i) => (
          <React.Fragment key={instruction.value}>
            <p key={instruction.value}>
              <span
                style={{
                  color: "red",
                  position: "relative",
                  cursor: "pointer"
                }}
                onClick={() => {
                  deleteSpecialInstructionHandler(instruction.value);
                }}
                title="Remove"
              >
                <i
                  style={{
                    color: "#ff0000",
                    cursor: "pointer",
                    fontSize: "1.5em"
                  }}
                >
                  <IoMdClose />
                </i>
              </span>{" "}
              {i + 1 + ". "}
              {instruction.text}
            </p>
          </React.Fragment>
        ))}
      </div>
      <form onSubmit={handleSumbit}>
        <div className="row">
          <div className="small-12 columns">
            <label>
              Instructions
              <input
                type="text"
                name="newInstructionInput"
                value={newInstructionInput}
                onChange={({ target }) => setNewInstructionInput(target.value)}
              />
            </label>
            <div
              style={{
                textAlign: "right"
              }}
            >
              <button disabled={!(newInstructionInput.length > 0)}>
                Add Instruction
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

SpecialInstructions.propTypes = {
  patientId: PropTypes.string.isRequired,
  updatePatient: PropTypes.func.isRequired,
  closeSelf: PropTypes.func.isRequired,
  special_instructions: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteSpecialInstruction: PropTypes.func.isRequired
};

export default connect(
  (state, { patientId }) => ({
    special_instructions: selectors.getPatientSpecialInstructions(
      state,
      patientId
    )
  }),
  {
    deleteSpecialInstruction,
    updatePatient
  }
)(SpecialInstructions);
