import {
  take,
  select,
  call,
  fork,
  cancel,
  put,
  takeLatest
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { selectors } from "reducers";
import { Types } from "actions/twilio-chat";
import TwilioChat from "twilio-chat";
import { Types as AuthTypes } from "actions/authentication";
import { isLogoutResponse, isUnauthorizedResponse } from "sagas/authentication";

export default function* rootTwilioChatSaga() {
  while (true) {
    yield take(Types.INITIALIZE_TWILIO_CHAT_CLIENT);
    try {
      const chatClient = yield createTwilioChatClient();
      yield put({ type: Types.TWILIO_CHAT_READY });
      const watchStartChatTask = yield fork(watchStartChat, chatClient);
      yield take([
        AuthTypes.LOGOUT_USER_INACTIVE,
        AuthTypes.LOGIN_USER_RESPONSE,
        isLogoutResponse,
        isUnauthorizedResponse
      ]);

      if (watchStartChatTask) yield cancel(watchStartChatTask);
    } catch (error) {
      console.error(error);
    }
  }
}

function* createTwilioChatClient() {
  const twilioToken = yield select(state => selectors.getTwilioToken(state));
  const client = yield TwilioChat.create(twilioToken);
  return client;
}

function* watchStartChat(chatClient) {
  yield takeLatest(Types.START_TWILIO_CHAT, function* (action) {
    const { payload: channelSid } = action;
    yield startTwilioChat(channelSid, chatClient);
  });
}

function* startTwilioChat(channelSid, chatClient) {
  try {
    yield put({
      type: Types.TWILIO_CHAT_MESSAGE_ADDED,
      payload: { body: "Connecting to Chat Room" }
    });
    console.log({ chatClient, channelSid });
    const channel = yield getTwilioChatChannel(chatClient, channelSid);
    if (!channel) {
      yield put({
        type: Types.TWILIO_CHAT_ERROR,
        error: "Could not connect to chat room"
      });
      throw Error("could not get chat channel");
    }
    if (channel.channelState.status !== "joined") {
      yield channel.join();
    }
    yield put({ type: Types.TWILIO_CHAT_STARTED });
    yield put({
      type: Types.TWILIO_CHAT_MESSAGE_ADDED,
      payload: { body: "Connected To Chat Room" }
    });
    yield call(getChatHistory, channel);

    const handlePageLeave = () => {
      channel.leave();
    };
    window.addEventListener("beforeunload", handlePageLeave);
    const channelEventsTask = yield fork(handleChatChannelEvents, channel);
    yield take([
      Types.CLOSE_TWILIO_CHAT,
      Types.START_TWILIO_CHAT,
      Types.END_TWILIO_CHAT
    ]);
    yield channel.leave();
    if (channelEventsTask) yield cancel(channelEventsTask);
    yield put({ type: Types.TWILIO_CHAT_ENDED });
    window.removeEventListener("beforeunload", handlePageLeave);
  } catch (error) {
    console.error(error);
    yield put({ type: Types.TWILIO_CHAT_ERROR, error });
    yield put({ type: Types.TWILIO_CHAT_ENDED, error });
  }
}

async function getTwilioChatChannel(chatClient, channelSid) {
  try {
    const channel = await chatClient
      .getChannelBySid(channelSid)
      .catch(error => {
        console.error(error);
      });
    if (channel) return channel;
    // else {
    //   return await chatClient.createChannel({
    //     uniqueName: channelSid
    //   });
    // }
  } catch (error) {
    console.error("getTwilioChatChannel ERROR:", error);
  }
}

function* getChatHistory(channel) {
  const { items = [] } = yield channel.getMessages();
  const restoredMessages = items.map(message => ({ ...message.state }));
  yield put({
    type: Types.TWILIO_CHAT_RESTORE_MESSAGES,
    payload: restoredMessages
  });
}
const chatEventChannel = channel =>
  eventChannel(emit => {
    channel.on("messageAdded", message => {
      console.log(message)
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { ...message.state }
      });
    });

    channel.on("memberJoined", () => {
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { body: "User Joined Chat" }
      });
    });

    channel.on("memberLeft", () => {
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { body: "User Left Chat" }
      });
    });
    channel.on("removed", () => {
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { body: "Chat Ended" }
      });
      emit({
        type: Types.END_TWILIO_CHAT
      });
    });
    return () => {};
  });

function* handleChatChannelEvents(channel) {
  const userName = yield select(state => selectors.getUserName(state));
  yield fork(listenSendMessage, channel, userName);
  const channelActions = yield call(chatEventChannel, channel);
  while (true) {
    const action = yield take(channelActions);
    yield put(action);
  }
}

function* listenSendMessage(channel, userName) {
  while (true) {
    const { payload } = yield take(Types.TWILIO_SEND_MESSAGE);
    try {
      yield channel.sendMessage(payload, {
        authorName: userName
      });
    } catch (error) {
      console.error(error);
    }
  }
}
