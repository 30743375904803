import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InlineWaiting } from "components/ui";
import PatientSearchForm from "components/patient-search-form";
import { addPatientToTemplate } from "components/dd_newModules/Redux/Actions/pdftemplate";

const AssignPdfs = ({ PDFTemplates, addPatientToTemplate }) => {
  const [selectedPatients, setSelectedPatients] = React.useState([]);
  const [selectedPdfTemplate, setSelectedPdfTemplate] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <div style={{ display: "grid", gridTemplateRows: "auto 1fr auto" }}>
      <PatientSearchForm
        selectedPatients={selectedPatients}
        setSelectedPatients={setSelectedPatients}
        feature="signatures"
      />
      <div style={{ marginTop: 20 }}>
        <h4>Select PDF Signature Template</h4>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            gridGap: 20
          }}
        >
          <select
            value={selectedPdfTemplate}
            onChange={({ target }) => {
              setSelectedPdfTemplate(target.value);
            }}
          >
            <option />
            {PDFTemplates.map(({ id, document_title }) => (
              <option value={id} key={id}>
                {document_title}
              </option>
            ))}
          </select>
          {isLoading ? (
            <InlineWaiting />
          ) : (
            <button
              disabled={selectedPatients.length == 0 || !selectedPdfTemplate}
              onClick={async () => {
                try {
                  setIsLoading(true);
                  await addPatientToTemplate(
                    selectedPdfTemplate,
                    selectedPatients
                  );
                } finally {
                  setIsLoading(false);
                  setSelectedPatients([]);
                }
              }}
            >
              Assign Patients
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

AssignPdfs.propTypes = {
  PDFTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPatientToTemplate: PropTypes.func.isRequired
};

export default connect(
  state => ({
    PDFTemplates: state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplate
  }),
  { addPatientToTemplate }
)(AssignPdfs);
