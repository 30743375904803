import React from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { FULFILLMENT } from "utils/enum";
import { compose, withProps, withHandlers, defaultProps } from "recompose";
import * as R from "ramda";

const BaseSelect = withHandlers({
  handleChange:
    ({ options, input: { onChange } }) =>
    event => {
      if (onChange) {
        const {
          target: { value }
        } = event;
        const option = options.find(x => x.value == value);
        onChange(event, option);
      }
    }
})(({ options, handleChange, input: { value } }) => (
  <select onChange={handleChange} value={value}>
    <option value="" />
    {options.map(({ text, value }) => (
      <option key={value} value={value}>
        {text}
      </option>
    ))}
  </select>
));

export const FulfillmentSelect = withProps({
  options: FULFILLMENT
})(BaseSelect);

export const AdminSelect = connect(
  state => ({
    options: selectors.getEnumAdmins(state)
  }),
  {}
)(BaseSelect);

export const CoachSelect = connect(
  state => ({
    options: selectors.getFormOptionsSleepCoach(state)
  }),
  {}
)(BaseSelect);

export const OrderStatusSelect = compose(
  defaultProps({
    updateOptionsOnly: false
  }),
  connect(
    state => ({
      allOptions: selectors.getEnumOrderStatuses(state),
      rejectedStatusId: selectors.getOrderStatusId(state, "Rejected"),
      orderRejectedReasons: selectors.getEnumOrderRejectedReasons(state)
    }),
    {},
    (
      { allOptions, orderRejectedReasons, rejectedStatusId },
      _,
      { updateOptionsOnly, ...passThroughProps }
    ) => ({
      orderRejectedReasons,
      rejectedStatusId,
      statusUpdateOptions: R.compose(
        updateOptionsOnly ? R.filter(x => x.isUpdateOption) : R.identity
      )(allOptions),
      ...passThroughProps
    })
  ),
  withHandlers({
    handleStatusChange:
      ({ input: { onChange } }) =>
      ({ target }) => {
        onChange({ status: target.value, rejected_reason: "" });
      },
    handleReasonChange:
      ({
        input: {
          value: { status },
          onChange
        }
      }) =>
      ({ target: { value } }) => {
        onChange({ status, rejected_reason: value });
      }
  })
)(
  ({
    input: { value },
    handleStatusChange,
    handleReasonChange,
    statusUpdateOptions,
    rejectedStatusId,
    orderRejectedReasons
  }) => (
    <div>
      <select
        onChange={handleStatusChange}
        value={value.status}
        style={{ marginBottom: 5 }}
      >
        <option />
        {statusUpdateOptions
          .filter(({ text }) => text !== "Voided")
          .map(({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
      </select>
      {value.status === rejectedStatusId && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ padding: 5, width: 150 }}>Rejected Reason</label>
          <select value={value.rejected_reason} onChange={handleReasonChange}>
            <option />
            {orderRejectedReasons.map(({ text, value }) => (
              <option value={value} key={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
);
