import React from "react";
import PropTypes from "prop-types";

const ListItem = ({ label, value, className, underline, subitem, style, valueSpanStyles }) => (
  <div
    className={`list-item ${className}`}
    style={{
      marginLeft: !subitem ? 0 : 25,
      borderBottom: underline ? "1px solid #dfdfdf" : "",
      ...style
    }}
  >
    {!subitem ? (
      <strong className="item-value">{label}</strong>
    ) : (
      <span className="item-value">{label}</span>
    )}
    <span className="item-value" style={valueSpanStyles}>{value}</span>
  </div>
);

ListItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  style: PropTypes.object,
  className: PropTypes.string, // extend styles
  subitem: PropTypes.bool,
  underline: PropTypes.bool,
  valueSpanStyles: PropTypes.object
};

ListItem.defaultProps = {
  className: "",
  underline: false,
  style: {}
};

export default ListItem;
