import { combineReducers } from "redux";
import * as R from "ramda";
import { Types } from "actions/batch";
import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";

const actionArg = R.nthArg(1);

const makeIsEnabled = setEnabledType =>
  createReducer(false, {
    [setEnabledType]: R.compose(
      Boolean,
      R.path(["payload", "isEnabled"]),
      actionArg
    )
  });

const makeIdsReducer = setIdsType =>
  createReducer([], {
    [setIdsType]: R.compose(R.path(["payload", "ids"]), actionArg)
  });

const makeTitleReducer = setIdsType =>
  createReducer([], {
    [setIdsType]: R.compose(R.pathOr([], ["payload", "titles"]), actionArg)
  });

const makeBatchReducer = (setEnabledType, setIdsType) =>
  combineReducers({
    isEnabled: makeIsEnabled(setEnabledType),
    ids: makeIdsReducer(setIdsType),
    titles: makeTitleReducer(setIdsType)
  });

const patientBatch = makeBatchReducer(
  Types.SET_PATIENT_BATCH_ENABLED,
  Types.SET_PATIENT_BATCH_IDS
);

const orderBatch = makeBatchReducer(
  Types.SET_ORDER_BATCH_ENABLED,
  Types.SET_ORDER_BATCH_IDS
);

const vmailBatch = makeBatchReducer(
  Types.SET_VMAIL_BATCH_ENABLED,
  Types.SET_VMAIL_BATCH_IDS
);

const rootBatchReducer = combineReducers({
  patientBatch,
  orderBatch,
  vmailBatch
});

export default rootBatchReducer;

const getPatientBatchIsEnabled = createSelector(
  [state => state.get("batch")],
  R.path(["patientBatch", "isEnabled"])
);
const getPatientBatchIds = createSelector(
  [state => state.get("batch")],
  R.path(["patientBatch", "ids"])
);
const getPatientBatchTitles = createSelector(
  [state => state.get("batch")],
  R.path(["patientBatch", "titles"])
);

const getOrderBatchIsEnabled = createSelector(
  [state => state.get("batch")],
  R.path(["orderBatch", "isEnabled"])
);
const getOrderBatchIds = createSelector(
  [state => state.get("batch")],
  R.path(["orderBatch", "ids"])
);
const getOrderBatchTitles = createSelector(
  [state => state.get("batch")],
  R.path(["orderBatch", "titles"])
);

const vmailBatchIsEnabled = createSelector(
  [state => state.get("batch")],
  R.path(["vmailBatch", "isEnabled"])
);
const getVmailBatchIds = createSelector(
  [state => state.get("batch")],
  R.path(["vmailBatch", "ids"])
);
const getVmailBatchTitles = createSelector(
  [state => state.get("batch")],
  R.path(["vmailBatch", "titles"])
);

export const selectors = {
  getPatientBatchIsEnabled,
  getPatientBatchIds,
  getPatientBatchTitles,

  getOrderBatchIsEnabled,
  getOrderBatchIds,
  getOrderBatchTitles,

  vmailBatchIsEnabled,
  getVmailBatchIds,
  getVmailBatchTitles
};
