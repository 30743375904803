import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { automatePatientContact } from "actions/sleep-coach-dashboard";
// import IoIosPaperPlane from "react-icons/lib/io/paper-airplane";
import IoMdPerson from "react-icons/lib/io/person";
import { createTab } from "actions/system-tray";
import { setPatientBatchIds } from "actions/batch";
import { getPatientsSnapshot } from "action-creators";
import { message, errorMessage } from "actions/message";
// import chunk from "lodash/chunk";
import AutodialerBtn from "../autodialer-btn";

// const AUTOMATION_BATCH_SIZE = 25;

const PatientTableControls = ({
  handleRefresh,
  // automatePatientContact,
  setPatientBatchIds,
  createTab,
  // rows=[],
  // message,
  // errorMessage,
  // userId,
  // getPatientsSnapshot,
  selectedPatients,
  setSelectedPatients,
  batchClear
}) => {
  // const [isFetchingAutomation, setIsFetchingAutomation] = useState(false);
  return (
    <>
      <span className="action-buttons-sleepcoach-dashboard">
        <span
          className="icon-button-sleepcoach-dashboard"
          onClick={() => {
            if (selectedPatients.length) {
              setPatientBatchIds(selectedPatients);
              createTab({
                type: "patients",
                key: "patients",
                label: `Patients (${selectedPatients.length})`,
                meta: { ids: selectedPatients }
              });
            }
            setSelectedPatients([]);
            handleRefresh();
            batchClear();
          }}
        >
          <IoMdPerson />
        </span>
        {/* <span */}
        {/*   className="icon-button-sleepcoach-dashboard" */}
        {/*   onClick={async () => { */}
        {/*     if (selectedPatients.length && !isFetchingAutomation) { */}
        {/*       try { */}
        {/*         setIsFetchingAutomation(true); */}
        {/*         message( */}
        {/*           `Automating patient contact in groups of ${AUTOMATION_BATCH_SIZE}...` */}
        {/*         ); */}
        {/*         const batches = chunk(selectedPatients, AUTOMATION_BATCH_SIZE); */}
        {/*         for (let batch of batches) { */}
        {/*           try { */}
        {/*             const { */}
        {/*               response: { failure } */}
        {/*             } = await automatePatientContact(batch); */}
        {/*             if (failure.length) { */}
        {/*               const names = failure */}
        {/*                 .map(id => { */}
        {/*                   const { first_name, last_name } = rows.find( */}
        {/*                     p => p.id === id */}
        {/*                   ); */}
        {/*                   return `${first_name} ${last_name}`; */}
        {/*                 }) */}
        {/*                 .join(", "); */}
        {/*               errorMessage( */}
        {/*                 `Unfortunately, the system was unable to send automation to the following selected patients: ${names}. Please follow up with these patients manually.` */}
        {/*               ); */}
        {/*             } */}
        {/*             const successCount = batch.length - failure.length; */}
        {/*             if (successCount) { */}
        {/*               message(`${successCount} patients contacted!`); */}
        {/*             } */}
        {/*           } catch (error) { */}
        {/*             errorMessage( */}
        {/*               "Something went wrong while contacting patients!" */}
        {/*             ); */}
        {/*           } */}
        {/*         } */}
        {/*         message("AUTOMATION COMPLETE!"); */}
        {/*         setSelectedPatients([]); */}
        {/*         handleRefresh(); */}
        {/*         batchClear(); */}
        {/*       } finally { */}
        {/*         setIsFetchingAutomation(false); */}
        {/*         getPatientsSnapshot(userId); */}
        {/*       } */}
        {/*     } */}
        {/*   }} */}
        {/* > */}
        {/*   <IoIosPaperPlane /> */}
        {/* </span> */}
        <AutodialerBtn
          selectedPatients={selectedPatients}
          setSelectedPatients={setSelectedPatients}
          refreshData={handleRefresh}
          batchClear={batchClear}
        />
      </span>
      </>
  );
};

export default connect(
  state => ({
    userId: selectors.getUserId(state)
  }),
  {
    createTab,
    setPatientBatchIds,
    automatePatientContact,
    message,
    errorMessage,
    getPatientsSnapshot
  }
)(PatientTableControls);

PatientTableControls.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  automatePatientContact: PropTypes.func.isRequired,
  setPatientBatchIds: PropTypes.func.isRequired,
  createTab: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  getPatientsSnapshot: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  batchClear: PropTypes.func.isRequired,
  selectedPatients: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedPatients: PropTypes.func.isRequired,
};
