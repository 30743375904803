import React from "react";
import PropTypes from "prop-types";

const StatBlock = ({ title, value, style }) => (
  <div className="stat-block" style={style}>
    <span className="stat-block-title">{title}</span>
    <span className="stat-block-value">{value && value.toLocaleString()}</span>
  </div>
);

export default StatBlock;

StatBlock.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object
};

StatBlock.defaultProps = { style: {} };
