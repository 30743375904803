import React, { useEffect } from "react";
import { post, get } from "utils/api";
import { useDispatch } from "react-redux";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";

function getRaLink(
  ui_link,
  accessKey,
  refreshToken,
  orderPk,
  { internalPatientId }
) {
  const link = `${ui_link}?accessKey=${accessKey}&refreshToken=${refreshToken}&patientId=${internalPatientId}&extOrderId=${orderPk}`;
  return link;
}

const useRaEligibility = ({ orderId, setRaLink }) => {
  const [insVerifications, setInsVerifications] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getInsuranceVerifications({ orderId, pullData: true });
  }, [orderId]);

  async function launcheRAIframe(
    ui_link,
    access_token,
    refreshToken,
    orderPk,
    payload
  ) {
    await new Promise(resolve => setTimeout(resolve, 500));
    setRaLink(getRaLink(ui_link, access_token, refreshToken, orderPk, payload));
  }

  async function checkEligibility(orderId) {
    try {
      setIsFetching(true);
      const {
        succeeded,
        message,
        access_token,
        refresh_token,
        payload,
        ui_link,
        orderPk,
        errors
      } = await post(`referral-answer/submit-order`, {
        orderId
      });
      if (succeeded) {
        launcheRAIframe(ui_link, access_token, refresh_token, orderPk, payload);
      } else {
        const errMsg = message
          ? `${message} - ${errors.join(", ")}`
          : "Failed to generate patient or order";
        dispatch(errorMessage(errMsg));
      }
    } catch (ex) {
      dispatch(errorMessage(getApiErrorMessage(ex)));
    } finally {
      setIsFetching(false);
    }
  }

  async function getInsuranceVerifications({ orderId, pullData }) {
    try {
      // reach out to connect answer to update our records
      const result = await get(
        `referral-answer/check-insurance-verifications/${orderId}?pullData=${pullData}`
      );
      setInsVerifications(result.payload);
    } catch (err) {
      console.error(err);
    }
  }
  return {
    isFetching,
    checkEligibility,
    insVerifications,
    getInsuranceVerifications
  };
};

export default useRaEligibility;
