import React, { Component } from 'react'
import PropTypes from "prop-types";
import "../../components/style/index.css"
import QuestionnaireInformation from "./QuestionnaireInformation";
import Questions from "./Questions/index";

export default class FillQuestionnaireContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            selectedQuestion: null,
            loading: true,
            questionnaireInformation : []
        }
    }

    addNew = () => {
        this.setState({
            isSavedType: 'new'
        })
    }

    appendQuestionBank = (collection) =>{
        this.setState({
            questionnaireInformation : collection
        });
    }

    saveAll = (objCollection) => {
        this.props.saveAll(objCollection, this.state.questionnaireInformation);
    }
    
    render() {
        return (
            <div className="dd_root">
                <div className="dd_row" style={{ margin: 25, backgroundColor: '#f9f9f9', maxHeight: 500 }}>
                    <QuestionnaireInformation saveAll={this.saveAll} Services={this.props.Services} Products={this.props.Products} questionnaire={this.props.questionnaire} />
                </div>
                <div className="dd_row" style={{ margin: 25, padding: 15, backgroundColor: '#f9f9f9', border: '1px black' }}>
                    <Questions appendQuestionBank={this.appendQuestionBank} questionnaire={this.props.questionnaire} loading={this.state.loading} />
                </div>
            </div >
        )
    }
}

FillQuestionnaireContainer.propTypes = {
  Services: PropTypes.arrayOf(PropTypes.object),
  Products: PropTypes.arrayOf(PropTypes.object),
  questionnaire: PropTypes.object,
  saveAll: PropTypes.func
};
