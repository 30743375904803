/** Prefix for action types */
const prefix = type => `s3/batch/${type}`;

export const Types = {
  SET_PATIENT_BATCH_ENABLED: prefix("SET_PATIENT_ENABLED"),
  SET_PATIENT_BATCH_IDS: prefix("SET_PATIENT_IDS"),
  SET_ORDER_BATCH_ENABLED: prefix("SET_ORDER_BATCH_ENABLED"),
  SET_ORDER_BATCH_IDS: prefix("SET_ORDER_IDS"),
  SET_VMAIL_BATCH_ENABLED: prefix("SET_VMAIL_BATCH_ENABLED"),
  SET_VMAIL_BATCH_IDS: prefix("SET_VMAIL_IDS")
};

/** Patient batch */
export const setPatientBatchEnabled = isEnabled => ({
  type: Types.SET_PATIENT_BATCH_ENABLED,
  payload: { isEnabled: !!isEnabled }
});

export const setPatientBatchIds = (ids, titles) => ({
  type: Types.SET_PATIENT_BATCH_IDS,
  payload: { ids, titles }
});

/** Order batch */
export const setOrderBatchEnabled = isEnabled => ({
  type: Types.SET_ORDER_BATCH_ENABLED,
  payload: { isEnabled: !!isEnabled }
});

export const setOrderBatchIds = (ids, titles) => ({
  type: Types.SET_ORDER_BATCH_IDS,
  payload: { ids, titles }
});

export const setVmailBatchEnabled = isEnabled => ({
  type: Types.SET_VMAIL_BATCH_ENABLED,
  payload: { isEnabled: !!isEnabled }
});

export const setVmailBatchIds = (ids, titles) => ({
  type: Types.SET_VMAIL_BATCH_IDS,
  payload: { ids, titles }
});
