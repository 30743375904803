import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import * as R from "ramda";

const ComplianceOutreachCoachFilter = ({
  setFilters,
  filters,
  complianceCoachOptions = []
}) => {
  const selectedSleepCoaches = R.propOr([], "compliance_coach", filters);
  const [searchResultsCoach, setSearchResultsCoach] = useState([])
  const [searchTerms, setSearchTerms] = useState("")

  useEffect(()=>{
    if(searchTerms !== ""){
      setSearchResultsCoach(complianceCoachOptions.filter(sco=> sco.name.toLowerCase().includes(searchTerms.toLowerCase())))
    }
    else{
      setSearchResultsCoach(complianceCoachOptions)
    }
  }, [searchTerms])


  return (
    <React.Fragment>
        <div
            onClick={() => {
            setFilters("compliance_coach");
            }}
            className="filter-pane-command controls"
            >
            Clear Selections
        </div>
        <input
            type="text"
            className="searchInputBox-coachfilter"
            onChange={(e) => {setSearchTerms(e.target.value)}}
            placeholder="Search By Coach"
        >
        </input>
        {
            searchResultsCoach.map(sco => (
              <div key={sco.value}>
                <label className="filter-entry filter-sub-entry">
                  <input
                    type="checkbox"
                    checked={selectedSleepCoaches.includes(sco.value)}
                    onChange={({ target }) => {
                      if (target.checked) {
                        setFilters(
                          "compliance_coach",
                          R.uniq(R.append(sco.value, selectedSleepCoaches))
                        );
                      } else {
                        setFilters(
                          "compliance_coach",
                          R.without([sco.value], selectedSleepCoaches)
                        );
                      }
                    }}
                  />
                  {sco.text}
                </label>
              </div>
            ))
        }
    </React.Fragment>
  );
};

export default connect((state, props) => ({
  filters: selectors.getQueryFilters(state, props.queryId)
}))(ComplianceOutreachCoachFilter);

ComplianceOutreachCoachFilter.propTypes = {
  queryId: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  complianceCoachOptions: PropTypes.arrayOf(PropTypes.object)
};
