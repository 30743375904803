import { selectors } from "reducers";
import { createSelector } from "reselect";

// selector fns using state from muiltiple reducers
// probably not ideal but easier to mantain/test rather here than handling logic in ui.
export const showLeavePatientVmailBtn = createSelector(
  [
    selectors.getPatientPhoneNumbers,
    selectors.getDialedNumber,
    selectors.getCallDirection
  ],
  (patientPhoneNumbers, dialedNumber, callDirection) =>
    callDirection === "outbound" && patientPhoneNumbers.includes(dialedNumber)
);

export const getIsOutboundCallingBlocked = createSelector(
  [
    selectors.new_getPhoneQueues,
    selectors.getUser,
    selectors.getIsOutboundCallRestricted
  ],
  (queues, user, outboundCallRestricted) => {
    const assignedQueue = queues.find(x => x.friendly_name == user.team_name);
    if (!assignedQueue || !outboundCallRestricted) {
      return false;
    }
    return assignedQueue.average_wait_time > 0;
  }
);

export const getDialerAnswerInboundCall = createSelector(
  [
    selectors.new_getPhoneQueues,
    selectors.getUser,
    selectors.getIsDialerInboundBlocked
  ],
  (queues, user, block_autodialer_incoming_calls) => {
    const assignedQueue = queues.find(x => x.friendly_name == user.team_name);
    if (block_autodialer_incoming_calls || !assignedQueue) {
      return false;
    }
    return assignedQueue.average_wait_time > 0;
  }
);
