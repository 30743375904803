import A from "action-types";

export const requestUpdateOrderPendingInfo = (orderId, pending_info) => ({
  type: A.ORDER_STATUS.UPDATE_REQUEST,
  payload: {
    orderId,
    update: { pending_info }
  },
  meta: { orderId }
})

export const requestUpdateOrderReasonRejectedInfo = (orderId, rejected_reason) => ({
  type: A.ORDER_STATUS.UPDATE_REQUEST,
  payload: {
    orderId,
    update: { rejected_reason }
  },
  meta: { orderId }
})

export const requestUpdateOrderClaimedBy = (orderId, claimedBy) => ({
  type: A.ORDER_STATUS.UPDATE_REQUEST,
  payload: {
    orderId,
    update: { claimed_by: claimedBy }
  },
  meta: { orderId }
})

export const responseUpdateOrder = (orderId, update) => ({
  type: A.ORDER_STATUS.UPDATE_RESPONSE,
  payload: {
    entities: {
      orderRows: {
        [orderId]: update
      }
    }
  },
  meta: { orderId }
})
export const errorUpdateOrder = (orderId, payload) => ({
  type: A.ORDER_STATUS.UPDATE_RESPONSE,
  error: true,
  payload,
  meta: { orderId }
})

export const requestInvoiceDetail = query => ({
  type: A.ORDER_STATUS.REQUEST_EXPORT_INVOICE,
  payload: { query }
})
