import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { requestNoteAdd, addOrderHoldReason } from "actions/order-profile";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";

const AddWorkListItem = ({
  orderHoldReasonIds,
  addOrderHoldReason,
  orderId,
  holdReasonOptions,
  isSubmittingNote,
  requestNoteAdd,
  canAddHoldReason
}) => {
  const [isCreatingHoldReason, setIsCreatingHoldReason] = React.useState(false);
  return (
    <Formik
      initialValues={{
        holdReasonToAdd: "",
        noteText: ""
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          const { holdReasonToAdd, noteText } = values;
          if (holdReasonToAdd.length === 0 && noteText.trim().length == 0)
            return;
          if (holdReasonToAdd) {
            setIsCreatingHoldReason(true);
            await addOrderHoldReason(orderId, holdReasonToAdd, noteText);
          } else {
            requestNoteAdd({
              note: noteText
            });
          }
          resetForm();
        } finally {
          setIsCreatingHoldReason(false);
        }
      }}
    >
      {({ values }) => (
        <Form className="add-reason-select">
          <FormikField
            name="holdReasonToAdd"
            component="select"
            placeholder="Worklist Item"
          >
            <option value="">Create Note</option>
            {canAddHoldReason && (
              <React.Fragment>
                <option value="" disabled>
                  --------------- Worklist Item --------------
                </option>
                {holdReasonOptions
                  .filter(({ value }) => !orderHoldReasonIds.includes(value))
                  .map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
              </React.Fragment>
            )}
          </FormikField>
          <FormikField
            name="noteText"
            placeholder={values.holdReasonToAdd ? "Comment..." : "Note..."}
            type="text"
          />
          {isCreatingHoldReason || isSubmittingNote ? (
            <InlineWaiting />
          ) : (
            <button
              type="submit"
              disabled={
                values.holdReasonToAdd.length === 0 &&
                values.noteText.trim().length == 0
              }
            >
              {values.holdReasonToAdd ? "Add Worklist Item" : "Create Note"}
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

AddWorkListItem.defaultProps = {
  orderHoldReasonIds: []
};

AddWorkListItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  addOrderHoldReason: PropTypes.func.isRequired,
  holdReasonOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderHoldReasonIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isSubmittingNote: PropTypes.bool,
  requestNoteAdd: PropTypes.func.isRequired,
  canAddHoldReason: PropTypes.bool
};

export default connect(
  (state, { orderId }) => ({
    holdReasonOptions: selectors.getOrderHoldReasonOptions(state),
    isSubmittingNote: selectors.getIsSubmittingNoteAdd(state, { orderId })
  }),
  (dispatch, { orderId }) => ({
    requestNoteAdd: (...args) => dispatch(requestNoteAdd({ orderId })(...args)),
    addOrderHoldReason: (...args) => dispatch(addOrderHoldReason(...args))
  })
)(AddWorkListItem);
