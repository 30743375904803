import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
//this component is a wrapper of http://react-day-picker.js.org/examples/input-from-to
import { formatDate, parseDate } from 'react-day-picker/moment';

export default class DayPickerRange extends Component {
  state = { from: undefined, to: undefined };

  componentDidMount() {
    const { initStartDate, initEndDate } = this.props;
    if (initStartDate && initEndDate) {
      this.setState({ from: parseDate(initStartDate), to: parseDate(initEndDate) });
    }
  }

  showFromMonth = () => {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker() && this.to.getDayPicker().showMonth(from);
    }
  }
  handleFromChange = (from) => {
    // Change the from date and focus the "to" input field
    this.setState({ from });
    this.props.handleFromClick({ from })
  }
  handleToChange = (to) => {
    this.setState({ to }, this.showFromMonth);
    this.props.handleToClick({ to })
  }
  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const { fromLabel, toLabel } = this.props;
    return (
      <div className="InputFromTo">
        {fromLabel && <span>{fromLabel}</span>}
        <DayPickerInput
          value={from}
          placeholder="MM/DD/YYYY"
          format="MM/DD/YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />
        {toLabel && <span>{toLabel}</span>}
        <DayPickerInput
          ref={el => (this.to = el)}
          value={to}
          placeholder="MM/DD/YYYY"
          format="MM/DD/YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: from },
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 2,
          }}
          onDayChange={this.handleToChange}
        />
      </div>
    );
  }
}

DayPickerRange.propTypes = {
  handleToClick: PropTypes.func.isRequired,
  handleFromClick: PropTypes.func.isRequired,
  fromLabel: PropTypes.string,
  toLabel: PropTypes.string,
  initStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.date, PropTypes.instanceOf(Date)]),
  initEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.date, PropTypes.instanceOf(Date)])
};
