import {
  take,
  put
} from 'redux-saga/effects'
import { Types } from "actions/order-profile";

export default function* logProfileAccess() {
  const loggedOrders = {}
  while (true) {
    const { meta: { orderId } } = yield take(Types.REQUEST_ORDER_PROFILE_ACCESS)
    if (!loggedOrders[orderId]) {
      yield put({
        type: Types.API_ORDER_PROFILE_ACCESS,
        payload: {
          apiRequest: {
            method: 'POST',
            route: `orders/${orderId}/access`,
            type: Types.RESPONSE_ORDER_PROFILE_ACCESS,
            meta: { orderId }
          }
        },
        meta: { orderId }
      })
      loggedOrders[orderId] = true
    }
  }
}
