import React, { useState } from 'react';
import PureModal from "components/ui/modal/pure-modal";
import ModalAskForCompliantMet from "./modal-ask-for-compliant-met";
import moment from 'moment';
import PropTypes from "prop-types";

const YesNoCheckbox = ({ defaultValue, disabled, onChange, hasMinimumTherapyDays, followUpDate, compliancePercentage, setUpDate }) => {

  const[changeValue,setChangeValue]  = useState(defaultValue);
  const [yesChecked, setYesChecked] = useState(false);
  const [noChecked, setNoChecked] = useState(false);
  const [intentionToSetAsCompliant, setIntentionToSetAsCompliant] = useState(false)

  const [isFollowUpDateValid, setIsFollowUpDateValid] = useState(true)
  const [isCompliancePercentageValid, setIsCompliancePercentageValid] = useState(true)

  const updateCheckboxes = () =>{
    setYesChecked(changeValue === true);
    setNoChecked(changeValue === false);
  }

  const handleYesChange = () => {
    if (!disabled) {
      if (!yesChecked) {
        setChangeValue(true);
        setIntentionToSetAsCompliant(true)
        const setUpDatePlus30Days = moment(setUpDate).add(30, "days");
        if (followUpDate && moment(followUpDate).isSameOrAfter(setUpDatePlus30Days)) {
          setIsFollowUpDateValid(true)
        } else {
          setIsFollowUpDateValid(false)
        }
        if(compliancePercentage && compliancePercentage >= 70){
          setIsCompliancePercentageValid(true)
        } else {
          setIsCompliancePercentageValid(false)
        }
      } else {
        setChangeValue(null);
        setIntentionToSetAsCompliant(false)
      }
      compliantMetConfirm();
    }

  };

  const handleNoChange = () => {
    setIsFollowUpDateValid(true)
    setIsCompliancePercentageValid(true)
    if (!disabled) {
      if (!noChecked) {
        setChangeValue(false);
      } else {
        setChangeValue(null);
      }
      setIntentionToSetAsCompliant(false)
      compliantMetConfirm();
    }
  };

  const compliantMetConfirm = () => {
    document.getElementById("compliantMetConfirmId").click();
  }

  const handleChange = () => {
    if (!disabled) {
      updateCheckboxes();
      onChange(changeValue);
    }
  };

  React.useEffect(() => {
    updateCheckboxes();
  }, [defaultValue])

  return (
    <div>
      <span style={{display:'flex', marginBottom: '0.6rem', alignItems: 'center', width: 'auto'}}>
        <label style={{marginRight: '2.75rem'}}>
          Is Compliant?
        </label>
        <span style={{display: 'inline-flex', width: "auto"}}>
          <label style={{marginRight: '0.75rem'}}>
            Yes
          </label>
        </span>

        <input
          type="checkbox"
          checked={yesChecked}
          onChange={handleYesChange}
          disabled={disabled}
          style={{margin: '0 0.75rem 0 0', display: 'inline-flex', alignSelf: 'center'}}
        />
        <span style={{display: 'inline-flex', width: "auto"}}>
          <label style={{marginRight: '0.75rem'}}>
            No
          </label>
        </span>
        <input
          type="checkbox"
          checked={noChecked}
          onChange={handleNoChange}
          disabled={disabled}
          style={{margin: '0', marginRight: '0.75rem', display: 'inline-flex', alignSelf: 'center'}}
        />
      </span>

      <PureModal
        renderTrigger={({ openModal }) => (<div id="compliantMetConfirmId" onClick={openModal}></div>)}
        renderContent={({ closeModal }) => {
          return <ModalAskForCompliantMet
            style={{ backgroundColor: 'red' }}
            isCompliant={changeValue === null}
            closeModal={closeModal}
            updateCompliantMet={handleChange}
            hasMinimumTherapyDays={hasMinimumTherapyDays}
            intentionToSetAsCompliant={intentionToSetAsCompliant}
            isFollowUpDateValid={isFollowUpDateValid}
            isCompliancePercentageValid={isCompliancePercentageValid}
          />
        }}
      ></PureModal>
    </div>
  );
};

YesNoCheckbox.propTypes = {
  defaultValue: PropTypes.bool, 
  disabled: PropTypes.bool, 
  onChange: PropTypes.func, 
  hasMinimumTherapyDays: PropTypes.bool, 
  followUpDate: PropTypes.string, 
  compliancePercentage: PropTypes.number, 
  setUpDate: PropTypes.string
};

export default YesNoCheckbox;