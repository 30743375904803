import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyInventory,
  deleteCompanyInventory
} from "actions/company-inventory";
import { selectors } from "reducers";
import moment from "moment";
import PureModal from "components/ui/modal/pure-modal";
import { aLink } from "utils/styles";
import MdAdd from "react-icons/lib/md/add";
import { IconButton } from "components/ui";
import { CompanyInventoryForm } from "./company-inventory-form";
import { CompanyInventoryModal } from "./company-inventory-modal";
import CompanyInventorySearch from "./company-inventory-search";

const filterCompanyInventoryItem = (searchValue, searchBy) => inventoryItem => {
  if (searchValue.length > 0) {
    switch (searchBy) {
      case "name":
        return inventoryItem.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "serial_number":
        return inventoryItem.serial_number
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "model_number":
        return inventoryItem.model_number
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      default:
        return false;
    }
  }
  return true;
};

const CompanyInventory = props => {
  const { rows, query, queryDispatcher } = useLocalQuery(props.inventory);
  const sleepCoaches = useSelector(selectors.getFormOptionsSleepCoach);

  React.useEffect(() => {
    props.fetchCompanyInventory();
  }, []);
  const [searchBy, setSearchBy] = useState("serial_number");
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState(rows);
  const getFilteredInventoryRows = async () => {
    setResults(rows.filter(filterCompanyInventoryItem(searchValue, searchBy)));
  };
  useEffect(() => {
    getFilteredInventoryRows();
  }, [rows]);
  useEffect(() => {
    getFilteredInventoryRows();
  }, [searchValue]);
  return (
    <>
      <div className="companyInventory--main">
      <header className="page-header">
          <div className="page-header-title change-log-header" >
            <h1>
              <span className="page-title">Company Inventory</span>
            </h1>
          </div>
        </header>
        <CompanyInventorySearch
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <Table
          TableRow={InventoryTableRow}
          columns={[
            {
              header: "Date",
              field: "date_of_service"
            },
            {
              header: "Serial Number",
              field: "serial_number"
            },
            {
              header: "Model Number",
              field: "model_number"
            },
            {
              header: "Name",
              field: "name"
            },
            {
              header: "Notes",
              field: "additional_equipment",
              style: { width: "20%" }
            },
            {
              field: "",
              Cell: () => (
                <td className="query-table-header-cell-container">
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <div
                        className="header-text"
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "flex-end"
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            openModal();
                          }}
                          icon={<MdAdd />}
                          text="Add Entry"
                          style={{ margin: 0, padding: "5px 10px" }}
                        />
                      </div>
                    )}
                  >
                    <CompanyInventoryForm sleepCoaches={sleepCoaches} />
                  </PureModal>
                </td>
              ),
              header: ""
            }
          ]}
          query={query}
          queryDispatcher={queryDispatcher}
          rows={results}
          rowKey="serial_number"
        />
      </div>
    </>
  );
};

CompanyInventory.propTypes = {
  inventory: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchCompanyInventory: PropTypes.func.isRequired
}

export default connect(
  state => ({
    user: state.get("user"),
    inventory: state.get("company-inventory")
  }),
  { fetchCompanyInventory }
)(CompanyInventory);

const InventoryTableRow = props => {
  const {
    date_of_service,
    serial_number,
    model_number,
    firstName,
    lastname,
    name,
    additional_equipment,
    guid,
    user_id
  } = props;
  const dispatch = useDispatch();

  const [editState, setEditState] = useState(false);

  const changeEditState = () => {
    setEditState(!editState);
  };

  const deleteInv = () => {
    dispatch(deleteCompanyInventory(guid));
  };

  return (
    <tr>
      <td>{moment(date_of_service).format("MMM DD, YYYY")}</td>
      <td>{serial_number}</td>
      <td>{model_number}</td>
      <td>{name}</td>
      <td>{additional_equipment}</td>
      <PureModal
        renderContent={({ closeModal }) => (
          <td>
            <div
              style={{
                paddingLeft: 50,
                paddingTop: 20,
                dispaly: "flex",
                alignItems: "center",
                fontSize: "large",
                ...aLink
              }}
            >
              <div>Would you like to delete this item?</div>
              <div className="button--container">
                <button
                  className="button tiny"
                  onClick={() => {
                    deleteInv();
                    closeModal();
                  }}
                >
                  Yes
                </button>
                <button
                  className="button tiny unassign--button"
                  onClick={closeModal}
                >
                  No
                </button>
              </div>
            </div>
          </td>
        )}
        renderTrigger={({ openModal }) => (
          <td className="deleteButton--container">
            <PureModal
              renderContent={({ closeModal }) => (
                <td>
                  <div
                    style={{
                      paddingLeft: 50,
                      paddingTop: 20,
                      dispaly: "flex",
                      alignItems: "center",
                      fontSize: "large",
                      ...aLink
                    }}
                  >
                    <CompanyInventoryModal
                      editState={editState}
                      closeModal={closeModal}
                      changeEditState={changeEditState}
                      date_of_service={date_of_service}
                      serial_number={serial_number}
                      model_number={model_number}
                      firstName={firstName}
                      lastname={lastname}
                      name={name}
                      additional_equipment={additional_equipment}
                      user_id={user_id}
                      guid={guid}
                    />
                  </div>
                </td>
              )}
              renderTrigger={({ openModal }) => (
                <button
                  onClick={() => {
                    openModal();
                  }}
                  className="button tiny"
                >
                  View/Edit
                </button>
              )}
            ></PureModal>
            <button
              onClick={openModal}
              className="button alert tiny button--delete"
            >
              Delete
            </button>
          </td>
        )}
      ></PureModal>
    </tr>
  );
};

InventoryTableRow.propTypes = { 
    date_of_service: PropTypes.string,
    serial_number: PropTypes.string,
    model_number: PropTypes.string,
    firstName: PropTypes.string,
    lastname: PropTypes.string,
    name: PropTypes.string,
    additional_equipment: PropTypes.string,
    guid: PropTypes.string,
    user_id: PropTypes.string
}
