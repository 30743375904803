import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePatient } from "actions/patient";
import SectionHeader from "components/profile/section_header";
import { InlineWaiting } from "components/ui";
import EditAddress from "./edit_address";
import MdHome from "react-icons/lib/md/home";
import IconButton from "components/ui/icon-button";
import { path } from "ramda";
import { getTimezoneFromZip } from "utils/dates";

const AddressInformation = ({
  patientInfo,
  updatePatient,
  isUpdatingPatient,
  readOnly
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <EditAddress
      onSubmit={formValues => {
        formValues.timezone = getTimezoneFromZip(formValues.zip)
        updatePatient({
          patientId: patientInfo.id,
          updates: {
            address: { ...formValues }
          }
        });
        setIsEditing(false);
      }}
      handleCancel={() => {
        setIsEditing(false);
      }}
      initialValues={{
        street_address: path(["address", "street_address"], patientInfo),
        street_address2: path(["address", "street_address2"], patientInfo),
        city: path(["address", "city"], patientInfo),
        state: path(["address", "state"], patientInfo),
        zip: path(["address", "zip"], patientInfo),
        timezone: "",
      }}
    />
  ) : (
    <div style={{ marginBottom: "25px" }}>
      <SectionHeader>
        Address
        <IconButton
          icon={<MdHome />}
          text="Edit"
          onClick={() => {
            setIsEditing(true);
          }}
          disabled={readOnly}
          style={{ fontSize: "12px", float: "right" }}
        />
      </SectionHeader>
      {isUpdatingPatient ? (
        <InlineWaiting />
      ) : (
        <div>
          {!path(["address", "state"], patientInfo) &&
          !path(["address", "city"], patientInfo) &&
          !path(["address", "street_address"], patientInfo) &&
          !path(["address", "street_address2"], patientInfo) ? (
            <p style={{ color: "#493511" }}>Please Update Patient Address.</p>
          ) : (
            <p>
              {path(["address", "street_address"], patientInfo)}
              <br />
              {path(["address", "street_address2"], patientInfo)}
              <br />
              {path(["address", "city"], patientInfo)},
              {"  " + path(["address", "state"], patientInfo)}{" "}
              {path(["address", "zip"], patientInfo)}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

AddressInformation.propTypes = {
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  updatePatient: PropTypes.func.isRequired,
  isUpdatingPatient: PropTypes.bool,
  readOnly: PropTypes.bool,
};

AddressInformation.defaultProps = {
  readOnly: false,
}

export default connect(
  (state, { patientId }) => ({
    isUpdatingPatient: state.getIn([
      "fetch",
      "UPDATE_PATIENT",
      patientId,
      "isFetching"
    ])
  }),
  {
    updatePatient
  }
)(AddressInformation);
