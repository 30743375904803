import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Rows } from "components/ui";
import PhysicianAddress from "./physician-address";

const normalizePhysician = ({ first_name, last_name, address, npi }) => {
  const name = `${first_name} ${last_name}`;
  const street_address = `${address.address_1 ?? ""} ${address.address_2 ?? ""}`;

  return {
    npi: `${npi}`,
    name,
    first_name,
    last_name,
    phone: address.telephone_number?.replace(/\D/g, ""),
    fax: address.fax_number?.replace(/\D/g, ""),
    address: {
      street_address,
      city: address.city,
      zip: address.postal_code,
      state: address.state
    }
  };
};

const PhysicianSearchResults = ({
  searchResults,
  searchValues,
  isFetching,
  handlePhysicianSelect
}) => {
  return isFetching || !searchValues ? null : (
    <div>
      <div className="search-results-header">
        <h5>Found {searchResults.count} Physicians</h5>
        <span className="search-values">
          {searchValues.npi && (
            <span className="search-value">NPI #:{searchValues.npi}</span>
          )}
          {searchValues.first_name && (
            <span className="search-value">
              First Name:{searchValues.first_name}
            </span>
          )}
          {searchValues.last_name && (
            <span className="search-value">
              Last Name:{searchValues.last_name}
            </span>
          )}
          {searchValues.address && (
            <React.Fragment>
              {searchValues.address.city && (
                <span className="search-value">
                  City:{searchValues.address.city}
                </span>
              )}
              {searchValues.address.state && (
                <span className="search-value">
                  State:{searchValues.address.state}
                </span>
              )}
              {searchValues.address.zip && (
                <span className="search-value">
                  Zip:{searchValues.address.zip}
                </span>
              )}
            </React.Fragment>
          )}
        </span>
      </div>
      <div className="search-results">
        <Rows>
          {searchResults.physicians &&
            searchResults.physicians.map(
              ({ first_name, last_name, addresses, npi }) => (
                <div key={npi} className="physician-info">
                  <div>
                    {first_name} {last_name}
                    <span style={{ padding: "0 10px", color: "#4281a6" }}>
                      NPI #: {npi}
                    </span>
                  </div>
                  {addresses.map((address, index) => (
                    <PhysicianAddress
                      key={index}
                      {...address}
                      onClick={() =>
                        handlePhysicianSelect(
                          normalizePhysician({
                            first_name,
                            last_name,
                            address,
                            npi
                          })
                        )
                      }
                    />
                  ))}
                </div>
              )
            )}
        </Rows>
      </div>
    </div>
  );
};

PhysicianSearchResults.propTypes = {
  handlePhysicianSelect: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  searchValues: PropTypes.object,
  searchResults: PropTypes.shape({
    count: PropTypes.number,
    physicians: PropTypes.array
  })
};

export default connect(state => ({
  isFetching: selectors.getIsFetchingPhysicians(state),
  searchValues: selectors.getPhysicianSearchValues(state),
  searchResults: selectors.getPhysicianSearchResults(state)
}))(PhysicianSearchResults);
