import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DayPicker from "react-day-picker";
import { PageHeader, InlineWaiting } from "components/ui";
import { connect } from "react-redux";
import { createWalkInOrder } from "action-creators";

const WalkInOrder = ({ patientId, userId, createWalkInOrder, closeSelf }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(new Date());
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await createWalkInOrder(patientId, {
        order_date: selectedDay,
        user_id: userId
      });
      closeSelf();
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <React.Fragment>
      <PageHeader title="New Walk-in Order" />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="small-12 columns">
            <div>
              {selectedDay
                ? moment(selectedDay).format("LL")
                : "Please select a day."}
            </div>
            <DayPicker
              onDayClick={day => setSelectedDay(day)}
              value={selectedDay}
              selectedDays={[selectedDay]}
            />
            <div
              style={{
                textAlign: "right"
              }}
            >
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <button>Add Walk-in Order</button>
              )}
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default connect(
  state => ({
    userId: state.getIn(["user", "id"])
  }),
  { createWalkInOrder }
)(WalkInOrder);

WalkInOrder.propTypes = {
  createWalkInOrder: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  closeSelf: PropTypes.func.isRequired
};
