import React from "react";
import PropTypes from "prop-types";
import { capitalize, capitalizeAddress } from "utils/misc";
import { Link } from "react-router-dom";
import { browserHistory } from "browser-history";
import BarcodeDisplay from "./barcode-display";
import IoMdPrinter from "react-icons/lib/io/printer";
import { path } from "ramda";

const PrintPackingSlips = ({ orders }) => (
  <div className="packing-slips-page">
    <div className="action-container no-print">
      <button onClick={() => browserHistory.goBack()}>Exit</button>
      <i className="print-button" onClick={() => window.print()}>
        <IoMdPrinter />
      </i>
    </div>
    <div className="print-packing-slips-container">
      {orders.map(order => {
        return (
          <div className="packing-slip-container" key={order.id}>
            <div className="packing-slip-header">
              <Link to={`/orders/${order.id}`}>
                <h1>Packing Slip</h1>
              </Link>
              <h5>{path(["order_info", "invoice_number"], order)}</h5>
            </div>
            <div className="shipping-container">
              <div className="address-box">
                <div className="shaded-container">Ship To:</div>
                <div>
                  <h6>
                    <strong>
                      {path(["patient_info", "patient_name"], order)}
                    </strong>
                  </h6>
                  <p>
                    {capitalizeAddress(
                      path(["order_info", "street_address"], order)
                    )}{" "}
                    <br />
                    {capitalize(path(["order_info", "city"], order)) +
                      ", " +
                      path(["order_info", "state"], order) +
                      " " +
                      path(["order_info", "zip"], order)}
                  </p>
                </div>
              </div>
              <div className="address-box">
                <div className="shaded-container">Ship From:</div>
                <div>
                  <h6>
                    <strong>
                      {path(["company_info", "company_name"], order)}
                    </strong>
                  </h6>
                  <p>
                    {capitalizeAddress(
                      path(["company_info", "street_address"], order)
                    )}
                    <br />
                    {capitalize(path(["company_info", "city"], order)) +
                      ", " +
                      path(["company_info", "state"], order) +
                      " " +
                      path(["company_info", "zip"], order)}
                  </p>
                </div>
              </div>
            </div>
            <div className="packing-slip-body">
              <div className="equipment-requested">
                <h4>Equipment Requested</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Manufacturer Product ID</th>
                      <th>Company Product ID</th>
                      <th>HCPCS</th>
                      <th>Product</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.equipment_requested.map(equipment => (
                      <tr key={equipment.id}>
                        <td>{equipment.type}</td>
                        <td>{equipment.id}</td>
                        <td>{equipment.company_product_id ?? equipment.id}</td>
                        <td>{equipment.manufacturer}</td>
                        <td>{equipment.HCPSC}</td>
                        <td>{equipment.Product}</td>
                        <td>{equipment.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="equipment-requested">
                  <h4>Tracking</h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Product ID</th>
                        <th>Ship Date</th>
                        <th>Carrier</th>
                        <th>Tracking Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.equipment_requested.map(equipment => (
                        <tr key={equipment.id}>
                          <td>{equipment.id}</td>
                          <td>
                            {path(["shippingDetails", "shipDate"], equipment)}
                          </td>
                          <td>
                            {path(["shippingDetails", "carrier"], equipment)}
                          </td>
                          <td>
                            {path(
                              ["shippingDetails", "tracking_number"],
                              equipment
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <BarcodeDisplay
                  orderSalesNumber={path(
                    ["order_info", "sales_order_number"],
                    order
                  )}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

PrintPackingSlips.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PrintPackingSlips;
