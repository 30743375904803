import React from 'react';
import PropTypes from 'prop-types';
import Icons from "./../../../components/ui/sidebar/icons";

class Modal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: 50,
      zIndex:1,
      overflow: 'scroll'
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: '#fff',
      borderRadius: 5,
      maxWidth: this.props.width && this.props.width.length > 0 ? this.props.width :"90%",
      minHeight: 300,
      margin: '0 auto',
      padding: 30,
      position: "relative"
    };

    const iconStyle = {
        position: "absolute",
        top: "5px",
        fontSize: "x-large",
        right: "5px",
        cursor: "pointer"
    }

    return (
      <div className="backdrop" style={backdropStyle}>
        <div className="modal" style={modalStyle}>
          <div className="footer">
            {!this.props.isCustom ?
              <button onClick={this.props.onClose}>
                Close
             </button> : 
              <React.Fragment>
                <Icons.Close style={iconStyle} onClick={this.props.onClose} />
                <div className="dd_row">
                  <div className="dd_col-12" style={{ textAlign: "center" }}>
                    <h2>{this.props.title}</h2>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  isCustom: PropTypes.bool
};

export default Modal;
