import React from "react";
import PropTypes from "prop-types";
import MaskFittingQuestions from "./question-answers";
import { PureModal } from "components/ui";
import moment from "moment";
import IoMdCheckBoxOut from "react-icons/lib/io/android-checkbox-outline";
import IoMdCheckBoxOutlineBlank from "react-icons/lib/io/android-checkbox-outline-blank";
import { aLink } from "utils/styles";
import * as R from "ramda";

const MaskFittingResult = ({
  masks,
  transaction_id,
  patientId,
  question_answers,
  setPatientFittingRecommendedMask,
  date_submitted,
  status,
  showQuestionnair
}) => {
  const canSelectMask =
    typeof setPatientFittingRecommendedMask === "function" &&
    R.all(x => !x.selected)(masks);
  return (
    <div className="mask-fitting-result">
      <div className="mask-fitting-result-status">
        <div className="mask-fitting-status">
          <span>{status}</span>
        </div>
        {showQuestionnair && question_answers && question_answers.length > 0 && (
          <PureModal
            renderTrigger={({ openModal }) => (
              <div
                onClick={openModal}
                style={{ ...aLink, fontSize: "1.10rem" }}
              >
                View patient questions/answers
              </div>
            )}
          >
            <MaskFittingQuestions question_answers={question_answers} />
          </PureModal>
        )}
        {date_submitted && (
          <div>
            Submitted:{" "}
            {date_submitted ? moment(date_submitted).format("L") : ""}
          </div>
        )}
      </div>
      <table className="recommended-masks-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Mask</th>
            <th>Confidence</th>
            <th>Manufacturer</th>
            {typeof setPatientFittingRecommendedMask === "function" && (
              <th>Selected</th>
            )}
          </tr>
        </thead>
        <tbody>
          {masks.map(
            (
              { inventory_id, name, confidence, manufacturer, selected },
              index
            ) => (
              <tr key={inventory_id}>
                <td>{index + 1}</td>
                <td>{name}</td>
                <td>{confidence}</td>
                <td>{manufacturer}</td>
                {typeof setPatientFittingRecommendedMask === "function" && (
                  <td>
                    {canSelectMask ? (
                      <div
                        onClick={() => {
                          setPatientFittingRecommendedMask({
                            patientId,
                            transaction_id,
                            inventory_id
                          });
                        }}
                        style={aLink}
                      >
                        Use Mask
                      </div>
                    ) : selected ? (
                      <span style={{ paddingLeft: 5 }}>
                        <IoMdCheckBoxOut color="green" size={20} />
                      </span>
                    ) : (
                      <span style={{ paddingLeft: 5 }}>
                        <IoMdCheckBoxOutlineBlank color="#ccc" size={20} />
                      </span>
                    )}
                  </td>
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

MaskFittingResult.defaultProps = {
  showQuestionnair: true
};

MaskFittingResult.propTypes = {
  masks: PropTypes.arrayOf(PropTypes.object),
  isSettingRecommendedMask: PropTypes.bool,
  setPatientFittingRecommendedMask: PropTypes.func,
  proccessed: PropTypes.bool,
  date_submitted: PropTypes.string,
  question_answers: PropTypes.arrayOf(PropTypes.object),
  transaction_id: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  status: PropTypes.string,
  showQuestionnair: PropTypes.bool
};

export default MaskFittingResult;
