import { useRef } from "react";

/* Cache formik async validation api calls,
 * By default formik validates after change, blur and submit events, currently you can only change this for entire form and not on a single field
 */
function useFormikAsyncValidationCache(apiFn) {
  const cachedValidationRef = useRef({});
  async function validateField(value) {
    if (cachedValidationRef && cachedValidationRef.current) {
      const cachedEmail = cachedValidationRef.current[value];
      if (cachedEmail != undefined) return cachedEmail;
    }
    const isInvalid = await apiFn(value);
    if (cachedValidationRef && cachedValidationRef.current) {
      cachedValidationRef.current[value] = isInvalid;
    }
    return isInvalid;
  }
  return validateField;
}

export default useFormikAsyncValidationCache;
