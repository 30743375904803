import React from "react";
import { Formik, Form, FieldArray } from "formik";
import { IconButton } from "components/ui";
import FormikField from "components/forms/formik-field";
import MdCancel from "react-icons/lib/md/cancel";
import MdAdd from "react-icons/lib/md/add";
import MdSave from "react-icons/lib/md/save";
import PropTypes from "prop-types";

const HcpcTypeForm = ({
  values,
  handleSubmit
}) => {
  return (
    <>
      <Formik initialValues={values} onSubmit={handleSubmit} enableReinitialize>
        {({ values, handleSubmit }) => (
          <Form style={{ width: "100%" }}>
            <div className="hcpc-container">
              <FieldArray
                name={"data"}
                render={arrayHelpers => (
                  <>
                    <div className="edit-group-containter">
                        <IconButton
                          type="button"
                          style={{ margin: 0, padding: "5px 15px" }}
                          onClick={formData => handleSubmit(formData)}
                          text="Save"
                          icon={<MdSave />}
                        />
                        <IconButton
                          type="button"
                          style={{ margin: 0, padding: "5px 15px" }}
                          onClick={() =>
                            arrayHelpers.push({
                              days_to_push_eligibility: "",
                              quantity_allowable: "",
                              company_hcpc_id: null
                            })
                          }
                          text="Add"
                          icon={<MdAdd />}
                        />
                      </div>
                      <div className="hcpc-inputfield-container">
                    {values.data.map((hcpcs, index) => (
                      <div key={index} className="grouping-container">
                        <FormikField
                          name={`data.${index}.quantity_allowable`}
                          type="number"
                          label="Allowable"
                        />
                        <FormikField
                          name={`data.${index}.days_to_push_eligibility`}
                          type="number"
                          label="Days"
                        />
                        <IconButton
                          onClick={() => arrayHelpers.remove(index)}
                          style={{
                            color: "#fe220c",
                            padding: 3
                          }}
                          icon={<MdCancel />}
                          type="button"
                          text="Delete"
                        />
                      </div>
                    ))}
                    </div>
                  </>
               )} 
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default HcpcTypeForm;

HcpcTypeForm.propTypes ={
  values: PropTypes.array,
  handleSubmit: PropTypes.func

}
