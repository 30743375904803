import React from "react";
import PropTypes from "prop-types";
import { withModal } from "components/ui";
import { Menu, MenuItem } from "components/ui/menu";
import {
  textPatient,
  textConsentPatient,
  pushNotifyPatient,
  textKudosPatient,
  sendPatientMaskFittingInstructions
} from "actions/patient";
import ContactRecord from "components/profile/modals/contact-record";
import SpecialInstructions from "components/profile/modals/special-instructions";
import WalkInOrder from "components/profile/modals/walk-in-order";
import PatientEmailMenu from "components/profile/email-menu";
import { useDispatch } from "react-redux";
import ModalLink from "components/shared/modal-link";
import HasAppPermission from "components/shared/has-app-permission";
import MdKeyboardArrowRight from "react-icons/lib/md/keyboard-arrow-right";
import HasRolePermission from "components/shared/has-role-permission";
import ComplianceEmailMenu from "./compliance-email-menu";

const ProfileMenu = ({
  patientInfo,
  patientId,
  onCreateOrder,
  openModal,
  closeModal,
  disabled
}) => {
  const dispatch = useDispatch();

  const isCompliancePatient =
    patientInfo.compliance_participant ||
    patientInfo?.patientTypeData?.compliance_status === 1; // This field will go away soon, we need to check it for now though

  const textSublist = [
    {
      label: "Send Reorder Text Message",
      onClick: () => {
        dispatch(textPatient({ patientId }));
      }
    },
    {
      label: "Send Text Consent Text Message (Primary)",
      onClick: () => {
        dispatch(
          textConsentPatient({
            patientId: patientId,
            phone: "Primary"
          })
        );
      }
    },
    {
      label: "Send Text Consent Text Message (Secondary)",
      onClick: () => {
        dispatch(
          textConsentPatient({
            patientId: patientId,
            phone: "Secondary"
          })
        );
      }
    },
    {
      label: "Send Push Notification",
      onClick: () => {
        dispatch(pushNotifyPatient({ patientId }));
      }
    }
  ];

  if (isCompliancePatient) {
    //Only allow sending the Kudos text to compliance patients
    const object = {
      label: "Send Compliance Kudos Text Message",
      onClick: () => {
        dispatch(textKudosPatient({ patientId }));
      }
    };
    textSublist.push(object);
  }

  return (
    <div>
      <Menu>
        <HasAppPermission permission="orderSupplies">
          <MenuItem
            disabled={disabled}
            onClick={() => {
              onCreateOrder();
            }}
          >
            Create Order
          </MenuItem>
          <MenuItem
            disabled={disabled}
            onClick={() =>
              openModal(
                <WalkInOrder closeSelf={closeModal} patientId={patientId} />
              )
            }
          >
            Walk-in Order
          </MenuItem>
        </HasAppPermission>
        <ModalLink disabled={disabled} to={`/patients/${patientId}/edit`}>
          <MenuItem disabled={disabled}>Edit Profile</MenuItem>
        </ModalLink>
        <MenuItem
          disabled={disabled}
          onClick={() =>
            openModal(
              <SpecialInstructions
                patientId={patientId}
                closeSelf={closeModal}
              />
            )
          }
        >
          Add Special Instructions
        </MenuItem>
        <HasAppPermission permission="orderSupplies">
          {!isCompliancePatient && (
            <PatientEmailMenu
              patientId={patientId}
              openModal={openModal}
              closeModal={closeModal}
              patientEmail={patientInfo.email}
            />
          )}
          {isCompliancePatient && (
            <ComplianceEmailMenu
              patientId={patientId}
              openModal={openModal}
              closeModal={closeModal}
              patientEmail={patientInfo.email}
            />
          )}
          <MenuItem disabled={disabled} sublist={textSublist}>
            Text Message
            <span className="menu-icon">
              <MdKeyboardArrowRight />
            </span>
          </MenuItem>
        </HasAppPermission>
        {patientInfo && patientInfo.autofit_eligible && (
          <HasAppPermission permission="maskFitting">
            <MenuItem
              onClick={() => {
                dispatch(sendPatientMaskFittingInstructions(patientId));
              }}
            >
              Send Mask Fitting Instructions
            </MenuItem>
          </HasAppPermission>
        )}
        <HasRolePermission
          // DEV-15262 Admins and SAAS users only
          allowedRoles={["Administrator", "ServiceAdmin", "ServiceCoach"]}
        >
          <MenuItem
            onClick={() =>
              openModal(
                <ContactRecord patientId={patientId} closeSelf={closeModal} />
              )
            }
          >
            Create Contact Record
          </MenuItem>
        </HasRolePermission>
      </Menu>
    </div>
  );
};

ProfileMenu.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  onCreateOrder: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

ProfileMenu.defaultProps = {
  disabled: false
};

export default withModal(ProfileMenu);
