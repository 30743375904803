import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader } from "components/ui/index";
import ProductSelect from "components/forms/product-select";
import ProductFAQs from "./product-faqs";
import PureModal from "components/ui/modal/pure-modal";
import ProductFAQsForm from "./product-faqs-form";
import { createFAQ } from "./../../Redux/Actions/faq";

const FAQs = ({ createFAQ }) => {
  const [selectedProduct, setSelectedProduct] = React.useState("");
  return (
    <div>
      <PageHeader title="FAQs" />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          gridGap: 10
        }}
      >
        <ProductSelect
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
        <PureModal
          renderTrigger={({ openModal }) => (
            <button type="button" style={{ margin: 0 }} onClick={openModal}>
              Add FAQ
            </button>
          )}
          renderContent={({ closeModal }) => (
            <ProductFAQsForm
              handleCancel={closeModal}
              handleSubmit={async values => {
                await createFAQ(selectedProduct, values);
              }}
            />
          )}
        />
      </div>
      <hr />
      <ProductFAQs productId={selectedProduct} />
    </div>
  );
};

FAQs.propTypes = {
  createFAQ: PropTypes.func.isRequired
};

export default connect(() => ({}), {
  createFAQ
})(FAQs);
