import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import HasRolePermission from "components/shared/has-role-permission";

const CompanyTeamSelect = ({ setFilters, teams, companies }) => {
  const [selectedTeam, selectTeam] = useState();
  return (
    <HasRolePermission allowedRoles={["Administrator", "SleepCoach"]}>
      <div className="comany-team-select">
        <label>Team</label>
        <select
          onChange={({ target }) => {
            selectTeam(target.value);
            const teamCompanyIds = companies
              .filter(({ assignedTeam }) => assignedTeam === target.value)
              .map(({ value }) => value);
            setFilters("company", teamCompanyIds);
          }}
          value={selectedTeam}
        >
          <option />
          {teams.map(({ value, text }) => (
            <option value={value} key={value}>
              {text}
            </option>
          ))}
        </select>
      </div>
    </HasRolePermission>
  );
};

export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state),
  teams: selectors.getTeamNameOptions(state)
}))(CompanyTeamSelect);

CompanyTeamSelect.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object),
  companies: PropTypes.arrayOf(PropTypes.object),
  setFilters: PropTypes.func.isRequired
};
