import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { TableButton } from "components/ui";
import { IoAndroidRefresh, IoPerson } from "react-icons/lib/io";
import { setPatientBatchIds } from "actions/batch";
import { createTab } from "actions/system-tray";
// import { exportPatientsReport } from "actions/exports";
// import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
// import IoMdEdit from "react-icons/lib/io/edit";
// import { TableSettingsNew } from "hooks/useTableSettings";
// import { put } from "utils/api";
// import { useFetch } from "hooks";
// import { errorMessage } from "actions/message";
import { uniq } from "ramda";

const TasksTableControls = ({
  // rows,
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows
  // tableSettings,
  // resetTableSettings,
  // updateTableSettings
}) => {
  const dispatch = useDispatch();

  // const bulkUpdatePatientTasksAPI = useFetch({
  //   apiFn: payload => put("tasks/bulk-update", payload),
  //   onError: () => dispatch(errorMessage("Failed to update tasks"))
  // });
  return (
    <div className="query-table-controls" style={{ borderRadius: 0 }}>
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoAndroidRefresh />
      </TableButton>
      <TableButton
        title={`Process Tasks: (${selectedRows.length} selected)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          const patientIds = uniq(selectedRows.map(x => x.patient_id));
          const patientNames = uniq(selectedRows.map(x => x.patient_name));
          dispatch(setPatientBatchIds(patientIds, patientNames));
          dispatch(
            createTab({
              type: "patients",
              key: "patients",
              label: `Patients (${patientIds.length})`,
              meta: { ids: patientIds }
            })
          );
          clearSelectedRows();
        }}
      >
        <IoPerson size={24} />
      </TableButton>
      {/* <PureModal */}
      {/*   renderTrigger={({ openModal }) => ( */}
      {/*     <TableButton */}
      {/*       disabled={selectedRows.length === 0} */}
      {/*       onClick={openModal} */}
      {/*       title={`Bulk update Tasks - (${selectedRows.length} selected)`} */}
      {/*     > */}
      {/*       <IoMdEdit /> */}
      {/*     </TableButton> */}
      {/*   )} */}
      {/*   renderContent={({ closeModal }) => ( */}
      {/*     <BulkUpdateTasks */}
      {/*       tasks={selectedRows} */}
      {/*       clearSelectedRows={clearSelectedRows} */}
      {/*       closeModal={closeModal} */}
      {/*       refreshData={refreshData} */}
      {/*       handleBulkUpdateTasks={async payload => { */}
      {/*         await bulkUpdatePatientTasksAPI.fetchData(payload); */}
      {/*         clearSelectedRows(); */}
      {/*         refreshData(); */}
      {/*         closeModal(); */}
      {/*       }} */}
      {/*     /> */}
      {/*   )} */}
      {/* /> */}
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} CMNS</span>
        )}
      </div>
      <div className="action-buttons companies-table-action-buttons">
        {/* <TableSettingsNew */}
        {/*   tableSettings={tableSettings} */}
        {/*   updateTableSettings={updateTableSettings} */}
        {/*   resetTableSettings={resetTableSettings} */}
        {/* /> */}
      </div>
    </div>
  );
};

export default TasksTableControls;

TasksTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired
  // tableSettings: PropTypes.object.isRequired,
  // resetTableSettings: PropTypes.func.isRequired,
  // updateTableSettings: PropTypes.func.isRequired,
  // rows: PropTypes.arrayOf(PropTypes.object).isRequired
};
