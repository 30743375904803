import C from "action-types";
import { createReducer } from "utils/reducer";

const insuranceImport = createReducer(
  {
    isFetching: false,
    accountNumberFailures: []
  },
  {
    [C.INSURANCE.INSURANCE_IMPORT_REQUEST]: () => ({
      isFetching: true,
      accountNumberFailures: []
    }),
    [C.INSURANCE.INSURANCE_IMPORT_SUCCESS]: () => ({
      isFetching: false,
      accountNumberFailures: []
    }),
    [C.INSURANCE.INSURANCE_IMPORT_FAILURE]: (_, { payload }) => ({
      isFetching: false,
      accountNumberFailures: payload
    })
  }
);

export default insuranceImport;
