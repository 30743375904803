import { useState, useCallback, useEffect } from "react";
import * as R from "ramda";

export {
  SelectRowContext,
  RowSelectCell,
  RowSelectHeaderCell
} from "./components";

/**
 * React Hook to provide local functioniality to handle table row selection
 *
 * @param {Object} options
 *    rows: Array<Object>*
 *    key: String(defaults to "id")
 *    query: Object
 *    metaExtractorFn: Function -> Object
 * @return {object}
 *    selectedRows : Array<Object>
 *    isRowSelected  : Function
 *    toggleAllRows  : Function
 *    clearSelectedRows  : Function
 *    toggleRow  : Function
 * @example
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "id", query, row => ({
    invoice_number: row.invoice_number
  }));
 */
function getRowMetaData(id, key, rows, metaExtractorFn) {
  if (typeof metaExtractorFn !== "function") return {};
  else {
    const row = rows.find(row => row[key] == id);
    return metaExtractorFn(row);
  }
}
const useSelectRow = (rows = [], key, query = {}, metaExtractorFn) => {
  const [selectedRows, setSelectedRows] = useState({});
  function toggleRow({ target }) {
    setSelectedRows(sel =>
      R.assoc(
        target.name,
        {
          id: target.name,
          checked: target.checked,
          ...getRowMetaData(target.name, key, rows, metaExtractorFn)
        },
        sel
      )
    );
  }
  const isRowSelected = useCallback(
    id => R.pathOr(false, [id, "checked"], selectedRows),
    [selectedRows]
  );
  function toggleAllRows() {
    const ids = rows.map(r => r[key]);
    if (
      R.all(row => R.pathEq(true, [row[key], "checked"], selectedRows), rows)
    ) {
      setSelectedRows({});
    } else {
      setSelectedRows({
        ...selectedRows,
        ...(ids?.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              id,
              checked: true,
              ...getRowMetaData(id, key, rows, metaExtractorFn)
            }
          }),
          {}
        ) ?? {})
      });
    }
  }
  function clearSelectedRows() {
    setSelectedRows({});
  }

  useEffect(() => {
    setSelectedRows({});
  }, [query.filters, query.sort]);

  return {
    toggleAllRows,
    toggleRow,
    selectedRows: R.pipe(R.values, R.filter(R.prop("checked")))(selectedRows),
    isRowSelected,
    clearSelectedRows
  };
};

export default useSelectRow;
