import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort, useFetch } from "hooks";
import { Link } from "react-router-dom";
import TableSortableHeader from "components/ui/table-sortable-header";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import PureModal from "components/ui/modal/pure-modal";
import { aLink } from "utils/styles";
import { CoachContactModal } from "./coach-contacts-modal";
import { FaGlobe } from "react-icons/lib/fa";

const CoachContactsTable = ({ data, dates }) => {
  const [modalTotal, setModalTotal] = useState(0);
  const [breakdown, setBreakdown] = useState([]);

  const { ascending, sortBy, getSortedArray, toggleSortDirection, setSortBy } =
    useSort({ by: "Coach", ascending: false });

  const {
    response: breakdownResponse,
    fetchData: getBreakdownCategory,
    isFetching: isFetching
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("breakdown-by-category", payload),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to pull breakdown: ${error.message}`);
    }
  });

  const {
    response: breakdownResponseType,
    fetchData: getBreakdownType,
    isFetching: isFetchingType
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("breakdown-by-type", payload),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to pull breakdown: ${error.message}`);
    }
  });

  const {
    response: breakdownResponseTotal,
    fetchData: getBreakdownTotal,
    isFetching: isFetchingTotal
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("breakdown-total", payload),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to pull breakdown: ${error.message}`);
    }
  });

  const getBreakdownByCategory = (category, guid) => {
    const payload = {
      type_Category: category,
      GUID: guid,
      startDate: dates.start_date,
      endDate: dates.end_date
    };
    getBreakdownCategory(payload);
  };
  const getBreakdownByType = (type, guid) => {
    const payload = {
      type_Category: type,
      GUID: guid,
      startDate: dates.start_date,
      endDate: dates.end_date
    };
    getBreakdownType(payload);
  };

  const getBreakdownByTotal = guid => {
    const payload = {
      GUID: guid,
      startDate: dates.start_date,
      endDate: dates.end_date
    };
    getBreakdownTotal(payload);
  };

  useEffect(() => {
    setBreakdown(breakdownResponse);
  }, [breakdownResponse]);

  useEffect(() => {
    setBreakdown(breakdownResponseType);
  }, [breakdownResponseType]);

  useEffect(() => {
    setBreakdown(breakdownResponseTotal);
  }, [breakdownResponseTotal]);

  useEffect(() => {
    setModalTotal(
      breakdown.reduce((total, element) => {
        return total + element.calls;
      }, 0)
    );
  }, [breakdown]);

  return (
    <table>
      <TableSortableHeader
        columns={[
          { key: "Coach", text: "Name" },
          {
            key: "phone.outgoing_call.successes",
            text: "Outgoing Calls"
          },
          {
            key: "phone.incoming_call.successes",
            text: "Inbound Calls"
          },
          { key: "phone.left_voicemail.successes", text: "Voicemails" },
          { key: "email.success_total", text: "Emails" },
          { key: "sms.text_message.successes", text: "Texts" },
          {
            key: "sms.push_notification.successes",
            text: "Notifications"
          },
          { key: "exposed_contacts_success_total", text: "Total" }
        ]}
        sortBy={sortBy}
        ascending={ascending}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      <tbody>
        {getSortedArray(data).map(d => (
          <tr key={d.COACH_GUID}>
            <td>
              <Link to={`/users/${d.COACH_GUID}`}>
                {d.Coach} {d.contracted_employee ? <FaGlobe /> : ""}
              </Link>
            </td>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getBreakdownByCategory("Outgoing Call", d.COACH_GUID);
                    openModal();
                  }}
                >
                  {d.phone.outgoing_call.successes}
                </td>
              )}
            >
              <CoachContactModal
                modalTotal={modalTotal}
                modalType="Outgoing Calls"
                breakdown={breakdown}
                isFetching={isFetching}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getBreakdownByCategory("Incoming Call", d.COACH_GUID);
                    openModal();
                  }}
                >
                  {d.phone.incoming_call.successes}
                </td>
              )}
            >
              <CoachContactModal
                modalTotal={modalTotal}
                modalType="Incoming Calls"
                breakdown={breakdown}
                isFetching={isFetching}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getBreakdownByCategory("Left Voicemail", d.COACH_GUID);
                    openModal();
                  }}
                >
                  {d.phone.left_voicemail.successes}
                </td>
              )}
            >
              <CoachContactModal
                modalTotal={modalTotal}
                modalType="Voicemails"
                breakdown={breakdown}
                isFetching={isFetching}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getBreakdownByType("Email", d.COACH_GUID);
                    openModal();
                  }}
                >
                  {d.email.success_total}
                </td>
              )}
            >
              <CoachContactModal
                modalTotal={modalTotal}
                modalType="Emails"
                breakdown={breakdown}
                isFetching={isFetchingType}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getBreakdownByCategory("Text Message", d.COACH_GUID);
                    openModal();
                  }}
                >
                  {d.sms.text_message.successes}
                </td>
              )}
            >
              <CoachContactModal
                modalTotal={modalTotal}
                modalType="Text Messages"
                breakdown={breakdown}
                isFetching={isFetching}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getBreakdownByCategory("Push Notification", d.COACH_GUID);
                    openModal();
                  }}
                >
                  {d.sms.push_notification.successes}
                </td>
              )}
            >
              <CoachContactModal
                modalTotal={modalTotal}
                modalType="Push Notifications"
                breakdown={breakdown}
                isFetching={isFetching}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getBreakdownByTotal(d.COACH_GUID);
                    openModal();
                  }}
                >
                  {d.exposed_contacts_success_total}
                </td>
              )}
            >
              <CoachContactModal
                modalTotal={modalTotal}
                modalType="Total"
                breakdown={breakdown}
                isFetching={isFetchingTotal}
              />
            </PureModal>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

CoachContactsTable.propTypes = {
  dates: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  data: selectors.getTeamCoachInsights(state)
}))(CoachContactsTable);
