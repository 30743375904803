import React from 'react'
import PropTypes from "prop-types";

const ActivationReportTable = ({ activationReport = {} }) => {
  return (
    <>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <>
              {activationReport.monthByMonthData.map((column) => (
                <td key={column.name}>{column.name}</td>
              ))}
            </>
          </tr>
          <>
            {["totalActivation", "totalDeactivation", ...activationReport.reasons].map(reason => {
              return (
                <tr key={reason}>
                  {reason === "totalActivation"
                    ? <td><strong>Total Activation</strong></td>
                    : reason === "totalDeactivation"
                      ? <td><strong>Total Deactivation</strong></td>
                      : <td><strong>{reason}</strong></td>}
                  {activationReport.monthByMonthData.map((column, i) => (
                    <td key={i}>{column[reason] || 0}</td>
                  ))}
                </tr>
              )
            })}
          </>
        </tbody>
      </table>
    </>
  )
}

export default ActivationReportTable;

ActivationReportTable.propTypes = {
  activationReport: PropTypes.shape({
    monthByMonthData: PropTypes.array,
    reasons: PropTypes.array
  })
};
