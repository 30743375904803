import React from "react";
import PropTypes from "prop-types";

const ToggleSlider = ({ width, value, onChange }) => {
  return (
    <div
      className="switch-wrap"
      style={{ width: width, borderRadius: width }}
      onClick={() => onChange(!value)}
    >
      <input
        type="checkbox"
        checked={value}
        onChange={() => console.log("hello")}
      />
      <div className="switch"></div>
    </div>
  );
};

ToggleSlider.propTypes = {
  width: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
};

export default ToggleSlider;
