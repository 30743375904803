import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { put as putBE } from "utils/api";
import { message } from "actions/message";
import FormikField from "components/forms/formik-field";
import { Formik } from "formik";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import IoIosInformationCircleOutline from "react-icons/lib/io/ios-information";
import PropTypes from 'prop-types'

const EmailUpdateForm = ({ patientGuidForJobs }) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);

  const updateEmail = async values => {
    dispatch(message("Updating Email..."));
    await putBE(`IVR/Work/UpdateEmail`, values);
    //Page reloads here for some reason, need to find out why
  };

  return (
    <div>
      {!showForm ? (
        <button onClick={() => setShowForm(true)}>Change Email Form</button>
      ) : (
        <Formik
          initialValues={{
            patient_guid: patientGuidForJobs,
            email: "",
            update_foc: false
          }}
        >
          {({ values, setFieldValue }) => (
            <form>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center"
                }}
              >
                <h2>Update Patient Email </h2>
                <Tooltip
                  id={`friendly_name`}
                  className="helpful-link-tooltip"
                  effect="solid"
                  message='Will update all patient orders with the status "IVR Pending Work".'
                >
                  <IoIosInformationCircleOutline size="1rem" />
                </Tooltip>
              </div>
              <label style={{ paddingRight: "2px" }}>
                Update Form of Contact to Email
                <input
                  name="update_foc"
                  type="checkbox"
                  className="orderAddressSameCheckbox"
                  checked={values.permanent_Email_change}
                  onChange={() =>
                    setFieldValue("update_foc", !values.update_foc)
                  }
                />
              </label>
              <FormikField name="email" label="Email" type="text" />

              <button
                style={{ marginRight: "10px" }}
                onClick={() => setShowForm(false)}
              >
                Cancel
              </button>
              <button
                disabled={values.email == ""}
                onClick={() => {
                  updateEmail(values);
                }}
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

EmailUpdateForm.propTypes = {
  patientGuidForJobs: PropTypes.string.isRequired
};

export default EmailUpdateForm;
