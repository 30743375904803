import React from "react";
import CoachStatus from "../coach-status";
import CoachOrders from "../coach-orders";
import CoachContacts from "../coach-contacts";
import CoachOpenOrders from "../coach-open-orders";
import TeamDashboardContext from "../context";
import HasRolePermission from "components/shared/has-role-permission";
import CoachLogins from "../coach-logins/index";

const TeamCoaches = () => {
  const { minimizeAll, selectedTeam, requestedDates } = React.useContext(
    TeamDashboardContext
  );

  return (
    <div className="team-dashboard-coaches-container">
      <CoachStatus
        selectedTeam={selectedTeam}
        minimizeAll={minimizeAll}
        requestedEndDate={requestedDates.end_date}
      />
      <CoachContacts minimizeAll={minimizeAll} dates={requestedDates} />
      <CoachOrders minimizeAll={minimizeAll} />
      <HasRolePermission allowedRoles={["Administrator"]}>
        <CoachOpenOrders minimizeAll={minimizeAll} />
      </HasRolePermission>
      <CoachLogins
        minimizeAll={minimizeAll}
        requestedDates={requestedDates}
        selectedTeam={selectedTeam}
      />
    </div>
  );
};

export default TeamCoaches;
