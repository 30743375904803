import React, { useState } from "react";
import { PureModal, Spinner, ConfirmationDialog } from "components/ui";
import { useDispatch } from "react-redux";
import TimeSlotSearch from "../assign-chat/time-slot-search";
import { useFetch } from "hooks";
import { post } from "utils/api";
import { errorMessage, message } from "actions/message";
import { PropTypes } from 'prop-types';
import { getApiErrorMessageCustomPathEnd } from "utils/misc";

const RescheduleAppointmentModal = ({ chat_type, chat_id, onSuccess, closeModal, slotId })=> {

    const [displaySpinner, setDisplaySpinner] = useState(false);

    const [values, setValues] = useState({chat_type: "",slot_id: "",topic_category_id: ""})

    const dispatch = useDispatch()

    function closeRescheduleModal(){
        document.getElementById("closeRescheduleModalButton").click();
    }

    const {
        fetchData : rescheduleChat
    } = useFetch({
        apiFn: values => post("dme_portal/reschedule_chat", values),
        defaultValue: [],
        onError: error => {
            dispatch(errorMessage(`Failed to reschedule: ${getApiErrorMessageCustomPathEnd(error, "message")}`))
        },
        onSuccess: () => {
            dispatch(message(`Rescheduled successfully`))
            closeRescheduleModal()
            onSuccess()
        }
    });

    const submit = async () => {
        setDisplaySpinner(true);
        await rescheduleChat({chat_id: slotId ? slotId : chat_id, slot_id: values.slot_id, replace_topics: false, keep_old_slot_active: true})
        setDisplaySpinner(false);
    }

    return(
        <div style={{padding: "10px"}}>
            <TimeSlotSearch values={values} setValues={setValues} appointmentChatType={chat_type} setEnableTopic={() => {}} setEnableDescription={() => {}}/>
            <button id="closeRescheduleModalButton" style={{display: "none"}} onClick={() => closeModal()}></button>
                <PureModal
                    maxWidth="sm"
                    fullWidth
                    renderTrigger={({ openModal }) => (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                {
                                    displaySpinner ?
                                    <Spinner />
                                    :
                                    <button
                                        id="rescheduleConfirmationModal"
                                        className="appointment-action-button reschedule-button"
                                        style={{ marginLeft: 10 }}
                                        onClick={() => {openModal()}}
                                        disabled={values.slot_id == ""}
                                    >
                                        Confirm Time Slot
                                    </button>
                                }
                            </div>
                        )
                    }
                    renderContent={({ closeModal }) => (
                        <div>
                            <ConfirmationDialog
                                cancelButtonStyles="appointment-action-button cancel-action-button"
                                confirmButtonStyles="appointment-action-button reschedule-button"
                                continueMsg="Yes"
                                onCancel={closeModal}
                                onConfirm={() => {closeModal() ;submit(closeModal)}}>
                                <h3>Are you sure you want to reschedule the appointment?</h3>
                            </ConfirmationDialog>
                        </div>
                    )}
                />
        </div>
    )

}

RescheduleAppointmentModal.propTypes = {
    chat_type: PropTypes.number,
    chat_id: PropTypes.string,
    onSuccess: PropTypes.func,
    closeModal: PropTypes.func,
    slotId: PropTypes.string
};

export default RescheduleAppointmentModal;
