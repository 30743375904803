import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MdEdit from "react-icons/lib/md/edit";
import MdDelete from "react-icons/lib/md/delete";
import IconButton from "components/ui/icon-button";

const CmnRow = ({
  Comments,
  GUID,
  HCPC,
  Product,
  cmnInitialDate,
  cmnExpirationDate,
  handleDelete,
  handleEdit,
  updated,
  readOnly,
  // updated_by
}) => (
  <tr style={{ height: 65 }}>
    <td>{HCPC}</td>
    <td>{Product}</td>
    <td>{cmnInitialDate ? moment(cmnInitialDate).format("LL") : ""}</td>
    <td>{cmnExpirationDate ? moment(cmnExpirationDate).format("LL") : ""}</td>
    <td>{Comments}</td>
    <td>
      <div>{moment(updated).format("L")}</div>
      {/*<div>{updated_by}</div>*/}
    </td>
    <td>
      <div style={{ float: "right" }}>
        <IconButton
          onClick={handleEdit}
          text="Edit"
          icon={<MdEdit />}
          style={{ padding: 3, marginRight: 10 }}
          type="button"
          disabled={readOnly}
        />
        <IconButton
          onClick={() => handleDelete(GUID)}
          text="Delete"
          style={{ color: "red", padding: 3 }}
          icon={<MdDelete />}
          type="button"
          disabled={readOnly}
        />
      </div>
    </td>
  </tr>
);

CmnRow.propTypes = {
  Comments: PropTypes.string,
  GUID: PropTypes.string.isRequired,
  HCPC: PropTypes.string,
  Product: PropTypes.string,
  cmnExpirationDate: PropTypes.string,
  cmnInitialDate: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  updated: PropTypes.string,
  updated_by: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default CmnRow;
