import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ImportResults = ({ results, title, fields }) => {
  const createLink = (result, linkText) => {
    if (linkText) {
      const regex = /\{.*\}/g;
      const linkVars = linkText.match(regex);
      let failure = false;
      linkVars.forEach(linkVar => {
        const temp = result[linkVar.slice(1, -1)];
        failure |= temp == undefined;
        linkText = linkText.replace(linkVar, temp);
      });
      if (failure) linkText = undefined;
    }
    return linkText;
  };

  const getBackgroundColor = rowNum => {
    if (rowNum % 2 == 0) return "#f8f8f8";
    return "#ffffff";
  };
  return (
    <>
      {results && results.length > 0 && (
        <>
          <h3>{title}</h3>
          <div
            className="results-table"
            style={{ gridTemplateColumns: "1fr ".repeat(fields.length) }}
          >
            {fields.map(field => (
              <div key={field.name} className="header">
                {field.title}
              </div>
            ))}
            {results.map((result, row) =>
              fields.map((field, key) => {
                const link = createLink(result, field.link);
                if (link)
                  return (
                    <Link
                      key={key}
                      className="entry"
                      to={link}
                      style={{ backgroundColor: getBackgroundColor(row) }}
                    >
                      {result[field.name]}
                    </Link>
                  );
                else
                  return (
                    <div
                      key={key}
                      className="entry"
                      style={{ backgroundColor: getBackgroundColor(row) }}
                    >
                      {result[field.name]}
                    </div>
                  );
              })
            )}
          </div>
        </>
      )}
    </>
  );
};

ImportResults.propTypes = {
  results: PropTypes.array,
  title: PropTypes.string,
  fields: PropTypes.array
};

export default ImportResults;
