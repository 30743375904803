import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageHeader from "components/ui/page-header";
import { submitBackorderImport } from "actions/backorder-import";
import { Waiting } from "components/ui";
import ImportResults from "./import-results";

const BackorderImportForm = ({
  isFetching,
  submitBackorderImport,
  results
}) => {
  const [headers, setHeaders] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const filesInput = useRef(null);

  const onBackorderImportSubmit = async e => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", filesInput.current.files[0]);
    formData.append("headers", headers);

    const { name: fileName } = filesInput.current.files[0];
    const confirmed = window.confirm(
      `Are you sure? Update backorders \n File: ${fileName}`
    );
    if (confirmed) {
      try {
        submitBackorderImport(formData);
      } finally {
        filesInput.current.value = "";
      }
    }
  };

  const disableInputButton = () => {
    return isFetching || !filesInput.current?.files.length;
  };

  return (
    <div>
      <PageHeader title="Backorder Importer" />
      <form
        encType="multipart/form-data"
        onSubmit={e => onBackorderImportSubmit(e)}
        style={{
          display: "grid",
          gridTemplateColumns: "500px",
          gridGap: 10
        }}
      >
        <input
          ref={filesInput}
          name="file"
          required
          type="file"
          onChange={forceUpdate}
          style={{ fontSize: "1.05rem" }}
        />
        <label>
          Headers
          <input
            style={{ marginLeft: "10px" }}
            name="headersCheckboxChecked"
            type="checkbox"
            checked={headers}
            onChange={() => setHeaders(!headers)}
          />
        </label>
        <input
          className="button"
          type="submit"
          value="Submit"
          disabled={disableInputButton()}
        />
      </form>
      <ImportResults
        results={results}
        title="Results"
        fields={[
          { title: "PO Number", name: "PONumber", link: "/orders/{orderId}" },
          { title: "Status", name: "Status" }
        ]}
      />
      {isFetching && (
        <div style={{ margin: "100px 0px 0px 0px" }}>
          <Waiting reason={`Submitting Backorder Updates`} />
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    isFetching: state.getIn(["backorder-import"]).isFetching,
    results: state.getIn(["backorder-import"]).results,
  }),
  {
    submitBackorderImport
  }
)(BackorderImportForm);

BackorderImportForm.propTypes = {
  isFetching: PropTypes.bool,
  submitBackorderImport: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired
};
