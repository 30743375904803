import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";

const ToggleSwitch = ({
  value,
  onChange,
  isUpdating,
  label,
  title,
  disabled
}) => (
  <label
    className={`toggle-switch-container ${disabled ? "disabled" : ""}`}
    title={title}
  >
    {label && <span className="toggle-switch-label">{label}</span>}
    <span className="toggle-switch">
      <input
        className="toggle-input"
        name="toggle-input"
        checked={!!value}
        type="checkbox"
        readOnly
        disabled={disabled}
        onClick={event => {
          event.stopPropagation();
          onChange(!value);
        }}
      />
      {isUpdating ? <InlineWaiting /> : <span className="toggle-slider" />}
    </span>
  </label>
);

ToggleSwitch.propTypes = {
  value: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ToggleSwitch;
