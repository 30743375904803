import React from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import {
  playAutomatedVoicemail,
  reconnectIVRPatientWithIVRSystem
} from "actions/phone";

const PatientPhoneActions = ({
  dialedNumber,
  callDirection,
  phoneIsConnected,
  patientInfo,
  playAutomatedVoicemail,
  reconnectIVRPatientWithIVRSystem
}) => {
  const displayAutoVMailButton =
    (dialedNumber == patientInfo.mobile_number ||
      dialedNumber == patientInfo.phone_number) &&
    phoneIsConnected == true &&
    callDirection == "outbound";
  const showSendToIvr =
    patientInfo.form_of_contact === "IVR" &&
    patientInfo.is_ivr_patient &&
    phoneIsConnected == true &&
    callDirection == "inbound";
  return (
    <React.Fragment>
      {displayAutoVMailButton && (
        <div
          onClick={() => playAutomatedVoicemail(patientInfo.id)}
          className="automatedVoicemailButton"
        >
          Send Automated Voicemail
        </div>
      )}
      {showSendToIvr && (
        <div
          onClick={() => reconnectIVRPatientWithIVRSystem(patientInfo.id)}
          className="automatedVoicemailButton"
        >
          Put Patient Back In IVR System
        </div>
      )}
    </React.Fragment>
  );
};

PatientPhoneActions.propTypes = {
  dialedNumber: PropTypes.string,
  callDirection: PropTypes.string,
  phoneIsConnected: PropTypes.bool,
  patientInfo: PropTypes.object.isRequired,
  playAutomatedVoicemail: PropTypes.func.isRequired,
  reconnectIVRPatientWithIVRSystem: PropTypes.func.isRequired
};

export default connect(
  state => ({
    phoneIsConnected: selectors.getIsPhoneConnected(state),
    callDirection: selectors.getCallDirection(state),
    dialedNumber: selectors.getDialedNumber(state)
  }),
  {
    playAutomatedVoicemail,
    reconnectIVRPatientWithIVRSystem
  }
)(PatientPhoneActions);
