import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { invalidateOrderInfo } from "actions/order-profile";
import MdRefresh from "react-icons/lib/md/refresh";

const RefreshOrderButton = ({
  orderId,
  invalidateOrderInfo,
  isFetchingOrderInfo
}) => (
  <div className="no-print order-refresh-button">
    <MdRefresh
      color="grey"
      size="28px"
      onClick={() => {
        if (!isFetchingOrderInfo) invalidateOrderInfo(orderId);
      }}
    />
  </div>
);

RefreshOrderButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  invalidateOrderInfo: PropTypes.func.isRequired,
  isFetchingOrderInfo: PropTypes.bool
};

export default connect(
  (state, { orderId }) => {
    const { isFetching } = selectors.getOrderInfo(state, { orderId });
    return {
      isFetchingOrderInfo: isFetching
    };
  },
  dispatch => ({
    invalidateOrderInfo: orderId => dispatch(invalidateOrderInfo({ orderId })())
  })
)(RefreshOrderButton);
