import { SHOW_LOADER, HIDE_LOADER } from './../ActionTypes';

export const showLoader = () => dispatch => {
    return dispatch({
        type: SHOW_LOADER
    });
}

export const hideLoader = () => dispatch => {
    return dispatch({
        type: HIDE_LOADER
    });
}

