import React, { useEffect, useState } from 'react';
import { BarChart, XAxis, YAxis, Tooltip, Bar, LabelList, Legend } from "recharts";
import { PropTypes } from 'prop-types';
import HeaderChart from './header-chart';

export default function ComplianceDashboardDoubleBarChart({ data, onItemSelected, startDate, endDate, companyId, isFetching }) {

    const [maxValue, setMaxValue] = useState(0);
    const [dataForCheckboxList, setDataForCheckboxList] = useState([])
    const [usingIndividualInfo, setUsingIndividualInfo] = useState(false)
    const [chartData, setChartData] = useState([])
    const [labelMaxWidth, setLabelMaxWidth] = useState(0);

    function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.font = font || getComputedStyle(document.body).font;
        return context.measureText(text).width;
    }

    useEffect(() => {
        if (data) {
            const checkItems = data.GrouperOptions.map(({ Text, Value }) => {
                return { value: Value, text: Text, elementId: "item" + Value }
            });

            setChartData(data.Results);
            setDataForCheckboxList(checkItems);

            data.Results.map(item => {
                const textWidth = getTextWidth(item.Grouper.Text);
                setLabelMaxWidth(textWidth > labelMaxWidth ? textWidth : labelMaxWidth);
                setMaxValue(item.TotalCount > maxValue ? item.TotalCount : maxValue);
            })
        }
    }, [data])

    const updateChartData = (individualData) => {
        setUsingIndividualInfo(true);
        setChartData(individualData);
    };

    const CustomTooltip = (data) => {
        if (data.active && data.payload && data.payload.length) {
            return (
                <div>
                    <div className="bar-charts-custom-tooltip">
                        <p className="custom-tooltip-label">{data.payload[0].payload.Grouper.Text}</p>
                    </div>
                    <ul className='custom-ul'>
                        <li className='custom-li completed'>
                            <span>  Completed: {data.payload[0].payload.Completed} </span>
                        </li>
                        <li className='custom-li pending'>
                            <span> Pending: {data.payload[0].payload.Pending} </span>
                        </li>
                    </ul>
                </div>
            );
        }
        return null;
    };

    const renderLabel = (prop, isCompletedBar) => {
        const { index } = prop;
        const target = chartData[index];
        return isCompletedBar ? target?.Completed : target?.Pending;
    };

    const splitString = (data) => {
        let searchedLength = 8;
        if (data?.Grouper?.Text?.length > searchedLength) {
            let aux = data?.Grouper?.Text?.slice(0, searchedLength);
            aux += "...";
            return aux;
        } else {
            return data?.Grouper?.Text;
        }
    };

    return (
        <div>
            <HeaderChart
                categoryName={`Owned by ${data.CategoryName}`}
                category={data.Category}
                startDate={startDate}
                endDate={endDate}
                companyId={companyId}
                updateData={updateChartData}
                dataForCheckboxList={dataForCheckboxList}
                isFetching={isFetching}
            />
            {
                !isFetching && chartData?.length === 0 ?
                    <div>
                        <h5 style={{ textAlign: "center", color: "red" }}>
                            {usingIndividualInfo ?
                                "No registers found for the selected filters"
                                : "No registers found for this chart"
                            }
                        </h5>
                    </div>
                    :
                    <div>
                        <div><BarChart
                            width={400}
                            height={300}

                            data={chartData}
                        >
                            <XAxis dataKey={splitString} />
                            <YAxis domain={[0, maxValue + 2]} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" />
                            <Bar
                                dataKey="Completed"
                                fill="rgb(68,114,196)"
                                onClick={(values) => {
                                    onItemSelected({ value: values.Grouper.Value, category: data.Category, filterName: values.Grouper.Text, categoryName: data.CategoryName })
                                }}
                            >
                                <LabelList
                                    content={props => renderLabel(props, true)}
                                    position="top"
                                    style={{ pointerEvents: 'none' }}
                                    isAnimationActive={false}
                                />
                            </Bar>
                            <Bar dataKey="Pending" fill="rgb(237,125,49)" onClick={(values) => {
                                onItemSelected({ value: values.Grouper.Value, category: data.Category, filterName: values.Grouper.Text, categoryName: data.CategoryName })
                            }}>
                                <LabelList
                                    content={props => renderLabel(props, false)}
                                    position="top"
                                    style={{ pointerEvents: 'none' }}
                                    isAnimationActive={false}
                                />
                            </Bar>
                        </BarChart>
                        </div>
                    </div>
            }
        </div>
    );
}

ComplianceDashboardDoubleBarChart.propTypes = {
    data: PropTypes.object,
    onItemSelected: PropTypes.func,
    startDate: PropTypes.date,
    endDate: PropTypes.date,
    companyId: PropTypes.string,
    isFetching: PropTypes.bool
}