import React from "react";
import PropTypes from "prop-types";
import RaEligibilityBtn from "features/referral-answer/eligibility/eligibility-btn";

const InsuranceVerification = ({ companyInfo, orderId }) => {
  if (
    companyInfo?.insurance_verification_type === "ReferralAnswer" &&
    companyInfo?.eligible_for_insurance_verification
  ) {
    return <RaEligibilityBtn orderId={orderId} />;
  } else if (
    companyInfo?.insurance_verification_type === "ReferralAnswer" &&
    !companyInfo?.eligible_for_insurance_verification
  ) {
    return (
      <div
        style={{
          marginTop: "20px",
          gridColumn: "1/-1",
          fontWeight: "bold",
          color: "red",
          fontSize: "1rem"
        }}
      >
        Not Eligible for Insurance Verification
      </div>
    );
  }
  return null;
};

InsuranceVerification.defaultProps = {};

InsuranceVerification.propTypes = {
  companyInfo: PropTypes.object,
  orderId: PropTypes.string.isRequired
};

export default InsuranceVerification;
