import React from "react";
import PropTypes from "prop-types";
import { PageHeader } from "components/ui";
import { fetchIvrJobs } from "actions/ivr-jobs";
import { killCompanyIVRJob } from "actions/company";
import { connect } from "react-redux";
import { selectors } from "reducers";
import useDateRange from "hooks/useDateRange";
import DateRange from "components/ui/date-range";
import { Link } from "react-router-dom";
import { today } from "utils/dates";
import moment from "moment";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";

const IvrJobTableRow = ({
  companyGuid,
  companyName,
  patientsToBatch,
  maxThreadsAllowed,
  limitPatientCallsForRequest,
  maxPatientCallsForThread,
  currentState,
  threadStart,
  completedReason,
  lastWaitStart,
  startedBy,
  threadName,
  patientsEligibleForOutreach,
  threadEnd,
  uniquePatientsContacted,
  waitMinuteBetweenBatches,
  error,
  errorMessage,
  handleKillCompanyIVRJob
}) => (
  <tr className="ivr-job">
    <td style={{ fontSize: "1.25em" }}>
      <Link to={`/companies/${companyGuid}`}>{companyName}</Link>
    </td>
    <td
      title={`batch: ${patientsToBatch}, maxThread: ${maxThreadsAllowed}, limitCalls: ${JSON.stringify(
        limitPatientCallsForRequest
      )}, maxCallsPerThread: ${maxPatientCallsForThread}, waitMinuteBetweenBatches: ${waitMinuteBetweenBatches}`}
      className="ivr-status"
    >
      {currentState}
    </td>
    <td>{startedBy} </td>
    <td>
      {threadStart ? moment(threadStart).format("MMM DD, YYYY hh:mm A") : ""}
    </td>
    <td>
      {lastWaitStart
        ? moment(lastWaitStart).format("MMM DD, YYYY hh:mm A")
        : ""}
    </td>
    <td>{threadEnd ? moment(threadEnd).format("MMM DD, YYYY hh:mm A") : ""}</td>
    <td>{completedReason}</td>
    <td>{patientsEligibleForOutreach}</td>
    <td>{uniquePatientsContacted}</td>
    <td>{threadName}</td>
    <td>
      {error ? <span style={{ color: "orange" }}>{errorMessage}</span> : ""}
    </td>
    <td>
      {currentState != "completed" && (
        <span
          style={{
            color: "red",
            cursor: "pointer",
            textDecoration: "underline"
          }}
          onClick={handleKillCompanyIVRJob}
        >
          Kill Job
        </span>
      )}
    </td>
  </tr>
);

const IVRJobManagement = ({ fetchIvrJobs, ivrJobs, killCompanyIVRJob }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const { startDate, endDate, setStartDate, setEndDate, dateRangeIsValid } =
    useDateRange({
      startDate: today(),
      endDate: today()
    });
  const requestIvrJobs = async () => {
    try {
      setIsFetching(true);
      await fetchIvrJobs(startDate, endDate);
    } finally {
      setIsFetching(false);
    }
  };
  React.useEffect(() => {
    requestIvrJobs();
  }, []);
  const { rows, query, queryDispatcher } = useLocalQuery(ivrJobs, {
    sort: { by: "threadStart", direction: "desc" },
    filters: { currentState: ["waiting", "processing", "started"] }
  });
  return (
    <div>
      <PageHeader title="IVR Job Management">
        <DateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSubmit={(start_date, end_date) => {
            requestIvrJobs(start_date, end_date);
          }}
          isFetching={isFetching}
          isValid={dateRangeIsValid}
        />
      </PageHeader>
      <div className="ivr-job-managment-container">
        <Table
          query={query}
          queryDispatcher={queryDispatcher}
          rows={rows}
          TableRow={row => (
            <IvrJobTableRow
              {...row}
              handleKillCompanyIVRJob={async () => {
                try {
                  await killCompanyIVRJob(row.companyGuid);
                } catch (e) {
                  /* handle error */
                } finally {
                  requestIvrJobs();
                }
              }}
            />
          )}
          rowKey="id"
          columns={[
            {
              field: "companyName",
              header: "Company"
            },
            {
              field: "currentState",
              header: "Status",
              filters: [
                { value: "completed", text: "Completed" },
                { value: "waiting", text: "Waiting" },
                { value: "started", text: "Started" },
                { value: "processing", text: "Processing" }
              ]
            },
            {
              field: "startedBy",
              header: "Started By"
            },
            { field: "threadStart", header: "Start Time" },
            {
              field: "lastWaitStart",
              header: "Last Wait"
            },
            {
              field: "threadEnd",
              header: "End Time"
            },
            { field: "completedReason", header: "Reason Completed" },
            {
              field: "patientsEligibleForOutreach",
              header: "Eligble Patients"
            },
            {
              field: "uniquePatientsContacted",
              header: "Patients Contacted"
            },
            {
              field: "threadName",
              header: "Thread Name"
            },
            {
              field: "error",
              header: "Error",
              filters: [
                { value: true, text: "Yes" },
                { value: false, text: "No" }
              ]
            },
            {
              field: "controls",
              header: " "
            }
          ]}
        />
      </div>
    </div>
  );
};

IvrJobTableRow.propTypes = {
  companyName: PropTypes.string.isRequired,
  patientsToBatch: PropTypes.number,
  threadName: PropTypes.string,
  maxThreadsAllowed: PropTypes.number,
  limitPatientCallsForRequest: PropTypes.bool,
  maxPatientCallsForThread: PropTypes.number,
  currentState: PropTypes.string,
  lastWaitStart: PropTypes.string,
  threadStart: PropTypes.string,
  startedBy: PropTypes.string,
  patientsEligibleForOutreach: PropTypes.number,
  threadEnd: PropTypes.string,
  uniquePatientsContacted: PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  completedReason: PropTypes.string,
  companyGuid: PropTypes.string.isRequired,
  waitMinuteBetweenBatches: PropTypes.number,
  handleKillCompanyIVRJob: PropTypes.func.isRequired
};

IVRJobManagement.propTypes = {
  ivrJobs: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchIvrJobs: PropTypes.func.isRequired,
  killCompanyIVRJob: PropTypes.func.isRequired
};

export default connect(
  state => ({
    ivrJobs: selectors.getIvrJobs(state)
  }),
  { fetchIvrJobs, killCompanyIVRJob }
)(IVRJobManagement);
