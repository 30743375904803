import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import compose from "lodash/fp/compose";
import { setDialerNumber } from "actions/phone";
import { resupplyEmailToPatient, updatePatient } from "actions/patient";
import { formatDate, getLocalTimeFromZip, getAge } from "utils/dates";
import SectionHeader from "components/profile/section_header";
import { Ul, AttributeItem } from "components/lists";
import { formatPhone } from "utils/misc";
import PureModal from "components/ui/modal/pure-modal";
import {
  InlineWaiting,
  withModal,
  ConfirmationDialog,
  StatusToggle,
  Spinner
} from "components/ui";
import ContactRecord from "components/profile/modals/contact-record";
import EditAccountInformation from "./edit_account_information";
import { FaEdit } from "react-icons/lib/fa";
import IconButton from "components/ui/icon-button";
import { MdEdit, MdSend } from "react-icons/lib/md";
import { aLink } from "utils/styles";
import HasAppPermission from "components/shared/has-app-permission";
import SmsPhoneStatus from "./sms-status";
import IoMdMinus from "react-icons/lib/io/minus";
import IoMdCheckmark from "react-icons/lib/io/checkmark";
import IoMdClose from "react-icons/lib/io/close";
import HasRolePermission from "components/shared/has-role-permission";

const AccountInformation = ({
  patientInfo,
  patientId,
  setDialerNumber,
  resupplyEmailToPatient,
  updatePatient,
  isUpdatingPatient,
  formOfContactOptions,
  genderOptions,
  openModal,
  closeModal,
  readOnly
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [updatingPatientInformation, setUpdatingPatientInformation] =
    useState(false);
  const [updatingCanEmail, setUpdatingCanEmail] = useState(false);

  async function toggleStatus(field) {
    setUpdatingCanEmail(true);
    await updatePatient({
      patientId,
      updates: { [field]: !patientInfo[field] }
    });
    setUpdatingCanEmail(false);
  }

  return isEditing ? (
    <EditAccountInformation
      updatePatient={async formValues => {
        if (
          formValues.form_of_contact === "Email" &&
          patientInfo.can_email === false
        ) {
          openModal(
            <ConfirmationDialog
              onCancel={closeModal}
              warningMsg="Cannot change patients form of contact to email if patient does allow resupply emails to be sent"
              continueMsg="Grant resupply email permission and continue?"
              onConfirm={async () => {
                closeModal();
                setIsEditing(false);
                setUpdatingPatientInformation(true);
                await updatePatient({
                  patientId,
                  updates: {
                    ...formValues,
                    date_of_birth: new Date(formValues.date_of_birth),
                    can_email: true
                  }
                });
                setUpdatingPatientInformation(false);
              }}
            />
          );
        } else {
          setIsEditing(false);
          setUpdatingPatientInformation(true);
          await updatePatient({
            patientId,
            updates: {
              ...formValues,
              date_of_birth: formValues.date_of_birth,
              setup_date: formValues.setup_date
            }
          });
          setUpdatingPatientInformation(false);
        }
        setIsEditing(false);
      }}
      patientId={patientId}
      formOfContactOptions={formOfContactOptions}
      genderOptions={genderOptions}
      handleCancel={() => {
        setIsEditing(false);
      }}
      initialValues={{
        form_of_contact: patientInfo.form_of_contact ?? "",
        can_email: patientInfo.can_email ?? false,
        phone_number: patientInfo.phone_number ?? "",
        mobile_number: patientInfo.mobile_number ?? "",
        email: patientInfo.email || "",
        speaks_spanish: patientInfo.speaks_spanish,
        accepts_email: patientInfo.accepts_email ?? true,
        accepts_text: patientInfo.accepts_text ?? true,
        accepts_push_notifications:
          patientInfo.accepts_push_notifications ?? true,
        gender: patientInfo.gender ?? "",
        date_of_birth: patientInfo?.date_of_birth
          ? formatDate(patientInfo.date_of_birth, "YYYY-MM-DD")
          : "",
        setup_date: patientInfo?.setup_date
          ? formatDate(patientInfo.setup_date, "YYYY-MM-DD")
          : ""
      }}
      patientInfo={patientInfo}
      setUpdatingPatientInformation={setUpdatingPatientInformation}
    />
  ) : (
    <div>
      <SectionHeader>
        Patient Information{" "}
        {!updatingPatientInformation ? (
          <IconButton
            icon={<MdEdit />}
            text="Edit"
            onClick={() => {
              setIsEditing(true);
            }}
            disabled={readOnly}
            style={{ fontSize: "12px", float: "right" }}
          />
        ) : null}
      </SectionHeader>
      {updatingPatientInformation ? (
        <InlineWaiting />
      ) : (
        <Ul>
          <HasAppPermission permission="orderSupplies">
            <AttributeItem title="Contact method:">
              {patientInfo.form_of_contact}
            </AttributeItem>
          </HasAppPermission>

          <AttributeItem title="Contact Types:">
            {patientInfo.form_of_contact === "Technology Only" && (
              <div>
                <div style={{ display: "flex" }}>
                  <div>Text Messages: </div>
                  <div
                    style={{
                      color: patientInfo.accepts_text ? "green" : "red"
                    }}
                  >
                    {patientInfo.accepts_text ? (
                      <IoMdCheckmark />
                    ) : (
                      <IoMdClose />
                    )}
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div>Push Notifications: </div>
                  <div
                    style={{
                      color: patientInfo.accepts_push_notifications
                        ? "green"
                        : "red"
                    }}
                  >
                    {patientInfo.accepts_push_notifications ? (
                      <IoMdCheckmark />
                    ) : (
                      <IoMdClose />
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>Email: </div>
                  {
                    <div
                      style={{
                        color: patientInfo.accepts_email ? "green" : "red"
                      }}
                    >
                      {patientInfo.accepts_email ? (
                        <IoMdCheckmark />
                      ) : (
                        <IoMdClose />
                      )}
                    </div>
                  }
                </div>
              </div>
            )}
          </AttributeItem>

          <AttributeItem
            title="Phone number:"
            actions={
              <HasRolePermission
                // DEV-15262 Admins and SAAS users only
                allowedRoles={["Administrator", "ServiceAdmin", "ServiceCoach"]}
              >
                <PureModal
                  renderTrigger={({ openModal }) => (
                    <HasAppPermission permission="orderSupplies">
                      <IconButton
                        icon={<FaEdit size={14} />}
                        onClick={openModal}
                        title="Create Contact Record"
                      />
                    </HasAppPermission>
                  )}
                  renderContent={({ closeModal }) => (
                    <ContactRecord
                      patientId={patientId}
                      closeSelf={closeModal}
                      initialType="Manual Call"
                    />
                  )}
                />
              </HasRolePermission>
            }
          >
            <div
              style={aLink}
              onClick={() => {
                setDialerNumber(patientInfo.phone_number, patientId);
              }}
            >
              {formatPhone(patientInfo.phone_number)}
            </div>
            {patientInfo.phone_number && (
              <SmsPhoneStatus
                text_consent={patientInfo.phone_number_text_consent}
                text_consent_information={
                  patientInfo.phone_number_text_consent_information
                }
              />
            )}
          </AttributeItem>
          <AttributeItem title="Phone type:">
            <div>{patientInfo.phone_number_type ?? "U"}</div>
          </AttributeItem>
          <AttributeItem title="Mobile number:">
            <div
              style={aLink}
              onClick={() => {
                setDialerNumber(patientInfo.mobile_number, patientId);
              }}
            >
              {formatPhone(patientInfo.mobile_number)}
            </div>
            {patientInfo.mobile_number && (
              <SmsPhoneStatus
                text_consent={patientInfo.mobile_number_text_consent}
                text_consent_information={
                  patientInfo.mobile_number_text_consent_information
                }
              />
            )}
          </AttributeItem>
          <AttributeItem
            title="Email Address:"
            actions={
              isSendingEmail ? (
                <InlineWaiting />
              ) : (
                <HasAppPermission permission="orderSupplies">
                  <IconButton
                    title="Send Resupply E-mail"
                    icon={<MdSend size={14} />}
                    onClick={async () => {
                      try {
                        setIsSendingEmail(true);
                        await resupplyEmailToPatient({
                          patientId
                        });
                      } finally {
                        setIsSendingEmail(false);
                      }
                    }}
                    disabled={readOnly || isSendingEmail}
                  />
                </HasAppPermission>
              )
            }
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            <a href={`mailto:${patientInfo.email}`}>{patientInfo.email}</a>
          </AttributeItem>
          <AttributeItem
            title="Date of Birth:"
            actions={
              <span>Age: {getAge(patientInfo.date_of_birth) || ""}</span>
            }
          >
            {formatDate(patientInfo.date_of_birth, "MM/DD/YYYY")}
          </AttributeItem>
          <AttributeItem title="Local Time:">
            {patientInfo.address?.zip && (
              <span>{getLocalTimeFromZip(patientInfo.address?.zip)}</span>
            )}
          </AttributeItem>
          <AttributeItem title="Primary Language:">
            {patientInfo.speaks_spanish ? "Spanish" : "English"}
          </AttributeItem>
          <AttributeItem title="Gender:">{patientInfo.gender}</AttributeItem>
          <AttributeItem title="Setup Date:">
            {formatDate(patientInfo.setup_date, "MM/DD/YYYY")}
          </AttributeItem>
          <AttributeItem title="Can email:">
            {patientInfo.can_email ? "Can email" : "Do not email"}
            {patientInfo.can_email === true &&
            patientInfo.form_of_contact === "Email" ? (
              <span>
                {updatingCanEmail ? (
                  <div
                    style={{
                      display: "inline-block",
                      maxHeight: "21px",
                      float: "right"
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <i
                    style={{
                      color: "#AAA",
                      cursor: "pointer",
                      marginLeft: 5,
                      display: "inline-block",
                      float: "right",
                      width: 15
                    }}
                    onClick={() =>
                      openModal(
                        <ConfirmationDialog
                          onCancel={closeModal}
                          warningMsg="Cannot revoke resupply email permission if form of contact is set to Email"
                          continueMsg="Update patients form of contact to Manual Call and continue?"
                          onConfirm={async () => {
                            closeModal();
                            setUpdatingCanEmail(true);
                            await updatePatient({
                              patientId,
                              updates: {
                                can_email: false,
                                form_of_contact: "Manual Call"
                              }
                            });
                            setUpdatingCanEmail(false);
                          }}
                        />
                      )
                    }
                  >
                    <IoMdMinus />
                  </i>
                )}
              </span>
            ) : updatingCanEmail ? (
              <div
                style={{
                  display: "inline-block",
                  maxHeight: "21px",
                  float: "right"
                }}
              >
                <Spinner />
              </div>
            ) : (
              <StatusToggle
                value={patientInfo.can_email}
                isUpdating={isUpdatingPatient}
                toggleStatus={() => toggleStatus("can_email")}
                disabled={readOnly}
              />
            )}
          </AttributeItem>
          <AttributeItem title="IVR consent:">
            {patientInfo.ivr_consent ? "Yes" : "No"}
          </AttributeItem>
        </Ul>
      )}
    </div>
  );
};

AccountInformation.propTypes = {
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  setDialerNumber: PropTypes.func.isRequired,
  resupplyEmailToPatient: PropTypes.func.isRequired,
  updatePatient: PropTypes.func.isRequired,
  isUpdatingPatient: PropTypes.bool,
  formOfContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  genderOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

AccountInformation.defaultProps = {
  readOnly: false
};

export default compose(
  withModal,
  connect(
    (state, { patientId }) => ({
      isUpdatingPatient: state.getIn([
        "fetch",
        "UPDATE_PATIENT",
        patientId,
        "isFetching"
      ]),
      genderOptions: selectors.getGenderOptions(state),
      formOfContactOptions: selectors.getFormOfContactOptions(state)
    }),
    {
      setDialerNumber,
      resupplyEmailToPatient,
      updatePatient
    }
  )
)(AccountInformation);
