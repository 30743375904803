import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader } from "components/ui";
import {
  getDocumentCategory,
  createDocumentCategory,
  deleteDocumentCategory,
  updateDocumentCategory,
  updateStatusDocumentCategory
} from "./../../Redux/Actions/documentCategory";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import PureModal from "components/ui/modal/pure-modal";
import DocumentCategoryForm from "./document-category-form";
import BoolCheckbox from "components/ui/bool-checkbox";
import EditLink from "components/ui/edit-link";

const DocumentCategory = ({
  DocumentCategories,
  isLoading,
  getDocumentCategory,
  createDocumentCategory,
  updateDocumentCategory
}) => {
  React.useEffect(() => {
    getDocumentCategory();
  }, []);
  const { rows, query, queryDispatcher, count } = useLocalQuery(
    DocumentCategories,
    {
      sort: { by: "document_category_name", direction: "asc" },
      filters: {},
      pagination: { page: 1, per: 100 }
    }
  );
  return (
    <div>
      <PageHeader title="Document Category Management">
        <PureModal
          renderTrigger={({ openModal }) => (
            <button type="button" style={{ margin: 0 }} onClick={openModal}>
              Add New Category
            </button>
          )}
          renderContent={({ closeModal }) => (
            <DocumentCategoryForm
              handleCancel={closeModal}
              handleSubmit={async values => {
                await createDocumentCategory(values);
                closeModal();
              }}
            />
          )}
        />
      </PageHeader>
      <Table
        containerClass="query-table"
        rowKey="document_category_name"
        isFetching={isLoading}
        columns={[
          {
            header: "Category Name",
            field: "document_category_name"
          },
          {
            header: "Active",
            field: "active",
            filters: [
              { text: "Yes", value: true },
              { text: "No", value: false }
            ]
          },
          {
            header: "Media",
            field: "is_media",
            filters: [
              { text: "Yes", value: true },
              { text: "No", value: false }
            ]
          },
          {
            field: "",
            header: " "
          }
        ]}
        rows={rows}
        query={query}
        count={count}
        queryDispatcher={queryDispatcher}
        TableRow={({
          document_category_id,
          document_category_name,
          active,
          is_media,
          can_update
        }) => (
          <tr>
            <td>{document_category_name}</td>
            <td>
              <BoolCheckbox value={active} />
            </td>
            <td>
              <BoolCheckbox value={is_media} />
            </td>
            <td>
              {can_update && (
                <PureModal
                  renderTrigger={({ openModal }) => (
                    <EditLink
                      onClick={openModal}
                      showIcon={false}
                      text="edit"
                    />
                  )}
                  renderContent={({ closeModal }) => (
                    <DocumentCategoryForm
                      handleCancel={closeModal}
                      initialValues={{
                        document_category_name,
                        active,
                        is_media
                      }}
                      handleSubmit={async values => {
                        await updateDocumentCategory({
                          document_category_id,
                          ...values
                        });
                        closeModal();
                      }}
                    />
                  )}
                />
              )}
            </td>
          </tr>
        )}
      />
    </div>
  );
};

DocumentCategory.propTypes = {
  DocumentCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  getDocumentCategory: PropTypes.func.isRequired,
  createDocumentCategory: PropTypes.func.isRequired,
  updateDocumentCategory: PropTypes.func.isRequired
};

export default connect(
  state => ({
    DocumentCategories: state.getIn(["newRootReducer"]).rdc_documentCategory
      .DocumentCategories,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  {
    getDocumentCategory,
    createDocumentCategory,
    deleteDocumentCategory,
    updateDocumentCategory,
    updateStatusDocumentCategory
  }
)(DocumentCategory);
