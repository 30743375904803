import React from "react";
import PropTypes from "prop-types";
import { getPendingOrderTasksHoldingOrder } from "features/tasks/helpers";
import { OrderProfileContext } from "components/order-profile/context";
// import MdPerson from "react-icons/lib/md/person";
// import MdShoppingCart from "react-icons/lib/md/shopping-cart";
// import MdBusiness from "react-icons/lib/md/business";
// import S3Icon from "assets/images/favicon.ico";

const TaskNotification = ({ orderId }) => {
  const { tasks } = React.useContext(OrderProfileContext);
  // const patientTasks = getPendingPatientTasksHoldingOrder(tasks);
  const orderTasks = getPendingOrderTasksHoldingOrder(tasks, orderId);
  // const allPendingTasks = patientTasks + orderTasks;
  // const dmeTasks = getPendingDMETasks(tasks);
  // const s3Tasks = getPendingS3Tasks(tasks);
  return (
    <div style={{ gridColumn: "1/-1", paddingTop: 10 }}>
      {orderTasks > 0 && (
        <div
          style={{
            color: "#FA9E46",
            fontSize: 14,
            border: "1px solid #ccc",
            padding: 5,
            boxShadow: "0px 3px 5px rgba(0,0,0,0.2)"
          }}
        >
          <div style={{ paddingBottom: 5, fontSize: 18 }}>
            There {orderTasks > 1 ? "are" : "is"}
            <span
              style={{
                color: "#fc8f26",
                fontWeight: "bold",
                paddingRight: 5,
                paddingLeft: 5
              }}
            >
              {orderTasks}
            </span>
            pending task{orderTasks !== 1 ? "s" : ""} requiring attention
          </div>
          {/* <div */}
          {/*   style={{ */}
          {/*     display: "grid", */}
          {/*     gridTemplateColumns: "repeat(4, auto)", */}
          {/*     gridGap: 10, */}
          {/*     alignItems: "center", */}
          {/*     color: "#0090bc" */}
          {/*   }} */}
          {/* > */}
          {/*   <span */}
          {/*     title="Order Tasks" */}
          {/*     style={{ */}
          {/*       display: "grid", */}
          {/*       paddingLeft: 10, */}
          {/*       gridTemplateColumns: "20px auto", */}
          {/*       alignItems: "center" */}
          {/*     }} */}
          {/*   > */}
          {/*     <MdShoppingCart /> */}
          {/*     {orderTasks} */}
          {/*   </span> */}
          {/*   <span */}
          {/*     style={{ borderLeft: "1px solid #ddd", height: "100%", width: 5 }} */}
          {/*   /> */}
          {/*   <span */}
          {/*     title="DME Tasks" */}
          {/*     style={{ */}
          {/*       display: "grid", */}
          {/*       gridTemplateColumns: "20px auto", */}
          {/*       alignItems: "center" */}
          {/*     }} */}
          {/*   > */}
          {/*     <MdBusiness /> {dmeTasks} */}
          {/*   </span> */}
          {/*   <span */}
          {/*     title="S3 Tasks" */}
          {/*     style={{ */}
          {/*       display: "grid", */}
          {/*       gridTemplateColumns: "20px auto", */}
          {/*       alignItems: "center" */}
          {/*     }} */}
          {/*   > */}
          {/*     <img src={S3Icon} /> */}
          {/*     {s3Tasks} */}
          {/*   </span> */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

TaskNotification.propTypes = { orderId: PropTypes.string.isRequired };

export default TaskNotification;
