import { GET_PRODUCT } from './../ActionTypes';

const initialState = {
    Products: []
}

const reducerProduct = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT:
            return {
                ...state,
                Products: action.payload
            };
        default:
            return state;
    }
}

export default reducerProduct;
