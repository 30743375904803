import {
  take,
  call
} from 'redux-saga/effects'
import isMatch from 'lodash/isMatch'

/**
 * Augments `take` effect by taking actions that partially match a
 * given object pattern.
 */
const takeMatch = source => call(_takeMatch, source)

/**
 * Exported function written in "effect" style to allow writing code
 * as `yield takeMatch(source)` while still being able to test. This
 * internal generator implements the behavior.
 */
export function* _takeMatch(source) {
  while (true) {
    const action = yield take()
    if (isMatch(action, source)) {
      return action
    }
  }
}

export default takeMatch
