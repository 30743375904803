import React from "react";
import PropTypes from "prop-types";
import DatePicker from "components/ui/date-picker";
import moment from "moment";

const formatDate = date => (date ? moment(date).format("ll") : null);

const FollowUpDateField = ({ follow_up_date, requestUpdateOrder }) => (
  <div className="pending-delayed-container no-print">
    <span className="subheader-text">Follow Up Date:</span>
    <span className="pending-delayed-field">
      <DatePicker
        css={{
          display: "inline-block",
          float: "right"
        }}
        modifiers={{
          disabled: { before: new Date() }
        }}
        containerCss={{ left: -200, bottom: -10 }}
        onChange={day => {
          // if order has fulfillment_type can only clear date
          if (!day) {
            requestUpdateOrder({
              follow_up_date: ""
            });
          } else {
            requestUpdateOrder({
              follow_up_date: moment(day).format("YYYY-MM-DD")
            });
          }
        }}
      />
      <span
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        {formatDate(follow_up_date)}
      </span>
    </span>
  </div>
);

FollowUpDateField.propTypes = {
  follow_up_date: PropTypes.string,
  requestUpdateOrder: PropTypes.func.isRequired,
  hasPendingIvrWork: PropTypes.bool
};

export default FollowUpDateField;
