import {
  take,
  fork
} from 'redux-saga/effects'
import get from 'lodash/get'

/**
 * Only forks one saga at a time for given ID. If forked saga
 * terminates, then will fork new one on next taken action.
 */
const pullById = (pattern, idPath, saga, ...args) => (
  fork(_pullById, pattern, idPath, saga, ...args)
)

function* _pullById(pattern, idPath, saga, ...args) {
  const tasks = {}
  while (true) {
    const action = yield take(pattern)
    const id = get(action, idPath)
    if (!id) {
      throw new Error(`pullById took action with pattern ${pattern} without ID. Check pattern and ID path ${idPath}.`)
    }
    const currTask = tasks[id]
    if (!currTask || !currTask.isRunning()) {
      tasks[id] = yield fork(saga, ...args.concat(action))
    }
  }
}

export default pullById
