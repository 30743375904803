import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { InlineWaiting } from "components/ui";
import TeamDashboardContext from "../context";
import { graphColors } from "utils/styles";
import { path } from "ramda";
import { formatPercantage } from "utils/misc";
import { Pie, PieChart, Tooltip } from "recharts";
import NoMaskOrdersTable from "./no-mask-orders-table";

const NoMaskReasonBreakdown = ({ coach_guid, coach_name }) => {
  const { requestedDates } = React.useContext(TeamDashboardContext);
  const { response: noMaskReasonCounts, fetchData, isFetching } = useFetch({
    defaultValue: [],
    apiFn: payload => put("coach-no-mask-reason", payload),
    transformError: path(["response", "body", "status"])
  });
  const total = noMaskReasonCounts.reduce((acc, cur) => cur.count + acc, 0);
  const orders = noMaskReasonCounts.reduce(
    (acc, cur) => [...cur.orders, ...acc],
    []
  );
  React.useEffect(() => {
    fetchData({
      startDate: requestedDates.start_date,
      endDate: requestedDates.end_date,
      GUID: coach_guid
    });
  }, [coach_guid]);
  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: 5,
          height: 80
        }}
      >
        <h3
          style={{
            display: "flex",
            marginLeft: 20,
            justifyContent: "flex-start"
          }}
        >
          Reasons For No Mask
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            color: "#085b55",
            fontSize: 22
          }}
        >
          <div style={{ fontWeight: "bold", paddingRight: 5 }}>
            {coach_name}
          </div>
          <div
            style={{
              fontSize: 14,
              color: "#444"
            }}
          >
            <span>{requestedDates.start_date}</span>
            <span style={{ padding: "0px 5px" }}>-</span>
            <span>{requestedDates.end_date}</span>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 10,
          display: "flex",
          flex: 1
        }}
      >
        {isFetching ? (
          <InlineWaiting />
        ) : noMaskReasonCounts.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 30,
              flex: 1
            }}
          >
            <h2 style={{ color: "#555" }}>No Data</h2>
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              flex: 1,
              gridGap: 20,
              justifyContent: "center",
              position: "relative",
              alignItems: "center"
            }}
          >
            <PieChart
              height={200}
              width={200}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <Pie
                dataKey="count"
                nameKey="reason"
                data={noMaskReasonCounts.map((d, i) => ({
                  ...d,
                  fill: graphColors[(i + 1) % graphColors.length]
                }))}
                isAnimationActive={false}
              />
              <Tooltip
                formatter={value =>
                  `${value} - ${formatPercantage(value, total)}`
                }
              />
            </PieChart>
            <table
              style={{
                tableLayout: "fixed"
              }}
            >
              <thead>
                <tr>
                  <th>Reason</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {noMaskReasonCounts.map(({ reason, count }, i) => (
                  <tr key={reason} style={{ fontSize: 18 }}>
                    <td
                      style={{
                        fontSize: 16,
                        color: graphColors[(i + 1) % graphColors.length]
                      }}
                    >
                      {reason}
                    </td>
                    <td
                      style={{
                        fontSize: 16,
                        color: graphColors[(i + 1) % graphColors.length]
                      }}
                    >
                      {count}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ gridColumn: "1/-1" }}>
              <NoMaskOrdersTable orders={orders} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

NoMaskReasonBreakdown.propTypes = {
  coach_guid: PropTypes.string.isRequired,
  coach_name: PropTypes.string.isRequired
};

export default NoMaskReasonBreakdown;
