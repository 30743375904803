import { createReducer } from "utils/reducer";
import { Types } from "actions/employee-online-times";
import { createSelector } from "reselect";
import { getSignalrOnlineUsers } from "features/signalR/reducer";
import * as R from "ramda";

export default createReducer(
  {},
  {
    [Types.REQUEST_EMPLOYEE_TOTAL_TIMES]: () => ({}),
    [Types.EMPLOYEE_TOTAL_TIMES_RESPONSE]: (_, { payload }) =>
      R.pipe(R.indexBy(R.prop("coach_guid")))(payload),
    [Types.EMPLOYEE_TOTAL_TIMES_FAILED]: () => ({})
  }
);

const getEmployeeTotalTimes = createSelector(
  [state => state.getIn(["employee-online-times"])],
  R.values
);

const getSignalREmployeeTotalTimes = createSelector(
  [getEmployeeTotalTimes, getSignalrOnlineUsers],
  (userTimes, connectedClients) => {
    return R.map(
      u => ({
        ...u,
        isOnline: !!R.propOr(false, u.coach_guid, connectedClients),
        isInDialer: !!R.pathOr(
          false,
          [u.coach_guid, "autodialerOpenedAt"],
          connectedClients
        )
      }),
      userTimes
    );
  }
);

export const selectors = {
  getEmployeeTotalTimes,
  getSignalREmployeeTotalTimes
};
