import { GET_VIDEOLIBRARY, UPDATE_VIDEOLIBRARY, DELETE_VIDEOLIBRARY, UPDATE_STATUS_VIDEOLIBRARY } from './../ActionTypes';

const initialState = {
    VideoLibrary: []
}

const reducerVideoLibrary = (state = initialState, action) => {
    switch (action.type) {
        case GET_VIDEOLIBRARY:
            return {
                ...state,
                VideoLibrary: action.payload
            };
        case UPDATE_VIDEOLIBRARY:
            return {
                ...state,
                VideoLibrary: state.VideoLibrary.map(
                    (content) => content.video_id === action.payload.video_id ? { ...content, link: action.payload.link, title: action.payload.title }
                        : content)
            };
        case DELETE_VIDEOLIBRARY:
            return {
                ...state,
                VideoLibrary: state.VideoLibrary.map(
                    (content) => content.video_id === action.payload ? { ...content, active: false }
                        : content)
            };
        case UPDATE_STATUS_VIDEOLIBRARY:
            return {
                ...state,
                VideoLibrary: state.VideoLibrary.map(
                    (content) => content.video_id === action.payload.video_id ? { ...content, active: action.payload.active }
                        : content)
            };
        default:
            return state;
    }
}

export default reducerVideoLibrary;
