import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { updateFilters } from "features/query/actions";
import moment from "moment";
import { isValidDateRange } from "utils/form-validation";
import { propOr } from "ramda";

const DateRangeFilter = ({
  filters,
  updateFilters,
  queryId,
  start_key,
  end_key
}) => {
  const [validDateRange, setValidDateRange] = useState(true);
  const [start, setStart] = useState(propOr("", start_key, filters));
  const [end, setEnd] = useState(
   filters[end_key] ? filters[end_key] : moment().format("YYYY-MM-DD")
  );
  useEffect(() => {
    const isValid = isValidDateRange(start, end, "YYYY-MM-DD");
    setValidDateRange(isValid);
  }, [start, end]);
  return (
    <div className="date-range-filter">
      <div
        onClick={() => {
          updateFilters(queryId, {
            ...filters,
            [start_key]: null,
            [end_key]: null
          });
          setStart("");
          setEnd("");
        }}
        className="filter-pane-command controls"
      >
        Clear Selections
      </div>
      <div className="date-range-filter-inputs">
        <label>
          From
          <input
            type="date"
            value={start}
            onChange={({ target }) => {
              setStart(target.value);
            }}
            onBlur={() => {
              updateFilters(queryId, {
                ...filters,
                [start_key]: validDateRange ? start : null,
                [end_key]: validDateRange ? end : null
              });
            }}
          />
        </label>
        <label>
          To
          <input
            type="date"
            value={end}
            onChange={({ target }) => {
              setEnd(target.value);
            }}
            onBlur={() => {
              updateFilters(queryId, {
                ...filters,
                [start_key]: validDateRange ? start : null,
                [end_key]: validDateRange ? end : null
              });
            }}
          />
        </label>
        {start && end && !validDateRange && <span>Invalid Date Range</span>}
      </div>
    </div>
  );
};

export default connect(
  (state, props) => ({
    filters: selectors.getQueryFilters(state, props.queryId)
  }),
  {
    updateFilters
  }
)(DateRangeFilter);

DateRangeFilter.propTypes = {
  queryId: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
  start_key: PropTypes.string.isRequired,
  end_key: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired
};
