import { all, take, call, put } from "redux-saga/effects";
import { Types } from "actions/patient-verification";
import { message, errorMessage } from "actions/message";
import * as api from "utils/api";

function* verificationRefused({ payload }) {
  try {
    const url = `patients/${payload}/flag_last_call_for_review`;
    yield call(api.get, url);
    yield put(message("Patient verification refusal logged."));
  } catch (error) {
    yield put(
      errorMessage(
        "An error occured while logging patient verification refusal."
      )
    );
  }
}

function* watchVerificationRefused() {
  while (true) {
    const action = yield take(Types.VERIFICATION_REFUSED, verificationRefused);
    yield call(verificationRefused, action);
  }
}

export default function* root() {
  yield all([watchVerificationRefused()]);
}
