import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import FaLockOpen from "react-icons/lib/fa/unlock";
// import FaUserPlus from "react-icons/lib/fa/user-plus";
import MdPhoneForwarded from "react-icons/lib/md/phone-forwarded";
import { FaGlobe } from "react-icons/lib/fa";

const CoachName = ({
  // userIsSleepCoach,
  coach_name,
  outboundCallRestricted,
  block_autodialer_incoming_calls,
  contracted_employee
}) => (
  <td className="coach-name-column">
    <span className="coach-name">
      {coach_name} {contracted_employee ? <FaGlobe /> : ""}
    </span>
    {/*!userIsSleepCoach && (
      <span className="coach-status-icon" title="Administrator">
        <FaUserPlus />
      </span>
    )*/}
    {outboundCallRestricted === false && (
      <span
        className="coach-status-icon"
        title="Outbound call restriction disabled"
      >
        <FaLockOpen />
      </span>
    )}
    {block_autodialer_incoming_calls === true && (
      <span className="coach-status-icon" title="Dialer Outbound Calls Only">
        <MdPhoneForwarded />
      </span>
    )}
  </td>
);

CoachName.propTypes = {
  coach_name: PropTypes.string.isRequired,
  userIsSleepCoach: PropTypes.bool,
  coach_guid: PropTypes.string.isRequired,
  outboundCallRestricted: PropTypes.bool,
  block_autodialer_incoming_calls: PropTypes.bool,
  contracted_employee: PropTypes.bool
};

export default connect((state, { coach_guid }) => ({
  outboundCallRestricted: selectors.getUserIsOutboundRestricted(
    state,
    coach_guid
  ),
  block_autodialer_incoming_calls: selectors.getUserDialerInboundBlocked(
    state,
    coach_guid
  )
}))(CoachName);
