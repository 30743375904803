import { combineReducers } from "redux";
import flow from "lodash/fp/flow";
import _keyBy from "lodash/fp/keyBy";
import _mapValues from "lodash/fp/mapValues";
import { ENUM_LOAD } from "actions/enum";
import { createReducer } from "utils/reducer";
import { and, or, not, eq, constant, variable, evaluate } from "utils/logic";

/* TODO refactor this eventually, tightly coupled to status guid and hardcoded status values, overly complicated for no reason IMO */
const makeLookup = dict => key => {
  const val = dict[key]
  if (!val) {
    console.error(`order status: ${key} does not exist.`)
    return key
  }
  return val
}

export const initActions = flow(
  _keyBy('text'),
  _mapValues('value'),
  makeLookup,
  lookupStatus => {

    const eqStatus = flow(
      lookupStatus,
      constant,
      eq(variable('orderStatus'))
    )
    const eqRole = flow(
      constant,
      eq(variable('userRole'))
    )

    return {
      // Permission to update status described in "Change status by
      // User in Legacy" spreadsheet. References a "Billed" status and
      // "Shipped and Completed" status, which may become explicit
      // statuses at somem point. At time of writing, they both refer
      // to "Completed" status.
      updateStatus: or(
        eqRole('Administrator'),
        and(
          or(
            eqRole('CompanyAdministrator'),
            eqRole('OfficeAdministrator'),
            eqRole('ServiceAdmin'),
            eqRole('ServiceCoach')
            // ,eqRole('ContractorAdministrator')
          ),
          not(or(
            eqStatus('Completed'),
            eqStatus('IVR Pending Work'))
          )
        )
      ),
      changeQuantities: or(
        eqStatus('New'),
        eqStatus('Accepted'),
        eqStatus('Coach Follow Up'),
        eqStatus('Incorrect'),
        eqStatus('Backordered'),
        eqStatus('On Hold'),
        eqStatus('Ready For Review'),
        eqStatus('Pending Buildout'),
        eqStatus('Order Loading'),
        and(
          or(
            eqRole('Administrator'),
            eqRole('CompanyAdministrator'),
            eqRole('OfficeAdministrator'),
            eqRole('ServiceAdmin'),
            eqRole('ServiceCoach'),
            eqRole('ContractorAdministrator')
          ),
          or(
            eqStatus('Pending'),
            eqStatus('Internal Processing'),
            eqStatus('Location Assistance Needed'),
            eqStatus('Integration Follow Up')
          )
        )
      ),
      decline: or(
        and(
          eqRole('Administrator'),
          or(
            eqStatus('Pending Buildout'),
            eqStatus('Ready For Review')
          )
        ),
        and(
          eqRole('SleepCoach'),
          eqStatus('Ready For Review')
        )
      ),
      accept: or(
        and(
          eqRole('Administrator'),
          or(
            eqStatus('New'),
            eqStatus('Pending Buildout'),
            eqStatus('Ready For Review')
          )
        ),
        and(
          eqRole('SleepCoach'),
          or(
            eqStatus('New'),
            eqStatus('Ready For Review')
          )
        )
      ),
      pending: and(
          eqRole('SleepCoach'),
          or(
            eqStatus('Coach Follow Up'),
          )
      ),
      followUp: or(
        and(
          eqRole('SleepCoach'),
          or(
            eqStatus('New'),
          )
        ),
        and(
          eqRole('Administrator'),
          not(or(
              // not any of these statuses
              eqStatus('Coach Follow Up'),
              eqStatus('Accepted'),
              eqStatus('Voided'),
              eqStatus('Completed'),
              eqStatus('Pending Shipment'),
              eqStatus('Rejected')
          ))
        ),
      ),
      showLogs: and(
        or(
          eqRole('Therapist'),
          eqRole('SleepCoach'),
          eqRole('Administrator'),
          eqRole('CompanyAdministrator'),
          eqRole('OfficeAdministrator'),
          eqRole('ServiceAdmin'),
          eqRole('ServiceCoach'),
          eqRole('ContractorAdministrator')

        )),
      removeEquipmentRequested:
        not(or(
          // not any of these statuses
          eqStatus('Completed'),
          eqStatus('Pending Shipment'),
          eqStatus('Rejected')
      )),
      updateTracking: or(
        eqStatus('Pending Shipment'),
        eqStatus('Pending Fulfillment'),
        eqStatus('Backordered')
      ),
      showSendToFulfillment: eqStatus('Accepted'),
      editSalesOrderNumber: and(
        or(
          eqRole('Administrator'),
          eqRole('CompanyAdministrator'),
          eqRole('OfficeAdministrator'),
          eqRole('ServiceAdmin'),
          eqRole('ServiceCoach'),
          eqRole('ContractorAdministrator')
        ),
        or(
          eqStatus('Pending'),
          eqStatus('On Hold'),
          eqStatus('Completed')
        )
      ),
      pendingIvrWork: eqStatus("IVR Pending Work")
    }
  }
)

const actions = createReducer(null, {
  [ENUM_LOAD]: (state, {
    payload: { orderStatuses } = {}
  }) => orderStatuses ? initActions(orderStatuses) : state
})

const permissions = combineReducers({ actions })

export default permissions

const makePermissionSelector = action => (state, props) => (
  state.actions ? evaluate(state.actions[action], props) : false
)

export const selectors = {
  getCanUpdateOrderStatus: makePermissionSelector('updateStatus'),
  getCanDeclineOrder: makePermissionSelector('decline'),
  getCanAcceptOrder: makePermissionSelector('accept'),
  getCanSendToPending: makePermissionSelector('pending'),
  getCanSendToFollowUp: makePermissionSelector('followUp'),
  getShowLogs: makePermissionSelector('showLogs'),
  getCanChangeQuantities: makePermissionSelector('changeQuantities'),
  getCanRemoveEquipmentRequested: makePermissionSelector('removeEquipmentRequested'),
  getCanUpdateTracking: makePermissionSelector('updateTracking'),
  getShowSendToFulfillment: makePermissionSelector('showSendToFulfillment'),
  getCanEditSalesOrderNumber: makePermissionSelector('editSalesOrderNumber'),
  getIsPendingIvrWork: makePermissionSelector('pendingIvrWork')
}
