import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";

const TextInput = ({
  name,
  label,
  onChange,
  placeholder,
  value,
  error,
  defaultValue,
  onBlur,
  type = "text"
}) => {
  let wrapperClass = "form_group";
  if (error && error[name]) {
    wrapperClass += "_has_error";
  } else {
    wrapperClass = "form_group";
  }

  return (
    <div style={styles[wrapperClass]}>
      <label htmlFor={name}>{label}</label>
      <div style={styles.field_div} className="field">
        <input
          defaultValue={defaultValue}
          type={type}
          name={name}
          className="form-control"
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
        {error &&
          error[name] &&
          <div style={styles.error_text}>{error[name]}</div>}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.object,
  defaultValue: PropTypes.object,
  onBlur: PropTypes.func,
  type: PropTypes.string
};

const styles = styler`
  error_text
    color: red
    padding: 0 0 10px 0
  form_group_has_error
    border: 2px solid red
    padding: 10px 0 0
  field_div
    margin: 2px
`;

export default Radium(TextInput);
