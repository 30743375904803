import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import * as R from 'ramda'
import { compose } from 'recompose'
import {
    withQuery,
    withTableHandlers,
    withApi,
    withHeaderFooter
} from 'features/query'
import withBatch from 'features/batch'
import Table from 'components/ui/impl/table'
import styler from "react-styling";
import MdSwapHoriz from "react-icons/lib/md/swap-horiz"
/* eslint-disable react/prop-types */

const formatDate = date => date ? moment(date).format('MM / DD / YYYY -  h:mm:ss a ') : '';
const formatShortDate = date => date ? moment(date).format('MM / DD / YYYY') : '';

const IVRCallsTable = compose(
    connect((state, { queryId, apiId }) => ({
        queryId: queryId,
        initialQuery: {
            sort: {
            },
            pagination: {
                per: 15,
                page: 1
            },
            filters: {},
            searches: {}
        },
        apiId: apiId,
    }), {
            //these are actions still part of connect HOC
            //queuePatients,         
        }),
    //This begins the other HOCs
    withQuery(),
    withApi({
        api: {
            route: 'IVR/Calls',
            responseKey: 'calls',
            idKey: 'callSid',
            entityKey: 'ivrCallsMaster'
        }
    }),
    withBatch({ recordIdKey: 'id' }),
    connect((state, {
        loadCall,
        loadPatient,
        loadOrder,
        startCompletedIVRCallsToOrderConversion
    }) => {

        return ({
            columns: R.filter(R.is(Object), [               
                {
                    header: 'CallSid',
                    field: 'callSid',
                    format: ({ callSid }) => (<span style={S.link} onClick={() => loadCall(callSid)}>{callSid}</span>)
                },
                {
                    header: 'Time',
                    field: 'firstCallRecordingTimeStamp',
                    format: ({ firstCallRecordingTimeStamp }) => (firstCallRecordingTimeStamp && formatDate(firstCallRecordingTimeStamp))
                },                
                {
                    header: 'Patient',
                    field: 'fullName',
                    format: ({ fullName, patientGuid }) => (
                        <span
                            title={`Click Here To Open Patient Profile: ${patientGuid}`}
                            style={S.link}
                            onClick={() => loadPatient(patientGuid)}
                        >
                            {fullName}
                        </span>),
                    search: {
                        by: "patient_guid",
                        value: ""
                    }
                },
                {
                    header: 'DOB',
                    field: 'dateOfBirth',
                    format: ({ dateOfBirth }) => (<span style={{whiteSpace: "nowrap"}}>{dateOfBirth && formatShortDate(dateOfBirth)}</span>)
                },
                {
                    header: 'Issue',
                    field: 'ivrIssue',
                    format: ({ ivrIssue }) => (ivrIssue === true ? <span style={{ backgroundColor: "red", color: "white", padding: "5px", borderRadius: "5px" }}>{"True"}</span> : "False"),
                    filters: [
                        {
                            text: 'Issue',
                            value: 'true'
                        },

                    ],                   
                },
                {
                    header: 'Converted',
                    field: 'convertedToOrder',
                    format: ({ convertedToOrder }) => (convertedToOrder === true ? <span style={{ backgroundColor: "green", color: "white", padding: "5px", borderRadius: "5px" }}>{"True"}</span> : "False"),
                    filters: [
                        {
                            text: 'Converted',
                            value: 'true'
                        },

                    ],                    
                },
                {
                    header: 'Order',
                    field: 'orderGuid',
                    format: ({ orderGuid }) => (
                        <span
                            title={`Click Here To Open Order Profile: ${orderGuid}`}
                            style={S.link}
                            onClick={() => loadOrder(orderGuid)}
                        >
                            {orderGuid}
                        </span>)
                }, 
            ]),
            customButtons: [
                {
                    component: () => (<span title="Start Call To Order Conversion For All Completed Calls" style={S.reloadMaster} onClick={() => startCompletedIVRCallsToOrderConversion()}>{<MdSwapHoriz />}</span>)
                },
               

            ]
        })
    }),
    //R.tap(say),
    withHeaderFooter({
        perPageOptions: [15, 50, 100, 150, 200, 250, 300, 500, 1000],
        //forceBatchReset: true
    }),
    withTableHandlers()
)(Table)

IVRCallsTable.propTypes = {
    startCompletedIVRCallsToOrderConversion: PropTypes.func,
    loadCall: PropTypes.func.isRequired,
    loadOrder: PropTypes.func.isRequired,
    loadPatient: PropTypes.func.isRequired,
    callSid: PropTypes.string,
    queryId: PropTypes.string,
    apiId: PropTypes.string,
}

export default IVRCallsTable

const S = styler`
  reloadMaster
    position: relative
    top: -2px
    font-size: 25px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  link
    display: table-cell
    color: blue
    cursor: pointer
`;
