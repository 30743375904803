import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { SumBlock } from "components/ui";

const UserOrders = ({ orderInsights }) => (
  <div className="user-orders">
    <SumBlock title="Total Orders" value={orderInsights.total} />
    <SumBlock title="Completed" value={orderInsights.completed} />
    <SumBlock title="Rejected" value={orderInsights.rejected} />
    <SumBlock title="Walk In Orders" value={orderInsights.walk_in_orders} />
    <SumBlock title="Net Orders" value={orderInsights.net_orders} />
    <SumBlock
      title="Orders Created"
      value={orderInsights.created_by_orders || 0}
    />
  </div>
);
UserOrders.defaultProps = {
  orderInsights: {}
};

UserOrders.propTypes = {
  orderInsights: PropTypes.object
};

export default connect(state => ({
  orderInsights: selectors.getUserOrderInsights(state)
}))(UserOrders);
