import React from "react";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { PageHeader, Spinner, TableButton } from "components/ui";
import { IoAndroidRefresh } from "react-icons/lib/io";
import { useSelector } from "react-redux";
import IVRUncompletedCallsTableRow from "./ivr-uncompleted-calls-table-row";
import FormikField from "components/forms/formik-field";
import { Formik } from "formik";
import { selectors } from "reducers";

const IVRUncompletedCalls = () => {
    const initialQuery = {
        sort: {},
        filters: {},
        pagination: { page: 1, per: 50 }
    };

    const { query, queryDispatcher, rows, data, isFetching } =
        useApiQuery("IVR/patients-not-called", initialQuery, "rows");

    const companies = useSelector(selectors.getFormOptionsCompany);

    return <div>
        <div className="patients-table-page">
            <PageHeader title="Uncontacted Patients" />

            <Formik
                initialValues={{ from_date: "", to_date: '', patient_name: "" }}
            >
                {({ values }) => (
                    <form>
                        <div style={{ display: "flex", marginBottom: "25px", padding: "0 5px", width: "100%" }}>
                            <FormikField
                                name="from_date"
                                label="From Job Date"
                                type="date"
                            />
                            <FormikField
                                name="to_date"
                                label="To Job Date"
                                type="date"
                                style={{ marginLeft: '50px' }}
                            />
                            <FormikField
                                name="patient_name"
                                label="Patient name"
                                type="text"
                                placeholder="Search Records By Patient's Name"
                                style={{ marginLeft: '50px', width: "50%" }}
                            />

                            <button
                                onClick={() => {
                                    if (values.from_date != '') {
                                        queryDispatcher({
                                            type: "setFilter",
                                            by: 'from_date',
                                            value: values.from_date
                                        })
                                    }
                                    else {
                                        queryDispatcher({
                                            type: "clearFilter",
                                            by: 'from_date',
                                        })
                                    }

                                    if (values.to_date != '') {
                                        queryDispatcher({
                                            type: "setFilter",
                                            by: 'to_date',
                                            value: values.to_date
                                        })
                                    }
                                    else {
                                        queryDispatcher({
                                            type: "clearFilter",
                                            by: 'to_date',
                                        })
                                    }

                                    if (values.patient_name != '') {
                                        queryDispatcher({
                                            type: "setFilter",
                                            by: 'patient_name',
                                            value: values.patient_name
                                        })
                                    }
                                    else {
                                        queryDispatcher({
                                            type: "clearFilter",
                                            by: 'patient_name',
                                        })
                                    }
                                }}
                                type="button"
                                style={{ marginLeft: '50px' }}
                                disabled={isFetching}
                            >
                                Search
                                {(isFetching) && <Spinner />}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>

            <Table
                query={query}
                data={data}
                queryDispatcher={queryDispatcher}
                isFetching={isFetching}
                containerClass="query-table "
                renderFooter={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flex: 1,
                            justifyContent: "space-between"
                        }}
                    >
                        <TableButton
                            onClick={() => { }}
                            disabled={false}
                        >
                            <IoAndroidRefresh color="#4281a6" size={14} />
                        </TableButton>
                        <div className="total-container">
                            <span className="count-label">Total:</span>
                            <span className="count-value">{rows.length}</span>
                        </div>
                    </div>
                }
                columns={[
                    {
                        header: "Job Date",
                        field: "job_date",
                    },
                    {
                        header: "Patient",
                        field: "patient_name",
                    },
                    {
                        header: "Company",
                        field: "company_name",
                        filters: companies
                    },
                    {
                        header: "Reason",
                        field: "reason",
                    }
                ]}
                rows={rows}
                TableRow={IVRUncompletedCallsTableRow}
                rowKey="rows"
                perPageOptions={[
                    { text: "50", value: 50 },
                    { text: "150", value: 150 },
                    { text: "500", value: 500 }
                ]}
            />
        </div>
    </div >
}


export default IVRUncompletedCalls;