/**
 * Query actions
 */
export const Types = {
  INIT: "QUERY_INIT",
  UPDATE_PER: "QUERY_UPDATE_PER",
  UPDATE_PAGE: "QUERY_UPDATE_PAGE",
  UPDATE_SORT_BY: "QUERY_UPDATE_SORT_BY",
  TOGGLE_SORT_DIRECTION: "QUERY_TOGGLE_SORT_DIRECTION",
  UPDATE_QUERY: "QUERY_UPDATE",
  RESET_API_AND_PARAMS: "QUERY_RESET_API_AND_PARAMS",
  ADD_FILTER: "QUERY_ADD_FILTER",
  REMOVE_FILTER: "QUERY_REMOVE_FILTER",
  SET_FILTERS: "QUERY_SET_FILTER",
  UPDATE_FILTERS: "QUERY_UPDATE_FILTERS",
  CLEAR_FILTERS: "QUERY_CLEAR_FILTERS",
  ADD_SEARCH: "QUERY_ADD_SEARCH",
  REMOVE_SEARCH: "QUERY_REMOVE_SEARCH",
  CLEAR_ALL_SEARCHES: "QUERY_CLEAR_ALL_SEARCHES",
  REQUEST_DATA: "QUERY_REQUEST_DATA",
  RESPONSE_DATA: "QUERY_RESPONSE_DATA",
  REQUEST_EXPORT: "QUERY_REQUEST_EXPORT",
  RESPONSE_EXPORT: "QUERY_RESPONSE_EXPORT"
};

export const queryInit = (queryId, query) => ({
  type: Types.INIT,
  payload: { query },
  meta: { queryId }
})

export const updatePer = queryId => per => ({
  type: Types.UPDATE_PER,
  payload: { per },
  meta: { queryId }
})

export const updatePage = queryId => page => ({
  type: Types.UPDATE_PAGE,
  payload: { page },
  meta: { queryId }
})

export const updateSortBy = queryId => by => ({
  type: Types.UPDATE_SORT_BY,
  payload: { by },
  meta: { queryId }
})

export const toggleSortDirection = queryId => () => ({
  type: Types.TOGGLE_SORT_DIRECTION,
  meta: { queryId }
})


//This will allow you to change the query params in the redux store
//so that the with-api will trgigger a new call to the server (JSB)
export const updateQuery = (queryId, query) => ({
  type: Types.UPDATE_QUERY,
  payload: { query },
  meta: {queryId}
})
//This is to remove the query params and api inoformation so that the HOCs will call with fresh data,
//specifically the with-api HOC (JSB)
export const resetQueryApiAndParams = (queryId) => ({
  type: Types.RESET_API_AND_PARAMS,  
  payload: {queryId}
})

export const addFilter = queryId => (by, value) => ({
  type: Types.ADD_FILTER,
  payload: { by, value },
  meta: { queryId }
})

export const removeFilter = queryId => (by, value) => ({
  type: Types.REMOVE_FILTER,
  payload: { by, value },
  meta: { queryId }
})

export const setFilters = queryId => (by, values) => ({
  type: Types.SET_FILTERS,
  payload: { by, values },
  meta: { queryId }
})

export const updateFilters = (queryId, filters) => ({
  type: Types.UPDATE_FILTERS,
  payload: { filters },
  meta: { queryId }
})

export const clearFilters = queryId => by => ({
  type: Types.CLEAR_FILTERS,
  payload: { by },
  meta: { queryId }
})

export const addSearch = queryId => (by, value) => ({
  type: Types.ADD_SEARCH,
  payload: { by, value },
  meta: {queryId}
})


export const removeSearch = queryId => (by) => ({
  type: Types.REMOVE_SEARCH,
  payload: { by},
  meta: { queryId }
})

export const clearAllSearches = queryId => () => ({
  type: Types.CLEAR_ALL_SEARCHES, 
  meta: { queryId }
})

/**
 * API actions
 */
export const requestData = (apiId, query, method, route, responseKey, entityKey, idKey) => () => ({
  type: Types.REQUEST_DATA,
  payload: {
    query,
    method,
    route,
    responseKey,
    entityKey,
    idKey
  },
  meta: { apiId }
})

export const responseData = (apiId, entities, ids, count) => ({
  type: Types.RESPONSE_DATA,
  payload: {
    entities,
    ids,
    count
  },
  meta: { apiId }
})
export const errorData = (apiId, payload) => ({
  type: Types.RESPONSE_DATA,
  error: true,
  payload,
  meta: { apiId }
})

export const requestExport = (query, method, route) => () => ({
  type: Types.REQUEST_EXPORT,
  payload: {
    query,
    method,
    route
  }
})

export const errorExport = payload => ({
  type: Types.RESPONSE_EXPORT,
  error: true,
  payload
})
