import React from "react";
import { PureModal } from "components/ui";
import { IoCalendar } from "react-icons/lib/io";
import ModalUpdateFormDates from "./modaL-update-form-start-date";
import PropTypes from "prop-types";

const UpdateFormDates = ({ patientId, formId, isEnabled, startDate, onSubmit }) => {
    return <PureModal
        renderTrigger={({ openModal }) => (
            <div>
                <span
                    title='Modify Form Start Date'
                    style={isEnabled ? { cursor: "pointer", color: 'blue' } : { pointerEvents: "none", opacity: "0.5" }}
                    onClick={openModal}
                >
                    <IoCalendar />
                </span>
            </div>
        )}

        renderContent={({ closeModal }) => <ModalUpdateFormDates patientId={patientId} formId={formId} startDate={startDate} onSubmitAction={onSubmit} closeModal={() => closeModal()}/>}

    ></PureModal>
};

UpdateFormDates.propTypes = {
    patientId: PropTypes.string, 
    formId: PropTypes.number, 
    isEnabled: PropTypes.bool, 
    startDate: PropTypes.string, 
    onSubmit: PropTypes.func
};

export default UpdateFormDates;