import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { errorMessage } from "actions/message";

import PropTypes from "prop-types";
import { PageHeader, InlineWaiting, Spinner } from "components/ui";
import { useFetch } from "hooks";
import { get, post } from "utils/api";
import { path } from "ramda";
import FormikField from "components/forms/formik-field";
import { Form, Formik } from "formik";

import FormikDropdownField from "components/forms/formik-dropdown-field";
import {
  fetchPatientProfileInfo,
  invalidatePatientProfileInfo
} from "actions/patient";

export default function AnswerPatientForm({ patientId, formId, closeModal }) {
  const [initValues, setInitValues] = useState(null);
  const [formQuestions, setFormQuestions] = useState([]);
  const dispatch = useDispatch();
  // Get questions
  const {
    response: formInfo,
    fetchData,
    isFetching: fetchingFormInfo
  } = useFetch({
    apiFn: () => get(`forms/${formId}/patients/${patientId}`),
    defaultValue: null,
    transformError: path(["response", "body", "status"])
  });

  async function initialData() {
    var result = await fetchData();

    if (result && result.success) {
      setFormQuestions(result.FormToPatient.Questions);
    }

    if (result && !result.success) {
      dispatch(errorMessage(result.message));
      closeModal();
    }

    if (!result) {
      closeModal();
    }
  }

  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    if (
      formInfo &&
      formInfo.FormToPatient &&
      formInfo.FormToPatient.Questions
    ) {
      let initialValues = {};

      formInfo.FormToPatient.Questions.map((_, index) => {
        const propertyKey = `answer_${index}`;
        initialValues[propertyKey] = "";
      });
      setInitValues(initialValues);
    }
  }, [formInfo]);
  // update Values

  const [formResponses, setFormResponses] = useState({});

  const refreshPatientProfile = () => {
    console.log(patientId);

    dispatch(invalidatePatientProfileInfo({ patientId }));
    dispatch(fetchPatientProfileInfo({ patientId }));
  };

  const uploadForm = useFetch({
    apiFn: values => post(`forms/${formId}/respond/${patientId}`, values),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    let result = {
      FormId: formId,
      PatientId: patientId,
      Questions: Object.values(formResponses)
    };

    const res = await uploadForm.fetchData(result);
    setSubmitting(false);

    if (res.success) {
      refreshPatientProfile();
      closeModal();
    } else {
      alert(res.message);
    }
  };

  const validate = value => {
    let errorMessage;
    if (value == null || value == "") {
      errorMessage = "Invalid field.This field is required";
    }

    return errorMessage;
  };

  const validateNull = value => {
    let errorMessage;
    if (!value) {
      errorMessage = "You must select an option";
    }
    return errorMessage;
  };

  const changeNAMark = questionId => {
    const newState = formQuestions.map(obj => {
      if (obj.FormQuestionsID === questionId) {
        return { ...obj, MarkedAsNA: !obj.MarkedAsNA ?? true };
      }

      return obj;
    });
    setFormQuestions(newState);
  };

  const formProps = formInfo?.FormToPatient;

  if (!fetchingFormInfo) {
    return (
      <div>
        <PageHeader
          title={`
                        ${formProps?.Name ?? ""} 
                        ${
                          formProps && formProps?.Order != 0
                            ? ` - Order: ${formInfo?.FormToPatient?.Order}`
                            : ""
                        }
                    `}
        />
        <div style={{ padding: "5px" }}>
          <p>{formInfo?.FormToPatient?.Description ?? ""}</p>
        </div>

        <div className="questionnaire-response-form">
          {initValues && (
            <Formik
              initialValues={{ ...initValues }}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ handleChange, isSubmitting, setFieldValue }) => (
                <Form className="questionnaire-response-form-container">
                  {formQuestions?.map((question, index) => (
                    <div key={index} style={{ padding: 5 }}>
                      {question?.FormQuestionTypeID == "1" && (
                        <>
                          <FormikField
                            type="text"
                            label={`${question.Order}) ${question.QuestionText}`}
                            name={`answer_${index}`}
                            validate={
                              question.IsRequired && !question.MarkedAsNA
                                ? validate
                                : null
                            }
                            placeholder="Enter your answer"
                            onChange={e => {
                              const responses = { ...formResponses };
                              responses[question.FormQuestionsID] = {
                                QuestionId: question.FormQuestionsID,
                                TextAnswer: e.target.value,
                                NumericAnswer: null,
                                QuestionOptionId: null,
                                MarkedAsNA: false
                              };
                              setFormResponses(responses);
                              handleChange(e);
                            }}
                            disabled={question.MarkedAsNA}
                            requiredQuestion={question.IsRequired}
                          />
                          {question.HasNAOption ? (
                            <span>
                              <input
                                type="checkbox"
                                name={`skipcheckbox_${index}`}
                                value={question.MarkedAsNA}
                                onChange={() => {
                                  const responses = { ...formResponses };
                                  setFieldValue(`answer_${index}`, "");
                                  responses[question.FormQuestionsID] = {
                                    QuestionId: question.FormQuestionsID,
                                    TextAnswer: "",
                                    NumericAnswer: null,
                                    QuestionOptionId: null,
                                    MarkedAsNA: !question.MarkedAsNA ?? true
                                  };
                                  setFormResponses(responses);
                                  changeNAMark(question.FormQuestionsID);
                                }}
                              />
                              <label htmlFor={`skipcheckbox_${index}`}>
                                N/A
                              </label>
                            </span>
                          ) : null}
                        </>
                      )}

                      {question?.FormQuestionTypeID == "2" && (
                        <>
                          <FormikField
                            name={`answer_${index}`}
                            component="select"
                            label={`${question.Order}) ${question.QuestionText}`}
                            validate={
                              question.IsRequired && !question.MarkedAsNA
                                ? validateNull
                                : null
                            }
                            onChange={e => {
                              const responses = { ...formResponses };
                              responses[question.FormQuestionsID] = {
                                QuestionId: question.FormQuestionsID,
                                TextAnswer: null,
                                NumericAnswer: null,
                                QuestionOptionId: e.target.value,
                                MarkedAsNA: false
                              };
                              setFormResponses(responses);
                              handleChange(e);
                            }}
                            disabled={question.MarkedAsNA}
                            requiredQuestion={question.IsRequired}
                          >
                            <option value={null}></option>
                            {question.QuestionOptions.map(
                              ({ FormQuestionOptionsID, Text }, index) => (
                                <option
                                  key={index}
                                  value={FormQuestionOptionsID}
                                >
                                  {Text}
                                </option>
                              )
                            )}
                          </FormikField>
                          {question.HasNAOption ? (
                            <span>
                              <input
                                type="checkbox"
                                name={`skipcheckbox_${index}`}
                                value={question.MarkedAsNA}
                                onChange={() => {
                                  const responses = { ...formResponses };
                                  setFieldValue(`answer_${index}`, "");
                                  responses[question.FormQuestionsID] = {
                                    QuestionId: question.FormQuestionsID,
                                    TextAnswer: null,
                                    NumericAnswer: null,
                                    QuestionOptionId: "",
                                    MarkedAsNA: !question.MarkedAsNA ?? true
                                  };
                                  setFormResponses(responses);
                                  changeNAMark(question.FormQuestionsID);
                                }}
                              />
                              <label htmlFor={`skipcheckbox_${index}`}>
                                N/A
                              </label>
                            </span>
                          ) : null}
                        </>
                      )}

                      {question?.FormQuestionTypeID == "3" && (
                        <>
                          <FormikField
                            name={`answer_${index}`}
                            component="select"
                            label={`${question.Order}) ${question.QuestionText}`}
                            validate={
                              question.IsRequired && !question.MarkedAsNA
                                ? validateNull
                                : null
                            }
                            onChange={e => {
                              const responses = { ...formResponses };
                              responses[question.FormQuestionsID] = {
                                QuestionId: question.FormQuestionsID,
                                TextAnswer: null,
                                NumericAnswer: e.target.value,
                                QuestionOptionId: null,
                                MarkedAsNA: false
                              };
                              setFormResponses(responses);
                              handleChange(e);
                            }}
                            disabled={question.MarkedAsNA}
                            requiredQuestion={question.IsRequired}
                          >
                            <option value={null}></option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </FormikField>
                          {question.HasNAOption ? (
                            <span>
                              <input
                                type="checkbox"
                                name={`skipcheckbox_${index}`}
                                value={question.MarkedAsNA}
                                onChange={() => {
                                  const responses = { ...formResponses };
                                  setFieldValue(`answer_${index}`, "");
                                  responses[question.FormQuestionsID] = {
                                    QuestionId: question.FormQuestionsID,
                                    TextAnswer: null,
                                    NumericAnswer: "",
                                    QuestionOptionId: null,
                                    MarkedAsNA: !question.MarkedAsNA ?? true
                                  };
                                  setFormResponses(responses);
                                  changeNAMark(question.FormQuestionsID);
                                }}
                              />
                              <label htmlFor={`skipcheckbox_${index}`}>
                                N/A
                              </label>
                            </span>
                          ) : null}
                        </>
                      )}

                      {question?.FormQuestionTypeID == "4" && (
                        <>
                          <FormikField
                            type="number"
                            label={`${question.Order}) ${question.QuestionText}`}
                            name={`answer_${index}`}
                            placeholder="Enter your answer"
                            validate={
                              question.IsRequired && !question.MarkedAsNA
                                ? validate
                                : null
                            }
                            onChange={e => {
                              const responses = { ...formResponses };
                              responses[question.FormQuestionsID] = {
                                QuestionId: question.FormQuestionsID,
                                TextAnswer: null,
                                NumericAnswer: e.target.value,
                                QuestionOptionId: null,
                                MarkedAsNA: false
                              };
                              setFormResponses(responses);
                              handleChange(e);
                            }}
                            disabled={question.MarkedAsNA}
                            requiredQuestion={question.IsRequired}
                          />
                          {question.HasNAOption ? (
                            <span>
                              <input
                                type="checkbox"
                                name={`skipcheckbox_${index}`}
                                value={question.MarkedAsNA}
                                onChange={() => {
                                  const responses = { ...formResponses };
                                  setFieldValue(`answer_${index}`, "");
                                  responses[question.FormQuestionsID] = {
                                    QuestionId: question.FormQuestionsID,
                                    TextAnswer: null,
                                    NumericAnswer: "",
                                    QuestionOptionId: null,
                                    MarkedAsNA: !question.MarkedAsNA ?? true
                                  };
                                  setFormResponses(responses);
                                  changeNAMark(question.FormQuestionsID);
                                }}
                              />
                              <label htmlFor={`skipcheckbox_${index}`}>
                                N/A
                              </label>
                            </span>
                          ) : null}
                        </>
                      )}

                      {question?.FormQuestionTypeID == "5" && (
                        <>
                          <FormikDropdownField
                            name={`answer_${index}`}
                            label={`${question.Order}) ${question.QuestionText}`}
                            validate={
                              question.IsRequired && !question.MarkedAsNA
                                ? validateNull
                                : null
                            }
                            handleChange={(e, optionsSelected) => {
                              const responses = { ...formResponses };
                              responses[question.FormQuestionsID] = {
                                QuestionId: question.FormQuestionsID,
                                TextAnswer: null,
                                NumericAnswer: null,
                                QuestionOptionIdList:
                                  optionsSelected.length > 0
                                    ? optionsSelected
                                    : null,
                                MarkedAsNA: false
                              };
                              setFormResponses(responses);
                              handleChange(e);
                            }}
                            disabled={question.MarkedAsNA}
                            requiredQuestion={question.IsRequired}
                            options={question.QuestionOptions}
                          />
                          {question.HasNAOption ? (
                            <span>
                              <input
                                type="checkbox"
                                name={`skipcheckbox_${index}`}
                                value={question.MarkedAsNA}
                                onChange={() => {
                                  const responses = { ...formResponses };
                                  setFieldValue(`answer_${index}`, "");
                                  responses[question.FormQuestionsID] = {
                                    QuestionId: question.FormQuestionsID,
                                    TextAnswer: null,
                                    NumericAnswer: null,
                                    QuestionOptionId: "",
                                    MarkedAsNA: !question.MarkedAsNA ?? true
                                  };
                                  setFormResponses(responses);
                                  changeNAMark(question.FormQuestionsID);
                                }}
                              />
                              <label htmlFor={`skipcheckbox_${index}`}>
                                N/A
                              </label>
                            </span>
                          ) : null}
                        </>
                      )}
                    </div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: 5
                    }}
                  >
                    {isSubmitting ? (
                      <InlineWaiting />
                    ) : (
                      <button type="Submit" style={{ margin: 0 }}>
                        Submit
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    );
  }

  return <Spinner />;
}

AnswerPatientForm.propTypes = {
  patientId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchPatientProfileInfo: PropTypes.func.isRequiredp
};
