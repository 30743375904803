import React, { Children } from 'react'
import PropTypes from 'prop-types'

const Rows = ({ children }) => (
  <div className='s3-ui-rows'>
    {Children.map(children, (row, index) => (
      row && <div key={index}>{row}</div>
    ))}
  </div>
)

export default Rows

Rows.propTypes = {
  children: PropTypes.node
}
