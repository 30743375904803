import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { withRouter } from "react-router-dom";
import PatientStatusTable from "./table";
import PageHeader from "components/ui/page-header";
import { pathOr } from "ramda";
import PatientSearchValues from "./patient-searches";
import moment from "moment";

const PatientStatus = ({
  patientStatus,
  searchId,
  searchFor,
  searchValue,
  userRole,
  userCanExport
}) => (
  <div>
    <PageHeader title={displayPatientStatusText(patientStatus)}>
      <PatientSearchValues queryId="patients-table" />
    </PageHeader>
    <PatientStatusTable
      key={`${patientStatus}-${searchId}`}
      patientStatus={patientStatus}
      userRole={userRole}
      queryId="patients-table"
      rowKey="id"
      initialQuery={{
        sort: patientStatus == "float-report"
          ? { by: "delayed_activation_date" , direction: "desc"}
          : { by: "ID", direction: "asc" }
        ,
        pagination: {
          per: 15,
          page: 1
        },
        filters: !patientStatus
          ? {}
          : {
            user: ["all"],
            status: [patientStatus],
            active: patientStatus !== "delayed" ? ["true"] : [],
            on_hold: ["false"],
            dateEligibleForAutomation: patientStatus == "unassigned" ? [`before^${moment().format("YYYY-MM-DD")}`] : undefined
          },
        searches: searchFor
          ? {
            [searchFor]: searchValue
          }
          : {}
      }}
      apiId="patients-table"
      export={
        userCanExport
          ? {
            route: "patients/export"
          }
          : null
      }
    />
  </div>
);

const displayPatientStatusText = status => {
  switch (status) {
    case "all":
      return "All Patients";
    case "float-report":
      return "Float Patients";
    case "unassigned":
      return "Unassigned Patients";
    case "delayed":
      return "Delayed Patients";
    case "contacts":
      return "Patient Contacts";
    default:
      return "Patients";
  }
};

const getPatientStatusFromRoutes = pathOr("", ["location", "state", "status"]);
const getSearchForValue = pathOr("", ["location", "state", "searchFor"]);
const getSearchValue = pathOr("", ["location", "state", "searchText"]);
const getSearchId = pathOr("", ["location", "state", "searchId"]);

export default withRouter(
  connect((state, props) => ({
    userRole: selectors.getUserRole(state),
    userCanExport: selectors.canUserExport(state),
    patientStatus: getPatientStatusFromRoutes(props),
    searchFor: getSearchForValue(props),
    searchValue: getSearchValue(props),
    searchId: getSearchId(props)
  }))(PatientStatus)
);

PatientStatus.propTypes = {
  userRole: PropTypes.string,
  userCanExport: PropTypes.bool,
  patientStatus: PropTypes.string,
  searchFor: PropTypes.string,
  searchValue: PropTypes.string,
  searchId: PropTypes.string
};
