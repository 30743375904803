import { put, take, select } from "redux-saga/effects";
import { Types, invokeMethod } from "features/signalR/actions";
import { selectors } from "reducers";

export default function* watchAutoDialerReconnected() {
  while (true) {
    yield take(Types.SIGNALR_CONNECTED);
    const isEnabled = yield select(state =>
      selectors.getIsAutoDialerEnabled(state)
    );
    if (isEnabled) {
      yield put(invokeMethod("AutoDialerStarted"));
    }
  }
}

