import React, { useState, createContext } from "react";
import { useFetch } from "hooks";
import { get, put, post } from "utils/api";
import { path } from "ramda";
import { errorMessage, message as sendToasterMessage } from "actions/message";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { browserHistory } from "browser-history";

export const FulfillmentInventoryContext = createContext();

const FulfillmentInventoryProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [selectedFulfillmentCompany, setSelectedFulfillmentCompany] = useState("d84d6a3a0c9e496b9fd163f5c7f984bf");  //Independence Medical
  const [fulfillmentInventoryFailures, setFulfillmentInventoryFailures] = useState([]);

  const generateErrorList = (error) => {
    const failures = error.response.body.status.split(":").pop();
    const failureList = failures?.split(",") ?? [];
    return failureList.map(x => ({ productID: x.trim() }));
  }
  const generateErrorMessage = (error) => {
    let _s = error.response?.body?.status;
    let _m = "";
    if (_s.length !== 0) {
      _m = `: ${_s}`;
    }
    return _m;
  }

  const {
    response: inventoryItemtemFulfillmentList,
    fetchData: getInventoryItemtemFulfillmentList,
    isFetching: isFetchingInventoryItemtemFulfillmentList
  } = useFetch({
    defaultValue: [],
    apiFn: (data) => get(`inventory_items/fulfillment_inventory_all/${data.inventoryId}`),
    transformError: path(["response", "body", "status"]),
    onError: () => {
      errorMessage(`Failed to pull inventory item fulfillment list`);
    }
  });
  const {
    response: fulfillmentInventoryItem,
    fetchData: getFulfillmentInventory,
    isFetching: isFetchingInventory
  } = useFetch({
    defaultValue: {},
    apiFn: (data) => get(`inventory_items/fulfillment_inventory_profile/${data.fulfillmentId}/${data.inventoryId}`),
    transformError: path(["response", "body", "status"]),
    onError: () => {
      errorMessage(`Failed to pull fulfillment inventory item`);
    }
  });
  const {
    response: fulfillmentInventoryList,
    fetchData: getFulfillmentInventoryList,
    isFetching: isFetchingInventoryList
  } = useFetch({
    defaultValue: [],
    apiFn: () => get(`inventory_items/fulfillment_inventory/${selectedFulfillmentCompany}`),
    transformError: path(["response", "body", "status"]),
    onError: () => {
      errorMessage(`Failed to pull fulfillment inventory`);
    }
  });
  const { 
    fetchData: fulfillmentInventoryExport,
    isFetching: isExportingInventory
  } = useFetch({
    defaultValue: [],
    apiFn: () => put(`inventory_items/export_fulfillment_inventory_xrefs/${selectedFulfillmentCompany}`),
    onSuccess: inventoryResponse => {
      inventoryResponse.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
    inventoryResponse.successes?.map(ir => dispatch(sendToasterMessage(ir)));
    browserHistory.push("/exports");
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
        errorMessage(`Failed to export fulfillment inventory: ${error.message}`);
    }
  });
  const { 
    fetchData: updateFulfillmentInventory,
    isFetching: isUpdatingInventory
  } = useFetch({
    defaultValue: [],
    apiFn: (data) => put(`inventory_items/fulfillment_inventory_update`, data),
    onSuccess: inventoryResponse => {
      inventoryResponse.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      dispatch(sendToasterMessage("Successfully updated fulfillment inventory record"));
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
        errorMessage(`Failed to update fulfillment inventory: ${error.message}`);
    }
  });
  const { 
    response: fulfillmentInventoryResponse,
    fetchData: submitFulfillmentInventoryImport,
    isFetching: fulfillmentIsFetching
  } = useFetch({
    defaultValue: [],
    apiFn: async (data) => post(`imports/fulfillment_inventory`, data),
    onSuccess: inventoryResponse => {
      inventoryResponse.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      dispatch(sendToasterMessage("Successfully imported fulfillment inventory file"));
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
      const list = generateErrorList(error);
      setFulfillmentInventoryFailures(list);
      dispatch(errorMessage(`Failed to import fulfillment inventory: ${error.message}`));
    }
  });
  const {
    response: fulfillmentInventoryItemtemChangeLogList,
    fetchData: getFulfillmentInventoryItemtemChangeLogList,
    isFetching: isFetchingFulfillmentInventoryItemtemChangeLogList
  } = useFetch({
    defaultValue: [],
    apiFn: (data) => get(`inventory_items/fulfillment_inventory_changelog/${data.inventoryId}/${data.fulfillmentId}`),
    transformError: path(["response", "body", "status"]),
    onError: (error) => {
      const m = generateErrorMessage(error);
      dispatch(errorMessage(`Failed to pull master inventory item change log list${m}`));
    }
  });
  return (
    <FulfillmentInventoryContext.Provider
      value={{
        inventoryItemtemFulfillmentList,
        getInventoryItemtemFulfillmentList,
        isFetchingInventoryItemtemFulfillmentList,
        updateFulfillmentInventory,
        isUpdatingInventory,
        fulfillmentInventoryItem,
        getFulfillmentInventory,
        isFetchingInventory,
        fulfillmentInventoryList,
        isFetchingInventoryList,
        isExportingInventory,
        getFulfillmentInventoryList,
        fulfillmentInventoryExport,
        selectedFulfillmentCompany,
        fulfillmentIsFetching,
        fulfillmentInventoryResponse,
        fulfillmentInventoryFailures,
        submitFulfillmentInventoryImport,
        setSelectedFulfillmentCompany,
        fulfillmentInventoryItemtemChangeLogList,
        getFulfillmentInventoryItemtemChangeLogList,
        isFetchingFulfillmentInventoryItemtemChangeLogList
      }}
    >
      {children}
    </FulfillmentInventoryContext.Provider>
  );
};

export default FulfillmentInventoryProvider;
FulfillmentInventoryProvider.propTypes = {
  children: PropTypes.node,
  selectedGuid: PropTypes.string
};
