import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { uploadProductPhoto } from "actions/product-media";
import { errorMessage } from "actions/message";
import { Formik } from "formik";
import * as Yup from "yup";
import FileUploadInput from "components/forms/file-upload-input";
// import ImageCropper from "../../../image-cropper";
// import { base64ToBlob } from "utils/files";

const InventoryPhotoEditor = ({
  inventoryItem,
  onFinished,
  uploadProductPhoto
}) => {
  const fileRef = React.useRef();
  return (
    <div>
      <div className="photo-editor-form">
        <Formik
          initialValues={{
            file: null
          }}
          onSubmit={async values => {
            let formData = new FormData();
            formData.append("file", values.file, "croppedImage.jpeg");
            await uploadProductPhoto({
              payload: formData,
              productId: inventoryItem.id
            });
            onFinished();
          }}
          validationSchema={Yup.object().shape({
            file: Yup.mixed().required("File is required")
          })}
        >
          {({ setFieldValue, errors, isValid, handleSubmit }) => (
            <div
              style={{ display: "flex", flexDirection: "column", padding: 10 }}
            >
              <div style={{ color: "red", padding: 5 }}>{errors.file}</div>
              <FileUploadInput
                ref={fileRef}
                onChange={event => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
                onRemove={() => {
                  setFieldValue("file", null);
                }}
              />
              {isValid && (
                <button type="submit" onClick={handleSubmit}>
                  Submit
                </button>
              )}
            </div>
          )}
        </Formik>
        <img src={inventoryItem.photo_url} />
      </div>
    </div>
  );
};

InventoryPhotoEditor.propTypes = {
  inventoryItem: PropTypes.object,
  uploadProductPhoto: PropTypes.func.isRequired,
  onFinished: PropTypes.func.isRequired
};

export default connect(() => ({}), {
  uploadProductPhoto,
  errorMessage
})(InventoryPhotoEditor);
