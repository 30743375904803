import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";

const CurrentPhoneStats = ({ usersStatus, phoneQueueStats }) => (
  <div className="phone-dashboard-current-stats">
    <div className="data-card">
      <div className="data-card-header">Active Coaches</div>
      <div className="data-card-body">{usersStatus.onlineCoaches}</div>
      <div className="data-card-sub-row">
        <div className="data-card-sub-section">
          <div className="sub-label">Ongoing Calls</div>
          <div className="sub-content">{usersStatus.coachesInCall}</div>
        </div>
        <div className="data-card-sub-section">
          <div className="sub-label">Working Dialer</div>
          <div className="sub-content">{usersStatus.workingCoachCount}</div>
        </div>
      </div>
    </div>
    <div className="data-card">
      <div className="data-card-header">Waiting In Queue</div>
      <div className="data-card-body">{phoneQueueStats.totalInQueue}</div>
      <div className="data-card-sub-row">
        <div className="data-card-sub-section">
          <div className="sub-label">Average Wait</div>
          <div className="sub-content">
            {phoneQueueStats.average_wait_time}
            <sub>Sec</sub>
          </div>
        </div>
        <div className="data-card-sub-section">
          <div className="sub-label">Longest Wait</div>
          <div className="sub-content">
            {phoneQueueStats.longestInQueue}
            <sub>Sec</sub>
          </div>
        </div>
      </div>
    </div>
  </div>
);

CurrentPhoneStats.propTypes = {
  usersStatus: PropTypes.object,
  phoneQueueStats: PropTypes.object
};

export default connect(state => ({
  usersStatus: selectors.getPhoneDashboardStatus(state),
  phoneQueueStats: selectors.new_getPhoneQueueStats(state)
}))(CurrentPhoneStats);
