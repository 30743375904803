import React from "react";
import PropTypes from "prop-types";
import {
  InlineSortControls,
  PageControls,
  AxisLabel
} from "components/ui/graph-components";
import {
  ResponsiveContainer,
  LabelList,
  BarChart,
  Bar,
  XAxis,
  YAxis
} from "recharts";
import { usePagination, useSort } from "hooks";
import MdBackArrow from "react-icons/lib/md/arrow-back";
import { IconButton } from "components/ui";

const InsuranceBreakdown = ({
  data,
  setSelectedPhysician,
  selectedPhysician
}) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "InsuranceCompanyName", ascending: true });
  const { curPageNum, totalPages, setPageNum, getPageData } = usePagination(
    data,
    20
  );
  return (
    <React.Fragment>
      <div className="interactive-graph-header">
        <IconButton
          style={{ color: "black", marginRight: "10px" }}
          icon={<MdBackArrow />}
          text="Back"
          onClick={() => setSelectedPhysician()}
        />
        <PageControls
          curPageNum={curPageNum}
          totalPages={totalPages}
          setPageNum={setPageNum}
          alwaysShow={false}
        />
        <h4>Physician - {selectedPhysician.Key}</h4>
        <InlineSortControls
          sortOptions={[
            { key: "InsuranceCompanyName", text: "Name" },
            { key: "count", text: "Count" }
          ]}
          setSortBy={setSortBy}
          sortBy={sortBy}
          ascending={ascending}
          toggleSortDirection={toggleSortDirection}
        />
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={getPageData(curPageNum, getSortedArray)}
          layout="vertical"
        >
          <XAxis
            type="number"
            dataKey="count"
            domain={[0, "dataMax + 5"]}
            scale="pow"
          />
          <YAxis
            type="category"
            dataKey="InsuranceCompanyName"
            width={250}
            interval={0}
            tick={
              <AxisLabel
                style={{
                  fontSize: "10px",
                  lineHeight: "18px"
                }}
              />
            }
          />
          <Bar dataKey="count" fill="#25a8e0" maxBarSize={50}>
            <LabelList dataKey="count" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default InsuranceBreakdown;

InsuranceBreakdown.propTypes = {
  data: PropTypes.array.isRequired,
  setSelectedPhysician: PropTypes.func.isRequired,
  selectedPhysician: PropTypes.object.isRequired
};
