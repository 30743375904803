import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { formatPhone } from "utils/misc";
import {
  fetchPatientProfileInfo,
  invalidatePatientProfileInfo
} from "actions/patient";

const returnRefreshIcon = () => {
    return (
      <svg
      className = "blacklist-refresh-icon"
        width="106"
        height="106"
        viewBox="0 0 106 106"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78.7453 28.0459C72.3411 21.6417 63.5519 17.6667 53.7911 17.6667C34.2694 17.6667 18.5019 33.4784 18.5019 53C18.5019 72.5217 34.2694 88.3334 53.7911 88.3334C70.2653 88.3334 84.0011 77.0709 87.9319 61.8334H78.7453C75.1236 72.1242 65.3186 79.5 53.7911 79.5C39.1719 79.5 27.2911 67.6192 27.2911 53C27.2911 38.3809 39.1719 26.5 53.7911 26.5C61.1228 26.5 67.6594 29.5475 72.4294 34.3617L58.2078 48.5834H89.1244V17.6667L78.7453 28.0459Z"
          fill="#56CCF2"
        />
      </svg>
    );
}

const returnSMSIcon = () => {
    return (
      <svg
        className="blacklist-company-numbers-icon"
        width="56"
        height="57"
        viewBox="0 0 56 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.6667 4.75H9.33335C6.76669 4.75 4.69002 6.8875 4.69002 9.5L4.66669 52.25L14 42.75H46.6667C49.2334 42.75 51.3334 40.6125 51.3334 38V9.5C51.3334 6.8875 49.2334 4.75 46.6667 4.75ZM21 26.125H16.3334V21.375H21V26.125ZM30.3334 26.125H25.6667V21.375H30.3334V26.125ZM39.6667 26.125H35V21.375H39.6667V26.125Z"
          fill="black"
        />
      </svg>
    );
}  

const BlackListCorrector = ({
  patientId,
  patientInfo,
}) => {
  const dispatch = useDispatch();
  const refreshPatientProfile = () => {
    dispatch(invalidatePatientProfileInfo({ patientId }));
    dispatch(fetchPatientProfileInfo({ patientId }));
  };
  const returnBlockedCompanyNumbers = blockedList => {    
    if (blockedList != null && blockedList.length > 0) {
      return blockedList.map((blockedCompanyAndPatientNumber, i) => (
        <div className="blocked-company" style={{ fontWeight: "300" }} key={i}>
          <span>{returnSMSIcon()}</span>
          <span>Text </span>
          <span style={{ fontWeight: "900" }}>START </span>
          <span>to </span>
          <span style={{ fontWeight: "600" }}>
            {formatPhone(blockedCompanyAndPatientNumber.company)}
          </span>
          <span> from </span>
          <span style={{ fontWeight: "600" }}>
            {formatPhone(blockedCompanyAndPatientNumber.patient)}
          </span>
        </div>
      ));
    } else {
      return "All Done.  Patient Has Been Removed From Blacklist";
    }
  };  
  return (
    <center style={{ padding: "50px" }}>
      <div style={{ fontWeight: "700", fontSize: "4em" }}>
        Patient Phone Number Blacklist
      </div>
      <div>        
          <div style={{ padding: "20px" }}>
            <p>
              This patient has sent
              {<span style={{ fontWeight: "700" }}> “STOP” </span>}as a message
              replying to an SMS/Text message that we have sent out to them in
              the past.
            </p>
            <p>
              In order to continue to send future SMS/Text messages to them, we
              need the Patient to send a new message containing the word
              {<span style={{ fontWeight: "700" }}> “START” </span>}to each
              number in the list below. They might need to send
              {<span style={{ fontWeight: "700" }}> “START” </span>}to more than
              one number and also from both their primary number and secondary
              number
            </p>
          </div>        
      </div>
      <div style={{ fontSize: "2em", fontWeight: "600" }}>
        Blocked Company Numbers
      </div>
      <div>
        {returnBlockedCompanyNumbers(patientInfo.retricted_numbers)}
      </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {         
          refreshPatientProfile();
        }}
      >
        <span>{returnRefreshIcon()}</span>
        <span style={{ color: "#56CCF2" }}>Refresh List</span>
      </div>
    </center>
  );
};

BlackListCorrector.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired
};

export default BlackListCorrector;
