import React, { Component } from "react";
import PropTypes from "prop-types";
import { fetchOrderStatusChangeHeatMap } from "actions/heat-map";
import { connect } from "react-redux";
import { selectors } from "reducers";
import HeatMapTable from "components/ui/statistics/heat-map-table";
import { Waiting } from "components/ui";

class OrderStatusChangeHeatMap extends Component {
  componentDidMount() {
    const {
      dates: { startDate, endDate },
      id,
      fetchOrderStatusChangeHeatMap,
      entity_type
    } = this.props;

    fetchOrderStatusChangeHeatMap({
      payload: {
        id,
        start_date: startDate,
        end_date: endDate,
        entity_type
      }
    });
  }

  addOrShiftDatesOneUnit = (newZoomStartDate, newZoomEndDate, tableData) => {
    const { id, fetchOrderStatusChangeHeatMap } = this.props;
    const {
      zoomLevel,
      originalZoomLevel,
      startDate,
      endDate,
      entityType
    } = tableData;
    const payload = {
      id,
      start_date: startDate,
      end_date: endDate,
      entity_type: entityType,
      original_zoom_level: originalZoomLevel,
      go_to_original_zoom_level: false,
      zoom_date: null,
      next_zoom_level: zoomLevel,
      shift_dates: {
        newZoomEndDate: newZoomEndDate,
        newZoomStartDate: newZoomStartDate
      }
    };
    fetchOrderStatusChangeHeatMap({
      payload
    });
  };

  zoomDate = (
    nextZoomLevel,
    zoomDate,
    tableData,
    goToOriginalZoomLevel = false
  ) => {
    const { id, fetchOrderStatusChangeHeatMap } = this.props;
    const { originalZoomLevel, startDate, endDate, entityType } = tableData;
    const payload = {
      id,
      start_date: startDate,
      end_date: endDate,
      entity_type: entityType,
      original_zoom_level: originalZoomLevel,
      go_to_original_zoom_level: goToOriginalZoomLevel,
      zoom_date: zoomDate,
      next_zoom_level: nextZoomLevel
    };
    fetchOrderStatusChangeHeatMap({
      payload
    });
  };
  render() {
    const { orderStatusChangeCounts, isFetching } = this.props;
    return (
      <div
        className="heatMap"
        style={{
          margin: "20px",
          minHeight: 750
        }}
      >
        {orderStatusChangeCounts && orderStatusChangeCounts.columns ? (
          <HeatMapTable
            shiftDatesOneUnit={this.addOrShiftDatesOneUnit}
            zoomDate={this.zoomDate}
            tableData={orderStatusChangeCounts}
            isFetching={isFetching}
          />
        ) : (
          <Waiting />
        )}
      </div>
    );
  }
}

OrderStatusChangeHeatMap.propTypes = {
  fetchOrderStatusChangeHeatMap: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  orderStatusChangeCounts: PropTypes.object.isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  }).isRequired,
  isFetching: PropTypes.bool,
  entity_type: PropTypes.string
};

OrderStatusChangeHeatMap.defaultProps = {
  entity_type: "UserOrderStatusChangeCounts"
};

export default connect(
  state => ({
    orderStatusChangeCounts: selectors.getOrderStatusChangeHeatMap(state),
    isFetching: selectors.getIsFetchingOrderStatusChangeHeatMap(state)
  }),
  { fetchOrderStatusChangeHeatMap }
)(OrderStatusChangeHeatMap);
