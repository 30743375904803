import { take, call, put, all } from "redux-saga/effects";
import * as api from "utils/api";
import A from "action-types";
import { errorMessage, message } from "actions/message";


export default function* root() {
    yield all([
        watchGetMasterIVRStates(), 
        watchGetAllIVRStatesByCallSid(),
        watchGetAllIVRStatesByPatient(),
        watchStartCompletedIVRCallsToOrderConversion(),
        watchSubmitIVRUpdateRequestUpdates()
    ])
}
function* watchGetMasterIVRStates() {
    while (true) {
        const action = yield take(A.IVR.STATES.GET_MASTER)
        yield call(getMasterIVRStates, action)
    }
}

export function* getMasterIVRStates() {    
    try {       
        let response = yield call(api.get, "IVR/CallState/MasterList")      
        yield put(
            {
                type: A.IVR.STATES.RESPONSE_GET_MASTER,
                response
            }
        )   
        //yield put(message("Success! Got Your Question"))
    } catch (payload) {
        yield put(errorMessage("Sorry Could Not Retrieve Master IVR Table."));
    }
}


function* watchGetAllIVRStatesByCallSid() {
    while (true) {
        const action = yield take(A.IVR.STATES.GET_ALL_BY_CALLSID)
        yield call(getAllIVRStatesByCallSid, action)
    }
}

export function* getAllIVRStatesByCallSid(action) {
    const {payload: {callSid}} = action
    try {
        let response = yield call(api.get, `IVR/CallState/GetAllByCallSid/${callSid}`)
        yield put(
            {
                type: A.IVR.STATES.RESPONSE_GET_ALL_BY_CALLSID,
                response
            }
        )
        //yield put(message("Success! Got Your Question"))
    } catch (payload) {
        yield put(errorMessage("Sorry Could Not Retrieve Call IVR Table."));
    }
}

function* watchGetAllIVRStatesByPatient() {
    while (true) {
        const action = yield take(A.IVR.STATES.GET_ALL_BY_PATIENT)
        yield call(getAllIVRStatesByPatient, action)
    }
}

export function* getAllIVRStatesByPatient(action) {
    const { payload: { fkiPatient } } = action
    try {
        let response = yield call(api.get, `IVR/CallState/GetAllByPatient/${fkiPatient}`)
        yield put(
            {
                type: A.IVR.STATES.RESPONSE_GET_ALL_BY_PATIENT,
                response
            }
        )        
    } catch (payload) {
        yield put(errorMessage("Sorry Could Not Retrieve Patient IVR Table."));
    }
}


function* watchStartCompletedIVRCallsToOrderConversion() {
    while (true) {
        const action = yield take(A.IVR.STATES.START_COMPLETED_IVR_CALLS_TO_ORDERS_CONVERSION)
        yield call(startCompletedIVRCallsToOrderConversion, action)
    }
}

export function* startCompletedIVRCallsToOrderConversion() {
    try {
        yield call(api.put, `IVR/CreateOrders`)
        yield put(message("Success For IVR Call to Order Conversion"))
        yield put({
            type: "QUERY_REQUEST_DATA",
            payload: {
                query: {
                    pagination: {per: 15, page: 1 },
                    sort: {},
                    filters: {},
                    searches: {}
                },
                method: "PUT",
                route: "IVR/Calls",
                responseKey: "calls",
                entityKey: "ivrCallsMaster",
                idKey: "callSid"
            },
            meta: {
                apiId: "ivrCalls"
            }
        })
    } catch (payload) {
        yield put(errorMessage("Sorry there was an error in converting calls to orders"));
    }
}

function* watchSubmitIVRUpdateRequestUpdates() {
    while (true) {
        const action = yield take(A.IVR.JOBS.SUBMIT_UPDATE_REQUEST_UPDATES)
        yield call(submitIVRUpdateRequestUpdates, action)
    }
}

export function* submitIVRUpdateRequestUpdates(action) {
    const { payload, payload: { patientGuid } } = action
    try {
        //This sends out an api call to update patient and order profile from my form
        //It will also update the table that lets us know about update requests
        let response = yield call(api.put, `IVR/Work/UpdateJobs`, payload)
        if (response.allUpdatesSuccessful == true) { 
            //Since this updates many parts of the db, 
            //I have the repsonse return if any of the try catches had failures on the backend,
            //If there were no failures, I have a prop called allUpdatesSuccessful return a true boolean
            yield put(message("Success For Updating jobs"))
            //acts as a refresh for the patient jobs table
            //yield put(setFilters("IVRPatientJobs")("patient_guid", patientGuid))  
             //acts as a refresh for the work table
            //let orderQuery = { sort: {}, pagination: { per: 15, page: 1 }, filters: {}, searches: {} }                
            //yield put(requestData("IVRWorkByPatient", orderQuery, "PUT", "IVR/Work", "patients", "ivrWork", "patientGuid" ))

            //Jared this one is to refresh the jobs table, which is the table right above my form in the 2nd tabm you get to this
            //tab by clicking on the Fo To Jobs link in the main table in the IVR work area
            yield put({
                type: 'QUERY_REQUEST_DATA',
                payload: {
                    query: {
                        pagination: {
                            per: 15,
                            page: 1
                        },
                        sort: {},
                        filters: {
                            patient_guid: [
                                patientGuid
                            ],
                            processed: [
                                'false'
                            ]
                        },
                        searches: {}
                    },
                    method: 'PUT',
                    route: 'IVR/Work/Jobs',
                    responseKey: 'jobs',
                    entityKey: 'ivrPatientJobs',
                    idKey: 'id'
                },
                meta: {
                    apiId: 'IVRPatientJobs'
                }
            })
            //This action is to refresh the main table in the IVR work area which tells you how many unprocessed jobs each patient has
            yield put({
                type: 'QUERY_REQUEST_DATA',
                payload: {
                    query: {
                        pagination: {
                            per: 15,
                            page: 1
                        },
                        sort: {},
                        filters: {},
                        searches: {}
                    },
                    method: 'PUT',
                    route: 'IVR/Work',
                    responseKey: 'patients',
                    entityKey: 'ivrWork',
                    idKey: 'patientGuid'
                },
                meta: {
                    apiId: 'IVRWorkByPatient'
                }
            })
        }
        else {
            yield put(errorMessage("Sorry there was an error in updating your jobs"));
        }
    } catch (payload) {
        yield put(errorMessage("Sorry there was an error in updating your jobs"));
    }
}
