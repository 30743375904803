import React from "react";
import PropTypes from "prop-types";
import IconButton from "components/ui/icon-button";
import { Ul, AttributeItem } from "components/lists";
import MdEdit from "react-icons/lib/md/edit";
import { formatDate } from "utils/misc";

const DisplayPatientInsurance = ({
  patientInsurance,
  readOnly,
  toggleInsurance,
  insuranceOrder
}) => {
  return (
    <Ul>
      <div className="insurance-section-header">
        {insuranceOrder}{" "}
        {patientInsurance?.insurance_company_name ? (
          <span style={{ float: "right" }}>
            <IconButton
              icon={<MdEdit />}
              text="Edit"
              style={{ fontSize: "12px" }}
              disabled={readOnly}
              onClick={toggleInsurance}
            />
          </span>
        ) : (
          <span style={{ float: "right" }}>
            <IconButton
              icon={<MdEdit />}
              text="Add"
              style={{ fontSize: "12px" }}
              disabled={readOnly}
              onClick={toggleInsurance}
            />
          </span>
        )}
      </div>
      {patientInsurance?.insurance_company_name && (
        <>
          <AttributeItem title="Insurance:">
            <span title={patientInsurance?.insurance_type_name}>
              {patientInsurance?.insurance_company_name ?? ""}
            </span>
          </AttributeItem>
          <AttributeItem title="Insurance Type:">
            {patientInsurance?.insurance_type_name ?? ""}
          </AttributeItem>
          <AttributeItem title="Policy Number:">
            {patientInsurance?.policy_number ?? ""}
          </AttributeItem>
          <AttributeItem title="Group Number:">
            {patientInsurance?.group_number ?? ""}
          </AttributeItem>
          <AttributeItem title="Guarantor">
            {patientInsurance?.guarantor_relation ?? ""}
          </AttributeItem>
          <AttributeItem title="Start Date:">
            {formatDate(patientInsurance.policy_start_date)}
          </AttributeItem>
          <AttributeItem title="End Date:">
            {formatDate(patientInsurance.policy_end_date)}
          </AttributeItem>
        </>
      )}
    </Ul>
  );
};

DisplayPatientInsurance.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInsurance: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  toggleInsurance: PropTypes.func.isRequired,
  insuranceOrder: PropTypes.oneOf(["Primary", "Secondary"])
};

DisplayPatientInsurance.defaultProps = {
  readOnly: false
};

export default DisplayPatientInsurance;
