import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Radium from "radium";
import styler from "react-styling";
import TextInput from "components/forms/text-input";
import { resetExpiredPassword } from "actions/authentication";
import { PASSWORD_REQUIREMENTS } from "utils/password";
import { isValidPassword } from "utils/form-validation";

class PasswordExpired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_confirmation: "",
      errors: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { resetExpiredPassword, email } = this.props;
    const { password, password_confirmation } = this.state;

    if (!this.formIsValid()) {
      return;
    }
    resetExpiredPassword({
      payload: { password, password_confirmation, email }
    });
  }

  formIsValid() {
    let formIsValid = true;
    if (this.state.old_password) {
      this.setState({
        errors: ["Current Password is Required."]
      });
      formIsValid = false;
      return formIsValid;
    }
    if (this.state.password !== this.state.password_confirmation) {
      this.setState({
        errors: ["Password and password confirmation do not match"]
      });
      formIsValid = false;
      return formIsValid;
    }
    if (!isValidPassword(this.state.password)) {
      this.setState({
        errors: [PASSWORD_REQUIREMENTS]
      });
      formIsValid = false;
    }
    if (formIsValid)
      this.setState({
        errors: []
      });
    return formIsValid;
  }

  render() {
    return (
      <div id="reset_password_screen">
        <div className="login_panel">
          <div>
            <h2 className="columns">Your password has expired.</h2>
            <p style={S.subheader} className="columns">
              {" "}
              Please reset your password{" "}
            </p>
          </div>
          <div className="row">
            <form acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
              <div className="large-12 columns">
                <TextInput
                  name="password"
                  label="New Password"
                  placeholder="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                />
                <TextInput
                  name="password_confirmation"
                  label="New Password Confirmation"
                  placeholder="password confirmation"
                  value={this.state.password_confirmation}
                  onChange={this.handleChange}
                  type="password"
                />
              </div>
              <div className="large-12 columns">
                <input
                  className="button"
                  disabled={!this.props.email}
                  type="submit"
                  value="Submit new password"
                />
              </div>
            </form>
          </div>
          <div className="row" style={S.errorDiv}>
            <ul>
              {this.state.errors.map((error, i) => (
                <li style={S.errorText} key={i}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

PasswordExpired.propTypes = {
  children: PropTypes.object,
  resetExpiredPassword: PropTypes.func.isRequired,
  email: PropTypes.string,
  resetFailure: PropTypes.bool
};

export default connect(
  state => ({
    email: state.getIn(["user", "email"]),
    resetFailure: state.getIn(["fetch", "UPDATE_EXPIRED_PASSWORD", "didFail"])
  }),
  {
    resetExpiredPassword
  }
)(Radium(withRouter(PasswordExpired)));

const S = styler`
  base
    min-width: 1300
    min-height: 100%
    display: flex
  main
    position: relative
  errorDiv
    padding: 0 15px
  errorText
    color: #ff4136
  header
    color: lightgray
  subheader
    color: lightgray
`;
