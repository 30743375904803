import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  LabelList,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import { propOr } from "ramda";

const ItemsRequested = ({ equipment }) => {
  const chartData = [
    { label: "Masks", count: propOr(-1, "masks", equipment) },
    { label: "Seals", count: propOr(-1, "seals", equipment) },
    { label: "Tubing", count: propOr(-1, "tubing", equipment) },
    { label: "Filters", count: propOr(-1, "filters", equipment) },
    { label: "Chinstraps", count: propOr(-1, "chinstraps", equipment) },
    { label: "Waterchambers", count: propOr(-1, "water_chambers", equipment) }
  ];
  return (
    <div className="item-types-requested-container">
      <h2 title="Excludes rejected and voided orders">Item Types Requested</h2>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 50, bottom: 20, left: 50 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <YAxis
            type="number"
            dataKey="count"
            domain={[0, dataMax => dataMax * 1.5]}
            scale="sqrt"
          />
          <XAxis dataKey="label" type="category" />
          <Bar
            dataKey="count"
            fill="rgba(45, 198, 184, 0.89)"
            isAnimationActive={false}
          >
            <LabelList dataKey="count" position="top" />
          </Bar>
          <Tooltip cursor={false} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ItemsRequested;

ItemsRequested.defaultProps = {
  equipment: {}
};

ItemsRequested.propTypes = {
  equipment: PropTypes.shape({
    chinstraps: PropTypes.number,
    filters: PropTypes.number,
    masks: PropTypes.number,
    seals: PropTypes.number,
    tubing: PropTypes.number,
    water_chambers: PropTypes.number
  })
};
