import { createReducer } from "utils/reducer";
import { Types } from "actions/company-contact-logic";
import * as R from "ramda";

const initialState = [
  {
    companyId: "",
    isFetching: false,
    cycles: []
  }
];

export default createReducer(initialState, {
  [Types.GET_COMPANY_CONTACT_LOGIC_REQUEST]: (state, action) => {
    const index = action.payload.index;
    const newState = R.clone(state);
    if (index >= state.length) {
      newState.push({});
    }
    newState[index] = {
      companyId: action.payload.companyId,
      isFetching: true,
      cycles: []
    };
    return newState;
  },
  [Types.GET_COMPANY_CONTACT_LOGIC_RESPONSE]: (state, action) => {
    const index = action.payload.index;
    const newState = R.clone(state);
    newState[index] = {
      companyId: action.payload.companyId,
      isFetching: false,
      cycles: [...action.payload.cycles]
    };
    return newState;
  },
  [Types.GET_COMPANY_CONTACT_LOGIC_FAILURE]: (state, action) => {
    const index = action.payload.index;
    const newState = R.clone(state);
    newState[index] = {
      companyId: action.payload.companyid,
      isFetching: false,
      cycles: []
    };
    return newState;
  },
  [Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLES_RESPONSE]: (state, action) => {
    const { companyId, cycles, response } = action.payload;
    const newState = R.clone(state);

    newState
      .filter(x => x.companyId === companyId)
      .forEach(compEntry => {
        compEntry.cycles = compEntry.cycles.filter(
          cycle => !cycles.some(x => x.id === cycle.id)
        );

        compEntry.cycles = [
          ...compEntry.cycles,
          ...cycles.filter(c => c.shouldUpdate),
          ...response
        ];

        compEntry.cycles.sort((a, b) => a.cycleCount - b.cycleCount);
      });

    return newState;
  },
  [Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLE_ACTION_ENABLED_RESPONSE]: (
    state,
    action
  ) => {
    const cycleId = action.payload.cycleId;
    const actionIds = action.payload.actionIds;
    const enabled = action.payload.enabled;
    const newState = R.clone(state);

    actionIds.forEach(actionId => {
      newState.forEach(element => {
        const cycles = element.cycles.filter(cycle => cycle.id === cycleId);
        cycles.forEach(cycle => {
          if (enabled) {
            cycle.actions.push({ actionId: actionId });
          } else {
            const action = cycle.actions.find(
              action => action.actionId === actionId
            );
            const index = cycle.actions.indexOf(action);
            cycle.actions.splice(index, 1);
          }
        });
      });
    });
    return newState;
  },
  [Types.COPY_LAST_COMPANY_CONTACT_LOGIC]: state => {
    if (!state.length) return initialState;
    const newState = R.clone(state);
    newState.push(R.clone(newState[newState.length - 1]));
    return newState;
  },
  [Types.CLEAR_COMPANY_CONTACT_LOGIC]: (state, action) => {
    const index = action.payload.index;
    let newState = R.clone(state);
    newState = [
      ...newState.slice(0, index),
      ...newState.slice(index + 1, newState.length)
    ];
    return newState;
  },
  [Types.CLEAR_ALL_COMPANY_CONTACT_LOGIC]: () => {
    return initialState;
  }
});
