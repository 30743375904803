import { put, take, all, call, takeLatest } from "redux-saga/effects";
import { invokeMethod } from "features/signalR/actions";
import { Types as PhoneTypes } from "actions/phone";
import { Types as AutoDialerTypes } from "actions/auto-dialer";
import { Types as UserProfileTypes } from "actions/user-profile";
// import {selectors} from "reducers";
const hubMethods = {
  [PhoneTypes.TWILIO_EVENT_CONNECT]: "InCall",
  [PhoneTypes.TWILIO_EVENT_DISCONNECT]: "CallEnded"
};

/* Emit changes to coach flags/settings */
function* watchCoachUpdates() {
  while (true) {
    const {
      payload,
      meta: { userId }
    } = yield take(UserProfileTypes.UPDATED_COACH_SETTINGS);
    yield put(
      invokeMethod("CoachSettingsUpdated", [
        userId,
        {
          outboundCallRestricted: payload.outboundCallRestricted,
          dialerInboundBlocked: payload.block_autodialer_incoming_calls
        }
      ])
    );
  }
}

function* watchAutoDialerEvents() {
  yield takeLatest(AutoDialerTypes.AUTO_DIALER_STARTED, function*() {
    yield put(invokeMethod("AutoDialerStarted"));
    yield take([
      AutoDialerTypes.AUTO_DIALER_END,
      AutoDialerTypes.AUTO_DIALER_ERROR
    ]);
    // const endReason = yield select(selectors.getAutoDialerEndReason)
    yield put(invokeMethod("AutoDialerEnded"));
  });
}

function* watchClientEvents() {
  while (true) {
    const { type } = yield take([...Object.keys(hubMethods)]);
    yield put(invokeMethod(hubMethods[type]));
  }
}

export default function* rootClientEventsSaga() {
  yield all([
    call(watchClientEvents),
    call(watchAutoDialerEvents),
    call(watchCoachUpdates)
  ]);
}
