import React from "react";
import AddEquipmentForm from "./add-equipment";
import AddEquipmentByIdForm from "./add-equipmentById";
import { get } from "utils/api";
import { pipe, propOr, prop, map } from "ramda";
import useFetch from "hooks/useFetch";

export { AddEquipmentForm, AddEquipmentByIdForm };

export const usePatientCompanyActiveEquipment = ({ patientId }) => {
  const {
    response: active_products,
    fetchData,
    isFetching: isFetchingCompanyActiveEq
  } = useFetch({
    apiFn: () => get(`patients/${patientId}/company_inventory`),
    defaultValue: [],
    transformResponse: pipe(propOr([], "inventory"), map(prop("invItem_ID")))
  });

  React.useEffect(() => {
    if (patientId) fetchData();
  }, [patientId]);

  return { isFetchingCompanyActiveEq, active_products };
};

export const PatientCompanyActiveEquipmentContext = React.createContext();
