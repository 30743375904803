import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DataTable from 'components/ui/data-table'
import every from 'lodash/every'
import zip from 'lodash/zip'

class DataFilterTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      searchTexts: props.filters.map(() => '')
    }

    this.handleSearchChange = this.handleSearchChange.bind(this)
  }

  handleSearchChange({
    target: {
      value: newText,
      name: index
    }
  }) {
    this.setState(({ searchTexts }) => {
      searchTexts = [...searchTexts]
      searchTexts[index] = newText
      return { searchTexts }
    })
  }

  render() {
    const {
      rows,
      columns,
      filters,
      filterCombine,
      initialSort,
      isLoading,
      onRefresh,
      onExport
    } = this.props
    const { searchTexts } = this.state

    const filtersWithText = zip(filters, searchTexts)
    const filteredRows = rows.filter(row => (
      filterCombine(filtersWithText, ([{ iteratee }, searchText]) => (
        iteratee(row, RegExp(searchText, 'i'), searchText)
      ))
    ))

    return (
      <div>
        {filtersWithText.map(([{ placeholder }, searchText], i) => (
          <input
            key={i}
            name={i}
            type='text'
            placeholder={placeholder || ''}
            value={searchText}
            onChange={this.handleSearchChange} />
        ))}
        <DataTable
          columns={columns}
          rows={filteredRows}
          initialSort={initialSort}
          isLoading={isLoading}
          onRefresh={onRefresh}
          onExport={onExport}>
        </DataTable>
      </div>
    )
  }
}

DataFilterTable.propTypes = {
  ...DataTable.propTypes,

  filters: PropTypes.arrayOf(PropTypes.shape({
    // (row: any, regex: RegExp, searchText: string) => boolean
    iteratee: PropTypes.func.isRequired,
    placeholder: PropTypes.string
  })).isRequired,

  // takes boolean results of all applied filters and combines them to
  // one boolean (e.g., supply Lodash `some` function so rows that
  // satisfy at least one filter qualify)
  //
  // ([boolean]) => boolean
  filterCombine: PropTypes.func
}

DataFilterTable.defaultProps = {
  filterCombine: every
}

export default DataFilterTable
