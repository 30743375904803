import React from "react";
import OrderProfile from "./order-profile";
import { useParams } from "react-router-dom";

const RootOrderProfile = () => {
  const { orderId } = useParams();
  return <OrderProfile orderId={orderId} />;
};

export default RootOrderProfile;
