import React, { Component } from "react";
import PropTypes from "prop-types";
import { PrintSectionTitle } from '../print-company-monthly-report'
import { formatNumbersForReports } from 'utils/misc'
export class PatientStatistics extends Component {
  render() {
    const patients = this.props.patients;
    const ins = patients.get("insurance_types");
    const contactMethods = patients.get("contact_methods");

    return (
      <div className='row'>
        <div className="print print-company-monthly-report_table-container">
          <div className='print-company-monthly-report_pt-table-title-container'>
            <PrintSectionTitle title='Patients' />
          </div>
          <div className='small-6 columns'>
            <div className="row">
              <table className='print-company-monthly-report_pt-table--left-column'>
                <tbody>
                  <tr className='print-company-monthly-report_table-header-row'>
                    <th>Status</th>
                    <th className="text-right">Count</th>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>All</td>
                    <td className="text-right">{formatNumbersForReports(patients.get("total"))}</td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Active</td>
                    <td className="text-right">{formatNumbersForReports(patients.get("active"))}</td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Inactive</td>
                    <td className="text-right">{formatNumbersForReports(patients.get("inactive"))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <table className='print-company-monthly-report_pt-table--left-column'>
                <tbody>
                  <tr className='print-company-monthly-report_table-header-row'>
                    <th>Contact Type (active patients)</th>
                    <th className="text-right">Count</th>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Manual call</td>
                    <td className="text-right">
                      {formatNumbersForReports(contactMethods.get("manual_call"))}
                    </td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Email</td>
                    <td className="text-right">
                      {formatNumbersForReports(contactMethods.get("email"))}
                    </td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Text message</td>
                    <td className="text-right">
                      {formatNumbersForReports(contactMethods.get("text_message"))}
                    </td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Push notification</td>
                    <td className="text-right">
                      {formatNumbersForReports(contactMethods.get("push_notification"))}
                    </td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>IVR</td>
                    <td className="text-right">
                      {formatNumbersForReports(contactMethods.get("ivr"))}
                    </td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Do not contact</td>
                    <td className="text-right">
                      {formatNumbersForReports(contactMethods.get("do_not_contact"))}
                    </td>
                  </tr>
                  <tr className='print-company-monthly-report_table-row'>
                    <td>Bad phone number</td>
                    <td className="text-right">
                      {formatNumbersForReports(contactMethods.get("bad_phone_number"))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='small-6 columns'>
            <div className="row">
              <table className='print-company-monthly-report_pt-table--right-column'>
                <tbody>
                  <tr className='print-company-monthly-report_table-header-row'>
                    <th>Insurance Type (active patients)</th>
                    <th className="text-right">Count</th>
                  </tr>
                  {ins.map(insurance => (
                    <tr key={insurance.get("text")}>
                      <td>{insurance.get("text")}</td>
                      <td className="text-right">
                        {formatNumbersForReports(insurance.get("value"))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
PatientStatistics.propTypes = {
  patients: PropTypes.object.isRequired
};
export default PatientStatistics;
