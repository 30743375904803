import React from "react";
import CompanyTeamSelect from "components/shared/company-team-select";
import { Link } from "react-router-dom";
import { RowSelectCell, RowSelectHeaderCell } from "hooks/useRowSelectNew";
import { useTableSettingsNew } from "hooks/useTableSettings";
import { selectors } from "reducers";
import { useSelector } from "react-redux";
import { formatDate } from "utils/dates";
import RACmnLink from "features/referral-answer/cmns/ra-cmn-link";
import * as R from "ramda";

const getTableSchema = ({ companies }) => {
  const Table = {
    All: {
      header: "all",
      field: "all",
      Cell: RowSelectHeaderCell,
      active: true,
      Row: row => <RowSelectCell id={row.cmn_id} />
    },
    Patient: {
      header: "Patient",
      field: "patient_name",
      active: true,
      Row: row => (
        <td>
          <Link to={`/patients/${row.patient_id}`}>{row.patient_name}</Link>
        </td>
      )
    },
    "Account #": {
      header: "Account #",
      field: "patient_account",
      active: true,
      Row: row => <td>{row.patient_account}</td>
    },
    "Cmn Status": {
      header: "Cmn Status",
      field: "status",
      active: true,
      Row: row => <td>{row.status}</td>,
      filters: [
        { text: "Active", value: "Active" },
        { text: "Pending", value: "Pending" },
        { text: "Received", value: "Received" },
        { text: "Review", value: "Review" },
        { text: "Superseded", value: "Superseded" }
      ]
    },
    "CMN ID": {
      header: "CMN ID",
      field: "external_id",
      active: true,
      Row: row => (
        <td>
          <RACmnLink patientId={row.patient_id} external_id={row.external_id} />
        </td>
      )
    },
    "Cmn Type": {
      header: "Cmn Type",
      field: "cmn_type",
      active: true,
      Row: row => <td>{row.type}</td>
    },
    Company: {
      header: "Company",
      field: "company",
      filter: "company_id",
      filters: companies,
      filterDownSelect: true,
      SubFilter: CompanyTeamSelect,
      active: true,
      Row: row => <td>{row.company_name}</td>
    },
    "Start Date": {
      header: "Start Date",
      field: "start_date",
      active: true,
      Row: row => <td>{formatDate(row.start_date)}</td>
    },
    "Expiration Date": {
      header: "Expiration Date",
      field: "expiration_date",
      active: true,
      Row: row => <td>{formatDate(row.expiration_date)}</td>
    },
    "Audit Date": {
      header: "Audit Date",
      field: "audit_date",
      active: false,
      Row: row => <td>{formatDate(row.audit_date)}</td>
    },
    "Physician Name": {
      header: "Physician",
      field: "physician_name",
      active: true,
      Row: row => <td>{row.physician_name}</td>
    },
    "Physician NPI": {
      header: "Physician NPI",
      field: "physician_npi",
      active: true,
      Row: row => <td>{row.physician_npi}</td>
    },
    HCPCS: {
      header: "HCPCS",
      field: "hcpcs",
      active: true,
      Row: row => <td>{row.hcpcs.sort().join(", ")}</td>
    }
  };
  return Table;
};

export const useCmnTable = () => {
  const companies = useSelector(selectors.getFormOptionsCompany);
  // const sleepCoaches = useSelector(getFormOptionsSleepCoachWithUserFirst);
  const userRole = useSelector(selectors.getUserRole);
  const orderStatusOptions = useSelector(selectors.getOrderStatusTextOptions);
  const taskTable = getTableSchema({
    companies,
    // sleepCoaches,
    userRole,
    orderStatusOptions
  });
  const tableSchema = Object.keys(taskTable).map(key => ({
    ...taskTable[key],
    key
  }));
  const defaultSettings = R.mapObjIndexed(R.pick(["active"]), taskTable);
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings
  } = useTableSettingsNew("tasks-table-settings", defaultSettings);

  const visibleTableSchema = tableSchema.filter(({ key }) =>
    isColumnVisible(key)
  );
  const tableColumns = visibleTableSchema.map(R.omit(["Row"]));
  const tableRows = visibleTableSchema.map(R.prop("Row"));

  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    tableColumns,
    tableRows
  };
};
