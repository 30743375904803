import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "components/ui";

const isSelected = (option, selected) => {
  if (Array.isArray(selected)) return selected.includes(option);
  if(typeof selected === "string") return option.value === selected
  return option === selected;
};

const onOptionDeselect = (event, option, deselectCallback) => {
  event.preventDefault();
  deselectCallback(option);
};

const renderOptions = (options, selected, onSelect, onDeselect) => {
  return (
    <div className="list-selector-entry-wrapper">
      {options?.map((option, index) => (
        <div
          key={option.value ?? index}
          onClick={() => {
            onSelect && onSelect(option);
          }}
        >
          <div
            className={
              isSelected(option, selected)
                ? "list-selector-entry-active"
                : "list-selector-entry"
            }
          >
            {option.text}
            {onDeselect && (
              <button
                className="deselect-button"
                onClick={event => onOptionDeselect(event, option, onDeselect)}
              >
                ✘
              </button>
            )}
          </div>
          <div className="list-selector-entry-divider" />
        </div>
      ))}
    </div>
  );
};

const ListSelector = props => {
  const {
    selected,
    options,
    noOptionsText,
    minHeight,
    maxHeight,
    onSelect,
    onDeselect,
    isLoading
  } = props;

  const optionsRendered = renderOptions(
    options,
    selected,
    onSelect,
    onDeselect
  );

  const style = { minHeight, maxHeight };

  return (
    <div className="list-selector" style={style}>
      {isLoading ? (
        <div className="list-selector-default">
          <Spinner size="45px" />
        </div>
      ) : (
        <div>
          {options?.length > 0 ? (
            <div>{optionsRendered}</div>
          ) : (
            <div className="list-selector-default">{noOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
};
ListSelector.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.arrayOf(PropTypes.object),
  noOptionsText: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  isLoading: PropTypes.bool
};

export default ListSelector;
