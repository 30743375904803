import React from "react";
import PropTypes from "prop-types";
import {
  IoAndroidCheckboxOutline,
  IoAndroidCheckboxOutlineBlank
} from "react-icons/lib/io";

const Checkbox = ({ value, ...rest }) => {
  return typeof value == "boolean" ? (
    value ? (
      <IoAndroidCheckboxOutline size={18} {...rest} />
    ) : (
      <IoAndroidCheckboxOutlineBlank size={18} color="#ddd" {...rest} />
    )
  ) : null;
};

Checkbox.propTypes = { value: PropTypes.bool };

export default Checkbox;
