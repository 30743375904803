import React from "react";
import PropTypes from "prop-types";

const DateField = ({ label, input }) => (
  <span>
    <label
      style={
        {
          // alignItems: "center",
          // cursor: "pointer",
          // justifyContent: "center",
          // fontSize: "1.15em",
          // display: "inline-flex",
          // paddingRight: 20
        }
      }
    >
      {label}
      <input type="date" {...input} value={input.value.split("T")[0]} />
    </label>
  </span>
);

DateField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object
};

export default DateField;
