import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

const onChange = (e, props) => {
    // console.log(e);
    if (!isNil(props.onChange)) {
        props.onChange(e)
    } else {
        console.log('onChange is not given in Porps');
    }
}

const TextComponent = (props) => {
    // console.log('typeof props.style',typeof props.style == "object");
    if (props.type === "checkbox") {
        return (
            <div>
                <input
                    name={props.name}
                    id={props.id} 
                    type={props.type}
                    style={!isNil(props.style) || typeof props.style == "object" ? {background:"#fafafa",...props.style}  : {background:"#fafafa"}  }
                    onChange={obj => onChange(obj, props)}
                    value={props.value}
                    checked={props.value}
                    placeholder={props.placeholder} 
                    disabled = {props.disabled}/>{props.children}
            </div>
        );
    }
    else {
        if(props.type === "radio"){
           
            
            return (
                <span>
                <input
                    id={props.id} 
                    name={props.name}
                    type={props.type}
                    style={!isNil(props.style) || typeof props.style == "object" ? {background:"#fafafa",...props.style}  : {background:"#fafafa"}  }
                    onChange={obj => onChange(obj, props)}
                    value={props.value}
                    checked={props.value}
                    placeholder={props.placeholder} 
                    disabled = {props.disabled}/>{props.radioboxText}
            </span>
            );
        }
        else{
            return (
                <input
                    maxLength={(props.maxLength || props.maxLength > 0)? props.maxLength: null}
                    id={props.id} 
                    name={props.name}
                    type={props.type}
                    style={!isNil(props.style) || typeof props.style == "object" ? {background:"#fafafa",...props.style}  : {background:"#fafafa"}  }
                    size={props.size}
                    className={!isNil(props.className) ? props.className:""}
                    onChange={obj => onChange(obj, props)}
                    value={props.value}
                    placeholder={props.placeholder} 
                    disabled = {props.disabled}/>
            );
        }
    }

}

TextComponent.defaultProps = {
    placeholder: "Input Text Component",
    type: "text",
    size:20
}

TextComponent.propTypes = {
    id:PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    checkboxText: PropTypes.string,
    radioboxText:PropTypes.string,
    size:PropTypes.number,
    maxLength:PropTypes.number,
    style:PropTypes.object,
    disabled:PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string
}

export default TextComponent;

/*
type = text , checkbox, radio 
*/
