import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import { Rows } from "components/ui";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import { transformEquipmentName } from "utils/order-equipment-questions";
import Header from "./header";
import Subheader from "./subheader";
import capitalize from "lodash/capitalize";
import { uniqBy } from "ramda";

const DysfunctionalEquipment = ({
  isFetching,
  requestedEquipment,
  info,
  questions
}) => (
  <div>
    {questions.length === 0 ? (
      ""
    ) : (
      <div>
        <Header showSpinner={isFetching}>
          Requested Equipment &amp; Dysfunctions
        </Header>

        {requestedEquipment.map((equipment, index) => (
          <div key={index}>
            <Subheader key={equipment}>{capitalize(equipment)}</Subheader>
            <Rows>
              {uniqBy(
                // remove duplicate filter question
                x => x.key,
                questions.filter(
                  q =>
                    q.equipment.toUpperCase() ===
                    transformEquipmentName(equipment)
                )
              ).map(({ key, text }) => (
                <div key={key}>{text}</div>
              ))}
            </Rows>
          </div>
        ))}
      </div>
    )}

    {info.other_issues &&
      info.other_issues.trim() && [
        <Subheader key="issues-prompt">
          Please include any additional information/concerns regarding your PAP
          therapy:
        </Subheader>,
        <p key="issues-answer">{info.other_issues}</p>
      ]}
    {info.supplies_on_hand &&
      info.supplies_on_hand.trim() && [
        <Subheader key="supplies-prompt">
          Insurance requires us to document the amount of supplies you have on
          hand:
        </Subheader>,
        <p key="supplies-answer">{info.supplies_on_hand}</p>
      ]}
  </div>
);

DysfunctionalEquipment.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  info: PropTypes.shape({
    other_issues: PropTypes.string,
    supplies_on_hand: PropTypes.string
  }).isRequired,
  requestedEquipment: PropTypes.arrayOf(PropTypes.string),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.bool
    })
  ).isRequired
};

export default compose(
  connect((state, props) => {
    const {
      isFetching: isFetchingDysfunctionInfo,
      data: { info, requestedEquipment, questions }
    } = selectors.getOrderDysfunctionInfo(state, props);
    const { isFetching: isFetchingEquipmentRequested } =
      selectors.getOrderEquipmentRequested(state, props);
    return {
      isFetching: isFetchingDysfunctionInfo || isFetchingEquipmentRequested,
      info,
      requestedEquipment,
      questions
    };
  }, mapDispatchToProps)
)(DysfunctionalEquipment);
