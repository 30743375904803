import React from "react";
import { InlineWaiting } from "components/ui";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { twoFactorAuthTokenSignIn } from "actions/authentication";

const TwoFactorRequired = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  async function mfaLogin(token) {
    try {
      setIsLoading(true);
      setError("");
      const success = await dispatch(twoFactorAuthTokenSignIn({ token }));
      if (!success) {
        setError(
          "Sorry something went wrong, please use code provided in email"
        );
      }
    } finally {
      setIsLoading(false);
    }
  }
  React.useEffect(() => {
    mfaLogin(token);
  }, [token]);
  return (
    <React.Fragment>
      <div id="login_background"></div>
      <section
        id="log_in_screen"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          overflow: "auto"
        }}
      >
        <div
          className="login_panel"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#cdcdcd",
            border: "1px solid #ccc"
          }}
        >
          {isLoading && (
            <>
              <InlineWaiting size={28} />
              <div style={{ fontSize: 20, color: "#017268" }}>
                Authenticating
              </div>
            </>
          )}
          {error && (
            <>
              <div style={{ fontSize: 18, color: "red", flex: 1 }}>{error}</div>
              {error && (
                <button
                  onClick={() => {
                    history.push("/login");
                  }}
                  style={{ marginTop: 10 }}
                >
                  Back to Login
                </button>
              )}
            </>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default TwoFactorRequired;
