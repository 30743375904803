import React from "react";
import PropTypes from "prop-types";
import { formatMoney } from "accounting";
import { SumBlock, Waiting } from "components/ui";
import isEmpty from "lodash/isEmpty"
import { CompletedOrders, ItemsRequested } from "components/ui/statistics";
import MonthlyPatients from "./monthly/monthly-patients";

const makeStatusRows = () => [
  { status: "Accepted", key: "accepted" },
  { status: "Backordered", key: "backordered" },
  { status: "Coach Follow Up", key: "coach_followup" },
  { status: "Completed", key: "completed" },
  { status: "Internal Processing", key: "internal_processing" },
  { status: "New", key: "new" },
  { status: "On Hold", key: "on_hold" },
  { status: "Pending", key: "pending" },
  { status: "Pending Buildout", key: "pending_buildout" },
  { status: "Pending Fulfillment", key: "pending_fulfillment" },
  { status: "Pending Shipment", key: "pending_shipment" },
  { status: "Incorrect", key: "incorrect" },
  {
    status: "Location Assistance Needed",
    key: "location_assistance_needed"
  },
  {
    status: "Integration Follow Up",
    key: "integration_follow_up"
  },
  { status: "Ready For Review", key: "ready_for_review" }
];

const CompanyMonthlyReportView = ({ orders, monthly, isFetchingMonthlyReport }) => {
  const statuses = orders ? orders.statuses : {}
  return (
    <>
      {(!isEmpty(orders) && !isFetchingMonthlyReport)
        ? <div className='row company-monthly-report_container'>
            <div className="row" style={{ marginBottom: 15 }}>
              <div className="medium-4 columns">
                <SumBlock
                  title="Platform Orders"
                  value={orders.total_orders}
                />
              </div>
              <div className="medium-4 columns">
                <SumBlock
                  title="Average Order Revenue"
                  value={formatMoney(orders.average_revenue)}
                />
              </div>
              <div className="medium-4 columns">
                <SumBlock
                  title="Potential Revenue"
                  value={formatMoney(orders.potential_revenue)}
                />
              </div>
            </div>
            <div className="row" style={{ marginBottom: 15 }}>
              <div className="medium-4 columns">
                <SumBlock
                  title="Additional Walk-in Orders"
                  value={orders.walk_in_orders}
                />
              </div>
              <div className="medium-4 columns">
                <SumBlock
                  title="Walk-in Order Revenue"
                  value={formatMoney(orders.walk_in_order_revenue)}
                />
              </div>
              <div className="medium-4 columns">
                <SumBlock
                  title="Combined Revenue"
                  value={formatMoney(
                    orders.potential_revenue +
                    orders.walk_in_order_revenue
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="large-12" style={{ marginBottom: 50 }}>
                {orders && <ItemsRequested equipment={orders.equipment} />}
              </div>
            </div>
            <div className="row">
              <table className="large-12">
                <tbody>
                  <tr>
                    <th>Status</th>
                    <th className="text-right">Count</th>
                    <th className="text-right">Revenue</th>
                  </tr>
                  <>
                    {makeStatusRows().map(row => {
                      return (
                        <tr key={row.key}>
                          <td>{row.status}</td>
                          <td className="text-right">
                            {statuses[`${row.key}_count`]}
                          </td>
                          <td className="text-right">
                            {formatMoney(statuses[`${row.key}_revenue`])}
                          </td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td>Rejected</td>
                      <td className="text-right">
                        {statuses.rejected_count}
                      </td>
                      <td className="text-right">
                        {formatMoney(statuses.rejected_value)}
                      </td>
                    </tr>
                  </>
                </tbody>
              </table>
            </div>
            <MonthlyPatients patients={monthly.get("patients")} />
            <CompletedOrders data={monthly.get("completed_order_stats")} />
        </div>
        : <Waiting reason="crunching the latest numbers..." />}
    </>
  )
}

export default CompanyMonthlyReportView

CompanyMonthlyReportView.propTypes = {
  orders: PropTypes.object,
  monthly: PropTypes.object,
  isFetchingMonthlyReport: PropTypes.bool
};
