import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import MdCall from "react-icons/lib/md/phone";
import { createTab } from "actions/system-tray";
import { enableAutoDialer, endAutoDialer } from "actions/auto-dialer";

const AutodialerBtn = ({
  currentlyInCall,
  autoDialerEnabled,
  createTab,
  enableAutoDialer,
  endAutoDialer,
  selectedPatients,
  followUpOrders,
  refreshData,
  batchClear
}) => {
  const isDisabled =
    !selectedPatients.length || autoDialerEnabled || currentlyInCall;
  return (
    <span
      className={`icon-button-sleepcoach-dashboard autodialer-btn ${
        isDisabled ? "disabled" : ""
      }`}
      onClick={() => {
        if (!isDisabled) {
          createTab({
            type: "patient-outreach",
            key: "patient-outreach",
            label: `Patients (${selectedPatients.length})`,
            meta: {
              ids: selectedPatients,
              followUpOrders
            },
            closeCallback: () => {
              endAutoDialer();
              batchClear();
              refreshData();
            }
          });
          enableAutoDialer(selectedPatients);
        }
      }}
    >
      <MdCall />
    </span>
  );
};

AutodialerBtn.propTypes = {
  autoDialerEnabled: PropTypes.bool,
  createTab: PropTypes.func.isRequired,
  currentlyInCall: PropTypes.bool,
  enableAutoDialer: PropTypes.func.isRequired,
  endAutoDialer: PropTypes.func.isRequired,
  selectedPatients: PropTypes.arrayOf(PropTypes.string).isRequired,
  followUpOrders: PropTypes.bool,
  refreshData: PropTypes.func.isRequired,
  batchClear: PropTypes.func.isRequired
};

AutodialerBtn.defaultProps = { followUpOrders: false };

export default connect(
  state => ({
    autoDialerEnabled: selectors.getIsAutoDialerEnabled(state),
    currentlyInCall: selectors.getIsPhoneConnected(state)
  }),
  {
    createTab,
    enableAutoDialer,
    endAutoDialer
  }
)(AutodialerBtn);
