import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUser } from "action-creators";
import {
  invalidateSleepCoachInsights,
  fetchSleepCoachInsights
} from "actions/insights";
import { ProfileCard, SumBlock, PageHeader, Waiting } from "components/ui";
import {
  PatientStatistics,
  OrderStatistics,
  ContactStatistics
} from "components/ui/statistics";
import DatePickerInput from "components/ui/date-picker-input";
import moment from "moment";

class SleepCoachScoreCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment().startOf("month").format("MM/DD/YYYY"),
      end: moment(new Date()).format("MM/DD/YYYY")
    };
  }

  componentDidMount() {
    const {
      user,
      userId,
      getUser,
      orderInsights,
      fetchSleepCoachInsights,
      invalidateSleepCoachInsights
    } = this.props;
    if (user.size == 0 || !orderInsights || orderInsights.size == 0) {
      getUser(userId);
    }
    invalidateSleepCoachInsights({
      userID: userId,
      start_date: this.state.start,
      end_date: this.state.end
    });
    fetchSleepCoachInsights({
      userID: userId,
      start_date: this.state.start,
      end_date: this.state.end
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      fetchSleepCoachInsights,
      invalidateSleepCoachInsights,
      userId
    } = this.props;
    invalidateSleepCoachInsights({
      userID: userId,
      start_date: this.state.start,
      end_date: this.state.end
    });
    fetchSleepCoachInsights({
      userID: userId,
      start_date: this.state.start,
      end_date: this.state.end
    });
  }

  handleEndDayClick = day => {
    this.setState({
      end: moment(day).format("MM/DD/YYYY")
    });
  };

  handleStartDayClick = day => {
    this.setState({
      start: moment(day).format("MM/DD/YYYY")
    });
  };
 

  render() {
    const {
      user,
      patientInsights,
      orderInsights,
      contactsInsights,
      fetchingInsights
    } = this.props;
    const title = user.get("first_name")
      ? user.get("first_name") + "'s Score Card"
      : "Your Score Card";
    const startDate = this.state.start;
    const endDate = this.state.end;

    return orderInsights ? (
      <div className="sleep-coach-score-card">
        <PageHeader title={title} />
        <div className="coach-score-card-container">
          <div className="leftSection">
            <section className="profile">
              <ProfileCard
                title="Profile"
                items={{
                  Email: user.get("email"),
                  Active: user.get("active")
                    ? user.get("active").toString()
                    : "false",
                  Extension: user.get("extension")
                }}
              />
              <ProfileCard
                title="Contact Types"
                items={{
                  Emails: user.get("emails_count"),
                  Calls: user.get("manual_calls_count"),
                  Texts: user.get("text_messages_count"),
                  App: user.get("app_count")
                }}
              />
            </section>
          </div>
          <div className="rightSection">
            <form
              className="score-card-header"
              onSubmit={this.handleSubmit.bind(this)}
            >
              <h2>S3 Platform Orders</h2>
              <div className="date-controls-container">
                <DatePickerInput
                  name="start"
                  label="Start Date *"
                  value={startDate}
                  placeholder={"MM/DD/YYY"}
                  showOutsideDays
                  handleDayChange={this.handleStartDayClick}
                />
                <DatePickerInput
                  name="end"
                  label="End Date *"
                  value={endDate}
                  placeholder={"MM/DD/YYY"}
                  showOutsideDays
                  handleDayChange={this.handleEndDayClick}
                />
                <button
                  type="submit"
                  value="Select Date Range"
                  className="btn small btn-primary"
                  onClick={this.handleSubmit.bind(this)}
                >
                  Submit
                </button>
              </div>
            </form>
            {!fetchingInsights ? (
              <div className="sum-block-container">
                <div className="equipment-cards-container">
                  <SumBlock
                    title="Masks"
                    value={
                      orderInsights
                        .getIn(["order_status", "masks"])
                        .toLocaleString() || 0
                    }
                  />
                  <SumBlock
                    title="Seals"
                    value={
                      orderInsights
                        .getIn(["order_status", "seals"])
                        .toLocaleString() || 0
                    }
                  />
                  <SumBlock
                    title="Filters"
                    value={
                      orderInsights
                        .getIn(["order_status", "filters"])
                        .toLocaleString() || 0
                    }
                  />
                  <SumBlock
                    title="Tubing"
                    value={
                      orderInsights
                        .getIn(["order_status", "tubing"])
                        .toLocaleString() || 0
                    }
                  />
                  <SumBlock
                    title="Chinstraps"
                    value={
                      orderInsights
                        .getIn(["order_status", "chinstraps"])
                        .toLocaleString() || 0
                    }
                  />
                  <SumBlock
                    title="Water Chambers"
                    tooltip="Water Chambers"
                    value={orderInsights.getIn([
                      "order_status",
                      "water_chambers"
                    ])}
                  />
                </div>
                <div className="order-cards-container">
                  <SumBlock
                    title="Net Orders"
                    tooltip="Water Chambers"
                    value={orderInsights.get("net_orders") || 0}
                  />
                  <SumBlock
                    title="My Patient Orders"
                    tooltip="My Patient Orders"
                    value={orderInsights.get("total") || 0}
                  />
                  <SumBlock
                    title="Orders I Submitted"
                    tooltip="My Patient Orders"
                    value={orderInsights.get("created_by_orders") || 0}
                  />
                  <SumBlock
                    title="My Patient Walkins"
                    tooltip="My Patient Orders"
                    value={orderInsights.get("walk_in_orders") || 0}
                  />
                  {/* <div className="large-2 columns"> */}
                  {/*   <div style={S.base}> */}
                  {/*     <div style={S.value}>{formatMoney(averageOrderRevenue) || 0}</div> */}
                  {/*     <div style={S.title}>Average Order Revenue</div> */}
                  {/*   </div> */}
                  {/* </div> */}
                </div>
              </div>
            ) : (
              <div
                style={{
                  marginTop: "350px"
                }}
              >
                <Waiting
                  reason={`crunching ${user.get("first_name")}'s numbers...`}
                />
              </div>
            )}
          </div>
        </div>
        {!fetchingInsights && (
          <div className="insight-cards-container">
            <PatientStatistics insights={patientInsights} />
            <OrderStatistics insights={orderInsights} />
            <ContactStatistics insights={contactsInsights} />
          </div>
        )}
      </div>
    ) : (
      <Waiting />
    );
  }
}

export default connect(
  state => ({
    user: state.getIn(["users", "profile"]),
    patients: state.get("needs_contact"),
    patientsSnapshot: state.get("patientsSnapshot"),
    patientInsights: state.getIn(["insights", "patientInsights"]),
    orderInsights: state.getIn(["insights", "orderInsights"]),
    contactsInsights: state.getIn(["insights", "contactsInsights"]),
    fetchingInsights: state.getIn(["fetch", "GET_INSIGHTS", "isFetching"]),
    userId: state.getIn(["user", "id"])
  }),
  {
    getUser,
    fetchSleepCoachInsights,
    invalidateSleepCoachInsights
  }
)(SleepCoachScoreCard);

SleepCoachScoreCard.propTypes = {
  contactsInsights: PropTypes.object,
  fetchSleepCoachInsights: PropTypes.func.isRequired,
  fetchingInsights: PropTypes.bool,
  getUser: PropTypes.func.isRequired,
  invalidateSleepCoachInsights: PropTypes.func.isRequired,
  orderInsights: PropTypes.object,
  patientInsights: PropTypes.object,
  userId: PropTypes.string.isRequired,
  user: PropTypes.object
};
