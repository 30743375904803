import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import {
  addPatientIdForVerification,
  setVerifiedPatientFlag,
  setReadOnlyPatientFlag,
  verificationRefused
} from "actions/patient-verification";
import { path } from "ramda";

const verificationMethods = {
  DATE_OF_BIRTH: "0",
  STREET_ADDRESS: "1"
};

const datesAreEqual = (date1, date2) => {
  let a = date1.toString().split("T")[0];
  let b = date2.toString().split("T")[0];
  return a === b;
};

const stringsAreEqual = (string1, string2) => {
  let a = string1.toLowerCase();
  let b = string2.toLowerCase();
  return a === b;
};

const getNumbersFromString = value => {
  const numsArray = value.match(/\d+/g);
  if (numsArray) return numsArray[0];
};

const PatientVerification = ({
  patientInfo,
  maxAttempts,
  addPatientIdForVerification,
  setVerifiedPatientFlag,
  setReadOnlyPatientFlag,
  verificationRefused
}) => {
  const [date, setDate] = useState(new Date());
  const [street, setStreet] = useState("");
  const [message, setMessage] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [verificationMethod, setVerificationMethod] = useState(
    verificationMethods.DATE_OF_BIRTH
  );
  const [noVerifactionMethod, setNoVerificationMethod] = useState(false);

  useEffect(() => {
    addPatientIdForVerification(patientInfo.id);
  }, []);

  useEffect(() => {
    if (attempts >= maxAttempts) setReadOnlyPatientFlag();
  }, [attempts]);

  if (patientInfo) {
    var patientDob = patientInfo.date_of_birth;
    var patientStreet = path(["address", "street_address"], patientInfo);
    var streetMessage = "";


    if (!patientDob && verificationMethod === verificationMethods.DATE_OF_BIRTH)
      setVerificationMethod(verificationMethods.STREET_ADDRESS)

    if (!patientDob && !patientStreet && !noVerifactionMethod)
      setNoVerificationMethod(true);

    if (typeof patientStreet === "string" || patientStreet instanceof String) {
      patientStreet = patientStreet.trim();
      if (patientStreet.toLowerCase().includes(" po box ")) {
        streetMessage = "PO Box Number";
        const poBoxIndex = patientStreet.indexOf(" po box ");
        patientStreet = getNumbersFromString(
          patientStreet.substr(poBoxIndex + 8)
        );
      } else {
        streetMessage = "House Number";
        patientStreet = getNumbersFromString(patientStreet);
      }
    }
  }

  const onFormSubmit = e => {
    e.preventDefault();
    let verified = false;

    switch (verificationMethod) {
      case verificationMethods.DATE_OF_BIRTH:
        verified = datesAreEqual(date, patientDob);
        break;
      case verificationMethods.STREET_ADDRESS:
        verified = stringsAreEqual(street, patientStreet);
        break;
    }

    if (verified) {
      setVerifiedPatientFlag();
    } else {
      setAttempts(attempts + 1);
      setMessage("incorrect");
    }
  };

  const onVerificationMethodSelected = e => {
    let method = e.target.value;
    if (method !== verificationMethod) {
      setMessage("");
      setVerificationMethod(method);
    }
  };

  const todaysDate = new Date().toISOString().split("T")[0];

  const patientId = patientInfo.id ?? "";
  const patientName = patientInfo.name ?? "a patient";

  return (
    <div className="verification-overlay">
      <ReactTooltip place="top" />
      <div className="verification-container">
        {noVerifactionMethod ? (
          <>
            <div className="verification-instructions">
              You are accessing{" "}
              <span className="patient-name">{patientName}&apos;s</span>{" "}
              profile.
              <br />
              We do not currently have an address or date of birth on record to
              verify their identity. <br/>
              Please use caution when discussing patient
              and order details. <br/>
              And please input the patient&apos;s date of
              birth and address into our system.
            </div>
            <div>
              <button className="verification-input" style={{float: "right", marginBottom: 0}} onClick={setVerifiedPatientFlag}>Ok</button>
            </div>
          </>
        ) : (
          <>
            <div className="verification-instructions">
              You are accessing{" "}
              <span className="patient-name">{patientName}&apos;s</span>{" "}
              profile. Please verify the patient&apos;s information.
            </div>
            <form className="verification-form" onSubmit={onFormSubmit}>
              <div>
                <select
                  className="verification-input"
                  onChange={onVerificationMethodSelected}
                  value={verificationMethod}
                >
                  {patientDob && (
                    <option value={verificationMethods.DATE_OF_BIRTH}>
                      Date of Birth
                    </option>
                  )}
                  {patientStreet && (
                    <option value={verificationMethods.STREET_ADDRESS}>
                      {streetMessage}
                    </option>
                  )}
                </select>
                <label className="verification-message">{message}</label>
                {verificationMethod === verificationMethods.DATE_OF_BIRTH && (
                  <input
                    className="verification-input"
                    type="date"
                    value={date}
                    min="1900-01-01"
                    max={todaysDate}
                    onChange={e => setDate(e.target.value)}
                  />
                )}
                {verificationMethod === verificationMethods.STREET_ADDRESS && (
                  <input
                    className="verification-input"
                    type="text"
                    value={street}
                    onChange={e => setStreet(e.target.value)}
                  />
                )}
              </div>
              <button type="submit">Submit</button>
            </form>
            <div
              className="skip-button danger"
              onClick={() => verificationRefused(patientId)}
              data-tip="Patient Refused to Provide Verification Information"
            >
              Verification Refused
            </div>
            <div
              className="skip-button"
              onClick={setReadOnlyPatientFlag}
              data-tip="Profile will be read-only"
            >
              Skip...
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PatientVerification.propTypes = {
  patientInfo: PropTypes.object.isRequired,
  maxAttempts: PropTypes.number.isRequired,
  addPatientIdForVerification: PropTypes.func.isRequired,
  setVerifiedPatientFlag: PropTypes.func.isRequired,
  setReadOnlyPatientFlag: PropTypes.func.isRequired,
  verificationRefused: PropTypes.func.isRequired
};

PatientVerification.defaultProps = {
  maxAttempts: 999
};

export default connect(() => ({}), {
  addPatientIdForVerification,
  setVerifiedPatientFlag,
  setReadOnlyPatientFlag,
  verificationRefused
})(PatientVerification);
