import React from "react";
import "./styles/confirmation-modal.css"
import PropTypes from "prop-types";

export default function ConfirmationModal({ title, closeModal, yesAction, notNowAction, yesOptionText, noOptionText, onlyShowOneButton }) {
    return <div className="main-container">
        <span className="title-container">
            {title}
        </span>

        {!onlyShowOneButton && <div className='dd_col-12'>
            <div className='dd_col-6'>
                <div className="button-container">
                    <button type="button" className="button alert" onClick={() => { closeModal && closeModal(); notNowAction && notNowAction(); }}>{noOptionText ?? "Not now"}</button>
                </div>
            </div>
            <div className='dd_col-6'>
                <div className="button-container">
                    <button type="button" className="button" onClick={() => { closeModal && closeModal(); yesAction && yesAction(); }}>{yesOptionText ?? "Yes"}</button>
                </div>
            </div>
        </div>
        }

        {onlyShowOneButton && <div className='dd_col-12'>
            <div style={{ justifyContent: 'center', marginTop: '5px' }}>
                <div className="button-container">
                    <button type="button" className="button" onClick={() => { closeModal && closeModal(); notNowAction && notNowAction(); }}>{yesOptionText ?? "OK"}</button>
                </div>
            </div>
        </div>
        }
    </div>
}
ConfirmationModal.propTypes = {
    title: PropTypes.string.isRequired,
    closeModal: PropTypes.bool.isRequired,
    yesAction: PropTypes.func.isOptional,
    notNowAction: PropTypes.func.isOptional,
    yesOptionText: PropTypes.string.isOptional,
    noOptionText: PropTypes.string.isOptional,
    onlyShowOneButton: PropTypes.bool.isOptional
};