import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";

const StatsListItem = ({ title, value, style }) => (
  <li style={[styles.li, style]}>
    <strong style={[styles.strong]}>{title}:</strong>{" "}
    {value}
  </li>
);

StatsListItem.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.any
};

StatsListItem.defaultProps = {
  value: "...",
  style: {}
};

const styles = {
  li: {
    fontSize: "1.15em"
  },
  strong: {
    color: "#666",
    fontWeight: 400
  }
};

export default Radium(StatsListItem);
