import {
  take,
  put
} from 'redux-saga/effects'
import { Types } from "actions/order-profile";

export default function*() {
  while (true) {
    yield take(Types.REQUEST_TYPES)
    yield put({
      type: Types.API_TYPES,
      payload: {
        apiRequest: {
          method: 'GET',
          route: 'orders/types',
          type: Types.RESPONSE_TYPES
        }
      }
    })
    yield take(Types.INVALIDATE_TYPES)
  }
}
