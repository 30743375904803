import { createReducer } from "utils/reducer";
import C from "action-types";
import * as R from "ramda";
import { createSelector } from "reselect";

export default createReducer(
  {},
  {
    [C.NOTIFICATIONS.ERROR]: (state, { type, message, n, id, period }) =>
      R.assoc(id, { type, message, n, id, period }, state),
    [C.NOTIFICATIONS.MESSAGE]: (state, { type, message, n, id, period }) =>
      R.assoc(id, { type, message, n, id, period }, state),
    [C.NOTIFICATIONS.UNKNOWN_ERROR]: (
      state,
      { type, message, n, id, period }
    ) => R.assoc(id, { type, message: message || "Something went wrong", n, id, period }, state),
    [C.NOTIFICATIONS.DISMISS]: (state, { id }) => R.dissoc(id, state)
  }
);

const getNotifications = createSelector(
  [state => state.getIn(["notifications"])],
  R.values
);

export const selectors = {
  getNotifications
};
