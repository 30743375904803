import { all, take, call, put } from "redux-saga/effects";
import { Types } from "actions/contacts";
import { errorMessage } from "actions/message";
import * as api from "utils/api";

function* updateContactAdminReview({ payload }) {
  try {
    const url = `contacts/${payload.contactId}/admin_review`;
    const putPayload = {
      adminReview: payload.adminReview,
      reason: payload.reason,
    };
    yield call(api.put, url, putPayload);
  } catch (error) {
    yield put(
      errorMessage("An error occured while slotting contact for admin review.")
    );
  }
}

function* watchUpdateContactAdminReview() {
  while (true) {
    const action = yield take(Types.UPDATE_ADMIN_REVIEW);
    yield call(updateContactAdminReview, action);
  }
}

export default function* root() {
  yield all([watchUpdateContactAdminReview()]);
}
