import React from "react";
import PatientSelector from "components/patient-selector";
import PatientQuestionnaires from "components/dd_newModules/subModule/PatientQuestionnaire/patient-questionnaires";

const PatientQuestionnaireSearch = () => {
  const [selectedPatient, setSelectedPatient] = React.useState({});
  return (
    <div
      style={{
        marginBottom: "15px",
        display: "flex",
        flex: 1,
        flexDirection: "column"
      }}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid #DEEAEE",
          padding: "20px"
        }}
      >
        <PatientSelector
          selectedPatients={[selectedPatient]}
          onPatientSelect={selectedPatientList => {
            const selectedPatient = selectedPatientList[0];
            setSelectedPatient({ ...selectedPatient });
          }}
          allowMultiSelect={false}
          onClear={() => {
            setSelectedPatient({});
          }}
        />
      </div>
      <div style={{ padding: 20 }}>
        {selectedPatient && selectedPatient.value ? (
          <PatientQuestionnaires patientId={selectedPatient.value} />
        ) : (
          <div
            style={{
              display: "flex",
              margin: 20,
              flex: 1,
              justifyContent: "center",
              border: "1px solid #DEEAEE",
              alignItems: "center",
              padding: 30
            }}
          >
            Search and select patient to view their questionnaires.
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientQuestionnaireSearch;
