import React from "react";
import PropTypes from "prop-types";
import { graphColors } from "utils/styles";
import { Pie, PieChart, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { path } from "ramda";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from 'react-tooltip';
import { formatPercantage } from "utils/misc";

const SmsBreakdown = ({ smsContacts, onExport }) => (
  <div className="graph-container">
    <ReactTooltip />
    {onExport && (<div className="stat-export-icon" data-tip="Export" onClick={onExport}><FaFileExcelO /></div>)}
    <h5 className="graph-header">SMS</h5>
    <ResponsiveContainer height={250}>
      <PieChart>
        <Pie
          data={[
            {
              name: "Text Messages",
              count: path(["text_message", "successes"], smsContacts),
              fill: graphColors[3]
            },
            {
              name: "Push Notifications",
              count: path(["push_notification", "successes"], smsContacts),
              fill: graphColors[4]
            }
          ]}
          isAnimationActive={false}
          dataKey="count"
          nameKey="name"
        />
        <Tooltip
          formatter={value =>
            `${value} - ${formatPercantage(value, smsContacts.success_total)}`
          }
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

SmsBreakdown.propTypes = {
  smsContacts: PropTypes.shape({
    text_message: PropTypes.object,
    push_notification: PropTypes.object,
    success_total: PropTypes.number
  }),
  onExport: PropTypes.func
};

export default SmsBreakdown;
