import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { browserHistory } from "browser-history";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { manageQueryDirection, deSnakeCase } from "utils/misc";
import { updateSearchTableQuery } from "actions/search-table-query";
import { colors } from "utils/styles";
import moment from "moment";
import { Map, fromJS } from "immutable";
import Radium from "radium";
import styler from "react-styling";
import { PageHeader, Waiting, TableFooter, InlineWaiting } from "components/ui";
import { search } from "actions/search";
import { requestFulfillmentStatusUpdate } from "actions/fulfillment-status";
import BatchRowCell from "hooks/useQuery/local-query/batch-row-cell";
import { getRouteParam } from "routes";

class FulfillmentSearchTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrders: [],
      allChecked: false,
      fulfillmentStatus: ""
    };
  }

  componentDidMount() {
    const { search_field, search_value, updateSearchTableQuery } = this.props;
    updateSearchTableQuery({
      table: "fulfillment",
      field: search_field,
      value: search_value,
      page: 1,
      per: 150,
      direction: "asc"
    })
  }

  componentDidUpdate(prevProps) {
    const { search_field, search_value, updateSearchTableQuery } = this.props
    if ((prevProps.search_field !== search_field) ||
      (prevProps.search_value !== search_value)) {

      updateSearchTableQuery({
        table: "fulfillment",
        field: search_field,
        value: search_value,
        page: 1,
        per: 150,
        direction: "asc"
      })
    }
  }

  updateQuery(updatedQuery) {
    const {
      updateSearchTableQuery,
      query
    } = this.props;

    const newQuery = manageQueryDirection(fromJS(updatedQuery), fromJS(query)).toJS()

    if (newQuery.filter_by && newQuery.filter_by !== "") {
      newQuery.filter = { [newQuery.filter_by.replace(" ", "_")]: newQuery.filter_value }
    }
    if (newQuery.order_by && (newQuery.order_by !== query.order)) {
      newQuery.filter = { type: newQuery.order_by }
    }
    newQuery.table = "fulfillment"
    updateSearchTableQuery(newQuery);
  }

  paginationAction(action) {
    const { search_field, search_value, updateSearchTableQuery } = this.props
    const sortBy = action.sort_by || null

    updateSearchTableQuery({
      table: "fulfillment",
      field: search_field,
      value: search_value,
      page: action.page,
      per: action.per,
      sortBy: sortBy
    })
  }
  // handleGetFulfillmentExport() {
  //   const { query, user, exportTable } = this.props;
  //   exportTable(fromJS(query), user.get('id'), "fulfillment");
  // }

  updateStatus = () => {
    const ids = this.state.selectedOrders;
    if (ids.length > 0 && this.state.fulfillmentStatus) {
      this.props.requestFulfillmentStatusUpdate(ids, this.state.fulfillmentStatus);
    }
  }

  handlePrint() {
    const ids = this.state.selectedOrders;
    browserHistory.push({
      pathname: `/fulfillment/print-packing-slips`,
      state: { orderIds: ids }
    });
  }

  handleCheckAll = () => {
    const { fulfillmentSearchResultIds } = this.props;
    if (this.state.selectedOrders.length !== fulfillmentSearchResultIds.length) {
      this.setState({ ...this.state, selectedOrders: fulfillmentSearchResultIds });
    } else {
      this.setState({ ...this.state, selectedOrders: [] });
    }
  }

  handleSelectChange = ({ target: { value } }) =>
    this.setState({ ...this.state, fulfillmentStatus: value });

  render() {
    const { query, fulfillment, search_field, search_value } = this.props;
    const ready = fulfillment.size || query

    return (
      <div>
        <PageHeader
          title={`Fulfillment orders where ${deSnakeCase(
            search_field
          ).toLowerCase()} is ${search_value}`}
          count={query && query.count}
        />
        {ready ? (
          <div className="row">
            <div className="row table-topper collapse">
              <div className="small-6 columns">
                <select
                  className="small-3"
                  onChange={e => this.updateQuery({ per: e.target.value })}
                  value={query.per}
                >
                  <option value="150">150</option>
                  <option value="300">300</option>
                  <option value="500">500</option>
                </select>
              </div>
              <div className="small-6 columns text-right">
                <select
                  className="small-3"
                  onChange={e => this.updateQuery({ order_by: e.target.value })}
                  value={query.order_by}
                >
                  <option value="All">All</option>
                  <option value="CPAP_Dropship">CPAP Dropship</option>
                  <option value="Independence_Medical">
                    Independence Medical
                  </option>
                  <option value="Internal">Internal</option>
                  <option value="PPM">PPM</option>
                  <option value="VGM">VGM</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                <table className="large-12">
                  <thead>
                    <tr>
                      <th
                        style={S.filter}
                        onClick={() => this.handleCheckAll()}
                      >
                        All
                      </th>
                      <th
                        style={S.filter}
                        onClick={() =>
                          this.updateQuery({ sort_by: "updated_at" })
                        }
                      >
                        Last Updated
                      </th>
                      <th
                        style={S.filter}
                        onClick={() =>
                          this.updateQuery({ sort_by: "created_at" })
                        }
                      >
                        Order Created
                      </th>
                      <th
                        style={S.filter}
                        onClick={() =>
                          this.updateQuery({ sort_by: "invoice_number" })
                        }
                      >
                        Invoice Number
                      </th>
                      <th>Company</th>
                      <th>Patient</th>
                      <th>Sleep Coach</th>
                      <th
                        style={S.filter}
                        onClick={() =>
                          this.updateQuery({ sort_by: "fulfillment_type" })
                        }
                      >
                        Fulfillment Type
                      </th>
                      <th
                        style={S.filter}
                        onClick={() => this.updateQuery({ sort_by: "status" })}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fulfillment.map((order) => (
                      <tr key={order.get("id")}>
                        <BatchRowCell
                          selectedRows={this.state.selectedOrders}
                          setSelectedRows={rows => {
                            this.setState({ ...this.state, selectedOrders: rows })
                          }}
                          id={order.get("id")}
                        />
                        <td style={S.td}>
                          {moment(order.get("updated_at")).format(
                            "YYYY / MM / DD"
                          )}
                        </td>
                        <td style={S.td}>
                          {moment(order.get("created_at")).format(
                            "YYYY / MM / DD"
                          )}
                        </td>
                        <td style={S.td}>
                          <Link to={`/orders/${order.get("id")}`}>
                            {order.get("invoice_number")}
                          </Link>
                        </td>
                        <td style={S.td}>{order.get("company")}</td>
                        <td style={S.td}>
                          <Link to={`/patients/${order.get("patient_id")}`}>
                            {order.get("patient")}
                          </Link>
                        </td>
                        <td style={S.td}>{order.get("sleep_coach")}</td>
                        <td style={S.td}>
                          {order.get("fulfillment_type")
                            ? order.get("fulfillment_type")
                            : "Internal"}
                        </td>
                        <td style={S.td}>{order.get("status")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row table-footer collapse">
              <div className="large-8 columns">
                <select
                  value={this.state.fulfillmentStatus}
                  onChange={this.handleSelectChange}
                >
                  <option value="" />
                  <option value="pending fulfillment">
                    Ready to ship (not sent to fulfillment center)
                  </option>
                  <option value="pending shipment">
                    Pending shipment (sent to fulfillment center and waiting for
                    shipment confirmation)
                  </option>
                  <option value="completed">Move to completed</option>
                </select>
              </div>
              <div className="large-4 columns">
                {this.props.isUpdatingFulfillment ? (
                  <InlineWaiting />
                ) : (
                  <input
                    className="button postfix"
                    type="submit"
                    value="Update Selected"
                    onClick={this.updateStatus}
                  />
                )}
              </div>
            </div>
            <TableFooter
              query={fromJS(query)}
              handlePrint={this.handlePrint.bind(this)}
              handlePagination={this.paginationAction.bind(this)}
              canExport={false}
            />
            {/* handleExportClick={this.handleGetFulfillmentExport.bind(this)} */}
          </div>
        ) : (
          <Waiting />
        )}
      </div>
    );
  }
}

export default withRouter(connect(
  (state, props) => {
    const entitiesFulfillment = state.getIn(["entities", "fulfillment"], Map()).toJS()
    const fulfillmentSearchResultIds = state.get('search').ids.fulfillment || []
    const fulfillmentsSearchResults = fulfillmentSearchResultIds.map((fulfillmentId) => {
      return entitiesFulfillment[fulfillmentId]
    })
    const query = state.get("search").metaData.fulfillment

    return {
      search_value: getRouteParam(props, 'value'),
      search_field: getRouteParam(props, 'field'),
      isUpdatingFulfillment: selectors.getIsUpdatingFulfilment(state),
      fulfillment: fromJS(fulfillmentsSearchResults),
      query: query,
      user: state.get("user"),
      fulfillmentSearchResultIds
    }
  },
  {
    updateSearchTableQuery,
    requestFulfillmentStatusUpdate,
    search
  }
)(Radium(FulfillmentSearchTable)));

FulfillmentSearchTable.propTypes = {
  isUpdatingFulfillment: PropTypes.bool.isRequired,
  fulfillment: PropTypes.object.isRequired,
  search_field: PropTypes.string.isRequired,
  search_value: PropTypes.string.isRequired,
  query: PropTypes.object,
  requestFulfillmentStatusUpdate: PropTypes.func.isRequired,
  updateSearchTableQuery: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fulfillmentSearchResultIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const S = styler`
  filter
    text-decoration: underline
    color: ${colors.primaryColor}
    cursor: pointer
  td
    white-space: nowrap
`;
