import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import MultiSelectDropdown from "components/forms/multi-select-dropdown";
import { setFilter, clearFilter } from "hooks/useQuery/actions";
// import { QueryTableContext } from "hooks/useQuery/api-query";

const TasksFilter = ({ query, queryDispatcher }) => {
  const taskActionOptions = useSelector(selectors.getTaskActionsOptions);
  const userId = useSelector(selectors.getUserId);
  const [selectedTaskActions, setSelectedTaskActions] = useState([]);
  const [taskStatus, setTaskStatus] = useState(
    query.filters?.task_status ?? "pending_tasks"
  );
  const showMyTasks = query.filters?.assigned_to == userId;
  return (
    <div className="table-task-filter-container">
      <div
        className="table-task-filter"
        style={{
          display: "grid",
          gridGap: 10,
          gridTemplateColumns: "1fr 100px",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            width: "100%",
            flexDirection: "column"
          }}
          className="task-filter-select-container"
        >
          <span className="table-task-filter-label">Tasks</span>
          <MultiSelectDropdown
            onChange={setSelectedTaskActions}
            onBlur={() => {
              queryDispatcher({
                type: "setFilter",
                by: taskStatus,
                value: [...selectedTaskActions]
              });
            }}
            onRemove={s => {
              queryDispatcher(
                setFilter(
                  taskStatus,
                  selectedTaskActions.filter(h => h !== s.value)
                )
              );
            }}
            values={selectedTaskActions}
            options={taskActionOptions.filter(x => x.is_patient_task)}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <label className="table-task-filter-label">Status</label>
          <select
            value={taskStatus}
            onChange={({ target }) => {
              queryDispatcher(clearFilter(taskStatus));
              setTaskStatus(target.value);
              // updateFilters({
              //   ...query.filters,
              //   [target.value]: selectedTaskActions,
              //   // task_status: [target.value]
              // });
              queryDispatcher(setFilter(target.value, ...selectedTaskActions));
            }}
          >
            <option value="pending_tasks">Pending</option>
            <option value="completed_tasks">Completed</option>
            <option value="any_tasks">Any</option>
          </select>
        </div>
      </div>

      <div
        className="table-task-filter"
        style={{
          display: "grid",
          gridGap: 10,
          gridTemplateColumns: "3fr 1fr 2fr",
          alignItems: "center"
        }}
      >
        <div
          style={{
            // borderRight: "1px solid #ccc",
            paddingRight: 15,
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end"
            }}
            className="radio-group-container"
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                padding: 5,
                color: "white"
              }}
            >
              <span style={{ paddingRight: 5 }} className="radio-group-option">
                My Tasks
              </span>
              <input
                type="radio"
                className="input-field"
                style={{ margin: 0 }}
                checked={showMyTasks}
                onChange={({ target }) => {
                  if (target.checked) {
                    queryDispatcher({
                      type: "setFilter",
                      by: "assigned_to",
                      value: userId
                    });
                  }
                }}
              />
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                padding: 5,
                color: "white"
              }}
            >
              <span style={{ paddingRight: 5 }} className="radio-group-option">
                All Tasks
              </span>
              <input
                type="radio"
                className="input-field"
                style={{ margin: 0 }}
                checked={!showMyTasks}
                onChange={({ target }) => {
                  if (target.checked) {
                    queryDispatcher({
                      type: "setFilter",
                      by: "assigned_to",
                      value: null
                    });
                  }
                }}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksFilter;

TasksFilter.propTypes = {
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired
};
