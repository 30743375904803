import { select, put, takeEvery } from "redux-saga/effects";
import { selectors } from "reducers";
import { Types } from "actions/phone";
import * as Sentry from "@sentry/react";

function* handleIncomingCall({ payload: connection }) {
  try {
    const dialerIsActive = yield select(selectors.getIsAutoDialerEnabled);
    const from = connection.parameters.From.replace("+1", "");
    const autoAnswerIncoming = connection?.customParameters?.get("auto_answer");
    // const queueCallToAnswer = yield select(state =>
    //   selectors.getQueueCallToAnswer(state)
    // );
    // const thisIsTheQueuedCall =
    //   queueCallToAnswer && queueCallToAnswer.from === from;
    if (dialerIsActive && autoAnswerIncoming) {
      connection.accept();
      yield put({ type: Types.TWILIO_EVENT_ACCEPT_INCOMING });
    }
    yield put({ type: Types.SET_CALL_DIRECTION, payload: "inbound" });
    yield put({ type: Types.SET_CONNECTED_INCOMING_NUMBER, payload: from });
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag("location", window.location.href);
      scope.setTag("incomingCall", true);
      Sentry.captureException(error);
    });
  }
}

export default function* watchIncomingCall() {
  yield takeEvery(Types.TWILIO_EVENT_INCOMING, handleIncomingCall);
}
