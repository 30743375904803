import { combineReducers } from 'redux'
import { staticReducer } from 'utils/reducer'
import {
  eq,
  or,
  variable,
  constant,
  evaluate
} from 'utils/logic'

const statuses = staticReducer([
  {
    value: 'new',
    text: 'New'
  },
  {
    value: 'ready',
    text: 'Accepted'
  },
  {
    value: 'pending',
    text: 'Pending Confirmation'
  },
  /*
  {
    value: 'delayed',
    text: 'Delayed'
  },
  */
  {
    value: 'follow_up',
    text: 'Follow Up'
  },
  {
    value: 'on_hold',
    text: 'On Hold'
  },
  {
    value: 'incorrect',
    text: 'Incorrect'
  },
  {
    value: 'completed',
    text: 'Completed'
  },
  {
    value: 'billed',
    text: 'Billed'
  },
  {
    value: 'rejected',
    text: 'Rejected'
  },
  {
    value: 'voided',
    text: 'Voided'
  },
  {
    value: 'internal_processing',
    text: 'Internal Processing'
  },
  {
    value: 'pending_buildout',
    text: 'Pending Buildout'
  },
  {
    value: 'ready_for_review',
    text: 'Ready for Review'
  },
  { 
    value: "location_assistance_needed",
    text: "Location Assistance Needed"
  },
  { 
    value: "order_loading",
    text: "Order Loading"
  },
  {
    value: "integration_follow_up",
    text: "Integration Follow Up"
  }
])

const claimPermissions = staticReducer(or(
  eq(
    variable('userRole'),
    constant('OfficeAdministrator')
  ),
  eq(
    variable('userRole'),
    constant('CompanyAdministrator')
  ),
  eq(
    variable('userRole'),
    constant('ServiceAdmin')
  ),
  eq(
    variable('userRole'),
    constant('ServiceCoach')
  ),
  eq(
    variable('userRole'),
    constant('ContractorAdministrator')
  )
))

const exportPermissions = staticReducer(or(
  eq(
    variable('userRole'),
    constant('Administrator')
  ),
  eq(
    variable('userRole'),
    constant('OfficeAdministrator')
  ),
  eq(
    variable('userRole'),
    constant('CompanyAdministrator')
  ),
  eq(
    variable('userRole'),
    constant('ServiceAdmin')
  ),
  eq(
    variable('userRole'),
    constant('ServiceCoach')
  ),
  eq(
    variable('userRole'),
    constant('ContractorAdministrator')
  )
))

const orderStatusRoot = combineReducers({
  statuses,
  claimPermissions,
  exportPermissions
})

export default orderStatusRoot

const getOrderIndexInfo = ({ statuses }, orderStatus) => statuses.find(({ value }) => value === orderStatus)

const getCanUserClaimOrder = ({ claimPermissions }, props) => (
  evaluate(claimPermissions, props)
)

const getCanUserExportOrder = ({ exportPermissions }, props) => (
  evaluate(exportPermissions, props)
)

const getOrderStatusTextOptions = ({ statuses }) => statuses

export const selectors = {
  getOrderIndexInfo,
  getCanUserClaimOrder,
  getCanUserExportOrder,
  getOrderStatusTextOptions
}
