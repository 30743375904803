import React from "react";
import PropTypes from "prop-types";
import MdEdit from "react-icons/lib/md/edit";

const EditLink = ({ showIcon, onClick, text }) => (
  <span
    style={{
      cursor: "pointer",
      color: "#4281a6"
    }}
    onClick={onClick}
  >
    {showIcon && <MdEdit />}
    <span style={{ paddingLeft: 7 }}>{text}</span>
  </span>
);

EditLink.defaultProps = { showIcon: true, text: "Edit" };

EditLink.propTypes = {
  showIcon: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default EditLink;
