import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  requestUserLogin,
  requestPasswordResetEmail
} from "actions/authentication";
import { selectors } from "reducers";
import Disclaimer from "./disclaimer";
import AzureAdSSO, { getAzureClientIdFromUrl } from "./azure-ad-sso";

const Login = ({
  isRequestingResetPasswordEmail,
  loginError,
  requestUserLogin,
  requestPasswordResetEmail,
  isSubmitting
}) => {
  const [mode, setMode] = React.useState(() =>
    getAzureClientIdFromUrl() ? "SSO" : "Login"
  );
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  function handleLogin(e) {
    e.preventDefault();
    requestUserLogin(email, password, window.device_token);
  }

  function handleForgotPassword(e) {
    e.preventDefault();
    requestPasswordResetEmail({ email });
  }
  return (
    <React.Fragment>
      <div id="login_background"></div>
      <section
        id="log_in_screen"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100vh",
          overflow: "auto"
        }}
      >
        {mode == "Login" && (
          <div
            className="login_panel"
            style={{ display: "flex", flex: 1, flexDirection: "column" }}
          >
            <div className="loginLogo"></div>
            <h2 className="loginWelcome">WELCOME</h2>
            <form
              acceptCharset="UTF-8"
              onSubmit={handleLogin}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%"
              }}
            >
              <div>
                <input
                  className="loginInput"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  placeholder="Email Address"
                  size="50"
                  type="email"
                  autoFocus
                  required
                />
                <input
                  className="loginInput"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  maxLength="128"
                  placeholder="Password"
                  size="50"
                  type="password"
                  required
                />
                {!isSubmitting && !loginError && <div>&nbsp;</div>}
                <div className="loginError">{!isSubmitting && loginError}</div>
                <div className="submitting">
                  {isSubmitting && "Submitting..."}
                </div>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <button className="buttonLogin" type="submit">
                  Sign In
                </button>
              </div>
            </form>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "10px"
              }}
            >
              <span
                className="clickable"
                onClick={() => setMode("ForgotPassword")}
              >
                Forgot your password?
              </span>
              <a
                style={{ marginTop: 15 }}
                href="https://sleepsolutionsandservices.com/terms-of-use"
                className="clickable"
              >
                Terms Of Use
              </a>
            </div>
            <Disclaimer />
          </div>
        )}
        {mode == "SSO" && (
          <div
            className="login_panel"
            style={{ display: "flex", flex: 1, flexDirection: "column" }}
          >
            <div className="loginLogo"></div>
            <h2 className="loginWelcome">WELCOME</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyItems: "flex-end",
                flex: 1,
                width: "100%"
              }}
            >
              <AzureAdSSO
                setEmail={setEmail}
                setPassword={setPassword}
                requestUserLogin={requestUserLogin}
              />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "10px"
                }}
              >
                <span
                  className="clickable"
                  onClick={() => setMode("ForgotPassword")}
                >
                  Forgot your password?
                </span>
                <a
                  style={{ marginTop: 15 }}
                  href="https://sleepsolutionsandservices.com/terms-of-use"
                  className="clickable"
                >
                  Terms Of Use
                </a>
              </div>
              <Disclaimer />
            </div>
          </div>
        )}
        {mode === "ForgotPassword" && (
          <div className="login_panel">
            <div className="loginLogo"></div>
            <h2 className="loginWelcome">Forgot your password?</h2>
            <form
              acceptCharset="UTF-8"
              onSubmit={handleForgotPassword}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <input
                className="loginInput"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                placeholder="Email Address"
                size="50"
                type="email"
                autoFocus
                required
              />
              <input
                className="buttonLogin"
                type="submit"
                value={
                  isRequestingResetPasswordEmail
                    ? "Sending Email"
                    : "Send me reset password instructions"
                }
                disabled={isRequestingResetPasswordEmail}
              />
            </form>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <span className="clickable" onClick={() => setMode("Login")}>
                Sign in
              </span>
              <br />
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default connect(
  state => ({
    loginError: selectors.getLoginError(state),
    isSubmitting: selectors.getIsLoginSubmitting(state),
    resetEmailFailure: state.getIn([
      "fetch",
      "SEND_RESET_PASSWORD_EMAIL",
      "didFail"
    ]),
    isRequestingResetPasswordEmail: state.getIn([
      "fetch",
      "SEND_RESET_PASSWORD_EMAIL",
      "isFetching"
    ])
  }),
  {
    requestUserLogin,
    requestPasswordResetEmail
  }
)(Login);

Login.propTypes = {
  requestUserLogin: PropTypes.func.isRequired,
  requestPasswordResetEmail: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  loginError: PropTypes.string,
  isRequestingResetPasswordEmail: PropTypes.bool
};
