import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PatientProfile from "components/screens/patient/patient-profile";
import { Waiting } from "components/ui";
import PatientInboundLookupTable from "./table";
import PatientSearch from "./patient-filter-search";
import PatientVerificationButton from "../patient-verification-button";
import { autoDialerProcessNext } from "actions/auto-dialer";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";
import IoArrowLeftA from "react-icons/lib/io/arrow-left-a";
import IconButton from "components/ui/icon-button";
import MdCancel from "react-icons/lib/md/cancel";
import MdSearch from "react-icons/lib/md/search";

const InboundPatientLookup = ({
  wrapupTime,
  autoDialerProcessNext,
  callFromQueue
}) => {
  const [selectedPatient, setSelectedPatient] = useState("");
  const [searchPatients, setSearchPatients] = useState(false);

  return (
    <div className="pages">
      <div className="page-container">
        <div
          className="patient-lookup-container"
          style={{ display: selectedPatient && "none" }}
        >
          <div className="patient-lookup-header">
            <span>Inbound Call - Patient Lookup</span>
            <IconButton
              icon={searchPatients ? <MdCancel /> : <MdSearch />}
              onClick={() => setSearchPatients(!searchPatients)}
              text={searchPatients ? "Cancel" : "Search"}
            />
          </div>
          {searchPatients ? (
            <div>
              <PatientSearch />
              <PatientInboundLookupTable
                setSelectedPatient={setSelectedPatient}
                callFromQueue={callFromQueue}
              />
            </div>
          ) : callFromQueue ? (
            <ul>
              {callFromQueue.patients.map(({ guid, name }) => (
                <li
                  key={guid}
                  onClick={() => setSelectedPatient(guid)}
                  className="clickable-link"
                >
                  {name}
                </li>
              ))}
            </ul>
          ) : (
            <Waiting />
          )}
        </div>
        {selectedPatient && (
          <div style={{ padding: 20 }}>
            <PatientProfile patientId={selectedPatient} /*verify*/ />
          </div>
        )}
      </div>
      <div className="page-select">
        <div className="autodialer-menu">
          {selectedPatient && (
            <i
              onClick={() => {
                setSelectedPatient(() => "");
              }}
              title="Back to patient lookup"
            >
              <IoArrowLeftA />
            </i>
          )}
          {wrapupTime !== 0 && (
            <i title="Next" onClick={autoDialerProcessNext}>
              <IoArrowRightA />
            </i>
          )}
          <span className="autodialer-status">Processing Call from Queue.</span>
          <PatientVerificationButton />
        </div>
      </div>
    </div>
  );
};

InboundPatientLookup.propTypes = {
  wrapupTime: PropTypes.number,
  autoDialerProcessNext: PropTypes.func.isRequired,
  callFromQueue: PropTypes.shape({
    to: PropTypes.string,
    from: PropTypes.string,
    patients: PropTypes.arrayOf(PropTypes.object)
  })
};

export default connect(
  state => ({
    callFromQueue: selectors.getQueueCallToAnswer(state),
    wrapupTime: selectors.getAutoDialerWrapupTime(state)
  }),
  {
    autoDialerProcessNext
  }
)(InboundPatientLookup);
