const prefix = type => `s3/phone/${type}`;

export const Types = {
  OPEN_PHONE: prefix("OPEN"),
  CLOSE_PHONE: prefix("CLOSE"),
  PHONE_CALL: prefix("PHONE_CALL"),
  PHONE_CALL_ERROR: prefix("PHONE_CALL_ERROR"),
  IVR_PHONE_CALLS: prefix("IVR_PHONE_CALLS"),
  HANGUP: prefix("HANGUP"),
  TOGGLE_MUTE: prefix("TOGGLE_MUTE"),
  TRANSFER: prefix("TRANSFER"),
  CANCEL_TRANSFER: prefix("CANCEL_TRANSFER"),
  SET_DIALER_NUMBER: prefix("SET_DIALER_NUMBER"),
  SET_QUEUE_SELECTION: prefix("SET_QUEUE_SELECTION"),
  SEND_DIGITS: prefix("SEND_DIGITS"),
  TWILIO_EVENT_ACCEPT_INCOMING: prefix("EVENT_ACCEPT_INCOMING"),
  TWILIO_EVENT_REJECT_INCOMING: prefix("EVENT_REJECT_INCOMING"),
  PAUSE_PHONE_RECORDING: prefix("PAUSE_PHONE_RECORDING"),
  RESUME_PHONE_RECORDING: prefix("RESUME_PHONE_RECORDING"),
  SET_PHONE_RECORDING_STATUS: prefix("SET_PHONE_RECORDING_STATUS"),
  TWILIO_EVENT_READY: prefix("EVENT_READY"),
  TWILIO_EVENT_OFFLINE: prefix("EVENT_OFFLINE"),
  TWILIO_EVENT_CONNECT: prefix("EVENT_CONNECT"),
  TWILIO_EVENT_DISCONNECT: prefix("EVENT_DISCONNECT"),
  TWILIO_EVENT_MUTE: prefix("EVENT_MUTE"),
  TWILIO_EVENT_UNMUTE: prefix("EVENT_UNMUTE"),
  TWILIO_EVENT_INCOMING: prefix("EVENT_INCOMING"),
  TWILIO_EVENT_INCOMING_CANCELED: prefix("EVENT_INCOMING_CANCELED"),
  TWILIO_EVENT_CALL_ERROR: prefix("TWILIO_EVENT_CALL_ERROR "),
  TWILIO_EVENT_DEVICE_ERROR: prefix("TWILIO_EVENT_DEVICE_ERROR "),
  TWILIO_EVENT_CALL_WARNING: prefix("TWILIO_EVENT_CALL_WARNING"),
  TWILIO_EVENT_CALL_WARNING_CLEARED: prefix(
    "TWILIO_EVENT_CALL_WARNING_CLEARED"
  ),
  ACCEPT_INCOMING: prefix("ACCEPT_INCOMING"),
  REJECT_INCOMING: prefix("REJECT_INCOMING"),
  QUEUE_UPDATE: prefix("QUEUE_UPDATE"),
  QUEUE_UPDATE_FAILED: prefix("QUEUE_UPDATE_FAILED"),
  CALL_DURATION: prefix("CALL_DURATION"),
  SET_QUEUE_CALL_ACTIVE: prefix("SET_QUEUE_CALL_ACTIVE"),
  SET_TRANSFER_IN_PROGRESS: prefix("SET_TRANSFER_IN_PROGRESS"),
  SET_CALL_IN_CONFERENCE_TRUE: prefix("SET_CALL_IN_CONFERENCE_TRUE"),
  SET_CALL_IN_CONFERENCE_FALSE: prefix("SET_CALL_IN_CONFERENCE_FALSE"),
  SET_TRANSFER_NUMBER_IN_CONFERENCE_TRUE: prefix(
    "SET_TRANSFER_NUMBER_IN_CONFERENCE_TRUE"
  ),
  SET_TWILIO_CALLSID: prefix("SET_TWILIO_CALLSID"),
  SET_QUEUE_CALL_TO_ANSWER: prefix("SET_QUEUE_CALL_TO_ANSWER"),
  SET_CALL_DIRECTION: prefix("SET_CALL_DIRECTION"),
  SET_TRANSFER_NUMBER: prefix("SET_TRANSFER_NUMBER"),
  SET_DIALED_NUMBER: prefix("SET_DIALED_NUMBER"),
  PLAY_AUTOMATED_VOICEMAIL: prefix("PLAY_AUTOMATED_VOICEMAIL"),
  PLAY_AUTOMATED_VOICEMAIL_SUCCESS: prefix("PLAY_AUTOMATED_VOICEMAIL_SUCCESS"),
  RECONNECT_IVR_PATIENT_WITH_IVR_SYSTEM: prefix(
    "RECONNECT_IVR_PATIENT_WITH_IVR_SYSTEM"
  ),
  SET_CONNECTED_INCOMING_NUMBER: prefix("SET_CONNECTED_INCOMING_NUMBER"),
  ANSWER_QUEUE: prefix("ANSWER_QUEUE")
};

export const openPhone = () => ({
  type: Types.OPEN_PHONE
});

export const closePhone = () => ({
  type: Types.CLOSE_PHONE
});

export const phoneCall = ({ to, from, patientToCall, ...rest }) => ({
  type: Types.PHONE_CALL,
  payload: { to, from, patientToCall, isQueueAnswer: false, ...rest }
});

export const startIVRPhoneCalls = ({ toArray }) => ({
  type: Types.IVR_PHONE_CALLS,
  payload: { toArray }
});

export const answerQueue = ({ queue, from }) => ({
  type: Types.ANSWER_QUEUE,
  payload: { to: `queue: ${queue}`, from, isQueueAnswer: true }
});

export const hangup = () => ({
  type: Types.HANGUP
});

export const toggleMute = () => ({
  type: Types.TOGGLE_MUTE
});

export const transfer = ({ number }) => ({
  type: Types.TRANSFER,
  payload: { number }
});

export const cancelTransfer = () => ({
  type: Types.CANCEL_TRANSFER
});

export const setDialerNumber = (dialerNumber, patientToCall) => ({
  type: Types.SET_DIALER_NUMBER,
  payload: { dialerNumber, patientToCall }
});

export const setQueueSelection = selection => ({
  type: Types.SET_QUEUE_SELECTION,
  payload: { selection }
});

export const setTransferNumber = number => ({
  type: Types.SET_TRANSFER_NUMBER,
  payload: number
});
export const sendDigits = digits => ({
  type: Types.SEND_DIGITS,
  payload: { digits }
});
export const handleAcceptIncoming = () => ({ type: Types.ACCEPT_INCOMING });

export const handleRejectIncoming = () => ({ type: Types.REJECT_INCOMING });

export const pausePhoneRecording = () => ({
  type: Types.PAUSE_PHONE_RECORDING
});

export const resumePhoneRecording = () => ({
  type: Types.RESUME_PHONE_RECORDING
});

export const setPhoneRecordingStatus = status => ({
  type: Types.SET_PHONE_RECORDING_STATUS,
  payload: { status }
});

export const playAutomatedVoicemail = patientGuid => ({
  type: Types.PLAY_AUTOMATED_VOICEMAIL,
  payload: { patientGuid }
});

export const reconnectIVRPatientWithIVRSystem = patientGuid => ({
  type: Types.RECONNECT_IVR_PATIENT_WITH_IVR_SYSTEM,
  payload: { patientGuid }
});

export const setConnectedIncomingNumber = number => ({
  type: Types.SET_CONNECTED_INCOMING_NUMBER,
  payload: { number }
});
