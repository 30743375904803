import React from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";
import { requestUpdateOrderReasonRejectedInfo } from "actions/order-status";

const ReasonRejectedInfo = ({
  value,
  orderRejectedReasons,
  requestUpdateOrderReasonRejectedInfo,
  orderId
}) => {
  const currentValue = orderRejectedReasons.find(
    reason => reason.text === value || reason.value === value
  );
  return (
    <select
      value={currentValue ? currentValue.value : value}
      onChange={({ target }) => {
        if (target.value && value !== target.value) {
          requestUpdateOrderReasonRejectedInfo(orderId, target.value);
        }
      }}
    >
      {!currentValue && (
        <option readOnly disabled value={value}>
          {value}
        </option>
      )}
      {orderRejectedReasons.map(({ text, value }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};

ReasonRejectedInfo.propTypes = {
  value: PropTypes.string,
  orderId: PropTypes.string.isRequired,
  requestUpdateOrderReasonRejectedInfo: PropTypes.func,
  orderRejectedReasons: PropTypes.arrayOf(PropTypes.object).isRequired
};

ReasonRejectedInfo.defaultProps = { value: "" };

export default connect(
  state => ({
    orderRejectedReasons: selectors.getEnumOrderRejectedReasons(state)
  }),
  { requestUpdateOrderReasonRejectedInfo }
)(ReasonRejectedInfo);
