import React from "react";
import PropTypes from "prop-types";

const Spinner = React.memo(({ size }) => (
  <div className="loading-spinner" style={{ height: size, width: size }} />
));

Spinner.propTypes = {
  size: PropTypes.string
};

Spinner.defaultProps = {
  size: "15px"
};

export default Spinner;
