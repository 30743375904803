import React from "react";
import PureModal from "components/ui/modal/pure-modal";
import MdHelp from "react-icons/lib/md/help";
import { IconButton } from "components/ui";

const CalanderHelpMenu = () => (
  <PureModal
    renderTrigger={({ openModal }) => (
      <IconButton
        icon={<MdHelp />}
        onClick={openModal}
        style={{
          fontSize: 16,
          background: "none",
          width: 30,
          justifySelf: "center",
          border: "none",
          boxShadow: "none",
          gridArea: "2"
        }}
      />
    )}
    renderContent={() => (
        <div className="calendar-help-menu">
            <h6 className="help-menu-title">Create Time Slots:</h6>
            <p>
                Drag And and release mouse over the desired time range, select type of
                outreach to schedule time slot for and any other desired options, hit
                the save button to save new time slot
            </p>
            <h6 className="help-menu-title">Edit Time Slots:</h6>
            <p>
                By clicking an already created time slot, you will be able to change both start and end date and time, and type of outreach. 
                You will be able to edit only the time slots that do not have a current appointment.
            </p>
        </div>
    )}
  />
);

export default CalanderHelpMenu;
