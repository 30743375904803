import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";

const DocumentCategoryForm = ({ initialValues, handleSubmit }) => (
  <Formik
    onSubmit={async values => {
      await handleSubmit(values);
    }}
    initialValues={initialValues}
  >
    {({ values, isSubmitting }) => (
      <Form style={{ padding: 10 }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            padding: 5
          }}
        >
          <div style={{ gridColumn: "span 2" }}>
            <FormikField
              label="Category Name"
              name="document_category_name"
              type="text"
              required
            />
          </div>
          <FormikField
            label="Active"
            name="active"
            type="checkbox"
            checked={values.active}
          />
          <FormikField
            label="Media"
            name="is_media"
            type="checkbox"
            checked={values.is_media}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          {isSubmitting ? (
            <InlineWaiting />
          ) : (
            <button type="submit">Save</button>
          )}
        </div>
      </Form>
    )}
  </Formik>
);

DocumentCategoryForm.defaultProps = {
  initialValues: {
    active: true,
    document_category_name: "",
    is_media: false
  }
};

DocumentCategoryForm.propTypes = {
  initialValues: PropTypes.shape({
    active: PropTypes.bool,
    document_category_name: PropTypes.string,
    is_media: PropTypes.bool
  }),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired
};

export default DocumentCategoryForm;
