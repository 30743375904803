import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { message, errorMessage } from "actions/message";
import { createPatientNote, fetchPatientNotes } from "actions/patient";
import { InlineWaiting } from "components/ui";
import moment from "moment";

const CopyChatButton = ({ patientId, chatMessages }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const dispatch = useDispatch();
  const copyConversionToNotes = async () => {
    if (!patientId) return;
    try {
      setIsFetching(true);
      const userMessages = chatMessages.filter(({ author }) => !!author);
      if (userMessages.length === 0) throw Error("No messages");
      const formatTimeStamp = timestamp => moment(timestamp).format("hh:mm:ss");
      await dispatch(
        createPatientNote({
          patientId,
          noteContent: userMessages
            .map(
              ({ body, attributes, timestamp }) =>
                `${attributes?.authorName} - ${formatTimeStamp(
                  timestamp
                )} - ${body}`
            )
            .join("\n")
        })
      );
      await dispatch(fetchPatientNotes({ patientId }));
      dispatch(message("Conversation copied to patient notes"));
    } catch (err) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setIsFetching(false);
    }
  };
  return isFetching ? (
    <li className="dropdown-item">
      <InlineWaiting />
    </li>
  ) : (
    <li className="dropdown-item" onClick={copyConversionToNotes}>
      Copy conversation as note
    </li>
  );
};

CopyChatButton.propTypes = {
  patientId: PropTypes.string,
  chatMessages: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CopyChatButton;
