import React from 'react'
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {  addExportAccessLogs} from "actions/exports";
import {
  USER_FETCH_ACTION_CREATORS,
  SLEEP_COACH,
  OFFICE_ADMINISTRATOR,
  COMPANY_ADMINISTRATOR,
  ADMINISTRATOR
} from 'actions/users'
import {
  getUsers,
  isUsersFetching, 
  selectors
} from 'reducers'
import { InlineWaiting} from 'components/ui'
import { connect } from 'react-redux'
import MultiSelectInput from "components/forms/multi-select-input"
import sortBy from "lodash/sortBy"
import { fromJS } from "immutable"
import values from "lodash/values"

// trim time from date
const parseDate = day => {
  return new Date(day).toISOString().slice(0, 10);
}

const usersList = [
  SLEEP_COACH,
  // THERAPIST,
  OFFICE_ADMINISTRATOR,
  COMPANY_ADMINISTRATOR,
  ADMINISTRATOR
];

export class LogExportForm extends React.Component{

constructor(props){
  super(props);
  this.state={        
    startDate: new Date(),
    endDate: new Date(),
    chosenLogs:[],
    companiesSelected: [],
    s3AdminsSelected: [],
    therapistsSelected: [],
    officeAdminsSelected: [],
    companyAdminsSelected: [],
    sleepCoachesSelected: [],    
    submitDisabled: false,
    today: new Date(),
    startDateErrorMessage: '',
    companyErrorMessage: '',
    invalidDateRange: false,
  }

 this.handleEndDateChange = this.handleEndDateChange.bind(this);
 this.handleStartDateChange = this.handleStartDateChange.bind(this);
 this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
 this.fetchUser = this.fetchUser.bind(this);
 this.fetchUsersFromList = this.fetchUsersFromList.bind(this);
 this.objArrayToArray = this.objArrayToArray.bind(this);
 this.validateDates = this.validateDates.bind(this);
}

fetchUser(userType){
  const [fetchUsers, invalidateUsers] = USER_FETCH_ACTION_CREATORS[userType]
  const { dispatch } = this.props
  dispatch(invalidateUsers())
  dispatch(fetchUsers())
}

fetchUsersFromList(usersList){
 for(let i=0; i < usersList.length; i++){
    this.fetchUser(usersList[i]);
 } 
}

componentDidMount(){
  this.fetchUsersFromList(usersList);
}

handleStartDateChange(day){
  this.setState({
    startDate: day,
  }, () => this.validateDates())
} 


handleEndDateChange(day){  
  this.setState({
    endDate: day,
  }, () => this.validateDates())
}

validateDates() {
try {
    if (parseDate(this.state.endDate) > parseDate(this.state.today)) {
      this.setState({
        invalidDateRange: "End date cannot be greater than Today."
      });
      return;
    }
    if (parseDate(this.state.startDate) > parseDate(this.state.endDate)) {
      this.setState({
        invalidDateRange: "Start date cannot be greater than End date."
      });
      return;
    }
    this.setState({
      invalidDateRange: "",
    });
  } catch(err) {
    this.setState({
      invalidDateRange: "Invalid Date",
    });
  }
}

handleMultiSelectChange(event){
  const options = event.target.selectedOptions;
  const stateName = event.target.name;
  let optionsSelected = []; 
 
  for(let i = 0; i < options.length; i++){
    if(options[i].value !== ""){
      optionsSelected.push({value: options[i].value})
    }
  } 
 
  this.setState({
    ...this.state,
    [stateName]: optionsSelected
  })
}

objArrayToArray(arrayOfObjects){
 let array = [];
 for(let i = 0; i < arrayOfObjects.length; i++){
  array.push(arrayOfObjects[i].value)
 }
 return array;
}

submitButtonHandler = () => {  
  let payload = {
    start_date: this.state.startDate,
    end_date: this.state.endDate,
    access_types: this.objArrayToArray( this.state.chosenLogs),
    companies: this.objArrayToArray( this.state.companiesSelected),
    sleep_coaches: this.objArrayToArray(this.state.sleepCoachesSelected),
    administrators: this.objArrayToArray(this.state.s3AdminsSelected),
    therapists: this.objArrayToArray(this.state.therapistsSelected),
    company_administrators: this.objArrayToArray(this.state.companyAdminsSelected),
    office_administrators: this.objArrayToArray(this.state.officeAdminsSelected)
  }

  let valid = !(payload.end_date == undefined) 
              && !(payload.start_date == undefined) 
              && (payload.companies.length > 0)
              && (payload.access_types.length > 0);  

  if(valid){ this.props.dispatch(addExportAccessLogs({payload})); }    
}

  render(){
    const {
      companies,
      sleepCoaches,
      therapists,
      administrators,
      companyAdministrators,
      officeAdministrators,
      isFetching
    } = this.props    
    return(

      <div>
        <h1>Access Logs Export Form</h1>
        <h4>Please choose your parameters</h4>
        <hr/>
        <form >           
          <div>
            <h4>Start Date</h4>
            <DayPickerInput
              value={this.state.startDate}
              onDayChange={this.handleStartDateChange}
              dayPickerProps={{
                todayButton: "Today",
                showWeekNumbers: true,
                disabledDays: { after: this.state.endDate }
              }}
            />
            <h4>End Date</h4>
            <DayPickerInput
              value={this.state.endDate}
              onDayChange={this.handleEndDateChange}
              dayPickerProps={{
                todayButton: "Today",
                showWeekNumbers: true,
                disabledDays: {
                  before: this.state.startDate,
                  after: this.state.today
                }
              }}
            />
            {this.state.invalidDateRange && (
              <p style={{ color: "red" }}>{this.state.invalidDateRange}</p>
            )}
          </div>
          <hr/>       
          
          <h2>Use Ctrl + Click To Select Multiple Items</h2>
          <p>Emails are provided to distinguish the employee&apos;s company</p>        

          <div style={{maxWidth: "25%"}}>
             <MultiSelectInput
                name="chosenLogs"
                label="Types of Logs To Export*"
                onChange={this.handleMultiSelectChange}
                options={fromJS([
                  {value: "patientAccessLog", text:"Patient Access Log"}, 
                  {value: "ordersAccessLog", text:"Orders Access Log"}
                ])}
                size="3"
                />
                <p style={{color: "red"}}>
             {(this.state.chosenLogs.length <1)
               ? "Please Choose Atleast One Type of Export" 
               : ""
             }
         </p>
      </div>      

        {isFetching && <InlineWaiting reason='Retrieving Information One Moment, Please...' />}
         {!(this.state.chosenLogs.length <1) && !(isFetching) && 
      <div>
          <h4>Employees Are Alphabetized By Last Name</h4>
          <hr/>

       <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridColumnGap: '30px'}}>
          <MultiSelectInput
             name="companiesSelected"
             label="Companies*"
             onChange={this.handleMultiSelectChange}
             options={fromJS(companies)}
            size= "10" 
           />

           {fromJS(sleepCoaches) &&
             <MultiSelectInput
             name="sleepCoachesSelected"
             label="Sleep Coaches"
             onChange={this.handleMultiSelectChange}
             options={fromJS(sleepCoaches)}
            size= "10" 
           />}          

           {fromJS(administrators) &&
                <MultiSelectInput
                name="s3AdminsSelected"
                label="S3 Admins"
                onChange={this.handleMultiSelectChange}
                options={fromJS(administrators)}
               size= "10" 
              />}       

           {fromJS(companyAdministrators) &&
             <MultiSelectInput
             name="companyAdminsSelected"
             label="Company Admins"
             onChange={this.handleMultiSelectChange}
             options={fromJS(companyAdministrators)}
            size= "10" 
           />}        

           {fromJS(officeAdministrators) &&
             <MultiSelectInput
             name="officeAdminsSelected"
             label="Office Admins"
             onChange={this.handleMultiSelectChange}
             options={fromJS(officeAdministrators)}
            size= "10" 
           />}

           {fromJS(therapists) &&
               <MultiSelectInput
               name="therapistsSelected"
               label="Therapists"
               onChange={this.handleMultiSelectChange}
               options={fromJS(therapists)}
              size= "10" 
             />}

          </div>      
          <div>{(this.state.companiesSelected.length < 1) ? <p style={{color: "red"}}>You Must Select a Company</p> : ""}</div>  
            </div>              
           }
          </form>      
           <div>           
           <button         
             disabled= {            
                  this.state.chosenLogs.length < 1 
               || this.state.companiesSelected.length < 1 
               || this.state.invalidDateRange
             }
             onClick={this.submitButtonHandler}
             >Submit
           </button>
           </div>
        </div> 
    );
  }
}

export default connect(
  state => {
    const companies = sortBy(values(state.getIn(["entities", "companies"]).toJS()), ["name"])
    const sleepCoaches = getUsers(state, SLEEP_COACH )
    const companyAdministrators = getUsers(state, COMPANY_ADMINISTRATOR )
    const officeAdministrators = getUsers(state, OFFICE_ADMINISTRATOR )
    const administrators = getUsers(state, ADMINISTRATOR )
    const therapists = selectors.getFormOptionsTherapist(state) 
    
    const translateSubmitValues = array =>
      array.map(e => ({ text: e.name, value: e.GUID }));

    const translateEntitiesValues = array =>
      array.map(e => ({
        text: `${e.name} - ${e.email}`,
        value: e.id
      }));

    const translateSleepCoachValues = array =>
      array.map(e => ({ text: e.first_name + " " + e.last_name, value: e.id }));

    const translateTherapistValues = array =>
      array.map(therapist => ({
        ...therapist,
        text: `${therapist.text} - ${therapist.email}`
      }));
    return {      
      companies: translateSubmitValues(companies),
      sleepCoaches: translateSleepCoachValues(sleepCoaches),
      therapists: translateTherapistValues(therapists),
      companyAdministrators: translateEntitiesValues(companyAdministrators),
      officeAdministrators: translateEntitiesValues(officeAdministrators),
      administrators: translateEntitiesValues(administrators),
      isFetching: 
      isUsersFetching(state, SLEEP_COACH) 
      || isUsersFetching(state, COMPANY_ADMINISTRATOR)
      || isUsersFetching(state, OFFICE_ADMINISTRATOR)
      || isUsersFetching(state, ADMINISTRATOR),           
    }
  },
)(LogExportForm);

LogExportForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  companies: PropTypes.array,
  therapists: PropTypes.array,
  companyAdministrators: PropTypes.array,
  officeAdministrators: PropTypes.array,
  administrators: PropTypes.array,
  sleepCoaches: PropTypes.array,
  isFetching: PropTypes.bool
};
