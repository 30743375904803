import React from "react";
import PropTypes from "prop-types";

const CardContainer = ({ children, title, classProp}) => (
    <div className={`dashboard-card-container ${classProp}-card-container`}>
      {title && <div className="dashboard-card-title">{title}</div>}
      {children}
    </div>
  );

export default CardContainer;

CardContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  classProp: PropTypes.string
}
