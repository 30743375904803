import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IVPatientJobsTable from "./ivr-patient-jobs-table";
import {
  queryInit,
  updateQuery,
  resetQueryApiAndParams
} from "../../features/query/actions";
import Radium from "radium";
import { PageHeader } from "components/ui";
import UpdateJobsForms from "./update-jobs-forms";

class JobsProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateRequestDictionary(input) {
    const dictionary = {
      S3_PatientAddress: "Patient",
      S3_Patient: "Patient",
      S3_OrderAddresses: "Order",
      sStreet: "Street",
      sCity: "City",
      sState: "State",
      sZip: "Zip Code"
    };
    let result = dictionary[input];
    if (result == undefined || result == null) {
      return "";
    } else {
      return result;
    }
  }

  render() {
    const {
      loadOrder,
      loadPatient,
      updateRequestsRecords,
      patientGuidForJobs,
      query
    } = this.props;
    let updateRequestRecordIds =
      query &&
      query.apiById &&
      query.apiById.IVRPatientJobs &&
      query.apiById.IVRPatientJobs.ids;
    let updateRequestsToArrayOfMaps =
      updateRequestsRecords && updateRequestsRecords.toIndexedSeq().toArray();
    let updateRequestsToArray =
      updateRequestsToArrayOfMaps &&
      updateRequestsToArrayOfMaps
        .map(map => map.toJS())
        .filter(x => updateRequestRecordIds.includes(x.id));

    let needsForm = updateRequestsToArray && updateRequestsToArray.length > 0;

    return (
      <React.Fragment>
        <div>
          <PageHeader title="Jobs" />
          <IVPatientJobsTable
            queryId="IVRPatientJobs"
            apiId="IVRPatientJobs"
            loadOrder={loadOrder}
            loadPatient={loadPatient}
            updateRequestDictionary={this.updateRequestDictionary}
            patientGuidForJobs={patientGuidForJobs}
          />
          {needsForm && (
            <div style={{ marginTop: "20px" }}>
              <UpdateJobsForms
                patientGuidForJobs={patientGuidForJobs}
                updateRequestsToArray={updateRequestsToArray}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    query: state.getIn(["query"]),
    updateRequestsRecords: state.getIn(["entities", "ivrPatientJobs"])
  }), //  "apiById" "IVRPatientJobs", "ids"
  dispatch =>
    bindActionCreators(
      {
        queryInit,
        updateQuery,
        resetQueryApiAndParams
      },
      dispatch
    )
)(Radium(JobsProfile));

JobsProfile.propTypes = {
  loadOrder: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  loadPatient: PropTypes.func.isRequired,
  patientGuidForJobs: PropTypes.string,
  updateRequestsRecords: PropTypes.object
};
