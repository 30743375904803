import React from "react";
import PropTypes from "prop-types";
import {
  compose,
  withStateHandlers,
  withHandlers,
  setPropTypes
} from "recompose";
import Window from "./window";

const withModal = compose(
  withStateHandlers(
    ({ modalEnabled = false, modalNode = null, windowProps = {} }) => ({
      modalEnabled,
      modalNode,
      windowProps
    }),
    // included in `passThroughProps`
    {
      openModal: () => (modalNode, windowProps = {}) => ({
        modalEnabled: true,
        modalNode,
        windowProps
      }),
      closeModal: () => () => ({ modalEnabled: false })
    }
  ),
  withHandlers({
    handleCloseModal: ({ closeModal }) => evt => {
      evt.stopPropagation();
      closeModal();
    }
  }),
  WrappedComponent =>
    setPropTypes({
      onEscape: PropTypes.func,
      onXClick: PropTypes.func,
      large: PropTypes.bool,
      children: PropTypes.node,
      disabled: PropTypes.bool
    })(
      ({
        modalEnabled,
        modalNode,
        windowProps,
        handleCloseModal,
        ...passThroughProps
      }) => (
        <React.Fragment>
          <WrappedComponent {...passThroughProps} />
          {modalEnabled && (
            <Window
              onXClick={handleCloseModal}
              onEscape={handleCloseModal}
              {...windowProps}
            >
              {modalNode}
            </Window>
          )}
        </React.Fragment>
      )
    )
);

export default withModal;
