import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { BonafidePatientContext } from "features/bonafide/use-bonafide";
import { aLink } from "utils/styles";
import MdErrorOutline from "react-icons/lib/md/error-outline";
import { InlineWaiting } from "components/ui";

const formatDateField = date => (date ? moment(date).format("MM/DD/YYYY") : "");

const getExpiredStyle = expDate =>
  expDate && moment(expDate, "YYYYMMDD").isBefore(moment(), "day")
    ? { color: "red" }
    : { color: "green" };

const PatientLmns = ({ handlePhysicianSelect }) => {
  const { lmns, errors, isFetching } = React.useContext(BonafidePatientContext);
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <h4>LMNs on File(Bonafide)</h4>
        {errors.lmns && (
          <div style={{ color: "red" }}>
            <MdErrorOutline />
            <span style={{ paddingLeft: 10 }}>{errors.lmns}</span>
          </div>
        )}
        {isFetching && <InlineWaiting />}
      </div>
      <table
        style={{
          width: "100%"
        }}
      >
        <thead>
          <tr>
            <th>LMN</th>
            <th>Type</th>
            <th>Start Date</th>
            <th>Audit Date</th>
            <th>Expiration</th>
            <th>HCPCS</th>
            <th>Items</th>
            <th>Insurance</th>
            <th>Physician</th>
            <th>Physician NPI</th>
            {handlePhysicianSelect && <th />}
          </tr>
        </thead>
        <tbody>
          {lmns.map(lmn => (
            <tr key={lmn.sysid}>
              <td>{lmn.lmn}</td>
              <th>{lmn.type}</th>
              <td>{formatDateField(lmn.startDate)}</td>
              <td>{formatDateField(lmn.auditDate)}</td>
              <td style={getExpiredStyle(lmn.expDate)}>
                {formatDateField(lmn.expDate)}
              </td>
              <td>{lmn.hcpcs.join(",")}</td>
              <td>{lmn.item.join(",")}</td>
              <td>{lmn.insurance}</td>
              <td>{lmn.physician}</td>
              <td>{lmn.npi}</td>
              {handlePhysicianSelect && (
                <td style={{ width: 100 }}>
                  <span
                    onClick={() => {
                      handlePhysicianSelect(lmn.npi, lmn.physician);
                    }}
                    style={aLink}
                  >
                    Use Physician
                  </span>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PatientLmns;

PatientLmns.propTypes = {
  handlePhysicianSelect: PropTypes.func
};
