import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PageHeader = ({ onClick, title, button, count, children, message }) => {
  return (
    <header className="page-header">
      <div className="page-header-title">
        <h1>
          <span
            onClick={onClick}
            className={`page-title ${onClick ? "page-title-link" : ""}`}
          >
            {title}
            {count && (
              <span className="page-count-value">
                ({count.toLocaleString()})
              </span>
            )}
          </span>
        </h1>
      </div>
      <div className="header-actions">
        {children}
        {button && (
          <div className="link-button">
            <Link className="button small" to={button.path}>
              {button.value}
            </Link>
          </div>
        )}
        { message && <span style={{ color: message.color }} > {message.value} </span>}
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  button: PropTypes.shape({ value: PropTypes.string, path: PropTypes.string }),
  count: PropTypes.number,
  children: PropTypes.node,
  message: PropTypes.object
};

export default PageHeader;
