import React from "react";
import PropTypes from "prop-types";

const TaskOwnerSelector = ({ setItem, item, alreadyExists }) => {
    
    return <div className={alreadyExists ? "dd_col-2" : "dd_col-6"}>
        <label>Task owner</label>
        <input
            type="radio"
            name="S3"
            value="S3"
            onChange={(value) => { setItem({ ...item, TaskOwner: value.target.value }) }}
            checked={item.TaskOwner == "S3"} />
        <label>S3</label>
        <input
            type="radio"
            name="DME"
            value="DME"
            onChange={(value) => { setItem({ ...item, TaskOwner: value.target.value }) }}
            checked={item.TaskOwner == "DME"} />
        <label>DME</label>
    </div>
};

TaskOwnerSelector.propTypes = {
    setItem: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    alreadyExists: PropTypes.bool.isRequired
};

export default TaskOwnerSelector;