import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { browserHistory } from "browser-history";

const JoinChatButton = ({
  // is_product_demo,
  master_chat_type,
  slot_id,
  start_time,
  is_canceled
}) => {
  const [seconds, setSeconds] = React.useState(0);
  const [isActive] = React.useState(true);
  // const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));

  // const diff = moment.duration(
  //   moment.tz(start_time, userLoggedIn.timezone).diff(moment().tz(userLoggedIn.timezone))
  // );

  // const remainingTime = {
  //   days: moment.utc(diff.as("milliseconds")).format("D") - 1,
  //   hours: moment.utc(diff.as("milliseconds")).format("H"),
  //   minutes: moment.utc(diff.as("milliseconds") + 60000).format("mm")
  // };
  React.useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const isJoinable = moment().isAfter(moment(start_time)) && !is_canceled;
  return (
    <div className="join-chat-button-container">
      {isJoinable ? (
        <button
          className="appointment-action-button reschedule-button"
          style={{ marginBottom: "0px" }}
          disabled={!isJoinable}
          onClick={() => {
            if (master_chat_type === "Text Chat") {
              browserHistory.push(`/textchat/${slot_id}`);
            } else if (master_chat_type == "Video Chat") {
              browserHistory.push(`/videochat/${slot_id}`);
            } else {
              browserHistory.push(`/productdemo/${slot_id}`);
            }
          }}
        >
          Join {master_chat_type}
        </button>
      ) : null}
    </div>
  );
};

JoinChatButton.propTypes = {
  slot_id: PropTypes.string,
  start_time: PropTypes.string,
  master_chat_type: PropTypes.string,
  is_canceled: PropTypes.bool
};

export default JoinChatButton;
