import UserProfile from "components/screens/user-profile";
import EditUser from "components/screens/users/edit-user";
import CreateUser from "components/screens/users/create-user";
import UserLogs from "components/screens/user-profile/user-logs";
import MultiFilterSleepCoachTable from "components/multiFilter-sleepCoach-table";
import MultiFilterAdministratorTable from "components/multiFilter-Administrator-table";
import MultiFilterCompanyAdministratorTable from "components/multiFilter-CompanyAdministrator-table";
import MultiFilterOfficeAdministratorTable from "components/multiFilter-OfficeAdministrator-table";
import MultiFilterTherapistTable from "components/multiFilter-Therapist-table";
// import MultiFilterComplianceCoachTable from "components/multiFilter-ComplianceCoach-table";

export default [
  {
    path: "/users/office-administrators",
    component: MultiFilterOfficeAdministratorTable,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "ServiceAdmin",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/users/company-administrators",
    component: MultiFilterCompanyAdministratorTable,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "ServiceAdmin",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/users/administrators",
    component: MultiFilterAdministratorTable,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/users/sleep-coaches",
    component: MultiFilterSleepCoachTable,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  // {
  //   path: "/users/compliance-coaches",
  //   component: MultiFilterComplianceCoachTable,
  //   allowedRoles: ["Administrator", "ServiceAdmin"]
  // },
  {
    path: "/users/therapists",
    component: MultiFilterTherapistTable,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/users/new",
    component: CreateUser,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/users/:id",
    component: UserProfile,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "ServiceAdmin",
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/users/:id/edit",
    component: EditUser,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "ServiceAdmin",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/users/:id/logs",
    component: UserLogs
  }
];
