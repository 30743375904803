import React, { useState, useEffect }  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { post, get, put, del } from "utils/api";
import { getApiErrorMessage } from "utils/misc";
import { errorMessage } from "actions/message";
import { useFetch } from "hooks";
import { path } from "ramda";
import EmailGroup from "./email-group";
import EmailGroups from "./email-groups";
import EmailGroupAddress from "./email-group-address";
import EmailGroupAddressForm from "./email-group-address-form";
import EmailGroupForm from "./email-group-form";


const Email = ({ errorMessage}) => { 

  const [createNewEmailGroupState, setCreateNewEmailGroupState] = useState(false);
  const [createNewEmailGroupAddressState, setCreateNewEmailGroupAddressState] = useState(
    false
  ); 
  const [currentEmailGroupID, setCurrentEmailGroupID] = useState(null);
  const [currentEmailGroupAddressID, setCurrentEmailGroupAddressID] = useState(null);
  const [editStateInAddress, setEditStateInAddress] = useState(false);

  const scrollToTop = () => {
    document.querySelectorAll('[role="main"]')[0].scrollTo(0, 0);
  };

   const {
     response: nonPatientUsers,     
     fetchData: getNonPatientUsers
   } = useFetch({
     defaultValue: [],
     apiFn: () => get(`mail/email_groups/nonpatient_users`),
     transformError: path(["response", "body", "status"]),
     onError: error => {
       errorMessage(`Failed to pull non-patient users: ${getApiErrorMessage(error)}`);
     }
   });

  //Email Group
  const openNewEmailGroupForm = () => {
    setCreateNewEmailGroupState(true);
  };
  const addNewEmailGroup = (email_group_name) => {
    createNewEmailGroup({ email_group_name });  
  };
  const updateEmailGroupName = (email_group_id, email_group_name) => {
    putEmailGroup({ email_group_id, email_group_name });
  };
   const deleteEmailGroupByID = email_group_id => {
     deleteEmailGroup(email_group_id );
  };
  
  const { fetchData: putEmailGroup } = useFetch({
    clearDataOnFetch: false,
    defaultValue: [],
    apiFn: data => put(`mail/email_group`, data),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to get update email group: ${getApiErrorMessage(error)}`);
    },
    onSuccess: () => {
      getAllEmailGroups();
    }
  });
  const {    
    fetchData: deleteEmailGroup
  } = useFetch({
    defaultValue: [],
    apiFn: data => del(`mail/email_group/${data}`),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to get delete email group: ${getApiErrorMessage(error)}`);
    },
    onSuccess: () => {
      setCurrentEmailGroupAddressID(null);
      getAllEmailGroups();
    }
  });
  const { response: newEmailGroup, fetchData: createNewEmailGroup } = useFetch({
    defaultValue: null,
    apiFn: data => post(`mail/email_group`, data),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to create new email group: ${getApiErrorMessage(error)}`);
    },
    onSuccess: () => {
      setCreateNewEmailGroupState(false);
      getAllEmailGroups();
    }
  });
  useEffect(() => {
    setCurrentEmailGroupID(newEmailGroup && newEmailGroup.email_group_id);
  }, [newEmailGroup]);

  const { response: allEmailGroups, fetchData: getAllEmailGroups } = useFetch({
    clearDataOnFetch: false,
    defaultValue: [],
    apiFn: () => get(`mail/email_groups`),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to get all email groups: ${getApiErrorMessage(error)}`);
    },
    onSuccess: () => {}
  });

  useEffect(() => {
    if (currentEmailGroupAddressID != null) {
      setCreateNewEmailGroupAddressState(false);
    }      
    }, [currentEmailGroupAddressID]);

  useEffect(() => {
    if (createNewEmailGroupState) {
      setCurrentEmailGroupID(null);
      setCreateNewEmailGroupAddressState(false);
      setCurrentEmailGroupAddressID(null);
    }}, [createNewEmailGroupState]);

  useEffect(() => {      
      getNonPatientUsers();
      getAllEmailGroups();
  }, []);

  useEffect(() => {
    setCreateNewEmailGroupAddressState(false);
    //setCreateNewEmailGroupState(false);
    setCurrentEmailGroupAddressID(null);
  }, [currentEmailGroupID]);
  
 
//Email Group Address
   // const openNewEmailGroupAddressForm = () => {
   //   setCreateNewEmailGroupAddressState(true);
   // };
  const addNewEmailGroupAddress = (address) => {postNewEmailGroupAddress(address) };
   const {
     response: newEmailGroupAddress,
     fetchData: postNewEmailGroupAddress
   } = useFetch({
     clearDataOnFetch: false,
     defaultValue: null,
     apiFn: (data) => post(`mail/email_group/member`, data),
     transformError: path(["response", "body", "status"]),
     onError: error => {       
       errorMessage(
         `Failed to add email group address: ${getApiErrorMessage(error)}`
       );
     },
     onSuccess: () => {getAllEmailGroups();}
   });

  useEffect(() => {    
    if (allEmailGroups) {
      var group = allEmailGroups.find(
        x => x.email_group_id === currentEmailGroupID
      );
      if (        
        group &&
        group.addresses.findIndex(
          x => x.email_group_member_id === newEmailGroupAddress
        ) != -1
      ) {        
        setCurrentEmailGroupAddressID(newEmailGroupAddress);
      }
    }  
  }, [newEmailGroupAddress, allEmailGroups]);
  
 

  const updateEmailGroupAddress = (address) => { putEmailGroupAddress(address)};
   const {     
     fetchData: putEmailGroupAddress
   } = useFetch({
     clearDataOnFetch: false,
     defaultValue: [],
     apiFn: data => put(`mail/email_group/member`, data),
     transformError: path(["response", "body", "status"]),
     onError: error => {
       errorMessage(
         `Failed to update email group address: ${getApiErrorMessage(error)}`
       );
     },
     onSuccess: () => {
       getAllEmailGroups();
       setEditStateInAddress(false);
     }
   });
  
  const deleteEmailGroupAddress = (email_group_member_id) => {
    delEmailGroupAddress(email_group_member_id);
  }
     const {       
       fetchData: delEmailGroupAddress
     } = useFetch({
       clearDataOnFetch: false,
       defaultValue: [],
       apiFn: data => del(`mail/email_group/member/${data}`, data),
       transformError: path(["response", "body", "status"]),
       onError: error => {
         errorMessage(
           `Failed to delete email group address: ${getApiErrorMessage(error)}`
         );
       },
       onSuccess: () => {
         getAllEmailGroups();
       }
     });
  const addEmailGroupAddressAccessibleCompany = (email_group_member_id, company_id) => { postEmailGroupAddressAccessibleCompany({email_group_member_id, company_id})};
   const {
     //response: updatedEmailGroupAddress,
     fetchData: postEmailGroupAddressAccessibleCompany
   } = useFetch({
     clearDataOnFetch: false,
     defaultValue: [],
     apiFn: data => post(`mail/email_group/member/accessible_company`, data),
     transformError: path(["response", "body", "status"]),
     onError: error => {
       errorMessage(
         `Failed to add email group address accessible company: ${getApiErrorMessage(
           error
         )}`
       );
     },
     onSuccess: () => {
       getAllEmailGroups();
     }
   });
  
  const deleteEmailGroupAddressAccessibleCompany = (
    email_group_member_id,
    company_id
  ) => { delEmailGroupAddressAccessibleCompany({email_group_member_id, company_id});};
   const {     
     fetchData: delEmailGroupAddressAccessibleCompany
   } = useFetch({
     clearDataOnFetch: false,
     defaultValue: [],
     apiFn: data => del(`mail/email_group/member/accessible_company/${data.email_group_member_id}/${data.company_id}`),
     transformError: path(["response", "body", "status"]),
     onError: error => {
       errorMessage(
         `Failed to delete email group address accessible company: ${error.message}`
       );
     },
     onSuccess: () => {
       getAllEmailGroups();
     }
   });
  
 
  //add/update address to group
  //add accessible company to login/email address
  return (
    <div>
      <h1 style={{ color: "#02B4A6" }}>Email Groups</h1>
      <div className="emailGroupsContainer">
        <div>
          <EmailGroups
            emailGroups={allEmailGroups}
            currentEmailGroupID={currentEmailGroupID}
            setCurrentEmailGroupID={setCurrentEmailGroupID}
            scrollToTop={scrollToTop}
            openNewEmailGroupForm={openNewEmailGroupForm}
          />
        </div>
        <div>
          {createNewEmailGroupState ? (
            <EmailGroupForm
              setCreateNewEmailGroupState={setCreateNewEmailGroupState}
              addNewEmailGroup={addNewEmailGroup}
            />
          ) : (
            (currentEmailGroupID != null || createNewEmailGroupState) &&
            allEmailGroups &&
            allEmailGroups.find(
              x => x.email_group_id === currentEmailGroupID
            ) && (
              <EmailGroup
                currentEmailGroupID={currentEmailGroupID}
                emailGroup={allEmailGroups.find(
                  x => x.email_group_id === currentEmailGroupID
                )}
                updateEmailGroupName={updateEmailGroupName}
                currentEmailGroupAddressID={currentEmailGroupAddressID}
                setCurrentEmailGroupAddressID={setCurrentEmailGroupAddressID}
                setCreateNewEmailGroupAddressState={
                  setCreateNewEmailGroupAddressState
                }
                deleteEmailGroupByID={deleteEmailGroupByID}
              />
            )
          )}
        </div>
        <div>
          {createNewEmailGroupAddressState ? (
            <EmailGroupAddressForm
              emailGroupID={currentEmailGroupID}
              addNewEmailGroupAddress={addNewEmailGroupAddress}
              setCreateNewEmailGroupAddressState={
                setCreateNewEmailGroupAddressState
              }
              nonPatientUsers={nonPatientUsers}
            />
          ) : (
            currentEmailGroupID != null &&
            currentEmailGroupAddressID != null &&
            allEmailGroups &&
            allEmailGroups.length > 0 &&
            allEmailGroups.find(
              x => x.email_group_id === currentEmailGroupID
            ) &&
            allEmailGroups
              .find(x => x.email_group_id === currentEmailGroupID)
              .addresses.find(
                x => x.email_group_member_id === currentEmailGroupAddressID
              ) && (
              <EmailGroupAddress
                  currentEmailGroupAddressID={currentEmailGroupAddressID}
                  address={allEmailGroups
                    .find(x => x.email_group_id === currentEmailGroupID)
                    .addresses.find(
                      x => x.email_group_member_id === currentEmailGroupAddressID
                    )}
                  addEmailGroupAddressAccessibleCompany={
                    addEmailGroupAddressAccessibleCompany
                  }
                  deleteEmailGroupAddressAccessibleCompany={
                    deleteEmailGroupAddressAccessibleCompany
                  }
                  deleteEmailGroupAddress={deleteEmailGroupAddress}
                  updateEmailGroupAddress={updateEmailGroupAddress}
                  editStateFromParent={ editStateInAddress}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  () => (
    {
      // user: selectors.getUser(state)
    }    
  ),
  { errorMessage }
)(Email);

Email.propTypes = {
  errorMessage: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired
};
