import React from "react";
import PropTypes from "prop-types";
import { Text } from "recharts";

const PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  fill
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) + 25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return value ? (
    <Text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? "start" : "end"}
      style={{ fontSize: "1.1rem" }}
    >
      {`${value} (${(percent * 100).toFixed(0)}%)`}
    </Text>
  ) : null;
};

export default PieLabel;

PieLabel.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  percent: PropTypes.number,
  value: PropTypes.number,
  fill: PropTypes.string,
  name: PropTypes.string
};
