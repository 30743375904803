import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { getIsOutboundCallingBlocked } from "reducers/selectors";
import { phoneCall, setDialerNumber, sendDigits, hangup } from "actions/phone";
import MdCall from "react-icons/lib/md/call";
import MdCallEnd from "react-icons/lib/md/call-end";
import MdClose from "react-icons/lib/md/close";
import DialerActions from "components/batch-auto-dialer/dialer-actions";

const PhoneInputDialer = ({
  outboundCallingBlocked,
  isReady,
  isConnected,
  dialerNumber,
  setDialerNumber,
  sendDigits,
  userPhoneNumber,
  patientToCall,
  autodialerEnabled,
  hangup,
  phoneCall
}) => {
  const hangupCall = () => {
    hangup();
  };

  return (
    <div className="dialer-number-input">
      <DialerActions />
      <input
        className={
          !isConnected && outboundCallingBlocked
            ? "outboundCallInputDisabled"
            : "outboundCallInput"
        }
        type="tel"
        value={dialerNumber}
        onKeyDown={({ key }) => {
          if (isConnected && /^([0-9]|\*|#)+$/.test(key)) {
            sendDigits(key);
          }
        }}
        onChange={({ target }) => {
          setDialerNumber(target.value);
        }}
        title={
          !isConnected && outboundCallingBlocked
            ? "Must Answer Queue First"
            : "Enter Number Here"
        }
        placeholder={
          !isConnected && outboundCallingBlocked
            ? "Must Answer Queue First"
            : "555-555-5555"
        }
        disabled={
          isConnected ? false : outboundCallingBlocked || autodialerEnabled
        }
      />
      {dialerNumber && (
        <span className="number-input-clear-icon">
          <MdClose
            title="Clear"
            onClick={() => {
              setDialerNumber("");
            }}
          />
        </span>
      )}
      {!isConnected ? (
        <button
          title={
            !isConnected && outboundCallingBlocked
              ? "Must Answer Queue First"
              : "Click To Place Call"
          }
          onClick={() => {
            phoneCall({
              to: dialerNumber,
              from: userPhoneNumber,
              patientToCall
            });
          }}
          disabled={
            !isReady || outboundCallingBlocked || dialerNumber.length <= 2
          }
          className="callButton"
        >
          <MdCall size="1.5em" />
        </button>
      ) : (
        <button
          title={"Click To Hang Up Call"}
          onClick={hangupCall}
          className="hangupButton"
        >
          <MdCallEnd size="1.5em" />
        </button>
      )}
    </div>
  );
};

export default connect(
  state => ({
    isReady: selectors.getIsPhoneReady(state),
    isConnected: selectors.getIsPhoneConnected(state),
    dialerNumber: selectors.getDialerNumber(state),
    outboundCallingBlocked:
      getIsOutboundCallingBlocked(state) &&
      !selectors.getAutoDialerIsInWrapupTime(state), // allow outbound calls during wrapup time
    userPhoneNumber: selectors.getUserPhoneNumber(state),
    userId: selectors.getUserId(state),
    patientToCall: selectors.getPatientToCallGuid(state),
    autodialerEnabled: selectors.getIsAutoDialerEnabled(state)
  }),
  { setDialerNumber, sendDigits, phoneCall, hangup }
)(PhoneInputDialer);

PhoneInputDialer.propTypes = {
  dialerNumber: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  setDialerNumber: PropTypes.func.isRequired,
  sendDigits: PropTypes.func.isRequired,
  outboundCallingBlocked: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userPhoneNumber: PropTypes.string.isRequired,
  phoneCall: PropTypes.func.isRequired,
  patientToCall: PropTypes.string,
  autodialerEnabled: PropTypes.bool,
  hangup: PropTypes.func.isRequired
};
