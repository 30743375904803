import React from "react";
import PropTypes from "prop-types";
import { useSort } from "hooks";
import { TimeDisplay } from "./time-display";
import MdArrowDropUp from "react-icons/lib/md/arrow-drop-up";
import MdArrowDropDown from "react-icons/lib/md/arrow-drop-down";
import { TotalTimeRow } from "./total-time-row";
import { Link } from "react-router-dom";
import { aLink } from "utils/styles";

const CoachLoginsTable = ({ data, dates }) => {
  const { ascending, sortBy, toggleSortDirection, setSortBy } = useSort({
    by: "coach",
    ascending: false
  });

  return (
    <>
      <table className="large-12" style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <td
              key="coachGuid"
              style={{ cursor: "pointer", userSelect: "none", width: "100px" }}
            >
              {sortBy === "coachGuid" ? (
                <span onClick={toggleSortDirection}>
                  <span style={{ paddingRight: 5 }}>Name</span>
                  {ascending ? <MdArrowDropUp /> : <MdArrowDropDown />}
                </span>
              ) : (
                <span onClick={() => setSortBy("coachGuid")}>
                  <span style={{ paddingRight: 5 }}>Name</span>
                  <span style={{ marginRight: "1em" }} />
                </span>
              )}
            </td>
            <td
              key="totalConnectionTime"
              style={{ cursor: "pointer", userSelect: "none", width: "150px" }}
            >
              {sortBy === "totalConnectionTime" ? (
                <span onClick={toggleSortDirection} >
                  <span style={{ paddingRight: 5 }}>Totals</span>
                  {ascending ? <MdArrowDropUp /> : <MdArrowDropDown />}
                </span>
              ) : (
                <span onClick={() => setSortBy("totalConnectionTime")}>
                  <span style={{ paddingRight: 5 }}>Totals</span>
                  <span style={{ marginRight: "1em" }} />
                </span>
              )}
            </td>
            <td
              key="timeDisplay"
              style={{
                cursor: "pointer",
                userSelect: "none",
                width: "80%",
                textAlign: "center"
              }}
            >
              {sortBy === "timeDisplay" ? (
                <span onClick={toggleSortDirection}>
                  <span style={{ paddingRight: 5 }}>Sessions</span>
                  {ascending ? <MdArrowDropUp /> : <MdArrowDropDown />}
                </span>
              ) : (
                <span onClick={() => setSortBy("timeDisplay")}>
                  <span style={{ paddingRight: 5 }}>Sessions</span>
                  <span style={{ marginRight: "1em" }} />
                </span>
              )}
            </td>
          </tr>
        </thead>
        <tbody>
          {data.map(c => (
            <tr key={c.coachGuid}>
              <td className="name-column" style={aLink}><Link to={`/users/${c.coachGuid}`}>{c.coachName}</Link></td>
              <TotalTimeRow
                data={data.find(d => d.coachGuid == c.coachGuid) || []}
                coachGuid={c.coachGuid}
              />
              <td style={{ height: "9em" }}>
                <TimeDisplay
                  data={data.filter(d => d.coachGuid == c.coachGuid)}
                  dates={dates}
                  coachGuid={c.coachGuid}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

CoachLoginsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dates: PropTypes.object.isRequired
};

export default CoachLoginsTable;
