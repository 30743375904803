export const toggleSort = by => ({
  type: "toggleSort",
  by
});

export const setSort = sort => ({
  type: "setSort",
  sort
});

export const updatePagination = ({ per, page }) => ({
  type: "updatePagination",
  per,
  page
});

export const toggleFilter = (by, value) => ({
  type: "toggleFilter",
  by,
  value
});

export const setFilter = (by, value) => ({
  type: "setFilter",
  by,
  value
});

export const clearFilter = by => ({
  type: "clearFilter",
  by
});

export const updateFilters = filters => ({
  type: "updateFilters",
  payload: filters
});

/* NOTE probably should explicitly set pagination although optional */
export const updateQuery = query => ({
  type: "updateFilters",
  ...query
});
