import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CompanyInventory from "../inventory";
import ManufacturerWeights from "./manufacturer-weights";
import AutofitDashboard from "components/autofit-dashboard";
import HasRolePermission from "components/shared/has-role-permission";

const AutofitOptions = ({ params }) => {
  const [, setSelectedTabIndex] = useState();

  return (
    <div>
      <HasRolePermission
        allowedRoles={["Administrator"]}
        renderElse={<AutofitDashboard companyId={params.id} />}
      >
        <div className="row section-header">
          <div className="company-monthly-report_tabs-container">
            <Tabs onSelect={index => setSelectedTabIndex(index)}>
              <div className="row">
                <TabList>
                  <div>
                    <Tab>
                      <div>Dashboard</div>
                    </Tab>
                    <Tab>
                      <div>Manufacturer Priority</div>
                    </Tab>
                    <Tab>
                      <div>Inventory Eligibility</div>
                    </Tab>
                  </div>
                </TabList>
              </div>
              <div className="row">
                <TabPanel>
                  <AutofitDashboard companyId={params.id} />
                </TabPanel>
                <TabPanel>
                  <ManufacturerWeights companyId={params.id} />
                </TabPanel>
                <TabPanel>
                  <CompanyInventory params={params} autofit={true} />
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </HasRolePermission>
    </div>
  );
};

AutofitOptions.propTypes = {
  params: PropTypes.object.isRequired
};

export default AutofitOptions;
