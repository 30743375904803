import React from "react";
import PropTypes from "prop-types";
import MdArrowDropDown from "react-icons/lib/md/arrow-downward";
import MdArrowDropUp from "react-icons/lib/md/arrow-upward";

const InlineSortControls = ({
  sortOptions,
  setSortBy,
  toggleSortDirection,
  ascending,
  sortBy
}) => (
  <span className="sort-controls">
    <span className="sort-label-text">Sort:</span>
    <span className="sort-options">
      {sortOptions.map(({ key, text }) =>
        sortBy === key ? (
          <span
            key={key}
            className="sort-option sort-is-active"
            onClick={toggleSortDirection}
          >
            {ascending ? <MdArrowDropUp /> : <MdArrowDropDown />}
            <span>{text}</span>
          </span>
        ) : (
          <span
            className="sort-option"
            key={key}
            onClick={() => setSortBy(key)}
          >
            <span>{text}</span>
          </span>
        )
      )}
    </span>
  </span>
);

InlineSortControls.defaultProps = {};

InlineSortControls.propTypes = {
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  toggleSortDirection: PropTypes.func.isRequired,
  setSortBy: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  ascending: PropTypes.bool
};

export default InlineSortControls;
