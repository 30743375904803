import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatNumbersForReports } from 'utils/misc'

export class PatientStatistics extends Component {
  render() {
    const patients = this.props.patients;
    const ins = patients.get("insurance_types");
    const contactMethods = patients.get("contact_methods");
    return (
      <div>
        <div className="row section-header">
          <div className="small-12 columns">
            <h3>Patients</h3>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <table className="small-12">
              <tbody>
                <tr>
                  <th>Status</th>
                  <th className="text-right">Count</th>
                </tr>
                <tr>
                  <td>All</td>
                  <td className="text-right">{formatNumbersForReports(patients.get("total"))}</td>
                </tr>
                <tr>
                  <td>Active</td>
                  <td className="text-right">{formatNumbersForReports(patients.get("active"))}</td>
                </tr>
                <tr>
                  <td>Inactive</td>
                  <td className="text-right">{formatNumbersForReports(patients.get("inactive"))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <table className="small-12">
              <tbody>
                <tr>
                  <th>Insurance Type (active patients)</th>
                  <th className="text-right">Count</th>
                </tr>
                {ins.map(insurance => (
                  <tr key={insurance.get("text")}>
                    <td>{insurance.get("text")}</td>
                    <td className="text-right">
                      {formatNumbersForReports(insurance.get("value"))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <table className="small-12">
              <tbody>
                <tr>
                  <th>Contact Type (active patients)</th>
                  <th className="text-right">Count</th>
                </tr>
                <tr>
                  <td>Manual call</td>
                  <td className="text-right">
                    {formatNumbersForReports(contactMethods.get("manual_call"))}
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td className="text-right">{formatNumbersForReports(contactMethods.get("email"))}</td>
                </tr>
                <tr>
                  <td>Text message</td>
                  <td className="text-right">
                    {formatNumbersForReports(contactMethods.get("text_message"))}
                  </td>
                </tr>
                <tr>
                  <td>Push notification</td>
                  <td className="text-right">
                    {formatNumbersForReports(contactMethods.get("push_notification"))}
                  </td>
                </tr>
                <tr>
                  <td>IVR</td>
                  <td className="text-right">
                    {formatNumbersForReports(contactMethods.get("ivr"))}
                  </td>
                </tr>
                <tr>
                  <td>Do not contact</td>
                  <td className="text-right">
                    {formatNumbersForReports(contactMethods.get("do_not_contact"))}
                  </td>
                </tr>
                <tr>
                  <td>Bad phone number</td>
                  <td className="text-right">
                    {formatNumbersForReports(contactMethods.get("bad_phone_number"))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
PatientStatistics.propTypes = {
  patients: PropTypes.object.isRequired
};
export default PatientStatistics;
