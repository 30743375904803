import React from "react";
import CompanyTeamSelect from "components/shared/company-team-select";
import TaskOwnerFilter from "./task-owner-filter";
import DateRangeHeaderColumn from "hooks/useQuery/api-query/table-header/date-range-header-filter";
import { Link } from "react-router-dom";
// import { taskHeaderOptions } from "utils/enum";
import { RowSelectCell, RowSelectHeaderCell } from "hooks/useRowSelectNew";
import { useTableSettingsNew } from "hooks/useTableSettings";
// import { getFormOptionsSleepCoachWithUserFirst, selectors } from "reducers";
import { selectors } from "reducers";
import { Checkbox } from "components/ui";
import { useSelector } from "react-redux";
import { formatDate } from "utils/dates";
import * as R from "ramda";

const getTableSchema = ({ companies, userRole, taskActions, }) => {
  const Table = {
    All: {
      header: "all",
      field: "all",
      Cell: RowSelectHeaderCell,
      active: true,
      Row: row => <RowSelectCell id={row.task_id} />
    },
    Type: {
      header: "Type",
      field: "type",
      dropdownPosition: "right",
      filters: [
        { value: "Patient", text: "Patient" },
        { value: "Order", text: "Order" }
      ],
      active: true,
      Row: row => <td>{row.type}</td>
    },
    Header: {
      header: "Task",
      field: "action_id",
      dropdownPosition: "right",
      filters: taskActions,
      active: true,
      Row: row => <td>{row.action_text}</td>
    },
    Text: {
      header: "Text",
      field: "text",
      active: true,
      Row: row => <td>{row.text}</td>
    },
    Status: {
      header: "Status",
      field: "status",
      filters: [
        { value: "Created", text: "Created" },
        { value: "Pending", text: "Pending" },
        { value: "Completed", text: "Completed" }
      ],
      active: true,
      Row: row => <td>{row.status}</td>
    },
    Owner: {
      header: "Owner",
      field: "owner",
      active: true,
      Row: row => <td>{row.assigned_to_name}</td>,
      disabledForRoles: [
        "OfficeAdministrator",
        "CompanyAdministrator",
        "Administrator"
      ],
      Cell: TaskOwnerFilter
    },
    Patient: {
      header: "Patient",
      field: "patient_name",
      active: true,
      Row: row => (
        <td>
          <Link to={`/patients/${row.patient_id}`}>{row.patient_name}</Link>
        </td>
      )
    },
    "Account Number": {
      header: "Account #",
      field: "patient_account",
      active: false,
      Row: row => <td>{row.patient_account}</td>
    },
    Outreach: {
      header: "Outreach",
      field: "requires_outreach",
      filters: [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
      ],
      active: false,
      Row: row => (
        <td>
          <Checkbox value={row.requires_outreach} />
        </td>
      )
    },
    "Invoice #": {
      header: "Invoice #",
      field: "order_invoice_number",
      active: true,
      Row: row => (
        <td>
          <Link to={`/orders/${row.order_id}`}>{row.order_invoice_number}</Link>
        </td>
      )
    },
    Company: {
      header: "Company",
      field: "company",
      filters: companies,
      filterDownSelect: true,
      SubFilter: CompanyTeamSelect,
      active: true,
      Row: row => <td>{row.company_name}</td>
    },
    "Date Created": {
      header: "Date Created",
      field: "created_at",
      Cell: () => (
        <DateRangeHeaderColumn header="Date Created" field="created_at" />
      ),
      active: true,
      Row: row => <td>{formatDate(row.created_at)}</td>
    },
    "Last Updated": {
      header: "Last Updated",
      field: "last_updated_at",
      Cell: () => (
        <DateRangeHeaderColumn header="Last Updated" field="last_updated_at" />
      ),
      active: false,
      Row: row => <td>{formatDate(row.last_updated_at)}</td>
    },
    "Follow Up Date": {
      header: "Follow Up Date",
      field: "follow_up_date",
      // Cell: () => (
      //   <DateRangeHeaderColumn header="Last Updated" field="last_updated_at" />
      // ),
      active: true,
      Row: row => <td>{formatDate(row.follow_up_date)}</td>
    }
  };
  if (["OfficeAdministrator", "CompanyAdministrator"].includes(userRole))
    return R.omit(["Owner"], Table);
  return Table;
};

export const useTaskTable = () => {
  const companies = useSelector(selectors.getFormOptionsCompany);
  // const sleepCoaches = useSelector(getFormOptionsSleepCoachWithUserFirst);
  // const admins = useSelector(selectors.getEnumAdmins);
  const userRole = useSelector(selectors.getUserRole);
  let taskActions = useSelector(selectors.getTaskActionsOptions)
  taskActions.sort(function (a, b) {
      return b.patient_type - a.patient_type || a.text.localeCompare(b.text);
  });

  const taskTable = getTableSchema({
    companies,
    // sleepCoaches,
    userRole,
    taskActions,
    // admins
  });
  const tableSchema = Object.keys(taskTable).map(key => ({
    ...taskTable[key],
    key
  }));
  const defaultSettings = R.mapObjIndexed(R.pick(["active"]), taskTable);
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings
  } = useTableSettingsNew("tasks-table-settings", defaultSettings);

  const visibleTableSchema = tableSchema.filter(({ key }) =>
    isColumnVisible(key)
  );
  const tableColumns = visibleTableSchema.map(R.omit(["Row"]));
  const tableRows = visibleTableSchema.map(R.prop("Row"));

  // remove sorting and filters when column is removed
  // React.useEffect(() => {
  //   const newQuery = cleanQuery(
  //     tableColumns.map(({ field }) => field),
  //     query
  //   );
  //   if (!isEqual(newQuery, query)) {
  //     queryDispatcher({ type: "updateQuery", ...newQuery });
  //   }
  // }, [tableColumns, queryDispatcher]);

  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    tableColumns,
    tableRows
  };
};
