import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import SearchMatches from "./search-matches";
import escapeRegExp from "lodash/escapeRegExp";
import MdClose from "react-icons/lib/md/close";

const getMatches = (transferNumber, phoneBook) => {
  const searchValue = escapeRegExp(transferNumber.toLowerCase());
  if (/^\d/.test(searchValue)) {
    const matchRegex = new RegExp(`[A-Za-z.s]*${searchValue}[A-Za-z.s]*`);
    return phoneBook.filter(({ extension }) => matchRegex.test(extension));
  } else {
    const matchRegex = new RegExp(`[A-Za-z.s]*${searchValue}[A-Za-z.s]*`);
    return phoneBook.filter(({ first_name, last_name }) =>
      matchRegex.test(`${first_name} ${last_name}`.toLowerCase())
    );
  }
};

const TransferInputField = ({
  transferNumber,
  setTransferNumber,
  phoneBook
}) => {
  // const [displayValue, setDisplayValue] = useState("");
  const [matchedItems, setMatchedItems] = useState([]);
  const [showResult, setShowResult] = useState(false);
  return (
    <div className="transfer-input-field">
      <div className="search-container">
        <div className="content">
          <div
            className="search-input-wrapper"
            title="Transfer to extension or phone #"
          >
            <input
              type="search"
              placeholder="Transfer #"
              className="transfer-input"
              value={transferNumber}
              onChange={async e => {
                const { value } = e.target;
                const matches = getMatches(value, phoneBook);
                setMatchedItems(matches);
                setTransferNumber(value);
              }}
              onClick={() => {
                // setDisplayValue("");
              }}
              onInput={e => {
                if (e.target.value && matchedItems.length > 0)
                  setShowResult(true);
                else setShowResult(false);
              }}
            />
            {transferNumber && (
              <span className="clear-icon">
                <MdClose
                  title="Clear"
                  onClick={() => {
                    // setDisplayValue("");
                    setTransferNumber("");
                  }}
                />
              </span>
            )}
          </div>
          <SearchMatches
            isOpen={showResult}
            items={matchedItems}
            onItemSelect={item => {
              setTransferNumber(item.extension);
              // setDisplayValue(`${item.first_name} ${item.last_name} - `);
              setShowResult(false);
            }}
            handleClose={() => {
              const isExtension = /\d{3,6}/.test(transferNumber);
              if (!isExtension) setTransferNumber("");
              setShowResult(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  phoneBook: selectors.getPhoneBookUsers(state)
}))(TransferInputField);

TransferInputField.propTypes = {
  phoneBook: PropTypes.arrayOf(PropTypes.object).isRequired,
  transferNumber: PropTypes.string.isRequired,
  setTransferNumber: PropTypes.func.isRequired
};
