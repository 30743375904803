import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PureModal from "components/ui/modal/pure-modal";
import ProductFaqs from "components/dd_newModules/subModule/FAQs/product-faqs";
import ProductFAQsForm from "components/dd_newModules/subModule/FAQs/product-faqs-form";
import { createFAQ } from "components/dd_newModules/Redux/Actions/faq";

const InventoryFaqs = ({ inventoryItem, createFAQ }) => {
  return (
    <div>
      <div>
        <h4 style={{ display: "inline-block" }}>Faqs</h4>
        <PureModal
          renderTrigger={({ openModal }) => (
            <button
              type="button"
              style={{ margin: 0, float: "right" }}
              onClick={openModal}
            >
              Add FAQ
            </button>
          )}
          renderContent={({ closeModal }) => (
            <ProductFAQsForm
              handleCancel={closeModal}
              handleSubmit={async (values) => {
                await createFAQ(inventoryItem.id, values);
              }}
            />
          )}
        />
      </div>
      <hr />
      <ProductFaqs productId={inventoryItem.id} />
    </div>
  );
};

InventoryFaqs.propTypes = {
  inventoryItem: PropTypes.object.isRequired,
  createFAQ: PropTypes.func.isRequired,
};

export default connect(() => ({}), { createFAQ })(InventoryFaqs);
