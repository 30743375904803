import React from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";

export const IsNotContractedEmployee = React.memo(
  ({ contractedEmployee, children }) => {
    if (
      contractedEmployee === false
    ) {
      return <React.Fragment>{children}</React.Fragment>;
    }
    return null;
  }
);

IsNotContractedEmployee.propTypes = {
  contractedEmployee: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default connect(state => ({
  contractedEmployee: selectors.getContractedEmployee(state)
}))(IsNotContractedEmployee);
