import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";

const FileUploader = forwardRef(({ onChange, onRemove }, fileRef) => {
  const handleClick = () => {
    fileRef.current.click();
  };
  const [selectedFile, setSelectedFile] = useState();
  const handleChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (typeof onChange === "function") {
      onChange(event);
    }
  };
  return (
    <>
      {selectedFile ? (
        <div style={{ margin: 5, padding: 5 }}>
          <span>{selectedFile.name}</span>
          <span
            onClick={() => {
              setSelectedFile(undefined);
              fileRef.current.value = "";
              if (typeof onRemove === "function") onRemove(undefined);
            }}
            style={{ color: "red", paddingLeft: 10, "cursor": "pointer" }}
          >
            Remove
          </span>
        </div>
      ) : (
        <button onClick={handleClick}>Choose File</button>
      )}
      <input
        type="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
});

FileUploader.propTypes = { onChange: PropTypes.func, onRemove: PropTypes.func };

export default FileUploader;
