import C from "action-types";
import { post } from "utils/api";
import { errorMessage, message } from "actions/message";

export const submitPatientImport = data => {
  return {
    type: C.PATIENT.PATIENT_IMPORT,
    payload: data
  }
};

export const initiatePatientImport = (requestorMGUID, companyMGUID) => async (dispatch)  => {
  try {
    const response = await post(`Niko/Import/${requestorMGUID}/${companyMGUID}`);
    if (response === "Import Completed Successfully") {
      dispatch(message(response));
    } else {
      dispatch(errorMessage(response));
    }
  } catch (error) {
    dispatch(errorMessage(`Niko Import failed, ${error}`));
  }
}

export const initiateAccountImport = (requestorMGUID, companyMGUID, accountNumber) => async (dispatch)  => {
  try {
    const response = await post(`Niko/Import/${requestorMGUID}/${companyMGUID}/${accountNumber}`);
    if (response === "Import Completed Successfully") {
      dispatch(message(response));
    } else {
      dispatch(errorMessage(response));
    }
  } catch (error) {
    dispatch(errorMessage(`Niko Import failed, ${error}`));
  }
}