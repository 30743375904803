import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { TableButton } from "components/ui";
import { IoRefresh, IoPerson, IoClipboard } from "react-icons/lib/io";
import { setPatientBatchIds, setOrderBatchIds } from "actions/batch";
// import BulkUpdateTasks from "../bulk-update-tasks";
import { createTab } from "actions/system-tray";
import { exportOrderTasks } from "actions/exports";
import { FaFileExcelO } from "react-icons/lib/fa";
import { TableSettingsNew } from "hooks/useTableSettings";
import * as R from "ramda";

const TasksTableControls = ({
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows,
  query,
  tableSettings,
  resetTableSettings,
  updateTableSettings
}) => {
  const dispatch = useDispatch();

  return (
    <div className="query-table-controls" style={{ borderRadius: 0 }}>
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoRefresh />
      </TableButton>
      <TableButton
        title="Export order tasks to excel file"
        onClick={async () => {
          const columnsToExport = R.pipe(
            R.values,
            R.filter(R.prop("active")),
            R.map(R.prop("field"))
          )(tableSettings);
          await dispatch(exportOrderTasks(query, columnsToExport));
        }}
      >
        <FaFileExcelO />
      </TableButton>
      <TableButton
        title={`Process Tasks: (${selectedRows.length} selected)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          const patientIds = selectedRows.map(x => x.patient_id);
          const patientNames = selectedRows.map(x => x.patient_name);
          dispatch(setPatientBatchIds(patientIds, patientNames));
          dispatch(
            createTab({
              type: "patients",
              key: "patients",
              label: `Patients (${patientIds.length})`,
              meta: { ids: patientIds }
            })
          );
          clearSelectedRows();
        }}
      >
        <IoPerson size={24} />
      </TableButton>
      <TableButton
        title={`Process orders (${selectedRows.length} selected)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          const orderIds = selectedRows.map(x => x.order_id);
          const orderTitles = selectedRows.map(x => x.invoice_number);
          dispatch(setOrderBatchIds(orderIds, orderTitles));
          dispatch(
            createTab({
              type: "orders",
              key: "orders",
              label: `Orders (${orderIds.length})`,
              meta: { ids: orderIds }
            })
          );
          clearSelectedRows();
        }}
      >
        <IoClipboard size={24} />
      </TableButton>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} Tasks</span>
        )}
      </div>
      <div className="action-buttons companies-table-action-buttons">
        <TableSettingsNew
          tableSettings={tableSettings}
          updateTableSettings={updateTableSettings}
          resetTableSettings={resetTableSettings}
        />
      </div>
    </div>
  );
};

export default TasksTableControls;

TasksTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  tableSettings: PropTypes.object.isRequired,
  resetTableSettings: PropTypes.func.isRequired,
  updateTableSettings: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};
