import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "components/ui/date-picker";
import { formatDate } from "utils/dates";

// const makeParameterMessage = (dateDifference, startDate, today) =>
//   `Results based upon ${dateDifference} days of data, from: ${formatDate(
//     startDate,
//     "MM/DD/YYYY"
//   )} to end of day ${formatDate(today, "MM/DD/YYYY")}`;

const arrayOfDaysOptions = [
  { text: "30 days", value: 30 },
  { text: "60 days", value: 60 },
  { text: "90 days", value: 90 },
  { text: "120 days", value: 120 }
];

class OrderDayPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfDays: 90,
      today: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      dateDifference: 0,
      // parameterMessage: ""
    };
    this.handleDayChange = this.handleDayChange.bind(this);
    this.submitDateRange = this.submitDateRange.bind(this);
  }

  componentWillMount() {
    const { user, getOrdersSnapshot } = this.props;
    const { numOfDays, endDate } = this.state;

    const today = moment();
    const startDate = moment(today).subtract(numOfDays, "days");
    const dateDifference = Math.ceil(
      moment
        .duration(moment(today).endOf("day") - moment(startDate).startOf("day"))
        .asDays()
    );
    // const parameterMessage = makeParameterMessage(
    //   dateDifference,
    //   startDate,
    //   today
    // );

    this.setState(
      {
        ...this.state,
        today,
        startDate,
        endDate: today,
        dateDifference,
        // parameterMessage
      },
      () => {
        getOrdersSnapshot(user.get("id"), this.state.startDate, endDate);
      }
    );
  }

  handleDayChange(indentifier, e) {
    const { user, getOrdersSnapshot } = this.props;
    const { today } = this.state;

    switch (indentifier) {
      case "select": {
        const startDate = moment(today).subtract(e.target.value, "days");
        const dateDifference = Math.ceil(
          moment
            .duration(
              moment(today).endOf("day") - moment(startDate).startOf("day")
            )
            .asDays()
        );
        // const parameterMessage = makeParameterMessage(
        //   dateDifference,
        //   startDate,
        //   today
        // );

        this.setState(
          {
            ...this.state,
            startDate,
            numOfDays: e.value,
            dateDifference
            // parameterMessage
          },
          () => getOrdersSnapshot(user.get("id"), this.state.startDate, today)
        );
        break;
      }
      case "startDate": {
        const startDateEntered = e || new Date();
        const dateDifferenceForStartDate = Math.ceil(
          moment
            .duration(
              moment(this.state.endDate).endOf("day") -
                moment(startDateEntered).startOf("day")
            )
            .asDays()
        );

        this.setState({
          ...this.state,
          startDate: startDateEntered,
          dateDifference: dateDifferenceForStartDate
        });
        break;
      }
      case "endDate": {
        const endDateEntered = e || new Date();
        const dateDifferenceForEndDate = Math.ceil(
          moment
            .duration(
              moment(endDateEntered).endOf("day") -
                moment(this.state.startDate).startOf("day")
            )
            .asDays()
        );

        this.setState({
          ...this.state,
          endDate: endDateEntered,
          dateDifference: dateDifferenceForEndDate
        });
        break;
      }
      default:
        break;
    }
  }

  submitDateRange() {
    const { getOrdersSnapshot, user } = this.props;
    const { startDate, endDate, dateDifference } = this.state;
    // const parameterMessage = makeParameterMessage(
    //   dateDifference,
    //   startDate,
    //   endDate
    // );

    this.setState(
      {
        ...this.state,
        // parameterMessage: parameterMessage,
        numOfDays: dateDifference
      },
      () => {
        getOrdersSnapshot(user.get("id"), startDate, endDate);
      }
    );
  }

  render() {
    const { startDate, endDate, numOfDays, dateDifference } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#f0eeee",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr auto",
          gridGap: 5,
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          paddingRight: "2rem"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div>Days Prior </div>
          <select
            onChange={e => this.handleDayChange("select", e)}
            value={numOfDays}
          >
            {/* dateDifference chosen from the date range does not exist in option array */}
            {!arrayOfDaysOptions.some(option => {
              return option.value === dateDifference - 1;
            }) && (
              <option key={"range"} value={dateDifference}>
                {`${dateDifference} ${dateDifference !== 1 ? "days" : "day"}`}
              </option>
            )}

            {arrayOfDaysOptions.map(({ text, value }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div>Start Date </div>
          <DatePicker
            month={moment(startDate).toDate()}
            selectedDays={moment(startDate).toDate()}
            showOutsideDays
            onChange={e => this.handleDayChange("startDate", e)}
          />
          <div>{formatDate(startDate)}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div>End Date </div>
          <DatePicker
            month={moment(endDate).toDate()}
            selectedDays={moment(endDate).toDate()}
            showOutsideDays
            onChange={e => this.handleDayChange("endDate", e)}
          />
          <div>{formatDate(endDate)}</div>
        </div>
        <div className="align-self-middle">
          <button
            className="button tiny"
            style={{ margin: 0 }}
            onClick={this.submitDateRange}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default OrderDayPicker;

OrderDayPicker.propTypes = {
  user: PropTypes.object.isRequired,
  getOrdersSnapshot: PropTypes.func
};
