import React from "react";
import PropTypes from "prop-types";
import without from "lodash/without";
import includes from "lodash/includes";
import uniq from "lodash/uniq";

const BatchRowCell = ({ selectedRows, setSelectedRows, id }) => (
  <td>
    <label
      style={{
        position: "relative",
        paddingLeft: "5px",
        margin: "-5px"
      }}
    >
      <input
        type="checkbox"
        checked={includes(selectedRows, id)}
        onChange={({ target: { checked } }) => {
          if (checked) setSelectedRows(uniq([...selectedRows, id]));
          else setSelectedRows(without(selectedRows, id));
        }}
      />
    </label>
  </td>
);

BatchRowCell.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default BatchRowCell;
