import { take, call, put } from "redux-saga/effects";
import { browserHistory } from "browser-history";
import {
  REQUEST_EXPORT_USER_TABLE,
  errorExportUserTable
} from 'actions/users'
import * as api from 'utils/api'

function* exportUserTable({ payload: { userType, filteredRows } }) {
  const url = `users/export/${userType}`
  const params= filteredRows.map(row => row.id);

  try {
    yield call(api.post, url, params)
    yield call(api.get, 'exports')
    browserHistory.push("/exports");
  } catch(error) {
    yield put(errorExportUserTable(error, userType))
  }
}

export default function* watchExportUserTable() {
  while (true) {
    const action = yield take(REQUEST_EXPORT_USER_TABLE)
    yield call(exportUserTable, action)
  }
}
