import React, { Component } from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";
import SelectInput from "components/forms/select-input";
import SearchableDropdown from "components/searchable-dropdown";
import PageHeader from "components/ui/page-header";
import { submitPatientImport, initiatePatientImport, initiateAccountImport } from "actions/patient-import";
import { Waiting } from "components/ui";
import ImportResults from "./import-results";
import HasRolePermission from "components/shared/has-role-permission";

class PatientImportForm extends Component {
  state = {
    headersCheckboxChecked: true,
    singleAccountImport: this.props.role === "ServiceCoach",
    initialImportChecked: false,
    company_id: "",
    file_patient_type: "",
    nikoCompanyId: "",
    nikoAccountNumber: "",
    userId: "",
    role: "",
    nikoButtonDisabled: false
  };

  nikoHealthInitiation = (e) => {
    e.preventDefault();
    if (this.props.companies === undefined || this.props.companies.length === 0) {
      window.confirm("Error, please wait for companies to finish loading before beginning import.");
      return;
    }
    if (this.state.singleAccountImport && this.state.nikoAccountNumber === "") {
      window.confirm("Error, no account number provided.");
      return;
    }
    const company = this.props.companies.find(c => c.value === this.state.nikoCompanyId);
    const confirmed = this.state.singleAccountImport 
      ? window.confirm(`Are you sure? Importing patient ${this.state.nikoAccountNumber} from\n${company?.text}`)
      : window.confirm(
      `Are you sure? Importing patients for\n${company?.text}`
    );
    if (confirmed) {
      try {
        this.setState({ nikoButtonDisabled: true });
        if(this.state.singleAccountImport) {
          this.props.initiateAccountImport(this.props.userId, company?.GUID, this.state.nikoAccountNumber);
          window.alert(`Manual import of account ${this.state.nikoAccountNumber} begun.\nYou may safely navigate away from this page and continue working.\nYou will receive a notification in the bottom right regarding success.`);
        } else {
          this.props.initiatePatientImport(this.props.userId, company?.GUID);
          window.alert("Warning. Manual import from Niko Health may take upwards of 5 min.\nYou may safely navigate away from this page and continue working.\nYou will receive a notification in the bottom right regarding success.");
        }
      } finally {
        this.setState({ nikoCompanyId: "", nikoButtonDisabled: false, singleAccountImport: false });
      }
    }
  }

  handleChange = e => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleSearchableSelectChange = value => {
    this.setState({ company_id: value });
  };

  onPatientImportSubmit = e => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("company", this.state.company_id);
    formData.append("file", this.filesInput.files[0]);
    formData.append("headers", this.state.headersCheckboxChecked);
    formData.append("initialImport", this.state.initialImportChecked);
    formData.append("patientType", this.state.file_patient_type);

    const companyName =
      this.state.company_id !== ""
        ? this.props.companies.find(c => c.value === this.state.company_id).text
        : "";
    const { name: fileName } = this.filesInput.files[0];
    const confirmed = window.confirm(
      `Are you sure? Importing patients for\n ${companyName} \n File: ${fileName}`
    );
    if (confirmed) {
      try {
        this.props.submitPatientImport(formData);
      } finally {
        this.filesInput.value = "";
        this.setState({ company_id: "" });
      }
    }
  };

  render() {
    const { companies, isFetching, accountNumberFailures } = this.props;
    const patientTypeOptions = [{ text: "Sleep Resupply", value: 1 }, { text: "Compliance", value: 2 }, { text: "Remote Set Up", value: 3 }];
    const searchableCompaniesOptions = companies?.map(x => ({ text: x.text, value: x.value, key: x.value }));
    const companyName =
      this.state.company_id !== ""
        ? companies.find(c => c.value === this.state.company_id).text
        : "";
    return (
      <div>
        <PageHeader title="Patient Importer" />
        <HasRolePermission allowedRoles={["Administrator", "ServiceAdmin", "ContractorAdministrator"]}>
        <form
          encType="multipart/form-data"
          onSubmit={e => this.onPatientImportSubmit(e)}
          style={{
            display: "grid",
            gridTemplateColumns: "500px",
            gridGap: 10
          }}
          >
          <SearchableDropdown
            name="company_id"
            label="Company"
            requireSearch={true}
            selected={this.state.company_id}
            options={searchableCompaniesOptions}
            onSelect={this.handleSearchableSelectChange}
            />
          <SelectInput
            name="file_patient_type"
            label="File Patient Type"
            required={true}
            selected={this.state.file_patient_type}
            options={patientTypeOptions}
            onChange={this.handleChange}
            />
          <input
            ref={input => {
              this.filesInput = input;
            }}
            name="file"
            required
            type="file"
            style={{ fontSize: "1.05rem" }}
            />
          <label>
            Headers
            <input
              style={{ marginLeft: "10px" }}
              name="headersCheckboxChecked"
              type="checkbox"
              checked={this.state.headersCheckboxChecked}
              onChange={this.handleChange}
              />
          </label>
          <label>
            Initial Import
            <input
              style={{ marginLeft: "10px" }}
              name="initialImportChecked"
              type="checkbox"
              checked={this.state.initialImportChecked}
              onChange={this.handleChange}
              />
          </label>
          <input
            className="button"
            type="submit"
            value="Submit"
            disabled={!this.state.company_id || isFetching}
            />
        </form>
        </HasRolePermission>

        <HasRolePermission allowedRoles={["Administrator", "ServiceAdmin", "ServiceCoach", "ContractorAdministrator"]}>
        <form
          encType="multipart/form-data"
          onSubmit={e => this.nikoHealthInitiation(e)}
          style={{
            display: "grid",
            gridTemplateColumns: "500px",
            gridGap: 10,
            marginTop: "5rem"
          }}
          >
          <SelectInput
            name="nikoCompanyId"
            label="Niko Health Companies"
            value={this.state.nikoCompanyId}
            options={companies?.filter(x => x.niko_health_company)}
            onChange={this.handleChange}
          />
          <label>
            Single Account Import
            <input
              style={{ marginLeft: "10px" }}
              name="singleAccountImport"
              type="checkbox"
              disabled={this.props.role === "ServiceCoach"}
              checked={this.state.singleAccountImport || this.props.role === "ServiceCoach"}
              onChange={this.handleChange}
            />
          </label>
          {this.state.singleAccountImport && <label>
            Account Number
            <input
              style={{ marginLeft: "10px" }}
              name="nikoAccountNumber"
              type="text"
              required={this.state.singleAccountImport}
              value={this.state.nikoAccountNumber}
              onChange={this.handleChange}
            />
          </label>}
          <input 
            className="button"
            type="submit"
            value="Manual Import from Niko Health"
            disabled={this.state.nikoButtonDisabled || !this.state.nikoCompanyId}
          />
        </form>
        </HasRolePermission>
        
        <ImportResults
          results={accountNumberFailures}
          title={`The following ${accountNumberFailures.length} patients failed`}
          fields={[{ title: "Account Number", name: "acctNum" }]}
        />
        {isFetching && (
          <div style={{ margin: "100px 0px 0px 0px" }}>
            <Waiting reason={`submitting ${companyName} patients`} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    companies: selectors.getFormOptionsCompany(state),
    isFetching: state.getIn(["patient-import"]).isFetching,
    accountNumberFailures: state.getIn(["patient-import"]).accountNumberFailures,
    userId: selectors.getUserId(state),
    nikoButtonDisabled: state.nikoButtonDisabled,
    role: selectors.getUserRole(state)
  }),
  {
    submitPatientImport,
    initiatePatientImport,
    initiateAccountImport
  }
)(PatientImportForm);

PatientImportForm.propTypes = {
  companies: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  accountNumberFailures: PropTypes.array,
  submitPatientImport: PropTypes.func.isRequired,
  initiatePatientImport: PropTypes.func.isRequired,
  initiateAccountImport: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};
