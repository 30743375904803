import { combineReducers } from "redux";
import { Types } from "actions/fulfillment-status";
import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";
import { prop } from "ramda";

const requestingUpdate = createReducer(false, {
  [Types.REQUEST_FULFILLMENT_STATUS_UPDATE]: () => true,
  [Types.RESPONSE_FULFILLMENT_STATUS_UPDATE]: () => false,
  [Types.FULFILLMENT_STATUS_UPDATE_ERROR]: () => false
});

export default combineReducers({ requestingUpdate });

const getIsUpdatingFulfilment = createSelector(
  [state => state.getIn(["fulfillmentStatus"])],
  prop("requestingUpdate")
);

export const selectors = {
  getIsUpdatingFulfilment
};
