import React from "react";
import PropTypes from "prop-types";
import { Card, ListItem } from "components/ui";
import { fromTo } from "utils/misc";
import JoinChatBtn from "./join-chat-btn";
import ClockIcon from "assets/images/clock_gray.png";
import moment from "moment";
import AppointmentActions from "./appointment-actions";
import { Link } from 'react-router-dom';
import "./appointment-actions.scss"

const UpcomingChat = ({ chats, master_chat_type, getUpcomingChat }) => {
  const { chat_details, topic_details, patient_details } = chats[0];
  const is_confirmed = chat_details.meeting_status !== "Un-confirmed" && chat_details.meeting_status !== "declined";
  const is_cancelled = chat_details.meeting_status === "canceled";
  const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));

  const getStatusColor = (meeting_status) => {

    var statusColor = "white";

    switch (meeting_status) {
      case "completed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "Confirmed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "not completed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "declined":
        statusColor = "rgb(174, 22, 62)";
        break;
      case "rescheduled":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "Un-confirmed":
        statusColor = "rgb(255, 211, 0)";
        break;
      case "canceled":
        statusColor = "rgb(242, 21, 21)";
    }

    return statusColor;
  }

  const diff = moment.duration(
    moment.tz(chat_details.start_time, userLoggedIn.timezone).diff(moment().tz(userLoggedIn.timezone))
  );

  const remainingTime = {
    days: moment.utc(diff.as("milliseconds")).format("D") - 1,
    hours: moment.utc(diff.as("milliseconds")).format("H"),
    minutes: moment.utc(diff.as("milliseconds") + 60000).format("mm"),
  };

  return (
    <Card style={{ margin: 8 }}>
      <div className="upcoming-chat-date-container" style={{ background: getStatusColor(chat_details.meeting_status) }}>
        <span>{moment(chat_details.date_time).format('MMM')}</span>
        <span>{moment(chat_details.date_time).format('DD')}</span>
        <span>{moment(chat_details.date_time).format('yyyy')}</span>
      </div>
      <div className="upcoming-chat-container">
        <div className="chat-header">
          <span>
            {
              topic_details[0]?.topic_name
            }
          </span>
          <span style={{ padding: "0 5px" }}> | </span>
          <span>{master_chat_type}</span>

          <div className="chat-time">
            <img src={ClockIcon} alt="" />
            <span>
              {fromTo(
                chat_details.start_time,
                chat_details.end_time,
                "to"
              )}
            </span>
          </div>
        </div>
        <div className="status-and-remaining-time">
          <div className="status-info">
            {!is_confirmed && <span className="status-info-confirmation-pending">Confirmation pending</span>}
            {is_cancelled && <span className="status-info-cancelled">Canceled</span>}
          </div>
          {!is_cancelled && is_confirmed &&
            (diff > 0 ?
              <div className="chat-remaining-time">
                {"Starts in"} {remainingTime.days} {"days"},{" "}
                {remainingTime.hours} {"Hrs"}, {remainingTime.minutes}{" "}
                {"minutes"}
              </div> :
              <div className="chat-remaining-time">
                <JoinChatBtn {...chat_details} master_chat_type={master_chat_type} isConfirmed={is_confirmed} />
              </div>)
          }
        </div>
      </div>
      <div className="upcoming-chat-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
          }}
        >
          {
            chat_details.description &&
            <ListItem
              label="Description"
              className="list-item-custom"
              value={chat_details.description}
            />
          }
          {chat_details.is_product_demo && <ListItem
            label="Current appointment/s"
            className="list-item-custom"
            value={chat_details.patients_invited + ' | ' + chat_details.invitees_limit}
          />}
          <ListItem
            label="With"
            className="list-item-custom"
          />
          <div className="list-item list-item-custom">
            {
              patient_details?.map(({ patient_name, patient_id, status }) => (
                status ?
                  <Link key={patient_id} to={`/patients/${patient_id}`} className="item-value" >
                    <span style={{ color: getStatusColor(status), fontFamily: 'Poppins-Bold' }}>{patient_name}</span>
                  </Link>
                  :
                  <Link key={patient_id} to={`/patients/${patient_id}`} className="item-value">
                    {patient_name}
                  </Link>
              ))
            }
          </div>
        </div>
        <div className="upcoming-chat-actions">
          {!is_cancelled && diff > 0 && <JoinChatBtn {...chat_details} master_chat_type={master_chat_type} isConfirmed={is_confirmed} />}
          {
            !is_cancelled &&
            <AppointmentActions
              slot_id={chat_details.is_product_demo ? chat_details.slot_id : undefined}
              canBeCanceled={chat_details.can_be_cancelled}
              {...chat_details}
              chat_id={chat_details.chat_id}
              getUpcomingChat={getUpcomingChat}
              createdByPatient={chat_details.created_by_patient}
              canReschedule={chat_details.can_reschedule}
              patientsToBeNotified={chat_details.is_product_demo ? chat_details.patients_invited : 1}
            />
          }
        </div>
      </div>
    </Card>
  );
};

UpcomingChat.propTypes = {
  chats: PropTypes.arrayOf(PropTypes.object),
  master_chat_type: PropTypes.string,
  getUpcomingChat: PropTypes.func
};

export default UpcomingChat;
