import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { StatBlock } from "components/ui";
import { useFetch } from "hooks";
import { get } from "utils/api";
import { errorMessage } from 'actions/message';
import { getApiErrorMessage } from "utils/misc";

const CoachStatsComplianceOutreach = () => {

    const userId = useSelector(selectors.getUserId)

    const {
        response: snapshotInfo,
        fetchData: fetchSnapshot,
    } = useFetch({
        apiFn: (userId) => get(`patients/compliance-snapshot?id=${userId}`),
        defaultValue: {},
        onError: error => {
            errorMessage(`Failed to get snapshot info: ${getApiErrorMessage(error)}`);
        }
    })

  useEffect(() => {
    fetchSnapshot(userId)
  }, [userId]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, auto)",
        gridGap: 20,
        marginBottom: 20
      }}
    >
      <StatBlock
        title="Compliance Active Patients"
        value={snapshotInfo.active ? snapshotInfo.active : "0"}
      />
      <StatBlock
        title="Eligible for Outreach"
        value={
            snapshotInfo.eligible ? snapshotInfo.eligible : "0"
        }
      />
      <StatBlock
        title="Not Eligible"
        value={
            snapshotInfo.not_eligible ? snapshotInfo.not_eligible : "0"
        }
      />
    </div>
  );
};

export default CoachStatsComplianceOutreach
