import { GET_PRODUCT } from './../ActionTypes';
import API from "./../../utils/axios";
import { showLoader, hideLoader } from "./common";

export const getProducts = () => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/products";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.products) {
            const filterObj = res.data.products.map((data) => {
                return {label: data.name,value: data.ID};
            });
            return dispatch({
                type: GET_PRODUCT,
                payload: filterObj
            });
        }else{
            return dispatch({
                type: GET_PRODUCT,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err);
    })
}

