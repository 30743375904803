import React from "react";
import AllCompanyTable from "components/screens/company-table";
import { Route, withRouter, Switch } from "react-router-dom";
import CompanyProfile from "components/screens/company";
import CompanyExports from "components/screens/company/company-exports";
import ManageCompany from "components/screens/company/manage-company";
import CompanyMisc from "components/screens/company/company-misc";
import CompanyOrders from "components/screens/company/company-orders";
import CompanyPatients from "components/screens/company/company-patients";
import CompanyContacts from "components/screens/company/company-contacts";
import EmailTemplateForm from "components/screens/company/email-template";
import EditBranch from "components/screens/company/edit-branch";
import CreateBranch from "components/screens/company/create-branch";
import CompanyMonthlyReport from "components/screens/company/monthly-report";
import CompanyContactLogic from "components/screens/company-contact-logic";
import CompanyInventory from "components/screens/company/inventory";
import CompanyInventoryProfile from "components/screens/company/inventory-profile";
import PatientDemographicLogs from "components/screens/company/company-patient-demographic-logs";
import CompanyMaskFitting from "components/screens/company/mask-fitting";
import CompanyAutomatedJobs from "components/screens/company/automated-jobs";
import CompanyAppPermissions from "components/screens/company/app-permissions";
import HasRolePermission from "components/shared/has-role-permission";
import { CompanyChangeLog } from "../components/screens/company/company-change-logs";
import { CompanyInsurance } from "components/screens/company/hcpc-eligbility/index";
import CompanyForms from "app/Companies/Presentation/pages/CompanyForms";
import CreateEditForm from "app/Companies/Presentation/pages/CreateEditForm";
import ViewForm from "app/Companies/Presentation/pages/viewForm";
import ComplianceDashboard from "components/screens/dashboard/compliance-dashboard";
import GenerateReport from "app/Companies/Presentation/pages/generate-report";
import Onboarding from "components/screens/company/onboarding";

export default [
  {
    path: "/companies/contact-logic/:patientType",
    component: CompanyContactLogic
  },
  {
    path: "/companies",
    component: AllCompanyTable,
    exact: true
  },
  {
    path: "/companies/new",
    exact: true,
    component: ManageCompany,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/companies/:id/edit",
    exact: true,
    component: withRouter(({ match }) => (
      <ManageCompany params={match.params} />
    )),
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/companies/:id/email-template",
    component: EmailTemplateForm,
    allowedRoles: ["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/companies/:id/new-email-template",
    component: EmailTemplateForm,
    allowedRoles: ["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/companies/:id/edit-email-template",
    component: EmailTemplateForm,
    allowedRoles: ["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/companies/:id/branch/add",
    component: CreateBranch,
    allowedRoles: ["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/companies/:id/branch/:bid/edit",
    component: EditBranch,
    allowedRoles: ["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/companies/:id/logs",
    exact: true,
    component: CompanyChangeLog,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/companies/:id",
    component: withRouter(({ match }) => (
      <CompanyProfile params={match.params}>
        <Switch>
          <Route path="/companies/:id/profile/onboarding">
            <Onboarding params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/complianceForms" >
            <CompanyForms params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/compliance-dashboard" >
            <ComplianceDashboard params={match.params} />
          </Route>
          <Route path="/companies/:id/forms/newForm" >
            <CreateEditForm params={match.params} />
          </Route>
          <Route path="/companies/:id/forms/editForm/:formId" >
            <CreateEditForm params={match.params} />
          </Route>
          <Route path="/companies/:id/forms/generateReport/:formId" >
            <GenerateReport params={match.params} />
          </Route>
          <Route path="/companies/:id/forms/viewForm/:idForm" >
            <ViewForm />
          </Route>
          <Route path="/companies/:id/profile/contacts">
            <CompanyContacts params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/orders">
            <CompanyOrders params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/patients">
            <CompanyPatients params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/monthly-report" exact>
            <CompanyMonthlyReport params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/exports" exact>
            <CompanyExports params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/patient-demographic-logs" exact>
            <PatientDemographicLogs params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/inventory" exact>
            <CompanyInventory params={match.params} title="Inventory" />
          </Route>

          <Route path="/companies/:companyId/profile/inventory/:xrefId" exact>
            <CompanyInventoryProfile params={match.params} title="Inventory" />
          </Route>

          <Route path="/companies/:id/profile/mask-fitting" exact>
            <CompanyMaskFitting params={match.params} />
          </Route>
          <Route path="/companies/:id/profile/insurance" exact>
            <CompanyInsurance params={match.params} title="Insurance" />
          </Route>
          <Route path="/companies/:id/profile/app-permissions" exact>
            <HasRolePermission allowedRoles={["Administrator"]}>
              <CompanyAppPermissions
                companyId={match.params?.id}
                params={match.params}
              />
            </HasRolePermission>
          </Route>
          <Route path="/companies/:id/profile/automated-jobs" exact>
            <HasRolePermission allowedRoles={["Administrator"]}>
              <CompanyAutomatedJobs companyId={match.params?.id} />
            </HasRolePermission>
          </Route>
          <Route path="/companies/:id">
            <CompanyMisc params={match.params} />
          </Route>
        </Switch>
      </CompanyProfile>
    )),
    allowedRoles: [
      "Administrator",
      "OfficeAdministrator",
      "CompanyAdministrator",
      "ServiceCoach",
      "ServiceAdmin",
      "SleepCoach", 
      "ContractorAdministrator"
    ]
  }
];
