import { get, post } from "utils/api";
import { invalidateOrderInfo } from "actions/order-profile";

const makeApiTypes = name => ({
  [`${name}`]: `${name}`,
  [`${name}_REQUEST`]: `${name}_REQUEST`,
  [`${name}_RESPONSE`]: `${name}_RESPONSE`,
  [`${name}_FAILURE`]: `${name}_FAILURE`
});

export const Types = {
  ...makeApiTypes("BONAFIDE_SUBMIT_ORDER"),
  ...makeApiTypes("BONAFIDE_ORDER_RESULT")
};

export const getBonafideInsuranceInfo = orderId => async dispatch => {
  try {
    dispatch({
      type: Types.BONAFIDE_ORDER_RESULT_REQUEST,
      orderId
    });
    const response = await get(`bonafide/order-info/${orderId}`);
    dispatch({
      type: Types.BONAFIDE_ORDER_RESULT_RESPONSE,
      orderId,
      response
    });
    return response;
  } catch (error) {
    dispatch({ type: Types.BONAFIDE_ORDER_RESULT_FAILURE, error });
  }
};

export const submitBonafideOrder = (
  orderId,
  orderSummary,
  confirmed = false,
  createAuth = false
) => async dispatch => {
  try {
    dispatch({
      type: Types.BONAFIDE_SUBMIT_ORDER_REQUEST,
      orderId
    });
    const response = await post(`bonafide/submit-order`, {
      orderId,
      orderSummary,
      confirmed,
      createAuth
    });
    dispatch({
      type: Types.BONAFIDE_SUBMIT_ORDER_RESPONSE,
      orderId,
      confirmed,
      response
    });
    dispatch(invalidateOrderInfo({ orderId })());
    return response;
  } catch (error) {
    dispatch({ type: Types.BONAFIDE_SUBMIT_ORDER_FAILURE, error });
  }
};
