import React from "react";

const Disclaimer = () => (
  <div
    style={{
      display: "flex",
      flex: 1,
      // backgroundColor: "rgb(256, 256, 256, .65)",
      // boxShadow: "inset 2px 2px 2px #ddd",
      borderRadius: 3,
      // transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      padding: 5,
      color: "rgb(41 53 63)",
      fontSize: 15,
      justifyContent: "flex-end",
      alignItems: "flex-end"
    }}
  >
    This system is for the use of authorized users only. Individuals using this
    computer system without authority, or in excess of their authority, are
    subject to having all of their activities on this system monitored and
    recorded by systems personnel. In the course of monitoring individuals
    improperly using this system, or in the course of system maintenance, the
    activities of authorized
  </div>
);

export default Disclaimer;
