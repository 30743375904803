import React, { Component } from "react";
import { Portal } from 'react-portal'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromJS } from "immutable"
import moment from "moment";
import {
  fetchCompanyMonthlyReport,
  invalidateCompanyMonthlyReport,
  fetchCompanyActivationReport,
  invalidateCompanyActivationReport
} from "actions/company";
import { withDatePicker } from 'components/ui/with-date-picker'
import { firstDayOfLastMonth, lastDayOfLastMonth } from 'utils/dates'
import { PrintCompanyMonthlyReport } from './print-company-monthly-report'
import CompanyReportView from './company-report-view'
import "react-tabs/style/react-tabs.css";

const lastMonth = moment()
  .subtract(1, "month")
  .add(1, "day")
  .format("MM");
const year = moment()
  .subtract(1, "month")
  .add(1, "day")
  .format("YYYY");

export class CompanyMonthlyReport extends Component {
  state = {
    isPrintModalOpen: false,
    selectedTabIndex: 0,
    monthlyReportType: 'custom',
    month: lastMonth,
    year: year
  }

  monthToFetch = () => {
    const { month, year } = this.state
    return `${month}/${year}`
  }

  updateDate = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  handleActivationReportSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const month = this.monthToFetch()
    const { params, fetchCompanyActivationReport, invalidateCompanyActivationReport } = this.props
    invalidateCompanyActivationReport({ id: params.id, month })
    fetchCompanyActivationReport({ id: params.id, month })
  }

  handleSelectTab = ({ index }) => this.setState({ selectedTabIndex: index })

  componentDidMount() {
    const {
      dates: { initStartDate, initEndDate },
      params,
      fetchCompanyMonthlyReport,
      invalidateCompanyMonthlyReport,
      invalidateCompanyActivationReport,
      fetchCompanyActivationReport
    } = this.props;
    invalidateCompanyMonthlyReport({
      id: params.id,
      startDate: initStartDate,
      endDate: initEndDate
    });
    fetchCompanyMonthlyReport({
      id: params.id,
      startDate: initStartDate,
      endDate: initEndDate
    });
    invalidateCompanyActivationReport({
      id: params.id,
      month: this.state.month
    });
    fetchCompanyActivationReport({ id: params.id, month: `${this.state.month}/${this.state.year}` });
  }

  handleMonthlyReportTypes = ({ type }) => {
    this.setState({ monthlyReportType: type })
  }
  handleSubmit = e => {
    e.preventDefault();
    const { dates: { to, from }, params, fetchCompanyMonthlyReport, invalidateCompanyMonthlyReport } = this.props
    const endDate = moment(to).format('MM/DD/YYYY')
    const startDate = moment(from).format('MM/DD/YYYY')
    invalidateCompanyMonthlyReport({ id: params.id, startDate, endDate })
    fetchCompanyMonthlyReport({ id: params.id, startDate, endDate })
  }

  togglePrintModal = () => {
    this.setState({ isPrintModalOpen: !this.state.isPrintModalOpen })
  }

  render() {
    const { dates: { to, from } } = this.props;
    const { monthlyReportType } = this.state
    return (
      this.state.isPrintModalOpen
        ? <Portal node={document && document.getElementById('print')}>
          <PrintCompanyMonthlyReport
            from={from}
            to={to}
            monthlyReportType={monthlyReportType}
            togglePrintModal={this.togglePrintModal}
            {...this.props} />
        </Portal>
        : <CompanyReportView
          from={from}
          to={to}
          monthToFetch={this.monthToFetch}
          handleMonthlyReportTypes={this.handleMonthlyReportTypes}
          handleActivationReportSubmit={this.handleActivationReportSubmit}
          togglePrintModal={this.togglePrintModal}
          handleSubmit={this.handleSubmit}
          handleSelectTab={this.handleSelectTab}
          updateDate={this.updateDate}
          selectedMonth={this.state.month}
          selectedYear={this.state.year}
          {...this.props}
        />)
  }
}

CompanyMonthlyReport.propTypes = {
  companyId: PropTypes.string,
  dates: PropTypes.shape({
    initStartDate: PropTypes.string,
    initEndDate: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string
  }),
  fetchCompanyMonthlyReport: PropTypes.func.isRequired,
  fetchCompanyActivationReport: PropTypes.func.isRequired,
  invalidateCompanyActivationReport: PropTypes.func.isRequired,
  handleFromClick: PropTypes.func.isRequired,
  handleToClick: PropTypes.func.isRequired,
  invalidateCompanyMonthlyReport: PropTypes.func.isRequired,
  isFetchingMonthlyReport: PropTypes.bool,
  monthly: PropTypes.object.isRequired,
  orders: PropTypes.object,
  params: PropTypes.shape({ id: PropTypes.string })
};

export default connect(
  state => ({
    orders: state.getIn(["companies", "reports", "monthlyReport", "orders"], fromJS({})).toJS(),
    monthly: state.getIn(["companies", "reports", "monthlyReport"]),
    isFetchingMonthlyReport: state.getIn(["fetch", "GET_COMPANY_MONTHLY_REPORT", "isFetching"]),
    activationReport: state.getIn(["companies", "reports", "activationReport"], fromJS([])).toJS(),
    isFetchingActivationReport: state.getIn(["fetch", "GET_MONTHLY_ACTIVATION_REPORT", "isFetching"])
  }),
  {
    fetchCompanyMonthlyReport,
    invalidateCompanyMonthlyReport,
    fetchCompanyActivationReport,
    invalidateCompanyActivationReport
  }
)(withDatePicker(CompanyMonthlyReport)({
  initStartDate: firstDayOfLastMonth(),
  initEndDate: lastDayOfLastMonth()
}))      
