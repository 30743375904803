import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { pipe } from "ramda";

const OrderTitle = ({ index, invoiceNumber }) => (
  <option value={index}>{invoiceNumber}</option>
);

OrderTitle.propTypes = {
  index: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.string
};

const getOrderInvoiceNumber = createSelector(
  [
    (state, orderId) => state.getIn(["entities", "orderRows", orderId]),
    (state, orderId) => state.getIn(["entities", "fulfillmentRows", orderId])
  ],
  (order, fulfillmentOrder) => {
    if (order)
      return pipe(({ invoice_number }) => `${invoice_number}`)(order.toJS());
    else if (fulfillmentOrder)
      return pipe(({ invoice_number }) => `${invoice_number}`)(
        fulfillmentOrder.toJS()
      );
    else return "";
  }
);

export default connect((state, { orderId }) => ({
  invoiceNumber: getOrderInvoiceNumber(state, orderId)
}))(OrderTitle);
