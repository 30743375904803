import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import * as R from "ramda";
import { compose } from "recompose";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withHeaderFooter
} from "features/query";
import Table from "components/ui/impl/table";
import IoMdCheckmark from "react-icons/lib/io/checkmark";

const formatDate = date => (date ? moment(date).format("YYYY / MM / DD") : "");

const InboundPatientTable = ({ setSelectedPatient, ...props }) => (
  <Table setSelectedPatient={setSelectedPatient} {...props} />
);
InboundPatientTable.propTypes = {
  setSelectedPatient: PropTypes.func.isRequired,
  callFromQueue: PropTypes.shape({
    to: PropTypes.string,
    from: PropTypes.string
  })
};

export default compose(
  connect((state, { setSelectedPatient, callFromQueue }) => ({
    initialQuery: {
      sort: {
        by: "ID",
        direction: "asc"
      },
      pagination: {
        per: 10,
        page: 1
      },
      filters: {
        DOB: [""],
        name: [""],
        account_number: [""],
        to_number: callFromQueue ? [callFromQueue.to] : [""],
        from_number: callFromQueue ? [callFromQueue.from] : [""]
      }
    },
    columns: R.filter(R.is(Object), [
      /* eslint-disable react/prop-types */
      {
        header: "Name",
        field: "last_name",
        format: ({ first_name, last_name, id }) => (
          <i className="clickable-link" onClick={() => setSelectedPatient(id)}>
            {first_name} {last_name}
          </i>
        )
      },
      {
        header: "Account #",
        field: "account_number"
      },
      {
        header: "Active",
        field: "active",
        format: ({ active }) => active && <IoMdCheckmark />,
        filters: [
          {
            text: "Active",
            value: "true"
          },
          {
            text: "Inactive",
            value: "false"
          }
        ]
      },
      {
        header: "Contact Type",
        field: "form_of_contact"
      },
      {
        header: "Insurance",
        field: "insurance_company.text",
        sort: "insurance_company"
      },
      {
        header: "Sleep Coach",
        field: "sleep_coach"
      },
      {
        header: "Company",
        field: "company"
      },
      {
        title: "Sort by eligibility date",
        header: "Eligibility Date",
        format: ({ dateEligibleForAutomation }) =>
          formatDate(dateEligibleForAutomation),
        field: "dateEligibleForAutomation"
      },
      {
        title: "Sort by follow-up type",
        header: "Follow-Up Type",
        field: "followupType"
      }
    ])
  })),
  withQuery({
    queryId: "inbound-patient-lookup"
  }),
  withApi({
    apiId: "inbound-patient-lookup",
    api: {
      route: "patients/possiblepatients",
      responseKey: "patients",
      idKey: "id",
      entityKey: "inboundPatientRows"
    }
  }),
  withHeaderFooter({
    // scrollable: true
  }),
  withTableHandlers()
)(InboundPatientTable);
