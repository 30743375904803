import React from "react";
import PropTypes from "prop-types";
import { resetPhilipsRecall } from "actions/patient";
import { useDispatch } from "react-redux";
import HasRolePermission from "components/shared/has-role-permission";

const PhilipsWorkflowReview = ({ patientInfo, patientId }) => {
  const dispatch = useDispatch();
  return (
    <div style={{ padding: "2rem", minHeight: "fit-content" }}>
      <div style={{ margin: "auto", width: "75%" }}>
        <p style={{ fontSize: "1.25rem" }}>
          {patientInfo.first_name} {patientInfo.last_name} has already worked
          through the Philips Recall workflow.
        </p>
        <p style={{ fontSize: "1.25rem" }}>
          {patientInfo.has_recalled_machine
            ? `${patientInfo.first_name} has an affected machine.`
            : `${patientInfo.first_name} does not have an affected machine.`}
        </p>
        <p style={{ fontSize: "1.25rem" }}>
          {patientInfo.has_recalled_machine
            ? patientInfo.accepts_phillips_recall_risk
              ? `${patientInfo.first_name} accepted the risk and chose to continue service.`
              : `${patientInfo.first_name} did not accept the risk and chose to discontinue service.`
            : `${patientInfo.first_name} was not read the disclaimer because they do not have an affected machine.`}
        </p>
        {patientInfo.has_recalled_machine ? (
          <p style={{ fontSize: "1.25rem" }}>
            Acknowledged On:{" "}
            {patientInfo.acknowledged_phillips_recall?.split("T")[0]}
          </p>
        ) : (
          ""
        )}
        <HasRolePermission allowedRoles={["Administrator"]}>
          <div>
            <p style={{ fontSize: "1.25rem" }}>
              As an admin, you may reset this patient&apos;s workflow. Do you
              wish to reset the workflow?
            </p>
            <div style={{ display: "inlineBlock", textAlign: "center" }}>
              <button
                onClick={e => {
                  e.preventDefault();
                  const confirmed = window.confirm(
                    "Are you sure? Resetting the workflow requires regathering the information from the patient. All records will be lost."
                  );
                  if (confirmed) dispatch(resetPhilipsRecall(patientId, true));
                }}
                style={{ backgroundColor: "darkRed" }}
              >
                Reset Philips Recall Workflow
              </button>
            </div>
          </div>
        </HasRolePermission>
      </div>
    </div>
  );
};

PhilipsWorkflowReview.propTypes = {
  patientInfo: PropTypes.object,
  patientId: PropTypes.string
};

export default PhilipsWorkflowReview;
