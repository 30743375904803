import React from "react";
import PropTypes from "prop-types";
import InlineWaiting from "components/ui/inline-waiting";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import * as R from "ramda";
import * as yup from "yup";
import { post, put } from "utils/api";
import moment from "moment";

const AnnouncementSchema = yup.object().shape({
  header: yup.string().required(),
  body: yup.string().required(),
  link: yup.string().url(),
  date: yup.date().required()
});

const postAnnouncement = values => {
  return post("announcements", {
    ...values,
    dateToDisplay: values.date,
    active: true
  });
};

const updateAnnouncement = (announcementId, updatedAnnouncement) => {
  return put(`announcements/${announcementId}`, updatedAnnouncement);
};

const AnnouncementsForm = ({
  closeModal,
  fetchAnnouncements,
  announcementToEdit = null
}) => {
  const makeValues = announcementToEdit => {
    return announcementToEdit
      ? {
          ...announcementToEdit,
          date: announcementToEdit.dateToDisplay
            ? moment(announcementToEdit.dateToDisplay).format("YYYY-MM-DD")
            : ""
        }
      : { header: "", body: "", link: "", date: "" };
  };
  return (
    <Formik
      initialValues={makeValues(announcementToEdit)}
      validationSchema={AnnouncementSchema}
      onSubmit={values => {
        if (announcementToEdit) {
          updateAnnouncement(values.id, values).then(() => {
            fetchAnnouncements();
            closeModal();
          });
        } else {
          postAnnouncement(values).then(() => {
            fetchAnnouncements();
            closeModal();
          });
        }
      }}
    >
      {({ isValid, isSubmitting, errors }) => (
        <Form>
          <div className="announcement-form">
            <h1> Announcements </h1>
            <div className="filter-selections">
              <FormikField
                name="header"
                label="Announcement Title"
                type="text"
              />
              <FormikField name="body" label="Body" as="textarea" />
              <FormikField name="link" label="Link" type="text" />
              <FormikField
                name="date"
                label="Date to Display on Announcement"
                type="date"
              />
            </div>
            {!isSubmitting ? (
              <button
                type="submit"
                className="button"
                disabled={!isValid}
                title={R.values(errors)}
              >
                Submit
              </button>
            ) : (
              <InlineWaiting />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AnnouncementsForm;

AnnouncementsForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fetchAnnouncements: PropTypes.func.isRequired,
  announcementToEdit: PropTypes.object.isRequired
};
