import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import CoachContactsTable from "./table";
// import CoachContactsGraph from "./graph";
// import MdShowChart from "react-icons/lib/md/show-chart";
// import MdViewHeadline from "react-icons/lib/md/view-headline";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const CoachContacts = ({ data, minimizeAll, dates }) => {
  // const [mode, setMode] = useState("table");
  const [isMinimized, setMinimized] = useState(false);
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  return (
    <div className="block coach-contacts">
      <div className="block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Coach Contacts
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
        {/* TODO: temporarly disable graph */}
        {/* <i */}
        {/*   style={{ alignSelf: "flex-end" }} */}
        {/*   className="flat-icon-button ripple" */}
        {/*   onClick={() => setMode(() => (mode === "table" ? "graph" : "table"))} */}
        {/* > */}
        {/*   {mode === "table" ? <MdShowChart /> : <MdViewHeadline />} */}
        {/* </i> */}
      </div>
      {!isMinimized && (
        <div className="block-content">
          <CoachContactsTable data={data} dates={dates} />
          {/*mode === "table" ? (
          ) : (
            <CoachContactsGraph data={data} />
          )*/}
        </div>
      )}
    </div>
  );
};

CoachContacts.propTypes = {
  dates: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool
};

export default connect(state => ({
  data: selectors.getTeamCoachInsights(state)
}))(CoachContacts);
