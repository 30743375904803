import React from "react";
import PropTypes from "prop-types";
import { PureModal } from "components/ui";
import * as R from "ramda";
import moment from "moment";
import { Link } from "react-router-dom";
import MaskFittingDetails from "./mask-fitting-details";

const formatDate = date => (date ? moment(date).format("YYYY / MM / DD") : "");

const AutofitTableRow = props => {
  const {
    status,
    first_name,
    last_name,
    patient_id,
    company,
    date_created,
    masks
  } = props;
  return (
    <tr>
      <td>
        <div className="coach-controls-dropdown">
          <PureModal
            renderTrigger={({ openModal }) => (
              <button
                onClick={openModal}
                style={{ padding: "5px", margin: 0 }}
                className="button secondary"
              >
                Details
              </button>
            )}
          >
            <MaskFittingDetails {...props} />
          </PureModal>
        </div>
      </td>
      <td>
        {patient_id ? (
          <Link to={`/patients/${patient_id}`}>
            {first_name} {last_name}
          </Link>
        ) : (
          <div>
            {first_name} {last_name}
          </div>
        )}
      </td>
      <td>{company}</td>
      <td>{status}</td>
      <td>{formatDate(date_created)}</td>
      <td>
        <div style={{ display: "flex" }}>
          <span>
            {R.pipe(
              R.ifElse(
                () => !patient_id,
                R.nth(0),
                R.find(({ selected }) => selected === true)
              ),
              R.propOr("", "name")
            )(masks)}
          </span>
        </div>
      </td>
    </tr>
  );
};

AutofitTableRow.propTypes = {
  status: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  patient_id: PropTypes.string,
  company: PropTypes.string.isRequired,
  date_created: PropTypes.string.isRequired,
  date_completed: PropTypes.string,
  date_submitted: PropTypes.string,
  masks: PropTypes.arrayOf(PropTypes.object),
  question_answers: PropTypes.arrayOf(PropTypes.object),
  scan_now: PropTypes.bool,
  scan_now_token: PropTypes.string,
  transaction_id: PropTypes.string
};

export default AutofitTableRow;
