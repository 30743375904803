import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { dismissMessage } from "actions/message";
import { selectors } from "reducers";
import Message from "./message";

const SystemNotification = ({ notifications, dismissMessage }) => (
  <div className="notifications-container no-print">
    {notifications.map(msg => (
      <Message key={msg.id} {...msg} dismiss={() => dismissMessage(msg.id)} />
    ))}
  </div>
);

export default connect(
  state => ({
    notifications: selectors.getNotifications(state)
  }),
  { dismissMessage }
)(SystemNotification);

SystemNotification.propTypes = {
  notifications: PropTypes.array.isRequired,
  dismissMessage: PropTypes.func.isRequired
};
