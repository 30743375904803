import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, setPropTypes, withState, withHandlers } from "recompose";
import { reduxForm, getFormValues } from "redux-form";
import { message, errorMessage } from "actions/message";
import { VariableFieldSet, InlineWaiting } from "components/ui";
import {
  OrderStatusSelect,
  AdminSelect,
  FulfillmentSelect,
  CoachSelect
} from "./fields";
import { selectors } from "reducers";
import { put } from "utils/api";

const form = "bulkOrderUpdate";
const getFormState = state => state.get("form");
const BulkOrderUpdate = compose(
  setPropTypes({
    orderIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onUpdateSuccess: PropTypes.func,
    onUpdateError: PropTypes.func
  }),
  reduxForm({
    form,
    getFormState
  }),
  connect(
    (state, { invalid }) => {
      const formValues = getFormValues(form, getFormState)(state);
      const userRole = selectors.getUserRole(state);
      const fieldOptions = FIELD_OPTIONS.filter(({ allowedRoles }) =>
        allowedRoles.includes(userRole)
      );
      return {
        invalid:
          invalid || (formValues ? !formValues.orderFields.length : true),
        userRole: selectors.getUserRole(state),
        fieldOptions
      };
    },
    { message, errorMessage }
  ),
  withState("isFetching", "setIsFetching", false),
  withHandlers({
    handleBulkUpdateSubmit: ({
      handleSubmit,
      orderIds,
      setIsFetching,
      onUpdateSuccess,
      onUpdateError,
      message,
      errorMessage
    }) =>
      handleSubmit(async ({ orderFields }) => {
        try {
          const update = orderFields.reduce(
            (acc, cur) =>
              typeof cur.value === "object"
                ? { ...acc, ...cur.value }
                : { ...acc, [cur.field]: cur.value },
            {}
          );
          setIsFetching(true);
          const response = await put("orders/bulk", {
            ids: orderIds,
            update
          });
          message("Orders updated successfully!");
          setIsFetching(false);
          if (onUpdateSuccess) {
            onUpdateSuccess(response);
          }
        } catch (error) {
          errorMessage("An error occurred while updating your orders.");
          setIsFetching(false);
          if (onUpdateError) {
            onUpdateError(error);
          }
        }
      })
  })
)(({ handleBulkUpdateSubmit, invalid, isFetching, fieldOptions }) => (
  <form onSubmit={handleBulkUpdateSubmit} className="bulk-order-update">
    <VariableFieldSet name="orderFields" fieldOptions={fieldOptions} />
    <button
      type="submit"
      disabled={invalid || isFetching}
      className="bulk-order-submit"
    >
      {isFetching ? <InlineWaiting /> : "Submit"}
    </button>
  </form>
));

const TextInput = ({ input, maxLength }) => (
  <input style={{ padding: "0.5rem" }} {...input} maxLength={maxLength} />
);

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  maxLength: PropTypes.number
};

const TextareaInput = ({ input }) => <textarea {...input} />;
TextareaInput.propTypes = {
  input: PropTypes.object.isRequired
};

const validateNoValue = errorMessage => val => val ? undefined : errorMessage;

const FIELD_OPTIONS = [
  {
    field: "status",
    text: "Status",
    defaultValue: {
      status: "",
      rejected_reason: ""
    },
    fieldProps: {
      component: props => (
        <OrderStatusSelect
          updateOptionsOnly={true}
          addBlankOption={true}
          {...props}
        />
      ),
      validate: (value = {}) => {
        const { status = "", rejected_reason = "" } = value;
        return (
          validateNoValue("Please select a status.")(status) ||
          (status === "Rejected" &&
            validateNoValue("Rejected Reason required")(rejected_reason))
        );
      }
    },
    allowedRoles: [
      "OfficeAdministrator",
      "CompanyAdministrator",
      "Administrator",
      "ServiceAdmin",
      "ServiceCoach",
      'ContractorAdministrator'
    ]
  },
  {
    field: "sales_order_number",
    text: "Sales Order Number",
    defaultValue: "",
    fieldProps: {
      component: TextInput,
      maxLength: 70,
      validate: validateNoValue("Please supply a sales order number.")
    },
    allowedRoles: [
      "OfficeAdministrator",
      "CompanyAdministrator",
      "Administrator",
      "ServiceAdmin",
      "ServiceCoach",
      'ContractorAdministrator'
    ]
  },
  {
    field: "fulfillment_type",
    text: "Fulfillment Type",
    defaultValue: "",
    fieldProps: {
      component: props => (
        <FulfillmentSelect addBlankOption={true} {...props} />
      ),
      validate: validateNoValue("Please select a fulfillment type.")
    },
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    field: "note",
    text: "Note",
    defaultValue: "",
    fieldProps: {
      component: TextareaInput,
      validate: validateNoValue("Please supply a note.")
    },
    allowedRoles: [
      "Administrator",
      "OfficeAdministrator",
      "CompanyAdministrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach",
      'ContractorAdministrator'
    ]
  },
  {
    field: "claimed_by",
    text: "Claimed By",
    defaultValue: "",
    fieldProps: {
      component: props => <AdminSelect addBlankOption={true} {...props} />,
      validate: validateNoValue("Please select an admin.")
    },
    allowedRoles: ["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    field: "assigned_coach",
    text: "Coach Assignment",
    defaultValue: "",
    fieldProps: {
      component: props => <CoachSelect addBlankOption={true} {...props} />,
      validate: validateNoValue("Please select a coach.")
    },
    allowedRoles: ["Administrator"]
  },
  {
    field: "pending_info",
    text: "Pending Info",
    defaultValue: "",
    fieldProps: {
      component: TextInput,
      validate: validateNoValue("Please supply pending info.")
    },
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      'ContractorAdministrator'
    ]
  }
];

export default BulkOrderUpdate;
