import React from "react";
import { ConfirmationDialog } from "components/ui";
import { useDispatch } from "react-redux";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import { PropTypes } from 'prop-types';

const CancelAppointmentConfirmation = ({ setIsSubmitting, chat_id, onSuccess: successFunction, closeModal, slot_id, patientsToBeNotified }) => {
    const dispatch = useDispatch()
    const {
        fetchData: fetchCancelAppointment,
        isFetching
    } = useFetch({
        apiFn: (id) => put(`dme_portal/upcoming_chats/${id}/cancel`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            dispatch(errorMessage(`Failed to cancel upcoming chat: ${getApiErrorMessage(error)}`));
        },
        onSuccess: () => {
            if(typeof successFunction == 'function'){
                successFunction()
            }
        }
    })

    const submit = async () => {
        if(typeof setIsSubmitting === 'function'){
            setIsSubmitting(true)
        }

        var appointmentToCancel = slot_id ? slot_id : chat_id
        await fetchCancelAppointment(appointmentToCancel);

        if(typeof setIsSubmitting === 'function'){
            setIsSubmitting(false)
        }
        if(typeof closeModal === 'function'){
            closeModal();
        }
    }

    return  (
        <ConfirmationDialog
            cancelButtonStyles="appointment-action-button cancel-action-button"
            confirmButtonStyles="appointment-action-button reschedule-button"
            continueMsg="Yes"
            cancelMsg="No"
            onCancel={closeModal}
            onConfirm={() => submit() }
            isFetching={isFetching}
        >
            <div>
                <h3>Are you sure you want to cancel the appointment?</h3>
                <h3>{patientsToBeNotified} patient/s will be notified.</h3>
            </div>
        </ConfirmationDialog>
    )
}

CancelAppointmentConfirmation.propTypes = {
    setIsSubmitting: PropTypes.func,
    chat_id: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    slot_id: PropTypes.string,
    patientsToBeNotified: PropTypes.string
};

export default CancelAppointmentConfirmation;
