import React from "react";
import Barcode from "react-barcode";
import PropTypes from "prop-types";

const transformSalesOrderNumber = orderSalesNumber => {
  const salesordernumberbyidstring = orderSalesNumber;
  const salesordernumberarray = salesordernumberbyidstring
    ? salesordernumberbyidstring.split(",")
    : "";
  return salesordernumberarray
    ? salesordernumberarray[0]
    : salesordernumberbyidstring;
};
const BarcodeDisplay = ({ orderSalesNumber }) => {
  const salesOrderNumber = transformSalesOrderNumber(orderSalesNumber);
  return salesOrderNumber == null || salesOrderNumber.trim() == "" ? (
    <div />
  ) : (
    <div>
      <h3>Sales Order Number</h3>
      <Barcode
        value={salesOrderNumber}
        lineColor="#000000"
        height={50}
        width={1}
      />
    </div>
  );
};

BarcodeDisplay.propTypes = {
  orderSalesNumber: PropTypes.string
};

export default BarcodeDisplay;
