import React from "react";
import { loginWithAzureADToken } from "actions/authentication";
import { useDispatch } from "react-redux";
import { InlineWaiting } from "components/ui";
import { PublicClientApplication } from "@azure/msal-browser";
import { AZURE_CLIENT_ID } from "environment";

export const getAzureClientIdFromUrl = () => {
  if (
    window.location.href
      .toLowerCase()
      .includes("atlantichome.sleepsolutionsandservices.com")
  ) {
    return "86bcdb64-496f-4b7a-9f87-b1a7d1fdcf19";
  }
  if (AZURE_CLIENT_ID) return AZURE_CLIENT_ID;
  return "";
};

const tenantId = "f6f442be-a6a0-4cbe-bc32-1f76a10f316b";

export const msalConfig = {
  auth: {
    clientId: getAzureClientIdFromUrl(),
    redirectUri: window.location.origin,
    authority: `https://login.microsoftonline.com/${tenantId}`
    // authority: "https://login.microsoftonline.com/common",
  }
};

const msalInstance = msalConfig.auth.clientId
  ? new PublicClientApplication(msalConfig)
  : null;

const AzureADLogin = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = React.useState(false);
  if (!msalInstance) return null;
  return (
    <div>
      {isFetching ? (
        <InlineWaiting />
      ) : (
        <button
          type="button"
          onClick={async () => {
            try {
              setIsFetching(true);
              const loginResponse = await msalInstance.loginPopup();
              console.log(loginResponse);
              console.log(loginResponse.account.username);
              console.log(loginResponse.tenantId);
              console.log(loginResponse);
              await dispatch(
                loginWithAzureADToken({
                  idToken: loginResponse.idToken,
                  tenantId: loginResponse.tenantId,
                  email: loginResponse.account.username
                })
              );
            } catch (e) {
              console.error(e);
            } finally {
              setIsFetching(false);
            }
          }}
        >
          Login with Office365
        </button>
      )}
    </div>
  );
};

export default AzureADLogin;
