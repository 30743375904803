import React from "react";
import PropTypes from "prop-types";
import MdFolder from "react-icons/lib/md/folder";

const FolderCard = ({ folder, onClick }) => (
  <div className="file-card" onClick={onClick}>
    <div className="card-wrapper" onClick={onClick}>
      <span className="file-name">
        <span style={{ padding: "0 10px" }}>
          <MdFolder size={26} />
        </span>
        {folder.name}
      </span>
    </div>
  </div>
);

FolderCard.propTypes = {
  folder: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default FolderCard;
