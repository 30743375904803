import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting, IconButton, PureModal } from "components/ui";
import { IoAndroidRefresh } from "react-icons/lib/io";
import { MdUnfoldMore, MdUnfoldLess, MdAdd, MdList } from "react-icons/lib/md";
import HcpcRow from "./hcpc-row";
import AddHcpcHistoryRecord from "./add-hcpc-history-record";
import PatientExcludedHCPCS, {
  usePatientExcludedHCPCS
} from "./excluded-hcpcs";
import { PatientProfileContext } from "components/profile/context";

const HcpcHistory = ({ patientId }) => {
  const { fetchPatientHCPCHistoryAPI } = React.useContext(
    PatientProfileContext
  );

  const [expandedRows, setExpandedRows] = React.useState({});
  const [editRow, setEditRow] = React.useState(null);

  const toggleExpanded = productType => {
    const current = expandedRows[productType] ?? false;
    setExpandedRows({ ...expandedRows, [productType]: !current });
    setEditRow(null);
  };

  const allExpanded =
    !Object.values(expandedRows).some(x => x === false) ||
    Object.values(expandedRows).length === 0;

  React.useEffect(() => {
    setEditRow(null);
  }, [expandedRows, patientId]);

  const orderHistory = fetchPatientHCPCHistoryAPI.response?.eligiblity ?? [];

  const excludedPatientHCPCInfo = usePatientExcludedHCPCS(patientId);

  return (
    <div className="patient-hcpc-history-tab">
      {fetchPatientHCPCHistoryAPI.isFetching ? (
        <div
          style={{
            minHeight: 300,
            display: "flex",
            flex: 1,
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          <InlineWaiting />
        </div>
      ) : (
        <div>
          <div className="eligibility-table-header">
            <div>Product Type</div>
            <div>Eligibility Date</div>
            <div>Last Ordered Date</div>
            <div>Last Ordered Item</div>
            <div>Last Ordered Item ID</div>
            <div>Insurance Rule(days|qty|offset)</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between"
              }}
            >
              <IconButton
                icon={
                  allExpanded ? (
                    <MdUnfoldLess size={18} />
                  ) : (
                    <MdUnfoldMore size={18} />
                  )
                }
                onClick={() => {
                  setEditRow(null);
                  if (allExpanded) {
                    setExpandedRows(
                      orderHistory.reduce(
                        (acc, x) => ({ ...acc, [x.productType]: false }),
                        {}
                      )
                    );
                  } else {
                    setExpandedRows(
                      orderHistory.reduce(
                        (acc, x) => ({ ...acc, [x.productType]: true }),
                        {}
                      )
                    );
                  }
                }}
              />
              <IconButton
                icon={<IoAndroidRefresh size={18} />}
                disabled={fetchPatientHCPCHistoryAPI.isFetching}
                onClick={() => {
                  fetchPatientHCPCHistoryAPI.fetchData(patientId);
                  // dispatch(invalidatePatientOrderHistory({ patientId }));
                  // dispatch(fetchPatientOrderHistory({ patientId }));
                }}
              />
            </div>
          </div>
          {orderHistory
            ?.filter(x => x.productType !== "Mask with Headgear")
            .map(x => (
              <HcpcRow
                key={x.productType}
                eligiblity={x}
                toggleExpanded={() => toggleExpanded(x.productType)}
                expanded={expandedRows[x.productType]}
                editRow={editRow}
                setEditRow={setEditRow}
                patientId={patientId}
              />
            ))}
        </div>
      )}

      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: 20
        }}
      >
        <PureModal
          renderTrigger={({ openModal }) => (
            <IconButton
              icon={<MdList />}
              text={`Excluded HCPCS (${excludedPatientHCPCInfo.excludedHcpcsCount})`}
              onClick={e => {
                e.stopPropagation();
                openModal();
              }}
              style={{ fontSize: "14px", float: "right" }}
            />
          )}
        >
          <PatientExcludedHCPCS
            patientId={patientId}
            {...excludedPatientHCPCInfo}
          />
        </PureModal>
        <PureModal
          renderTrigger={({ openModal }) => (
            <IconButton
              icon={<MdAdd />}
              text="Add Record"
              onClick={e => {
                e.stopPropagation();
                openModal();
              }}
              style={{ fontSize: "14px", float: "right" }}
            />
          )}
          renderContent={({ closeModal }) => (
            <AddHcpcHistoryRecord
              patientId={patientId}
              onSuccess={async () => {
                await fetchPatientHCPCHistoryAPI.fetchData(patientId);
                closeModal();
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

HcpcHistory.propTypes = {
  patientId: PropTypes.string.isRequired
};

export default HcpcHistory;
