import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.pages = this.pages.bind(this);
    this.pageCount = this.pageCount.bind(this);
  }

  pages() {
    let maxPages = this.pageCount();
    let newArr = [];
    for (let i = 1; i <= maxPages; i++) {
      newArr.push(i);
    }
    return newArr;
  }

  pageCount() {
    return Math.ceil(this.props.count / this.props.per);
  }

  nextPage() {
    this.props.action({ page: this.props.page + 1, per: this.props.per });
  }

  prevPage() {
    this.props.action({ page: this.props.page - 1, per: this.props.per });
  }

  setPage(e) {
    this.props.action({ page: e.target.value, per: this.props.per });
  }

  render() {
    let currentPage = this.props.page;
    return (
      <div style={styles.container}>
        {currentPage > 1 &&
          <button style={styles.button} onClick={this.prevPage.bind(this)}>
            Previous
          </button>}

        <label style={styles.label}>
          <select
            style={styles.select}
            value={currentPage}
            onChange={this.setPage.bind(this)}
          >
            {this.pages().map((page, i) => {
              return <option value={page} key={i}>{page}</option>;
            })}
          </select> <i>of {isNaN(this.pageCount()) ? "0" : this.pageCount()}</i>
        </label>

        {currentPage < this.pageCount() &&
          <button style={styles.button} onClick={this.nextPage.bind(this)}>
            Next
          </button>}
      </div>
    );
  }
}

Pagination.propTypes = {
  per: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired
};

export default Radium(Pagination);

var styles = {
  container: {
    textAlign: "center",
    backgroundColor: "#EEE",
    paddingTop: "14px",
    clear: "both",
    margin: "0 10px"
  },
  select: {
    width: "45px"
  },
  label: {
    display: "inline-block",
    margin: "0 15px"
  },
  button: {
    padding: "0",
    background: "transparent",
    color: "#333"
  }
};
