import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Dropdown } from "components/ui";
import { updateCoachSettings } from "actions/user-profile";
import MdMoreVert from "react-icons/lib/md/more-vert";
import ToggleSwitch from "components/ui/toggle-switch";


const CoachControls = ({
  coach_guid,
  outboundCallRestricted,
  updateCoachSettings,
  block_autodialer_incoming_calls
}) => {
  const [isUpdating, setIsUpdating] = React.useState(false);

  return (
    <td>
      <div className="coach-controls-dropdown">
        <Dropdown
          trigger={
            <span className="coach-controls-menu">
              <MdMoreVert />
            </span>
          }
          style={{ margin: 0, width: 210 }}
        >
          <ul className="dropdown-list">
            <li className="dropdown-item">
              <ToggleSwitch
                value={outboundCallRestricted}
                title="Must answer inbound calls before making an outbound call"
                label="Outbound Call Restricted"
                onChange={async () => {
                  try {
                    setIsUpdating("outboundCallRestricted");
                    await updateCoachSettings(coach_guid, {
                      outboundCallRestricted: !outboundCallRestricted
                    });
                  } finally {
                    setIsUpdating(false);
                  }
                }}
                isUpdating={isUpdating === "outboundCallRestricted"}
              />
            </li>
            <li className="dropdown-item">
              <ToggleSwitch
                label="Dialer Answer Inbound Calls"
                title="Auto Dialer automatically answers inbound calls"
                value={!block_autodialer_incoming_calls}
                isUpdating={isUpdating === "block_autodialer_incoming_calls"}
                onChange={async () => {
                  try {
                    setIsUpdating("block_autodialer_incoming_calls");
                    await updateCoachSettings(coach_guid, {
                      block_autodialer_incoming_calls: !block_autodialer_incoming_calls
                    });
                  } finally {
                    setIsUpdating(false);
                  }
                }}
              />
            </li>
          </ul>
        </Dropdown>
      </div>
    </td>
  );
};

CoachControls.propTypes = {
  coach_guid: PropTypes.string.isRequired,
  outboundCallRestricted: PropTypes.bool,
  updateCoachSettings: PropTypes.func.isRequired,
  block_autodialer_incoming_calls: PropTypes.bool
};

export default connect(
  (state, { coach_guid }) => ({
    outboundCallRestricted: selectors.getUserIsOutboundRestricted(
      state,
      coach_guid
    ),
    block_autodialer_incoming_calls: selectors.getUserDialerInboundBlocked(
      state,
      coach_guid
    )
  }),
  {
    updateCoachSettings
  }
)(CoachControls);
