import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import range from "lodash/range";

export const TimeDisplay = ({ data, dates }) => {
  const [days, setDays] = useState(1);
  const [sections, setSections] = useState(24);
  const [columnWidth, setColumnWidth] = useState("4.1666666%");
  const [minuteRepresentation, setMinuteRepresentation] = useState(
    100 / (24 * 60) / days
  );

  const getNumberOfDays = () => {
    const start = moment(dates.start_date);
    const end = moment(dates.end_date);

    const numberOfDays = end.diff(start, "days") + 1;
    setDays(numberOfDays);
    setSections(numberOfDays * 24);
  };
  useEffect(() => {
    getNumberOfDays();
    setMinuteRepresentation(100 / (24 * 60) / days);
  }, [days]);
  const calculateColumnWidth = () => {
    const width = minuteRepresentation * 60 + "%";
    setColumnWidth(width);
  };

  const getComponentWidth = time => {
    const width = moment.duration(time).asMinutes() * minuteRepresentation;
    return width + "%";
  };

  const getComponentStart = time => {
    const startOf = moment(time).diff(data[0].startTime, "minutes");
    const percentageLeft = startOf * minuteRepresentation;
    return percentageLeft + "%";
  };

  useEffect(() => {
    getNumberOfDays();
    calculateColumnWidth();
  }, []);

  return (
    <>
      <div className="hour-list">
        {range(sections).map((s, index) => {
          if (days < 4) {
            return (
              <div className="hour" style={{ width: columnWidth }} key={index}>
                {((s + 11) % 12) + 1}
              </div>
            );
          } else if (days > 3 && days <= 7) {
            if (((s + 11) % 12) + 1 === 12 || ((s + 11) % 12) + 1 === 6) {
              return (
                <div
                  className="hour"
                  style={{ width: columnWidth }}
                  key={index}
                >
                  {((s + 11) % 12) + 1}
                </div>
              );
            } else {
              return (
                <div
                  className="hour"
                  style={{ width: columnWidth }}
                  key={index}
                ></div>
              );
            }
          }
        })}
      </div>
      <div className="time-display-container">
        {range(sections).map((s, index) => {
          return (
            <>
              <div
                style={{ width: columnWidth, height: "100%" }}
                key={index}
                className={
                  (index + 1) % 2 === 0
                    ? "time-display-section"
                    : "time-display-section-odd"
                }
              ></div>
            </>
          );
        })}
        {data[0].connectionData.map((cd, index) => {
          return (
            <div
              className="connection-session timetooltip"
              style={{
                width: getComponentWidth(cd.totalTime),
                left: getComponentStart(cd.start)
              }}
              key={index}
            >
              <span className="timetooltiptext">
                {moment(cd.start).format("LT") +
                  " - " +
                  moment(cd?.end ?? moment()).format("LT") +
                  " " +
                  moment(cd.start).format("MM/DD")}
              </span>
            </div>
          );
        })}

        {data[0].autodialerData.map((cd, index) => {
          return (
            <div
              className="autodialer-session timetooltip"
              style={{
                width: getComponentWidth(cd.totalTime),
                left: getComponentStart(cd.start)
              }}
              key={index}
            >
              <span className="timetooltiptext">
                {moment(cd.start).format("LT") +
                  " - " +
                  moment(cd?.end ?? moment()).format("LT") +
                  " " +
                  moment(cd.start).format("MM/DD")}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

TimeDisplay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dates: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  })
};
