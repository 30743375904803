import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Video from 'twilio-video';
import Participant from './Participant';

const Room = ({ roomName, token, handleLogout, fetchError, isRemoteVisible, members }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [isParticipantDisconnected, setParticipantDisconnected] = useState(false);

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
      setParticipantDisconnected(false);
    };

    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );

      setParticipantDisconnected(true);
    };

    Video.connect(token, {
      name: roomName
      //logLevel: "debug"
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    }).catch((error) => {
      if(error == "Permission denied")
        fetchError("Browser could not access any video or audio devices");
      else
        fetchError(error.message);
    });

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const remoteParticipants = participants.map(participant => (
    <Participant members={members} key={participant.sid} participant={participant} />
  ));

  return (
    <React.Fragment>
      {isRemoteVisible ? <div className="room">
        <div className="dd_row">
          <div className="dd_col-12">
            <button onClick={handleLogout}>Leave Room</button>
            <br />
            <br />
            <br />
          </div>
          </div>
          <div className="dd_row">
          <div className="dd_col-6">
            <div className="local-participant">
              {room ? (
                <Participant
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                  members={members}
                />
              ) : (
                  ''
                )}
            </div>
          </div>
          <div className="dd_col-6">
            {isRemoteVisible && participants && participants.length === 0 && !isParticipantDisconnected ? <div className="participant">
              <h3>Please wait untill participant get connected</h3></div> :
              <React.Fragment>
                <div className="remote-participants">{remoteParticipants}</div>
              </React.Fragment>
            }

            {isRemoteVisible && participants && participants.length === 0 && isParticipantDisconnected && 
              <div className="participant"><h3>Participant Disconnected</h3></div>
            }
          </div>
        </div>
      </div> :
        <div className="room">
          <div className="dd_col-12">
            <button onClick={handleLogout}>Leave Room</button>
            <br />
            <br />
            <br />
          </div>
          <div className="dd_col-6">
            <div className="local-participant">
              {room ? (
                <Participant
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                  members={members}
                />
              ) : (
                  ''
                )}
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Room;

Room.propTypes = {
  roomName: PropTypes.string,
  token: PropTypes.string,
  handleLogout: PropTypes.func,
  fetchError: PropTypes.string,
  isRemoteVisible: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.object)
};
