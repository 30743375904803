import React from "react";
import PropTypes from "prop-types";

const QuestionCard = ({ question, index }) => {

    const checkMultipleAnswers = (label, text) => {
        //if(text == null) return text

        const hasMultipleOptions = text?.indexOf("|") != -1
        if(!hasMultipleOptions || text == null){
            return text
        } else {
            const options = text.split("|").map((option,i) => (
                <>
                    <br/>
                    <span key={i} style={{ marginBottom: 0, paddingLeft:"1.5em"}}>{`${label} ${i+1})  ${option}`}</span>
                </>
            ))
            
            return options;
        }
       
    }

    const checkMultipleTasks = (label, text) => {
        //if(text == null) return label

        const hasMultipleOptions = text?.indexOf("|") != -1
        if(!hasMultipleOptions || text == null){
            return <p style={{ margin: 0, paddingLeft:"1.5em"}}>{label}: {text}</p>
        } else {
            const options = text.split("|").map((option,i) => (
                <>
                    <span key={i} style={{ paddingLeft:"1.5em"}}>{label} {" "} {i+1} : {option}</span>
                    <br/>
                </>   
            ))
            
            return options;
        }

    }
    return (
        <div className="info-div"
            key={`question-${index}`}
            style={{ border: "1px solid #ccc", padding: 5, marginBottom: 10 }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto auto",
                    flex: 1,
                    justifyContent: "space-between",
                    fontSize: 18
                }}
            >
                <div style={{ marginBottom: 5 }}>
                    <div style={{display: "flex"}}>
                        <h6>Question {index + 1}{") "} {question.Text}</h6>
                        {
                            question.WasRequired ?
                            <h6 style={{color: "red", marginLeft: "5px"}}>*</h6>
                            :
                            null
                        }
                    </div>
                    
                    <div
                        style={{
                            display: "flex"
                        }}
                        >
                        {
                            question.MarkedAsNA ?
                            <h6 style={{ color: "gray", fontWeight: "bold", marginLeft: "5px"}}>N/A</h6>
                            :
                            <h6 style={{ color: "black", fontWeight: "bold"}}>Answer: {checkMultipleAnswers("",question.AnsweredValue)}</h6>
                        }
                    </div>
                </div>

            </div>
            {question.TaskActionText != null && question.TaskActionText.length > 0 &&
                <div>
                    <h6>Generated task:</h6>
                    <div>
                        {checkMultipleTasks("Action", question.TaskActionText)}
                        {checkMultipleTasks("Status", question.TaskStatus)}
                        {checkMultipleTasks("Assigned to", question.AssignedTo)}
                    </div>
                </div>
            }
        </div>
    )
}

export default QuestionCard;


QuestionCard.propTypes = {
    question: PropTypes.object,
    index: PropTypes.number,
};