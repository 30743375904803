import { useState, useCallback, useEffect } from "react";

const initialState = {
  x: null,
  y: null,
  elementWidth: null,
  elementHeight: null
};

function useMouseEvents() {
  const [state, setState] = useState(initialState);
  const [entered, setEntered] = useState(false);
  const [active, setActive] = useState(false);
  const [element, setElement] = useState(null);
  const onMoveCallback = useCallback(
    event => {
      const { pageX = 0, pageY = 0 } = event;
      const rect = element.getBoundingClientRect();
      const x = pageX - rect.left - (window.pageXOffset || window.scrollX);
      const y = pageY - rect.top - (window.pageYOffset || window.scrollY);
      setState(() => ({
        x,
        y,
        elementWidth: rect.width,
        elementHeight: rect.height
      }));
    },
    [element]
  );

  useEffect(() => {
    if (element !== null) {
      // TODO look into react batch updates or throttling for better performance
      const onMove = e => {
        setEntered(true);
        onMoveCallback(e);
      };
      const onLeave = () => setEntered(false);
      const addEvent = element.addEventListener.bind(element);
      addEvent("mouseenter", onMove);
      addEvent("mousemove", onMove);
      addEvent("mouseleave", onLeave);

      return () => {
        const removeEvent = element.removeEventListener.bind(element);
        removeEvent("mouseenter", onMove);
        removeEvent("mousemove", onMove);
        removeEvent("mouseleave", onLeave);
      };
    }
  }, [element, onMoveCallback]);

  useEffect(() => {
    if (entered) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [entered]);

  return [active ? state : initialState, setElement];
}

export default useMouseEvents;
