import { combineReducers } from "redux";
import { createReducer } from "utils/reducer";
import { Types } from "actions/phone-dashboard";
import { createSelector } from "reselect";
import * as R from "ramda";

const callSnapshot = createReducer(
  {},
  {
    [Types.REQUEST_CALL_SNAPSHOT]: () => ({}),
    [Types.CALL_SNAPSHOT_RESPONSE]: (_, { payload }) => payload,
    [Types.CALL_SNAPSHOT_FAILED]: () => ({})
  }
);

export default combineReducers({ callSnapshot });

const getCallSnapshots = createSelector(
  [state => state.getIn(["phone-dashboard"])],
  R.pathOr([], ["callSnapshot", "time_grouped_statistics_list"])
);

export const selectors = {
  getCallSnapshots
};
