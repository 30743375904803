import React from "react";
import PropTypes from "prop-types";
import StatsListItem from "./stats_list_item";
import {
  ResponsiveContainer,
  LabelList,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const OrdersCompleted = ({ data }) => (
  <div
    className="orders-completed"
    style={{ display: "grid", gridTemplateColumns: "minmax(250px, 350px) 1fr" }}
  >
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <h2 style={{ fontSize: 18, marginBottom: 20 }}>Completed Orders Stats</h2>
      {data && (
        <>
          <ul>
            <StatsListItem
              title="Total Completed Orders"
              value={data.get("completed_orders")}
            />
            <StatsListItem
              title="Total Line Items"
              value={data.get("total_line_items")}
            />
            <StatsListItem
              title="Average Items Per Order"
              value={data.get("avg_items_per_order")}
            />
          </ul>
          <hr />
          <ul>
            <StatsListItem
              title="Masks(with headgear) Sold"
              value={data.get("masks_with_headgear_sold")}
            />
            <StatsListItem title="Masks Sold" value={data.get("masks_sold")} />
            <StatsListItem title="Seals Sold" value={data.get("seals_sold")} />
            <StatsListItem title="Tubing" value={data.get("tubing_sold")} />
            <StatsListItem
              title="Disposable Filters Sold"
              value={data.get("disp_filters_sold")}
            />
            <StatsListItem
              title="Non-Disposable Filters Sold"
              value={data.get("non_disp_filters_sold")}
            />
            <StatsListItem
              title="Chinstraps Sold"
              value={data.get("chinstraps_sold")}
            />
            <StatsListItem
              title="Waterchambers Sold"
              value={data.get("waterchambers_sold")}
            />
            <StatsListItem
              title="Miscellaneous"
              value={data.get("misc_sold")}
            />
          </ul>
        </>
      )}
    </div>
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {data && (
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            data={[
              {
                label: "Masks(with headgear) Sold",
                count: data.get("masks_with_headgear_sold")
              },
              { label: "Masks Sold", count: data.get("masks_sold") },
              { label: "Seals Sold", count: data.get("seals_sold") },
              { label: "Tubing", count: data.get("tubing_sold") },
              {
                label: "Disp-Filters Sold",
                count: data.get("disp_filters_sold")
              },
              {
                label: "Non-Disp-Filters Sold",
                count: data.get("non_disp_filters_sold")
              },
              {
                label: "Chinstraps Sold",
                count: data.get("chinstraps_sold")
              },
              {
                label: "Waterchambers Sold",
                count: data.get("waterchambers_sold")
              },
              { label: "Miscellaneous", count: data.get("misc_sold") }
            ]}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis
              type="number"
              dataKey="count"
              domain={[0, dataMax => dataMax * 1.5]}
              scale="sqrt"
            />
            <XAxis dataKey="label" type="category" />
            <Bar
              dataKey="count"
              fill="rgba(45, 198, 184, 0.89)"
              isAnimationActive={false}
            >
              <LabelList dataKey="count" position="top" />
            </Bar>
            <Tooltip cursor={false} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  </div>
);

OrdersCompleted.propTypes = {
  data: PropTypes.object.isRequired
};

export default OrdersCompleted;
