import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MachineBreakdown from "./machine-breakdown";
import ManufacturerBreakdown from "./manufacturer-breakdown";

const PatientMachineOverview = ({ patientMachineCount }) => {
  const [selectedManufacturer, setSelectedManufacturer] = useState();
  useEffect(() => {
    setSelectedManufacturer();
  }, [patientMachineCount]);
  return (
    <div className="interactive-graph-container">
      {!selectedManufacturer ? (
        <MachineBreakdown
          data={patientMachineCount}
          setSelectedManufacturer={setSelectedManufacturer}
        />
      ) : (
        <ManufacturerBreakdown
          data={selectedManufacturer.Items}
          setSelectedManufacturer={setSelectedManufacturer}
          selectedManufacturer={selectedManufacturer}
        />
      )}
    </div>
  );
};

PatientMachineOverview.propTypes = {
  patientMaskCount: PropTypes.array.isRequired
};

export default React.memo(PatientMachineOverview);

PatientMachineOverview.propTypes = {
  patientMachineCount: PropTypes.array.isRequired
};
