import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "react-select/dist/react-select.css";

const AutoCompleteSelect = ({ value, label, options, onChange, isMulti }) => {
  return (
    <Select
      placeholder={label}
      value={value}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
    />
  );
};

export default AutoCompleteSelect;

AutoCompleteSelect.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool
};
