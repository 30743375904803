import React, { useState } from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";
import SelectInput from "components/forms/select-input";
import PageHeader from "components/ui/page-header";
import { importOrders } from "actions/importer";
import { InlineWaiting } from "components/ui";

const OrderImport = ({ importOrders, companies, orderImportStatus }) => {
  const [selectedCompany, setSelectedCompany] = useState("");
  // const fileInputRef = createRef();
  const [selectedFile, setSelectedFile] = useState();
  const submitOrderImportForm = async e => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("company", selectedCompany);
    formData.append("file", selectedFile);
    await importOrders(formData);
  };
  return (
    <div>
      <PageHeader title="Order Importer" />
      <form
        encType="multipart/form-data"
        onSubmit={submitOrderImportForm}
        style={{
          display: "grid",
          gridTemplateColumns: "500px",
          gridGap: 10
        }}
      >
        <SelectInput
          name="company_id"
          label="Company"
          value={selectedCompany}
          options={companies.filter(c =>
            c.name.includes("Bay State Medical, Inc.")
          )}
          onChange={({ target }) => setSelectedCompany(target.value)}
        />
        <input
          name="file"
          required
          type="file"
          style={{ fontSize: "1.05rem" }}
          onChange={({ target }) => setSelectedFile(target.files[0])}
        />
        {orderImportStatus?.inProgress ? (
          <InlineWaiting reason="Importing Orders" />
        ) : (
          <button className="button" type="submit" disabled={!selectedCompany}>
            Submit
          </button>
        )}
      </form>
      {orderImportStatus.results?.status && (
        <div>
          <h5>Status: {orderImportStatus.results?.status}</h5>
          <div>Patient Errors: accounts</div>
          {orderImportStatus.results?.patientFailList?.map(patient => (
            <div key={patient}>{patient}</div>
          ))}
          <div>Orders Errors: accounts</div>
          {orderImportStatus.results?.orderFailList?.map(order => (
            <div key={order}>{order}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    companies: selectors.getFormOptionsCompany(state),
    orderImportStatus: selectors.getOrderImportStatus(state)
  }),
  { importOrders }
)(OrderImport);

OrderImport.propTypes = {
  companies: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  accountNumberFailures: PropTypes.array,
  importOrders: PropTypes.func.isRequired,
  orderImportStatus: PropTypes.shape({
    inProgress: PropTypes.bool,
    results: PropTypes.object.isRequired
  }).isRequired
};
