import React from "react";
import PropTypes from "prop-types";
import { propOr } from "ramda";
import { useFetch } from "hooks";
import { put } from "utils/api";
import moment from "moment";
import { scheduleTypeOptions, getChatType } from "./options";
import { Spinner } from "components/ui";
import MdPerson from "react-icons/lib/md/person";

const TimeSlotSearch = ({ values, setValues, appointmentChatType, timeZoneChanged, resetTimeZoneChangedValue, setEnableTopic, setEnableDescription }) => {
  const {
    response: timeSlots,
    fetchData,
    isFetching
  } = useFetch({
    apiFn: values => put("dme_portal/available_slots", values),
    defaultValue: [],
    transformResponse: propOr([], "slots")
  });
  const [selectedDate, setSelectedDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [chatType, setChatType] = React.useState("");

  React.useEffect(() => {
    if (appointmentChatType) {
      fetchData({ date: selectedDate, chat_type: appointmentChatType });
    } else {
      fetchData({ date: selectedDate, chat_type: chatType });
    }
  }, [selectedDate, chatType, appointmentChatType]);

  React.useEffect(() => {
    if (appointmentChatType && timeZoneChanged) {
      fetchData({ date: selectedDate, chat_type: appointmentChatType });
      resetTimeZoneChangedValue();
    }

    if (!appointmentChatType && timeZoneChanged) {
      fetchData({ date: selectedDate, chat_type: chatType });
      resetTimeZoneChangedValue();
    }
  }, [timeZoneChanged]);

  return (
    <div style={{ display: "grid", gridGap: 10, gridRows: "1fr 2fr" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center"
        }}
      >
        <div className="form-field">
          <div className="form-field-header">Select Date</div>
          <input
            type="date"
            value={selectedDate}
            onChange={({ target }) => setSelectedDate(target.value)}
            className="input-field"
          />
        </div>

        {
          appointmentChatType ?
            null :
            <div className="form-field">
              <div className="form-field-header">Chat Type</div>
              <select
                value={chatType}
                onChange={({ target }) => setChatType(target.value)}
                className="input-field"
              >
                <option />
                {scheduleTypeOptions.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </div>
        }
      </div>

      {isFetching ? (
        <div
          className="list-selector-default"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spinner size="45px" />
        </div>
      ) : (
        <div
          className="list-selector"
          style={{ minHeight: 300, display: "flex", flexDirection: "column" }}
        >
          <div className="list-selector-entry-wrapper">
            {timeSlots.map(
              ({
                slot_id,
                master_chat_type,
                start_time,
                end_time,
                slots_remaining,
                active_appointments,
                is_product_demo,
                has_topic
              }) => (
                <>
                  <div
                    onClick={() => {
                      if (values.slot_id == slot_id) {
                        setValues({
                          ...values,
                          slot_id: "",
                          chat_type: "",
                          is_product_demo: false,
                          has_topic: false
                        });
                        setEnableTopic(true);
                        setEnableDescription(true);
                      } else {
                        if (is_product_demo && has_topic) {
                          setEnableTopic(false);
                          setEnableDescription(false);
                        }
                        else {
                          setEnableTopic(true);
                          if (!is_product_demo) {
                            setEnableDescription(true);
                          }
                          else {
                            setEnableDescription(false);
                          }
                        }
                        setValues({
                          ...values,
                          slot_id,
                          chat_type: getChatType(master_chat_type),
                          is_product_demo,
                          has_topic
                        });
                      }
                    }}
                    className={
                      values.slot_id == slot_id
                        ? "list-selector-entry-active"
                        : "list-selector-entry"
                    }
                  >
                    <div
                      style={{ display: "flex", flex: 1, userSelect: "none" }}
                    >
                      <span>{master_chat_type} - </span>
                      <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                        {moment(start_time).format("h:mm A") +
                          " to " +
                          moment(end_time).format("h:mm A")}
                      </span>
                    </div>
                    <div
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                    >
                      <MdPerson />
                      <span style={{ paddingLeft: 5 }}>
                        {active_appointments} /{" "}
                        {slots_remaining + active_appointments}
                      </span>
                    </div>
                  </div>
                  <div className="list-selector-entry-divider" />
                </>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

TimeSlotSearch.defaultProps = {};

TimeSlotSearch.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    slot_id: PropTypes.string,
    chat_type: PropTypes.number
  }).isRequired,
  appointmentChatType: PropTypes.number,
  timeZoneChanged: PropTypes.bool,
  resetTimeZoneChangedValue: PropTypes.func,
  setEnableTopic: PropTypes.func,
  setEnableDescription: PropTypes.func
};

export default TimeSlotSearch;
