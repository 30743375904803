import * as R from "ramda";
import { Types as AuthTypes } from "actions/authentication";
import { isUserTokenExpired } from "utils/misc";

const typeEq = R.propEq("type");

const userPattern = R.cond([
  // if stored user hasn't expired or successful login occurs, then
  // matches
  [
    R.anyPass([
      R.allPass([
        typeEq(AuthTypes.LOAD_STORED_USER),
        R.compose(R.not, isUserTokenExpired, R.path(["payload", "user"]))
      ]),
      R.allPass([
        typeEq(AuthTypes.LOGIN_USER_RESPONSE),
        R.compose(R.not, R.prop("error"))
      ])
    ]),
    R.T
  ],
  [R.T, R.F]
]);

export default userPattern;
