import { GET_ARTICLES, DELETE_ARTICLES, UPDATE_ARTICLES, UPDATE_STATUS_ARTICLES } from '../ActionTypes';

const initialState = {
    AsNeeddedArticles: []
}

const reducerArticles = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARTICLES:
            return {
                ...state,
                AsNeeddedArticles: action.payload.articles
            };
        case UPDATE_ARTICLES:
            return {
                ...state,
                AsNeeddedArticles: state.AsNeeddedArticles.map(
                    (content) => content.article_id === action.payload.article_id ? { ...content, link: action.payload.link, title: action.payload.title }
                        : content)
            };
        case DELETE_ARTICLES:
            return {
                ...state,
                AsNeeddedArticles: state.AsNeeddedArticles.map(
                    (content) => content.article_id === action.payload ? { ...content, active: false }
                        : content)
            };
        case UPDATE_STATUS_ARTICLES:
            return {
                ...state,
                AsNeeddedArticles: state.AsNeeddedArticles.map(
                    (content) => content.article_id === action.payload.article_id ? { ...content, active: action.payload.active }
                        : content)
            };
        default:
            return state;
    }
}

export default reducerArticles;
