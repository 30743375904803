import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import A from "action-types";
import { requestUserLogout } from "actions/authentication";
import { makeFetchCreator } from "actions/fetch";
import { errorMessage } from "actions/message";
import { put } from "utils/api";
import { isValidPassword } from "utils/form-validation";
import { PASSWORD_REQUIREMENTS } from "utils/password";
import compact from "lodash/compact";

const updateUserProfile = makeFetchCreator({
  url: "users/update_profile",
  method: put,
  baseType: A.USER.UPDATE
});

const INITIAL_STATE = {
  form: {
    password: "",
    password_confirmation: ""
  },
  errors: []
};

export class PasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  submit(e) {
    e.preventDefault();
    const { form } = this.state;
    const valid = isValidPassword(form.password);
    const match = form.password === form.password_confirmation;
    if (!valid || !match) {
      this.setState({
        errors: compact([
          !valid &&
            "Your password did not meet the required criteria. Please see prompt above.",
          !match && "Your password and confirmation do not match."
        ])
      });
      return;
    }
    const { dispatch, user } = this.props;
    dispatch(
      updateUserProfile({
        payload: {
          user: { ...user, ...form }
        }
      })
    )
      .then(() => {
        dispatch(requestUserLogout());
      })
      .catch(error => {
        dispatch(errorMessage(error.response.body.status));
      });
    this.setState(INITIAL_STATE);
  }

  render() {
    const { form, errors } = this.state;
    return (
      <div className="row">
        <div className="small-12 columns">
          <h3
            style={{
              marginBottom: "0px"
            }}
          >
            Password reset
          </h3>
          <i
            style={{
              display: "block",
              marginBottom: "10px"
            }}
          >
            {PASSWORD_REQUIREMENTS}
          </i>
          <form onSubmit={e => this.submit(e)}>
            <input
              type="password"
              placeholder="Password"
              value={form.password}
              onChange={({ target: { value } }) =>
                this.setState(({ form }) => ({
                  form: { ...form, password: value }
                }))
              }
            />

            <input
              type="password"
              placeholder="Confirm Password"
              value={form.password_confirmation}
              onChange={({ target: { value } }) =>
                this.setState(({ form }) => ({
                  form: { ...form, password_confirmation: value }
                }))
              }
            />
            <div
              style={{
                color: "#f00",
                fontWeight: "600",
                marginBottom: "1rem"
              }}
            >
              {errors.map((e, i) => (
                <div key={i}>{e}</div>
              ))}
            </div>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    );
  }
}

export default connect()(PasswordForm);

PasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};
PasswordForm.contextTypes = {
  router: PropTypes.object
};
