import React, { useState, useEffect } from "react";
import { PageHeader, TabList } from "components/ui";
import { Switch, Route } from "react-router-dom";
import AvailabilityScheduler from "./availability-scheduler";
import UpcomingChats from "./upcoming/index.js";
import ChatHistory from "./history";
import ManagerView from "./manager-view";
import { selectors } from "reducers/user";
import { useSelector, useDispatch } from 'react-redux';
import FaRefresh from "react-icons/lib/fa/refresh";
import { getCurrentTimeZone } from "utils/dates";
import { useFetch } from "hooks";
import { path } from "ramda";
import { errorMessage, message } from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import { put } from '../../../utils/api';

const ChatsContainer = () => {
  const [currentCoachTimeZoneInfo, setCurrentCoachTimeZoneInfo] = useState("");
  const [timeZone, setTimeZone] = useState(null);
  const dispatch = useDispatch();

  const userRole = useSelector(selectors.getUserRole);
  const isAdmin = userRole == "CompanyAdministrator" || userRole == "Administrator" || userRole == "ServiceAdmin";

  useEffect(() => {
    var userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));
    if(!userLoggedIn.timezoneExists)
      updateTimeZone();
    else
      updateTimeZoneDescription(userLoggedIn.timezone);
  }, []);

  const {
    fetchData: fetchUpdateTimeZone,
  } = useFetch({
    apiFn: data => put(`users/update_timezone`, JSON.stringify(data), { "Content-Type": "application/json" }),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onSuccess: () => {
      dispatch(message("Time zone updated successfully"));
    },
    onError: error => {
      dispatch(errorMessage(`Failed to update time zone: ${getApiErrorMessage(error)}`));
    }
  })


  const updateTimeZone = async () => {
    var currentTimeZone = getCurrentTimeZone();
    var timeZoneSaved = await fetchUpdateTimeZone(currentTimeZone);
    if (timeZoneSaved)
      updateTimeZoneInfo(timeZoneSaved);
    updateTimeZoneDescription(timeZoneSaved);
    setTimeZone(timeZoneSaved);
  }

  const updateTimeZoneInfo = (currentTimeZone) => {
    var userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));
    if (userLoggedIn) {
      userLoggedIn.timezone = currentTimeZone;
      window.localStorage.setItem("evergreen", JSON.stringify(userLoggedIn));
    }
  }

  const updateTimeZoneDescription = (currentTimeZone) => {

    const optionsShort = {
      timeZoneName: "short",
      timeZone: currentTimeZone
    };

    const optionsLong = {
      timeZoneName: "long",
      timeZone: currentTimeZone
    };

    const optionsLongOffset = {
      timeZoneName: "longOffset",
      timeZone: currentTimeZone
    };

    const optionsShortOffset = {
      timeZoneName: "shortOffset",
      timeZone: currentTimeZone
    };

    var longTimeFormat = new Intl.DateTimeFormat('en-US', optionsLong);
    var shortTimeFormat = new Intl.DateTimeFormat('en-US', optionsShort);
    var longOffsetTimeFormat = new Intl.DateTimeFormat('en-US', optionsLongOffset);
    var shortOffsetTimeFormat = new Intl.DateTimeFormat('en-US', optionsShortOffset);

    var timeZoneShortName = shortTimeFormat.formatToParts().find(x => x.type === 'timeZoneName').value;
    var timeZoneLongName = longTimeFormat.formatToParts().find(x => x.type === 'timeZoneName').value;
    var timeZoneLongOffset = longOffsetTimeFormat.formatToParts().find(x => x.type === 'timeZoneName').value;
    var timeZoneShortOffset = shortOffsetTimeFormat.formatToParts().find(x => x.type === 'timeZoneName').value;

    var titleText = timeZoneShortName == timeZoneShortOffset ?
      timeZoneLongName + " (" + timeZoneLongOffset + ")" :
      timeZoneShortName + ' - ' + timeZoneLongName + " (" + timeZoneLongOffset + ")";

    setCurrentCoachTimeZoneInfo(titleText);
  }

  const resetTimeZoneValue = () => {
    setTimeZone(null);
  }

  return (
    <div>
      <PageHeader title="Video/Text Chats" />
      <div
        style={{
          display: "flex",
          padding: "5px 0px",
          alignItems: "center"
        }}
      >
        <TabList
          routes={[
            {
              path: `/chats/upcoming`,
              label: "Upcoming"
            },
            {
              path: `/chats/time-slots`,
              label: "My Schedule"
            },
            {
              path: `/chats/history`,
              label: "History"
            },
            isAdmin
              ? {
                  path: `/chats/manager-view`,
                  label: "Manager View"
                }
              : false
          ].filter(Boolean)}
        />
      </div>
      <div
        style={{
          textAlign: "right",
          paddingBottom: "8px",
          alignItems: "right",
          fontFamily: "Poppins-Regular, PT-Sans, sans-serif"
        }}
      >
        Your TimeZone: <span style={{ color: "SteelBlue", paddingRight: "5px" }}>{currentCoachTimeZoneInfo}</span>
        <FaRefresh onClick={updateTimeZone} style={{ fill: "SteelBlue", border: "SteelBlue" }} />
      </div>
      <Switch>
        <Route path="/chats/time-slots">
          <AvailabilityScheduler timeZone={timeZone} resetTimeZoneValue={() => resetTimeZoneValue()} />
        </Route>
        <Route path="/chats/upcoming">
          <UpcomingChats timeZone={timeZone} resetTimeZoneValue={() => resetTimeZoneValue()} />
        </Route>
        <Route path="/chats/history">
          <ChatHistory timeZone={timeZone} resetTimeZoneValue={() => resetTimeZoneValue()} />
        </Route>
        {isAdmin &&
          <Route path="/chats/manager-view">
            <ManagerView timeZone={timeZone} resetTimeZoneValue={() => resetTimeZoneValue()} />
          </Route>}
      </Switch>

    </div>
  );
};

export default ChatsContainer;
