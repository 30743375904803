import React from "react";
import PropTypes from "prop-types";
import { PageHeader } from "components/ui";
import TrackingSearchForm from "./tracking-search";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { Link } from "react-router-dom";
import CompanyTeamSelect from "components/shared/company-team-select";
import { selectors } from "reducers";
import { connect } from "react-redux";
import "./style.scss";
import { formatDate } from "utils/misc";

const TrackingTable = ({ companies }) => {
  const initialQuery = {
    sort: {},
    filters: [],
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching } = useApiQuery(
    "tracking",
    initialQuery,
    "tracking"
  );
  return (
    <div>
      <PageHeader title="Tracking" />
      <TrackingSearchForm queryDispatcher={queryDispatcher} />
      <Table
        query={query}
        data={data}
        rows={rows}
        queryDispatcher={queryDispatcher}
        isFetching={isFetching}
        rowKey="tracking_id"
        TableRow={row => (
          <tr>
            <td>
              <Link to={`patients/${row.patient_id}`}>
                {row.first_name} {row.last_name}
              </Link>
            </td>
            <td>{row.account_number}</td>
            <td>
              <Link to={`/orders/${row.order_id}`}>{row.invoice_number}</Link>
            </td>
            <td>{row.carrier}</td>
            <td>{row.tracking_number}</td>
            <td>{formatDate(row.ship_date)}</td>
            <td>{row.line_item_count}</td>
            <td>{row.company_name}</td>
          </tr>
        )}
        containerClass="query-table tracking-table dynamic-columns"
        columns={[
          {
            header: "Patient",
            field: "last_name"
          },
          {
            header: "Account #",
            field: "account_number"
          },
          {
            header: "Order",
            field: "invoice_number"
          },
          {
            header: "Carrier",
            field: "carrier"
          },
          {
            header: "Tracking Number",
            field: "tracking_number"
          },
          {
            header: "Ship Date",
            field: "ship_date"
          },

          {
            header: "Items",
            field: "line_item_count"
          },
          {
            header: "Company",
            field: "company_name",
            filters: companies,
            filterDownSelect: true,
            SubFilter: CompanyTeamSelect
          }
        ]}
      />
    </div>
  );
};

export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state)
}))(TrackingTable);

TrackingTable.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired
};
