import React from "react";
import PropTypes from "prop-types";
import { nth, prop } from "ramda";

const OrdersClaimedTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p style={{ color: "#249f94", fontSize: "1.5rem", fontWeight: "bold" }}>
          <span style={{ color: "#222"}}>{`${payload ? prop("value", nth(0, payload)) : "0"}`}</span>
        </p>
      </div>
    );
  } else {
    return <div />;
  }
};
  
export default OrdersClaimedTooltip

OrdersClaimedTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.object)
}
