import React from "react";
import PropTypes from "prop-types";
import { compose, withState, withHandlers } from "recompose";
import { FieldArray, Field } from "redux-form";
import * as R from "ramda";
import { Rows } from "components/ui";
import IoMdTrash from "react-icons/lib/io/trash-b";

let Fields = ({
  fields,
  fieldSelection,
  handleFieldSelection,
  fieldOptions
}) => (
  <div>
    <Rows>
      {fields.map((field, index, fields) => {
        const {
          fieldProps,
          text
        } = fieldOptions.find(o => o.field === fields.get(index).field)
        return (
          <div key={index} className='variable-field'>
            <div>
              <div className='variable-field-label-text'>{text}</div>
              <Field
                {...fieldProps}
                name={`${field}.value`} />
            </div>
            <i
              onClick={() => fields.remove(index)}
              className="remove-field"
              title="Remove field"
            >
              <IoMdTrash />
            </i>
          </div>
        )
      })}
    </Rows>
    <div className='add-field-container'>
      <select value={fieldSelection} onChange={handleFieldSelection}>
        <option value='' className='choose-field'>Choose Field</option>
        {R.compose(
           R.map(({ text, field }) => (
             <option key={field} value={field}>{text}</option>
           )),
           R.filter(({ filterCondition }) => typeof filterCondition === 'function' ? filterCondition(fields.getAll()) : true ),
           R.reject(R.compose(
             R.includes(R.__, R.map((field, index, fields) => fields.get(index).field, fields)),
             R.prop('field')
           ))
        )(fieldOptions)}
      </select>
    </div>
  </div>
)

Fields = compose(
  withState('fieldSelection', 'setFieldSelection', ''),
  withHandlers({
    handleFieldSelection: ({ setFieldSelection, fieldOptions, fields }) => ({ target: { value } }) => {
      setFieldSelection(value)
      const { defaultValue } = fieldOptions.find(o => o.field === value)
      fields.push({ field: value, value: defaultValue })
    }
  })
)(Fields)

Fields.propTypes = {
  fields: PropTypes.PropTypes.object,
  fieldSelection: PropTypes.string,
  handleFieldSelection: PropTypes.func,
  fieldOptions: PropTypes.arrayOf(PropTypes.object),
  addField: PropTypes.func
}

const VariableFieldSet = ({
  name,
  fieldOptions,
  validate
}) => (
  <div className='s3-ui-variable-form-field'>
    <FieldArray
      name={name}
      component={Fields}
      fieldOptions={fieldOptions}
      validate={validate}
    />
  </div>
)

VariableFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  fieldOptions: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    fieldProps: PropTypes.shape({
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
      validate: PropTypes.func
    }).isRequired
  })).isRequired
}

export default VariableFieldSet
