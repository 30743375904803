import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageHeader from "components/ui/page-header";
import styler from "react-styling";
import Radium from "radium";
import { requestImportTracking } from "actions/import-tracking";
import SelectInput from "components/forms/select-input";
import { InlineWaiting } from "components/ui";
import ImportResults from "../admin-tools/import-results";

class FulfillmentTrackingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headersCheckboxChecked: true,
      fulfillmentType: "",
      errors: {}
    };
  }

  onFulfillmentTrackingSubmit = e => {
    e.preventDefault();
    try {
      if (!this.state.fulfillmentType) {
        this.setState({ errors: { fulfillmentType: "Required." } });
        return;
      }
      let formData = new FormData();
      formData.append("company", this.state.fulfillmentType);
      formData.append("file", this.filesInput.files[0]);
      formData.append("headers", this.state.headersCheckboxChecked);
      this.props.requestImportTracking(formData);
      this.setState({
        headersCheckboxChecked: true,
        fulfillmentType: "",
        filesInput: null,
        errors: {}
      });
      this.filesInput.value = null;
    } catch (e) {
      /* handle error */
    }
  };

  handleChange = ({ target }) => {
    const name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  render() {
    const { accountNumberFailures } = this.props;
    return (
      <div>
        <PageHeader title="Import tracking" />
        <div className="column">
          <div className="row">
            <form
              encType="multipart/form-data"
              onSubmit={this.onFulfillmentTrackingSubmit}
            >
              <input
                ref={input => {
                  this.filesInput = input;
                }}
                name="file"
                required
                type="file"
              />
              <div style={S.selectDiv}>
                <SelectInput
                  name="fulfillmentType"
                  label="Fulfillment Type"
                  value={this.state.fulfillmentType}
                  error={this.state.errors}
                  options={[
                    { text: "McKesson", value: "McKesson" },
                    { text: "VGM", value: "VGM" },
                    { text: "VGM - App Tracking", value: "VGM_APP_TRACKING" },
                    { text: "Internal", value: "Internal" },
                    { text: "Independence", value: "Independence" },
                    { text: "CPAP_Dropship", value: "CPAP_Dropship" },
                    { text: "PPM", value: "PPM" },
                    { text: "Bonafide", value: "BONAFIDE" }
                  ]}
                  onChange={this.handleChange}
                />
              </div>
              <label>
                Headers
                <input
                  style={S.checkBox}
                  name="headersCheckboxChecked"
                  type="checkbox"
                  checked={this.state.headersCheckboxChecked}
                  onChange={this.handleChange.bind(this)}
                />
              </label>

              <div style={S.submitContainer}>
                <input
                  style={S.submitButton}
                  className="button"
                  type="submit"
                  value="Submit"
                  disabled={this.props.isFetching}
                />
                {this.props.isFetching && <InlineWaiting reason={`submitting ${this.state.fulfillmentType} tracking`}/>}
              </div>
            </form>

            {this.props.success && (
              <div className="alert-box success">
                Your form has been submitted successfully!
              </div>
            )}
            {!this.props.success && (
              <ImportResults
                results={accountNumberFailures}
                title={`The following ${accountNumberFailures.length} tracking updates failed`}
                fields={[{ title: "Account Number", name: "acctNum" }]}
              />
            )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    success: state.getIn(["fulfillment", "importTrackingSuccess"]),
    isFetching: state.getIn(["tracking-import"]).isFetching,
    accountNumberFailures: state.getIn(["tracking-import"]).accountNumberFailures
  }),
  {
    requestImportTracking
  }
)(Radium(FulfillmentTrackingForm));

FulfillmentTrackingForm.propTypes = {
  success: PropTypes.bool,
  requestImportTracking: PropTypes.func,
  isFetching: PropTypes.bool,
  accountNumberFailures: PropTypes.array,
};

const S = styler`
selectDiv
  width: 500px
checkBox
  margin-left: 10px
submitContainer
  display: flex
  align-items: center
submitButton
  margin-bottom: 0
  margin-right: 10px
`;
