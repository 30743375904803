import React from "react";
import * as Yup from 'yup';
import "./styles.css"
import { Form, Formik } from "formik";
import PropTypes from "prop-types";


const PatientSearchFilters = ({ email, phoneNumber, ticketNumberId, setSearchFilters }) => {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Please use a valid email account'),
        phoneNumber: Yup.string(),
        dateOfBirth: Yup.date()
            .max(new Date(), 'date of birth can not be in the future'),
        firstName: Yup.string(),
        lastName: Yup.string(),
    });
    let initialValues = {
        email: email,
        phoneNumber: phoneNumber,
        dateOfBirth: "",
        firstName: "",
        lastName: "",
        ticketNumberId: ticketNumberId
    };

    return (
        <div>
            <h2 className="section-title">Patient Search</h2>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => setSearchFilters(values)}
                enableReinitialize={true}
            >
                {({ handleChange, handleReset, values, errors }) => (
                    <Form>
                        {/* Filters/Inputs */}
                        <div className="patient-search-filters">

                            <div className="firstTimeRegistration-patientSearch-inputWrapper" style={{ width: "46%" }}>
                                <label className="firstTimeRegistration-patientSearch-label">Email:</label>
                                <input
                                    name="email"
                                    className="firstTimeRegistration-patientSearch-input"
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                {errors.email && <p className="firstTimeRegistration-patientSearch-errorLabel">{errors.email}</p>}
                            </div>

                            <div className="firstTimeRegistration-patientSearch-inputWrapper" style={{ width: "46%" }}>
                                <label className="firstTimeRegistration-patientSearch-label">Phone number:</label>
                                <input
                                    name="phoneNumber"
                                    className="firstTimeRegistration-patientSearch-input"
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                />
                                {errors.phoneNumber && <p className="firstTimeRegistration-patientSearch-errorLabel">{errors.phoneNumber}</p>}
                            </div>

                            <div className="firstTimeRegistration-patientSearch-inputWrapper">
                                <label className="firstTimeRegistration-patientSearch-label">First name:</label>
                                <input
                                    name="firstName"
                                    className="firstTimeRegistration-patientSearch-input"
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                                {errors.firstName && <p className="firstTimeRegistration-patientSearch-errorLabel">{errors.firstName}</p>}
                            </div>

                            <div className="firstTimeRegistration-patientSearch-inputWrapper">
                                <label className="firstTimeRegistration-patientSearch-label">Last name:</label>
                                <input
                                    name="lastName"
                                    className="firstTimeRegistration-patientSearch-input"
                                    onChange={handleChange}
                                    value={values.lastName}
                                />
                                {errors.lastName && <p className="firstTimeRegistration-patientSearch-errorLabel">{errors.lastName}</p>}
                            </div>

                            <div className="firstTimeRegistration-patientSearch-inputWrapper">
                                <label className="firstTimeRegistration-patientSearch-label">Date of birth:</label>
                                <input
                                    name="dateOfBirth"
                                    type="date"
                                    className="firstTimeRegistration-patientSearch-input"
                                    onChange={handleChange}
                                    value={values.dateOfBirth}
                                />
                                {errors.dateOfBirth && <p className="firstTimeRegistration-patientSearch-errorLabel">{errors.dateOfBirth}</p>}
                            </div>

                        </div>
                        {/* Buttons */}
                        <div className="patient-search-buttons">
                            <button type="button" onClick={handleReset} className="patientSearch-buttonSecondary patientSearch-buttonBase">
                                Clear
                            </button>
                            <button type="submit" className="patientSearch-buttonPrimary patientSearch-buttonBase">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default PatientSearchFilters;
PatientSearchFilters.propTypes = {
    patientData: PropTypes.object.isRequired,
    patientSelectedID: PropTypes.bool,
    email:PropTypes.string,
    phoneNumber: PropTypes.string,
    setSearchFilters:PropTypes.func,
    ticketNumberId: PropTypes.string
  };
  