import React from "react";
import PropTypes from "prop-types";

import Radium from "radium";
import styler from "react-styling";

export const ChartHeader = ({ title, tooltip}) => {
  return (
    <header>
      <h3 style={S.header} title={tooltip}>{title}</h3>
    </header>
  );
};

export default Radium(ChartHeader);

ChartHeader.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string
};

ChartHeader.defaultProps = {
  tooltip: ''
}

const S = styler`
  header:
    font-size: 1.4em
    text-transform: uppercase
`;
