const orderEquipmentQuestions = [
  {
    equipment: "mask",
    key: "mask_dirty",
    text: "My Mask is dirty with risk of infection."
  },
  {
    equipment: "mask",
    key: "mask_broken",
    text: "My Mask is in poor and/or non-working condition."
  },
  {
    equipment: "mask",
    key: "mask_loose",
    text: "My Mask will not adequately seal due to wear."
  },
  {
    equipment: "headgear",
    key: "headgear_loose",
    text: "My Headgear is stretched limiting pressure support."
  },
  {
    equipment: "headgear",
    key: "headgear_broken",
    text: "My Headgear is in poor and/or non-working condition."
  },
  {
    equipment: "seals",
    key: "seal_loose",
    text: "My Seal/Cushion/Pillow will not adequately seal due to wear."
  },
  {
    equipment: "seals",
    key: "seal_broken",
    text: "My Seal/Cushion/Pillow is in poor and/or non-working condition."
  },
  {
    equipment: "filter",
    key: "filter_dirty",
    text: "My Filter is dirty with risk of infection."
  },
  {
    equipment: "tubing",
    key: "tubing_dirty",
    text: "My Tubing is dirty with risk of infection."
  },
  {
    equipment: "tubing",
    key: "tubing_broken",
    text: "My Tubing is in poor and/or non-working condition."
  },
  {
    equipment: "water chamber",
    key: "waterchamber_dirty",
    text: "My Waterchamber is dirty with risk of infection.",
    defaultValue: true
  },
  {
    equipment: "water chamber",
    key: "waterchamber_broken",
    text: "My Waterchamber is in poor and/or non-working condition.",
    defaultValue: true
  },
  {
    equipment: "chinstrap",
    key: "chinstrap_broken",
    text: "My Chinstrap Is in poor and/or non-working condition.",
    defaultValue: true
  },
  {
    equipment: "chinstrap",
    key: "chinstrap_loose",
    text: "My Chinstrap is stretched, limiting pressure support.",
    defaultValue: true
  }
];

export default orderEquipmentQuestions;

export const transformEquipmentName = equipment => {
  switch (equipment.toUpperCase()) {
    case "SEAL/CUSHION/PILLOW":
      return "SEALS";
    case "WATERCHAMBER":
      return "WATER CHAMBER";
    case "FILTERS":
    case "NONDISPOSABLE FILTER":
    case "DISPOSABLE FILTER":
      return "FILTER";
    default:
      return equipment.toUpperCase();
  }
};

export const getEquipmentQuestions = equipment => {
  return orderEquipmentQuestions.filter(
    question =>
      question.equipment.toUpperCase() === transformEquipmentName(equipment)
  );
};
