import React from "react";
import PropTypes from "prop-types";
import { PureModal } from "components/ui";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import { IoIosCopy } from "react-icons/lib/io";
import ModalCopyFormToAnotherCompany from "./modal-copy-form-to-another-company";

export default function CopyForm({ companyId, formId, iconSize, name, order, patientStage, successAction, setFormToBeAssignedId }) {
    
    return (
        <div>
            <PureModal
                renderTrigger={({ openModal }) => (
                    <div>
                        <Tooltip
                            id={`single-stat-tooltip-a`}
                            className="single-stat-tooltip"
                            effect="solid"
                            message={'Copy form'}
                        >
                            <IoIosCopy style={{ color: "#007bff", fontSize: iconSize, cursor: 'pointer' }} onClick={openModal} />
                        </Tooltip>
                    </div>
                )}
                renderContent={({ closeModal }) => (<ModalCopyFormToAnotherCompany sourceCompanyId={companyId} formId={formId} name={name} order={order} patientStage={patientStage} closeModal={() => closeModal()} successAction={() => successAction()} setFormToBeAssignedId={(formId) => setFormToBeAssignedId(formId)}/>)}
            />
        </div>
    )
}

CopyForm.propTypes = {
    companyId: PropTypes.string.isRequired,
    formId: PropTypes.number.isRequired,
    iconSize: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    patientStage: PropTypes.number.isRequired,
    successAction: PropTypes.func.isRequired,
    setFormToBeAssignedId: PropTypes.func,
};