import { GET_PATIENTS, GET_PATIENTS_QUESTIONNAIRE, GET_QUESTIONNAIRE_DETAILS, GET_PATIENTS_INSURANCE } from './../ActionTypes';

const initialState = {
    Patients: [],
    Questionnaire: [],
    Insurances: [],
    QuestionnaireDetails: {}
}

const reducerPatients = (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENTS:
            return {
                ...state,
                Patients: action.payload
            };
        case GET_PATIENTS_QUESTIONNAIRE:
            return {
                ...state,
                Questionnaire: action.payload
            };
        case GET_QUESTIONNAIRE_DETAILS:
            return {
                ...state,
                QuestionnaireDetails: action.payload
            };
        case GET_PATIENTS_INSURANCE:
            return {
                ...state,
                Insurances: action.payload
            };
        default:
            return state;
    }
}

export default reducerPatients;
