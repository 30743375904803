import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import TextInput from "components/forms/text-input";
import FileCard from "./file-card";
import MdFolder from "react-icons/lib/md/folder";
import IconButton from "components/ui/icon-button";
import MdArrowBack from "react-icons/lib/md/arrow-back";
import { createTab } from "actions/system-tray";

const filterByText = searchValue => ({ name }) =>
  name.toLowerCase().includes(searchValue.toLowerCase());

const FolderFiles = ({ folder, files, isLoggedIn, createTab, goBack }) => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <div>
      <div className="folder-files-header">
        <IconButton
          text="back"
          icon={<MdArrowBack size={24} />}
          onClick={goBack}
          style={{ color: "black" }}
        />
        <h4 style={{ align: "center" }}>
          <MdFolder size={24} />
          {folder.name}
        </h4>
      </div>
      <div>
        <TextInput
          value={searchValue}
          label="Search"
          name="g-drive-filter"
          onChange={({ target }) => setSearchValue(target.value)}
        />
      </div>
      {isLoggedIn ? (
        <div className="user-files-container">
          {files.filter(filterByText(searchValue)).map(file => (
            <FileCard key={file.id} file={file} createTab={createTab} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

FolderFiles.defaultProps = {};

FolderFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ),
  folder: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  }),
  isLoggedIn: PropTypes.bool,
  createTab: PropTypes.func.isRequired,
  goBack: PropTypes.func
};

export default connect(
  (state, props) => ({
    files: selectors.getGapiFolderFiles(state, props),
    isLoggedIn: selectors.getGapiIsLoggedIn(state)
  }),
  { createTab }
)(FolderFiles);
