import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ label, input }) => (
  <span>
    <label
      style={{
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        fontSize: "1.15em",
        display: "inline-flex",
        paddingRight: 20
      }}
    >
      {label}
      <input
        type="checkbox"
        style={{ margin: "0px 10px" }}
        {...input}
        checked={input.value}
      />
    </label>
  </span>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object
};

export default Checkbox;
