import React, { Component } from "react";
import PropTypes from "prop-types";
import { browserHistory } from "browser-history";
import { connect } from "react-redux";
import { getInventoryExport } from "actions/inventory";
import { InlineWaiting, ExportButton } from "components/ui";
import { selectors } from "reducers";

class ExportInventory extends Component {
  state = {
    isSubmitting: false
  };
  handleGetInventoryExport = () => {
    const { userId, getInventoryExport } = this.props;
    this.setState({ isSubmitting: true });
    getInventoryExport({ userId }).then(() => {
      browserHistory.push(`/exports`);
    });
  };
  render() {
    return this.state.isSubmitting ? (
      <InlineWaiting />
    ) : (
      <ExportButton onClick={this.handleGetInventoryExport} title="Export Inventory"/>
    );
  }
}

export default connect(
  state => ({ userId: selectors.getUserId(state) }),
  { getInventoryExport }
)(ExportInventory);

ExportInventory.propTypes = {
  getInventoryExport: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}
