import React from "react";
import PropTypes from "prop-types";
import { graphColors } from "utils/styles";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from "react-tooltip";
import {
  ResponsiveContainer,
  LabelList,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import { deSnakeCase } from "utils/misc";

const ChartColors = {
  total: "#c2c2c2",
  email: graphColors[2],
  ivr: graphColors[4],
  mobile_app: graphColors[3],
  web_app: graphColors[1],
  patient_portal: graphColors[7],
  external_portal: graphColors[10],
  text: graphColors[9]
};

const OrdersByContactType = ({ data, onExport }) => {
  const [visibleBars, setVisibleBars] = React.useState({
    total: true,
    email: true,
    web_app: true,
    mobile_app: true,
    text: true,
    ivr: true,
    patient_portal: true
  });
  const handleLegendCheckbox = ({ target }) => {
    setVisibleBars({
      ...visibleBars,
      [target.name]: !visibleBars[target.name]
    });
  };
  return (
    <div
      style={{
        border: "1px solid rgb(221, 221, 221)",
        padding: "16px 20px 20px 16px",
        position: "relative"
      }}
    >
      <ReactTooltip />
      {onExport && (
        <div className="stat-export-icon" data-tip="Export" onClick={onExport}>
          <FaFileExcelO />
        </div>
      )}
      <h3
        style={{
          fontSize: "1.4em",
          textTransform: "uppercase"
        }}
      >
        Orders By Contact Type
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            left: 50,
            right: 50
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis type="number" />
          <XAxis
            xAxisId={0}
            dy={0}
            dx={0}
            label={{ value: "", angle: 0, position: "top" }}
            interval={0}
            dataKey="name"
            tickLine={false}
            tick={{ fontSize: 12, angle: 0 }}
          />
          <XAxis
            xAxisId={1}
            dy={-9}
            dx={0}
            label={{ value: "", angle: 0, position: "bottom" }}
            interval={0}
            dataKey="total"
            tickLine={false}
            tick={{ fontSize: 9, angle: 0 }}
            axisLine={false}
          />
          {visibleBars.email && (
            <Bar
              dataKey="orders_from_patient_portal_email"
              maxBarSize={15}
              fill={ChartColors.email}
              minPointSize={5}
            >
              <LabelList
                dataKey="orders_from_patient_portal_email"
                position="top"
              />
            </Bar>
          )}
          {visibleBars.web_app && (
            <Bar
              dataKey="orders_from_web_app"
              maxBarSize={15}
              fill={ChartColors.web_app}
              minPointSize={5}
            >
              <LabelList dataKey="orders_from_web_app" position="top" />
            </Bar>
          )}
          {visibleBars.mobile_app && (
            <Bar
              dataKey="orders_from_mobile_app"
              maxBarSize={15}
              fill={ChartColors.mobile_app}
              minPointSize={5}
            >
              <LabelList dataKey="orders_from_mobile_app" position="top" />
            </Bar>
          )}
          {visibleBars.ivr && (
            <Bar
              dataKey="orders_from_ivr"
              maxBarSize={15}
              fill={ChartColors.ivr}
              minPointSize={5}
            >
              <LabelList dataKey="orders_from_ivr" position="top" />
            </Bar>
          )}
          {visibleBars.text && (
            <Bar
              dataKey="orders_from_text_message"
              maxBarSize={15}
              fill={ChartColors.text}
              minPointSize={5}
            >
              <LabelList dataKey="orders_from_text_message" position="top" />
            </Bar>
          )}
          <Bar
            dataKey="orders_from_external_portal"
            fill={ChartColors.external_portal}
            maxBarSize={15}
            minPointSize={5}
          >
            <LabelList dataKey="orders_from_external_portal" position="top" />
          </Bar>
          {visibleBars.patient_portal && (
            <Bar
              dataKey="orders_from_patient_portal"
              fill={ChartColors.patient_portal}
              maxBarSize={15}
              minPointSize={5}
            >
              <LabelList dataKey="orders_from_patient_portal" position="top" />
            </Bar>
          )}

          <Tooltip
            cursor={false}
            formatter={(value, name) => [value, deSnakeCase(name)]}
          />
        </BarChart>
      </ResponsiveContainer>
      <div className="form-of-contact-chart-legend">
        <label style={{ color: ChartColors.email }}>
          <input
            type="checkbox"
            name="email"
            onChange={handleLegendCheckbox}
            checked={visibleBars.email}
          />
          Email
        </label>
        <label
          style={{
            color: ChartColors.web_app
          }}
        >
          <input
            type="checkbox"
            name="web_app"
            onChange={handleLegendCheckbox}
            checked={visibleBars.web_app}
          />
          Web App
        </label>
        <label style={{ color: ChartColors.mobile_app }}>
          <input
            type="checkbox"
            name="mobile_app"
            onChange={handleLegendCheckbox}
            checked={visibleBars.mobile_app}
          />
          Mobile App
        </label>
        <label style={{ color: ChartColors.ivr }}>
          <input
            type="checkbox"
            name="ivr"
            onChange={handleLegendCheckbox}
            checked={visibleBars.ivr}
          />
          IVR
        </label>
        <label style={{ color: ChartColors.text }}>
          <input
            type="checkbox"
            name="text"
            onChange={handleLegendCheckbox}
            checked={visibleBars.text}
          />
          Text Message
        </label>
        <label
          style={{
            color: ChartColors.patient_portal
          }}
        >
          <input
            type="checkbox"
            name="patient_portal"
            onChange={handleLegendCheckbox}
            checked={visibleBars.patient_portal}
          />
          Patient Portal
        </label>
      </div>
    </div>
  );
};

OrdersByContactType.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      orders_from_web_app: PropTypes.number,
      orders_from_ivr: PropTypes.number,
      orders_from_mobile_app: PropTypes.number,
      orders_from_patient_portal: PropTypes.number,
      orders_from_patient_portal_email: PropTypes.number,
      orders_from_patient_portal_text: PropTypes.number,
      total: PropTypes.number
    })
  ).isRequired,
  onExport: PropTypes.func
};

export default OrdersByContactType;
