import includes from 'lodash/includes'
import get from 'lodash/get'
import * as R from "ramda";

export const createReducer = (initialState, handlers) => (
  state = initialState,
  action
) => {
  if ({}.hasOwnProperty.call(handlers, action.type)) {
    return handlers[action.type](state, action);
  }
  return state;
};

export const toggleReducer = (initialState, trueTypes, falseTypes) => (state = initialState, action) => {
  if (includes(trueTypes, action.type)) {
    return true
  }
  if (includes(falseTypes, action.type)) {
    return false
  }
  return state
}

export const idReducer = (idKey, reducer) => (state = {}, action) => {
  const id = get(action, idKey)
  if (id) {
    return {
      ...state,
      [id]: reducer(state[id], action)
    }
  }
  return state
}

export const staticReducer = constant => () => constant

export const ifNoError = reducer => (state, action) => (
  action.error ? state : reducer(state, action)
)

export const makeFetchHandlers = ([
  fetchingType,
  fetchedType,
  failedType
], flat = false) => R.omit(R.isEmpty)({
  [fetchingType]: state => flat ? true : ({ ...state, isFetching: true, didFail: false }),
  [fetchedType]: state => flat ? false: ({ ...state, isFetching: false, didFail: false }),
  [failedType]: state => flat ? false : ({ ...state, isFetching: false, didFail: true })
});

/* ([strings],[strings]) returns reducer */
/* takes array of path and array of action types */
/* types should be in order [fetching, fetched, failed] */
/* if flat option is true returns state as boolean instad of object with failed state */
export const makeFetchReducer = (types, flat = false) => {
  const initialState = flat ? false : { isFetching: false, didFail: false }

  return createReducer(initialState, makeFetchHandlers(types, flat));
};
