import C from 'action-types'
import { makeFetchTuple } from 'actions/fetch'

export const [
  fetchSleepCoachInsights,
  invalidateSleepCoachInsights
] = makeFetchTuple({
  url: ({ userID, start_date, end_date }) => `insights?id=${userID}&start_date=${start_date}&end_date=${end_date}`,
  baseType: C.INSIGHTS.GET,
  cache: false
})

export const [
  fetchAdminInsights,
  invalidateAdminInsights
] = makeFetchTuple({
  url: ({ userId, start_date, end_date }) => `insights?id=${userId}&start_date=${start_date}&end_date=${end_date}`,
  baseType: C.INSIGHTS.GET,
  cache: false
})

// const prefix = type => `s3/insights/${type}`

// export const SET_OFFICE_ADMIN_START = prefix('SET_OFFICE_ADMIN_START')
// export const setOfficeAdminStart = (id, date) => ({
//   type: SET_OFFICE_ADMIN_START,
//   payload: { id, date }
// })

// export const SET_OFFICE_ADMIN_END = prefix('SET_OFFICE_ADMIN_END')
// export const setOfficeAdminEnd = (id, date) => ({
//   type: SET_OFFICE_ADMIN_END,
//   payload: { id, date }
// })

// export const REQUEST_OFFICE_ADMIN_INSIGHTS = prefix('REQUEST_OFFICE_ADMIN_INSIGHTS')
// export const RESPONSE_OFFICE_ADMIN_INSIGHTS = prefix('RESPONSE_OFFICE_ADMIN_INSIGHTS')
