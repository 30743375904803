import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { exportTable } from "action-creators";
import { updateSearchTableQuery } from "actions/search-table-query"
import { fetchPatientFormAttributes } from "actions/patient";
import { Map, fromJS } from "immutable";
import { deSnakeCase } from "utils/misc";
import { Waiting, PopupSelect, TableFooter } from "components/ui/index";
import Radium from "radium";
import styler from "react-styling";
import map from "lodash/fp/map";
import flow from "lodash/fp/flow";
import sortBy from "lodash/fp/sortBy";
import { Link, withRouter } from "react-router-dom";
import PageHeader from "components/ui/page-header";
import { getRouteParam } from "routes";
import { colors } from "utils/styles";

const PATIENT_ROW_ENTITY = 'patientRows'
const updatePatientSearchTableQuery = query => updateSearchTableQuery(query, PATIENT_ROW_ENTITY)

class ManagePatientSearchTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: []
    };
  }

  componentDidMount() {
    const { fetchPatientFormAttributes, search_field, search_value, updateSearchTableQuery } = this.props
    fetchPatientFormAttributes();
    updateSearchTableQuery({
      table: "patients",
      field: search_field,
      value: search_value,
      page: 1,
      per: 150,
      direction: "asc",
      sort_by: "ID"
    })
  }

  componentDidUpdate(prevProps) {
    const { search_field, search_value, updateSearchTableQuery } = this.props
    if (
      (prevProps.search_field !== search_field) ||
      (prevProps.search_value !== search_value)) {
      updateSearchTableQuery({
        table: "patients",
        field: search_field,
        value: search_value,
        page: 1,
        per: 150,
        by: 'ID',
        direction: "asc"
      })
    }
  }

  updateQuery(newQuery) {
    const { updateSearchTableQuery, query } = this.props
    //handle 'asc' & 'desc' sorting by columns
    //if user clicked the same column as last time, toggle direction
    if (newQuery.sort_by != "" &&
      query.sort_by != "" &&
      query.sort_by === newQuery.sort_by) {
      newQuery.direction = query.direction === "asc" ? "desc" : "asc"
      //if user is sorting a different column, set direction to 'asc'
    } else if (
      query.sort_by != newQuery.sort_by) {
      newQuery.direction = "asc"
    }
    if (newQuery.filter_by && newQuery.filter_by !== "") {
      newQuery.filter = { [newQuery.filter_by.replace(" ", "_")]: newQuery.filter_value }
    }
    if (query.filter && (newQuery.order_by !== query.filter.order)) {
      newQuery.filter = { order: newQuery.order_by }
    }
    newQuery.table = "patients"
    updateSearchTableQuery(newQuery)
  }


  paginationAction(action) {
    const { search_field, search_value, updateSearchTableQuery } = this.props
    const sortBy = action.sort_by || null

    updateSearchTableQuery({
      table: "patients",
      field: search_field,
      value: search_value,
      page: action.page,
      per: action.per,
      sortBy: sortBy
    })
  }

  handleExportTable() {
    const { query, search_field, search_value, user, exportTable } = this.props;
    const searchParams = {
      sort_by: "ID",
      direction: "asc",
      page: query.page,
      //backend needs 'query.count' here, not '.per'
      //because it export all results not just those on page
      per: query.count,
      search_by: search_field,
      search_value: search_value,
      filter_by: [
      ]
    }
    exportTable(fromJS(searchParams), user.get("id"), "patients");
  }

  render() {
    const {
      patients,
      sleepCoaches,
      query,
      search_field,
      search_value
    } = this.props
    const ready = ((patients.length > 0) || (query)) && this.props.ready
    const button = {
      path: `/`,
      value: `Close ${search_value} patients`
    };
    const filterColumn = (column, value) => {
      this.updateQuery({
        column_filters: {
          [column]: value
        }
      }, query);
    }
    return ready
      ? <div>
          <PageHeader
            title={`Patients where ${deSnakeCase(search_field).toLowerCase()} is ${search_value}`}
            button={button}
            count={query.count}
          />
          {ready && (patients.size === 0 || query.count === 0) && <div style={S.none}>None found</div>}

          {ready
            ? <div className="row">
              <div className="row table-topper collapse">
                <div className="columns">
                  <select
                    className="small-2"
                    onChange={e =>
                      this.updateQuery({ per: e.target.value })}
                    value={query.per}
                  >
                    <option value="15">15</option>
                    <option value="150">150</option>
                    <option value="300">300</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  <table className="large-12">
                    <thead>
                      <tr>
                        <th
                          style={S.filter}
                          onClick={() =>
                            this.updateQuery({ sort_by: "name" })}
                        >
                          Name
                        </th>
                        <th
                          style={S.filter}
                          onClick={() =>
                            this.updateQuery({ sort_by: "ID" })}
                        >
                          ID
                        </th>

                        <th
                          style={S.filter}
                          onClick={() =>
                            this.updateQuery(
                              { sort_by: "form_of_contact" },
                              query
                            )}
                        >
                          Contact Type
                        </th>
                        <th
                          style={S.filter}
                          onClick={() =>
                            this.updateQuery(
                              { sort_by: "insurance_company" },
                              query
                            )}
                        >
                          Insurance
                        </th>
                        <th style={S.filter}>
                          <PopupSelect
                            label="Sleep Coach"
                            list={flow(
                              sortBy('formAttributeIndex'),
                              map((c) => ({
                                text: c.name,
                                value: c.GUID
                              }))
                            )(sleepCoaches)}
                            onChange={guid => filterColumn("sleep_coach", guid)}
                          />
                        </th>
                        <th
                          style={S.filter}
                          onClick={() =>
                            this.updateQuery({ sort_by: "company" })}
                        >
                          Company
                        </th>                        
                      </tr>
                    </thead>
                    <tbody>
                      {patients.map((patient, i) => {
                        const nonCompliant = !patient.get("compliant")
                          ? "red"
                          : "";
                        return (
                          <tr
                            key={i}
                            className={nonCompliant}
                            style={{}}
                          >
                            <td style={S.nameTD}>
                              <Link to={`/patients/${patient.get("id")}`}>
                                {patient.get("first_name") +
                                  " " +
                                  patient.get("last_name")}
                              </Link>
                            </td>
                            <td style={S.td}>{patient.get("account_number")}</td>

                            <td style={S.td}>
                              {patient.get("form_of_contact")}
                            </td>
                            <td style={S.td}>
                              {patient.getIn(["insurance_company", "text"])}
                            </td>
                            <td style={S.td}>{patient.get("sleep_coach")}</td>
                            <td style={S.td}>{patient.get("company")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <TableFooter
                query={fromJS(query)}
                handleExportClick={this.handleExportTable.bind(this)}
                handlePagination={this.paginationAction.bind(this)}
                canExport={false}
              />
            </div>
            : <Waiting reason="Gathering and sorting your data..." />}
      </div>
      : <Waiting reason="fetching patients..." />
  }
}

export default withRouter(connect(
  (state, props) => {
    const entitiesPatients = state.getIn(["entities", PATIENT_ROW_ENTITY], Map()).toJS()
    const patientSearchResultIds = state.get('search').ids.patients || []
    const patientsSearchResults = patientSearchResultIds.map((patientId) => {
      return entitiesPatients[patientId]
    })

    return {
      search_value: getRouteParam(props, 'value'),
      search_field: getRouteParam(props, 'field'),
      user: state.get("user"),
      patients: fromJS(patientsSearchResults),
      ready: !state.getIn(["fetch", "PUT_SEARCH", "isFetching"]),
      sleepCoaches: state.getIn(["entities", "sleepCoaches"], Map()).toJS(),
      query: state.get("search").metaData.patients
    }
  },
  dispatch =>
    bindActionCreators(
      {
        exportTable,
        fetchPatientFormAttributes,
        updateSearchTableQuery: updatePatientSearchTableQuery
      },
      dispatch
    )
)(Radium(ManagePatientSearchTable)));

ManagePatientSearchTable.propTypes = {
  exportTable: PropTypes.func.isRequired,
  fetchPatientFormAttributes: PropTypes.func.isRequired,
  search_field: PropTypes.string.isRequired,
  search_value: PropTypes.string.isRequired,
  patients: PropTypes.object.isRequired,
  query: PropTypes.object,
  ready: PropTypes.bool,
  sleepCoaches: PropTypes.object.isRequired,
  updateSearchTableQuery: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const S = styler`
  offsetComp
    right: 20
    bottom: 20
    left: 20
  filter
    text-decoration: underline
    color: ${colors.primaryColor}
    cursor: pointer
  soft
    opacity: 0.6
  td
    white-space: nowrap
`;
