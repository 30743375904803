import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import InlineWaiting from "components/ui/inline-waiting";
import { connect } from "react-redux";
import {
  addEquipmentItem,
  addEquipmentItemById,
  fetchPatientEquipment
} from "actions/patient";
import { selectors } from "reducers";
import {
  AddEquipmentForm,
  AddEquipmentByIdForm
} from "components/forms/add-equipment";

class AddEquipment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false
    };
  }

  addPatientEquipmentById = async ({ product_id }) => {
    try {
      const { patientId, dispatch } = this.props;
      this.setState({
        isSubmitting: true
      });
      await dispatch(
        addEquipmentItemById({ patientId, productId: product_id })
      );
      await dispatch(fetchPatientEquipment({ patientId }));
    } finally {
      this.setState({
        isSubmitting: false
      });
    }
  };

  addPatientEquipment = async result => {
    try {
      const { equipmentTypeId, id } = result;
      const { patientId, dispatch } = this.props;
      this.setState({
        isSubmitting: true
      });
      await dispatch(
        addEquipmentItem({
          patientId,
          type: equipmentTypeId,
          productId: id
        })
      );
      await dispatch(fetchPatientEquipment({ patientId }));
    } finally {
      this.setState({
        isSubmitting: false
      });
    }
  };

  render() {
    const {
      isFetchingInventory,
      current_equip,
      patientId,
      isFetchingCompanyActiveEq,
      active_products,
      disabled
    } = this.props;

    return (
      <div className="add-patient-equipment">
        {isFetchingInventory ? (
          <InlineWaiting />
        ) : (
          <React.Fragment>
            <AddEquipmentByIdForm
              handleSubmit={this.addPatientEquipmentById}
              isSubmitting={this.state.isSubmitting}
              disabled={disabled}
              patientId={patientId}
            />
            <AddEquipmentForm
              handleSubmit={this.addPatientEquipment}
              isSubmitting={this.state.isSubmitting}
              current_equip={current_equip}
              disabled={disabled}
              patientId={patientId}
              active_products={active_products}
              isFetchingCompanyActiveEq={isFetchingCompanyActiveEq}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect(
  (state, { patientId }) => ({
    isFetchingInventory: selectors.isFetchingInventory(state),
    current_equip: selectors
      .getPatientsEquipment(state, patientId)
      .map(eq => eq.invItem_ID)
  }),
  dispatch => ({
    dispatch
  })
)(AddEquipment);

AddEquipment.propTypes = {
  patientId: PropTypes.string.isRequired,
  current_equip: PropTypes.array,
  isFetchingInventory: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isFetchingCompanyActiveEq: PropTypes.bool,
  active_products: PropTypes.arrayOf(PropTypes.string)
};

AddEquipment.defaultProps = {
  disabled: false
};
