import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { InlineWaiting } from "components/ui";
import FormikField from "components/forms/formik-field";

const ProductVideoForm = ({ onSubmit, handleCancel, initialValues }) => (
  <div style={{ padding: 10 }}>
    <Formik
      onSubmit={async values => {
        await onSubmit(values);
      }}
      initialValues={initialValues}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormikField label="Title" name="title" type="text" />
          <FormikField label="Link" name="link" type="text" required />
          <FormikField
            label="Active"
            name="active"
            type="checkbox"
            checked={values.active}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between"
            }}
          >
            <button onClick={handleCancel} type="button" className="warning">
              Cancel
            </button>
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <button type="submit">Save</button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

ProductVideoForm.defaultProps = {
  initialValues: {
    link: "",
    title: "",
    active: true
  }
};

ProductVideoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  initialValues: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
    active: PropTypes.bool
  })
};

export default ProductVideoForm;
