import { take, call, put } from "redux-saga/effects";
import { browserHistory } from "browser-history";
import { Types } from 'actions/user-profile'
import { errorMessage } from "actions/message";
import * as api from 'utils/api'

function* exportUserInsights({payload}) {
  const url = `users/export_sleep_coach_insights`
  try {
    yield call(api.post, url, payload)
    yield call(api.get, 'exports')
    browserHistory.push("/exports");
  } catch(error) {
    yield put(errorMessage("An error occurred while exporting user insights."))
  }
}

export default function* watchExportUserInsights() {
  while (true) {
    const action = yield take(Types.REQUEST_EXPORT_USER_INSIGHTS)
    yield call(exportUserInsights, action)
  }
}
