import { Types } from "./actions";
import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";
import * as R from "ramda";
import { combineReducers } from "redux";

const clients = createReducer(
  {},
  {
    [Types.CLIENTS]: (_, { payload }) =>
      R.indexBy(R.prop("connectionId"), payload),
    [Types.CLIENT_CONNECTED]: (state, { payload }) =>
      R.assoc(R.prop("connectionId", payload), payload, state),
    [Types.CLIENT_DISCONNECTED]: (state, { payload }) =>
      R.dissoc(R.prop("connectionId", payload), state),
    [Types.CLIENT_UPDATED]: (state, { payload }) =>
      R.assoc(R.prop("connectionId", payload), payload, state),
    [Types.SIGNALR_DISCONNECTED]: () => ({})
  }
);

export default combineReducers({
  connected: createReducer(false, {
    [Types.SIGNALR_CONNECTED]: () => true,
    [Types.SIGNALR_CONNECTION_ERROR]: () => false,
    [Types.SIGNALR_DISCONNECTED]: () => false
  }),
  connecting: createReducer(false, {
    [Types.SIGNALR_CONNECTING]: () => true,
    [Types.SIGNALR_CONNECTED]: () => false,
    [Types.SIGNALR_CONNECTION_ERROR]: () => false,
    [Types.SIGNALR_DISCONNECTED]: () => false
  }),
  clients
});

/* ===== Selectors ===== */

const getSignalrState = createSelector(
  [state => state.getIn(["signalR"])],
  signalr_state => signalr_state
);

const getSignalrClientList = createSelector(
  [getSignalrState],
  R.pipe(R.prop("clients"))
);

const mergeConnections = connections => {
  const getAutoDialerStatus = R.reduce(
    (val, cur) =>
      !val || R.prop("autodialerOpenedAt", cur) > val
        ? R.prop("autodialerOpenedAt", cur)
        : val,
    ""
  );
  const getConnectedTime = R.reduce(
    (val, cur) =>
      !val || R.prop("connectedAt", cur) < val
        ? R.prop("connectedAt", cur)
        : val,
    ""
  );
  const getCallConnectedTime = R.reduce(
    (val, cur) =>
      R.prop("callConnectedAt", cur) ? R.prop("callConnectedAt", cur) : val,
    ""
  );
  return {
    ...R.pipe(R.nth(0), R.omit(["connectionId"]))(connections),
    connectedAt: getConnectedTime(connections),
    autodialerOpenedAt: getAutoDialerStatus(connections),
    callConnectedAt: getCallConnectedTime(connections)
  };
};

//array of online users
const getSignalrUserStatus = createSelector(
  [getSignalrClientList],
  R.pipe(
    R.values,
    R.groupBy(R.prop("userId")),
    R.map(mergeConnections),
    R.values
  )
);

//keyed by userId
export const getSignalrOnlineUsers = createSelector(
  [getSignalrUserStatus],
  R.indexBy(R.prop("userId"))
);

const getCurrentUserStatus = createSelector(
  [getSignalrOnlineUsers, (_, userId) => userId],
  (users, userId) => R.propOr({}, userId, users)
);

const getOnlineCoaches = createSelector(
  [getSignalrUserStatus],
  R.filter(u => u.role === "SleepCoach")
);

const getPhoneDashboardStatus = createSelector([getOnlineCoaches], users => ({
  onlineCoaches: users.length,
  workingCoachCount: users.filter(
    ({ autodialerOpenedAt }) => !!autodialerOpenedAt
  ).length,
  coachesInCall: users.filter(({ callConnectedAt }) => !!callConnectedAt).length
}));

export const selectors = {
  getSignalrState,
  getIsConnectedSignalr: createSelector([getSignalrState], R.prop("connected")),
  getSignalrClientList,
  getSignalrUserStatus,
  getSignalrOnlineUsers,
  getCurrentUserStatus,
  getPhoneDashboardStatus
};
