import React from "react";
import PropTypes from "prop-types";
import PatientsTable from "./patients-table";
import CoachStatsComplianceOutreach from "./coach-stats-compliance-outreach";
//import ScDashboardTableSelect from "./table-select";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PhoneNumberPrompt from "components/two-factor-auth/phone-number-prompt";

const CompliancePatientOutreach = ({ userRole, user, complianceCoaches }) => {
  {/* const [patientsToView, setPatientsToView] = */}
  {/*   React.useState("legacy_autodialer"); */}
  return (
    userRole != "ContractorAdministrator" && (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <PhoneNumberPrompt user={user} />
        <CoachStatsComplianceOutreach />
        {/* <ScDashboardTableSelect */}
        {/*   patientsToView={patientsToView} */}
        {/*   setPatientsToView={setPatientsToView} */}
        {/* /> */}
          <PatientsTable
            initialQuery={{
              sort: {
                by: "last_name",
                direction: "asc"
              },
              pagination: {
                page: 1,
                per: 500
              },
              filters: {}
            }}
            complianceCoachOptions={complianceCoaches}
          />
      </div>
    )
  );
};

export default connect(state => ({
  userRole: selectors.getUserRole(state),
  user: selectors.getUser(state),
  complianceCoaches: selectors.getComplianceCoaches(state)
}))(CompliancePatientOutreach);

CompliancePatientOutreach.propTypes = {
  userRole: PropTypes.string.isRequired,
  complianceCoaches: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired
};
