import React from 'react'
import PropTypes from 'prop-types'
import FaFileExcelO from 'react-icons/lib/fa/file-excel-o'

const ExportButton = ({ onClick, title }) => (
  <div
    title={title}
    className='s3-ui-export-button'
    onClick={onClick}>
    <FaFileExcelO />
  </div>
)

ExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string
}

export default ExportButton
