import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";
import { aLink } from "../../utils/styles";

const ProfileCard = ({ title, items, customDisplay, parentClassOveride }) => {
  const handleValue = (label, value) => {
    let domFrag;
    if (label === "Email") {
      domFrag = <a href={`mailto:${value}`}>{value}</a>;
    } else if (label === "Phone") {
      domFrag = <span style={aLink}>{value}</span>;
    } else if (Array.isArray(value)) {
      domFrag = (
        <ul style={S.innerUl}>
          {value.map((v, i) => (
            <li style={S.innerLi} key={i}>
              {v}
            </li>
          ))}
        </ul>
      );
    } else {
      domFrag = value;
    }
    return domFrag;
  };

  return (
    <div
     className="row"
    >
      <div
        style={S.container}
        className={ parentClassOveride || "small-12 columns"}
      >
        <span style={S.header}>
          {title && <h4>{title}</h4>}
          {customDisplay && <span>{customDisplay()}</span>}
        </span>
        <ul>
          {Object.keys(items).map((label, i) => (
            <li style={S.li} key={i}>
              <strong>{label}:</strong>
              {handleValue(label, items[label])}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Radium(ProfileCard);

ProfileCard.propTypes = {
  title: PropTypes.string,
  items: PropTypes.object,
  parentClassOveride: PropTypes.string,
  customDisplay: PropTypes.func
};

const S = styler`
  li
    display: flex
    justify-content: space-between
    flex-wrap: wrap
  innerUl
    margin: 6px 0px 0 5px
    padding-left: 10px
  innerLi
    border-bottom: none
    font-size: 11px
  container
    padding: 0
    margin-bottom: 1rem
    // margin: 0 0 20px 20px
  header
    display: grid 
    grid-template-columns: 1fr auto
    align-items: center
    border-bottom: 1px solid rgb(238, 238, 238)
`;
