import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { useFetch } from "hooks";
import { put } from "utils/api";
import CallRecordingSearchResults from "./callrecording-search-results";
import MdSearch from "react-icons/lib/md/search";

const CallRecordingSearchForm = () => {
  const { response: callrecordings, fetchData, isFetching } = useFetch({
    apiFn: values => put("dme_portal/search_callrecordings", values),
    defaultValue: [],
    transformResponse: ({ callrecordings }) => callrecordings
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 10,
        border: "1px solid #eee"
      }}
    >
      <Formik
        initialValues={{
          call_date_from: "",
          call_date_to: "",
          call_direction: "",
          client_id: "",
          call_sid: "",
          from_phone: "",
          to_phone: ""
        }}
        onSubmit={async values => {
          await fetchData(values);
        }}
        enableReinitialize={true}
      >
        {({ resetForm }) => (
          <Form className="callrecording-search-form">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: 10
              }}
            >
              <FormikField
                label="Call Date From"
                name="call_date_from"
                type="date"
              />
              <FormikField
                label="Call Date To"
                name="call_date_to"
                type="date"
              />
              <FormikField name="call_direction" label="Call Direction" component="select" type="text">
                <option value="">Any</option>
                <option value="incoming">Incoming</option>
                <option value="outgoing">Outgoing</option>
              </FormikField>
              <FormikField name="client_id" label="Client Id" type="text" />
              <FormikField name="call_sid" label="Call Sid" type="text" />
              <div></div>
              <FormikField name="from_phone" label="From Phone" type="text" />
              <FormikField name="to_phone" label="To Phone" type="text" />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "left"
              }}
            >
              <button
                type="button"
                onClick={resetForm}
                className="button warning"
              >
                Clear
              </button>&nbsp;
              <button type="submit" className="secondary">
                <MdSearch />
                Search
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <CallRecordingSearchResults
        callrecordings={callrecordings}
        isFetching={isFetching}
      />
    </div>
  );
};

CallRecordingSearchForm.defaultProps = { feature: "" };

CallRecordingSearchForm.propTypes = {
  feature: PropTypes.string
};

export default CallRecordingSearchForm;
