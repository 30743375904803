import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";
import MdPlus from "react-icons/lib/md/add";

const CmnCreateNoteEntry = ({ createNewCmnNote }) => (
  <Formik
    initialValues={{
      HCPC: "",
      Product: "",
      cmnInitialDate: "",
      cmnExpirationDate: "",
      Comments: "",
    }}
    onSubmit={async (values) => {
      try {
        await createNewCmnNote(values);
      } catch (e) {
        /* handle error */
      }
    }}
  >
    {({ isSubmitting }) => (
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <div>
          <FormikField label="HCPC" name="HCPC" type="text" />
        </div>
        <div>
          <FormikField label="Product" name="Product" type="text" />
        </div>
        <div>
          <FormikField label="Initial Date" name="cmnInitialDate" type="date" />
        </div>
        <div>
          <FormikField
            label="Expiration"
            name="cmnExpirationDate"
            type="date"
          />
        </div>
        <div>
          <FormikField label="Comments" name="Comments" as="textarea" />
        </div>
        <div
          style={{
            flex: 1,
            marginTop: 10,
            marginLeft: 10,
            minWidth: 120,
          }}
        >
          {isSubmitting ? (
            <InlineWaiting />
          ) : (
            <button style={{ padding: "5px 10px" }} type="submit">
              <MdPlus size={18} />
              <span style={{ paddingLeft: 5 }}>Add Entry</span>
            </button>
          )}
        </div>
      </Form>
    )}
  </Formik>
);

CmnCreateNoteEntry.propTypes = {
  createNewCmnNote: PropTypes.func.isRequired,
};
export default CmnCreateNoteEntry;
