export const Types = {
  CREATE_TAB: "CREATE_TAB",
  CLOSE_TAB: "CLOSE_TAB",
  OPEN_TAB: "OPEN_TAB",
  DESTROY_TAB: "DESTROY_TAB"
};

export const createTab = (tab, start_open = true) => ({
  type: Types.CREATE_TAB,
  payload: { tab },
  meta: { start_open }
});

export const destroyTab = key => ({
  type: Types.DESTROY_TAB,
  payload: { key }
});

export const openTab = key => ({
  type: Types.OPEN_TAB,
  payload: { key }
});

export const closeTab = key => ({
  type: Types.CLOSE_TAB,
  payload: { key }
});
