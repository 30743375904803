import { createReducer } from "utils/reducer";
import * as R from "ramda";

const Types = {
  SET_PATIENT_TASKS: "SET_PATIENT_TASKS",
  UPDATE_PATIENT_TASK: "UPDATE_PATIENT_TASK"
};

const patientTasks = createReducer(
  {},
  {
    [Types.SET_PATIENT_TASKS]: (state, { tasks }) =>
      R.indexBy(R.prop("task_id"), tasks),
    [Types.UPDATE_PATIENT_TASK]: (state, { task }) =>
      R.assocPath([task.task_id], task, state)
  }
);

export default patientTasks;
