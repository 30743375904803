import { formatDate } from "utils/dates";

export default patientInfo => ({
  address: patientInfo.address ?? {
    street_address: "",
    zip: "",
    state: ""
  },
  physician: patientInfo?.physician ?? {
    npi: "",
    name: "",
    address: {
      street_address: "",
      city: "",
      zip: "",
      state: ""
    },
    phone: ""
  },
  account_number: patientInfo.account_number,
  insurances:
    patientInfo?.insurances?.map(insurance => ({
      ...insurance,
      policy_start_date: formatDate(insurance.policy_start_date, "YYYY/MM/DD")
    })) ?? [],
  order_create_method: "Web App",
  issue_type: "",
  saw_physician_last: "",
  ordered_by: "patient",
  high_priority: false,
  other_issues: "",
  issues_with_pap_device: false,
  supply_period: "90",
  changed_insurance: null,
  changed_physician: false,
  changed_address: false,
  address_change_type: "T",
  office_pickup: false,
  caregiver_name: "",
  quantity_on_hand:
    "Only the singular items currently being used and needing replacement. No additional replacements on hand.",
  hours_per_night: "",
  nights_per_week: "",
  mask_dirty: true,
  mask_broken: true,
  mask_loose: true,
  headgear_loose: true,
  headgear_broken: true,
  seal_loose: true,
  seal_broken: true,
  filter_dirty: true,
  tubing_dirty: true,
  tubing_broken: true,
  waterchamber_dirty: true,
  waterchamber_broken: true,
  chinstrap_broken: true,
  chinstrap_loose: true,
  inpatient_facility: false,
  outside_purchase: false,
  changes_since_last_order: false,
  has_issue: false,
  reason_for_no_mask: ""
});
