import React from "react";
import OrderWorklistTable from "./table";

const OrderWorklist = () => {
  return (
    <div className="order-worklist-page no-print">
      <div className="order-worklist-header">Order Worklist</div>
      <OrderWorklistTable />
    </div>
  );
};

export default OrderWorklist;
