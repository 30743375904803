import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/dates";
import { PureModal, ListItem } from "components/ui";
import { put } from "utils/api";
import { errorMessage } from "actions/message";
import { useDispatch } from "react-redux";
import { aLink } from "utils/styles";

const InsuranceVerificationDetails = ({ insVerifications, orderId }) => {
  const dispatch = useDispatch();
  return (
    <div>
      {insVerifications?.map(x => (
        <div
          key={x.createdDt}
          style={{
            background: "#f9f9f9",
            border: "1px solid #ccc",
            padding: 5
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between"
            }}
          >
            <span style={{ fontWeight: "bolder" }}>{x.type} Insurance</span>
            <PureModal
              renderTrigger={({ openModal }) => (
                <span
                  style={{
                    cursor: "pointer",
                    color: "#4281A6",
                    lineHeight: "inherit",
                    textDecoration: "none"
                  }}
                  onClick={openModal}
                >
                  Details
                </span>
              )}
            >
              <div style={{ margin: 10 }}>
                <ListItem label="Insurance Type" value={x.type} />
                <ListItem label="Insurance Name" value={x.insuranceNm} />
                <ListItem label="Group No" value={x.groupNo} />
                <ListItem label="PolicyNo" value={x.policyNo} />
                <ListItem label="Status" value={x.status} />
                <ListItem
                  label="Eligibility Status"
                  value={x.eligibilityStatus}
                />
                <ListItem
                  label="Checked On"
                  value={formatDate(x.ebCheckedOn)}
                />
                <ListItem
                  label="Effective Date"
                  value={formatDate(x.effectiveDt)}
                />
                <ListItem
                  label="Created Date"
                  value={formatDate(x.ebCheckedOn)}
                />
                <ListItem
                  label="EB Response ID"
                  value={
                    <span
                      style={aLink}
                      onClick={async () => {
                        try {
                          var eligDocumentLink = await put(
                            `referral-answer/insurance-document-link`,
                            {
                              orderId,
                              traceId: x.ebResponesId,
                              integrationProviderName: x.integrationProviderName
                            }
                          );
                          window.open(eligDocumentLink, "_blank");
                        } catch (err) {
                          dispatch(errorMessage("Failed to load document"));
                        }
                      }}
                    >
                      {x.ebResponesId}
                    </span>
                  }
                />
                <ListItem
                  label="Integration Provider Name"
                  value={x.integrationProviderName}
                />
              </div>
            </PureModal>
          </div>
          <ListItem
            label="Eligibility Status"
            style={{ fontWeight: "normal", fontSize: "0.85em", padding: 0 }}
            value={x.eligibilityStatus}
          />
          <ListItem
            label="Checked On"
            style={{ fontWeight: "normal", fontSize: "0.85em", padding: 0 }}
            value={formatDate(x.ebCheckedOn)}
          />
        </div>
      ))}
    </div>
  );
};

InsuranceVerificationDetails.propTypes = {
  insVerifications: PropTypes.arrayOf(PropTypes.object),
  orderId: PropTypes.string.isRequired
};

export default InsuranceVerificationDetails;
