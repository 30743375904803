import React, { useState } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "components/ui/toggle-switch";

const UserOutboundCallSwitch = ({
  outboundCallRestricted,
  id,
  updateCoachSettings
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  return (
    <ToggleSwitch
      value={outboundCallRestricted}
      isUpdating={isUpdating}
      onChange={async () => {
        try {
          setIsUpdating(true);
          await updateCoachSettings(id, {
            outboundCallRestricted: !outboundCallRestricted
          });
        } finally {
          setIsUpdating(false);
        }
      }}
    />
  );
};

UserOutboundCallSwitch.propTypes = {
  outboundCallRestricted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  updateCoachSettings: PropTypes.func.isRequired
};

export default UserOutboundCallSwitch;
