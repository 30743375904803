import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import * as R from "ramda";
import MdCheck from "react-icons/lib/md/check";
import MdClose from "react-icons/lib/md/close";
import { compose, withProps } from "recompose";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withExport,
  withHeaderFooter
} from "features/query";
import { selectors } from "reducers";
import { withModal } from "components/ui";
import Table from "components/ui/impl/table";
import { createTab } from "actions/system-tray";
import { setPatientBatchIds } from "actions/batch";
import CompanyTeamSelect from "components/shared/company-team-select";
import SleepCoachTeamSelect from "components/shared/coach-team-select";
import DateRangeFilter from "components/shared/date-range-filter";
import AdminReviewCheckBox from "./admin-review-check-box";

const formatDate = date =>
  date ? moment(date).format("MMM Do, YYYY h:mm a") : "";

/* eslint-disable react/prop-types */
const PatientsContactsTable = compose(
  connect(
    state => ({
      formOfContactOptions: selectors.getEnumFormOfContact(state),
      sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state),
      companyOptions: selectors.getFormOptionsCompany(state)
    }),
    {
      createTab,
      setPatientBatchIds
    }
  ),
  withQuery(),
  withApi({
    api: {
      route: "contacts",
      responseKey: "contacts",
      idKey: "contact_guid",
      entityKey: "contactRows",
      method: "POST"
    }
  }),
  withExport(),
  withModal,
  withProps(({ companyOptions, sleepCoachOptions, createTab }) => ({
    disabledsort: true,
    columns: R.filter(R.is(Object), [
      {
        header: "Date",
        field: "date_time",
        format: ({ twilio_started, parent_call_sid, success }) => {
          if (parent_call_sid && success) {
            return (
              <div
                className="link"
                onClick={() => {
                  createTab({
                    sid: parent_call_sid,
                    key: "contact-record",
                    type: "contact-record",
                    label: "Call Log"
                  });
                }}
              >
                {formatDate(twilio_started)}
              </div>
            );
          } else {
            return formatDate(twilio_started);
          }
        },
        renderFilter: props => (
          <DateRangeFilter
            start_key="start"
            end_key="end"
            {...props}
          />
        )
      },
      {
        header: "Success",
        field: "success",
        filters: [
          { text: "Successful", value: "successful" },
          { text: "Unsuccessful", value: "unsuccesful" },
        ],
        format: ({ success }) => (
          success ? (
            <MdCheck color="green" size={16} />
          ) : (
            <MdClose color="red" size={16} />
          )
        )
      },
      {
        header: "Patient",
        field: "patient_name",
        search: true,
        format: ({ patient_first_name, patient_last_name, patient_guid }) => (
          <Link to={`/patients/${patient_guid}`}>
            {patient_first_name} {patient_last_name}
          </Link>
        )
      },
      {
        header: "Type",
        field: "type",
      },
      {
        header: "Category",
        field: "category",
        filters: [
          { text: "Outgoing Call", value: "Outgoing" },
          { text: "Incoming Call", value: "Incoming" },
          // { text: "Left Voicemail", value: "Left Voicemail" },
          // { text: "Resupply Request", value: "Resupply Request" },
          // { text: "Text Message", value: "Text Message" },
          // {
          //   text: "Download Mobile App Request",
          //   value: "Download Mobile App Request"
          // },
          // {
          //   text: "Consent To Text Message Request",
          //   value: "Consent To Text Message Request"
          // },
          // { text: "Other", value: "Other" }
        ]
      },
      {
        header: "Sleep Coach",
        field: "client_guid",
        filters: sleepCoachOptions,
        subFilter: SleepCoachTeamSelect,
        filterDownSelect: true,
        format: ({ client_first_name, client_last_name, client_guid }) => (
          <Link to={`/users/${client_guid}`}>
            {client_first_name} {client_last_name}
          </Link>
        )
      },
      {
        header: "Company",
        field: "company_guid",
        filters: companyOptions,
        filterDownSelect: true,
        subFilter: CompanyTeamSelect,
        format: ({ company_name, company_guid }) => (
          <Link to={`/companies/${company_guid}/profile`}>{company_name}</Link>
        )
      },
      {
        header: "Admin Review",
        field: "admin_review",
        filters: [
          { text: "Review needed", value: "true" },
          { text: "No review needed", value: "false" },
        ],
        format: ({ admin_review, contact_guid }) => (
          <AdminReviewCheckBox adminReview={admin_review} contactId={contact_guid} />
        )
      }
    ]),
    showCount: true
  })),
  withHeaderFooter({
    perPageOptions: [15, 150, 300, 500],
    scrollable: true
  }),
  withTableHandlers(),
)(Table);

export default PatientsContactsTable;
