import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import PureWindow from "components/ui/modal/pure-window";
import { inactivityLogout } from "actions/authentication";
import { selectors } from "reducers";
import { useDispatch, useSelector } from "react-redux";

const TIMEOUT = 1000 * 60 * 14; // 14 minutes
const PROMPT_TIMEOUT = 1000 * 60; // 1 minute

function App() {
  const [showIdleWarning, setShowIdleWarning] = useState(false);
  const [remainingIdleTime, setRemainingIdleTime] = useState(0);

  const userRole = useSelector(selectors.getUserRole);
  const isConnected = useSelector(selectors.getIsPhoneConnected);

  // Pause if on call and user is not a sleep coach
  const shouldPause = isConnected && userRole !== "SleepCoach";

  const dispatch = useDispatch();

  const handleStillHere = () => {
    setShowIdleWarning(false);
    activate();
  };

  const onPrompt = () => {
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setShowIdleWarning(true);
    setRemainingIdleTime(PROMPT_TIMEOUT);
  };

  const onIdle = () => {
    // Events will be rebound as long as `stopOnMount` is not set.
    setShowIdleWarning(false);
    setRemainingIdleTime(0);
    dispatch(inactivityLogout());
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setShowIdleWarning(false);
    setRemainingIdleTime(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_TIMEOUT,
    onPrompt,
    onIdle,
    onActive,
    disabled: shouldPause,
    crossTab: true,
    name: "idle-timer"
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemainingIdleTime(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return showIdleWarning ? (
    <PureWindow minimize={false} closeable={false}>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          fontSize: "1.15em",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h1>Session Timeout</h1>
        <p
          style={{
            fontSize: "1.15em"
          }}
        >
          For security reasons inactive sessions are automatically closed.
        </p>
        <p
          style={{
            fontSize: "1.15em"
          }}
        >
          Your session will close in {remainingIdleTime} seconds
        </p>
        <p
          style={{
            fontSize: "1.15em"
          }}
        >
          If you wish to remain logged please click continue below
        </p>
        <button onClick={handleStillHere}>Continue</button>
      </div>
    </PureWindow>
  ) : null;
}

export default App;
