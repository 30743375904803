import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { put } from "utils/api";
import { formatDate } from "utils/dates";
import { MdFileDownload } from "react-icons/lib/md";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import DateRangeHeaderColumn from "hooks/useQuery/api-query/table-header/date-range-header-filter";
import ResendPdfButton from "./resend-pdf-button";
import { selectors } from "reducers";
import { useFetch } from "hooks";

const PdfSignatureHistory = ({ companies }) => {
  const resendPDFTemplateApi = useFetch({
    apiFn: params => {
      console.log(params);
      return put(`dme_portal/signature_documents`, params);
    },
    defaultValue: []
  });
  // export const resendPDFTemplate = (
  //   signature_document_id,
  //   patients
  // ) => dispatch => {
  //   return API()
  //     .put("dme_portal/signature_documents", {
  //       signature_document_id,
  //       patients: patients.map(({ id }) => id)
  //     })
  //     .then(res => {
  //       if (res && res.data && res.data.success) {
  //         dispatch(message(res.data.message));
  //       } else {
  //         dispatch(errorMessage(res.data.message));
  //       }
  //     })
  //     .catch(err => {
  //       const responseErrorMessage = err.response.data.message;
  //       dispatch(errorMessage(responseErrorMessage));
  //     });
  // };
  const initialQuery = {
    sort: {},
    filters: {},
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("dme_portal/pdf_signatures", initialQuery, "pdf_signatures");
  return (
    <Table
      containerClass="query-table manage-pdf-table"
      isFetching={isFetching}
      fetchData={fetchData}
      rows={rows}
      query={query}
      queryDispatcher={queryDispatcher}
      data={data}
      rowKey="id"
      perPageOptions={[
        { text: "50", value: 50 },
        { text: "150", value: 150 },
        { text: "500", value: 500 }
      ]}
      columns={[
        {
          header: "Document",
          field: "template_title"
        },
        {
          header: "Patient",
          field: "patient_name"
        },
        {
          header: "Account #",
          field: "patient_account"
        },
        {
          header: "Company",
          field: "company_id",
          filters: companies,
          filterDownSelect: true
        },
        {
          header: "Sent By",
          field: "sent_by_guid"
        },
        {
          header: "Status",
          field: "status",
          filters: [
            {
              value: "Signed",
              text: "Signed"
            },
            {
              value: "Pending",
              text: "Pending"
            },
            {
              value: "Expired",
              text: "Expired"
            }
          ]
        },
        // {
        //   header: "Date Created",
        //   field: "date_created",
        //   Cell: () => (
        //     <DateRangeHeaderColumn header="Date Created" field="date_created" />
        //   )
        // },
        {
          header: "Date Sent",
          field: "date_sent",
          Cell: () => (
            <DateRangeHeaderColumn header="Date Sent" field="date_sent" />
          )
        },
        {
          header: "Expires",
          field: "email_link_expiration"
        },
        {
          field: "controls",
          header: " "
        }
      ]}
      TableRow={row => (
        <tr>
          <td>{row.template_title}</td>
          <td>
            <Link to={`/patients/${row.patient_id}`}>{row.patient_name}</Link>
          </td>
          <td>{row.patient_account}</td>
          <td>{row.company_name}</td>
          <td>{row.sent_by_name}</td>
          <td style={{ color: row.signed ? "green" : "default" }}>
            {row.status}
          </td>
          {/*<td>{formatDate(row.date_created)}</td>*/}
          <td>{formatDate(row.date_sent)}</td>
          <td>{formatDate(row.email_link_expiration)}</td>
          <td>
            {!row.signed && (
              <ResendPdfButton
                patient_id={row.patient_id}
                resendPDFTemplate={async params => {
                  await resendPDFTemplateApi.fetchData(params);
                  fetchData();
                }}
                isFetching={resendPDFTemplateApi.isFetching}
                template_guid={row.template_guid}
                id={row.id}
                assignment_id={row.assignment_id}
              />
            )}
            {row.download_url && row.signed && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={row.download_url}
                style={{ color: "green" }}
              >
                <MdFileDownload style={{ fontSize: 20 }} /> Download
              </a>
            )}
          </td>
        </tr>
      )}
    />
  );
};

export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state)
}))(PdfSignatureHistory);

PdfSignatureHistory.propTypes = {
  companies: PropTypes.array.isRequired
};
