import React from "react";
import PropTypes from "prop-types";
import { IconButton, PureModal, InlineWaiting } from "components/ui";
import { MdAdd, MdDeleteSweep } from "react-icons/lib/md";
import SectionHeader from "components/profile/section_header";
import UploadPatientDocument from "./upload-document";
import { useFetch } from "hooks";
import { get, del } from "utils/api";
import { propOr } from "ramda";

const PatientDocuments = ({ readOnly, patientId }) => {
  const trashColor = readOnly ? "gray" : "red";
  const {
    response: patientDocuments,
    isFetching,
    fetchData
  } = useFetch({
    apiFn: patientId => get(`dme_portal/patients/${patientId}/documents`),
    defaultValue: [],
    transformResponse: propOr([], "documents")
  });
  const { isFetching: isDeletingDocument, fetchData: deleteDocument } =
    useFetch({
      apiFn: ({ patientId, document_id }) =>
        del(`dme_portal/patients/${patientId}/documents/${document_id}`)
    });
  React.useEffect(() => {
    fetchData(patientId);
  }, [patientId]);
  return (
    <div style={{ padding: 5 }}>
      <SectionHeader>
        Documents
        <PureModal
          renderTrigger={({ openModal }) => (
            <IconButton
              icon={<MdAdd />}
              text="Add"
              onClick={openModal}
              style={{ fontSize: "12px", float: "right" }}
              disabled={readOnly}
            />
          )}
          renderContent={({ closeModal }) => (
            <UploadPatientDocument
              patientId={patientId}
              closeModal={() => {
                closeModal();
                fetchData(patientId);
              }}
            />
          )}
        />
      </SectionHeader>
      <div>
        <table>
          <thead>
            <tr>
              <td>Title</td>
              <td width="25%">Category Name</td>
              <td width="10%">Link</td>
              <td width="5%">Delete</td>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <tr>
                <td colSpan={4}>Fetching the document data....</td>
              </tr>
            ) : patientDocuments.length === 0 ? (
              <tr>
                <td colSpan={4}>No Document Found</td>
              </tr>
            ) : (
              patientDocuments
                ?.filter(d => d.active)
                .map(({ document_id, title, document_category_name, file }) => (
                  <tr key={document_id}>
                    <td>{title}</td>
                    <td>{document_category_name}</td>
                    <td>
                      {file.length > 0 ? (
                        <a href={file} target="blank">
                          Link
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {isDeletingDocument ? (
                        <InlineWaiting />
                      ) : (
                        <MdDeleteSweep
                          color={trashColor}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={async () => {
                            if (
                              confirm(
                                "Are you sure you want to archive this document?"
                              )
                            ) {
                              await deleteDocument({ patientId, document_id });
                              await fetchData(patientId);
                            }
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PatientDocuments.propTypes = {
  patientId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

export default PatientDocuments;
