import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  LabelList,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import { PrintSectionTitle } from "components/screens/company/print-company-monthly-report";
import { formatNumbersForReports } from "utils/misc";

const PrintOrdersCompleted = ({ data }) => (
  <Fragment>
    <div>
      <div className="print print-company-monthly-report_table-container">
        <PrintSectionTitle title="Orders Completed" />
        {data && (
          <Fragment>
            <div className="row">
              <table className="print-company-monthly-report_orders-completed-table">
                <tbody>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Completed Orders</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("completed_orders"))}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Total Line Items</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("total_line_items"))}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Average Items Per Order</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("avg_items_per_order"))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <table className="print-company-monthly-report_orders-completed-table">
                <tbody>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Masks Sold</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("masks_sold"))}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Masks(with headgear) Sold</td>
                    <td className="text-right">
                      {formatNumbersForReports(
                        data.get("masks_with_headgear_sold")
                      )}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Seals Sold</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("seals_sold"))}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Disposable Filters Sold</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("disp_filters_sold"))}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Non-Disposable Filters Sold</td>
                    <td className="text-right">
                      {formatNumbersForReports(
                        data.get("non_disp_filters_sold")
                      )}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Chinstraps Sold</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("chinstraps_sold"))}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Waterchambers Sold</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("waterchambers_sold"))}
                    </td>
                  </tr>
                  <tr className="print-company-monthly-report_table-row">
                    <td>Miscellaneous</td>
                    <td className="text-right">
                      {formatNumbersForReports(data.get("misc_sold"))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </div>
    </div>
    <div
      className="print-company-monthly-report_completed-orders-chart-container"
      style={{ padding: 50 }}
    >
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={[
            {
              label: "Masks(with headgear) Sold",
              count: data.get("masks_with_headgear_sold")
            },
            { label: "Masks Sold", count: data.get("masks_sold") },
            { label: "Seals Sold", count: data.get("seals_sold") },
            { label: "Tubing", count: data.get("tubing_sold") },
            {
              label: "Disp-Filters Sold",
              count: data.get("disp_filters_sold")
            },
            {
              label: "Non-Disp-Filters Sold",
              count: data.get("non_disp_filters_sold")
            },
            {
              label: "Chinstraps Sold",
              count: data.get("chinstraps_sold")
            },
            {
              label: "Waterchambers Sold",
              count: data.get("waterchambers_sold")
            },
            { label: "Miscellaneous", count: data.get("misc_sold") }
          ]}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <YAxis
            type="number"
            dataKey="count"
            domain={[0, dataMax => dataMax * 1.5]}
            scale="sqrt"
          />
          <XAxis dataKey="label" type="category" />
          <Bar
            dataKey="count"
            fill="rgba(45, 198, 184, 0.89)"
            isAnimationActive={false}
          >
            <LabelList dataKey="count" position="top" />
          </Bar>
          <Tooltip cursor={false} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </Fragment>
);

PrintOrdersCompleted.propTypes = {
  data: PropTypes.object.isRequired
};

export default PrintOrdersCompleted;
