// import only neccessarry icons to keep bundle size small
// this should be unnessecary when updated to react-icons v3
import Headset from "react-icons/lib/md/headset"
import Person from "react-icons/lib/io/ios-person"
import Hammer from "react-icons/lib/io/hammer"

export default {
  Headset,
  Person,
  Hammer
}
