import React from "react";
import PropTypes from "prop-types";
import { aLink } from "utils/styles";
import GoLinkExternal from "react-icons/lib/go/link-external";

const getStatusTextColor = status => {
  switch (status) {
    case "success":
      return "green";
    case "failed":
      return "orange";
    case "error":
      return "red";
    default:
      return "#222";
  }
};

const BonafideOrderStatus = ({ response }) => {
  return (
    <div className="bonafide-status-result-container">
      {response?.status && (
        <React.Fragment>
          <div className="bonafide-overview">
            <div className="status-label" style={{ alignItems: "center" }}>
              <div>Bonafide Transaction:</div>
              <div
                className={`status-text`}
                style={{
                  color: getStatusTextColor(response.status)
                }}
              >
                {response.status}
              </div>
            </div>
            {response.order?.bonafideOrderUrl && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    ...aLink
                  }}
                  title="Open Order In Bonafide"
                  onClick={() => {
                    window.open(response.order?.bonafideOrderUrl, "_blank");
                  }}
                >
                  <GoLinkExternal size={14} />
                </span>
              </div>
            )}
          </div>
          <div className="status-label">
            <div style={{ fontSize: "1.15em", marginTop: 10 }}>
              Eligibility:
            </div>
            <div
              className={`status-text`}
              style={{
                color: response.eligStatus === "InActivity" ? "orange" : "#222",
                display: "flex",
                alignItems: "flex-end",
                fontSize: "1.15em"
              }}
            >
              {response.eligStatus}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

BonafideOrderStatus.propTypes = {
  response: PropTypes.object,
  requestedBy: PropTypes.string,
  requestedAt: PropTypes.string
};

export default BonafideOrderStatus;
