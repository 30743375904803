import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Formik, Form } from "formik";
import { InlineWaiting } from "components/ui";
import FormikField from "components/forms/formik-field";
import { submitSupportIssue } from "actions/support";
const Support = ({
  user,
  companies,
  submitSupportIssue
}) => {
  const initialValues = {
    attatchments: false,
    companyName: "",
    details: "",
    email: user?.email,
    emailOnFile: user?.email,
    files: "",
    issue: "",
    issuePriority: "Low",
    issueType: "",
    summary: "",
    userId: user?.id,
    userRole: user?.role,
    userName: `${user?.first_name} ${user?.last_name}`
  };

  const priorityList = [
    { 
      text: "Highest",
      value: "Highest"
    },
    { 
      text: "High",
      value: "High"
    },
    { 
      text: "Medium",
      value: "Medium"
    },
    { 
      text: "Low",
      value: "Low"
    },
    { 
      text: "Lowest",
      value: "Lowest"
    }
  ];

  const issueTypes = [
    {
      text: "Issue with order, shipping, or status",
      value: "Order"
    },
    {
      text: "Issue with patient details",
      value: "Patient"
    },
    {
      text: "Issue with the site or a technical question",
      value: "Site or Technical"
    },
    {
      text: "Other",
      value: "Other"
    },
  ]

  const setSelectedDocument = (event, values) => {
    const MBSize = (event.target.files[0].size / 1024);
    if (MBSize > 101376) {
        values.files = "";
        window.alert('File size must be less than 99MB.');
    } else {
      values.files = event.target.files[0];
    }
  }

  const clearProperty = (values, property) => {
    values[property] = "";
  }

  const returnInputType = (values) => {
    switch (values.issueType) {
      case "Order":
        clearProperty(values, "patientGUID");
        clearProperty(values, "summary");
        return <FormikField
          label="Order GUID if Applicable"
          name="orderGUID"
          type="input"
          value={values.orderGUID}
        />;
      case "Patient":
        clearProperty(values, "orderGUID");
        clearProperty(values, "summary");
        return <FormikField
          label="Patient GUID if Applicable"
          name="patientGUID"
          type="input"
          value={values.patientGUID}
        />;
      case "Site or Technical":
        clearProperty(values, "patientGUID");
        clearProperty(values, "orderGUID");
        clearProperty(values, "summary");
        return "";
      case "Other":
        clearProperty(values, "patientGUID");
        clearProperty(values, "orderGUID");
        return <FormikField
          label="Issue Type Label"
          name="summary"
          type="input"
          required
          value={values.summary}
        />;
      default:
        return "";
    }
  }

  return (
    <div className="support-form-container">
      <Formik
        onSubmit={async values => {
        await submitSupportIssue(values).then(() => {
          clearProperty(values, "summary");
          clearProperty(values, "companyName");
          clearProperty(values, "details");
          clearProperty(values, "issue");
          clearProperty(values, "issuePriority");
          clearProperty(values, "issueType");
          clearProperty(values, "orderGUID");
          clearProperty(values, "patientGUID");
        });
      }}
      initialValues={initialValues}
      >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="dd_row">
            <div className="dd_col-4">
              <FormikField
                label="Name"
                name="userName"
                component="input"
                disabled
                required
                value={values.userName}
                />
            </div>
            <div className="dd_col-8">
              <FormikField
                label="Contact Information: Email"
                name="email"
                component="input"
                required
                value={values.email}
                />
            </div>
          </div>
          <div className="dd_row">
            <div className="dd_col-3">
              <FormikField
                label="Issue Type"
                name="issueType"
                component="select"
                required
                value={values.issueType}
                >
                <option />
                  {issueTypes.map(({ text, value }) => (
                    <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
            </div>
            <div className="dd_col-3">
              <FormikField
                label="Issue Priority"
                name="issuePriority"
                component="select"
                value={values.issuePriority}
                >
                <option />
                  {priorityList.map(({ text, value }) => (
                    <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
            </div>
            <div className="dd_col-6">
              {
                values.issueType === ""
                ? ""
                : returnInputType(values)
              }
            </div>
          </div>
          <div className="dd_row" style={{ padding: "7px" }}>
            <FormikField
              label="Select Company if Company Specific:"
              name="companyName"
              component="select"
            >
            <option />
              {
                companies.map(({ text, value }) => (
                  <option key={value} value={text}>
                    {text}
                  </option>
                ))
              }
            </FormikField>
          </div>
          <div style={{ padding: "7px" }}>
            <FormikField
              label="Issue"
              name="issue"
              component="textarea"
              required
              />
            <FormikField
              label="Details"
              name="details"
              component="textarea"
              />
          </div>
          <div style={{ padding: "7px" }}>
            <FormikField
              label="Attatchments"
              name="attatchments"
              type="checkbox"
              checked={values.attatchments}
            />
            {
              values.attatchments
                ? <>
                    <input 
                      name="files"
                      type="file"
                      multiple
                      accept={".jpg,.jpeg,.png,.gif,.xls,.xlsx,.jpg,.jpeg,.png,.mp3,.pdf,.doc,.docx,.txt,.xls,.xlsx,.gif"}
                      onChange={event => setSelectedDocument(event, values)}
                    />
                    <small>
                      <label style={{ color: "red" }}>
                        File size should be less than 99MB
                      </label>
                      <label>
                        Screenshot Shortcut: Windows Key + Shift + &apos;S&apos;.
                        Screenshot is initially in clipboard only. You must save the file to your hard drive to then select it to upload it.
                      </label>
                    </small>
                  </>
                : clearProperty(values, "files")
            }
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              padding: "7px"
            }}
          >
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <button type="submit" disabled={isSubmitting}>Submit</button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  </div>
  );
};

Support.propTypes = {
  user: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  submitSupportIssue: PropTypes.func.isRequired
};

export default connect(state => ({
  user: selectors.getUser(state),
  companies: selectors.getFormOptionsCompany(state)
}),
  {
    submitSupportIssue
  }
)(Support);