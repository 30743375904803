import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import moment from "moment";
import Timer from "components/screens/team-dashboard/coach-status/timer";
import MdCheck from "react-icons/lib/md/check";
import { formatPercantage } from "utils/misc";
import { formatSeconds, getTimeEllapsedInSeconds } from "utils/dates";
import CoachControls from "components/screens/team-dashboard/coach-status/coach-controls";
import CoachName from "components/screens/team-dashboard/coach-status/coach-name";

const getCoachADPercentage = (
  appendCurrentValues,
  time_in_queue_seconds,
  total_time_in_seconds,
  currentUserStatus
) => {
  if (appendCurrentValues) {
    const { connectedAt, autodialerOpenedAt } = currentUserStatus;
    const totalOnlineTime =
      total_time_in_seconds + getTimeEllapsedInSeconds(connectedAt);
    const totalAdTime =
      time_in_queue_seconds + getTimeEllapsedInSeconds(autodialerOpenedAt);
    return formatPercantage(totalAdTime, totalOnlineTime, 1);
  } else {
    return formatPercantage(time_in_queue_seconds, total_time_in_seconds, 1);
  }
};

const EmployeeTableRow = React.memo(
  ({
    coach_name,
    coach_guid,
    contracted_employee,
    team_name,
    currentUserStatus,
    total_time_in_seconds,
    time_in_queue_seconds,
    requestedEndDate,
    userIsSleepCoach,
    isOnline,
    isInDialer,
    userOffshore
  }) => {
    const appendCurrentValues =
      requestedEndDate &&
      !moment(requestedEndDate, "MM/DD/YYYY").isBefore(moment(), "day");
    return (
      <tr>
        {!userOffshore && (
          <CoachControls
            coach_guid={coach_guid}
            userIsSleepCoach={userIsSleepCoach}
          />
        )}
        <CoachName
          coach_name={coach_name}
          coach_guid={coach_guid}
          userIsSleepCoach={userIsSleepCoach}
          contracted_employee={contracted_employee}
        />
        <td>{team_name}</td>
        <td>
          {isOnline && (
            <span style={{ paddingLeft: 5 }}>
              <MdCheck color="green" size={17} />
            </span>
          )}
        </td>
        <td>{isInDialer ? <MdCheck color="green" size={17} /> : null}</td>
        <td>
          {currentUserStatus.callConnectedAt ? (
            <Timer startedAt={currentUserStatus.callConnectedAt} />
          ) : null}
        </td>
        <td>
          {appendCurrentValues && currentUserStatus.connectedAt ? (
            <span style={{ color: "green" }}>
              <Timer
                startedAt={moment(
                  moment.tz(currentUserStatus.connectedAt, "US/Central")
                )
                  .subtract(total_time_in_seconds, "seconds")
                  .toISOString()}
                formatter={formatSeconds}
                tick={1000}
              />
            </span>
          ) : (
            formatSeconds(total_time_in_seconds)
          )}
        </td>
        <td>
          {appendCurrentValues && currentUserStatus.autodialerOpenedAt ? (
            <span style={{ color: "green" }}>
              <Timer
                startedAt={moment(
                  moment.tz(currentUserStatus.autodialerOpenedAt, "US/Central")
                )
                  .subtract(time_in_queue_seconds, "seconds")
                  .toISOString()}
                formatter={formatSeconds}
                tick={1000}
              />
            </span>
          ) : (
            formatSeconds(time_in_queue_seconds)
          )}
        </td>
        <td>
          <span>
            {getCoachADPercentage(
              appendCurrentValues,
              time_in_queue_seconds,
              total_time_in_seconds,
              currentUserStatus
            )}
          </span>
        </td>
      </tr>
    );
  }
);

EmployeeTableRow.propTypes = {
  coach_guid: PropTypes.string.isRequired,
  coach_name: PropTypes.string.isRequired,
  contracted_employee: PropTypes.bool,
  team_name: PropTypes.string,
  isOnline: PropTypes.bool,
  isInDialer: PropTypes.bool,
  currentUserStatus: PropTypes.shape({
    connectedAt: PropTypes.string,
    callConnectedAt: PropTypes.string,
    autodialerOpenedAt: PropTypes.string
  }),
  time_in_queue_seconds: PropTypes.number,
  total_time_in_seconds: PropTypes.number,
  userIsSleepCoach: PropTypes.bool,
  userOffshore: PropTypes.bool,
  requestedEndDate: PropTypes.string
};

const mapStateToProps = (state, { coach_guid }) => ({
  currentUserStatus: selectors.getCurrentUserStatus(state, coach_guid),
  userIsSleepCoach: selectors.getUserIsSleepCoach(state, coach_guid),
  userOffshore: selectors.getUserOffshore(state)
});

export default connect(mapStateToProps)(EmployeeTableRow);
