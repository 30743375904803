import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  // queuePatients,
  // flushPatientQueue,
  invalidatePatient,
  fetchPatient
} from "actions/patient";
import { clearPatientVerification } from "actions/patient-verification";
import { autoDialerSetActivePage } from "actions/auto-dialer";
import Pages from "features/pages";
import PatientProfile from "components/screens/patient/patient-profile";
import InboundPatientLookup from "./inbound-patient-lookup";
import PatientChatProfile from "./patient-profile-chat";
import AutoDialerCustomRenderer from "./autodialer-custom-renderer";
import DialerActions from "./dialer-actions";

const BatchAutoDialer = ({
  patientIds,
  activePageIndex,
  invalidatePatient,
  chatDetails,
  autoDialerSetActivePage,
  // queuePatients,
  // flushPatientQueue,
  proccessingInboundCall,
  fetchPatient,
  clearPatientVerification
}) => {
  useEffect(() => {
    //queuePatients(patientIds);
    return () => {
      //flushPatientQueue();
    };
  }, [patientIds]); /* eslint-disable-line */

  useEffect(() => {
    return () => {
      clearPatientVerification();
    };
  }, []);

  return (
    <>
      <DialerActions />
      {proccessingInboundCall ? (
        <InboundPatientLookup />
      ) : chatDetails ? (
        <PatientChatProfile chatDetails={chatDetails} />
      ) : (
        <Pages
          showNext={false}
          showSelect={false}
          showBack={false}
          activePageIndex={activePageIndex}
          onActivePageIndexChange={autoDialerSetActivePage}
          pages={patientIds.map(id => ({
            title: `patient`,
            view: (
              <PatientProfile
                fetchWaitDuration={360000}
                patientId={id} /*verify*/
              />
            ),
            onRefresh: () => {
              invalidatePatient({ patientId: id });
              fetchPatient({ patientId: id });
            }
          }))}
          customRender={<AutoDialerCustomRenderer />}
        />
      )}
    </>
  );
};

export default connect(
  state => ({
    proccessingInboundCall: selectors.getAutoDialerIsInboundCall(state),
    autoDialerStatus: selectors.getAutoDialerStatus(state),
    patientIds: selectors.getAutoDialerPatientIds(state),
    activePageIndex: selectors.getAutoDialerCurrentPageIndex(state),
    chatDetails: selectors.getAutoDialerChatDetails(state)
  }),
  {
    // queuePatients,
    // flushPatientQueue,
    invalidatePatient,
    fetchPatient,
    autoDialerSetActivePage,
    clearPatientVerification
  }
)(BatchAutoDialer);

BatchAutoDialer.propTypes = {
  activePageIndex: PropTypes.number,
  proccessingInboundCall: PropTypes.bool,
  autoDialerSetActivePage: PropTypes.func.isRequired,
  invalidatePatient: PropTypes.func.isRequired,
  fetchPatient: PropTypes.func.isRequired,
  // queuePatients: PropTypes.func.isRequired,
  // flushPatientQueue: PropTypes.func.isRequired,
  patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  chatDetails: PropTypes.object,
  clearPatientVerification: PropTypes.func.isRequired
};
