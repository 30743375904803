import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { addCompanyInventory } from "actions/company-inventory";


export const CompanyInventoryForm = ({sleepCoaches}) => {

    const coaches = sleepCoaches
    const dispatch = useDispatch();
    const [inventoryToAdd, setInventoryToAdd] = useState({ fkiAssignedTo: null });
    const [assignedUser, setAssignedUser] = useState("none")
    const handleUserInput = e => {
        const updatedState = { ...inventoryToAdd };
        updatedState[e.target.id] = e.target.value;
        setInventoryToAdd(updatedState);
      };

      const sleepCoachSelection = (e) =>{
          setAssignedUser(e.target.value)
      }

      const addInv = () => {
          dispatch(addCompanyInventory(inventoryToAdd, assignedUser));
      };
   
  return (
    
      <div className="addInventoryModal">

        <div className="field">
        <label>Serial Number</label>
          <input  onChange={handleUserInput} type="text" name="serialNumber" id="SSerialNumber" required/>
        </div>
        <label>Model Number</label>
        <div className="field">
          <input   onChange={handleUserInput} type="text" name="modelNumber" id="SModelNumber" required/>
        </div>
        <label>Notes</label>
        <div className="field">
          <input onChange={handleUserInput} type="text" name="additionalEquipment" id="SAdditionalEquipment" required/>
        </div>
        <label>Sleep Coach</label>
          <select onChange={sleepCoachSelection}>
            <option key={null} id={null}>None </option>
            {(coaches) ? coaches.map(({ text, value }) => (
              <option  id={value} value={value} key={value}>
                {text}
              </option>
            ))
        : ""}
          </select>
          <button className="button tiny" onClick={()=>{
              addInv();
          }}>
              Save
            </button>
      </div>
    
  );
};


export default connect(
    state => ({
      inventory: state.get("company-inventory")
    }),
    { addCompanyInventory }
  );

CompanyInventoryForm.propTypes = {
  sleepCoaches: PropTypes.arrayOf(PropTypes.object)
}
