import React from "react";
import PropTypes from "prop-types";
import { withModal, InlineWaiting } from "components/ui";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
// import { AddPatientToSleepGlad } from "features/sleepglad/actions";
import { useDispatch } from "react-redux";
import { PatientCompanyActiveEquipmentContext } from "components/forms/add-equipment";
import HasRolePermission from "components/shared/has-role-permission";
// import SleepgladBlacklistFormulary from "features/sleepglad/blacklist-formulary";
import { useFetch } from "hooks";
import { post } from "utils/api";
import { message, errorMessage } from "actions/message";

const MaskFittingEligibleButton = ({ patientId, openModal, closeModal }) => {
  const dispatch = useDispatch();
  const { active_products } = React.useContext(
    PatientCompanyActiveEquipmentContext
  );

  const { fetchData } = useFetch({
    apiFn: payload => post(`sleepglad/invitePatient`, payload),
    defaultValue: {},
    onSuccess: () => {
      dispatch(message("Mask Fitting Scan Started"));
    },
    onError: err => {
      dispatch(errorMessage(err.response?.body?.ExceptionMessage ?? "Sorry, Something went wrong"));
    }
  });
  return (
    <button
      className="button-mask-fit-request"
      onClick={() =>
        openModal(
          <MaskFittingQuestions
            active_products={active_products}
            handleCancel={closeModal}
            patientId={patientId}
            handleSubmit={async ({
              mask_type,
              blacklist_masks,
              reset,
              blacklist_manufacturers,
              ahi,
              cmH2O
            }) => {
              try {
                await fetchData({
                  patient_id: patientId,
                  mask_type,
                  blacklist_masks,
                  blacklist_manufacturers,
                  ahi,
                  reset,
                  cmH2O
                });
              } finally {
                closeModal();
              }
            }}
          />
        )
      }
    >
      Send Mask Fitting Request
    </button>
  );
};

MaskFittingEligibleButton.propTypes = {
  autofit_eligible: PropTypes.bool,
  patientId: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withModal(MaskFittingEligibleButton);

const MaskFittingQuestions = ({ handleSubmit, handleCancel }) => {
  return (
    <div style={{ padding: 5, margin: 5 }}>
      <Formik
        initialValues={{
          mask_type: "",
          notify_patient: true,
          blacklist_masks: [],
          blacklist_manufacturers: [],
          reset: false,
          ahi: "",
          cmH2O: ""
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 10
            }}
          >
            {/*<SleepgladBlacklistFormulary patientId={patientId} />*/}
            <FormikField
              name="mask_type"
              label="Preferred Mask Type"
              component="select"
            >
              <option>No Preference</option>
              <option value="Full Face">Full Face</option>
              <option value="Nasal Cushion">Nasal Cushion</option>
              <option value="Nasal Pillow">Nasal Pillow</option>
            </FormikField>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: 20,
                flex: 1
              }}
            >
              <FormikField
                label="Apnea Hypopnea Index"
                name="ahi"
                type="number"
                value={values.ahi}
              />
              <FormikField
                label="CPAP Pressure(cmH2O)"
                name="cmH2O"
                type="number"
                value={values.cmH2O}
              />
              <HasRolePermission allowedRoles={["Administrator"]}>
                <FormikField
                  label="Reset Patient"
                  name="reset"
                  type="checkbox"
                  checked={values.reset}
                />
              </HasRolePermission>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                marginTop: 20
              }}
            >
              <button
                style={{ margin: 0 }}
                className="button warning danger"
                onClick={handleCancel}
                type="button"
              >
                Cancel
              </button>
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <button style={{ margin: 0 }} type="submit">
                  Continue
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

MaskFittingQuestions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  active_products: PropTypes.arrayOf(PropTypes.string).isRequired,
  patientId: PropTypes.string.isRequired
};
