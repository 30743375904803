import React, { useState, useEffect, useMemo } from 'react';
import { PageHeader, Spinner } from "components/ui";
import { useDropzone } from 'react-dropzone';
import { IoCloseRound, IoEdit, IoImage } from 'react-icons/lib/io';
import { MdFileUpload } from 'react-icons/lib/md';
import OnboardingTile from './onboarding-tile';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, thumbsContainer, thumb, thumbInner } from './styles';
import { useFetch } from 'hooks';
import { path } from "ramda";
import { post, get, put } from 'utils/api';
import { getApiErrorMessage } from "utils/misc";
import * as msg from "actions/message";
import { useDispatch } from "react-redux";
import propTypes from "prop-types"

export default function Onboarding({ params }) {
  const companyId = params.id;
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [tiles, setTiles] = useState([]);
  const [companyLogo, setCompanyLogo] = useState("")
  const [editCompanyLogo, setEditCompanyLogo] = useState(false);

  const { fetchData: saveTile, isFetching: fetchingSaveTile } = useFetch({
    apiFn: (body) => post(`companies/${companyId}/onboarding`, body),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(msg.errorMessage(`Failed to save tile: ${getApiErrorMessage(error)}`))
    }
  });

  const { fetchData: fetchEditTile, isFetching: fetchingEditTile } = useFetch({
    apiFn: (body) => put(`companies/${companyId}/onboarding/${companyLogo.TileId}`, body),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(msg.errorMessage(`Failed to update tile: ${getApiErrorMessage(error)}`))
    }
  });

  const { fetchData: fetchTiles, isFetching: fetchingTiles } = useFetch({
    apiFn: () => get(`companies/${companyId}/onboarding`),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(msg.errorMessage(`Failed to get onboarding data: ${getApiErrorMessage(error)}`))
    }
  });

  const getTiles = async () => {
    const result = await fetchTiles();
    const newTiles = result.filter(x => !x.IsCompanyLogo);

    if (newTiles.length == 0)
      addTile();
    else
      setTiles(newTiles);

    const logo = result.filter(x => x.IsCompanyLogo)[0];
    if (logo) {
      setCompanyLogo(logo);
      setEditCompanyLogo(false);
    }
    else {
      setCompanyLogo({});
      setEditCompanyLogo(true);
    }
  }


  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'text/html': ['.html', '.htm'],
    },
    onDrop: async (acceptedFiles) => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));

      setFiles(newFiles[0]);
      const formData = new FormData();
      formData.append("file", newFiles[0]);
      formData.append("title", "Company Logo");
      formData.append("description", "Company Logo");

      if (companyLogo.TileId)
        await fetchEditTile(formData);
      else
        await saveTile(formData);

      setEditCompanyLogo(false);
      dispatch(msg.message("Company logo saved successfully"));
    }
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const thumbs = () => (
    <div style={thumb} key={companyLogo.FileUrl}>
      <div style={thumbInner}>

        <p style={{ margin: "0px" }}> <IoImage size={"25px"} /> {companyLogo.Title}</p>
      </div>
    </div>
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    getTiles();
  }, []);

  const addTile = () => {
    const newTile = { Title: "", Description: "", FileUrl: "", TileId: 0, IsCompanyLogo: false };
    setTiles([...tiles, newTile]);
  }

  const removeTile = (index) => {
    const newTile = tiles.filter((_, i) => i !== index)
    setTiles(newTile);
  }

  return (
    <div>
      <PageHeader title="Onboarding" />
      {
        !fetchingTiles &&
        <>
          <div>
            <h4 style={{ cursor: 'pointer', color: '#666' }}>Company logo
              {
                !(fetchingSaveTile || fetchingEditTile) && (
                  !editCompanyLogo ?
                    <IoEdit size={"16px"} style={{ marginLeft: '10px' }} onClick={() => setEditCompanyLogo(!editCompanyLogo)} />
                    :
                    <IoCloseRound size={"16px"} style={{ marginLeft: '10px' }} onClick={() => setEditCompanyLogo(!editCompanyLogo)} />)
              }

              {
                (fetchingSaveTile || fetchingEditTile) && <Spinner />
              }
            </h4>
            {!fetchingSaveTile && editCompanyLogo && <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <MdFileUpload size={"20px"} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drag and drop some files here, or click to select files</p>
              }
              <aside style={thumbsContainer}>
                {thumbs()}
              </aside>
            </div>}
            {
              !editCompanyLogo &&
              <>

                <img src={companyLogo.FileUrl} alt="Logo" style={{maxWidth: "100%", maxHeight: "20rem", display: "flex", margin: "0 auto"}}/>

              </>
            }
          </div>
          <br />
          {tiles.length < 6 && <button type="button" onClick={() => { addTile() }}>Add tile</button>}
          {
            !fetchingSaveTile && tiles && tiles.length > 0 &&
            tiles.map((tile, index) => (
              <OnboardingTile
                data={tile}
                key={index}
                index={index}
                saveTile={saveTile}
                companyId={companyId}
                getTiles={getTiles}
                fetchingCreateTile={fetchingSaveTile}
                removeTile={removeTile}
              />
            ))
          }
        </>
      }
      {fetchingTiles && <Spinner />}
    </div>
  )
}
Onboarding.propTypes = {
  params: propTypes.object
}