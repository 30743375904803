import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import MdArrowDropUp from "react-icons/lib/md/arrow-drop-up";
import MdArrowDropDown from "react-icons/lib/md/arrow-drop-down";
import * as R from "ramda";
import { selectors } from "reducers";
import { getCompanyPatientDemographicLogs } from "actions/company";
import { Spinner } from "components/ui";

const formatDate = (date) =>
  date ? moment(date).format("YYYY-MM-DD  hh:mm:ss") : "";

const PatientDemographicLogTable = ({
  patientDemographicLogs: { isFetching, logs, indexParams, total },
  companyId,
  getCompanyPatientDemographicLogs,
}) => {
  const getDemoLogs = (property, field, value) => {
    if (indexParams[property][field] === value) return;
    let indexParamsClone = R.clone(indexParams);
    indexParamsClone[property][field] = value;
    getCompanyPatientDemographicLogs(companyId, indexParamsClone);
  };

  const getDemoLogsWithArray = (properties) => {
    let indexParamsClone = R.clone(indexParams);
    properties.forEach((x) => (indexParamsClone[x[0]][x[1]] = x[2]));
    getCompanyPatientDemographicLogs(companyId, indexParamsClone);
  };

  const getSortState = (sortField) => {
    let { by, direction } = indexParams.sort;
    if (by === sortField) {
      return direction === "desc" ? <MdArrowDropUp /> : <MdArrowDropDown />;
    }
  };

  const setSort = (sortField) => {
    let { by, direction } = indexParams.sort;
    let directionToSort =
      sortField === by && direction === "asc" ? "desc" : "asc";
    let properties = [
      ["sort", "by", sortField],
      ["sort", "direction", directionToSort],
      ["pagination", "page", 1],
    ];
    getDemoLogsWithArray(properties);
  };

  let anyLogs = !isFetching && logs.length;
  let { page, per } = indexParams.pagination ?? (0, 0);
  let showPrevious = page > 1;
  let showNext = page * per < total;
  let totalPages = Math.floor(total / per) + 1;
  if (total % per == 0) totalPages--;

  return (
    <div className="demo-logs-table">
      <div className="demo-logs-header-container">
        <div className="demo-logs-header" onClick={() => setSort("Last_Name")}>
          Name{getSortState("Last_Name")}
        </div>
        <div
          className="demo-logs-header"
          onClick={() => setSort("account_number")}
        >
          Account Number{getSortState("account_number")}
        </div>
        <div className="demo-logs-header" onClick={() => setSort("timestamp")}>
          Timestamp{getSortState("timestamp")}
        </div>
        <div
          className="demo-logs-header"
          onClick={() => setSort("column_changed")}
        >
          Column Changed{getSortState("column_changed")}
        </div>
        <div className="demo-logs-header" onClick={() => setSort("old_value")}>
          Old Value{getSortState("old_value")}
        </div>
        <div className="demo-logs-header" onClick={() => setSort("new_value")}>
          New Value{getSortState("new_value")}
        </div>
      </div>
      <div className="demo-logs-content-container">
        {isFetching ? (
          <div className="spinner-container">
            <Spinner size="45px" />
          </div>
        ) : logs.length ? (
          logs.map((x) => (
            <Fragment key={`${x.timestamp} ${x.Last_Name} ${x.column_changed}`}>
              <div className="demo-logs-content">
                <Link
                  to={`/patients/${x.Patient_ID}`}
                >{`${x.First_Name} ${x.Last_Name}`}</Link>
              </div>
              <div className="demo-logs-content">{x.account_number}</div>
              <div className="demo-logs-content">{formatDate(x.timestamp)}</div>
              <div className="demo-logs-content">{x.column_changed}</div>
              <div className="demo-logs-content">{x.old_value}</div>
              <div className="demo-logs-content">{x.new_value}</div>
            </Fragment>
          ))
        ) : (
          <div className="demo-logs-not-found">
            No Patient Demographic Logs Found...
          </div>
        )}
      </div>
      <div className="pagination-control-container">
        {anyLogs && (
          <>
            <select
              className="pagination-control per-page-select"
              value={per}
              onChange={({ target: { value } }) =>
                getDemoLogs("pagination", "per", value)
              }
            >
              <option value={100}>100</option>
              <option value={150}>150</option>
              <option value={250}>250</option>
            </select>
            <div
              className="pagination-control clickable"
              onClick={() => getDemoLogs("pagination", "page", page - 1)}
            >
              {showPrevious && "Previous"}
            </div>
            <select
              className="pagination-control page-select"
              value={page}
              onChange={({ target: { value } }) =>
                getDemoLogs("pagination", "page", value)
              }
            >
              {[...Array(totalPages).keys()].map((x) => (
                <option key={x} value={x + 1}>{x + 1}</option>
              ))}
            </select>
            of {totalPages}
            <div
              className="pagination-control clickable"
              onClick={() => getDemoLogs("pagination", "page", page + 1)}
            >
              {showNext && "Next"}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PatientDemographicLogTable.propTypes = {
  logs: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  indexParams: PropTypes.object,
  getCompanyPatientDemographicLogs: PropTypes.func.isRequired,
  patientDemographicLogs: PropTypes.shape({
    isFetching: PropTypes.bool,
    logs: PropTypes.arrayOf(PropTypes.object),
    indexParams: PropTypes.object,
    total: PropTypes.number
  })
};

export default connect(
  (state) => ({
    patientDemographicLogs: selectors.getCompanyPatientDemographicLogs(state),
  }),
  {
    getCompanyPatientDemographicLogs,
  }
)(PatientDemographicLogTable);
