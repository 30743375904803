import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  sendPatientMaskFittingInstructions,
  updatePatientMaskFitElegibility,
  setPatientFittingRecommendedMask
} from "actions/patient";
import { InlineWaiting } from "components/ui";
import MaskFittingResult from "./fitting-result";
import FittingHistory from "./fitting-history";
import MaskFittingEligibleButton from "./eligible-button";
// import { useFetch } from "hooks";
// import { get } from "utils/api";
import * as R from "ramda";

export const MaskRecomendation = ({
  maskFittingResults,
  patient,
  isFetchingMaskFittingResults,
  sendPatientMaskFittingInstructions,
  setPatientFittingRecommendedMask,
  patientId
}) => {
  const [selectedTransaction, setSelectedTransaction] = React.useState({
    transaction_id: "",
    masks: []
  });
  const maskFittingResultHistory = maskFittingResults.filter(c => {
    return c.transaction_id !== selectedTransaction.transaction_id;
  });
  // const { response, fetchData } = useFetch({
  //   apiFn: patientId => get(`sleepglad/history/${patientId}`),
  //   defaultValue: {},
  //   transformError: R.path(["response", "body", "status"])
  // });
  // React.useEffect(() => {
  //   fetchData(patientId);
  // }, [patientId]);
  React.useEffect(() => {
    if (maskFittingResults.length > 0) {
      setSelectedTransaction(R.nth(0, maskFittingResults));
    }
  }, [maskFittingResults]);
  const autofit_eligible = R.prop("autofit_eligible", patient);
  return (
    <div className="mask-fitting">
      <h5 className="fitting-header-label">
        <span>Mask Fitting</span>
        <MaskFittingEligibleButton
          autofit_eligible={autofit_eligible}
          patientId={patientId}
        />
      </h5>
      <div className="mask-fitting-container">
        {isFetchingMaskFittingResults ? (
          <InlineWaiting />
        ) : (
          <div>
            {maskFittingResults.length === 0 ? (
              <div className="patient-mask-fitting-status">
                {autofit_eligible ? (
                  <div>Waiting for patient submission</div>
                ) : (
                  <div>Not Available</div>
                )}
              </div>
            ) : (
              <div>
                <MaskFittingResult
                  {...selectedTransaction}
                  patientId={patientId}
                  setPatientFittingRecommendedMask={
                    setPatientFittingRecommendedMask
                  }
                  sendPatientMaskFittingInstructions={
                    sendPatientMaskFittingInstructions
                  }
                />
                <FittingHistory
                  maskFittingResultHistory={maskFittingResultHistory}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

MaskRecomendation.propTypes = {
  patientId: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  updatePatientMaskFitElegibility: PropTypes.func.isRequired,
  maskFittingResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetchingMaskFittingResults: PropTypes.bool,
  sendPatientMaskFittingInstructions: PropTypes.func.isRequired,
  setPatientFittingRecommendedMask: PropTypes.func.isRequired
};

export default connect(
  (state, { patientId }) => ({
    patient: selectors.getPatientProfile(state, patientId),
    maskFittingResults: selectors.getPatientMaskFittingResults(
      state,
      patientId
    ),
    isFetchingMaskFittingResults: state.getIn(
      ["fetch", "GET_MASK_FITTING_RESULTS", patientId, "isFetching"],
      false
    )
  }),
  {
    updatePatientMaskFitElegibility,
    sendPatientMaskFittingInstructions,
    setPatientFittingRecommendedMask
  }
)(MaskRecomendation);
