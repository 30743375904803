import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { getCompanyPatientReport } from "actions/company";
import { ChartHeader } from "components/ui";
import PatientMasksOverview from "./patient-masks";
import PatientMachinesOverview from "./patient-machines";
import PhysicianInsuranceTable from "./physician-insurance";
import InactivationBreakdown from "./patient-inactivations";
import PatientInsurance from "./patient-insurance";
import DateRange from "components/ui/date-range";
import DateRangeContext from "../date-range-context";

const CompanyPatients = ({
  insuranceReport,
  patientInactiveCount,
  patientMachineCount,
  patientMaskCount,
  patientInsuranceCount,
  isFetchingPatientReport
}) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeIsValid,
    handleSubmit
  } = React.useContext(DateRangeContext);

  return (
    <div className="company-patient-page">
      <div className="company-patient-page-header">
        <DateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSubmit={handleSubmit}
          isFetching={isFetchingPatientReport}
          isValid={dateRangeIsValid}
        />
      </div>
      <div className="page-graphs">
        <InactivationBreakdown {...patientInactiveCount} />
        <div className="graph-container no-print">
          <ChartHeader title="Patient Masks Overview" />
          <PatientMasksOverview patientMaskCount={patientMaskCount} />
        </div>
        <div className="graph-container no-print">
          <ChartHeader title="Patient Machines Overview" />
          <PatientMachinesOverview patientMachineCount={patientMachineCount} />
        </div>
        <div className="graph-container no-print">
          <ChartHeader title="Physicians & Insurance" />
          <PhysicianInsuranceTable insuranceReport={insuranceReport} />
        </div>
        <div className="graph-container no-print">
          <ChartHeader title="Patient Insurance" />
          <PatientInsurance data={patientInsuranceCount} />
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state, props) => ({
    companyId: props.params.id,
    insuranceReport: selectors.getCompanyPhysicianReportInsurance(state),
    patientInactiveCount: selectors.getCompanyPatientReportInactive(state),
    patientMachineCount: selectors.getCompanyPatientReportMachineCount(state),
    patientMaskCount: selectors.getCompanyPatientReportMaskCount(state),
    patientInsuranceCount: selectors.getCompanyPatientInsuranceCount(state),
    isFetchingPatientReport: selectors.getIsFetchingPatientReport(state)
  }),
  {
    getCompanyPatientReport
  }
)(CompanyPatients);

CompanyPatients.propTypes = {
  getCompanyPatientReport: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  insuranceReport: PropTypes.arrayOf(PropTypes.object).isRequired,
  patientInactiveCount: PropTypes.shape({
    Deactivations: PropTypes.number,
    Activations: PropTypes.number
  }),
  patientMachineCount: PropTypes.arrayOf(PropTypes.object).isRequired,
  patientMaskCount: PropTypes.arrayOf(PropTypes.object).isRequired,
  patientInsuranceCount: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetchingPatientReport: PropTypes.bool
};
