import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import SelectDropdown from "components/forms/select-dropdown";

const EmailGroupAddressForm = ({
  emailGroupID,
  addNewEmailGroupAddress,
  setCreateNewEmailGroupAddressState,
  nonPatientUsers
}) => {
  const [selectedUser, setselectedUser] = useState(null);
  const [selectedUserCompany] = useState(null);
  const [memberAddress] = useState(null);
  const [role] = useState(null);


  return (
    <div className="emailGroupInfo emailGroupDropShadow">
      <div style={{ margin: "10px" }}>
        <div className="center">
          <h2 style={{ color: "#02b4a6", marginTop: "10px" }}>
            Email Group Form
          </h2>
          <div style={{ all: "unset" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                alignItems: "center",
                columnGap: "50px"
              }}
            >
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="center">Choose A User</div>
            <SelectDropdown
              required={true}
              errorMessage="You must choose a user"
              mainHeight={45}
              searchable={true}
              onChange={item => {
                setselectedUser(item);
              }}
              placeHolder="Choose An Exisiting S3 User..."
              value={selectedUser}
              options={nonPatientUsers}
            />
          </div>
          <div
            style={{ marginTop: "20px" }}
            onClick={() => {
              addNewEmailGroupAddress({
                email_group_id: emailGroupID,
                first_name: null,
                last_name: null,
                role: role,
                member_name: null,
                person: true,
                email_address: memberAddress,
                user_id: selectedUser,
                company_id: selectedUserCompany
              });
            }}
            className="submitButton displayGridOne"
          >
            <span className="justifyAndAlignSelfCenter">SUBMIT</span>
          </div>
          <div
            style={{ marginTop: "20px", marginBottom: "30px" }}
            onClick={() => {
              setCreateNewEmailGroupAddressState(false);
            }}
            className="submitButton displayGridOne"
          >
            <span className="justifyAndAlignSelfCenter">CANCEL</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state)
}))(EmailGroupAddressForm);

EmailGroupAddressForm.propTypes = {
  emailGroupID: PropTypes.string.isRequired,
  addNewEmailGroupAddress: PropTypes.func.isRequired,
  setCreateNewEmailGroupAddressState: PropTypes.func.isRequired,
  nonPatientUsers: PropTypes.array.isRequired
};
