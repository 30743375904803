import React, { useState } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";
import PureWindow from "./pure-window";
import useKeyboardEvent from "hooks/useKeyboardEvent";
import { useSelector } from "react-redux";
import { selectors } from "reducers";

const PureModal = ({
  startOpen,
  persistent,
  renderTrigger,
  renderContent,
  children,
  windowProps,
  onClose
}) => {
  const [modalOpen, setModalOpen] = useState(startOpen);
  const chatIsOpen = useSelector(selectors.getChatIsOpen);
  useKeyboardEvent("Escape", () => {
  if (typeof onClose === "function") onClose();
    setModalOpen(false);
  });
  return (
    <React.Fragment>
      {renderTrigger({ openModal: () => setModalOpen(true) })}
      {persistent ? (
        <Portal>
          <PureWindow
            onXClick={() => setModalOpen(false)}
            hidden={!modalOpen}
            {...windowProps}
          >
            {renderContent &&
              renderContent({ closeModal: () => setModalOpen(false) })}
            {children}
          </PureWindow>
        </Portal>
      ) : (
        modalOpen && (
          <Portal>
            <PureWindow
              onXClick={() => {
                if (typeof onClose === "function") onClose();
                setModalOpen(false);
              }}
              {...windowProps}
              chatIsOpen={chatIsOpen}
            >
              {renderContent &&
                renderContent({ closeModal: () => setModalOpen(false) })}
              {children}
            </PureWindow>
          </Portal>
        )
      )}
    </React.Fragment>
  );
};

PureModal.propTypes = {
  startOpen: PropTypes.bool,
  persistent: PropTypes.bool,
  renderTrigger: PropTypes.func,
  renderContent: PropTypes.func,
  children: PropTypes.node,
  windowProps: PropTypes.object,
  onClose: PropTypes.func
};

export default PureModal;
