import React, { Component } from "react";
import PropTypes from "prop-types";
import { fetchLineItemCountHeatMap } from "actions/heat-map.js";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Waiting } from "components/ui";
import HeatMapTable from "components/ui/statistics/heat-map-table";

class UserLineItemHeatMap extends Component {
  componentDidMount() {
    const {
      dates: { startDate, endDate },
      userId,
      fetchLineItemCountHeatMap
    } = this.props;

    fetchLineItemCountHeatMap({
      payload: {
        id: userId,
        start_date: startDate,
        end_date: endDate,
        entity_type: "SleepCoachLineItemCounts"
      }
    });
  }

  addOrShiftDatesOneUnit = (newZoomStartDate, newZoomEndDate, tableData) => {
    const { userId, fetchLineItemCountHeatMap } = this.props;
    const {
      zoomLevel,
      originalZoomLevel,
      startDate,
      endDate,
      entityType
    } = tableData;
    const payload = {
      id: userId,
      start_date: startDate,
      end_date: endDate,
      entity_type: entityType,
      original_zoom_level: originalZoomLevel,
      go_to_original_zoom_level: false,
      zoom_date: null,
      next_zoom_level: zoomLevel,
      shift_dates: {
        newZoomEndDate: newZoomEndDate,
        newZoomStartDate: newZoomStartDate
      }
    };
    fetchLineItemCountHeatMap({
      payload
    });
  };

  zoomDate = (
    nextZoomLevel,
    zoomDate,
    tableData,
    goToOriginalZoomLevel = false
  ) => {
    const { userId, fetchLineItemCountHeatMap } = this.props;
    const { originalZoomLevel, startDate, endDate, entityType } = tableData;
    const payload = {
      id: userId,
      start_date: startDate,
      end_date: endDate,
      entity_type: entityType,
      original_zoom_level: originalZoomLevel,
      go_to_original_zoom_level: goToOriginalZoomLevel,
      zoom_date: zoomDate,
      next_zoom_level: nextZoomLevel
    };
    fetchLineItemCountHeatMap({
      payload
    });
  };
  render() {
    const { lineItemHeatMap, isFetching } = this.props;
    return lineItemHeatMap && lineItemHeatMap.columns ? (
      <div className="heatMap" style={{ margin: "20px", minHeight: 745 }}>
        <HeatMapTable
          shiftDatesOneUnit={this.addOrShiftDatesOneUnit}
          zoomDate={this.zoomDate}
          tableData={lineItemHeatMap}
          isFetching={isFetching}
        />
      </div>
    ) : (
      <Waiting />
    );
  }
}

UserLineItemHeatMap.propTypes = {
  fetchLineItemCountHeatMap: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  lineItemHeatMap: PropTypes.object.isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  }).isRequired,
  isFetching: PropTypes.bool
};

export default connect(
  state => ({
    lineItemHeatMap: selectors.getLineItemCountHeatMap(state),
    isFetching: selectors.getIsFetchingLineItemCountHeatMap(state)
  }),
  { fetchLineItemCountHeatMap }
)(UserLineItemHeatMap);
