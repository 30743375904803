import React from "react";
import PropTypes from "prop-types";
import StatsListItem from "./stats_list_item";
import { Pie, PieChart, Tooltip, ResponsiveContainer } from "recharts";

const PatientStatistics = ({ insights }) => (
  <div className="graph-card">
    <div className="card-top">
      <h3 className="card-header">Patient Stats</h3>
      <ul>
        <StatsListItem
          title="Active"
          value={insights.get("active_patients").toLocaleString()}
        />
        <StatsListItem
          title="Inactive"
          value={insights.get("inactive_patients").toLocaleString()}
        />
        <StatsListItem
          title="Eligible for Outreach"
          value={insights.get("eligible_for_supplies").toLocaleString()}
        />
        <StatsListItem
          title="Retention"
          value={insights.get("retention").toLocaleString()}
        />
      </ul>
    </div>

    <div className="card-bottom">
      <ResponsiveContainer height={200}>
        <PieChart>
          <Pie
            data={[
              {
                value: insights.get("due_for_follow_up"),
                label: "Due for follow-up",
                fill: "#3B8686"
              },
              {
                value: insights.get("due_for_second_follow_up"),
                label: "Due for second follow-up",
                fill: "#CC1F1A"
              },
              {
                value: insights.get("eligible_for_supplies"),
                label: "Eligible for Outreach",
                fill: "#2DC6B8"
              }
            ]}
            dataKey="value"
            nameKey="label"
            isAnimationActive={false}
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

PatientStatistics.propTypes = {
  insights: PropTypes.object
};

PatientStatistics.defaultProps = {
  insights: {}
};

export default PatientStatistics;
