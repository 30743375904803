import { useReducer, useEffect, useState } from "react";
import { post, put, get } from "utils/api";
import tasksReducer from "./reducer";
import * as R from "ramda";

const useTasks = () => {
  const [tasks, taskDispatcher] = useReducer(tasksReducer, []);
  const [isFetchingTasks, setIsFetchingTasks] = useState(false);
  async function getPatientTasks(patientId) {
    try {
      setIsFetchingTasks(true);
      const tasks = await get(`tasks/${patientId}`);
      taskDispatcher({ type: "SET_PATIENT_TASKS", tasks });
    } finally {
      setIsFetchingTasks(false);
    }
  }
  async function createTaskNote({ patientId, task_id, note }) {
    const task = await post(`tasks/${patientId}/${task_id}/note`, {
      note
    });
    taskDispatcher({ type: "UPDATE_PATIENT_TASK", task });
  }

  async function updateTask({
    patientId,
    task_id,
    order_id,
    action_id,
    assigned_to_guid,
    status,
    owner
  }) {
    const task = await put(`tasks/${patientId}/${task_id}/update`, {
      status,
      action_id,
      assigned_to_guid,
      order_id,
      owner
    });
    taskDispatcher({ type: "UPDATE_PATIENT_TASK", task });
  }

  async function createTask({
    patientId,
    type,
    action_id,
    orderId,
    text,
    owner,
    assigned_to_guid
  }) {
    const task = await post(`tasks/${patientId}`, {
      text,
      type,
      owner,
      orderId,
      action_id,
      assigned_to_guid
    });
    taskDispatcher({ type: "UPDATE_PATIENT_TASK", task });
  }
  const [patientTasks, setPatientTasks] = useState([]);
  useEffect(() => {
    setPatientTasks(R.values(tasks));
  }, [tasks]);

  return {
    tasks: patientTasks,
    createTask,
    createTaskNote,
    getPatientTasks,
    updateTask,
    isFetchingTasks
  };
};

export default useTasks;
