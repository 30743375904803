import { createReducer } from "utils/reducer";
import { Types } from "actions/order-profile";
import * as R from "ramda";
import { createSelector } from "reselect";

const holdReasons = createReducer(
  {},
  {
    [Types.RESPONSE_ORDER_HOLD_REASONS]: (
      state,
      { payload: { order_hold_reasons }, meta: { orderId } }
    ) =>
      R.assoc(
        orderId,
        R.indexBy(R.prop("holdReasonPK"), order_hold_reasons),
        state
      ),
    [Types.INVALIDATE_ORDER_HOLD_REASONS]: (state, { meta: { orderId } }) =>
      R.dissoc(orderId, state)
  }
);

export default holdReasons;

const getHoldReasons = createSelector(
  [state => state.getIn(["orderProfile"])],
  R.prop("holdReasons")
);
const getOrderHoldReasons = createSelector(
  [getHoldReasons, (_, orderId) => orderId],
  (holdReasons, orderId) =>
    R.pipe(R.propOr({}, [orderId]), R.values)(holdReasons)
);
const getOrderHasUnresolvedHoldReason = createSelector(
  [getOrderHoldReasons],
  reasons =>
    R.pipe(
      R.filter(h => !h.resolved),
      R.length
    )(reasons) > 0
);

const getOrderHasCmnHold = createSelector(
  [getOrderHoldReasons],
  R.pipe(
    R.find(h => h.holdReasonPK === 1001),
    R.propOr(true, "resolved"),
    R.not
  )
);

export const selectors = {
  getOrderHoldReasons,
  getOrderHasUnresolvedHoldReason,
  getOrderHasCmnHold
};
