import React from "react";
import AddressUpdateForm from "./address-update-form";
import PropTypes from "prop-types";
import EmailUpdateForm from "./email-update-form";

const UpdateJobsForms = ({ patientGuidForJobs, updateRequestsToArray }) => {
  const hasAddressChange = updateRequestsToArray.some(
    x => x.fieldToUpdate == "Address"
  );
  const hasEmailChange = updateRequestsToArray.some(
    x => x.fieldToUpdate == "Email"
  );
  return (
    <div>
      <div>
        {hasEmailChange ? (
          <EmailUpdateForm patientGuidForJobs={patientGuidForJobs} />
        ) : (
          ""
        )}
      </div>
      <div>
        {hasAddressChange ? (
          <AddressUpdateForm patientGuidForJobs={patientGuidForJobs} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UpdateJobsForms;
UpdateJobsForms.propTypes = {
  patientGuidForJobs: PropTypes.string.isRequired,
  updateRequestsToArray: PropTypes.object.isRequired
};
