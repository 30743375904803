import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";

const TrackingSearchForm = ({ queryDispatcher }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 10,
        border: "1px solid #eee"
      }}
    >
      <Formik
        initialValues={{
          account_number: "",
          tracking_number: ""
        }}
        onSubmit={values => {
          console.log("submit");
          console.log(values);
          queryDispatcher({ type: "updateFilters", payload: values });
        }}
      >
        {() => (
          <Form
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr auto",
              alignItems: "center",
              gridGap: 10
            }}
          >
            <FormikField name="account_number" label="Account #" type="text" />
            <FormikField
              name="tracking_number"
              label="Tracking #"
              type="text"
            />
            <button style={{ margin: 0 }} type="submit">
              Search
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

TrackingSearchForm.defaultProps = {};

TrackingSearchForm.propTypes = {
  queryDispatcher: PropTypes.func.isRequired
};

export default TrackingSearchForm;
