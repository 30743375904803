import React from "react";
import PropTypes from "prop-types";

const PhoneSmsStatus = ({ text_consent_information, text_consent }) => {
  const notTextable = text_consent == "Not Textable";
  return (
    <span
      className={notTextable ? "not-textable" : "textable-by-consent"}
      title={text_consent_information}
    >
      {text_consent}
    </span>
  );
};

PhoneSmsStatus.propTypes = {
  text_consent_information: PropTypes.string,
  text_consent: PropTypes.string
};

export default PhoneSmsStatus;
