import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InlineWaiting } from "components/ui";
import PatientSearchForm from "components/patient-search-form";
import { assignQuestionnaireToPatients } from "actions/questionnaire";
import { getQuestionnaire } from "components/dd_newModules/Redux/Actions/questionnaire";

const AssigneQuestionnaire = ({
  questionnaires,
  getQuestionnaire,
  assignQuestionnaireToPatients
}) => {
  const [selectedPatients, setSelectedPatients] = React.useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    getQuestionnaire();
  }, []);
  return (
    <div style={{ display: "grid", gridTemplateRows: "auto 1fr auto" }}>
      <PatientSearchForm
        selectedPatients={selectedPatients}
        setSelectedPatients={setSelectedPatients}
        feature="questionnaires"
      />
      <div style={{ marginTop: 20 }}>
        <h4>Select Questionnaire</h4>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            gridGap: 20
          }}
        >
          <select
            value={selectedQuestionnaire}
            onChange={({ target }) => {
              setSelectedQuestionnaire(target.value);
            }}
          >
            <option />
            {questionnaires
              .filter(({ active }) => active)
              .map(({ questionnaire_id, title }) => (
                <option value={questionnaire_id} key={questionnaire_id}>
                  {title}
                </option>
              ))}
          </select>
          {isLoading ? (
            <InlineWaiting />
          ) : (
            <button
              disabled={selectedPatients.length == 0 || !selectedQuestionnaire}
              onClick={async () => {
                try {
                  setIsLoading(true);
                  const success = await assignQuestionnaireToPatients(
                    selectedQuestionnaire,
                    selectedPatients
                  );
                  if (success) setSelectedPatients([]);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              Assign Patients
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

AssigneQuestionnaire.propTypes = {
  questionnaires: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignQuestionnaireToPatients: PropTypes.func.isRequired,
  getQuestionnaire: PropTypes.func.isRequired
};

export default connect(
  state => ({
    questionnaires: state.getIn(["newRootReducer"]).rdc_questionnaire
      .Questionnaire
  }),
  { getQuestionnaire, assignQuestionnaireToPatients }
)(AssigneQuestionnaire);
