import React from "react";
import PropTypes from "prop-types";
import MdArrowDropUp from "react-icons/lib/md/arrow-drop-up";
import MdArrowDropDown from "react-icons/lib/md/arrow-drop-down";

// Simple Table Header Ui
// all functionality and logic can be found in useSort hook
const TableSortableHeader = ({
  columns,
  ascending,
  sortBy,
  toggleSortDirection,
  setSortBy
}) => (
  <thead>
    <tr>
      {columns.map(({ key, text }) => (
        <td key={key} style={{ cursor: "pointer", userSelect: "none" }}>
          {sortBy === key ? (
            <span onClick={toggleSortDirection}>
              <span style={{ paddingRight: 5 }}>{text}</span>
              {ascending ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </span>
          ) : (
            <span onClick={() => setSortBy(key)}>
              <span style={{ paddingRight: 5 }}>{text}</span>
              <span style={{ marginRight: "1em" }} />
            </span>
          )}
        </td>
      ))}
    </tr>
  </thead>
);

TableSortableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  ascending: PropTypes.bool,
  sortBy: PropTypes.string,
  toggleSortDirection: PropTypes.func.isRequired,
  setSortBy: PropTypes.func.isRequired
};

export default TableSortableHeader;
