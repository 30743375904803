import { Types } from "actions/app-layout";
import { createReducer } from "utils/reducer";
import { propOr, assoc, prop } from "ramda";
import { createSelector } from "reselect";

// TODO move phone open, side bar minimized and chat now window open flags here
export default createReducer(
  { pdf_window_open: false },
  {
    [Types.SET_PDF_WINDOW_OPEN_STATUS]: (state, action) =>
      assoc("pdf_window_open", prop("payload", action), state)
  }
);

export const selectors = {
  getPdfWindowIsOpen: createSelector(
    [state => state.get("app-layout")],
    propOr(false, "pdf_window_open")
  )
};
