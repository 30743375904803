import { combineReducers } from 'redux'
import {
  createSelector,
  createStructuredSelector
} from 'reselect'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import entities, { selectors as localFromEntities } from './entities'
import fetchStatus, { selectors as localFromFetchStatus } from './fetchStatus'
import orderNotes, { selectors as localFromOrderNotes } from './orderNotes'
import orderLineItems, { selectors as localFromOrderLineItems } from './orderLineItems'
import logs, { selectors as localFromLogs } from './logs'
import permissions, { selectors as localFromPermissions } from './permissions'
import holdReasons, { selectors as localFromHoldReasons } from './hold-reasons'

const orderProfile = combineReducers({
  entities,
  fetchStatus,
  orderNotes,
  orderLineItems,
  logs,
  permissions,
  holdReasons
})

export default orderProfile

const liftSelectors = (selectors, sliceKey) => (
  mapValues(selectors, selector => (state, ...args) => {
    if (state) {
      // if `state` exists, assume selector
      return selector(state[sliceKey], ...args)
    } else {
      // otherwise assume selector factory
      const selectorInst = selector()
      return (state, ...args) => selectorInst(state[sliceKey], ...args)
    }
  })
)

const fromEntities = liftSelectors(localFromEntities, 'entities')
const fromFetchStatus = liftSelectors(localFromFetchStatus, 'fetchStatus')
const fromOrderNotes = liftSelectors(localFromOrderNotes, 'orderNotes')
const fromOrderLineItems = liftSelectors(localFromOrderLineItems, 'orderLineItems')
const fromLogs = liftSelectors(localFromLogs, 'logs')
const fromPermissions = liftSelectors(localFromPermissions, 'permissions')

// Note for future implementations: I would advise not to create
// structured selectors like these. Although it is a benefit to group
// data with its fetch status so components do not have to be
// concerned, it is often awkward to destructure the return value, to
// know what shape to expect, and to remember to add more data
// selectors.
const dataSelectors = mapValues({
  getOrderInfo: [
    fromEntities.getOrderInfo,
    fromFetchStatus.getIsFetchingOrderInfo
  ],
  getOrderAdminInfo: [
    fromEntities.getOrderAdminInfo,
    fromFetchStatus.getIsFetchingOrderInfo
  ],
  getOrderPatientInfo: [
    fromEntities.getOrderPatientInfo,
    fromFetchStatus.getIsFetchingOrderPatientInfo
  ],
  getOrderPhysicianInfo: [
    fromEntities.getOrderPhysicianInfo,
    fromFetchStatus.getIsFetchingOrderPatientInfo
  ],
  getOrderComplianceInfo: [
    fromEntities.getOrderComplianceInfo,
    fromFetchStatus.getIsFetchingOrderComplianceInfo
  ],
  getOrderPatientEquipmentInfo: [
    fromEntities.getOrderPatientEquipmentInfo,
    fromFetchStatus.getIsFetchingOrderEquipmentInfo
  ],
  getOrderEquipmentRequested: [
    fromEntities.getOrderEquipmentRequested,
    fromFetchStatus.getIsFetchingOrderEquipmentInfo
  ],
  getOrderDysfunctionInfo: [
    fromEntities.getOrderDysfunctionInfo,
    fromFetchStatus.getIsFetchingOrderDysfunctionInfo
  ],
  getOrderCompanyInfo: [
    fromEntities.getOrderCompanyInfo,
    fromFetchStatus.getIsFetchingCompanyInfo
  ]
}, ([data, isFetching]) => createStructuredSelector({ data, isFetching }))

const getOrderNotes = createSelector([
  fromEntities.getNotes,
  fromOrderNotes.getOrderNoteIds,
  fromFetchStatus.getIsFetchingOrderNotes
], (allNotes, ids = [], isFetching) => ({
  data: ids.map(id => allNotes[id]),
  isFetching
}))

const getOrderLineItems = createSelector([
  fromEntities.getLineItems,
  fromOrderLineItems.getOrderLineItemIds,
  fromFetchStatus.getIsFetchingOrderLineItems
], (allItems, ids = [], isFetching) => ({
  data: ids.map(id => allItems[id]),
  isFetching
}))

const getOrderAccessLogs = createSelector([
  fromLogs.getOrderAccessLogIds,
  fromEntities.getAccessLogs,
  fromFetchStatus.getIsFetchingOrderAccessLogs
], (ids, logs, isFetching) => ({
  data: ids.map(id => logs[id]),
  isFetching
}))

const getOrderChangeLogs = createSelector([
  fromLogs.getOrderChangeLogIds,
  fromEntities.getChangeLogs,
  fromFetchStatus.getIsFetchingOrderChangeLogs
], (ids, logs, isFetching) => ({
  data: ids.map(id => logs[id]),
  isFetching
}))

export const selectors = {
  ...dataSelectors,
  getOrderNotes,
  getOrderLineItems,
  getOrderAccessLogs,
  getOrderChangeLogs,
  ...pick(fromEntities, [
    'getLineItem',
    'getOrderStatus',
    'getEquipmentTypes',
    'getEquipmentType',
    'getOrderDysfunctionEquipment',
  ]),
  ...pick(fromFetchStatus, [
    'getIsSubmittingNoteAdd',
    'getIsUpdatingOrder',
    'getIsSubmittingLineItemRemoval',
    'getIsUpdatingTracking',
    'getIsSubmittingNewLineItem',
    'getIsFetchingOrderProfile',
    'getDidFailOrderProfile'
  ]),
}

// these selectors have similar prop requirements (i.e., `orderStatus`
// and `userRole`), which they cannot derive themselves; group
// together for easier composition
export const permissionSelectors = fromPermissions
export const holdReasonSelectors = localFromHoldReasons;
