import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import GoogleAuthButton from "features/google-api/components/sign-in";
import { Waiting } from "components/ui";
import FolderFiles from "./folder-files";
import FolderCard from "./folder-card";

const GoogleDriveFiles = ({ folders, isLoggedIn, isFetching }) => {
  const [selectedFolder, setSelectedFolder] = useState();
  return (
    <div>
      <div className="user-files-page-header">
        <h1>Google Drive Files</h1>
        <span className="google-auth-button">
          <GoogleAuthButton />
        </span>
      </div>
      {isFetching && <Waiting />}
      {isLoggedIn && (
        <div>
          {!selectedFolder ? (
            <div>
              <div className="user-files-container">
                {folders.map(folder => (
                  <FolderCard
                    key={folder.id}
                    folder={folder}
                    onClick={() => setSelectedFolder(folder)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>
              <FolderFiles
                folder={selectedFolder}
                goBack={() => setSelectedFolder()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

GoogleDriveFiles.defaultProps = {};

GoogleDriveFiles.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ),
  isLoggedIn: PropTypes.bool,
  isFetching: PropTypes.bool
};

export default connect(state => ({
  folders: selectors.getGapiUserFolders(state),
  isLoggedIn: selectors.getGapiIsLoggedIn(state),
  isFetching: selectors.getGapiIsFetchingFolders(state)
}))(GoogleDriveFiles);
