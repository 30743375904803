import { SET_VIDEOCHAT_DETAILS, SET_TEXTCHAT_DETAILS, SET_DEMOCHAT_DETAILS, SET_API_ERROR, SET_TWILIO_ERROR } from '../ActionTypes';
import API from "../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage, message } from "../../../../actions/message";

export const getChatDetails = (slot_id, chat_type) => dispatch => {
    console.log('Initiate for chat/start API');
    dispatch(showLoader());
    const url = "dme_portal/chats/start";
    let createObj = {
        slot_id: slot_id
    };
    API().post(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            console.log('Chat Start Detail fetch successfully.');
            dispatch(message(res.data.message));
            if (chat_type === 1) {
                let createObj = {
                    uniqueName: res.data.chats_to_connect_to.text_chats[0].unique_name,
                    friendlyName: res.data.chats_to_connect_to.text_chats[0].friendly_name,
                    members: res.data.chats_to_connect_to.text_chats[0].members,
                    TextChatInitialize: true
                };
                return dispatch({
                    type: SET_TEXTCHAT_DETAILS,
                    payload: createObj
                });
            } else {
                let createObj = {
                    roomName: res.data.chats_to_connect_to.video_chat.room_name,
                    members: res.data.chats_to_connect_to.video_chat.members,
                    VideoChatInitialize: true
                };
                return dispatch({
                    type: SET_VIDEOCHAT_DETAILS,
                    payload: createObj
                });
            }
        } else {
            dispatch(setAPIError(res.data.message));
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(setAPIError(responseErrorMessage));
        dispatch(errorMessage(responseErrorMessage));
    });
}

export const getDemoChatDetails = (slot_id) => dispatch => {
    console.log('fetch api data for slot ' + slot_id);
    dispatch(showLoader());
    const url = "dme_portal/chats/start";
    let createObj = {
        slot_id: slot_id
    };
    API().post(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            console.log('API Response.');
            console.log(res.data.chats_to_connect_to);
            let createObj = {
                videoDetails: res.data.chats_to_connect_to.video_chat,
                textChatDetails: res.data.chats_to_connect_to.text_chats
            };
            return dispatch({
                type: SET_DEMOCHAT_DETAILS,
                payload: createObj
            });
        } else {
            dispatch(setAPIError(res.data.message));
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(setAPIError(responseErrorMessage));
        dispatch(errorMessage(responseErrorMessage));
    });
}

export const setAPIError = (errorMessage) => dispatch => {
    return dispatch({
        type: SET_API_ERROR,
        payload: errorMessage

    });
}

export const setTwilioError = (errorMessage) => dispatch => {
    return dispatch({
        type: SET_TWILIO_ERROR,
        payload: errorMessage
    });
}

