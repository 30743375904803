import React, {useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getChatHistory } from "components/dd_newModules/Redux/Actions/schedule";
import moment from "moment";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";

const ChatHistory = ({ History, getChatHistory, isLoading, timeZone, resetTimeZoneValue }) => {
  React.useEffect(() => {
    getChatHistory();
  }, []);

  useEffect(() => {   
    if(timeZone){
      getChatHistory();
      resetTimeZoneValue();
    }
  }, [timeZone]);

  const { rows, query, queryDispatcher, count } = useLocalQuery(History, {
    sort: { by: "created_date", direction: "asc" },
    filters: {},
    pagination: { page: 1, per: 100 }
  });
  return (
    <div>
      <Table
        containerClass="query-table chats-table"
        rowKey="slot-id"
        isFetching={isLoading}
        columns={[
          {
            header: "Type",
            field: "master_chat_type",
            dropdownPosition: "right",
            filters: [
              { text: "Text Chat", value: "Text Chat" },
              { text: "Video Chat", value: "Video Chat" },
              { text: "Product Demo", value: "Product Demo" }
            ],
            
          },
          {
            header: "Date",
            field: "date"
          },
          {
            header: "Title",
            field: "title"
          },
          {
            header: "Description",
            field: "description"
          },
          {
            header: "Status",
            field: "status"
          },
          {
            header: "Patients",
            field: "patients"
          }
        ]}
        rows={rows}
        query={query}
        count={count}
        queryDispatcher={queryDispatcher}
        TableRow={({ chats, master_chat_type }) =>
          chats.map(
            ({
              patient_details,
              chat_details: {
                title,
                description,
                chat_id,
                start_time,
                end_time,
                meeting_status
              }
            }) => (
              <tr key={chat_id}>
                <td>{master_chat_type}</td>
                <td>
                  <span>
                    {moment(start_time).format("M/D/YYYY")}{" "}
                    | {" "}
                    {moment(start_time).format("h:mm A")}{" "}
                    to{" "}
                    {moment(end_time).format("h:mm A")}
                  </span>
                </td>
                <td>{title}</td>
                <td>{description}</td>
                <td>{meeting_status}</td>
                <td>
                  {patient_details.map(({ patient_id, patient_name }) => (
                    <span key={patient_id}>{patient_name}</span>
                  ))}
                </td>
              </tr>
            )
          )
        }
      />
    </div>
  );
};

ChatHistory.propTypes = {
  History: PropTypes.arrayOf(PropTypes.object),
  getChatHistory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
]),
  resetTimeZoneValue: PropTypes.func
};

export default connect(
  state => ({
    History: state.getIn(["newRootReducer"]).rdc_schedule.History,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  { getChatHistory }
)(ChatHistory);
