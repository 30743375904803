import { Formik, Form } from "formik";
import React from "react";
import PropTypes from "prop-types";
import FormikField from "components/forms/formik-field";
import TextInput from "components/forms/text-input";
import "./style.scss";
import { selectors } from "reducers";
import { connect } from "react-redux";

const EditCompanyInventoryForm = ({
  company,
  inventoryItem,
  setIsLoading,
  updateCompanyInventoryAPI,
  closeModal
}) => {
  const handleSubmit = values => {
    setIsLoading(true);
    updateCompanyInventoryAPI.fetchData(values);
  };

  return (
    <Formik
      initialValues={{
        company_inventory_item_guid: inventoryItem.xref_id,
        autofit_eligible: inventoryItem.autofit_eligible,

        backordered: inventoryItem.backordered,
        company_product_id: inventoryItem.company_product_id,
        active: inventoryItem.active
      }}
      onSubmit={async values => {
        await handleSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <div className="edit-company-inventory-form">
          <h4>{inventoryItem.name}</h4>
          <Form style={{ maxWidth: "600px" }}>
            <div>
              <TextInput
                label="Company Product Id"
                name="company_product_id"
                type="text"
                value={values.company_product_id}
                onChange={e => {
                  setFieldValue("company_product_id", e.target.value);
                }}
              />

              <div
                className="company-inventory-checkbox-container"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {/* Checkboxes */}
                <div>
                  <FormikField
                    label="Backordered"
                    name="backordered"
                    type="checkbox"
                    checked={values.backordered}
                  />
                </div>

                <div>
                  <FormikField
                    label="Active"
                    name="active"
                    type="checkbox"
                    checked={values.active}
                  />
                </div>
                {/* End Checkboxes */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <button
                className="warning"
                style={{ margin: "0px 20px" }}
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
              <button style={{ margin: "0px 20px" }} type="submit">
                Save
              </button>
            </div>
            {company.company_relationships?.children.length > 0 ? (
              <div>
                You will also be editing this item for:
                <ul>
                  {company.company_relationships.children.map(x => (
                    <li key={x.company_guid}>{x.company_name}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default connect(state => ({
  company: selectors.getCompanyProfile(state)
}))(EditCompanyInventoryForm);

EditCompanyInventoryForm.propTypes = {
  setIsLoading: PropTypes.func,
  inventoryItem: PropTypes.object,
  updateCompanyInventoryAPI: PropTypes.object,
  closeModal: PropTypes.func,
  company: PropTypes.object
};
