import React, { useState, useCallback } from 'react';
import Room from './Room';
import "./video.css";

const VideoChat = ({ roomNm, twilioToken, fetchError, isRemoteVisible, members }) => {
  const [token, setToken] = useState(twilioToken);

  const handleLogout = useCallback(() => {
    setToken(null);
    location.replace("/dashboard");
  }, []);

  let render;
  if (token) {
    render = (
      <Room roomName={roomNm} token={twilioToken} handleLogout={handleLogout} fetchError={fetchError} isRemoteVisible={isRemoteVisible} members={members} />
    );
  }else {
    render = null;
  } 
  return render;
};

export default VideoChat;
