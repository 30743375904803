import React from "react";
import PropTypes from "prop-types";

const OrderWorklistCompletionStatus = ({
  allHoldReasonsResolved,
  hold_reasons
}) => (
  <span style={{ color: allHoldReasonsResolved ? "#222" : "#777" }}>
    {hold_reasons.filter(r => r.resolved).length}/
    {hold_reasons.filter(r => r.holdReason).length}
    {/* TODO should realy filter out  null reasons on backend eventually*/}
  </span>
);

OrderWorklistCompletionStatus.propTypes = {
  allHoldReasonsResolved: PropTypes.bool.isRequired,
  hold_reasons: PropTypes.arrayOf(PropTypes.object)
};

export default OrderWorklistCompletionStatus;
