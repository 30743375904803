import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import IoTrashA from "react-icons/lib/io/trash-a";
import IoPlus from "react-icons/lib/io/plus";
import SectionHeader from 'components/profile/section_header.jsx';
import '../pages/companyForms.css';
import TaskOwnerSelector from './task-owner-selector';

export default function ModalConditionalQuestion({ handleCancel, comparisons, binaryOperatorType, saveConditions, showTaskActions, taskActions, toEditQuestion, questionData }) {

    const [conditionsSelect, setConditionsSelect] = useState([]);

    const [countCondition, setCountCondition] = useState((questionData && questionData.Conditions) ? questionData.Conditions.length : 0);

    const [condition, setCondition] = useState(
        {
            Value: '0',
            Order: 0,
            TaskActionId: '0',
            ComparisonOperator: '1',
            ComparisonOperatorName: '',
            BinaryOperator: '0',
            BinaryOperatorName: '',
            TaskOwner: ''
        }
    );

    const [error, setError] = useState(false);

    const [newQuestionText, setNewQuestionText] = useState(toEditQuestion ? { newText: questionData.Text, newSpanishText: questionData.SpanishText } : { newText: "", newSpanishText: "" })

    const [newRequiredAndNAStatus, setNewRequiredAndNAStatus] = useState(toEditQuestion ? { IsRequired: questionData.IsRequired, HasNAOption: questionData.HasNAOption } : { IsRequired: true, HasNAOption: false })

    useEffect(() => {

        (toEditQuestion && questionData && questionData.Conditions) ? setConditionsSelect((conditionsSelect) => [...conditionsSelect, ...questionData.Conditions.map(obj => { return { ...obj, TaskActionId: obj.TaskActionId ? obj.TaskActionId : null } })]) : null

    }, [taskActions])

    const showComparisons = () => {
        return comparisons.map((op, i) => (
            <option key={i} value={op.ItemId}>{op.ItemValue}</option>
        ))
    }

    const showBinaryOperatorType = () => {

        return binaryOperatorType.map((op, i) => (
            <option key={i} value={op.ItemId}>{op.ItemValue}</option>
        ))
    }

    const addCondition = () => {
        if (validateConditions()) {
            const conditionSave = {
                Value: condition.Value,
                Order: countCondition + 1,
                TaskActionId: condition.TaskActionId !== '0' ? condition.TaskActionId : null,
                ComparisonOperator: condition.ComparisonOperator,
                ComparisonOperatorName: condition.ComparisonOperatorName,
                BinaryOperator: condition.BinaryOperator !== '0' ? condition.BinaryOperator : null,
                BinaryOperatorName: condition.BinaryOperatorName,
                TaskOwner: condition.TaskOwner
            }
            setConditionsSelect([...conditionsSelect, conditionSave]);
            setCountCondition(conditionSave.Order);
            setCondition({ ...condition, Value: '0', TaskActionId: '0', ComparisonOperator: '0', BinaryOperator: '0', BinaryOperatorName: '', TaskOwner: '' })
        } else {
            setError(true);
        }
    }

    const deletecondition = (id) => {

        const findDelete = conditionsSelect.filter(x => x.Order !== id)

        const deleteConditions = findDelete.map((item, i) => ({
            Value: item.Value,
            Order: i + 1,
            ComparisonOperator: item.ComparisonOperator,
            TaskActionId: item.TaskActionId,
            BinaryOperator: item.BinaryOperator,
            ComparisonOperatorName: item.ComparisonOperatorName,
            BinaryOperatorName: item.BinaryOperatorName,
            TaskOwner: item.TaskOwner
        }));

        if (deleteConditions.length)
            setCountCondition(deleteConditions[deleteConditions.length - 1].Order);
        else
            setCountCondition(0);

        setConditionsSelect(deleteConditions);
    }

    const handleChangeCondition = (e) => {

        const option = e.target.value;
        const name = e.target.name;

        if (name === "TaskActionId" && option == 0)
            setCondition({ ...condition, TaskActionId: option, TaskOwner: '' });
        else
            setCondition({ ...condition, [name]: option });

        setError(false);
    }

    const handleChangeComparison = (e) => {

        const option = e.target.value;

        if (option !== '0') {

            const findComp = comparisons.find(x => x.ItemId === +option)

            setCondition({ ...condition, ComparisonOperator: option, ComparisonOperatorName: findComp.ItemValue });
        }

        setError(false);
    }

    const handleChangeBinaryOperator = (e) => {

        const option = e.target.value;

        if (option !== '0') {

            const findComp = binaryOperatorType.find(x => x.ItemId === +option)

            setCondition({ ...condition, BinaryOperator: option, BinaryOperatorName: findComp.ItemValue });
        } else {
            setCondition({ ...condition, BinaryOperator: option, BinaryOperatorName: "" });
        }

        setError(false);
    }

    const handleChangeNewText = (e) => {

        const option = e.target.value;
        const name = e.target.name;

        setNewQuestionText({ ...newQuestionText, [name]: option });

        setError(false);
    }

    const handleChangeRequiredAndNA = (e) => {

        const name = e.target.name;
        if (name == "IsRequired") {
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["IsRequired"]: !newRequiredAndNAStatus.IsRequired });
        } else if (name == "HasNAOption") {
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["HasNAOption"]: !newRequiredAndNAStatus.HasNAOption });
        }
    }

    const showTableConditions = () => {
        return (
            conditionsSelect.length ?
                <div className='scrollTableAQuestion'>
                    <table>
                        <thead>
                            <tr>{showHeadersConditions()}</tr>
                        </thead>
                        <tbody>{showConditionsSelect()}</tbody>
                    </table>
                </div>
                : <p><span>No Found Conditions</span></p>
        )
    }

    const showHeadersConditions = () => {

        const headers = ["ORDER", "VALUE", "COMPARISON OP", "BINARY OP", "TASK", ""];
        return headers.map((h, i) => <th key={i}>{h}</th>);
    };

    const getNameList = (list, id) => {
        return id ? list.find(x => x.ItemId == id).ItemValue : '';
    }

    const showComparisonOperator = (typeValue) => {
        let findComp
        if (typeof typeValue === "number") {
            findComp = comparisons.find(x => x.ItemId === typeValue)
        } else {
            findComp = comparisons.find(x => x.ItemId === +typeValue)
        }

        if (findComp) {
            return findComp.ItemValue
        }
    }

    const showBinaryOperator = (typeValue) => {
        let findComp
        if (typeof typeValue === "number") {
            findComp = binaryOperatorType.find(x => x.ItemId === typeValue)
        } else {
            findComp = binaryOperatorType.find(x => x.ItemId === +typeValue)
        }

        if (findComp) {
            return findComp.ItemValue
        }
    }

    const showConditionsSelect = () => {
        return conditionsSelect.map((item, i) => (
            <tr key={i}>
                <td>{item.Order}</td>
                <td>{item.Value}</td>
                <td>{showComparisonOperator(item.ComparisonOperator)}</td>
                <td>{showBinaryOperator(item.BinaryOperator)}</td>
                <td>{taskActions && getNameList(taskActions, item.TaskActionId)}</td>
                <td><IoTrashA style={{ color: "red", fontSize: "1.5em", cursor: 'pointer' }} onClick={() => deletecondition(item.Order)}></IoTrashA></td>
            </tr>
        ));
    }

    const validateConditions = () => {

        const findQ = conditionsSelect.find(x => x.Value === condition.Value && x.ComparisonOperatorName === condition.ComparisonOperatorName);

        return !findQ;
    }

    const finalConditionDisable = () => {
        const isThereANullBinaryOp = conditionsSelect.some((element) => element.BinaryOperator === null)

        if (isThereANullBinaryOp) {
            return true
        } else {
            return false
        }
    }

    const taskDisable = () => {
        const isThereATaskSelected = conditionsSelect.some((element) => element.TaskActionId !== null)

        if (isThereATaskSelected) {
            return true
        } else {
            return false
        }
    }

    return (
        <div className='patient-types-statuses-container'>
            <div className="section-title">
                <div className="dd_col-12">
                    <SectionHeader>CONDITIONAL QUESTION</SectionHeader>
                </div>
                {
                    toEditQuestion &&
                    <div>
                        <div className="dd_col-6">
                            <label>Question Text</label>
                            <input
                                name="newText"
                                type="text"
                                value={newQuestionText.newText}
                                onChange={handleChangeNewText}
                                disabled={questionData.AlreadyExists ? questionData.AlreadyExists : false}
                            />
                        </div>
                        <div className="dd_col-6">
                            <label>Question Spanish Text</label>
                            <input
                                name="newSpanishText"
                                type="text"
                                value={newQuestionText.newSpanishText}
                                onChange={handleChangeNewText}
                                disabled={questionData.AlreadyExists ? questionData.AlreadyExists : false}
                            />
                        </div>
                    </div>
                }
                {
                    questionData?.AlreadyExists &&
                    <div className="dd_col-12">
                        <span style={{ color: 'red' }}>Cannot change text of an already existing question</span>
                    </div>
                }
                {
                    toEditQuestion &&
                    <div className="dd_col-12">
                        <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <input type="checkbox" value={newRequiredAndNAStatus.IsRequired} name="IsRequired" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.IsRequired} />
                        <label>Required</label>
                        <input type="checkbox" value={newRequiredAndNAStatus.HasNAOption} name="HasNAOption" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.HasNAOption} />
                        <label>Add N/A</label>
                    </div>
                }
                <div className='dd_col-12'>
                    <fieldset>
                        <legend>Condition Construction</legend>
                        <div>
                            <div className="dd_col-2">
                                <label>Value</label>
                                <input
                                    name="Value"
                                    type="number"
                                    value={condition.Value}
                                    onChange={handleChangeCondition}
                                    disabled={finalConditionDisable()}
                                />
                            </div>
                            <div className="dd_col-3">

                                <label>Comparison</label>
                                <select name="ComparisonOperator" value={condition.ComparisonOperator} onChange={handleChangeComparison} disabled={finalConditionDisable()} >
                                    {showComparisons()}
                                </select>
                            </div>
                            <div className="dd_col-2">

                                <label>Binary Operator Type</label>
                                <select name="BinaryOperator" value={condition.BinaryOperator} onChange={handleChangeBinaryOperator} disabled={finalConditionDisable()} >
                                    <option value='0'></option>
                                    {showBinaryOperatorType()}
                                </select>
                            </div>
                            <div className="dd_col-3">
                                <label>Task Action Id</label>
                                <select name="TaskActionId" value={condition.TaskActionId} onChange={handleChangeCondition} disabled={finalConditionDisable() || taskDisable()} >
                                    <option value='0'></option>
                                    {showTaskActions()}
                                </select>
                            </div>
                            <div className="dd_col-2">
                                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <IoPlus
                                    hidden={isNaN(Number(condition.Value)) || Number(condition.Value) < 0 || condition.Value === "" || finalConditionDisable() || (condition.TaskActionId !== "0" && condition.TaskOwner == '')}
                                    style={{ color: "#007bff", fontSize: "2em", cursor: "pointer"}}
                                    onClick={addCondition} >
                                </IoPlus>
                            </div>
                            {
                                condition.TaskActionId != 0 &&
                                <TaskOwnerSelector setItem={setCondition} item={condition} />
                            }
                        </div>
                        <div className="dd_col-12">
                            <span style={{ color: 'red' }} hidden={!error}>A condition with the same data already exists</span>
                        </div>
                        <div className="dd_col-12">
                            <span style={{ color: 'black' }}>Remarks:</span>
                        </div>
                        <div className="dd_col-12">
                            <span style={{ color: 'black' }}>The condition that does not have a binary operator will be considered as the last one, and will not allow others to be created</span>
                        </div>
                        <div className="dd_col-12">
                            <span style={{ color: 'black' }}>Only one condition can have an associated task</span>
                        </div>
                    </fieldset>
                </div>
                <div className='dd_col-12'>
                    <fieldset>
                        <legend>Table conditions</legend>
                        {showTableConditions()}
                    </fieldset>
                </div>
                <div className='dd_col-12'>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            type="button"
                            className="button"
                            disabled={!conditionsSelect.length || (toEditQuestion && (!newQuestionText.newText.length > 0 || !newQuestionText.newSpanishText.length > 0))}
                            onClick={() => {
                                saveConditions([], conditionsSelect, questionData, toEditQuestion, newQuestionText, newRequiredAndNAStatus)
                                handleCancel()
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

ModalConditionalQuestion.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    comparisons: PropTypes.arrayOf(PropTypes.object).isRequired,
    binaryOperatorType: PropTypes.arrayOf(PropTypes.object).isRequired,
    saveConditions: PropTypes.func.isRequired,
    showTaskActions: PropTypes.func.isRequired,
    taskActions: PropTypes.arrayOf(PropTypes.object).isRequired,
    toEditQuestion: PropTypes.bool,
    questionData: PropTypes.object,
};