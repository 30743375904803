import React from "react";
import CompanyPatients from "../company-patients";
import CompanyContacts from "../company-contacts";
import CompanyEquipmentSold from "../company-equipment-sold";
import CompanyOrders from "../company-orders";
import TeamDashboardContext from "../context";

const TeamCompanies = () => {
  const { minimizeAll } = React.useContext(TeamDashboardContext);
  return (
    <div className="team-dashboard-companies-container">
      <CompanyPatients minimizeAll={minimizeAll} />
      <CompanyContacts minimizeAll={minimizeAll} />
      <CompanyOrders minimizeAll={minimizeAll} />
      <CompanyEquipmentSold minimizeAll={minimizeAll} />
    </div>
  );
};

TeamCompanies.defaultProps = {};

TeamCompanies.propTypes = {};

export default TeamCompanies;
