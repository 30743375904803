import React from "react";
import PropTypes from "prop-types";
import { useFormikContext, Field } from "formik";
import { PureModal, InlineWaiting } from "components/ui";
import PatientsCurrentEquipment from "./patients-equipment";
import RadioGroupField from "components/forms/radio-group-field";
import FormikField from "components/forms/formik-field";

const Acknowledgment = ({ patientId, readOnly, setCurrentStep }) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext();
  React.useEffect(() => {
    if (!readOnly) {
      if (!values.hasMachine) setFieldValue("recallStatus", "Not Affected");
      else setFieldValue("recallStatus", "");
    }
  }, [readOnly, values.hasMachine]);
  return (
    <div style={{ padding: "2rem", minHeight: "fit-content" }}>
      <div className="philips-recall-prompt">
        <p>
          Philips healthcare recently issued a recall affecting cpap and bilevel
          pap machines. The impacted models are the Dreamstation, the System
          One, and the Dreamstation Go travel pap. Our records indicate that you
          might have one of these type of devices. Do you have one of these
          impacted models:
        </p>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gridGap: 20
          }}
        >
          <Field
            component={RadioGroupField}
            name="hasMachine"
            label="Do you have one of these impacted models?"
            value={values.hasMachine}
            className="form-field philips-radio-group"
            options={[
              { value: true, text: "Yes, I have a recalled model" },
              { value: false, text: "No I do not have a recalled model" }
            ]}
            disabled={readOnly}
          />
          <PureModal
            renderTrigger={({ openModal }) => (
              <div style={{ display: "flex", flex: 1, alignItems: "flex-end" }}>
                <button
                  onClick={openModal}
                  type="button"
                  className="button secondary"
                  style={{ flex: 1, margin: 0, width: "100%", padding: 5 }}
                >
                  View Patients Current Equipment
                </button>
              </div>
            )}
          >
            <PatientsCurrentEquipment patientId={patientId} />
          </PureModal>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gridGap: 20,
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 30
          }}
        >
          {values.hasMachine !== false && (
            <FormikField
              label="I Acknowledge I made patient aware of the philips recall information"
              name="hasAcknowledged"
              type="checkbox"
              checked={values.hasAcknowledged}
              disabled={readOnly}
              className="form-field philips-checkbox"
            />
          )}
        </div>
        {isSubmitting ? (
          <InlineWaiting />
        ) : !values.hasMachine ? (
          <button type="submit" style={{ margin: 0 }}>
            Submit
          </button>
        ) : (
          <button
            type="button"
            disabled={!values.hasAcknowledged || values.hasMachine == null}
            onClick={() => {
              setCurrentStep(2);
            }}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

Acknowledgment.propTypes = {
  patientId: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  hasAcknowledged: PropTypes.bool,
  acknowledgedInfo: PropTypes.object,
  readOnly: PropTypes.bool
};

export default Acknowledgment;
