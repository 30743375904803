import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCompany,
  getCompanyOrdersReport,
  getCompanyContactsReport,
  getCompanyPatientReport,
  getCompanyPatientDemographicLogs,
  getCompanyPatientDemographicLogsCount,
  // getCompanyActiveInventory,
  getCompanyManufacturerWeights
} from "actions/company";
import { selectors } from "reducers";
import { PageHeader } from "components/ui";
import CompanyTabs from "./company-tabs";
import { firstDayOfLastMonth, lastDayOfLastMonth } from "utils/dates";
import useDateRange from "hooks/useDateRange";
import DateRangeContext from "./date-range-context";
import { fetchOrderStatusChangeHeatMap } from "actions/heat-map";
import { AppPermissionProvider } from "components/shared/app-permission";
import { browserHistory } from "browser-history";

const CompanyProfile = ({
  params,
  getCompany,
  getCompanyOrdersReport,
  getCompanyContactsReport,
  getCompanyPatientReport,
  fetchOrderStatusChangeHeatMap,
  getCompanyPatientDemographicLogs,
  getCompanyPatientDemographicLogsCount,
  // getCompanyActiveInventory,
  getCompanyManufacturerWeights,
  company,
  userRole,
  userAppPermissions,
  children
}) => {
  const { startDate, endDate, setStartDate, setEndDate, dateRangeIsValid } =
    useDateRange({
      startDate: firstDayOfLastMonth(),
      endDate: lastDayOfLastMonth()
    });
  useEffect(() => {
    getCompany(params.id);
    getCompanyOrdersReport(params.id, {
      start: startDate,
      end: endDate
    });
    getCompanyPatientReport(params.id, startDate, endDate);
    getCompanyContactsReport({ id: params.id, start: startDate, end: endDate });
    getCompanyPatientDemographicLogs(params.id, {
      filter_by: [
        { by: "startDate", value: startDate },
        { by: "endDate", value: endDate }
      ],
      sort: { by: "timestamp", direction: "desc" },
      pagination: { page: 1, per: 150 },
      search_for: {},
      search_for_array: []
    });
    getCompanyPatientDemographicLogsCount(params.id, startDate, endDate);
    // getCompanyActiveInventory(params.id);
    getCompanyManufacturerWeights(params.id);
  }, [params.id]);

  return (
    <AppPermissionProvider companyId={params.id}>
      <div>
        <PageHeader title={company.company_name || ""}>
          <button
            style={{ margin: 0, padding: 5 }}
            onClick={browserHistory.goBack}
            className="secondary button"
          >
            Go Back
          </button>
        </PageHeader>
        <div className="company-profile-container">
          <CompanyTabs
            id={params.id}
            userRole={userRole}
            userAppPermissions={userAppPermissions}
            managesCompliance={company?.compliance_workflow}
          />
          <DateRangeContext.Provider
            value={{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              dateRangeIsValid,
              handleSubmit: (startDate, endDate) => {
                getCompanyOrdersReport(params.id, {
                  start: startDate,
                  end: endDate
                });
                getCompanyPatientReport(params.id, startDate, endDate);
                getCompanyContactsReport({
                  id: params.id,
                  start: startDate,
                  end: endDate
                });
                fetchOrderStatusChangeHeatMap({
                  payload: {
                    id: params.id,
                    start_date: startDate,
                    end_date: endDate,
                    entity_type: "CompanyOrderStatusChangeCounts"
                  }
                });
                getCompanyPatientDemographicLogs(params.id, {
                  filter_by: [
                    { by: "startDate", value: startDate },
                    { by: "endDate", value: endDate }
                  ],
                  sort: { by: "timestamp", direction: "desc" },
                  pagination: { page: 1, per: 150 },
                  search_for: {},
                  search_for_array: []
                });
                getCompanyPatientDemographicLogsCount(
                  params.id,
                  startDate,
                  endDate
                );
              }
            }}
          >
            <div className="company-content">{children}</div>
          </DateRangeContext.Provider>
        </div>
      </div>
    </AppPermissionProvider>
  );
};

export default connect(
  state => ({
    company: selectors.getCompanyProfile(state),
    userRole: selectors.getUserRole(state),
    userAppPermissions: selectors.getUserAppPermissions(state)
  }),
  {
    getCompany,
    getCompanyOrdersReport,
    getCompanyContactsReport,
    getCompanyPatientReport,
    fetchOrderStatusChangeHeatMap,
    getCompanyPatientDemographicLogs,
    getCompanyPatientDemographicLogsCount,
    // getCompanyActiveInventory,
    getCompanyManufacturerWeights
  }
)(CompanyProfile);

CompanyProfile.propTypes = {
  children: PropTypes.node.isRequired,
  company: PropTypes.object,
  getCompany: PropTypes.func.isRequired,
  getCompanyContactsReport: PropTypes.func.isRequired,
  getCompanyOrdersReport: PropTypes.func.isRequired,
  getCompanyPatientReport: PropTypes.func.isRequired,
  params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  fetchOrderStatusChangeHeatMap: PropTypes.func.isRequired,
  getCompanyPatientDemographicLogs: PropTypes.func.isRequired,
  getCompanyPatientDemographicLogsCount: PropTypes.func.isRequired,
  // getCompanyActiveInventory: PropTypes.func.isRequired,
  getCompanyManufacturerWeights: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  userAppPermissions: PropTypes.object.isRequired
};
