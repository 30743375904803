import React, { useContext } from "react";
import FormikField from "components/forms/formik-field";
import { Form, Formik, FieldArray } from "formik";
import { selectors } from "reducers";
import { connect } from "react-redux";
import MdCancel from "react-icons/lib/md/cancel";
import MdAdd from "react-icons/lib/md/add";
import { IconButton } from "components/ui";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { InsuranceCompanyContext } from "./insurance-company-provider";

const InsuranceCompanyForm = ({
  insuranceOptions,
  values,
  closeModal,
  add
}) => {
  const insuranceName = values.sInsuranceCompanyName;
  const { updateInsurance } = useContext(InsuranceCompanyContext);
  return (
    <Formik
      initialValues={values}
      onSubmit={formData => {
        updateInsurance(formData);
        closeModal();
      }}
      validationSchema={Yup.object().shape({
        sInsuranceCompanyName: Yup.array()
          .of(Yup.string().required("Required"))
          .min(1),
        typeGuid: Yup.string().required("Required"),
        sPayorID: Yup.string().nullable(),
        sExtraInfo: Yup.string().nullable(),
        iAllowableDuration: Yup.string().nullable(),
        bActive: Yup.bool(),
        bCountsMaskAsSeal: Yup.bool(),
        bCMNRequired: Yup.bool(),
        bExcluded: Yup.bool(),
        bUseAsPrimary: Yup.bool(),
        bUseAsSecondary: Yup.bool(),
        bAllowsNinetyDaySupply: Yup.bool(),
        bEligibleForVerification: Yup.bool()
      })}
      enableReinitialize
    >
      {({ values, handleChange }) => {
        return (
          <div style={{ margin: "20px" }} id="insurance-company-form-container">
            <header
              className="page-header"
              style={{ margin: "0 10px 0 10px " }}
            >
              <div className="page-header-title">
                <h1>
                  <span className="page-title">
                    {add
                      ? "Insurance Company"
                      : values.prefix + "_" + insuranceName}
                  </span>
                </h1>
              </div>
            </header>
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 10
              }}
            >
              <fieldset>
                <legend>Main</legend>
                <FieldArray
                  name={"sInsuranceCompanyName"}
                  render={arrayHelpers => (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "inline-flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        {add ? (
                          <IconButton
                            type="button"
                            style={{
                              margin: "0 0 20px 0",
                              padding: "5px 15px",
                              float: "right"
                            }}
                            onClick={() => arrayHelpers.push("")}
                            text="Add"
                            icon={<MdAdd />}
                          />
                        ) : null}
                      </div>
                      {values.sInsuranceCompanyName?.map(
                        (sInsuranceCompanyName, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            {!add && (
                              <div
                                style={{
                                  width: "25%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "flex-end",
                                  paddingBottom: "2.5%"
                                }}
                              >
                                {values.prefix + "_"}{" "}
                              </div>
                            )}
                            <FormikField
                              name={`sInsuranceCompanyName.${index}`}
                              label={`Name`}
                              style={{ width: "75%" }}
                              type="text"
                              onChange={handleChange}
                            />
                            {add ? (
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                                style={{
                                  padding: 3,
                                  height: "80%",
                                  alignSelf: "center"
                                }}
                                icon={<MdCancel />}
                                type="button"
                                text="Delete"
                              />
                            ) : null}
                          </div>
                        )
                      )}
                    </>
                  )}
                />
                <FormikField name="typeGuid" label="Type" component="select">
                  <option key={0} />
                  {insuranceOptions.map(io => {
                    return (
                      <option key={io.value} value={io.value}>
                        {io.text}
                      </option>
                    );
                  })}
                </FormikField>
              </fieldset>
              <fieldset>
                <legend>Additional Info</legend>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormikField
                    name="sPayorID"
                    label="Client Insurance ID"
                    disabled={values.sInsuranceCompanyName.length > 1}
                    style={{ width: "45%" }}
                    type="text"
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormikField
                    name="sExtraInfo"
                    label="Extra Info"
                    style={{ width: "55%" }}
                    type="text"
                  />
                  <FormikField
                    name="iAllowableDuration"
                    label="Allowable Duration"
                    style={{ width: "15%", marginRight: "2%" }}
                    type="number"
                  />
                  <FormikField
                    name="iCapitated"
                    label="Capitated Duration"
                    style={{ width: "15%", marginRight: "2%" }}
                    type="number"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px"
                  }}
                >
                  <FormikField
                    name="bActive"
                    label="Active"
                    type="checkBox"
                    style={{ display: "flex" }}
                    className="form-field inline-checkbox"
                    checked={values.bActive}
                  />
                  <FormikField
                    name="bAllowsNinetyDaySupply"
                    label="Allows 90 Day Supply"
                    type="checkBox"
                    style={{ display: "flex" }}
                    className="form-field inline-checkbox"
                    checked={values.bAllowsNinetyDaySupply}
                  />
                  <FormikField
                    name="bCountsMaskAsSeal"
                    label="Counts Mask As Seal"
                    type="checkBox"
                    style={{ display: "flex" }}
                    className="form-field inline-checkbox"
                    checked={values.bCountsMaskAsSeal}
                  />
                  <FormikField
                    name="bCMNRequired"
                    label="CMN Required"
                    type="checkBox"
                    style={{ display: "flex" }}
                    className="form-field inline-checkbox"
                    checked={values.bCMNRequired}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "25px"
                  }}
                >
                  <FormikField
                    name="bEligibleForVerification"
                    label="Eligible For Verification"
                    type="checkBox"
                    style={{ display: "flex" }}
                    className="form-field inline-checkbox"
                    checked={values.bEligibleForVerification}
                  />
                  <FormikField
                    name="bExcluded"
                    label="Excluded"
                    type="checkBox"
                    style={{ display: "flex", flexDirection: "spaceBetween" }}
                    className="form-field inline-checkbox"
                    checked={values.bExcluded}
                  />

                  <FormikField
                    name="bUseAsPrimary"
                    label="Use As Primary"
                    type="checkBox"
                    style={{ display: "flex" }}
                    className="form-field inline-checkbox"
                    checked={values.bUseAsPrimary}
                  />
                  <FormikField
                    name="bUseAsSecondary"
                    label="Use As Secondary"
                    type="checkBox"
                    style={{ display: "flex" }}
                    className="form-field inline-checkbox"
                    checked={values.bUseAsSecondary}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "25px"
                  }}
                >
                  <div>IVR:</div>
                  <FormikField
                    name="bAsksDysfunctionsQuestions"
                    label="Requires Dysfunctions Questions"
                    type="checkBox"
                    style={{ display: "flex", flexDirection: "spaceBetween" }}
                    className="form-field inline-checkbox"
                    checked={values.bAsksDysfunctionsQuestions}
                  />
                  <FormikField
                    name="bIVRAutoShip"
                    label="Offers Autoship"
                    type="checkBox"
                    style={{ display: "flex", flexDirection: "spaceBetween" }}
                    className="form-field inline-checkbox"
                    checked={values.bIVRAutoShip}
                  />
                  <FormikField
                    name="bIVREducationStatement"
                    label="Reads Education Statement"
                    type="checkBox"
                    style={{ display: "flex", flexDirection: "spaceBetween" }}
                    className="form-field inline-checkbox"
                    checked={values.bIVREducationStatement}
                  />
                </div>
              </fieldset>
              <div>
                <button type="submit" style={{ width: "12%", float: "right" }}>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
export default connect(state => ({
  insuranceOptions: selectors.getFormOptionsInsurance(state)
}))(InsuranceCompanyForm);
InsuranceCompanyForm.propTypes = {
  insuranceOptions: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  values: PropTypes.object,
  add: PropTypes.bool,
  prefix: PropTypes.string
};
