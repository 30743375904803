import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { createReducer, makeFetchReducer } from "utils/reducer";
import * as R from "ramda";
import { Types } from "./actions";

const isLoggedIn = createReducer(false, {
  [Types.GOOGLE_AUTH_LOGGED_OUT]: () => false,
  [Types.GOOGLE_AUTH_LOGGED_IN]: () => true
});

const isFetching = combineReducers({
  company_files: makeFetchReducer(
    [
      Types.REQUEST_GET_COMPANY_FILES,
      Types.RESPONSE_GET_COMPANY_FILES,
      Types.FAILURE_GET_COMPANY_FILES
    ],
    true
  ),
  folders: makeFetchReducer(
    [
      Types.REQUEST_GET_FOLDERS,
      Types.RESPONSE_GET_FOLDERS,
      Types.FAILURE_GET_FOLDERS
    ],
    true
  ),
  files: makeFetchReducer(
    [
      Types.REQUEST_GET_FOLDER_FILES,
      Types.RESPONSE_GET_FOLDER_FILES,
      Types.FAILURE_GET_FOLDER_FILES
    ],
    true
  )
});

const company_files = createReducer([], {
  [Types.GOOGLE_AUTH_LOGGED_OUT]: () => [],
  [Types.RESPONSE_GET_COMPANY_FILES]: (state, { payload: { files } }) => files
});

const files = createReducer(
  {},
  {
    [Types.GOOGLE_AUTH_LOGGED_OUT]: () => ({}),
    [Types.RESPONSE_GET_FOLDER_FILES]: (
      state,
      { payload: { files, parent } }
    ) => ({ ...state, [parent]: files })
  }
);

const folders = createReducer([], {
  [Types.GOOGLE_AUTH_LOGGED_OUT]: () => [],
  [Types.RESPONSE_GET_FOLDERS]: (state, { payload: { folders } }) => folders
});

const isInitialized = createReducer(false, {
  [Types.GOOGLE_API_INITIALIZED]: () => true,
  [Types.GOOGLE_AUTH_LOGGED_OUT]: () => true,
  [Types.GOOGLE_AUTH_LOGGED_IN]: () => true
});

export default combineReducers({
  isLoggedIn,
  isInitialized,
  company_files,
  isFetching,
  folders,
  files
});

const getGapiIsLoggedIn = createSelector(
  [state => state.get("googleApi")],
  R.propOr(false, "isLoggedIn")
);

const getGapiCompanyFiles = createSelector(
  [state => state.get("googleApi")],
  R.propOr([], "company_files")
);

const getGapiFolderFiles = createSelector(
  [
    state => state.get("googleApi"),
    (_, props) => R.path(["folder", "id"], props)
  ],
  (gapiState, folderId) => R.pathOr([], ["files", folderId])(gapiState)
);

const getGapiUserFolders = createSelector(
  [state => state.get("googleApi")],
  R.propOr([], "folders")
);

const getGapiClientEnabled = createSelector(
  [state => state.get("googleApi")],
  R.propOr(false, "isInitialized")
);

const getGapiIsFetchingCompanyFiles = createSelector(
  [state => state.get("googleApi")],
  R.pathOr(false, ["isFetching", "company_files"])
);

const getGapiIsFetchingFiles = createSelector(
  [state => state.get("googleApi")],
  R.pathOr(false, ["isFetching", "files"])
);

const getGapiIsFetchingFolders = createSelector(
  [state => state.get("googleApi")],
  R.pathOr(false, ["isFetching", "folders"])
);

const getGapiAllFileOptions = createSelector(
  [state => state.get("googleApi")],
  gapiState => {
    const allFiles = R.pipe(R.prop("files"))(gapiState);
    return Object.keys(allFiles)
      .reduce(
        (previous, key) => [...previous, ...R.propOr([], key, allFiles)],
        []
      )
      .map(({ id, name }) => ({ text: name, value: id }));
  }
);

export const selectors = {
  getGapiAllFileOptions,
  getGapiClientEnabled,
  getGapiCompanyFiles,
  getGapiIsFetchingCompanyFiles,
  getGapiIsFetchingFiles,
  getGapiIsFetchingFolders,
  getGapiIsLoggedIn,
  getGapiUserFolders,
  getGapiFolderFiles
};
