import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import IVRCallsTable from "components/ivr-control-center/ivr-calls-table"
import IVRCallStatesTable from "components/ivr-control-center/ivr-call-states-table"
import { queryInit, updateQuery, resetQueryApiAndParams } from "../../features/query/actions"
import A from "action-types";

export const startCompletedIVRCallsToOrderConversion = () => ({
    type: A.IVR.STATES.START_COMPLETED_IVR_CALLS_TO_ORDERS_CONVERSION,
    payload: "ivrCalls"
})


class IVRTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableType: "master",   
            callSid: ""
        };
        this.switchTableType = this.switchTableType.bind(this)
        this.handleChange = this.handleChange.bind(this)      
        this.loadCall= this.loadCall.bind(this)
    }

    handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState({
            ...this.state,
            [name]: value
        })
    }   

    switchTableType(newTableType) {
        this.setState({
            ...this.state,
            tableType: newTableType
        })
    }

    loadCall(incomingCallSid = null) {
        if (incomingCallSid != null) {
            this.setState({
                ...this.state,
                callSid: incomingCallSid,
                tableType: "call"
            })
        } else {
            const { tableType } = this.state
            const callSid = this.state.callSid.slice()
            const { query, updateQuery } = this.props;
            const callStatesQuery = query.queryById.ivrCallStates;
            let filters = {
                callSid: [callSid],
            }
            const newQuery = {
                ...callStatesQuery,
                filters: filters
            }
            if (tableType === "master") {
                this.setState({
                    ...this.state,
                    callSid: callSid,
                    tableType: "call"
                })
            } else { updateQuery("ivrCallStates", newQuery); }  
        }            
    }   

    render() {
        const { tableType, callSid } = this.state
        const { loadOrder, loadPatient, startCompletedIVRCallsToOrderConversion } = this.props
        
        return (
            <React.Fragment>
                <div className='orderFormQuestionsSubmit'>
                    <label>
                        CallSid
                        <input
                            name="callSid"
                            type='text'
                            value={callSid}
                            onChange={e => this.handleChange(e)} />
                    </label>

                    <button
                        onClick={() => this.loadCall()}
                    >
                        Submit
                    </button>
                </div>             
                <div>                    
                    {tableType === "master" &&
                        <div>
                        <IVRCallsTable 
                            startCompletedIVRCallsToOrderConversion={startCompletedIVRCallsToOrderConversion}
                            loadCall= {this.loadCall}
                            loadOrder={loadOrder}
                            loadPatient={loadPatient}
                            queryId={"ivrCalls"}
                            apiId={"ivrCalls"}    
                        />
                    </div>}
                    {tableType === "call" &&
                        <div>
                        <IVRCallStatesTable                            
                            loadMasterTable={this.switchTableType}
                            loadOrder={loadOrder}
                            loadPatient={loadPatient}
                            callSid={this.state.callSid}
                            queryId={"ivrCallStates"}
                            apiId={"ivrCallStates"}    
                        />
                    </div>}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state) => ({     
        query: state.getIn(["query"]),
    }),
    dispatch =>
        bindActionCreators(
            {                
                queryInit,
                updateQuery,
                resetQueryApiAndParams,
                startCompletedIVRCallsToOrderConversion
            },
            dispatch
        )
)(IVRTableContainer)


IVRTableContainer.propTypes = {
    startCompletedIVRCallsToOrderConversion: PropTypes.func,
    loadOrder: PropTypes.func.isRequired,
    loadPatient: PropTypes.func.isRequired,
    query: PropTypes.object.isRequired,
    updateQuery: PropTypes.func.isRequired
};
