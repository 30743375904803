import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import * as R from "ramda";
import { compose, withProps } from "recompose";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withExport,
  withHeaderFooter
} from "features/query";
import withBatch from "features/batch";
import { selectors } from "reducers";
import { withModal } from "components/ui";
import Table from "components/ui/impl/table";
import {
  queuePatients,
  flushPatientQueue,
  invalidatePatient,
  fetchPatient
} from "actions/patient";
import { createTab } from "actions/system-tray";
import { setPatientBatchIds } from "actions/batch";
import BulkPatientUpdate from "components/bulk-patient-update";
import { outreachCycleOptions } from "utils/enum";
import CompanyTeamSelect from "components/shared/company-team-select";
import SleepCoachTeamSelect from "components/shared/coach-team-select";
import DateRangeFilter from "components/shared/date-range-filter";
import IoMdEdit from "react-icons/lib/io/edit";
import IoMdPerson from "react-icons/lib/io/person";
import IoMdCheckmark from "react-icons/lib/io/checkmark";
import { isBeforeNow } from "utils/dates";
import NewDateRangeFilter from "components/shared/new-date-range-filter";

const formatDate = date => (date ? moment(date).format("YYYY / MM / DD") : "");
const formatPatientStage = patient_stage => patient_stage.Description;

const PatientStatusTable = compose(
  connect(
    state => ({
      formOfContactOptions: selectors.getEnumFormOfContact(state),
      sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state),
      companieOptions: selectors.getFormOptionsCompany(state),
      userAppPermissions: selectors.getUserAppPermissions(state),
      complianceCoaches: selectors.getComplianceCoaches(state)
    }),
    {
      createTab,
      queuePatients,
      flushPatientQueue,
      invalidatePatient,
      fetchPatient,
      setPatientBatchIds
    }
  ),
  withQuery(),
  withApi({
    api: {
      route: "patients",
      responseKey: "patients",
      idKey: "id",
      entityKey: "patientRows"
    }
  }),
  withExport(),
  withBatch({ recordIdKey: "id" }),
  withModal,
  withProps(
    ({
      companieOptions,
      complianceCoaches,
      sleepCoachOptions,
      formOfContactOptions,
      batchColumn,
      markedForBatch,
      openModal,
      closeModal,
      requestData,
      createTab,
      setPatientBatchIds,
      batchClear,
      patientStatus,
      userRole,
      userAppPermissions
    }) => ({
      columns: R.filter(R.is(Object), [
        /* eslint-disable react/prop-types */
        batchColumn,
        {
          header: "Active",
          field: "active",
          format: ({ active }) => active && <IoMdCheckmark />,
          filters: [
            {
              text: "Active",
              value: "true"
            },
            {
              text: "Inactive",
              value: "false"
            }
          ]
        },
        {
          header: "Name",
          field: "last_name",
          format: ({ first_name, last_name, id }) => (
            <Link to={`/patients/${id}`}>
              {first_name} {last_name}
            </Link>
          )
        },
        {
          header: "Account #",
          field: "account_number"
        },
        userAppPermissions?.orderSupplies && {
          header: "Contact Type",
          field: "form_of_contact",
          filters: formOfContactOptions
        },
        {
          header: "Insurance",
          field: "insurance_company",
          sort: "insurance_company"
        },
        {
          header: filters => (
            <div
              style={{
                color: filters.delayed_activation_date ? "#af0200" : "initial"
              }}
            >
              Delayed Activation Date
            </div>
          ),
          field: "delayed_activation_date",
          format: ({ delayed_activation_date }) => formatDate(delayed_activation_date),
          renderFilter: patientStatus == "float-report"  || patientStatus == "unassigned" ? 
                props => (
                        <NewDateRangeFilter
                            field="delayed_activation_date"
                            selectStyles={{width: "100%"}}
                            {...props}
                        />
                    )
                :
                null
        },
        [
          "Administrator",
          "SleepCoach",
          "ServiceAdmin",
          "ServiceCoach",
          "ContractorAdministrator"
        ].includes(userRole) && {
          header: "Sleep Coach",
          field: "sleep_coach",
          filters: sleepCoachOptions,
          filterDownSelect: true,
          subFilter: SleepCoachTeamSelect
        },
        {
          header: "Compliance Coach",
          field: "compliance_coach",
          sort: "compliance_coach",
          filters: complianceCoaches
        },
        {
          header: "Company",
          field: "company",
          filters: companieOptions,
          filterDownSelect: true,
          subFilter: CompanyTeamSelect
        },
        ...(userAppPermissions?.orderSupplies &&
        ["OfficeAdministrator", "CompanyAdministrator"].includes(userRole)
          ? [
              {
                title: "Insurance Eligibility Date",
                header: "Insurance Eligibility Date",
                field: "patient_insurance_eligible",
                format: ({ patient_insurance_eligible }) =>
                  formatDate(patient_insurance_eligible)
              },
              {
                title: "Eligible for Outreach",
                header: "Eligible for Outreach",
                field: "dateEligibleForAutomation",
                format: ({ dateEligibleForAutomation }) =>
                  isBeforeNow(dateEligibleForAutomation) && <IoMdCheckmark />
              }
            ]
          : []),
        ...([
          "Administrator",
          "SleepCoach",
          "ServiceAdmin",
          "ServiceCoach",
          "ContractorAdministrator"
        ].includes(userRole)
          ? [
              {
                title: "Sort by outreach date",
                header: filters => (
                    <div
                      style={{
                        color: filters.dateEligibleForAutomation ? "#af0200" : "initial"
                      }}
                    >
                      Outreach Date
                    </div>
                  ),
                format: ({ dateEligibleForAutomation }) =>
                  formatDate(dateEligibleForAutomation),
                field: "dateEligibleForAutomation",
                renderFilter: patientStatus == "float-report"  || patientStatus == "unassigned" ? 
                    props => (
                        <NewDateRangeFilter
                            field="dateEligibleForAutomation"
                            selectStyles={{width: "100%"}}
                            {...props}
                        />
                    )
                    :
                    null
              }
            ]
          : []),
        userAppPermissions?.orderSupplies &&
          ["all", "float-report"].includes(patientStatus) && {
            header: "Outreach Cycle",
            field: "cycle_count",
            filters: outreachCycleOptions
          },
        patientStatus === "delayed" && {
          header: "Delayed Date",
          field: "delayed_activation_date",
          format: ({ delayed_activation_date }) =>
            formatDate(delayed_activation_date)
        },
        {
          header: filters => (
            <div
              style={{
                color: filters.created_at_start ? "#af0200" : "initial"
              }}
            >
              Created
            </div>
          ),
          field: "created_at",
          format: ({ date_added }) => formatDate(date_added),
          renderFilter: props => (
            <DateRangeFilter
              start_key="created_at_start"
              end_key="created_at_end"
              {...props}
            />
          )
        },
        ["Administrator", "SleepCoach"].includes(userRole) && {
          header: "Ordered",
          field: "has_order_after_added",
          onHeaderClick: () => {}, // get rid or sorting on column
          format: ({ has_order_after_added }) =>
            has_order_after_added && <IoMdCheckmark />,

          filters: [
            {
              text: "Yes",
              value: "true"
            },
            {
              text: "No",
              value: "false"
            }
          ]
        },
        userAppPermissions?.orderSupplies && {
          header: "New Setup",
          field: "new_setup",
          format: ({ new_setup }) => new_setup && <IoMdCheckmark />,
          filters: [
            {
              text: "True",
              value: "true"
            },
            {
              text: "False",
              value: "false"
            }
          ]
        },
        userAppPermissions?.orderSupplies && {
          header: "Remote Setup",
          field: "remote_setup",
          format: ({ remote_setup }) => remote_setup && <IoMdCheckmark />,
          filters: [
            {
              text: "True",
              value: "true"
            },
            {
              text: "False",
              value: "false"
            }
          ]
        },
        userRole !== "SleepCoach" &&
          userAppPermissions?.orderSupplies && {
            header: "On Hold",
            field: "on_hold",
            format: ({ on_hold }) => on_hold && <IoMdCheckmark />,
            filters: [
              {
                text: "True",
                value: "true"
              },
              {
                text: "False",
                value: "false"
              }
            ]
          },
          {
            header: "Patient Stage",
            field: "patient_stage",
            filters: [
              { value: "1", text: "Sleep Resupply" },
              { value: "2", text: "Compliance" },
              { value: "3", text: "Remote Set Up" }
            ],
            format: ({ patient_stage }) =>
            formatPatientStage(patient_stage)
          },
      ]),

      customDisplay: [
        () =>
          markedForBatch.length > 0 ? (
            <div
              key="patients-marked-label"
              style={{
                color: "white",
                display: "flex",
                flex: 1,
                justifyContent: "center"
              }}
            >
              {markedForBatch.length} Patients Selected
            </div>
          ) : null
      ],
      customButtons: [
        {
          icon: () => <IoMdPerson />,
          title: `Process patients (${markedForBatch.length} selected)`,
          onClick: () => {
            if (markedForBatch.length) {
              setPatientBatchIds(markedForBatch);
              createTab({
                type: "patients",
                key: "patients",
                label: `Patients (${markedForBatch.length})`,
                meta: { ids: markedForBatch }
              });
              batchClear();
            }
          }
        },
        ...([
          "Administrator",
          "ServiceAdmin",
          "ContractorAdministrator"
        ].includes(userRole)
          ? [
              {
                icon: () => <IoMdEdit />,
                title: `Bulk update patients (${markedForBatch.length} selected)`,
                onClick: () => {
                  if (markedForBatch.length) {
                    openModal(
                      <div>
                        <h3>Bulk Patient Update</h3>
                        <BulkPatientUpdate
                          patientIds={markedForBatch}
                          onUpdateError={() => {
                            batchClear();
                          }}
                          onUpdateSuccess={() => {
                            closeModal();
                            batchClear();
                            requestData();
                          }}
                        />
                      </div>
                    );
                  }
                }
              }
            ]
          : [])
      ],
      showCount: true
    })
  ),
  withHeaderFooter({
    perPageOptions: [15, 150, 300, 500],
    forceBatchReset: true,
    scrollable: true
  }),
  withTableHandlers()
)(Table);

PatientStatusTable.propTypes = {
  patientStatus: PropTypes.string.isRequired
};

export default PatientStatusTable;
