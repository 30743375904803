import { Types } from "actions/product-media";
import { createReducer } from "utils/reducer";

const initialState = {
  videos: [],
  images: [],
};

const getMediaSorted = (media) => {
  return [
    ...media.filter((medium) => medium.primary),
    ...media.filter((medium) => medium.active && !medium.primary),
    ...media.filter((medium) => !medium.active && !medium.primary),
  ];
};

const productMedia = createReducer(initialState, {
  [Types.GET_PRODUCT_VIDEOS]: (state, { payload }) => ({
    ...state,
    videos: getMediaSorted(payload),
  }),
  [Types.GET_PRODUCT_PHOTOS]: (state, { payload }) => ({
    ...state,
    images: getMediaSorted(payload),
  }),
  [Types.SET_PHOTO_PRIMARY]: (state, { payload: { mediaId, primary } }) => {
    const images = state.images.map((x) => ({
      ...x,
      primary: x.media_id === mediaId && primary,
    }));
    return {
      ...state,
      images: getMediaSorted(images),
    };
  },
  [Types.SET_PHOTO_ACTIVE]: (state, { payload: { mediaId, active } }) => {
    const images = state.images.map((x) => ({
      ...x,
      active: x.media_id === mediaId ? active : x.active,
    }));
    return {
      ...state,
      images: getMediaSorted(images),
    };
  },
  [Types.SET_VIDEO_PRIMARY]: (state, { payload: { mediaId, primary } }) => {
    const videos = state.videos.map((x) => ({
      ...x,
      primary: x.media_id === mediaId && primary,
    }));
    return {
      ...state,
      videos: getMediaSorted(videos),
    };
  },
  [Types.SET_VIDEO_ACTIVE]: (state, { payload: { mediaId, active } }) => {
    const videos = state.videos.map((x) => ({
      ...x,
      active: x.media_id === mediaId ? active : x.active,
    }));
    return {
      ...state,
      videos: getMediaSorted(videos),
    };
  },
});

export default productMedia;