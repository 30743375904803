import { Types } from "actions/twilio-chat";
import { createReducer } from "utils/reducer";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import * as R from "ramda";

const error = createReducer("", {
  [Types.START_TWILIO_CHAT]: () => ""
});

const isReady = createReducer(false, {
  [Types.TWILIO_CHAT_READY]: () => true
});

const isOpen = createReducer(false, {
  [Types.START_TWILIO_CHAT]: () => true,
  [Types.CLOSE_TWILIO_CHAT]: () => false
});

const isConnected = createReducer(false, {
  [Types.TWILIO_CHAT_STARTED]: () => true,
  [Types.TWILIO_CHAT_ENDED]: () => false
});

const messages = createReducer([], {
  [Types.START_TWILIO_CHAT]: () => [],
  [Types.TWILIO_CHAT_MESSAGE_ADDED]: (state, { payload }) => [
    ...state,
    payload
  ],
  [Types.TWILIO_CHAT_RESTORE_MESSAGES]: (state, { payload }) => [
    ...state,
    ...payload
  ]
});
const chatDetails = createReducer(
  {},
  {
    [Types.PENDING_CHAT_RECIEVED]: (_, { payload }) => payload,
    [Types.CLOSE_TWILIO_CHAT]: () => ({})
  }
);
const pendingChats = createReducer([], {
  [Types.PENDING_CHATS_RECIEVED]: (_, { payload }) => payload
});

export default combineReducers({
  error,
  isReady,
  isOpen,
  messages,
  chatDetails,
  pendingChats,
  isConnected
});

export const selectors = {
  getChatIsOpen: createSelector(
    [state => state.getIn(["twilio-chat"])],
    R.prop("isOpen")
  ),
  getChatIsConnected: createSelector(
    [state => state.getIn(["twilio-chat"])],
    R.prop("isConnected")
  ),
  getChatMessages: createSelector(
    [state => state.getIn(["twilio-chat"])],
    R.prop("messages")
  )
};
