import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IoMdCheckmark from "react-icons/lib/io/checkmark";
import IoMdClose from "react-icons/lib/io/close";

const InventoryTableRow = ({
  id,
  product_id,
  name,
  hcpcs,
  product_type,
  manufacturer,
  avgReimbursment,
  productActive
}) => (
  <tr>
    <td>
      <Link to={`/inventory/${id}`}>{name}</Link>
    </td>
    <td>{product_id}</td>
    <td>{hcpcs}</td>
    <td>{product_type}</td>
    <td>{manufacturer}</td>
    <td>${avgReimbursment}</td>
    <td>{productActive ? <IoMdCheckmark /> : <IoMdClose />}</td>
  </tr>
);
export default React.memo(InventoryTableRow);

InventoryTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  product_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hcpcs: PropTypes.string.isRequired,
  product_type: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  avgReimbursment: PropTypes.number.isRequired,
  productActive: PropTypes.bool.isRequired
};
