import React, { useContext } from "react";
import PropTypes from "prop-types";
import QueryTableContext from "../context";
import {
  MdFilterList,
  MdArrowDropDown,
  MdArrowDropUp
} from "react-icons/lib/md";
import {
  hasFilter,
  parseDateFilter
} from "hooks/useQuery/helpers";
import { path, pathOr } from "ramda";
import Dropdown from "components/ui/dropdown";
import { IconButton } from "components/ui";
import isEqual from "lodash/isEqual";

const getSortIcon = (field, query) => {
  if (query?.sort?.by == field) {
    return path(["sort", "direction"], query) === "asc" ? (
      <MdArrowDropUp size={16} />
    ) : (
      <MdArrowDropDown size={16} />
    );
  }
};

const DateTimeHeaderColumn = ({
  header,
  field,
  headerClassName,
  style,
  title,
  dropdownPosition,
  isDateInput,
  decreaseFontSize,
  showFilterTags = false
}) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  return (
    <td
      className={`query-table-header-cell-container ${headerClassName}`}
      style={style}
      title={title}
    >
      <div className="query-table-header-cell">
        <div
          className={`header-text ${hasFilter(field, query.filters) ? "active" : ""} ${decreaseFontSize ? "small-header-text" : ""}`}
          onClick={() => {
            queryDispatcher({
              type: "toggleSort",
              by: field
            });
          }}
        >
          {header}
          <div className="sort-icon">{getSortIcon(field, query)}</div>
        </div>
        <DateRangeDropDown
          query={query}
          queryDispatcher={queryDispatcher}
          dropdownPosition={dropdownPosition}
          field={field}
          isDateInput={isDateInput}
          showFilterTags={showFilterTags}
        />
      </div>
    </td>
  );
};

export default DateTimeHeaderColumn;

DateTimeHeaderColumn.propTypes = {
  field: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  style: PropTypes.object,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  filterDownSelect: PropTypes.bool,
  title: PropTypes.string,
  dropdownPosition: PropTypes.oneOf(["", "left", "right", "middle"]),
  isDateInput: PropTypes.bool,
  decreaseFontSize: PropTypes.bool,
  showFilterTags: PropTypes.bool
};

const DateRangeDropDown = ({ field, dropdownPosition, isDateInput, showFilterTags }) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  const value = pathOr("", ["filters", field], query);
  const parsedValue = parseDateFilter(value);
  const [tmpFilter, setTmpFilter] = React.useState({
    date: parsedValue.date ?? "",
    date2: "",
    mode: parsedValue.mode ?? "before",
  });

  function stopPropagation(e) {
    e.stopPropagation();
  }

  function onClose() {
    const queryValue = tmpFilter;

    let queryString = tmpFilter.mode == "between"
      ? `${tmpFilter.mode}^${queryValue.date}^${queryValue.date2}`
      : `${tmpFilter.mode}^${queryValue.date}`
      
      if(tmpFilter.mode == "clear") 
        queryString = {}

    if (!isEqual(parsedValue, queryValue) && !isEqual(value, queryValue.date)) {
      queryDispatcher({
        type: "setFilter",
        by: field,
        value: queryString //after^2023-11-26
      });
    }
  }
  return (
    <Dropdown
      trigger={
        <IconButton
          icon={<MdFilterList />}
          className="button secondary icon-button column-filter-trigger"
        />
      }
      onClose={() => onClose()}
      style={{ marginTop: "35px" }}
      position={dropdownPosition}
    >
      <ul className="dropdown-list" onClick={stopPropagation}>
        <li
          className="dropdown-command"
          onClick={() => {
            setTmpFilter({ 
                date: "",
                date2: "",
                mode: "clear", 
            });
          }}
        >
          Clear
        </li>
        {showFilterTags && <ShowFilterTags tmpFilter={tmpFilter} setTmpFilter={setTmpFilter} />}
        {isDateInput &&
          <input
            type="date"
            style={{ margin: "0px" }}
            value={tmpFilter.date ?? ""}
            onChange={({ target }) => {
              setTmpFilter(x => ({ ...x, date: target.value }));
            }}
          />
        }
      </ul>
    </Dropdown>
  );
};
DateRangeDropDown.propTypes = {
  field: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired,
  dropdownPosition: PropTypes.oneOf(["", "left", "right", "middle"]),
  isDateInput: PropTypes.bool,
  showFilterTags: PropTypes.bool
};

const ShowFilterTags = ({ tmpFilter, setTmpFilter }) => {
  return (
    <>
      <li style={{ padding: "5px", paddingTop: "15px" }}>
        <select
          value={tmpFilter.mode ?? ""}
          onChange={({ target }) => {
            setTmpFilter(x => ({ ...x, mode: target.value ?? "before" }));
          }}
          style={{ margin: "0px" }}
        >
          <option value="before">Before</option>
          <option value="after">After</option>
          <option value="between">Between</option>
        </select>
      </li>
      <li style={{ padding: "5px" }}>
        <div
          style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          <input
            type="time"
            value={tmpFilter.date ?? ""}
            onChange={({ target }) => {
              setTmpFilter(x => ({ ...x, date: target.value }));
            }}
            style={{ margin: 0, marginTop: 10 }}
          />
          {tmpFilter.mode === "between" && (
            <>
              <span style={{ padding: "3px" }}>And</span>
              <input
                type="time"
                style={{ margin: "0px" }}
                value={tmpFilter.date2 ?? ""}
                onChange={({ target }) => {
                  setTmpFilter(x => ({ ...x, date2: target.value }));
                }}
              />
            </>
          )}
        </div>
      </li>
    </>
  )
}
ShowFilterTags.propTypes = {
  tmpFilter: PropTypes.object,
  setTmpFilter: PropTypes.func,
};
