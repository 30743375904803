import { Types } from "actions/companiesTable";
import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";
import * as R from "ramda";

export default createReducer([], {
  [Types.COMPANIES_TABLE_RESPONSE]: (_, { response }) =>{
    return R.propOr([], "companies", response);
  },
  [Types.CLEAR_COMPANY_TABLE]: () => {
    return [];
  }
});



const getCompaniesTableEntries = state => state.get("companiesTable");

const getCompaniesTableOptions = createSelector(
  [getCompaniesTableEntries],
  R.map(({ company_name, id }) => ({
    text: company_name,
    value: id,
  }))
);

export const selectors = {
  getCompaniesTableEntries,
  getCompaniesTableOptions
};
