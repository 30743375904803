import React from "react";
import { PageHeader, TabList } from "components/ui";
import { Switch, Route } from "react-router-dom";
import { getQuestionnaire } from "components/dd_newModules/Redux/Actions/questionnaire";
import { getServices } from "components/dd_newModules/Redux/Actions/services";
import AssignQuestionnaires from "./assign-questionnaires";
import QuestionnaireHistory from "./questionnaire-history";
import MangeQuestionnaires from "./manage-questionnaires";
import { useDispatch } from "react-redux";

const Questionnaires = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getServices());
    dispatch(getQuestionnaire());
  }, []);
  return (
    <div>
      <PageHeader title="Patient Questionnaires" />
      <div
        style={{
          display: "flex",
          padding: "10px 0px",
          alignItems: "center"
        }}
      >
        <TabList
          routes={[
            {
              exact: true,
              path: `/questionnaires`,
              label: "Assign Questionnaire"
            },
            {
              path: `/questionnaires/search`,
              label: "Search"
            },
            {
              path: `/questionnaires/manage`,
              label: "Manage Questionnaires"
            }
          ]}
        />
      </div>
      <Switch>
        <Route path="/questionnaires" component={AssignQuestionnaires} exact />
        <Route
          path="/questionnaires/search"
          component={QuestionnaireHistory}
          exact
        />
        <Route
          path="/questionnaires/manage"
          component={MangeQuestionnaires}
          exact
        />
      </Switch>
    </div>
  );
};

export default Questionnaires;
