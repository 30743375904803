import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import EditPatientInsurance from "./edit-insurance";
import DisplayPatientInsurance from "./display-patient-insurance";
import SectionHeader from "components/profile/section_header";
import { connect } from "react-redux";
import { updatePatient } from "actions/patient";
import { selectors } from "reducers";
import { PatientProfileContext } from "components/profile/context";

const InsuranceInformation = ({
  patientId,
  patientInfo,
  updatePatient,
  guarantorOptions
}) => {
  const [isEditingInsurance, setIsEditingInsurance] = useState(false);
  const [isEditingInsuranceSecondary, setIsEditingInsuranceSecondary] =
    useState(false);
  const toggleInsurance = () => {
    setIsEditingInsurance(!isEditingInsurance);
  };
  const toggleInsuranceSecondary = () => {
    setIsEditingInsuranceSecondary(!isEditingInsuranceSecondary);
  };
  const { companyInsurances } = useContext(PatientProfileContext);

  return (
    <div className="patient-insurance-section">
      <SectionHeader>Insurance Information</SectionHeader>
      {!isEditingInsurance ? (
        <DisplayPatientInsurance
          patientId={patientId}
          patientInsurance={patientInfo.primary_insurance}
          toggleInsurance={toggleInsurance}
          insuranceOrder="Primary"
        />
      ) : (
        <EditPatientInsurance
          patientId={patientId}
          patientInsurance={patientInfo.primary_insurance}
          toggleInsurance={toggleInsurance}
          insuranceOrder="Primary"
          guarantorOptions={guarantorOptions}
          insuranceOptions={companyInsurances}
          updatePatientInsurance={values => {
            return updatePatient({
              patientId,
              updates: { primary_insurance: values }
            });
          }}
        />
      )}
      {!isEditingInsuranceSecondary ? (
        <DisplayPatientInsurance
          patientId={patientId}
          patientInsurance={patientInfo.secondary_insurance}
          toggleInsurance={toggleInsuranceSecondary}
          insuranceOrder="Secondary"
        />
      ) : (
        <EditPatientInsurance
          patientId={patientId}
          patientInsurance={patientInfo.secondary_insurance}
          toggleInsurance={toggleInsuranceSecondary}
          insuranceOrder="Secondary"
          guarantorOptions={guarantorOptions}
          insuranceOptions={companyInsurances}
          updatePatientInsurance={values => {
            return updatePatient({
              patientId,
              updates: { secondary_insurance: values }
            });
          }}
        />
      )}
    </div>
  );
};

InsuranceInformation.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  updatePatient: PropTypes.func.isRequired,
  guarantorOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  // insuranceTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool.isRequired
};

InsuranceInformation.defaultProps = {
  readOnly: false
};

export default connect(
  state => ({
    guarantorOptions: selectors.getInsuranceRelationshipOptions(state)
    // insuranceTypeOptions: selectors.getFormOptionsInsurance(state)
  }),
  { updatePatient }
)(InsuranceInformation);
