import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CompatibleItemsTable from "./compatible-items-table";
import CrosswalkTable from "./crosswalk-table";
import InventoryMedia from "./inventory-media";
import InventoryFaqs from "./inventory-faqs";
import InventoryProvider from "../inventory-provider";
import FulfillmentInventoryProvider from "../../fulfillment-inventory/fulfillment-inventory-provider";
import ChangeLogTable from "./change-log-table";

const InventoryInfoTabs = ({ inventoryItem, isLoading, media, masterProfile, id }) => {
  const [, setSelectedTabIndex] = useState(0);
  return (
    <FulfillmentInventoryProvider>
      <InventoryProvider>
        <Tabs onSelect={index => setSelectedTabIndex(index)}>
          <div className="row">
            <TabList>
              <div>
                <Tab>
                  <div>Compatability</div>
                </Tab>
                <Tab>
                  <div>Media</div>
                </Tab>
                <Tab>
                  <div>FAQs</div>
                </Tab>
                <Tab>
                  <div>Change Logs</div>
                </Tab>
                {masterProfile ? 
                <Tab>
                  <div>Item Crosswalk</div>
                </Tab>
                : ""}
              </div>
            </TabList>
          </div>
          <div className="row">
            <TabPanel>
              <CompatibleItemsTable inventoryItem={inventoryItem} masterProfile={masterProfile} />
            </TabPanel>
            <TabPanel>
              <InventoryMedia
                media={media}
                isLoading={isLoading}
                inventoryItem={inventoryItem}
              />
            </TabPanel>
            <TabPanel>
              <InventoryFaqs inventoryItem={inventoryItem} />
            </TabPanel>
            <TabPanel>
              <ChangeLogTable id={id} masterProfile={masterProfile} />
            </TabPanel>
            {masterProfile ? 
            <TabPanel>
                  <CrosswalkTable inventoryItem={inventoryItem} />
            </TabPanel>
            : ""}
          </div>
        </Tabs>
      </InventoryProvider>
    </FulfillmentInventoryProvider>
  );
};

export default InventoryInfoTabs;

InventoryInfoTabs.propTypes = {
  inventoryItem: PropTypes.object.isRequired,
  masterProfile: PropTypes.bool,
  isLoading: PropTypes.bool,
  media: PropTypes.object,
  id: PropTypes.string
};
