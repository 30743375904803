import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const ExportsTable = ({ exportTable }) => (
  <table style={{ width: "100%" }}>
    <thead>
      <tr>
        <th colSpan={2}>Created at</th>
        <th>Description</th>
        <th>Status</th>
        <th>File</th>
      </tr>
    </thead>
    <tbody>
      {exportTable.map((e, i) => (
        <tr key={i}>
          <td colSpan={2}>
            {/*moment(e.get("created_at"), moment.ISO_8601).format("LL")*/}
            {moment(e.get("created_at")).format("MMM DD, YYYY -  hh:mm A")}
          </td>
          <td>{e.get("description")}</td>
          <td>{e.get("status")}</td>
          <td>
            {e.get("status") === "Completed" && (
              <a href={e.get("file")} target="_blank" rel="noopener noreferrer">
                Download
              </a>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default ExportsTable;

ExportsTable.propTypes = {
  exportTable: PropTypes.object.isRequired
};
