import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import {
  compose,
  setPropTypes,
  withState
} from "recompose";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

const DatePickerInput = compose(
  setPropTypes({
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string
  }),
  Radium,
  withState('validDate', 'setValidDate', true),
  withState('date', 'setDate', props => {
    return props.value
  })
)(({
  label,
  placeholder,
  handleDayChange,
  value,
  validDate,
  fromDate,
  toDate,
  inputProps
}) => (
    <div className={`date-picker-container${!validDate ? '--invalid-date' : ''}`}>
      <label>{label}</label>
      <DayPickerInput
      dayPickerProps={{ disabledDays: { before: fromDate, after:toDate}}}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={placeholder}
        onDayChange={handleDayChange}
        format='MM/DD/YYYY'
        value={value === 'Invalid date' ? '' : value} 
        inputProps={inputProps}
        />
    </div>
  ));

export default Radium(DatePickerInput);
