import React, { Component } from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";
import SearchableDropdown from "components/searchable-dropdown";
import PageHeader from "components/ui/page-header";
import { submitInsuranceImport } from "actions/insurance-import";
import { Waiting } from "components/ui";
import ImportResults from "./import-results";
import HasRolePermission from "components/shared/has-role-permission";

class InsuranceImportForm extends Component {
  state = {
    headersCheckboxChecked: true,
    company_id: "",
    userId: "",
    role: "",
  };

  handleChange = e => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleSearchableSelectChange = value => {
    this.setState({ company_id: value });
  };

  onInsuranceImportSubmit = e => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("company", this.state.company_id);
    formData.append("file", this.filesInput.files[0]);
    formData.append("headers", this.state.headersCheckboxChecked);

    const companyName =
      this.state.company_id !== ""
        ? this.props.companies.find(c => c.value === this.state.company_id).text
        : "";
    const { name: fileName } = this.filesInput.files[0];
    const confirmed = window.confirm(
      `Are you sure? Importing patients for\n ${companyName} \n File: ${fileName}`
    );
    if (confirmed) {
      try {
        this.props.submitInsuranceImport(formData);
      } finally {
        this.filesInput.value = "";
        this.setState({ company_id: "" });
      }
    }
  };

  render() {
    const { companies, isFetching, accountNumberFailures } = this.props;
    const searchableCompaniesOptions = companies?.map(x => ({ text: x.text, value: x.value, key: x.value }));
    const companyName =
      this.state.company_id !== ""
        ? companies.find(c => c.value === this.state.company_id).text
        : "";
    console.log("this.state", this.state);
    return (
      <div>
        <PageHeader title="Insurance Importer" />
        <HasRolePermission allowedRoles={["Administrator", "ServiceAdmin", "ContractorAdministrator"]}>
        <form
          encType="multipart/form-data"
          onSubmit={e => this.onInsuranceImportSubmit(e)}
          style={{
            display: "grid",
            gridTemplateColumns: "500px",
            gridGap: 10
          }}
          >
          <SearchableDropdown
            name="company_id"
            label="Company"
            requireSearch={true}
            selected={this.state.company_id}
            options={searchableCompaniesOptions}
            onSelect={this.handleSearchableSelectChange}
            />
          <input
            ref={input => {
              this.filesInput = input;
            }}
            name="file"
            required
            type="file"
            style={{ fontSize: "1.05rem" }}
            />
          <label>
            Headers
            <input
              style={{ marginLeft: "10px" }}
              name="headersCheckboxChecked"
              type="checkbox"
              checked={this.state.headersCheckboxChecked}
              onChange={this.handleChange}
              />
          </label>
          <input
            className="button"
            type="submit"
            value="Submit"
            disabled={!this.state.company_id || isFetching}
            />
        </form>
        </HasRolePermission>

        <ImportResults
          results={accountNumberFailures}
          title={`The following ${accountNumberFailures.length} insurances failed`}
          fields={[{ title: "Payor ID", name: "acctNum" }]}
        />
        {isFetching && (
          <div style={{ margin: "100px 0px 0px 0px" }}>
            <Waiting reason={`submitting ${companyName} insurances`} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    companies: selectors.getFormOptionsCompany(state),
    isFetching: state.getIn(["insurance-import"]).isFetching,
    accountNumberFailures: state.getIn(["insurance-import"]).accountNumberFailures,
    userId: selectors.getUserId(state),
    role: selectors.getUserRole(state)
  }),
  {
    submitInsuranceImport
  }
)(InsuranceImportForm);

InsuranceImportForm.propTypes = {
  companies: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  accountNumberFailures: PropTypes.array,
  submitInsuranceImport: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};
