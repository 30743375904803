import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import useOutsideClick from "hooks/useOutsideClick";
import useKeyboardEvent from "hooks/useKeyboardEvent";

const ClickableTextInput = ({
  value,
  placeholder,
  numeric,
  fontColor,
  onEditingFinish,
  height,
  clickable
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isInFocus, setIsInFocus] = useState(false);
  const [editText, setEditText] = useState(value || "");
  const inputRef = useRef();

  useEffect(() => {
    if (!clickable && isInFocus) {
      setIsEditing(false);
      setIsInFocus(false);
      onEditingFinish(editTextFormatted());
    }
  }, [clickable]);

  const editTextFormatted = () => {
    if (numeric) return parseInt(editText);
    return editText;
  };

  useOutsideClick(inputRef, () => {
    if (isInFocus) {
      setIsEditing(false);
      setIsInFocus(false);
      onEditingFinish(editTextFormatted());
    }
  });

  useKeyboardEvent("Enter", () => {
    if (isInFocus) {
      inputRef.current.blur();
      setIsEditing(false);
      setIsInFocus(false);
      onEditingFinish(editTextFormatted());
    }
  });

  const onInputClick = () => {
    if (clickable) {
      setIsEditing(true);
      setIsInFocus(true);
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  };

  const onInputChange = event => {
    if (clickable) {
      setIsInFocus(true);
      let eventText = event.target.value;
      eventText = numeric ? eventText.replace(/[^0-9]/g, "") : eventText;
      if (isEditing) {
        setEditText(eventText);
      }
    }
  };

  const className = `${
    isEditing || !editText ? "input-editable" : "not-input-editable"
  } ${clickable ? "cursor-pointer" : ""}`;

  return (
    <input
      className={className}
      style={{ textShadow: "0 0 0 " + (fontColor || "black"), height: height }}
      ref={inputRef}
      type="text"
      placeholder={placeholder}
      value={editText}
      onChange={onInputChange}
      onClick={onInputClick}
    />
  );
};

ClickableTextInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  numeric: PropTypes.bool,
  fontColor: PropTypes.string,
  onEditingFinish: PropTypes.func.isRequired,
  height: PropTypes.string,
  clickable: PropTypes.bool.isRequired
};

ClickableTextInput.defaultProps = {
  clickable: true
};

export default ClickableTextInput;
