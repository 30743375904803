import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { requestUpdateOrderPendingInfo } from "actions/order-status";

export class PendingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value || "" };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
  };

  handleOnBlur = ({ target: { value } }) => {
    if (!value.trim() && !this.props.value) return;
    else if (value.trim() !== this.props.value)
      this.requestUpdateOrderPendingInfo(this.props.orderId, value);
  };

  requestUpdateOrderPendingInfo = debounce(
    (orderId, newVal) =>
      this.props.requestUpdateOrderPendingInfo(orderId, newVal),
    300,
    { leading: false }
  );

  render() {
    return (
      <input
        value={this.state.value || ""}
        onChange={this.handleChange}
        onBlur={this.handleOnBlur}
        title={this.state.value || "No Pending Info Recorded"}
        disabled={this.props.disabled}
      />
    );
  }
}

PendingInfo.propTypes = {
  value: PropTypes.string,
  orderId: PropTypes.string.isRequired,
  requestUpdateOrderPendingInfo: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
export default connect(
  null,
  { requestUpdateOrderPendingInfo }
)(PendingInfo);
