import { get } from "utils/api";

const prefix = type => "s3/phone-system/" + type;

export const Types = {
  GET_PHONE_BOOK_USERS_REQUEST: prefix("GET_PHONE_BOOK_USERS_REQUEST"),
  GET_PHONE_BOOK_USERS: prefix("GET_PHONE_BOOK_USERS"),
  GET_PHONE_BOOK_USERS_SUCCESS: prefix("GET_PHONE_BOOK_USERS_SUCCESS"),
  GET_PHONE_BOOK_USERS_FAILURE: prefix("GET_PHONE_BOOK_USERS_FAILURE")
};

export const requestVoipUsers = () => ({
  type: Types.GET_PHONE_BOOK_USERS,
  callAPI: () => get("admin/phones")
});
