import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { fetchPatientProfileInfo, editPatient } from "actions/patient";
import PatientForm from "components/patient-form";
import { selectors } from "reducers";
import { withRouter } from "react-router-dom";
import { PageHeader } from "components/ui";
import { propOr } from "ramda";
import moment from "moment";

const EditPatientForm = ({ match }) => {
  const patientId = match.params.id;
  const patientInfo = useSelector(state =>
    selectors.getPatientInfo(state, patientId)
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchPatientProfileInfo({ patientId }));
  }, [match.params.id]);
  return (
    <div className="page-form-page">
      <PageHeader
        title={`Edit Patient ${propOr("", "first_name", patientInfo)} ${propOr(
          "",
          "last_name",
          patientInfo
        )}`}
      />
      <PatientForm
        handleSubmit={values => dispatch(editPatient(match.params.id, values))}
        patient={{
          ...patientInfo,
          date_of_birth: patientInfo?.date_of_birth
            ? moment(patientInfo.date_of_birth).format("YYYY-MM-DD")
            : "",
          setup_date: patientInfo?.setup_date ? moment(patientInfo.setup_date).format("YYYY-MM-DD")
            : "",
          compliance_coach_id: patientInfo?.compliance_coach_id ?? ""
        }}
      />
    </div>
  );
};
export default withRouter(EditPatientForm);

EditPatientForm.propTypes = {
  match: PropTypes.object.isRequired
};
