import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Waiting } from "components/ui";
import MdFilterList from "react-icons/lib/md/filter-list";
import MdArrowDropUp from "react-icons/lib/md/arrow-drop-up";
import MdArrowDropDown from "react-icons/lib/md/arrow-drop-down";
import MdSearchIcon from "react-icons/lib/md/search";
import FaFilter from "react-icons/lib/fa/filter";
import MdClear from "react-icons/lib/md/clear";
import { connect } from "react-redux";
import { propOr, pathOr } from "ramda";

const stopPropagation = event => event.stopPropagation();

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterToggles: {},
      inputValueArray: [],
      filterSearch: {},
    };
  }

  handleFilterToggle(event, columnIndex) {
    if (event) event.stopPropagation();
    this.setState(({ filterToggles }) => ({
      filterToggles: {
        // only one filter pane open at a time
        // ...filterToggles,
        [columnIndex]: !filterToggles[columnIndex]
      }
    }));
  }

  handleAddFilterClick(event, filter, column) {
    event.stopPropagation();
    const { onAddFilter } = this.props;
    if (onAddFilter) {
      onAddFilter(filter, column);
    }
  }

  handleRemoveFilter(event, filter, column) {
    event.stopPropagation();
    const { onRemoveFilter } = this.props;
    if (onRemoveFilter) {
      onRemoveFilter(filter, column);
    }
  }

  handleClearFilters(event, column) {
    event.stopPropagation();
    this.props.onClearFilters(column);
  }

  handleInvertFilters(event, column) {
    event.stopPropagation();
    this.props.onInvertFilters(column);
  }

  handleInputChange(event, index) {
    event.stopPropagation();
    let value = event.target.value;
    let newInputValueArray = this.state.inputValueArray;
    newInputValueArray[index] = value;

    this.setState({
      inputValueArray: newInputValueArray
    });
  }

  handleAddSearchSubmit(event, column, value, index) {
    event.stopPropagation();
    const { query, queryId, onAddSearch } = this.props;
    let searches = query && query.queryById[queryId].searches;
    let by = column.search.by || column.field;
    let shouldMakeAPICall =
      !searches || (value !== searches[by] && value !== "");

    //don't send uneeded API request
    if (shouldMakeAPICall) {
      if (onAddSearch) {
        if (value !== undefined) {
          onAddSearch(value, column);
          this.handleFilterToggle(null, index);
        }
      }
    }
  }

  handleFilterDownSelect = ({ target }, columnIndex) => {
    this.setState({
      filterSearch: {
        ...this.state.filterSearch,
        [columnIndex]: target.value
      }
    });
  };

  downSelectFilter = columnIndex => ({ text }) => {
    try {
      const value = this.state.filterSearch[columnIndex];
      if (typeof value === "string" && value.length > 0) {
        return text.toLowerCase().includes(value.trim().toLowerCase());
      }
      return true;
    } catch (e) {
      return true;
    }
  };

  handleRemoveSearch(event, column, index) {
    event.stopPropagation();
    const { query, queryId, onRemoveSearch } = this.props;
    let searches = query && query.queryById[queryId].searches;
    let by = column.search.by || column.field;
    let reduxSearch = searches[by];
    let shouldMakeAPICall = reduxSearch !== null && reduxSearch !== undefined;
    let newInputValueArray = this.state.inputValueArray;
    newInputValueArray[index] = "";

    //if to actually remove filter
    if (shouldMakeAPICall) {
      if (onRemoveSearch) {
        onRemoveSearch(column);
        this.setState({
          inputValueArray: newInputValueArray
        });
        this.handleFilterToggle(null, index);
      }
    }
    //if to clear input field
    else {
      this.setState({
        inputValueArray: newInputValueArray
      });
    }
  }

  render() {
    const {
      query,
      queryId,
      columns,
      scrollable,
      rows,
      rowKey,
      onHeaderClick,
      onClearFilters,
      onInvertFilters,
      isLoading,
      disabledsort,
      isComplianceContacts
    } = this.props;
    const { filterToggles, inputValueArray } = this.state;
    return (
      <div className={`table-container ${scrollable ? "scrollable" : ""}`}>
        <table className="s3-ui-table">
          <thead>
            <tr>
              {columns.map(({ HeaderCell, ...c }, i) => {
                if (HeaderCell) {
                  return <HeaderCell key={c.key || c.field} rows={rows} />;
                } else
                  return (
                    <th
                      key={c.key || c.field}
                      title={c.title}
                      className={`${c.className || ""} ${onHeaderClick && "interactive"
                        }`}
                    >
                      <div className="header">
                        <div
                          onClick={() => {
                            if (c.onHeaderClick && !disabledsort) {
                              // column-specific `onHeaderClick` override
                              c.onHeaderClick(c, i);
                            } else if (onHeaderClick && !disabledsort) {
                              // general `onHeaderClick`
                              onHeaderClick(c, i);
                            }
                          }}
                          className={`header-text${c.filters &&
                            c.filters.some(f => {
                              return f.active;
                            })
                            ? " active"
                            : ""
                            }`}
                        >
                          {typeof c.header === "function"
                            ? c.header(
                              pathOr(
                                {},
                                ["queryById", queryId, "filters"],
                                query
                              )
                            )
                            : c.header}
                        </div>
                        <div className="header-aux">
                          {c.sorted === "asc" && !disabledsort ? (
                            <MdArrowDropUp />
                          ) : c.sorted === "desc" && !disabledsort ? (
                            <MdArrowDropDown />
                          ) : (
                            ""
                          )}
                          {(c.filters ||
                            c.search ||
                            typeof c.renderFilter === "function") && (
                              <div
                                onClick={e => this.handleFilterToggle(e, i)}
                                className="filter-icon-container"
                              >
                                <MdFilterList />
                              </div>
                            )}
                        </div>
                      </div>
                      {(c.filters ||
                        c.search ||
                        typeof c.renderFilter === "function") &&
                        filterToggles[i] && (
                          <div>
                            <div className="filter-pane" style={c.field == "on_hold" ? {right: "0px"} : undefined}>
                              {c.search && (
                                <React.Fragment>
                                  <form
                                    onSubmit={e =>
                                      this.handleAddSearchSubmit(
                                        e,
                                        c,
                                        inputValueArray[i],
                                        i
                                      )
                                    }
                                  >
                                    <input
                                      className="searchInputBox"
                                      placeholder={c.search.placeholder}
                                      value={
                                        inputValueArray[i] == undefined ||
                                          inputValueArray[i] == null
                                          ? c.search.value
                                          : inputValueArray[i]
                                      }
                                      onChange={e =>
                                        this.handleInputChange(e, i)
                                      }
                                    />
                                    <i
                                      title="Click To Submit Search"
                                      className="filterListSearchIcon"
                                      onClick={e =>
                                        this.handleAddSearchSubmit(
                                          e,
                                          c,
                                          inputValueArray[i],
                                          i
                                        )
                                      }
                                    >
                                      <MdSearchIcon />
                                    </i>
                                    <i
                                      title="Click To Clear Search"
                                      className="filterListSearchIcon"
                                      style={{ color: "red" }}
                                      onClick={e =>
                                        this.handleRemoveSearch(e, c, i)
                                      }
                                    >
                                      <MdClear />
                                    </i>
                                  </form>
                                  {c.filters && (
                                    <hr className="hrForTableSearch" />
                                  )}
                                </React.Fragment>
                              )}
                              {c.renderFilter && (
                                <c.renderFilter
                                  setFilters={this.props.setFilters}
                                  queryId={this.props.queryId}
                                />
                              )}
                              {c.filters && (
                                <React.Fragment>
                                  {onClearFilters && (
                                    <div
                                      onClick={e =>
                                        this.handleClearFilters(e, c)
                                      }
                                      className="filter-pane-command controls"
                                    >
                                      Clear Selections
                                    </div>
                                  )}
                                  {onInvertFilters && (
                                    <div
                                      onClick={e =>
                                        this.handleInvertFilters(e, c)
                                      }
                                      className="filter-pane-command controls"
                                    >
                                      Invert Selections
                                    </div>
                                  )}
                                  {c.subFilter && (
                                    <c.subFilter
                                      setFilters={this.props.setFilters}
                                    />
                                  )}
                                  {c.filterDownSelect && (
                                    <div className="filter-downselect">
                                      <label>
                                        <FaFilter />
                                      </label>
                                      <input
                                        value={this.state.filterSearch[i] || ""}
                                        onChange={e =>
                                          this.handleFilterDownSelect(e, i)
                                        }
                                      />
                                    </div>
                                  )}
                                  {c.filters
                                    .filter(this.downSelectFilter(i))
                                    .map(f => (
                                      <label
                                        key={f.value}
                                        onClick={stopPropagation}
                                      >
                                        <input
                                          type={
                                            f.radioGroupName
                                              ? "radio"
                                              : "checkbox"
                                          }
                                          name={
                                            f.radioGroupName
                                              ? f.radioGroupName
                                              : ""
                                          }
                                          checked={f.active}
                                          onChange={e =>
                                            !f.active
                                              ? this.handleAddFilterClick(
                                                e,
                                                f,
                                                c
                                              )
                                              : this.handleRemoveFilter(e, f, c)
                                          }
                                        />
                                        {f.text}
                                      </label>
                                    ))}
                                </React.Fragment>
                              )}
                            </div>
                            <div
                              className="filter-pane-out-of-bounds"
                              onClick={e => {
                                this.setState({ filterSearch: {} });
                                this.handleFilterToggle(e, i);
                              }}
                            />
                          </div>
                        )}
                    </th>
                  );
              })}
            </tr>
          </thead>
          <tbody className={isLoading ? "loading" : ""}>
            {rows.map((row, ri) => {
              return isComplianceContacts ? (
                <React.Fragment key={propOr(ri, rowKey, row)}>
                  <tr className={ri % 2 === 0 ? "even" : "odd"}>
                    {columns.map(({ RowCell, format, field, key, formKey }) => {
                      if (RowCell) {
                        return (
                          <RowCell
                            key={`${rowKey ? row[rowKey] : ri}-${field || key}`}
                            {...row}
                          />
                        );
                      }
                      const formatField = format || field;
                      return (
                        <td
                          key={`${rowKey ? row[rowKey] : ri}-${field || key}`}
                          style={{
                            backgroundColor: row.CoachName === "Grand Total" ? "#CCCCCC" : "",    
                            ...(formKey === "formName" || formKey === "formDescription") && !row.IsActive
                              ? { textDecoration: "line-through" }
                              : { textDecoration: "none" },
                          }}
                          onClick={() => {
                            row.showCompanyName = !row?.showCompanyName 
                            this.setState(prevState => ({ showCompanyNames: !prevState.showCompanyNames }))
                          }}
                        >
                          { }
                          {typeof formatField === "string" ? (
                            typeof get(row, formatField) === "object" ? (
                              get(row, formatField)
                                ?.sort((a, b) => a.Type - b.Type)
                                .map((element, index) => {
                                  if (index === get(row, formatField).length - 1) {
                                    return (
                                      <span key={`${index}-description`}>
                                        {element.Description}
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span key={`${index}-description`}>
                                        {`${element.Description}, `}
                                      </span>
                                    );
                                  }
                                })
                            ) : (
                              get(row, formatField)
                            )
                          ) : formKey === "editForm" && (!row.IsActive || row.HasAnswers) ? (
                            null
                          ) : (
                            formatField(row)
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.showCompanyName && 
                    row.CoachAvailableCompanies?.map(
                      company => <span style={{ display: "block", marginLeft: "16px" }} key={company}>{company}</span>
                    )
                  }

                  {this.props.customRow && this.props.customRow(row, ri)}
                </React.Fragment>
              ) : (
                <React.Fragment key={propOr(ri, rowKey, row)}>
                  <tr className={ri % 2 === 0 ? "even" : "odd"}>
                    {columns.map(({ RowCell, format, field, key, formKey }) => {
                      if (RowCell) {
                        return (
                          <RowCell
                            key={`${rowKey ? row[rowKey] : ri}-${field || key}`}
                            {...row}
                          />
                        );
                      }
                      const formatField = format || field;
                      return (
                        <td
                          key={`${rowKey ? row[rowKey] : ri}-${field || key}`}
                          style={
                            (formKey === "formName" || formKey === "formDescription") &&
                              !row.IsActive
                              ? { textDecoration: "line-through" }
                              : { textDecoration: "none" }
                          }
                        >
                          {typeof formatField === "string" ? (
                            typeof get(row, formatField) === "object" ? (
                              get(row, formatField)
                                ?.sort((a, b) => a.Type - b.Type)
                                .map((element, index) => {
                                  if (index === get(row, formatField).length - 1) {
                                    return (
                                      <span key={`${index}-description`}>
                                        {element.Description}
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span key={`${index}-description`}>
                                        {`${element.Description}, `}
                                      </span>
                                    );
                                  }
                                })
                            ) : (
                              get(row, formatField)
                            )
                          ) : formKey === "editForm" && (!row.IsActive || row.HasAnswers) ? (
                            null
                          ) : (
                            formatField(row)
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {this.props.customRow && this.props.customRow(row, ri)}
                </React.Fragment>
              );
            })}
            {/* {isComplaincePrp} */}
          </tbody>

        </table>
        {isLoading && <Waiting size="50px" />}
      </div >
    );
  }
}

Table.propTypes = {
  query: PropTypes.object.isRequired,
  queryId: PropTypes.string,
  rowKey: PropTypes.string,
  disabledsort: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      title: PropTypes.string,
      field: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      sorted: PropTypes.oneOf(["asc", "desc"]),
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
          radioGroupName: PropTypes.string //If blank then it is multi-select checkboxes
        })
      ),
      search: PropTypes.object,
      subFilter: PropTypes.object,
      renderFilter: PropTypes.func
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHeaderClick: PropTypes.func,
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  onRemoveSearch: PropTypes.func,
  onAddSearch: PropTypes.func,
  setFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  onInvertFilters: PropTypes.func,
  isLoading: PropTypes.bool,
  scrollable: PropTypes.bool,
  customRow: PropTypes.func,
  isComplianceContacts: PropTypes.bool,
};

Table.defaultProps = {
  rowKey: "id"
};

export default connect(state => ({
  query: state.getIn(["query"])
}))(Table);
