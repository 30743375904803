import C from "action-types";
import { receiveType } from 'actions/fetch'
import { combineReducers } from 'redux'
import map from 'lodash/map'

const RECEIVE_PUT_SEARCH = receiveType(C.SEARCH.PUT)

const ids = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PUT_SEARCH:
      return {
        ...state,
        [action.table]: map(action.response.entities[action.entityKey || action.table], p => p.id)
      }
    default:
      return state;
  }
}

const metaData = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PUT_SEARCH:
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          count: action.response.searchData.count
        }
      }
    case C.SEARCH.SEARCH_TABLE_QUERY_UPDATE:
      return {
        ...state,
        [action.query.table]: {
          ...state[action.query.table],
          ...action.query
        }
      }
    default:
      return state
  }
}

const search = combineReducers({
  ids,
  metaData
})

export default search
