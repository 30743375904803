import moment from "moment";
import * as R from "ramda";
import { isValidDate } from "utils/form-validation";
import { formatAddress } from "utils/misc";

export const isEligibleForRepap = machines => {
  return R.all(purchased5YearsAgo)(machines);
};

const purchased5YearsAgo = ({ datePurchased }) => {
  const eligbleDate = moment(datePurchased);
  if (!isValidDate(datePurchased)) return false;
  return eligbleDate.isBefore(moment(new Date()).subtract(5, "years"));
};

export const getRecallWorkflowStatus = ({ philips_recall_response }) => {
  if (
    philips_recall_response?.hasEquipment === null ||
    !philips_recall_response?.hasAcknowledged
  )
    return "not_started";
  else if (philips_recall_response?.hasAcknowledged === true) {
    if (philips_recall_response.hasEquipment === false) return "completed";
    else if (
      philips_recall_response.hasEquipment === true &&
      philips_recall_response.acceptance !== null
    )
      return "completed";
    else if (
      philips_recall_response.acceptance == null ||
      philips_recall_response.acknowledgedRisk === null ||
      philips_recall_response.philipsDataApproval === null
    )
      return "started";
  }
  return "completed";
};

export const getPhilipsWorkflowRequired = patientInfo => {
  if (!patientInfo?.opt_in_phillips_recall ?? false) return;
  const philipsStatus = convertLegacyPhilipsStatus(patientInfo);
  if (
    patientInfo.deactivationReasons?.some(
      ({ text }) => text === "Declined Service Due To Recall"
    )
  ) {
    return "Warning. This patient has declined service. Before contining, you must correct this patients Philips recall status";
  } else if (
    !philipsStatus ||
    ["Not Started", "Reset", "Pending"].includes(philipsStatus)
  ) {
    return "Warning. This patient has not completed the Philips Recall Workflow. Before contining, you must complete the recall workflow";
  }
};
export const convertLegacyPhilipsStatus = patientInfo => {
  const philipsRecallStatus = patientInfo?.philips_recall?.recallStatus
    ? patientInfo?.philips_recall?.recallStatus
    : patientInfo?.recallStatus;
  switch (philipsRecallStatus) {
    case "Complete - No Machine":
      return "Not Affected";
    case "Complete - Decline Service":
      return "Completed";
    case "Complete - Accept Risk":
      return "Completed";
    case "Reset":
      return "";
    case "Pushed":
      return "Pending";
    case "Not Started":
      return "Not Started";
    default:
      if (philipsRecallStatus?.includes("Not Affected")) return "Not Affected";
      else if (philipsRecallStatus?.includes("Complete")) return "Completed";
      else return philipsRecallStatus;
  }
};

export const getPhilipsRegistrationQuestionsValues = (
  patientInfo,
  philips_recall
) => {
  console.log(philips_recall.questionnaireResponses);
  const questionResponses =
    philips_recall.questionnaireResponses?.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.questionID]: cur.answer
      }),
      {}
    ) ?? {};
  return {
    first_name: R.propOr(
      patientInfo.first_name,
      "questionnaireResponses.first_name",
      questionResponses
    ),
    last_name: R.propOr(
      patientInfo.last_name,
      "questionnaireResponses.last_name",
      questionResponses
    ),
    home_address: R.propOr(
      formatAddress(patientInfo.address),
      "questionnaireResponses.home_address",
      questionResponses
    ),
    phone_type: R.propOr(
      "",
      "questionnaireResponses.phone_type",
      questionResponses
    ),
    email: R.propOr(
      patientInfo.email,
      "questionnaireResponses.email",
      questionResponses
    ),
    additional_info: R.propOr(
      "",
      "questionnaireResponses.additional_info",
      questionResponses
    )
  };
};

export const getPhilipsRegistrationQuestionList = values => {
  const questionnaireResponsesList = Object.keys(
    values.questionnaireResponses
  ).map(key => {
    return {
      questionID: `questionnaireResponses.${key}`,
      answer: values.questionnaireResponses[key],
      ...questionnaireResponsesMetaData[key]
    };
  });
  return questionnaireResponsesList;
};

const contactOptions = [
  { text: "Text Message", value: "Text Message" },
  { text: "Email", value: "Email" },
  { text: "Phone Call", value: "Phone Call" },
  {
    text: "Receive no additional information",
    value: "Receive no additional information"
  }
];
const phoneOptions = [
  { text: "Cell", value: "Cell" },
  { text: "Home", value: "Home" }
];
const questionnaireResponsesMetaData = {
  first_name: {
    questionAsAsked: "What is your first name?",
    questionType: "Text Input",
    questionOptionsJSON: ""
  },
  last_name: {
    questionAsAsked: "What is your last name?",
    questionType: "Text Input",
    questionOptionsJSON: ""
  },
  home_address: {
    questionAsAsked: "What is your address?",
    questionType: "Text Input",
    questionOptionsJSON: ""
  },
  phone_number: {
    questionAsAsked: "What is your phone number?",
    questionType: "Text Input",
    questionOptionsJSON: ""
  },
  phone_type: {
    questionAsAsked: "What type of phone number was provided?",
    questionType: "Select Element",
    questionID: "questionnaireResponses.phone_type",
    questionOptionsJSON: JSON.stringify(phoneOptions)
  },
  email: {
    questionAsAsked: "What is your email address?",
    questionType: "Text Input",
    questionID: "questionnaireResponses.email",
    questionOptionsJSON: ""
  },
  additional_info: {
    questionAsAsked:
      "If you would like to receive additional information please select how you would like to receive the information.",
    questionType: "Select Element",
    questionOptionsJSON: JSON.stringify(contactOptions)
  }
};
