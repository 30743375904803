import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";
import { autoDialerProcessNext } from "actions/auto-dialer";

const AutoDialerStatus = ({
  wrapupTime,
  patientIds,
  activePageIndex,
  autoDialerStatus,
  autoDialerProcessNext,
  currentCompany
}) => {
  return (
    <div className="autodialer-menu">
      {wrapupTime > 0 && activePageIndex < patientIds.length - 1 && (
        <i title="Next" onClick={autoDialerProcessNext}>
          <IoArrowRightA />
        </i>
      )}
      <span className="autodialer-status">{autoDialerStatus}</span>
      <div className="current-company">Current Company: {currentCompany}</div>
    </div>
  );
};

AutoDialerStatus.propTypes = {
  wrapupTime: PropTypes.number,
  patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  autoDialerStatus: PropTypes.string,
  currentCompany: PropTypes.string,
  activePageIndex: PropTypes.number,
  autoDialerProcessNext: PropTypes.func.isRequired,
  wrapupTimeLimit: PropTypes.number
};

export default connect(
  state => ({
    autoDialerStatus: selectors.getAutoDialerStatus(state),
    patientIds: selectors.getAutoDialerPatientIds(state),
    activePageIndex: selectors.getAutoDialerCurrentPageIndex(state),
    wrapupTime: selectors.getAutoDialerWrapupTime(state),
    currentCompany: selectors.getCurrentCompany(state)
  }),
  {
    autoDialerProcessNext
  }
)(AutoDialerStatus);
