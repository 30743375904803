import React from "react";
import { PureModal } from "components/ui";
import RescheduleAppintmentModal from './index.jsx'
import PropTypes from "prop-types";

export default function CardRescheduleAppointmentButton ({ chat_type, chat_id, onSuccess, slot_id }) {

    return (
        <div>
            <PureModal
                maxWidth="sm"
                fullWidth
                renderTrigger={({ openModal }) => (
                    <button
                        className="appointment-action-button reschedule-button"
                        style={{ marginLeft: 10 }}
                        onClick={() => {openModal()}}
                    >
                        Reschedule
                    </button>
                    )
                }
                renderContent={({ closeModal }) => (
                    <RescheduleAppintmentModal
                        chat_id={ chat_id }
                        chat_type={ chat_type }
                        onSuccess={ onSuccess }
                        closeModal={ closeModal }
                        slotId={slot_id}
                    />
                )}
            />
        </div>
    )
}

CardRescheduleAppointmentButton.propTypes = {
    chat_type: PropTypes.string, 
    chat_id: PropTypes.string, 
    onSuccess: PropTypes.func, 
    slot_id: PropTypes.string
}