import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as R from "ramda";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import Header from "./header";
import { FULFILLMENT } from "utils/enum";

const SendToFulfillment = ({
  showSendToFulfillment,
  acceptable_fulfillment,
  requestFulfillmentUpdate
}) =>
  !showSendToFulfillment ? null : (
    <div className="send-to-fulfillment">
      {acceptable_fulfillment.length === 0 ? null : (
        <>
          <Header>Send to Fulfillment</Header>
          <div>
            {R.innerJoin(
              (f, value) => f.value === value,
              FULFILLMENT,
              acceptable_fulfillment
            ).map(({ text, value }) => (
              <button
                key={value}
                onClick={() => requestFulfillmentUpdate(value)}
              >
                {text}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );

export default connect((state, props) => {
  const { data: { acceptable_fulfillment = [] } = {} } =
    selectors.getOrderCompanyInfo(state, props);
  return {
    acceptable_fulfillment,
    showSendToFulfillment: selectors.getShowSendToFulfillment(state, props)
  };
}, mapDispatchToProps)(SendToFulfillment);

SendToFulfillment.propTypes = {
  orderId: PropTypes.string.isRequired,
  showSendToFulfillment: PropTypes.bool,
  acceptable_fulfillment: PropTypes.arrayOf(PropTypes.string),
  requestFulfillmentUpdate: PropTypes.func.isRequired
};
