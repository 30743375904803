import React from 'react'
import PropTypes from "prop-types";
import { Waiting } from "components/ui";
import ActivationReportGraph from './activation-report-graph'
import ActivationReportTable from './activation-report-table'
import { MonthlyReportPieChart } from './monthly-report-pie-chart'

const lineColors = ["#2b4059", "#FA8072", "#F00", "#47a147", "#2DC8B8", "#494999", "#5B1C1C", "#CE4435", "#9D44B5", "#06A59B", "salmon", "magenta", "blue", "red", "green", "teal", "yellow", "tomato"]

const ActivationReportView = ({ isFetchingActivationReport, activationReport }) => {
  const groupByReason = activationReport.reasons.reduce((acc, curr, i) => {
    if (!acc[curr] && curr !== "name") {
        const name = curr
        acc[curr] = { name, value: 0, color: lineColors[i] }
        acc[curr].value = activationReport.monthByMonthData.reduce((acc, curr) => {
          return curr[name] ? acc + curr[name] : acc + 0
        }, 0)
        acc[curr].checked = true
    }
    return acc
  }, {})
  const groupByActivation = activationReport.monthByMonthData.reduce((acc, curr)=>{
    acc.totalActivation = { value: (acc.totalActivation.value || 0) + curr.totalActivation, name: "Total Activation", color: '#42c8b8'}
    acc.totalDeactivation = { value: (acc.totalDeactivation.value || 0) + curr.totalDeactivation, name: "Total Deactivation", color: '#2a4059'}
    return acc
  }, {totalActivation: {}, totalDeactivation: {}})
  return (
    <div className='row company-monthly-report_container' style={{ margin: 0 }}>
      <div className='large-12'>
        {!isFetchingActivationReport
          ? <>
              <div>
                <ActivationReportGraph activationReport={activationReport} groupByReason={groupByReason} />
                <div className='activation-report_pie-chart-container'>
                  <MonthlyReportPieChart data={Object.values(groupByActivation)} title='Activation and Deactivation' />
                  <MonthlyReportPieChart data={Object.values(groupByReason)} title='Deactivation By Reason' />
                </div>
                <ActivationReportTable activationReport={activationReport} />
              </div>
            </>
            : <Waiting reason="crunching the latest numbers..." />
        }
      </div>
    </div>
  )
}

export default ActivationReportView

ActivationReportView.propTypes = {
  activationReport: PropTypes.shape({
    monthByMonthData: PropTypes.arrayOf(PropTypes.object),
    reasons: PropTypes.arrayOf(PropTypes.string)
  }),
  isFetchingActivationReport: PropTypes.bool
};
