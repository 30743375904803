import { combineReducers } from "redux";
import flow from "lodash/fp/flow";
import { staticReducer } from "utils/reducer";
import { createSelector } from "reselect";
import { eq, or, evaluate, variable, constant } from "utils/logic";
import * as R from "ramda";

const statuses = staticReducer([
  {
    value: "all",
    text: "All"
  },
  {
    value: "float-report",
    text: "Float"
  },
  {
    value: "unassigned",
    text: "Unassigned Patients"
  },
  {
    value: "delayed",
    text: "Delayed"
  }
]);

const userRoleEq = flow(constant, eq(variable("userRole")));

const viewSleepCoach = staticReducer(
  or(userRoleEq("Administrator"), userRoleEq("SleepCoach"))
);

const canBatchUpdate = staticReducer(userRoleEq("Administrator"));

const patientStatusRoot = combineReducers({
  statuses,
  canBatchUpdate,
  viewSleepCoach
});

export default patientStatusRoot;

const getPatientStatusState = path =>
  createSelector([state => state.getIn(["patientStatus"])], R.propOr({}, path));

const getPatientStatusText = createSelector(
  [getPatientStatusState("statuses"), (_, patientStatus) => patientStatus],
  (statuses, patientStatus) =>
    R.propOr("", "text")(R.find(x => x.value === patientStatus, statuses))
);

const getCanViewSleepCoach = createSelector(
  [getPatientStatusState("viewSleepCoach"), (_, userRole) => userRole],
  (viewSleepCoach, userRole) => evaluate(viewSleepCoach, { userRole })
);

const getCanBatchUpdate = createSelector(
  [getPatientStatusState("canBatchUpdate"), (_, userRole) => userRole],
  (canBatchUpdate, userRole) => evaluate(canBatchUpdate, { userRole })
);

export const selectors = {
  getPatientStatusText,
  getCanBatchUpdate,
  getCanViewSleepCoach
};
