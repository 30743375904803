import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IoMdCheckmark from "react-icons/lib/io/checkmark";
import IoMdClose from "react-icons/lib/io/close";

const InventoryTableRow = ({
  master_id,
  product_id,
  item_name,
  hcpcs,
  product_type,
  manufacturer,
  crosswalk_id,
  fulfillment_id,
  active,
  // manufacturer_id,
  uom
}) => (
  <tr>
    <td>
      <Link to={`/inventory/${master_id}`}>{item_name}</Link>
    </td>
    <td>{product_id}</td>
    <td>
      <Link to={`/${fulfillment_id}/fulfillment-inventory/${master_id}`}>{crosswalk_id ?? "NA"}</Link>
    </td>
    <td>{uom}</td>
    <td>{active ? <IoMdCheckmark /> : <IoMdClose />}</td>
    <td>{hcpcs}</td>
    <td>{product_type}</td>
    <td>{manufacturer}</td>
  </tr>
);
export default React.memo(InventoryTableRow);

InventoryTableRow.propTypes = {
  master_id: PropTypes.string.isRequired,
  product_id: PropTypes.string.isRequired,
  item_name: PropTypes.string.isRequired,
  hcpcs: PropTypes.string.isRequired,
  product_type: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  manufacturer_id: PropTypes.string.isRequired,
  crosswalk_id: PropTypes.string.isRequired,
  fulfillment_id: PropTypes.string.isRequired,
  uom: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
};
