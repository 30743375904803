import React, { useContext } from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/dates";
import MdComment from "react-icons/lib/md/comment";
import MdEdit from "react-icons/lib/md/edit";
import { PureModal, Checkbox, InlineWaiting } from "components/ui";
import TaskForm from "features/tasks/task-form";
import TaskComments from "./task-comments";
import MdPerson from "react-icons/lib/md/person";
import MdShoppingCart from "react-icons/lib/md/shopping-cart";
import { OrderProfileContext } from "components/order-profile/context";

function TaskItem({ task, orderId, patientId, updateTask, createTaskNote }) {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { accessible_users } = useContext(OrderProfileContext);
  return (
    <div className="task-item" key={task.task_id}>
      <div
        className={`task-item ${
          task.status === "Completed" ? "resolved" : "un-resolved"
        }`}
      >
        <div className="task-status">
          {task.type === "informational" ? (
            <span style={{ paddingRight: 28 }} />
          ) : (
            <span
              style={{ paddingRight: 10, fontSize: 14, cursor: "pointer" }}
              onClick={async () => {
                try {
                  setIsUpdating(true);
                  await updateTask({
                    ...task,
                    order_id: orderId,
                    status: task.status == "Completed" ? "Pending" : "Completed"
                  });
                } finally {
                  setIsUpdating(false);
                }
              }}
            >
              {isUpdating ? (
                <InlineWaiting />
              ) : (
                <Checkbox value={task.status == "Completed"} />
              )}
            </span>
          )}
          <span style={{ paddingRight: 10, fontSize: 14 }}>
            {task.order_id ? <MdShoppingCart /> : <MdPerson />}
          </span>
          <span>{task.action_text}</span>
        </div>
        {task.text && <div className="task-description">{task.text}</div>}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <PureModal
          renderTrigger={({ openModal }) => (
            <div style={{ paddingRight: 10 }} onClick={openModal}>
              <MdComment />
              <span className="number-step">{task.notes?.length}</span>
            </div>
          )}
        >
          <TaskComments
            task={task}
            createTaskNote={createTaskNote}
            patientId={patientId}
          />
        </PureModal>
        <div className="task-item-meta">
          <div>
            Owner: <span>{task.owner}</span>
          </div>
          <div>Assigned To: {task.assigned_to_name}</div>
          <div>Last Updated: {formatDate(task.last_updated_at)}</div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            alignItems: "center"
          }}
        >
          <span>Created: </span>
          <div className="task-item-meta">
            <div className="task-item-info">
              <div>{task.created_by_name}</div>
              <div>{formatDate(task.created_at)}</div>
            </div>
          </div>
        </div>
        <PureModal
          renderTrigger={({ openModal }) => (
            <div className="edit-task-icon">
              <i className="flat-icon-button ripple" onClick={openModal}>
                <MdEdit color="#555" />
              </i>
            </div>
          )}
          renderContent={({ closeModal }) => (
            <TaskForm
              accessible_users={accessible_users}
              handleSubmit={async values => {
                await updateTask({ ...values, orderId, patientId });
                closeModal();
              }}
              orderId={orderId}
              patientId={patientId}
              task={task}
            />
          )}
        />
      </div>
    </div>
  );
}

export default TaskItem;

TaskItem.propTypes = {
  task: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  orderId: PropTypes.string,
  updateTask: PropTypes.func.isRequired,
  createTaskNote: PropTypes.func.isRequired
};
