import React from "react";
import PropTypes from "prop-types";
import SelectInput from "components/forms/select-input";
import TextInput from "components/forms/text-input";
import { selectors } from "reducers";
import { connect } from "react-redux";
import MultiSelectDropdown from "components/forms/multi-select-dropdown";
import { updateInventoryForm } from "actions/inventory";
import { IoIosInformation } from "react-icons/lib/io";
import { Tooltip } from "components/ui";

const InventoryForm = ({
  inventoryItems,
  handleSubmit,
  onChange,
  form,
  editing,
  hcpcsOptions,
  manufacturerOptions,
  equipmentTypeOptions,
  updateInventoryForm,
  errors
}) => {
  const requiresChild = ["E0601", "E0470", "E0471"];
  const inventoryOptions = inventoryItems
    ?.filter(x => (form.parent ? !x.parent : x.parent))
    ?.map(x => ({
      product_type: x.product_type,
      text: x.name,
      value: x.id,
      key: x.value
    }));
  const selectedHcpc = hcpcsOptions.find(x => x.value == form.hcpcs);
  React.useEffect(() => {
    //product_type and product_style should be driven by hcpc code and not what user selects in this form.
    // This is unneccessary since backend should not even be using these 2 properties but added this as precaution
    updateInventoryForm({
      form: {
        product_type: selectedHcpc?.productType ?? "",
        product_style: selectedHcpc?.productStyle ?? ""
      }
    });
  }, [selectedHcpc]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SelectInput
          name="manufacturer_id"
          label="Manufacturer *"
          value={form.manufacturer_id}
          options={manufacturerOptions}
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="product_id"
          label="Product ID *"
          value={form.product_id}
          onChange={onChange}
          error={errors}
        />
        <SelectInput
          name="hcpcs"
          label="HCPCS *"
          value={form.hcpcs}
          onChange={e => {
            const selectedHCPC = hcpcsOptions.find(
              x => x.text === e?.target?.value
            );

            const tbrHCPCS = {
              target: { name: "hcpcs", value: e?.target?.value }
            };
            onChange(tbrHCPCS);
            if (selectedHCPC.parent !== form.parent) {
              const tbrParentBool = {
                target: { name: "parent", value: selectedHCPC?.parent }
              };
              onChange(tbrParentBool);
              const tbrParents = { target: { name: "parents", value: [] } };
              onChange(tbrParents);
              const tbrChildren = { target: { name: "children", value: [] } };
              onChange(tbrChildren);
            }
          }}
          options={hcpcsOptions}
          error={errors}
        />
        <SelectInput
          name="product_type"
          label={
            <span>
              Product Type{" "}
              <Tooltip
                id={`hcpc_product_style`}
                className="helpful-link-tooltip"
                effect="solid"
                message="This value is based on the product type of the HCPC selected."
              >
                <IoIosInformation size="1rem" />
              </Tooltip>
            </span>
          }
          value={selectedHcpc?.productType}
          disabled
          defaultOption=""
          options={equipmentTypeOptions}
        />
        <SelectInput
          name="product_style"
          label={
            <span>
              Product Style{" "}
              <Tooltip
                id={`hcpc_product_style`}
                className="helpful-link-tooltip"
                effect="solid"
                message="This value is based on the product style of the HCPC selected."
              >
                <IoIosInformation size="1rem" />
              </Tooltip>
            </span>
          }
          value={selectedHcpc?.productStyle}
          disabled
          defaultOption=""
          options={[
            { value: "Full Face", text: "Full Face" },
            { value: "Heated Tubing", text: "Heated Tubing" },
            { value: "Machine", text: "Machine" },
            { value: "Nasal Pillow", text: "Nasal Pillow" },
            { value: "Nasal Cushion", text: "Nasal Cushion" },
            { value: "Standard Tubing", text: "Standard Tubing" },
            { value: "Other", text: "Other" }
          ]}
          error={errors}
        />
        <TextInput
          name="name"
          label="Name *"
          value={form.name}
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="description"
          label="Description *"
          value={form.description}
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="avgReimbursment"
          label="Average Reimbursement *"
          type="number"
          value={form.avgReimbursment ? form.avgReimbursment.toString() : ""}
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="quantity"
          label="Quantity *"
          type="number"
          value={form.quantity ? form.quantity.toString() : ""}
          onChange={onChange}
          error={errors}
        />
        <SelectInput
          blankFirstOption={false}
          name="productActive"
          label={editing ? "Product Active *" : "All Companies Status *"}
          value={form.productActive.toString()}
          defaultOption={form.productActive.toString()}
          options={[
            { value: "true", text: "Active" },
            { value: "false", text: "Inactive" }
          ]}
          onChange={onChange}
          error={errors}
        />
        {editing || form.hcpcs ? (
          form.parent ? (
            <span style={{ fontSize: "0.875rem" }}>
              {requiresChild.includes(form.hcpcs)
                ? "Child Inventory Item(s) *"
                : "Child Inventory Item(s)"}
              <MultiSelectDropdown
                searchable={true}
                blankFirstOption={true}
                name="children"
                values={form.children}
                defaultOption={form.children}
                options={inventoryOptions}
                onChange={value => {
                  const tbr = { target: { name: "children", value } };
                  onChange(tbr);
                }}
                onClear={() => {
                  const tbr = { target: { name: "children", value: [] } };
                  onChange(tbr);
                }}
                error={errors}
              />
            </span>
          ) : (
            <span style={{ fontSize: "0.875rem" }}>
              {`Parent Inventory Item(s)`}
              <MultiSelectDropdown
                blankFirstOption={true}
                searchable={true}
                name="parents"
                values={form.parents}
                defaultOption={form.parents}
                options={inventoryOptions}
                onChange={value => {
                  const tbr = { target: { name: "parents", value } };
                  onChange(tbr);
                }}
                onClear={() => {
                  const tbr = { target: { name: "parents", value: [] } };
                  onChange(tbr);
                }}
                error={errors}
              />
            </span>
          )
        ) : (
          ""
        )}
        {selectedHcpc?.productType.includes("Mask") && (
          <div style={{ marginTop: "1rem" }}>
            <SelectInput
              name="alternate_item_id"
              label="Alternate Item"
              value={form.alternate_item_id}
              defaultOption=""
              options={inventoryItems
                .filter(x => {
                  if (selectedHcpc?.productType == "Mask") {
                    return x.product_type === "Mask with Headgear";
                  } else if (
                    selectedHcpc?.productType == "Mask with Headgear"
                  ) {
                    return x.product_type === "Mask";
                  } else return false;
                })
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(x => ({
                  text: x.name,
                  value: x.id,
                  key: x.value
                }))}
              onChange={onChange}
              error={errors}
            />
          </div>
        )}
        <button type="submit">{editing ? "Update Item" : "Add item"}</button>
      </form>
    </div>
  );
};

export default connect(
  state => ({
    inventoryItems: selectors.getInventoryItems(state)
  }),
  {
    updateInventoryForm
  }
)(InventoryForm);

InventoryForm.propTypes = {
  inventoryItems: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  form: PropTypes.shape({
    productActive: PropTypes.bool,
    avgReimbursment: PropTypes.number,
    quantity: PropTypes.number,
    manufacturer_id: PropTypes.string,
    product_type: PropTypes.string,
    product_style: PropTypes.string,
    product_id: PropTypes.string,
    hcpcs: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    parents: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.arrayOf(PropTypes.string),
    parent: PropTypes.bool,
    alternate_item_id: PropTypes.string
  }).isRequired,
  editing: PropTypes.bool,
  manufacturers: PropTypes.array.isRequired,
  equipmentTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  hcpcsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.object.isRequired,
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object),
  updateInventoryForm: PropTypes.func.isRequired
};
