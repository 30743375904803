import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PhysicianBreakdown from "./physician-breakdown";
import InsuranceBreakdown from "./insurance-breakdown";

const PhysicianInsuranceTable = ({ insuranceReport }) => {
  const [selectedPhysician, setSelectedPhysician] = useState();
  useEffect(() => {
    setSelectedPhysician();
  }, [insuranceReport]);
  return (
    <div className="interactive-graph-container">
      {!selectedPhysician ? (
        <PhysicianBreakdown
          data={insuranceReport}
          setSelectedPhysician={setSelectedPhysician}
        />
      ) : (
        <InsuranceBreakdown
          data={selectedPhysician.Items}
          selectedPhysician={selectedPhysician}
          setSelectedPhysician={setSelectedPhysician}
        />
      )}
    </div>
  );
};

PhysicianInsuranceTable.propTypes = {
  insuranceReport: PropTypes.array.isRequired
};

export default PhysicianInsuranceTable;
