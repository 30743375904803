import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort } from "hooks";
import { Link } from "react-router-dom";
import TableSortableHeader from "components/ui/table-sortable-header";
import { FaGlobe } from "react-icons/lib/fa";

const TeamCoachOrdersTable = ({ data }) => {
  const { ascending, sortBy, getSortedArray, toggleSortDirection, setSortBy } =
    useSort({ by: "Coach", ascending: false });
  return (
    <table>
      <TableSortableHeader
        columns={[
          { key: "Coach", text: "Name" },
          { key: "Masks_Sold", text: "Masks" },
          { key: "Seals_Sold", text: "Seals" },
          { key: "Masks_Ratio", text: "Masks Ratio" },
          { key: "Seals_Ratio", text: "Seals Ratio" },
          { key: "TotalOrders", text: "Total Orders" }
        ]}
        sortBy={sortBy}
        ascending={ascending}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      <tbody>
        {getSortedArray(data).map(d => (
          <tr key={d.COACH_GUID}>
            <td>
              <Link to={`/users/${d.COACH_GUID}`}>
                {d.Coach} {d.contracted_employee ? <FaGlobe /> : ""}
              </Link>
            </td>
            <td>{d.Masks_Sold}</td>
            <td>{d.Seals_Sold}</td>
            <td>{d.Masks_Ratio}</td>
            <td>{d.Seals_Ratio}</td>
            <td>{d.TotalOrders}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TeamCoachOrdersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  data: selectors.getTeamCoachInsights(state)
}))(TeamCoachOrdersTable);
