import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { InlineWaiting } from "components/ui";
import FormikField from "components/forms/formik-field";

const ProductFAQsForm = ({ handleSubmit, handleCancel, initialValues }) => (
  <div style={{ padding: 10 }}>
    <Formik
      onSubmit={async values => {
        await handleSubmit(values);
      }}
      initialValues={initialValues}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormikField
            label="Quesiton"
            name="question"
            component="textarea"
            required
          />
          <FormikField
            label="Answer"
            name="answer"
            component="textarea"
            required
          />
          <FormikField
            label="Active"
            name="active"
            type="checkbox"
            checked={values.active}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between"
            }}
          >
            <button onClick={handleCancel} type="button" className="warning">
              Cancel
            </button>
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <button type="submit">Save</button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

ProductFAQsForm.defaultProps = {
  initialValues: {
    question: "",
    answer: "",
    active: true
  }
};

ProductFAQsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  initialValues: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
    active: PropTypes.bool
  })
};

export default ProductFAQsForm;
