import React, { useContext } from "react";
import PropTypes from "prop-types";
import { colors } from "utils/styles";
import { Field } from "formik";
import { MdCheck } from "react-icons/lib/md";
import HasRolePermission from "components/shared/has-role-permission";
import FormikField from "components/forms/formik-field";
import { getEquipmentSupplyInfo } from "./options";
import RadioGroupField from "components/forms/radio-group-field";
import { PatientProfileContext } from "components/profile/context";

const OrderSummary = ({ values }) => {
  const { company_order_settings } = useContext(PatientProfileContext);

  const options = company_order_settings.supply_period_options.map(period => ({
    text: `${period} Day Supply`,
    value: String(period)
  }));

  return (
    <div className="order-summary">
      <h3>Order Summary</h3>
      <div style={{ display: "flex" }}>
        <span style={{ flex: 1 }}>
          <Field
            component={RadioGroupField}
            name="supply_period"
            label="How many days of supplies"
            value={values.supply_period}
            options={options}
          />
        </span>
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "OfficeAdministrator",
            "SleepCoach"
          ]}
        >
          <span>
            <FormikField
              label="High Priority Order"
              name="high_priority"
              type="checkbox"
              checked={values.high_priority}
            />
          </span>
        </HasRolePermission>
      </div>
      <div className="order-form-prompt order-form-warning">
        Let&#39;s review your order and verify that it&#39;s complete. You will
        receive a{" "}
        <span className="supply-requested">
          {values.supply_period + " day"}
        </span>{" "}
        supply of the following items:
      </div>
      <ul>
        {values.requested_equipment
          .map(getEquipmentSupplyInfo)
          .map(({ text, description, value }) => (
            <li key={value}>
              <div className="order-summary-checkmark-container">
                <MdCheck size="30px" color={colors.primaryColor} />
              </div>
              <div className="order-summary-item-description">
                <strong>{text}</strong>
                <div>{description}</div>
              </div>
            </li>
          ))}
      </ul>
      <div className="order-form-warning">
        I understand that this order request for supplies will be billed through
        insurance and is subject to copay and deductible as well as screening
        for eligibility of supplies. A current prescription, recent doctor visit
        and compliance download may be needed for order processing. A
        representative from our verification team will contact you if additional
        documentation is needed. Changes may occur to the supplies ordered in
        the verification process.{" "}
        {company_order_settings.last_day_to_order_message}
      </div>
    </div>
  );
};

export default OrderSummary;

OrderSummary.propTypes = {
  values: PropTypes.shape({
    supply_period: PropTypes.string,
    high_priority: PropTypes.bool,
    requested_equipment: PropTypes.arrayOf(PropTypes.string).isRequired,
    primary_insurance: PropTypes.object,
    secondary_insurance: PropTypes.object
  }).isRequired,
  patientInfo: PropTypes.object
};
