import { get, put, post } from "utils/api";
import { makeFetchCreator, makeFetchTuple } from "actions/fetch";
import keyBy from "lodash/keyBy";
import uniqueId from "lodash/uniqueId";
import { getExports } from "action-creators";
import * as R from "ramda";

// new filter actions
export const INIT = "INVENTORY_FILTER_INIT";
export const initFilters = filter => ({
  type: INIT,
  filter
});

// export const UPDATE_FILTERS = "INVENTORY_UPDATE_FILTERS";
// export const updateFilters = (queryId, query) => ({
//   type: UPDATE_FILTERS,
//   payload: { query },
//   meta: { queryId }
// });
//
// export const ADD_FILTER = "INVENTORY_ADD_FILTER";
// export const addFilter = (by, value) => ({
//   type: ADD_FILTER,
//   filter: { by, value }
// });
//
// export const REMOVE_FILTER = "INVENTORY_REMOVE_FILTER";
// export const removeFilter = (by, value) => ({
//   type: REMOVE_FILTER,
//   filter: { by, value }
// });

export const SET_FILTERS = "INVENTORY_SET_FILTERS";
export const setFilter = (by, value) => ({
  type: SET_FILTERS,
  filter: {
    by,
    value
  }
});
//
// export const CLEAR_FILTER = "INVENTORY_CLEAR_FILTERS";
// export const clearFilter = by => ({
//   type: CLEAR_FILTER,
//   filter: { by }
// });
//
// export const TOGGLE_SORT_DIRECTION = "INVENTORY_TOGGLE_SORT_DIRECTION";
// export const toggleSortDirection = sortBy => ({
//   type: TOGGLE_SORT_DIRECTION,
//   sortBy
// });

export const UPDATE_INVENTORY_FORM = "UPDATE_INVENTORY_FORM";
export const updateInventoryForm = ({ form }) => {
  return {
    type: UPDATE_INVENTORY_FORM,
    form
  };
};

export const SET_INVENTORY_FORM = "SET_INVENTORY_FORM";
export const setInventoryForm = ({ form }) => {
  return {
    type: SET_INVENTORY_FORM,
    form
  };
};

export const GET_INVENTORY = "GET_INVENTORY";
// set inventory parents from children for faster compatibility checks.
// see actions/__tests__/inventory
export const transformInventoryItems = obj => {
  let concatValues = (k, l, r) => (k == "parents" ? R.concat(l, r) : r);
  const getUpdates = item =>
    item.children.reduce((acc, id) => [...acc, { id, parents: [item.id] }], []);
  const updates = R.reduce(
    (acc, item) =>
      R.mergeDeepWithKey(
        concatValues,
        acc,
        R.indexBy(R.prop("id"), getUpdates(item))
      ),
    {}
  )(obj);
  const result = R.pipe(
    R.map(R.assoc("parents", [])),
    R.indexBy(R.prop("id")),
    R.mergeDeepWithKey(concatValues, updates)
  )(obj);
  return result;
};

export const [fetchInventory, invalidateInventory] = makeFetchTuple({
  url: `inventory_items`,
  baseType: GET_INVENTORY,
  method: get,
  transform: response => ({
    entities: {
      inventoryItems: transformInventoryItems(response.contents),
      manufacturers: keyBy(
        response.manufacturers.map(m => ({ ...m, GUID: m.key, name: m.value })),
        "key"
      )
    }
  }),
  mapActionToKeyIn: () => []
});

const POST_INVENTORY_ITEM = "POST_INVENTORY_ITEM";
const postInventory = makeFetchCreator({
  url: `inventory_items`,
  baseType: POST_INVENTORY_ITEM,
  method: post,
  mapActionToKeyIn: ({ inventoryId }) => [inventoryId, uniqueId()]
});

export const createInventoryItem = ({ payload }) => dispatch => {
  return dispatch(postInventory({ payload })).then(response => {
    dispatch(invalidateInventoryItem({ inventoryId: response.response.id }));
    dispatch(fetchInventoryItem({ inventoryId: response.response.id }));
    return response;
  });
};

const PUT_INVENTORY = "PUT_INVENTORY";
const putInventory = makeFetchCreator({
  url: ({ inventoryId }) => `inventory_items/${inventoryId}`,
  baseType: PUT_INVENTORY,
  method: put,
  mapActionToKeyIn: ({ inventoryId }) => [inventoryId, uniqueId()]
});

export const updateInventory = ({ payload, inventoryId }) => dispatch => {
  return dispatch(putInventory({ payload, inventoryId })).then(
    ({ inventoryId }) => {
      dispatch(invalidateInventoryItem({ inventoryId }));
      dispatch(fetchInventoryItem({ inventoryId }));
    }
  );
};

export const GET_INVENTORY_ITEM = "GET_INVENTORY_ITEM";
export const [fetchInventoryItem, invalidateInventoryItem] = makeFetchTuple({
  url: ({ inventoryId }) => `inventory_items/${inventoryId}`,
  baseType: GET_INVENTORY_ITEM,
  transform: response => ({
    entities: {
      inventoryItems: { [response.id]: response }
    }
  }),
  mapActionToKeyIn: ({ inventoryId }) => [inventoryId]
});

export const GET_INVENTORY_EXPORT = "GET_INVENTORY_EXPORT";
export const [fetchInventoryExport, invalidateInventoryExport] = makeFetchTuple(
  {
    url: `inventory_items/export`,
    baseType: GET_INVENTORY_EXPORT
  }
);

export const getInventoryExport = ({ userId }) => dispatch => {
  dispatch(invalidateInventoryExport());
  return dispatch(fetchInventoryExport()).then(() => {
    dispatch(getExports(userId));
  });
};

export const fetchInventoryItemAndSetForm = ({ inventoryId }) => (
  dispatch,
  getState
) => {
  dispatch(invalidateInventoryItem({ inventoryId }));
  dispatch(fetchInventoryItem({ inventoryId })).then(inventoryId => {
    const inventoryItem = getState()
      .getIn(["entities", "inventoryItems", inventoryId])
      .toJS();
    dispatch(setInventoryForm({ form: inventoryItem }));
  });
};
