import React from "react";
import PropTypes from "prop-types";
import { formatTimeFromTimeSlot, getGridRowSpan } from "./scheduler-helpers";
import { TimeSlotContext } from "./use-availabilityScheduler";
import MdPeopleOutline from "react-icons/lib/md/people-outline";
import { MdPlayArrow, MdVideocam, MdSms } from "react-icons/lib/md";

const getChatStatusClass = (appointmentStatus = "") => {
    if(appointmentStatus !== null) {
        return appointmentStatus.replace(" ", "-").toLowerCase();
    } else {
        return ""
    }
}

const getChatTypeIcon = (master_chat_type = "") => {
    if(master_chat_type == "Video Chat"){
        return <MdVideocam size="15px" className="chat-type-slot-icon"/>
    } else if(master_chat_type == "Product Demo"){
        return <MdPlayArrow size="15px" className="chat-type-slot-icon"/>
    } else if(master_chat_type == "Text Chat"){
        return <MdSms size="15px" className="chat-type-slot-icon"/>
    } else {
      return <span/>
    }
}

const ScheduledSlot = React.memo(({ slot }) => {
  const { editSlot, setEditSlot, setPendingTimeSlot } = React.useContext(
    TimeSlotContext
  );
  const [mouseDown, setMouseDown] = React.useState(false);
  const gridSpan = getGridRowSpan(slot.row1, slot.row2);

  return (
    <div
      key={slot.slot_id}
      className={`scheduled-slot active ${
        editSlot && editSlot.slot_id === slot.slot_id ? "selected" : ""
      } ${getChatStatusClass(slot.status)}`}
      style={{
        gridColumn: `${slot.col + 1} / span 1`,
        gridRow: `${slot.row1 + 1} / span ${gridSpan}`
      }}
      title={slot.master_chat_type != "0" ? slot.master_chat_type : "No chat type selected"}
      onMouseUp={() => {
        if (mouseDown) {
          setPendingTimeSlot(null);
          if (editSlot && editSlot.slot_id === slot.slot_id) setEditSlot(null);
          else {
            setEditSlot(slot);
          }
        }
        setMouseDown(false);
      }}
      onMouseDown={e => {
        e.stopPropagation();
        setMouseDown(true);
      }}
    >
      <div className="scheduled-slot-info">
        {gridSpan > 2 && (
          <div className="available-slots">
            <MdPeopleOutline size={14} />{" "}
            <span>
              {slot.slots_remaining > 0 ? slot.max_invitees - slot.slots_remaining : slot.max_invitees} / {slot.max_invitees}
            </span>
          </div>
        )}
        <div className="slot-time">
          {getChatTypeIcon(slot.master_chat_type)}
          <div>
            {formatTimeFromTimeSlot(slot.row1)}-
            {formatTimeFromTimeSlot(slot.row2)}
          </div>
        </div>
      </div>
    </div>
  );
});

ScheduledSlot.propTypes = {
  slot: PropTypes.shape({
    slot_id: PropTypes.string.isRequired,
    col: PropTypes.number.isRequired,
    master_chat_type: PropTypes.string.isRequired,
    row1: PropTypes.number.isRequired,
    row2: PropTypes.number.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    slots_remaining: PropTypes.number,
    max_invitees: PropTypes.number,
    status: PropTypes.string
  }).isRequired
};

export default ScheduledSlot;
