import React from 'react'
import PropTypes from 'prop-types'
import { InlineWaiting } from 'components/ui'

const Header = ({ showSpinner, children }) => (
  <div className='order-profile-header'>
    <h4>{children}</h4>
    {showSpinner && <InlineWaiting />}
  </div>
)

Header.propTypes = {
  showSpinner: PropTypes.bool,
  children: PropTypes.node
}

export default Header
