import { all } from "redux-saga/effects";
import rootEnumSaga from './enum'
import rootAuthenticationSaga from './authentication'
import rootOrderProfileSaga from './order-profile'
import rootOrderStatusSaga from './order-status'
import rootFulfillmentStatusSaga from './fulfillment-status'
import rootUsersSaga from './users'
import rootUserProfileSaga from './user-profile'
import rootCompaniesSaga from './companies'
import rootImportTrackingSaga from './import-tracking'
import rootExportsSaga from './exports'
import { saga as rootQuerySaga } from 'features/query'
import rootApiSaga from 'features/api-saga'
import rootGoogleApiSaga from 'features/google-api/sagas'
// import rootPatientProfileSaga from './patient-profile'
import rootPhoneSaga from './phone'
// import rootVoicemailRecurringSaga from './voicemailsRecurring'
import rootVoicemailSaga from "./voicemails"
import rootPhysicianSearchSaga from "./physician-search"
import rootCompanySaga from "./company"
import rootOrderFormQuestionsIVRSaga from "./order-form-questions-ivr"
import rootIVRStatesSaga from "./ivr-states"
import rootAutoDialer from "./auto-dialer";
import rootSignalrSaga from "features/signalR/sagas"
import coachLiveUpdates from "./coach-live-updates"
import rootTwilioChatSaga from "./twilio-chat"
import rootPatientVerificationSaga from './patient-verification'
import rootBackorderImportSaga from "./backorder-import"
import rootPatientImportSaga from "./patient-import"
import rootInsuranceImportSaga from "./insurance-import"
import rootCompanyContactLogicSaga from "./company-contact-logic"
import rootContactSaga from "./contacts"
import rootAppVersionSaga from "./app-version"
export default function* rootSaga() {
  yield all([
    rootEnumSaga(),
    rootAuthenticationSaga(),
    rootOrderProfileSaga(),
    rootUsersSaga(),
    rootCompaniesSaga(),
    rootImportTrackingSaga(),
    rootOrderStatusSaga(),
    rootFulfillmentStatusSaga(),
    rootExportsSaga(),
    rootQuerySaga(),
    rootApiSaga(),
    // rootPatientProfileSaga(),
    // rootInsightsSaga(),
    rootPhoneSaga(),
    // rootVoicemailRecurringSaga(),
    rootVoicemailSaga(),
    rootPhysicianSearchSaga(),
    rootGoogleApiSaga(),
    rootCompanySaga(),
    rootOrderFormQuestionsIVRSaga(),
    rootIVRStatesSaga(),
    rootAutoDialer(),
    rootSignalrSaga(),
    coachLiveUpdates(),
    rootTwilioChatSaga(),
    rootUserProfileSaga(),
    rootPatientVerificationSaga(),
    rootBackorderImportSaga(),
    rootPatientImportSaga(),
    rootInsuranceImportSaga(),
    rootCompanyContactLogicSaga(),
    rootContactSaga(),
    rootAppVersionSaga()
  ])
}
if (module.hot) {
  module.hot.decline();
}
