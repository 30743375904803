import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort, useFetch } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import PureModal from "components/ui/modal/pure-modal";
import { CompanyContactModal } from "./company-contacts-modal";
import { put } from "utils/api";
import { path } from "ramda";
import { aLink } from "utils/styles";
import TeamDashboardContext from "./context";

const CompanyContacts = ({ data }) => {
  const [modalTotal, setModalTotal] = useState(0);
  const [companyBreakdown, setCompanyBreakdown] = useState([]);
  const { minimizeAll, requestedDates } = React.useContext(
    TeamDashboardContext
  );

  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "company_name", ascending: true });
  const [isMinimized, setMinimized] = useState(false);
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);

  const {
    response: companyBreakdownResponse,
    fetchData: getBreakdownCompanyCategory,
    isFetching: isFetchingCompany
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("company-breakdown-by-category", payload),
    transformError: path(["response", "body", "status"]),
  });

  const {
    response: companyBreakdownResponseType,
    fetchData: getCompanyBreakdownType,
    isFetching: isFetchingCompanyType
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("company-breakdown-by-type", payload),
    transformError: path(["response", "body", "status"]),
  });

  const {
    response: companyBreakdownResponseTotal,
    fetchData: getCompanyBreakdownTotal,
    isFetching: isFetchingCompanyTotal
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("company-breakdown-total", payload),
    transformError: path(["response", "body", "status"]),
  });

  const getCompanyBreakdownByCategory = (category, company) => {
    const payload = {
      type_Category: category,
      companyName: company,
      startDate: requestedDates.start_date,
      endDate: requestedDates.end_date
    };
    getBreakdownCompanyCategory(payload);
  };
  const getCompanyBreakdownByType = (type, company) => {
    const payload = {
      type_Category: type,
      companyName: company,
      startDate: requestedDates.start_date,
      endDate: requestedDates.end_date
    };
    getCompanyBreakdownType(payload);
  };

  const getCompanyBreakdownByTotal = company => {
    const payload = {
      companyName: company,
      startDate: requestedDates.start_date,
      endDate: requestedDates.end_date
    };
    getCompanyBreakdownTotal(payload);
  };

  useEffect(() => {
    setCompanyBreakdown(companyBreakdownResponse)
  }, [companyBreakdownResponse]);

  useEffect(() => {
    setCompanyBreakdown(companyBreakdownResponseType);
  }, [companyBreakdownResponseType]);

  useEffect(() => {
    setCompanyBreakdown(companyBreakdownResponseTotal);
  }, [companyBreakdownResponseTotal]);

  useEffect(() => {
    setModalTotal(
      companyBreakdown.reduce((total, element) => {
        return total + element.calls;
      }, 0)
    );
  }, [companyBreakdown]);

  return (
    <div className="block contacts">
      <div className="block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Contacts
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <table>
            <TableSortableHeader
              columns={[
                { key: "company_name", text: "Company" },
                {
                  key: "phone.outgoing_call.successes",
                  text: "Outgoing Calls"
                },
                {
                  key: "phone.incoming_call.successes",
                  text: "Inbound Calls"
                },
                {
                  key: "phone.left_voicemail.successes",
                  text: "Voicemails"
                },
                { key: "email.success_total", text: "Emails" },
                { key: "sms.text_message.successes", text: "Texts" },
                {
                  key: "sms.push_notification.successes",
                  text: "Notifications"
                },
                { key: "exposed_contacts_success_total", text: "Total" }
              ]}
              sortBy={sortBy}
              ascending={ascending}
              setSortBy={setSortBy}
              toggleSortDirection={toggleSortDirection}
            />
            <tbody>
              {getSortedArray(data).map(d => (
                <tr key={d.company_name}>
                  <td>{d.company_name}</td>

                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <td
                        style={aLink}
                        onClick={() => {
                          getCompanyBreakdownByCategory(
                            "Outgoing Call",
                            d.company_name
                          );
                          openModal();
                        }}
                      >
                        {d.phone.outgoing_call.successes}
                      </td>
                    )}
                  >
                    <CompanyContactModal
                      modalTotal={modalTotal}
                      modalType="Outgoing Calls"
                      companyBreakdown={companyBreakdown}
                      isFetching={isFetchingCompany}
                    />
                  </PureModal>
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <td
                        style={aLink}
                        onClick={() => {
                          getCompanyBreakdownByCategory(
                            "Incoming Call",
                            d.company_name
                          );
                          openModal();
                        }}
                      >
                        {d.phone.incoming_call.successes}
                      </td>
                    )}
                  >
                    <CompanyContactModal
                      modalTotal={modalTotal}
                      modalType="Incoming Calls"
                      companyBreakdown={companyBreakdown}
                      isFetching={isFetchingCompany}
                    />
                  </PureModal>
                 
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <td
                        style={aLink}
                        onClick={() => {
                          getCompanyBreakdownByCategory(
                            "Left Voicemail",
                            d.company_name
                          );
                          openModal();
                        }}
                      >
                        {d.phone.left_voicemail.successes}
                      </td>
                    )}
                  >
                    <CompanyContactModal
                      modalTotal={modalTotal}
                      modalType="Voicemails"
                      companyBreakdown={companyBreakdown}
                      isFetching={isFetchingCompany}
                    />
                  </PureModal>
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <td
                        style={aLink}
                        onClick={() => {
                          getCompanyBreakdownByType("Email", d.company_name);
                          openModal();
                        }}
                      >
                        {d.email.success_total}
                      </td>
                    )}
                  >
                    <CompanyContactModal
                      modalTotal={modalTotal}
                      modalType="Emails"
                      companyBreakdown={companyBreakdown}
                      isFetching={isFetchingCompanyType}
                    />
                  </PureModal>
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <td
                        style={aLink}
                        onClick={() => {
                          getCompanyBreakdownByCategory(
                            "Text Message",
                            d.company_name
                          );
                          openModal();
                        }}
                      >
                        {d.sms.text_message.successes}
                      </td>
                    )}
                  >
                    <CompanyContactModal
                      modalTotal={modalTotal}
                      modalType="Text Messages"
                      companyBreakdown={companyBreakdown}
                      isFetching={isFetchingCompany}
                    />
                  </PureModal>
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <td
                        style={aLink}
                        onClick={() => {
                          getCompanyBreakdownByCategory(
                            "Push Notification",
                            d.company_name
                          );
                          openModal();
                        }}
                      >
                        {d.sms.push_notification.successes}
                      </td>
                    )}
                  >
                    <CompanyContactModal
                      modalTotal={modalTotal}
                      modalType="Push Notifications"
                      companyBreakdown={companyBreakdown}
                      isFetching={isFetchingCompany}
                    />
                  </PureModal>
                  <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByTotal(d.company_name);
                    openModal();
                  }}
                >
                  {d.exposed_contacts_success_total}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Total"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompanyTotal}
              />
            </PureModal>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

CompanyContacts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool
};

export default connect(state => ({
  data: selectors.getTeamContactsInsights(state)
}))(CompanyContacts);
