import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";

const FilterableProductSelect = ({
  selectedManufacturer,
  setSelectedManufacturer,
  selectedType,
  setSelectedType,
  selectedProduct,
  setSelectedProduct,
  inventoryItems,
}) => {
  const unique = (array, propertyName) => {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  };

  const manufacturerOptions = unique(
    inventoryItems.map((item) => ({
      text: item.manufacturer,
      value: item.manufacturer_id,
    })),
    "value"
  );

  const typeOptions = unique(
    inventoryItems.map((item) => ({
      text: item.product_type,
      value: item.product_type,
    })),
    "value"
  );

  const inventoryItemOptions = inventoryItems
    .filter(
      (item) =>
        (selectedType === "" || selectedType === item.product_type) &&
        (selectedManufacturer === "" ||
          selectedManufacturer === item.manufacturer_id)
    )
    .map((item) => ({
      text: item.name,
      value: item.id,
    }));

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: "10px",
      }}
    >
      <label>
        Manufacturer
        <select
          value={selectedManufacturer}
          onChange={({ target }) => setSelectedManufacturer(target.value)}
          style={{ margin: 0 }}
        >
          <option />
          {manufacturerOptions
            .sort((a, b) =>
              a.text.toLowerCase().localeCompare(b.text.toLowerCase())
            )
            .map(({ text, value }) => (
              <option value={value} key={value}>
                {text}
              </option>
            ))}
        </select>
      </label>
      <label>
        Type
        <select
          value={selectedType}
          onChange={({ target }) => setSelectedType(target.value)}
          style={{ margin: 0 }}
        >
          <option />
          {typeOptions
            .sort((a, b) =>
              a.text.toLowerCase().localeCompare(b.text.toLowerCase())
            )
            .map(({ text, value }) => (
              <option value={value} key={value}>
                {text}
              </option>
            ))}
        </select>
      </label>
      <label>
        Product
        <select
          value={selectedProduct}
          onChange={({ target }) => setSelectedProduct(target.value)}
          style={{ margin: 0 }}
        >
          <option />
          {inventoryItemOptions
            .sort((a, b) =>
              a.text.toLowerCase().localeCompare(b.text.toLowerCase())
            )
            .map(({ text, value }) => (
              <option value={value} key={value}>
                {text}
              </option>
            ))}
        </select>
      </label>
    </div>
  );
};

FilterableProductSelect.propTypes = {
  selectedProduct: PropTypes.string,
  setSelectedProduct: PropTypes.func.isRequired,
  inventoryItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventoryItemOptions: PropTypes.arrayOf(PropTypes.object),
  selectedManufacturer: PropTypes.string,
  setSelectedManufacturer: PropTypes.func,
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func
};

export default connect((state) => ({
  inventoryItems: selectors.getInventoryItems(state),
}))(FilterableProductSelect);
