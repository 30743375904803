import { call, put, all, take } from "redux-saga/effects";
import { Types } from "actions/physician-search";
import { get } from "utils/api";

export default function* rootExportsSaga() {
  yield all([watchPhysicianSearchRequest()]);
}

function* watchPhysicianSearchRequest() {
  while (true) {
    const action = yield take(Types.PHYSICIAN_API_REQUEST);
    yield call(fetchPhysicians, action);
  }
}

const buildApiQuery = formData => {
  const applyWildcard = value =>
    value.trim().length < 2 ? "" : `${value.trim()}*`;
  const { first_name = "", last_name = "", npi = "", address = {} } = formData;
  const { city = "", zip = "", state = "" } = address;
  const unusedParams =
    "&organization_name&address_purpose&enumeration_type&taxonomy_description&country_code&skip";

  return `?number=${npi}&first_name=${applyWildcard(
    first_name
  )}&last_name=${applyWildcard(last_name)}&city=${applyWildcard(
    city
  )}&postal_code=${zip}&state=${state}${unusedParams}&limit=50&version=2.1`;
};

const transformResponse = ({ result_count, results = [] }) => ({
  count: result_count,
  physicians: results.map(({ basic, addresses, number }) => ({
    first_name: basic.first_name,
    last_name: basic.last_name,
    addresses: addresses.filter(
      ({ address_purpose }) => address_purpose !== "MAILING"
    ),
    npi: number
  }))
});

export function* fetchPhysicians({ payload }) {
  try {
    const queryParamaters = buildApiQuery(payload);
    const result = yield get(`npi/search${queryParamaters}`);
    yield put({
      type: Types.PHYSICIAN_API_RESPONSE,
      payload: transformResponse(result)
    });
  } catch (error) {
    console.error(error);
    yield put({ type: Types.PHYSICIAN_API_ERROR, error });
  }
}
