import React from 'react';
import PropTypes from "prop-types";
import isNil from 'lodash/isNil';

const Select = ({ id, value, options, error, onChange,multiselect }) => {
    return (
        <label className={`patient-form-input patient-form-select-input${error ? ' patient-form-has-error' : ''}`}>
            <select
                id={id}
                value={!isNil(value) ? value : ''}
                onChange={e => !isNil(onChange) && onChange(e.target)}>
              {/* dont know what is going on this line, undefined mapMultiSelectOption */}
              {/* isNil(multiselect) ?  mapOptions(options): mapMultiSelectOption(options) */}
                { isNil(multiselect) ?  mapOptions(options): null }
            </select>
        </label>
    )
}

const mapOptions = options => Array.isArray(options) && options.map(({ value, text }) => (
    <option key={value} id = {text} value={value}>{text}</option>
))

export default Select;

Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  onChange: PropTypes.func,
  multiselect: PropTypes.bool
};
