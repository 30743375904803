import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";

import { useOutsideClick } from "hooks";
import MdArrowDropUp from "react-icons/lib/md/arrow-drop-up";
import MdArrowDropDown from "react-icons/lib/md/arrow-drop-down";
import MdTrash from "react-icons/lib/md/delete-sweep";
import SelectedIcon from "react-icons/lib/io/ios-checkmark-outline";
import MdRemove from "react-icons/lib/md/close";
import unique from "lodash/uniq";

const MultiSelectDropdown = ({
  options,
  values,
  searchable,
  onChange,
  onClear,
  placeHolder,
  renderValues,
  shiftSelect,
  onRemove,
  onBlur,
  dropdownStyle
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [headerTooltip, setHeaderTooltip] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filteredContract, setFilteredContract] = useState("All")
  //useState commented and preserved just in case. The setter function is currently not being used.
  //const [filteredActive, setFilteredActive] = useState("All")
  const filteredActive = "All"
  const userRole = useSelector(selectors.getUserRole)
  React.useEffect(() => {
    setHeaderTooltip(`${values.length} Selected`);
  }, [values]);
  function clearSelected(e) {
    e.stopPropagation();
    if (onClear) onClear();
    onChange([]);
  }
  function filterByText(item) {
    if (filterText.length > 0 && typeof item.text === "string") {
      return item.text.toLowerCase().includes(filterText.toLowerCase());
    }
    return true;
  }
  function toggleSelected(value) {
    if (!values.includes(value)) {
      onChange([...values, value]);
    } else {
      onChange(values.filter(v => v !== value));
    }
  }
  function handleSelectAll() {  
    var newvalues = filterOptions(options).map(e => e.GUID);
        onChange(unique([...values, ...newvalues]));
  }

  function handleContractOptionChange(changeEvent) {
    setFilteredContract(changeEvent.target.value)
  }

  //Function commented and preserved just in case. It is currently not being used.
  /*function handleActiveOptionChange(changeEvent) {
    setFilteredActive(changeEvent.target.value)
  }*/

  function filterOptions()
  {
    var fc = options
    switch(filteredContract)
    {
      case "All": 
      default: break;
      case "AllowContract": fc = (fc.filter(v => v.allow_contracted_viewing)); break;
      case "DontAllowContract": fc = (fc.filter(v => !v.allow_contracted_viewing)); break;
    }
    switch(filteredActive)
    {
      case "All": 
      default: break;
      case "AllowContract": fc = (fc.filter(v => v.active)); break;
      case "DontAllowContract": fc = (fc.filter(v => !v.active)); break;
    }
    return fc; 
  }
  const dropDownRef = useRef();
  useOutsideClick(dropDownRef, () => {
    if (isOpen) {
      setIsOpen(false);
      if (typeof onBlur === "function") onBlur();
    }
  });
  return (
    <div>
    <div className="multi-select-dropDown-container" ref={dropDownRef}>
      <div
        className="select-dropDown-header"
        onClick={() => {
          if (!isOpen) setIsOpen(true);
        }}
        >
        {isOpen && searchable ? (
          <input
          className="select-dropDown-header-title"
          type="search"
          onClick={e => e.stopPropagation()}
          value={filterText}
            onChange={({ target }) => setFilterText(target.value)}
            />
        ) : (
          <React.Fragment>
            <div className="select-dropDown-header-title" title={headerTooltip}>
              {renderValues ? (
                <InputTitle
                  selected={filterOptions(options).filter(({ value }) =>
                  values.includes(value)
                  )}
                  toggleSelected={toggleSelected}
                  placeHolder={placeHolder}
                  onRemove={onRemove}
                  />
                  ) : (
                    headerTooltip
                    )}
            </div>
          </React.Fragment>
        )}
        {
          values.length > 0 ? (
            <MdTrash
            color="red"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={clearSelected}
              />
          ) : isOpen ? 
            (<MdArrowDropUp />) : 
            (< MdArrowDropDown />)
        }
      </div>
      {isOpen && 
      (
        <div>
          <div style={{ display: 'grid', gridTemplateColumns: '65% 25% 10%', alignItems: 'center'}}>
            <div>
              <input type="radio" id="All" name="show" value="All" onChange={handleContractOptionChange} checked={filteredContract=="All"}/>
              <label>All</label>
              <input type="radio" id="allowContract" name="show" value="AllowContract" onChange={handleContractOptionChange} checked={filteredContract=="AllowContract"}/> 
              <label>Allow Contract</label>
              <input type="radio" id="dontAllowContract" name="show" value="DontAllowContract" onChange={handleContractOptionChange} checked={filteredContract=="DontAllowContract"}/>
              <label>{`Don't Allow Contract`}</label>
            </div>
            <div>
              <b>Total Seleted:</b> {values.length} / <b>Filtered:</b> {filterOptions(options).length} /<b>Total:</b> {options.length}                
            </div>
            <div>
              {userRole == "Administrator" && (              
                <button type="button"  title="This will add ALL companies in the current filter view.  It does not remove companies." onClick={handleSelectAll}>
                  Add All
                </button>
                )}         
            </div>
          </div>
        <ul className="select-dropDown-list" style={dropdownStyle} onClick={e => e.stopPropagation()}>
          {filterOptions(options).filter(filterByText).map(({ value, text, allow_contracted_viewing, assignedTeam, active }, index) => {
            const isSelected = values.includes(value);
            return (
              <li
              className={`select-dropDown-list-item ${
                isSelected ? "active" : ""
              }`}
              key={value}
              onClick={e => {
                if (shiftSelect && e.shiftKey) {
                  onChange([
                    ...filterOptions(options)
                        .filter(filterByText)
                        .slice(0, index + 1)
                        .map(({ value }) => value)
                      ]);
                  } else toggleSelected(value);
                }}
                >
                <div style={{ display: 'grid', gridTemplateColumns: '2%  48%  2% 18% 2% 18% 10%', alignItems: 'center'}}>
                  <div>
                      {isSelected && <SelectedIcon style={{ float: "left" }} />}
                  </div>
                  <div>
                      {text}
                  </div>
                  <div>
                    {allow_contracted_viewing && <SelectedIcon />}
                  </div>
                  <div>
                    Contracted Viewing
                  </div>
                  <div>
                    {active && <SelectedIcon />}
                  </div>
                  <div>
                    Active
                  </div>

                  <div>
                      {assignedTeam}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        </div>
)}
    </div>
</div>
  );
};

const InputTitle = ({ selected, toggleSelected, placeHolder, onRemove }) => {
  if (selected.length <= 0 && placeHolder) return placeHolder;
  else
    return selected.map((s) => (
      <span
        key={s.value}
        style={{
          display: "inline-flex",
          alignItems: "left",
          flexWrap: "wrap",
          width:'30%'
        }}
      >
        <i
          onClick={e => {
            e.stopPropagation();
            toggleSelected(s.value);
            if (typeof onRemove === "function") onRemove(s);
          }}
          className="flat-icon-button"
          style={{ fontSize: ".75rem", padding: "0 2px" }}
        >
          <MdRemove color="red" />
        </i>
        {s.text}
      </span>
    ));
};

export default MultiSelectDropdown;

MultiSelectDropdown.defaultProps = {
  placeHolder: "",
  values: [],
  searchable: true,
  renderValues: true,
  dropdownStyle: {}
};

MultiSelectDropdown.propTypes = {
  shiftSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  placeHolder: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.any
    })
  ).isRequired,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  renderValues: PropTypes.bool,
  onRemove: PropTypes.func,
  dropdownStyle: PropTypes.object
};
