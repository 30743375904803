import React from "react";
import PropTypes from "prop-types";
import { FieldArray, Field } from "redux-form";
import { Rows } from "components/ui";

const Fields = ({ fieldOptions }) => (
  <div>
    <Rows>
      {fieldOptions.map((x, index) => {
        const { field, fieldProps, text } = x;
        return (
          <div key={index} className="variable-field">
            <div>
              <div className="variable-field-label-text">{text}</div>
              <Field {...fieldProps} name={`${field}.value`} />
            </div>
          </div>
        );
      })}
    </Rows>
  </div>
);

Fields.propTypes = {
  fieldOptions: PropTypes.array
};

const FieldArraySet = ({ name, fieldOptions }) => (
  <div className="s3-ui-variable-form-field">
    <FieldArray name={name} component={Fields} fieldOptions={fieldOptions} />
  </div>
);

FieldArraySet.propTypes = {
  name: PropTypes.string.isRequired,
  fieldOptions: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      defaultValue: PropTypes.any,
      fieldProps: PropTypes.shape({
        component: PropTypes.func.isRequired,
        validate: PropTypes.func
      }).isRequired
    })
  ).isRequired
};

export default FieldArraySet;
