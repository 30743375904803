import { eventChannel } from "redux-saga";
import { take, call, put } from "redux-saga/effects";
import { Types } from "actions/phone";

const createDeviceChannel = device =>
  eventChannel(emit => {
    device.on("ready", () => emit({ type: Types.TWILIO_EVENT_READY }));
    device.on("offline", () => emit({ type: Types.TWILIO_EVENT_OFFLINE }));
    device.on("connect", connection =>
      emit({ type: Types.TWILIO_EVENT_CONNECT, connection })
    );
    device.on("disconnect", () =>
      emit({ type: Types.TWILIO_EVENT_DISCONNECT })
    );
    device.on("incoming", connection =>
      emit({ type: Types.TWILIO_EVENT_INCOMING, payload: connection })
    );
    device.on("cancel", () =>
      emit({ type: Types.TWILIO_EVENT_INCOMING_CANCELED })
    );
    device.on("error", error =>
      emit({ type: Types.TWILIO_EVENT_DEVICE_ERROR, error })
    );
    return () => {
      //TODO: unsubscribe: remove event listeners?
    };
  });

export function* listenDeviceEvents(device) {
  const channel = yield call(createDeviceChannel, device);
  while (true) {
    const action = yield take(channel);
    // console.info(action);
    yield put(action);
  }
}

const createConnectionChannel = connection =>
  eventChannel(emit => {
    connection.on("mute", isMuted =>
      emit({
        type: isMuted ? Types.TWILIO_EVENT_MUTE : Types.TWILIO_EVENT_UNMUTE
      })
    );
    connection.on("disconnect", () =>
      emit({ type: Types.TWILIO_EVENT_DISCONNECT })
    );
    connection.on("error", error =>
      emit({ type: Types.TWILIO_EVENT_CALL_ERROR, error })
    );
    connection.on("accept", () =>
      emit({ type: Types.TWILIO_EVENT_ACCEPT_INCOMING })
    );
    connection.on("reject", () =>
      emit({ type: Types.TWILIO_EVENT_REJECT_INCOMING })
    );
    connection.on("warning", warning => {
      console.warn(warning) /* eslint-disable-line */
      emit({ type: Types.TWILIO_EVENT_CALL_WARNING, payload: warning })
    });
    connection.on("warning-cleared", () =>
      emit({ type: Types.TWILIO_EVENT_CALL_WARNING_CLEARED })
    );
    return () => {
      // console.info("event channel unsubscribe");
      //TODO: do i need to unsubscribe from events?
    };
  });

export function* listenConnectionEvents(connection) {
  const channel = yield call(createConnectionChannel, connection);
  while (true) {
    const action = yield take(channel);
    // console.info(action);
    yield put(action);
  }
}
