import React, { useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Waiting, ListItem, PureModal } from "components/ui";
import HasRolePermission from "components/shared/has-role-permission";
import EditFulfillmentInventoryForm from "./edit-fulfillment-inventory";
import { FulfillmentInventoryContext } from "../fulfillment-inventory-provider";
import InventoryInfoTabs from "./inventory-info-tabs";

const FulfillmentInventoryProfile = () => {
  const {
    fulfillmentInventoryItem,
    getFulfillmentInventory,
    isFetchingInventory,
    updateFulfillmentInventory,
    isUpdatingInventory,
  } = useContext(FulfillmentInventoryContext);

  const history = useHistory();
  const { fulfillmentId, inventoryId } = useParams();
  useEffect(() => {
    getFulfillmentInventory({ fulfillmentId, inventoryId });
  }, []);

  return !isFetchingInventory ? (
    <div className="inventory-profile">
      <PageHeader title={`${fulfillmentInventoryItem.fulfillment_company ?? ""} Fulfillment Inventory`}>
        <HasRolePermission
          allowedRoles={[
            "Administrator"
          ]}
        >
          <PureModal
            renderTrigger={({ openModal }) => (
              <button className="button small" onClick={openModal}>
                Edit
              </button>
            )}
            renderContent={({ closeModal }) => (
              <EditFulfillmentInventoryForm
                fulfillmentInventoryItem={fulfillmentInventoryItem}
                setIsLoading={isFetchingInventory}
                updateFulfillmentInventory={updateFulfillmentInventory}
                getFulfillmentInventory={getFulfillmentInventory}
                closeModal={closeModal}
              />
            )}
          />
        </HasRolePermission>
        <button className="button small" onClick={history.goBack}>
          Go Back
        </button>
      </PageHeader>
      <div className="inventory-item-details">
        {(fulfillmentInventoryItem.photo_url_success ?? false) ? (
          <img
            className="inventory-photo"
            src={fulfillmentInventoryItem.photo_url}
            alt="Inventory Photo"
          ></img>
        ) : (
          <div className="inventory-photo photo-not-found">
            <div className="photo-not-found-text">No Photo</div>
          </div>
        )}

        <div className="inventory-item-list">
          <ListItem
            label="Manufacturer"
            value={fulfillmentInventoryItem.manufacturer}
          />
          <ListItem
            label="HCPCS"
            value={fulfillmentInventoryItem.hcpcs}
          />
          <ListItem
            label="Style"
            value={fulfillmentInventoryItem.product_style}
          />
          <ListItem
            label="Type"
            value={fulfillmentInventoryItem.product_type}
          />
          <ListItem
            label="Description"
            value={fulfillmentInventoryItem.description}
          />
          <ListItem
            label="Avg Reimbursement"
            value={`$${fulfillmentInventoryItem.avg_reimbursment}`}
          />
        </div>
        <div className="inventory-item-list">
          <ListItem
            label="Fulfillment Crosswalk Id"
            value={fulfillmentInventoryItem.fulfillment_id}
          />
          <ListItem
            label="Product Id"
            value={fulfillmentInventoryItem.product_id}
          />
          <ListItem
            label="Fulfillment Active"
            value={`${fulfillmentInventoryItem.fulfillment_active}`}
          />
          <ListItem
            label="Master Active"
            value={`${fulfillmentInventoryItem.master_active}`}
          />
          <ListItem
            label="Unit of Measure"
            value={`${fulfillmentInventoryItem.uom}`}
          />
        </div>
      </div>
      <InventoryInfoTabs
        fulfillmentId={fulfillmentId}
        inventoryId={inventoryId}
      />
    </div>
  ) : (
    <Waiting reason={isFetchingInventory ? `Fetching Fulfillment Inventory Profile` : isUpdatingInventory ? "Updating Fulfillment Inventory" : ""} />
  );
};

export default FulfillmentInventoryProfile;
