import { GET_RARTICLES, DELETE_RARTICLES, UPDATE_RARTICLES, UPDATE_STATUS_RARTICLES, UPDATE_SCHEDULED_TYPE, UPDATE_SCHEDULED_DAYS } from '../ActionTypes';

const initialState = {
    Articles: [],
    ScheduleType: "s",
    ScheduleDays: 0
}

const reducerRecurringArticles = (state = initialState, action) => {
    switch (action.type) {
        case GET_RARTICLES:
            return {
                ...state,
                Articles: action.payload.recurringArticles,
                ScheduleType: action.payload.schedule_type,
                ScheduleDays: action.payload.schedule_days
            };
        case UPDATE_SCHEDULED_TYPE:
            return {
                ...state,
                ScheduleType: action.payload
            };
        case UPDATE_SCHEDULED_DAYS:
            return {
                ...state,
                ScheduleDays: action.payload
            };
        case UPDATE_RARTICLES: {
            let newCollection = state.Articles.map(
                (content) => content.article_id === action.payload.article_id ? { ...content, active: action.payload.active, orderno: action.payload.orderno, link: action.payload.link, title: action.payload.title }
                    : content);
            const sortCollection = newCollection.sort((a, b) => {
                return parseInt(a.orderno) - parseInt(b.orderno);
            });
            return {
                ...state,
                Articles: sortCollection
            };
        }
        case DELETE_RARTICLES:
            return {
                ...state,
                Articles: state.Articles.map(
                    (content) => content.article_id === action.payload ? { ...content, active: false }
                        : content)
            };
        case UPDATE_STATUS_RARTICLES:
            return {
                ...state,
                Articles: state.Articles.map(
                    (content) => content.article_id === action.payload.article_id ? { ...content, active: action.payload.active }
                        : content)
            };
        default:
            return state;
    }
}

export default reducerRecurringArticles;
