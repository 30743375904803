import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import useSleepgladFormulary from "./use-sleepglad-formulary";
import MultiSelectDropdown from "components/forms/multi-select-dropdown";
import { InlineWaiting } from "components/ui";

const SleepgladBlacklistFormulary = ({ patientId, companyId }) => {
  const { values, setFieldValue } = useFormikContext();
  const {
    maskOptions,
    manufacturers,
    isFetchingFormulary
  } = useSleepgladFormulary(companyId, patientId);
  React.useEffect(() => {
    setFieldValue("blacklist_masks", []);
    setFieldValue("blacklist_manufacturers", []);
  }, [companyId, patientId]);
  return (
    <>
      <div
        style={{
          marginBottom: 20,
          width: "100%"
        }}
      >
        <label>Masks To Exclude</label>
        {isFetchingFormulary ? (
          <InlineWaiting />
        ) : (
          <MultiSelectDropdown
            searchable
            onChange={value => {
              setFieldValue("blacklist_masks", value);
            }}
            onClear={() => setFieldValue("blacklist_masks", [])}
            values={values.blacklist_masks}
            options={maskOptions}
          />
        )}
      </div>
      <div
        style={{
          marginBottom: 20,
          width: "100%"
        }}
      >
        <label>Manufacturers To Exclude</label>
        {isFetchingFormulary ? (
          <InlineWaiting />
        ) : (
          <MultiSelectDropdown
            searchable
            onChange={value => {
              setFieldValue("blacklist_manufacturers", value);
            }}
            onClear={() => setFieldValue("blacklist_manufacturers", [])}
            values={values.blacklist_manufacturers}
            options={manufacturers}
          />
        )}
      </div>
    </>
  );
};

export default SleepgladBlacklistFormulary;

SleepgladBlacklistFormulary.propTypes = {
  patientId: PropTypes.string,
  companyId: PropTypes.string
};
