import React, { useState } from "react";
import PropTypes from "prop-types";
import SmsSvg from "components/screens/company-contact-logic/images/sms-svg";
import {
  MdMailOutline,
  MdCheckBoxOutlineBlank,
  MdCheckBox
} from "react-icons/lib/md";
import { IoAndroidSend } from "react-icons/lib/io";
import MobileScreenShareSvg from "../screens/company-contact-logic/images/mobile-screen-share-svg";
import { connect } from "react-redux";
import { automatePatientOutreach } from "actions/outreach";
import { message, errorMessage } from "actions/message";

const BulkOutreachConfirmationModal = ({
  patients,
  requestData,
  setFetchStatus,
  batchClear,
  automatePatientOutreach
}) => {
  const [selectedEmail, setSelectedEmail] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const handleEmailSelection = selection => {
    if (selection == "") {
      setSelectedEmail(selection);
      setSendEmail(false);
    } else {
      setSelectedEmail(selection);
      setSendEmail(true);
    }
  };
  const [sendPush, setSendPush] = useState(false);
  const togglePush = () => {
    setSendPush(!sendPush);
  };
  const [sendText, setSendText] = useState(false);
  const toggleText = () => {
    setSendText(!sendText);
  };
  const SendAutomation = async () => {
    try {
      setFetchStatus(true);
      const { response } = await automatePatientOutreach({
        ids: patients,
        email: { send: sendEmail, type: selectedEmail },
        text: sendText,
        push: sendPush
      });
      message(
        "Automation started. We will notify you when its complete. You do not need to stay on this page for automation to continue.",
        -1
      );
      if (response.failure.length > 0) {
        errorMessage(
          `Unfortunately, the system was unable to send automation to the following selected patients:\n ${response.failure.join(
            ", "
          )}`,
          -1
        );
      }
      if (response && response.exception) {
        errorMessage(response.exeption, -1);
      }
    } catch (e) {
      errorMessage(`Automation failed to send.`, -1);
    } finally {
      requestData();
      setFetchStatus(false);
      batchClear();
    }
  };
  const ValidateAutomation = () => {
    if (!sendText && !sendPush && !sendEmail) {
      alert("You have not made any selections");
      return;
    }
    if ((sendText || sendPush) && selectedEmail == "mobile_app") {
      if (
        confirm(
          "Your email selection does not match the text and/or push notification you selected, would you like to send anyway?"
        )
      ) {
        SendAutomation();
      } else {
        return;
      }
    } else SendAutomation();
  };
  return (
    <>
      <div className="bulk-outreach-modal-header">
        Send the following automation to {patients.length} patient/s:
      </div>
      <div
        className={
          sendPush
            ? "bulk-outreach-modal-category-selected"
            : "bulk-outreach-modal-category"
        }
        title="Push"
      >
        <MobileScreenShareSvg size="2em" />{" "}
      </div>
      <div className="bulk-outreach-modal-listItem">
        Resupply{" "}
        {sendPush ? (
          <MdCheckBox size="1em" color="blue" onClick={togglePush} />
        ) : (
          <MdCheckBoxOutlineBlank size="1em" onClick={togglePush} />
        )}
      </div>
      <div
        className={
          sendText
            ? "bulk-outreach-modal-category-selected"
            : "bulk-outreach-modal-category"
        }
        title="Text"
      >
        <SmsSvg size="2em" />
      </div>
      <div className="bulk-outreach-modal-listItem">
        Resupply{" "}
        {sendText ? (
          <MdCheckBox size="1em" color="blue" onClick={toggleText} />
        ) : (
          <MdCheckBoxOutlineBlank size="1em" onClick={toggleText} />
        )}
      </div>
      <div
        className={
          sendEmail
            ? "bulk-outreach-modal-category-selected"
            : "bulk-outreach-modal-category"
        }
        title="Email"
      >
        <MdMailOutline size="2em" />{" "}
      </div>
      <div className="bulk-outreach-list-container">
        <div className="bulk-outreach-modal-listItem">
          Resupply:
          {selectedEmail == "resupply" ? (
            <MdCheckBox
              size="1.2em"
              color="blue"
              onClick={() => handleEmailSelection("")}
            />
          ) : (
            <MdCheckBoxOutlineBlank
              size="1em"
              onClick={() => handleEmailSelection("resupply")}
            />
          )}
        </div>
        <div className="bulk-outreach-modal-listItem">
          Mobile App:
          {selectedEmail == "mobile_app" ? (
            <MdCheckBox
              size="1em"
              color="blue"
              onClick={() => handleEmailSelection("")}
            />
          ) : (
            <MdCheckBoxOutlineBlank
              size="1em"
              onClick={() => handleEmailSelection("mobile_app")}
            />
          )}
        </div>
      </div>
      <div className="bulk-outreach-modal-send-container">
        <div
          className="bulk-outreach-modal-send"
          onClick={async () => ValidateAutomation()}
        >
          <IoAndroidSend size="2em" />
        </div>
      </div>
    </>
  );
};
export default connect(null, {
  automatePatientOutreach
})(BulkOutreachConfirmationModal);

BulkOutreachConfirmationModal.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.string),
  requestData: PropTypes.func.isRequired,
  setFetchStatus: PropTypes.func.isRequired,
  batchClear: PropTypes.func.isRequired,
  automatePatientOutreach: PropTypes.func.isRequired
};
