import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPatientsSnapshot } from "action-creators";
import { SingleStatCard } from "components/dashboard/dashboard-cards/single-stat-card";


const PatientsOverviewContainer = ({
  user,
  patientsSnapshot,
  getPatientsSnapshot
}) => {
  useEffect(() => {
    getPatientsSnapshot(user.get("id"));
  }, []);
  return (
    <>
    {patientsSnapshot && <PatientsOverview
      patients={patientsSnapshot}
      getPatientsSnapshot={getPatientsSnapshot}
    />}
    </>
  );
};

export default connect(
  state => ({
    user: state.get("user"),
    patientsSnapshot: state.get("patientsSnapshot")
  }),
  { getPatientsSnapshot }
)(PatientsOverviewContainer);

PatientsOverviewContainer.propTypes = {
  user: PropTypes.object.isRequired,
  patientsSnapshot: PropTypes.object,
  getPatientsSnapshot: PropTypes.func.isRequired
};

const PatientsOverview = ({ patients }) => {
  const patientStats = patients.toJS()
  const dashboardData = [
    {
      title: "Active Patients",
      tooltipText: "The total count of patients under management by S3.",
      class: "active-patients-card",
      value: patientStats.active,
    },
    {
      title: "Insurance Eligible for Supplies",
      tooltipText: `The count of active patients who could order based on their insurance's replacement schedule
      if the patient is ready for their supplies.`,
      class: "ins-elig-patients-card",
      value: patientStats.insurance_eligible,
    },
    {
      title: "Ready for Outreach",
      tooltipText:
        "The count of active, ins eligible patients who are scheduled for outreach from their sleep coach.",
      class: "ready-outreach-patients-card",
      value: patientStats.eligible,
    },
  ];

  return (
    <>
      {dashboardData.map((patientStat, i) => (
        <SingleStatCard
          key={i}
          classProp={patientStat.class}
          title={patientStat.title}
          tooltipText={patientStat.tooltipText} 
          numDashboardCards={dashboardData.length} 
          value={patientStat.value} />
      ))}
    </>
  );
};

PatientsOverview.propTypes = {
  patients: PropTypes.object
};
