import * as storage from "utils/storage";
import moment from "moment";

export const getLoginAttempts = () => {
  try {
    const serializedAttempts = storage.get("login-attempts");
    return (
      JSON.parse(serializedAttempts) || { badAttempts: 0, lastAttempt: "" }
    );
  } catch (e) {
    return { badAttempts: 0, lastAttempt: "" };
  }
};

export const updateBadLoginAttempts = () => {
  const { badAttempts } = getLoginAttempts();
  storage.put(
    "login-attempts",
    JSON.stringify({ badAttempts: badAttempts + 1, lastAttempt: new Date() })
  );
};

export const resetLoginAttempts = () => {
  storage.put(
    "login-attempts",
    JSON.stringify({ badAttempts: 0, lastAttempt: "" })
  );
};

export const userSigninAttemptsExceeded = () => {
  const { badAttempts, lastAttempt } = getLoginAttempts();
  if (badAttempts <= 5) return false;
  const now = new Date();
  if (moment(now).diff(lastAttempt, "minutes") <= 5) {
    return true;
  } else {
    resetLoginAttempts();
    return false;
  }
};
