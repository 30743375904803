import { GET_ARTICLES, DELETE_ARTICLES, UPDATE_ARTICLES, UPDATE_STATUS_ARTICLES } from '../ActionTypes';
import API from "../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage, message } from "../../../../actions/message";

export const getArticles = () => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/articles";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res.data.articles) {
                return dispatch({
                    type: GET_ARTICLES,
                    payload: res.data
                });
            }
        } else {
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_ARTICLES,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_ARTICLES,
            payload: []
        });
    })
}

export const createArticles = (data) => dispatch => {
    dispatch(getArticles());
    dispatch(showLoader());
    let createObj = {
        title: data.title,
        link: data.link,
        company: data.company,
        active: true
    };
    const url = "dme_portal/articles";
    API().post(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            dispatch(getArticles());
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const deleteArticles = (aid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/articles/" + aid;
    API().delete(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: DELETE_ARTICLES,
                payload: aid
            });
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const updateArticles = (data) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/articles";
    let createObj = {
        article_id: data.article_id,
        title: data.title,
        link: data.link,
        company: data.company,
        active: data.active
    }
    API().put(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_ARTICLES,
                payload: createObj
            });
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const updateStatusArticles = (data, status) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/articles";
    let createObj = {
        article_id: data.article_id,
        title: data.title,
        link: data.link,
        company: data.company,
        active: status
    };
    API().put(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_STATUS_ARTICLES,
                payload: createObj
            });
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    });
}
