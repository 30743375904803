// polyfill all `core-js` features:
// import "core-js";
// polyfill compilled generator & async functions
// import "regenerator-runtime/runtime";
import React from "react";
import configureStore from "./store/configureStore.js";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "./app";
// import { requestLoadUser } from "actions/authentication";
import { registerServiceWorker } from "./../register-sw";
import "./index.scss";
import { isProduction } from "environment";
import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

if (isProduction()) {
  Sentry.init({
    dsn: "https://79b5ccf715fb4c66933980c627d64efb@sentry.io/1379195",
    defaultIntegrations: false,
    integrations: [
      // new Integrations.BrowserTracing({
      //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      // }),
      new Sentry.Integrations.Breadcrumbs(),
      //new Sentry.Integrations.UserAgent()
      // new Sentry.Integrations.TryCatch()
    ]
  });
}

const store = configureStore();
// store.dispatch(requestLoadUser());
registerServiceWorker();

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept();
}
