import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMultiplePatients } from "components/dd_newModules/Redux/Actions/patients";
import ListSelector from "components/ui/list-selector";
import moment from "moment";

class PatientSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      convertedDateOfBirth: "",
      selectedSearchedPatients: [],
      selectedPatients: [],
      loadingSearch: false,
    };
  }

  searchPatient = async (event) => {
    event.preventDefault();
    this.setState({
      loadingSearch: true,
    });

    await this.props.getMultiplePatients(
      this.state.name,
      this.state.email,
      this.state.convertedDateOfBirth,
      this.props.searchedPatients
    );

    this.setState({
      loadingSearch: false,
    });
  };

  clearSearchPatient = () => {
    this.setState({
      name: "",
      email: "",
      dateOfBirth: "",
      convertedDateOfBirth: "",
      selectedPatient: "",
      loadingSearch: false,
    });
    this.props.getMultiplePatients("", "", "", []);
    if (this.props.onClear) this.props.onClear();
  };

  onSelectButtonClick = (event) => {
    event?.preventDefault();
    this.props.onPatientSelect(this.state.selectedSearchedPatients);
    this.setState({
      selectedSearchedPatients: [],
    });
  };

  handleDayChangePatient = (from) => {
    const convertedDate = moment(from).format("YYYY-MM-DD");
    this.setState({
      dateOfBirth: from,
      convertedDateOfBirth: convertedDate,
    });
  };

  changeHandler = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onSearchedPatientSelect = (patient) => {
    let selectedSearchedPatients = [...this.state.selectedSearchedPatients];
    if (this.props.allowMultiSelect) {
      if (selectedSearchedPatients.includes(patient)) {
        selectedSearchedPatients.splice(
          selectedSearchedPatients.indexOf(patient),
          1
        );
      } else {
        selectedSearchedPatients.push(patient);
      }
      this.setState({ selectedSearchedPatients });
    } else {
      selectedSearchedPatients = [patient];
      this.setState({ selectedSearchedPatients });
    }

    this.props.onPatientSelect(selectedSearchedPatients);
    this.setState({
      selectedSearchedPatients: [],
    });
  };

  getSearchedPatientsList = () => {
    let searchedPatientsList = [...this.props.searchedPatients];
    this.props.selectedPatients.forEach((selectedPatient) => {
      const index = searchedPatientsList.indexOf(selectedPatient);
      if (index > -1) {
        searchedPatientsList.splice(index, 1);
      }
    });
    return searchedPatientsList;
  };

  render() {
    const searchedPatientsList = this.getSearchedPatientsList();

    return (
      <>
        <h3>Search Patients</h3>
        <div className="patient-selector">
          <form onSubmit={this.searchPatient}>
           
            <label>Patient Name</label>
            <input
              type="text"
              value={this.state.name}
              name="name"
              onChange={this.changeHandler}
              placeholder="Enter Patient Name"
            />
            <label>Patient Email</label>
            <input
              type="text"
              value={this.state.email}
              name="email"
              onChange={this.changeHandler}
              placeholder="Enter Patient Email"
            />
            <label>Patient Date of Birth</label>
            <input type="date" onChange={(e)=>this.handleDayChangePatient(e.target.value)} value={this.state.dateOfBirth} />

            <div className="patient-search-button-wrapper">
              <button type="button" onClick={this.clearSearchPatient}>
                Clear
              </button>
              <button type="submit">Search</button>
            </div>
          </form>
          <div>
            Search Results
            <ListSelector
              minHeight={"224px"}
              maxHeight={"224px"}
              selected={this.state.selectedSearchedPatients}
              options={searchedPatientsList}
              onSelect={this.onSearchedPatientSelect}
              isLoading={this.state.loadingSearch}
            />
          </div>
          {/* <div className="select-patient-button-wrapper">
            <button onClick={this.onSelectButtonClick}>🡆</button>
          </div> */}
          <div>
            Selected Patients
            <ListSelector
              minHeight={"224px"}
              maxHeight={"224px"}
              selected={this.state.selectedSelectedPatients}
              options={this.props.selectedPatients}
              onSelect={this.onSelectedPatientSelect}
              onDeselect={this.props.onPatientDeselect}
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  state => ({
    searchedPatients: state.getIn(["newRootReducer"]).rdc_patients.Patients,
  }),
  { getMultiplePatients }
)(PatientSelector);

PatientSelector.propTypes = {
  selectedPatients: PropTypes.arrayOf(PropTypes.object),
  onPatientDeselect: PropTypes.func.isRequired,
  getMultiplePatients: PropTypes.func.isRequired,
  searchedPatients: PropTypes.arrayOf(PropTypes.object),
  onPatientSelect: PropTypes.func.isRequired,
  allowMultiSelect: PropTypes.bool,
  onClear: PropTypes.func
};
