import { useState, useCallback, useEffect } from "react";
import difference from "lodash/difference";
import uniq from "lodash/uniq";
import without from "lodash/without";
import includes from "lodash/includes";

export {
  SelectRowContext,
  RowSelectCell,
  RowSelectHeaderCell
} from "./components";

const useSelectRow = (rows = [], key, query = {}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const toggleRow = ({ target }) => {
    setSelectedRows(sel =>
      target.checked ? uniq([...sel, target.name]) : without(sel, target.name)
    );
  };
  const isRowSelected = useCallback(
    id => includes(selectedRows, id),
    [selectedRows]
  );

  const toggleAllRows = () => {
    const ids = rows.map(r => r[key]);
    const currentlyUnmarked = difference(ids, selectedRows);
    if (currentlyUnmarked.length) {
      // if any records aren't marked already, then mark all
      setSelectedRows(sel => uniq([...sel, ...ids]));
    } else {
      // else if all records are marked, clear batched ids
      setSelectedRows([]);
    }
  };
  const clearSelectedRows = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [query.filters, query.sort]);

  return {
    toggleAllRows,
    toggleRow,
    selectedRows,
    isRowSelected,
    clearSelectedRows
  };
};

export default useSelectRow;
