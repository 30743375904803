import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { InlineWaiting } from "components/ui";
import moment from "moment";

const ChangeLogPanel = ({ logs, isFetchingLogs }) => (
  <div className="logs-page">
    {!isFetchingLogs ? (
      <div className="log-container">
        <table>
          <thead>
            <tr>
              <th>Changed By</th>
              <th>Changed Date</th>
              <th>Patient</th>
              <th>Changed Field</th>
              <th>Changed From</th>
              <th>Changed To</th>
            </tr>
          </thead>
          <tbody>
            {logs.map(log => (
              <tr key={log.id}>
                <td>{log.changedByName || ""}</td>
                <td>
                  {moment(log.changedDate).format("MMM DD, YYYY hh:mm A")}
                </td>
                <td>
                  <Link to={`/patients/${log.patientID}`}>
                    {`${log.patientFirstName} ${log.patientLastName}`}
                  </Link>
                </td>
                <td>{log.changedField}</td>
                <td>{log.oldValue}</td>
                <td>{log.newValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="logs-waiting-container">
        <InlineWaiting />
      </div>
    )}
  </div>
);

ChangeLogPanel.propTypes = {
  logs: PropTypes.array.isRequired,
  isFetchingLogs: PropTypes.bool
};

export default ChangeLogPanel;
