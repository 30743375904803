import React, { useState } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import moment from "moment";
import ModalLink from "components/shared/modal-link";

const filterNotes = filter => note => {
  const { orderID, invoiceNumber } = note;
  if (!filter) return true;
  else if (filter === "patient") {
    return !orderID;
  } else if (filter === "order") {
    return orderID && invoiceNumber;
  }
};
const PatientNotes = ({ patientNotes }) => {
  const [notesFilter, setNotesFilter] = useState();
  return (
    <div className="patient-notes-container">
      <div className="patient-notes-header">
        <select
          className="note-filter-select"
          value={notesFilter}
          onChange={({ target }) => {
            setNotesFilter(target.value);
          }}
        >
          <option value="">All Notes</option>
          <option value="patient">Patient Notes</option>
          <option value="order">Order Notes</option>
        </select>
      </div>
      <ul>
        {patientNotes
          .filter(filterNotes(notesFilter))
          .map(
            ({ id, content, user_name, updated_at, orderID, invoiceNumber }) =>
              orderID && invoiceNumber ? (
                <li key={id} className="note-container">
                  <div className="note-header">
                    <strong>{user_name}</strong> -{" "}
                    {moment(updated_at).format("LLLL")}
                  </div>
                  <span className="note-type">
                    Order note for{" "}
                    <ModalLink
                      title="Go to this order's page"
                      to={"/orders/" + orderID}
                    >
                      {invoiceNumber}
                    </ModalLink>
                  </span>
                  <div className="note-content">{content}</div>
                </li>
              ) : (
                <li key={id} className="note-container">
                  <div className="note-header">
                    <strong>{user_name}</strong> -{" "}
                    {moment(updated_at).format("LLLL")}
                  </div>
                  <div className="note-type">Patient Note</div>
                  <div className="note-content">{content}</div>
                </li>
              )
          )}
      </ul>
    </div>
  );
};

PatientNotes.defaultProps = {};

PatientNotes.propTypes = {
  patientNotes: PropTypes.array,
  patientOrders: PropTypes.array
};

export default connect((state, { patientId }) => ({
  patientNotes: selectors.getPatientNotes(state, patientId)
}))(PatientNotes);
