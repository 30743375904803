import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import SearchableDropdown from "../../../searchable-dropdown/index";
import { Spinner } from "components/ui";
import { activityCenterStart } from "actions/auto-dialer";
import MdCall from "react-icons/lib/md/phone";

const ActivityCenter = ({
  teams,
  user,
  autoDialerEnabled,
  activityCenterStart,
  isFetchingByButton
}) => {
  const [team, setTeam] = useState(user.team_id);

  // if (autoDialerEnabled) {
  //   return (
  //     <h1
  //       style={{
  //         textAlign: "center",
  //         color: "red",
  //         fontWeight: "700",
  //         margin: "200"
  //       }}
  //     >
  //       You must stop your autodialer before applying new filters
  //     </h1>
  //   );
  // }
  return (
    <>
      {teams ? (
        <div className="autodialer-filters-container">
          <h1 className="autodialer-filters-title">Activity Center</h1>
          <div className="autodialer-filters-row">
            <div className="autodialer-filters-row-group">
              <div className="autodialer-filters-row-top">
                <label className="autodialer-filters-input-top-label">
                  Teams
                </label>
                <SearchableDropdown
                  options={[
                    { text: "", value: "", key: "" },
                    ...teams.map(({ key, text }) => ({ value: key, text, key }))
                  ]}
                  selected={team}
                  onSelect={value => {
                    setTeam(value);
                  }}
                />
              </div>
            </div>
          </div>
          {team && !autoDialerEnabled && (
            <button
              className={
                isFetchingByButton
                  ? "autodialer-filters-start-fetching"
                  : "autodialer-filters-start"
              }
              title={
                isFetchingByButton ? "Fetching now" : "Start Activity Center"
              }
              onClick={() => {
                if (!isFetchingByButton) {
                  activityCenterStart(user.id, team);
                }
              }}
            >
              <MdCall />
            </button>
          )}
        </div>
      ) : (
        <div style={{ textAlign: "center", margin: "50px" }}>
          <Spinner size="45px" />
        </div>
      )}
    </>
  );
};

ActivityCenter.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.PropTypes.object.isRequired,
  autoDialerEnabled: PropTypes.bool.isRequired,
  activityCenterStart: PropTypes.func.isRequired,
  isFetchingByButton: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    teams: selectors.getTeamNameOptions(state),
    autoDialerEnabled: selectors.getIsAutoDialerEnabled(state),
    isFetchingByButton: selectors.getAutoDialerIsFetchingBatchByButton(state),
    user: selectors.getUser(state)
  }),
  {
    activityCenterStart
  }
)(ActivityCenter);
