import { post } from "utils/api";
import { errorMessage } from "actions/message";

export const Types = {
  START_ORDER_IMPORT: "START_ORDER_IMPORT",
  ORDER_IMPORT_FAILED: "ORDER_IMPORT_FAILED",
  ORDER_IMPORT_COMPLETED: "ORDER_IMPORT_COMPLETED"
};

export const importOrders = payload => async dispatch => {
  try {
    dispatch({ type: Types.START_ORDER_IMPORT });
    const response = await post("imports/orders", payload);
    console.log(response);
    dispatch({ type: Types.ORDER_IMPORT_COMPLETED, payload: response });
    return response;
  } catch (err) {
    dispatch(errorMessage(`Order Import Failed: ${err.message}`));
    dispatch({ type: Types.ORDER_IMPORT_FAILED, error: err.message });
  }
};
