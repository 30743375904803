import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  setPhotoActive,
  setPhotoPrimary,
  setVideoActive,
  setVideoPrimary
} from "actions/product-media";
import { Waiting, PureModal } from "components/ui";
import MediaUploadForm from "./media-upload-form";

const formatYoutubeUrl = youtubeUrl => {
  return youtubeUrl.replace("watch?v=", "embed/");
};

const formatVideoUrl = videoUrl => {
  if (videoUrl.includes("youtube")) return formatYoutubeUrl(videoUrl);
  return videoUrl;
};

const setNextEligiblePrimaryMedia = (
  media,
  currentIndex,
  setPrimaryCallback
) => {
  let nextPrimary = null;
  if (currentIndex == 0) {
    if (currentIndex + 1 < media.length && media[currentIndex + 1].active)
      nextPrimary = media[currentIndex + 1];
  } else if (media[currentIndex - 1].active) {
    nextPrimary = media[currentIndex - 1];
  }
  setPrimaryCallback(nextPrimary?.product_id, nextPrimary?.media_id, true);
};

const InventoryMedia = ({
  inventoryItem,
  media,
  isLoading,
  setPhotoActive,
  setPhotoPrimary,
  setVideoActive,
  setVideoPrimary
}) => {
  return (
    <div className="inventory-media">
      {isLoading ? (
        <Waiting />
      ) : (
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 10
            }}
          >
            <div>
              <h4>Videos</h4>
              <hr />
              <ProductVideos
                setVideoActive={setVideoActive}
                setVideoPrimary={setVideoPrimary}
                videos={media.videos}
                inventoryItem={inventoryItem}
              />
            </div>
            <div>
              <div style={{ display: "flex", flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1
                  }}
                >
                  <h4 style={{ marginBottom: 0 }}>Photos</h4>
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <span
                        style={{
                          flex: 1,
                          justifyContent: "flex-end",
                          display: "flex"
                        }}
                      >
                        <button
                          type="button"
                          style={{
                            margin: 0,
                            padding: "5px 10px"
                          }}
                          onClick={openModal}
                        >
                          Add Media
                        </button>
                      </span>
                    )}
                    renderContent={({ closeModal }) => (
                      <MediaUploadForm
                        closeModal={closeModal}
                        inventoryItem={inventoryItem}
                      />
                    )}
                  />
                </div>
              </div>
              <hr />
              <ProductImages
                setPhotoActive={setPhotoActive}
                setPhotoPrimary={setPhotoPrimary}
                images={media.images}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

InventoryMedia.propTypes = {
  inventoryItem: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  media: PropTypes.object,
  getProductVideos: PropTypes.func,
  getProductPhotos: PropTypes.func,
  setPhotoPrimary: PropTypes.func.isRequired,
  setPhotoActive: PropTypes.func.isRequired,
  setVideoPrimary: PropTypes.func.isRequired,
  setVideoActive: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default connect(() => ({}), {
  setPhotoPrimary,
  setPhotoActive,
  setVideoPrimary,
  setVideoActive
})(InventoryMedia);

const ProductVideos = ({ videos, setVideoActive, setVideoPrimary }) => {
  return (
    <div className="videos-container">
      {videos?.map((video, index) => {
        return (
          <div className="video-card" key={video.media_id}>
            <div className="video-wrapper">
              <div className="video-iframe-container">
                <iframe
                  className="video-iframe"
                  src={formatVideoUrl(video.link)}
                  frameBorder="0"
                  allow="encrypted-media"
                  allowFullScreen
                  title={video.title}
                />
                <div
                  className="media-primary-button"
                  data-tip={video.primary ? "Primary" : "Make Primary"}
                  onClick={() => {
                    if (!video.primary)
                      setVideoPrimary(video.product_id, video.media_id, true);
                    if (!video.active)
                      setVideoActive(video.product_id, video.media_id, true);
                  }}
                >
                  {video.primary && "✔"}
                </div>
                {!video.active ? (
                  <div
                    className="media-activate-button"
                    data-tip="Reactivate"
                    onClick={() =>
                      setVideoActive(video.product_id, video.media_id, true)
                    }
                  >
                    ↺
                  </div>
                ) : (
                  <div
                    className="media-deactivate-button"
                    data-tip="Deactivate"
                    onClick={() => {
                      if (video.primary && videos.length > 1) {
                        setNextEligiblePrimaryMedia(
                          videos,
                          index,
                          setVideoPrimary
                        );
                        setVideoPrimary(
                          video.product_id,
                          video.media_id,
                          false
                        );
                      }
                      setVideoActive(video.product_id, video.media_id, false);
                    }}
                  >
                    x
                  </div>
                )}
              </div>
            </div>
            <div className="video-card-description">
              <h4 className="video-title" title={video.title}>
                {video.title}
              </h4>
              <div>{video.description}</div>
            </div>
            <div className={!video.active ? "media-inactive-overlay" : ""} />
          </div>
        );
      })}
    </div>
  );
};
ProductVideos.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object),
  setVideoActive: PropTypes.func.isRequired,
  setVideoPrimary: PropTypes.func.isRequired
};

const ProductImages = ({ images, setPhotoActive, setPhotoPrimary }) => {
  return (
    <div className="photos-container">
      <ReactTooltip />
      {images?.map((image, index) => {
        return (
          <div className="inventory-photo" key={image.media_id}>
            <div
              className="media-primary-button"
              data-tip={image.primary ? "Primary" : "Make Primary"}
              onClick={() => {
                if (!image.primary)
                  setPhotoPrimary(image.product_id, image.media_id, true);
                if (!image.active)
                  setPhotoActive(image.product_id, image.media_id, true);
              }}
            >
              {image.primary && "✔"}
            </div>
            {!image.active ? (
              <div
                className="media-activate-button"
                data-tip="Reactivate"
                onClick={() =>
                  setPhotoActive(image.product_id, image.media_id, true)
                }
              >
                ↺
              </div>
            ) : (
              <div
                className="media-deactivate-button"
                data-tip="Deactivate"
                onClick={() => {
                  console.log(image)
                  if (image.primary && images.length > 1) {
                    setNextEligiblePrimaryMedia(images, index, setPhotoPrimary);
                    setPhotoPrimary(image.product_id, image.media_id, false);
                  }
                  setPhotoActive(image.product_id, image.media_id, false);
                }}
              >
                x
              </div>
            )}
            <img src={image.link} alt="Product Image" />
            <div className={!image.active ? "media-inactive-overlay" : ""} />
          </div>
        );
      })}
    </div>
  );
};
ProductImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  setPhotoActive: PropTypes.func.isRequired,
  setPhotoPrimary: PropTypes.func.isRequired
};
