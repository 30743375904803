import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { get, post, put, del } from "utils/api";
import { path, prop } from "ramda";
import { useFetch } from "hooks";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import CmnRow from "./cmn-row";
import CmnEditRow from "./cmn-edit-row";
import CmnCreateNoteEntry from "./create-cmn-note";
import PureModal from "components/ui/modal/pure-modal";
import { IconButton } from "components/ui";
import { MdAdd, MdInfo } from "react-icons/lib/md";
import PatientLmnsOnFile from "features/bonafide/components/order-form/patient-lmns";
import { aLink } from "utils/styles";

const CmnNotes = React.memo(({ patientId, readOnly }) => {
  const { response: hcpcs, isFetching, fetchData } = useFetch({
    apiFn: patientId => get(`patients/${patientId}/CMN_Notes`),
    defaultValue: [],
    transformResponse: prop("cmn_notes"),
    transformError: path(["response", "body", "status"])
  });
  React.useEffect(() => {
    fetchData(patientId);
  }, [patientId]);
  const { rows, query, queryDispatcher } = useLocalQuery(hcpcs, {
    sort: {},
    filters: {}
  });
  const patientInfo = useSelector(state =>
    selectors.getPatientInfo(state, patientId)
  );
  const createNewCmnNote = async cmn_entry => {
    await post(`patients/${patientId}/CMN_Notes`, cmn_entry);
    await fetchData(patientId);
  };
  const updateCmnNote = async (guid, cmn_entry) => {
    await put(`patients/${patientId}/CMN_Notes/${guid}`, cmn_entry);
    await fetchData(patientId);
  };
  const deleteCmnNote = async guid => {
    await del(`patients/${patientId}/CMN_Notes/${guid}`);
    await fetchData(patientId);
  };
  return (
    <div
      className="cmn-notes-table"
      style={{
        border: "1px solid rgb(221, 221, 221)"
      }}
    >
      <Table
        query={query}
        containerClass="query-table patient-tab-table dynamic-columns"
        queryDispatcher={queryDispatcher}
        rows={rows}
        isFetching={isFetching}
        TableRow={cmnEntry => (
          <CmnNoteTableRow
            cmnEntry={cmnEntry}
            updateCmnNote={updateCmnNote}
            deleteCmnNote={deleteCmnNote}
            readOnly={readOnly}
          />
        )}
        renderFooter={
          <div className="query-footer">
            {patientInfo.uses_bonafide_api && (
              <PureModal
                renderTrigger={({ openModal }) => (
                  <div
                    style={{
                      paddingLeft: 10,
                      dispaly: "flex",
                      alignItems: "center",
                      ...aLink
                    }}
                    onClick={openModal}
                  >
                    <MdInfo size={16} />
                    <span style={{ paddingLeft: 5 }}>
                      LMNs on File(Bonafide)
                    </span>
                  </div>
                )}
              >
                <PatientLmnsOnFile />
              </PureModal>
            )}
            <div className="total-container">
              <span className="count-label">Count:</span>
              <span className="count-value">{rows.length}</span>
            </div>
          </div>
        }
        rowKey="GUID"
        columns={[
          {
            field: "HCPC",
            header: "HCPC/CMN",
            style: { width: "10%" }
          },
          {
            field: "Product",
            header: "Product",
            style: { width: "15%" }
          },
          {
            field: "cmnInitialDate",
            header: "Initial Date",
            style: { width: "17%" }
          },
          {
            field: "cmnExpirationDate",
            header: "Expiration Date",
            style: { width: "17%" }
          },
          {
            field: "Comments",
            header: "Comments",
            style: { width: "23%" }
          },
          {
            field: "updated",
            header: "Last Updated"
          },
          {
            field: "",
            Cell: () => (
              <td className="query-table-header-cell-container">
                <PureModal
                  renderTrigger={({ openModal }) => (
                    <div
                      className="header-text"
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end"
                      }}
                    >
                      <IconButton
                        onClick={openModal}
                        icon={<MdAdd />}
                        text="Add Entry"
                        style={{ margin: 0, padding: "5px 10px" }}
                        disabled={readOnly}
                      />
                    </div>
                  )}
                >
                  <CmnCreateNoteEntry createNewCmnNote={createNewCmnNote} />
                </PureModal>
              </td>
            ),
            header: ""
          }
        ]}
      />
    </div>
  );
});

CmnNotes.propTypes = {
  patientId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

CmnNotes.defaultProps = {
  readOnly: false,
}

const CmnNoteTableRow = ({ cmnEntry, updateCmnNote, deleteCmnNote, readOnly }) => {
  const [editRow, setEditRow] = React.useState(false);
  return editRow ? (
    <CmnEditRow
      {...cmnEntry}
      handleSave={async values => {
        try {
          await updateCmnNote(cmnEntry.GUID, values);
          setEditRow(false);
        } catch (e) {
          /* handle error */
        }
      }}
      handleCancel={() => {
        setEditRow(false);
      }}
    />
  ) : (
    <CmnRow
      {...cmnEntry}
      readOnly={readOnly}
      handleEdit={() => setEditRow(true)}
      handleDelete={async guid => {
        try {
          const confirmation = confirm(
            "Are you sure you want to delete this entry?"
          );
          if (confirmation) await deleteCmnNote(guid);
        } catch (e) {
          /* handle error */
        }
      }}
    />
  );
};

CmnNoteTableRow.propTypes = {
  cmnEntry: PropTypes.object.isRequired,
  updateCmnNote: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  deleteCmnNote: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default CmnNotes;
