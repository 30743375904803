import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import SelectDropdown from "components/forms/select-dropdown";
import MdSettings from "react-icons/lib/md/settings";
import MdRemoveCircle from "react-icons/lib/md/remove-circle";
import MdPerson from "react-icons/lib/md/person";
import MdGroup from "react-icons/lib/md/group";

const EmailGroupAddress = ({
  editStateFromParent,
  currentEmailGroupAddressID,
  address,
  deleteEmailGroupAddress,
  deleteEmailGroupAddressAccessibleCompany,
  addEmailGroupAddressAccessibleCompany,
  companies,
}) => {
  const [editable, setEditable] = useState(false);
  const [addAccessibleCompany, setAddAccessibleCompany] = useState(false);
  const [selectedAccessibleCompany] = useState(null);

  const [firstNameEditState, setFirstNameEditState] = useState("");
  const [lastNameEditState, setLastNameEditState] = useState("");
  const [isPerson, setIsPerson] = useState(null);

  useEffect(() => {
    if (!editStateFromParent) {
      setEditable(false);
    }
  }, [editStateFromParent]);
  useEffect(() => {
    if (editable) {
      if (address.person) {
        setFirstNameEditState(address.first_name);
        setLastNameEditState(address.last_name);
        // setUserRoleEditState(address.role);
        // setMemberNameEditState("");
        setIsPerson(true);
      } else {
        setFirstNameEditState("");
        setLastNameEditState("");
        // setUserRoleEditState("");
        // setMemberNameEditState(address.member_name);
        setIsPerson(false);
      }
      // setCompanyEditState(address.company_id);
      // setMemberEmailAddressEditState(address.email_address);
    }
  }, [editable]);

  useEffect(() => {
    setEditable(false);
  }, [currentEmailGroupAddressID, address]);

  const getFilteredListOfCompanies = () => {
    if (companies && address && address.accessible_companies) {
      return companies.filter(
        x =>
          address.accessible_companies.findIndex(
            y => y.company_id === x.value
          ) === -1
      );
    } else if (companies) {
      return companies;
    } else {
      return [];
    }
  };
  return (
    <div className="emailGroupAddress emailGroupDropShadow">

      <div style={{ margin: "10px" }}>
        <span
          className="editButton"
          title="Edit Address"
          onClick={() => {
            setEditable(!editable);
          }}
          style={{
            position: "relative",
            top: "4px",
            right: "-97%",
            cursor: "pointer"
          }}
        >
          <MdSettings />
        </span>
        {editable ? (
          <>
            <div style={{ all: "unset" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  alignItems: "center",
                  columnGap: "25px",
                  justifyItems: "center"
                }}
              >
                <span
                  title="Set Address As Person Type"
                  onClick={() => {
                    setIsPerson(true);
                  }}
                  className="addressFormPersonButton"
                >
                  <MdPerson />
                </span>
              </div>
            </div>
            {isPerson && (
              <>
                <input
                  style={{}}
                  className="createEmailGroupInput"
                  value={firstNameEditState}
                  onChange={e => {
                    setFirstNameEditState(e.target.value);
                  }}
                  placeholder={"User's First Name..."}
                />
                <input
                  style={{ marginTop: "10px" }}
                  className="createEmailGroupInput"
                  value={lastNameEditState}
                  onChange={e => {
                    setLastNameEditState(e.target.value);
                  }}
                  placeholder={"User's Last Name..."}
                />
              </>
            )}
          </>
        ) : (
          <>
            <div className="emailGroupAddressMemberName center">
              <span
                title={
                  address.person
                    ? "This is a single user"
                    : "This is a group email"
                }
                className="addressMemberIcon"
              >
                {address.person ? (
                  <MdPerson />
                ) : (
                  <span>
                    <MdGroup />
                  </span>
                )}
              </span>
              <span
                  style={{
                    fontSize: "2em",
                    paddingLeft: "5px",
                    fontWeight: address.is_S3_user ?  "bolder" : "normal" ,
                    color: address.can_email_from_profile ? "green" : "red"
                  }}
                >
                {address.person
                  ? address.first_name + " " + address.last_name
                  : address.member_name}
              </span>
            </div>
            <div className="emailGroupAddressMemberEmail center">
              <span>{address.email_address}</span>
            </div>
            <div className="emailGroupAddressMemberEmail center">
              <span>{address.company_name}</span>
            </div>
            <div className=" emailGroupAddressMemberRole center">
              <span>{address.person && address.role}</span>
            </div>
          </>
        )}

        <div className="emailGroupAddressAccessibleCompanies">
          <div className="center">
            <span
              style={{
                color: "grey",
                position: "relative",
                left: "-14px",
                fontSize: "1.5em",
              }}
            >
              Accessible Companies
            </span>
          </div>
          <div className="emailGroup-Companies">
            {address.accessible_companies &&
              address.accessible_companies.map((a, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{ width: "100%" }}
                    className="center"
                  >
                    {editable && (
                      <span
                        title={
                          a.company_name
                            ? `Remove Access To ${a.company_name}`
                            : "Remove Acccess To This Company"
                        }
                        className="accessibleCompanyDeleteButton"
                        onClick={() => {
                          deleteEmailGroupAddressAccessibleCompany(
                            address.email_group_member_id,
                            a.company_id
                          );
                        }}
                      >
                        <MdRemoveCircle />
                      </span>
                    )}
                    <div>{a.company_name}</div>
                  </div>
                </React.Fragment>
              ))}
          </div>
          {addAccessibleCompany &&
            companies &&
            companies.length > 0 &&
            address && (
              <>
                <div
                  className="center"
                  style={{ marginTop: "10px", color: "grey" }}
                >
                  Companies
                </div>
                <SelectDropdown
                  searchable={true}
                  onChange={item => {
                    addEmailGroupAddressAccessibleCompany(
                      address.email_group_member_id,
                      item
                    );
                  }}
                  onBlur={() => {}}
                  placeHolder="Choose A New Accessible Company..."
                  value={selectedAccessibleCompany}
                  options={getFilteredListOfCompanies()}
                />
                <div
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                  onClick={() => {
                    setAddAccessibleCompany(false);
                  }}
                  className="submitButton displayGridOne"
                >
                  <span className="justifyAndAlignSelfCenter">CANCEL</span>
                </div>
              </>
            )}
        </div>
        {editable && (
          <div
            style={{ paddingBottom: "20px" }}
            onClick={() => {
              deleteEmailGroupAddress(address.email_group_member_id);
            }}
            className="deleteAddressButton displayGridOne"
          >
            <span className="justifyAndAlignSelfCenter">DELETE ADDRESS</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state)
}))(EmailGroupAddress);

EmailGroupAddress.propTypes = {
  editStateFromParent: PropTypes.bool,
  currentEmailGroupAddressID: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  address: PropTypes.object.isRequired,
  deleteEmailGroupAddress: PropTypes.func.isRequired,
  addEmailGroupAddressAccessibleCompany: PropTypes.func.isRequired,
  deleteEmailGroupAddressAccessibleCompany: PropTypes.func.isRequired,
  updateEmailGroupAddress: PropTypes.func.isRequired
};
