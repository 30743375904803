import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { StatBlock } from "components/ui";
import { getPatientsSnapshot } from "action-creators";

const CoachStats = ({ userId, getPatientsSnapshot, snapshot }) => {
  useEffect(() => {
    getPatientsSnapshot(userId);
  }, []);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, auto)",
        gridGap: 20,
        marginBottom: 20
      }}
    >
      <StatBlock
        title="Active Patients"
        value={
          snapshot.get("active") ? snapshot.get("active").toLocaleString() : "0"
        }
      />
      <StatBlock
        title="Eligible for Outreach"
        value={
          snapshot.get("eligible")
            ? snapshot.get("eligible").toLocaleString()
            : "0"
        }
      />
      <StatBlock
        title="Not Eligible"
        value={
          snapshot.get("not_eligible")
            ? snapshot.get("not_eligible").toLocaleString()
            : "0"
        }
      />
    </div>
  );
};

export default connect(
  state => ({
    userId: selectors.getUserId(state),
    snapshot: state.get("patientsSnapshot")
  }),
  {
    getPatientsSnapshot
  }
)(CoachStats);

CoachStats.propTypes = {
  snapshot: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  getPatientsSnapshot: PropTypes.func.isRequired
};
