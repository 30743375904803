import { GET_DOCUMENT_CATEGORY,DELETE_DOCUMENT_CATEGORY, UPDATE_DOCUMENT_CATEGORY, UPDATE_STATUS_DOCUMENT_CATEGORY  } from '../ActionTypes';
import API from "../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage , message } from "actions/message";

export const getDocumentCategory = () => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/document_categories";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res.data.document_categories) {
                return dispatch({
                    type: GET_DOCUMENT_CATEGORY,
                    payload: res.data.document_categories
                });
            }
        }else{
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_DOCUMENT_CATEGORY,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_DOCUMENT_CATEGORY,
            payload: []
        });
    });
}

export const createDocumentCategory = ({
  document_category_name,
  active,
  is_media
}) => dispatch => {
  dispatch(showLoader());
  API()
    .post("dme_portal/document_categories", { document_category_name, active, is_media })
    .then(res => {
      dispatch(hideLoader());
      if (res && res.data && res.data.success) {
        dispatch(message(res.data.message));
        dispatch(getDocumentCategory());
      } else {
        dispatch(errorMessage(res.data.message));
      }
    })
    .catch(err => {
      const responseErrorMessage = err.response.data.message;
      dispatch(hideLoader());
      dispatch(errorMessage(responseErrorMessage));
    });
};

export const deleteDocumentCategory = (DCid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/document_categories/" + DCid;
    API().delete(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: DELETE_DOCUMENT_CATEGORY,
                payload: DCid
            });
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const updateDocumentCategory = ({
  document_category_id,
  document_category_name,
  active,
  is_media
}) => dispatch => {
  dispatch(showLoader());
  API()
    .put("dme_portal/document_categories", { document_category_id, document_category_name, active, is_media })
    .then(res => {
      dispatch(hideLoader());
      if (res && res.data && res.data.success) {
        dispatch(message(res.data.message));
        dispatch(getDocumentCategory());
        return dispatch({
          type: UPDATE_DOCUMENT_CATEGORY,
          payload: { document_category_id, document_category_name, active, is_media }
        });
      } else {
        dispatch(errorMessage(res.data.message));
      }
    })
    .catch(err => {
      const responseErrorMessage = err.response.data.message;
      dispatch(hideLoader());
      dispatch(errorMessage(responseErrorMessage));
    });
};

export const updateStatusDocumentCategory = (data, status) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/document_categories";
    data.active = status;
    API().put(url, data).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_STATUS_DOCUMENT_CATEGORY,
                payload: data
            });
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}
