import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { browserHistory } from "browser-history";
import { aLink } from "utils/styles";
import { Portal } from "react-portal";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import * as storage from "utils/storage";

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null };
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    this.captureException(error, errorInfo);
  }

  getUserFeedback = () => {
    Sentry.showReportDialog();
  };

  resetError = () => {
    browserHistory.push("/dashboard");
    this.setState({ error: false, errorInfo: false });
  };

  captureException = (error, errorInfo) => {
    const serializedUser = storage.get("evergreen");
    const user = JSON.parse(serializedUser) || {};
    Sentry.withScope(scope => {
      scope.setUser({
        id: user.id
      });
      scope.setTag("location", window.location.href);
      scope.setTag("userId", user.id);
      scope.setTag("errorBoundary", true);
      Sentry.captureException(error, { errorInfo });
    });
  };

  render() {
    if (this.state.error) {
      return (
        <React.Fragment>
          <Portal>
            <div className="error-boundary-underlay" />
          </Portal>
          <div className="error-boundary">
            <div className="error-boundary-error" />
            <h1>Error</h1>
            <h2>Woops! Sorry something went wrong.</h2>
            <div className="error-actions">
              <div style={aLink} onClick={this.resetError}>
                Back to homepage
              </div>
              <div>
                <div
                  onClick={this.getUserFeedback}
                  style={aLink}
                  className="feedback-link"
                >
                  Provide Feedback
                </div>
                <details>
                  {this.state.error.toString()}
                  {this.state.errorInfo.componentStack.toString()}
                </details>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default withRouter(ErrorBoundary);
