import React from "react";
import PropTypes from "prop-types";
import Loader from "../loader/index.jsx";
import {
  LabelList,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const ItemStatistics = ({ insights }) => (
  <div className="items-sold-container">
    <h3 className="bar-graph-header">Items Sold</h3>
    {insights ? (
      <div className="graph-container">
        <BarChart
          height={300}
          width={900}
          data={[
            {
              label: "Masks",
              count: insights.getIn(["order_status", "masks"])
            },
            {
              label: "Seals",
              count: insights.getIn(["order_status", "seals"])
            },
            {
              label: "Filters",
              count: insights.getIn(["order_status", "filters"])
            },
            {
              label: "Tubing",
              count: insights.getIn(["order_status", "tubing"])
            },
            {
              label: "Chinstraps",
              count: insights.getIn(["order_status", "chinstraps"])
            },
            {
              label: "WaterChambers",
              count: insights.getIn(["order_status", "water_chambers"])
            }
          ]}
          margin={{ top: 20, right: 50, bottom: 20, left: 50 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <YAxis
            type="number"
            dataKey="count"
            domain={[0, dataMax => dataMax * 1.5]}
            scale="sqrt"
          />
          <XAxis dataKey="label" type="category" />
          <Bar
            dataKey="count"
            fill="rgba(45, 198, 184, 0.89)"
            isAnimationActive={false}
          >
            <LabelList dataKey="count" position="top" />
          </Bar>
          <Tooltip cursor={false} />
        </BarChart>
      </div>
    ) : (
      <Loader />
    )}
  </div>
);

ItemStatistics.propTypes = {
  insights: PropTypes.object
};

export default ItemStatistics;
