import { makeFetchUsersType } from "actions/users";
import { createSelector } from "reselect";

export const isUsersFetching = (state, userType) => {
  return state.getIn([makeFetchUsersType(userType), 'isFetching'])
}

const isFetchingInventory = createSelector(
  [state => state.getIn(["fetch", "GET_INVENTORY", "isFetching"])],
  isFetching => isFetching
);

export const selectors = {
  isFetchingInventory
};
