import { put } from "utils/api";

export const Types = {
  REQUEST_EMPLOYEE_TOTAL_TIMES: "REQUEST_EMPLOYEE_TOTAL_TIMES",
  EMPLOYEE_TOTAL_TIMES_RESPONSE: "EMPLOYEE_TOTAL_TIMES_RESPONSE",
  EMPLOYEE_TOTAL_TIMES_FAILED: "EMPLOYEE_TOTAL_TIMES_FAILED"
};

export const fetchEmployeeTimes = (start_date, end_date) => async dispatch => {
  dispatch({ type: Types.REQUEST_EMPLOYEE_TOTAL_TIMES });
  try {
    const { On_Line_Totals } = await put("lead-dashboard", {
      start_date,
      end_date
    });
    dispatch({
      type: Types.EMPLOYEE_TOTAL_TIMES_RESPONSE,
      payload: On_Line_Totals
    });
    return On_Line_Totals;
  } catch (error) {
    dispatch({ type: Types.EMPLOYEE_TOTAL_TIMES_FAILED, error });
  }
};
