import React from "react";
import { put, post, del } from "utils/api";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { message, errorMessage } from "actions/message";

const useAvailabilityScheduler = () => {
  const dispatch = useDispatch();
  const [userTimeSlots, setUserTimeSlots] = React.useState([]);
  const [pendingTimeSlot, setPendingTimeSlot] = React.useState(null);
  const [week, selectWeek] = React.useState(moment().week());
  const [selected, setSelected] = React.useState({
    row1: null,
    col: null
  });
  const [editSlot, setEditSlot] = React.useState();
  const [gettingTimeSlots, setGettingTimeSlots] = React.useState(false)
  const [creatingOrEditingTimeSlots, setCreatingOrEditingTimeSlots] = React.useState(false)

  React.useEffect(() => {
    getUserTimeSlots(week);
  }, [week]);

  const getUserTimeSlots = async week => {
    setGettingTimeSlots(true)
    const startDate = moment().week(week).startOf("week").format("L");
    const endDate = moment().week(week).endOf("week").format("L");
    const { slots = [] } = await put(
      "/dme_portal/available_slots_by_date_range",
      {
        startDate,
        endDate,
        active: true
      }
    );
    setUserTimeSlots(
      slots?.map(s => ({
        ...s,
        start_time: moment(s.start_time).format("L LT"),
        end_time: moment(s.end_time).format("L LT")
      })) ?? []
    );
    setGettingTimeSlots(false)
  };
  const createTimeSlot = async (
    start_time,
    end_time,
    { title, description, max_number_of_invitees, service_ids, product_ids, chat_type, recurring, recurrenceEndDate, weekdays, appointment_data }
  ) => {
    try {
      const patients = appointment_data.appointment_patient_ids.map(({ user_id }) => { return user_id });
      const clean_appointment_data = { appointment_topic_id: appointment_data.appointment_topic_id, appointment_patient_ids: patients }
      const { success, message: responseMsg } = await post(
        "/dme_portal/slots",
        {
          start_time: moment(start_time).format("L LT"),
          end_time: moment(end_time).format("L LT"),
          title,
          description,
          max_number_of_invitees,
          service_ids,
          product_ids,
          chat_type,
          recurring,
          recurrenceEndDate,
          weekdays,
          appointment_data: clean_appointment_data
        }
      );
      if (success) dispatch(message(responseMsg));
      else dispatch(errorMessage(responseMsg));
      return true
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to create time slot"
        )
      );
      return false
    }
  };

  const deleteTimeSlotById = async timeSlotId => {
    const result = await del(`/dme_portal/slot/${timeSlotId}`);
    
    if (result && result.success)
      dispatch(message(result.message));
    else if (!result.success)
      dispatch(errorMessage(result.message));

    return result.success
  };

  const editTimeSlotById = async (slot_id, start_time, end_time, { title, description, max_number_of_invitees, chat_type, appointment_data }) => {
    const patients = appointment_data.appointment_patient_ids.map(({ user_id }) => { return user_id });
    const clean_appointment_data = { appointment_topic_id: appointment_data.appointment_topic_id, appointment_patient_ids: patients }
    const body = { 
      slot_id, 
      new_start_date:  moment(start_time).format("L"), 
      new_end_date: moment(end_time).format("L"), 
      new_end_time: moment(end_time).format("LT"),
      new_start_time:  moment(start_time).format("LT"),
      title, 
      description, 
      new_max_number_of_invitees: max_number_of_invitees, 
      new_type: chat_type,
      appointment_data: clean_appointment_data
    }

    try{
      const result = await put('/dme_portal/slot', body);
      if (result && result.success)
        dispatch(message(result.message));

      return result.success
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? err.message ?? "Failed to Update Timeslot"
        )
      );
    }
  };

  return {
    userTimeSlots,
    getUserTimeSlots,
    createTimeSlot,
    editTimeSlotById,
    deleteTimeSlotById,
    pendingTimeSlot,
    setPendingTimeSlot,
    week,
    selectWeek,
    selected,
    setSelected,
    editSlot,
    setEditSlot,
    gettingTimeSlots,
    creatingOrEditingTimeSlots,
    setCreatingOrEditingTimeSlots
  };
};

export default useAvailabilityScheduler;

export const TimeSlotContext = React.createContext();
