import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { withRouter } from "react-router-dom";
import PatientsContactsTable from "./table";
import PageHeader from "components/ui/page-header";


const PatientsContacts = ({
  userRole,
}) => (
  <div>
    <PageHeader title="Patient Contacts"/>
    <PatientsContactsTable
      key={`Patient-Contacts`}
      userRole={userRole}
      queryId="patients-contacts"
      rowKey="contact_guid"
      initialQuery={{
        sort: {
          by: "date_time",
          direction: "desc"
        },
        pagination: {
          per: 15,
          page: 1
        },
      }}
      apiId="patients-contacts"
    />
  </div>
);

export default withRouter(
  connect((state) => ({
    userRole: selectors.getUserRole(state),
  }))(PatientsContacts)
);

PatientsContacts.propTypes = {
  userRole: PropTypes.string,
};
