import React, { Component } from "react";
import PropTypes from "prop-types";
import DataTable, { COLUMN_SHAPE } from "components/ui/data-table";
import every from "lodash/every";
import IoMdAddCircle from "react-icons/lib/io/plus-circled";
import IoMdRemoveCircle from "react-icons/lib/io/minus-circled";

// columnType => boolean
const isFilterable = ({ filterable }) =>
  filterable === undefined || !!filterable;

export const REG_EXP = "REG_EXP";
export const EQUALS = "EQUALS";
export const AT_MOST = "AT_MOST";
export const AT_LEAST = "AT_LEAST";

const TEST_OPS = {
  [REG_EXP]: (input, value) => {
    try {
      return RegExp(input, "i").test(value);
    } catch (e) {
      return false;
    }
  },
  [EQUALS]: (input, value) => parseFloat(input) === parseFloat(value),
  [AT_MOST]: (input, value) => parseFloat(input) >= parseFloat(value),
  [AT_LEAST]: (input, value) => parseFloat(input) <= parseFloat(value)
};

const defaultFilter = props => {
  const columnIndex = props.columns.findIndex(isFilterable);
  return {
    input: "",
    columnIndex,
    testOpKey: props.columns[columnIndex].numerical ? EQUALS : REG_EXP,
    selectActiveBoolean: ""
  };
};

class DataCustomFilterTable extends Component {
  constructor(props) {
    super(props);
    // const columnIndex = props.columns.findIndex(isFilterable);
    this.state = {
      filters: props.initialFilters || [defaultFilter(props)],
      filteredRows: []
    };
    this.handleFilterSelectActiveChange = this.handleFilterSelectActiveChange.bind(
      this
    );
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
    this.handleFilterColumnChange = this.handleFilterColumnChange.bind(this);
    this.handleTestOpChange = this.handleTestOpChange.bind(this);
    this.handleAddFilter = this.handleAddFilter.bind(this);
    this.isComplianceContacts = this.props.isComplianceContacts ?? false
  }

  handleFilterInputChange({ target: { value: input, name: index } }) {
    this.setState(({ filters }) => {
      filters = [...filters];
      filters[index] = {
        ...filters[index],
        input
      };
      return { filters };
    });
  }
  ///////////////////////////////////////////////////////////////////////
  handleFilterSelectActiveChange({
    target: { value: selectActiveBoolean, name: index }
  }) {
    this.setState(({ filters }) => {
      filters = [...filters];
      filters[index] = {
        ...filters[index],
        selectActiveBoolean
      };
      return { filters };
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////
  handleFilterColumnChange({ target: { value: columnIndex, name: index } }) {
    this.setState(({ filters }) => {
      filters = [...filters];
      filters[index] = {
        ...filters[index],
        columnIndex,
        testOpKey: this.props.columns[columnIndex].numerical ? EQUALS : REG_EXP,
        input: "",
        selectActiveBoolean: ""
      };
      return { filters };
    });
  }

  handleTestOpChange({ target: { value: testOpKey, name: index } }) {
    this.setState(({ filters }) => {
      filters = [...filters];
      filters[index] = {
        ...filters[index],
        testOpKey
      };
      return { filters };
    });
  }

  handleAddFilter() {
    this.setState(({ filters }) => {
      filters = [...filters, defaultFilter(this.props)];
      return { filters };
    });
  }

  handleRemoveFilter(filterIndex) {
    this.setState(({ filters }) => ({
      filters: [
        ...filters.slice(0, filterIndex),
        ...filters.slice(filterIndex + 1)
      ]
    }));
  }

  render() {
    const { columns, rows, initialSort, filterCombine } = this.props;
    const { filters } = this.state;

    const filteredRows = rows.filter(row =>
      filterCombine(
        filters,
        ({ input, columnIndex, testOpKey, selectActiveBoolean }) => {
          const column = columns[columnIndex];
          const test = column.filterTest || column.field;
          // field is either a function or (string) index
          const testStr = typeof test === "function" ? test(row) : row[test];
          const trimmedInput = input.trim();
          return selectActiveBoolean
            ? TEST_OPS[testOpKey](selectActiveBoolean, testStr)
            : trimmedInput
              ? TEST_OPS[testOpKey](input.trim(), testStr)
              : true;
        }
      )
    );

    return (
      <div className="ui-data-column-filter-table">
        <div className="filter-container">
          <div className="filters">
            {filters.map(
              ({ input, columnIndex, testOpKey, selectActiveBoolean }, i) => {
                const isNumerical = !!columns[columnIndex].numerical;
                const isOptionList = !!columns[columnIndex].options;
                const isBoolean = !!columns[columnIndex].boolean;
                return (
                  <div key={i} className="filter">
                    <div className="select-container">
                      <select
                        name={i}
                        value={columnIndex}
                        onChange={this.handleFilterColumnChange}
                      >
                        {columns.filter(isFilterable).map(({ header }, i) => (
                          <option key={i} value={i}>
                            {header}
                          </option>
                        ))}
                      </select>
                      {isNumerical && (
                        <select
                          name={i}
                          value={testOpKey}
                          onChange={this.handleTestOpChange}
                        >
                          <option value={EQUALS}>equals</option>
                          <option value={AT_MOST}>at most</option>
                          <option value={AT_LEAST}>at least</option>
                        </select>
                      )}
                    {isOptionList && (
                      <select
                        style={{ marginLeft: '15px' }}
                        name={i}
                        value={selectActiveBoolean}
                        onChange={this.handleFilterSelectActiveChange}
                      >
                        <option />
                        {columns[columnIndex].options.map(
                          ({ text, key, value }) => (
                            <option key={key} value={value}>
                              {text}
                            </option>
                          )
                        )}
                      </select>
                    )}
                      {isBoolean && (
                        <select
                          name={i}
                          value={selectActiveBoolean}
                          onChange={this.handleFilterSelectActiveChange}
                        >
                          <option value="">All</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      )}
                    </div>
                    {!isBoolean && !isOptionList && (
                      <input
                        name={i}
                        type="text"
                        value={input}
                        onChange={this.handleFilterInputChange}
                      />
                    )}
                    {i === 0 ? (
                      <i
                        className="mod-filter"
                        title="Add filter"
                        onClick={this.handleAddFilter}
                      >
                        <IoMdAddCircle />
                      </i>
                    ) : (
                        <i
                          className="mod-filter"
                          title="Remove filter"
                          onClick={() => this.handleRemoveFilter(i)}
                        >
                          <IoMdRemoveCircle />
                        </i>
                      )}
                  </div>
                );
              }
            )}
          </div>
        </div>
        {/* Added two props to test if they appear: onRefresh, onExport */}
        <DataTable
          onRefresh={this.props.onRefresh}
          onExport={() => this.props.onExport(filteredRows)}
          columns={columns}
          rows={filteredRows}
          initialSort={initialSort}
          isComplianceContacts={this.isComplianceContacts}
        />
      </div>
    );
  }
}

DataCustomFilterTable.propTypes = {
  ...DataTable.propTypes,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      ...COLUMN_SHAPE,
      filterTest: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      filterable: PropTypes.boolean,
      numerical: PropTypes.boolean,
      boolean: PropTypes.boolean
    })
  ).isRequired,

  filterCombine: PropTypes.func,
  passFilteredRowsToParent: PropTypes.func,
  initialFilters: PropTypes.arrayOf(
    PropTypes.shape({
      input: PropTypes.string,
      columnIndex: PropTypes.number,
      testOpKey: PropTypes.string,
      selectActiveBoolean: PropTypes.string
    })
  )
};

DataCustomFilterTable.defaultProps = {
  filterCombine: every
};

export default DataCustomFilterTable;
