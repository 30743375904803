import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { PageHeader } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { useLocation } from "react-router-dom";
import { pathOr } from "ramda";
import TasksTableControls from "./table-controls";
import useRowSelectNew, { SelectRowContext } from "hooks/useRowSelectNew";
import { useTaskTable } from "./useTable";
import TaskFilter from "./task-filter";
import TaskActionsRow from "../task-actions-row";

const TasksTable = () => {
  const location = useLocation();
  const userRole = useSelector(selectors.getUserRole);
  const initialFilters = pathOr(
    {
      task_status: "pending_tasks",
      owner: ["SleepCoach"].includes(userRole)
        ? ["S3"]
        : ["OfficeAdministrator", "CompanyAdministrator"].includes(userRole)
        ? ["DME"]
        : undefined,
    },
    ["state", "filters"],
    location
  );
  const initialQuery = {
    sort: {},
    filters: initialFilters,
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("tasks/patients", initialQuery, "tasks");
  rows.map((item) => {
    let latestUpdatedAtTask = item.tasks.reduce((latestTask, currentTask) => {
        // Parse the dates and handle null values
        const latestUpdatedAt = latestTask ? new Date(latestTask.updated_at) : null;
        const currentUpdatedAt = currentTask.updated_at ? new Date(currentTask.updated_at) : null;

        // Compare the dates and update 'latestTask' if needed
        if (!latestUpdatedAt || (currentUpdatedAt && currentUpdatedAt > latestUpdatedAt)) {
            return currentTask;
        } else {
            return latestTask;
        }
    }, null);
    let latestUpdatedAt = latestUpdatedAtTask ? latestUpdatedAtTask.updated_at : null;
    item.last_updated_at = latestUpdatedAt && item.patient_updated_at ?
                      (latestUpdatedAt > item.patient_updated_at ? latestUpdatedAt: item.patient_updated_at):
                      (latestUpdatedAt ?
                        latestUpdatedAt :
                        (item.patient_updated_at ? item.patient_updated_at : null)
                      );
  });
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelectNew(rows, "patient_id", query, row => ({
    ...row
  }));
  const {
    tableColumns,
    tableRows,
    tableSettings,
    resetTableSettings,
    updateTableSettings
  } = useTaskTable({ query, queryDispatcher });
  return (
    <div className="tasks-page">
      <PageHeader title="Patient Tasks" />
      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <Table
          columns={tableColumns}
          rows={rows}
          query={query}
          data={data}
          queryDispatcher={queryDispatcher}
          isFetching={isFetching}
          containerClass="query-table tasks-order-table dynamic-columns"
          TableRow={row => (
            <>
              <tr
                className={`task-row ${row.index % 2 === 0 ? "even" : "odd"}`}
              >
                {tableRows.map((Row, key) => (
                  <Row {...row} key={key} />
                ))}
              </tr>
              <TaskActionsRow {...row} />
            </>
          )}
          rowKey="patient_id"
          perPageOptions={[
            { text: "50", value: 50 },
            { text: "150", value: 150 },
            { text: "500", value: 500 }
          ]}
          Controls={
            <TasksTableControls
              rows={rows}
              query={query}
              selectedRows={selectedRows}
              clearSelectedRows={clearSelectedRows}
              isFetching={isFetching}
              refreshData={() => {
                fetchData(query);
              }}
              tableSettings={tableSettings}
              resetTableSettings={resetTableSettings}
              updateTableSettings={updateTableSettings}
            />
          }
        >
          <TaskFilter query={query} queryDispatcher={queryDispatcher} />
        </Table>
      </SelectRowContext.Provider>
    </div>
  );
};

export default TasksTable;
