import keyBy from "lodash/keyBy"
// import mergeAll from 'lodash/fp/mergeAll'

export const entityTransform = (entityType, key = "id") => response => ({
  entities: {
    [entityType]: {
      [response[key]]: response
    }
  }
})

export const entitiesTransform = (
  entityType,
  responseKey = entityType,
  key = "id"
) => response => ({
  entities: {
    [entityType]: keyBy(response[responseKey], key)
  }
})

// deprecated mothods
// export const tableTransform = (
//   tableType,
//   responseKey = "data"
// ) => response => ({
//   tablePagination: {
//     [tableType]: response[responseKey]
//   }
// })
//
// export const combineTransforms = fns => response => mergeAll(
//   fns.map(f => f(response))
// )
