import { useState, useEffect, useCallback } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import * as R from "ramda";
export { default as TableSettings } from "./components";
export { default as cleanQuery } from "./clean-query";

// refactored to simplify and make table setup easier to manage
export { default as useTableSettingsNew } from "./useTableSettingsNew";
export { default as TableSettingsNew } from "./componentsNew";

const getVisibleColumns = R.pipe(
  R.values,
  R.filter(R.prop("active")),
  R.map(R.prop("field"))
);

const useTableSettings = (table, columns = []) => {
  const userId = useSelector(selectors.getUserId);
  const [tableSettings, setTableSettings] = useLocalStorage(
    `${table}_${userId}`,
    R.indexBy(
      R.prop("field"),
      columns.map(c => ({ ...c, active: R.propOr(true, "active", c) }))
    )
  );
  const [visibleColumns, setVisibleColumns] = useState(
    getVisibleColumns(tableSettings)
  );
  const updateTableSettings = newSettings => {
    setTableSettings(newSettings);
  };
  const resetTableSettings = () => {
    setTableSettings(
      R.indexBy(
        R.prop("field"),
        columns.map(c => ({ ...c, active: R.propOr(true, "active", c) }))
      )
    );
  };
  const isColumnVisible = useCallback(
    ({ field }) => R.pathOr(true, [field, "active"], tableSettings),
    [tableSettings]
  );

  useEffect(() => {
    setVisibleColumns(getVisibleColumns(tableSettings));
  }, [tableSettings]);

  return {
    isColumnVisible,
    updateTableSettings,
    resetTableSettings,
    columns,
    tableSettings,
    visibleColumns
  };
};

export default useTableSettings;
