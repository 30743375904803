import React, { useContext } from "react";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InsuranceCompanyContext } from "./insurance-company-provider";
import { selectors } from "reducers";
import { connect } from "react-redux";
import { Waiting } from "components/ui";
import PropTypes from "prop-types";

const BulkUpdateInsurance = ({ insuranceOptions, closeModal }) => {
  const {
    insurance,
    hcpcsList,
    SubmitBulkUpdateForm,
    isFetchingBulkUpdateList,
    formSubmit,
    isFetchingBulkSubmit
  } = useContext(InsuranceCompanyContext);

  const validateNumber = e => {
    let error;
    if (isNaN(e)) {
      error = "Must be a number";
    } else if (!e) {
      error = "Required";
    } else if (Math.sign(e) != 1) {
      error = "Must be a positive number";
    } else if (e % 1 != 0) {
      error = "Must be a whole number";
    }
    return error;
  };
  const validateRequired = e => {
    let error;
    if (e === "") {
      error = "Required";
    }
    return error;
  };
  const handleSubmit = values => {
    SubmitBulkUpdateForm(values).then(() => {
      if (formSubmit) {
        closeModal();
      }
    });
  };

  return (
    insurance && (
      <Formik
        initialValues={hcpcsList}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {() => (
          <Form style={{ width: "100%", height: "100%" }}>
            <div id="bulk-update-insurance-table">
              <div id="container">
                <table>
                  <thead>
                    <tr style={{ width: "500%" }}>
                      <th
                        colSpan="1"
                        style={{
                          fontSize: "2em",
                          textAlign: "left",
                          paddingLeft: "1%",
                          height: "30%",
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        <div className="bulkUpdateHeader">Bulk Update</div>
                        {isFetchingBulkSubmit ? (
                          <Waiting size={"20px"} />
                        ) : (
                          <button className="modalButton" type="submit">
                            Submit
                          </button>
                        )}
                      </th>
                      <td colSpan="44"></td>
                    </tr>
                    <tr>
                      <th colSpan="1"></th>
                      <td colSpan="4">Generic</td>
                      <td colSpan="15" scope="colgroup">
                        Frequency (Days)
                      </td>
                      <td colSpan="15" scope="colgroup">
                        Quantity
                      </td>
                      <td colSpan="4" scope="colgroup">
                        Flags
                      </td>
                      <td colSpan="3" scope="colgroup">
                        IVR Flags
                      </td>
                    </tr>
                    <tr id="test">
                      <th>Name (Not Editable)</th>
                      <th>Payor ID</th>
                      <th>Default Frequency</th>
                      <th>Insurance Plan Type</th>
                      <th>Is Active</th>
                      <th>A7027</th>
                      <th>A7028</th>
                      <th>A7029</th>
                      <th>A7030</th>
                      <th>A7031</th>
                      <th>A7032</th>
                      <th>A7033</th>
                      <th>A7034</th>
                      <th>A7035</th>
                      <th>A7036</th>
                      <th>A7037</th>
                      <th>A7038</th>
                      <th>A7039</th>
                      <th>A7046</th>
                      <th>A4604</th>
                      <th>A7027</th>
                      <th>A7028</th>
                      <th>A7029</th>
                      <th>A7030</th>
                      <th>A7031</th>
                      <th>A7032</th>
                      <th>A7033</th>
                      <th>A7034</th>
                      <th>A7035</th>
                      <th>A7036</th>
                      <th>A7037</th>
                      <th>A7038</th>
                      <th>A7039</th>
                      <th>A7046</th>
                      <th>A4604</th>
                      <th>Allows 90 Day Supply</th>
                      <th>Counts Mask As Seal</th>
                      <th>Excluded</th>
                      <th>CMN Required</th>
                      <th>Requires Dysfunctions Questions</th>
                      <th>IVR Offers Autoship</th>
                      <th>IVR Education Statement</th>
                    </tr>
                  </thead>
                  <tbody id="body">
                    {isFetchingBulkUpdateList ? (
                      <Waiting size={50} />
                    ) : (
                      Object.values(hcpcsList).map(hcpcsInfo => {
                        return (
                          <tr key={hcpcsInfo.guid}>
                            <th
                              style={{
                                fontWeight: "bolder",
                                fontSize: "small"
                              }}
                            >
                              {hcpcsInfo.nameNoPrefix}
                            </th>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.sPayorID`}
                                type="input"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.iAllowableDuration`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              {" "}
                              <FormikField
                                name={`${hcpcsInfo.guid}.typeGuid`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                {insuranceOptions.map(io => {
                                  return (
                                    <option key={io.value} value={io.value}>
                                      {io.text}
                                    </option>
                                  );
                                })}
                              </FormikField>
                            </td>
                            <td>
                              {" "}
                              <FormikField
                                name={`${hcpcsInfo.guid}.bActive`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Active
                                </option>
                                <option key={false} value={false}>
                                  Not Active
                                </option>
                              </FormikField>
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7027.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7028.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7029.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7030.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7031.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7032.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7033.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7034.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7035.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7036.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7037.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7038.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7039.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7046.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A4604.frequency`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7027.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7028.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7029.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7030.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7031.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7032.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7033.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7034.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7035.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7036.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7037.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7038.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7039.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A7046.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.A4604.quantity`}
                                type="number"
                                validate={validateNumber}
                                className="no-arrows"
                              />
                            </td>
                            <td>
                              {" "}
                              <FormikField
                                name={`${hcpcsInfo.guid}.bAllowsNinetyDaySupply`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Yes
                                </option>
                                <option key={false} value={false}>
                                  No
                                </option>
                              </FormikField>
                            </td>
                            <td>
                              {" "}
                              <FormikField
                                name={`${hcpcsInfo.guid}.bCountsMaskAsSeal`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Yes
                                </option>
                                <option key={false} value={false}>
                                  No
                                </option>
                              </FormikField>
                            </td>

                            <td>
                              {" "}
                              <FormikField
                                name={`${hcpcsInfo.guid}.bExcluded`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Yes
                                </option>
                                <option key={false} value={false}>
                                  No
                                </option>
                              </FormikField>
                            </td>
                            <td>
                              {" "}
                              <FormikField
                                name={`${hcpcsInfo.guid}.bCMNRequired`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Yes
                                </option>
                                <option key={false} value={false}>
                                  No
                                </option>
                              </FormikField>
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.bAsksDysfunctionsQuestions`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Yes
                                </option>
                                <option key={false} value={false}>
                                  No
                                </option>
                              </FormikField>
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.bIVRAutoShip`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Yes
                                </option>
                                <option key={false} value={false}>
                                  No
                                </option>
                              </FormikField>
                            </td>
                            <td>
                              <FormikField
                                name={`${hcpcsInfo.guid}.bIVREducationStatement`}
                                component="select"
                                validate={validateRequired}
                              >
                                <option key={0} />
                                <option key={true} value={true}>
                                  Yes
                                </option>
                                <option key={false} value={false}>
                                  No
                                </option>
                              </FormikField>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  );
};

export default connect(state => ({
  insuranceOptions: selectors.getFormOptionsInsurance(state)
}))(BulkUpdateInsurance);

BulkUpdateInsurance.propTypes = {
  insuranceOptions: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func
};
