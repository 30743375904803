export default function getDialerLock(promise) {
  if (!window.navigator.locks) {
    console.warn("Web Lock API is not available");
    return new Promise(resolve => {
      resolve({});
      return promise;
    });
  }
  const controller = new AbortController();
  const lockOptions = {
    signal: controller.signal
  };
  return new Promise((resolve, reject) => {
    try {
      // If the lock isn't acquired within one second, then abort it
      setTimeout(() => {
        controller.abort();
        reject(
          "Couldn't grant access to AutoDialer, is it already running in another tab/window?"
        );
      }, 1000);
      navigator.locks.request("auto-dialer", lockOptions, lock => {
        resolve(lock);
        return promise;
      });
    } catch (error) {
      reject(error);
    }
  });
}
