import React from "react";
import PropTypes from "prop-types";
import MdSend from "react-icons/lib/md/send";

const SendMsgInput = ({ onMessageSend, value }) => {
  const [inputValue, setInputValue] = React.useState("");
  function sendMessage() {
    if (inputValue.length > 0) onMessageSend(inputValue);
    setInputValue("");
  }

  React.useEffect(() => {
    setInputValue(value);
  }, [value])

  return (
    <form
      className="send-msg-container"
      onSubmit={event => {
        event.preventDefault();
        sendMessage();
      }}
    >
      <input
        type="text"
        className="send-msg-input"
        autoFocus
        placeholder="Enter your message..."
        onChange={({ target }) => setInputValue(target.value)}
        value={inputValue}
      />
      <span className="send-msg-icon" onClick={sendMessage}>
        <MdSend />
      </span>
    </form>
  );
};

export default SendMsgInput;

SendMsgInput.propTypes = {
  onMessageSend: PropTypes.func.isRequired,
  value: PropTypes.string
};
