import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { initiateSendOrderToNikoHealth } from "actions/order-profile";
import MdSend from "react-icons/lib/md/send";

const SendOrderToNHButton = ({
  orderId,
  userId,
  initiateSendOrderToNikoHealth
}) => {
const [ disabled, setDisabled ] = useState(false);

return  (
      <span
        className={`copy-to-clipboard-button print-none`}
        title={disabled ? 'Sending order to Niko Health' : 'Send order to Niko Health'}
      >
      <MdSend
        color="grey"
        size="28px"
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (userId !== "" && orderId !== "") {
            setDisabled(true);
            initiateSendOrderToNikoHealth(userId, orderId).then(() => {
              setDisabled(false);
            });
          } else {
            window.alert("Please wait for order to finish loading.");
          }
        }}
      />
    </span>
  );
};

SendOrderToNHButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  initiateSendOrderToNikoHealth: PropTypes.func.isRequired
};

export default connect(
  state => ({ userId: selectors.getUserId(state), }),
  {
    initiateSendOrderToNikoHealth,
  }
)(SendOrderToNHButton);