import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { transfer, setTransferNumber, cancelTransfer } from "actions/phone";
import MdPhoneForwarded from "react-icons/lib/md/phone-forwarded";
import MdClose from "react-icons/lib/md/close";
import TransferInput from "./transfer-input";

const TransferControls = ({
  isConnected,
  isCallInConference /* TODO broken ? */,
  transferNumber,
  setTransferNumber,
  transfer,
  cancelTransfer
}) => {
  return (
    <div className="transfer-controls">
      <React.Fragment>
        <TransferInput
          transferNumber={transferNumber}
          setTransferNumber={setTransferNumber}
        />
        <button
          className="transfer-btn transfer-button"
          disabled={!transferNumber || !isConnected}
          onClick={() => {
            transfer({ number: transferNumber });
          }}
          title={
            isCallInConference
              ? "In Conference Call Now For A Warm Transfer"
              : isConnected
              ? "Normal Call"
              : "Transfer Call"
          }
        >
          <MdPhoneForwarded size="1.5em" />
        </button>
      </React.Fragment>
      <button
        className="transfer-btn cancel-transfer-button"
        title="Hangup Transfer Leg"
        onClick={cancelTransfer}
        disabled={!isConnected}
      >
        <MdClose size="1.5em" />
      </button>
    </div>
  );
};

export default connect(
  state => ({
    isConnected: selectors.getIsPhoneConnected(state),
    transferNumber: selectors.getTransferNumber(state)
  }),
  {
    transfer,
    setTransferNumber,
    cancelTransfer
  }
)(TransferControls);

TransferControls.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  isCallInConference: PropTypes.bool,
  transfer: PropTypes.func.isRequired,
  cancelTransfer: PropTypes.func.isRequired,
  transferNumber: PropTypes.string.isRequired,
  setTransferNumber: PropTypes.func.isRequired
};
