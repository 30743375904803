import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import MultiSelectDropdown from "components/forms/multi-select-dropdown";

const initialValues = { clients: [], companies: [], teams: [], phone: "" };
const TwilioLookupInput = ({ input, sleepCoaches, companies, teams }) => {
  const { value, onChange } = input;
  const [mode, setMode] = useState(value.mode || "clients");
  return (
    <span className="to-input-field-container">
      <select
        value={mode}
        onChange={({ target }) => {
          setMode(target.value);
          onChange({ [target.value]: initialValues[target.value] });
        }}
      >
        <option value="clients">Client</option>
        <option value="companies">Company</option>
        <option value="teams">Team</option>
        <option value="phone">Phone #</option>
      </select>
      {mode === "clients" && (
        <MultiSelectDropdown
          options={sleepCoaches}
          values={input.value.clients}
          onChange={sc => onChange({ clients: sc })}
          renderValues={false}
        />
      )}
      {mode === "companies" && (
        <MultiSelectDropdown
          options={companies}
          values={input.value.companies}
          onChange={c => onChange({ companies: c })}
          renderValues={false}
        />
      )}
      {mode === "teams" && (
        <MultiSelectDropdown
          options={teams}
          values={input.value.teams}
          onChange={t => onChange({ teams: t })}
          renderValues={false}
        />
      )}
      {mode === "phone" && (
        <input
          value={input.value.phone || ""}
          type="tel"
          onChange={e => {
            onChange({ phone: e.target.value });
          }}
        />
      )}
    </span>
  );
};

TwilioLookupInput.propTypes = {
  input: PropTypes.object,
  sleepCoaches: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })
  ),
  companies: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })
  ),
  teams: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })
  )
};

export default connect(state => ({
  sleepCoaches: selectors.getFormOptionsSleepCoach(state),
  companies: selectors.getFormOptionsCompany(state),
  teams: selectors.getTeamNameOptions(state)
}))(TwilioLookupInput);
