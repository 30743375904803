import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { selectors } from "reducers";
import { withModal, StatusToggle, IconButton, Spinner } from "components/ui";
import moment from "moment";
import SectionHeader from "./section_header.jsx";
import { Ul, AttributeItem } from "../lists";
import DatePicker from "../ui/date-picker";
import { updatePatient } from "actions/patient";
import compose from "lodash/fp/compose";
import DeactivatePatient from "./modals/deactivate-patient";
import IoMdMinus from "react-icons/lib/io/minus";
import { MdEdit, MdHelp } from "react-icons/lib/md/index.js";
import CancelIcon from "react-icons/lib/md/cancel";
import SaveIcon from "react-icons/lib/md/save";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import { errorMessage } from "actions/message.js";
import HasAppPermission from "components/shared/has-app-permission";
import PhilipResetToggle from "./philips-recall/reset-toggle.jsx";
// import { HasRolePermission } from "components/shared/has-role-permission.jsx";
import HasRolePermission from "components/shared/has-role-permission";

const style = {
  icon: {
    color: "#AAA",
    cursor: "pointer",
    marginLeft: 5,
    display: "inline-block",
    float: "right",
    width: 15
  },
  wait: {
    cursor: "wait"
  },
  clear: {
    marginRight: 10
  },
  datePicker: {
    marginLeft: 5,
    display: "inline-block",
    float: "right"
  }
};

function OtherDetails({
  patientInfo,
  patientId,
  inactivationReasons,
  rotechInactivationReasons,
  openModal,
  closeModal,
  isUpdating,
  updatePatient,
  readOnly
}) {
  const stages = { resupply: 1, compliance: 2 };

  const patientIsRotech =
    patientInfo?.company_name === "Sleep Central a Rotech Healthcare Company";

  const dispatch = useDispatch();

  const [stageIsEditable, setStageIsEditable] = useState(false);

  const [patientStage, setPatientStage] = useState();

  const [updatingStage, setUpdatingStage] = useState(false);

  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingDelayedActivation, setUpdatingDelayedActivation] =
    useState(false);
  const [updatingHoldStatus, setUpdatingHoldStatus] = useState(false);
  const [updatingNewSetup, setUpdatingNewSetup] = useState(false);
  const [updatingOffshoreCalls, setUpdatingOffshoreCalls] = useState(false);
  const [updatingRemoteSetup, setUpdatingRemoteSetup] = useState(false);

  const userRole = useSelector(selectors.getUserRole);

  useEffect(() => {
    if (patientInfo.stage_id) {
      setPatientStage(patientInfo.stage_id);
    }
  }, [patientInfo]);

  async function handleDayChange(field, event) {
    setUpdatingDelayedActivation(true);
    await updatePatient({
      patientId,
      updates: { [field]: event ? moment(event).format("YYYY-MM-DD") : "" }
    });
    setUpdatingDelayedActivation(false);
  }

  async function toggleStatus(field) {
    if (field == "active") {
      setUpdatingStatus(true);
    } else if (field == "on_hold") {
      setUpdatingHoldStatus(true);
    } else if (field == "new_setup") {
      setUpdatingNewSetup(true);
    } else if (field == "remote_setup") {
      setUpdatingRemoteSetup(true);
    } else if (field == "disallow_offshore_calls") {
      setUpdatingOffshoreCalls(true);
    }

    await updatePatient({
      patientId,
      updates: { [field]: !patientInfo[field] }
    });
    setUpdatingStatus(false);
    setUpdatingHoldStatus(false);
    setUpdatingNewSetup(false);
    setUpdatingOffshoreCalls(false);
    setUpdatingRemoteSetup(false);
  }

  async function changeStage(value) {
    if (!value) {
      dispatch(errorMessage("To save, you must select a Stage"));
      return;
    }
    setUpdatingStage(true);
    const successfulResponse = await updatePatient({
      patientId,
      updates: { ["stage"]: Number(value) }
    });
    if (successfulResponse && !isUpdating) {
      setStageIsEditable(false);
    }
    setUpdatingStage(false);
  }

  async function deactivatePatient(inactiveReasonId) {
    setUpdatingStatus(true);
    await updatePatient({
      patientId,
      updates: { active: false, inactiveReasonId }
    });
    setUpdatingStatus(false);
  }

  return (
    <div style={{ paddingBottom: "40px" }}>
      <SectionHeader>Settings</SectionHeader>
      <Ul>
        <AttributeItem title="Status:">
          {patientInfo.active ? "Active" : "Inactive"}
          {patientInfo.active ? (
            <span>
              <i
                style={style.icon}
                onClick={() =>
                  !readOnly &&
                  openModal(
                    <DeactivatePatient
                      patientId={patientId}
                      deactivatePatient={deactivatePatient}
                      inactivationReasons={
                        patientIsRotech
                          ? rotechInactivationReasons
                          : inactivationReasons
                      }
                      toggleStatus={() => toggleStatus("active")}
                      closeSelf={closeModal}
                    />
                  )
                }
              >
                <IoMdMinus />
              </i>
            </span>
          ) : updatingStatus ? (
            <div className="settings-spinner-container">
              <Spinner />
            </div>
          ) : (
            <StatusToggle
              value={patientInfo.active}
              toggleStatus={() => toggleStatus("active")}
              disabled={readOnly}
            />
          )}
        </AttributeItem>
        <AttributeItem
          title="Stage:"
          style={{
            display: "flex",
            justifyContent: patientInfo?.stage_id ? "space-between" : "end",
            alignItems: "center"
          }}
        >
          {stageIsEditable ? (
            <select
              disabled={updatingStage}
              value={patientStage}
              onChange={e => setPatientStage(e.target.value)}
              className="stage-select"
            >
              {patientInfo.stage_id == null ? (
                <option value="">Choose a stage</option>
              ) : null}
              {patientInfo.CompanyManagesResupply ? (
                <option value={stages.resupply}>Resupply</option>
              ) : null}
              {patientInfo.CompanyManagesCompliance ? (
                <option value={stages.compliance}>Compliance</option>
              ) : null}
            </select>
          ) : patientInfo.stage_id == stages.resupply ? (
            "Resupply"
          ) : patientInfo.stage_id == stages.compliance ? (
            "Compliance"
          ) : (
            ""
          )}
          {stageIsEditable ? (
            <div style={{ display: "flex", position: "relative" }}>
              {updatingStage ? (
                <div className="stage-spinner-container">
                  <Spinner />
                </div>
              ) : null}
              <IconButton
                onClick={() => {
                  setStageIsEditable(false);
                  setPatientStage(patientInfo.stage_id);
                }}
                text="Cancel"
                style={{ color: "gray" }}
                icon={<CancelIcon />}
                type="button"
              />
              <IconButton
                text="Save"
                style={{ color: "green" }}
                icon={<SaveIcon />}
                onClick={() => changeStage(patientStage)}
              />
            </div>
          ) : patientInfo?.stage_id == stages.resupply &&
            userRole != "Administrator" &&
            userRole != "CompanyAdministrator" ? (
            <div>
              <Tooltip
                id={`single-stat-tooltip-a`}
                className="single-stat-tooltip"
                effect="solid"
                message={
                  "Only Administrators can change Stage from Resupply to Compliance"
                }
              >
                <MdHelp style={{ fontSize: "15px" }} />
              </Tooltip>
            </div>
          ) : (
            <IconButton
              icon={<MdEdit />}
              text="Edit"
              style={{ fontSize: "12px" }}
              disabled={false}
              onClick={() => {
                setStageIsEditable(true);
              }}
            />
          )}
        </AttributeItem>
        <HasAppPermission permission="orderSupplies">
          <AttributeItem
            title="Delayed activation:"
            actions={
              updatingDelayedActivation ? (
                <Spinner />
              ) : (
                <DatePicker
                  disabledDays={{ before: moment().toDate() }}
                  css={style.datePicker}
                  disabled={readOnly}
                  showOutsideDays
                  onChange={e => handleDayChange("delayed_activation_date", e)}
                />
              )
            }
          >
            {patientInfo.delayed_activation_date
              ? moment(patientInfo.delayed_activation_date).format("ll")
              : "N/A"}
          </AttributeItem>
          <AttributeItem title="Remote setup:">
            {patientInfo.remote_setup ? "yes" : "no"}
            {updatingRemoteSetup ? (
              <div className="settings-spinner-container">
                <Spinner />
              </div>
            ) : (
              <StatusToggle
                value={patientInfo.remote_setup}
                isUpdating={isUpdating}
                toggleStatus={() => toggleStatus("remote_setup")}
                disabled={readOnly}
              />
            )}
          </AttributeItem>
          <AttributeItem title="Hold status:">
            {patientInfo.on_hold ? "On hold" : "Not on hold"}
            {updatingHoldStatus ? (
              <div className="settings-spinner-container">
                <Spinner />
              </div>
            ) : (
              <StatusToggle
                value={patientInfo.on_hold}
                isUpdating={isUpdating}
                toggleStatus={() => toggleStatus("on_hold")}
                disabled={readOnly}
              />
            )}
          </AttributeItem>
          <AttributeItem title="New setup:">
            {patientInfo.new_setup ? "yes" : "no"}
            {updatingNewSetup ? (
              <div className="settings-spinner-container">
                <Spinner />
              </div>
            ) : (
              <StatusToggle
                value={patientInfo.new_setup}
                isUpdating={isUpdating}
                toggleStatus={() => toggleStatus("new_setup")}
                disabled={readOnly}
              />
            )}
          </AttributeItem>

          <HasRolePermission allowedRoles={["Administrator"]}>
            <AttributeItem title="Offshore Limited:">
              {patientInfo.disallow_offshore_calls ? "yes" : "no"}
              {updatingOffshoreCalls ? (
                <div className="settings-spinner-container">
                  <Spinner />
                </div>
              ) : (
                <StatusToggle
                  value={patientInfo.disallow_offshore_calls}
                  isUpdating={isUpdating}
                  toggleStatus={() => toggleStatus("disallow_offshore_calls")}
                  disabled={readOnly}
                />
              )}
            </AttributeItem>
          </HasRolePermission>
        </HasAppPermission>
        {patientInfo.opt_in_phillips_recall && (
          <HasRolePermission allowedRoles={["Administrator"]}>
            <PhilipResetToggle
              patientInfo={patientInfo}
              patientId={patientId}
              readOnly={readOnly}
            />
          </HasRolePermission>
        )}
        {patientIsRotech && (
          <AttributeItem title="30 Day Payor:">
            {patientInfo.thirty_day_payor ? "Yes" : "No"}
          </AttributeItem>
        )}
      </Ul>
    </div>
  );
}

OtherDetails.propTypes = {
  inactivationReasons: PropTypes.array,
  rotechInactivationReasons: PropTypes.array,
  isUpdating: PropTypes.bool,
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  updatePatient: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

OtherDetails.defaultProps = {
  readOnly: false
};

export default compose(
  withModal,
  connect(
    (state, { patientId }) => ({
      patientInfo: selectors.getPatientInfo(state, patientId),
      isUpdating: state.getIn([
        "fetch",
        "UPDATE_PATIENT",
        patientId,
        "isFetching"
      ]),
      inactivationReasons: selectors.getEnumInactivationReasons(state),
      rotechInactivationReasons:
        selectors.getEnumRotechInactivationReasons(state)
    }),
    { updatePatient }
  )
)(OtherDetails);
