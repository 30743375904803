import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { PureModal, IconButton, ToggleSwitch } from "components/ui";
import { pathOr } from "ramda";
import { updateStatusQuestionnaire } from "components/dd_newModules/Redux/Actions/questionnaire";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import QuestionnaireForm from "./questionnaire-form";
import { browserHistory } from "browser-history";
import MdEdit from "react-icons/lib/md/edit";
import {
  createQuestionnaire,
  updateQuestionnaire
} from "actions/questionnaire";
import "./styles.scss";

const ManageQuestionnaires = ({ questionnaires, createQuestionnaire }) => {
  const { rows, query, queryDispatcher } = useLocalQuery(questionnaires, {
    sort: { by: "created_at", direction: "desc" }
  });
  return (
    <div>
      <Table
        containerClass="query-table manage-questionnaire-table dynamic-columns"
        TableRow={QuestionnaireTableRow}
        columns={[
          {
            header: "Questionnaire",
            field: "title"
          },
          {
            header: "Type",
            field: "questionnaire_type",
            filters: [
              { text: "Service", value: "1" },
              { text: "Product", value: "2" }
            ]
          },
          {
            header: "Product/Service Name",
            field: "product_name"
          },
          {
            header: "Questions",
            field: "questions"
          },
          {
            header: "Active",
            field: "active",
            filters: [
              { text: "Yes", value: true },
              { text: "No", value: false }
            ]
          },
          {
            header: "",
            field: ""
          }
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="questionnaire_id"
      />
      <PureModal
        renderTrigger={({ openModal }) => (
          <button
            type="button"
            onClick={openModal}
            style={{
              margin: "10px 0px",
              marginTop: 25,
              radius: 3,
              borderRadius: 3,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              display: "flex"
            }}
          >
            Create New Questionnaire
          </button>
        )}
        windowProps={{ large: true }}
        renderContent={({ closeModal }) => (
          <QuestionnaireForm
            handleSubmit={async values => {
              const success = await createQuestionnaire(values);
              if (success) closeModal();
            }}
          />
        )}
      />
    </div>
  );
};

ManageQuestionnaires.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionnaires: PropTypes.arrayOf(PropTypes.object).isRequired,
  createQuestionnaire: PropTypes.func.isRequired,
  updateStatusQuestionnaire: PropTypes.func.isRequired,
  updateQuestionnaire: PropTypes.func.isRequired
};

export default connect(
  state => ({
    services: pathOr(
      [],
      ["rdc_services", "Services"],
      state.getIn(["newRootReducer"])
    ),
    questionnaires: pathOr(
      [],
      ["rdc_questionnaire", "Questionnaire"],
      state.getIn(["newRootReducer"])
    )
  }),
  {
    createQuestionnaire,
    // deleteQuestionnaire,
    updateQuestionnaire,
    updateStatusQuestionnaire
  }
)(ManageQuestionnaires);

const QuestionnaireTableRow = ({
  questionnaire_id,
  title,
  questionnaire_type,
  product_name,
  service_name,
  questions,
  active,
  service_id,
  product_id,
  description
}) => {
  const dispatch = useDispatch();
  return (
    <tr>
      <td
        onClick={() => {
          browserHistory.push(`/questionnaires/manage/${questionnaire_id}`);
        }}
      >
        {title}
      </td>
      <td>{questionnaire_type == 2 ? "Product" : "Service"}</td>
      <td>{questionnaire_type == 2 ? product_name : service_name}</td>
      <td>{questions.length}</td>
      <td>
        <ToggleSwitch
          value={active}
          onChange={checked =>
            dispatch(
              updateStatusQuestionnaire(
                {
                  service_id,
                  product_id,
                  questionnaire_id,
                  description,
                  title,
                  questions,
                  questionnaire_type
                },
                checked
              )
            )
          }
        />
      </td>
      <td>
        <PureModal
          renderTrigger={({ openModal }) => (
            <IconButton onClick={openModal} text="Edit" icon={<MdEdit />} />
          )}
          windowProps={{ large: true }}
          renderContent={({ closeModal }) => (
            <QuestionnaireForm
              initialValues={{
                questionnaire_id,
                title,
                questionnaire_type,
                service_id,
                description,
                product_id,
                product_name,
                service_name,
                questions,
                active
              }}
              handleSubmit={async values => {
                const success = await dispatch(updateQuestionnaire(values));
                if (success) closeModal();
              }}
            />
          )}
        />
      </td>
    </tr>
  );
};

QuestionnaireTableRow.propTypes = {
  questionnaire_id: PropTypes.string.isRequired,
  title: PropTypes.string,
  questionnaire_type: PropTypes.string,
  product_name: PropTypes.string,
  service_name: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  active: PropTypes.bool,
  service_id: PropTypes.string,
  product_id: PropTypes.string,
  description: PropTypes.string
};
