import { GET_PATIENTS, GET_PATIENTS_QUESTIONNAIRE, GET_QUESTIONNAIRE_DETAILS, GET_PATIENTS_INSURANCE } from './../ActionTypes';
import API from "./../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage, message } from "./../../../../actions/message";
import moment from 'moment';

export const getPatients = (name, email, date_of_birth) => dispatch => {
    dispatch(showLoader());
    if (name.length === 0 && email.length === 0 && date_of_birth.length === 0) {
        dispatch(getPatientQuestionnaire(''));
        dispatch(hideLoader());
        return dispatch({
            type: GET_PATIENTS,
            payload: []
        });
    } else {
        const bodyParam = {
            "name": name,
            "date_of_birth": date_of_birth,
            "account_number": "",
            "email": email,
            "primary_phone_number": "",
            "secondary_phone_number": ""
        };
        const url = "dme_portal/search_patients";
        API().put(url, bodyParam).then(res => {
            if (res && res.data && res.data.success) {
                if (res.data.patients && res.data.patients.length > 0) {
                    dispatch(getPatientQuestionnaire(res.data.patients[0].user_id));
                    const filterObj = res.data.patients.map((data) => {
                        let combined = data.patient_name;
                        if (data.email) {
                            combined = combined + " | " + data.email;
                        }

                        if (data.date_of_birth) {
                            combined = combined + " | " + moment(data.date_of_birth).format('MM/DD/YYYY');
                        }

                        return { text: combined, value: data.user_id , label : combined};
                    });
                    dispatch(hideLoader());
                    return dispatch({
                        type: GET_PATIENTS,
                        payload: filterObj
                    });
                }else{
                    dispatch(getPatientQuestionnaire(''));
                    dispatch(hideLoader());
                    dispatch(errorMessage(res.data.message));
                    return dispatch({
                        type: GET_PATIENTS,
                        payload: []
                    });
                }
            } else {
                dispatch(getPatientQuestionnaire(''));
                dispatch(hideLoader());
                dispatch(errorMessage(res.data.message));
                return dispatch({
                    type: GET_PATIENTS,
                    payload: []
                });
            }
        }).catch(err => {
            console.log('error', err.response);
            const responseErrorMessage =  err.response.data.message;
            dispatch(getPatientQuestionnaire(''));
            dispatch(hideLoader());
            dispatch(errorMessage(responseErrorMessage));
            return dispatch({
                type: GET_PATIENTS,
                payload: []
            });
        });
    }
}

export const getMultiplePatients = (name, email, date_of_birth) => async dispatch => {
    if (name.length === 0 && email.length === 0 && date_of_birth.length === 0) {
        return dispatch({
            type: GET_PATIENTS,
            payload: []
        });
    } else {
        const bodyParam = {
            "name": name,
            "date_of_birth": date_of_birth,
            "account_number": "",
            "email": email,
            "primary_phone_number": "",
            "secondary_phone_number": ""
        };
        const url = "dme_portal/search_patients";
        await API().put(url, bodyParam).then(res => {
            if (res && res.data && res.data.success) {
                if (res.data.patients && res.data.patients.length > 0) {
                    const filterObj = res.data.patients.map((data) => {
                        let combined = data.patient_name;
                        if (data.email) {
                            combined = combined + " | " + data.email;
                        }

                        if (data.date_of_birth) {
                            combined = combined + " | " + moment(data.date_of_birth).format('MM/DD/YYYY');
                        }

                        return { text: combined, value: data.user_id , label : combined};
                    });

                    let finalList = [];
                    let custommessage = "";
                    filterObj.map((data) => {
                        const isFound = finalList.filter(m => m.value === data.value);
                        if(isFound && isFound.length === 0)
                        {
                            custommessage = "Search result found, Patient list updated.";
                            finalList.push(data);
                        }
                    });

                    if(custommessage.length > 0)
                    {
                        dispatch(message(custommessage));
                    }
                    return dispatch({
                        type: GET_PATIENTS,
                        payload: finalList
                    });
                }else{
                    dispatch(errorMessage(res.data.message));
                    return dispatch({
                        type: GET_PATIENTS,
                        payload: []
                    });
                }
            } else {
                dispatch(errorMessage(res.data.message));
                return dispatch({
                    type: GET_PATIENTS,
                    payload: []
                });
            }
        }).catch(err => {
            console.log('error', err.response);
            const responseErrorMessage =  err.response.data.message;
            dispatch(errorMessage(responseErrorMessage));
            return dispatch({
                type: GET_PATIENTS,
                payload: []
            });
        });
    }
}

export const getPatientQuestionnaire = (pid) => dispatch => {
    if (pid && pid.length > 0) {
        dispatch(showLoader());
        const url = "dme_portal/patients/" + pid + "/questionnaires";
        API().get(url).then(res => {
            if (res && res.data && res.data.success) {
                if (res.data.questionnaires) {
                    dispatch(hideLoader());
                    return dispatch({
                        type: GET_PATIENTS_QUESTIONNAIRE,
                        payload: res.data.questionnaires
                    });
                }
            } else {
                dispatch(hideLoader());
                dispatch(errorMessage(res.data.message));
                return dispatch({
                    type: GET_PATIENTS_QUESTIONNAIRE,
                    payload: []
                });
            }
        }).catch(err => {
            console.log('error', err.response);
            const responseErrorMessage =  err.response.data.message;
            dispatch(hideLoader());
            dispatch(errorMessage(responseErrorMessage));
            return dispatch({
                type: GET_PATIENTS_QUESTIONNAIRE,
                payload: []
            });
        });
    } else {
        return dispatch({
            type: GET_PATIENTS_QUESTIONNAIRE,
            payload: []
        });
    }
}

export const getPatientQuestionnaireDetails = (pid, qid) => dispatch => {
    dispatch(showLoader());
    const url = `dme_portal/patients/${pid}/questionnaires/${qid}`;
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.questionnaire) {
            if (res && res.data && res.data.questionnaire) {
                return dispatch({
                    type: GET_QUESTIONNAIRE_DETAILS,
                    payload: res.data.questionnaire
                });
            }
        } else {
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_QUESTIONNAIRE_DETAILS,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err);
        dispatch(hideLoader());
        return dispatch({
            type: GET_QUESTIONNAIRE_DETAILS,
            payload: []
        });
    })
}

export const getPatientInsurances = (pid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/insurances/" + pid;
    API().get(url).then(res => {
        if (res && res.data && res.data.success) {
            if (res.data.insurances) {
                dispatch(hideLoader());
                return dispatch({
                    type: GET_PATIENTS_INSURANCE,
                    payload: res.data.insurances
                });
            }
        } else {
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_PATIENTS_INSURANCE,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_PATIENTS_INSURANCE,
            payload: []
        });
    });
}
