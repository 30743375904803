import { get, put, post, del } from "utils/api";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";


/** Prefix for action types */
const prefix = type => `s3/company-inventory/${type}`;

export const Types = {
  SET_COMPANY_INVENTORY: prefix("SET_COMPANY_INVENTORY"),
  UPDATE_COMPANY_INVENTORY: prefix("UPDATE_COMPANY_INVENTORY"),
  ADD_COMPANY_INVENTORY: prefix("ADD_COMPANY_INVENTORY"),
  DELETE_COMPANY_INVENTORY: prefix("DELETE_COMPANY_INVENTORY"),
};

export const setCompanyInventory = inventory => ({
  type: Types.SET_COMPANY_INVENTORY,
  payload: { inventory }
});

export const fetchCompanyInventory = () => async(dispatch) => {
    const result = await get("company_inventory/all-inventory")
    dispatch(setCompanyInventory(result))
}

export const updateInventory = inventory => ({
  type: Types.UPDATE_COMPANY_INVENTORY,
  payload: inventory
});

export const updateCompanyInventory = (inventory, guid) => async(dispatch) => {
    await put(`company_inventory/update?guid=${guid}`, inventory)
    await dispatch(fetchCompanyInventory())
};


export const addInventory = inventory => ({
  type: Types.ADD_COMPANY_INVENTORY,
  payload: inventory
});

export const addCompanyInventory = (inventory, guid) => async(dispatch) => {

  try{
    await post(`company_inventory/add?guid=${guid}`, inventory)
    await dispatch(fetchCompanyInventory())
  }
  catch(e){
    dispatch(errorMessage(getApiErrorMessage(e)));
  }
};

export const deleteInventory = inventory => ({
  type: Types.DELETE_COMPANY_INVENTORY,
  payload: inventory
});

export const deleteCompanyInventory = (guid) => async(dispatch) => {
    await del(`company_inventory/delete?guid=${guid}`)
    await dispatch(fetchCompanyInventory())
};
