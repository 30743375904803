// import only neccessarry icons to keep bundle size small
// this should be unnessecary when updated to react-icons v3
import Speedometer from "react-icons/lib/io/ios-speedometer";
import Star from "react-icons/lib/io/ios-star";
import Briefcase from "react-icons/lib/io/ios-briefcase";
import Person from "react-icons/lib/io/person";
import Heart from "react-icons/lib/io/heart";
import Cart from "react-icons/lib/io/ios-cart";
import PaperPlane from "react-icons/lib/io/ios-paperplane";
import Stats from "react-icons/lib/io/stats-bars";
import Pricetags from "react-icons/lib/io/ios-pricetag";
import Document from "react-icons/lib/io/android-document";
import News from "react-icons/lib/fa/newspaper-o";
import IoMdList from "react-icons/lib/io/android-list";
import Call from "react-icons/lib/io/android-call";
import Settings from "react-icons/lib/md/settings";
import Wrench from "react-icons/lib/io/wrench";
import Close from "react-icons/lib/io/android-close";
import HelpCircle from "react-icons/lib/io/help-circled";
import LogOut from "react-icons/lib/io/log-out";
import Recording from "react-icons/lib/io/ios-recording";
import GoogleLogo from "react-icons/lib/fa/google";
import MdGroupWork from "react-icons/lib/md/group-work";
import Hammer from "react-icons/lib/io/hammer";
import MdCallEnd from "react-icons/lib/md/call-end";
import FaChartBar from "react-icons/lib/fa/area-chart";
import MdPeople from "react-icons/lib/md/people";
import FAQs from "react-icons/lib/io/informatcircled";
import YouTube from "react-icons/lib/io/social-youtube-outline";
import Clipboard from "react-icons/lib/io/clipboard";
import ChatBoxes from "react-icons/lib/io/ios-chatboxes";
import MdMail  from "react-icons/lib/md/mail";

export default {
  Speedometer,
  Star,
  Briefcase,
  Person,
  Heart,
  Cart,
  PaperPlane,
  Stats,
  Pricetags,
  Document,
  News,
  IoMdList,
  Call,
  Settings,
  Wrench,
  HelpCircle,
  LogOut,
  Recording,
  GoogleLogo,
  MdGroupWork,
  Hammer,
  MdCallEnd,
  FaChartBar,
  MdPeople,
  FAQs,
  YouTube,
  Clipboard,
  ChatBoxes,
  Close,
  MdMail
};
