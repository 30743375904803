import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ChangeLogTable from "./change-log-table";

const InventoryInfoTabs = ({ fulfillmentId, inventoryId }) => {
  const [, setSelectedTabIndex] = useState(0);
  return (
        <Tabs onSelect={index => setSelectedTabIndex(index)}>
          <div className="row">
            <TabList>
              <div>
                <Tab>
                  <div>Change Logs</div>
                </Tab>
              </div>
            </TabList>
          </div>
          <div className="row">
            <TabPanel>
              <ChangeLogTable fulfillmentId={fulfillmentId} inventoryId={inventoryId} />
            </TabPanel>
          </div>
        </Tabs>
  );
};

export default InventoryInfoTabs;

InventoryInfoTabs.propTypes = {
  fulfillmentId: PropTypes.string,
  inventoryId: PropTypes.string
};
