import React from "react";
import PropTypes from "prop-types";
import { ChartHeader } from "components/ui";
import Radium from "radium";
import styler from "react-styling";
import ReactTooltip from 'react-tooltip';
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import {
  ResponsiveContainer,
  LabelList,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

export const OrderBreakdown = ({ data, onExport }) => (
  <div style={S.container}>
    <ReactTooltip />
    {onExport && (<div className="stat-export-icon" data-tip="Export" onClick={onExport}><FaFileExcelO /></div>)}
    <ChartHeader
      title="Item Types Requested"
      tooltip="Excludes rejected and voided orders."
    />
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 15,
          right: 20,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis type="number" domain={[0, "dataMax + 5"]} scale="pow" />
        <Bar dataKey="count" fill="rgb(49, 130, 189)">
          <LabelList dataKey="count" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default Radium(OrderBreakdown);

const S = styler`
  container
    border: 1px solid #DDD
    padding: 16px 20px 20px 20px
    position: relative
`;

OrderBreakdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string
    })
  ),
  onExport: PropTypes.func
};
