import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
const XZeroHistoryTab = ({ xZeroRecords }) => {
  return (
    <div>
      {xZeroRecords.length > 0 && (
        <div>
          <h5 style={{ marginLeft: "10px" }}>XZEROs</h5>
          <table className="large-12" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <td>Product Type</td>
                <td>Quantity</td>
                <td>Date Created</td>
                <td>Replacement Date</td>
                <td>HCPC Code</td>
              </tr>
            </thead>
            <tbody>
              {xZeroRecords.map(record => {
                return (
                  <tr key={record.sHCPCCode + "xzero"}>
                    <td>{record.sProductType}</td>
                    <td>{record.iQuantity}</td>
                    <td>{moment(record.dDateCreated).format("ll")}</td>
                    <td>{moment(record.dReplacementDate).format("ll")}</td>
                    <td>{record.sHCPCCode}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

XZeroHistoryTab.propTypes = {
  xZeroRecords: PropTypes.array.isRequired,
  lineItemEligibility: PropTypes.bool
};

export default XZeroHistoryTab;
