import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader, InlineWaiting } from "components/ui";
import PatientSearchForm from "components/patient-search-form";
import { assignQuestionnaireToPatient } from "components/dd_newModules/Redux/Actions/questionnaire";
import { useFetch } from "hooks";
import { get } from "utils/api";

const AssignQuestionnaire = ({ assignQuestionnaireToPatient, isLoading }) => {
  const [selectedPatients, setSelectedPatients] = React.useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState();

  const getQuestionnairesApi = useFetch({
    defaultValue: [],
    transformResponse: ({ success, questionnaires }) => {
      if (!success) return [];
      return questionnaires.map(x => ({
        ...x,
        value: x.questionnaire_id,
        text:
          x.questionnaire_type === "2"
            ? `Product Type - ${x.title}`
            : `Service Type - ${x.title}`
      }));
    },
    apiFn: () => get("dme_portal/questionnaires")
  });
  React.useEffect(() => {
    getQuestionnairesApi.fetchData();
  }, []);
  return (
    <div style={{ display: "grid", gridTemplateRows: "auto 1fr auto" }}>
      <PageHeader title="Assign Questionnaire To Patient(s)" />
      <PatientSearchForm
        selectedPatients={selectedPatients}
        setSelectedPatients={setSelectedPatients}
        feature="questionaires"
      />
      <div style={{ marginTop: 20 }}>
        <h6>Select Questionnaire</h6>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            gridGap: 20
          }}
        >
          <select
            value={selectedQuestionnaire}
            onChange={({ target }) => {
              setSelectedQuestionnaire(target.value);
            }}
          >
            <option />
            {getQuestionnairesApi.response
              .filter(x => x.active)
              .map(({ text, value }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
          </select>
          {isLoading ? (
            <InlineWaiting />
          ) : (
            <button
              disabled={selectedPatients.length == 0 || !selectedQuestionnaire}
              onClick={() => {
                assignQuestionnaireToPatient(
                  selectedQuestionnaire,
                  selectedPatients
                );
                setSelectedPatients([]);
              }}
            >
              Assign Patients
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

AssignQuestionnaire.propTypes = {
  assignQuestionnaireToPatient: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default connect(
  state => ({
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  {
    assignQuestionnaireToPatient
  }
)(AssignQuestionnaire);
