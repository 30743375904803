import React from "react";
import FormikField from "components/forms/formik-field";
import { hoursPerNightOptions, nightsPerWeekOptions } from "./options";

const Usage = () => (
  <div>
    <h3>Tell us about your equipment usage</h3>
    <FormikField
      name="hours_per_night"
      component="select"
      label="How many hours per night are you using PAP therapy?"
    >
      {hoursPerNightOptions.map(({ text, value }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </FormikField>
    <FormikField
      name="nights_per_week"
      component="select"
      label="How many nights per week are you using PAP therapy?"
    >
      {nightsPerWeekOptions.map(({ text, value }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </FormikField>
    <div className="order-form-warning">
      Adequate use of PAP device is recommended for your health and insurance
      coverage of supplies.
    </div>
  </div>
);

export default Usage;
