import React from "react";
import { IoClose } from "react-icons/lib/io";
import PropTypes from "prop-types";

export default function GridCancelAppointmentButton({ chatId, openModal }){

    const onClick = () =>{
        openModal(chatId);
    }

    return (
        <div onClick={ onClick.bind(null) }>
            <IoClose color={"red"} style={{cursor: "pointer"}}/>
        </div>
    )
}

GridCancelAppointmentButton.propTypes = {
    chatId: PropTypes.string,
    openModal: PropTypes.func
}
