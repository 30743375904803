import { combineReducers } from "redux";
import { fromJS } from "immutable";
import {
  SET_INVENTORY_FORM,
  UPDATE_INVENTORY_FORM,
  INIT,
  // ADD_FILTER,
  // REMOVE_FILTER,
  SET_FILTERS,
  // TOGGLE_SORT_DIRECTION
} from "actions/inventory";
import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";
import * as R from "ramda";
import orderBy from "lodash/orderBy";

// inventory filters reducer
const initialState = fromJS({
  compatibleEquipment: false,
  compatible_equip: [],
  product_type: [],
  manufacturer_id: [],
  productActive: []
});

const initFilters = (_, { filter = {} }) =>
  initialState.merge({
    ...filter
  });

// const addFilter = (state, { filter: { by, value } }) =>
//   state.updateIn([by], values => values.concat(value));
//
// const removeFilter = (state, { filter: { by, value } }) =>
//   state.updateIn([by], values => values.filter(v => v !== value));

const setFilter = (state, { filter: { by, value } }) =>
  state.merge({
    [by]: value ? (typeof value === "string" ? [value] : value) : value
  });

// const toggleSortDirection = (state, { sortBy }) =>
//   state.merge({
//     sortBy,
//     sortDirection: state.get("sortDirection") === "asc" ? "desc" : "asc"
//   });

export const filters = createReducer(initialState, {
  [INIT]: initFilters,
  // [ADD_FILTER]: addFilter,
  // [REMOVE_FILTER]: removeFilter,
  [SET_FILTERS]: setFilter,
  // [TOGGLE_SORT_DIRECTION]: toggleSortDirection
});

const form = (state = {}, action) => {
  switch (action.type) {
    case SET_INVENTORY_FORM:
      return {
        ...state,
        ...action.form,
        company_id: action.form.company_id,
        manufacturerId: action.form.manufacturerId
      };
    case UPDATE_INVENTORY_FORM:
      return { ...state, ...action.form };
    default:
      return state;
  }
};

export default combineReducers({
  form,
  filters
});

const getInventoryState = state => state.getIn(["inventory"]);
const getInventoryFilterState = createSelector(
  [getInventoryState],
  inventoryState =>
    inventoryState.filters ? inventoryState.filters.toJS() : {}
);

const getInventoryItemsFromProps = (_, props) =>
  R.propOr([], "inventoryItems", props);

const getInventoryRows = createSelector(
  [getInventoryFilterState, getInventoryItemsFromProps],
  (filterState, inventoryItems) => {
    const isUnique = R.uniqBy(R.prop("id"));
    const hasValue = (obj, key) =>
      R.prop(key, obj) && !R.isEmpty(R.prop(key, obj));

    const result = R.pipe(
      R.filter(
        item =>
          !hasValue(filterState, "product_type") ||
          R.includes(
            R.prop("product_type", item),
            R.prop("product_type", filterState)
          )
      ),
      R.filter(
        item =>
          !hasValue(filterState, "manufacturer_id") ||
          R.includes(
            R.prop("manufacturer_id", item),
            R.prop("manufacturer_id", filterState)
          )
      ),
      R.filter(
        item =>
          !hasValue(filterState, "productActive") ||
          R.includes(
            R.prop("productActive", item),
            R.prop("productActive", filterState)
          )
      ),
      R.sortWith([]),
      isUnique
    )(inventoryItems);
    return orderBy(result, filterState.sortBy, filterState.sortDirection);
  }
);

export const selectors = {
  getInventoryFilterState,
  getInventoryRows
};
