import React from "react";
import PropTypes from "prop-types";
import { find, propOr, pipe } from "ramda";

const CompanyBranchOptions = ({ companyId, companies }) => {
  const companyBranches = pipe(
    find(({ value }) => value === companyId),
    propOr([], "branches")
  )(companies);
  return (
    <>
      <option />
      {companyBranches
        .filter(x => x.active)
        .map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
    </>
  );
};

CompanyBranchOptions.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  companyId: PropTypes.string
};

export default CompanyBranchOptions;
