import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as R from "ramda";
import { updateCompanyManufacturerWeights } from "actions/company";
import { selectors } from "reducers";
import DraggableTag from "components/draggable/draggable-tag";
import IoMdTrash from "react-icons/lib/io/trash-b";
import DraggableBucket from "components/draggable/draggable-bucket";

const MULTIPLIER = 1000;

const ManufacturerWeights = ({
  companyId,
  manufacturerWeights,
  updateCompanyManufacturerWeights,
  autofitManufacturers, 
}) => {
  const [bucketContainerMap, setBucketContainerMap] = useState({});
  const [upperBound, setUpperBound] = useState(1);
  const [shouldSaveChanges, setShouldSaveChanges] = useState(false);

  useEffect(() => {
    if (manufacturerWeights)
      manufacturerWeights.weights.forEach(x => {
        onBucketDrop(getBucketIdFromWeight(x.weight), x.manufacturer_id, false);
      });
    calculateUpperBound();
  }, [manufacturerWeights]);

  useEffect(() => {
    if (shouldSaveChanges) {
      const timer = setTimeout(() => {
        saveChanges();
        setShouldSaveChanges(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [shouldSaveChanges, bucketContainerMap]);

  const saveChanges = () => {
    var payload = { weights: convertBucketContainerMap() };
    updateCompanyManufacturerWeights(companyId, payload);
  };

  const getWeightFromBucketId = id => {
    return Math.floor(MULTIPLIER / (parseInt(id) + 1));
  };

  const getBucketIdFromWeight = weight => {
    if (weight === -1) return "disabled";
    return Math.floor(MULTIPLIER / weight) - 1;
  };

  const convertBucketContainerMap = () => {
    var payload = [];
    for (const key in bucketContainerMap) {
      const weight = key === "disabled" ? -1 : getWeightFromBucketId(key);
      bucketContainerMap[key].forEach(manufacturer_id => {
        payload.push({ manufacturer_id: manufacturer_id, weight: weight });
      });
    }
    return payload;
  };

  const calculateUpperBound = () => {
    var tempBound = 0;
    for (const key in bucketContainerMap) {
      if (!bucketContainerMap[key].length) continue;
      const keyInt = parseInt(key) + 1;
      if (keyInt > tempBound) tempBound = keyInt;
    }
    setUpperBound(tempBound);
  };

  const getBucketChildren = (bucketId, disabled = false) => {
    const containerList = bucketContainerMap[bucketId];
    if (!containerList) return;
    return manufacturerWeights.weights
      .filter(x => containerList.includes(x.manufacturer_id))
      .filter(x => autofitManufacturers.includes(x.manufacturer_id))
      .map(x => (
        <DraggableTag
          key={x.manufacturer_id}
          id={x.manufacturer_id}
          text={x.manufacturer}
          disabled={disabled}
        />
      ));
  };

  const onBucketDrop = (bucketId, containerId, saveChanges = true) => {
    for (const key in bucketContainerMap) {
      bucketContainerMap[key] = bucketContainerMap[key].filter(
        x => x != containerId
      );
    }

    if (bucketContainerMap[bucketId])
      bucketContainerMap[bucketId].push(containerId);
    else bucketContainerMap[bucketId] = [containerId];

    setBucketContainerMap(R.clone(bucketContainerMap));
    calculateUpperBound();
    setShouldSaveChanges(saveChanges);
  };

  return (
    <div className="manufacturer-weights-container">
      <div>
        {[...Array(upperBound + 1).keys()].map(idx => (
          <DraggableBucket
            key={idx}
            id={idx}
            title={`Priority: ${idx + 1}`}
            onItemDropped={onBucketDrop}
          >
            {getBucketChildren(idx)}
          </DraggableBucket>
        ))}
      </div>
      <div>
        <DraggableBucket
          id="disabled"
          title="Disabled"
          icon={<IoMdTrash />}
          containerStyle={{ height: "100%" }}
          onItemDropped={onBucketDrop}
        >
          {getBucketChildren("disabled", true)}
        </DraggableBucket>
      </div>
    </div>
  );
};

ManufacturerWeights.propTypes = {
  companyId: PropTypes.string.isRequired,
  manufacturerWeights: PropTypes.object.isRequired,
  updateCompanyManufacturerWeights: PropTypes.func.isRequired,
  autofitManufacturers: PropTypes.array
};

export default connect(
  state => ({
    manufacturerWeights: selectors.getCompanyManufacturerWeights(state),
    autofitManufacturers: selectors.getCompanyAutofitManufacturers(state)
  }),
  {
    updateCompanyManufacturerWeights
  }
)(ManufacturerWeights);
