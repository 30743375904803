import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, FieldArray } from "formik";
import { IconButton, InlineWaiting } from "components/ui";
import FormikField from "components/forms/formik-field";
import QuestionOptionsForm from "./question-options-form";
import { MdAdd, MdCancel } from "react-icons/lib/md";
import { connect } from "react-redux";
import { pathOr } from "ramda";
import * as Yup from "yup";

const QuestionnaireForm = ({ initialValues, handleSubmit, services }) => {
  return (
    <div className="questionnaire-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Required"),
          description: Yup.string().required("Required"),
          questionnaire_type: Yup.string().required("Required"),
          questions: Yup.array()
            .of(
              Yup.object().shape({
                question_text: Yup.string().required("Required"),
                question_type: Yup.string().required("Required")
              })
            )
            .min(1, "Questionnaire must have at least one question"),
          service_id: Yup.string().when("questionnaire_type", {
            is: value => value && value == "1",
            then: () => Yup.string().required("Service is required"),
            otherwise: () => Yup.string().nullable()
          }),
          product_id: Yup.string().when("questionnaire_type", {
            is: value => value && value == "2",
            then: () => Yup.string().required("product is required"),
            otherwise: () => Yup.string().nullable()
          })
        })}
      >
        {({ values, isValid, isSubmitting, setFieldValue }) => {
          return (
            <Form className="questionnaire-form-container">
              <FormikField
                name="title"
                type="text"
                label="Questionnaire Name"
              />
              <FormikField name="description" type="text" label="Description" />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr minmax(200px, auto)",
                  gridGap: 20
                }}
              >
                <FormikField
                  name="questionnaire_type"
                  component="select"
                  label="Type"
                  onChange={({ target }) => {
                    setFieldValue("questionnaire_type", target.value);
                    setFieldValue("service_id", "");
                    setFieldValue("product_id", "");
                  }}
                >
                  <option />
                  <option value="1">Service</option>
                  <option value="2">Product</option>
                </FormikField>
                {values.questionnaire_type == "1" && (
                  <FormikField
                    name="service_id"
                    label="Service"
                    component="select"
                  >
                    <option />
                    {services.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </FormikField>
                )}
                {values.questionnaire_type == "2" && (
                  <FormikField
                    name="product_id"
                    component="select"
                    label="Product"
                  >
                    <option />
                  </FormikField>
                )}
              </div>
              <hr />
              <fieldset className="question-fields">
                <legend>Questions</legend>
                <FieldArray
                  name="questions"
                  render={arrayHelpers => (
                    <div>
                      {values.questions &&
                        values.questions.length > 0 &&
                        values.questions.map((question, index) => (
                          <div key={index} style={{ padding: 5 }}>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns:
                                  "1fr minmax(200px, auto) auto",
                                alignItems: "center",
                                gridGap: 10
                              }}
                            >
                              <FormikField
                                name={`questions.${index}.question_text`}
                                type="text"
                                label="Question"
                              />
                              <FormikField
                                name={`questions.${index}.question_type`}
                                component="select"
                                label="Type"
                              >
                                <option />
                                <option value="1">Yes/No</option>
                                <option value="2">Multichoice</option>
                                <option value="3">Text</option>
                                <option value="4">LinkerScale</option>
                              </FormikField>
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                                style={{
                                  /* color: "#e8910c" */ color: "#fe220c",
                                  padding: 3
                                }}
                                icon={<MdCancel />}
                                type="button"
                                text="Delete"
                              />
                            </div>
                            {question.question_type == "2" && (
                              <QuestionOptionsForm
                                index={index}
                                {...question}
                              />
                            )}
                          </div>
                        ))}
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 5
                        }}
                      >
                        <IconButton
                          type="button"
                          style={{ margin: 0, padding: "5px 15px" }}
                          onClick={() =>
                            arrayHelpers.push({
                              question_text: "",
                              question_type: "",
                              options: [],
                              active: true
                            })
                          }
                          text="Add a Question"
                          icon={<MdAdd />}
                        />
                      </div>
                    </div>
                  )}
                />
              </fieldset>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: 5
                }}
              >
                {isSubmitting ? (
                  <InlineWaiting />
                ) : (
                  <button
                    type="Submit"
                    style={{ margin: 0 }}
                    disabled={!isValid}
                  >
                    Save
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

QuestionnaireForm.defaultProps = {
  initialValues: {
    questions: [],
    title: "",
    service_name: "",
    product_name: "",
    question_type: "",
    questionnaire_type: "1"
  }
};

QuestionnaireForm.propTypes = {
  initialValues: PropTypes.shape({
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        question_type: PropTypes.string,
        question_text: PropTypes.string
        // options: PropTypes.arrayOf(
        //   PropTypes.shape({
        //     option_text: PropTypes.string
        //   })
        // )
      })
    ),
    title: PropTypes.string,
    service_name: PropTypes.string,
    product_name: PropTypes.string,
    description: PropTypes.string,
    questionnaire_type: PropTypes.string
  }),
  handleSubmit: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  services: pathOr(
    [],
    ["rdc_services", "Services"],
    state.getIn(["newRootReducer"])
  )
}))(QuestionnaireForm);
