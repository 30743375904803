import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import * as R from 'ramda'
import { compose } from 'recompose'
import {
    withQuery,
    withTableHandlers,
    withApi,
    withHeaderFooter
} from 'features/query'
import withBatch from 'features/batch'
import Table from 'components/ui/impl/table'
import styler from "react-styling";
import MdArrowUpward from "react-icons/lib/md/arrow-upward"
import MdAssignmentReturned from "react-icons/lib/md/assignment-returned"
/* eslint-disable react/prop-types */

const formatDate = date => date ? moment(date).format('MM / DD / YYYY -  h:mm:ss a ') : '';
const formatShortDate = date => date ? moment(date).format('MM / DD / YYYY') : '';

const IVRCallStatesTable = compose(
    connect((state, { queryId, apiId, callSid }) => ({
        queryId: queryId,
        initialQuery: {
            sort: {
            },
            pagination: {
                per: 15,
                page: 1
            },
            filters: { callSid: [callSid]},
            searches: {}
        },
        apiId: apiId,
    }), {
            //these are actions still part of connect HOC
            //queuePatients,         
        }),
    //This begins the other HOCs
    withQuery(),
    withApi({
        api: {
            route: 'IVR/Call',
            responseKey: 'states',
            idKey: 'pkIVRID',
            entityKey: 'ivrCallStates'
        }
    }),
    withBatch({ recordIdKey: 'id' }),
    connect((state, {
        loadPatient,
        loadOrder,
        loadMasterTable
    }) => {
        return ({
            columns: R.filter(R.is(Object), [
                {
                    header: 'ID',
                    field: 'pkIVRID',
                },
                {
                    header: 'CallSid',
                    field: 'callSid',
                },
                {
                    header: 'Time',
                    field: 'callRecordingTimeStamp',
                    format: ({ callRecordingTimeStamp }) => (<span style={{whiteSpace: "nowrap"}}>{callRecordingTimeStamp && formatDate(callRecordingTimeStamp)}</span>)
                },                  
                {
                    header: 'Question',
                    field: 'ivrOrderFormQuestionText',
                    format: ({ ivrOrderFormQuestionText, fkiOrderFormQuestion }) => (<span
                        style={{fontSize: "1.5em"}}
                        title={`Primary Key For Question: ${fkiOrderFormQuestion}`}                       
                    >
                        {ivrOrderFormQuestionText}
                    </span>)
                },
                {
                    header: 'Answer',
                    field: 'value',
                    format: ({ value }) => {                        
                            if (value === "00") {
                                return "User Redirected To Main Menu"
                            } else {
                               return value 
                            }                           
                    }                  
                }, 
                {
                    header: 'Location',
                    field: 'formLocation',
                    format: ({ formLocation }) => (<div className={formLocation} style={{textTransform: "capitalize"}}>{formLocation}</div>)
                },
                {
                    header: 'Equipment',
                    field: 'equipmentType',
                    format: ({ equipmentType }) => (<span style={{ textTransform: "capitalize" }}>{equipmentType}</span>)
                },
                {
                    header: 'Patient',
                    field: 'fullName',
                    format: ({ fullName, patientGuid }) => (
                        <span
                            title={`Click Here To Open Patient Profile: ${patientGuid}`}
                            style={S.link}
                            onClick={() => loadPatient(patientGuid)}
                        >
                            {fullName}
                        </span>
                    ),
                   
                },                             
                {
                    header: 'DOB',
                    field: 'dateOfBirth',
                    format: ({ dateOfBirth }) => (<span style={{ whiteSpace: "nowrap" }}>{dateOfBirth && formatShortDate(dateOfBirth)}</span>)
                }, 
                {
                    header: 'Issue',
                    field: 'ivrIssue',
                    format: ({ ivrIssue }) => (ivrIssue === true ? <span style={{ backgroundColor: "red", color: "white", padding: "5px", borderRadius: "5px" }}>{"True"}</span> : "False"),
                    filters: [
                        {
                            text: 'Issue',
                            value: 'true'
                        },
                    ],                   
                },
                {
                    header: 'Converted',
                    field: 'convertedToOrder',
                    format: ({ convertedToOrder }) => (convertedToOrder === true ? <span style={{ backgroundColor: "green", color: "white", padding: "5px", borderRadius: "5px" }}>{"True"}</span> : "False"),
                    filters: [
                        {
                            text: 'Converted',
                            value: 'true'
                        },                                           
                    ],                 
                },               
                {
                    header: 'Order',
                    field: 'orderGuid',
                    format: ({ orderGuid }) => (
                        <span
                            title={`Click Here To Open Order Profile: ${orderGuid}`}
                            style={S.link}
                            onClick={() => loadOrder(orderGuid)}
                        >
                            {orderGuid}
                        </span>
                    )
                },               
            ]),
            customButtons: [                
                {
                    component: () => (<span title="Go To Master Table" style={S.reloadMaster} onClick={() => loadMasterTable("master")}>{<MdArrowUpward/>}</span>)
                },
                {
                    component: () => (<span title="Load Order Form From State Records" style={S.reloadMaster}>{<MdAssignmentReturned />}</span>)
                },
            ]
        })
    }),
    withHeaderFooter({
        perPageOptions: [15, 150, 300, 500],
    }),
    withTableHandlers()
)(Table)

IVRCallStatesTable.propTypes = {    
    loadMasterTable: PropTypes.func.isRequired,
    loadOrder: PropTypes.func.isRequired,
    loadPatient: PropTypes.func.isRequired,
    callSid: PropTypes.string,
    queryId: PropTypes.string,
    apiId: PropTypes.string,
}

export default IVRCallStatesTable

const S = styler`
  reloadMaster
    position: relative
    top: -2px
    //left: 5px
    font-size: 25px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  link
    display: table-cell
    color: blue
    cursor: pointer
  worked
    opacity: 0.5
  leftFilter
    margin-right: 5
`;
