import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { get } from "utils/api";
import { InlineWaiting, PureModal } from "components/ui";
import { useDispatch } from "react-redux";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";

const RaPatientCmnsButton = ({ patientId, fetchPatientCmns }) => {
  const dispatch = useDispatch();
  const {
    response: raCmnPatientCmnInfo,
    isFetching,
    fetchData: getPatientRaCmnLink
  } = useFetch({
    apiFn: patientId => get(`referral-answer/iframe-link/${patientId}`),
    throwOnError: true,
    defaultValue: {}
  });
  const { fetchData: importPatientCmns } = useFetch({
    apiFn: patientId => get(`referral-answer/import-patient-cmns/${patientId}`),
    defaultValue: {}
  });
  return (
    <PureModal
      renderTrigger={({ openModal }) => (
        <div>
          {isFetching ? (
            <InlineWaiting />
          ) : (
            <button
              style={{ margin: 0, padding: "5px 10px" }}
              onClick={async () => {
                try {
                  await getPatientRaCmnLink(patientId);
                  openModal();
                } catch (err) {
                  dispatch(errorMessage(getApiErrorMessage(err)));
                }
              }}
            >
              Manage Cmns
            </button>
          )}
        </div>
      )}
      onClose={async () => {
        await importPatientCmns(patientId);
        await fetchPatientCmns(patientId);
      }}
      windowProps={{ large: true }}
    >
      {raCmnPatientCmnInfo.cmn_dashboard_link && (
        <iframe
          src={raCmnPatientCmnInfo.cmn_dashboard_link}
          height="100%"
          width="100%"
          style={{ height: "100%", display: "flex", flex: 1 }}
          allow="encrypted-media"
          allowFullScreen
          title="Patient Cmns"
          scrolling="yes"
          frameBorder="0"
        />
      )}
    </PureModal>
  );
};

RaPatientCmnsButton.propTypes = {
  patientId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  fetchPatientCmns: PropTypes.func.isRequired
};

export default RaPatientCmnsButton;
