import React from "react";
import PropTypes from "prop-types";
import { formatTimeFromTimeSlot, getGridRowSpan } from "./scheduler-helpers";
import { CreateEditViewTimeSlotModal } from "./create-time-slot-modal";

const TempScheduledSlot = React.memo(({ col, row1, row2, setEditSlot, timeZone }) => {
  const [mouseDown, setMouseDown] = React.useState(false);
  return (
    <div
      className="scheduled-slot temp"
      style={{
        gridColumn: `${col + 1} / span 1`,
        gridRow: `${row1 + 1} / span ${getGridRowSpan(row1, row2)}`
      }}
      onMouseUp={() => {
        if (mouseDown) {
          setEditSlot()
          setMouseDown(false);
        }
      }}
      onMouseDown={e => {
        e.stopPropagation();
        setMouseDown(true);
      }}
    >

      <div className="temp-scheduled-slot-hour-range-container">
        <p style={{margin: "0px", textAlign: "center"}}>{formatTimeFromTimeSlot(row1)}-{formatTimeFromTimeSlot(row2)}</p>
        <CreateEditViewTimeSlotModal timeZone={timeZone}/>
      </div>

    </div>
  );
});

TempScheduledSlot.propTypes = {
  col: PropTypes.number.isRequired,
  row1: PropTypes.number.isRequired,
  row2: PropTypes.number.isRequired,
  setEditSlot: PropTypes.func,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
]),
};

export default TempScheduledSlot;
