import React from "react";
import PropTypes from "prop-types";

// const getScanNowLink = token =>
//   `https://papresupply.com/mask-fitting?token=${token}`;
//
const TransactionCreated = ({ status, invite_link, message }) => {
  return (
    <div className="mask-transaction-submit-results">
      {status === 200 ? (
        <>
          <div className="transaction-success">
            Mask fitting transaction created
          </div>
          <button
            onClick={() => {
              window.open(
                invite_link,
                "_blank",
                "height=" + screen.height + ", width=" + screen.width
              );
            }}
          >
            Open Scan Link
          </button>
        </>
      ) : (
        <div className="transaction-error">{message}</div>
      )}
    </div>
  );
};
export default TransactionCreated;

TransactionCreated.propTypes = {
  status: PropTypes.number,
  invite_link: PropTypes.string,
  message: PropTypes.string
};
