import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateAdminReview } from "actions/contacts";

const AdminReviewCheckBox = ({ contactId, adminReview, updateAdminReview }) => {
  const [checked, setChecked] = useState(adminReview);

  const onCheckboxChange = value => {
    setChecked(value);
    updateAdminReview(contactId, value, "Review Requested");
  };

  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={() => onCheckboxChange(!checked)}
    />
  );
};

AdminReviewCheckBox.propTypes = {
  contactId: PropTypes.string.isRequired,
  adminReview: PropTypes.bool.isRequired,
  updateAdminReview: PropTypes.func.isRequired
};

export default connect(() => ({}), { updateAdminReview })(AdminReviewCheckBox);
