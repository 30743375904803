import FormikField from "components/forms/formik-field";
import { PageHeader } from "components/ui";
import { Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { useFetch } from 'hooks';
import { path } from "ramda";
import { errorMessage, message } from "actions/message";
import { put } from './../../../../utils/api';
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

const ModalUpdateFormDates = ({ patientId, formId, startDate, onSubmitAction, closeModal }) => {

    const dispatch = useDispatch();

    const validateEmptyDate = value => {
        let errorMessage;
        if (!value) {
            errorMessage = "You must select a date";
        }
        return errorMessage;
    };

    const {
        fetchData: fetchUpdateFormsDates,
    } = useFetch({
        apiFn: ({ start_date }) => put(`patients/${patientId}/update-form-startdate/${formId}/${start_date}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
    })

    const updateFormsDate = async (value) => {
        const response = await fetchUpdateFormsDates(value);
        if (response && response?.message === undefined) {
            dispatch(message("The forms dates were updated successfully."));
            closeModal();
            onSubmitAction("All");
        } else {
            dispatch(errorMessage(response.message))
        }
    }

    return (
        <div>
            <PageHeader title="Update form start date" />
            <Formik
                initialValues={{
                    start_date: moment(startDate).format("yyyy-MM-DD")
                }}
                enableReinitialize={true}
                onSubmit={(values) => updateFormsDate(values)}
            >
                {({ handleChange, isSubmitting }) => (
                    <Form>
                        <div className="dd_col-3">
                            <FormikField
                                name="start_date"
                                type="date"
                                label="Choose new start Date"
                                disabled={isSubmitting}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                validate={validateEmptyDate}
                            />
                        </div>
                        <div className='dd_col-12'>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                <button
                                    type="submit"
                                    className="button"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

ModalUpdateFormDates.propTypes = {
    patientId: PropTypes.string, 
    formId: PropTypes.number, 
    startDate: PropTypes.string, 
    onSubmitAction: PropTypes.func, 
    closeModal: PropTypes.func
};

export default ModalUpdateFormDates;