import { take, call, put, all } from "redux-saga/effects";
import { Types } from "actions/company-contact-logic";
import { message, errorMessage } from "actions/message";
import * as api from "utils/api";

function* getCompanyContactLogic({ payload }) {
  const { companyId, index, patientTypeId } = payload;
  try {
      yield put({
        type: Types.GET_COMPANY_CONTACT_LOGIC_REQUEST,
        payload: index
      });
      const response = yield call(api.get, `contactlogic/company/${companyId}/${patientTypeId}`);
      yield put({
        type: Types.GET_COMPANY_CONTACT_LOGIC_RESPONSE,
        payload: { companyId, index, cycles: response }
      });

      yield put(message("Company Contact Logic Retrieved"));

  } catch (error) {
    yield put(errorMessage("Something went wrong!"));
    yield put({
      type: Types.GET_COMPANY_CONTACT_LOGIC_FAILURE,
      payload: { index }
    });
  }
}

function* updateContactLogicCycleActionEnabled({ payload }) {
  const { cycleId, actionIds, enabled } = payload;
  try {
    yield put({
      type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLE_ACTION_ENABLED_REQUEST,
      payload: { cycleId, actionIds, enabled }
    });
    const url = `contactlogic/${cycleId}/actions/${
      enabled ? "enable" : "disable"
    }`;
    yield call(api.put, url, actionIds);
    yield put({
      type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLE_ACTION_ENABLED_RESPONSE,
      payload: { cycleId, actionIds, enabled }
    });

    //yield put(message("Actions updated"));
  } catch (error) {
    yield put(errorMessage("Something went wrong!"));
    yield put({
      type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLE_ACTION_ENABLED_FAILURE
    });
  }
}

function* updateContactLogicCycles({ payload }) {
  const { companyId, cycles } = payload;
  try {
    yield put({
      type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLES_REQUEST,
      payload: { companyId, cycles }
    });
    const url = `contactlogic/company/${companyId}/update_contact_logic`;
    const response = yield call(api.put, url, cycles);
    yield put({
      type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLES_RESPONSE,
      payload: { companyId, cycles, response }
    });
    yield put(message("Actions updated"));
  } catch (error) {
    yield put(errorMessage("Something went wrong!"));
    yield put({
      type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLES_FAILURE
    });
  }
}

function* watchGetCompanyContactLogic() {
  while (true) {
    const action = yield take(Types.GET_COMPANY_CONTACT_LOGIC);
    yield call(getCompanyContactLogic, action);
  }
}

function* watchUpdateContactLogicCycleActionEnabled() {
  while (true) {
    const action = yield take(
      Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLE_ACTION_ENABLED
    );
    yield call(updateContactLogicCycleActionEnabled, action);
  }
}

function* watchUpdateContactLogicCycles() {
  while (true) {
    const action = yield take(Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLES);
    yield call(updateContactLogicCycles, action);
  }
}


export default function* root() {
  yield all([
    watchGetCompanyContactLogic(),
    watchUpdateContactLogicCycleActionEnabled(),
    watchUpdateContactLogicCycles(),
  ]);
}
