import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: this.props.tabs[0].name
    };
  }
  changeTab(tab) {
    this.setState({ tab });
  }

  render() {
    const { tabs, contentStyle = {}, placeholder } = this.props;
    const activeLi = tab =>
      (tab.name === this.state.tab ? styles.activeLi : {});
    return (
      <div>
        <ul style={[styles.ul]}>
          {tabs.map((tab, i) => (
            <li
              style={[styles.li, activeLi(tab)]}
              key={`tab${i}`}
              onClick={() => this.changeTab(tab.name)}
            >
              {tab.name}
            </li>
          ))}
        </ul>
        <div className="tabSet" style={contentStyle}>
          {tabs.map(
            (tab) => (tab.name === this.state.tab ? tab.content : "")
          )}
          <div className="tabContentPlaceholder" style={placeholder.css}>
            {placeholder.s}
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(Tabs);
Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  contentStyle: PropTypes.object,
  placeholder: PropTypes.object
};

const styles = styler`
  ul
    display: flex
  li
    background-color: #EDEDED
    border-color: #DDD
    border-style: solid
    border-width: 1px 0 0 1px
    cursor: pointer
    display: table-cell
    flex: 1
    padding: 10px 5px
    text-align: center
    :hover
      background-color: #DDD
  activeLi
    background-color: #DDD
  liLast
    border-right: 1px solid #DDD
`;
