import React from "react";
import PropTypes from "prop-types";
import IoMdMenu from "react-icons/lib/io/android-menu";
import { useOutsideClick } from "hooks";

export const MenuItem = ({ children, onClick, sublist, disabled }) => {
  function onMenuItemClick(e) {
    if (!disabled && onClick) onClick(e);
  }

  const className = `menu-item ${disabled ? "disabled" : ""}`;

  return (
    <div className={className} onClick={onMenuItemClick}>
      <div>{children}</div>
      {sublist && (
        <div className="menu menu-sub-list">
          {sublist.map(({ label, onClick: subItemClick }) => (
            <div className="menu-item" key={label} onClick={subItemClick}>
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  sublist: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func
    })
  )
};

export const Menu = ({ children, disabled }) => {
  const [showMenu, setShowMenu] = React.useState(false);

  const menuRef = React.createRef();
  useOutsideClick(menuRef, () => setShowMenu(false));
  return (
    <div
      className={disabled ? "menu-container-disabled" : "menu-container"}
      ref={menuRef}
    >
      <i className="menu-icon" onClick={() => setShowMenu(true)}>
        <IoMdMenu />
      </i>
      {showMenu && (
        <React.Fragment>
          <div className="menu">
            <div onClick={() => setShowMenu(true)}>{children}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired
};

Menu.defaultProps = {
  disabled: false
};
