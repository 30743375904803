import React from "react";
import PropTypes from "prop-types";

export const QuantityInput = ({
  line_item_id,
  disabled,
  quantity,
  requestUpdateLineItemById
}) => {
  const [value, setValue] = React.useState(quantity || 0);
  React.useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const handleChange = ({ target: { value } }) => {
    if (!value || value > 0) setValue(value);
  };

  const handleBlur = () => {
    if (value !== quantity) {
      if (value > 9) {
        if (
          window.confirm(
            `Are you sure you want to set quantity to ${value} for this item?`
          )
        )
          requestUpdateLineItemById({
            line_item_id,
            quantity: value
          });
        else {
          setValue(quantity);
        }
      } else
        requestUpdateLineItemById({
          line_item_id,
          quantity: value
        });
    }
  };

  return (
    <input
      className="quantity-input"
      type="number"
      min="1"
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

QuantityInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  quantity: PropTypes.number,
  requestUpdateLineItemById: PropTypes.func.isRequired
};

export const ZeroBilledInput = ({
  value,
  line_item_id,
  requestUpdateLineItemById,
  disabled
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1
    }}
  >
    <input
      type="checkbox"
      checked={!!value}
      disabled={disabled}
      onChange={async ({ target }) => {
        await requestUpdateLineItemById({
          line_item_id,
          zeroChargeItem: target.checked
        });
      }}
    />
  </div>
);

ZeroBilledInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  requestUpdateLineItemById: PropTypes.func.isRequired,
  value: PropTypes.bool
};
