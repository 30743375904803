import React from 'react'
import PropTypes from 'prop-types'
import flow from 'lodash/fp/flow'
import chunk from 'lodash/fp/chunk'
import zipAll from 'lodash/fp/zipAll'

const FieldTable = ({
  rows,
  columnCount
}) => {
  const visualRows = flow(
    chunk(Math.ceil(rows.length / columnCount)),
    zipAll
  )(rows)
  return (
    <table className='s3-ui-field-table'>
      <tbody>
        {visualRows.map((row, index) => (
          <tr key={index}>
            {row.map((data, index) => ([
              <td key={`th-${index}`} className={data ? 'has-field' : ''}>{data && data.field}</td>,
              <td key={`td-${index}`}>{data && data.value}</td>
            ]))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

FieldTable.defaultProps = {
  rows: [],
  columnCount: 1
}

FieldTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.value
  })).isRequired,
  columnCount: PropTypes.number.isRequired
}

export default FieldTable
