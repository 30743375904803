import React from "react";
import { PureModal } from "components/ui";
import CancelAppointmentConfirmation from "./index.jsx"
import PropTypes from "prop-types";

export default function CardCancelAppointmentButton({ setIsSubmitting, chat_id, onSuccess, slot_id, patientsToBeNotified }) {

    return (
        <PureModal
            maxWidth="sm"
            fullWidth
            renderTrigger={({ openModal }) => (
                <button
                    className="appointment-action-button cancel-appointment-button"
                    style={{ marginLeft: 10 }}
                    onClick={() => { openModal() }}
                >
                    Cancel Appointment
                </button>
            )}
            renderContent={({ closeModal }) => (
                <CancelAppointmentConfirmation
                    setIsSubmitting={setIsSubmitting}
                    chat_id={chat_id}
                    onSuccess={onSuccess}
                    closeModal={closeModal}
                    slot_id={slot_id}
                    patientsToBeNotified={patientsToBeNotified}
                />
            )}

        />
    )
}

CardCancelAppointmentButton.propTypes = {
    setIsSubmitting: PropTypes.func, 
    chat_id: PropTypes.string, 
    onSuccess: PropTypes.func, 
    slot_id: PropTypes.string, 
    patientsToBeNotified: PropTypes.number
}