import { get, put, post } from "./utils/api";
import { snakeCase } from "./utils/misc";
import C from "./action-types";
import { browserHistory } from "browser-history";
import "object-assign";
import {
  fetchPatientWalkInOrders,
  invalidatePatientWalkInOrders
} from "actions/patient";
import { message, errorMessage } from "actions/message";
import moment from "moment";

export const getSearchFormAttributes = () => ({
  type: C.SEARCH.GET_VALUES,
  callAPI: () => get("search/values")
});

export function setReady(refs, val = true) {
  return {
    type: C.READY,
    refs,
    val
  };
}

/* TODO userID unused param */
export function getOrdersSnapshot(userID, startDate, endDate) {
  const url = `orders/snapshot/new?startDate=${moment(startDate).format(
    "MM/DD/YYYY"
  )}&endDate=${moment(endDate).format("MM/DD/YYYY")}`;
  return {
    type: C.SNAPSHOT.ORDERS,
    callAPI: () => get(url)
  };
}
// Patients --------------------------------------------------------------------
export function getPatientsSnapshot(user) {
  const url = "patients/snapshot";
  return {
    type: C.SNAPSHOT.PATIENTS,
    callAPI: () => get(url, { id: user })
  };
}

export function getPatientProfile(patientId) {
  const url = "patients/" + patientId;
  return {
    type: C.PATIENT.GET_PROFILE,
    callAPI: () => get(url)
  };
}

export const createWalkInOrder = (patientId, { order_date, user_id }) => ({
  type: C.PATIENT.CREATE_WALK_IN_ORDER,
  callAPI: () =>
    post(`patients/${patientId}/walk_in_orders`, {
      order_date: moment(order_date).format("MM/DD/YYYY"),
      user_id
    }),
  callback: dispatch => {
    dispatch(invalidatePatientWalkInOrders({ patientId }));
    dispatch(fetchPatientWalkInOrders({ patientId }));
  },
  customMsg: "Walk in order created!"
});

/* TODO don't need to pass in callback fn */
export function createContactRecord(patientId, contactRecord, callback) {
  const url = `patients/${patientId}/contact_records`;
  return {
    type: C.PATIENT.CREATE_CONTACT_RECORD,
    callAPI: () => post(url, contactRecord),
    callback,
    customMsg: "Contact Record created!"
  };
}

// Orders ----------------------------------------------------------------------
// export function getOrdersForPrint(ids) {
//   const url = `orders/print-packing-slips`;
//   const params = { ids: ids };
//   return {
//     type: C.ORDERS.ORDERS_FOR_PRINT,
//     callAPI: () => put(url, params)
//   };
// }

export function exportTable(query, userId, table) {
  const jsQuery = query.toJS();

  let filterArray = [];
  if (jsQuery.filter) {
    for (var column in jsQuery.filter) {
      const value = jsQuery.filter[column];
      if (value !== "") {
        filterArray.push({
          by: column,
          value
        });
      }
    }
  }

  const payload = {
    sort: {
      by: query.get("sort_by"),
      direction: query.get("direction")
    },
    filter_by: filterArray,
    pagination: {
      page: query.get("page"),
      per: query.get("per")
    },
    search_for: {
      by: query.get("search_by"),
      value: query.get("search_value")
    }
  };
  return {
    type: C.EXPORTS.TABLE,
    callAPI: () => put(`${table}/export`, payload),
    callback: dispatch => {
      dispatch(getExports(userId));
      browserHistory.push(`/exports`);
    }
  };
}

export const getExports = userID => ({
  type: C.EXPORTS.GET,
  callAPI: () => get("exports", { id: userID })
});

export const updateOrderTableClaimedBy = (orderID, userName) => ({
  type: C.ORDERS.UPDATE_TABLE,
  orderID,
  userName
});

// User Form && Profile----------------------------------------------------------------------
export function getUsers(path) {
  return {
    type: C.USERS.GET,
    callAPI: () => get(`users?type=${snakeCase(path)}`),
    payload: { path },
    ready: { refs: ["users", "tableReady"] }
  };
}

export function getUser(id) {
  return {
    type: C.USER.GET,
    callAPI: () => get(`users/${id}`),
    ready: { refs: [["users", "profileReady"]] }
  };
}

export const updateUser = ({ id, user }) => async dispatch => {
  try {
    dispatch({ type: C.USER.SET });
    await put(`users/${id}`, user);
    dispatch(message("User updated!"));
    browserHistory.goBack();
  } catch (error) {
    dispatch(errorMessage(`User updated failed, ${error}`));
  }
};

export const createUser = ({ user }) => async dispatch => {
  try {
    dispatch({ type: C.USER.SET });
    if (!user.role)
      throw Error(
        "No user type provided"
      ); /* is possible form is not validating this correctly */
    const userId = await post("users", user);
    dispatch(message("User Created!"));
    browserHistory.push(`/users/${userId}`);
  } catch (error) {
    dispatch(errorMessage(`User Create failed, ${error}`));
  }
};
