import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Waiting } from "components/ui";
import { loginWithToken } from "actions/authentication";
import { useDispatch } from "react-redux";
// import useSafeSetState from "hooks/useSafeSetState";

const parseQueryString = string => {
  return string
    .slice(1)
    .split("&")
    .map(queryParam => {
      const data = queryParam.split("=");
      return { key: data[0], value: data[1] };
    })
    .reduce(
      (query, data) => ({
        ...query,
        [data.key]: data.value
      }),
      {}
    );
};

const SsoAuthentication = () => {
  const { search } = useLocation();
  const { token } = parseQueryString(search);
  const dispatch = useDispatch();
  // const [isFetching, setIsFetching] = useSafeSetState(false);
  useEffect(() => {
    ssoLogin(token);
  }, [token]);
  async function ssoLogin(token) {
    try {
      // setIsFetching(true);
      await dispatch(loginWithToken({ token }));
    } finally {
      // setIsFetching(false);
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Waiting reason="Redirecting you to correct site" />
    </div>
  );
};

export default SsoAuthentication;
