import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, InlineWaiting } from "components/ui";
import SaveIcon from "react-icons/lib/md/save";
import CancelIcon from "react-icons/lib/md/cancel";
import SearchableDropdown from "components/searchable-dropdown";
import MdDelete from "react-icons/lib/md/delete";
import { formatDate } from "utils/misc";
import { Ul, AttributeItem } from "components/lists";

const EditPatientInsurance = ({
  patientInsurance,
  insuranceOptions,
  guarantorOptions,
  toggleInsurance,
  updatePatientInsurance,
  insuranceOrder
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [insuranceEdit, setInsuranceEdit] = useState({
    insurance_company_id: patientInsurance.insurance_company_id,
    group_number: patientInsurance.group_number,
    policy_number: patientInsurance.policy_number,
    guarantor_relation: patientInsurance.guarantor_relation,
    policy_start_date: formatDate(
      patientInsurance.policy_start_date,
      "YYYY-MM-DD"
    ),
    policy_end_date: formatDate(patientInsurance.policy_end_date, "YYYY-MM-DD"),
    primary_order: insuranceOrder == "Primary" ? 1 : 2,
    insurance_type_id: patientInsurance.insurance_type_id
  });

  const handleChanges = e => {
    setInsuranceEdit({ ...insuranceEdit, [e.target.name]: e.target.value });
  };

  const submitPatientChanges = async () => {
    setIsUpdating(true);
    await updatePatientInsurance(insuranceEdit);
    setIsUpdating(false);
    toggleInsurance();
  };
  const submitDeleteInsurance = async () => {
    if (confirm("Are you sure you want to delete this?"))
      try {
        setIsUpdating(true);
        await updatePatientInsurance({
          ...insuranceEdit,
          insurance_company_id: "",
          insurance_type_id: ""
        });
        setIsUpdating(false);
      } catch (e) {
        setIsUpdating(false);
      }
    else toggleInsurance();
  };
  const clearInsuranceChanges = () => {
    setInsuranceEdit(null);
  };

  return (
    <div className="patient-insurance-edit">
      <div className="insurance-section-header">
        {insuranceOrder}{" "}
        {isUpdating ? (
          <span style={{ float: "right", maxWidth: 25, paddingRight: 5 }}>
            <InlineWaiting />
          </span>
        ) : (
          <span style={{ float: "right" }}>
            {/* only display delete button if we are looking at secondary insurance and it exists */}
            {patientInsurance?.insurance_company_id != null &&
              insuranceOrder == "Secondary" && (
                <IconButton
                  onClick={() => {
                    const confirmation = confirm(
                      "Are you sure you want to delete insurance?"
                    );
                    if (confirmation) {
                      submitDeleteInsurance();
                      toggleInsurance();
                    }
                  }}
                  text="Delete"
                  style={{ color: "red" }}
                  icon={<MdDelete />}
                  type="button"
                />
              )}
            <IconButton
              onClick={() => {
                toggleInsurance(), clearInsuranceChanges();
              }}
              text="Cancel"
              style={{ color: "gray" }}
              icon={<CancelIcon />}
              type="button"
            />
            <IconButton
              text="Save"
              style={{ color: "green" }}
              icon={<SaveIcon />}
              onClick={submitPatientChanges}
            />
          </span>
        )}
      </div>
      <Ul>
        <AttributeItem title="Insurance:">
          <SearchableDropdown
            options={[{ text: "", value: "", key: "" }, ...insuranceOptions]}
            selected={insuranceEdit.insurance_company_id}
            onSelect={value => {
              setInsuranceEdit(ins => ({
                ...ins,
                insurance_company_id: value
              }));
            }}
          />
        </AttributeItem>
        <AttributeItem title="Insurance Type:">
          {patientInsurance.insurance_type_name}
        </AttributeItem>
        {insuranceEdit.insurance_company_id && (
          <>
            <AttributeItem title="Policy Number:">
              <input
                style={{ height: "1rem", margin: 0 }}
                type="text"
                onChange={e => handleChanges(e)}
                name="policy_number"
                value={insuranceEdit.policy_number}
              />
            </AttributeItem>
            <AttributeItem title="Group Number:">
              <input
                style={{ height: "1rem", margin: 0 }}
                type="text"
                onChange={e => handleChanges(e)}
                name="group_number"
                value={insuranceEdit.group_number}
              />
            </AttributeItem>
            <AttributeItem title="Guarantor:">
              <select
                style={{
                  height: "1.1rem",
                  margin: 0,
                  padding: "0px 0px 0px 5px",
                  display: "flex",
                  alignItems: "center"
                }}
                onChange={handleChanges}
                name="guarantor_relation"
                value={insuranceEdit.guarantor_relation}
              >
                <option />
                {guarantorOptions.map(({ text, value, key }) => (
                  <option key={key} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </AttributeItem>
            <AttributeItem title="Start Date:">
              <input
                style={{ height: "1rem", margin: 0 }}
                type="date"
                onChange={e => handleChanges(e)}
                name="policy_start_date"
                value={insuranceEdit.policy_start_date}
              />
            </AttributeItem>
            <AttributeItem title="End Date:">
              <input
                style={{ height: "1rem", margin: 0 }}
                type="date"
                onChange={e => handleChanges(e)}
                name="policy_end_date"
                value={insuranceEdit.policy_end_date}
              />
            </AttributeItem>
          </>
        )}
      </Ul>
    </div>
  );
};

EditPatientInsurance.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInsurance: PropTypes.object.isRequired,
  insuranceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  updatePatientInsurance: PropTypes.func.isRequired,
  guarantorOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool.isRequired,
  toggleInsurance: PropTypes.func.isRequired,
  insuranceOrder: PropTypes.oneOf(["Primary", "Secondary"])
};

EditPatientInsurance.defaultProps = {
  readOnly: false
};

export default EditPatientInsurance;
