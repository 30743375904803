import React from "react";
import PropTypes from "prop-types";

const PatientStatus = ({ patientInfo }) => {

  return (
    <div className={`patient-status-bar ${patientInfo?.active ? patientInfo?.on_hold ? "patient-on-hold" : "patient-active" : "patient-inactive"}`}>
        <h6 className='general-status-container'>{patientInfo?.active ? patientInfo?.on_hold ? "On Hold" : "Active" : "Inactive"}</h6>
    </div>
  )
};

PatientStatus.propTypes = {
  patientInfo: PropTypes.object.isRequired
};

export default PatientStatus;
