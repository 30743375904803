export const colors = {
  brazen: "#156153",
  newMexico: "#0E6E60",
  tealElephant: "#249C91",
  theCreep: "#2DC6B8",
  babyMint: "#BBE2D7",
  takeCare: "#DFF6F0",
  buttercups: "#FFE545",
  vitaminC: "#FF9900",
  marty: "#FA2A00",
  hannah: "#D3398B",
  trueAqua: "#49C6CE",
  trueBlue: "#2973B4",
  white: "#FFFFFF",
  ghost: "#FAFAFA",
  snow: "#F9F9F9",
  vapor: "#F6F6F6",
  whiteSmoke: "#F5F5F5",
  silver: "#EFEFEF",
  smoke: "#EEEEEE",
  gainsboro: "#DDDDDD",
  iron: "#CCCCCC",
  base: "#AAAAAA",
  aluminum: "#999999",
  jumbo: "#888888",
  monsoon: "#777777",
  steel: "#666666",
  charcoal: "#555555",
  tuatara: "#444444",
  oil: "#333333",
  jet: "#222222",
  black: "#000000",
  baseGreen: "#042C29",
  linkGreen: "#2DC6B8",
  primaryColor: "#4281A6",
  secondaryColor: "#e7e7e7",
  alertColor: "#B11623",
  successColor: "#43AC6A",
  warningColor: "#f08a24",
  infoColor: "#a0d3e8"
};

export const ICON_COLOR = '#38c6b8'
export const ICON_FONT_SIZE = "17"

export const shadows = {
  depth1: "0 2px 10px 0 rgba(0,0,0,.08)",
  depth2: "0 6px 20px 0 rgba(0,0,0,.19)",
  depth3: "0 17px 50px 0 rgba(0,0,0,.19)",
  depth4: "0 25px 55px 0 rgba(0,0,0,.21)",
  depth5: "0 40px 77px 0 rgba(0,0,0,.22)"
};

export const graphColors = [
  "#25a8e0",
  "#0090bc",
  "#123290",
  "#00928a",
  "#1aa79c", //logo colors
  "#E7DFB9",
  "#A9AF9E",
  "#7FBA81",
  "#FD5250",
  "#E9BD5B",
  "#F38630",
  "#1D99A9",
  "#134353",
  "#8D6854",
  "#6FE2DB",
  "#99C7B7",
  "#CC4530",
  "#9CA376",
  "#F39237",
  "#2dc6b8"
];

export const defaultMargin = 25;

export const spacing = {
  default: defaultMargin
};

export const absoluteCenter = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: "auto",
  width: "auto"
};

export const buttonRow = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "1em",
  float: "left",
  padding: "0 0 0 13px"
};

export const aLink = {
  color: colors.primaryColor,
  cursor: "pointer"
};
