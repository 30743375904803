import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { get } from "utils/api";
import { path } from "ramda";
import { formatDate } from "utils/misc";
import MdCheckmark from "react-icons/lib/io/checkmark";
import IoMdClose from "react-icons/lib/io/close";
import { Spinner } from "components/ui";

const SpecialFieldsTab = ({ patientId }) => {
  const {
    response: specialFields,
    isFetching,
    fetchData
  } = useFetch({
    apiFn: patientId => get(`patients/${patientId}/special-fields`),
    defaultValue: [],
    // transformResponse: prop("cmn_notes"),
    transformError: path(["response", "body", "status"])
  });

  React.useEffect(() => {
    fetchData(patientId);
  }, [patientId]);

  return (
    <div>
      {!isFetching ? (
        <div className="special-fields">
          <div>
            <h4>Authorization Required: </h4>
            <div style={{ alignSelf: "center" }}>
              {specialFields.authorization_required ? (
                <MdCheckmark />
              ) : (
                <IoMdClose />
              )}
            </div>
          </div>
          <div>
            <h4>Ventilator Patient: </h4>
            <div style={{ alignSelf: "center" }}>
              {specialFields.ventilator_patient ? (
                <MdCheckmark />
              ) : (
                <IoMdClose />
              )}
            </div>
          </div>
          <div>
            <h4>Baseline Sleep Study Date: </h4>
            <h5 style={{ alignSelf: "center" }}>
              {specialFields.baseline_sleep_study_date == null
                ? "Unknown"
                : formatDate(specialFields.baseline_sleep_study_date)}
            </h5>
          </div>
          <div>
            <h4>Face to Face Expiration: </h4>
            <h5 style={{ alignSelf: "center" }}>
              {specialFields.face_to_face_expiration == null
                ? "Unknown"
                : formatDate(specialFields.face_to_face_expiration)}
            </h5>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

SpecialFieldsTab.propTypes = {
  patientId: PropTypes.string.isRequired
};

export default SpecialFieldsTab;
