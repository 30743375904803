import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import CompanyEquipmentSoldTable from "./table";
import CompanyEquipmentSoldGraph from "./graph";
import MdShowChart from "react-icons/lib/md/show-chart";
import MdViewHeadline from "react-icons/lib/md/view-headline";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const CompanyEquipmentSold = ({ data, minimizeAll }) => {
  const [mode, setMode] = useState("table");
  const [isMinimized, setMinimized] = useState(false);
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  return (
    <div className="block equipment">
      <div className="block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Equipment Sold
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
        <i
          style={{ alignSelf: "flex-end" }}
          className="flat-icon-button ripple"
          onClick={() => setMode(() => (mode === "table" ? "graph" : "table"))}
        >
          {mode === "table" ? <MdShowChart /> : <MdViewHeadline />}
        </i>
      </div>
      {!isMinimized && (
        <div className="block-content">
          {mode === "table" ? (
            <CompanyEquipmentSoldTable data={data} />
          ) : (
            <CompanyEquipmentSoldGraph data={data} />
          )}
        </div>
      )}
    </div>
  );
};

CompanyEquipmentSold.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool
};

export default connect(state => ({
  data: selectors.getTeamEquipmentSold(state)
}))(CompanyEquipmentSold);
