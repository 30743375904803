import React, { useState } from "react";
import PropTypes from "prop-types";
import { InlineWaiting, IconButton } from "components/ui";
import useRaEligibility from "./use-ra-eligibility";
import MdVerifiedUser from "react-icons/lib/md/verified-user";
import ElgibilityWindow from "./eligibility-window";
import InsuranceVerificationDetails from "./insurance-verification-details";

const RaEligibityBtn = ({ orderId }) => {
  const [raLink, setRaLink] = useState("");
  const {
    isFetching,
    checkEligibility,
    insVerifications,
    getInsuranceVerifications
  } = useRaEligibility({
    orderId,
    setRaLink
  });
  return (
    <div style={{ gridColumn: "-1/1", paddingTop: 20 }} className="no-print">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 5,
          alignItems: "center"
        }}
      >
        <div>
          {isFetching ? (
            <InlineWaiting />
          ) : (
            <IconButton
              icon={<MdVerifiedUser />}
              onClick={() => checkEligibility(orderId)}
              text="Insurance Verification"
              style={{ padding: 8 }}
            />
          )}
          {raLink && (
            <ElgibilityWindow
              raLink={raLink}
              setRaLink={setRaLink}
              onClose={() => {
                getInsuranceVerifications({ orderId, pullData: true });
              }}
            />
          )}
        </div>
        <InsuranceVerificationDetails
          insVerifications={insVerifications}
          orderId={orderId}
        />
      </div>
    </div>
  );
};

RaEligibityBtn.propTypes = { orderId: PropTypes.string.isRequired };

export default RaEligibityBtn;
