import * as api from "utils/api";
import { errorMessage } from "actions/message";
import * as msg from "actions/message";

export const Types = {
  REQUEST_TEAM_INSIGHTS: "REQUEST_TEAM_INSIGHTS",
  RESPONSE_TEAM_INSIGHTS: "RESPONSE_TEAM_INSIGHTS",
  ERROR_TEAM_INSIGHTS: "ERROR_TEAM_INSIGHTS",
  UPDATED_COACH_TOTAL_TIME: "UPDATED_COACH_TOTAL_TIME",
  UPDATED_COACH_QUEUE_TIME: "UPDATED_COACH_QUEUE_TIME"
};

export const requestTeamInsights =
  (team_name, { start_date, end_date }) =>
  async dispatch => {
    try {
      dispatch({
        type: Types.REQUEST_TEAM_INSIGHTS,
        meta: {
          team_name,
          start_date,
          end_date
        }
      });
      const result = await api.put("team-dashboard", {
        team_name,
        start_date,
        end_date
      });
      dispatch({
        type: Types.RESPONSE_TEAM_INSIGHTS,
        payload: result,
        meta: {
          team_name,
          start_date,
          end_date
        }
      });
    } catch (e) {
      dispatch(errorMessage("Sorry something went wrong"));
      dispatch({
        type: Types.ERROR_TEAM_INSIGHTS
      });
    }
  };

export const updateTeamAllocation = values => async dispatch => {
  try {
    await api.put(`allocation`, values);
    dispatch(msg.message("Allocation updated"));
  } catch (e) {
    dispatch(msg.errorMessage("Something went wrong, failed to update team"));
  }
};
