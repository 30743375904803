import React, { useState } from "react";
import PropTypes from "prop-types";
import PureModal from "components/ui/modal/pure-modal";
import MdSettings from "react-icons/lib/md/settings";
import { assocPath, pathOr } from "ramda";

const TableSettingsNew = ({ updateTableSettings, tableSettings }) => {
  const [tmpTableSettings, setTmpTableSettings] = useState(tableSettings);
  const columns = Object.keys(tableSettings);
  const toggleColumn = (key, checked) => {
    setTmpTableSettings(settings =>
      assocPath([key, "active"], checked, settings)
    );
  };
  const isColumnVisible = key =>
    pathOr(true, [key, "active"], tmpTableSettings);
  return (
    <PureModal
      renderTrigger={({ openModal }) => (
        <span className="icon-button" onClick={openModal}>
          <MdSettings />
        </span>
      )}
      style={{ overflow: "hidden" }}
      renderContent={({ closeModal }) => (
        <div style={{ padding: "10px 0px" }}>
          <h5 style={{ paddingLeft: 5 }}>Table Columns</h5>
          <div
            style={{
              padding: 5,
              overflow: "auto",
              border: "1px solid #ccc",
              maxHeight: "calc(100vh - 305px)",
              display: "grid",
              gridTemplateColumns: "1fr 1fr"
            }}
          >
            {columns.map(col => (
              <label
                key={col}
                style={{
                  marginLeft: 20,
                  padding: 5,
                  display: "flex",
                  fontSize: 16,
                  alignItems: "center"
                }}
              >
                <input
                  type="checkbox"
                  checked={isColumnVisible(col)}
                  onChange={({ target: { checked } }) =>
                    toggleColumn(col, checked)
                  }
                  style={{ margin: 0 }}
                />
                <span style={{ marginLeft: 10 }}>{col}</span>
              </label>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              marginTop: 5,
              padding: 5,
              justifyContent: "space-between"
            }}
          >
            <button
              onClick={() => {
                closeModal();
              }}
              className="button warning danger"
              style={{ margin: 0 }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                updateTableSettings(tmpTableSettings);
                closeModal();
              }}
              style={{ margin: 0 }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    />
  );
};

TableSettingsNew.propTypes = {
  updateTableSettings: PropTypes.func.isRequired,
  tableSettings: PropTypes.object.isRequired
};

export default TableSettingsNew;
