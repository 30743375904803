import { GET_QUESTIONNAIRE, DELETE_QUESTIONNAIRE, UPDATE_STATUS_QUESTIONNAIRE, GET_QUESTIONNAIRELIST, SET_CLEAR } from './../ActionTypes';
import API from "./../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage , message } from "./../../../../actions/message";

export const getQuestionnaire = () => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/questionnaires";
    //const url = "/dme_portal/products";
    API().get(url).then(res => {
        if (res && res.data && res.data.success) {
            if (res && res.data && res.data.questionnaires) {
                dispatch(hideLoader());
                return dispatch({
                    type: GET_QUESTIONNAIRE,
                    payload: res.data.questionnaires
                });
            }
        }else{
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_QUESTIONNAIRE,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err);
        dispatch(hideLoader());
        return dispatch({
            type: GET_QUESTIONNAIRE,
            payload: []
        });
    })
}

export const getQuestionnaireList = () => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/questionnaires";
    //const url = "/dme_portal/products";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res && res.data && res.data.questionnaires) {
                const filterObj = res.data.questionnaires.map((data) => {
                    let newtitle = data.title;
                    if (data.questionnaire_type === "2") {
                        newtitle = "Product Type - " + data.title;
                    }
                    else {
                        newtitle = "Service Type - " + data.title;
                    }
                    return {label: newtitle,value: data.questionnaire_id, active: data.active};
                });
                return dispatch({
                    type: GET_QUESTIONNAIRELIST,
                    payload: filterObj
                });
            }
        }else{
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_QUESTIONNAIRELIST,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err);
        dispatch(hideLoader());
        return dispatch({
            type: GET_QUESTIONNAIRELIST,
            payload: []
        });
    })
}

export const createQuestionnaire = (data) => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/questionnaires";
    API().post(url, data).then(res => {
        if (res && res.data && res.data.success) {
            dispatch(hideLoader());
            dispatch(message(res.data.message));
            dispatch(getQuestionnaire());
        } else {
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const deleteQuestionnaire = (qid) => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/questionnaires/" + qid;
    API().delete(url).then(res => {
        if (res && res.data && res.data.success) {
            dispatch(hideLoader());
            return dispatch({
                type: DELETE_QUESTIONNAIRE,
                payload: qid
            });
        }else{
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const updateQuestionnaire = (data) => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/questionnaires";
    API().put(url, data).then(res => {
        if (res && res.data && res.data.success) {
            dispatch(hideLoader());
            dispatch(message(res.data.message));
            dispatch(getQuestionnaire());
        }else{
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const updateStatusQuestionnaire = (data, status) => dispatch => {
    data.active = status;
    dispatch(showLoader());
    const url = "/dme_portal/questionnaires";
    API().put(url, data).then(res => {
        if (res && res.data && res.data.success) {
            dispatch(hideLoader());
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_STATUS_QUESTIONNAIRE,
                payload: data
            });
        }else{
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const setClearStatus = (status) => dispatch => {
    return dispatch({
        type: SET_CLEAR,
        payload: status
    });
}

export const assignQuestionnaireToPatient = (questionnaire_id, patients) => dispatch => {
    let createObj = {
        patient_ids: patients,
        questionnaire_id: questionnaire_id
    };
    dispatch(showLoader());
    const url = "/dme_portal/questionnaires/assignment";
    API().post(url, createObj).then(res => {
        if (res && res.data && res.data.success) {
            dispatch(setClearStatus(true));
            dispatch(hideLoader());
            dispatch(message(res.data.message));
        } else {
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}
