export const scheduleTypeOptions = [
  { value: 1, text: "Text Chat" },
  { value: 2, text: "Video Chat" },
  { value: 3, text: "Product Demo" }
];

export const getChatType = type => {
  switch (type) {
    case "Text Chat":
      return 1;
    case "Video Chat":
      return 2;
    case "Product Demo":
      return 3;
  }
};
