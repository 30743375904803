import C from "action-types";
import { search } from "actions/search"
import { put } from "utils/api"

const updateSearchQuery = (query) => ({
  type: C.SEARCH.SEARCH_TABLE_QUERY_UPDATE,
  query
})

// entityKey is optional
export const updateSearchTableQuery = (query, entityKey) => dispatch => {
  dispatch(updateSearchQuery(query))
  dispatch(search(query.table, entityKey))
}

export const updateOrderThenSearchFetch = (orderId, order) => dispatch => {
  put(`orders/${orderId}`, order).then(() => {
    dispatch(search("orders"))
  })
}
