import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PureModal from "components/ui/modal/pure-modal";
import { selectors } from "features/google-api/reducers";
import { dettachCompanyFile } from "features/google-api/actions";

class DetachCompanyFile extends Component {
  detachCompanyFile = () => {
    const { companyId, dettachCompanyFile, file } = this.props;
    dettachCompanyFile(companyId, file.id, true);
  };

  render() {
    const { isLoggedIn, file } = this.props;
    return isLoggedIn ? (
      <PureModal
        persistent={true}
        large
        renderTrigger={({ openModal }) => (
          <span onClick={openModal}>
            <span style={{ color: "red", cursor: "pointer" }}>Remove</span>
          </span>
        )}
        renderContent={({ closeModal }) => (
          <div>
            <h2>Remove Company File {file.name}</h2>
            <div
              style={{
                padding: "10px"
              }}
            >
              <h5>Are you sure you want to continue?</h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px"
              }}
            >
              <button className="button alert" onClick={closeModal}>
                No
              </button>
              <button
                className="button success"
                onClick={e => {
                  e.preventDefault();
                  this.detachCompanyFile();
                  closeModal();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        )}
      />
    ) : null;
  }
}

DetachCompanyFile.propTypes = {
  companyId: PropTypes.string.isRequired,
  file: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  dettachCompanyFile: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool
};

export default connect(
  state => ({
    isLoggedIn: selectors.getGapiIsLoggedIn(state)
  }),
  { dettachCompanyFile }
)(DetachCompanyFile);
