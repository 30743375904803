import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import DateRange from "components/ui/date-range";
import DateRangeContext from "../date-range-context";
import { getCompanyContactsReport, exportCompanyInsights } from "actions/company";
import { Waiting } from "components/ui";
import ContactSummary from "./contacts-summary";
import CallDirectionChart from "./call-direction-chart";
import AnsweredCallsChart from "./answered-calls-chart";
import EmailBreakdown from "./email-breakdown";
import SmsBreakdown from "./sms-breakdown";

const CompanyContacts = ({ 
  contactsReport, 
  isFetchingContacts,
  exportCompanyInsights,
  params
}) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeIsValid,
    handleSubmit
  } = React.useContext(DateRangeContext);

  const onExport = (exportType) => {
    const payload = {
      companyId: params.id,
      startDate: startDate,
      endDate: endDate,
      contacts: {
        [exportType]: true,
      },
    }

    exportCompanyInsights(payload);
  }

  return (
    <div className="company-contacts-container">
      <div className="date-range-input">
        <DateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSubmit={handleSubmit}
          isValid={dateRangeIsValid}
          isFetching={isFetchingContacts}
        />
      </div>
      {!isFetchingContacts ? (
        <div className="contact-report-container">
          <ContactSummary contactsReport={contactsReport} />
          <div className="graph-row breakdown-row">
            <EmailBreakdown emailContacts={contactsReport.email} onExport={() => onExport("email")} />
            <SmsBreakdown smsContacts={contactsReport.sms} onExport={() => onExport("sms")} />
          </div>
          <div className="graph-row">
            <CallDirectionChart phoneContacts={contactsReport.phone} onExport={() => onExport("call")} />
            <AnsweredCallsChart phoneContacts={contactsReport.phone} onExport={() => onExport("voicemail")} />
          </div>
        </div>
      ) : (
        <Waiting reason="Gathering contact information..." />
      )}
    </div>
  );
};

export default connect(
  state => ({
    contactsReport: selectors.getCompanyContactsReport(state),
    isFetchingContacts: selectors.getIsFetchingCompanyContactsReport(state),
  }),
  { 
    getCompanyContactsReport, 
    exportCompanyInsights 
  }
)(CompanyContacts);

CompanyContacts.propTypes = {
  getCompanyContactsReport: PropTypes.func,
  params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  contactsReport: PropTypes.shape({
    phone: PropTypes.object,
    email: PropTypes.object,
    sms: PropTypes.object
  }),
  isFetchingContacts: PropTypes.bool,
  exportCompanyInsights: PropTypes.func.isRequired,
};
