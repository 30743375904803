import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { InventoryContext } from "../inventory-provider";
import SearchableDropdown from "components/searchable-dropdown";

const CompatibleItemsCreateForm = ({
  inventoryItems,
  inventoryItem,
  mode
}) => {
  const {
    createRelationship,
    createRelationshipIsFetching
  } = useContext(InventoryContext);
  const [formState, setFormState] = useState({
      new_item_id: "",
  });

  const existingRelationships = [inventoryItem?.id, ...inventoryItem?.parents ?? [], ...inventoryItem?.children ?? []];
  const searchableInventoryOptions = inventoryItems.filter(ii => (inventoryItem.parent ? !ii.parent : ii.parent) && !existingRelationships.some(er => er === ii.id))
            ?.map(x => ({ text: x.name, value: x.id, key: x.value }));

  const handleSearchableSelectChange = value => {
    const updateFormState = {...formState};
    updateFormState.new_item_id = value;
    setFormState(updateFormState);
  };

  const handleSubmition = () => {
    let data = {
        parentId: "",
        childId: ""
    };
    
    if (mode === "parents") {
      data.parentId = formState.new_item_id;
      data.childId = inventoryItem.id;
    } else {
      data.parentId = inventoryItem.id;
      data.childId = formState.new_item_id;
    }
    createRelationship(data);
  };

  return (
    <form
        encType="multipart/form-data"
        onSubmit={(e) => {
            e.preventDefault();
            handleSubmition();
        }}
        >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "50%", margin: "0 auto" }}>
                <SearchableDropdown
                    name="new_item_id"
                    label="Inventory Item"
                    requireSearch={true}
                    selected={formState.new_item_id}
                    options={searchableInventoryOptions}
                    onSelect={handleSearchableSelectChange}
                    style={{ width: "95%", padding: "4.25px" }}
                    />
                <input
                    className="button"
                    type="submit"
                    value={mode === "children" ? "Add Child" : "Add Parent"}
                    disabled={!formState.new_item_id || createRelationshipIsFetching}
                    style={{ margin: "0" }}
                    />
            </div>
    </form>
  );
};

CompatibleItemsCreateForm.propTypes = {
  inventoryItems: PropTypes.arrayOf(PropTypes.object),
  inventoryItem: PropTypes.shape({
    id: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.string),
    parents: PropTypes.arrayOf(PropTypes.string),
    parent: PropTypes.bool
  }).isRequired,
  mode: PropTypes.string.isRequired
};

export default connect((state) => ({
    inventoryItems: selectors.getInventoryItems(state),
  }))(CompatibleItemsCreateForm);