import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";
import { colors } from "../../utils/styles";

export const SumBlock = ({ value, title, tooltip, subLabel }) => (
  <div style={S.base}>
    <div style={S.title}>
      <span data-tooltip tabIndex="1" title={tooltip}>
        {typeof title === "function" ? title() : title}
      </span>
    </div>
    <div style={S.value}>
      <span data-tooltip tabIndex="1" title={value}>
        {value || 0}
      </span>
    </div>
    <div style={S.subLabel}>{subLabel}</div>
  </div>
);

export default Radium(SumBlock);

SumBlock.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,
  styles: PropTypes.object
};

SumBlock.defaultProps = {
  styles: {},
  value: 0
};

const S = styler`
  base
    color: ${colors.white}
    background-image: linear-gradient(rgb(36, 156, 145), rgb(45, 198, 184))
    border-radius: 6px
    box-shadow: 2px 2px 8px 0px #88888861
    width: 100%
    text-align: center
  value
    font-size: 2.8em 
    display: flex
    justify-content: center
    padding: 0.425em
    overflow: hidden
    text-overflow: ellipsis
  subLabel
    font-size: 1.35em 
    color: #f2f2f2
    display: flex
    justify-content: center
    padding: 0 5px
    overflow: hidden
    text-overflow: ellipsis
  title
    font-size: 1.8em
    padding: 0.425em
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
`;
