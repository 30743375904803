import { SET_VIDEOCHAT_DETAILS, SET_TEXTCHAT_DETAILS, SET_DEMOCHAT_DETAILS, SET_API_ERROR, SET_TWILIO_ERROR  } from '../ActionTypes';

const initialState = {
    VideoChatRoomDetails: {},
    TextChatRoomDetails: {},
    ProductDemoRoomDetails: {},
    errorMessage: "",
    twilioErrorMessage: ""
}

const reducerChat = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEXTCHAT_DETAILS:
            return {
                ...state,
                TextChatRoomDetails: action.payload
            };
        case SET_VIDEOCHAT_DETAILS:
            return {
                ...state,
                VideoChatRoomDetails: action.payload
            };
        case SET_DEMOCHAT_DETAILS:
            return {
                ...state,
                ProductDemoRoomDetails:  action.payload
            };
        case SET_API_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };
        case SET_TWILIO_ERROR:
            return {
                ...state,
                twilioErrorMessage: action.payload
            };
        default:
            return state;
    }
}

export default reducerChat;
