import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isValidNumber } from "utils/form-validation";
import { Waiting } from "components/ui";
import { withRouter } from "react-router-dom";
import { browserHistory } from "browser-history";
import { selectors } from "reducers";
import {
  updateInventory,
  createInventoryItem,
  setInventoryForm,
  fetchInventoryItemAndSetForm,
  updateInventoryForm,
  fetchInventoryItem,
  invalidateInventoryItem,
  fetchInventory,
  invalidateInventory
} from "actions/inventory";
import PageHeader from "components/ui/page-header";
import InventoryForm from "./inventory-form";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import { getRouteParam } from "routes";

class ManageInventory extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      id,
      inventoryItem,
      setInventoryForm,
      manufacturerOptions,
      invalidateInventory
    } = this.props;
    const inventoryId = id;

    if (inventoryId && !isEmpty(inventoryItem)) {
      setInventoryForm({ form: inventoryItem });
    }
    if (isEmpty(manufacturerOptions)) {
      invalidateInventory();
      fetchInventory();
    }
    if (!inventoryId) {
      this.clearInventory();
    }
  }

  componentWillUnmount() {
    this.clearInventory();
  }

  componentDidUpdate(prevProps) {
    const { id, inventoryItem, setInventoryForm, inventoryForm } = this.props;

    //handle navigating from update to create
    if (!id && prevProps.id) {
      this.clearInventory();
    }
    //handles the refresh of the page, so that the form will populate correctly
    if (inventoryItem.manufacturer != undefined) {
      if (inventoryForm.manufacturer == undefined) {
        setInventoryForm({ form: inventoryItem });
      }
    }
  }

  clearInventory() {
    const form = {
      manufacturer_id: "",
      product_type: "",
      product_style: "",
      product_id: "",
      hcpcs: "",
      name: "",
      description: "",
      avgReimbursment: "",
      productActive: "true",
      quantity: "",
      parents: [],
      children: []
    };
    this.props.setInventoryForm({ form });
  }

  inventoryFormIsValid() {
    let formIsValid = true;
    const { inventoryForm } = this.props;
    let errors = {};
    if (inventoryForm.manufacturer_id.length == 0) {
      errors.manufacturer_id = "Please select a manufacturer";
      formIsValid = false;
    }
    // if (inventoryForm.product_type.length == 0) {
    //   errors.product_type = "Please select a product type";
    //   formIsValid = false;
    // }
    // if (inventoryForm.product_style.length == 0) {
    //   errors.product_style = "Please select a product style";
    //   formIsValid = false;
    // }
    if (inventoryForm.name.length == 0) {
      errors.name = "Please enter a name";
      formIsValid = false;
    }
    if (inventoryForm.product_id.length == 0) {
      errors.product_id = "Please enter a product id";
      formIsValid = false;
    }
    if (inventoryForm.hcpcs.length == 0) {
      errors.hcpcs = "Please enter a valid HCPCS code";
      formIsValid = false;
    }
    if (inventoryForm.description.length == 0) {
      errors.description = "Please enter a valid description";
      formIsValid = false;
    }
    if (!isValidNumber(inventoryForm.quantity)) {
      errors.avgReimbursment = "Please enter a valid number as a quantity";
      formIsValid = false;
    }
    if (!isValidNumber(inventoryForm.avgReimbursment)) {
      errors.avgReimbursment =
        "Please enter a valid number as average reimbursement";
      formIsValid = false;
    }
    const requiresChild = ["E0601", "E0470", "E0471"];
    if (
      requiresChild.includes(inventoryForm.hcpcs) &&
      inventoryForm.children.length === 0
    ) {
      errors.children = "Please select a child inventory item";
      formIsValid = false;
    }
    this.props.updateInventoryForm({ form: { errors } });
    return formIsValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      updateInventory,
      createInventoryItem,
      inventoryForm,
      id,
      hcpcsOptions
    } = this.props;
    if (!this.inventoryFormIsValid()) {
      return;
    }
    const inventoryId = id;
    //deleted company from the omit method
    const selectedHcpc = hcpcsOptions.find(x => x.value == inventoryForm.hcpcs);
    if (inventoryId) {
      updateInventory({
        payload: {
          ...omit(inventoryForm, ["errors"]),
          product_type: selectedHcpc.productType,
          product_style: selectedHcpc?.productStyle
        },
        inventoryId
      }).then(() => {
        browserHistory.push(`/inventory/${inventoryId}`);
      });
    } else {
      //deleted company from the omit method
      createInventoryItem({
        payload: omit(inventoryForm, ["errors"]),
        product_type: selectedHcpc.productType,
        product_style: selectedHcpc?.productStyle
      }).then(response => {
        browserHistory.push(`/inventory/${response.response.id}`);
      });
    }
  }

  onChange({ target }) {
    this.props.updateInventoryForm({ form: { [target.name]: target.value } });
  }

  render() {
    const {
      inventoryForm,
      hcpcsOptions,
      manufacturerOptions,
      equipmentTypeOptions,
      id
    } = this.props;

    return !isEmpty(inventoryForm) ? (
      <div>
        <PageHeader title="Inventory Item">
          <button onClick={browserHistory.goBack}>Go Back</button>
        </PageHeader>
        <InventoryForm
          manufacturerOptions={manufacturerOptions}
          equipmentTypeOptions={equipmentTypeOptions}
          handleSubmit={this.handleSubmit}
          hcpcsOptions={hcpcsOptions}
          form={inventoryForm}
          editing={id ? true : false}
          onChange={this.onChange}
          errors={inventoryForm.errors}
        />
      </div>
    ) : (
      <Waiting />
    );
  }
}

export default withRouter(
  connect(
    (state, props) => {
      const id = getRouteParam(props, "id");
      return {
        manufacturerOptions: selectors.getManufacturerOptions(state),
        equipmentTypeOptions: selectors.getEquipmentTypeOptions(state),
        hcpcsOptions: selectors.getHcpcsOptions(state),
        inventoryForm: state.get("inventory").form,
        inventoryItem: selectors.getInventoryItem(state, id),
        id
      };
    },
    {
      createInventoryItem,
      updateInventory,
      updateInventoryForm,
      setInventoryForm,
      fetchInventoryItemAndSetForm,
      fetchInventoryItem,
      invalidateInventoryItem,
      fetchInventory,
      invalidateInventory
    }
  )(ManageInventory)
);

ManageInventory.propTypes = {
  createInventoryItem: PropTypes.func.isRequired,
  equipmentTypeOptions: PropTypes.array.isRequired,
  id: PropTypes.string,
  invalidateInventory: PropTypes.func.isRequired,
  inventoryForm: PropTypes.object,
  inventoryItem: PropTypes.object,
  manufacturerOptions: PropTypes.array.isRequired,
  setInventoryForm: PropTypes.func.isRequired,
  updateInventory: PropTypes.func.isRequired,
  updateInventoryForm: PropTypes.func.isRequired,
  hcpcsOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};
