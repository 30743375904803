import React from "react";
import PropTypes from "prop-types";
import { graphColors } from "utils/styles";
import { Pie, PieChart, Tooltip, ResponsiveContainer } from "recharts";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from 'react-tooltip';
import ContactStatsList from './contact-stats-list'

const ContactStatistics = ({ insights, onExport }) => (
  <div className="graph-card">
    <div className="card-top">
      <ReactTooltip />
      {onExport && (<div className="stat-export-icon" data-tip="Export" onClick={onExport}><FaFileExcelO /></div>)}
      <h3 className="card-header">Contact Stats</h3>
      <ContactStatsList insights={insights} />
    </div>
    <div className="card-bottom">
      <ResponsiveContainer height={200}>
        <PieChart>
          <Pie
            data={[
              {
                value: insights.getIn(["phone", "outgoing_call_ivr", "successes"]),
                label: "IVR Outgoing Calls",
                fill: graphColors[0]
              },
              {
                value: insights.getIn(["phone", "incoming_call_ivr", "successes"]),
                label: "IVR Incoming Calls",
                fill: graphColors[1]
              },
              {
                value: insights.getIn(["phone", "outgoing_call_sleep_coach", "successes"]),
                label: "Sleep Coach Outgoing Calls",
                fill: graphColors[6]
              },
              {
                value: insights.getIn(["phone", "incoming_call_sleep_coach", "successes"]),
                label: "Sleep Coach Incoming Calls",
                fill: graphColors[7]
              },
              {
                value: insights.getIn(["phone", "left_voicemail", "successes"]),
                label: "Voicemails",
                fill: graphColors[2]
              },
              {
                value: insights.getIn(["email", "success_total"]),
                label: "Emails",
                fill: graphColors[3]
              },
              {
                value: insights.getIn(["sms", "text_message", "successes"]),
                label: "Text Messages",
                fill: graphColors[4]
              },
              {
                value: insights.getIn([
                  "sms",
                  "push_notification",
                  "successes"
                ]),
                label: "Push Notifications",
                fill: graphColors[5]
              }
            ]}
            dataKey="value"
            nameKey="label"
            isAnimationActive={false}
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

ContactStatistics.propTypes = {
  insights: PropTypes.object,
  onExport: PropTypes.func
};

export default ContactStatistics;
