import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from "prop-types";
import CompanyMonthlyReportView from './company-monthly-report-view'
import styler from "react-styling";
import moment from "moment";
import DayPickerRange from 'components/ui/day-picker-range';
import {
  firstDayOfLastMonth,
  lastDayOfLastMonth,
  enumerateYearsBetweenDates,
  monthDates
} from "utils/dates";
import IconButton from "components/ui/icon-button";
import MdPrint from "react-icons/lib/md/print";
import ActivationReportView from './activation-report-view'
import "react-tabs/style/react-tabs.css";
import * as R from "ramda";

// this constant controls the year that the drop down starts with
const YEARS = enumerateYearsBetweenDates('01-01-2015', moment().format('MM-DD-YYYY'))
class CompanyReportView extends Component {
  state = {
    isPrintModalOpen: false,
    selectedTabIndex: 0,
  }

  handleSelectTab = ({ index }) => this.setState({ selectedTabIndex: index })

  formatActivationReportData = () => {
    const formattedData = this.props.activationReport.reduce((acc, curr) => {
      const totalDeactivation = curr.Total_Deactivations
      const totalActivation = curr.Total_Activations

      const monthByMonthData = curr.Data.reduce((acc, curr) => {
        if (!acc[curr.reason] && curr.reason !== null) {
          acc[curr.reason] = curr.counts
        }
        if (!acc.name) {
          acc = {
            name: curr.month,
            totalActivation,
            totalDeactivation,
            ...acc
          }
        }
        return acc
      }, {})

      const reasons = curr.Data.reduce((acc, curr) => {
        if (!acc.includes(curr.reason) && curr.reason !== null) {
          acc.push(curr.reason)
        }
        return acc
      }, [])
      acc.monthByMonthData.push(monthByMonthData)
      acc.reasons = R.uniq([...reasons, ...acc.reasons])
      return acc
    }, { monthByMonthData: [], reasons: [] })
    const orderedMonthByMonthData = formattedData.monthByMonthData.reverse()
    formattedData.monthByMonthData = orderedMonthByMonthData
    return formattedData
  }

  render() {
    const {
      handleSubmit,
      isFetchingActivationReport,
      monthToFetch,
      handleToClick,
      handleFromClick,
      updateDate,
      selectedMonth,
      selectedYear
    } = this.props;
    const month = monthToFetch()
    return (
      (<div>
        <div
          className="row section-header company-report-date-picker"
          style={S.ordersHeader}>
          <div className="row">
            <div className="small-6 columns">
              <h2 style={S.h2}>S3 Platform Orders</h2>
            </div>
            <div className="small-6 columns">
              <IconButton
                onClick={this.props.togglePrintModal}
                style={{ float: 'right' }}
                text="Print"
                icon={<MdPrint size={22} />}
              />
            </div>
          </div>
          <div className="company-monthly-report_tabs-container">
          <Tabs onSelect={index => this.handleSelectTab({ index })}>
            <div  className="row">
              <TabList>
                <div>
                  <Tab>
                    <div>Monthly Report</div>
                  </Tab>
                  <Tab>
                    <div>Activation Report</div>
                  </Tab>
                </div>
              </TabList>
            </div>
            <div className="row"> 
              <TabPanel>
                <form >
                  <div className='row'>
                    <div className='small-9 columns'>
                      <DayPickerRange
                        initStartDate={firstDayOfLastMonth()}
                        initEndDate={lastDayOfLastMonth()}
                        handleFromClick={handleFromClick}
                        handleToClick={handleToClick}
                      />
                    </div>
                    <div className='small-3 columns'>
                      <button
                        type="submit"
                        className="postfix"
                        value="Select Date Range"
                        onClick={handleSubmit}>
                        Submit
                    </button>
                    </div>
                  </div>
                </form>
                <CompanyMonthlyReportView {...this.props} />
              </TabPanel>
              <TabPanel>
                <form onSubmit={this.props.handleActivationReportSubmit}>
                  <div className='row'>
                    <div className='small-4 columns'>
                      <select value={selectedMonth} id="month" name="month" onChange={updateDate} required>
                        {monthDates.map(month => <option key={month.value} value={month.value}>{month.displayName}</option>)}
                      </select>
                    </div>
                    <div className='small-4 columns' >
                      <select value={selectedYear} id="year" name="year" onChange={updateDate} required>
                        {YEARS.map(year => <option key={year} value={year}>{year}</option>)}
                      </select>
                    </div>
                    <div className="small-4 columns">
                      <button
                        type="submit"
                        className="postfix"
                        value="Select Date Range"
                        disabled={isFetchingActivationReport}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                <ActivationReportView
                  isFetchingActivationReport={isFetchingActivationReport}
                  activationReport={this.formatActivationReportData()}
                  month={month} />
              </TabPanel>
            </div>
          </Tabs>
          </div>
        </div>
      </div >)
    )
  }
}

const S = styler`
  button
    margin: 0px 5px 0px -16px
    height: 36px
  h2
    margin-left: 1rem
  h3
    margin: 20
    padding: 15
  subHeader
    font-size: 1.4em
    color: #727272
  ordersHeader
    margin-bottom: 7
  sumblocks:
    margin-bottom: 15
  datePicker
    display: inline-block
    margin-left: 1em
`;

export default CompanyReportView

CompanyReportView.propTypes = {
  companyId: PropTypes.string,
  dates: PropTypes.shape({
    initStartDate: PropTypes.string,
    initEndDate: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string
  }),
  handleFromClick: PropTypes.func.isRequired,
  handleToClick: PropTypes.func.isRequired,
  isFetchingActivationReport: PropTypes.bool,
  monthly: PropTypes.object.isRequired,
  orders: PropTypes.object,
  params: PropTypes.shape({ id: PropTypes.string }),
  activationReport: PropTypes.arrayOf(PropTypes.object),
  handleActivationReportSubmit: PropTypes.func.isRequired,
  togglePrintModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  monthToFetch: PropTypes.func.isRequired,
  updateDate: PropTypes.func.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired
};
