import React from "react";
import { useFormikContext, FieldArray } from "formik";
import FormikField from "components/forms/formik-field";
import { IconButton } from "components/ui";
import MdRemove from "react-icons/lib/md/remove";
import MdAdd from "react-icons/lib/md/add";

const PhilipsRegistration = () => {
  const { setFieldValue, values } = useFormikContext();
  return (
    <div style={{ border: "1px solid #ccc" }}>
      <FieldArray
        name="patientEquipment"
        render={arrayHelpers => (
          <div
            style={{
              display: "flex",
              padding: 5,
              backgroundColor: "#fafafa",
              flex: 1,
              flexDirection: "column"
            }}
          >
            <div style={{ display: "flex", flex: 1 }}>
              <h4 style={{ flex: 1, display: "flex" }}>
                Please Enter Serial Numbers for the affected machines.
              </h4>
              <IconButton
                onClick={() =>
                  arrayHelpers.push({
                    serialNumberCollected: "",
                    confirmationNumber: "",
                    hasEverUsedOzone: ""
                  })
                }
                type="button"
                icon={<MdAdd />}
                text="Add Machine"
              />
            </div>
            {values.patientEquipment.map((eq, index) => (
              <div key={index}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    alignItems: "center",
                    gridGap: 5
                  }}
                >
                  <FormikField
                    name={`patientEquipment.${index}.serialNumberCollected`}
                    type="text"
                    label="Serial Number"
                    style={{ margin: 0 }}
                  />
                  <span>
                    {index > 0 && (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          icon={<MdRemove size={18} />}
                          style={{ color: "red" }}
                          type="button"
                        />
                      </span>
                    )}
                  </span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                    gridGap: 5
                  }}
                >
                  <div style={{ paddingLeft: 10 }}>
                    <FormikField
                      label="Has Ozone or Activated Oxygen been used to sterilize the device?"
                      name={`patientEquipment.${index}.hasEverUsedOzone`}
                      type="checkbox"
                      checked={values.patientEquipment[index].hasEverUsedOzone}
                    />
                  </div>
                  <FormikField
                    type="text"
                    label="Confirmation # (optional)"
                    name={`patientEquipment.${index}.confirmationNumber`}
                  />
                </div>
                <hr />
              </div>
            ))}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            ></div>
          </div>
        )}
      />
      <div
        style={{
          padding: 5
        }}
      >
        <FormikField
          type="text"
          name="questionnaireResponses.first_name"
          label="What is your first name?"
        />
        <FormikField
          type="text"
          name="questionnaireResponses.last_name"
          label="What is your last name?"
        />
        <FormikField
          type="text"
          name="questionnaireResponses.home_address"
          label="What is your address?"
        />
        <FormikField
          type="text"
          name="questionnaireResponses.phone_type"
          label="What type of phone number was provided?"
          component="select"
        >
          <option />
          <option value="Cell">Cell</option>
          <option value="Home">Home</option>
        </FormikField>
        <FormikField
          type="text"
          name="questionnaireResponses.email"
          label="What is your email address?"
        />
        <FormikField
          type="text"
          name="questionnaireResponses.additional_info"
          label="If you would like to receive additional information please select how you would like to receive the information."
          component="select"
        >
          <option />
          <option value="Text Message">Text Message</option>
          <option value="Email">Email</option>
          <option value="Phone Call">Phone Call</option>
          <option value="Receive no additional information">
            Receive no additional information
          </option>
        </FormikField>
      </div>
      <div
        style={{
          padding: 20,
          borderTop: "1px solid #ccc",
          backgroundColor: "#fafafa"
        }}
      >
        <p style={{ fontSize: "1.25rem" }}>
          You agree Philips may hold your data to meet its legal obligations in
          the field of product safety, to investigate reported problems and to
          improve its products. Your information may be shared with regulatory
          authorities and other entities of the Philips group, including in the
          U.S. where a different level of protection applies. Philips has put in
          place appropriate contractual protections as required by law. For more
          information about this or to exercise your right of access,
          rectification, restriction, erasure and portability please contact
          Philips’s Data Protection Officer by submitting your request at:
          www.philips.com/a-w/privacy. You also have a right to lodge a
          complaint with the Supervisory Authority for data protection. Philips
          stores your information for the duration imposed by applicable laws.
        </p>
        <label
          htmlFor="accepted"
          style={{
            userSelect: false,
            marginLeft: 20,
            fontSize: "1.25rem",
            fontWeight: "bold"
          }}
        >
          <span style={{ paddingRight: 10 }}>
            I Acknowledge I made patient aware of the the philips recall
            information
          </span>
          <input
            style={{ margin: 0 }}
            type="checkbox"
            id="accepted"
            checked={values.accepted === true}
            onChange={async e => {
              setFieldValue("accepted", e.target.checked);
            }}
          />
        </label>
      </div>
    </div>
  );
};

export default PhilipsRegistration;
