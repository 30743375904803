import { GET_VIDEOLIBRARY, UPDATE_VIDEOLIBRARY, DELETE_VIDEOLIBRARY, UPDATE_STATUS_VIDEOLIBRARY } from './../ActionTypes';
import API from "./../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage , message } from "actions/message";
import * as api from "utils/api";

export const getVideoLibrary = (pid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/products/" + pid + "/videos";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res.data.videos) {
                return dispatch({
                    type: GET_VIDEOLIBRARY,
                    payload: res.data.videos
                });
            }
        }else{
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_VIDEOLIBRARY,
                payload: []
            });
        }
    })
    .catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_VIDEOLIBRARY,
            payload: []
        });
    });
}

export const createVideoLibrary = (data) => dispatch => {
    dispatch(showLoader());
    let createObj = {
        link : data.link,
        title : data.title,
        active : true
    }
    const url = "dme_portal/products/" + data.product_id + "/videos";
    API().post(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            dispatch(getVideoLibrary(data.product_id));
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const deleteVideoLibrary = (pid, vid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/products/" + pid + "/videos/" + vid + "";
    API().delete(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: DELETE_VIDEOLIBRARY,
                payload: vid
            });
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const updateVideoLibrary = (data) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/products/" + data.product_id + "/videos";
    let createObj = {
        id : data.video_id,
        video_id : data.video_id,
        link : data.link,
        title : data.title,
        active : data.active
    }
    API().put(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            dispatch(getVideoLibrary(data.product_id));
            return dispatch({
                type: UPDATE_VIDEOLIBRARY,
                payload: createObj
            });
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}


export const updateStatusVideoLibrary = (data, status) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/products/" + data.product_id + "/videos";
    let createObj = {
        id : data.video_id,
        video_id: data.video_id,
        link : data.link,
        title : data.title,
        active : status
    };
    API().put(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_STATUS_VIDEOLIBRARY,
                payload: createObj
            });
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const getProductVideos = productId => async dispatch => {
  try {
    dispatch(showLoader());
    dispatch({
      type: GET_VIDEOLIBRARY,
      payload: []
    });
    const { media } = await api.get(`dme_portal/products/${productId}/videos`);
    return dispatch({
      type: GET_VIDEOLIBRARY,
      payload: media ? media : []
    });
  } finally {
    dispatch(hideLoader());
  }
};

export const deleteProductVideo = (productId, videoId) => async dispatch => {
  try {
    dispatch(showLoader());
    const response = await api.del(
      `dme_portal/products/${productId}/videos/${videoId}`
    );
    dispatch(message(response.message));
    return dispatch({
      type: DELETE_VIDEOLIBRARY,
      payload: videoId
    });
  } finally {
    dispatch(hideLoader());
  }
};

export const updateProductVideo = (
  productId,
  videoId,
  { link, title, active }
) => async dispatch => {
  try {
    dispatch(showLoader());
    await api.put(`dme_portal/products/${productId}/videos`, {
      id: videoId,
      video_id: videoId,
      link,
      title,
      active
    });
    return dispatch({
      type: UPDATE_VIDEOLIBRARY,
      payload: {
        id: videoId,
        video_id: videoId,
        link: link,
        title: title,
        active: active
      }
    });
  } finally {
    dispatch(hideLoader());
  }
};

export const createProductVideo = (
  productId,
  { link, title, active }
) => async dispatch => {
  try {
    dispatch(showLoader());
    await api.post(`dme_portal/products/${productId}/videos`, {
      link: link,
      title: title,
      active: active
    });
    getProductVideos(productId)(dispatch);
  } finally {
    dispatch(hideLoader());
  }
};
