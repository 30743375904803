import React from "react";
import PropTypes from "prop-types";
import PureModal from "components/ui/modal/pure-modal";
import MaskFittingResult from "./fitting-result";
import { aLink } from "utils/styles";

const MaskFittingHistory = ({ maskFittingResultHistory }) => {
  if (maskFittingResultHistory.length <= 0) return null;
  else
    return (
      <PureModal
        renderTrigger={({ openModal }) => (
          <div
            onClick={openModal}
            style={aLink}
            className="mask-fitting-history-link"
          >
            View Older Mask Fitting Results
          </div>
        )}
      >
        {maskFittingResultHistory.map(result => (
          <div
            className="mask-fitting-history-entry"
            key={result.transaction_id}
          >
            <MaskFittingResult {...result} />
          </div>
        ))}
      </PureModal>
    );
};

MaskFittingHistory.propTypes = {
  maskFittingResultHistory: PropTypes.arrayOf(PropTypes.object)
};

export default MaskFittingHistory;
