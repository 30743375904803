import { combineReducers } from 'redux'
import mapValues from 'lodash/mapValues'
import some from 'lodash/some'
import {
  idReducer,
  toggleReducer,
  createReducer
} from 'utils/reducer'
import { Types } from "actions/order-profile";
import A from "action-types";

const tupleMap = {
  orderInfo: [Types.API_ORDER_INFO, Types.RESPONSE_ORDER_INFO],
  patientInfo: [Types.API_PATIENT_INFO, Types.RESPONSE_PATIENT_INFO],
  complianceInfo: [Types.API_COMPLIANCE_INFO, Types.RESPONSE_COMPLIANCE_INFO],
  equipmentInfo: [Types.API_EQUIPMENT_INFO, Types.RESPONSE_EQUIPMENT_INFO],
  dysfunctionInfo: [Types.API_DYSFUNCTION_INFO, Types.RESPONSE_DYSFUNCTION_INFO],
  notes: [Types.API_NOTES, Types.RESPONSE_NOTES],
  noteAdd: [Types.API_NOTE_ADD, Types.RESPONSE_NOTE_ADD],
  companyInfo: [Types.API_COMPANY_INFO, Types.RESPONSE_COMPANY_INFO],
  update: [Types.API_ORDER_UPDATE, Types.RESPONSE_ORDER_UPDATE],
  updateFromIndex: [A.ORDER_STATUS.UPDATE_REQUEST, A.ORDER_STATUS.UPDATE_RESPONSE],
  lineItems: [Types.API_LINE_ITEMS, Types.RESPONSE_LINE_ITEMS],
  lineItemRemoval: [Types.API_LINE_ITEM_REMOVAL, Types.RESPONSE_LINE_ITEM_REMOVAL],
  trackingUpdate: [Types.API_TRACKING_UPDATE, Types.RESPONSE_TRACKING_UPDATE],
  newLineItemById: [Types.API_NEW_LINE_ITEM_BY_ID, Types.RESPONSE_NEW_LINE_ITEM_BY_ID],
  newLineItem: [Types.API_NEW_LINE_ITEM, Types.RESPONSE_NEW_LINE_ITEM],
  products: [Types.API_PRODUCTS, Types.RESPONSE_PRODUCTS],
  accessLogs: [Types.API_ACCESS_LOGS, Types.RESPONSE_ACCESS_LOGS],
  changeLogs: [Types.API_CHANGE_LOGS, Types.RESPONSE_CHANGE_LOGS]
}

const isFetching = combineReducers(mapValues(tupleMap, ([apiType, responseType]) => (
  idReducer('meta.orderId', toggleReducer(false, [apiType], [responseType]))
)))

const handleFailResponse = (_, { error }) => !!error

const didFail = combineReducers(mapValues(tupleMap, ([, responseType]) => (
  idReducer('meta.orderId', createReducer(false, {
    [responseType]: handleFailResponse
  }))
)))

const fetchStatus = combineReducers({
  isFetching,
  didFail
})

export default fetchStatus

const individualSelectors = {
  getIsFetchingOrderInfo: (state, { orderId }) => !!state.isFetching.orderInfo[orderId],
  getIsFetchingOrderPatientInfo: (state, { orderId }) => !!state.isFetching.patientInfo[orderId],
  getIsFetchingOrderComplianceInfo: (state, { orderId }) => !!state.isFetching.complianceInfo[orderId],
  getIsFetchingOrderEquipmentInfo: (state, { orderId }) => !!state.isFetching.equipmentInfo[orderId],
  getIsFetchingOrderDysfunctionInfo: (state, { orderId }) => !!state.isFetching.dysfunctionInfo[orderId],
  getIsFetchingOrderNotes: (state, { orderId }) => !!state.isFetching.notes[orderId],
  getIsSubmittingNoteAdd: (state, { orderId }) => !!state.isFetching.noteAdd[orderId],
  getIsFetchingCompanyInfo: (state, { orderId }) => !!state.isFetching.companyInfo[orderId],
  getIsUpdatingOrder: (state, { orderId }) => !!state.isFetching.update[orderId] || !!state.isFetching.updateFromIndex[orderId],
  getIsFetchingOrderLineItems: (state, { orderId }) => !!state.isFetching.lineItems[orderId],
  getIsSubmittingLineItemRemoval: (state, { orderId }) => !!state.isFetching.lineItemRemoval[orderId],
  getIsUpdatingTracking: (state, { orderId }) => !!state.isFetching.trackingUpdate[orderId],
  getIsSubmittingNewLineItem: (state, { orderId }) => !!state.isFetching.newLineItemById[orderId] || !!state.isFetching.newLineItem[orderId],
  getIsFetchingProducts: (state, { orderId }) => !!state.isFetching.products[orderId],
  getIsFetchingOrderAccessLogs: (state, { orderId }) => !!state.isFetching.accessLogs[orderId],
  getIsFetchingOrderChangeLogs: (state, { orderId }) => !!state.isFetching.changeLogs[orderId]
}

const getIsFetchingOrderProfile = (...args) => (
  some(individualSelectors, selector => selector(...args))
)

const getDidFailOrderProfile = ({ didFail }, { orderId }) => (
  some(didFail, orderId)
)

export const selectors = {
  ...individualSelectors,
  getIsFetchingOrderProfile,
  getDidFailOrderProfile
}
