import React from "react";
import PropTypes from "prop-types";
import { AppPermissionContext } from "components/shared/app-permission";
import CmnNotes from "../cmn-notes";
import PatientCmnsList from "./patient-cmns";

const PatientCmns = ({ patientId }) => {
  const { ra_cmn_integration } = React.useContext(AppPermissionContext);
  if (ra_cmn_integration) return <PatientCmnsList patientId={patientId} />;
  return <CmnNotes patientId={patientId} />;
};

PatientCmns.propTypes = { patientId: PropTypes.string.isRequired };

export default PatientCmns;
