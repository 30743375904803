import React, { useContext } from "react";
import PropTypes from "prop-types";
import MdFilterList from "react-icons/lib/md/filter-list";
import FaFilter from "react-icons/lib/fa/filter";
import * as R from "ramda";
import Dropdown from "components/ui/dropdown";
import { IconButton } from "components/ui";
import isEqual from "lodash/isEqual";
import QueryTableContext from "../context";

const toggleListItem = R.curry((value, list) =>
  R.ifElse(R.includes(value), R.without([value]), R.append(value))(list)
);

const FilterDropdown = ({
  filterKey,
  filters,
  filterDownSelect,
  SubFilter,
  dropdownPosition,
  FilterComponent,
  setAddIndexToDropdown
}) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  const selected = R.pathOr([], ["filters", filterKey], query);
  const [tmpFilter, setTmpFilter] = React.useState(selected);
  const [filterSearchValue, setFilterSearchValue] = React.useState("");

  function downSelectFilters({ text }) {
    return filterSearchValue.length > 0
      ? text.toLowerCase().includes(filterSearchValue.trim().toLowerCase())
      : true;
  }
  function setFilters(by, value) {
    setTmpFilter(value);
  }
  function stopPropagation(e) {
    e.stopPropagation();
  }
  
  return (
    <Dropdown
      trigger={
        <IconButton
          icon={<MdFilterList />}
          className="button secondary icon-button column-filter-trigger"
          onClick={() => setAddIndexToDropdown(true)}
        />
      }
      onClose={() => {
        if (!isEqual(tmpFilter, selected)){
          tmpFilter.length !== 0 ? 
          queryDispatcher({
            type: "setFilter",
            by: filterKey,
            value: tmpFilter
          })
          :
          queryDispatcher({
            type: "clearFilter",
            by: filterKey,
          })
        }
        setAddIndexToDropdown(false)
      }}
      style={{ marginTop: 35 }}
      position={dropdownPosition}
    >
      {FilterComponent ? (
        <FilterComponent />
      ) : (
        <ul className="dropdown-list" onClick={stopPropagation}>
          <li
            className="dropdown-command"
            onClick={() => {
              setTmpFilter([]);
            }}
          >
            Clear Selections
          </li>
            <li
                className="dropdown-command"
                onClick={() => {
                const value = filters
                    .filter(({ value }) => !tmpFilter.includes(value))
                    .map(({ value }) => value);
                setTmpFilter(value);
                }}
            >
                Invert Selections
            </li>
          
          {SubFilter && <SubFilter setFilters={setFilters} />}
          {filterDownSelect && (
            <li className="filter-downselect-input">
              <label>
                <FaFilter />
              </label>
              <input
                value={filterSearchValue}
                onChange={({ target }) => setFilterSearchValue(target.value)}
              />
            </li>
          )}
          {
            
            R.filter(downSelectFilters, filters).map(({ value, text }) => (
                <li
                  key={value}
                  className="dropdown-item"
                  onClick={() => {
                    setTmpFilter(tmp => toggleListItem(value, tmp));
                  }}
                >
                  <input
                    type="checkbox"
                    checked={tmpFilter?.includes(value) ?? false}
                    readOnly
                  />
                  <label>{text}</label>
                </li>
              ))
          }
        </ul>
      )}
    </Dropdown>
  );
};

export default React.memo(FilterDropdown);

FilterDropdown.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired,
  filterDownSelect: PropTypes.bool,
  SubFilter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func
  ]),
  dropdownPosition: PropTypes.oneOf(["", "left", "right", "middle"]),
  FilterComponent: PropTypes.elementType,
  setAddIndexToDropdown: PropTypes.func
};
