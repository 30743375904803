import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import FormikField from "components/forms/formik-field";
import TextInput from "components/forms/text-input";
import "./style.scss";

const EditFulfillmentInventoryForm = ({
  fulfillmentInventoryItem,
  updateFulfillmentInventory,
  getFulfillmentInventory,
  closeModal
}) => {
  const { fulfillmentId, inventoryId } = useParams();
  return (
    <Formik
      initialValues={{
        inventory_item_guid: inventoryId,
        fulfillment_company_guid: fulfillmentId,
 
        sItemID: fulfillmentInventoryItem.fulfillment_id,
        sUOM: fulfillmentInventoryItem.uom,
        bActive: fulfillmentInventoryItem.fulfillment_active
      }}
      onSubmit={async values => {
        await updateFulfillmentInventory(values);
        getFulfillmentInventory({ fulfillmentId, inventoryId });
      }}
    >
      {({ values, setFieldValue }) => (
        <div className="edit-fulfillment-inventory-form">
          <h4>{fulfillmentInventoryItem.name}</h4>
          <Form style={{ maxWidth: "600px" }}>
            <div>
              <TextInput
                label="Fulfillment Crosswalk Id"
                name="sItemID"
                type="text"
                value={values.sItemID}
                onChange={e => {
                  setFieldValue("sItemID", e.target.value);
                }}
              />
              <TextInput
                label="Unit of Measure"
                name="sUOM"
                type="text"
                value={values.sUOM}
                onChange={e => {
                  setFieldValue("sUOM", e.target.value);
                }}
              />
              <div
                className="fulfillment-inventory-checkbox-container"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {/* Checkboxes */}
                <div>
                  <FormikField
                    label="Active"
                    name="bActive"
                    type="checkbox"
                    checked={values.bActive}
                  />
                </div>
                {/* End Checkboxes */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <button
                className="warning"
                style={{ margin: "0px 20px" }}
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
              <button style={{ margin: "0px 20px" }} type="submit">
                Save
              </button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default EditFulfillmentInventoryForm;

EditFulfillmentInventoryForm.propTypes = {
  setIsLoading: PropTypes.func,
  fulfillmentInventoryItem: PropTypes.object,
  updateFulfillmentInventory: PropTypes.func.isRequired,
  getFulfillmentInventory: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  company: PropTypes.object
};
