import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ModalLink from "components/shared/modal-link";
import useLocalQuery, {
  Table,
  NonSortableHeader
} from "hooks/useQuery/local-query";
import { selectors } from "reducers";
import { connect } from "react-redux";
import { capitalize } from "utils/misc";

const formatTooltip = date =>
  date ? moment(date).format("MMM DD, YYYY  hh:mm A") : "";
const formatDate = date => (date ? moment(date).format("ll") : "");

const OrdersTab = ({ orderStatusOptions, orders }) => {
  const { rows, query, queryDispatcher } = useLocalQuery(orders, {
    sort: { by: "created_at", direction: "desc" }
  });
  // TODO DO NOT USE, has weird issue with causing component to rerender infinitely
  // const isFetchingOrders = useSelector(state =>
  //   state.getIn(["fetch", "PATIENT_ORDERS", patientId, "isFetching"])
  // );
  return (
    <div>
      <Table
        isFetching={false}
        containerClass="query-table patient-tab-table dynamic-columns"
        TableRow={OrderRow}
        columns={[
          {
            header: "Invoice Number",
            field: "invoice_number",
            style: { width: 160 },
            Cell: () => <NonSortableHeader text="Invoice Number" />
          },
          {
            header: "Date Created",
            field: "created_at",
            style: { width: 160 }
          },
          {
            header: "Date Completed",
            field: "completion_date",
            style: { width: 160 }
          },
          {
            header: "Requested Equipment",
            field: "requested_equipment",
            Cell: () => <NonSortableHeader text="Requested Equipment" />
          },
          {
            header: "Platform",
            field: "platform",
            Cell: () => <NonSortableHeader text="Platform" />
          },
          {
            header: "Status",
            field: "status",
            filters: orderStatusOptions
          },
          {
            header: "Assignment",
            field: "sleep_coach_assignment_name"
          }
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
      />
    </div>
  );
};

OrdersTab.propTypes = {
  patientId: PropTypes.string.isRequired,
  orderStatusOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  orders: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect((state, { patientId }) => ({
  orders: selectors.getPatientOrders(state, patientId),
  orderStatusOptions: selectors.getOrderStatusTextOptions(state)
}))(OrdersTab);

const OrderRow = React.memo(
  ({
    invoice_number,
    id,
    created_at,
    completion_date,
    requested_equipment,
    sleep_coach_assignment_id,
    sleep_coach_assignment_name,
    platform,
    status
  }) => (
    <tr>
      <td>
        <ModalLink to={{ pathname: `/orders/${id}`, state: { patientId: id } }}>
          {invoice_number}
        </ModalLink>
      </td>
      <td title={formatTooltip(created_at)}>{formatDate(created_at)}</td>
      <td title={formatTooltip(completion_date)}>
        {formatDate(completion_date)}
      </td>
      <td>{requested_equipment}</td>
      <td>{platform}</td>
      <td>{capitalize(status)}</td>
      <td>
        <ModalLink to={`/users/${sleep_coach_assignment_id}`}>
          {sleep_coach_assignment_name}
        </ModalLink>
      </td>
    </tr>
  )
);

OrderRow.propTypes = {
  id: PropTypes.string.isRequired,
  invoice_number: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  completion_date: PropTypes.string,
  requested_equipment: PropTypes.string,
  status: PropTypes.string.isRequired,
  sleep_coach_assignment_id: PropTypes.string,
  sleep_coach_assignment_name: PropTypes.string,
  platform: PropTypes.string
};
