import React, { Component } from 'react'

export const withDatePicker = WrappedComponent => ({ initStartDate, initEndDate }) => {
  return class extends Component {
    state = {
      from: initStartDate || undefined,
      to: initEndDate || undefined,
      initStartDate,
      initEndDate
    };

    handleFromClick = ({ from }) => {
      this.setState({ from });
    }
    handleToClick = ({ to }) => {
      this.setState({ to });
    }

    render() {
      return <WrappedComponent
        handleToClick={this.handleToClick}
        handleFromClick={this.handleFromClick}
        dates={this.state}
        {...this.props}
      />;
    }
  };
}
