import React from "react";
import moment from "moment";
import { today } from "utils/dates";
import { isValidDateRange } from "utils/form-validation";

const useDateRange = ({
  startDate: initialStartDate = today(),
  endDate: initialEndDate = today(),
  format = "MM/DD/YYYY" /* NOTE initial values should be in provided format */
}) => {
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);
  const [dateRangeIsValid, setIsValidDateRange] = React.useState(
    isValidDateRange(startDate, endDate, format)
  );
  return {
    startDate,
    endDate,
    setStartDate: day => {
      const newDate = day ? moment(day, format).format(format) : "";
      setStartDate(newDate);
      setIsValidDateRange(isValidDateRange(newDate, endDate));
    },
    setEndDate: day => {
      const newDate = day ? moment(new Date(day)).format(format) : "";
      setEndDate(newDate);
      setIsValidDateRange(isValidDateRange(startDate, newDate));
    },
    dateRangeIsValid
  };
};

export default useDateRange;
