import { GET_DOCUMENT_CATEGORY, DELETE_DOCUMENT_CATEGORY, UPDATE_DOCUMENT_CATEGORY, UPDATE_STATUS_DOCUMENT_CATEGORY } from '../ActionTypes';

const initialState = {
    DocumentCategories: []
}

const reducerDocumentCategory = (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCUMENT_CATEGORY:
            return {
                ...state,
                DocumentCategories: action.payload
            };
        case UPDATE_DOCUMENT_CATEGORY:
            return {
                ...state,
                DocumentCategories: state.DocumentCategories.map(
                    (content) => content.document_category_id === action.payload.document_category_id ? { ...content, document_category_name: action.payload.document_category_name, is_media: action.payload.is_media }
                        : content)
            };
        case DELETE_DOCUMENT_CATEGORY:
            console.log(action.payload);
            console.log(state.DocumentCategories);
            return {
                ...state,
                DocumentCategories: state.DocumentCategories.map(
                    (content) => content.document_category_id === action.payload ? { ...content, active: false }
                        : content)
            };
        case UPDATE_STATUS_DOCUMENT_CATEGORY:
            return {
                ...state,
                DocumentCategories: state.DocumentCategories.map(
                    (content) => content.document_category_id === action.payload.document_category_id ? { ...content, active: action.payload.active }
                        : content)
            };
        default:
            return state;
    }
}

export default reducerDocumentCategory;
