import { take, call, put } from 'redux-saga/effects'
import C from "action-types";
import { message, errorMessage } from 'actions/message'
import * as api from 'utils/api'
import * as R from "ramda";

const getApiError = error =>
  R.pathOr(error, ["response", "body", "status"], error);

function* trackingImport({ payload }) {
  const SUBMIT_URL = 'imports/tracking'
  const SUBMIT_SUCCESS_MESSAGE = 'Your fulfillment tracking was successfully submitted!'
  const SUBMIT_ERROR_MESSAGE = 'An error occurred submitting your fulfillment tracking.'
    try {
      yield put({ type: C.TRACKING.TRACKING_IMPORT_REQUEST });
      const response = yield call(api.post, SUBMIT_URL, payload);
      yield put({ type: C.TRACKING.TRACKING_IMPORT_SUCCESS });
      yield put(
        message(`${SUBMIT_SUCCESS_MESSAGE} ${response.status}`, -1)
      );
    } catch (error) {
      const accountNumbers = R.pipe(
        R.split(":"),
        R.nth(1),
        x => (x ? R.split(",", x) : []),
        R.map(x => ({ acctNum: x.trim() }))
      )(getApiError(error));
  
      yield put({
        type: C.TRACKING.TRACKING_IMPORT_FAILURE,
        payload: accountNumbers
      });
      yield put(errorMessage(SUBMIT_ERROR_MESSAGE, -1));
    }
  }

export default function* watchImportTracking() {
  while (true) {
    const action = yield take(C.TRACKING.TRACKING_IMPORT)
    yield call(trackingImport, action);
  }
}