import { all, call, put, take, race } from "redux-saga/effects";
import {
  Types as AuthTypes,
  loadStoredUser,
  responseUserLogin,
  errorUserLogin,
  responseUserLogout,
  errorUserLogout,
  storeUserEmail
} from "actions/authentication";
import { reinitializeApp } from "actions/util";
import { errorMessage } from "actions/message";
import { browserHistory } from "browser-history";
import * as api from "utils/api";
import * as storage from "utils/storage";
import { transformUserRole } from "utils/misc";
import {
  updateBadLoginAttempts,
  resetLoginAttempts,
  userSigninAttemptsExceeded
} from "./login-attempts-utils";
import { pathOr } from "ramda";
import { Types as AppVersionTypes } from "actions/app-version";
import handleCheckUserEnvironement from "./environment-redirect";

export const UNAUTHORIZED = 401;
export const PASSWORD_EXPIRED = 409;

function* loadUser() {
  while (true) {
    yield take(AuthTypes.REQUEST_LOAD_USER);
    const serializedUser = yield call(storage.get, "evergreen");
    const user = JSON.parse(serializedUser);
    yield put(loadStoredUser(user));
  }
}

export function* login({
  payload: {
    credentials: { email, password, device_token }
  }
}) {
  try {
    if (userSigninAttemptsExceeded()) {
      yield put(
        errorUserLogin(
          "Too many invalid requests, please wait a few minutes before trying again."
        )
      );
    } else {
      const user = yield call(api.post, "Authentication2", {
        email,
        password,
        device_token
      });
      if (user.MFAAuthRequired) {
        yield put({ type: AuthTypes.TWO_FACTOR_AUTH_REQUIRED });
        const redirectedFrom = pathOr(
          false,
          ["location", "state", "from", "pathname"],
          browserHistory
        );
        browserHistory.push({
          pathname: "/mfa/required",
          state: { from: redirectedFrom, user }
        });
        return;
      } else {
        const userLoggedIn = transformUserRole(user);
        const redirected = yield call(
          handleCheckUserEnvironement,
          userLoggedIn
        );
        if (redirected) return;
        yield call(storage.put, "evergreen", JSON.stringify(userLoggedIn));
        yield put(responseUserLogin(userLoggedIn));
        yield call(resetLoginAttempts);
        const redirectedFrom = pathOr(
          false,
          ["location", "state", "from", "pathname"],
          browserHistory
        );
        if (redirectedFrom && redirectedFrom !== "/login") {
          browserHistory.push(redirectedFrom);
        } else {
          var landing =
            user.role === "ContractorAdministrator"
              ? "/team-dashboard"
              : "/Dashboard";
          console.log("You are redirecting to " + landing);
          browserHistory.push(landing);
        }
        yield put({
          type: AppVersionTypes.CHECK_APP_VERSION_LOGIN,
          versions: user.versions
        });
      }
    }
  } catch (error) {
    if (isNetworkError(error)) {
      yield put(
        errorUserLogin(
          "Could not communicate with server, please try again later."
        )
      );
      return;
    }
    if (error && error.status === PASSWORD_EXPIRED) {
      /*expired password*/
      yield put(storeUserEmail({ email }));
      browserHistory.push("/password-expired");
      const errorMessage = pathOr(
        "Password is expired.",
        ["response", "body", "status"],
        error
      );
      yield put(errorUserLogin(errorMessage));
    } else {
      if (error.status === 403) yield call(updateBadLoginAttempts);

      const errorMessage = pathOr(
        error.status === 403
          ? "Invalid Credentials."
          : "An unknown error occurred. Please try again later.",
        ["response", "body", "status"],
        error
      );
      yield put(errorUserLogin(errorMessage));
    }
  }
}
const isNetworkError = err => {
  if (
    err.message.includes("Request has been terminated") &&
    err.message.includes("the network is offline")
  )
    return true;
  return false;
};

function* watchLogin() {
  while (true) {
    const action = yield take(AuthTypes.LOGIN_USER_REQUEST);
    yield call(login, action);
  }
}

function* watchTwoFactorApproved() {
  while (true) {
    const { user } = yield take([
      AuthTypes.TWO_FACTOR_AUTH_APPROVED,
      AuthTypes.TWO_FACTOR_AUTH_LOGIN,
      AuthTypes.AZURE_AD_SSO
    ]);
    const userLoggedIn = transformUserRole(user);
    const redirected = yield call(handleCheckUserEnvironement, userLoggedIn);
    if (redirected) return;
    yield call(storage.put, "evergreen", JSON.stringify(userLoggedIn));
    yield put(responseUserLogin(userLoggedIn));
    yield call(resetLoginAttempts);
    const redirectedFrom = pathOr(
      false,
      ["location", "state", "from", "pathname"],
      browserHistory
    );
    if (redirectedFrom && redirectedFrom !== "/login") {
      browserHistory.push(redirectedFrom);
    } else {
      browserHistory.push("/dashboard");
    }
    yield put({
      type: AppVersionTypes.CHECK_APP_VERSION_LOGIN,
      versions: user.versions
    });
  }
}

export function* logout() {
  try {
    yield call(put, "logout", { type: "PUT" });
    yield put(responseUserLogout());
  } catch (payload) {
    yield put(errorUserLogout(payload));
  }
}

function* watchLogout() {
  while (true) {
    const action = yield take(AuthTypes.LOGOUT_USER_REQUEST);
    yield call(logout, action);
  }
}

export const isUnauthorizedResponse = ({ error, payload }) =>
  (error && error.status === UNAUTHORIZED) ||
  // if following FSA convention
  (error === true && payload && payload.status === UNAUTHORIZED);

export const isLogoutResponse = ({ type, error }) =>
  type === AuthTypes.LOGOUT_USER_RESPONSE && !error;

export const LOGOUT_MESSAGE = "You have been logged out.";
export const EXPIRED_MESSAGE = "Your session has expired.";

export function* handleExit() {
  while (true) {
    const { logoutResponse, unauthorized, inactiveTimeout } = yield race({
      inactiveTimeout: take(AuthTypes.LOGOUT_USER_INACTIVE),
      logoutResponse: take(isLogoutResponse),
      unauthorized: take(isUnauthorizedResponse)
    });
    const redirectedFrom = pathOr(false, ["location"], browserHistory);
    if (unauthorized && redirectedFrom) {
      browserHistory.push({
        pathname: "/login",
        state: { from: redirectedFrom }
      });
    } else browserHistory.push("/login");
    yield call(storage.clear);
    yield put(reinitializeApp());

    if (logoutResponse) {
      yield put(errorMessage(LOGOUT_MESSAGE));
    }
    if (unauthorized) {
      yield put(errorMessage(EXPIRED_MESSAGE));
    }
    if (inactiveTimeout) {
      yield put({ type: AuthTypes.LOGOUT_USER_INACTIVE });
    }
  }
}

export default function* rootAuthenticationSaga() {
  yield all([
    loadUser(),
    watchLogin(),
    watchLogout(),
    handleExit(),
    watchTwoFactorApproved()
  ]);
}
