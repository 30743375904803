import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import useLocalQuery, { Table, NonSortableHeader } from "hooks/useQuery/local-query";
import AutofitTableRow from "./table-row";

const AutofitTable = ({
  companieOptions,
  maskFittingTransactions,
  isFetching,
  searchValue,
  fetchMaskFittingTransactions
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(
    maskFittingTransactions,
    {
      sort: { by: "date_created", direction: "desc" }
    }
  );
  React.useEffect(() => {
    fetchMaskFittingTransactions();
  }, []);
  return (
    <Table
      isFetching={isFetching}
      containerClass="query-table mask-fitting-table s3-ui-table"
      TableRow={AutofitTableRow}
      columns={[
        {
          header: "",
          field: "controls",
          Cell: () => <NonSortableHeader text="" style={{ width: 80 }} />
        },
        {
          header: "Assigned Patient",
          field: "last_name",
          sort: "last_name"
          // filter: "patient_id",
          // filters: [{ text: "None", value: "" }]
        },
        {
          header: "Company",
          field: "company",
          filterKey: "company_id",
          sort: "company",
          filters: companieOptions,
          filterDownSelect: true
        },
        {
          header: "Status",
          field: "status",
          filter: "status",
          filters: [
            { text: "AUTOFIT SUCCESSFUL", value: "AUTOFIT SUCCESSFUL" },
            { text: "WAITING FOR PATIENT", value: "WAITING FOR PATIENT" },
            { text: "WAITING FOR RESPONSE", value: "WAITING FOR RESPONSE" },
            { text: "AUTOFIT FAILURE", value: "AUTOFIT FAILURE" }
          ]
        },
        {
          header: "Created Date",
          field: "date_created"
        },
        {
          header: "Recommended Mask",
          field: "recomended_mask",
          Cell: () => <NonSortableHeader text="Recommended Mask" />
        }
      ]}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={rows.filter(({ first_name, last_name }) =>
        `${first_name} ${last_name}`
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )}
      rowKey="transaction_id"
    />
  );
};

export default connect(state => ({
  companieOptions: selectors.getFormOptionsCompany(state)
}))(AutofitTable);

AutofitTable.propTypes = {
  companieOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ),
  maskFittingTransactions: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  fetchMaskFittingTransactions: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired
};
