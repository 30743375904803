import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { selectors as querySelectors } from "features/query";
import { withRouter } from "react-router-dom";
import { PageHeader } from "components/ui";
import FulfillmentStatusTable from "./table";
import { pathOr } from "ramda";

const titles = {
  "ready-to-ship": "Ready To Ship",
  "pending-shipment": "Pending Shipment",
  "shipped-and-completed": "Shipped and Completed",
  "orders-backordered": "Backordered"
};

const FulfillmentStatus = ({
  count,
  dispatch,
  fulfillmentStatus,
  userId,
  userRole,
  userCanExport
}) => {
  const fulfillmentText = titles[fulfillmentStatus] || "Fulfillment Orders";
  return (
    <div key={`${fulfillmentStatus}`}>
      <PageHeader title={fulfillmentText} count={count} />
      <FulfillmentStatusTable
        fulfillmentStatus={fulfillmentStatus}
        queryId="fulfillment-table"
        rowKey="id"
        apiId="fulfillment-table"
        userRole={userRole}
        dispatch={dispatch}
        export={
          userCanExport
            ? {
                route: "fulfillment/export"
              }
            : null
        }
        initialQuery={{
          sort: {
            by: "created_at",
            direction: "asc"
          },
          pagination: {
            per: 150,
            page: 1
          },
          filters: {
            status: [fulfillmentStatus],
            sleep_coach: userRole === "SleepCoach" ? [userId] : []
          }
        }}
      />
    </div>
  );
};

const getFulfillmentStatusFromRoute = pathOr("", [
  "location",
  "state",
  "status"
]);
export default withRouter(
  connect((state, props) => ({
    count: querySelectors.getQueryCount(state, "fulfillment-table"),
    fulfillmentStatus: getFulfillmentStatusFromRoute(props),
    userId: selectors.getUserId(state),
    userRole: selectors.getUserRole(state),
    userCanExport: selectors.canUserExport(state)
  }))(FulfillmentStatus)
);

FulfillmentStatus.propTypes = {
  count: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  fulfillmentStatus: PropTypes.string,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userCanExport: PropTypes.bool
};
