import React from 'react'

export default [
  '',
  'Fedex',
  'USPS',
  'UPS',
  'DHL',
  'ONTRAC',
  'Other'
].map(x => <option key={x} value={x}>{x}</option>)
