import React from "react";
import PropTypes from "prop-types";
import AutoDialerTab from "./autodialer-tab";
import MdClose from "react-icons/lib/md/close";
import MdInsertDriveFile from "react-icons/lib/md/insert-drive-file";
import IoIosCart from "react-icons/lib/io/ios-cart";
import {
  MdVoicemail,
  MdRecordVoiceOver,
  MdPhone,
  MdPerson
} from "react-icons/lib/md";
import { prop } from "ramda";

const tabIcon = type => {
  switch (type) {
    case "patients":
      return <MdPerson />;
    case "patient-outreach":
      return <MdPhone />;
    case "orders":
      return <IoIosCart />;
    case "vmails":
      return <MdVoicemail cIoIosCart />;
    case "googleFile":
      return <MdInsertDriveFile />;
    case "contact-record":
      return <MdRecordVoiceOver />;
    default:
      return null;
  }
};

const Tab = ({
  handleOpen,
  handleClose,
  type,
  label,
  isOpen,
  meta,
  handleMinimize
}) =>
  type !== "patient-outreach" ? (
    <div
      onClick={e => {
        if (!isOpen) handleOpen(e);
        else handleMinimize(e);
      }}
      className={`system-tab ${isOpen ? "is-open" : ""}`}
    >
      <span>{tabIcon(type)}</span>
      <span className="tab-label">{label}</span>
      {handleClose && (
        <span className="close-icon" onClick={handleClose}>
          <MdClose size={18} />
        </span>
      )}
    </div>
  ) : (
    <AutoDialerTab
      handleOpen={handleOpen}
      handleClose={handleClose}
      isOpen={isOpen}
      followUpOrders={prop("followUpOrders", meta)}
    />
  );

Tab.defaultProps = {
  label: ""
};

Tab.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleMinimize: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object
};

export default Tab;
