import * as R from "ramda";
import { createReducer } from "utils/reducer";
import { combineReducers } from "redux";

// const search_for_array = createReducer([], {});
const sort = createReducer(
  {},
  {
    toggleSort: (state, action) => ({
      direction:
        state.by === action.by // toggle direction
          ? state.direction === "asc"
            ? "desc"
            : "asc"
          : "asc",
      by: R.prop("by", action)
    }),
    updateQuery: (state, action) => R.propOr(state, "sort", action),
    setSort: (state, action) => R.prop("sort", action)
  }
);
const pagination = createReducer(
  { page: 1, per: 0 },
  {
    updatePagination: (state, action) => ({
      per: R.propOr(state.per, "per", action),
      page: R.propOr(state.page, "page", action)
    }),
    toggleFilter: R.assoc("page", 1),
    updateFilters: R.assoc("page", 1),
    setFilter: R.assoc("page", 1),
    updateQuery: (state, action) => R.propOr(state, "pagination", action)
  }
);

const toggleListItem = R.curry((value, list) =>
  R.ifElse(R.includes(value), R.without([value]), R.append(value))(list)
);

const filters = createReducer(
  {},
  {
    setFilter: (state, action) => R.assoc(action.by, action.value, state),
    updateFilters: (state, action) => R.propOr({}, "payload", action),
    toggleFilter: (state, action) =>
      R.assoc(
        action.by,
        toggleListItem(action.value, R.propOr([], action.by, state)),
        state
      ),
    clearFilter: (state, action) => R.dissoc(action.by, state),
    updateQuery: (state, action) => R.propOr(state, "filters", action)
  }
);

export default combineReducers({
  sort,
  pagination,
  filters
  // search_for_array,
});
