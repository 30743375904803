import C from "action-types";
import { createReducer } from "utils/reducer";

const backorderImport = createReducer(
  {
    isFetching: false,
    results: [],
    error: {}
  },
  {
    [C.ORDERS.BACKORDER_IMPORT_REQUEST]: () => ({
      isFetching: true,
      results: [],
      error: {}
    }),
    [C.ORDERS.BACKORDER_IMPORT_SUCCESS]: (_, { payload }) => ({
      isFetching: false,
      results: payload.results,
      error: {}
    }),
    [C.ORDERS.BACKORDER_IMPORT_FAILURE]: (_, { payload }) => ({
      isFetching: false,
      results: [],
      error: payload
    })
  }
);

export default backorderImport;
