import { GET_FAQ, UPDATE_FAQ, DELETE_FAQ, UPDATE_STATUS_FAQ } from './../ActionTypes';
import API from "./../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage , message } from "./../../../../actions/message";

export const getFAQ = (pid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/products/" + pid + "/faqs";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res.data.faqs) {
                return dispatch({
                    type: GET_FAQ,
                    payload: res.data.faqs
                });
            }
        }else{
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_FAQ,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_FAQ,
            payload: []
        });
    });
}

export const createFAQ = (product_id, { answer, question, active }) => dispatch => {
  dispatch(showLoader());
  API()
    .post(`dme_portal/products/${product_id}/faqs`, { answer, question, active })
    .then(res => {
      dispatch(hideLoader());
      if (res && res.data && res.data.success) {
        dispatch(message(res.data.message));
        dispatch(getFAQ(product_id));
      } else {
        dispatch(errorMessage(res.data.message));
      }
    })
    .catch(err => {
      console.log("error", err.response);
      const responseErrorMessage = err.response.data.message;
      dispatch(hideLoader());
      dispatch(errorMessage(responseErrorMessage));
    });
};

export const deleteFAQ = (pid, fid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/products/" + pid + "/faqs/" + fid + "";
    API().delete(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: DELETE_FAQ,
                payload: fid
            });
        }else{
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}

export const updateFAQ = (
  product_id,
  { faq_id, answer, question, active }
) => dispatch => {
  dispatch(showLoader());
  API()
    .put(`dme_portal/products/${product_id}/faqs`, {
      faq_id,
      answer,
      question,
      active
    })
    .then(res => {
      dispatch(hideLoader());
      if (res && res.data && res.data.success) {
        dispatch(message(res.data.message));
        dispatch(getFAQ(product_id))
        return dispatch({
          type: UPDATE_FAQ,
          payload: { faq_id, answer, question, active }
        });
      } else {
        dispatch(errorMessage(res.data.message));
      }
    })
    .catch(err => {
      const responseErrorMessage = err.response.data.message;
      dispatch(hideLoader());
      dispatch(errorMessage(responseErrorMessage));
    });
};

export const updateStatusFAQ = (data, status) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/products/" + data.product_id + "/faqs";
    let createObj = {
        faq_id : data.faq_id,
        answer : data.answer,
        question : data.question,
        active : status
    };
    API().put(url, createObj).then(res => {
        if (res && res.data && res.data.success) {
            dispatch(hideLoader());
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_STATUS_FAQ,
                payload: createObj
            });
        }else{
            dispatch(hideLoader());
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage =  err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}
