import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QueryTable from 'components/ui/query-table'
import { applyQuery } from 'utils/query'

class DataTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: {
        sort: {
          direction: 'asc',
          ...props.initialSort
        },
        filters: {}
      }
    }
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this)
  }

  handleQueryUpdate(query) {
    this.setState({ query })
  }

  render() {
    const { query } = this.state
    const {
      disabledsort,
      columns,
      rows,
      isLoading,
      onRefresh,
      onExport,
      isComplianceContacts = false
    } = this.props

    const queriedRows = applyQuery(rows, query)

    return (
      <QueryTable
        disabledsort = {disabledsort}
        query={query}
        onQueryUpdate={this.handleQueryUpdate}
        columns={columns}
        rows={queriedRows}
        isLoading={isLoading}
        onRefresh={onRefresh}
        onExport={onExport}
        isComplianceContacts={isComplianceContacts}
      >
      </QueryTable>
    )
  }
}

export const COLUMN_SHAPE = {
  title: PropTypes.string,
  header: PropTypes.node.isRequired,
  onHeaderClick: PropTypes.func,
  disabledsort: PropTypes.bool,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired,
  sort: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.func
  ])
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(COLUMN_SHAPE)).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabledsort: PropTypes.bool,
  initialSort: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  onRefresh: PropTypes.func,
  onExport: PropTypes.func,
  isComplianceContacts: PropTypes.bool,
}

export default DataTable
