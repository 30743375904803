import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeTab } from "actions/system-tray";

/* close system modals(ie batch patient/order modals) when navigating to new page */
const ModalLink = ({ disabled, children, ...rest }) => {
  const dispatch = useDispatch();

  if (disabled) {
    return (
      <span>{children}</span>
    )
  }

  return (
    <Link
      {...rest}
      onClick={() => {
        dispatch(closeTab());
      }}
    >
      {children}
    </Link>
  );
};

export default ModalLink;

ModalLink.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ])
};
