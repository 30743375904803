import React from "react";
import PropTypes from "prop-types";
import PageHeader from "components/ui/page-header";
import PhoneBookTable from "./table";
import useSignalR from "features/signalR/use-signalr";
import {
  joinGroup,
  leaveGroup,
  getOnlineUsers
} from "features/signalR/actions";
import { connect } from "react-redux";
import { selectors } from "reducers";

const SignalrUserStatus = () => {
  useSignalR(
    [joinGroup("ClientListener"), getOnlineUsers()],
    [leaveGroup("ClientListener")]
  );
  return null;
};

const PhoneBook = ({ userRole }) => (
  <div>
    {userRole === "Administrator" && <SignalrUserStatus />}
    <PageHeader title="Phone Book" />
    <PhoneBookTable />
  </div>
);

export default connect(state => ({
  userRole: selectors.getUserRole(state)
}))(PhoneBook);

PhoneBook.propTypes = {
  userRole: PropTypes.string
};
