import React from "react";
import PropTypes from "prop-types";
import AudioPlayer from "components/ui/audio-player";
import { get } from "utils/api";
import { useFetch } from "hooks";

const AWSVmailRecording = ({ id, ...props }) => {
  const { response: uri, fetchData } = useFetch({
    apiFn: vmID => get(`voicemails/${vmID}/uri`),
    defaultValue: ""
  });

  React.useEffect(() => {
    fetchData(id);
  }, [id]);

  return uri ? (
    <AudioPlayer
      preloadAudio={"none"}
      src={uri}
      showAllFeatures={false}
      {...props}
    />
  ) : (
    ""
  );
};

export default AWSVmailRecording;

AWSVmailRecording.propTypes = {
  twilio_link: PropTypes.string,
  duration: PropTypes.number,
  id: PropTypes.string
};

// const VmailRecording = ({ twilio_link, duration, id, ...props }) => {
//   // if (twilio_link !== null)
//   //   return (
//   //     <AudioPlayer
//   //       preloadAudio={"none"}
//   //       src={twilio_link}
//   //       showAllFeatures={false}
//   //     />
//   //   );
//   return (
//     <AWSVmailRecording
//       twilio_link={twilio_link}
//       duration={duration}
//       id={id}
//       {...props}
//     />
//   );
// };
// export default VmailRecording;
//
// VmailRecording.propTypes = {
//   twilio_link: PropTypes.string,
//   vmID: PropTypes.string.isRequired,
//   duration: PropTypes.number
// };
