import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  getProductVideos,
  deleteProductVideo,
  updateProductVideo
} from "./../../Redux/Actions/videoLibrary";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import PureModal from "components/ui/modal/pure-modal";
import ProductVideoForm from "./product-video-form";
import BoolCheckbox from "components/ui/bool-checkbox";
import EditLink from "components/ui/edit-link";
import moment from "moment";

const ProductVideos = ({
  productId,
  getProductVideos,
  updateProductVideo,
  VideoLibrary,
  isLoading
}) => {
  React.useEffect(() => {
    if (productId) getProductVideos(productId);
  }, [productId]);
  const { rows, query, queryDispatcher, count } = useLocalQuery(
    productId ? VideoLibrary : [],
    {
      sort: { by: "created_date", direction: "asc" },
      filters: {},
      pagination: { page: 1, per: 100 }
    }
  );
  return (
    <div>
      <Table
        containerClass="query-table"
        rowKey="media_id"
        isFetching={isLoading}
        columns={[
          {
            header: "Title",
            field: "title"
          },
          {
            header: "Link",
            field: "link"
          },
          {
            header: "Created Date",
            field: "created_date"
          },
          {
            header: "Active",
            field: "active",
            filters: [
              { text: "Yes", value: "true" },
              { text: "No", value: "false" }
            ]
          },
          {
            field: "",
            header: " "
          }
        ]}
        rows={rows}
        query={query}
        count={count}
        queryDispatcher={queryDispatcher}
        TableRow={row => (
          <tr>
            <td>{row.title}</td>
            <td>
              <a href={row.link} target="blank">
                Link
              </a>
            </td>
            <td>{moment(row.created_date).format("L")}</td>
            <td>
              <BoolCheckbox value={row.active} />
            </td>
            <td>
              <PureModal
                renderTrigger={({ openModal }) => (
                  <EditLink onClick={openModal} showIcon={false} text="edit" />
                )}
                renderContent={({ closeModal }) => (
                  <ProductVideoForm
                    handleCancel={closeModal}
                    initialValues={row}
                    onSubmit={async values => {
                      await updateProductVideo(productId, row.media_id, values);
                      closeModal();
                    }}
                  />
                )}
              />
            </td>
          </tr>
        )}
      />
    </div>
  );
};

export default connect(
  (state, { productId }) => ({
    inventoryItem: selectors.getInventoryItem(state, productId),
    VideoLibrary: state.getIn(["newRootReducer"]).rdc_videoLibrary.VideoLibrary,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  {
    getProductVideos,
    updateProductVideo,
    deleteProductVideo
  }
)(ProductVideos);

ProductVideos.propTypes = {
  productId: PropTypes.string,
  getProductVideos: PropTypes.func.isRequired,
  updateProductVideo: PropTypes.func.isRequired,
  VideoLibrary: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool
};
