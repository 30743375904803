import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SearchableDropdown from "components/searchable-dropdown";
import { get, post } from "utils/api";
import { message } from "actions/message";
import FormikField from "components/forms/formik-field";
import { Formik } from "formik";
import PhoneNumberField from "components/forms/phone-number-field";
import { formatPhone } from "utils/misc";
import PropTypes from 'prop-types'

const IVRTesting = ({ isFormsIVR }) => {
  const [testPatient, setTestPatient] = useState(null);
  const [patients, setPatients] = useState([]);
  const dispatch = useDispatch();

  const getPatients = async e => {
    dispatch(message("Getting patients..."));
    // setIsFetching(true);
    const response = await get(`patients/ivr_test_patients/${e.phone}`);
    setTestPatient(response[0].patient_guid);
    dispatch(
      message(
        `Found ${response.length} patient${response.length === 1 ? "" : "s"}`
      )
    );
    setPatients(response);
  };

  const projectIVRPatient = pat => {
    // The IVR outbound route has a specific object it uses, but many of its properties are not used.
    // Specifically only uses the to, from, and the guid
    return {
      patientGuid: pat.patient_guid,
      to: pat.patient_phone,
      from: pat.outbound_ivr_number,
      secondTo: "",
      patientPK: 1,
      returnCode: "",
      primaryContact: ""
    };
  };
  const startIVRCall = () => {
    // Take the selected patient guid and use it to start the call
    const selectedPatient = patients.find(x => x.patient_guid == testPatient);

    if (selectedPatient.has_active_form || !isFormsIVR) {
      const projectedPatient = projectIVRPatient(selectedPatient);
      isFormsIVR ? post(`IVR/Forms/Out`, projectedPatient) : post(`IVR/Out`, projectedPatient);
    }
    else
    {
      dispatch(message("No active forms were found for the selected patient"));
    }
  };

  return (
    <div>
      <Formik
        onSubmit={(values, { getPatients }) => {
          if (values.phone.length > 10) {
            dispatch(message("Phone must be shorter than 10 numbers"));
          } else {
            getPatients(values);
          }
        }}
        initialValues={{ phone: "" }}
      >
        {({ values }) => (
          <form>
            <FormikField
              name="phone"
              label="Phone Number:"
              type="tel"
              component={PhoneNumberField}
              placeholder="Search Patient By Phone Number"
            />
            <button
              disabled={values.phone == ""}
              onClick={() => {
                getPatients(values);
              }}
              type="button"
            >
              Search
            </button>
          </form>
        )}
      </Formik>

      <div>
        Patients Found:
        <SearchableDropdown
          options={patients.map((x, i) => {
            return {
              text:
                x.company_name +
                " calling from " +
                formatPhone(x.outbound_ivr_number) +
                " to " +
                x.first_name +
                " " +
                x.last_name +
                " at " +
                formatPhone(x.patient_phone),
              value: x.patient_guid,
              key: x.patient_guid + i
            };
          })}
          selected={testPatient}
          onSelect={e => {
            setTestPatient(e);
          }}
        />
        <input
          className="button"
          type="submit"
          value="Start IVR Call"
          disabled={!testPatient}
          onClick={() => {
            startIVRCall(testPatient);
          }}
        />
      </div>
    </div>
  );
};

IVRTesting.propTypes = {
  isFormsIVR: PropTypes.bool.isRequired
};

export default IVRTesting;
