import React, { useState } from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { submitPhilipsRecall } from "actions/patient";
import AcknowledgmentForm from "./ackowledgment";
import ConfirmationForm from "./confirmation";
import {
  getPhilipsRegistrationQuestionList,
  getPhilipsRegistrationQuestionsValues,
  convertLegacyPhilipsStatus
} from "../helpers";
import { isValidEmail } from "utils/redux-form";
import * as Yup from "yup";

const PhilipsRecallForm = ({
  patientId,
  patientInfo,
  callSid,
  closeModal,
  submitPhilipsRecall
}) => {
  const { philips_recall } = patientInfo;
  const philipsRecallStatus = convertLegacyPhilipsStatus(
    patientInfo.philips_recall?.recallStatus
  );
  const [currentStep, setCurrentStep] = useState(
    philipsRecallStatus === "Pending" ? 2 : 1
  );
  return (
    <Formik
      initialValues={{
        hasMachine: philips_recall?.hasMachine,
        hasAcknowledged: philips_recall?.hasAcknowledged,
        recallStatus: philips_recall?.recallStatus,
        philipsDataApproval: philips_recall?.philipsDataApproval,
        twilioCallSid: callSid,
        platform: "Phoenix",
        questionnaireResponses: getPhilipsRegistrationQuestionsValues(
          patientInfo,
          philips_recall
        ),
        patientEquipment:
          philips_recall.hasMachine === true &&
          philips_recall?.patientEquipment?.length > 0
            ? philips_recall.patientEquipment
            : [
                {
                  serialNumberCollected: "",
                  confirmationNumber: "",
                  hasEverUsedOzone: ""
                }
              ],
        philips_confirmation_email: patientInfo.email
      }}
      validationSchema={Yup.object().shape({
        hasMachine: Yup.bool()
          .oneOf([true, false], "Please Select One")
          .nullable(true),
        patientEquipment: Yup.array().when("philipsDataApproval", {
          is: value => value === true,
          then: () =>
            Yup.array().of(
              Yup.object().shape({
                serialNumberCollected: Yup.string().required(
                  "Please enter serial number"
                )
              })
            )
        }),
        recallStatus: Yup.string().when("hasMachine", {
          is: value => value == true,
          then: () =>
            Yup.string()
              .oneOf(["Pending", "Completed"], "Please Select One")
              .required("Required")
        }),
        philips_confirmation_email: Yup.string()
          .when("hasMachine", {
            is: value => value == true,
            then: () =>
              Yup.string()
                .test(
                  "checkValidEmail",
                  "Invalid email",
                  email => !isValidEmail(email)
                )
                .nullable(true)
          })
          .nullable(true)
      })}
      initialTouched={{
        hasMachine: true,
        hasAcknowledged: true
      }}
      onSubmit={async values => {
        const questionnaireResponsesList =
          getPhilipsRegistrationQuestionList(values);
        const formValues = {
          ...values,
          questionnaireResponses: questionnaireResponsesList
        };
        await submitPhilipsRecall(patientId, formValues, true);
        closeModal();
      }}
      enableReinitialize
    >
      {() => (
        <Form>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex"
            }}
            className="philips-recall-form"
          >
            {currentStep === 1 && (
              <AcknowledgmentForm
                setCurrentStep={setCurrentStep}
                patientInfo={patientInfo}
                patientId={patientId}
                readOnly={philipsRecallStatus === "Pending"}
              />
            )}
            {currentStep === 2 && (
              <ConfirmationForm
                setCurrentStep={setCurrentStep}
                patientInfo={patientInfo}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

PhilipsRecallForm.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  callSid: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  submitPhilipsRecall: PropTypes.func.isRequired
};

export default connect(
  (state, { patientId }) => ({
    patientInfo: selectors.getPatientInfo(state, patientId),
    callSid: selectors.getTwilioCallSid(state)
  }),
  { submitPhilipsRecall }
)(PhilipsRecallForm);
