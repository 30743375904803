import React from "react";
import PropTypes from "prop-types";
import { browserHistory } from "browser-history";
import { withRouter } from "react-router-dom";
import ChangeLogsPanel from "./change-logs-panel";
import AccessLogsPanel from "./access-logs-panel";
import { get } from "utils/api";
import { path, prop, nth } from "ramda";
import { useFetch } from "hooks";

const UserLogs = props => {
  const userId = path(["match", "params", "id"], props);
  const {
    response: accessLogs,
    isFetching: isFetchingUserAccessLogs,
    fetchData: fetchAccessLogs
  } = useFetch({
    apiFn: userId => get(`users/${userId}/logs/access`),
    defaultValue: [],
    transformResponse: prop("logs"),
    transformError: path(["response", "body", "status"])
  });

  const {
    response: changeLogs,
    isFetching: isFetchingUserChangeLogs,
    fetchData: fetchChangeLogs
  } = useFetch({
    apiFn: userId => get(`users/${userId}/logs/change`),
    defaultValue: [],
    transformResponse: prop("logs"),
    transformError: path(["response", "body", "status"])
  });

  React.useEffect(() => {
    fetchAccessLogs(userId);
    fetchChangeLogs(userId);
  }, [userId]);
  const [showChangeLogs, setShowChangeLogs] = React.useState(true);

  return (
    <div>
      <div className="user-page-header">
        <h1>{prop("accessedByName", nth(0, accessLogs))}</h1>
        <div className="user-header-controls">
          <button onClick={browserHistory.goBack}>Go Back</button>
        </div>
      </div>
      <div className="user-logs-container">
        <div
          className={`log-tab ${showChangeLogs ? "active" : ""}`}
          onClick={() => setShowChangeLogs(true)}
        >
          Change Logs
        </div>
        <div
          className={`log-tab ${showChangeLogs ? "" : "active"}`}
          onClick={() => setShowChangeLogs(false)}
        >   
          Access Logs
        </div>
        {showChangeLogs ? (
          <ChangeLogsPanel
            logs={changeLogs}
            isFetchingLogs={isFetchingUserChangeLogs}
          />
        ) : (
          <AccessLogsPanel
            logs={accessLogs}
            isFetchingLogs={isFetchingUserAccessLogs}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(UserLogs);

UserLogs.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};
