import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { PureModal, IconButton } from "components/ui";
import { get } from "utils/api";
import { path } from "ramda";
import { formatDate } from "utils/misc";
import GoBrowser from "react-icons/lib/go/browser";
import FaMobileAlt from "react-icons/lib/fa/mobile";
import FaSignInAlt from "react-icons/lib/fa/sign-in";
import { AttributeItem } from "components/lists";

const PatientLoginRecords = ({ patientId }) => {
  const { response: patientLoginRecords, fetchData } = useFetch({
    apiFn: patientId => get(`patients/${patientId}/login_records`),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });
  React.useEffect(() => {
    fetchData(patientId);
  }, [patientId]);
  const hasUsedMobile = patientLoginRecords?.find(
    ({ platform }) => platform === "Mobile App",
    patientLoginRecords
  );
  const hasUsedWeb = patientLoginRecords?.find(
    ({ platform }) => platform === "Web Portal",
    patientLoginRecords
  );
  return (
    <PureModal
      renderTrigger={({ openModal }) => (
        <AttributeItem
          title="Platform Usage:"
          actions={
            <IconButton
              text="Logs"
              onClick={openModal}
              type="button"
              icon={<FaSignInAlt />}
            />
          }
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              userSelect: "none"
            }}
          >
            <span
              style={{
                color: hasUsedMobile ? "green" : "#ccc",
                display: "flex",
                alignItems: "center"
              }}
              title={
                hasUsedMobile
                  ? "Has used Mobile App"
                  : "Has NOT used Mobile App"
              }
            >
              <FaMobileAlt size={20} />
              <span style={{ paddingLeft: 2 }}>Mobile</span>
            </span>
            <span
              style={{
                margin: "0px 5px",
                height: "auto",
                alignSelf: "stretch",
                width: 1,
                flexShrink: "0",
                backgroundColor: "rgba(0, 0, 0, 0.12)"
              }}
            />
            <span
              style={{
                color: hasUsedWeb ? "green" : "#ccc",
                display: "flex",
                alignItems: "center"
              }}
              title={
                hasUsedWeb
                  ? "Has used Portal Web App"
                  : "Has NOT used Portal Web App"
              }
            >
              <GoBrowser size={20} />
              <span style={{ paddingLeft: 2 }}>Web</span>
            </span>
          </span>
        </AttributeItem>
      )}
    >
      <div style={{ padding: 5 }}>
        <h4>Login Records</h4>
        <table style={{ tableLayout: "fixed", width: "100%" }}>
          <thead>
            <tr>
              <th>Time</th>
              <th>Platform</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(patientLoginRecords) &&
              patientLoginRecords?.map(x => (
                <tr key={x.created_at}>
                  <td>{formatDate(x.created_at, "LLL")}</td>
                  <td>
                    <span style={{ paddingRight: 5, fontSize: 20 }}>
                      {x.platform === "Mobile App" && <FaMobileAlt />}
                      {x.platform === "Web Portal" && <GoBrowser />}
                    </span>
                    {x.platform}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </PureModal>
  );
};

PatientLoginRecords.propTypes = {
  patientId: PropTypes.string.isRequired
};

export default PatientLoginRecords;
