import { combineReducers } from "redux";
import { createReducer } from "utils/reducer";
import * as R from "ramda";
import { Types } from "actions/team-dashboard";
import { createSelector } from "reselect";

const fetchingInsights = createReducer(false, {
  [Types.REQUEST_TEAM_INSIGHTS]: () => true,
  [Types.RESPONSE_TEAM_INSIGHTS]: () => false,
  [Types.ERROR_TEAM_INSIGHTS]: () => false
});

const insights = createReducer(
  {
    Contacts: [],
    Orders: [],
    Patients: [],
    Coach_Insights: [],
    Team_Insights: [],
    On_Line_Totals: [],
    Allocation_Ratio: []
  },
  {
    [Types.RESPONSE_TEAM_INSIGHTS]: (_, action) =>
      R.propOr(
        {
          Contacts: [],
          Orders: [],
          Patients: [],
          Coach_Insights: [],
          Team_Insights: [],
          On_Line_Totals: [],
          Allocation_Ratio: []
        },
        "payload",
        action
      ),
    [Types.ERROR_TEAM_INSIGHTS]: () => ({
      Contacts: [],
      Orders: [],
      Patients: [],
      Coach_Insights: [],
      Team_Insights: [],
      On_Line_Totals: [],
      Allocation_Ratio: []
    }),
    [Types.UPDATED_COACH_TOTAL_TIME]: (state, { payload }) =>
      R.assoc(
        "On_Line_Totals",
        R.map(
          c =>
            c.coach_guid !== payload.coach_guid
              ? c
              : {
                  ...c,
                  total_time_in_seconds:
                    c.total_time_in_seconds + payload.seconds
                },
          state.On_Line_Totals
        ),
        state
      ),
    [Types.UPDATED_COACH_QUEUE_TIME]: (state, { payload }) =>
      R.assoc(
        "On_Line_Totals",
        R.map(
          c =>
            c.coach_guid !== payload.coach_guid
              ? c
              : {
                  ...c,
                  //end_reason: payload.end_reason,
                  time_in_queue_seconds:
                    c.time_in_queue_seconds + payload.seconds
                },
          state.On_Line_Totals
        ),
        state
      )
  }
);

export default combineReducers({
  fetchingInsights,
  insights
});

const getIsFetchingTeamInsights = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.prop("fetchingInsights")
);
const getTeamPatientInsights = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Patients"])
);
const getTeamContactsInsights = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Contacts"])
);
const getTeamEquipmentSold = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Orders"])
);

const getTeamOrders = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Coach_Insights"])
);

const getTeamAllocation = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Allocation_Ratio"])
);

const getPatientsAllocated = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Patients_Allocated"])
);

const getCoachAllocation = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Coach_Allocation"])
);

const getTeamCoachInsights = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "Team_Insights"])
);

const getTeamCoachOpenOrders = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pipe(
    R.pathOr([], ["insights", "Open_Coach_Order_Counts"]),
    R.map(c => ({
      ...R.omit(["counts"], c),
      ...R.propOr([], "counts", c).reduce(
        (acc, curr) => ({ ...acc, [curr.status]: curr.count }),
        {}
      )
    }))
  )
);

const getTeamCoachOnlineTotals = createSelector(
  [state => state.getIn(["teamDashboard"])],
  R.pathOr([], ["insights", "On_Line_Totals"])
);

export const selectors = {
  getIsFetchingTeamInsights,
  getTeamPatientInsights,
  getTeamContactsInsights,
  getTeamOrders,
  getTeamAllocation,
  getPatientsAllocated,
  getCoachAllocation,
  getTeamEquipmentSold,
  getTeamCoachInsights,
  getTeamCoachOpenOrders,
  getTeamCoachOnlineTotals
};
