import React, { useContext } from "react";
import { ListItem, Checkbox, PureModal } from "components/ui";
import InsuranceCompanyForm from "./insurance-company-form";
import PropTypes from "prop-types";
import { InsuranceCompanyContext } from "./insurance-company-provider";
import HasRolePermission from "components/shared/has-app-permission";
import IoIosInformationCircleOutline from "react-icons/lib/io/ios-information";
import { Tooltip } from "components/ui/tool-tip/tooltip";

const InsuranceCompanyDisplay = ({ companyGuid }) => {
  const { hcpcs } = useContext(InsuranceCompanyContext);

  var educationStatement =
    "Your insurance company promotes your health by allowing you to replace your supplies on a regular basis to prevent complications caused by wear, bacteria or mold. According to your insurance provider guidelines, you will soon be eligible to receive new supplies.";
  const educationStatementTootlip = (
    <div>
      IVR Education Statement
      <Tooltip
        id={`helpful-link-tooltip-education`}
        className="helpful-link-tooltip"
        effect="solid"
        message={`Statement reads as: ${educationStatement}`}
      >
        <IoIosInformationCircleOutline size="1rem" />
      </Tooltip>
    </div>
  );
  return (
    <div
      style={{
        width: "50%",
        margin: "auto",
        alignSelf: "center",
        paddingTop: "40px"
      }}
    >
      <div style={{ padding: 10 }}>
        <ListItem
          label="Name"
          value={hcpcs.prefix + "_" + hcpcs.sInsuranceCompanyName}
        />
        <ListItem label="Type" value={hcpcs.sType} />
        <ListItem
          label="Allows 90 Day Supply"
          value={<Checkbox value={hcpcs.bAllowsNinetyDaySupply} />}
        />
        <ListItem
          label="CMN Required"
          value={<Checkbox value={hcpcs.bCMNRequired} />}
        />
        <ListItem
          label="Counts Mask As Seal"
          value={<Checkbox value={hcpcs.bCountsMaskAsSeal} />}
        />
        <ListItem
          label="Eligible For Verification"
          value={<Checkbox value={hcpcs.bEligibleForVerification} />}
        />
        <ListItem
          label="Excluded"
          value={<Checkbox value={hcpcs.bExcluded} />}
        />
        <ListItem
          label="Use As Primary"
          value={<Checkbox value={hcpcs.bUseAsPrimary} />}
        />
        <ListItem
          label="Use As Secondary"
          value={<Checkbox value={hcpcs.bUseAsSecondary} />}
        />
        <ListItem
          label="Requires Dysfunctions Questions"
          value={<Checkbox value={hcpcs.bAsksDysfunctionsQuestions} />}
        />
        <ListItem
          label="IVR Offers Autoship"
          value={<Checkbox value={hcpcs.bIVRAutoShip} />}
        />
        <ListItem
          label={educationStatementTootlip}
          value={<Checkbox value={hcpcs.bIVREducationStatement} />}
        />
        <ListItem
          label="Allowable Duration"
          value={hcpcs.iAllowableDuration ? hcpcs.iAllowableDuration : "None"}
        />
        <ListItem
          label="Capitated Duration"
          value={hcpcs.iCapitated ? hcpcs.iCapitated : "None"}
        />
        <ListItem
          label="Client Insurance ID"
          value={hcpcs.sPayorID ? hcpcs.sPayorID : "None"}
        />
        <HasRolePermission allowedRoles={["Administrator"]}>
          <ListItem label="Legacy ID" value={hcpcs.legacyId} />
        </HasRolePermission>
        <ListItem
          label="Extra Info"
          value={hcpcs.sExtraInfo ? hcpcs.sExtraInfo : "None"}
        />
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "ServiceAdmin"
            //,'ContractorAdministrator'
          ]}
        >
          <PureModal
            renderTrigger={({ openModal }) => (
              <button onClick={openModal} className="button tiny secondary">
                Edit
              </button>
            )}
            renderContent={({ closeModal }) => (
              <InsuranceCompanyForm
                values={{
                  companyGuid: companyGuid ?? "",
                  insuranceCompanyGuid: hcpcs.guid,
                  bActive: hcpcs.bActive,
                  bEligibleForVerification: hcpcs.bEligibleForVerification,
                  bExcluded: hcpcs.bExcluded,
                  bAllowsNinetyDaySupply: hcpcs.bAllowsNinetyDaySupply,
                  bCMNRequired: hcpcs.bCMNRequired,
                  bCountsMaskAsSeal: hcpcs.bCountsMaskAsSeal,
                  bUseAsPrimary: hcpcs.bUseAsPrimary,
                  bUseAsSecondary: hcpcs.bUseAsSecondary,
                  sExtraInfo: hcpcs.sExtraInfo ?? "",
                  sPayorID: hcpcs.sPayorID ?? "",
                  iAllowableDuration: hcpcs.iAllowableDuration ?? 0,
                  iCapitated: hcpcs.iCapitated ?? 0,
                  sInsuranceCompanyName: [hcpcs.sInsuranceCompanyName],
                  typeGuid: hcpcs.typeGuid ?? "",
                  prefix: hcpcs.prefix,
                  bAsksDysfunctionsQuestions: hcpcs.bAsksDysfunctionsQuestions,
                  bIVRAutoShip: hcpcs.bIVRAutoShip,
                  bIVREducationStatement: hcpcs.bIVREducationStatement
                }}
                add={false}
                closeModal={closeModal}
              />
            )}
          />
        </HasRolePermission>
      </div>
    </div>
  );
};

export default InsuranceCompanyDisplay;
InsuranceCompanyDisplay.propTypes = {
  hcpcs: PropTypes.object,
  companyGuid: PropTypes.string
};
