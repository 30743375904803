import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { supplyOptions } from "./options";
import HasRolePermission from "components/shared/has-role-permission";
import NoMaskReasonInput from "./no-mask-reason-input";
import { PatientProfileContext } from "components/profile/context";
import { formatDate } from "utils/dates";
import { withModal } from 'components/ui';
import ConfirmationModal from "components/shared/confirmation-modal";

const PopularEquipment = [
  "tubing",
  "disposable filter",
  "nondisposable filter",
  "seals",
  "mask",
  "headgear"
];
const BasicEquipment = [
  "tubing",
  "disposable filter",
  "nondisposable filter",
  "seals"
];

const ChooseSupplies = ({
  values,
  setFieldValue,
  patientHCPCHistory,
  eligibleEquipmentType,
  patientInfo,
  openModal,
  closeModal
}) => {
  useEffect(() => {
    if (values.requested_equipment?.includes("mask")) {
      setFieldValue("reason_for_no_mask", "");
    }
  }, [values.requested_equipment]);

  const [selectedEligibleValue, setSelectedEligibleValue] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      openModal(
        <ConfirmationModal
          title="The item(s) you are ordering are not currently eligible through insurance, this means you may be responsible for the total cost of the item(s), would you like to proceed and add them to your order?"
          closeModal={closeModal}
          yesAction={() => { handleEligibleConfirmationModal() }}
          notNowAction={() => { }}
          noOptionText="No"
        />
      );
      setShowModal(false);
    }
  }, [showModal, openModal, closeModal]);

  const handleEquipmentOnChange = (thisItem, selectedEquipment, e, value) => {
    if (thisItem == null || thisItem.isEligible) {
      if (e.target.checked) selectedEquipment.push(value);
      else {
        const idx = values.requested_equipment.indexOf(value);
        selectedEquipment.remove(idx);
      }
    } else {
      if (!thisItem.isEligible) {
        if (e.target.checked) {
          var eligibleValue = [...values.requested_equipment, value];
          handleSelectedSupplies(eligibleValue);
        } else {
          const idx = values.requested_equipment.indexOf(value);
          selectedEquipment.remove(idx);
          check_has_not_eligible_selected(value);
        }
      }
    }
  };

  const handleEligibleConfirmationModal = () => {
    var selectedEquipment = [...selectedEligibleValue];
    setFieldValue("requested_equipment", selectedEquipment);
    setFieldValue("has_not_eligible_selected", true);
  };

  const check_has_not_eligible_selected = (currentValue = '') => {
    let has_not_eligible_selected = false;
    for (const value of values.requested_equipment) {
      const item = patientHCPCHistory && patientHCPCHistory?.find(phh =>
        phh.productType.toLowerCase()?.includes(value.toLowerCase())
      );
      if (currentValue != value) {
        if (!item.isEligible) {
          has_not_eligible_selected = true;
        }
      }
    }
    setFieldValue("has_not_eligible_selected", has_not_eligible_selected);
  };

  const handleSelectedSupplies = (supplies) => {
    let has_not_eligible_selected = false;
    for (const value of supplies) {
      const item = patientHCPCHistory && patientHCPCHistory?.find(phh =>
        phh.productType.toLowerCase()?.includes(value.toLowerCase())
      );
      if (!item.isEligible) {
        has_not_eligible_selected = true;
      }
    }

    if (has_not_eligible_selected) {
      setSelectedEligibleValue(supplies);
      setShowModal(true);
    } else {
      setFieldValue("requested_equipment", supplies);
    }
  };
  
  const { company_order_settings } = useContext(PatientProfileContext);

  const eligibleEquipment = supplyOptions
    .filter(
      equip =>
        company_order_settings?.equipment_type_options?.includes(equip.value) &&
        eligibleEquipmentType.includes(equip.value)
    )
    .map(x => x.value);

  return (
    <FieldArray
      name="requested_equipment"
      render={selectedEquipment => (
        <>
          <div className="choose-supplies">
            <div style={{ display: "flex" }}>
              <ul>
                {supplyOptions
                  .filter(x =>
                    company_order_settings?.equipment_type_options?.includes(
                      x.value
                    )
                  )
                  .map(({ value, text, description }) => {
                    const thisItem = patientHCPCHistory?.find(phh =>
                      phh.productType.toLowerCase()?.includes(value.toLowerCase())
                    );
                    return (
                      <li key={value}>
                        <label>
                          <div className="supply-checkbox-container">
                            <input
                              name="requested_equipment"
                              type="checkbox"
                              value={value}
                              checked={values.requested_equipment.includes(
                                value
                              )}
                              onChange={e =>
                                handleEquipmentOnChange(
                                  thisItem,
                                  selectedEquipment,
                                  e,
                                  value
                                )
                              }
                            />
                          </div>
                          <div>
                            <div style={{ display: "flex" }}>
                              <div>
                                {text}
                                {"   "}
                              </div>{" "}
                              {thisItem && (
                                <div
                                  className={
                                    thisItem.isEligible
                                      ? "eligible"
                                      : "notEligible"
                                  }
                                >
                                  {thisItem.isEligible
                                    ? "Eligible"
                                    : formatDate(
                                        thisItem.eligibilityDate,
                                        "MM/DD/YYYY"
                                      )}
                                </div>
                              )}
                            </div>
                            <div className="description">{description}</div>
                          </div>
                        </label>
                      </li>
                    );
                  })}
              </ul>

              <div className="eligbleDisclaimerParent">
                <div className="eligbleDisclaimer">
                  * Items automatically selected are based off perceived
                  eligibility from insurance rules
                </div>
              </div>
            </div>
            {!patientInfo?.new_setup && (
              <div className="choose-supplies-button-group">
                <button
                  onClick={() => {
                    handleSelectedSupplies(eligibleEquipment);
                  }}
                  type="button"
                >
                  Eligible Items
                </button>
                <button
                  onClick={() => {
                    handleSelectedSupplies(PopularEquipment);
                  }}
                  type="button"
                >
                  Popular
                </button>
                <button
                  onClick={() => {
                    handleSelectedSupplies(BasicEquipment);
                  }}
                  type="button"
                >
                  Basic
                </button>
              </div>
            )}
            <HasRolePermission allowedRoles={["SleepCoach"]}>
              <NoMaskReasonInput
                requested_equipment={values.requested_equipment}
              />
            </HasRolePermission>
          </div>
        </>
      )}
    />
  );
};

ChooseSupplies.propTypes = {
  values: PropTypes.shape({
    requested_equipment: PropTypes.arrayOf(PropTypes.string).isRequired,
    has_not_eligible_selected: PropTypes.bool.isRequired
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  patientHCPCHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  eligibleEquipmentType: PropTypes.arrayOf(PropTypes.string),
  patientInfo: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withModal(ChooseSupplies);
