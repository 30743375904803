import { Types } from "actions/app-version";
import { createReducer } from "utils/reducer";
import { assoc, prop } from "ramda";
import { createSelector } from "reselect";
import { AppVersion } from "environment";

export default createReducer(
  { currentversion: AppVersion, newVersion: null },
  {
    [Types.SET_NEW_APP_VERSIONS]: (state, action) =>
      assoc("newVersion", action.payload)(state)
  }
);

export const selectors = {
  getCurrentAppVersion: createSelector(
    [state => state.get("appVersion")],
    prop("currentversion")
  ),
  getNewAppVersion: createSelector(
    [state => state.get("appVersion")],
    prop("newVersion")
  )
};
