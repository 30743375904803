import React from "react";
import PropTypes from "prop-types";

export default function ModalAssignFormToPatients({ closeModal, onYes, onNo }) {

    const onYesAction = ()=>{
        closeModal();
        if (typeof onYes === 'function') {
            onYes();
        }
    };

    return <div>
        <div style={{textAlign:'center' , paddingTop:'10px'}}>
            <span  style={{ fontSize: '20px'}}>
            Would you like to assign this form to existing patients?
            </span>
        </div>
        <div className='dd_col-12'>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft:'50px' }}>
                    <button type="button" className="button alert" onClick={() => { closeModal(); onNo && onNo()}}> No </button>
                </div>
            </div>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-end", paddingRight:'50px' }}>
                    <button type="button" className="button" onClick={() => { onYesAction(); }}> Yes </button>
                </div>
            </div>
        </div>
    </div>
}

ModalAssignFormToPatients.propTypes = {
    closeModal: PropTypes.func.isRequired,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func
};