import React, { Component } from "react";
import { connect } from "react-redux";
import { PageHeader, ToggleSwitch, Waiting } from "components/ui";
import DataTable from "../../../ui/data-table";
import Modal from "../../components/ModalComponent";
import AddNewArticle from "./addNew";
import PropTypes from "prop-types";
import {
  getArticles,
  createArticles,
  updateArticles,
  updateStatusArticles,
} from "./../../Redux/Actions/articles";
import { errorMessage } from "./../../../../actions/message";

let Styles = {
  container: {
    background: "#fff",
    border: "1px solid #DEEAEE",
    padding: "20px",
    marginBottom: "15px",
  },
  textRendering: {
    textRendering: "optimizeLegibility!important",
  },
};

class Articles extends Component {
  static getDerivedStateFromProps(props) {
    if (props.Articles && props.Articles.length > 0) {
      return {
        scheduleDays: props.ScheduleDays,
      };
    }
    return null;
  }

  static propTypes = {
    errorMessage: PropTypes.func,
    Articles: PropTypes.arrayOf(PropTypes.object),
    ScheduleDays: PropTypes.number,
    isLoader: PropTypes.bool,
    getArticles: PropTypes.func,
    createArticles: PropTypes.func,
    updateArticles: PropTypes.func,
    updateStatusArticles: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetch: false,
      modalOpen: false,
      selectedArticle: null,
      scheduleDays: props.ScheduleDays,
      emptyArticle: {
        article_id: 0,
        link: "",
        title: "",
        company: "",
        active: true,
      },
      columns: [
        {
          title: "Article Title",
          header: "Article Title",
          field: (article) =>
            article.link.length > 0 ? (
              <a href={article.link} target="blank">
                {article.title}
              </a>
            ) : (
              <>{article.title}</>
            ),
        },
        {
          title: "Edit",
          header: "Edit",
          field: (article) => (
            <button type="button" onClick={() => this.onArticleEdit(article)}>
              Edit
            </button>
          ),
        },
        {
          title: "Active",
          header: "Active",
          field: article => (
            <ToggleSwitch
              onChange={checked =>
                this.props.updateStatusArticles(article, checked)
              }
              value={article.active}
            />
          )
        },
      ],
    };
  }

  componentDidMount() {
    this.props.getArticles();
  }

  addNewQuestion = () => {
    this.setState({
      selectedArticle: this.state.emptyArticle,
    });
    this.onClose();
  };

  onClose = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  isUrlValid = (userInput) => {
    var urlregex = new RegExp(
      "^(http://www.|https://www.|ftp://www.|www.){1}([0-9A-Za-z]+.)"
    );
    if (!urlregex.test(userInput)) return false;
    else {
      if (userInput.indexOf("://") === -1) {
        this.props.errorMessage("http or https protocol required to validate!");
      } else {
        return true;
      }
    }
  };

  onDataSave = (data) => {
    if (data.link.length === 0) {
      this.props.errorMessage("Link field required!");
    } else if (data.title.length === 0) {
      this.props.errorMessage("Title field required!");
    } else if (data.company.length === 0) {
      this.props.errorMessage("Copmany field required!");
    } else {
      if (data.link.length > 0) {
        if (this.isUrlValid(data.link)) {
          if (data && data.article_id === 0) {
            this.props.createArticles(data);
          } else {
            this.props.updateArticles(data);
          }
          this.onClose();
        } else {
          this.props.errorMessage("Enter Valid Link");
        }
      }
    }
  };

  onArticleEdit = (data) => {
    this.setState({
      selectedArticle: data,
    });
    this.onClose();
  };

  render() {
    return (
      <div className="dd_root dd_row">
        {this.props.isLoader && <Waiting />}
        <PageHeader title="As Needed Articles" />
        <Modal onClose={this.onClose} show={this.state.modalOpen}>
          <AddNewArticle
            onSave={this.onDataSave}
            Article={this.state.selectedArticle}
          />
        </Modal>
        <div
          className="dd_col-12"
          style={{ ...Styles.container, height: "auto", overflowY: "auto" }}
        >
          <button
            type="button"
            className="dd_col-2"
            style={{ margin: "10px" }}
            onClick={this.addNewQuestion}
          >
            Add Article
          </button>
          {this.props.Articles && this.props.Articles.length > 0 ? (
            <div className="dd_col-12" style={{ margin: "10px" }}>
              <DataTable
                columns={this.state.columns}
                rows={this.props.Articles}
                disabledsort={true}
              />
            </div>
          ) : (
            !this.props.isLoader && (
              <div
                className="dd_col-12 dd_noDataTitle"
                style={{ fontSize: "20px" }}
              >
                No Articles Created yet
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Articles: state.getIn(["newRootReducer"]).rdc_articles.AsNeeddedArticles,
    ScheduleDays: state.getIn(["newRootReducer"]).rdc_articles.ScheduleDays,
    isLoader: state.getIn(["newRootReducer"]).rdc_loader.isLoader,
  };
};

export default connect(mapStateToProps, {
  errorMessage,
  getArticles,
  createArticles,
  updateArticles,
  updateStatusArticles,
})(Articles);
