import React from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"
import './Message.css'
import moment from 'moment'

function Message({author, body, host, authorId, timestamp, attributes}){
    const classes = classNames('Message', {
        log: !author
    })
  
    return (host.id == authorId) || (host.id == attributes?.authorId) ? (
        <div className={classes} style={{display: "flex", flexDirection: "column",  alignItems: "end"}}>
            <div className='author-message-info-container'>
                {author && (<span className="author">{author}</span>)}
                <div>-</div>
                <div className='timestamp'>
                    {moment(timestamp).format("hh:mm a ")}
                </div>
            </div>
            <div className='host-message-body'>
               {body} 
            </div>
        </div>
    ) : (
        <div className={classes}>
            <div className='patient-message-info-container'>
                {author && (<span className="author">{author}</span>)}
                <div>-</div>
                <div className='timestamp'>
                    {moment(timestamp).format("hh:mm a ")}
                </div>
            </div>
            <div className='patient-message-body'>
               {body} 
            </div>
        </div>
    )
}

Message.propTypes = {
    author: PropTypes.string,
    body: PropTypes.string.isRequired,
    host: PropTypes.object,
    authorId: PropTypes.string,
    timestamp: PropTypes.object,
    attributes: PropTypes.object,
    me: PropTypes.bool,
}

export default Message
