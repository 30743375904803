import { take, call, put, all } from "redux-saga/effects";
import * as api from "utils/api";
import A from "action-types";
import { requestData } from "../features/query/actions";

export default function* root() { 
   yield all([
       watchAssignVmailToSleepCoach(),
       watchMarkVmailAs()
       
    ])
}
function* watchAssignVmailToSleepCoach() {
    while (true) {
        const action = yield take(A.VMAILS.ASSIGN_VOICEMAILS_TO_COACH)
        yield call(assignVmailToSleepCoach, action)
    }
}

function* watchMarkVmailAs() {
    while (true) {
        const action = yield take(A.VMAILS.MARK_VOICEMAILS_AS)
        yield call(markVmailAs, action)
    }
}


export function* assignVmailToSleepCoach(action) {
    const { apiId, query, method, route, responseKey, entityKey, idKey } = action.payload.dataForRefresh;
    const { batchClear } = action.payload;
    const payload = {
        sleep_coach: action.payload.sleep_coach,
        voicemail_guids: action.payload.voicemail_guids
    }
    try {
        yield call(api.put, "voicemails/assignCoach", payload)
        //show notifcation that it was assigned        
        
        yield put(requestData(apiId, query, method, route, responseKey, entityKey, idKey)())
        yield batchClear()
    } catch (payload) {
        //yield put(errorUserLogout(payload))
    }
}


export function* markVmailAs(action) {
    const { apiId, query, method, route, responseKey, entityKey, idKey } = action.payload.dataForRefresh;
    const { batchClear } = action.payload;
    const payload = {
        mark_as: action.payload.mark_as,
        voicemail_guids: action.payload.voicemail_guids
    }
    try {
        yield call(api.put, "voicemails/markAs", payload)
        //show notifcation that it was assigned
       
        yield put(requestData(apiId, query, method, route, responseKey, entityKey, idKey)())
        yield batchClear()
        
       
    } catch (payload) {
        //yield put(errorUserLogout(payload))
    }
}
