import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/dates";
import { MdCheckBox, MdCancel } from "react-icons/lib/md";
import { get } from "utils/api";
import { useFetch } from "hooks";

const LineItemEligiblityTab = ({ patientId }) => {
  const lineItemEligiblityApi = useFetch({
    apiFn: patientId =>
      get(`patients/${patientId}/hcpcs?useLegacyEligiblity=true`),
    defaultValue: { hcpcsRecords: [] }
  });
  React.useEffect(() => {
    lineItemEligiblityApi.fetchData(patientId);
  }, [patientId]);
  return (
    <div>
      <table className="large-12" style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <td>Product Type</td>
            <td>Eligibility Date</td>
            <td>Quantity</td>
            <td>Last Ordered Date</td>
            <td>HCPC Code</td>
          </tr>
        </thead>
        <tbody>
          {lineItemEligiblityApi.response?.hcpcsRecords.map((record, i) => {
            return (
              <tr key={`${record.code}-${i}`}>
                <td style={{ display: "flex" }}>
                  {!record.eligible ? (
                    <div title="Patient is not currently eligible for this item">
                      <MdCancel style={{ color: "red", marginRight: "5px" }} />
                      {record.productType}
                    </div>
                  ) : (
                    <div title="Patient is currently eligible for this item">
                      <MdCheckBox
                        style={{ color: "green", marginRight: "5px" }}
                      />
                      {record.productType}
                    </div>
                  )}
                </td>
                <td>
                  <div>{formatDate(record.eligibilityDate, "ll")}</div>
                </td>
                <td>{record.quantity}</td>
                <td>{formatDate(record.orderDate, "ll")}</td>
                <td>{record.code}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

LineItemEligiblityTab.propTypes = {
  patientId: PropTypes.string.isRequired
};

export default LineItemEligiblityTab;
