/**
 * This module exports factory functions to make action creators to be
 * dispatched by Redux. It is tightly coupled to the fetch reducer.
 */
import { get } from "utils/api"; //jsb added put

export const requestType = baseType => `REQUEST_${baseType}`;
export const receiveType = baseType => `RECEIVE_${baseType}`;
export const failureType = baseType => `FAILURE_${baseType}`;
export const invalidateType = baseType => `INVALIDATE_${baseType}`;

export const requestExp = /^REQUEST_(.+)/;
export const receiveExp = /^RECEIVE_(.+)/;
export const failureExp = /^FAILURE_(.+)/;
export const invalidateExp = /^INVALIDATE_(.+)/;

const shouldFetch = fetchingState => {
  if (!fetchingState) {
    return true;
  } else if (fetchingState.get("isFetching")) {
    return false;
  } else {
    return fetchingState.get("didInvalidate");
  }
};

/**
 * Makes action creator used to request an endpoint. Action creators
 * will dispatch two actions: (1) a "REQUEST" action to indicate that
 * a fetch is being made; (2) either a "RECEIVE" or "FAILURE" action
 * to indicate the success or failure of the fetch, respectively. The
 * first argument parameterizes the action creator.
 *
 *   url: string or (object => string) - URL of endpoint to
 *   fetch. Function argument takes parameter object of action creator
 *   as argument and returns URL string.
 *
 *   baseType: string - type to prepend "REQUEST_", "RECEIVE_", or
 *   "FAILURE_" to. Assigned as a property to action creator for easy
 *   lookup.
 *
 *   mapActionToKeyIn: object => [string] - Takes action creator
 *   parameters and returns array of strings to indicate fetch
 *   location in Redux store.
 *
 *   transform: object => any - Transforms response data as specified
 *   by function before action is dispatched to Redux store.
 *
 *   method: (string, object) => Promise - Function that takes URL
 *   endpoint and payload object to execute fetch action. Works well
 *   will functions exported by utils/api.
 *
 *   cache: boolean - Explicitly set whether to cache or not. A cached
 *   fetch request will not fetch again if action creator dispatched
 *   unless invalidated. GET requests are cached by default.
 *
 *   fetchReducer: [string] - Uncommonly used. Keys into fetch reducer
 *   root from root reducer.
 */
export const makeFetchCreator = ({
  url,
  baseType,
  mapActionToKeyIn = () => [],
  transform = x => x,
  method = get,
  cache,
  meta,
  onErrorAction,
  fetchReducer = ["fetch"]
}) => {
  cache = cache !== undefined ? cache : method === get;
  const creator =
    (params = {}) =>
    (dispatch, getState) => {
      if (
        !cache ||
        !mapActionToKeyIn ||
        shouldFetch(
          getState().getIn([
            ...fetchReducer,
            baseType,
            ...mapActionToKeyIn(params)
          ])
        )
      ) {
        const apiUrl = typeof url === "function" ? url(params) : url;
        dispatch({
          ...params,
          type: requestType(baseType),
          mapActionToKeyIn
        });
        return method(apiUrl, params.payload)
          .then(response =>
            dispatch({
              ...params,
              type: receiveType(baseType),
              response: transform(response, params),
              receivedAt: Date.now(),
              mapActionToKeyIn,
              meta: meta && params
            })
          )
          .catch(error => {
            dispatch({
              ...params,
              type: failureType(baseType),
              error,
              // sometimes error does not appear properly in Redux
              // DevTools, so the string message is included here
              errorMessage: error && error.message,
              errorStack: error && error.stack,
              mapActionToKeyIn
            });
            if (typeof onErrorAction === "function") {
              dispatch(onErrorAction(error));
            }
            throw error;
          });
      } else {
        return Promise.resolve();
      }
    };
  creator.baseType = baseType;
  return creator;
};

/**
 * Makes action creator used to mark fetch data as invalidated. If the
 * user has made a cached fetch, then it must be invalidated if
 * another request needs to be made. Otherwise, the fetch action
 * creator will not call the API method.
 *
 * It is uncommon to call `makeInvalidator` without a corresponding
 * `makeFetchCreator`. For convencience, use `makeFetchTuple` to make
 * both types of action creators.
 */
export const makeInvalidator =
  ({ baseType, mapActionToKeyIn = () => [] }) =>
  params => ({
    ...params,
    type: invalidateType(baseType),
    mapActionToKeyIn
  });

/**
 * Makes both fetch and invalidator action creators as the first and
 * second elements in an array, respectively.
 */
export const makeFetchTuple = params => [
  makeFetchCreator(params),
  makeInvalidator(params)
];
