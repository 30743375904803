import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import pick from 'lodash/pick'
import compose from 'lodash/fp/compose'
import { selectors } from 'reducers'
import { mapDispatchToProps } from 'actions/order-profile'

const PackingSlipHeader = ({
  invoice_number
}) => (
  <div className='packing-slip-header'>
    <h1>Packing Slip Of Requested Items</h1>
    <h5>{invoice_number}</h5>
  </div>
)

PackingSlipHeader.propTypes = {
  orderId: PropTypes.string.isRequired,
  invoice_number: PropTypes.string
}

export default compose(
  connect(
    (state, props) => {
      const { data } = selectors.getOrderInfo(state, props)
      return {
        ...pick(data, [
          'invoice_number'
        ])
      }
    },
    mapDispatchToProps
  )
)(PackingSlipHeader)
