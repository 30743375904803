import React from "react";
import PropTypes from "prop-types";
import { MdRemove } from "react-icons/lib/md";

export const PureWindow = ({
  large,
  onXClick,
  children,
  hidden,
  minimize,
  closeable,
  className,
  chatIsOpen,
  modalZIndex,
  modalBehindModal,
  biggerMaxWidth,
  title
}) => (
  <div className={`modal-window ${className}`} onClick={e => e.stopPropagation()}>
    <div
      className="modal-window-overlay"
      style={{ display: hidden ? "none" : "block" }}
    />
    <div
      className={`modal-window-content-container${large ? " large" : ""} ${
        chatIsOpen ? "chat-active" : ""
      } ${biggerMaxWidth ? "bigger-max-width" : ""}`}
      style={
        !modalBehindModal
          ? { display: hidden ? "none" : "block" }
          : {
              display: hidden && !modalZIndex ? "none" : "block",
              zIndex: modalZIndex ? modalZIndex : "unset"
            }
      }
    >
      {closeable && (
        <div className={title ? "modal-status-bar-title" : "modal-status-bar"}>
          {minimize ? (
            <span className="close-modal-x">
              <MdRemove size={28} onClick={onXClick} color="black" />
            </span>
          ) : title ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                display: "flex",
                background: "#f3f3f3"
              }}
            >
              <span style={{ width: "95%" }}>{title}</span>
              <span
                className="close-modal-x"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "5%"
                }}
                onClick={onXClick}
              >
                &#x2715;
              </span>
            </div>
          ) : (
            <span className="close-modal-x" onClick={onXClick}>
              &#x2715;
            </span>
          )}
        </div>
      )}
      <div className="modal-window-content">{children}</div>
    </div>
  </div>
);

PureWindow.propTypes = {
  onXClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func,
  large: PropTypes.bool,
  hidden: PropTypes.bool,
  minimize: PropTypes.bool,
  chatIsOpen: PropTypes.bool,
  closeable: PropTypes.bool,
  className: PropTypes.string,
  modalZIndex: PropTypes.number,
  modalBehindModal: PropTypes.bool,
  biggerMaxWidth: PropTypes.bool,
  title: PropTypes.string
};

PureWindow.defaultProps = {
  large: false,
  minimize: false,
  onOutsideClick: () => {},
  closeable: true,
  className: ""
};

export default PureWindow;
