import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateRange from "components/ui/date-range";
import DateRangeContext from "../date-range-context";
import PatientDemographicLogTable from "./table";

const PatientDemographicLogs = ({ params: { id } }) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeIsValid,
    handleSubmit,
  } = React.useContext(DateRangeContext);

  return (
    <div className="company-demo-logs-container">
      <div className="date-range-input">
        <DateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSubmit={handleSubmit}
          isValid={dateRangeIsValid}
        />
      </div>
      <PatientDemographicLogTable companyId={id} />
    </div>
  );
};

PatientDemographicLogs.propTypes = {
  params: PropTypes.shape({ id: PropTypes.string.isRequired }),
};

export default connect(() => ({}), {})(PatientDemographicLogs);
