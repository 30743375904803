import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { unsetReadOnlyPatientFlag } from "actions/patient-verification";

const PatientVerificationButton = ({
  patientVerification,
  unsetReadOnlyPatientFlag
}) => (
  <>
    {patientVerification.readOnly && (
      <button
        className="validate-patient-button"
        onClick={unsetReadOnlyPatientFlag}
      >
        Validate Patient
      </button>
    )}
  </>
);

PatientVerificationButton.propTypes = {
  patientVerification: PropTypes.object.isRequired,
  unsetReadOnlyPatientFlag: PropTypes.func.isRequired
};

export default connect(
  state => ({ patientVerification: selectors.getPatientVerification(state) }),
  { unsetReadOnlyPatientFlag }
)(PatientVerificationButton);
