import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { withStateHandlers } from 'recompose'
import PatientFinder from 'components/patient-finder'
import BulkPatientUpdate from 'components/bulk-patient-update'
import { PageHeader, Spinner } from 'components/ui'
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { useFetch } from "hooks/index.jsx";
import { put } from "utils/api.js";
import { path } from "ramda";

let PatientUpdateByGuid = ({
  handleGuidResponse,
  patientsNotFound,
  patientsFound,
  clearPatients
}) => {
  const [companiesData, setCompaniesData] = useState();
  const [patientsData, setPatientsData] = useState();
  const companies = useSelector(selectors.getFormOptionsCompany);

  const { fetchData: fetchCompaniesSettings } = useFetch({
    apiFn: () => put(`companies/companies-patient-stages`, companies.map(({value}) => value)),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });

  const { fetchData: fetchPatientsInfo } = useFetch({
    apiFn: () => put(`patients/patients-stage`, { Patients: patientsFound }),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });

  const getCompaniesStageSettings = async () => {
    const result = await fetchCompaniesSettings();
    if(result.success) {
      setCompaniesData(result.Companies);
    }
  }

  const getPatientsStage = async () => {
    const result = await fetchPatientsInfo();
    if(result.success) {
      setPatientsData({ anyCompliancePatients: result.CompliancePatients, anyResupplyPatients: result.ResupplyPatients});
    }
  }

  useEffect(() => {
    if(companies?.length > 0){
      getCompaniesStageSettings();
    }
  }, [companies])

  useEffect(() => {
    if(patientsFound.length > 0 && companies?.length > 0){
      getPatientsStage();
    }
  }, [patientsFound, companies])

  return <div className='patient-update-by-guid'>
    <PatientFinder onGuidResponse={handleGuidResponse} companiesLoaded={companies.length > 0}/>
    <div>
    {companies.length == 0 && (
        <div>
          Loading accessible companies... 
          <Spinner/>
        </div>
      )}
      {patientsNotFound.length > 0 && (
        <div className='patients-not-found' title={patientsNotFound.join('\n')}>
          No records were found for {patientsNotFound.length} IDs
          (hover for details).
        </div>
      )}
      {patientsFound.length > 0 && (
        <div className='patients-found' title={patientsFound.join('\n')}>
          {patientsFound.length} patients found. Updates will be
          applied to these patients (hover for details).
        </div>
      )}
      {patientsFound.length > 0 && companiesData.length > 0 && patientsData != undefined && (
        <BulkPatientUpdate
          patientIds={patientsFound}
          onUpdateSuccess={clearPatients} 
          companiesData={companiesData}
          patientsData={patientsData}
          />
      )}
    </div>
  </div>
}

PatientUpdateByGuid.propTypes = {
  clearPatients: PropTypes.func.isRequired,
  handleGuidResponse: PropTypes.func.isRequired,
  patientsFound: PropTypes.arrayOf(PropTypes.string).isRequired,
  patientsNotFound: PropTypes.arrayOf(PropTypes.string).isRequired
};

PatientUpdateByGuid = withStateHandlers(
  { patientsFound: [], patientsNotFound: [] },
  {
    handleGuidResponse: () => ({ found, notFound }) => ({
      patientsFound: found || [],
      patientsNotFound: notFound || []
    }),
    clearPatients: () => () => (
      { patientsFound: [], patientsNotFound: [] }
    )
  }
)(PatientUpdateByGuid)

export default PatientUpdateByGuid;

export const PatientUpdateByGuidScreen = props => (
  <div>
    <PageHeader title='Bulk Patient Update' />
    <PatientUpdateByGuid {...props} />
  </div>
)
