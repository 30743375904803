import { errorMessage } from "actions/message";
import {
  invalidatePatientProfileInfo,
  invalidatePatientOrders,
  fetchPatientOrders,
  fetchPatientProfileInfo
} from "actions/patient";
import * as R from "ramda";
import { post } from "utils/api";
import orderEquipmentQuestions, {
  transformEquipmentName
} from "utils/order-equipment-questions";
import { formatDate } from "utils/dates";

export const Types = {
  CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
  ORDER_FORM_RESPONSE: "ORDER_FORM_RESPONSE", // old order form action, used to extend auto dialer time
  CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",
  ORDER_SUBMITTED_IN_OTHER_WINDOW: "ORDER_SUBMITTED_IN_OTHER_WINDOW"
};

export const createPatientOrderNew = order => async dispatch => {
  try {
    /*
      remove values for dysfunctional issues for non requested equipment
      look into alternative/cleaner solutions
    */
    const { requested_equipment, insurances } = order;
    const primary_insurance = insurances.find(
      insurance => insurance.primary_order == 1
    );
    const secondary_insurance = insurances.find(
      insurance => insurance.primary_order == 1
    );

    const strippedKeys =
      primary_insurance?.requires_dysfunctions ||
      secondary_insurance?.requires_dysfunctions
        ? R.pipe(
            R.filter(
              ({ equipment }) =>
                !requested_equipment
                  .map(transformEquipmentName)
                  .includes(equipment.toUpperCase())
            ),
            R.map(({ key }) => key)
          )(orderEquipmentQuestions)
        : orderEquipmentQuestions.map(x => x.key);

    const payload = R.omit(strippedKeys, order);
    const { id } = await post("create-order", {
      ...payload,
      requested_equipment: R.uniq(requested_equipment)
    });
    dispatch({
      type: Types.ORDER_FORM_RESPONSE,
      payload: { orderId: id }
    });
    try {
      // get updated patient profile order
      dispatch(invalidatePatientProfileInfo({ patientId: order.patient_id }));
      dispatch(fetchPatientProfileInfo({ patientId: order.patient_id }));
      dispatch(invalidatePatientOrders({ patientId: order.patient_id }));
      await dispatch(fetchPatientOrders({ patientId: order.patient_id }));
    } catch (error) {
      /* handle error */
    }
    return id;
  } catch (error) {
    console.error(error);
    dispatch(errorMessage("Sorry, your order could not be processed"));
  }
};

export const createPatientOrder = order => async dispatch => {
  try {
    /*
      remove values for dysfunctional issues for non requested equipment
      look into alternative/cleaner solutions
    */
    const { requested_equipment, secondary_insurance, primary_insurance } =
      order;

    const strippedKeys =
      primary_insurance.requires_dysfunctions ||
      secondary_insurance.requires_dysfunctions
        ? R.pipe(
            R.filter(
              ({ equipment }) =>
                !requested_equipment
                  .map(transformEquipmentName)
                  .includes(equipment)
            ),
            R.map(({ key }) => key)
          )(orderEquipmentQuestions)
        : orderEquipmentQuestions.map(x => x.key);

    const payload = R.omit(strippedKeys, order);
    const { id } = await post("orders", {
      order: {
        ...payload,
        requested_equipment: R.uniq(requested_equipment),
        primary_insurance: {
          ...primary_insurance,
          policy_start_date: formatDate(
            primary_insurance.policy_start_date,
            "YYYY-MM-DD"
          ),
          policy_end_date: formatDate(
            primary_insurance.policy_end_date,
            "YYYY-MM-DD"
          )
        },
        secondary_insurance: {
          ...secondary_insurance,
          policy_start_date: formatDate(
            secondary_insurance.policy_start_date,
            "YYYY-MM-DD"
          ),
          policy_end_date: formatDate(
            secondary_insurance.policy_end_date,
            "YYYY-MM-DD"
          )
        }
      }
    });
    dispatch({
      type: Types.ORDER_FORM_RESPONSE,
      payload: { orderId: id }
    });
    try {
      // get updated patient profile order
      dispatch(invalidatePatientProfileInfo({ patientId: order.patient_id }));
      dispatch(fetchPatientProfileInfo({ patientId: order.patient_id }));
      dispatch(invalidatePatientOrders({ patientId: order.patient_id }));
      await dispatch(fetchPatientOrders({ patientId: order.patient_id }));
    } catch (error) {
      /* handle error */
    }
    return id;
  } catch (error) {
    dispatch(errorMessage("Sorry, your order could not be processed"));
  }
};
