import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";

const MultiSelectInput = ({
  size,
  name,
  label,
  onChange,
  value,
  error,
  options,
  }) => {

  const wrapperClass = (name, error) => {
    let wrapperClass = "form_group";
    if (error && error[name]) {
      wrapperClass += "_has_error";
    } else {
      wrapperClass = "form_group";
    }
    return wrapperClass
  }

  return (
    <div style={styles[wrapperClass(name)]} className="form-group" >
      <label htmlFor={name}>{label}</label>
      <div style={styles.field_div} className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select
          name={name}
          value={value}
          onChange={onChange}
          className="form-control"
          multiple={true}
          style={!size ? styles.select : {}}
          size={size}
        >
          <option value={""}></option>
          {options.map((option, i) => {
            return (
              <option
                key={i}
                value={option.get("value")}
              >
                {option.get("text")}
              </option>
            );
          })}
        </select>
        {error &&
          error[name] &&
          <div style={styles.error_text} className="alert alert-danger">
            {error[name]}
          </div>}
      </div>
    </div>
  );

};

MultiSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  options: PropTypes.object,
  value: PropTypes.array,
  size: PropTypes.string
};



export default Radium(MultiSelectInput);

const styles = styler`
error_text
  color: red
  padding: 0 0 10px 0
form_group_has_error
  border: 2px solid red
  padding: 10px 0 0
field_div
  margin: 2px
select
  height: 200px
`;