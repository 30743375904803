import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import { isRequired } from "utils/redux-form";
import Header from "./header";
import MdInfo from "react-icons/lib/md/info-outline";
import {
  getPendingOrderTasksHoldingOrder
} from "features/tasks/helpers";
import { OrderProfileContext } from "components/order-profile/context";

const UpdateStatus = ({
  orderStatusOptions,
  patientInfo,
  closeSelf,
  orderId,
  requestStatusUpdate,
  // hasUnresolvedHoldReasons,
  rejectedStatusId,
  requestNoteAdd,
  orderRejectedReasons,
  orderStatus,
  userRole
}) => { 
  const { tasks } = React.useContext(OrderProfileContext);
  // const patientTasks = getPendingPatientTasksHoldingOrder(tasks);
  const pendingOrderTasks = getPendingOrderTasksHoldingOrder(tasks, orderId);
return (
  <div style={{ padding: 5 }}>
    <Header>Update Status</Header>
    <Formik
      onSubmit={({ status, rejectedReason, note }) => {
        if (status) {
          requestStatusUpdate(status, rejectedReason);
        }
        if (note) {
          requestNoteAdd({ note });
        }
        if (closeSelf) {
          closeSelf();
        }
      }}
      initialValues={{ status: "", rejectedReason: "", note: "" }}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <FormikField
            label="Status"
            name="status"
            component="select"
            onChange={e => {
              handleChange(e);
              setFieldValue("rejectedReason", "");
            }}
            validate={isRequired}
          >
            <option value="" />
            {orderStatusOptions
              .filter(
                // prevent order from moving to accepted, completed, pending fulfillment if hasUnresolvedHoldReasons
                ({ text }) =>
                  !pendingOrderTasks ||
                  ![
                    "Accepted",
                    "Completed",
                    "Pending Fulfillment",
                    "Pending Shipment"
                  ].includes(text)
              )
              .filter(
                ({ text }) =>
                  !(
                    text === "Coach Follow Up" &&
                    patientInfo?.creation_method === "Philips Import"
                  )
              )
              .filter(({ text }) => text !== "Voided")
              .filter(({ text }) => {
                if (
                  [
                    "OfficeAdministrator",
                    "CompanyAdministrator",
                    "ServiceCoach"
                  ].includes(userRole) &&
                  orderStatus === "backordered" &&
                  !["Completed", "Rejected", "Coach Follow Up"].includes(text)
                )
                  return false;
                return true;
              })
              .map(({ value, text }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
          </FormikField>
          {values.status === rejectedStatusId && (
            <FormikField
              label="Rejected Reason"
              name="rejectedReason"
              component="select"
              validate={value => {
                if (values.status === rejectedStatusId)
                  return isRequired(value);
              }}
            >
              <option value="" />
              {orderRejectedReasons.map(({ value, text }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
            </FormikField>
          )}
          <FormikField label="Notes" name="note" component="textarea" />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              padding: 5
            }}
          >
            <button type="submit" style={{ margin: 0 }}>
              Update Status
            </button>
            {pendingOrderTasks > 0 && (
              <label
                style={{
                  flex: 1,
                  justifySelf: "center"
                }}
              >
                <MdInfo color="rgb(232, 145, 12)" size={18} />{" "}
                <span>Order has unresolved tasks</span>
              </label>
            )}
          </div>
        </Form>
      )}
    </Formik>
  </div>
);
}

UpdateStatus.defaultProps = {
  orderStatusOptions: [],
  orderRejectedReasons: []
};

export default connect((state, props) => {
  const { data: patientInfo = {} } = selectors.getOrderPatientInfo(
    state,
    props
  );
  return {
    patientInfo,
    orderStatusOptions: selectors.getOrderStatusUpdateOptions(state, props),
    // hasUnresolvedHoldReasons: selectors.getOrderHasUnresolvedHoldReason(
    //   state,
    //   props.orderId
    // ),
    rejectedStatusId: selectors.getOrderStatusId(state, "Rejected"),
    orderRejectedReasons: selectors.getEnumOrderRejectedReasons(state),
    orderStatus: selectors.getOrderStatus(state, props),
    userRole: selectors.getUserRole(state)
  };
}, mapDispatchToProps)(UpdateStatus);

UpdateStatus.propTypes = {
  canSubmit: PropTypes.bool,
  closeSelf: PropTypes.func.isRequired,
  orderStatusOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRole: PropTypes.string.isRequired,
  // hasUnresolvedHoldReasons: PropTypes.bool,
  requestNoteAdd: PropTypes.func.isRequired,
  requestStatusUpdate: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  orderRejectedReasons: PropTypes.arrayOf(PropTypes.object),
  rejectedStatusId: PropTypes.string,
  orderStatus: PropTypes.string,
  patientInfo: PropTypes.object,
  orderId: PropTypes.string.isRequired
};
