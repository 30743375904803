import React, { Component } from "react";
import PropTypes from "prop-types";

const openFileInNewWindow = file => {
  window.open(`https://docs.google.com/document/d/${file.id}`);
};

class FileCard extends Component {
  state = {
    isSelected: false
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = evt => {
    if (!this.node.contains(evt.target)) {
      document.removeEventListener("click", this.handleClickOutside);
      this.setState({ isSelected: false });
    }
  };

  openMenu = evt => {
    evt.preventDefault();
    document.addEventListener("click", this.handleClickOutside);
    this.setState({ isSelected: true });
  };

  closeMenu = evt => {
    evt.preventDefault();
    this.setState({ isSelected: !this.state.isSelected });
  };

  handleClick = (choice, evt) => {
    evt.preventDefault();
    const { createTab, file } = this.props;
    switch (choice) {
      case "tab":
        createTab({
          key: `${file.id}`,
          type: "googleFile",
          label: `${file.name}`
        });
        break;
      case "window":
        openFileInNewWindow(file);
        break;
      default:
    }
  };

  render() {
    const { file } = this.props;
    return (
      <div className="file-card" ref={el => (this.node = el)}>
        {!this.state.isSelected ? (
          <div className="card-wrapper" onClick={this.openMenu}>
            <span className="file-name">{file.name}</span>
          </div>
        ) : (
          <div className="card-wrapper active" onClick={this.closeMenu}>
            <span
              className="file-name"
              onClick={this.handleClick.bind(null, "none")}
              title={file.name}
            >
              {file.name}
            </span>
            <span
              className="menu-option"
              onClick={this.handleClick.bind(null, "tab")}
            >
              Open
            </span>
            <span
              className="menu-option"
              onClick={this.handleClick.bind(null, "window")}
            >
              Open In New Window
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default FileCard;

FileCard.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  createTab: PropTypes.func.isRequired
};
