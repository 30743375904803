import React from "react";

export const EligibilityGroup = ({ data, compareData }) => {
  return (
    data.sort(compareData).map( d => (
    <div className="grouping-container" key={d.company_hcpc_id + d.days_to_push_eligibility}>
      <div className="eligiblity-item">
        <div>Qty Allowed: {d.quantity_allowable}</div>
      </div>
      <div className="eligiblity-item">
        <div>Push Eligibility: {d.days_to_push_eligibility} Days</div>
      </div>
      </div>))
  );
};
