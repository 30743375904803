import React from "react";
import PropTypes from "prop-types";
import InlineWaiting from "components/ui/inline-waiting"
import Radium from "radium";
import styler from "react-styling";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import IoMdPrinter from "react-icons/lib/io/printer";
import Pagination from "./pagination/pagination";

const TableFooter = ({
  query,
  handleExportClick,
  handlePagination,
  handlePrint,
  canExport,
  isSubmitting
}) => {
  return (
    <div style={S.container}>
      <div style={S.pagContainer}>
        <div style={S.pagDiv}>
          {query
            ? <Pagination
              per={parseInt(query.get("per"))}
              page={parseInt(query.get("page"))}
              count={parseInt(query.get("count"))}
              action={handlePagination}
            />
            : <div style={S.empty}>&nbsp;</div>}
        </div>
      </div>
      <div style={S.downloadDiv}>
        {handlePrint &&
          <a onClick={handlePrint}>
            <i style={S.printer}>
              <IoMdPrinter />
            </i>
          </a>}
        {canExport &&
          <div>
            {
              isSubmitting
                ? <InlineWaiting />
                : <a style={S.downloadIcon} onClick={handleExportClick}>
                  <FaFileExcelO />
                </a>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default Radium(TableFooter);

TableFooter.propTypes = {
  query: PropTypes.object,
  handlePagination: PropTypes.func,
  handleExportClick: PropTypes.func,
  handlePrint: PropTypes.func,
  canExport: PropTypes.bool,
  isSubmitting: PropTypes.bool
};

const S = styler`
  container
    background-color: #EEEEEE
  pagContainer
    width: 100%
    background-color: #EEEEEE
  pagDiv
    float: left
    width: 85%
    background-color: #EEEEEE;
  downloadDiv
    background-color: #EEEEEE
    width: 15%
    float: right
    padding: 0 0 13px
    height: 58px
  downloadIcon
    color: green
    background-color: #EEEEEE
    font-size: 30px
    margin: 9px 0 0 25px
  printer
    font-size: 28px
    padding: 13px
  empty
    height: 58px
`;
