import React from 'react'

const Thanks = () => {
  return (
    <div className="thanks-container">
      <h1>Thank you! Your order has been submitted.</h1>
      <h3>You can close this browser window now.</h3>
    </div >
  )
}

export default Thanks
