import React from "react";
import AllocationCompanyTable from "./allocation-companies";
import AllocationCoachesTable from "./allocation-coaches";
import AllocationCallFactor from "./allocation-call-factor";

const TeamAllocation = () => {
  return (
    <>
      <div className="team-dashboard-allocation-container">
        <AllocationCallFactor />
        <AllocationCompanyTable />
        <AllocationCoachesTable />
      </div>
    </>
  );
};

export default TeamAllocation;
