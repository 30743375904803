import React from "react";
import moment from "moment";
import IoArrowLeftA from "react-icons/lib/io/arrow-left-a";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";
import { IconButton } from "components/ui";
import { TimeSlotContext } from "./use-availabilityScheduler";

const WeekSelect = React.memo(() => {
  const { selectWeek, week, setPendingTimeSlot } = React.useContext(
    TimeSlotContext
  );
  const handleWeekChange = w => {
    selectWeek(w);
    setPendingTimeSlot(null);
  };
  const from_date = moment().week(week).startOf("week");
  const to_date = moment().week(week).endOf("week");
  return (
    <div className="week-select">
      <IconButton
        icon={<IoArrowLeftA />}
        title="Previous Week"
        onClick={() => handleWeekChange(week - 1)}
        style={{ padding: 4 }}
      />
      <span style={{ fontSize: 15 }}>
        {from_date.format("L")} - {to_date.format("L")}
      </span>
      <IconButton
        icon={<IoArrowRightA />}
        title="Next Week"
        onClick={() => handleWeekChange(week + 1)}
        style={{ padding: 4 }}
      />
    </div>
  );
});

export default WeekSelect;
