import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";

const ProductSelect = ({
  selectedProduct,
  setSelectedProduct,
  inventoryItemOptions
}) => {
  return (
    <div>
      {/*
      <label>
        Equipment Type
        <select style={{ margin: 0 }} />
      </label>
      <label>
        Manufacturer
        <select style={{ margin: 0 }} />
      </label>*/}
      <label>
        Product
        <select
          value={selectedProduct}
          onChange={({ target }) => setSelectedProduct(target.value)}
          style={{ margin: 0 }}
        >
          <option />
          {inventoryItemOptions
            .sort((a, b) => ( a.text.toLowerCase().localeCompare(b.text.toLowerCase())))
            .map(({ text, value }) => (
            <option value={value} key={value}>
              {text}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

ProductSelect.propTypes = {
  selectedProduct: PropTypes.string,
  setSelectedProduct: PropTypes.func.isRequired,
  inventoryItemOptions: PropTypes.arrayOf(PropTypes.object)
};

export default connect(state => ({
  inventoryItemOptions: selectors.getInventoryItemOptions(state)
}))(ProductSelect);
