import { useCallback } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import * as R from "ramda";
export { default as TableSettings } from "./components";
export { default as cleanQuery } from "./clean-query";

const useTableSettings = (tableName, defaultActiveColumns) => {
  // const userId = useSelector(selectors.getUserId);
  const [tableSettings, setTableSettings] = useLocalStorage(
    `${tableName}`,
    defaultActiveColumns
  );
  const updateTableSettings = newSettings => {
    setTableSettings(newSettings);
  };
  const resetTableSettings = () => {
    setTableSettings(defaultActiveColumns);
  };
  const isColumnVisible = useCallback(
    key => R.pathOr(true, [key, "active"], tableSettings),
    [tableSettings]
  );

  // useEffect(() => {
  //   // setVisibleColumns(getVisibleColumns(tableSettings));
  //   setTableSettings(defaultActiveColumns);
  // }, [defaultActiveColumns]);

  return {
    isColumnVisible,
    updateTableSettings,
    resetTableSettings,
    tableSettings
  };
};

export default useTableSettings;
