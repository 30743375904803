import React from "react";
import PropTypes from "prop-types";
import MdGroup from "react-icons/lib/md/group";



 const EmailGroups = ({
   emailGroups,
   currentEmailGroupID,
   setCurrentEmailGroupID,
   scrollToTop,
   openNewEmailGroupForm
 }) => {
   return (
     <div>       
       <div
         style={{
           marginBottom: "-42px",
           display: "grid",
           justifyItems: "center",
           gridTemplateColumns: "1fr"
         }}
       >
         <span
           style={{
             color: "grey",
             fontSize: "2.5em",
             position: "relative",
             left: "-24px"
           }}
         >
           Groups
         </span>
         <span
           onClick={() => {
             openNewEmailGroupForm();
           }}
           title={"Add New Eamil Group"}
           className="addItemButton"
           style={{
             position: "relative",
             left: "37px",
             top: "-41px",
             fontSize: "30px"
           }}
         >
           +
         </span>
       </div>
       <div className="emailGroups">
         {emailGroups.map((g, i) => (
           <React.Fragment key={i}>
             <div
               title={`Click To Open ${g.group_name}`}
               onClick={() => {
                 setCurrentEmailGroupID(g.email_group_id);
                 scrollToTop();
               }}
               style={{ padding: "10px" }}
               className={
                 currentEmailGroupID != g.email_group_id
                   ? "emailGroupRow emailGroupDropShadow emailGroupAlignAndJustify"
                   : "emailGroupRowSelected emailGroupDropShadow emailGroupAlignAndJustify"
               }
             >
               <span className="emailGroupRowName" style={{color:g.accessible_companies.length > 0 ? "black" : "red"}}> 
               {g.addresses.some(x=>x.person == false)  &&<MdGroup />}
                {g.group_name}
                </span>
             </div>
           </React.Fragment>
         ))}
       </div>
     </div>
   );
 };
EmailGroups.propTypes = {
  emailGroups: PropTypes.array.isRequired,
  setCurrentEmailGroupID: PropTypes.func.isRequired,
  currentEmailGroupID: PropTypes.string,
  scrollToTop: PropTypes.func.isRequired,
  openNewEmailGroupForm: PropTypes.func.isRequired
};
export default EmailGroups;