import React from "react";
import PropTypes from "prop-types";
import MessageList from "./message-list";
import MdMoreVert from "react-icons/lib/md/more-vert";
import SendMsgInput from "./send-msg-input";
import AutoMsgList from "./auto-msg-list";
import { Dropdown } from "components/ui";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  closeTwilioChat,
  endTwilioChat,
  sendTwilioChatMsg,
  initializeTwilioChatClient
} from "actions/twilio-chat";
import CopyChatButton from "./copy-chat-button";

const TwilioChat = ({
  chatDetails,
  chatMessages,
  chatIsOpen,
  connectedToChatRoom,
  closeTwilioChat,
  endTwilioChat,
  sendTwilioChatMsg,
  initializeTwilioChatClient
}) => {
  const [sendMsgInputValue, setSendMsgInputValue] = React.useState("");

  React.useEffect(() => {
    initializeTwilioChatClient();
  }, []);

  React.useEffect(() => {
    if (connectedToChatRoom && chatDetails) {
      const first_name = chatDetails?.patient_first_name;
      const last_name = chatDetails?.patient_last_name;
      const message =
        first_name && last_name
          ? `Hello ${first_name} ${last_name}, how may I help you today?`
          : "Hello, how may I help you today?";

      // sending the message too early will not work.
      //   wait one second before sending.
      const timer = setTimeout(() => sendTwilioChatMsg(message), 1000);
      return () => clearTimeout(timer);
    }
  }, [connectedToChatRoom]);

  return (
    <section
      role="twilio-chat"
      style={{
        display: chatIsOpen ? "grid" : "none"
      }}
      className="twilio-chat-window"
    >
      <div className="chat-header-container">
        <h3 className="chat-header-text">Chat Now</h3>
        <Dropdown
          position="left"
          trigger={
            <span className="chat-drop-down-menu">
              <MdMoreVert size={22} />
            </span>
          }
          style={{ margin: 0, width: 210 }}
        >
          <ul className="dropdown-list" style={{ margin: 0 }}>
            {connectedToChatRoom && (
              <li className="dropdown-item" onClick={endTwilioChat}>
                Leave Chat
              </li>
            )}
            <li className="dropdown-item" onClick={closeTwilioChat}>
              Close Chat{connectedToChatRoom && " and Leave"}
            </li>
            <CopyChatButton
              patientId={chatDetails?.patient_id}
              chatMessages={chatMessages}
            />
          </ul>
        </Dropdown>
      </div>
      <div className="twilio-chat-container">
        <MessageList messages={chatMessages} />
        {connectedToChatRoom && (
          <>
            <AutoMsgList onMessageSelect={msg => setSendMsgInputValue(msg)} />
            <SendMsgInput
              onMessageSend={msg => sendTwilioChatMsg(msg)}
              value={sendMsgInputValue}
            />
          </>
        )}
      </div>
    </section>
  );
};

TwilioChat.propTypes = {
  chatDetails: PropTypes.object,
  chatMessages: PropTypes.arrayOf(PropTypes.object),
  chatIsOpen: PropTypes.bool,
  closeTwilioChat: PropTypes.func.isRequired,
  endTwilioChat: PropTypes.func.isRequired,
  sendTwilioChatMsg: PropTypes.func.isRequired,
  initializeTwilioChatClient: PropTypes.func.isRequired,
  connectedToChatRoom: PropTypes.bool
};

export default connect(
  state => ({
    chatMessages: selectors.getChatMessages(state),
    chatIsOpen: selectors.getChatIsOpen(state),
    connectedToChatRoom: selectors.getChatIsConnected(state),
    chatDetails: selectors.getAutoDialerChatDetails(state)
  }),
  {
    closeTwilioChat,
    sendTwilioChatMsg,
    initializeTwilioChatClient,
    endTwilioChat
  }
)(TwilioChat);
