import React from "react";
import PropTypes from "prop-types";

const TaskActionsRows = ({ tasks, index, order_id }) => {
  return (
    <tr
      key={`${order_id}-2`}
      className={`task-actions-row ${index % 2 === 0 ? "even" : "odd"}`}
    >
      <td colSpan="100%">
        <span className="task-actions-list">
          {tasks.map(t => (
            <span
              key={`${order_id}-${t.id}`}
              className={`task-action ${t.resolved && "resolved"} ${
                t.order_d ? "order-task" : "patient-task"
              }`}
              data={t.text}
            >
              <span className="task-name">{t.action_text}</span>
              {/* {t.owner && <span className="task-owner">{t.owner}</span>} */}
            </span>
          ))}
        </span>
      </td>
    </tr>
  );
};

TaskActionsRows.defaultProps = {};

TaskActionsRows.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  order_id: PropTypes.string
};

export default TaskActionsRows;
