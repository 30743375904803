import OrderStatus from "components/order-status-new";
import OrderWorklist from "components/order-worklist";
import OrderProfile from "components/order-profile";
import ExternalOrderProfile from "components/external-order-profile";
import OrderSearchTable from "components/screens/order/order-search-table";
import ManagePrintPackingSlip from "components/screens/order/manage-print-packing-slip";
import BatchPrintOrders from "components/screens/order/batch-print-orders";
import FulfillmentStatus from "components/fulfillment-status";
import FulfillmentSearchTable from "components/screens/fulfillment/fulfillment-search-table";
import FulfillmentTrackingForm from "components/screens/fulfillment/fulfillment-tracking-form";
import OrdersTable from "components/orders-table";

export default [
  {
    path: "/orders",
    component: OrderStatus,
    exact: true
  },
  {
    path: "/orders-table",
    component: OrdersTable,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "OfficeAdministrator", 
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/orders/worklist",
    component: OrderWorklist
  },
  {
    path: "/orders/batch-print-orders",
    component: BatchPrintOrders
  },
  {
    path: "/orders/:id/print-packing-slip",
    component: ManagePrintPackingSlip,
    exact: true
  },
  {
    path: "/orders/:orderId",
    component: OrderProfile
  },
  {
    path: "/external-orders/:orderId",
    component: ExternalOrderProfile
  },
  {
    path: "/search/orders/:field/:value",
    component: OrderSearchTable
  },
  {
    path: "/fulfillment",
    component: FulfillmentStatus,
    exact: true
  },
  {
    path: "/fulfillment/import-tracking",
    component: FulfillmentTrackingForm,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/fulfillment/print-packing-slips",
    component: ManagePrintPackingSlip
  },
  {
    path: "/search/fulfillment/:field/:value",
    component: FulfillmentSearchTable
  }
];
