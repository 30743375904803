import React from "react";
import PropTypes from "prop-types";
import AddWorkListItem from "./add-worklist-item";
import OrderHoldReason from "./order-hold-reason";
import OrderNote from "./order-note";

const OrderClipboard = ({
  orderId,
  clipboardItems,
  order_hold_reasons,
  selectedClipboardOption,
  orderStatus,
  updateOrderHoldReason
}) => {
  const orderIsCompleted = [
    "Accepted",
    "Completed",
    "Pending Fulfillment",
    "Pending Shipment"
  ].includes(orderStatus);
  const orderIsRejected = ["Rejected", "Voided"].includes(orderStatus);
  return (
    <React.Fragment>
      <div className="s3-ui-rows" style={{ height: "100%" }}>
        {clipboardItems.map(ci =>
          ci.holdReason ? (
            <OrderHoldReason
              key={ci.holdReasonPK}
              orderId={orderId}
              reason={ci}
              updateOrderHoldReason={updateOrderHoldReason}
              canEditHoldReason={
                // can not edit completed orders, can only resolve rejected orders 
                !orderIsCompleted && !(orderIsRejected && ci.resolved == true)
              }
            />
          ) : (
            <OrderNote key={ci.id} {...ci} />
          )
        )}
      </div>
      <AddWorkListItem
        orderId={orderId}
        orderHoldReasonIds={order_hold_reasons.map(
          ({ holdReasonPK }) => holdReasonPK
        )}
        selectedClipboardOption={selectedClipboardOption}
        canAddHoldReason={!orderIsCompleted}
      />
    </React.Fragment>
  );
};

OrderClipboard.propTypes = {
  orderId: PropTypes.string.isRequired,
  clipboardItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  order_hold_reasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedClipboardOption: PropTypes.string.isRequired,
  orderStatus: PropTypes.string,
  updateOrderHoldReason: PropTypes.func.isRequired
};

OrderClipboard.defaultProps = {
  order_hold_reasons: [],
  notes: []
};

export default OrderClipboard;

export const OrderClipboardOptions = ({
  selectedClipboardOption,
  setClipboardOption,
  noteCount,
  order_hold_reasons
}) => {
  const handleOptionChange = ({ target }) => {
    setClipboardOption(target.value);
  };
  return (
    <div className="worklist-header-radio-group">
      <label>
        <input
          type="radio"
          value="all"
          checked={selectedClipboardOption === "all"}
          onChange={handleOptionChange}
          className="form-check-input"
        />
        All
      </label>
      <label>
        <input
          type="radio"
          value="notes"
          checked={selectedClipboardOption === "notes"}
          onChange={handleOptionChange}
          className="form-check-input"
        />
        Notes
        <div className="number-step">{noteCount}</div>
      </label>
      <label>
        <input
          type="radio"
          value="hold-reasons"
          checked={selectedClipboardOption === "hold-reasons"}
          onChange={handleOptionChange}
          className="form-check-input"
        />
        Worklist Items
        <div className="number-step">
          {order_hold_reasons.filter(r => r.resolved).length}/
          {order_hold_reasons.length}
        </div>
      </label>
    </div>
  );
};

OrderClipboardOptions.propTypes = {
  selectedClipboardOption: PropTypes.string.isRequired,
  setClipboardOption: PropTypes.func.isRequired,
  noteCount: PropTypes.number.isRequired,
  order_hold_reasons: PropTypes.arrayOf(PropTypes.object)
};
