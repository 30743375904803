import { EXPORTS_LAST_UPDATED } from 'actions/exports'
import { prop } from "ramda"

const rootExportsReducer = (state = {
  lastUpdated: null
}, action) => {
  switch (action.type) {
  case EXPORTS_LAST_UPDATED:
    return {
      lastUpdated: action.payload.time
    }
  default:
    return state
  }
}

export default rootExportsReducer

export const getExportsLastUpdated = state => prop("lastUpdated", state.getIn(["userExports"]))
