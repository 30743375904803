import { makeFetchCreator } from "actions/fetch";
import { put } from "utils/api";
import uniqueId from "lodash/uniqueId";
import partition from "lodash/partition";

export const AUTOMATE_PATIENT_OUTREACH = "AUTOMATE_PATIENT_OUTREACH";

// admins on behalf of sleep-coach
export const patientOutreach = makeFetchCreator({
  url: "automation/outreach",
  baseType: AUTOMATE_PATIENT_OUTREACH,
  method: put,
  mapActionToKeyIn: ({ requestID }) => [requestID],
  transform: ({ automation, exception }) => {
    const [success, failure] = partition(
      automation,
      ({ status }) => status === "success"
    ).map(ids => ids.map(({ id }) => id));
    return { exception, success, failure };
  }
});

export const automatePatientOutreach = ({ids, email, text, push}) =>
  patientOutreach({ payload: { ids, email, text, push }, requestID: uniqueId() });
