import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withProps, withStateHandlers, withHandlers } from "recompose";
import get from "lodash/get";
import compose from "lodash/fp/compose";
import compact from "lodash/compact";
import moment from "moment";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withExport,
  withHeaderFooter
} from "features/query";
import withBatch from "features/batch";
import { setOrderBatchIds } from "actions/batch";
import { getFormOptionsSleepCoachWithUserFirst, selectors } from "reducers";
import BulkOrderUpdate from "components/bulk-order-update";
import Table from "components/ui/impl/table";
import { Window } from "components/ui";
import CompanyTeamSelect from "components/shared/company-team-select";
import SleepCoachTeamSelect from "components/shared/coach-team-select";
import IoMdClipboard from "react-icons/lib/io/clipboard";
import IoMdEdit from "react-icons/lib/io/edit";
import { createTab } from "actions/system-tray";
import ClaimOrder from "components/order-status/claim-order";
import HoldReasonFilter from "./hold-reason-filter";
import OrderHoldReasonRow from "./hold-reason-row";
import OrderWorklistCompletionStatus from "./order-worklist-completions-status";

const formatDateField = field => row => {
  const value = get(row, field);
  return value ? moment(value).format("MM / DD / YYYY") : "";
};

const OrderWorklistTable = ({
  bulkUpdateEnabled,
  disableBulkUpdate,
  markedForBatch,
  onBulkUpdateSuccess,
  batchClear,
  ...props
}) => (
  <React.Fragment>
    <Window
      disabled={!bulkUpdateEnabled}
      onXClick={disableBulkUpdate}
      onEscape={disableBulkUpdate}
    >
      <BulkOrderUpdate
        orderIds={markedForBatch}
        onUpdateError={() => {
          batchClear();
        }}
        onUpdateSuccess={onBulkUpdateSuccess}
      />
    </Window>
    <HoldReasonFilter
      setFilters={props.setFilters}
      clearFilters={props.clearFilters}
    />
    <Table {...props} customRow={OrderHoldReasonRow} />
  </React.Fragment>
);

export default compose(
  connect(
    (state, { orderStatus }) => {
      const { id: userId, role: userRole } = selectors.getUser(state);
      const canExport = selectors.getCanCurrentUserExportOrder(state);
      return {
        userRole,
        queryId: orderStatus,
        initialQuery: {
          sort: {
            by: "submitted",
            direction: "desc"
          },
          pagination: {
            page: 1,
            per: 50
          },
          filters: {
            order: ["all"],
            sleep_coach: userRole === "SleepCoach" ? [userId] : undefined,
            resolved: ["false"]
          }
        },
        apiId: orderStatus,
        export: canExport
          ? {
              route: "worklist/export"
            }
          : undefined,
        userId,
        orderStatusOptions: selectors.getOrderStatusTextOptions(state),
        companies: selectors.getFormOptionsCompany(state),
        sleepCoaches: getFormOptionsSleepCoachWithUserFirst(state),
        admins: selectors.getEnumAdmins(state)
      };
    },
    {
      createTab,
      setOrderBatchIds
    }
  ),
  withQuery({
    queryId: "order-worklist",
    initialQuery: {
      sort: {
        by: "submitted",
        direction: "desc"
      },
      pagination: {
        page: 1,
        per: 150
      },
      filters: {
        status: [],
        order: ["all"],
        resolved: ["false"]
      }
    }
  }),
  withApi({
    apiId: "order-worklist",
    api: {
      route: "orders/withHoldReasons",
      responseKey: "orders",
      idKey: "id",
      entityKey: "orderRows"
    }
  }),
  withExport(),
  withBatch({ recordIdKey: "id" }),
  withStateHandlers(
    { bulkUpdateEnabled: false },
    {
      enableBulkUpdate: () => () => ({ bulkUpdateEnabled: true }),
      disableBulkUpdate: () => () => ({ bulkUpdateEnabled: false })
    }
  ),
  withProps(
    ({
      companies,
      orderStatusOptions,
      sleepCoaches,
      batchColumn,
      markedForBatch,
      admins,
      setOrderBatchIds,
      createTab,
      batchClear,
      enableBulkUpdate
    }) => ({
      /* eslint-disable react/prop-types */
      columns: compact([
        batchColumn,
        {
          header: "Order Number",
          field: "invoice_number",
          format: ({ id, invoice_number }) => (
            <Link to={`/orders/${id}`}>{invoice_number}</Link>
          )
        },
        {
          header: "Order Progress",
          field: "allHoldReasonsResolved",
          filter: "resolved",
          format: OrderWorklistCompletionStatus,
          onHeaderClick: () => {}, // noop ignore sorting
          filters: [
            {
              text: "In Progress",
              value: "false"
            },
            {
              text: "All Worklist Items Completed",
              value: "true"
            },
            {
              text: "No Assigned Worklist Items",
              value: "None"
            }
          ]
        },
        {
          header: "Patient",
          field: "patient",
          format: ({ patient_id, patient }) => (
            <Link to={`/patients/${patient_id}`}>{patient}</Link>
          )
        },
        {
          header: "Admin",
          format: ({ claimed_by, id }) => (
            <ClaimOrder claimed_by={claimed_by} id={id} />
          ),
          filter: "admin",
          field: "claimed_by",
          filterDownSelect: true,
          filters: admins
        },
        {
          header: "Company",
          field: "company",
          filters: companies,
          filterDownSelect: true,
          subFilter: CompanyTeamSelect
        },
        {
          header: "Status",
          field: "status",
          filter: "status",
          filters: orderStatusOptions
        },
        {
          header: "Status changed",
          field: "status_changed",
          format: formatDateField("status_changed")
        },
        {
          header: "Last Updated",
          field: "last_updated",
          format: formatDateField("last_updated")
        },
        {
          header: "Sleep Coach",
          field: "sleep_coach",
          filters: sleepCoaches,
          filterDownSelect: true,
          subFilter: SleepCoachTeamSelect
        },
        {
          header: "Follow Up Date",
          field: "follow_up_date",
          format: formatDateField("follow_up_date")
        },
        {
          header: "Priority",
          field: "high_priority",
          format: ({ high_priority }) => (high_priority ? "High" : null),
          filters: [
            {
              text: "High",
              value: "true"
            },
            {
              text: "Low",
              value: "false"
            }
          ]
        }
      ]),
      customDisplay: [
        () =>
          markedForBatch.length > 0 ? (
            <span style={{ color: "white" }}>
              {markedForBatch.length} Orders Selected
            </span>
          ) : null
      ],
      customButtons: [
        {
          icon: () => <IoMdClipboard />,
          title: `Process orders (${markedForBatch.length} selected)`,
          onClick: () => {
            if (markedForBatch.length) {
              setOrderBatchIds(markedForBatch);
              createTab({
                type: "orders",
                key: "orders",
                label: `Orders (${markedForBatch.length})`,
                meta: { ids: markedForBatch }
              });
              batchClear();
            }
          }
        },
        {
          icon: () => <IoMdEdit />,
          title: `Bulk update orders (${markedForBatch.length} selected)`,
          onClick: () => {
            if (markedForBatch.length) {
              enableBulkUpdate();
            }
          }
        }
      ]
    })
  ),
  withHeaderFooter({
    perPageOptions: [50, 100, 150],
    forceBatchReset: true,
    customFilters: [
      {
        options: [
          {
            value: "all",
            text: "All Orders"
          },
          {
            value: "mine",
            text: "My Orders"
          }
        ],
        field: "order"
      }
    ],
    scrollable: true
  }),
  withTableHandlers(),
  withHandlers({
    onBulkUpdateSuccess: ({
      disableBulkUpdate,
      requestData,
      batchClear
    }) => () => {
      batchClear();
      disableBulkUpdate();
      requestData();
    }
  })
)(OrderWorklistTable);

OrderWorklistTable.propTypes = {
  bulkUpdateEnabled: PropTypes.bool,
  disableBulkUpdate: PropTypes.func.isRequired,
  markedForBatch: PropTypes.arrayOf(PropTypes.string),
  onBulkUpdateSuccess: PropTypes.func,
  batchClear: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired
};
