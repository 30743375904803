import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { IconButton } from "components/ui";
import MdSave from "react-icons/lib/md/save";
import MdClose from "react-icons/lib/md/close";

const CalenderControls = ({ handleDayChange }) => {
  const [useInput, setUseInput] = React.useState(false);
  return (
    <div className="calender-controls">
      {useInput ? (
        <DaysToPushInput
          setUseInput={setUseInput}
          handleDayChange={handleDayChange}
        />
      ) : (
        <div className="push-days-container">
          <button
            className="button secondary calender-btn"
            onClick={() => {
              handleDayChange(moment().add(1, "week"));
            }}
          >
            +7
          </button>
          <button
            className="button secondary calender-btn"
            onClick={() => handleDayChange(moment().add(30, "days"))}
          >
            +30
          </button>
          <button
            className="button secondary calender-btn"
            onClick={() => handleDayChange(moment().add(45, "days"))}
          >
            +45
          </button>
          <button
            onClick={() => setUseInput(true)}
            className="button secondary calender-btn"
          >
            +?
          </button>
        </div>
      )}
    </div>
  );
};

CalenderControls.propTypes = {
  handleDayChange: PropTypes.func.isRequired
};

export default CalenderControls;

const DaysToPushInput = ({ handleDayChange, setUseInput }) => {
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    inputRef.current.focus();
  }, []);
  const [daysToPush, setDaysToPush] = React.useState("");
  return (
    <div className="custom-days-input">
      <input
        style={{ margin: 0 }}
        ref={inputRef}
        value={daysToPush}
        onChange={({ target }) => setDaysToPush(target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleDayChange(moment().add(daysToPush, "days"));
          }
        }}
        placeholder="# of Days to push"
        type="number"
      />
      <IconButton
        style={{ padding: 5 }}
        icon={<MdClose color="red" />}
        onClick={() => {
          setUseInput(false);
        }}
      />
      <IconButton
        disabled={!daysToPush}
        style={{ padding: 5 }}
        icon={<MdSave color="green" />}
        onClick={() => handleDayChange(moment().add(daysToPush, "days"))}
      />
    </div>
  );
};

DaysToPushInput.propTypes = {
  handleDayChange: PropTypes.func.isRequired,
  setUseInput: PropTypes.func.isRequired
};
