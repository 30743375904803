import axios from "axios";
import { host } from "environment";

const API = (cType) => {
  if (cType === undefined || cType.length === 0) {
    cType = "application/json";
  }
  let token =
    JSON.parse(window.localStorage.getItem("evergreen")) &&
    JSON.parse(window.localStorage.getItem("evergreen"))[
      "authentication_token"
    ];
  return axios.create({
    baseURL: host,
    timeout: 3000 * 60 * 60,
    headers: {
      Authorization: `bearer ${token}`,
      "Content-Type": cType,
      Accept: "application/vnd.sleepcoach.us; version=1",
    },
  });
};
export default API;
