import React from "react";
import PropTypes from "prop-types";
import {
  InlineSortControls,
  PageControls,
  AxisLabel
} from "components/ui/graph-components";
import {
  ResponsiveContainer,
  LabelList,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import { usePagination, useSort } from "hooks";

const PatientInsurance = ({ data }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "InsuranceCompanyName", ascending: true });
  const { curPageNum, totalPages, setPageNum, getPageData } = usePagination(
    data,
    15
  );
  return (
    <div className="interactive-graph-container">
      <React.Fragment>
        <div className="interactive-graph-header">
          <span />
          <PageControls
            curPageNum={curPageNum}
            totalPages={totalPages}
            setPageNum={setPageNum}
            alwaysShow={false}
          />
          <h4>Insurances</h4>
          <InlineSortControls
            sortOptions={[
              { key: "InsuranceCompanyName", text: "Name" },
              { key: "Ins_Type_Count", text: "Count" }
            ]}
            setSortBy={setSortBy}
            sortBy={sortBy}
            ascending={ascending}
            toggleSortDirection={toggleSortDirection}
          />
        </div>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={getPageData(curPageNum, getSortedArray)}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              dataKey="Ins_Type_Count"
              domain={[0, "dataMax + 5"]}
              scale="pow"
            />
            <YAxis
              type="category"
              dataKey="InsuranceCompanyName"
              allowDataOverflow
              width={275}
              interval={0}
              tick={<AxisLabel style={{ fontSize: 10 }} />}
            />
            <Bar
              dataKey="Ins_Type_Count"
              fill="rgb(49, 130, 189)"
              maxBarSize={50}
            >
              <LabelList dataKey="Ins_Type_Count" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </React.Fragment>
    </div>
  );
};

PatientInsurance.propTypes = {
  data: PropTypes.array.isRequired
};

export default PatientInsurance;
