import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { PureModal, Waiting } from "components/ui";
import { InsuranceCompanyContext } from "./insurance-company-provider";
import InsuranceCompanyForm from "./insurance-company-form";
import SelectDropdown from "components/forms/select-dropdown";
import BulkUpdateInsurance from "./bulk-update-modal";
import MultiSelectDropdown from "components/forms/multi-select-dropdown/index";
import IoMdAddCircle from "react-icons/lib/io/plus-circled";
import IoMdRemoveCircle from "react-icons/lib/io/minus-circled";
import { useDebounce } from "hooks";
import { aLink } from "utils/styles";
import PropTypes from "prop-types";
import HasRolePermission from "components/shared/has-role-permission";

const InsuranceCompanyList = ({
  setBulkSelected,
  bulkSelectInsurances,
  setBulkSelectInsurances
}) => {
  const {
    companyGuid,
    getBulkUpdateInsurances,
    getInsurance,
    initialBulkValues,
    insurance,
    isUpdatingInsurance,
    isFetchingInsurance,
    selectedInsurance,
    setSelectedInsurance,
    initiateCompanyInsuranceExport
  } = useContext(InsuranceCompanyContext);

  useEffect(() => {
    getInsurance(companyGuid);
  }, []);

  useEffect(() => {
    if (bulkSelectInsurances.length > 0) {
      return;
    }
    if (bulkSelectInsurances.length === 0 && insurance.length > 0) {
      setBulkSelectInsurances(
        insurance?.map(x => ({ checked: false, GUID: x.sMGInsuranceID }))
      );
    }
  }, [insurance]);

  const [searchValue, setSearchValue] = useState({
    value: "",
    index: null
  });
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const [filteredInsurances, setFilteredInsurances] = useState(insurance);
  const initialValues = {
    companyGuid: companyGuid,
    insuranceCompanyGuid: "",
    bActive: true,
    bAllowsNinetyDaySupply: true,
    bEligibleForVerification: false,
    bExcluded: false,
    bCMNRequired: true,
    bCountsMaskAsSeal: true,
    bUseAsPrimary: false,
    bUseAsSecondary: false,
    sPayorID: "",
    sExtraInfo: "",
    iAllowableDuration: 0,
    sInsuranceCompanyName: [""],
    typeGuid: "",
    prefix: ""
  };
  useEffect(() => {
    getInsurance(companyGuid);
  }, []);
  useEffect(() => {
    setFilteredInsurances(insurance);
  }, [insurance]);

  // filter state and blank filter state
  const blankFilterState = {
    selectOne: {
      value: "",
      inputType: "",
      fieldsArrayOptions: ""
    },
    selectTwo: {
      value: ""
    }
  };

  const [filterState, setFilterState] = useState([
    {
      selectOne: {
        value: "dropdown-bActive-bool",
        inputType: "dropdown",
        fieldsArrayOptions: "bool"
      },
      selectTwo: { value: "true" }
    },
    {
      selectOne: {
        value: "input-sInsuranceCompanyName-text",
        inputType: "input",
        fieldsArrayOptions: "sInsuranceCompanyName"
      },
      selectTwo: { value: "" }
    }
  ]);
  const removeFilterState = index => {
    const updatedFilter = [...filterState];
    updatedFilter.splice(index, 1);
    setFilterState(updatedFilter);
  };

  const addFilterState = e => {
    e.preventDefault();
    setFilterState([...filterState, { ...blankFilterState }]);
  };

  // arrays of select dropdown options for different dropdown types
  const fieldsArray = [
    {
      text: "Active",
      value: "dropdown-bActive-bool"
    },
    {
      text: "Allowable Duration",
      value: "multidropdown-iAllowableDuration-int"
    },
    {
      text: "Capitated Duration",
      value: "multidropdown-iCapitated-int"
    },
    {
      text: "Allows Ninety Day Supply",
      value: "dropdown-bAllowsNinetyDaySupply-bool"
    },
    {
      text: "Auto Generated",
      value: "dropdown-bAutoGenerated-bool"
    },
    {
      text: "CMN Required",
      value: "dropdown-bCMNRequired-bool"
    },
    {
      text: "Counts Mask As Seal",
      value: "dropdown-bCountsMaskAsSeal-bool"
    },
    {
      text: "Excluded",
      value: "dropdown-bExcluded-bool"
    },
    {
      text: "Initial Value",
      value: "dropdown-bInitialValue-bool"
    },
    {
      text: "Insurance Type",
      value: "multidropdown-type-string"
    },
    {
      text: "Name",
      value: "input-sInsuranceCompanyName-text"
    }
  ];
  const fieldsArrayBooleanOptions = [
    {
      text: "All",
      value: ""
    },
    {
      text: "True",
      value: "true"
    },
    {
      text: "False",
      value: "false"
    }
  ];

  let allowableUniqueValuesArray = [
    ...new Set(insurance.map(x => x.iAllowableDuration))
  ];
  let fieldsArrayAllowableDuration = allowableUniqueValuesArray.map(x => ({
    text: `${x} Months`,
    value: `${x}`
  }));
  let capitatedUniqueValuesArray = [
    ...new Set(insurance.map(x => x.iCapitated))
  ];
  let fieldsArrayCapitatedDuration = capitatedUniqueValuesArray.map(x => ({
    text: `${x} Months`,
    value: `${x}`
  }));
  let fieldsArrayInsuranceTypes = [...new Set(insurance.map(x => x.type))]
    ?.sort(function (a, b) {
      return a < b ? -1 : a > b ? 1 : 0;
    })
    ?.map(x => ({ text: x, value: x }));

  // all options stored in an object for accessing via object bracket notation and string interpolation...
  const allFieldsOptionsObject = {
    fieldsArray: fieldsArray,
    bool: fieldsArrayBooleanOptions,
    iAllowableDuration: fieldsArrayAllowableDuration,
    iCapitated: fieldsArrayCapitatedDuration,
    type: fieldsArrayInsuranceTypes
  };

  const handleChange = (value, index, selectOption) => {
    if (selectOption === "selectOne") {
      const [inputType, propertyName, inputValueOptions] = value.split("-");
      const updatedFilterState = [...filterState];
      updatedFilterState[index].selectOne.value = value;
      updatedFilterState[index].selectOne.inputType = inputType;
      updatedFilterState[index].selectOne.fieldsArrayOptions =
        inputValueOptions === "bool" ? inputValueOptions : propertyName;

      // make sure the value of selectTwo is blank in case the filter is being changed
      updatedFilterState[index].selectTwo.value = "";

      setFilterState(updatedFilterState);
    } else {
      if (selectOption === "selectTwo-text-input") {
        const updatedFilterState = [...filterState];
        updatedFilterState[index].selectTwo.value = value.toUpperCase();
        setFilterState(updatedFilterState);
      } else if (selectOption === "selectTwo-single") {
        const updatedFilterState = [...filterState];
        updatedFilterState[index].selectTwo.value = value;
        setFilterState(updatedFilterState);
      } else {
        if (value.length !== 0) {
          const updatedFilterState = [...filterState];
          updatedFilterState[index].selectTwo.value = value;
          setFilterState(updatedFilterState);
        } else {
          const updatedFilterState = [...filterState];
          updatedFilterState[index] = blankFilterState;
          setFilterState(updatedFilterState);
        }
      }
    }
  };

  const handleMultiSelectClear = idx => {
    const updatedFilter = [...filterState];
    updatedFilter[idx].selectTwo.value = [];
    setFilterState(updatedFilter);
  };

  const buildFilter = filter => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };
  const filterData = (data, query) => {
    const filteredData = data.filter(item => {
      for (let key in query) {
        if (key === "sInsuranceCompanyName") {
          return item.sInsuranceCompanyName?.toUpperCase().includes(query[key]);
        } else if (item[key] === undefined || !query[key].includes(item[key])) {
          return false;
        }
      }
      return true;
    });
    return filteredData;
  };
  useEffect(() => {
    let filter = {
      bActive: [],
      bAllowsNinetyDaySupply: [],
      bAutoGenerated: [],
      bCMNRequired: [],
      bCountsMaskAsSeal: [],
      bExcluded: [],
      bInitialValue: [],
      iAllowableDuration: [],
      iCapitated: [],
      sInsuranceCompanyName: [],
      type: []
    };
    for (const fs of filterState) {
      if (fs.selectTwo.value === "") continue;
      const [inputType, propertyName, valueType] =
        fs.selectOne.value.split("-");
      if (inputType === "multidropdown") {
        if (valueType === "string") {
          fs.selectTwo.value.map(x => {
            filter[propertyName].push(x);
          });
        } else {
          fs.selectTwo.value.map(x => {
            const value = parseInt(x);
            filter[propertyName].push(value);
          });
        }
      } else {
        let value = null;
        if (valueType === "bool") {
          value = JSON.parse(fs.selectTwo.value);
        } else if (valueType === "text") {
          value = fs.selectTwo.value;
        } else if (valueType === "int") {
          value = parseInt(fs.selectTwo.value);
        }
        filter[propertyName].push(value);
      }
    }
    const formatedFilter = buildFilter(filter);
    const filteredData = filterData(insurance, formatedFilter);
    setFilteredInsurances(filteredData);
  }, [insurance, filterState]);
  useEffect(() => {
    if (debouncedSearchTerm.index !== null) {
      handleChange(
        debouncedSearchTerm.value,
        debouncedSearchTerm.index,
        "selectTwo-text-input"
      );
    }
  }, [debouncedSearchTerm]);
  const handleBulkSelectors = e => {
    let bulkSelectInsurancesCopy = [...bulkSelectInsurances];
    const selectedId = e.target.id;
    if (selectedId === "allSelector") {
      const filteredInsuranceListGUIDs = filteredInsurances.map(
        x => x.sMGInsuranceID
      );
      const bulkSelectInsListInCurrentView = bulkSelectInsurancesCopy.filter(
        x => filteredInsuranceListGUIDs.includes(x.GUID)
      );
      if (bulkSelectInsListInCurrentView.every(x => x.checked)) {
        bulkSelectInsListInCurrentView.forEach(x => (x.checked = false));
      } else {
        bulkSelectInsListInCurrentView.forEach(x => (x.checked = true));
      }
    } else {
      const foundInsurance = bulkSelectInsurancesCopy.find(
        x => x.GUID === selectedId
      );
      foundInsurance.checked = e.target.checked;
    }

    setBulkSelectInsurances(bulkSelectInsurancesCopy);
  };

  useEffect(() => {
    if (
      bulkSelectInsurances
        ?.filter(x => x.GUID !== selectedInsurance)
        ?.some(x => x.checked)
    ) {
      setBulkSelected(true);
    } else if (
      bulkSelectInsurances
        ?.filter(x => x.GUID !== selectedInsurance)
        ?.every(x => !x.checked)
    ) {
      setBulkSelected(false);
    }
  }, [bulkSelectInsurances]);

  const handleOpenModal = openModal => {
    const insurancesToGet = bulkSelectInsurances
      .filter(x => x.checked === true)
      .map(x => x.GUID);
    const payload = { companyGuid, ids: insurancesToGet };
    openModal();
    getBulkUpdateInsurances(payload);
  };

  const ReturnInputType = ({ typeName, value, options, idx }) => {
    switch (typeName) {
      case "dropdown":
        return (
          <div className="insuranceListSearchInput">
            <SelectDropdown
              searchable={false}
              onChange={value => handleChange(value, idx, "selectTwo-single")}
              placeHolder="Values to Filter By..."
              value={value}
              options={options}
            />
          </div>
        );
      case "multidropdown":
        return (
          <MultiSelectDropdown
            options={options}
            onChange={value => handleChange(value, idx, "selectTwo-multi")}
            values={value}
            onClear={() => handleMultiSelectClear(idx)}
            searchable={true}
            placeHolder="Type to filter options by name"
          />
        );
      default:
        return "";
    }
  };
  ReturnInputType.propTypes = {
    typeName: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    idx: PropTypes.number
  };

  return isFetchingInsurance || isUpdatingInsurance ? (
    <Waiting size="50px" style={{ position: "sticky" }} />
  ) : (
    <>
      <HasRolePermission
          allowedRoles={[
            "Administrator",
            "ServiceAdmin",
            "CompanyAdministrator"
          ]}
        >
      </HasRolePermission>
      <div className="insuranceList--button--container">
        <button className="modalButton" onClick={() => {
          const ids = bulkSelectInsurances.filter(x => x.checked === true).map(x => x.GUID);
          const payload = { companyGuid, ids };
          initiateCompanyInsuranceExport(payload);
        }}>
          Export All
        </button>
        <PureModal
          renderTrigger={({ openModal }) => (
            <button className="modalButton" onClick={openModal}>
              Add Insurance
            </button>
          )}
          renderContent={({ closeModal }) => (
            <InsuranceCompanyForm
              values={initialValues}
              closeModal={closeModal}
              add={true}
            />
          )}
        />
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "ServiceAdmin",
            "CompanyAdministrator"
            //,'ContractorAdministrator'
          ]}
        >
          <PureModal
            style={{ overflow: "hidden" }}
            windowProps={{ large: true }}
            renderTrigger={({ openModal }) => (
              <button
                className="modalButton"
                onClick={() => {
                  if (
                    bulkSelectInsurances.filter(x => x.checked)?.length <= 1
                  ) {
                    window.alert(
                      "Please select at least two insurance companies via the checkboxes to continue to the bulk update tool."
                    );
                  } else {
                    handleOpenModal(openModal);
                  }
                }}
              >
                Bulk Update
              </button>
            )}
            renderContent={({ closeModal }) => (
              <BulkUpdateInsurance
                values={initialBulkValues}
                closeModal={closeModal}
                add={true}
              />
            )}
          />
        </HasRolePermission>
      </div>
      <div className="searchInputContainer">
        {filterState.map((fs, idx) => {
          const inputType = fs.selectOne.inputType;

          const fieldsArrayFiltered = fieldsArray.filter(option => {
            const sanityCheck = !filterState.some(chosenOption => {
              const choIdx = filterState.indexOf(chosenOption);
              if (choIdx === idx) {
                return false;
              } else {
                const taco = option?.value?.substring(
                  0,
                  option?.value?.length - 2
                );
                const taco2 = chosenOption?.selectOne?.value.substring(
                  0,
                  chosenOption?.selectOne?.value?.length - 2
                );

                return taco === taco2;
              }
            });

            return sanityCheck;
          });

          const secondFieldsArray =
            allFieldsOptionsObject[fs.selectOne.fieldsArrayOptions];

          return (
            <div
              key={`filterOptionDiv-${idx}`}
              className={`filterOptionDiv-${idx}`}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr auto",
                gridGap: 5,
                margin: "2px 5px"
              }}
            >
              <SelectDropdown
                searchable={false}
                onChange={value => handleChange(value, idx, "selectOne")}
                placeHolder="Choose An Option to Filter By..."
                value={fs.selectOne.value}
                options={fieldsArrayFiltered}
              />
              {inputType === "input" ? (
                <input
                  style={{
                    margin: "auto",
                    borderRadius: "4px"
                  }}
                  onChange={e =>
                    setSearchValue({ index: idx, value: e.target.value })
                  }
                  value={searchValue.value}
                  type="text"
                />
              ) : (
                <ReturnInputType
                  idx={idx}
                  typeName={inputType}
                  value={
                    inputType === "multidropdown"
                      ? (Array.isArray(fs.selectTwo.value) &&
                          fs.selectTwo.value) ||
                        []
                      : fs.selectTwo.value
                  }
                  options={secondFieldsArray}
                />
              )}
              {idx === 0 ? (
                <i
                  className="mod-filter"
                  title="Add filter"
                  onClick={e => {
                    addFilterState(e);
                  }}
                  style={{ fontSize: "large" }}
                >
                  <IoMdAddCircle
                    style={{ filter: "drop-shadow(3px 3px 3px #7b7b7c)" }}
                  />
                </i>
              ) : (
                <i
                  className="mod-filter"
                  title="Remove filter"
                  onClick={() => {
                    removeFilterState(idx);
                  }}
                  style={{ fontSize: "large" }}
                >
                  <IoMdRemoveCircle
                    style={{ filter: "drop-shadow(3px 3px 3px #7b7b7c)" }}
                  />
                </i>
              )}
            </div>
          );
        })}
      </div>

      <div id="allSelector" onClick={handleBulkSelectors}>
        All
      </div>
      {filteredInsurances &&
        filteredInsurances.map(ins => {
          return (
            <div className="insuranceContainer" key={ins.sMGInsuranceID}>
              <div>
                <label className="insuranceContainer-checkbox">
                  <input
                    type="checkbox"
                    id={ins.sMGInsuranceID}
                    onChange={handleBulkSelectors}
                    checked={
                      bulkSelectInsurances?.find(
                        x => x.GUID === ins.sMGInsuranceID
                      )?.checked
                    }
                  />
                </label>
              </div>
              <div
                style={aLink}
                className={
                  selectedInsurance == ins.sMGInsuranceID
                    ? "insuranceList--item--selected"
                    : "insuranceList--item"
                }
                onClick={() => {
                  setSelectedInsurance(ins.sMGInsuranceID);
                }}
              >
                {ins.nameNoPrefix?.toUpperCase()}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default connect(state => ({
  insuranceOptions: selectors.getFormOptionsInsurance(state)
}))(InsuranceCompanyList);

InsuranceCompanyList.propTypes = {
  insuranceOptions: PropTypes.arrayOf(PropTypes.object),
  insuranceCompanies: PropTypes.array,
  insuranceCompanyList: PropTypes.array,
  selectedInsurance: PropTypes.string,
  setSelectedInsurance: PropTypes.func,
  initiateCompanyInsuranceExport: PropTypes.func,
  companyGuid: PropTypes.string,
  setBulkSelected: PropTypes.func,
  bulkSelectInsurances: PropTypes.arrayOf(PropTypes.object),
  setBulkSelectInsurances: PropTypes.func
};
