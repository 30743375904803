import { combineReducers } from 'redux'
import {
  createReducer,
  ifNoError,
  idReducer
} from 'utils/reducer'
import { Types } from "actions/order-profile";

const orderAccessIds = createReducer([], {
  [Types.RESPONSE_ACCESS_LOGS]: ifNoError((_, { payload }) => (
    payload.map(({ id }) => id)
  ))
})

const accessIds = idReducer('meta.orderId', orderAccessIds)

const orderChangeIds = createReducer([], {
  [Types.RESPONSE_CHANGE_LOGS]: ifNoError((_, { payload }) => (
    payload.map(({ id }) => id)
  ))
})

const changeIds = idReducer('meta.orderId', orderChangeIds)

const logs = combineReducers({
  accessIds,
  changeIds
})

export default logs

const EMPTY_IDS = []
const getOrderAccessLogIds = (state, { orderId }) => state.accessIds[orderId] || EMPTY_IDS
const getOrderChangeLogIds = (state, { orderId }) => state.changeIds[orderId] || EMPTY_IDS

export const selectors = {
  getOrderAccessLogIds,
  getOrderChangeLogIds
}
