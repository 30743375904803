import { Types } from "actions/physician-search";
import { createReducer } from "utils/reducer";
import * as R from "ramda";
import { createSelector } from "reselect";

const initialState = {
  fetching: false,
  searchValues: null,
  result: { count: 0, physicians: [] }
};
const physicianSearch = createReducer(initialState, {
  [Types.PHYSICIAN_API_REQUEST]: (_, { payload }) => ({
    ...initialState,
    isFetching: true,
    searchValues: payload
  }),
  [Types.PHYSICIAN_API_RESPONSE]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    result: payload
  }),
  [Types.PHYSICIAN_SEARCH_INVALIDATE]: () => ({
    ...initialState
  }),
  [Types.PHYSICIAN_API_ERROR]: state => ({
    ...state,
    result: { count: 0, physicians: [] },
    isFetching: false
  })
});

export default physicianSearch;

const getPhysicianSearchState = state => state.getIn(["physicianSearch"]);

export const selectors = {
  getIsFetchingPhysicians: createSelector(
    [getPhysicianSearchState],
    searchState => R.prop("isFetching", searchState)
  ),
  getPhysicianSearchResults: createSelector(
    [getPhysicianSearchState],
    searchState => R.propOr({ count: 0, physicians: [] }, "result", searchState)
  ),
  getPhysicianSearchValues: createSelector(
    [getPhysicianSearchState],
    searchState => R.propOr(null, "searchValues", searchState)
  )
};
