import React from "react";
import PropTypes from "prop-types";
import { formatPhone } from "utils/misc";

const styles = {
  container: {
    padding: 5,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "10"
  },
  label: { fontWeight: 800, marginRight: 10 }
};

const PhysicianInfo = ({
  name,
  street_address,
  city,
  zip,
  state,
  phone,
  npi,
  fax
}) => (
  <div style={styles.container}>
    <div>
      <div>
        <span style={styles.label}>Name:</span>
        {name}
      </div>
      <div>
        <span style={styles.label}>NPI #:</span>
        {npi}
      </div>
      <div>
        <span style={styles.label}>Phone:</span>
        {formatPhone(phone)}
      </div>
      <div>
        <span style={styles.label}>Fax:</span>
        {formatPhone(fax)}
      </div>
    </div>
    <div>
      <div>
        <span style={styles.label}>Address:</span>
        {street_address}
      </div>
      <div>
        <span style={styles.label}>City:</span>
        {city}
      </div>
      <div>
        <span style={styles.label}>Zip:</span>
        {zip}
      </div>
      <div>
        <span style={styles.label}>State:</span>
        {state}
      </div>
    </div>
  </div>
);

PhysicianInfo.propTypes = {
  name: PropTypes.string,
  npi: PropTypes.number,
  street_address: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  state: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string
};

export default PhysicianInfo;
