import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import pick from "lodash/pick";
import { selectors } from "reducers";
import Header from "./header";

export const ShippingCard = ({
  header,
  name,
  street_address,
  street_address2,
  city,
  state,
  zip
}) => (
  <div className="package-shipping-card">
    <div>{header}</div>
    <div>
      <h6>{name}</h6>
      <div>{street_address}</div>
      <div>{street_address2}</div>
      <div>
        {city}
        {city && state && ", "}
        {state} {zip}
      </div>
    </div>
  </div>
);
ShippingCard.propTypes = {
  header: PropTypes.string,
  name: PropTypes.string,
  street_address: PropTypes.string,
  street_address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string
};

const Shipping = ({
  isFetching,
  patient_name,
  shipToAddress,
  company_name,
  shipFromAddress,
  fulfillment_type
}) => (
  <div>
    <Header showSpinner={isFetching}>
      <span>Shipping</span>
      {fulfillment_type && (
        <span style={{ float: "right" }} className="no-print">
          Fullfillment Type: {fulfillment_type}
        </span>
      )}
    </Header>
    <div className="package-shipping">
      <ShippingCard header="Ship To:" name={patient_name} {...shipToAddress} />
      <ShippingCard
        header="Ship From:"
        name={company_name}
        {...shipFromAddress}
      />
    </div>
  </div>
);

Shipping.propTypes = {
  orderId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  patient_name: PropTypes.string,
  shipToAddress: PropTypes.object,
  company_name: PropTypes.string,
  shipFromAddress: PropTypes.object,
  fulfillment_type: PropTypes.string
};

Shipping.defaultProps = {
  shipToAddress: {},
  shipFromAddress: {}
};

export default connect((state, props) => {
  const patientInfo = selectors.getOrderPatientInfo(state, props);
  const orderInfo = selectors.getOrderInfo(state, props);
  const companyInfo = selectors.getOrderCompanyInfo(state, props);
  return {
    isFetching:
      patientInfo.isFetching || orderInfo.isFetching || companyInfo.isFetching,
    ...pick(patientInfo.data, ["patient_name"]),
    ...pick(orderInfo.data, "fulfillment_type"),
    shipToAddress: {
      ...pick(orderInfo.data, ["street_address", "street_address2", "city", "state", "zip"])
    },
    ...pick(companyInfo.data, ["company_name"]),
    shipFromAddress: {
      ...pick(companyInfo.data, ["street_address", "city", "state", "zip"])
    }
  };
})(Shipping);
