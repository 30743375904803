import React from "react";
// import PropTypes from "prop-types";
// import ProfileHeader from "components/order-profile/profile-header";
// import OrderInformation from "components/order-profile/order-information";
// import Updates from "components/order-profile/updates";
// import PackingSlipHeader from "components/order-profile/packing-slip-header";
// import Shipping from "components/order-profile/shipping";
// import ReplacementOrder from "components/order-profile/replacement-order";
// import SendToFulfillment from "components/order-profile/send-to-fulfillment";
// import EquipmentRequested from "components/order-profile/equipment-requested";
// import AccessLogs from "components/order-profile/access-logs";
// import OrderWorklist from "components/order-profile/work-list";
// import OrderNotes from "components/order-profile/notes";
//
const ExternalOrderProfile = props => {
  return (
    <div className="order-profile-container">
      {JSON.stringify(props)}
      {/* <OrderWorklist orderId={orderId} /> */}
      {/* <div className="order-profile"> */}
      {/*   <div className="order-profile-page"> */}
      {/*     <ProfileHeader */}
      {/*       orderId={orderId} */}
      {/*       orderRef={orderRef} */}
      {/*       setShowNotes={setShowNotes} */}
      {/*     /> */}
      {/*     <OrderInformation orderId={orderId} /> */}
      {/*     <Updates orderId={orderId} /> */}
      {/*     <Questions /> */}
      {/*   </div> */}
      {/*   <footer style={{ padding: "50px" }}>1/{showNotes ? 3 : 2} pages</footer> */}
      {/*   <Page> */}
      {/*     <PackingSlipHeader orderId={orderId} /> */}
      {/*     <Shipping orderId={orderId} /> */}
      {/*     <ReplacementOrder orderId={orderId} /> */}
      {/*     <SendToFulfillment orderId={orderId} /> */}
      {/*     <EquipmentRequested orderId={orderId} /> */}
      {/*   </Page> */}
      {/*   <footer style={{ padding: "50px" }}>2/{showNotes ? 3 : 2} pages</footer> */}
      {/*   <div style={{ display: showNotes ? "block" : "none" }}> */}
      {/*     <Page> */}
      {/*       <OrderNotes orderId={orderId} readOnly /> */}
      {/*     </Page> */}
      {/*     <footer style={{ padding: "50px" }}> 3/3 pages</footer> */}
      {/*   </div> */}
      {/*   {showLogs && ( */}
      {/*     <div className="no-print"> */}
      {/*       <Page> */}
      {/*         <h1>Logs</h1> */}
      {/*         <AccessLogs orderId={orderId} /> */}
      {/*       </Page> */}
      {/*     </div> */}
      {/*   )} */}
      {/* </div> */}
    </div>
  );
};

ExternalOrderProfile.defaultProps = {};

ExternalOrderProfile.propTypes = {};

export default ExternalOrderProfile;
