import React from "react";
import {
  getDemoChatDetails,
  setAPIError,
  setTwilioError
} from "./../../../Redux/Actions/chat";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Waiting } from "components/ui";
import TextChatGeneral from "./TextChatGeneral";
import MessageList from "../../../components/TwilioProgrammableTextChat/MessageList";
import PropTypes from "prop-types";
import TwilioVideo from "./../../../components/TwilioProgrammableVideo/VideoChat";
import { selectors } from "reducers";

class DemoChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReLoader: false,
      peerIdentity: "",
      roomName: "",
      hasJoinedRoom: false,
      partnerConnected: false,
      twilioToken: "",
      logs: [],
      videoChatInitiate: false,
      textChatInitiate: false,
      messages: [],
      textchatCollection: [],
      APIMembers: []
    };
  }

  static propTypes = {
    getDemoChatDetails: PropTypes.func,
    setAPIError: PropTypes.func,
    isLoader: PropTypes.bool,
    twilioErrorMessage: PropTypes.string,
    ProductDemoRoomDetails: PropTypes.object.isRequired,
    setTwilioError: PropTypes.func,
    match: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    userId: PropTypes.string.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (
      !state.videoChatInitiate &&
      !state.textChatInitiate &&
      props.ProductDemoRoomDetails &&
      props.ProductDemoRoomDetails.videoDetails &&
      props.ProductDemoRoomDetails.textChatDetails
    ) {
      return {
        roomName: props.ProductDemoRoomDetails.videoDetails.room_name,
        textchatCollection: props.ProductDemoRoomDetails.textChatDetails,
        APIMembers: props.ProductDemoRoomDetails.videoDetails.members
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (
      !this.state.videoChatInitiate &&
      this.state.roomName &&
      this.state.roomName.length > 0 &&
      this.state.twilioToken
    ) {
      this.setState({
        videoChatInitiate: true
      });
    }

    if (
      !this.state.textChatInitiate &&
      this.state.textchatCollection &&
      this.state.textchatCollection.length > 0 &&
      this.state.twilioToken
    ) {
      this.setState({
        textChatInitiate: true
      });
    }
  }

  componentDidMount() {
    let authDetails =
      JSON.parse(window.localStorage.getItem("evergreen")) &&
      JSON.parse(window.localStorage.getItem("evergreen"));
    if (authDetails && authDetails.twilio) {
      this.setState({
        identity: authDetails.first_name + " " + authDetails.last_name,
        twilioToken: authDetails.twilio.twilioToken
      });
    }
    let slot_id = "";
    if (this.props.match.params.id) {
      slot_id = this.props.match.params.id;
      this.props.setTwilioError("");
      this.props.setAPIError("");
      this.props.getDemoChatDetails(slot_id);
      window.addEventListener("beforeunload", this.leaveRoomIfJoined);
    } else {
      this.props.setAPIError("Facing issue to fetch details.");
    }
  }

  addMessage = message => {
    if (
      this.state.textChatInitiate &&
      this.props.twilioErrorMessage.length === 0 &&
      this.props.errorMessage.length === 0
    ) {
      if (message.author && message.author.length > 0) {
        const participantDetail =
          this.state.APIMembers &&
          this.state.APIMembers.length > 0 &&
          this.state.APIMembers.filter(m => m.id === message.author) &&
          this.state.APIMembers.filter(m => m.id === message.author).length >
            0 &&
          this.state.APIMembers.filter(m => m.id === message.author)[0];
        if (participantDetail) {
          message.author = participantDetail.name;
        }
      }
      const messageData = { ...message };
      this.setState({
        messages: [...this.state.messages, messageData]
      });
    } else {
      this.setState({
        messages: []
      });
    }
  };

  fetchError = errorMessage => {
    this.props.setTwilioError(errorMessage);
  };

  refreshPage = () => {
    this.props.setAPIError("");
    this.props.setTwilioError("");
    this.setState(
      {
        textChatInitiate: false,
        videoChatInitiate: false,
        isReLoader: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            videoChatInitiate: true,
            textChatInitiate: true,
            isReLoader: false
          });
        }, 2000);
      }
    );
    //location.reload();
  };

  render() {
    return (
      <div className="dd_row">
        {(this.props.isLoader || this.state.isReLoader) && <Waiting />}
        {(this.props.isLoader || this.state.isReLoader) && (
          <h2>Please wait to configured room..</h2>
        )}
        {this.props.errorMessage && this.props.errorMessage.length > 0 && (
          <h2 style={{ color: "red" }}>{this.props.errorMessage}</h2>
        )}
        {!this.props.isLoader &&
          !this.state.isReLoader &&
          this.props.twilioErrorMessage &&
          this.props.twilioErrorMessage.length > 0 && (
            <div className="dd_col-12">
              <h2 style={{ color: "red" }}>{this.props.twilioErrorMessage}</h2>
            </div>
          )}
        {!this.props.isLoader &&
          !this.state.isReLoader &&
          this.props.twilioErrorMessage &&
          this.props.twilioErrorMessage.length > 0 && (
            <div className="dd_col-12">
              <button onClick={this.refreshPage}>Re-Join</button>
            </div>
          )}
        {this.props.twilioErrorMessage.length === 0 &&
          this.props.errorMessage.length === 0 && (
            <React.Fragment>
              {this.state.videoChatInitiate && (
                <React.Fragment>
                  <TwilioVideo
                    fetchError={this.fetchError}
                    roomNm={this.state.roomName}
                    twilioToken={this.state.twilioToken}
                    isRemoteVisible={false}
                    members={this.state.APIMembers}
                  />
                </React.Fragment>
              )}
              {this.state.textChatInitiate && (
                <div className="dd_col-6">
                  <div className="AppContainer">
                    <MessageList messages={this.state.messages} />
                    {this.state.textchatCollection.map((data, index) => {
                      return (
                        <TextChatGeneral
                          userId={this.props.userId}
                          twilioToken={this.state.twilioToken}
                          roomDetail={data}
                          addMessage={this.addMessage}
                          greeting={index}
                          key={index}
                          userName={this.state.identity}
                          members={data.members}
                          fetchError={this.fetchError}
                          setTwilioError={this.props.setTwilioError}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ProductDemoRoomDetails: state.getIn(["newRootReducer"]).rdc_chat
      .ProductDemoRoomDetails,
    errorMessage: state.getIn(["newRootReducer"]).rdc_chat.errorMessage,
    twilioErrorMessage: state.getIn(["newRootReducer"]).rdc_chat
      .twilioErrorMessage,
    isLoader: state.getIn(["newRootReducer"]).rdc_loader.isLoader,
    userId: selectors.getUserId(state)
  };
};

export default connect(mapStateToProps, {
  getDemoChatDetails,
  setAPIError,
  setTwilioError
})(withRouter(DemoChat));
