import React, { useContext } from "react";
import PropTypes from "prop-types";
import QueryTableContext from "../context";
import {
  MdFilterList,
  MdArrowDropDown,
  MdArrowDropUp
} from "react-icons/lib/md";
import {
  hasFilter,
  serializeDateFilter,
  parseDateFilter
} from "hooks/useQuery/helpers";
import { path, propOr } from "ramda";
import Dropdown from "components/ui/dropdown";
import { IconButton } from "components/ui";
import isEqual from "lodash/isEqual";

const getSortIcon = (field, query) => {
  if (query?.sort?.by == field) {
    return path(["sort", "direction"], query) === "asc" ? (
      <MdArrowDropUp size={16} />
    ) : (
      <MdArrowDropDown size={16} />
    );
  }
};

const DateRangeHeaderColumn = ({
  header,
  field,
  headerClassName,
  style,
  title,
  dropdownPosition
}) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  return (
    <td
      className={`query-table-header-cell-container ${headerClassName}`}
      style={style}
      title={title}
    >
      <div className="query-table-header-cell">
        <div
          className={`header-text ${
            hasFilter(field, query.filters) ? "active" : ""
          }`}
          onClick={() => {
            queryDispatcher({
              type: "toggleSort",
              by: field
            });
          }}
        >
          {header}
          <div className="sort-icon">{getSortIcon(field, query)}</div>
        </div>
        <DateRangeDropDown
          query={query}
          queryDispatcher={queryDispatcher}
          dropdownPosition={dropdownPosition}
          field={field}
        />
      </div>
    </td>
  );
};

export default DateRangeHeaderColumn;

DateRangeHeaderColumn.propTypes = {
  field: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  style: PropTypes.object,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  filterDownSelect: PropTypes.bool,
  title: PropTypes.string,
  dropdownPosition: PropTypes.oneOf(["", "left", "right", "middle"])
};

const DateRangeDropDown = ({ field, dropdownPosition }) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  const value = path(["filters", field], query);
  const parsedValue = parseDateFilter(value);
  const [tmpFilter, setTmpFilter] = React.useState({
    ...parsedValue,
    mode: propOr("before", "mode", parsedValue)
  });

  function stopPropagation(e) {
    e.stopPropagation();
  }
  return (
    <Dropdown
      trigger={
        <IconButton
          icon={<MdFilterList />}
          className="button secondary icon-button column-filter-trigger"
        />
      }
      onClose={() => {
        const queryValue = tmpFilter.date ? tmpFilter : {};
        if (
          queryValue.mode === "beforeafter" &&
          (!queryValue.date2 || !queryValue.date)
        ) {
          return;
        } else if (!isEqual(parsedValue, queryValue)) {
          queryDispatcher({
            type: "setFilter",
            by: field,
            value: serializeDateFilter(
              queryValue.mode == "beforeafter" &&
                queryValue.date2 < queryValue.date // swap dates if date2 < date
                ? {
                    ...queryValue,
                    date: queryValue.date2,
                    date2: queryValue.date
                  }
                : queryValue
            )
          });
        }
      }}
      style={{ marginTop: 35 }}
      position={dropdownPosition}
    >
      <ul className="dropdown-list" onClick={stopPropagation}>
        <li
          className="dropdown-command"
          onClick={() => {
            setTmpFilter({ mode: "before" });
          }}
        >
          Clear
        </li>
        <li style={{ padding: 5, paddingTop: 15 }}>
          <select
            value={tmpFilter.mode}
            onChange={({ target }) => {
              setTmpFilter(({ date, date2 }) => ({
                mode: target.value,
                date,
                date2: target.value === "beforeafter" ? date2 : ""
              }));
            }}
            style={{ margin: 0 }}
          >
            <option value="before">Before</option>
            <option value="after">After</option>
            <option value="beforeafter">Between</option>
          </select>
        </li>
        <li style={{ padding: 5 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <input
              type="date"
              value={tmpFilter.date ?? ""}
              onChange={({ target }) => {
                setTmpFilter(x => ({ ...x, date: target.value }));
              }}
              style={{ margin: 0, marginTop: 10 }}
            />
            {tmpFilter.mode === "beforeafter" && (
              <>
                <span style={{ padding: 3 }}>And</span>
                <input
                  type="date"
                  style={{ margin: 0 }}
                  value={tmpFilter.date2 ?? ""}
                  onChange={({ target }) => {
                    setTmpFilter(x => ({ ...x, date2: target.value }));
                  }}
                />
              </>
            )}
          </div>
        </li>
      </ul>
    </Dropdown>
  );
};

DateRangeDropDown.propTypes = {
  field: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired,
  dropdownPosition: PropTypes.oneOf(["", "left", "right", "middle"])
};
