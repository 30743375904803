import { useEffect } from "react";
import { useFetch } from "hooks";
import { get } from "utils/api";

const useSleepgladFormulary = (companyId, patientId) => {
  const { response, fetchData, isFetching } = useFetch({
    apiFn: ({ companyId, patientId }) =>
      get(
        `autofit/inventory?only_active=true&${addQueryParamater(
          "company_id",
          companyId
        )}${addQueryParamater("patient_id", patientId)}`
      ),
    defaultValue: { maskOptions: [], manufacturers: [] },
    transformResponse: ({ inventories, manufacturers }) => ({
      maskOptions: inventories.map(({ id, name }) => ({
        text: name,
        value: id
      })),
      manufacturers: manufacturers.map(({ id, name }) => ({
        text: name,
        value: id
      }))
    })
  });
  useEffect(() => {
    if (patientId || companyId) {
      fetchData({ companyId, patientId });
    }
  }, [patientId, companyId]);
  const { maskOptions = [], manufacturers = [] } = response;
  return {
    maskOptions,
    manufacturers,
    isFetchingFormulary: isFetching
  };
};

const addQueryParamater = (key, value) => (value ? `&${key}=${value}` : "");

export default useSleepgladFormulary;
