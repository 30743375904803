import React from "react";
import PropTypes from "prop-types";
import MdLocationCity from "react-icons/lib/md/location-city";
import MdLocationOn from "react-icons/lib/md/location-on";

const PhysicianAddress = ({
  onClick,
  address_purpose,
  city,
  postal_code,
  state,
  telephone_number,
  address_1,
  address_2
}) => (
  <div className="physician-address" onClick={onClick}>
    <span>
      {address_purpose === "LOCATION" ? (
        <MdLocationCity size={14} />
      ) : (
        <MdLocationOn size={14} />
      )}
      {address_1} {address_2}
    </span>
    <span>
      <span>{city}, </span>
      <span>{state}, </span>
      <span>{postal_code}</span>
    </span>
    <span>{telephone_number}</span>
  </div>
);

PhysicianAddress.defaultProps = {
  address_2: "",
  telephone_number: ""
};

PhysicianAddress.propTypes = {
  address_purpose: PropTypes.string.isRequired,
  address_1: PropTypes.string.isRequired,
  address_2: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string.isRequired,
  postal_code: PropTypes.string.isRequired,
  telephone_number: PropTypes.string,
  onClick: PropTypes.func
};

export default PhysicianAddress;
