import { useState } from "react";

// Return a wrapped version of useState's function that
// reads and persists value in local storage.
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      /* eslint-disable-next-line */
      console.error(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      // use same api as useState
      // param could be value or function
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      /* eslint-disable-next-line */
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
