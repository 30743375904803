import React from "react";
import Barcode from "react-barcode";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const BarcodeDisplay = ({ salesOrderNumber }) => {
  //Sometimes companies put multiple order numbers into this field, so we make an array of order numbers here and map a barcode for each split
  return salesOrderNumber == null || salesOrderNumber.trim() == "" ? (
    <div></div>
  ) : (
    <div>
      <h3>Sales Order Number</h3>
      <Barcode
        value={salesOrderNumber}
        lineColor="#000000"
        width={1}
        height={50}
      />
    </div>
  );
};

BarcodeDisplay.propTypes = {
  salesOrderNumber: PropTypes.string,
  orderId: PropTypes.string
};

export default connect((state, props) => {
  const orderProfile = state.getIn(["orderProfile"]).entities.orderInfo;
  const orderInfoById = orderProfile && orderProfile[props.orderId];
  const salesOrderNumberByIdString =
    orderInfoById && orderInfoById.sales_order_number;
  const salesOrderNumberArray = salesOrderNumberByIdString
    ? salesOrderNumberByIdString.split(",")
    : "";
  const salesOrderNumber = salesOrderNumberArray
    ? salesOrderNumberArray[0]
    : salesOrderNumberByIdString;
  return {
    salesOrderNumber
  };
})(BarcodeDisplay);
