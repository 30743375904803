import { get } from "utils/api";
// import { errorMessage } from "actions/message";

export const Types = {
  REQUEST_CALL_SNAPSHOT: "REQUEST_CALL_SNAPSHOT",
  CALL_SNAPSHOT_RESPONSE: "CALL_SNAPSHOT_RESPONSE",
  CALL_SNAPSHOT_FAILED: "CALL_SNAPSHOT_FAILED"
};

/* eslint-disable-next-line */
export const fetchCallSnapshot = (start_date, end_date) => async dispatch => {
  dispatch({ type: Types.REQUEST_CALL_SNAPSHOT });
  try {
    const { time_grouped_statistics } = await get("PhoneDashboard");
    dispatch({
      type: Types.CALL_SNAPSHOT_RESPONSE,
      payload: time_grouped_statistics
    });
    return time_grouped_statistics;
  } catch (error) {
    dispatch({ type: Types.CALL_SNAPSHOT_FAILED, error });
  }
};
