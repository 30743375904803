import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import Tab from "./tab";
import { destroyTab, openTab, closeTab } from "actions/system-tray";
import BatchPatientView from "components/batch-patient-view";
import BatchOrderView from "components/batch-order-view";
import BatchAutoDialer from "components/batch-auto-dialer";
import BatchVmailView from "components/batch-vmails-view";
import PureWindow from "components/ui/modal/pure-window";
import ContactRecordProfile from "components/contact-records/call-profile";

const tabToComponent = tab => {
  switch (tab.type) {
    case "patients":
      return <BatchPatientView />;
    case "patient-outreach":
      return <BatchAutoDialer />;
    case "orders":
      return <BatchOrderView />;
    case "vmails":
      return <BatchVmailView />;
    case "contact-record":
      return <ContactRecordProfile callSid={tab.sid} isModal />;
    case "googleFile":
      return (
        <div className="file-preview-container">
          <iframe
            className="file-preview-frame"
            width="800"
            frameBorder="0"
            scrolling="no"
            src={`https://docs.google.com/document/d/${tab.key}`}
          />
        </div>
      );
    default:
      return <div>Not Available</div>;
  }
};

const SystemTray = ({
  tabs,
  open_tab,
  destroyTab,
  openTab,
  closeTab,
  chatIsOpen
}) => (
  <React.Fragment>
    {tabs.map(t => (
      <PureWindow
        key={t.key}
        large
        minimize
        hidden={t.key !== open_tab}
        onXClick={() => {
          if (t.onClose === "function") t.onClose();
          closeTab(t.key);
        }}
        chatIsOpen={chatIsOpen}
        className={t.className}
      >
        {tabToComponent(t)}
      </PureWindow>
    ))}
    <div className="no-print">
      <div
        className={`system-tray ${chatIsOpen ? "chat-active" : ""}`}
        id="system-tray"
      >
        {tabs.map(tab => (
          <Tab
            key={tab.key}
            {...tab}
            isOpen={open_tab === tab.key}
            handleOpen={() => {
              openTab(tab.key);
            }}
            handleMinimize={() => {
              closeTab(tab.key);
            }}
            handleClose={e => {
              e.stopPropagation();
              if (typeof tab.closeCallback === "function") {
                tab.closeCallback();
              }
              destroyTab(tab.key);
            }}
          />
        ))}
      </div>
    </div>
  </React.Fragment>
);

export default connect(
  state => ({
    tabs: selectors.getTabs(state),
    open_tab: selectors.getOpenTab(state),
    chatIsOpen: selectors.getChatIsOpen(state)
  }),
  {
    destroyTab,
    openTab,
    closeTab
  }
)(SystemTray);

SystemTray.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  open_tab: PropTypes.string,
  destroyTab: PropTypes.func.isRequired,
  openTab: PropTypes.func.isRequired,
  closeTab: PropTypes.func.isRequired,
  chatIsOpen: PropTypes.bool
};
