export const autoMessages = [
  {
    category: "Casual",
    messages: [
      {
        text: "Hold",
        value: "Please hold while I look that up.",
      },
      {
        text: "Bye",
        value: "Thank you. Have a great Day!",
      },
      {
        text: "Time?",
        value: "What time is it currently for you?",
      },
      {
        text: "Phone?",
        value: "What is a good phone number for you?",
      },
    ],
  },
  {
    category: "Applications",
    messages: [
      {
        text: "Tried Mobile?",
        value: "Have you tried logging in to the My Sleep Coach Mobile App?",
      },
      {
        text: "Tried Portal?",
        value: "Have you tried logging in to the Patient Portal Website?",
      },
      {
        text: "Android",
        value:
          "https://play.google.com/store/apps/details?id=com.s3phoenix.mobile",
      },
      {
        text: "iOS",
        value:
          "https://itunes.apple.com/us/app/my-sleep-coach/id936292288?mt=8",
      },
      {
        text: "Portal",
        value: "https://papresupply.com/",
      },
    ],
  },
  {
    category: "Ordering",
    messages: [
      {
        text: "Mask?",
        value: "What type of mask do you currently have?",
      },
      {
        text: "Filter?",
        value: "What type of filter do you currently have?",
      },
      {
        text: "Tubing?",
        value: "What type of tubing do you currently have?",
      },
      {
        text: "Seals?",
        value: "What type of seals/cushions do you currently have?",
      },
      {
        text: "Head Gear?",
        value: "What type of head gear do you currently have?",
      },
      {
        text: "Chinstrap?",
        value: "What type of chin strap do you currently have?",
      },
      {
        text: "Manufacturer?",
        value: "Could you tell me the name of the manufacturer?",
      },
      {
        text: "Model #?",
        value: "Could you tell me the model number?",
      },
    ],
  },
];
