import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { firstDayOfThisMonth, today } from "utils/dates";
import { fetchUserInsights, exportUserInsights } from "actions/user-profile";
import {
  fetchLineItemCountHeatMap,
  fetchOrderStatusChangeHeatMap
} from "actions/heat-map.js";
import DayPickerRange from "components/ui/day-picker-range";
import { Waiting } from "components/ui";
import UserLineItemHeatMap from "./line-item-heat-map";
import UserExports from "./user-exports";
import OrderStatusesChangeHeatMap from "components/ui/statistics/order-status-change-heat-map";
import UserEquipmentSoldGraph from "./equipment-sold-graph";
import UserOrders from "./user-orders";
import UserOrderStatuses from "./user-order-statuses";
import UserInsightsOverview from "./user-insights-overview";
import { HasRolePermission as CanView } from "components/shared/has-role-permission";
import useDateRange from "hooks/useDateRange";

const UserInsightsContainer = ({
  userId,
  userType,
  fetchUserInsights,
  fetchLineItemCountHeatMap,
  fetchOrderStatusChangeHeatMap,
  isFetchingUserInsights,
  insightsError,
  exportUserInsights
}) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDateRange({
    startDate: firstDayOfThisMonth(),
    endDate: today()
  });
  const [selectedView, setSelectedView] = React.useState("insights");
  const requestUserHeatMap = async () => {
    if (["SleepCoach"].includes(userType))
      fetchLineItemCountHeatMap({
        payload: {
          id: userId,
          start_date: startDate,
          end_date: endDate,
          entity_type: "SleepCoachLineItemCounts"
        }
      });
    if (
      [
        "Administrator",
        "SleepCoach",
        "OfficeAdministrator",
        "CompanyAdministrator"
      ].includes(userType)
    )
      await fetchOrderStatusChangeHeatMap({
        payload: {
          id: userId,
          start_date: startDate,
          end_date: endDate,
          entity_type: "UserOrderStatusChangeCounts"
        }
      });
  };

  const sendUserExport = exportType => {
    const payload = {
      userId,
      startDate,
      endDate,
      [exportType]: true
    };
    exportUserInsights(payload);
  };

  return (
    <div className="user-insights-container">
      <div className="user-insights-header">
        <div className="tab-menu">
          <CanView
            userRole={userType}
            allowedRoles={[
              "Administrator",
              "SleepCoach",
              "OfficeAdministrator",
              "CompanyAdministrator"
            ]}
          >
            <span
              onClick={() => setSelectedView("insights")}
              className={selectedView === "insights" ? "is-active" : ""}
            >
              Insights
            </span>
          </CanView>
          <CanView userRole={userType} allowedRoles={["SleepCoach"]}>
            <span
              onClick={() => setSelectedView("equipment-heat-map")}
              className={
                selectedView === "equipment-heat-map" ? "is-active" : ""
              }
            >
              Equipment Sold Heat Map
            </span>
          </CanView>
          <CanView
            userRole={userType}
            allowedRoles={[
              "CompanyAdministrator",
              "OfficeAdministrator",
              "Administrator",
              "SleepCoach"
            ]}
          >
            <span
              onClick={() => setSelectedView("order-heat-map")}
              className={selectedView === "order-heat-map" ? "is-active" : ""}
            >
              Orders Updated Heat Map
            </span>
          </CanView>
          <CanView
            userRole={userType}
            allowedRoles={[
              "CompanyAdministrator",
              "OfficeAdministrator",
              "Administrator",
              "SleepCoach"
            ]}
          >
            <span
              onClick={() => setSelectedView("exports")}
              className={selectedView === "exports" ? "is-active" : ""}
            >
              Exports
            </span>
          </CanView>
        </div>
        <DayPickerRange
          handleFromClick={({ from }) => {
            setStartDate(from);
          }}
          handleToClick={({ to }) => {
            setEndDate(to);
          }}
          initStartDate={startDate}
          initEndDate={endDate}
          dates={{ to: startDate, from: endDate }}
        />
        <button
          className="goRange postfix"
          disabled={isFetchingUserInsights || !startDate || !endDate}
          onClick={() => {
            fetchUserInsights(userId, startDate, endDate);
            requestUserHeatMap();
          }}
        >
          Submit
        </button>
      </div>
      <div
        style={{
          display: selectedView === "insights" ? "flex" : "none",
          flexDirection: "column"
        }}
      >
        {!insightsError ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CanView
              userRole={userType}
              allowedRoles={["SleepCoach", "Administrator"]}
            >
              {isFetchingUserInsights && (
                <Waiting reason="Fetching user insights." />
              )}
              <UserOrders />
              <UserEquipmentSoldGraph />
              <UserInsightsOverview onExport={sendUserExport} />
            </CanView>
            <CanView
              userRole={userType}
              allowedRoles={["OfficeAdministrator", "CompanyAdministrator"]}
            >
              {isFetchingUserInsights ? (
                <Waiting reason="Fetching user insights." />
              ) : (
                <UserOrderStatuses />
              )}
            </CanView>
          </div>
        ) : (
          <div
            style={{
              color: "red",
              display: "flex",
              paddingTop: 150,
              justifyContent: "center"
            }}
          >
            Sorry Something went wrong, failed to pull user insights
          </div>
        )}
      </div>
      {selectedView === "equipment-heat-map" && (
        <UserLineItemHeatMap userId={userId} dates={{ startDate, endDate }} />
      )}
      {selectedView === "order-heat-map" && (
        <OrderStatusesChangeHeatMap
          id={userId}
          dates={{ startDate, endDate }}
        />
      )}
      {selectedView === "exports" && (
        <UserExports id={userId} dates={{ startDate, endDate }} />
      )}
    </div>
  );
};

UserInsightsContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string,
  fetchUserInsights: PropTypes.func.isRequired,
  fetchLineItemCountHeatMap: PropTypes.func.isRequired,
  fetchOrderStatusChangeHeatMap: PropTypes.func.isRequired,
  insightsError: PropTypes.bool,
  isFetchingUserInsights: PropTypes.bool,
  exportUserInsights: PropTypes.func.isRequired
};

export default connect(
  state => ({
    insightsError: selectors.getUserProfileFetchErrors(state).insights,
    isFetchingUserInsights: selectors.getIsFetchingUserInsights(state)
  }),
  {
    fetchUserInsights,
    fetchLineItemCountHeatMap,
    fetchOrderStatusChangeHeatMap,
    exportUserInsights
  }
)(UserInsightsContainer);
