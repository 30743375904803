import React from "react";
import PropTypes from "prop-types";
import MdBackArrow from "react-icons/lib/md/arrow-back";
import MdForwardArrow from "react-icons/lib/md/arrow-forward";
import "./styles.scss";

const PageSelect = ({ curPageNum, totalPages, setPageNum, alwaysShow }) => {
  return (
    <div className="pagination-controls">
      {(alwaysShow || totalPages !== 1) && (
        <span className="pagination-controls-container">
          {curPageNum > 1 && (
            <span
              className="arrow-container previous-page"
              onClick={() => setPageNum(curPageNum - 1)}
            >
              <MdBackArrow size={18} />
            </span>
          )}
          <span className="current-page">
            Page {curPageNum} of {totalPages}
          </span>
          {curPageNum < totalPages && (
            <span
              className="arrow-container next-page"
              onClick={() => setPageNum(curPageNum + 1)}
            >
              <MdForwardArrow size={18} />
            </span>
          )}
        </span>
      )}
    </div>
  );
};

PageSelect.defaultProps = {
  alwaysShow: true
};

PageSelect.propTypes = {
  curPageNum: PropTypes.number,
  totalPages: PropTypes.number,
  setPageNum: PropTypes.func.isRequired,
  alwaysShow: PropTypes.bool
};

export default PageSelect;
