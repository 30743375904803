import { makeFetchTuple } from 'actions/fetch'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

export const SLEEP_COACH = 'SLEEP_COACH'
export const THERAPIST = 'THERAPIST'
export const OFFICE_ADMINISTRATOR = 'OFFICE_ADMINISTRATOR'
export const COMPANY_ADMINISTRATOR = 'COMPANY_ADMINISTRATOR'
export const ADMINISTRATOR = 'ADMINISTRATOR'
export const COMPLIANCE_COACH = 'COMPLIANCE_COACH'

export const USER_TYPE_INFO = {
  [SLEEP_COACH]: {
    actionType: SLEEP_COACH,
    urlType: 'sleep_coaches',
    entityType: 'sleepCoachTable',
    reducer: 'sleepCoachTable'
  },
  [THERAPIST]: {
    actionType: THERAPIST,
    urlType: 'therapists',
    entityType: 'therapists',
    reducer: 'therapist'
  },
  [OFFICE_ADMINISTRATOR]: {
    actionType: OFFICE_ADMINISTRATOR,
    urlType: 'office_administrators',
    entityType: 'officeAdministrators',
    reducer: 'officeAdministrator'
  },
  [COMPANY_ADMINISTRATOR]: {
    actionType: COMPANY_ADMINISTRATOR,
    urlType: 'company_administrators',
    entityType: 'companyAdministrators',
    reducer: 'companyAdministrator'
  },
  [ADMINISTRATOR]: {
    actionType: ADMINISTRATOR,
    urlType: 'administrators',
    entityType: 'administrators',
    reducer: 'administrator'
  },
  [COMPLIANCE_COACH]: {
    actionType: COMPLIANCE_COACH,
    urlType: 'compliance_coaches',
    entityType: 'complianceCoaches',
    reducer: 'complianceCoach'
  }
}

export const makeFetchUsersType = userActionType => `USERS_FETCH_${userActionType}`

const makeFetchUsers = ({
  actionType,
  urlType,
  entityType
}) => makeFetchTuple({
  url: `users?type=${urlType}`,
  baseType: makeFetchUsersType(actionType),
  transform: users => ({
    entities: {
      [entityType]: keyBy(users, 'id')
    },
    ids: users.map(u => u.id)
  })
})

export const USER_FETCH_ACTION_CREATORS = mapValues(USER_TYPE_INFO, typeInfo => makeFetchUsers(typeInfo))

export const REQUEST_EXPORT_USER_TABLE = 'REQUEST_EXPORT_USER_TABLE'
export const exportUserTable = (userType, filteredRows) => ({
  type: REQUEST_EXPORT_USER_TABLE,
  payload: { userType, filteredRows }
})
export const RESPONSE_EXPORT_USER_TABLE = 'RESPONSE_EXPORT_USER_TABLE'
export const errorExportUserTable = (payload, userType) => ({
  type: RESPONSE_EXPORT_USER_TABLE,
  error: true,
  payload,
  meta: { userType }
})
