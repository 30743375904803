export const errorAction = type => payload => ({
  type,
  payload,
  error: true
})

// reinitializes store to default state
export const REINITIALIZE_APP = 'REINITIALIZE_APP'
export const reinitializeApp = () => ({
  type: REINITIALIZE_APP
})
