import React, { useState } from "react";
import PageHeader from "components/ui/page-header";
import PatientOutreachTable from "./table";

const PatientOutreachTablePage = () => {
  const [isEmailSelected, setIsEmailSelected] = useState(false);
  const [isTextSelected, setIsTextSelected] = useState(false);
  const toggleEmail = () => {
    setIsEmailSelected(!isEmailSelected);
  };
  const toggleText = () => {
    setIsTextSelected(!isTextSelected);
  };
  return (
    <div>
      <PageHeader title="Patient Outreach" />
      <PatientOutreachTable
        toggleEmail={toggleEmail}
        toggleText={toggleText}
        isTextSelected={isTextSelected}
        isEmailSelected={isEmailSelected}
      />
    </div>
  );
};

export default PatientOutreachTablePage;
