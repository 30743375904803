import React, { useState } from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import IoMdCheckBoxOut from "react-icons/lib/io/android-checkbox-outline";
import IoMdCheckBoxOutlineBlank from "react-icons/lib/io/android-checkbox-outline-blank";
import MdEdit from "react-icons/lib/md/edit";
import PureModal from "components/ui/modal/pure-modal";
import EditOrderHoldReason from "./edit-order-hold-reason";
import moment from "moment";

const OrderHoldReason = ({
  reason,
  updateOrderHoldReason,
  orderId,
  canEditHoldReason,
}) => {
  const [isUpdatingHoldReason, setIsUpdatingHoldReason] = useState(false);
  return (
    <div className="worklist-item hold-reason">
      <div
        className={`hold-reason ${
          reason.resolved ? "resolved" : "un-resolved"
        }`}
      >
        {isUpdatingHoldReason ? (
          <InlineWaiting />
        ) : (
          <div
            className={`hold-reason-label ${
              canEditHoldReason ? "" : "disabled"
            }`}
            onClick={async () => {
              try {
                if (!canEditHoldReason) return;
                setIsUpdatingHoldReason(true);
                await updateOrderHoldReason(orderId, {
                  ...reason,
                  resolved: !reason.resolved,
                });
              } finally {
                setIsUpdatingHoldReason(false);
              }
            }}
          >
            {reason.resolved ? (
              <i className="flat-icon-button ripple" title="Mark Completed">
                <IoMdCheckBoxOut />
              </i>
            ) : (
              <i className="flat-icon-button ripple">
                <IoMdCheckBoxOutlineBlank />
              </i>
            )}
            {reason.holdReason}
          </div>
        )}
        {reason.comments && (
          <div className="reason-comments">{reason.comments}</div>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="bold">Creator: </div>
        <div className="worklist-item-meta">
          <div className="worklist-item-info">{reason.createdByName}</div>
          <div>{moment(reason.createdDate).format("MMMM Do YYYY, h:mm a")}</div>
        </div>
        <div className="bold">Resolver: </div>
        <div className="worklist-item-meta">
          {reason.resolvedByName && reason.resolvedDate ? (
            <div>
              <div className="worklist-item-info">{reason.resolvedByName}</div>
              <div>
                {moment(reason.resolvedDate).format("MMMM Do YYYY, h:mm a")}
              </div>
            </div>
          ) : (
            <div className="worklist-item-info warning">Not Resolved</div>
          )}
        </div>
        <PureModal
          renderTrigger={({ openModal }) => (
            <div className="edit-hold-reason">
              <i className="flat-icon-button ripple" onClick={openModal}>
                <MdEdit color="#555" />
              </i>
            </div>
          )}
          renderContent={({ closeModal }) => (
            <EditOrderHoldReason
              reason={reason}
              orderId={orderId}
              updateOrderHoldReason={updateOrderHoldReason}
              closeModal={closeModal}
              canEditHoldReason={canEditHoldReason}
            />
          )}
        />
      </div>
    </div>
  );
};

OrderHoldReason.propTypes = {
  updateOrderHoldReason: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  reason: PropTypes.shape({
    holdReasonPK: PropTypes.number,
    resolved: PropTypes.bool,
    holdReason: PropTypes.string,
    comments: PropTypes.string,
    updatedByName: PropTypes.string,
    createdDate: PropTypes.string,
    createdByName: PropTypes.string,
    resolvedByName: PropTypes.string,
    resolvedDate: PropTypes.string
  }).isRequired,
  canEditHoldReason: PropTypes.bool,
};

export default OrderHoldReason;
