import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import SelectDropdown from "components/forms/select-dropdown";

const TeamSelect = ({ teams, value, onChange }) => (
  <SelectDropdown options={teams} value={value} onChange={onChange} />
);

export default connect(state => ({
  teams: selectors.getTeamNameOptions(state)
}))(TeamSelect);

TeamSelect.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};