import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { PureModal, TableButton } from "components/ui";
import IoMdRefresh from "react-icons/lib/io/refresh";
import IoMdPerson from "react-icons/lib/io/person";
import { setPatientBatchIds } from "actions/batch";
import BulkPatientUpdate from "components/bulk-patient-update";
import { createTab } from "actions/system-tray";
import { exportPatientsReport } from "actions/exports";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import IoMdEdit from "react-icons/lib/io/edit";
import { TableSettings } from "hooks/useTableSettings";
import { PatientsTableSettingsContext } from "./settings";
import HasRolePermission from "components/shared/has-role-permission";
import * as R from "ramda";

const PatientsTableControls = ({
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows,
  query
}) => {
  const dispatch = useDispatch();
  const { tableSettings, updateTableSettings, columns, resetTableSettings } =
    useContext(PatientsTableSettingsContext);
  return (
    <div className="query-table-controls">
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoMdRefresh />
      </TableButton>
      <HasRolePermission
        allowedRoles={[
          "Administrator",
          "ServiceAdmin",
          "ServiceCoach",
          "OfficeAdministrator",
          "CompanyAdministrator"
        ]}
      >
        <TableButton
          title="Export patients to excel file"
          onClick={async () => {
            const columnsToExport = R.pipe(
              R.values,
              R.filter(R.prop("active")),
              R.map(R.prop("field"))
            )(tableSettings);
            await dispatch(exportPatientsReport(query, columnsToExport));
          }}
        >
          <FaFileExcelO />
        </TableButton>
      </HasRolePermission>
      <TableButton
        title={`Process patients (${selectedRows.length} selected)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          dispatch(
            setPatientBatchIds(
              selectedRows.map(x => x.id),
              selectedRows.map(x => x.patient_name)
            )
          );
          dispatch(
            createTab({
              type: "patients",
              key: "patients",
              label: `Patients (${selectedRows.length})`,
              meta: { ids: selectedRows.map(x => x.id) }
            })
          );
          clearSelectedRows();
        }}
      >
        <IoMdPerson size={24} />
      </TableButton>
      <HasRolePermission allowedRoles={["Administrator", "ServiceAdmin"]}>
        <PureModal
          renderTrigger={({ openModal }) => (
            <TableButton
              disabled={selectedRows.length === 0}
              onClick={openModal}
              title={`Bulk update Patients (${selectedRows.length} selected)`}
            >
              <IoMdEdit />
            </TableButton>
          )}
          renderContent={({ closeModal }) => (
            <BulkPatientUpdate
              patientIds={selectedRows.map(x => x.id)}
              onUpdateError={() => {
                clearSelectedRows();
              }}
              onUpdateSuccess={() => {
                closeModal();
                clearSelectedRows();
                refreshData();
              }}
            />
          )}
        />
      </HasRolePermission>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} Patients</span>
        )}
      </div>
      <div className="action-buttons companies-table-action-buttons">
        <TableSettings
          columns={columns}
          tableSettings={tableSettings}
          updateTableSettings={updateTableSettings}
          resetTableSettings={resetTableSettings}
        />
      </div>
    </div>
  );
};

export default PatientsTableControls;

PatientsTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, patient_name: PropTypes.string })
  ).isRequired,
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired
};
