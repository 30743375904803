import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

const onChange = (e, props) => {
    // console.log(e.target.value);
    if (!isNil(props.onChange)) {
        props.onChange(e)
    } else {
        console.log('onChange is not given in Porps');
    }
}

const TextComponent = (props) => {
      
            return (
                <textarea
                    maxLength={(props.maxLength || props.maxLength > 0)? props.maxLength: null}
                    id={props.id} 
                    name={props.name}
                    type="textArea"
                    rows={props.rows}
                    cols={props.cols}
                    onChange={obj => onChange(obj, props)}
                    value={props.value}
                    placeholder={props.placeholder} 
                    style={{background:"#fafafa"}}
                    disabled={props.disabled}/>
            )
}

TextComponent.defaultProps = {
    placeholder: "TextArea Component",
    cols:30,
    rows:10
}

TextComponent.propTypes = {
    name: PropTypes.string,
    id:PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    rows:PropTypes.number,
    cols:PropTypes.number,
    disabled:PropTypes.bool,
    maxLength:PropTypes.number
}

export default TextComponent;

/*
type = text , checkbox, radio 
*/