import React from "react";
import PropTypes from "prop-types";
import PureWindow from "components/ui/modal/pure-window";

const ElgibilityWindow = ({ raLink, setRaLink, onClose }) => {
  return (
    <PureWindow
      onXClick={() => {
        onClose();
        setRaLink();
      }}
      className="referral-answer-window"
    >
      <iframe
        src={raLink}
        height="100%"
        allow="encrypted-media"
        allowFullScreen
        title="Patient Verification"
        scrolling="yes"
        width="100%"
        frameBorder="0"
      />
    </PureWindow>
  );
};

ElgibilityWindow.propTypes = {
  raLink: PropTypes.string.isRequired,
  setRaLink: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ElgibilityWindow;
