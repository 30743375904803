import React from "react";
import { PureModal } from "components/ui";
import RescheduleAppointmentModal from './index.jsx'
import PropTypes from "prop-types";

export function RescheduleAppointmentGridOpenModal (){
    document.getElementById(`rescheduleAppointmentGridOpenModal`).click();
}

export default function RescheduleAppointmentGridModal({ setIsSubmitting, chat_id, onSuccess, chat_type }) {

    return (
        <PureModal
            renderTrigger={({ openModal }) => <div onClick={() => openModal()} id={`rescheduleAppointmentGridOpenModal`}></div>}
            renderContent={({ closeModal }) =>
                <RescheduleAppointmentModal
                    setIsSubmitting={ setIsSubmitting }
                    chat_id={ chat_id }
                    onSuccess={ onSuccess }
                    closeModal={ closeModal }
                    chat_type={chat_type}
                />
            }
        />
    )
}

RescheduleAppointmentGridModal.propTypes = {
    setIsSubmitting: PropTypes.func, 
    chat_id: PropTypes.string, 
    onSuccess: PropTypes.func, 
    chat_type: PropTypes.string
}