import React from "react";
import { SingleStatCard } from "components/dashboard/dashboard-cards/single-stat-card";
import './compliance-metrics-patients-overview.scss';
import PropTypes from "prop-types"


const ComplianceMetricsPatientsOverview = ({ complianceMetricsData, onProgressBarClicked }) => {

  const dashboardData = [
    {
      title: "Current Active Patients",
      tooltipText: "All patients active (not On Hold and not Delayed) and with stage = Compliance",
      class: "active-patients-card",
      value: complianceMetricsData.ActivePatientsCount,
      isCompliantPatientsCard: false
    },
    {
      title: "Compliant Patients",
      subtitle: "Over all compliance patients",
      tooltipText: "",
      class: "compliant-patients-card",
      value: complianceMetricsData.CompliantPatientsCount 
        ? { count: complianceMetricsData.CompliantPatientsCount, percentage: complianceMetricsData.CompliantPatientsPercentage, countNotCompliant: complianceMetricsData.NotCompliantPatientsCount }
        : { count: "", percentage: "", countNotCompliant: "" },
      isCompliantPatientsCard: true
    },
    {
      title: "Ready for Outreach",
      tooltipText: "",
      class: "ready-outreach-patients-card",
      value: complianceMetricsData.ReadyForOutreach,
      isCompliantPatientsCard: false
    },
  ];

  return (
    <>
      {dashboardData.map((patientStat, i) => (
        <SingleStatCard
          key={i}
          classProp={patientStat.class}
          title={patientStat.title}
          subtitle={patientStat.subtitle}
          tooltipText={patientStat.tooltipText}
          numDashboardCards={dashboardData.length}
          value={patientStat.value}
          isCompliantPatientsCard={patientStat.isCompliantPatientsCard}
          onProgressBarClicked={onProgressBarClicked}
          isComplianceDashboard={true}
        />
      ))}
    </>
  );
};

export default ComplianceMetricsPatientsOverview;


ComplianceMetricsPatientsOverview.propTypes = {
  complianceMetricsData: PropTypes.object, 
  onProgressBarClicked: PropTypes.func
}