import React from "react";
import { PureModal } from "components/ui";
import CancelAppointmentConfirmation from "./index.jsx"
import PropTypes from "prop-types";

export function CancelAppointmentGridOpenModal (){
    document.getElementById(`cancelAppointmentGridOpenModal`).click();
}

export default function CancelAppointmentGridModal({ setIsSubmitting, chat_id, onSuccess }) {

    return (
        <PureModal
            renderTrigger={({ openModal }) => <div onClick={() => openModal()} id={`cancelAppointmentGridOpenModal`}></div>}
            renderContent={({ closeModal }) =>
                <CancelAppointmentConfirmation
                    setIsSubmitting={ setIsSubmitting }
                    chat_id={ chat_id }
                    onSuccess={ onSuccess }
                    closeModal={ closeModal }
                />
            }
        />
    )
}

CancelAppointmentGridModal.propTypes = {
    setIsSubmitting: PropTypes.func,
    chat_id: PropTypes.string,
    onSuccess: PropTypes.func
}