import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import PerPageSelect from "./per-page-select";
import PageSelect from "./page-select";
import QueryTableContext from "../context";

const TableFooter = ({ perPageOptions, usePagination, count, rowCount }) => {
  const {
    queryDispatcher,
    query: { pagination }
  } = useContext(QueryTableContext);
  const setPerPage = useCallback(
    per => {
      queryDispatcher({
        type: "updatePagination",
        per
      });
    },
    [queryDispatcher]
  );
  const setPage = useCallback(
    page => {
      queryDispatcher({
        type: "updatePagination",
        page
      });
    },
    [queryDispatcher]
  );
  return (
    <div className="query-footer">
      {usePagination && perPageOptions && (
        <PerPageSelect
          per={pagination.per}
          perPageOptions={perPageOptions}
          setPerPage={setPerPage}
        />
      )}
      {usePagination && (
        <PageSelect
          page={pagination.page}
          per={pagination.per}
          count={count}
          setPage={setPage}
        />
      )}
      {!usePagination ? (
        <div className="total-container">
          <span className="count-label">Count:</span>
          <span className="count-value">{rowCount}</span>
        </div>
      ) : (
        <div className="total-container">
          <span className="count-label">Total:</span>
          <span className="count-value">{count}</span>
        </div>
      )}
    </div>
  );
};

TableFooter.propTypes = {
  perPageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    })
  ),
  rowCount: PropTypes.number,
  count: PropTypes.number,
  usePagination: PropTypes.bool
};

TableFooter.defaultProps = {
  pagination: {}
};

export default React.memo(TableFooter);
