import React from "react";
import { PageHeader } from "components/ui";
import PatientForm from "components/patient-form";
import { createPatient } from "actions/patient";
import { useDispatch } from "react-redux";

const NewPatient = () => {
  const dispatch = useDispatch();
  return (
    <div className="page-form-page">
      <PageHeader title="Create New Patient" />
      <PatientForm handleSubmit={values => dispatch(createPatient(values))} />
    </div>
  );
};
export default NewPatient;
