import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetPassword } from "actions/authentication";
import { PASSWORD_REQUIREMENTS, PASSWORD_EXP } from "utils/password";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikField from "components/forms/formik-field";

const PasswordReset = ({ resetPassword, children }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const has_token = searchParams.has("token");

  return (
    <div id="reset_password_screen">
      {has_token ? (
        <div className="login_panel">
          <div>
            <h2>Please reset your password</h2>
          </div>
          <Formik
            initialValues={{
              password: "",
              password_confirmation: "",
              token: searchParams.get("token")
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required("Required")
                .matches(PASSWORD_EXP, PASSWORD_REQUIREMENTS),
              password_confirmation: Yup.string()
                .required("Required")
                .oneOf([Yup.ref("password")], "Passwords do not match")
            })}
            onSubmit={async values => {
              await resetPassword(values);
            }}
          >
            {() => (
              <Form acceptCharset="UTF-8">
                <FormikField name="password" label="Password" type="password" />
                <FormikField
                  name="password_confirmation"
                  label="Confirm Password"
                  type="password"
                />
                <button type="submit">Submit</button>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h2 style={{ color: "red" }}>Invalid Link</h2>
        </div>
      )}
      {children}
    </div>
  );
};

PasswordReset.propTypes = {
  resetFailure: PropTypes.bool,
  children: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  resetPassword: PropTypes.func.isRequired,
  isRequestingNewPassword: PropTypes.bool
};

export default connect(
  state => ({
    resetFailure: state.getIn(["fetch", "CREATE_NEW_PASSWORD", "didFail"])
    // isRequestingNewPassword: state.getIn([
    //   "fetch",
    //   "CREATE_NEW_PASSWORD",
    //   "isFetching"
    // ])
  }),
  {
    resetPassword
  }
)(PasswordReset);
