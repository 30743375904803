import React from "react";
import PropTypes from "prop-types";
import { compose, setPropTypes, withState, withHandlers } from "recompose";
import { reduxForm, getFormValues, change } from "redux-form";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as R from "ramda";
import DayPicker from "react-day-picker/DayPicker";
import moment from "moment";
import { message, errorMessage } from "actions/message";
import { VariableFieldSet, InlineWaiting } from "components/ui";
import { put } from "utils/api";
import MdInfo from "react-icons/lib/md/info";
import { getApiErrorMessage } from "utils/misc";
import ConfirmationModal from "components/shared/confirmation-modal";
import PureModal from "components/ui/modal/pure-modal";
import { selectors } from "reducers";

let companiesStageData = {};
let patientStages = {};
let editableModalMessagge = "";
let newCompanyPatientType = "";

const BaseSelect = withHandlers({
  handleChange:
    ({ options, input: { onChange } }) =>
      event => {
        if (onChange) {
          const {
            target: { value }
          } = event;
          const option = options.find(x => x.value == value);
          onChange(event, option);
        }
      }
})(({ options, handleChange, input: { value } }) => (
  <select onChange={handleChange} value={value}>
    <option value="" />
    {options.map(({ text, value }) => (
      <option key={value} value={value}>
        {text}
      </option>
    ))}
  </select>
));

const connectOptions = selector =>
  connect(state => ({ options: selector(state) }));

const InsuranceSelect = connectOptions(selectors.getFormOptionsInsurance)(
  BaseSelect
);

const CompanySelect = connectOptions(selectors.getFormOptionsCompany)(
  BaseSelect
);

const BranchSelect = connect(() => {
  const getOptions = createSelector(
    [selectors.getFormOptionsBranch, (_, p) => p.company],
    (branches, company) =>
      company
        ? R.filter(x => x.company == company, branches).filter(x => x.active)
        : branches
  );
  return (state, props) => ({
    options: getOptions(state, props)
  });
})(BaseSelect);

const FormOfContactSelect = connectOptions(state =>
  selectors.getFormOfContactOptions(state).filter(({ active }) => active)
)(BaseSelect);

const TherapistSelect = connectOptions(selectors.getFormOptionsTherapist)(
  BaseSelect
);

const SleepCoachSelect = connectOptions(
  selectors.getFormOptionsSleepCoachWithUserFirst
)(BaseSelect);

const ComplianceCoachSelect = connectOptions(
  selectors.getComplianceCoaches
)(BaseSelect);

const NewSetupRadio = compose(
  connectOptions(() => [
    { text: "No", value: false },
    { text: "Yes", value: true }
  ]),
  withHandlers({
    handleChange:
      ({ input: { onChange } }) =>
        ({ target: { value } }) => {
          onChange(value === "true"); /* to convert to boolean type */
        }
  })
)(({ options, input: { value: inputValue }, handleChange }) => (
  <div>
    {options.map(({ text, value }) => (
      <label key={value}>
        <input
          type="radio"
          value={value}
          checked={inputValue === value}
          onChange={handleChange}
        />
        {text}
      </label>
    ))}
  </div>
));

const CheckboxInput = ({ input: { value, onChange } }) => (
  <input type="checkbox" checked={value} onChange={onChange} />
);
CheckboxInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func
  }).isRequired
};

const DateInput = withHandlers({
  handleDayClick:
    ({ input: { onChange } }) =>
      (day, { selected }) => {
        onChange(selected ? undefined : day);
      }
})(({ input: { value }, handleDayClick }) => (
  <div>
    <div>{value ? moment(value).format("LL") : "Please select a day."}</div>
    <DayPicker
      showOutsideDays
      selectedDays={value || null}
      onDayClick={handleDayClick}
    />
  </div>
));

const ActiveGroupInput = compose(
  connect(state => ({
    inactivationReasons: selectors.getEnumInactivationReasons(state)
  })),
  withHandlers({
    handleActiveChange:
      ({ input: { onChange } }) =>
        ({ target: { checked } }) => {
          onChange({ active: checked, inactiveReasonId: "" });
        },
    handleReasonChange:
      ({
        input: {
          value: { active },
          onChange
        }
      }) =>
        ({ target: { value } }) => {
          onChange({ active, inactiveReasonId: value });
        }
  })
)(
  ({
    input: {
      value: { active }
    },
    handleActiveChange,
    handleReasonChange,
    inactivationReasons
  }) => (
    <div>
      <CheckboxInput
        input={{
          value: active,
          onChange: handleActiveChange
        }}
      />
      {!active && (
        <div>
          <div>Inactivation Reason</div>
          <select onChange={handleReasonChange}>
            <option />
            {inactivationReasons.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
);

const TypeAndStatus =
  withHandlers({
    handleTypeChange:
      ({
        input: {
          onChange,
        },
      }) =>
        ({ target: { value } }) => { onChange({ stage: value }) },
  })(({ input: { value: { stage } },
    handleTypeChange
  }) => {
    return (
      <div>
        <div className="variable-field-label-text">Select new patient Stage</div>
        <select
          style={{ margin: "0px", padding: "0px 0px 0px 5px", display: "flex", alignItems: "center" }}
          onChange={(e) => { handleTypeChange(e) }}
          name="stage"
          value={stage}
        >
          <option value={""}>Choose an option</option>
          <option value={"2"}>Compliance</option>
          <option value={"1"}>Resupply</option>
        </select>
      </div>
    )
  }
  );

const SpecialInstructionsInput = withHandlers({
  handleChange:
    ({ input: { onChange } }) =>
      event => {
        if (onChange) {
          const {
            target: { value }
          } = event;
          onChange(event, value);
        }
      }
})(({ handleChange, input: { value } }) => (
  <input onChange={handleChange} value={value} type="text" />
));

const CycleCountSelect = withHandlers({
  handleChange:
    ({ input: { onChange } }) =>
      event => {
        if (onChange) {
          const {
            target: { value }
          } = event;
          onChange(event, value);
        }
        console.log({ value: event.target.value, onChange });
      }
})(({ handleChange, input: { value } }) => (
  <select onChange={handleChange} value={value}>
    {[...Array(100).keys()].map(i => (
      <option key={i} value={i + 1}>
        {i + 1}
      </option>
    ))}
  </select>
));

const openModal = (currentPatientStage) => {
  if (currentPatientStage == "1") {
    editableModalMessagge = `Attention: The selected company does not manage Resupply. By choosing this option, stage will be changed to Compliance`;
    newCompanyPatientType = "2";
  } else if (currentPatientStage == "2") {
    editableModalMessagge = `Attention: The selected company does not manage Compliance. By choosing this option, stage will be changed to Resupply`;
    newCompanyPatientType = "1";
  } else {
    editableModalMessagge = `Attention: The selected company does not manage Compliance or Resupply. Please choose another company`;
    newCompanyPatientType = "0";
  }
  document.getElementById("companyChangeConfirmation").click();
}

const FIELD_OPTIONS = R.sortBy(R.prop("text"), [
  {
    field: "walkInOrder",
    text: "New Walk-In Order",
    fieldProps: {
      component: DateInput
    }
  },
  {
    field: "form_of_contact",
    text: "Form of Contact",
    fieldProps: {
      component: props => (
        <div className="field-container-split">
          <FormOfContactSelect {...props} />
          <div className="bulk-update-info">
            <MdInfo size={18} /> Will not change patients to email form of
            contact if they do not allow resupply emails.
          </div>
        </div>
      )
    }
  },
  {
    field: "can_email",
    text: "Allow Resupply Email",
    defaultValue: false,
    fieldProps: {
      component: props => (
        <div className="field-container-split">
          <CheckboxInput {...props} />
          {props.input.value === false /* eslint-disable-line */ && (
            <div className="bulk-update-info">
              <MdInfo size={18} /> Will change patients form of contact to
              manual call if current contact method is set to email
            </div>
          )}
        </div>
      )
    }
  },
  {
    field: "active",
    text: "Active",
    defaultValue: {
      active: false,
      inactivation_reason: ""
    },
    fieldProps: {
      component: ActiveGroupInput
    }
  },
  {
    field: "on_hold",
    text: "On Hold",
    defaultValue: false,
    fieldProps: {
      component: CheckboxInput
    }
  },
  {
    field: "delayed_activation_date",
    text: "Delayed Activation",
    fieldProps: {
      component: DateInput
    }
  },
  {
    field: "bulk_outreach_date",
    text: "Outreach Date",
    fieldProps: {
      component: DateInput
    }
  },
  {
    field: "outreach_cycle_from_order",
    text: "Outreach Cycle Count",
    defaultValue: "1",
    fieldProps: {
      component: CycleCountSelect //CycleCountInput
    }
  },
  {
    field: "primary_insurance",
    text: "Primary Insurance Type",
    fieldProps: {
      component: withHandlers({
        handleCompanyChange:
          ({ input: { onChange } }) =>
            ({ target: { value } }) => {
              onChange({
                insurance_company_id: value,
                primary_order: 1,
                policy_number: null,
                group_number: null,
                policy_start_date: null,
                policy_end_date: null
              });
            }
      })(
        ({
          input: {
            value: { primary_insurance }
          },
          handleCompanyChange
        }) => (
          <div>
            <InsuranceSelect
              input={{
                value: primary_insurance,
                onChange: handleCompanyChange
              }}
            />
          </div>
        )
      )
    }
  },
  {
    field: "company_branch",
    text: "Company",
    defaultValue: { company_id: "", branch_office_id: "" },
    fieldProps: {
      component: withHandlers({
        handleCompanyChange:
          ({ input: { onChange } }) =>
            ({ target: { value } }) => {
              if (value != "") {
                const stageSettings = companiesStageData.find(c => c.Company == value)
                if(!stageSettings.ManagesCompliance && !stageSettings.ManagesResupply){
                  openModal("0");
                }
                else if (patientStages.anyCompliancePatients && !stageSettings.ManagesCompliance) {
                  openModal("2");
                }
                else if (patientStages.anyResupplyPatients && !stageSettings.ManagesResupply) {
                  openModal("1");
                }
              }
              onChange({ company_id: value, branch_office_id: "" });
            },
        handleBranchChange:
          ({ input: { onChange } }) =>
            ({ target: { value } }, { company }) => {
              onChange({ company_id: company, branch_office_id: value });
            }
      })(
        ({
          input: {
            value: { company_id, branch_office_id }
          },
          handleCompanyChange,
          handleBranchChange
        }) => (
          <div>
            <CompanySelect
              input={{ value: company_id, onChange: handleCompanyChange }}
            />
            <div>Branch</div>
            <BranchSelect
              input={{ value: branch_office_id, onChange: handleBranchChange }}
              company={company_id}
            />
          </div>
        )
      )
    }
  },
  {
    field: "compliance_coach_id",
    text: "Compliance Coach",
    defaultValue: "",
    fieldProps: {
      component: ComplianceCoachSelect
    }
  },
  {
    field: "therapist_id",
    text: "Therapist",
    defaultValue: "",
    fieldProps: {
      component: TherapistSelect
    }
  },
  {
    field: "sleep_coach_id",
    text: "Sleep Coach",
    defaultValue: "",
    fieldProps: {
      component: SleepCoachSelect
    }
  },
  {
    field: "new_setup",
    text: "New Patient Setup?",
    defaultValue: false,
    fieldProps: {
      component: NewSetupRadio
    }
  },
  {
    field: "overrideEligibilityDate",
    text: "Override Eligibility Date",
    fieldProps: {
      component: CheckboxInput
    }
  },
  {
    field: "has_recalled_machine",
    text: "Has Recalled Machine",
    fieldProps: {
      component: CheckboxInput
    }
  },
  {
    field: "special_instructions",
    text: "Special Instructions",
    fieldProps: {
      component: SpecialInstructionsInput
    }
  },
  {
    field: "patient_stage",
    text: "Patient Stage",
    defaultValue: {
      patient_stage: null,
    },
    fieldProps: {
      component: TypeAndStatus
    }
  },
  {
    field: "send_resupply_email",
    text: "Send Resupply Emails",
    defaultValue: true,
    fieldProps: {
      component: CheckboxInput
    }
  },
  {
    field: "send_welcome_email",
    text: "Send Welcome Emails",
    defaultValue: true,
    fieldProps: {
      component: CheckboxInput
    }
  },
  {
    field: "send_request_text_email",
    text: "Send Request Text Consent Emails",
    defaultValue: true,
    fieldProps: {
      component: CheckboxInput
    }
  },
  {
    field: "send_request_text_text",
    text: "Send Request Text Consent Texts",
    defaultValue: true,
    fieldProps: {
      component: CheckboxInput
    }
  },
  {
    field: "remote_setup",
    text: "Remote Setup",
    defaultValue: false,
    fieldProps: {
      component: CheckboxInput
    }
  }
]);

let BulkPatientUpdate = ({
  handleBulkUpdateSubmit,
  isSubmitDisabled,
  isFetching,
  companiesData,
  patientsData,
  formValues,
  change
}) => {
  companiesStageData = companiesData;
  patientStages = patientsData;

  const setPatientStageBasedOnCompanySettings = () => {
    const newPatientType = { field: 'patient_stage', value: { stage: newCompanyPatientType } };
    formValues.patientFields.push(newPatientType);
  }

  const resetCompanyInput = () => {
    const companyBranchIndex = formValues.patientFields.findIndex(x => x.field == "company_branch");
    const patientStageIndex = formValues.patientFields.findIndex(x => x.field == "patient_stage");
    const newValue = { field: "company_branch", value: { company_id: "", branch_office_id: "" } };
    change('bulkPatientUpdate', `patientFields[${companyBranchIndex}]`, newValue);
    
    if(patientStageIndex){
      const patientStageNewValue = { field: 'patient_stage', value: { stage: '' } }
      change('bulkPatientUpdate', `patientFields[${patientStageIndex}]`, patientStageNewValue);
    }

  }

  return (
    <>
      <h3 style={{ margin: "10px" }}>Bulk Patient Update</h3>
      <form onSubmit={(e) => {
        e.preventDefault()
        handleBulkUpdateSubmit()
      }} className="bulk-patient-update">
        <VariableFieldSet name="patientFields" fieldOptions={FIELD_OPTIONS} />
        <div className="submit-container">
          <button type="submit" disabled={isSubmitDisabled || isFetching}>
            {isFetching ? <InlineWaiting /> : "Submit"}
          </button>
        </div>

        <PureModal
          renderTrigger={({ openModal }) => <div style={{ display: "none" }} onClick={() => openModal()} id="companyChangeConfirmation"></div>}
          renderContent={({ closeModal }) =>
            <ConfirmationModal
              title={editableModalMessagge}
              closeModal={closeModal}
              yesAction={() => { setPatientStageBasedOnCompanySettings(); closeModal(); }}
              notNowAction={() => { resetCompanyInput(); closeModal(); }}
              yesOptionText={"Confirm"}
              onlyShowOneButton={newCompanyPatientType == "0"}
            />
          }
          windowProps={{ closeable: false }}
        >
        </PureModal>
      </form>
    </>
  )
};

const form = "bulkPatientUpdate";
const getFormState = state => state.get("form");
BulkPatientUpdate = compose(
  setPropTypes({
    patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onUpdateSuccess: PropTypes.func,
    onUpdateError: PropTypes.func
  }),
  reduxForm({
    form,
    getFormState
  }),
  connect(
    state => {
      const formValues = getFormValues(form, getFormState)(state);
      return {
        isSubmitDisabled: formValues ? !formValues.patientFields.length : true,
        formValues: formValues
      };
    },
    {
      change,
      message,
      errorMessage
    }
  ),
  withState("isFetching", "setIsFetching", false),
  withHandlers({
    handleBulkUpdateSubmit: ({
      handleSubmit,
      patientIds,
      onUpdateSuccess,
      onUpdateError,
      message,
      errorMessage,
      setIsFetching
    }) =>
      handleSubmit(async ({ patientFields }) => {
        setIsFetching(true);
        try {
          await put("patients/bulk", {
            ids: patientIds,
            update: makeFieldDictionary(patientFields)
          });
          message("Patients updated successfully!");
          setIsFetching(false);
          if (onUpdateSuccess) {
            onUpdateSuccess();
          }
        } catch (error) {
          errorMessage(getApiErrorMessage(error));
          setIsFetching(false);
          if (onUpdateError) {
            onUpdateError(error);
          }
        }
      })
  })
)(BulkPatientUpdate);
BulkPatientUpdate.propTypes = {
  handleBulkUpdateSubmit: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  companiesData: PropTypes.array,
  patientsData: PropTypes.object, 
  formValues: PropTypes.object,
  change: PropTypes.func
};

const transformPatientField = ({ field, value }) => {
  // some form fields are uniquely formatted; handle these special
  // cases here
  switch (field) {
    case "active":
    case "company_branch":
      return value;
    default:
      return { [field]: value };
  }
};

const makeFieldDictionary = R.compose(R.mergeAll, R.map(transformPatientField));

export default BulkPatientUpdate;
