import { combineReducers } from "redux";
import { createSelector } from "reselect";
import uniq from "lodash/uniq";
import without from "lodash/without";
import omit from "lodash/omit";
import { createReducer } from "utils/reducer";
import { Types } from "./actions";
import { keys, pathOr } from "ramda";

// pagination reducer
const initPagination = (
  state,
  {
    payload: {
      query: { pagination }
    }
  }
) => pagination || state;
const updatePer = (_, { payload: { per } }) => ({ page: 1, per });
const updatePage = ({ per }, { payload: { page } }) => ({ page, per });
const resetPagination = state => state && { page: 1, per: state.per };
const pagination = createReducer(null, {
  [Types.INIT]: initPagination,
  [Types.UPDATE_PER]: updatePer,
  [Types.UPDATE_PAGE]: updatePage,
  // reset existing pagination if these actions occur
  [Types.UPDATE_SORT_BY]: resetPagination,
  [Types.TOGGLE_SORT_DIRECTION]: resetPagination,
  [Types.ADD_FILTER]: resetPagination,
  [Types.REMOVE_FILTER]: resetPagination,
  [Types.SET_FILTERS]: resetPagination,
  [Types.UPDATE_FILTERS]: resetPagination,
  [Types.CLEAR_FILTERS]: resetPagination,
  [Types.ADD_SEARCH]: resetPagination,
  [Types.REMOVE_SEARCH]: resetPagination
});

// sort reducer
const initSort = (
  state,
  {
    payload: {
      query: { sort }
    }
  }
) => ({ ...sort });
const updateSortBy = ({ by, direction }, { payload: { by: newBy } }) => ({
  by: newBy,
  direction: by !== newBy ? "asc" : direction
});
const toggleSortDirection = ({ by, direction }) => ({
  by,
  direction: direction === "asc" ? "desc" : "asc"
});
const sort = createReducer(null, {
  [Types.INIT]: initSort,
  [Types.UPDATE_SORT_BY]: updateSortBy,
  [Types.TOGGLE_SORT_DIRECTION]: toggleSortDirection
});

// filters reducer
const initFilters = (
  state,
  {
    payload: {
      query: { filters }
    }
  }
) => ({ ...filters });

const addFilter = (state, { payload: { by, value } }) => ({
  ...(state || {}),
  [by]: uniq([...((state && state[by]) || []), value])
});

const removeFilter = (state, { payload: { by, value } }) => ({
  ...(state || {}),
  [by]: without(state && state[by], value)
});

const setFilters = (state, { payload: { by, values } }) => ({
  ...(state || {}),
  [by]: values
});

const clearFilters = (state, { payload: { by } }) => omit(state, by);

const filters = createReducer(null, {
  [Types.INIT]: initFilters,
  [Types.ADD_FILTER]: addFilter,
  [Types.REMOVE_FILTER]: removeFilter,
  [Types.SET_FILTERS]: setFilters,
  [Types.UPDATE_FILTERS]: (state, { payload: { filters } }) => ({ ...filters }),
  [Types.CLEAR_FILTERS]: clearFilters
});

//searches reducer
const initSearches = (
  state,
  {
    payload: {
      query: { searches }
    }
  }
) => searches || null;

const addSearch = (state, { payload: { by, value } }) => ({
  ...(state || {}),
  [by]: value
});

const removeSearch = (state, { payload: { by } }) => omit(state, by);

const clearAllSearches = state => omit(state, "searches");

const searches = createReducer(null, {
  [Types.INIT]: initSearches,
  [Types.ADD_SEARCH]: addSearch,
  [Types.REMOVE_SEARCH]: removeSearch,
  [Types.CLEAR_ALL_SEARCHES]: clearAllSearches
});

// query root reducer
const queryReducer = combineReducers({
  pagination,
  sort,
  filters,
  searches
});

// delegates to query reducer by ID
const queryById = (state = {}, action) => {
  const { meta } = action;
  if (meta && meta.queryId) {
    return {
      ...state,
      [meta.queryId]: queryReducer(state[meta.queryId], action)
    };
  }
  return state;
};

// entityKey reducer
const entityKey = createReducer("", {
  [Types.REQUEST_DATA]: (_, { payload: { entityKey } }) => entityKey
});

// ids reducer
const storeIds = (state, { error, payload }) => (!error ? payload.ids : state);

const ids = createReducer([], {
  [Types.RESPONSE_DATA]: storeIds
});

// fetching reducer
const fetching = createReducer(false, {
  [Types.REQUEST_DATA]: () => true,
  [Types.RESPONSE_DATA]: () => false
});

// count reducer
const storeCount = (state, { error, payload }) =>
  !error ? payload.count : state;

const count = createReducer(0, {
  [Types.RESPONSE_DATA]: storeCount
});

// api root reducer
const apiReducer = combineReducers({
  entityKey,
  ids,
  fetching,
  count
});

// delegates to api reducer by ID
const apiById = (state = {}, action) => {
  const { meta } = action;
  if (meta && meta.apiId) {
    return {
      ...state,
      [meta.apiId]: apiReducer(state[meta.apiId], action)
    };
  }
  return state;
};

// root
const root = combineReducers({
  queryById,
  apiById
});

export default root;

// selectors
const getQuery = createSelector(
  [state => state.get("query").queryById, (_, queryId) => queryId],
  (queryState, queryId) => queryState[queryId]
);

const getQueryRecords = createSelector(
  [
    (state, apiId) => {
      const apiSlice = state.get("query").apiById[apiId];
      const entityKey = apiSlice && apiSlice.entityKey;
      return state.getIn(["entities", entityKey]);
    },
    (state, apiId) => {
      const apiSlice = state.get("query").apiById[apiId];
      const ids = apiSlice && apiSlice.ids;
      return ids;
    }
  ],
  (entities, ids) => {
    entities = entities ? entities.toJS() : {};
    const records = (ids || [])
      .map(id => entities[id])
      .filter(el => el !== undefined);
    return records;
  }
);
const getIsQueryFetching = createSelector(
  [state => state.get("query").apiById, (_, apiId) => apiId],
  (queryState, apiId) => pathOr(false, [apiId, "fetching"], queryState)
);

const getQueryCount = createSelector(
  [state => state.get("query").apiById, (_, apiId) => apiId],
  (queryState, apiId) => pathOr(0, [apiId, "count"], queryState)
);

export const selectors = {
  getQuery,
  getQueryRecords,
  getQueryFilters: createSelector(
    [state => state.get("query").queryById, (_, queryId) => queryId],
    (query, queryId) => pathOr({}, [queryId, "filters"], query)
  ),
  getQuerySearches: createSelector(
    [state => state.get("query").queryById, (_, queryId) => queryId],
    (query, queryId) => {
      const searches = pathOr({}, [queryId, "searches"], query);
      return keys(searches).map(by => ({ by, value: searches[by] }));
    }
  ),
  getIsQueryFetching,
  getQueryCount
};
