import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";

const ScDashboardTableSelect = ({ setPatientsToView, patientsToView }) => {
  return (
    <div className="sc-table-select-container">
      <div className="sc-table-radio-group">
        <label>
          <span
            className={patientsToView === "activity_center" ? "selected" : ""}
          >
            Activity Center
          </span>
          <input
            type="radio"
            value="activity_center"
            checked={patientsToView === "activity_center"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>

        <label>
          <span
            className={patientsToView === "eligible_patients" ? "selected" : ""}
          >
            Filterable Activity Center
          </span>
          <input
            type="radio"
            value="eligible_patients"
            checked={patientsToView === "eligible_patients"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>
        <label>
          <span
            className={patientsToView === "legacy_autodialer" ? "selected" : ""}
          >
            Legacy Autodialer
          </span>
          <input
            type="radio"
            value="legacy_autodialer"
            checked={patientsToView === "legacy_autodialer"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>
        <label>
          <span
            className={
              patientsToView === "patients_with_followup_orders"
                ? "selected"
                : ""
            }
          >
            Follow Up Orders
          </span>
          <input
            type="radio"
            value="patients_with_followup_orders"
            checked={patientsToView === "patients_with_followup_orders"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>
        <label>
          <span
            className={
              patientsToView === "patients_with_new_orders" ? "selected" : ""
            }
          >
            New Orders
          </span>
          <input
            type="radio"
            value="patients_with_new_orders"
            checked={patientsToView === "patients_with_new_orders"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>
        <label>
          <span
            className={
              patientsToView === "patients_with_backordered_orders"
                ? "selected"
                : ""
            }
          >
            Backordered Orders
          </span>
          <input
            type="radio"
            value="patients_with_backordered_orders"
            checked={patientsToView === "patients_with_backordered_orders"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>
      </div>
    </div>
  );
};

ScDashboardTableSelect.propTypes = {
  setPatientsToView: PropTypes.func.isRequired,
  patientsToView: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  sleepCoaches: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  sleepCoaches: selectors.getFormOptionsSleepCoachWithUserFirst(state),
  teams: selectors.getTeamNameOptions(state)
}))(ScDashboardTableSelect);
