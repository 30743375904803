import React from "react";
import PropTypes from "prop-types";
import SaveIcon from "react-icons/lib/md/save";
import CancelIcon from "react-icons/lib/md/cancel";
import { InlineWaiting } from "components/ui";
import IconButton from "components/ui/icon-button";
import { Formik, Field } from "formik";
import moment from "moment";

const CmnEditRow = React.memo(
  ({
    HCPC,
    Product,
    cmnInitialDate,
    cmnExpirationDate,
    Comments,
    updated,
    // updated_by,
    handleCancel,
    handleSave,
  }) => (
    <tr>
      <Formik
        initialValues={{
          HCPC,
          Product,
          cmnInitialDate: cmnInitialDate
            ? moment(cmnInitialDate).format("YYYY-MM-DD")
            : "",
          cmnExpirationDate: cmnExpirationDate
            ? moment(cmnExpirationDate).format("YYYY-MM-DD")
            : "",
          Comments,
        }}
        onSubmit={async (values) => {
          await handleSave(values);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <React.Fragment>
            <td>
              <Field name="HCPC" type="text" />
            </td>
            <td>
              <Field name="Product" type="text" />
            </td>
            <td>
              <Field name="cmnInitialDate" type="date" />
            </td>
            <td>
              <Field name="cmnExpirationDate" type="date" />
            </td>
            <td>
              <Field name="Comments" component="textarea" rows="1" />
            </td>
            <td>
              <div>{moment(updated).format("L")}</div>
              {/*<div>{updated_by}</div>*/}
            </td>
            <td
              style={{
                minWidth: 120,
              }}
            >
              <div style={{ float: "right" }}>
                {isSubmitting ? (
                  <InlineWaiting />
                ) : (
                  <IconButton
                    text="Save"
                    onClick={handleSubmit}
                    style={{ color: "gren", padding: 3, marginRight: 10 }}
                    icon={<SaveIcon />}
                    type="submit"
                  />
                )}
                <IconButton
                  text="Cancel"
                  onClick={handleCancel}
                  style={{ color: "#e8910c", padding: 3 }}
                  icon={<CancelIcon />}
                  type="button"
                />
              </div>
            </td>
          </React.Fragment>
        )}
      </Formik>
    </tr>
  )
);

CmnEditRow.propTypes = {
  Comments: PropTypes.string,
  HCPC: PropTypes.string,
  Product: PropTypes.string,
  cmnInitialDate: PropTypes.string,
  cmnExpirationDate: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  updated: PropTypes.string,
  updated_by: PropTypes.string,
};

export default CmnEditRow;
