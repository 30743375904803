import {
  take,
  fork,
  put
} from 'redux-saga/effects'
import isEqual from 'lodash/isEqual'
import {
  Types,
  invalidateLineItems,
  invalidateOrderInfo
} from 'actions/order-profile'

export default function* watchTrackingUpdateRequest() {
  const tasks = {}
  while (true) {
    const action = yield take(Types.REQUEST_TRACKING_UPDATE)
    const { meta: { lineItemId } } = action
    const currTask = tasks[lineItemId]
    if (!currTask || !currTask.isRunning()) {
      tasks[lineItemId] = yield fork(updateTracking, action)
    }
  }
}

function* updateTracking({
  payload: { update },
  meta: {
    orderId,
    lineItemIds
  }
}) {
  yield put({
    type: Types.API_TRACKING_UPDATE,
    payload: {
      apiRequest: {
        method: 'POST',
        route: `orders/${orderId}/add_shipping_details`,
        params: lineItemIds.map(line_item_id => ({
          line_item_id,
          ...update
        })),
        type: Types.RESPONSE_TRACKING_UPDATE,
        meta: { orderId, lineItemIds }
      }
    },
    meta: { orderId, lineItemIds }
  })
  let keepTaking = true
  while (keepTaking) {
    const { meta: {
      orderId: responseOrder,
      lineItemIds: responseLine
    } } = yield take(Types.RESPONSE_TRACKING_UPDATE)
    if (orderId === responseOrder && isEqual(lineItemIds, responseLine)) {
      yield put(invalidateLineItems({ orderId })())
      yield put(invalidateOrderInfo({ orderId })())
      keepTaking = false
    }
  }
}
