import React from "react";
import PropTypes from "prop-types";
import TableHeaderColumn from "./table-header-column";

const TableHeader = ({ columns }) => {
  return (
    <thead>
      <tr className="query-table-header">
        {columns.map(
          ({
            Cell,
            dropdownPosition,
            field,
            filterKey,
            filters,
            filterDownSelect,
            header,
            headerClassName,
            style,
            SubFilter,
            title,
          }) =>
            Cell ? (
              <Cell key={field} />
            ) : (
              <TableHeaderColumn
                key={field}
                field={field}
                header={header}
                filterKey={filterKey ? filterKey : field}
                filters={filters}
                style={style}
                title={title}
                headerClassName={headerClassName}
                filterDownSelect={filterDownSelect}
                SubFilter={SubFilter}
                dropdownPosition={dropdownPosition}
              />
            )
        )}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object)
};

export default TableHeader;
