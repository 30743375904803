import React, { useEffect, useState } from "react";
import DropdownComplianceBarChartFilter from "components/ui/dropdown/dropdown-compliance-bar-chart-filter";
import { IconButton } from "components/ui";
import MdFilterList from "react-icons/lib/md/filter-list";
import FaFilter from "react-icons/lib/fa/filter";
import { clone, filter } from "ramda";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import { IoAlertCircled, } from "react-icons/lib/io";
import PropTypes from "prop-types";
import moment from "moment";

export default function ComplianceDashboardChartsFilter({ items, category, categoryName, filterFunction, startDate, endDate, companyId, changeUsingIndividualInfo, usingIndividualInfo, isFetchingMetricsData }) {

    let defaultValue = {
        Category: category,
        QueryLimit: -1,
        SelectedGroups: [],
        CompanyId: companyId,
        DateFrom: startDate,
        DateTo: endDate
    };

    const [filterSearchValue, setFilterSearchValue] = useState("");
    const [selectedFilter, setSelectedFilter] = useState(defaultValue);
    const [checkedState, setCheckedState] = useState(new Array(items.length).fill(false));

    useEffect(() => {
        setSelectedFilter({
            ...selectedFilter,
            CompanyId: companyId,
            DateFrom: startDate,
            DateTo: endDate
        })
    }, [startDate, endDate, companyId])


    useEffect(() => {
        if (!usingIndividualInfo) {
            resetFilter()
        }
    }, [usingIndividualInfo])


    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    }

    const handleFilterChange = (value, queryLimitValue) => {
        const currentFilter = clone(selectedFilter);
        currentFilter.QueryLimit = value && currentFilter.QueryLimit != queryLimitValue ? parseInt(queryLimitValue) : -1;
        setSelectedFilter(currentFilter);
    }

    const resetFilter = () => {
        setSelectedFilter(defaultValue);
    }

    const handleFilterSelectionChanged = (key, selected) => {
        const currentFilter = clone(selectedFilter);
        let currentSelectedGroups = currentFilter.SelectedGroups ? clone(currentFilter.SelectedGroups) : [];

        if (currentFilter.QueryLimit === 3) {
            const exists = currentFilter.SelectedGroups && currentFilter.SelectedGroups.find((item) => {
                if (key === null) {
                    return item == key
                } else {
                    return item == parseInt(key)
                }
            });
            if (selected && !exists) {
                currentSelectedGroups.push(key);
            }
            else if (!selected && exists === key) {
                currentSelectedGroups = currentFilter.SelectedGroups.filter(item => item !== key);
            }
        }

        const newFilter = {
            Category: currentFilter.Category,
            QueryLimit: 3,
            SelectedGroups: currentSelectedGroups ? currentSelectedGroups : [],
            CompanyId: companyId,
            DateFrom: startDate,
            DateTo: endDate
        };

        setSelectedFilter(newFilter);
    }

    const handleFilterSelectionChangedFromLabel = (key, elementId) => {
        const elementChecked = !document.getElementById(elementId).checked;
        handleFilterSelectionChanged(key, elementChecked)
    }

    function downSelectFilters({ text }) {
        return filterSearchValue.length > 0
            ? text.toLowerCase().includes(filterSearchValue.trim().toLowerCase())
            : true;
    }

    const getClassname = () => {
        if (!items || items.length <= 0)
            return 'dropdown-command-disabled';

        return selectedFilter.QueryLimit === 3 ? 'dropdown-command active' : 'dropdown-command';
    }

    return (
        <div>
            <DropdownComplianceBarChartFilter
                trigger={
                    <IconButton
                        icon={<MdFilterList />}
                        className="button secondary icon-button column-filter-trigger"
                        disabled={isFetchingMetricsData}
                    />
                }
                onClose={() => {
                    if (selectedFilter.QueryLimit !== -1) {
                        if (selectedFilter.QueryLimit === 3 && selectedFilter.SelectedGroups?.length === 0) {
                            return
                        } else {
                            filterFunction(selectedFilter)
                        }
                    }
                }}
                disableTrigger={isFetchingMetricsData}
            >
                <ul className="dropdown-list">
                    <li
                        className="dropdown-command"
                        onClick={() => {
                            resetFilter()
                            changeUsingIndividualInfo(false)
                        }}
                    >
                        Clear Selections
                    </li>
                    <li key="0" className={selectedFilter.QueryLimit === 0 ? "dropdown-command active" : "dropdown-command "} onClick={() => handleFilterChange(true, "0")}> Top 5 </li>
                    <li key="1" className={selectedFilter.QueryLimit === 1 ? "dropdown-command active" : "dropdown-command "} onClick={() => handleFilterChange(true, "1")}> Top 10 </li>
                    <li key="2" className={selectedFilter.QueryLimit === 2 ? "dropdown-command active" : "dropdown-command "} onClick={() => handleFilterChange(true, "2")}> All </li>
                    <li key="3" className={getClassname()} onClick={() => items.length > 0 ? handleFilterChange(true, "3") : null} >
                        {categoryName}
                        {
                            (!items || items.length <= 0) &&
                            <Tooltip
                                id='single-stat-tooltip-compliance-dashboard'
                                className="single-stat-tooltip"
                                effect="solid"
                                message='No elements were found for this chart.'
                            >
                                <IoAlertCircled size="1rem" style={{ color: 'red' }} />
                            </Tooltip>
                        }
                    </li>
                    {
                        selectedFilter.QueryLimit === 3 &&
                        <li className="filter-downselect-input">
                            <FaFilter />
                            <input value={filterSearchValue} onChange={({ target }) => setFilterSearchValue(target.value)} />
                        </li>
                    }
                    {
                        selectedFilter.QueryLimit === 3 && items && filter(downSelectFilters, items).map(({ value, text, elementId }, index) => (
                            <li
                                key={value}
                                className="dropdown-item"
                                onClick={() => {
                                    handleFilterSelectionChangedFromLabel(value, elementId);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    id={elementId}
                                    onChange={e => {
                                        handleFilterSelectionChanged(value, e.target.checked);
                                        handleOnChange(index)
                                    }}
                                    checked={checkedState[index] || selectedFilter.SelectedGroups.find((item) => { return item === value }) === value}
                                />
                                <label>{text}</label>
                            </li>
                        ))
                    }
                </ul>
            </DropdownComplianceBarChartFilter>
        </div>
    )
}
ComplianceDashboardChartsFilter.propTypes = {
    items: PropTypes.array,
    category: PropTypes.number,
    categoryName: PropTypes.string,
    filterFunction: PropTypes.func,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.date, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.date, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
    companyId: PropTypes.string,
    changeUsingIndividualInfo: PropTypes.func,
    usingIndividualInfo: PropTypes.bool,
    isFetchingMetricsData: PropTypes.bool
}