import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";

export class StatesSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      united_states: [
        "AL",
        "AA", 
        "AE", 
        "AP", 
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ]
    };
  }

  render() {
    const { value, onChange, error, name, label, disabled } = this.props;
    // let wrapperClass = "form_group";
    // if (error && error[name]) {
    //   wrapperClass += "_has_error";
    // } else {
    //   wrapperClass = "form_group";
    // }
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <div>
          <select
            name={name ? name : "state"}
            className="address-input"
            onChange={e => onChange(e, { state: e.target.value })}
            disabled={disabled}
            value={value}
          >
            <option value="" />
            {this.state.united_states.map((state, i) => (
              <option key={i} value={state}>{state}</option>
            ))}
          </select>
          {error &&
            error[name] &&
            <div className="alert alert-danger">
              {error[name]}
            </div>}
        </div>
      </div>
    );
  }
}

export default Radium(StatesSelect);
StatesSelect.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object
};

StatesSelect.defaultProps = {
  disabled: false,
}
