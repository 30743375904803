import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "features/query/reducers";
import { removeSearch } from "features/query/actions";
import MdRemove from "react-icons/lib/md/clear";
import { IconButton } from "components/ui";
import { deSnakeCase } from "utils/misc";
// import { browserHistory } from "browser-history";

const PatientTableSearches = ({ searches, removeSearch, queryId }) => (
  <div style={{ flex: 1, justContent: "center" }}>
    {searches.map(({ by, value }) => (
      <span
        key={by}
        style={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto auto auto",
          gridGap: 5,
          fontSize: "1.45em"
        }}
      >
        <IconButton
          onClick={() => {
            removeSearch(queryId, by);
            // browserHistory.push({
            //   pathname: "/patients",
            //   state: { status: "" }
            // });
          }}
          icon={<MdRemove color="red" />}
        />
        <span>
          Where
          <span style={{ fontWeight: 600, padding: "0 5px" }}>
            {deSnakeCase(by)}
          </span>
          Is
        </span>
        <span style={{ color: "#4281a6" }}> {value} </span>
      </span>
    ))}
  </div>
);

PatientTableSearches.propTypes = {
  queryId: PropTypes.string.isRequired,
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      by: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  removeSearch: PropTypes.func
};

export default connect(
  (state, { queryId }) => ({
    searches: selectors.getQuerySearches(state, queryId)
  }),
  { removeSearch: (queryId, by) => removeSearch(queryId)(by) }
)(PatientTableSearches);
