import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ListItem, Waiting, Checkbox } from "components/ui";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { formatPhone } from "utils/misc";
import { formatDate } from "utils/dates";
import CompanyBranches from "./company-branches";
import CompanyOverview from "./company-overview";
import HasRolePermission from "components/shared/has-role-permission";
import CompanyInfoSheets from "features/google-api/components/company-info-sheets";
import CompanyIVRDetails from "./ivr-details";
import { isEmpty } from "ramda";

class CompanyMisc extends PureComponent {
  getFulfillmentCompanies() {
    const { company } = this.props;

    const companyProperties = Object.getOwnPropertyNames(company);
    const activeFulfillmentList = companyProperties?.filter(
      x =>
        x !== "uses_fulfillment" &&
        x.includes("uses_") &&
        x.includes("_fulfillment") &&
        company[x]
    );
    const removedUses = activeFulfillmentList?.map(x => x.replace("uses_", ""));
    const removedFulfillment = removedUses?.map(x =>
      x.replace("_fulfillment", "")
    );
    return removedFulfillment?.map(x => x.toUpperCase());
  }

  render() {
    const { company } = this.props;
    const fulfillmentCompanies = this.getFulfillmentCompanies();

    return isEmpty(company) ? (
      <Waiting reason="Fetching Company..." />
    ) : (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            gridGap: "20px"
          }}
        >
          <div>
            <div>
              <ListItem
                label="Name"
                value={company.company_name}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Nonfulfillment Account#"
                value={company.account_number}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Phone #"
                value={formatPhone(company.outbound_phone_number)}
                style={{ padding: 0 }}
              />
              <ListItem label="Address" style={{ padding: 0 }} />
              <ListItem
                label="Street"
                subitem
                value={company.address?.street_address}
                style={{ padding: 0 }}
              />
              <ListItem
                label="City"
                subitem
                value={company.address?.city}
                style={{ padding: 0 }}
              />
              <ListItem
                label="State"
                subitem
                value={company.address?.state}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Zip"
                subitem
                value={company.address?.zip}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Team"
                value={
                  company.team_name === "Not Assigned" ? (
                    <span style={{ color: "red" }}>{company.team_name}</span>
                  ) : (
                    company.team_name
                  )
                }
                style={{ padding: 0 }}
              />
              <ListItem
                label="Active"
                value={<Checkbox value={company.company_active} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Review Orders"
                value={<Checkbox value={company.order_review} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Fulfillment Companies"
                value={fulfillmentCompanies.join(", ")}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Includes Pricing"
                value={<Checkbox value={company.include_pricing} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Purchases From S3"
                value={<Checkbox value={company.s3_middleman} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Tracks Compliance"
                value={<Checkbox value={company.tracks_compliance} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Order Loading"
                value={<Checkbox value={company.order_loading} />}
                style={{ padding: 0 }}
              />
              {company.order_loading && (
                <ListItem
                  label="Email"
                  subitem
                  value={<Checkbox value={company.order_loading_email} />}
                  style={{ padding: 0 }}
                />
              )}
              {company.order_loading && (
                <ListItem
                  label="IVR"
                  subitem
                  value={<Checkbox value={company.order_loading_IVR} />}
                  style={{ padding: 0 }}
                />
              )}
              {company.order_loading && (
                <ListItem
                  label="Mobile App"
                  subitem
                  value={<Checkbox value={company.order_loading_mobile_app} />}
                  style={{ padding: 0 }}
                />
              )}
              {company.order_loading && (
                <ListItem
                  label="Patient Portal"
                  subitem
                  value={
                    <Checkbox value={company.order_loading_patient_portal} />
                  }
                  style={{ padding: 0 }}
                />
              )}
              {company.order_loading && (
                <ListItem
                  label="Web App"
                  subitem
                  value={<Checkbox value={company.order_loading_web_app} />}
                  style={{ padding: 0 }}
                />
              )}

              <ListItem
                label="Handles Orders"
                value={<Checkbox value={company.company_handles_orders} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Allows Contracted Viewing"
                value={<Checkbox value={company.allows_contracted_viewing} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Records Phone Calls"
                value={<Checkbox value={company.settings.bRecordsCalls} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Uses Fulfillment"
                value={<Checkbox value={company.uses_fulfillment} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Uses Address Change Task"
                value={<Checkbox value={company.uses_address_change_task} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Uses Insurance Change Task"
                value={<Checkbox value={company.uses_insurance_change_task} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Uses Physician Change Task"
                value={<Checkbox value={company.uses_physician_change_task} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Autofill Order Line Items"
                value={<Checkbox value={company.autofill_order_line_items} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="Review Autofit Results"
                value={<Checkbox value={company.review_autofit_results} />}
                style={{ padding: 0 }}
              />
              <ListItem
                label="IVR Company"
                value={<Checkbox value={company.ivr_company} />}
                style={{ padding: 0 }}
              />
              {company.ivr_company && (
                <>
                  <ListItem
                    label="IVR Phone #"
                    subitem
                    value={formatPhone(company.ivr_outbound_phone_number)}
                    style={{ padding: 0 }}
                  />
                  <ListItem
                    label="IVR Daily Order goal"
                    subitem
                    value={company.ivr_order_goal}
                    style={{ padding: 0 }}
                  />

                  <ListItem
                    label="IVR Schedule"
                    subitem
                    value={`${company.ivr_start} - ${company.ivr_end}`}
                    style={{ padding: 0 }}
                  />
                  <ListItem
                    label="Uses Branch Names"
                    subitem
                    value={<Checkbox value={company.reads_branch_names} />}
                    style={{ padding: 0 }}
                  />
                </>
              )}
              <HasRolePermission allowedRoles={["Administrator"]}>
                <ListItem
                  label="Contractual Text Consent"
                  value={<Checkbox value={company.AutoTextConsent} />}
                  style={{ padding: 0 }}
                />
                <ListItem
                  label="Philips Recall Prompt"
                  value={<Checkbox value={company.phillips_recall_prompt} />}
                  style={{ padding: 0 }}
                />
                <ListItem
                  label="Service Start Date"
                  value={formatDate(company.service_start_date)}
                  style={{ padding: 0 }}
                />
                <ListItem
                  label="Outreach Schedule (CST)"
                  value={`${company.outreach_start_time} - ${company.outreach_end_time}`}
                  style={{ padding: 0 }}
                />
              </HasRolePermission>
              {company.patient_type_settings.map((x, idx) => {
                return (
                  <ListItem
                    label={`Manages ${x.sPatientTypeLabel}`}//"Manages Compliance"
                    value={<Checkbox value={x.bActive} />}
                    style={{ padding: 0 }}
                    key={idx}
                  />
                )
              })}
              {/* <ListItem */}
              {/*   label="Last Billed Date" */}
              {/*   value={formatDate(company.last_billed_date)} */}
              {/*   style={{ padding: 0 }} */}
              {/*   underline */}
              {/* /> */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10
              }}
            >
              <HasRolePermission
                // allowedRoles={["Administrator", "ServiceAdmin", "ContractorAdministrator"]}
                allowedRoles={["Administrator", "ServiceAdmin"]}
              >
                <Link to={`/companies/${company.id}/edit`}>
                  <button className="button tiny secondary">Edit</button>
                </Link>
              </HasRolePermission>
              <HasRolePermission allowedRoles={["Administrator"]}>
                <Link to={`/companies/${company.id}/email-template`}>
                  <button
                    className="button tiny secondary"
                    style={{ marginLeft: "10px" }}
                  >
                    Edit Email Template
                  </button>
                </Link>
                <Link to={`/companies/${company.id}/logs`}>
                  <button
                    className="button tiny secondary"
                    style={{ marginLeft: "10px" }}
                  >
                    Logs
                  </button>
                </Link>
              </HasRolePermission>
              {company.ivr_company && <CompanyIVRDetails company={company} />}
            </div>
          </div>
          <CompanyBranches company={company} />
        </div>
        <CompanyOverview company={company} />
        <HasRolePermission allowedRoles={["Administrator", "SleepCoach"]}>
          <CompanyInfoSheets company={company} />
        </HasRolePermission>
      </div>
    );
  }
}

export default connect(state => ({
  company: selectors.getCompanyProfile(state)
}))(CompanyMisc);

CompanyMisc.propTypes = {
  params: PropTypes.object,
  company: PropTypes.object.isRequired
};
CompanyMisc.contextTypes = {
  router: PropTypes.object
};
