import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestExport } from '../actions'

const withExport = ({ export: config = {} } = {}) => connect(null, (dispatch, props) => {
  const {
    method = 'PUT',
    route
  } = { ...config, ...props.export }
  return bindActionCreators({
    requestExport: route && requestExport(props.query, method, route)
  }, dispatch)
})

export default withExport
