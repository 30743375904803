import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as R from "ramda";

const getMessageType = (author, userId) => {
  if (!author) return "log";
  return userId === author ? "my-msg" : "participant-msg";
};
const Message = ({ author, body, userId, attributes, timestamp }) => {
  const messageType = getMessageType(author, userId);
  if (messageType === "log")
    return (
      <div className="message log">
        <div className="msg-body">{body}</div>
      </div>
    );
  return (
    <div className={`message ${messageType}`}>
      <div className="msg-details">
        <div className="msg-timestamp">
          {moment(timestamp).format("hh:mm a ")}
        </div>
        <div className="divider">-</div>
        <div className="msg-author">{R.prop("authorName", attributes)}</div>
      </div>
      <div className="msg-body">{body}</div>
    </div>
  );
};

export default Message;

Message.propTypes = {
  author: PropTypes.string,
  body: PropTypes.string.isRequired,
  userId: PropTypes.string,
  attributes: PropTypes.object,
  timestamp: PropTypes.instanceOf(Date)
};
