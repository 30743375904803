import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { resetPhilipsRecall } from "actions/patient";
import { AttributeItem } from "components/lists";
import { StatusToggle } from "components/ui";

const PhilipResetToggle = ({ patientId, patientInfo, readOnly }) => {
  const [isResetting, setIsResetting] = React.useState(false);
  const dispatch = useDispatch();
  async function resetPhilipsWorkflow() {
    if (confirm("Are your sure you want to reset the Philips workflow")) {
      try {
        setIsResetting(true);
        await dispatch(resetPhilipsRecall(patientId, true));
      } finally {
        setIsResetting(false);
      }
    }
  }
  return (
    <AttributeItem title="Philips Recall Reset:">
      <StatusToggle
        value={patientInfo?.philips_recall?.recallStatus}
        isUpdating={isResetting}
        toggleStatus={resetPhilipsWorkflow}
        disabled={readOnly}
      />
    </AttributeItem>
  );
};

PhilipResetToggle.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object,
  readOnly: PropTypes.bool
};

export default PhilipResetToggle;
