import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import SelectDropdown from "components/forms/select-dropdown";

const SleepCoachSelect = ({ sleepCoaches, value, onChange }) => (
  <SelectDropdown options={sleepCoaches} value={value} onChange={onChange} />
);

export default connect(state => ({
  sleepCoaches: selectors.getFormOptionsSleepCoachWithUserFirst(state)
}))(SleepCoachSelect);

SleepCoachSelect.propTypes = {
  sleepCoaches: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    }) 
  ),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
 