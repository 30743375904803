import React from "react";
import PropTypes from "prop-types";

const CircularProgressBar = ({
  size,
  strokeWidth,
  percentage,
  circleText,
  color
}) => {
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const circumference = radius * Math.PI * 2;
  const offset = circumference - (circumference * percentage) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
      />
      <circle
        className="circle-progress"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: offset,
          stroke: color
        }}
      />
      <text
        className="circle-text"
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        style={{ fill: color, fontSize: size * 0.35 }}
      >
        {circleText ? circleText : percentage}
      </text>
    </svg>
  );
};

CircularProgressBar.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  percentage: PropTypes.number,
  circleText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string
};

CircularProgressBar.defaultProps = {
  size: 100,
  percentage: 25,
  strokeWidth: 10,
  color: "#25a8e0"
};

export default CircularProgressBar;
