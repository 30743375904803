import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const CompanyPatients = ({ data, minimizeAll }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "CompanyName", ascending: true });
  const [isMinimized, setMinimized] = useState(false);
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  return (
    <div className="block patients">
      <div className="block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Patients
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <table>
            <TableSortableHeader
              columns={[
                { key: "CompanyName", text: "Company" },
                { key: "eligible_for_supplies", text: "Eligible" },
              ]}
              sortBy={sortBy}
              ascending={ascending}
              setSortBy={setSortBy}
              toggleSortDirection={toggleSortDirection}
            />
            <tbody>
              {getSortedArray(data).map(d => (
                <tr key={d.CompanyName}>
                  <td>{d.CompanyName}</td>
                  <td>{d.eligible_for_supplies}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

CompanyPatients.defaultProps = {
  data: []
};
CompanyPatients.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool
};

export default connect(state => ({
  data: selectors.getTeamPatientInsights(state)
}))(CompanyPatients);
