// import React from "react";
import * as dd_newModule from "components/dd_newModules/subModule";
import AssignQuestionnaire from "components/questionnaires/AssignQuestionnaire";

// TODO delete deprecated components/routes
export default [
  {
    path: "/textchat/:id",
    component: dd_newModule.TextChat,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/productdemo/:id",
    component: dd_newModule.ProductDemo,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/videoChat/:id",
    component: dd_newModule.VideoChat,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/videoLibrary",
    component: dd_newModule.VideoLink,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/DocumentCategory",
    component: dd_newModule.DocumentCategory,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/FAQs",
    component: dd_newModule.FAQs,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/Questionnaire",
    component: dd_newModule.Questionnaire,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/AsNeededArticles",
    component: dd_newModule.Articles,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/RecurringArticles",
    component: dd_newModule.RecurringArticles,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/PatientQuestionnaire",
    component: dd_newModule.PatientQuestionnaire,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/AssignQuestionnaire",
    component: AssignQuestionnaire,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ]
  }
];
