import React from "react";
import PropTypes from "prop-types";
// import HasRolePermission from "components/shared/has-role-permission";
import { Link, useParams } from "react-router-dom";
import { Checkbox } from "components/ui";
import { RowSelectCell } from "hooks/useRowSelect";

const TableRow = React.memo(
  ({
    xref_id,
    product_id,
    name,
    hcpcs,
    product_type,
    manufacturer,
    avgReimbursment,
    active,
    // autofit_eligible,
    backordered,
    company_product_id
  }) => {
    const params = useParams();
    return (
      <tr>
        <RowSelectCell id={xref_id} />
        <td>
          <Link to={`/companies/${params.id}/profile/inventory/${xref_id}`}>
            {name}
          </Link>
        </td>
        <td>{product_id}</td>
        <td>{company_product_id}</td>
        <td>{hcpcs}</td>
        <td>{product_type}</td>
        <td>{manufacturer}</td>
        <td>${avgReimbursment}</td>
        <td>
          <Checkbox value={active} />
          {/* <HasRolePermission */}
          {/*   allowedRoles={[ */}
          {/*     "Administrator", */}
          {/*     "ServiceAdmin", */}
          {/*     "CompanyAdministrator", */}
          {/*     "OfficeAdministrator", */}
          {/*     "ServiceCoach" */}
          {/*   ]} */}
          {/*   renderElse={<Checkbox value={active} />} */}
          {/* > */}
          {/* </HasRolePermission> */}
        </td>
        <td>
          <Checkbox value={backordered} />
          {/* <HasRolePermission */}
          {/*   allowedRoles={[ */}
          {/*     "Administrator", */}
          {/*     "ServiceAdmin", */}
          {/*     "CompanyAdministrator", */}
          {/*     "OfficeAdministrator", */}
          {/*     "ServiceCoach" */}
          {/*   ]} */}
          {/*   renderElse={<Checkbox value={backordered} />} */}
          {/* > */}
          {/*   <input */}
          {/*     type="checkbox" */}
          {/*     checked={backordered} */}
          {/*     onChange={({ target }) => */}
          {/*       onChange({ */}
          {/*         backordered: target.checked, */}
          {/*  */}
          {/*         company_inventory_item_guid: xref_id, */}
          {/*         active, */}
          {/*         autofit_eligible, */}
          {/*         company_product_id */}
          {/*       }) */}
          {/*     } */}
          {/*   /> */}
          {/* </HasRolePermission> */}
        </td>
      </tr>
    );
  }
);

export default TableRow;

TableRow.propTypes = {
  id: PropTypes.string.isRequired,
  product_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hcpcs: PropTypes.string.isRequired,
  product_type: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  avgReimbursment: PropTypes.number.isRequired,
  productActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  active: PropTypes.bool,
  xref_id: PropTypes.string,
  autofit_eligible: PropTypes.bool,
  backordered: PropTypes.bool,
  company_product_id: PropTypes.string
};
