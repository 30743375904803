import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import VoicemailControls from "./voicemail-controls";
import PatientPhoneLookup from "./patient-phone-lookup";
import PatientProfile from "components/screens/patient/patient-profile";
import { useFetch } from "hooks";
import { get, put } from "utils/api";

function VoicemailProfile({ id }) {
  const params = useParams();
  const vmailId = params?.id ?? id;
  const {
    response: vmail,
    // isFetching,
    fetchData
  } = useFetch({
    apiFn: vmailId => get(`voicemails/${vmailId}`),
    clearDataOnFetch: false
  });
  const markAsListenedAPI = useFetch({
    apiFn: ({ id, userId }) =>
      put(`voicemails/${id}/listened`, { id: userId, listenedTo: true }),
    onSuccess: ({ id }) => {
      fetchData(id);
    }
  });
  const markAsWorkedAPI = useFetch({
    apiFn: ({ id, userId }) =>
      put(`voicemails/${id}`, { id: userId, worked: true }),
    onSuccess: ({ id }) => {
      fetchData(id);
    }
  });
  const [selectedPatient, setSelectedPatient] = React.useState("");
  React.useEffect(() => {
    fetchData(vmailId);
    setSelectedPatient("");
  }, [vmailId]);
  return (
    <div className="voicemail-profile">
      <div>
        <VoicemailControls
          vmail={vmail}
          markAsListenedAPI={markAsListenedAPI}
          markAsWorkedAPI={markAsWorkedAPI}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            padding: 5,
            flex: 1,
            alignItems: "center"
          }}
        >
          <h5>Patient Lookup</h5>
          {selectedPatient && (
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <button
                style={{ margin: 0, padding: "5px 10px" }}
                onClick={() => {
                  setSelectedPatient("");
                }}
              >
                Go Back
              </button>
            </div>
          )}
        </div>
        {!vmail ? (
          <div />
        ) : !selectedPatient ? (
          <PatientPhoneLookup
            initialValues={{
              to_number: vmail?.to_number,
              from_number: vmail?.from_number
            }}
            setSelectedPatient={setSelectedPatient}
          />
        ) : (
          <div style={{ position: "relative" }}>
            <PatientProfile patientId={selectedPatient} />
          </div>
        )}
      </div>
    </div>
  );
}

VoicemailProfile.defaultProps = {};

VoicemailProfile.propTypes = {
  id: PropTypes.string
};

export default VoicemailProfile;
