import React, { useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { selectors } from "reducers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  updateTeamAllocation,
  requestTeamInsights
} from "actions/team-dashboard";
import { useSort } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import TeamDashboardContext from "../context";

const CompanyAllocationCallFactor = ({
  data,
  requestTeamInsights,
  updateTeamAllocation
}) => {
  const min = 0.5; // Determined by business
  const max = 2.5;
  const [isMinimized, setMinimized] = useState(false);
  // const dispatch = useDispatch();
  const { requestedDates, selectedTeam } =
    React.useContext(TeamDashboardContext);
  const { ascending, sortBy, getSortedArray, toggleSortDirection, setSortBy } =
    useSort({ by: "company_name", ascending: true });

  const recalculatePatientsToCall = (callFactor, totalPatients) => {
    // Actual daily total won't be updated until sproc runs again, but we can display it
    // 22 is pulled from working days in a month
    if (isNaN(parseFloat(callFactor))) return 0;
    return Math.round((callFactor * totalPatients) / 22) ?? 0;
  };

  const callFactorOutOfRange = callFactor => {
    if (callFactor < min || isNaN(parseFloat(callFactor))) return min;
    return callFactor;
  };
  return (
    <div>
      {data.length > 0 ? (
        <div className="block">
          <div className="block-header">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setMinimized(!isMinimized)}
            >
              Call Factor
              <i className="flat-icon-button">
                {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
              </i>
            </span>
            {/* Can put information bubble here */}
          </div>

          {!isMinimized && (
            <Formik
              initialValues={{ data: data.map((x, i) => ({ ...x, index: i })) }}
              enableReinitialize
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <FieldArray
                    name={"data"}
                    render={() => {
                      return (
                        <table className="company-allocation-slider-container">
                          <TableSortableHeader
                            columns={[
                              { key: "company_name", text: "Company Name" },
                              {
                                key: "call_factor",
                                text: "Call Factor"
                              },
                              {
                                key: "total_active_patients",
                                text: "Total Active Patients"
                              },
                              {
                                key: "todays_eligible_patients",
                                text: "Today's Eligible Patients"
                              },
                              {
                                key: "actual_daily_total",
                                text: "Current Patient Call Goal"
                              },
                              {
                                key: "calculated_daily_total",
                                text: "Updated Patient Call Goal"
                              }
                            ]}
                            sortBy={sortBy}
                            ascending={ascending}
                            setSortBy={setSortBy}
                            toggleSortDirection={toggleSortDirection}
                          />
                          <tbody>
                            {getSortedArray(values.data).map(x => (
                              <tr key={x.company_guid}>
                                <td className="allocation-company-title">
                                  {x.company_name}
                                </td>

                                <td className="allocation-percent-container">
                                  <input
                                    className="allocation-percent-input"
                                    style={{
                                      backgroundColor: "transparent"
                                    }}
                                    type="number"
                                    min={min}
                                    max={max}
                                    step=".1"
                                    value={x.call_factor ?? 1.1}
                                    onChange={e => {
                                      const callFactor = parseFloat(
                                        e.target.value
                                      ).toFixed(1);
                                      if (callFactor > max) return;
                                      setFieldValue(
                                        `data.${x.index}.call_factor`,
                                        e.target.value //Not using callFactor here because I'd like user to be able to clear out input completely
                                      );
                                      setFieldValue(
                                        `data.${x.index}.calculated_daily_total`,
                                        recalculatePatientsToCall(
                                          callFactor,
                                          x.total_active_patients
                                        )
                                      );
                                    }}
                                    onBlur={e => {
                                      const callFactor = parseFloat(
                                        e.target.value
                                      ).toFixed(1);
                                      setFieldValue(
                                        `data.${x.index}.call_factor`,
                                        callFactorOutOfRange(callFactor)
                                      );
                                    }}
                                  />
                                </td>
                                <td>
                                  <h4>{x.total_active_patients}</h4>
                                </td>
                                <td>
                                  <h4>{x.todays_eligible_patients}</h4>
                                </td>
                                <td>
                                  <h4>{x.actual_daily_total}</h4>
                                </td>
                                <td>
                                  {x.calculated_daily_total !==
                                  x.actual_daily_total ? (
                                    <h4>{x.calculated_daily_total}</h4>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      );
                    }}
                  />

                  <div className="allocation-control-buttons">
                    {values.data.length == data.length && (
                      //Will get errors looking for data[i].call_factor without this length check
                      <button
                        type="submit"
                        disabled={
                          !values.data.some(
                            (x, i) =>
                              x.call_factor != data[i].call_factor &&
                              x.call_factor >= 0.5 &&
                              x.call_factor.toString().length < 4 //Don't let them put anything like 1.25
                          )
                        }
                        className="allocation-submit-button"
                        onClick={() => {
                          updateTeamAllocation({
                            data: values.data.map(x => {
                              return {
                                company_guid: x.company_guid,
                                call_factor: x.call_factor
                              };
                            })
                          });

                          // Just setting this to re-disable button, could refetch data instead
                          requestTeamInsights(selectedTeam, requestedDates);
                        }}
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      ) : (
        <p>This team doesn&apos;t have any companies.</p>
      )}
    </div>
  );
};

CompanyAllocationCallFactor.propTypes = {
  data: PropTypes.array.isRequired,
  requestTeamInsights: PropTypes.func.isRequired,
  updateTeamAllocation: PropTypes.func.isRequired
};

export default connect(
  state => ({
    data: selectors.getTeamAllocation(state)
  }),
  { requestTeamInsights, updateTeamAllocation }
)(CompanyAllocationCallFactor);
