import { fromJS, Map } from "immutable";

export default fromJS({
  ordersSnapshot: {
    OrderStats: [
      { status: "accepted", revenue: 0 },
      { status: "being_processed", revenue: 0 },
      { status: "completed", revenue: 0 },
      { status: "estimated_revenue", revenue: 0 },
      { status: "new", revenue: 0 },
      { status: "on_hold", revenue: 0 },
      { status: "pending_fulfillment", revenue: 0 },
      { status: "pending_shipment", revenue: 0 },
      { status: "rejected", revenue: 0 },
      { status: "total", revenue: 0 },
      { status: "walk_ins", revenue: 0 }
    ],
    estimated_revenue: 0
  },
  patientsSnapshot: {
    active: 0,
    due_for_follow_up: 0,
    eligible: 0,
    inactive: 0,
    missing_dates: 0,
    total: 0
  },
  companies: {
    reports: {
      orders: {
        revenue: 0,
        sleep_coach_numbers: [
          {
            id: 0,
            name: "",
            count: 0
          }
        ],
        equipment_breakdown: [
          {
            name: "",
            count: 0
          },
          {
            name: "",
            count: 0
          }
        ],
        status_breakdown: [
          {
            name: "COMPLETED",
            count: 0
          },
          {
            name: "REJECTED",
            count: 0
          },
          {
            name: "WALKINS",
            count: 0
          }
        ]
      },
      ordersReady: false,
      contactsReady: false,
      monthlyReport: {},
      monthlyReportReady: false
    }
  },
  orders: {
    overviewReady: true,
    overview: {},
    formReady: false,
    formActive: false,
    form: {},
    tableQuery: Map({
      sort_by: "",
      direction: "asc",
      filter: Map({
        status: "",
        company: "",
        sleep_coach: "",
        order: "all"
      }),
      page: 1,
      per: 150,
      count: "",
      type: ""
    }),
    table: []
  },
  vmails: {
    vmCountSinceLastPageVisit: 0,
    userOnVoiceMailPage: false,
    profileReady: false,
    tableReady: false,
    profile: {},
    tableQuery: {
      listenedTo: "all",
      worked: "all",
      assigned: "all",
      type: "all",
      per: "25",
      page: "1",
      sleep_coach: "all",
      team: "all",
      assignmentMode: false
    },
    table: []
  },
  fulfillment: {
    importTrackingSuccess: false,
    tableReady: true,
    tableQuery: Map({
      sort_by: "",
      direction: "asc",
      filter: Map({
        status: "",
        company: "",
        sleep_coach: "",
        order: "all"
      }),
      page: 1,
      per: 150,
      count: "",
      type: ""
    }),
    table: []
  },
  users: {
    tableReady: true,
    formReady: true,
    profileReady: false,
    profile: {},
    therapists: [],
    "compliance-therapists": [],
    administrators: [],
    "office-administrators": [],
    "company-administrators": [],
    "sleep-coaches": [],
    form: Map({
      role: "SleepCoach",
      first_name: "",
      last_name: "",
      company: "",
      email: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
      active: true,
      call_group: "",
      extension: "",
      enabled: false,
      accessible_companies: fromJS([]),
      errors: Map({
        first_name: null,
        last_name: null,
        company: null,
        phone_number: null,
        password: null,
        password_confirmation: null
      })
    })
  },
  exports: [],
  entities: {
    hcpcsRecords: {},
    patientRows: {},
    patients: {},
    team_names: {},
    companies: {},
    companiesTable: {},
    patientNotes: {},
    orders: {},
    sleepCoaches: {},
    walkInOrders: {},
    contactRecords: {},
    events: {},
    equipment: {},
    users: {},
    fulfillment: {},
    manufacturers: {},
    inventoryItems: {},
    changeLogs: {},
    accessLogs: {},
    maskFittingResults: {},
    no_mask_reasons: [],
    hcpcs_list: [],
    fulfillment_companies: []
  },
  fetch: {},
  table: {}
});
