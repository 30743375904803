import { put, take, call, all } from "redux-saga/effects";
import {
  Types
} from "actions/order-profile";

const makeApiAction = (orderId, route) => (apiType, responseType) => {
  return {
    type: apiType,
    payload: {
      apiRequest: {
        type: responseType,
        meta: { orderId },
        method: "GET",
        route
      }
    },
    meta: { orderId }
  };
};

function* watchOrderInfo() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take([Types.REQUEST_ORDER_INFO, Types.INVALIDATE_ORDER_INFO]);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/order_info`)(
        Types.API_ORDER_INFO,
        Types.RESPONSE_ORDER_INFO
      )
    );
  }
}

function* watchPatientInfo() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take(Types.REQUEST_PATIENT_INFO);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/patient_info`)(
        Types.API_PATIENT_INFO,
        Types.RESPONSE_PATIENT_INFO
      )
    );
  }
}

function* watchOrderNotes() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take([Types.REQUEST_NOTES, Types.INVALIDATE_NOTES]);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/notes`)(Types.API_NOTES, Types.RESPONSE_NOTES)
    );
  }
}

function* watchEquipmentInfo() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take(Types.REQUEST_EQUIPMENT_INFO);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/equipment_info`)(
        Types.API_EQUIPMENT_INFO,
        Types.RESPONSE_EQUIPMENT_INFO
      )
    );
  }
}

function* watchComplianceInfo() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take(Types.REQUEST_COMPLIANCE_INFO);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/compliance`)(
        Types.API_COMPLIANCE_INFO,
        Types.RESPONSE_COMPLIANCE_INFO
      )
    );
  }
}

function* watchCompanyInfo() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take(Types.REQUEST_COMPANY_INFO);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/company_info`)(
        Types.API_COMPANY_INFO,
        Types.RESPONSE_COMPANY_INFO
      )
    );
  }
}

function* watchLineItems() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take([Types.REQUEST_LINE_ITEMS, Types.INVALIDATE_LINE_ITEMS]);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/equipment_requested`)(
        Types.API_LINE_ITEMS,
        Types.RESPONSE_LINE_ITEMS
      )
    );
  }
}

function* watchChangeLogs() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take(Types.REQUEST_CHANGE_LOGS);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/logs/change`)(
        Types.API_CHANGE_LOGS,
        Types.RESPONSE_CHANGE_LOGS
      )
    );
  }
}

function* watchAccessLogs() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take(Types.REQUEST_ACCESS_LOGS);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/logs/access`)(
        Types.API_ACCESS_LOGS,
        Types.RESPONSE_ACCESS_LOGS
      )
    );
  }
}

function* watchDysfunctionInfo() {
  while (true) {
    const {
      meta: { orderId }
    } = yield take(Types.REQUEST_DYSFUNCTION_INFO);
    yield put(
      makeApiAction(orderId, `orders/${orderId}/dysfunctional_equipment`)(
        Types.API_DYSFUNCTION_INFO,
        Types.RESPONSE_DYSFUNCTION_INFO
      )
    );
  }
}

export default function* rootDataSaga() {
  yield all([
    call(watchPatientInfo),
    call(watchOrderNotes),
    call(watchOrderInfo),
    call(watchLineItems),
    call(watchComplianceInfo),
    call(watchCompanyInfo),
    call(watchEquipmentInfo),
    call(watchAccessLogs),
    call(watchChangeLogs),
    call(watchDysfunctionInfo)
  ]);
}
