import React from "react";
import PropTypes from "prop-types";
import { PureModal, AlertBar } from "components/ui";
import PhilipsRecallForm from "./philips-recall-form";
import PhilipsWorkflowReview from "./workflow-review";
import { convertLegacyPhilipsStatus } from "./helpers";

const PhilipsRecallBanner = ({ patientId, patientInfo }) => {
  const philipsRecallStatus = convertLegacyPhilipsStatus(patientInfo);
  if (
    !philipsRecallStatus ||
    philipsRecallStatus === "Pending" ||
    philipsRecallStatus === "Not Started"
  )
    return (
      <AlertBar color={philipsRecallStatus == "Pending" ? "orange" : "red"}>
        <span className="philips-alert-bar-prompt">
          <strong className="philips-alert-bar-status">
            Recall Status,{" "}
            {philipsRecallStatus == "Pending" ? "Pending" : "Not Started"}.
          </strong>
          <PureModal
            renderTrigger={({ openModal }) => (
              <button style={{ margin: 0, padding: 10 }} onClick={openModal}>
                Philips Recall
              </button>
            )}
            renderContent={({ closeModal }) => (
              <PhilipsRecallForm
                closeModal={closeModal}
                patientId={patientId}
              />
            )}
          />
        </span>
      </AlertBar>
    );
  else if (
    philipsRecallStatus === "Not Affected" ||
    philipsRecallStatus === "Completed"
  )
    return (
      <AlertBar color="green">
        <span className="philips-alert-bar-prompt">
          <strong className="philips-alert-bar-status">
            Recall Status:{" "}
            {philipsRecallStatus === "Completed" ? "Completed" : "Not Affected"}
            .
          </strong>
          <PureModal
            renderTrigger={({ openModal }) => (
              <button style={{ margin: 0, padding: 10 }} onClick={openModal}>
                Review Philips Recall
              </button>
            )}
            renderContent={({ closeModal }) => (
              <PhilipsWorkflowReview
                patientId={patientId}
                patientInfo={patientInfo}
                closeModal={closeModal}
              />
            )}
          />
        </span>
      </AlertBar>
    );
  return (
    <AlertBar>
      <span className="philips-alert-bar-prompt">
        <strong className="philips-alert-bar-status">
          Recall Status: {philipsRecallStatus}.
        </strong>
        <PureModal
          renderTrigger={({ openModal }) => (
            <button style={{ margin: 0, padding: 10 }} onClick={openModal}>
              Review Philips Recall
            </button>
          )}
          renderContent={({ closeModal }) => (
            <PhilipsWorkflowReview
              patientId={patientId}
              patientInfo={patientInfo}
              closeModal={closeModal}
            />
          )}
        />
      </span>
    </AlertBar>
  );
};

export default PhilipsRecallBanner;

PhilipsRecallBanner.propTypes = {
  patientId: PropTypes.string.isRequired,
  philipsRecallStatus: PropTypes.string,
  patientInfo: PropTypes.object
};
