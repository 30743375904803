import { get } from "utils/api";

export const Types = {
  INITIALIZE_TWILIO_CHAT_CLIENT: "INITIALIZE_TWILIO_CHAT_CLIENT",
  TWILIO_CHAT_READY: "TWILIO_CHAT_READY",
  START_TWILIO_CHAT: "START_TWILIO_CHAT",
  CLOSE_TWILIO_CHAT: "CLOSE_TWILIO_CHAT",
  END_TWILIO_CHAT: "END_TWILIO_CHAT",
  TWILIO_CHAT_STARTED: "TWILIO_CHAT_STARTED",
  TWILIO_CHAT_ENDED: "TWILIO_CHAT_ENDED",
  TWILIO_SEND_MESSAGE: "TWILIO_SEND_MESSAGE",
  TWILIO_CHAT_SET_CHANNEL: "TWILIO_CHAT_SET_CHANNEL",
  TWILIO_CHAT_REMOVE_CHANNEL: "TWILIO_CHAT_REMOVE_CHANNEL",
  TWILIO_CHAT_MESSAGE_ADDED: "TWILIO_CHAT_MESSAGE_ADDED",
  TWILIO_CHAT_RESTORE_MESSAGES: "TWILIO_CHAT_RESTORE_MESSAGES",
  TWILIO_CHAT_ERROR: "TWILIO_CHAT_ERROR",
  PENDING_CHAT_RECIEVED: "PENDING_CHAT_RECIEVED",
  PENDING_CHATS_RECIEVED: "PENDING_CHATS_RECIEVED"
};

export const initializeTwilioChatClient = () => ({
  type: Types.INITIALIZE_TWILIO_CHAT_CLIENT
});

export const startTwilioChat = channelSid => ({
  type: Types.START_TWILIO_CHAT,
  payload: channelSid
});

export const closeTwilioChat = () => ({
  type: Types.CLOSE_TWILIO_CHAT
});

export const sendTwilioChatMsg = message => ({
  type: Types.TWILIO_SEND_MESSAGE,
  payload: message
});

export const endTwilioChat = () => ({ type: Types.END_TWILIO_CHAT });

export const getPendingChats = teamName => async dispatch => {
  try {
    const result = await get(`dme_portal/chats/${teamName}/chat_now`);
    if (result) {
      dispatch({ type: Types.PENDING_CHAT_RECIEVED, payload: result });
      return result;
    } else return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllChatRequests = () => async dispatch => {
  const result = await get(`dme_portal/chats/info`);
  console.log(result);
  dispatch({ type: Types.PENDING_CHATS_RECIEVED, result });
};
