import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { InlineWaiting, withModal, ConfirmationDialog } from "components/ui";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import PhoneNumberField from "components/forms/phone-number-field";
import MultiSelectDropdown from "components/forms/multi-select-dropdown-admin-companies";
import HasRolePermission from "components/shared/has-role-permission";
import * as R from "ramda";
import { isValidPhone, isValidEmail } from "utils/redux-form";
import {
  asyncValidateUniqueEmail,
  asyncUserHasAssignedPatients
} from "utils/form-validation";
import { browserHistory } from "browser-history";
import * as Yup from "yup";
import moment from "moment";

export const transformUserFormValues = formValues => {
  const isS3User = ["Administrator", "SleepCoach"].includes(formValues.role);
  const transformedValues = {
    accessible_companies: formValues.accessible_companies || [],
    require_order_review:
      formValues.role === "SleepCoach"
        ? formValues.require_order_review
        : undefined,
    hire_date: isS3User ? formValues.hire_date : undefined,
    employee_id: isS3User ? formValues.employee_id : undefined
  };
  return { ...formValues, ...transformedValues };
};

const UserForm = ({
  user,
  companies,
  teamOptions,
  handleSubmit,
  openModal,
  closeModal
}) => {
  const validationSchema = Yup.lazy(values => {
    return Yup.object().shape({
      email: Yup.string()
        .required("Required")
        .test("checkValidEmail", "Invalid email", email => !isValidEmail(email))
        .test("checkDuplEmail", "Email already used", async email => {
          return !(await asyncValidateUniqueEmail(email, user.id));
        }),
      role: Yup.string().required("Required"),
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      company_id: Yup.string().test("company_required", "Required", val => {
        if (["Administrator", "SleepCoach"].includes(values.role)) {
          return true;
        } else return !!val;
      }),
      phone_number: Yup.string().test(
        "valid-number",
        "Invalid phone number",
        val => !isValidPhone(val)
      )
    });
  });
  const initialValues = {
    role: R.propOr("", "role", user),
    first_name: R.propOr("", "first_name", user),
    last_name: R.propOr("", "last_name", user),
    email: R.propOr("", "email", user),
    active: R.propOr(true, "active", user),
    mfa_enabled: R.propOr(false, "mfa_enabled", user),
    call_group: R.propOr(R.propOr("", "team_name", user), "call_group", user),
    extension: R.propOr("", "extension", user),
    phone_number: R.propOr("", "phone_number", user),
    can_make_calls: R.propOr(false, "can_make_calls", user),
    can_be_emailed_from_patient_profile: R.propOr(
      false,
      "can_be_emailed_from_patient_profile",
      user
    ),
    contracted_employee: R.propOr(false, "contracted_employee", user),
    compliance_coach: user?.compliance_coach ?? false,
    chat_capable: R.propOr("", "chat_capable", user),
    spanish_speaker: R.propOr("", "speaks_spanish", user),
    require_order_review: R.propOr(false, "require_order_review", user),
    hire_date: R.pipe(R.propOr("", "hire_date"), hire_date =>
      hire_date ? moment(hire_date).format("YYYY-MM-DD") : ""
    )(user),
    employee_id: R.propOr("", "employee_id", user),
    company_id: R.pathOr("", ["company", "key"], user),
    require_ip_approval: user?.require_ip_approval,
    accessible_companies: R.pipe(
      R.pathOr([], ["accessible_companies"]),
      R.reject(({ value }) => R.isNil(value)),
      R.map(R.prop("key"))
    )(user),
    password: "",
    password_confirmation: ""
  };
  const [isSAASCompany, setIsSAASCompany] = React.useState();
  return (
    <Formik
      initialValues={initialValues}
      initialTouched={{
        role: true,
        first_name: true,
        last_name: true,
        phone_number: true,
        company_id: true,
        email: true
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        /* edit user and deactivating user */
        if (user.id && user.active && !values.active) {
          const currentlyAssignedPatients = await asyncUserHasAssignedPatients(
            user.id
          );
          if (currentlyAssignedPatients > 0) {
            setSubmitting(false);
            openModal(
              <ConfirmationDialog
                onCancel={() => {
                  closeModal();
                }}
                warningMsg={`Coach currently has ${currentlyAssignedPatients} assigned patients`}
                continueMsg="Unassign all patients and continue?"
                onConfirm={() => {
                  closeModal();
                  setSubmitting(true);
                  handleSubmit({ ...values, assign_patients_to: null });
                }}
              />
            );
          } else {
            await handleSubmit(transformUserFormValues(values));
          }
        } else {
          await handleSubmit(transformUserFormValues(values));
        }
      }}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        isValid,
        isSubmitting,
        errors
      }) => {
        return (
          <Form>
            <HasRolePermission allowedRoles={["Administrator"]}>
              <fieldset>
                <legend>Type</legend>
                <FormikField
                  name="role"
                  component="select"
                  onChange={e => {
                    handleChange(e);
                    if (
                      //  dme to s3 user
                      ["Administrator", "SleepCoach"].includes(
                        e.target.value
                      ) &&
                      !["Administrator", "SleepCoach"].includes(values.role)
                    ) {
                      setFieldValue("call_group", "");
                      setFieldValue("company_id", "");
                      setFieldValue("accessible_companies", []);
                    }
                  }}
                >
                  <option />
                  <option value="SleepCoach">Sleep Coach</option>
                  <option value="Administrator">Administrator</option>
                  <option value="CompanyAdministrator">
                    Company Administrator
                  </option>
                  <option value="OfficeAdministrator">
                    Office Administrator
                  </option>
                  {/* <option value="ComplianceCoach">Compliance Coach</option> */}
                </FormikField>
              </fieldset>
            </HasRolePermission>
            <HasRolePermission allowedRoles={["ServiceAdmin", "Constractor"]}>
              <fieldset>
                <legend>Type</legend>
                <FormikField
                  name="role"
                  component="select"
                  onChange={e => {
                    handleChange(e);
                    if (
                      e.target.value === "OfficeAdministrator" ||
                      e.target.value === "ContractorAdministrator"
                    )
                      setFieldValue("can_make_calls", true);
                    else setFieldValue("can_make_calls", false);
                  }}
                >
                  <option />
                  <option value="CompanyAdministrator">
                    Company Administrator
                  </option>
                  <option value="OfficeAdministrator">SleepCoach</option>
                </FormikField>
              </fieldset>
            </HasRolePermission>
            <fieldset>
              <legend>Details</legend>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: 30
                }}
              >
                <FormikField
                  name="first_name"
                  label="First Name *"
                  type="text"
                />
                <FormikField name="last_name" label="Last Name *" type="text" />
              </div>
              <React.Fragment>
                <FormikField
                  name="company_id"
                  label="Company *"
                  component="select"
                  onChange={({ target }) => {
                    if (target.value !== values.company_id) {
                      const company = R.find(
                        ({ GUID }) => GUID === target.value
                      )(companies);
                      const software_as_service = R.propOr(
                        false,
                        "software_as_service",
                        company
                      );
                      // remove assigned team when moving user from software_as_service company to non software_as_service company
                      if (!software_as_service && isSAASCompany) {
                        setFieldValue("call_group", "");
                        setFieldValue("chat_capable", false);
                      }
                      setIsSAASCompany(software_as_service);
                    }
                    setFieldValue("company_id", target.value);
                  }}
                >
                  <option />
                  {companies.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </FormikField>
                <label style={{ marginBottom: 10 }}>
                  <div style={{ display: "flex", flex: 1 }}>
                    <span style={{ flex: 1 }}>Accessible Companies</span>
                    {errors.accessible_companies && (
                      <span
                        style={{ justifyContent: "flex-end", color: "red" }}
                        className="form-field-error"
                      >
                        {errors.accessible_companies}
                      </span>
                    )}
                  </div>
                  <MultiSelectDropdown
                    searchable
                    onChange={value => {
                      setFieldValue("accessible_companies", value);
                    }}
                    onClear={() => setFieldValue("accessible_companies", [])}
                    values={values.accessible_companies}
                    options={companies}
                  />
                </label>
              </React.Fragment>
              <FormikField name="email" label="Email Address *" type="email" />
              <FormikField
                name="phone_number"
                label="Phone Number"
                type="tel"
                component={PhoneNumberField}
              />
              <HasRolePermission
                allowedRoles={[
                  "Administrator",
                  "ServiceAdmin",
                  "ContractorAdministrator"
                ]}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: 20
                  }}
                >
                  <FormikField
                    label="Password"
                    name="password"
                    maxLength="128"
                    size="50"
                    type="password"
                  />
                  <FormikField
                    label="Password Confirmation"
                    name="password_confirmation"
                    size="50"
                    type="password"
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridGap: 10,
                    gridTemplateColumns: "100px 150px 200px 1fr 1fr"
                  }}
                >
                  <FormikField
                    label="Active"
                    name="active"
                    type="checkbox"
                    checked={values.active}
                  />
                  <FormikField
                    label="Two-Factor Auth"
                    name="mfa_enabled"
                    type="checkbox"
                    checked={values.mfa_enabled}
                  />
                  {["SleepCoach"].includes(values.role) &&
                    !user.admin_elevated && (
                      <FormikField
                        label="Require Order Review"
                        name="require_order_review"
                        type="checkbox"
                        checked={values.require_order_review}
                      />
                    )}
                  {["SleepCoach", "Administrator"].includes(values.role) && (
                    <FormikField
                      label="Hire Date"
                      name="hire_date"
                      type="date"
                    />
                  )}
                  {["SleepCoach", "Administrator"].includes(values.role) && (
                    <FormikField
                      label="Employee Id"
                      name="employee_id"
                      type="text"
                    />
                  )}
                </div>
              </HasRolePermission>
            </fieldset>
            <HasRolePermission
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "ContractorAdministrator"
              ]}
            >
              <fieldset>
                <legend>Voip Settings</legend>
                {(["Administrator", "SleepCoach"].includes(values.role) ||
                  user.admin_elevated ||
                  isSAASCompany) && (
                  <FormikField
                    label="Team"
                    name="call_group"
                    component="select"
                  >
                    <option />
                    {teamOptions.map(({ text, value }) => (
                      <option key={value} value={value}>
                        {text}
                      </option>
                    ))}
                  </FormikField>
                )}
                <FormikField name="extension" label="Extension" type="number" />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <FormikField
                    label="Access To S3 Phone"
                    name="can_make_calls"
                    type="checkbox"
                    checked={values.can_make_calls}
                  />
                  <FormikField
                    label="Can Be Emailed From Patient Profile"
                    name="can_be_emailed_from_patient_profile"
                    type="checkbox"
                    checked={values.can_be_emailed_from_patient_profile}
                  />
                  <FormikField
                    label="Contracted Employee"
                    name="contracted_employee"
                    type="checkbox"
                    checked={values.contracted_employee}
                    style={{ marginLeft: 50 }}
                  />
                  <FormikField
                    label="Compliance Coach"
                    name="compliance_coach"
                    type="checkbox"
                    checked={values.compliance_coach}
                    style={{ marginLeft: 50 }}
                  />
                  {(["Administrator", "SleepCoach"].includes(values.role) ||
                    user.admin_elevated ||
                    isSAASCompany) && (
                    <FormikField
                      label="Text Chat Enabled"
                      name="chat_capable"
                      type="checkbox"
                      checked={values.chat_capable}
                      style={{ marginLeft: 50 }}
                    />
                  )}
                  {(["Administrator", "SleepCoach"].includes(values.role) ||
                    user.admin_elevated ||
                    isSAASCompany) && (
                    <FormikField
                      label="Spanish Speakeer"
                      name="spanish_speaker"
                      type="checkbox"
                      checked={values.spanish_speaker}
                      style={{ marginLeft: 50 }}
                    />
                  )}
                  {["SleepCoach"].includes(values.role) && (
                    <FormikField
                      label="Require Ip Approval"
                      name="require_ip_approval"
                      type="checkbox"
                      checked={values.require_ip_approval}
                      style={{ marginLeft: 50 }}
                    />
                  )}
                </div>
              </fieldset>
            </HasRolePermission>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                type="button"
                style={{ marginRight: 30 }}
                className="button warning"
                onClick={e => {
                  e.preventDefault();
                  browserHistory.goBack();
                }}
              >
                Cancel
              </button>
              {!isSubmitting ? (
                <button
                  type="submit"
                  className="button"
                  disabled={!isValid}
                  title={R.values(errors)}
                >
                  Submit
                </button>
              ) : (
                <InlineWaiting />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

UserForm.defaultProps = {
  user: {
    role: "",
    first_name: "",
    last_name: "",
    email: "",
    active: true,
    mfa_enabled: true,
    call_group: "",
    phone_number: "",
    extension: "",
    can_make_calls: false,
    can_be_emailed_from_patient_profile: false,
    contracted_employee: false,
    compliance_coach: false
  }
};

UserForm.propTypes = {
  user: PropTypes.object,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  teamOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withModal(
  connect(state => ({
    companies: selectors.getFormOptionsCompany(state),
    teamOptions: selectors.getTeamNameOptions(state)
  }))(UserForm)
);
