import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { get } from "utils/api";

export const AppPermissionContext = React.createContext();

export const AppPermissionProvider = ({ patientId, companyId, children }) => {
  const { fetchData, response } = useFetch({
    apiFn: ({ patientId, companyId }) => {
      if (patientId) {
        return get(`patients/${patientId}/app_permissions`);
      } else if (companyId) {
        return get(`companies/${companyId}/app_permissions`);
      } else {
        console.error(
          "prop patientId or companyId not provided in AppPermissionProvider"
        );
        return Promise.resolve({});
      }
    },
    defaultValue: {}
  });
  React.useEffect(() => {
    fetchData({ patientId, companyId });
  }, [patientId, companyId]);
  return (
    <AppPermissionContext.Provider value={response}>
      {children}
    </AppPermissionContext.Provider>
  );
};

export const HasPermission = ({ children, permission, allowAdmins }) => {
  const userRole = useSelector(selectors.getUserRole);
  const appPermissions = useContext(AppPermissionContext);
  if (allowAdmins && userRole === "Administrator") return <>{children}</>;
  if (appPermissions && appPermissions[permission]) return <>{children}</>;
  else return null;
};

HasPermission.propTypes = {
  permission: PropTypes.oneOf([
    "orderSupplies",
    "documents",
    "myOrders",
    "signatures",
    "myProducts",
    "questionaires",
    "scheduleChat",
    "maskFitting",
    "chatNow",
    "maskFitting",
    "patientTasks",
    "new_hcpc_eligibility"
  ]).isRequired,
  children: PropTypes.node.isRequired,
  allowAdmins: PropTypes.bool
};

HasPermission.defaultProps = { allowAdmins: true };

AppPermissionProvider.propTypes = {
  patientId: PropTypes.string,
  companyId: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default AppPermissionProvider;
