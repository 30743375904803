import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import MdWarning from "react-icons/lib/md/warning";

const CallQualityWarning = ({ callQualityWarning }) =>
  callQualityWarning ? (
    <div className="call-quality-warning">
      <MdWarning />
      <span className="call-quality-text">
        We have detected poor call quality conditions. You may experience
        degraded call quality.
      </span>
    </div>
  ) : null;

CallQualityWarning.propTypes = {
  callQualityWarning: PropTypes.string
};

export default connect(state => ({
  callQualityWarning: selectors.getCallQualityWarning(state)
}))(CallQualityWarning);
