import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { graphColors } from "utils/styles";
import { groupRowsBy } from "utils/misc";

const transformData = groupRowsBy("CompanyName", [
  { key: "Masks_Sold", label: "Masks" },
  { key: "Masks_with_Headgear_Sold", label: "Masks With Headgear" },
  { key: "Seals_Sold", label: "Seals" },
  { key: "Disp_Filters_Sold", label: "Disposable Filters" },
  { key: "Non_Disp_Filters_Sold", label: "Non_Disp_Filters_Sold" },
  { key: "Chinstraps_Sold", label: "Chinstraps" },
  { key: "Tubing_Sold", label: "Tubing" },
  { key: "Misc_Sold", label: "Misc" }
]);

const CompanyEquipmentSoldGraph = ({ data }) => (
  <ResponsiveContainer height={400}>
    <LineChart
      data={transformData(data)}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 5
      }}
      isAnimationActive={false}
    >
      <XAxis type="category" dataKey="name" />
      <YAxis scale="sqrt" />
      <Tooltip />
      <Legend iconType="rect" />
      {data.map(({ CompanyName }, i) => (
        <Line
          key={CompanyName}
          dataKey={CompanyName}
          type="step"
          stroke={graphColors[i % graphColors.length]}
        />
      ))}
    </LineChart>
  </ResponsiveContainer>
);

CompanyEquipmentSoldGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CompanyEquipmentSoldGraph;
