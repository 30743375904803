import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, PureModal, Spinner } from 'components/ui'
import { useFetch } from 'hooks';
import { path } from 'ramda';
import { get } from 'utils/api';
import { errorMessage, message } from "actions/message";
import { getApiErrorMessage, formatDate } from 'utils/misc';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormikField from 'components/forms/formik-field';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from "reducers/company.js";
import { exportFormReport } from 'actions/exports';
import ConfirmationModal from 'components/shared/confirmation-modal';

export default function GenerateReport() {

    const { formId } = useParams()
    const dispatch = useDispatch()
    const formRef = useRef();
    const companyInfo = useSelector(selectors.getCompanyProfile)

    const [formInfo, setFormInfo] = useState({Name: "", Description: "", Questions: []})
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [branches, setBranches] = useState([])

    const {
        fetchData: fetchFormInfo,
        isFetching: fetchingFormInfo,
    } = useFetch({
        apiFn: () => get(`forms/${formId}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const getFormInfo = async () => {

        const response = await fetchFormInfo();

        if (response) {
            setFormInfo({
                Name: response.Name,
                Description: response.Description,
                Questions: response.Questions,
                IsActive: response.IsActive
            });
        }
    }

    useEffect(() => {
      if(formId){
        getFormInfo()
      }
      if(companyInfo.branches){
        setBranches(companyInfo.branches)
      }
    }, [companyInfo])

    const validateDates = () => {
        if (dateFrom && dateTo && moment(dateTo).isBefore(moment(dateFrom)) && !moment(dateFrom).isSame(moment(dateTo), 'day')) {
          dispatch(errorMessage("Date From should be before Date To"))
          return false
        } else {
          return true
        }
    };

    const optionSelectionHandle = (option) => {
        if(formRef.current.values.Options.includes(option)){
            const newOptionArray = formRef.current.values.Options.filter((element) => element != option)
            formRef.current.setFieldValue("Options", newOptionArray)
        } else {
            const newOptionArray = [...formRef.current.values.Options, option]
            formRef.current.setFieldValue("Options", newOptionArray)
        }
    }

    const branchSelectionHandle = (branchId) => {
        if(formRef.current.values.Branches.includes(branchId)){
            const newBranchesArray = formRef.current.values.Branches.filter((element) => element != branchId)
            formRef.current.setFieldValue("Branches", newBranchesArray)
        } else {
            const newBranchesArray = [...formRef.current.values.Branches, branchId]
            formRef.current.setFieldValue("Branches", newBranchesArray)
        }
    }

    const getAllOptionsFromQuestion = (question) => {
        let optionsArray
        if(question.QuestionType == 3) {
            optionsArray = [-1, -2]
        } else {
            optionsArray = question.Options.map((option) => {
                return option.FormQuestionOptionId
            })
        }
        return optionsArray
    }

  return (
    <div className='forms-report-generator'>
        <PageHeader title='Forms Data Report Generator'></PageHeader>
        {
            fetchingFormInfo ?
            <Spinner />
            :
            <Formik
                initialValues={{
                    Name: formInfo?.Name,
                    Description: formInfo?.Description,
                    QuestionId: "",
                    Options: [],
                    Branches: [],
                    DateFilter: "",
                    fromDate: "",
                    toDate: ""
                }}
                enableReinitialize={formId ? true : false}
                onSubmit={(data) => {
                    if(validateDates()){
                        dispatch(exportFormReport({
                            FormId: Number(formId),
                            QuestionId: Number(data.QuestionId),
                            DateFilter: data.DateFilter,
                            FromDate: data.fromDate,
                            ToDate: data.toDate,
                            Branches: data.Branches,
                            Options: data.Options
                        }))
                    }
                }}
                innerRef={formRef}
            >
                {
                    ({isSubmitting, submitForm, setFieldValue, values}) => (
                        <Form>
                            <div className='two-fields-container'>
                                <FormikField
                                    name="Name"
                                    label="Name"
                                    type="text"
                                    disabled={true}
                                />
                                <FormikField
                                    name="Description"
                                    label="Description"
                                    type="text"
                                    disabled={true}
                                />
                            </div>
                            <p>Branches</p>
                            <div className='report-branches-container'>
                                    {
                                        branches.map((element) => (
                                        <div key={element.id}>
                                            <input 
                                                type="checkbox"
                                                value={element.id}
                                                name="Branches"
                                                id={element.id}
                                                onChange={(e) => branchSelectionHandle(e.target.value)}
                                            />
                                            <label htmlFor={element.id}>{element.branch_name}</label>
                                        </div>
                                        ))
                                    }
                            </div>
                            <p>Type of date</p>
                            <div>
                                <input 
                                    type="checkbox"
                                    name="DateFilter"
                                    value="Setup"
                                    onChange={(e) => setFieldValue("DateFilter", e.target.value)}
                                    checked={values.DateFilter == "Setup"}
                                />
                                <label>Setup date</label>
                                <input 
                                    type="checkbox"
                                    name="DateFilter"
                                    value="Form Completion"
                                    onChange={(e) => setFieldValue("DateFilter", e.target.value)}
                                    checked={values.DateFilter == "Form Completion"}
                                />
                                <label>Form completion date</label>
                            </div>
                            <div className="two-fields-container">
                                <input 
                                    disabled={values.DateFilter == ""}
                                    name='fromDate' 
                                    type="date"
                                    value={formatDate(dateFrom, "yyyy-MM-DD")}
                                    onChange={(e) => {setDateFrom(e.target.value); setFieldValue("fromDate", formatDate(e.target.value, "yyyy-MM-DD HH:mm:ss"))}}
                                />
                                <input 
                                    disabled={values.DateFilter == ""}
                                    name="toDate"
                                    type="date"
                                    value={formatDate(dateTo, "yyyy-MM-DD")}
                                    onChange={(e) => {setDateTo(e.target.value); setFieldValue("toDate", formatDate(e.target.value, "yyyy-MM-DD HH:mm:ss"))}}
                                />
                            </div>
                            {
                                values.DateFilter == "" ?
                                null
                                :
                                <>
                                    <PageHeader title='Questions & Responses'></PageHeader>
                                    <p>Please select a question to be included in the report</p>
                                    <p>Please select one or more answers to the question you selected. If no answers are selected, ALL answers will be reported as a default</p>
                                    <div className='legend-section'>
                                        <div>
                                            <div className='legend-selected-color'></div>
                                            <p>Response selected</p>
                                        </div>
                                        <div>
                                            <div className='legend-not-selected-color'></div>
                                            <p>Response not selected</p>
                                        </div>
                                    </div>
                                    <div className='questions-responses-section'>
                                        <div>
                                            {
                                                formInfo.Questions.map((element) => {
                                                    if(element.QuestionType == 2 || element.QuestionType == 3 || element.QuestionType == 5 ) {
                                                        return (
                                                            <div key={element.QuestionId}>
                                                                <div className='question-container'>
                                                                    <input 
                                                                        name='QuestionId' 
                                                                        type="checkbox"
                                                                        value={element.QuestionId}
                                                                        onChange={(e) => {
                                                                            if(values.QuestionId == e.target.value){
                                                                                setFieldValue("QuestionId", "")
                                                                                setFieldValue("Options", [])
                                                                            } else {
                                                                                setFieldValue("QuestionId", e.target.value)
                                                                                setFieldValue("Options", getAllOptionsFromQuestion(element))
                                                                                dispatch(message("All responses were selected"))
                                                                            }
                                                                        }}
                                                                        checked={values.QuestionId == element.QuestionId}
                                                                    />
                                                                    <p>{element.Text}</p>
                                                                </div>
                                                                <div className='option-container'>
                                                                    {
                                                                        element.QuestionType == 3 ?
                                                                        <div>
                                                                            <div 
                                                                                className='option-text'
                                                                                style={{display: element.QuestionId == values.QuestionId ? "block" : "none", backgroundColor: values.Options.includes(-1) ? "#ffffcc" : "#ffffff"}}
                                                                                onClick={() => optionSelectionHandle(-1)}
                                                                            >
                                                                                <p>Yes</p>
                                                                            </div>
                                                                            <div 
                                                                                className='option-text'
                                                                                style={{display: element.QuestionId == values.QuestionId ? "block" : "none", backgroundColor: values.Options.includes(-2) ? "#ffffcc" : "#ffffff"}}
                                                                                onClick={() => optionSelectionHandle(-2)}
                                                                            >
                                                                                <p>No</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        (element.Options && element.Options.length > 0) ?
                                                                        element.Options.map((option) => (
                                                                            <div 
                                                                                className='option-text'
                                                                                key={option.FormQuestionOptionId}
                                                                                style={{display: element.QuestionId == values.QuestionId ? "block" : "none", backgroundColor: values.Options.includes(option.FormQuestionOptionId) ? "#ffffcc" : "#ffffff"}}
                                                                                onClick={() => optionSelectionHandle(option.FormQuestionOptionId)}
                                                                            >
                                                                                <p>{option.Text}</p>
                                                                            </div>
                                                                        ))
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                            <PureModal
                                renderTrigger={({ openModal }) => 
                                    <button 
                                        disabled={values.DateFilter == "" || values.fromDate == "" || values.toDate == "" || values.QuestionId == "" || isSubmitting} 
                                        className='generate-report-button' type="button" onClick={() => formInfo.IsActive ? submitForm() : openModal()} 
                                        id="ConfirmReportOnInactiveForm"
                                    >
                                        Generate
                                    </button>}
                                renderContent={({ closeModal }) => 
                                    <ConfirmationModal 
                                        title="This form is currently not active. Do you wish to proceed?"
                                        noOptionText="No"
                                        closeModal={closeModal}
                                        yesAction={() => {validateDates() ? submitForm() : null} }
                                    />}
                            >
                            </PureModal>
                        </Form>
                    )
                }
                
            </Formik>
        }
    </div>
  )
}
