import { GET_RARTICLES, DELETE_RARTICLES, UPDATE_STATUS_RARTICLES, UPDATE_SCHEDULED_TYPE, UPDATE_SCHEDULED_DAYS } from '../ActionTypes';
import API from "../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage, message } from "../../../../actions/message";

export const getArticles = () => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/recurring_articles";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            return dispatch({
                type: GET_RARTICLES,
                payload: res.data
            });
        } else {
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_RARTICLES,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_RARTICLES,
            payload: []
        });
    });
}

export const updateScheduleType = (type) => dispatch => {
    dispatch(showLoader());
    let createObj = {
        schedule_type: parseInt(type)
    }
    const url = "dme_portal/recurring_articles/type";
    API().post(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_SCHEDULED_TYPE,
                payload: createObj
            });
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    });
}

export const createArticles = (data) => dispatch => {
    dispatch(showLoader());
    let createObj = {
        title: data.title,
        link: data.link,
        company: data.company,
        active: true,
        orderno: data.orderno
    };
    const url = "dme_portal/recurring_articles";
    API().post(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            dispatch(getArticles());
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    });
}

export const deleteArticles = (aid) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/recurring_articles/" + aid;
    API().delete(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: DELETE_RARTICLES,
                payload: aid
            });
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    });
}

export const updateArticles = (data) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/recurring_articles";
    let createObj = {
        article_id: data.article_id,
        title: data.title,
        link: data.link,
        company: data.company,
        active: data.active,
        orderno: parseInt(data.orderno)
    }
    API().put(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            dispatch(getArticles());
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    });
}

export const updateStatusArticles = (data, status) => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/recurring_articles";
    let createObj = {
        article_id: data.article_id,
        title: data.title,
        link: data.link,
        company: data.company,
        active: status,
        orderno: parseInt(data.orderno)
    };
    API().put(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_STATUS_RARTICLES,
                payload: createObj
            });
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    });
}

export const updateScheduleDays = (days) => dispatch => {
    var cDays = parseInt(days);
    if (!Number.isInteger(cDays) || cDays < 1) {
        dispatch(errorMessage("Enter Valid Days."));
        return;
    }
    dispatch(showLoader());
    let createObj = {
        schedule_days: parseInt(days)
    }
    const url = "dme_portal/recurring_articles/days";
    API().post(url, createObj).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            dispatch(message(res.data.message));
            return dispatch({
                type: UPDATE_SCHEDULED_DAYS,
                payload: cDays
            });
        } else {
            dispatch(errorMessage(res.data.message));
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
    })
}
