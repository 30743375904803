import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { pathOr } from 'ramda';
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import useRowSelect, { SelectRowContext, RowSelectHeaderCell } from 'hooks/useRowSelectNew';
import { PageHeader } from 'components/ui';
import ManagerViewTableRow from './manager-view-table-row';
import ManagerViewTableControls from './manager-view-table-controls';
import DateTimeHeaderColumn from 'hooks/useQuery/api-query/table-header/date-time-header-filter';
import CancelAppointmentGridModal, { CancelAppointmentGridOpenModal } from '../cancel-appointment/grid-button-open-modal.jsx';
import RescheduleAppointmentGridModal, { RescheduleAppointmentGridOpenModal } from '../reschedule-appointment/grid-button-open-modal';
import moment from "moment";
import PropTypes from "prop-types";
import { applicationDateTimeFormat, formatDate, getApiErrorMessageCustomPathEnd } from 'utils/misc';
import { useDispatch, useSelector } from 'react-redux';
import { post } from "utils/api";
import { selectors } from "reducers";
import { useFetch } from "hooks";
import { errorMessage } from "actions/message";


export default function ManagerView({ timeZone, resetTimeZoneValue }) {

    const user = useSelector(selectors.getUser);
    const dispatch = useDispatch()
    const location = useLocation();
    const initialFilters = pathOr({}, ["state", "filters"], location);

    const [dateFrom, setDateFrom] = useState(formatDate(moment(), applicationDateTimeFormat));
    const [dateTo, setDateTo] = useState(formatDate(moment().add(30, "days"), applicationDateTimeFormat));

    const [coachesToFilter, setCoachesToFilter] = useState([])
    const [companiesToFilter, setCompaniesToFilter] = useState([])
    const [patientsToFilter, setPatientsToFilter] = useState([])
    const [topicsToFilter, setTopicsToFilter] = useState([])

    const [chatToBeUsed, setChatToBeUsed] = useState([])

    const [chatTypeToBeUsed, setChatTypeToBeUsed] = useState()

    useEffect(() => {
        if (timeZone) {
            fetchData(query);
            resetTimeZoneValue();
        }
    }, [timeZone]);

    const initialQuery = {
        sort: {},
        filters: {
            ...initialFilters,
            Status: ["Confirmation Pending", "Confirmed"],
            ToDate: dateTo,
            FromDate: dateFrom
        },
        pagination: { page: 1, per: 500 }
    };

    const { fetchData: fetchCoaches } = useFetch({
        apiFn: () => post("dme_portal/appointments/manager/available-coaches-create", JSON.stringify([user.id]), {"content-type": "application/json"}),
        defaultValue: [],
        onError: error => {
            dispatch(errorMessage(`Failed to fetch coaches: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
        }
    });

    const { query, queryDispatcher, rows, data, isFetching, fetchData } =
        useApiQuery("dme_portal/appointments/manager", initialQuery, "Result")
    const {
        toggleAllRows,
        isRowSelected,
        toggleRow,
        clearSelectedRows,
        selectedRows
    } = useRowSelect(rows, "AppointmentId", query, row => ({
        AppointmentId: `${row.AppointmentId}`,
        Status: `${row.Status}`
    }));

    const getChatType = (chatTypeString) => {
        switch (chatTypeString) {
            case "Text Chat":
                return 1
            case "Video Chat":
                return 2
            case "Product Demo":
                return 3
            default:
                return ""
        }
    }

    const getFilteredArray = (arrayToFilter) => {
        let jsonObject = arrayToFilter.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

        return uniqueArray
    }

    const getCoaches = async () =>  {
        const res = await fetchCoaches()
        if(res){
            let properStructure = res.Result.map((element) => ({ value: element.value, text: element.text, GUID: element.value }))
            setCoachesToFilter(properStructure)
        }
    }

    const getCompaniesToFilter = (managerViewRows) => {
        let companiesArray = managerViewRows.map(row => ({ value: row.CompanyId, text: row.CompanyName, GUID: row.CompanyId }))

        return getFilteredArray(companiesArray)
    }

    const getPatientsToFilter = (managerViewRows) => {
        let patientsArray = []
        managerViewRows.forEach(row => {
            patientsArray.push({ value: row.PatientId, text: row.PatientName, GUID: row.PatientId })
        });

        return getFilteredArray(patientsArray)
    }

    const getTopicsToFilter = (managerViewRows) => {
        let topicsArray = managerViewRows.map(row => ({ value: row.Topic, text: row.Topic }))

        return getFilteredArray(topicsArray)
    }

    useEffect(() => {
        if (rows && rows.length > 0) {
            getCoaches()
            setCompaniesToFilter(getCompaniesToFilter(rows))
            setPatientsToFilter(getPatientsToFilter(rows))
            setTopicsToFilter(getTopicsToFilter(rows))
        }
    }, [rows])

    const cancelSelectedAppointment = (chatId) => {
        setChatToBeUsed(chatId)
        CancelAppointmentGridOpenModal()
    }

    const rescheduleSelectedAppointment = (chatId, chat_type) => {
        setChatToBeUsed(chatId)
        setChatTypeToBeUsed(getChatType(chat_type))
        RescheduleAppointmentGridOpenModal()
    }

    return (
        <div className="patients-table-page">
            <PageHeader title="Manager View" />
            <SelectRowContext.Provider
                value={{
                    toggleAllRows,
                    toggleRow,
                    selectedRows,
                    isRowSelected,
                    clearSelectedRows,
                    cancelSelectedAppointment,
                    rescheduleSelectedAppointment
                }}
            >
                <Table
                    query={query}
                    data={data}
                    queryDispatcher={queryDispatcher}
                    isFetching={isFetching}
                    containerClass="query-table patients-table dynamic-columns"
                    columns={[
                        {
                            header: "all",
                            field: "all",
                            Cell: () => (
                                <RowSelectHeaderCell />
                            )
                        },
                        {
                            header: "Appointment Date",
                            field: "AppointmentDate",
                            Cell: () => (
                                <DateTimeHeaderColumn
                                    header="Appointment Date"
                                    field="AppointmentDate"
                                    isDateInput={true}
                                    dropdownPosition="right"
                                    decreaseFontSize={true}
                                />
                            )
                        },
                        {
                            header: "Start Time",
                            field: "StartTime",
                            dropdownPosition: "right",
                            Cell: () => (
                                <DateTimeHeaderColumn
                                    header="Start Time"
                                    field="StartTime"
                                    isDateInput={false}
                                    showFilterTags={true}
                                />
                            )
                        },
                        {
                            header: "Duration",
                            field: "DurationInMinutes",
                        },
                        {
                            header: "Coach",
                            field: "CoachName",
                            filters: coachesToFilter,
                            filterDownSelect: true,
                        },
                        {
                            header: "Company",
                            field: "CompanyName",
                            filters: companiesToFilter,
                            filterDownSelect: true,
                        },
                        {
                            header: "Chat Type",
                            field: "ChatType",
                            filters: [
                                { text: "Text Chat", value: "Text Chat" },
                                { text: "Video Chat", value: "Video Chat" },
                                { text: "Product Demo", value: "Product Demo" },
                            ],
                        },
                        {
                            header: "Patient",
                            field: "PatientName",
                            filters: patientsToFilter,
                            filterDownSelect: true,
                        },
                        {
                            header: "Topic",
                            field: "Topic",
                            filters: topicsToFilter
                        },
                        {
                            header: "Status",
                            field: "Status",
                            filters: [
                                { text: "Confirmation Pending", value: "Confirmation Pending" },
                                { text: "Confirmed", value: "Confirmed" },
                                { text: "Cancelled", value: "Cancelled" },
                                { text: "Expired", value: "Expired" },
                                { text: "Completed", value: "Completed" }
                            ]
                        },
                        {
                            header: "Reschedule",
                            field: "CanReschedule",
                        },
                        {
                            header: "Cancel",
                            field: "CanCancel"
                        }
                    ]}
                    rows={rows}
                    TableRow={ManagerViewTableRow}
                    rowKey="id"
                    perPageOptions={[
                        { text: "50", value: 50 },
                        { text: "150", value: 150 },
                        { text: "500", value: 500 }
                    ]}
                    Controls={
                        <ManagerViewTableControls
                            query={query}
                            queryDispatcher={queryDispatcher}
                            selectedRows={selectedRows}
                            isFetching={isFetching}
                            refreshData={() => {
                                fetchData(query);
                                clearSelectedRows()
                            }}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            dateTo={dateTo}
                            setDateTo={setDateTo}
                        />
                    }
                />
            </SelectRowContext.Provider>

            <CancelAppointmentGridModal
                setIsSubmitting={null}
                chat_id={chatToBeUsed}
                onSuccess={() => fetchData(query)}
            />

            <RescheduleAppointmentGridModal
                setIsSubmitting={null}
                chat_id={chatToBeUsed}
                onSuccess={() => fetchData(query)}
                chat_type={chatTypeToBeUsed}
            />
        </div>
    )
}

ManagerView.propTypes = {
    timeZone: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    resetTimeZoneValue: PropTypes.func
}
