import includes from "lodash/includes";
import * as R from "ramda";

const cleanQuery = (visibleColumns, query) => {
  const newQuery = R.reduce(
    (acc, key) => {
      switch (key) {
        case "filters": {
          const origFilters = R.propOr({}, "filters", query);
          const newFilters = R.reduce(
            (acc, key) =>
              includes(visibleColumns, key)
                ? {
                    ...acc,
                    [key]: origFilters[key]
                  }
                : { ...acc, [key]: [] },
            {},
            R.keys(origFilters)
          );

          return { ...acc, filters: newFilters };
        }
        case "sort": {
          const sort = R.propOr({}, "sort", query);
          return includes(visibleColumns, sort.by)
            ? { ...acc, sort }
            : { ...acc, sort: {} };
        }
        default:
          return { ...acc, [key]: query[key] };
      }
    },
    {},
    R.keys(query)
  );
  return newQuery;
};

export default cleanQuery;
