import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { PureModal, TableButton } from "components/ui";
import IoMdRefresh from "react-icons/lib/io/refresh";
import IoMdClipboard from "react-icons/lib/io/clipboard";
import { setOrderBatchIds } from "actions/batch";
import BulkOrderUpdate from "components/bulk-order-update";
import { createTab } from "actions/system-tray";
import {
  exportOrdersInvoiceReport,
  exportOrdersReport
} from "actions/orders-table";
import MdPrint from "react-icons/lib/md/print";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import IoIosPaper from "react-icons/lib/io/ios-paper";
import IoMdEdit from "react-icons/lib/io/edit";
import { browserHistory } from "browser-history";
import { TableSettings } from "hooks/useTableSettings";
import { OrdersTableSettingsContext } from "./settings";
import HasRolePermission from "components/shared/has-role-permission";

const OrdersTableControls = ({
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows,
  query
}) => {
  const dispatch = useDispatch();
  const { tableSettings, updateTableSettings, columns, resetTableSettings } =
    useContext(OrdersTableSettingsContext);
  return (
    <div className="query-table-controls">
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoMdRefresh />
      </TableButton>
      <HasRolePermission
        allowedRoles={[
          "Administrator",
          "ServiceAdmin",
          "ServiceCoach",
          "OfficeAdministrator",
          "CompanyAdministrator",
          'ContractorAdministrator'
        ]}
      >
        <TableButton
          title="Export orders to excel file"
          onClick={() => {
            dispatch(exportOrdersReport(query));
          }}
        >
          <FaFileExcelO />
        </TableButton>
      </HasRolePermission>
      <TableButton
        onClick={() => {
          dispatch(exportOrdersInvoiceReport(query));
        }}
        title="Export Invoice Report(all orders)."
      >
        <IoIosPaper />
      </TableButton>
      <HasRolePermission
        allowedRoles={[
          "Administrator",
          "ServiceAdmin",
          "ServiceCoach",
          "OfficeAdministrator",
          "CompanyAdministrator",
          'ContractorAdministrator'
        ]}
      >
        <TableButton
          title={`Print orders (${selectedRows.length} selected)`}
          disabled={selectedRows.length === 0}
          onClick={() => {
            browserHistory.push({
              pathname: "/orders/batch-print-orders",
              state: { orderIds: selectedRows.map(x => x.id) }
            });
          }}
        >
          <MdPrint />
        </TableButton>
      </HasRolePermission>
      <TableButton
        title={`Process orders (${selectedRows.length} selected)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          const orderIds = selectedRows.map(x => x.id);
          const orderTitles = selectedRows.map(x => x.invoice_number);
          dispatch(setOrderBatchIds(orderIds, orderTitles));
          dispatch(
            createTab({
              type: "orders",
              key: "orders",
              label: `Orders (${orderIds.length})`,
              meta: { ids: orderIds }
            })
          );
          clearSelectedRows();
        }}
      >
        <IoMdClipboard size={24} />
      </TableButton>
      <PureModal
        renderTrigger={({ openModal }) => (
          <TableButton
            disabled={selectedRows.length === 0}
            onClick={openModal}
            title={`Bulk update orders (${selectedRows.length} selected)`}
          >
            <IoMdEdit />
          </TableButton>
        )}
        renderContent={({ closeModal }) => (
          <BulkOrderUpdate
            orderIds={selectedRows.map(x => x.id)}
            onUpdateError={() => {
              clearSelectedRows();
            }}
            onUpdateSuccess={() => {
              closeModal();
              clearSelectedRows();
              refreshData();
            }}
          />
        )}
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} Orders</span>
        )}
      </div>
      <div className="action-buttons companies-table-action-buttons">
        <TableSettings
          columns={columns}
          tableSettings={tableSettings}
          updateTableSettings={updateTableSettings}
          resetTableSettings={resetTableSettings}
        />
      </div>
    </div>
  );
};

export default OrdersTableControls;

OrdersTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired
};
