import { fetchInventoryItem, invalidateInventoryItem } from "actions/inventory";
import { message, errorMessage } from "actions/message";
import { post, get, put } from "utils/api";

export const Types = {
  POST_PRODUCT_PHOTO: "POST_PRODUCT_PHOTO",
  GET_PRODUCT_VIDEOS: "GET_PRODUCT_VIDEOS",
  GET_PRODUCT_PHOTOS: "GET_PRODUCT_PHOTOS",
  SET_PHOTO_ACTIVE: "SET_PHOTO_ACTIVE",
  SET_PHOTO_PRIMARY: "SET_PHOTO_PRIMARY",
  SET_VIDEO_ACTIVE: "SET_VIDEO_ACTIVE",
  SET_VIDEO_PRIMARY: "SET_VIDEO_PRIMARY",
};

// Actions
const getProductVideosAction = (videos) => ({
  type: Types.GET_PRODUCT_VIDEOS,
  payload: videos,
});

const getProductPhotosAction = (photoUrls) => ({
  type: Types.GET_PRODUCT_PHOTOS,
  payload: photoUrls,
});

const setPhotoActiveAction = (mediaId, active) => ({
  type: Types.SET_PHOTO_ACTIVE,
  payload: { mediaId, active },
});

const setPhotoPrimaryAction = (mediaId, primary) => ({
  type: Types.SET_PHOTO_PRIMARY,
  payload: { mediaId, primary },
});

const setVideoActiveAction = (mediaId, active) => ({
  type: Types.SET_VIDEO_ACTIVE,
  payload: { mediaId, active },
});

const setVideoPrimaryAction = (mediaId, primary) => ({
  type: Types.SET_VIDEO_PRIMARY,
  payload: { mediaId, primary },
});

// Action Creators
export const uploadProductPhoto = ({ payload, productId }) => (dispatch) => {
  return post(`dme_portal/product/photo/${productId}/true`, payload)
    .then((response) => {
      dispatch(invalidateInventoryItem({ inventoryId: productId }));
      dispatch(fetchInventoryItem({ inventoryId: productId }));
      dispatch(message("Photo Uploaded Successfully!"));
      dispatch(getProductPhotos(productId))
      return response;
    })
    .catch(() => {
      dispatch(errorMessage("Photo Could Not Be Uploaded."));
    });
};

export const uploadProductVideo = (productId, payload) => async dispatch => {
  try {
    await post(`dme_portal/products/${productId}/videos`, payload);
    dispatch(message("Video Uploaded Successfully!"));
    await dispatch(getProductVideos(productId));
  } catch (err) {
    dispatch(errorMessage("Video Could Not Be Uploaded."));
  }
};

export const getProductVideos = (productId) => async (dispatch) => {
  const response = await get(`dme_portal/products/${productId}/videos`);
    dispatch(getProductVideosAction(response.media ?? []));
    if (response.success === true) return;
    dispatch(errorMessage("Unable to retrieve product videos."));
};

export const getProductPhotos = (productId) => async (dispatch) => {
  const response = await get(`dme_portal/products/${productId}/photos`);
    dispatch(getProductPhotosAction(response.media ?? []));
    if (response.success === true) return;
    dispatch(errorMessage("Unable to retrieve product photos."));
};

export const setPhotoActive = (productId, mediaId, active) => async (dispatch) => {
  try {
    const payload = [{ id: mediaId, active }];
    await put(`dme_portal/products/${productId}/photos/`, payload);
    dispatch(setPhotoActiveAction(mediaId, active));
  } catch (err) {
    dispatch(errorMessage("Error when updating photo."));
  }
};

export const setPhotoPrimary = (productId, mediaId, primary) => async (dispatch) => {
  try {
    const payload = [{ id: mediaId, primary }];
    await put(`dme_portal/products/${productId}/photos/`, payload);
    dispatch(invalidateInventoryItem({ inventoryId: productId }));
    dispatch(fetchInventoryItem({ inventoryId: productId }));
    dispatch(setPhotoPrimaryAction(mediaId, primary));
  } catch (err) {
    dispatch(errorMessage("Error when updating photo."));
  }
};

export const setVideoActive = (productId, mediaId, active) => async (dispatch) => {
  try {
    const payload = [{ id: mediaId, active }];
    await put(`dme_portal/products/${productId}/videos/`, payload);
    dispatch(setVideoActiveAction(mediaId, active));
  } catch (err) {
    dispatch(errorMessage("Error when updating photo."));
  }
};

export const setVideoPrimary = (productId, mediaId, primary) => async (dispatch) => {
  try {
    const payload = [{ id: mediaId, primary }];
    await put(`dme_portal/products/${productId}/videos/`, payload);
    dispatch(invalidateInventoryItem({ inventoryId: productId }));
    dispatch(fetchInventoryItem({ inventoryId: productId }));
    dispatch(setVideoPrimaryAction(mediaId, primary));
  } catch (err) {
    dispatch(errorMessage("Error when updating photo."));
  }
};
