import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import ModalLink from "components/shared/modal-link";
import { withHandlers } from "recompose";
import compose from "lodash/fp/compose";
import pick from "lodash/pick";
import moment from "moment";
import { FieldTable, withModal } from "components/ui";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import { requestUpdateOrderClaimedBy } from "actions/order-status";
import Header from "./header";
import { formatPhone } from "utils/misc";

const formatDate = date =>
  date ? moment(date).format("MMMM Do YYYY, h:mm a") : null;

const OrderInformation = ({
  isFetching,
  isUpdating,
  sales_order_number,
  account_number,
  invoice_number,
  street_address,
  street_address2,
  city,
  state,
  zip,
  order_date,
  company,
  sleep_coach_patient_current,
  sleep_coach_patient_current_id,
  sleep_coach_assignment_id,
  sleep_coach_assignment_name,
  phone_number,
  insurance,
  submitted_by,
  adminName,
  adminId,
  userId,
  patientId,
  canClaim,
  handleClaim,
  canEditSalesOrderNumber,
  handleSalesOrderClick,
  patientEmail,
  niko_info,
  supply_period
}) => (
  <div className="order-information">
    {!isFetching && niko_info?.niko_order && (
      <div style={{ fontSize: "medium", fontWeight: "bold" }}>
        <a
          href={
            niko_info?.niko_order_id != ""
              ? `${niko_info?.niko_url}v2/#/orders/order/${niko_info?.niko_order_id}/details`
              : `${niko_info?.niko_url}v2/#/patients/${account_number}/demographics`
          }
          target="_blank"
          rel="noreferrer"
        >
          {niko_info?.niko_order_id != ""
            ? "Niko Health Order Details"
            : "Niko Health User Profile"}
        </a>
      </div>
    )}
    <Header showSpinner={isFetching} className="order-information-header">
      Order Information
    </Header>
    <FieldTable
      columnCount={2}
      rows={[
        [
          "Sales Order",
          <span
            key="order-sales-number"
            className={`${
              canEditSalesOrderNumber && !isUpdating
                ? "edit-sales-order-number"
                : ""
            }`}
            onClick={
              canEditSalesOrderNumber && !isUpdating
                ? handleSalesOrderClick
                : undefined
            }
          >
            {sales_order_number && sales_order_number.trim()
              ? sales_order_number
              : canEditSalesOrderNumber && !isUpdating
              ? "Add"
              : ""}
          </span>
        ],
        [
          "Account Number",
          patientId ? (
            <ModalLink to={`/patients/${patientId}`}>
              {account_number}
            </ModalLink>
          ) : (
            account_number
          )
        ],
        ["Invoice Number", invoice_number],
        ["Street Address", street_address],
        ["Street Address Line Two", street_address2],
        ["City", city],
        ["State", state],
        ["ZIP", zip],
        ["Order Date", formatDate(order_date)],
        ["Company", company],
        [
          "Sleep Coach",
          sleep_coach_patient_current_id ? (
            <ModalLink to={`/users/${sleep_coach_patient_current_id}`}>
              {sleep_coach_patient_current}
            </ModalLink>
          ) : (
            sleep_coach_patient_current
          )
        ],
        [
          "Assignment",
          sleep_coach_assignment_id ? (
            <ModalLink to={`/users/${sleep_coach_assignment_id}`}>
              {sleep_coach_assignment_name}
            </ModalLink>
          ) : (
            sleep_coach_assignment_name
          )
        ],
        [
          "Admin",
          canClaim && !isFetching && !isUpdating && adminId !== userId ? (
            <span onClick={handleClaim} className="admin-claim">
              {(adminName && adminName.trim()) || "Claim"}
            </span>
          ) : (
            adminName
          )
        ],
        ["Phone Number", formatPhone(phone_number)],
        [
          "Email",
          <a key={`${userId}-${patientEmail}`} href={`mailto:${patientEmail}`}>
            {patientEmail}
          </a>
        ],
        ["Insurance", insurance],
        ["Submitted By", submitted_by],
        [
          "Quantity Requested",
          supply_period ? `${supply_period} day supply` : ""
        ]
      ].map(([field, value]) => ({ field, value }))}
    />
  </div>
);

export default compose(
  connect(
    (state, props) => {
      const { data: orderInfo, isFetching: isFetchingOrderInfo } =
        selectors.getOrderInfo(state, props);
      const { data: adminInfo, isFetching: isFetchingOrderAdminInfo } =
        selectors.getOrderAdminInfo(state, props);
      const { data: patientInfo, isFetching: isFetchingOrderPatientInfo } =
        selectors.getOrderPatientInfo(state, props);
      return {
        ...pick(orderInfo, [
          "sales_order_number",
          "account_number",
          "invoice_number",
          "street_address",
          "street_address2",
          "city",
          "state",
          "zip",
          "order_date",
          "company",
          "sleep_coach_patient_current",
          "sleep_coach_patient_current_id",
          "sleep_coach_assignment_name",
          "sleep_coach_assignment_id",
          "phone_number",
          "insurance",
          "submitted_by",
          "supply_period",
          "niko_info"
        ]),
        adminName: adminInfo && adminInfo.name,
        adminId: adminInfo && adminInfo.id,
        patientId: patientInfo && patientInfo.patient_id,
        isFetching:
          isFetchingOrderInfo ||
          isFetchingOrderAdminInfo ||
          isFetchingOrderPatientInfo,
        isUpdating: selectors.getIsUpdatingOrder(state, props),
        canClaim: selectors.getCanCurrentUserClaimOrder(state),
        canEditSalesOrderNumber: selectors.getCanEditSalesOrderNumber(
          state,
          props
        ),
        patientEmail: patientInfo && patientInfo.email,
        userId: selectors.getUser(state).id
      };
    },
    (dispatch, props) => ({
      ...mapDispatchToProps(dispatch, props),
      ...bindActionCreators({ requestUpdateOrderClaimedBy }, dispatch)
    })
  ),
  withModal,
  withHandlers({
    handleClaim:
      ({ orderId, userId, requestUpdateOrderClaimedBy }) =>
      () => {
        requestUpdateOrderClaimedBy(orderId, userId);
      },
    handleSalesOrderClick:
      ({
        openModal,
        closeModal,
        sales_order_number,
        requestSalesOrderNumberUpdate
      }) =>
      () => {
        let input;
        openModal(
          <div className="sales-order-form">
            <Header>Sales Order Number</Header>
            <form
              onSubmit={event => {
                event.preventDefault();
                requestSalesOrderNumberUpdate(input.value);
                closeModal();
              }}
            >
              <input
                type="text"
                ref={i => (input = i)}
                defaultValue={sales_order_number}
                maxLength={70}
              />
              <div className="submit-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        );
      }
  })
)(OrderInformation);

OrderInformation.propTypes = {
  account_number: PropTypes.string,
  adminId: PropTypes.string,
  adminName: PropTypes.string,
  canClaim: PropTypes.bool,
  canEditSalesOrderNumber: PropTypes.bool,
  city: PropTypes.string,
  company: PropTypes.string,
  handleClaim: PropTypes.func.isRequired,
  handleSalesOrderClick: PropTypes.func.isRequired,
  insurance: PropTypes.string,
  invoice_number: PropTypes.string,
  isFetching: PropTypes.bool,
  isUpdating: PropTypes.bool,
  niko_info: PropTypes.object,
  order_date: PropTypes.string,
  patientId: PropTypes.string,
  phone_number: PropTypes.string,
  sales_order_number: PropTypes.string,
  sleep_coach_patient_current: PropTypes.string,
  sleep_coach_patient_current_id: PropTypes.string,
  sleep_coach_assignment_name: PropTypes.string,
  sleep_coach_assignment_id: PropTypes.string,
  state: PropTypes.string,
  street_address: PropTypes.string,
  street_address2: PropTypes.string,
  submitted_by: PropTypes.string,
  supply_period: PropTypes.number,
  userId: PropTypes.string,
  zip: PropTypes.string,
  patientEmail: PropTypes.string
};
