import {
  delay,
  all,
  race,
  call,
  take,
  takeLatest,
  put,
  select
} from "redux-saga/effects";
import data from "./data";
import notes from "./notes";
import update from "./update";
import lineItems from "./line-items";
import tracking from "./tracking";
import types from "./types";
import logs from "./logs";
import { selectors } from "reducers";
import { Types as BatchTypes } from "actions/batch";
import {
  Types,
  invalidateOrders,
  requestPatientInfo,
  requestNotes,
  requestPhysicianInfo,
  requestComplianceInfo,
  requestEquipmentInfo,
  requestDysfunctionInfo,
  requestCompanyInfo,
  requestLineItems,
  requestChangeLogs,
  requestAccessLogs,
  requestOrderInfo,
  requestOrderProfileAccess
} from "actions/order-profile";

const getOrder = orderId => state =>
  selectors.getOrderInfo(state, { orderId }).data;

function* fetchOrderProfile(action) {
  const { orderId, useCache } = action;
  const orderExists = yield select(getOrder(orderId));
  if (!useCache || !orderExists) {
    yield all([
      put(requestOrderProfileAccess({ orderId })()),
      put(requestPatientInfo({ orderId })()),
      put(requestNotes({ orderId })()),
      put(requestPhysicianInfo({ orderId })()),
      put(requestEquipmentInfo({ orderId })()),
      put(requestComplianceInfo({ orderId })()),
      put(requestDysfunctionInfo({ orderId })()),
      put(requestCompanyInfo({ orderId })()),
      put(requestLineItems({ orderId })()),
      put(requestChangeLogs({ orderId })()),
      put(requestAccessLogs({ orderId })()),
      put(requestOrderInfo({ orderId })())
    ]);
    yield race({
      success: all([
        take(Types.RESPONSE_PATIENT_INFO),
        take(Types.RESPONSE_NOTES),
        take(Types.RESPONSE_EQUIPMENT_INFO),
        take(Types.RESPONSE_DYSFUNCTION_INFO),
        take(Types.RESPONSE_COMPLIANCE_INFO),
        take(Types.RESPONSE_COMPANY_INFO),
        take(Types.RESPONSE_LINE_ITEMS),
        take(Types.RESPONSE_ACCESS_LOGS),
        take(Types.RESPONSE_CHANGE_LOGS),
        take(Types.RESPONSE_ORDER_INFO)
      ]),
      timeout: delay(20000) //timeout saga after 30 seconds
    });
  }
}

export function* watchFetchOrderProfile() {
  yield takeLatest(Types.REQUEST_ORDER_PROFILE, fetchOrderProfile);
}
// function* fetchBatchOrders(ids) {
//   const fetchAll = ids.map(id => call(fetchOrderProfile, id));
//   // Call each in order they are present in the array
//   // waterfall orderProfile requests
//   for (let fetchOrder of fetchAll) {
//     yield fetchOrder;
//   }
// }
//
// function* watchBatchOrderProcess() {
//   while (true) {
//     const {
//       payload: { ids = [] }
//     } = yield take(SET_ORDER_BATCH_IDS);
//     // cancel on new batch proccess or close modal.
//     yield race([call(fetchBatchOrders, ids), take(SET_ORDER_BATCH_IDS)]);
//   }
// }

function* clearCachedOrders() {
  while (true) {
    yield take(BatchTypes.SET_ORDER_BATCH_IDS);
    yield put(invalidateOrders());
  }
}
export default function* orderProfile() {
  yield all([
    call(clearCachedOrders),
    call(watchFetchOrderProfile),
    call(data),
    call(notes),
    call(update),
    call(lineItems),
    call(tracking),
    call(types),
    call(logs)
  ]);
}
