import React, { Component } from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";

class MultiPageForm extends Component {
  static propTypes = {
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        view: PropTypes.node.isRequired,
        validation: PropTypes.bool,
        NextButton: PropTypes.func
      })
    ).isRequired,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      activePageIndex: 0
    };
  }

  handleBack = () =>
    this.setState(({ activePageIndex }) => ({
      activePageIndex: activePageIndex - 1
    }));

  handleNext = () => {
    this.setState(({ activePageIndex }) => ({
      activePageIndex: activePageIndex + 1
    }));
  };

  handleTraverse = activePageIndex => this.setState({ activePageIndex });

  render() {
    const { activePageIndex } = this.state;
    const { pages, onSubmit, isSubmitting } = this.props;
    const { view, validation, NextButton } = pages[activePageIndex];
    const numPages = pages.length;

    return (
      <div className="multi-page-form">
        {isSubmitting && <div className="multi-page-form-submit-overlay"></div>}
        <div className="multi-page-form-breadcrumbs">
          {pages.map(({ title, subtitle }, index) => (
            <Breadcrumb
              key={index}
              index={index}
              title={title}
              subtitle={subtitle}
              active={index === activePageIndex}
              onTraverse={
                index < activePageIndex ? this.handleTraverse : undefined
              }
            />
          ))}
        </div>
        <div className="multi-page-form-page">{view}</div>
        <div className="multi-page-form-nav">
          {isSubmitting && <InlineWaiting />}
          {activePageIndex > 0 && (
            <button
              className="secondary"
              onClick={this.handleBack}
              disabled={isSubmitting}
              type="button"
            >
              Back
            </button>
          )}
          {activePageIndex < numPages - 1 && (
            <React.Fragment>
              {NextButton ? (
                <NextButton handleNext={this.handleNext} />
              ) : (
                <button
                  onClick={this.handleNext}
                  disabled={validation !== undefined && !validation}
                  type="button"
                >
                  Next
                </button>
              )}
            </React.Fragment>
          )}
          {activePageIndex === numPages - 1 && onSubmit && (
            <button
              onClick={onSubmit}
              disabled={
                (validation !== undefined && !validation) || isSubmitting
              }
              type="submit"
            >
              Submit
            </button>
          )}
        </div>
      </div>
    );
  }
}

class Breadcrumb extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onTraverse: PropTypes.func,
    active: PropTypes.bool
  };

  handleClick = () => {
    const { onTraverse, index } = this.props;
    if (onTraverse) {
      onTraverse(index);
    }
  };

  render() {
    const { index, active, onTraverse, title, subtitle } = this.props;
    return (
      <div
        onClick={this.handleClick}
        className={`multi-page-form-breadcrumb${active ? " active" : ""}${
          onTraverse ? " traversable" : ""
        }`}
      >
        <div className="multi-page-form-breadcrumb-title">
          {index + 1}: {title}
        </div>
        {subtitle && (
          <div className="multi-page-form-breadcrumb-subtitle">{subtitle}</div>
        )}
      </div>
    );
  }
}

export default MultiPageForm;
