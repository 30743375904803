import React from "react";
import { Switch, Route } from "react-router-dom";
import ContactLogicTableManager from "./contact-logic-table-manager";

const CompanyContactLogic = () => {
  return (
    <div>
      <Switch>
        <Route path="/companies/contact-logic/:patientType" component={ContactLogicTableManager} />
      </Switch>
    </div>
  );
};

export default CompanyContactLogic;
