import React from "react";
import PropTypes from "prop-types";
import MdWarning from "react-icons/lib/md/warning";
import { Spinner } from ".";

const ConfirmationDialog = ({
  warningMsg,
  continueMsg,
  cancelMsg,
  onCancel,
  onConfirm,
  children,
  cancelButtonStyles,
  confirmButtonStyles,
  isFetching
}) => (
  <div style={{ padding: 5 }}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 20
      }}
    >
      {warningMsg && (
        <React.Fragment>
          <span style={{ padding: "0 10px", color: "rgb(232, 145, 12)" }}>
            <MdWarning size={32} />
          </span>
          <span
            style={{
              fontSize: "1.15rem"
            }}
          >
            {warningMsg}
          </span>
        </React.Fragment>
      )}
      {children}
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: 10
      }}
    >
        {
            isFetching ?
            <Spinner size="15px"/>
            :
            <>
                <button onClick={onCancel} className={`${cancelButtonStyles ?? "secondary"}`}>
                    {cancelMsg ?? "cancel"}
                </button>
                <button className={`${confirmButtonStyles}`} onClick={onConfirm}>{continueMsg}</button>
            </>
        }
    </div>
  </div>
);

ConfirmationDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  warningMsg: PropTypes.string,
  continueMsg: PropTypes.string,
  cancelMsg:  PropTypes.string,
  children: PropTypes.node,
  cancelButtonStyles: PropTypes.string,
  confirmButtonStyles: PropTypes.string,
  isFetching: PropTypes.bool
};

export default ConfirmationDialog;
