import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import OpenOrdersTable from "./open-orders-table";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import IoIosInformationCircleOutline from "react-icons/lib/io/ios-information";

const CoachOpenOrders = ({ data, minimizeAll }) => {
  const [isMinimized, setMinimized] = useState(false);
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  return (
    <div className="block open-orders">
      <div className="block-header">
        <span onClick={() => setMinimized(!isMinimized)}>
          Open Orders
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
        <Tooltip
          id={`open-orders-tooltip`}
          className="open-orders-tooltip"
          effect="solid"
          message="Number of new orders and number of coach follow up orders that have not changed since 8am today"
        >
          <IoIosInformationCircleOutline size="1rem" />
        </Tooltip>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <OpenOrdersTable data={data} />
        </div>
      )}
    </div>
  );
};

CoachOpenOrders.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool
};

export default connect(state => ({
  data: selectors.getTeamCoachOpenOrders(state)
}))(CoachOpenOrders);
