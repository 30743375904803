export default {
  // OAth 2.0 Client Id
  CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  // APi Key
  API_KEY: process.env.GOOGLE_API_KEY,
  // Array of API discovery doc URLs for APIs used by the quickstart
  DISCOVERY_DOCS: [
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
  ],
  // Authorization scopes required by the API; multiple scopes can be
  SCOPES: "https://www.googleapis.com/auth/drive https://mail.google.com",
  // list of shared folders to integrate with phoenix
  // TODO find alternate implementation for this?
  FOLDERS: ["Company Info Sheets", "Outreach Templates"]
};
