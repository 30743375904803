import React, { useContext } from "react";
import PropTypes from "prop-types";
import QueryTableContext from "./context";
import { prop } from "ramda";

const TableBody = ({ TableRow, rowKey }) => {
  const { rows } = useContext(QueryTableContext);
  return (
    <tbody className="body">
      {rows.map((row, index) => (
        <TableRow {...row} key={prop(rowKey, row)} index={index} />
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  TableRow: PropTypes.elementType.isRequired,
  rowKey: PropTypes.string.isRequired
};

export default TableBody;
