import {
  delay,
  take,
  call,
  fork,
  select,
  all,
  put,
  takeLatest,
  takeEvery,
  cancel,
  race
} from "redux-saga/effects";
import { Types, setPhoneRecordingStatus } from "actions/phone";
import { listenConnectionEvents } from "./event-handler";
import { selectors } from "reducers";
import * as api from "utils/api";
import { Device } from "twilio-client";
import { setDialerCallPromptStatus } from "actions/auto-dialer";
/* eslint-disable no-console */

function* watchHangup() {
  while (true) {
    yield take(Types.HANGUP);
    yield call(Device.disconnectAll);
  }
}

function* watchMute() {
  while (true) {
    yield take(Types.TOGGLE_MUTE);
    const connection = Device.activeConnection();
    if (connection) {
      connection.mute(!connection.isMuted());
    }
  }
}

function* watchPauseRecording() {
  yield takeLatest(
    Types.PAUSE_PHONE_RECORDING,
    updatePhoneRecordingStatus,
    true
  );
}

function* watchResumeRecording() {
  yield takeLatest(
    Types.RESUME_PHONE_RECORDING,
    updatePhoneRecordingStatus,
    false
  );
}

function* updatePhoneRecordingStatus(pause) {
  try {
    const callDirection = yield select(state =>
      selectors.getCallDirection(state)
    );
    const connection = Device.activeConnection();
    if (connection) {
      const { status } = yield call(
        api.post,
        `twilio/new/general/recording/${callDirection}/${pause}`
      );
      yield put(setPhoneRecordingStatus(status));
    }
  } catch (e) {
    //eslint-disable-next-line
    console.error(e);
  }
}

function* watchCallActions() {
  yield all([
    call(watchHangup),
    call(watchMute),
    call(watchPauseRecording),
    call(watchResumeRecording)
  ]);
}

function* handleCallConnection({ connection }) {
  try {
    const connectionHandlerTask = yield fork(
      listenConnectionEvents,
      connection
    );
    yield put({
      type: Types.SET_TWILIO_CALLSID,
      payload: connection?.parameters?.CallSid
    }); //for outbound calls only
    const watchCallActionsTask = yield fork(watchCallActions);
    const digitSendTask = yield fork(watchDigitInputTask, connection);
    const durationTask = yield fork(updateCallDuration);

    //Ask for call disposition here
    const { leftMessage } = yield race({
      leftMessage: take(Types.PLAY_AUTOMATED_VOICEMAIL_SUCCESS),
      ended: take(Types.TWILIO_EVENT_DISCONNECT)
    });
    //If we don't leave a message, ask for disposition
    //If we do, skip disposition
    var orderPlaced = yield select(state =>
      selectors.getAutodialerPlacedOrder(state)
    );
    if (!leftMessage && !orderPlaced)
      yield put(setDialerCallPromptStatus(true));
    else yield take(Types.TWILIO_EVENT_DISCONNECT);
    yield cancel([
      connectionHandlerTask,
      digitSendTask,
      durationTask,
      watchCallActionsTask
    ]);
  } catch (e) {
    console.error(e);
  }
}

function* watchDigitInputTask(connection) {
  yield takeEvery(Types.SEND_DIGITS, function ({ payload: { digits } }) {
    if (/^([0-9]|\*|#)+$/.test(digits)) {
      connection.sendDigits(digits);
    }
  });
}

function* updateCallDuration() {
  let seconds = 0;
  while (true) {
    yield put({
      type: Types.CALL_DURATION,
      payload: { seconds }
    });
    ++seconds;
    yield delay(1000);
  }
}

export default function* watchConnection() {
  yield takeEvery(Types.TWILIO_EVENT_CONNECT, handleCallConnection);
}
