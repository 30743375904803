import { post } from "utils/api";
import { showLoader, hideLoader } from "components/dd_newModules/Redux/Actions/common";
import { errorMessage, message } from "actions/message";

export const submitSupportIssue = (request) => async (dispatch)  => {
    try {
      let formData = new FormData();
      formData.append('attatchments', request.attatchments);
      formData.append('details', request.details);
      formData.append('files', request.files);
      formData.append('issue', request.issue);
      formData.append('issueType', request.issueType);
      formData.append('issuePriority', request.issuePriority);

      if (request.orderGUID) {
        formData.append('orderGUID', request.orderGUID);
      }
      if (request.patientGUID) {
        formData.append('patientGUID', request.patientGUID);
      }
      if (request.summary) {
        formData.append('summary', request.summary);
      }
      if (request.companyName) {
        formData.append('companyName', request.companyName);
      }
      if (request.email === request.emailOnFile) {
        formData.append('email', request.email);
      } else {
        formData.append('email', request.email);
        formData.append('emailOnFile', request.emailOnFile);
      }

      dispatch(showLoader());
      const response = await post(`support`, formData);
      if (response.success) {
        dispatch(hideLoader());
        dispatch(message(response.message));
      } else {
        dispatch(hideLoader());
        dispatch(errorMessage(response.message));
      }
    } catch (error) {
      dispatch(hideLoader());
      dispatch(errorMessage(`Jira issue submission failed, ${error}`));
    }
  }