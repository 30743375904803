import React from "react";
import slice from "lodash/slice";

export const makePagerFn = (data = [], perPage) => {
  if (!Array.isArray(data)) throw Error("makePagerFn: expected array");
  if (typeof perPage !== "number" || perPage <= 0)
    throw Error("makePagerFn: invalid perPage value");

  return (curPage, sortFn) => {
    if (typeof curPage !== "number") {
      //eslint-disable-next-line
      // console.warn(`curPage invalid value`);
      return [];
    }
    const totalPages = Math.ceil(
      data.length / (perPage <= data.length ? perPage : data.length)
    );
    if (curPage < 1 || curPage > totalPages) {
      //eslint-disable-next-line
      // console.warn(`curPage out of range expected 1 to ${totalPages}`);
      return [];
    }
    const begin = (curPage - 1) * perPage;
    const end = begin + perPage;
    if (typeof sortFn === "function") {
      return slice(sortFn(data), begin, end);
    }
    return slice(data, begin, end);
  };
};

export const getTotalPages = (array = [], perPage) => {
  if (!Array.isArray(array)) return 0;
  if (array.length === 0) return 0;
  // if (typeof perPage != "number" || perPage <= 0) return 0;
  return Math.ceil(
    array.length / (perPage <= array.length ? perPage : array.length)
  );
};

const usePagination = (array = [], perPage) => {
  // const [data, updateArray] = React.useState(array);
  const [curPageNum, setPageNum] = React.useState(1);
  const [pagerFn, setPagerFn] = React.useState(() =>
    makePagerFn(array, perPage)
  );
  const [totalPages, setTotalPages] = React.useState(
    getTotalPages(array, perPage)
  );

  //reset part, lets just set startedAt to now
  React.useEffect(
    () => {
      // updateArray(array),
      setPagerFn(() => makePagerFn(array, perPage));
      setTotalPages(getTotalPages(array, perPage));
      setPageNum(1);
    },
    [array] // Run this effect only if any variable
    // in that array is different from the last run
  );
  return {
    curPageNum,
    totalPages,
    setPageNum,
    getPageData: pagerFn
  };
};

export default usePagination;
