import React from "react";
import PropTypes from "prop-types";

const DraggableBucket = ({
  id,
  title,
  icon,
  onItemDropped,
  containerStyle,
  children
}) => {
  const onDragOver = event => {
    event.preventDefault();
  };

  const onDrop = event => {
    const droppedItemId = event.dataTransfer.getData("drag-item-id");
    if (droppedItemId) {
      onItemDropped(id, droppedItemId);
    }
  };
  
  return (
    <div className="draggable-bucket-container" style={containerStyle}>
      <div className="draggable-bucket" onDragOver={onDragOver} onDrop={onDrop}>
        <div className="draggable-bucket-title">
          {title}
          <div className="draggable-bucket-icon">{icon}</div>
        </div>
        {children}
      </div>
    </div>
  );
};

DraggableBucket.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string,
  icon: PropTypes.object,
  onItemDropped: PropTypes.func.isRequired,
  containerStyle: PropTypes.object,
  children: PropTypes.array
};

export default DraggableBucket;
