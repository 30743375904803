import { put, takeEvery, all } from "redux-saga/effects";
import * as R from "ramda";
import { message, errorMessage } from "actions/message";
import { Types, invalidateOrderInfo } from "actions/order-profile";
import A from "action-types";
import { pullById, takeMatch } from "utils/saga";

function* updateOrder({
  payload: { update },
  meta: { orderId }
}) {
  yield put({
    type: Types.API_ORDER_UPDATE,
    payload: {
      apiRequest: {
        method: 'PUT',
        route: `orders/${orderId}`,
        params: update,
        type: Types.RESPONSE_ORDER_UPDATE,
        meta: { orderId }
      }
    },
    meta: { orderId }
  })

  // keep task alive until matching response taken
  yield takeMatch({
    type: Types.RESPONSE_ORDER_UPDATE,
    meta: { orderId }
  })
}

const watchOrderUpdateRequest = pullById(Types.REQUEST_ORDER_UPDATE, 'meta.orderId', updateOrder)

const putInvalidateOrderInfo = orderId => put(invalidateOrderInfo({ orderId })())

const updateMessage = R.ifElse(
  R.has("error"),
  R.always(errorMessage("An error occurred while updating the order.")),
  R.always(message("Order update successful!"))
);

const watchOrderUpdateThroughProfile = takeEvery(
  Types.RESPONSE_ORDER_UPDATE,
  function*(action) {
    const orderId = R.path(["meta", "orderId"], action);
    yield put(invalidateOrderInfo({ orderId })());
    yield put(updateMessage(action));
  }
);

const putInvalidateOrdersFromIndex = R.pipe(
  R.path(['payload', 'entities', 'orderRows']),
  R.keys,
  R.map(putInvalidateOrderInfo),
  all
)

const watchOrderUpdateThroughIndex = takeEvery(
  A.ORDER_STATUS.UPDATE_RESPONSE,
  function* (action) {
    yield putInvalidateOrdersFromIndex(action)
  }
)

const watchOrderUpdateResponse = all([
  watchOrderUpdateThroughProfile,
  watchOrderUpdateThroughIndex
])

const rootEffect = all([
  watchOrderUpdateRequest,
  watchOrderUpdateResponse
])

export default function* rootUpdateSaga() {
  yield rootEffect
}
