import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import CardContainer from "./card-container";
import styler from "react-styling";
import moment from "moment"
import { get, put } from "utils/api";
import { withModal } from "components/ui";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import PencilEdit from "react-icons/lib/fa/edit";
import IconButton from "components/ui/icon-button";
import IoMdPlus from "react-icons/lib/io/plus";
import IoMdTrash from "react-icons/lib/io/trash-b";
import AnnouncementForm from "components/screens/admin-tools/announcements/announcements-form"

const getAnnouncements = () => {
  return get('announcements');
}

export const AnnouncementCard = ({ openModal, closeModal, isAdmin, classProp={} }) => {
  const [ announcements, setAnnouncements ] = useState([])
  

  const fetchAnnouncements = async () => {
    try {
      const announcements = await getAnnouncements()
      setAnnouncements(announcements);
    } catch (error) {
      console.error("ANNOUNCEMENTS ERROR: ", error);
    }
  }

  const inactivateAnnouncement = announcementId => {
    put(`announcements/${announcementId}`, { active: false }).then(() => {
      fetchAnnouncements()
    })
  };

  useEffect(()=> {
   fetchAnnouncements()
  }, [])

  return (
    <CardContainer title="Announcements" classProp={classProp}>
      <div className="announcement-card-container">
      {isAdmin && <div>
          <IconButton
            icon={<IoMdPlus />}
            text="Create"
            onClick={() => {
              openModal(
                <AnnouncementForm
                  closeModal={closeModal}
                  fetchAnnouncements={fetchAnnouncements}
                />
              );
            }}
            style={{ fontSize: "12px" }}
          />
        </div>}
          <div className="announcement-container">
            {announcements.map((ann) => (
              <div key={ann.id} style={S.singleAnnouncementContainer}>
                <h5>{ann.header}</h5>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={S.announcementHeaderDate}>
                    {moment(ann.dateToDisplay).format("MM/DD/YYYY")}
                  </div>
                 {isAdmin &&  <div style={S.announcementHeaderControls}>
                    <IconButton
                      icon={<PencilEdit />}
                      text="Edit"
                      onClick={() =>
                        openModal(
                          <AnnouncementForm
                            announcementToEdit={ann}
                            closeModal={closeModal}
                            fetchAnnouncements={fetchAnnouncements}
                          />
                        )
                      }
                      style={{ fontSize: "12px" }}
                    />
                    <IconButton
                      icon={<IoMdTrash />}
                      text="Inactivate"
                      onClick={() => inactivateAnnouncement(ann.id)}
                      style={{ fontSize: "12px" }}
                    />
                  </div>}
                </div>
                <pre className="announcement-body" style={S.announcementBody}>{ann.body}</pre>
                <div>
                  <a target="_blank" href={`${ann.link}`} rel="noopener noreferrer">
                    {ann.link}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
    </CardContainer>
  );
}

const S = styler`
  singleAnnouncementContainer
    border: 1px solid #d8d4d4
    borderRadius: 5px
    padding: .5rem
    margin: .75rem 0
  announcementHeaderDate
    width: 45%
    fontSize: 0.85rem 
  announcementHeaderControls
    display: flex;
    justify-content: flex-end;
    margin: 0 0 1rem 0;
  announcementHeader
    fontWeight: bold
  announcementBody
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
`;


export default compose(withModal, connect())(AnnouncementCard)

AnnouncementCard.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  classProp: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
