import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "../../../components/ModalComponent";
import { PageHeader, DataTable } from "components/ui";
import AddQuestion from "./newQuestion";
import EditQuestion from "./editQuestion";

export default function AddInfromation(props) {

    const [rows, setRows] = useState([]);
    const [modalOpen, modalChange] = useState(false);
    const [selectedQuestion, questionSelectionChange] = useState(null);

    const columns = [
        { title: "Question", header: "Question", field: (data) => <h4>{data.question_text}</h4> },
        { title: "Question Type", header: "Question Type", field: (data) => <h4>{checkTypeOfQuestion(data.question_type)}</h4> },
        {
            title: "Edit", header: "Edit", field: data => (
                data.active && <button type="button" onClick={() => onLinkEdit(data)} >Edit</button>
            )
        },
        {
            title: "Delete", header: "Delete", field: data => (
                data.active ? <button type="button" onClick={() => onDataDelete(data)} >Delete</button> : <h4><span style={{color:"red"}}>DELETED</span></h4>
            )
        }
    ]

    useEffect(didMount,[]);
    function didMount(){
        if(props.questionnaire && props.questionnaire.questions)
        {
            let questionCollection= [];
            props.questionnaire.questions.map((data) =>{
                let newQuestionCollection = {
                    active:data.active,
                    question_id : data.question_id,
                    question_type : data.question_type,
                    question_text : data.question_text,
                    options : data.options,
                    rowId : Math.random().toString(36).substring(7)
                }
                questionCollection.push(newQuestionCollection);
            });

            if(questionCollection.length > 0)
            {
                setRows(questionCollection);
                props.appendQuestionBank(questionCollection);
            }
        }
        return willUnmount
    }
   
    function willUnmount(){
      
    }

    function checkTypeOfQuestion(id) {
        let question_label = '';
        switch (parseInt(id)) {
            case 1:
                question_label = 'Yes/No';
                break;
            case 2:
                question_label = 'Multichoice';
                break;
            case 3:
                question_label = 'Text';
                break;
            case 4:
                question_label = 'Linkerscale';
                break;
            default:
                break;
        }
        return question_label
    }

    function onLinkEdit(data) {
        questionSelectionChange(data)
        onModalChange();
    }

    function onDataDelete(data) {
        let updatedRow = rows;
        if (data.question_id && data.question_id.length > 0) {
            updatedRow = rows.map(
                (content) => content === data ? { ...content, active: false }
                    : content);
        } else {
            updatedRow = rows.filter(m => m !== data);
        }
        setRows(updatedRow);
        props.appendQuestionBank(updatedRow);
    }

    function onModalChange() {
        modalChange(!modalOpen);
    }

    function addQuestion() {
        questionSelectionChange(null);
        onModalChange();
    }

    function addedNewQuestion(newQuestion){
        newQuestion.rowId = Math.random().toString(36).substring(7);
        //props.appendQuestionBank(newQuestion);
        setRows([...rows,newQuestion])
        onModalChange();
        props.appendQuestionBank([...rows,newQuestion]);
    }

    function editedQuestion(editQuestion) {
        let rowAfterEdit = rows.map(ques => {
            if (ques.rowId === editQuestion.rowId) {
                return editQuestion;
            }
            return ques;
        });

        setRows(rowAfterEdit);
        onModalChange();
        props.appendQuestionBank(rowAfterEdit);
    }

    return (
        <div>
            <Modal
                onClose={onModalChange}
                show={modalOpen}
            >
                {
                    (selectedQuestion) ? <EditQuestion question={selectedQuestion} editedQuestion={editedQuestion} /> : <AddQuestion newAddedQuestion={addedNewQuestion} /> 
                }
            </Modal>
            <PageHeader title="Questions of Questionnaire" />
            <div className="dd_col-10" />

            <button className="dd_col-2" type="button" onClick={addQuestion}>Add New Question</button>
            <DataTable
                columns={columns}
                rows={rows}
                disabledsort={true}
            />
        </div>
    )
}

AddInfromation.propTypes = {
  appendQuestionBank: PropTypes.func.isRequired,
  questionnaire: PropTypes.object.isRequired
}
