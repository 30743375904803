import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCompanyTable, exportCompanyTable } from "actions/companiesTable";
import { selectors } from "reducers";
import DataColumnFilterTable from "components/ui/data-column-filter-table";
import { useLocalStorage } from "hooks";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import CompanyTableRow from "./table-row";
import CompanySearchInput from "./search-input";
import IoMdRefresh from "react-icons/lib/io/refresh";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import * as R from "ramda";
import TableSettings from "components/ui/table-settings";
import useDateRange from "hooks/useDateRange";
import DateRange from "components/ui/date-range";
import { firstDayOfThisMonth, today } from "utils/dates";

const getMatches = (searchValue, rows) => {
  if (searchValue.length > 0)
    return rows.filter(({ company_name }) =>
      company_name.toLowerCase().includes(searchValue.toLowerCase())
    );
  else return rows;
};

const CompanyTable = ({
  fetchCompanyTable,
  companies,
  exportCompanyTable,
  teamNameOptions,
  isS3User
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [companySearchText, setCompanySearchText] = React.useState("");
  const [visibleColumns, setVisibleColumns] = useLocalStorage(
    "company-table-settings",
    {
      "Team Name": true,
      "Company Active": true,
      "Walk In Orders": true,
      "Total Orders": true,
      "Patient Insights": true,
      "Order Statuses": false,
      "Contact Insights": false,
      "Manages Compliance": false
    }
  );
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeIsValid
  } = useDateRange({
    startDate: firstDayOfThisMonth(),
    endDate: today()
  });
  async function requestCompanies() {
    try {
      setIsFetching(true);
      await fetchCompanyTable(startDate, endDate);
    } finally {
      setIsFetching(false);
    }
  }

  React.useEffect(() => {
    requestCompanies(startDate, endDate);
  }, []);

  const { rows, query, queryDispatcher } = useLocalQuery(companies, {
    sort: { by: "company_name", direction: "asc" },
    filters: { active: [true] }
  });

  return (
    <Table
      containerClass="query-table companies-table"
      isFetching={isFetching}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={getMatches(companySearchText, rows)}
      rowKey="id"
      TableRow={row => (
        <CompanyTableRow
          {...row}
          visibleColumns={visibleColumns}
          isS3User={isS3User}
        />
      )}
      columns={R.filter(R.is(Object), [
        {
          header: "Name",
          field: "company_name",
          style: { width: 200 }
        },
        visibleColumns["Team Name"] &&
          isS3User && {
            header: "Team",
            field: "team_name",
            filters: teamNameOptions,
            title: "Assigned Team"
          },
        visibleColumns["Company Active"] && {
          header: "Comp. Active",
          field: "active",
          filters: [
            { text: "Yes", value: true },
            { text: "No", value: false }
          ],
          title: "Company Active"
        },
        visibleColumns["Walk In Orders"] && {
          header: "Walk-in Orders",
          field: "walk_in_orders",
          title: "Total Walk In Orders"
        },
        visibleColumns["Total Orders"] && {
          header: "Orders",
          field: "orders.count",
          title: "Total Orders"
        },
        ...(visibleColumns["Patient Insights"]
          ? [
              {
                header: "Active",
                field: "patients.active",
                title: "Active Patients"
              },
              {
                header: "Inactive",
                field: "patients.inactive",
                title: "Inactive Patients"
              },
              {
                header: "Eligible",
                field: "patients.eligible",
                title: "Patients Eligible For Outreach"
              }
            ]
          : []),
        ...(visibleColumns["Order Statuses"]
          ? [
              {
                header: "New",
                field: "orders.new_count",
                title: "New Orders"
              },
              {
                header: "Ready For Review",
                field: "orders.ready_for_review_count",
                title: "Ready For Review"
              },
              {
                header: "IVR Pending Buildout",
                field: "orders.pending_buildout_count",
                title: "IVR Pending Buildout"
              },
              {
                header: "Pending",
                field: "orders.pending_count",
                title: "Pending Orders"
              },
              {
                header: "Coach Followup",
                field: "orders.coach_followup_count",
                title: "Coach Followup"
              },
              {
                header: "On Hold",
                field: "orders.on_hold_count",
                title: "Orders On Hold"
              },
              {
                header: "Location Assistance Needed",
                field: "orders.location_assistance_needed_count",
                title: "Location Assistance Needed"
              },
              {
                header: "Integration Follow Up",
                field: "orders.integration_follow_up_count",
                title: "Integration Follow Up"
              },
              {
                header: "Fulfillment",
                field: "orders.sent_to_fulfillment_count",
                title: "Orders Sent To Fulfillment"
              },
              {
                header: "Pending Ship",
                field: "orders.pending_shipment_count",
                title: "Orders Pending Shipment"
              },
              {
                header: "Backordered",
                field: "orders.backordered_count",
                title: "Backordered Orders"
              },
              {
                header: "Accepted",
                field: "orders.accepted_count",
                title: "Accpeted Orders"
              },
              {
                header: "Completed",
                field: "orders.completed_count",
                title: "Completed Orders"
              },
              {
                header: "Rejected",
                field: "orders.rejected_count",
                title: "Rejected Orders"
              },
              {
                header: "Incorrect",
                field: "orders.incorrect_count",
                title: "Incorrect Orders"
              },
              {
                header: "Internal Processing",
                field: "orders.internal_processing_count",
                title: "Internal Processing Orders"
              }
            ]
          : []),
        ...(visibleColumns["Contact Insights"]
          ? [
              {
                header: "Outbound Calls",
                field: "contacts.Total_Success_Outbound_calls",
                title: "Outbound Calls"
              },
              {
                header: "Inbound Calls",
                field: "contacts.Total_Success_Inbound_call",
                title: "Inbound Calls"
              },
              {
                header: "Emails",
                field: "contacts.Total_Success_Email_Resupply_Request",
                title: "Emails"
              },
              {
                header: "Text Msg",
                field: "contacts.Total_Success_Text_messages",
                title: "Text Msg"
              },
              {
                header: "Push Notification",
                field: "contacts.Total_Success_Push_Notifications",
                title: "Push Notifications"
              }
            ]
          : []),
          visibleColumns["Manages Compliance"] && {
            header: "Manages Compliance",
            field: "manages_compliance",
            filters: [
              { text: "Yes", value: true },
              { text: "No", value: false }
            ],
            title: "Manages Compliance"
          },
      ])}
      controls={
        <div className="query-table-controls">
          <CompanySearchInput
            setCompanySearchText={setCompanySearchText}
            companySearchText={companySearchText}
          />
          <div className="action-buttons companies-table-action-buttons companies-table-date-range-container">
            {(visibleColumns["Walk In Orders"] ||
              visibleColumns["Contact Insights"]) && (
              <DateRange
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleSubmit={requestCompanies}
                isValid={dateRangeIsValid}
                isFetching={isFetching}
              />
            )}
          </div>
          <div className="action-buttons companies-table-action-buttons">
            <span
              className="icon-button"
              onClick={() => {
                if (!isFetching) requestCompanies(startDate, endDate);
              }}
            >
              <IoMdRefresh />
            </span>
            <TableSettings
              visibleColumns={R.omit(
                [...(isS3User ? [] : ["Team Name"])],
                visibleColumns
              )}
              setVisibleColumns={setVisibleColumns}
              renderLables={{
                "Walk In Orders": (
                  <div className="companies-table-option-container">
                    <span className="option-name">
                      Walk In Orders (Date Ranged)
                    </span>
                  </div>
                ),
                "Patient Insights": (
                  <div className="companies-table-option-container">
                    <span className="option-name">Patient Insights</span>
                    <sub className="column-option-info">
                      Active Patients, Inactive Patients, Eligible Patients,
                      Follow-Up Patients, 2nd Follow Up
                    </sub>
                  </div>
                ),
                "Order Statuses": (
                  <div className="companies-table-option-container">
                    <span className="option-name">Order Statuses</span>
                    <sub className="column-option-info">
                      New, Pending, Fulfillment, Pending Shipment, Backordered,
                      Accepted, Completed, Rejected, Incorrect, Internal
                      Processing
                    </sub>
                  </div>
                ),
                "Contact Insights": (
                  <div className="companies-table-option-container">
                    <span className="option-name">
                      Contact Insights (Date Ranged)
                    </span>
                    <sub className="column-option-info">
                      Outbound Calls, Inbound Calls, Emails, Text Message, Push
                      Notifications
                    </sub>
                  </div>
                )
              }}
            />
            <span
              className="icon-button"
              onClick={() => {
                const companies = getMatches(companySearchText, rows);
                if (companies.length) {
                  exportCompanyTable(
                    companies,
                    startDate,
                    endDate,
                    visibleColumns
                  );
                }
              }}
            >
              <FaFileExcelO />
            </span>
          </div>
        </div>
      }
    />
  );
};

CompanyTable.propTypes = {
  initialFilters: DataColumnFilterTable.propTypes.initialFilters,
  fetchCompanyTable: PropTypes.func.isRequired,
  exportCompanyTable: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  teamNameOptions: PropTypes.arrayOf(PropTypes.object),
  isS3User: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    companies: selectors.getCompaniesTableEntries(state),
    teamNameOptions: selectors.getTeamNameOptions(state),
    isS3User: selectors.getIsS3User(state)
  }),
  { exportCompanyTable, fetchCompanyTable }
)(CompanyTable);
