import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPatientQuestionnaireDetails } from "../../Redux/Actions/patients";
import { PageHeader } from "components/ui";
import { Link } from "react-router-dom";
import IoPrinter from "react-icons/lib/io/printer";
import ReactToPrint from "react-to-print";
import moment from "moment";

const formatDateTime = date => (date ? moment(date).format("hh:mm a") : "");

const PatientQuestionnairResults = ({
  getPatientQuestionnaireDetails,
  QuestionnaireDetails,
  patient_id,
  questionnaire_assignment_id
}) => {
  React.useEffect(() => {
    getPatientQuestionnaireDetails(patient_id, questionnaire_assignment_id);
  }, []);
  const questionnairRef = React.useRef();
  return (
    <div style={{ padding: 10 }}>
      <PageHeader title="Questionnaire Result">
        <ReactToPrint
          trigger={() => (
            <div
              className="no-print print-order-button"
              ref={questionnairRef}
              onClick={() => {
                window.print();
              }}
            >
              <IoPrinter color="grey" size="28px" />
            </div>
          )}
          content={() => questionnairRef.current}
        />
      </PageHeader>
      <div
        style={{ display: "flex", flexDirection: "column", padding: 20 }}
        ref={questionnairRef}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: 10
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "200px auto",
              fontSize: 20,
              gridGap: 10
            }}
          >
            <div style={{ fontWeight: 500 }}>Questionnaire Title</div>
            <div>{QuestionnaireDetails.title}</div>
            <div style={{ fontWeight: 500 }}>Description</div>
            <div>{QuestionnaireDetails.description}</div>
            <div style={{ fontWeight: 500 }}>Questionnaire Type</div>
            <div>
              {QuestionnaireDetails.questionnaire_type === "2"
                ? "Product"
                : "Service"}
            </div>
            <div style={{ fontWeight: 500 }}>Patient</div>
            <div>
              <Link to={`/patients/${QuestionnaireDetails.patient_id}`}>
                {QuestionnaireDetails.patient_name}
              </Link>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "200px auto",
              fontSize: 20,
              gridGap: 10
            }}
          >
            <div style={{ fontWeight: 500 }}>Assigned Date</div>
            <div>{formatDateTime(QuestionnaireDetails.submitted_date)}</div>
            <div style={{ fontWeight: 500 }}>Response Date</div>
            <div>
              {formatDateTime(
                QuestionnaireDetails.date_of_digital_consent_accept
              )}
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div style={{ padding: 10 }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: 10
              }}
            >
              <h4>Question</h4>
              <h4>Question Type</h4>
              <h4>Patient Response</h4>
            </div>
            {QuestionnaireDetails.questions &&
              QuestionnaireDetails.questions.map(data => (
                <div
                  key={data.question_id}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    fontSize: "22px",
                    gridGap: 10,
                    marginTop: 20
                  }}
                >
                  <div>
                    <div>{data.question_text}</div>
                  </div>
                  <div
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      flex: 1
                    }}
                  >
                    <div>{getQuestionTypeText(data.question_type)}</div>
                  </div>
                  <div
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      flex: 1
                    }}
                  >
                    <QuestionnaireAnswere {...data} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PatientQuestionnairResults.propTypes = {
  patient_id: PropTypes.string.isRequired,
  questionnaire_assignment_id: PropTypes.string.isRequired,
  getPatientQuestionnaireDetails: PropTypes.func.isRequired,
  QuestionnaireDetails: PropTypes.object
};

export default connect(
  state => ({
    QuestionnaireDetails: state.getIn(["newRootReducer"]).rdc_patients
      .QuestionnaireDetails
  }),
  { getPatientQuestionnaireDetails }
)(PatientQuestionnairResults);

const getQuestionTypeText = type => {
  switch (type) {
    case "1":
      return "Yes/No";
    case "2":
      return "Multichoice";
    case "3":
      return "Text";
    case "4":
      return "Linkerscale";
    default:
      return type;
  }
};

const QuestionnaireAnswere = ({ question_type, options, answer_value }) => {
  switch (question_type) {
    case "1":
      return (
        <div style={{ fontWeight: 550 }}>{answer_value ? "Yes" : "No"}</div>
      );
    case "2":
      return (
        <ul>
          {options.map((pdata, pindex) => (
            <li key={pindex}>
              <input
                readOnly={true}
                type="checkbox"
                checked={pdata.isChecked}
              />
              <label>{pdata.option_text} </label>
            </li>
          ))}
        </ul>
      );
    case "3":
    case "4":
      return <div style={{ fontWeight: 550 }}>{answer_value}</div>;
    default:
      return <div>Unknown Question Type</div>;
  }
};

QuestionnaireAnswere.propTypes = {
  question_type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  answer_value: PropTypes.string
};
