import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import A from "action-types";
import { get } from "utils/api";
import {
  getUsers,
  updateOrderTableClaimedBy
} from "action-creators";
import moment from "moment";
import { Map, fromJS } from "immutable";
import { manageQueryDirection, snakeCase, getInitials } from "utils/misc";
import {
  PageHeader,
  Waiting,
  TableFooter
} from "components/ui/index";
import Radium from "radium";
import styler from "react-styling";
import { colors } from "utils/styles";
import { search } from "actions/search";
import {
  updateSearchTableQuery,
  updateOrderThenSearchFetch
} from "actions/search-table-query";
import { getRouteParam } from "routes";

export function getOrders(query, path, userID) {
  return {
    type: A.ORDERS.GET,
    callAPI: () => get(`orders?id=${userID}&status=${snakeCase(path)}`, query)
  };
}

export function setOrdersQuery(query) {
  return {
    type: A.ORDERS.SET_QUERY,
    query
  };
}

class OrderSearchTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ini: {
        type: "all",
        sort_by: "company-all",
        direction: "asc",
        per: "150",
        page: "1"
      },
      sortBy: {
        "Order Number": "id",
        Submitted: "submitted",
        "Last Updated": "last_updated"
      },
      sortBySelects: { Company: "company-all", "Sleep Coach": "sleep_coach" },
      routeMap: {
        new: {
          label: "New",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Pending Information"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "pending_info"
          ]
        },
        ready: {
          label: "Ready",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Pending Information",
            "Status"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "pending_info",
            "status"
          ]
        },
        pending: {
          label: "Pending",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Reason"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "reason"
          ]
        },
        on_hold: {
          label: "On Hold",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Pending Information",
            "Status",
            "Reason"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "pending_info",
            "status",
            "reason"
          ]
        },
        incorrect: {
          label: "Incorrect",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Pending Information",
            "Status",
            "Reason"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "pending_info",
            "status",
            "reason"
          ]
        },
        completed: {
          label: "Completed",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Shipped",
            "Completed",
            "Sales Order",
            "Status",
            "Pending Information"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "shipped",
            "completed",
            "sales_order",
            "status",
            "pending_info"
          ]
        },
        billed: {
          label: "Billed",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Shipped",
            "Completed",
            "Sales Order",
            "Status"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "shipped",
            "completed",
            "sales_order",
            "status"
          ]
        },
        rejected: {
          label: "Rejected",
          headers: [
            "Order Number",
            "Patient",
            "Company",
            "Submitted",
            "Last Updated",
            "Sleep Coach",
            "Status",
            "Reason"
          ],
          refs: [
            "invoice_number",
            "patient",
            "company",
            "submitted",
            "last_updated",
            "sleep_coach",
            "status",
            "reason"
          ]
        }
      }
    };
  }

  makeHeader(h) {
    const sortBy = this.state.sortBy[h] && (
      <th
        key={h}
        style={S.sort}
        onClick={() => this.updateQuery({ sort_by: this.state.sortBy[h] })}
      >
        {h}
      </th>
    );

    return sortBy || <th key={h}>{h}</th>;
  }

  paginationAction(obj) {
    const { query } = this.props;
    this.updateQuery(obj, query);
  }

  componentDidMount() {
    const {
      getUsers,
      updateSearchTableQuery,
      search_field,
      search_value
    } = this.props;

    updateSearchTableQuery({
      table: "orders",
      sort_by: "updated_at",
      field: search_field,
      value: search_value,
      page: 1,
      per: 150,
      direction: "asc"
    });

    if (!this.props["sleep coach"].size) {
      getUsers("sleep-coaches");
    }
  }

  updateQuery(updatedQuery) {
    const { updateSearchTableQuery, query } = this.props;
    const newQuery = manageQueryDirection(
      fromJS(updatedQuery),
      fromJS(query)
    ).toJS();

    if (newQuery.filter_by && newQuery.filter_by !== "") {
      newQuery.filter = {
        [newQuery.filter_by.replace(" ", "_")]: newQuery.filter_value
      };
    }
    if (query.filter && newQuery.order_by !== query.filter.order) {
      newQuery.filter = { order: newQuery.order_by };
    }
    newQuery.table = "orders";
    updateSearchTableQuery(newQuery);
  }

  componentDidUpdate(prevProps) {
    const { search_field, search_value, updateSearchTableQuery } = this.props;
    if (
      prevProps.search_field !== search_field ||
      prevProps.search_value !== search_value
    ) {
      updateSearchTableQuery({
        table: "orders",
        field: search_field,
        value: search_value,
        direction: "asc"
      });
    }
  }

  handleExportTable() {
    const { query, user, exportTable } = this.props;
    exportTable(fromJS(query), user.get("id"), "orders");
  }

  handleClaim(order) {
    const { user, updateOrderThenSearchFetch } = this.props;
    updateOrderThenSearchFetch(
      order.get("id"),
      { claimed_by: user.get("id") },
      true
    );
  }

  render() {
    const {
      orders,
      query,
      searchData,
      // route,
      user,
      isFetchingOrders
    } = this.props;
    // const seesClaimColumn = route.path !== "new";
    const userRole = user.get("role");
    const ableToClaim =
      userRole === "OfficeAdministrator" || userRole === "CompanyAdministrator";
    const noneFound = !isFetchingOrders && !orders.size;
    const title = noneFound
      ? "No orders found with these criteria"
      : `Orders where ${query && query.field} is ${query && query.value}`;

    return (
      <div>
        <PageHeader title={title} count={searchData && searchData.count} />

        <div className="row">
          <div className="row table-topper collapse">
            <div className="small-6 columns">
              <select
                className="small-4"
                onChange={e => this.updateQuery({ per: e.target.value }, query)}
                value={query.per}
              >
                <option value="150">150</option>
                <option value="300">300</option>
                <option value="500">500</option>
              </select>
            </div>
            <div className="small-6 columns text-right">
              {userRole !== "Administrator" && userRole !== "Therapist" && (
                <select
                  className="small-4"
                  onChange={e => this.updateQuery({ type: e.target.value })}
                  value={query.type}
                >
                  <option value="all">All Orders</option>
                  <option value="mine">My Orders</option>
                </select>
              )}
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <table className="large-12">
                <thead>
                  <tr>
                    <th> Admin</th>
                    {this.state.routeMap["completed"].headers.map(h =>
                      this.makeHeader(h)
                    )}
                  </tr>
                </thead>
                {!isFetchingOrders && orders.size ? (
                  <tbody>
                    {orders.map((order, i) => (
                      <tr style={S.tr} key={i}>
                          <td>
                            {order.getIn(["claimed_by", "value"]) != " " ? (
                              <div style={S.claimedByDiv}>
                                {getInitials(
                                  order.getIn(["claimed_by", "value"])
                                )}
                              </div>
                            ) : (
                              <div style={S.claimDiv}>
                                {ableToClaim && (
                                  <a onClick={() => this.handleClaim(order)}>
                                    claim
                                  </a>
                                )}
                              </div>
                            )}
                          </td>
                        {this.state.routeMap["completed"].refs.map((ref, i) =>
                          ref == "invoice_number" ? (
                            <td key={i}>
                              <Link to={`/orders/${order.get("id")}`}>
                                {order.get(ref)}
                              </Link>
                            </td>
                          ) : ref == "patient" ? (
                            <td key={i}>
                              <Link to={`/patients/${order.get("patient_id")}`}>
                                {order.get(ref)}
                              </Link>
                            </td>
                          ) : ref == "claimed_by" ? (
                            <td key={i}>{}</td>
                          ) : [
                              "submitted",
                              "shipped",
                              "last_updated",
                              "completed"
                            ].indexOf(ref) !== -1 ? (
                            <td key={i}>
                              {order.get(ref) === null
                                ? ""
                                : moment(order.get(ref)).format(
                                    "YYYY / MM / DD"
                                  )}
                            </td>
                          ) : (
                            <td key={i}>{order.get(ref)}</td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div style={S.waitingDiv}>
                    <Waiting reason="fetching orders..." />
                  </div>
                )}
              </table>
            </div>
          </div>
          <TableFooter
            query={fromJS(query)}
            handleExportClick={this.handleExportTable.bind(this)}
            handlePagination={this.paginationAction.bind(this)}
            canExport={false}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state, props) => {
    const entitiesOrders = state.getIn(["entities", "orders"], Map()).toJS();
    const orderSearchResultIds = state.get("search").ids.orders || [];
    const ordersSearchResults = orderSearchResultIds.map(orderId => {
      return entitiesOrders[orderId];
    });

    return {
      search_value: getRouteParam(props, 'value'),
      search_field: getRouteParam(props, 'field'),
      isFetchingOrders: state.getIn(["fetch", "PUT_SEARCH", "isFetching"]),
      orders: fromJS(ordersSearchResults),
      "sleep coach": state.getIn(["users", "sleep-coaches"]),
      query: state.get("search").metaData.orders,
      user: state.get("user")
    };
  },
  {
    setOrdersQuery,
    getUsers,
    updateOrderTableClaimedBy,
    updateSearchTableQuery,
    updateOrderThenSearchFetch,
    search
  }
)(Radium(OrderSearchTable)));

OrderSearchTable.propTypes = {
  "sleep coach": PropTypes.object.isRequired,
  exportTable: PropTypes.func,
  search_field: PropTypes.string.isRequired,
  search_value: PropTypes.string.isRequired,
  getUsers: PropTypes.func.isRequired,
  isFetchingOrders: PropTypes.bool,
  orders: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  query: PropTypes.object,
  search: PropTypes.func.isRequired,
  searchData: PropTypes.object,
  setOrdersQuery: PropTypes.func.isRequired,
  updateOrderThenSearchFetch: PropTypes.func.isRequired,
  updateSearchTableQuery: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

OrderSearchTable.defaultProps = {
  query: {}
};

const S = styler`
  offsetComp
    right: 20
    bottom: 20
    left: 20
  sort
    text-decoration: underline
    color: ${colors.primaryColor}
    cursor: pointer
  soft
    opacity: 0.6
  td
    white-space: nowrap
  tr
    height: 35px
  claimedByDiv
    background-color: #c0c0c0
    text-align: center
    padding: 3px
    border-radius: 3px
  claimDiv
    text-align: center
  none
    font-size: 120%
    margin: 1rem
    font-weight: bold
  waitingDiv
    margin-top: 125px
`;
