import React from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { useFetch } from "hooks";
import { MdCheck, MdClose } from "react-icons/lib/md";
import { get } from "utils/api";
import cronstrue from "cronstrue";

const CompanyAutomatedJobs = ({ companyId }) => {
  const { response, isFetching, fetchData } = useFetch({
    apiFn: companyId => get(`companies/${companyId}/scheduled_jobs`),
    defaultValue: []
  });
  const { rows, query, queryDispatcher } = useLocalQuery(response ?? [], {
    sort: {}
  });
  React.useEffect(() => {
    fetchData(companyId);
  }, [companyId]);
  return (
    <div>
      <Table
        style={{ width: "100%" }}
        isFetching={isFetching}
        containerClass="query-table dynamic-columns"
        TableRow={job => (
          <tr key={job.id}>
            <td>{job.description}</td>
            <td>{cronstrue.toString(job.cron_expression)}</td>
            <td>{job.job_type}</td>
            <td>{JSON.parse(job.extra_properties).timezone}</td>
            <td>{job.branch_name}</td>
            <td>
              {job.active ? <MdCheck color="green" /> : <MdClose color="red" />}
            </td>
          </tr>
        )}
        columns={[
          {
            header: "Description",
            field: "description"
          },
          {
            header: "Schedule",
            field: "cron_expression"
          },
          {
            header: "Type",
            field: "job_type"
          },
          {
            header: "Time Zone",
            field: "extra_properties"
          },
          {
            header: "Branch",
            field: "branch_name"
          },
          {
            header: "Active",
            field: "active"
          }
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
      />
    </div>
  );
};

CompanyAutomatedJobs.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default CompanyAutomatedJobs;
