import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { googleSignIn, googleSignOut } from "features/google-api/actions";
import { selectors } from "features/google-api/reducers";
import GoogleIconSvg from "./google-icon-svg";

const GoogleAuthButton = ({ googleSignIn, googleSignOut, isLoggedIn }) => {
  return (
    <button
      type="button"
      className="google-button"
      onClick={() => {
        if (isLoggedIn) googleSignOut();
        else googleSignIn();
      }}
    >
      <GoogleIconSvg />
      <span className="google-button-text">
        {isLoggedIn ? "Sign Out" : "Sign in with Google"}
      </span>
    </button>
  );
};

GoogleAuthButton.propTypes = {
  isLoggedIn: PropTypes.bool,
  googleSignIn: PropTypes.func.isRequired,
  googleSignOut: PropTypes.func.isRequired
};

export default connect(
  state => ({ isLoggedIn: selectors.getGapiIsLoggedIn(state) }),
  { googleSignIn, googleSignOut }
)(GoogleAuthButton);
