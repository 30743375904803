import map from 'lodash/map'
import compact from 'lodash/compact'
import filter from 'lodash/fp/filter'
import every from 'lodash/every'
import some from 'lodash/some'
import flow from 'lodash/fp/flow'
import sortBy from 'lodash/fp/sortBy'
import reverse from 'lodash/reverse'
import identity from 'lodash/identity'
import slice from 'lodash/slice'
/**
 * Used in reducer selectors to transform queries used in the UI to
 * queries readable by the API.
 */
export const transformUiQuery = ({ sort, pagination, filters, searches }) => ({  
  search_for_array: compact(
    map(searches, (value, by) => value  === "" ? null : {
      by,
      value: value
    })
    )
  ,
  sort,
  pagination,
  filter_by: compact(map(filters, (values, by) => values && values.length ? {
    by,
    value: typeof values === 'string' ? values : values.length ? values.join('^') : null
  } : null))
})
/**
 * Used in `DataTable` component and reducer selectors
 */
export const applyQuery = (rows, {
  sort,
  filters,
  pagination
}) => {
  const sortIteratee = sort && sort.by
  const direction = sort && sort.direction
  const limit  = pagination && pagination.per && !isNaN(parseInt(pagination.per)) ? parseInt(pagination.per) : null

  const customFilter = filter(row => (
    every(filters, (values, key) => !values.length || some(values, val => row[key] === val))
  ))

  const customSort = sortIteratee ? flow([
    sortBy(sortIteratee),
    direction === 'desc' ? reverse : identity
  ]) : identity

  const customPagination = limit
    ? flow([
        rows =>
          slice(
            rows,
            pagination.page ? pagination.page * limit - limit : 0,
            pagination.page ? pagination.page * limit : limit
          )
      ])
    : identity;

  const processRows = flow([
    customFilter,
    customSort,
    customPagination
  ])

  const queriedRows = processRows(rows)

  return queriedRows
}
