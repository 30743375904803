import React from "react";
import PropTypes from "prop-types";

import Radium from "radium";
import styler from "react-styling";

const SectionHeader = props => {
  return <h3 style={S.h3}>{props.children}</h3>;
};

export default Radium(SectionHeader);

SectionHeader.propTypes = {
  children: PropTypes.any
};

const S = styler`
  h3
    font-size: 1.4em
    text-transform: uppercase
    border-bottom: 1px solid #DDD
`;
