import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { exportUserInsights } from "actions/user-profile";

const UserExports = ({
  id,
  dates: { startDate, endDate },
  exportUserInsights,
}) => {
  const [payload, setPayload] = useState({})

  const toggleFieldInPayload = (payload, field) => {
    setPayload({ ...payload, [field]: !payload[field] });
  };

  const constructPayload = () => {
    return {
      userId: id,
      startDate: startDate,
      endDate: endDate,
      ...payload
    };
  };

  const submitForm = (event) => {
    event.preventDefault();
    exportUserInsights(constructPayload());
  };

  return (
    <div>
      <form onSubmit={submitForm}>
        <div className="row">
          <div className="small-6 columns">
            <h3>Export Topics</h3>
            <div>
              <input
                type="checkbox"
                name="orderStats"
                checked={payload.orderStats || false}
                onClick={() => toggleFieldInPayload(payload, "orderStats")}
              />
              <label htmlFor="orderStats">Order Stats</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="contactStats"
                checked={payload.contactStats || false}
                onClick={() => toggleFieldInPayload(payload, "contactStats")}
              />
              <label htmlFor="contactStats">
                Contact Stats
              </label>
            </div>
            <button type="submit" style={{ marginTop: "10px" }}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default connect(() => ({}), { exportUserInsights })(UserExports);

UserExports.propTypes = {
  id: PropTypes.string.isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }).isRequired,
  exportUserInsights: PropTypes.func.isRequired
};
