import request from "superagent";
import assign from "object-assign";
import { Promise } from "es6-promise";
import { host } from "environment";
/* eslint-disable no-console */

let store;
export const initApi = _store => (store = _store);

// export const host =
//   process.env.NODE_ENV === "production"
//     ? "https://phoenix-backend-production.com/api/"
//     : "https://phoenix-backend-staging.com/api/";
// :  "https://870d1b4f2f14.ngrok.io/api/";
// :"http://localhost:50836/api/";
export const Accept = "application/vnd.sleepcoach.us; version=1";

export const makeBaseHeaders = () => {
  const user = store.getState().get("user");
  return assign(
    { Accept },
    user && user.get && user.get("authentication_token")
      ? {
          Authorization: `${user.get("token_type")} ${user.get(
            "authentication_token"
          )}`
        }
      : undefined
  );
};

export function get(path, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .get(host + path)
      .query(params)
      .set(assign(makeBaseHeaders(), headers))
      .end((error, response) => {
        if (error) {
          console.error("API ERROR: ", error);
          reject(error);
        } else {
          resolve(response.body);
        }
      });
  });
}

export function put(path, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .put(host + path)
      .set(assign(makeBaseHeaders(), headers))
      .send(params)
      .end((error, response) => {
        if (error) {
          console.error("API ERROR: ", error);
          reject(error);
        } else {
          resolve(response.body);
        }
      });
  });
}

export function post(path, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .post(host + path)
      .set(assign(makeBaseHeaders(), headers))
      .send(params)
      .end((error, response) => {
        if (error) {
          console.error("API ERROR: ", error);
          reject(error);
        } else {
          const data = response.body;
          resolve(data);
        }
      });
  });
}

export function del(path, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .del(host + path)
      .set(assign(makeBaseHeaders(), headers))
      .query(params)
      .end((error, response) => {
        if (error) {
          console.error("API ERROR: ", error);
          reject(error);
        } else {
          resolve(response.body);
        }
      });
  });
}

// reason for this is to all canceling api requests, I don't see any value using a 3rd party package(superagent) for api requests
// NOTE: should handle expired authentication_token and exception handling limited
export async function apiRequest(path, body = {}, params = {}) {
  return await fetch(`${host}${path}`, {
    method: "PUT",
    headers: { ...makeBaseHeaders(), "content-type": "application/json" },
    body: JSON.stringify(body),
    ...params
  })
    .then(handleErrors)
    .then(handleResponse);
}

const handleErrors = async response => {
  if (!response.ok) {
    throw new Error(response);
  }
  return response;
};

const handleResponse = async response => {
  const body = await response.json();
  return body;
};
