import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PureModal from "components/ui/modal/pure-modal";
import MdInsertDriveFile from "react-icons/lib/md/insert-drive-file";
import { selectors } from "features/google-api/reducers";
import { attachCompanyFile } from "features/google-api/actions";
import SelectDropdown from "components/forms/select-dropdown";

class CompanyAttachFile extends Component {
  state = {
    selectedFile: ""
  };

  handleChange = fileId => {
    this.setState({ selectedFile: fileId });
  };

  attachCompanyFile = e => {
    e.preventDefault();
    const { attachCompanyFile, company } = this.props;
    attachCompanyFile(company.id, this.state.selectedFile);
  };

  render() {
    const { isLoggedIn, company, fileOptions } = this.props;
    return isLoggedIn ? (
      <PureModal
        persistent={true}
        large
        renderTrigger={({ openModal }) => (
          <span onClick={openModal} className="drive-action">
            <MdInsertDriveFile />
            Attach File To Company {company.name}
          </span>
        )}
        renderContent={({ closeModal }) => (
          <div
            className="new-file-prompt"
            style={{
              height: "400px",
              display: "grid",
              gridTemplateRows: "auto 1fr auto",
              padding: "10px"
            }}
          >
            <h2>Link File To Company {company.company_name}</h2>
            <SelectDropdown
              options={fileOptions}
              value={this.state.selectedFile}
              onChange={this.handleChange}
              name="selectedFile"
              alwaysOpen
              height={500}
            />
            <span className="new-file-actions">
              <button className="button alert" onClick={closeModal}>
                Cancel
              </button>
              <button
                disabled={this.state.selectedFile === ""}
                onClick={e => {
                  this.attachCompanyFile(e);
                  closeModal();
                }}
              >
                Save
              </button>
            </span>
          </div>
        )}
      />
    ) : null;
  }
}

CompanyAttachFile.propTypes = {
  company: PropTypes.object.isRequired,
  fileOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  attachCompanyFile: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool
};

export default connect(
  state => ({
    fileOptions: selectors.getGapiAllFileOptions(state),
    isLoggedIn: selectors.getGapiIsLoggedIn(state)
  }),
  { attachCompanyFile }
)(CompanyAttachFile);
