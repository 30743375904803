import React from "react";
import PropTypes from "prop-types";
import {
  InlineSortControls,
  PageControls,
  AxisLabel
} from "components/ui/graph-components";
import {
  ResponsiveContainer,
  LabelList,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { usePagination, useSort } from "hooks";

const MachineBreakdown = ({ data = [], setSelectedManufacturer }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "Key", ascending: true });
  const { curPageNum, totalPages, setPageNum, getPageData } = usePagination(
    data,
    10
  );
  return (
    <React.Fragment>
      <div className="interactive-graph-header">
        <span />
        <PageControls
          curPageNum={curPageNum}
          totalPages={totalPages}
          setPageNum={setPageNum}
          alwaysShow={false}
        />
        <h4>Manufacturers</h4>
        <InlineSortControls
          sortOptions={[
            { key: "Key", text: "Name" },
            { key: "Count", text: "Count" }
          ]}
          setSortBy={setSortBy}
          sortBy={sortBy}
          ascending={ascending}
          toggleSortDirection={toggleSortDirection}
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={getPageData(curPageNum, getSortedArray)}
          layout="vertical"
          padding={{
            right: 50,
            left: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="Count"
            domain={[0, "dataMax + 20"]}
            scale="pow"
          />
          <YAxis
            type="category"
            dataKey="Key"
            tick={
              <AxisLabel
                style={{
                  fontSize: 12
                }}
              />
            }
            width={250}
          />
          <Tooltip />
          <Bar
            dataKey="Count"
            fill="rgb(49, 130, 189)"
            onClick={c => {
              setSelectedManufacturer(c);
            }}
            maxBarSize={50}
          >
            <LabelList dataKey="Count" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default MachineBreakdown;

MachineBreakdown.propTypes = {
  data: PropTypes.array,
  setSelectedManufacturer: PropTypes.func.isRequired
};
