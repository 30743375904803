import { errorMessage, message } from "actions/message";
import FormikField from 'components/forms/formik-field';
import { PageHeader, Spinner, InlineWaiting } from "components/ui";
import { Form, Formik } from "formik";
import { useFetch } from 'hooks';
import { path } from "ramda";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { fetchCompanyTable } from "actions/companiesTable";
import { getApiErrorMessage } from "utils/misc";
import { post, get } from './../../../../utils/api';
import PropTypes from "prop-types";

const ModalCopyFormToAnotherCompany = ({ sourceCompanyId, formId, fetchCompanyTable, companies, closeModal, name, order, patientStage, successAction, setFormToBeAssignedId }) => {
    const [error, setError] = useState(true);
    const [formOrderAlreadyExists, setFormOrderAlreadyExists] = useState(false);
    const [formOrder, setFormOrder] = useState(0);
    const [targetCompany, setTargetCompany] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormOrder(order);
        getCompanies(patientStage);
    }, []);

    const getCompanies = async (patientStageIdentifier) =>
        await fetchCompanyTable(null, null, patientStageIdentifier);

    const validateEmpty = (value, fieldName) => {

        if (value === '' || value === undefined || value === 0) {
            setError(true);
            return "The '" + fieldName + "' field is required";
        }
        else {
            setError(false);
            return '';
        }
    }

    const {
        fetchData: fetchCopyForm,
    } = useFetch({
        apiFn: (data) => post(`forms/${formId}/copy-form/${sourceCompanyId}/${data.selectedCompany}/${data.name}/${data.order}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to copy form: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchCompanyFormOrderAlreadyExists,
        isFetching: checkingFormOrder
    } = useFetch({
        apiFn: (data) => get(`forms/company-form-order-already-exists/${data.selectedCompany}/${formId}/${data.order}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get if company form order already exists: ${getApiErrorMessage(error)}`);
        }
    })

    const copyForm = async (value) => {
        if (!error) {
            const response = await fetchCopyForm(value);
            if (response) {
                if (response.success) {
                    dispatch(message("The form was copied successfully."));
                    successAction();
                    setFormToBeAssignedId(response.FormId);
                    closeModal();
                } else {
                    dispatch(errorMessage(response.message))
                }
            }
        }
    }

    const checkIfFormOrderAlreadyExists = async (selectedCompany, formOrder) => {
        if (selectedCompany && formOrder) {
            const response = await fetchCompanyFormOrderAlreadyExists({ selectedCompany: selectedCompany, order: formOrder });
            if (response) {
                if (response.success) {
                    setFormOrderAlreadyExists(response.companyHasFormOrder);
                } else {
                    dispatch(errorMessage(response.message))
                }
            }
        }
    }

    return (
        <div>
            <div>
                <PageHeader title="Copy form" />
            </div>
            {checkingFormOrder && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <InlineWaiting />
                    Checking form order...
                </div>)
            }
            <Formik
                initialValues={{
                    name: name,
                    order: order
                }}
                enableReinitialize={true}
                onSubmit={(values) => copyForm(values)}
            >
                {({ handleChange, isSubmitting }) => (
                    <Form>
                        <div className="dd_col-12">
                            <span style={{ color: 'red' }} hidden={!formOrderAlreadyExists}>Form order already exists in destination company</span>
                        </div>
                        <div className="dd_col-6">
                            <FormikField
                                name="name"
                                label="Form Name"
                                type="text"
                                disabled={isSubmitting || checkingFormOrder}
                                onChange={e => { handleChange(e) }}
                                validate={value => {
                                    validateEmpty(value, "Form Name")
                                }}
                            />
                        </div>
                        <div className="dd_col-6">
                            <FormikField name="order"
                                label="Order"
                                type="number"
                                disabled={isSubmitting || checkingFormOrder}
                                onBlur={e => {
                                    setFormOrder(e.target.value);
                                    checkIfFormOrderAlreadyExists(targetCompany, e.target.value);
                                }}
                                onChange={e => {
                                    handleChange(e);
                                }}
                                validate={value => {
                                    validateEmpty(value, "Order");
                                }}
                            />

                        </div>
                        <div className="dd_col-12">

                        </div>
                        <div className="dd_col-12">
                            {
                                companies && companies.length > 0 ?
                                    <FormikField
                                        name="selectedCompany"
                                        component="select"
                                        label="Choose company"
                                        disabled={isSubmitting || checkingFormOrder}
                                        onChange={e => {
                                            handleChange(e);
                                            setTargetCompany(e.target.value);
                                            checkIfFormOrderAlreadyExists(e.target.value, formOrder);
                                        }}
                                        validate={value => validateEmpty(value, "Company")}
                                    >
                                        <option key={null} value={null}></option>
                                        {
                                            companies.map(({ company_name, id }) =>
                                                (<option key={id} value={id}>{company_name}</option>)
                                            )
                                        }

                                    </FormikField>
                                    :
                                    <Spinner />
                            }
                        </div>
                        <div className='dd_col-12'>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    type="submit"
                                    className="button"
                                    disabled={isSubmitting || checkingFormOrder || error}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

ModalCopyFormToAnotherCompany.propTypes = {
    sourceCompanyId: PropTypes.string.isRequired,
    formId: PropTypes.number.isRequired,
    fetchCompanyTable: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    closeModal: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    patientStage: PropTypes.number.isRequired,
    successAction: PropTypes.func.isRequired,
    setFormToBeAssignedId: PropTypes.func.isRequired
};

export default connect(
    state => ({
        companies: state.get("companiesTable")
    }),
    {
        fetchCompanyTable
    }
)(ModalCopyFormToAnotherCompany);