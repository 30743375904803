import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import * as R from "ramda";

const CompanyBranchFilter = ({ companies = [], setFilters, filters }) => {
  const selectedCompanies = R.propOr([], "company", filters);
  const selectedBranches = R.propOr([], "company_branch_guid", filters);

  // const [searchResultsCompany, setSearchResultsCompany] = useState(companies);
  const [searchTerms, setSearchTerms] = useState("");

  const filterCompanies = company => {
    return (
      company.name.toLowerCase().includes(searchTerms.toLowerCase()) ||
      company.branches.some(x =>
        x.text.toLowerCase().includes(searchTerms.toLowerCase())
      )
    );
  };

  return (
    <React.Fragment>
      <div
        onClick={() => {
          setFilters("company", []);
          setFilters("company_branch_guid", []);
        }}
        className="filter-pane-command controls"
      >
        Clear Selections
      </div>
      <input
        type="text"
        className="searchInputBox-coachfilter"
        onChange={e => {
          setSearchTerms(e.target.value);
        }}
        placeholder="Search By Company or Branch"
      ></input>
      {companies
        .filter(filterCompanies)
        .map(c => ({
          ...c,
          branches: c.branches.filter(b =>
            b.text.toLowerCase().includes(searchTerms.toLowerCase())
          )
        }))
        .map(c => (
          <div key={c.GUID}>
            <label className="filter-entry">
              <input
                type="checkbox"
                checked={selectedCompanies.includes(c.GUID)}
                onChange={({ target }) => {
                  if (target.checked) {
                    setFilters(
                      "company",
                      R.uniq([c.GUID, ...selectedCompanies])
                    );
                    // setFilters(
                    //   "company_branch_guid",
                    //   R.uniq(
                    //     R.concat(
                    //       [c.GUID, ...c.branches.map(b => b.value)],
                    //       selectedBranches
                    //     )
                    //   )
                    // );
                  } else {
                    setFilters(
                      "company",
                      R.without([c.GUID], selectedCompanies)
                    );
                    // setFilters(
                    //   "company_branch_guid",
                    //   R.without(
                    //     [c.GUID, ...c.branches.map(b => b.value)],
                    //     selectedBranches
                    //   )
                    // );
                  }
                }}
              />
              <span style={{ display: "flex", flex: "1 1 auto" }}>
                {c.text}
              </span>
            </label>
            {c.branches.map(b => (
              <div key={b.value}>
                <label className="filter-entry filter-sub-entry">
                  <input
                    type="checkbox"
                    checked={selectedBranches.includes(b.value)}
                    onChange={({ target }) => {
                      if (target.checked) {
                        setFilters(
                          "company",
                          R.uniq([c.GUID, ...selectedCompanies])
                        );
                        setFilters(
                          "company_branch_guid",
                          R.uniq([b.value, ...selectedBranches])
                        );
                      } else {
                        setFilters(
                          "company_branch_guid",
                          R.without([b.value], selectedBranches)
                        );
                      }
                    }}
                  />
                  {b.text}
                </label>
              </div>
            ))}
            {c.branches.length > 0 && (
              <div>
                <label className="filter-entry filter-sub-entry">
                  <input
                    type="checkbox"
                    checked={selectedBranches.includes(c.GUID)}
                    onChange={({ target }) => {
                      if (target.checked) {
                        setFilters(
                          "company",
                          R.uniq([c.GUID, ...selectedCompanies])
                        );
                        setFilters(
                          "company_branch_guid",
                          R.uniq([c.GUID, ...selectedBranches])
                        );
                      } else {
                        setFilters(
                          "company_branch_guid",
                          R.without([c.GUID], selectedBranches)
                        );
                      }
                    }}
                  />
                  No Branch
                </label>
              </div>
            )}
          </div>
        ))}
    </React.Fragment>
  );
};

export default connect((state, props) => ({
  companies: selectors.getFormOptionsCompany(state),
  filters: selectors.getQueryFilters(state, props.queryId)
}))(CompanyBranchFilter);

CompanyBranchFilter.propTypes = {
  queryId: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};
