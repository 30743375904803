import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import ProfileHeader from "./profile-header";
import OrderInformation from "./order-information";
import Updates from "./updates";
import DysfunctionalEquipment from "./dysfunctional-equipment";
import PackingSlipHeader from "./packing-slip-header";
import Shipping from "./shipping";
import ReplacementOrder from "./replacement-order";
import SendToFulfillment from "./send-to-fulfillment";
import OrderLineItems from "./order-line-items";
import Tracking from "./tracking";
import AddLineItem from "./add-line-item";
import ChangeLogs from "./change-logs";
import AccessLogs from "./access-logs";
import OrderClipboard from "./clipboard";
import BarcodeDisplay from "./barcodeDisplay";
import OrderNotes from "./notes";
import PhilipsIvrInfo from "./philips-order";
import OrderProfileProvider from "./context";

const Page = ({ children }) => (
  <div className="order-profile-page">{children}</div>
);
Page.propTypes = {
  children: PropTypes.node
};

const OrderProfile = ({
  orderId,
  showLogs,
  canChangeQuantities,
  requestOrderProfile,
  patientInfo,
  batchView
}) => {
  React.useEffect(() => {
    requestOrderProfile(orderId, { useCache: batchView });
  }, [orderId]);
  const [showNotes, setShowNotes] = React.useState(false);
  const orderRef = React.useRef();
  return (
    <OrderProfileProvider patientId={patientInfo?.patient_id} orderId={orderId}>
      <div className="order-profile-container" ref={orderRef}>
        <OrderClipboard orderId={orderId} />
        <div className="order-profile">
          <Page>
            <ProfileHeader
              orderId={orderId}
              orderRef={orderRef}
              setShowNotes={setShowNotes}
            />
            <OrderInformation orderId={orderId} />
            {patientInfo?.creation_method == "Philips Import" ? (
              <PhilipsIvrInfo orderId={orderId} />
            ) : (
              <>
                <Updates orderId={orderId} />
                <DysfunctionalEquipment orderId={orderId} />
              </>
            )}
          </Page>
          <footer style={{ padding: "50px" }}>
            1/{showNotes ? 3 : 2} pages
          </footer>
          <Page>
            <PackingSlipHeader orderId={orderId} />
            <Shipping orderId={orderId} />
            <ReplacementOrder orderId={orderId} />
            <SendToFulfillment orderId={orderId} />
            <OrderLineItems orderId={orderId} />
            <Tracking orderId={orderId} />
            <BarcodeDisplay orderId={orderId} />
            {canChangeQuantities && <AddLineItem orderId={orderId} />}
          </Page>
          <footer style={{ padding: "50px" }}>
            2/{showNotes ? 3 : 2} pages
          </footer>
          <div style={{ display: showNotes ? "block" : "none" }}>
            <Page>
              <OrderNotes orderId={orderId} readOnly />
            </Page>
            <footer style={{ padding: "50px" }}> 3/3 pages</footer>
          </div>
          {showLogs && (
            <div className="no-print">
              <Page>
                <h1>Logs</h1>
                <ChangeLogs orderId={orderId} />
                <AccessLogs orderId={orderId} />
              </Page>
            </div>
          )}
        </div>
      </div>
    </OrderProfileProvider>
  );
};

export default connect((state, props) => {
  const { data: patientInfo } = selectors.getOrderPatientInfo(state, props);
  return {
    showLogs: selectors.getShowLogs(state, props),
    patientInfo,
    canChangeQuantities: selectors.getCanChangeQuantities(state, props)
  };
}, mapDispatchToProps)(OrderProfile);

OrderProfile.propTypes = {
  orderId: PropTypes.string.isRequired,
  showLogs: PropTypes.bool,
  canChangeQuantities: PropTypes.bool,
  requestOrderProfile: PropTypes.func.isRequired,
  batchView: PropTypes.bool,
  patientInfo: PropTypes.object
};
