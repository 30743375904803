import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getFormOptionsSleepCoachWithUserFirst } from "reducers";
import { PureModal, TableButton } from "components/ui";
import { IoEdit } from "react-icons/lib/io";
import { put } from "utils/api";
import { useFetch } from "hooks";

const BulkUpdateVoicemails = ({ selectedRows, refreshData }) => {
  const sleepCoaches = useSelector(getFormOptionsSleepCoachWithUserFirst);
  const [option, setOption] = React.useState("assign_coach");
  const [selectedCoach, setSelectedCoach] = React.useState("");
  const assignCoachAPI = useFetch({
    apiFn: ({ sleep_coach, voicemail_guids }) =>
      put(`voicemails/assignCoach`, { sleep_coach, voicemail_guids })
  });
  const markAsAPI = useFetch({
    apiFn: payload => put(`voicemails/markAs`, payload)
  });
  return (
    <PureModal
      renderTrigger={({ openModal }) => (
        <TableButton
          disabled={selectedRows.length === 0}
          onClick={openModal}
          title={`Bulk update Voicemails (${selectedRows.length} selected)`}
        >
          <IoEdit />
        </TableButton>
      )}
      renderContent={({ closeModal }) => (
        <div style={{ padding: 5 }}>
          <h3>Bulk update voicemails - {selectedRows.length} Selected</h3>
          <select
            onChange={({ target }) => setOption(target.value)}
            value={option}
          >
            <option value="assign_coach">Assign Voicemails</option>
            <option value="markAsListened">Mark As Listened</option>
            <option value="markAsListenedAndWorked">Mark As Worked</option>
            <option value="markAsUnlistenedAndUnworked">
              Reset Voicemails
            </option>
          </select>
          {option == "assign_coach" && (
            <div>
              <label>Coach</label>
              <select
                value={selectedCoach}
                onChange={({ target }) => setSelectedCoach(target.value)}
              >
                <option />
                {sleepCoaches.map(x => (
                  <option value={x.value} key={x.value}>
                    {x.text}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 30
            }}
          >
            <button onClick={closeModal} className="secondary">
              Cancel
            </button>
            <button
              disabled={option == "assign_coach" && !selectedCoach}
              onClick={async () => {
                if (option == "assign_coach") {
                  await assignCoachAPI.fetchData({
                    sleep_coach: selectedCoach,
                    voicemail_guids: selectedRows.map(x => x.id)
                  });
                } else
                  await markAsAPI.fetchData({
                    mark_as: option,
                    voicemail_guids: selectedRows.map(x => x.id)
                  });
                refreshData();
                closeModal();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    />
  );
};

BulkUpdateVoicemails.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  refreshData: PropTypes.func.isRequired
};

export default BulkUpdateVoicemails;
