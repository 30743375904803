import React from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import { ListItem } from "components/ui";
import * as R from "ramda";
import { formatPercantage } from "utils/misc";

const CompanyProfileContacts = ({ companyContacts }) => (
  <div className="company-profile-contacts" style={{ marginBottom: "1rem" }}>
    <h4
      style={{
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(238, 238, 238)"
      }}
    >
      Contacts
    </h4>
    <div className="profile-contacts-container">
      <ListItem
        label="Phone:"
        value={R.path(["phone", "success_call_total"], companyContacts)}
        style={{ padding: 5, fontSize: "1rem" }}
        underline
      />
      <ListItem
        label="Outbound Calls:"
        value={R.path(["phone", "outgoing_call", "successes"], companyContacts)}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
      <ListItem
        label="Voicemails:"
        value={`(${R.path(
          ["phone", "left_voicemail", "successes"],
          companyContacts
        )})  
        ${formatPercantage(
          R.path(["phone", "left_voicemail", "successes"], companyContacts),
          R.path(["phone", "outgoing_call", "successes"], companyContacts)
        )}`}
        subitem
        style={{ padding: 0, marginLeft: 35, fontSize: "1rem" }}
      />
      <ListItem
        label="Inbound Calls:"
        value={R.path(["phone", "incoming_call", "successes"], companyContacts)}
        subitem
        style={{ padding: 0, fontSize: "1rem" }}
      />
      <ListItem
        label="Emails:"
        value={R.path(["email", "success_total"], companyContacts)}
        style={{ padding: 5, fontSize: "1rem" }}
        underline
      />
      <ListItem
        label="Resupply Request:"
        value={R.path(
          ["email", "resupply_request", "successes"],
          companyContacts
        )}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
      <ListItem
        label="Mobile App Instructions:"
        value={R.path(
          ["email", "download_mobile_app_request", "successes"],
          companyContacts
        )}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
      <ListItem
        label="Follow Up"
        value={R.path(
          ["email", "download_mobile_app_request", "successes"],
          companyContacts
        )}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
      <ListItem
        label="Text Consent:"
        value={R.path(
          ["email", "text_consent_request", "successes"],
          companyContacts
        )}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
      <ListItem
        label="Other:"
        value={R.path(["email", "other", "successes"], companyContacts)}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
      <ListItem
        label="SMS:"
        value={R.path(["sms", "success_total"], companyContacts)}
        className="small-list-item"
        style={{ padding: 5, fontSize: "1rem" }}
        underline
      />
      <ListItem
        label="Text Message:"
        value={R.path(["sms", "text_message", "successes"], companyContacts)}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
      <ListItem
        label="Push Notifications:"
        value={R.path(
          ["sms", "push_notification", "successes"],
          companyContacts
        )}
        style={{ padding: 0, fontSize: "1rem" }}
        subitem
      />
    </div>
  </div>
);

CompanyProfileContacts.propTypes = {
  companyContacts: PropTypes.object
};

export default connect(state => ({
  companyContacts: selectors.getCompanyProfileContacts(state)
}))(CompanyProfileContacts);
