import { take, call, put } from "redux-saga/effects";
import C from "action-types";
import { message, errorMessage } from "actions/message";
import * as api from "utils/api";

function* backorderImport({ payload }) {
  try {
    yield put({ type: C.ORDERS.BACKORDER_IMPORT_REQUEST });
    const url = "orders/bulk/backorder";
    const response = yield call(api.post, url, payload);
    yield put({ type: C.ORDERS.BACKORDER_IMPORT_SUCCESS, payload: response });
    yield put(message("Backorder import submitted successfully!"));
  } catch (error) {
    yield put({ type: C.ORDERS.BACKORDER_IMPORT_FAILURE, payload: error });
    yield put(errorMessage("There was a problem with the import"));
  }
}

export default function* watchBackorderImport() {
  while (true) {
    const action = yield take(C.ORDERS.BACKORDER_IMPORT);
    yield call(backorderImport, action);
  }
}
