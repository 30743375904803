import React from "react";
import CompanyTeamSelect from "components/shared/company-team-select";
import { Link } from "react-router-dom";
import { RowSelectCell, RowSelectHeaderCell } from "hooks/useRowSelectNew";
import { useTableSettingsNew } from "hooks/useTableSettings";
import { selectors } from "reducers";
import { Checkbox } from "components/ui";
import { useSelector } from "react-redux";
import { formatDate } from "utils/dates";
import ClaimOrder from "./claim-order";
import * as R from "ramda";

function getTableSchema({
  // sleepCoaches,
  admins,
  companies,
  userRole,
  orderStatusOptions
}) {
  const Table = {
    All: {
      header: "all",
      field: "all",
      Cell: RowSelectHeaderCell,
      active: true,
      Row: row => <RowSelectCell id={row.order_id} />
    },
    "Order Status": {
      header: "Order Status",
      field: "order_status",
      dropdownPosition: "right",
      active: true,
      filters: orderStatusOptions,
      Row: row => <td>{row.order_status}</td>
    },
    "Invoice #": {
      header: "Invoice #",
      field: "invoice_number",
      active: true,
      Row: row => (
        <td>
          <Link to={`/orders/${row.order_id}`}>{row.invoice_number}</Link>
        </td>
      )
    },
    Admin: {
      header: "Admin",
      field: "claimed_by_id",
      dropdownPosition: "right",
      active: true,
      filterDownSelect: true,
      filters: admins,
      Row: row => (
        <td>
          <ClaimOrder
            id={row.order_id}
            claimed_by={{ value: row.claimed_by_name, key: row.claimed_by_id }}
          />
        </td>
      )
    },
    Patient: {
      header: "Patient",
      field: "patient_name",
      active: true,
      Row: row => (
        <td>
          <Link to={`/patients/${row.patient_id}`}>{row.patient_name}</Link>
        </td>
      )
    },
    "Account Number": {
      header: "Account #",
      field: "patient_account",
      active: false,
      Row: row => <td>{row.patient_account}</td>
    },
    Company: {
      header: "Company",
      field: "company",
      filter: "company_id",
      filters: companies,
      filterDownSelect: true,
      SubFilter: CompanyTeamSelect,
      active: true,
      Row: row => <td>{row.company_name}</td>
    },
    Physician: {
      header: "Physician",
      field: "physician_name",
      active: true,
      Row: row => <td>{row.physician_name}</td>
    },
    "Date Created": {
      header: "Date Created",
      field: "order_created_at",
      // Cell: () => (
      //   <DateRangeHeaderColumn header="Date Created" field="created_at" />
      // ),
      active: true,
      Row: row => <td>{formatDate(row.order_created_at)}</td>
    },
    Progress: {
      header: "Progress",
      field: "task_status",
      active: true,
      filter: "task_status",
      filters: [
        { value: "pending_tasks", text: "In Progress" },
        { value: "completed_tasks", text: "All Tasks Completed" }
      ],
      Row: row => (
        <td>
          {row.completed_tasks}/{row.total_tasks}
        </td>
      )
    },
    "Last Updated": {
      header: "Last Updated",
      field: "last_updated_at",
      // Cell: () => (
      //   <DateRangeHeaderColumn header="Last Updated" field="last_updated_at" />
      // ),
      active: true,
      Row: row => <td>{formatDate(row.last_updated_at)}</td>
    },
    "High Priority": {
      header: "High Priority",
      field: "high_priority",
      // Cell: () => (
      //   <DateRangeHeaderColumn header="Last Updated" field="last_updated_at" />
      // ),
      filters: [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
      ],
      active: true,
      Row: row => (
        <td>
          <Checkbox value={row.high_priority} />
        </td>
      )
    },
    "Follow Up Date": {
      header: "Follow Up Date",
      field: "follow_up_date",
      // Cell: () => (
      //   <DateRangeHeaderColumn header="Last Updated" field="last_updated_at" />
      // ),
      active: true,
      Row: row => <td>{formatDate(row.follow_up_date)}</td>
    },
    "NPI": {
      header: "NPI",
      field: "physician_npi",
      active: false,
      Row: row => <td>{row.physician_npi}</td>
    }
  };
  if (["OfficeAdministrator", "CompanyAdministrator"].includes(userRole))
    return R.omit(["Owner"], Table);
  return Table;
}

export function useTaskTable() {
  const companies = useSelector(selectors.getFormOptionsCompany);
  // const sleepCoaches = useSelector(getFormOptionsSleepCoachWithUserFirst);
  const userRole = useSelector(selectors.getUserRole);
  const orderStatusOptions = useSelector(selectors.getOrderStatusTextOptions);
  const admins = useSelector(selectors.getEnumAdmins);
  const taskTable = getTableSchema({
    companies,
    admins,
    // sleepCoaches,
    userRole,
    orderStatusOptions
  });
  const tableSchema = Object.keys(taskTable).map(key => ({
    ...taskTable[key],
    key
  }));
  const defaultSettings = R.mapObjIndexed(R.pick(["active"]), taskTable);
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings
  } = useTableSettingsNew("tasks-table-settings", defaultSettings);

  const visibleTableSchema = tableSchema.filter(({ key }) =>
    isColumnVisible(key)
  );
  const tableColumns = visibleTableSchema.map(R.omit(["Row"]));
  const tableRows = visibleTableSchema.map(R.prop("Row"));

  // remove sorting and filters when column is removed
  // React.useEffect(() => {
  //   const newQuery = cleanQuery(
  //     tableColumns.map(({ field }) => field),
  //     query
  //   );
  //   if (!isEqual(newQuery, query)) {
  //     queryDispatcher({ type: "updateQuery", ...newQuery });
  //   }
  // }, [tableColumns, queryDispatcher]);

  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    tableColumns,
    tableRows
  };
}
