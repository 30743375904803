import { message, errorMessage } from "actions/message";
import { post, get, put } from "utils/api";

export const Types = {
  GET_PRODUCT_FAQS: "GET_PRODUCT_FAQS",
  POST_PRODUCT_FAQ: "POST_PRODUCT_FAQ",
  PUT_PRODUCT_FAQ: "PUT_PRODUCT_FAQ",
};

// Actions
const getProductFaqsAction = (faqs) => ({
  type: Types.GET_PRODUCT_FAQS,
  payload: faqs,
});

// const postProductFaqAction = (payload) => ({
//   type: Types.POST_PRODUCT_FAQ,
//   payload,
// });

const putProductFaqAction = (faq) => ({
  type: Types.PUT_PRODUCT_FAQ,
  payload: faq,
});

// Action Creators
export const uploadProductFaq = (productId, question, answer, active) => async (
  dispatch
) => {
  try {
    const payload = { question, answer, active };
    await post(`dme_portal/products/${productId}/faqs`, payload);
    dispatch(message("Faq Uploaded Successfully!"));
    // getProductPhotos(productId)(dispatch);
  } catch {
    dispatch(errorMessage("Faq Could Not Be Uploaded."));
  }
};

export const getProductFaqs = (productId) => async (dispatch) => {
  try {
    const response = await get(`dme_portal/products/${productId}/faqs`);
    dispatch(getProductFaqsAction(response.faqs));
  } catch {
    dispatch(errorMessage("Unable to retrieve product faqs."));
  }
};

export const UpdateProductFaq = (
  productId,
  faq_id,
  question,
  answer,
  active
) => async (dispatch) => {
  try {
    const payload = { question, answer, faq_id, active };
    await put(`dme_portal/products/${productId}/faqs`, payload);
    dispatch(putProductFaqAction(payload));
  } catch (err) {
    dispatch(errorMessage("Error when updating photo."));
  }
};
