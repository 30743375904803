import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'
import moment from 'moment'
import Table from 'components/ui/impl/table'
import { selectors } from 'reducers'
import { mapDispatchToProps } from 'actions/order-profile'
import Header from './header'

const ChangeLogs = ({ isLoading, rows }) => (
  <div>
    <Header showSpinner={isLoading}>Change Logs</Header>
    <Table
      rows={rows}
      rowKey="id"
      columns={[
        {
          header: 'Changed By',
          field: "changedByName",
          format: ({ changedByName }) => (
            changedByName || 'null'
          )
        },
        {
          header: 'Changed Time',
          field: "changedDate",
          format: ({ changedDate }) => (
            moment(changedDate).format("MMM DD, YYYY hh:mm A")
          )
        },
        {
          header: 'Patient',
          field: "patientFirstName",
          format: ({ patientFirstName, patientLastName }) => (
            `${patientFirstName} ${patientLastName}`
          )
        },
        {
          header: 'Changed Field',
          field: 'changedField'
        },
        {
          header: 'Changed From',
          field: 'oldValue'
        },
        {
          header: 'Changed To',
          field: 'newValue'
        }
      ]}
    />
  </div>
)

export default compose(
  connect((state, props) => {
    const {
      data: rows,
      isFetching: isLoading
    } = selectors.getOrderChangeLogs(state, props)
    return {
      rows,
      isLoading
    }
  }, mapDispatchToProps)
)(ChangeLogs)

ChangeLogs.propTypes = {
  orderId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
}
