import React from "react";
import PropTypes from "prop-types";
import TabLink from "./tab-link";

const TabList = ({ routes }) => (
  <ul className="tab-list">
    {routes.map(({ label, path, exact }) => (
      <TabLink key={label} label={label} path={path} exact={exact} />
    ))}
  </ul>
);

TabList.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      exact: PropTypes.bool
    })
  ).isRequired
};

export default TabList;
