import React from "react";
import PropTypes from "prop-types";
import { PieLabel } from "components/ui/graph-components";
import {
  Bar,
  BarChart,
  LabelList,
  CartesianGrid,
  Label,
  Legend,
  Pie,
  PieChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const PatientInactivations = ({ newPatientsInRange, deactivatedInRange }) => {
  // total active patients - total deactivated patients in range
  const net =
    newPatientsInRange.totalPatientCount - deactivatedInRange.deactivationCount;
  return (
    <div className="patient-status-breakdown">
      <div className="interactive-graph-container">
        <h4
          style={{
            color: "rgb(49, 130, 189)",
            boxShadow: "0 2px 1px -1px rgba(0, 0, 0, 0.1)"
          }}
        >
          Patient Growth
        </h4>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            margin={{ top: 10, bottom: 10, right: 50 }}
            data={[
              {
                name: "Activated",
                count: newPatientsInRange.totalPatientCount,
                fill: "green"
              },
              {
                name: "Inactivated",
                count:
                  typeof deactivatedInRange.deactivationCount == "number"
                    ? -deactivatedInRange.deactivationCount
                    : 0,
                fill: "rgb(255, 65, 54)"
              }
            ]}
            stackOffset="sign"
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis
              dataKey="count"
              interval={0}
              padding={{ top: 20, bottom: 20 }}
            />
            <Tooltip />
            <ReferenceLine y={0} stroke="#000" />
            <ReferenceArea
              y1={0}
              y2={net}
              fill={!net ? "#dddddd" : net > 0 ? "#82c182" : "#b46868"}
            />
            <ReferenceLine y={net} stroke="blue" strokeDasharray="3 10">
              <Label
                value={`Growth:  ${net}`}
                stroke={`${net > 0 ? "green" : "rgb(255, 65, 54)"}`}
                position={net > 0 ? "insideBottom" : "insideTop"}
                style={{ fontSize: "1.15em", letterSpacing: "0.1em" }}
              />
            </ReferenceLine>
            <Bar dataKey="count" maxBarSize={50} isAnimationActive={false}>
              <LabelList dataKey="count" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="interactive-graph-container">
        <h4
          style={{
            color: "rgb(49, 130, 189)",
            boxShadow: "0 2px 1px -1px rgba(0, 0, 0, 0.1)"
          }}
        >
          Patients Added
        </h4>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Legend />
            <Pie
              data={[
                {
                  label: "Active",
                  value: newPatientsInRange.activationCount || 0,
                  fill: "green"
                },
                {
                  label: "Inactive",
                  value: newPatientsInRange.deactivationCount || 0,
                  fill: "rgb(255, 65, 54)"
                }
              ]}
              dataKey="value"
              nameKey="label"
              cx="50%"
              cy="50%"
              innerRadius={50}
              labelLine
              isAnimationActive={false}
              label={PieLabel}
            >
              <Label
                value={`Total: ${newPatientsInRange.totalPatientCount}`}
                position="center"
                fill="rgb(49, 130, 189)"
                style={{ fontSize: "1.25em" }}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PatientInactivations;

PatientInactivations.propTypes = {
  deactivatedInRange: PropTypes.shape({
    deactivationCount: PropTypes.number
  }).isRequired,
  newPatientsInRange: PropTypes.shape({
    activationCount: PropTypes.number,
    deactivationCount: PropTypes.number,
    totalPatientCount: PropTypes.number
  }).isRequired
};
