import TwilioExport from "components/twilio-export";
import PatientImportForm from "components/screens/admin-tools/patient-import";
import InsuranceImportForm from "components/screens/admin-tools/insurance-import";
import OrderImportForm from "components/screens/admin-tools/order-import";
import BackorderImportForm from "components/screens/admin-tools/backorder-import";
import LogExportForm from "components/log-export";
import IVRControlCenter from "components/ivr-control-center";
import IVRJobManagement from "components/ivr-job-management";
import { ContactRecordProfileRoute } from "components/contact-records/call-profile";
import CompanyInventory from "components/company-inventory/company-inventory";
import AutofitDashboard from "components/autofit-dashboard";
import TrackingTable from "components/tracking-table";

export default [
  {
    path: "/tools/patient-import",
    component: PatientImportForm,
    allowedRoles: ["Administrator", "ServiceAdmin", "ServiceCoach", "ContractorAdministrator"]
  },
  {
    path: "/tools/insurance-import",
    component: InsuranceImportForm,
    allowedRoles: ["Administrator", "ServiceAdmin", "ServiceCoach", "ContractorAdministrator"]
  },
  {
    path: "/tools/order-import",
    component: OrderImportForm,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/tools/backorder-import",
    component: BackorderImportForm,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/tools/twilio-export",
    component: TwilioExport,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/tools/log-export",
    component: LogExportForm,
    allowedRoles: [
      "Administrator",
      "ServiceAdmin",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/tools/ivr-control-center",
    component: IVRControlCenter,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/tools/ivr-job-management",
    component: IVRJobManagement,
    allowedRoles: ["Administrator"]
  },
  {
    // not really used except for debugging
    path: "/contact-records/:sid",
    component: ContactRecordProfileRoute,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/tools/company-inventory",
    component: CompanyInventory,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/tools/autofit",
    component: AutofitDashboard,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "Therapist",
      "ServiceCoach",
      "ServiceAdmin",
      "SleepCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/tracking-table",
    component: TrackingTable
  }
];
