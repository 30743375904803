import { call, take, put } from "redux-saga/effects";
import { isUserTokenExpired } from "utils/misc";
import { pick } from "ramda";
import { Types as AuthTypes } from "actions/authentication";
import { get } from "utils/api";
import { Types } from "actions/user-profile";

export default function* coachSettingsSaga() {
  while (true) {
    const {
      payload: { user }
    } = yield take([AuthTypes.LOAD_STORED_USER, AuthTypes.LOGIN_USER_RESPONSE]);
    if (
      !isUserTokenExpired(user) &&
      (user.role === "SleepCoach" || user.role === "ServiceCoach")
    ) {
      yield call(fetchCoachSettings, user.id);
    }
  }
}

function* fetchCoachSettings(userId) {
  try {
    const result = yield get(`users/${userId}`);
    yield put({
      type: Types.SET_USER_SETTINGS,
      payload: pick(
        ["outboundCallRestricted", "block_autodialer_incoming_calls"],
        result
      )
    });
  } catch (error) {
    yield put({ type: Types.GET_COACH_SETTINGS_ERROR, error });
  }
}
