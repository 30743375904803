import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectors } from "reducers";
import A from "action-types";
import { Map, fromJS } from "immutable";
import { PageHeader} from "components/ui/index";
import Radium from "radium";
import styler from "react-styling";
import { requestVoipUsers } from 'actions/phone-book';
import CallRecordingSearchForm from "../../callrecording-search-form/index";

export function assignVmailToSleepCoach(voicemailGuids, sleepCoach, dataForRefresh, batchClear) {
    const payload = {
        voicemail_guids: voicemailGuids,
        sleep_coach: sleepCoach,
        dataForRefresh: dataForRefresh,
        batchClear: batchClear
    }
    return {
        type: A.VMAILS.ASSIGN_VOICEMAILS_TO_COACH,
        payload,
    }
}

export function markVmailAs(voicemailGuids, markAs, dataForRefresh, batchClear) {
    const payload = {
        voicemail_guids: voicemailGuids,
        mark_as: markAs,
        dataForRefresh: dataForRefresh,
        batchClear: batchClear
    }
    return {
        type: A.VMAILS.MARK_VOICEMAILS_AS,
        payload,
    }
}

export function setVMailsQuery(query) {
    return {
        type: A.VMAILS.SET_QUERY,
        query
    };
}

export class CallRecordingContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ini: {               
                team: "all",                           
            },           
        };
        this.onAdminSelectChange = this.onAdminSelectChange.bind(this);
    }

    componentWillMount() {
        this.props.requestVoipUsers();
    }

    componentWillUnmount() {
        this.props.setVMailsQuery(Map(this.state.ini));
    }

    queryWillUpdate(query, oldQuery) {
        const { setVMailsQuery } = this.props;
        const newQuery = oldQuery.merge(fromJS(query));        
        setVMailsQuery(newQuery);
    }

    onAdminSelectChange({ target: { value, name } }, voicemails, dataForRefresh = {}, batchClear) {
        const { vmailQuery } = this.props;
        const voicemailGuids = voicemails;

            //This is for assignment feature
        if (name == "team") {
                let newQuery = vmailQuery;
                newQuery = newQuery.setIn(["team"], value)
                this.queryWillUpdate(newQuery, vmailQuery)
        }        
        else {
            switch (value) { 
               case "":
                    break;
               case "markAsListened":
               case "markAsUnlistened":
               case "markAsWorked":
               case "markAsUnworked":
               case "markAsListenedAndWorked":
               case "markAsUnlistenedAndUnworked":
                    if (voicemails.length > 0) {    
                        let markType = value;
                        this.props.markVmailAs(voicemailGuids, markType, dataForRefresh, batchClear)
                   }                        
                   break;
               default:                 
                   if (voicemails.length > 0) {
                       let sleepCoach = value;                            
                       this.props.assignVmailToSleepCoach(voicemailGuids, sleepCoach, dataForRefresh, batchClear)
                    }
                    break;
            }                     
        }     
    }
    
    render() {
        const button = { path: `/`, value: "Close Call Recordings" };
        
        return (
            <div style={[S.offsetComp]}>
                <PageHeader
                    title="Call Recordings"
                    button={button}
                />
                <CallRecordingSearchForm></CallRecordingSearchForm>
            </div>
        );
    }
}

export default connect(
    state => ({
        teams: state.getIn(["entities", "team_names"]),
        phoneEmployeesArray: selectors.getPhoneBook(state), /* TODO why not pull from form attributes? */
        queryById: state.getIn(["query", "queryById"]),
        apiById: state.getIn(["query"]).apiById,
        vmailQuery: state.getIn(["vmails", "tableQuery"]),
        query: state.getIn(["query"]),
        ready: state.getIn(["vmails", "tableReady"]),
        user: state.get("user"),
        unSeenVMCount: state.getIn(["vmails", "vmCountSinceLastPageVisit"]),
        allSleepCoaches: state.getIn(["entities", "sleepCoaches"])
    }),
    dispatch =>
        bindActionCreators(
            {                
                setVMailsQuery,                
                requestVoipUsers,
                assignVmailToSleepCoach,
                markVmailAs
            },
            dispatch
        )
)(Radium(CallRecordingContainer));

CallRecordingContainer.propTypes = {   
    markVmailAs: PropTypes.func.isRequired,
    assignVmailToSleepCoach: PropTypes.func.isRequired,
    requestVoipUsers: PropTypes.func.isRequired,
    ready: PropTypes.bool,
    query: PropTypes.object,
    setVMailsQuery: PropTypes.func.isRequired,
    unSeenVMCount: PropTypes.number,
    vmailQuery: PropTypes.object,
    user: PropTypes.object,
    teams: PropTypes.object,
    phoneEmployeesArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    apiById: PropTypes.object,
    allSleepCoaches: PropTypes.object
};
CallRecordingContainer.contextTypes = {
    router: PropTypes.object
};

const S = styler`
  offsetComp
    width: 97%
    right: 20
    bottom: 20
    left: 20
  td
    white-space: nowrap
  link
    display: table-cell
    padding: 0.5625rem 0.625rem
  worked
    opacity: 0.5
  leftFilter
    margin-right: 5
`;
