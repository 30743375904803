import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader, Waiting } from "components/ui";
import { withRouter } from "react-router-dom";
import TextInput from "components/forms/text-input"
import { updateEmailTemplate, getEmailTemplate, setEmailTemplateForm } from "actions/company"
import { browserHistory } from "browser-history";
import { getRouteParam } from "routes";

export class EmailTemplateForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { getEmailTemplate } = this.props;
    getEmailTemplate(getRouteParam(this.props, "id"));
  }

  handleChange({ target }) {
    const { setEmailTemplateForm } = this.props
    setEmailTemplateForm({ [target.name]: target.value })
  }

  submitChanges(e) {
    e.preventDefault();
    const { updateEmailTemplate, template } = this.props;
    const companyId = getRouteParam(this.props, 'id');

    updateEmailTemplate(companyId, template)
      .then(() => {
        browserHistory.push(`/companies/${companyId}/profile`)
      })
  }

  render() {
    const { template } = this.props;
    const companyId = getRouteParam(this.props, 'id');
    const button = {
      path: `/companies/${companyId}/profile`,
      value: "Close Edit Template"
    };

    return template
      ? <div className="large-12 columns">
        <PageHeader
          title={
            companyId
              ? `Edit ${template.name}`
              : "New Email Template"
          }
          button={button}
        />
        <form
          onSubmit={e => this.submitChanges(e)}
        >
          <label><abbr title="required">*</abbr> Name</label>
          <TextInput name="name" value={template.name} onChange={this.handleChange} />

          <label>Tag</label>
          <TextInput name="subject" value={template.subject} onChange={this.handleChange} />

          <div className="text-right">
            <input className="button" type="submit" value="Submit" />
          </div>
          <textarea rows="35" cols="90" name="body" value={template.body} onChange={this.handleChange} />
        </form>
      </div>
      : <Waiting />;
  }
}

export default connect(
  state => ({
    template: state.get("company").emailForm
  }),
  {
    getEmailTemplate,
    updateEmailTemplate,
    setEmailTemplateForm
  }
)(withRouter(EmailTemplateForm));

EmailTemplateForm.propTypes = {
  getEmailTemplate: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  setEmailTemplateForm: PropTypes.func.isRequired,
  template: PropTypes.shape({
    name: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string
  }).isRequired,
  updateEmailTemplate: PropTypes.func.isRequired
};
