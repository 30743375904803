import React, { createContext, useState } from "react";
import { useFetch } from "hooks";
import { get, post, put } from "utils/api";
import { path } from "ramda";
import { errorMessage, message as sendToasterMessage } from "actions/message";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { fetchInventoryItem, invalidateInventoryItem } from "actions/inventory";

export const InventoryContext = createContext();

const InventoryProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [masterInventoryFailures, setMasterInventoryFailures] = useState([]);
  const [xrefInventoryFailures, setXrefInventoryFailures] = useState([]);
  const [companyInventoryFailures, setCompanyInventoryFailures] = useState([]);
  
  const generateErrorList = (error) => {
    const failures = error.response.body.status.split(":").pop();
    const failureList = failures?.split(",") ?? [];
    return failureList.map(x => ({ productID: x.trim() }));
  }
  const generateErrorMessage = (error) => {
    let _s = error.response?.body?.status;
    let _m = "";
    if (_s.length !== 0) {
      _m = `: ${_s}`;
    }
    return _m;
  }

  const {
    response: inventoryItemtemDMEList,
    fetchData: getInventoryItemtemDMEList,
    isFetching: isFetchingInventoryItemtemDMEList
  } = useFetch({
    defaultValue: [],
    apiFn: (data) => get(`inventory_items/dme_inventory_all/${data.inventoryId}`),
    transformError: path(["response", "body", "status"]),
    onError: (error) => {
      const m = generateErrorMessage(error);
      dispatch(errorMessage(`Failed to pull inventory item dme list${m}`));
    }
  });
  const {
    response: inventoryItemtemDMEChangeLogList,
    fetchData: getInventoryItemtemDMEChangeLogList,
    isFetching: isFetchingInventoryItemtemDMEChangeLogList
  } = useFetch({
    defaultValue: [],
    apiFn: (data) => get(`inventory_items/dme_inventory_changelog/${data.xrefId}`),
    transformError: path(["response", "body", "status"]),
    onError: (error) => {
      const m = generateErrorMessage(error);
      dispatch(errorMessage(`Failed to pull inventory item dme change log list${m}`));
    }
  });
  const { 
    response: companyInventoryResponse,
    fetchData: submitCompanyInventoryImport,
    isFetching: companyIsFetching
  } = useFetch({
    defaultValue: [],
    apiFn: async (data) => post(`imports/dme_inventory`, data),
    onSuccess: inventoryResponse => {
      inventoryResponse.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      dispatch(sendToasterMessage("Successfully imported dme inventory file"));
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
      const list = generateErrorList(error);
      setCompanyInventoryFailures(list);
      const m = list?.length;
      dispatch(errorMessage(`Failed to import ${m} dme inventory`));
    }
  });
  const { 
    response: xrefInventoryResponse,
    fetchData: submitCompanyXrefInventoryImport,
    isFetching: xrefIsFetching
  } = useFetch({
    defaultValue: [],
    apiFn: async (data) => post(`imports/xref_inventory`, data),
    onSuccess: inventoryResponse => {
      inventoryResponse.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      dispatch(sendToasterMessage("Successfully imported xref inventory file"));
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
      const list = generateErrorList(error);
      setXrefInventoryFailures(list);
      const m = list?.length;
      dispatch(errorMessage(`Failed to import ${m} xref inventory`));
    }
  });
  const { 
    response: masterInventoryResponse,
    fetchData: submitMasterInventoryImport,
    isFetching: masterIsFetching
  } = useFetch({
    defaultValue: [],
    apiFn: async (data) => post(`imports/master_inventory`, data),
    onSuccess: inventoryResponse => {
      inventoryResponse.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      dispatch(sendToasterMessage("Successfully imported master inventory file"));
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
      const list = generateErrorList(error);
      setMasterInventoryFailures(list);
      const m = list?.length;
      dispatch(errorMessage(`Failed to import ${m} master inventory`));
    }
  });
  const {
    response: masterInventoryItemtemChangeLogList,
    fetchData: getMasterInventoryItemtemChangeLogList,
    isFetching: isFetchingMasterInventoryItemtemChangeLogList
  } = useFetch({
    defaultValue: [],
    apiFn: (data) => get(`inventory_items/master_inventory_changelog/${data.itemId}`),
    transformError: path(["response", "body", "status"]),
    onError: (error) => {
      const m = generateErrorMessage(error);
      dispatch(errorMessage(`Failed to pull master inventory item change log list${m}`));
    }
  });
  const { 
    response: deleteRelationshipResponse,
    fetchData: deleteRelationship,
    isFetching: deleteRelationshipIsFetching
  } = useFetch({
    defaultValue: {},
    apiFn: async (data) => put(`inventory_items/delete_relationship`, data),
    onSuccess: response => {
      response.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      dispatch(invalidateInventoryItem({ inventoryId: response.idOne }));
      dispatch(fetchInventoryItem({ inventoryId: response.idOne }));
      dispatch(invalidateInventoryItem({ inventoryId: response.idTwo }));
      dispatch(fetchInventoryItem({ inventoryId: response.idTwo }));
      dispatch(sendToasterMessage("Successfully deleted relationship"));
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
      const m = generateErrorMessage(error);
      dispatch(errorMessage(`Failed to delete inventory relationship${m}`));
    }
  });
  const { 
    response: createRelationshipResponse,
    fetchData: createRelationship,
    isFetching: createRelationshipIsFetching
  } = useFetch({
    defaultValue: {},
    apiFn: async (data) => post(`inventory_items/create_relationship`, data),
    onSuccess: response => {
      response.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      dispatch(invalidateInventoryItem({ inventoryId: response.parentId }));
      dispatch(fetchInventoryItem({ inventoryId: response.parentId }));
      dispatch(invalidateInventoryItem({ inventoryId: response.childId }));
      dispatch(fetchInventoryItem({ inventoryId: response.childId }));
      dispatch(sendToasterMessage("Successfully created relationship"));
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
      const m = generateErrorMessage(error);
      dispatch(errorMessage(`Failed to create inventory relationship${m}`));
    }
  });
  return (
    <InventoryContext.Provider
      value={{
        inventoryItemtemDMEList,
        getInventoryItemtemDMEList,
        isFetchingInventoryItemtemDMEList,
        companyIsFetching,
        companyInventoryResponse,
        companyInventoryFailures,
        submitCompanyInventoryImport,
        xrefIsFetching,
        xrefInventoryResponse,
        xrefInventoryFailures,
        submitCompanyXrefInventoryImport,
        masterIsFetching,
        masterInventoryResponse,
        masterInventoryFailures,
        submitMasterInventoryImport,
        deleteRelationshipResponse,
        deleteRelationship,
        deleteRelationshipIsFetching,
        createRelationshipResponse,
        createRelationship,
        createRelationshipIsFetching,
        inventoryItemtemDMEChangeLogList,
        getInventoryItemtemDMEChangeLogList,
        isFetchingInventoryItemtemDMEChangeLogList,
        masterInventoryItemtemChangeLogList,
        getMasterInventoryItemtemChangeLogList,
        isFetchingMasterInventoryItemtemChangeLogList
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export default InventoryProvider;
InventoryProvider.propTypes = {
  children: PropTypes.node,
  selectedGuid: PropTypes.string
};
