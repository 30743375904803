import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "reducers";
import { updateCompanyInventory } from "actions/company-inventory";

export const CompanyInventoryModal = ({
  closeModal,
  editState,
  date_of_service,
  serial_number,
  model_number,
  name,
  additional_equipment,
  changeEditState,
  user_id,
  guid
}) => {

    const dispatch = useDispatch();
    const sleepCoaches = useSelector(selectors.getFormOptionsSleepCoach);
    const [selectedSleepCoach, setSelectedSleepCoach] = useState(user_id);

    const registerSleepCoachChange = (e) =>{
        setSelectedSleepCoach(e.target.value)
    }

    const [inventoryEdit, setInventoryEdit] = useState({
        SSerialNumber: serial_number,
        SModelNumber: model_number,
        SAdditionalEquipment: additional_equipment,
        FkiAssignedTo: null,
        SGUID: guid
      });
    const [inventoryEditCompare] = useState({
        SSerialNumber: serial_number,
        SModelNumber: model_number,
        SAdditionalEquipment: additional_equipment,
        FkiAssignedTo: null,
        SGUID: guid
      });

    const handleUserEdit = e => {
        const updatedState = { ...inventoryEdit };
        updatedState[e.target.id] = e.target.value;
        setInventoryEdit(updatedState);
      };

      const checkForChanges = () =>{
          if(JSON.stringify(inventoryEdit) !== JSON.stringify(inventoryEditCompare) || selectedSleepCoach !== user_id){
              dispatch(updateCompanyInventory(inventoryEdit, selectedSleepCoach));
              closeModal();
          }
          else if(JSON.stringify(inventoryEdit) === JSON.stringify(inventoryEditCompare) && selectedSleepCoach === user_id){
              closeModal();
          }
      }


  if (editState) {
    return (
      <>
       <div className="inventoryModal">
          <label>
            Sleep Coach
            <select defaultValue={user_id} onChange={registerSleepCoachChange} >
              <option key={null}>none</option>
              {sleepCoaches.map(({ text, value }) => {
                return (
                <option value={value} key={value}>
                  {text}
                </option>
                )
              })
            }
            </select>
            </label>

        <div className="field">
          <label>Serial Number</label>
          <input
            onChange={handleUserEdit}
            type="text"
            name="serialNumber"
            id="SSerialNumber"
            defaultValue={serial_number}
            required
          />
        </div>
        <label>Model Number</label>
        <div className="field">
          <input
            onChange={handleUserEdit}
            type="text"
            name="modelNumber"
            id="SModelNumber"
            defaultValue={model_number}
            required
          />
        </div>
        <label>Notes</label>
        <div className="field">
          <input
            onChange={handleUserEdit}
            type="text"
            name="additionalEquipment"
            id="SAdditionalEquipment"
            defaultValue={additional_equipment}
            required
          />
        </div>
        <div>
          Date of Service: {moment(date_of_service).format("MMM DD, YYYY")} (Not Editable)
        </div>
        <div><button className="button tiny" onClick={()=>{
            changeEditState();
            checkForChanges();
        }}>Save Changes</button></div></div>
      </>
    );
  } else {
    return (
     <>
        <div>
          Assigned to: {name}
        </div>
        <div>Serial Number: {serial_number}</div>
        <div>Model Number: {model_number}</div>
        <div>Additional Equipment: {additional_equipment}</div>
        <div>
          Date of Service: {moment(date_of_service).format("MMM DD, YYYY")}
        </div>
        <div className="button--container">
          <button className="button tiny" onClick={changeEditState}>
            Edit
          </button>
        </div>
        </>
    );
  }
};
CompanyInventoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  editState: PropTypes.bool,
  date_of_service: PropTypes.string,
  serial_number: PropTypes.string,
  model_number: PropTypes.string,
  name: PropTypes.string,
  additional_equipment: PropTypes.string,
  changeEditState: PropTypes.func,
  user_id: PropTypes.string,
  guid: PropTypes.string
}
