import { PASSWORD_EXP } from "utils/password";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import moment from "moment";
import { get, post } from "utils/api";

export const isRequired = (value) =>
  value || typeof value === "number" ? undefined : "Required";

export function isValidPhoneNumber(value = "") {
  if (typeof value !== "string" || value.length == 0) {
    return false;
  } else {
    return /^(\+?)(0|[0-9][0-9]{9,13})$/i.test(value);
  }
}

export const isValidEmail = isEmail;

// accept 5 or 9 digit zip
export function isValidUSZip(sZip) {
  if (typeof sZip !== "string" || sZip.length == 0) {
    return false;
  } else {
    // return /^\d{5}(-\d{4})?$/.test(sZip);
    return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(sZip);
  }
}

export function isValidNumber(s) {
  const integer = parseFloat(s);
  return !Number.isNaN(integer);
}

export function isValidDate(d) {
  if (typeof d !== "string") return false;
  //handles "1992-08-06T21:00:00" format from DB
  const date = d.split("T")[0].replace(/-/g, "/");

  if (d.length == 0) {
    return false;
  } else if (/^[1|2]\d{3}[/|-]\d{1,2}[/|-]\d{1,2}$/.test(date)) {
    // tests for YYYY/MM/DD or YYYY-MM-DD
    return true;
  } else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
    // tests for MM/DD/YYYY
    return true;
  } else {
    return false;
  }
}

export function isValidPassword(p) {
  return PASSWORD_EXP.test(p);
}

export function isValidDateRange(start, end, format = "MM/DD/YYYY") {
  if (!start || !end) return false;
  const startDate = moment(start, format);
  const endDate = moment(end, format);
  if (!startDate.isValid || !endDate.isValid) return false;
  return moment(startDate).isSameOrBefore(endDate);
}

export const asyncValidateUniqueEmail = async (email, userId) => {
  try {
    if (typeof email === "string" && email.length > 0) {
      const { ids = [] } = await post(`patients/check_duplicate_email`, {email, user_id:userId});
      if (userId) {
        if (ids.length > 0 && !ids.includes(userId))
          return "Email is already used.";
      } else {
        if (ids.length > 0) return "Email is already used.";
      }
    }
  } catch (error) {
    return;
  }
};

export const asyncUserHasAssignedPatients = async (userId) => {
  try {
    const result = await get(`users/has_patients/${userId}`);
    return result;
  } catch (error) {
    return -1;
  }
};

export const isValidUrl = (value) =>
  typeof value === "string"
    ? isURL(value, {
        protocols: ["http", "https"],
      })
    : false;
