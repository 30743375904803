import { createContext, useEffect } from "react";
import useTableSettings, { cleanQuery } from "hooks/useTableSettings";
import isEqual from "lodash/isEqual";

export const OrdersTableSettingsContext = createContext();

const useOrderTableSettings = (query, queryDispatcher) => {
  const columns = [
    {
      header: "Patient",
      field: "patient",
      active: true
    },
    {
      header: "Account #",
      field: "patient_account_number",
      active: true
    },
    {
      header: "Admin",
      field: "claimed_by",
      active: false
    },
    {
      header: "Company",
      field: "company",
      active: true
    },
    {
      header: "Submitted",
      field: "submitted",
      active: true
    },
    {
      header: "Status changed",
      field: "status_changed",
      active: false
    },
    {
      header: "Platform",
      field: "platform",
      active: false
    },
    {
      header: "Order Progress",
      field: "hold_count_open",
      active: false
    },
    {
      header: "Last Updated",
      field: "last_updated",
      active: false
    },
    {
      header: "Delayed Date",
      field: "pending_delayed_date",
      active: false
    },
    {
      header: "Entered By",
      field: "sleep_coach",
      active: true
    },
    {
      header: "Insurance",
      field: "insurance",
      active: false
    },
    {
      header: "Priority",
      field: "high_priority",
      active: false
    },
    {
      header: "Follow Up Reason",
      field: "followUpReason",
      active: false
    },
    {
      header: "On Hold Reason",
      field: "onHoldReason",
      active: false
    },
    {
      header: "Reason Rejected",
      field: "reason",
      active: false
    },
    {
      header: "Pending Info",
      field: "pending_info",
      active: false
    }
  ];
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    visibleColumns,
    resetTableSettings
  } = useTableSettings("orders-table-settings", columns);

  // remove sorting and filters when column is removed
  useEffect(() => {
    const newQuery = cleanQuery(visibleColumns, query);
    if (!isEqual(newQuery, query)) {
      queryDispatcher({ type: "updateQuery", ...newQuery });
    }
  }, [visibleColumns, queryDispatcher]);
  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  };
};

export default useOrderTableSettings;
