import React from "react";
import { PureModal } from "components/ui";
import AvailableCoachesCreateModal from "./available-coaches-modal.jsx";
import PropTypes from "prop-types";

export default function CreateAppointmentForCoach({ onSubmit }) {
    return (
    <div style={{paddingLeft:"10px"}}>
        <PureModal
            renderTrigger={({ openModal }) => (
                <div>
                    <span
                        onClick={openModal}
                        style={{fontSize: "20px", cursor: "pointer", color: "#0090bc", top:"6px"}}
                        title="Create schedules and appointments for coaches"
                    >
                        CREATE SCHEDULE
                    </span>
                </div>
            )}

            renderContent={
                ({ closeModal }) =>
                    <AvailableCoachesCreateModal
                        onClose={() => closeModal()}
                        onSubmit={() => onSubmit() }
                    />
            }
        ></PureModal>
    </div>
    )
}

CreateAppointmentForCoach.propTypes = {
    onSubmit: PropTypes.func
}