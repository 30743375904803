import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import HasRolePermission from "components/shared/has-role-permission";

const CompanyTeamSelect = ({ setFilters, teams, sleepCoaches }) => {
  const [selectedTeam, selectTeam] = useState();
  return (
    <HasRolePermission allowedRoles={["Administrator", "SleepCoach"]}>
      <div className="comany-team-select">
        <label>Team</label>
        <select
          onChange={({ target }) => {
            selectTeam(target.value);
            const sleepCoachIds = sleepCoaches
              .filter(({ team_name }) => team_name === target.value)
              .map(({ GUID }) => GUID);
            setFilters("sleep_coach", sleepCoachIds);
          }}
          value={selectedTeam}
        >
          <option />
          {teams.map(({ value, text }) => (
            <option value={value} key={value}>
              {text}
            </option>
          ))}
        </select>
      </div>
    </HasRolePermission>
  );
};

export default connect(state => ({
  sleepCoaches: selectors.getFormOptionsSleepCoach(state),
  teams: selectors.getTeamNameOptions(state)
}))(CompanyTeamSelect);

CompanyTeamSelect.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object),
  sleepCoaches: PropTypes.arrayOf(PropTypes.object),
  setFilters: PropTypes.func.isRequired
};
