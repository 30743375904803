import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PatientProfile from "components/screens/patient/patient-profile";
import { autoDialerProcessNext } from "actions/auto-dialer";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";

const PatientProfileChat = ({
  wrapupTime,
  autoDialerProcessNext,
  chatDetails
}) => (
  <div className="pages">
    <div className="page-container">
      {chatDetails && chatDetails.patient_id && (
        <div style={{ padding: 20 }}>
          <PatientProfile patientId={chatDetails.patient_id} />
        </div>
      )}
    </div>
    <div className="page-select">
      <div className="autodialer-menu">
        {wrapupTime !== 0 && (
          <i title="Next" onClick={autoDialerProcessNext}>
            <IoArrowRightA />
          </i>
        )}
        <span className="autodialer-status">Text Chat with patient</span>
      </div>
    </div>
  </div>
);

PatientProfileChat.propTypes = {
  wrapupTime: PropTypes.number,
  autoDialerProcessNext: PropTypes.func.isRequired,
  chatDetails: PropTypes.object
};

export default connect(
  state => ({
    wrapupTime: selectors.getAutoDialerWrapupTime(state),
    chatDetails: selectors.getAutoDialerChatDetails(state)
  }),
  {
    autoDialerProcessNext
  }
)(PatientProfileChat);
