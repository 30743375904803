// follows redux-middleware callApi generated types
const makeApiTypes = name => ({
  [`${name}_REQUEST`]: `${name}_REQUEST`,
  [`${name}`]: `${name}`,
  [`${name}_SUCCESS`]: `${name}_SUCCESS`,
  [`${name}_FAILURE`]: `${name}_FAILURE`
});

export default {
  READY: "READY",
  ORDER: {
    GET: "GET_ORDER",
    // SET_FORM: "SET_ORDER_FORM",
    // SET_FORM_ACTIVE: "SET_ORDER_FORM_ACTIVE",
    SELECT_COMMON: "SELECT_COMMON_ORDER",
    ADD_LINE_ITEM: "ADD_ORDER_LINE_ITEM",
    TYPES_AND_MANUFACTURER: "TYPES_AND_MANUFACTURER"
  },
  ORDERS: {
    GET: "GET_ORDERS",
    RECEIVE: "RECEIVE_GET_ORDERS",
    UPDATE: "UPDATE_ORDER",
    SET_QUERY: "SET_ORDERS_QUERY",
    EXPORT: "GET_ORDER_EXPORT",
    UPDATE_TABLE: "UPDATE_ORDERS_TABLE",
    ...makeApiTypes("BACKORDER_IMPORT")
  },
  ORDER_STATUS: {
    UPDATE_REQUEST: "ORDER_UPDATE_REQUEST",
    UPDATE_RESPONSE: "ORDER_UPDATE_RESPONSE",
    REQUEST_EXPORT_INVOICE: "REQUEST_EXPORT_INVOICE"
  },
  PATIENT: {
    SNAPSHOT: "PATIENT_SNAPSHOT",
    IMPORT_FAILURE: "FAILURE_SUBMIT_PATIENT_IMPORT",
    IMPORT_SUCCESS: "RECEIVE_SUBMIT_PATIENT_IMPORT",
    GET: "GET_PATIENT",
    GET_PROFILE: "GET_PROFILE",
    GET_PROFILE_INFO: "GET_PROFILE_INFO",
    GET_PROFILE_ORDER_HISTORY: "GET_PROFILE_ORDER_HISTORY",
    GET_NOTES: "GET_PATIENT_NOTES",
    GET_ORDERS: "GET_PATIENT_ORDERS",
    GET_WALK_IN_ORDERS: "GET_PATIENT_WALK_IN_ORDERS",
    GET_SPECIAL_FIELDS: "GET_PATIENT_SPECIAL_FIELDS",
    GET_CONTACT_RECORDS: "GET_PATIENT_CONTACT_RECORDS",
    GET_EQUIPMENT: "GET_PATIENT_EQUIPMENT",
    GET_CHANGE_LOGS: "GET_PATIENT_CHANGE_LOGS",
    GET_ACCESS_LOGS: "GET_PATIENT_ACCESS_LOGS",
    GET_HCPCS_RECORDS: "GET_PATIENT_HCPCS_RECORDS",
    GET_XZERO_RECORDS: "GET_PATIENT_XZERO_RECORDS",
    ACCESS: "PATIENT_ACCESS",
    CREATE: "CREATE_PATIENT",
    UPDATE: "UPDATE_PATIENT",
    FETCH: "FETCH_PATIENTS",
    GET_CONTACT_LIST: "GET_PATIENT_CONTACT_LIST",
    SEND_TEXT: "SEND_TEXT_TO_PATIENT",
    SEND_PUSH_NOTIFICATION: "SEND_PUSH_NOTIFICATION_TO_PATIENT",
    SEND_APP_DOWNLOAD_EMAIL: "SEND_APP_DOWNLOAD_EMAIL",
    SEND_RESUPPLY_EMAIL: "SEND_RESUPPLY_EMAIL_TO_PATIENT",
    SEND_TEXT_CONSENT_EMAIL: "SEND_TEXT_CONSENT_EMAIL_TO_PATIENT",
    SEND_TEXT_CONSENT_TEXT: "SEND_TEXT_CONSENT_TEXT_TO_PATIENT",
    SEND_KUDOS_TEXT: "SEND_KUDOS_TEXT",
    SET_FORM: "SET_FORM",
    ADD_NOTE: "ADD_NOTE_TO_PATIENT",
    CREATE_WALK_IN_ORDER: "CREATE_WALK_IN_ORDER",
    CREATE_CONTACT_RECORD: "CREATE_CONTACT_RECORD",
    ADD_EQUIPMENT_ITEM: "ADD_EQUIPMENT_ITEM",
    DELETE_EQUIPMENT_ITEM: "DELETE_EQUIPMENT_ITEM",
    GET_MASK_FITTING_RESULTS: "GET_MASK_FITTING_RESULTS",
    ...makeApiTypes("PATIENT_IMPORT")
  },
  INSURANCE: {
    IMPORT_FAILURE: "FAILURE_SUBMIT_INSURANCE_IMPORT",
    IMPORT_SUCCESS: "RECEIVE_SUBMIT_INSURANCE_IMPORT",
    ...makeApiTypes("INSURANCE_IMPORT")
  },
  TRACKING: {
    IMPORT_FAILURE: "FAILURE_SUBMIT_TRACKING_IMPORT",
    IMPORT_SUCCESS: "RECEIVE_SUBMIT_TRACKING_IMPORT",
    ...makeApiTypes("TRACKING_IMPORT")
  },
  SEARCH: {
    PUT: "PUT_SEARCH",
    GET_VALUES: "GET_SEARCH_VALUES",
    QUERY: "SEARCH_QUERY",
    SEARCH_TABLE_QUERY_UPDATE: "SEARCH_TABLE_QUERY_UPDATE"
  },
  COMPLIANCE: {
    CHECK: "CHECK_COMPLIANCE"
  },
  COMPANY: {
    GET: "GET_COMPANY",
    ...makeApiTypes("GET_COMPANY"),
    SET: "SET_COMPANY",
    POST: "CREATE_COMPANY",
    SET_BRANCH: "SET_COMPANY_BRANCH",
    UPDATE_BRANCH: "UPDATE_BRANCH",
    GET_MONTHLY_REPORT: "GET_MONTHLY_REPORT",
    GET_ORDERS_REPORT: "GET_COMPANY_ORDERS_REPORT",
    ...makeApiTypes("GET_COMPANY_ORDERS_REPORT"),
    GET_CONTACTS_REPORT: "GET_COMPANY_CONTACTS_REPORT",
    ...makeApiTypes("GET_COMPANY_CONTACTS_REPORT"),
    ...makeApiTypes("GET_PATIENTS_INSURANCE_REPORT")
  },
  SNAPSHOT: {
    ORDERS: "ORDERS_SNAPSHOT",
    PATIENTS: "PATIENTS_SNAPSHOT"
  },
  USER: {
    LOGOUT: "LOGOUT_USER",
    LOGIN: "LOGIN_USER",
    GET: "GET_USER",
    SET: "SET_USER",
    UPDATE: "UPDATE_USER"
  },
  USERS: {
    GET: "GET_USERS"
  },
  FULFILLMENT: {
    FULFILLMENT_TABLE_QUERY_UPDATE: "FULFILLMENT_TABLE_QUERY_UPDATE",
    GET: "GET_FULFILLMENT",
    RECEIVE: "RECEIVE_GET_FULFILLMENT",
    SET_QUERY: "SET_FULFILLMENT_QUERY",
    GET_ORDERS: "GET_FULFILLMENT_ORDERS"
  },
  EXPORTS: {
    POST: "POST_EXPORT",
    GET: "GET_EXPORTS",
    TABLE: "EXPORT_TABLE"
  },
  INSIGHTS: {
    GET: "GET_INSIGHTS",
    RECEIVE: "RECEIVE_GET_INSIGHTS"
  },
  INVENTORY: {
    GET: "GET_INVENTORY",
    GET_EXPORT: "GET_INVENTORY_EXPORT",
    GET_ITEM: "GET_INVENTORY_ITEM",
    SET_FORM: "SET_INVENTORY_FORM",
    UPDATE: "UPDATE_INVENTORY",
    SET_PROFILE: "SET_INVENTORY_PROFILE",
    ADD: "ADD_INVENTORY",
    GET_META: "GET_INVENTORY_META",
  },
  NOTIFICATIONS: {
    MESSAGE: "MESSAGE",
    UNKNOWN_ERROR: "UNKNOWN_ERROR",
    ERROR: "ERROR",
    DISMISS: "DISMISS_MSG"
  },
  VMAILS: {
    GET: "GET_VOICEMAILS",
    EXPORT: "GET_VMAIL_EXPORT",
    SET_QUERY: "SET_VOICEMAILS_QUERY",
    ASSIGN_VOICEMAILS_TO_COACH: "ASSIGN_VOICEMAILS_TO_COACH",
    MARK_VOICEMAILS_AS: "MARK_VOICEMAILS_AS"
  },
  VMAIL: {
    LISTENED_TO: "LISTENED_TO_VOICEMAIL",
    GET: "GET_VOICEMAIL",
    SET_PROFILE: "SET_VOICEMAIL_PROFILE",
    WORKED: "WORKED_VOICEMAIL"
  },
  IVR: {
    QUESTIONS: {
      RESPONSE_GET_ALL: "IVR_RESPONSE_GET_ALL",
      GET_ALL: "IVR_GET_ALL_QUESTIONS",
      CREATE_QUESTION: "IVR_CREATE_QUESTION",
      UPDATE_QUESTION: "IVR_UPDATE_QUESTION",
      TOGGLE_QUESTION: "IVR_TOGGLE_QUESTION"
    },
    STATES: {
      GET_MASTER: "IVR_STATES_GET_MASTER",
      GET_ALL_BY_CALLSID: "IVR_STATES_GET_ALL_BY_CALLSID",
      GET_ALL_BY_PATIENT: "IVR_STATES_GET_ALL_BY_PATIENT",
      RESPONSE_GET_MASTER: "RESPONSE_IVR_STATES_GET_MASTER",
      RESPONSE_GET_ALL_BY_CALLSID: "RESPONSE_IVR_STATES_GET_ALL_BY_CALLSID",
      RESPONSE_GET_ALL_BY_PATIENT: "RESPONSE_IVR_STATES_GET_ALL_BY_PATIENT",
      START_COMPLETED_IVR_CALLS_TO_ORDERS_CONVERSION:
        "IVR_STATES_START_COMPLETED_IVR_CALLS_TO_ORDERS_CONVERSION"
    },
    JOBS: {
      SUBMIT_UPDATE_REQUEST_UPDATES: "IVR_JOBS_SUBMIT_UPDATE_REQUEST_UPDATES"
    }
  }
};
