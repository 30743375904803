import React from "react";
import PropTypes from "prop-types";
import CmnNotes from "./cmn-notes";
import RaPatientCmns from "features/referral-answer/cmns/patient-cmns";
import {
  // HasPermission
  AppPermissionContext
} from "components/shared/app-permission";

const PatientCmns = ({ patientId, readOnly }) => {
  const { ra_cmn_integration } = React.useContext(AppPermissionContext);

  if (ra_cmn_integration)
    return <RaPatientCmns patientId={patientId} readOnly={readOnly} />;
  else return <CmnNotes patientId={patientId} readOnly={readOnly} />;
};

PatientCmns.propTypes = {
  patientId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

export default PatientCmns;
