import {
  all,
  delay,
  race,
  spawn,
  call,
  take,
  put,
  takeEvery
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import config from "./config";
import { Types } from "./actions";
import { errorMessage } from "actions/message";
import { Types as AuthTypes } from "actions/authentication";
function* initClient() {
  yield take(Types.GOOGLE_API_LOADED);
  try {
    const gapi = window.gapi;
    const channel = googleApiEventChannel(gapi);
    yield put({ type: Types.GOOGLE_API_INITIALIZED });
    yield spawn(watchGoogleApiEvents, channel);
    yield checkUserLogin({ error: false });
    yield takeEvery([AuthTypes.LOGIN_USER_RESPONSE], checkUserLogin);
  } catch (error) {
    //eslint-disable-next-line no-console
  }
}

function* checkUserLogin(action) {
  const { error } = action;
  if (!error) {
    const gapi = window.gapi;
    const isLoggedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
    if (isLoggedIn) {
      yield put({ type: Types.GOOGLE_AUTH_LOGGED_IN });
      yield call(fetchFolders);
    } else yield put({ type: Types.GOOGLE_AUTH_LOGGED_OUT });
  }
}

function* watchGetCompanyFilesRequest() {
  while (true) {
    const action = yield take(Types.REQUEST_GET_COMPANY_FILES);
    const { timeout } = yield race({
      fetched: call(fetchCompanyFiles, action),
      timeout: delay(2000)
    });
    if (timeout) {
      yield put({ type: Types.FAILURE_GET_COMPANY_FILES, error: "timeout" });
    }
  }
}

function* watchGetDriveDataRequest() {
  while (true) {
    const action = yield take(Types.REQUEST_GET_DRIVE_DATA);
    const { timeout } = yield race({
      fetched: call(fetchFolders, action),
      timeout: delay(5000)
    });
    if (timeout) {
      yield put({ type: Types.FAILURE_GET_DRIVE_DATA, error: "timeout" });
    }
  }
}

function* watchAttachFileToCompanyRequest() {
  while (true) {
    const action = yield take(Types.REQUEST_ATTACH_COMPANY_FILE);
    yield call(attachCompanyFile, action);
  }
}

function* watchGoogleApiEvents(channel) {
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* watchGoogleSignOut() {
  while (true) {
    yield take(Types.GOOGLE_API_SIGNOUT);
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      yield call(auth2.signOut);
      window.gapi.auth2.getAuthInstance().signOut();
    } catch (errror) {
      yield put(errorMessage("Something went wrong!"));
    }
  }
}

function* watchGoogleSignIn() {
  while (true) {
    yield take(Types.GOOGLE_API_SIGNIN);
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      yield call(auth2.signIn);
    } catch (error) {
      yield put(errorMessage("Failed To Authorize Google account"));
    }
  }
}

function googleApiEventChannel(client) {
  return eventChannel(emitter => {
    client.auth2.getAuthInstance().isSignedIn.listen(isLoggedIn => {
      if (isLoggedIn) {
        emitter({ type: Types.GOOGLE_AUTH_LOGGED_IN });
        emitter({ type: Types.REQUEST_GET_DRIVE_DATA });
      } else emitter({ type: Types.GOOGLE_AUTH_LOGGED_OUT });
    });
    //unsubscribe function
    return () => {};
  });
}

export function* fetchCompanyFiles(action) {
  try {
    const client = window.gapi.client;
    const {
      payload: { companyId }
    } = action;
    const {
      result: { files = [] }
    } = yield client.drive.files.list({
      q: `properties has { key='companyId' and value='${companyId}' }`,
      includeTeamDriveItems: true,
      supportsTeamDrives: true,
      prettyPrint: true,
      sharedWithMe: true,
      mimeType: "application/vnd.google-apps.document"
    });
    yield put({ type: Types.RESPONSE_GET_COMPANY_FILES, payload: { files } });
  } catch (error) {
    yield put({ type: Types.FAILURE_GET_COMPANY_FILES, error });
  }
}

export function* fetchFolders() {
  try {
    const client = window.gapi.client;
    yield put({ type: Types.REQUEST_GET_FOLDERS });
    const {
      result: { files = [] }
    } = yield client.drive.files.list({
      q: `mimeType='application/vnd.google-apps.folder' and ${config.FOLDERS.map(
        folder => `name contains '${folder}'`
      ).join(" or ")}`,
      prettyPrint: true,
      sharedWithMe: true,
      pageSize: 500,
      orderBy: "name"
    });
    yield put({
      type: Types.RESPONSE_GET_FOLDERS,
      payload: { folders: files }
    });
    yield all(files.map(({ id }) => call(fetchFolderFiles, id)));
  } catch (error) {
    //eslint-disable-next-line no-console
    errorMessage("Failed to pull info from Google Drive");
    yield put({ type: Types.FAILURE_GET_FOLDERS });
  }
}

export function* fetchFolderFiles(folderId) {
  try {
    const client = window.gapi.client;
    yield put({
      type: Types.REQUEST_GET_FOLDER_FILES,
      payload: { parent: folderId }
    });
    const {
      result: { files = [] }
    } = yield client.drive.files.list({
      q: `parents in '${folderId}'`,
      prettyPrint: true,
      sharedWithMe: true,
      orderBy: "name"
    });
    yield put({
      type: Types.RESPONSE_GET_FOLDER_FILES,
      payload: { files, parent: folderId }
    });
  } catch (error) {
    yield put({ type: Types.FAILURE_GET_FOLDER_FILES, error });
    yield put(
      errorMessage("Something went wrong. Failed to get Google Drive data.")
    );
  }
}

export function* attachCompanyFile(action) {
  try {
    const client = window.gapi.client;
    const {
      payload: { companyId, fileId, detach = false }
    } = action;
    const { result } = yield client.request({
      path: `/drive/v3/files/${fileId}/permissions`
    });
    const isOwner = result.permissions.some(({ role }) => role === "owner");
    if (!isOwner) {
      yield put({ type: Types.FAILURE_ATTACH_COMPANY_FILE });
      yield put(errorMessage("Not Authorized to perform this action."));
      return;
    }
    yield client.request({
      path: `/drive/v3/files/${fileId}`,
      method: "PATCH",
      body: {
        properties: {
          companyId: detach ? "" : companyId
        }
      }
    });
    yield put({
      type: Types.RESPONSE_ATTACH_COMPANY_FILE,
      payload: { companyId, fileId }
    });
    yield put({
      type: Types.REQUEST_GET_COMPANY_FILES,
      payload: { companyId }
    });
  } catch (error) {
    yield put({ type: Types.FAILURE_ATTACH_COMPANY_FILE, error });
    yield put(errorMessage("Failed to link google drive file to company."));
  }
}

export default function* googleApiSaga() {
  yield all([
    initClient(),
    watchGoogleSignIn(),
    watchGoogleSignOut(),
    watchGetCompanyFilesRequest(),
    watchGetDriveDataRequest(),
    watchAttachFileToCompanyRequest()
  ]);
}
