import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";

const PrintPageHeader = ({ title, dateString }) => {
  return (
    <header className='print-company-monthly-report_header'>
      <div className='row print-company-monthly-report_title-container'>
        <div>
          <h1 className='print-company-monthly-report_title'>
            {title}
          </h1>
        </div>
      </div>
      <div className='row'>
        <div className='print-company-monthly-report_date-container'>
          {dateString}
        </div>
      </div>
    </header>
  );
};

PrintPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  dateString: PropTypes.string
};

export default Radium(PrintPageHeader);

