import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './MessageForm.css'
import { MdSend } from 'react-icons/lib/md'

class MessageForm extends Component {
  static propTypes = {
    onMessageSend: PropTypes.func.isRequired,
  }

  componentDidMount = () => {
    this.input.focus()
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
    if (this.input.value && this.input.value.trim().length > 0) {
      this.props.onMessageSend(this.input.value.trim())
    }
    this.input.value = ""
  }

  render() {
    return (
      <form className="MessageForm" onSubmit={this.handleFormSubmit}>
        <div className="input-container">
          <input
            type="text"
            ref={(node) => (this.input = node)}
            placeholder="Enter your message..."
            style={{fontSize: "16px"}}
          />
        </div>
        <div className="button-container">
          <button type="submit" style={{height: "100%"}}>
            <MdSend size="20px"/>
          </button>
        </div>
      </form>
    )
  }
}

export default MessageForm
