import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import * as R from 'ramda'
import { compose } from 'recompose'
import {
    withQuery,
    withTableHandlers,
    withApi,    
    withHeaderFooter
} from 'features/query'
import { selectors } from 'reducers'
import Table from 'components/ui/impl/table'
import { formatPhone } from "utils/misc";
import IoMdCheckmark from "react-icons/lib/io/checkmark";
import { aLink } from "utils/styles";

const formatDate = date => date ? moment(date).format('MM / DD / YYYY') : ''

const VMailPatientTable = compose(
    connect((state, { filters, queryId, sort, pagination }) => ({
        queryId: queryId,
        initialQuery: {
            sort: sort,
            pagination: pagination,
            filters: {                
                DOB: [filters.DOB],
                name: [filters.name],
                account_number: [filters.account_number],
                to_number: [filters.to_number],
                from_number: [filters.from_number]
            }
        },        
        apiId: queryId,        
    })),
    withQuery(),
    withApi({
        api: {
            route: 'patients/possiblepatients',
            responseKey: 'patients',
            idKey: 'id',
            entityKey: 'vmailPatientRows'
        }
    }),   
    connect((state, {       
        loadPatientID, filters
    }) => ({
      columns: R.filter(R.is(Object), [ /* eslint-disable react/prop-types */
            {
                header: 'Active',
                field: 'active',
                format: ({ active }) => active && (
                    <i>
                      <IoMdCheckmark />
                    </i>
                ),
                filters: [
                    {
                        text: 'Active',
                        value: 'true'
                    },
                    {
                        text: 'Inactive',
                        value: 'false'
                    }
                ]
            },
            {
                header: 'Name',
                field: 'last_name',
                format: ({ first_name, last_name, id }) => (
                  <span style={aLink} onClick={() => loadPatientID(id)}>
                    {first_name} {last_name}
                  </span>
                )
            },
            {
                header: 'DOB',
                field: 'date_of_birth',
                format: ({ date_of_birth }) => formatDate(date_of_birth)
                
            },
            {
                header: 'Account #',
                field: 'account_number'
            },
            {
                header: 'Phone',
                field: 'phone',
                format: ({ phone }) => phone &&
                    <span className={formatPhone(phone) == formatPhone(filters.from_number) ? "thereIsAPhoneMatchClass" : ""}
                    title={formatPhone(phone) == formatPhone(filters.from_number) ? "Phone Number Match!" : "" }
                >
                    {formatPhone(phone)}
                </span>
            },        
            {
                header: 'Mobile',
                field: 'cell',
                format: ({ cell }) => cell &&
                    <span className={formatPhone(cell) == formatPhone(filters.from_number) ? "thereIsAPhoneMatchClass" : ""}
                    title={formatPhone(cell) == formatPhone(filters.from_number) ? "Phone Number Match!" : ""}
                    >
                    {formatPhone(cell)}
                    </span>
            }, 
            {
                header: 'Insurance',
                field: 'insurance_company',
                sort: 'insurance_company'
            },
            selectors.getCanViewSleepCoachForCurrentUser(state) && {
                header: 'Sleep Coach',
                field: 'sleep_coach',
                filters: selectors.getFormOptionsSleepCoachWithUserFirst(state)
            },
            {
                header: 'Company',
                field: 'company',
                filters: selectors.getFormOptionsCompany(state)
            },            
            {
                title: 'Sort by eligibility date',
                header: 'Eligibility Date',
                format: ({ dateEligibleForAutomation }) => formatDate(dateEligibleForAutomation),
                field: 'dateEligibleForAutomation'
            }
        ])})),   
    withHeaderFooter({
    perPageOptions: [5, 10, 15, 20]
  }),
    withTableHandlers()
)(Table)

VMailPatientTable.propTypes = {
    filters: PropTypes.object,
    patientStatus: PropTypes.string,
    loadPatientID: PropTypes.func
}

export default VMailPatientTable
