import { combineReducers } from 'redux'
import { createReducer } from 'utils/reducer'
import { Types } from 'actions/order-profile'

const noteIds = createReducer({}, {
  [Types.RESPONSE_NOTES]: (state, {
    payload: { notes },
    meta: { orderId }
  }) => ({
    ...state,
    [orderId]: notes.map(({ id }) => id)
  })
})

const orderNotes = combineReducers({
  noteIds
})

export default orderNotes

const getOrderNoteIds = (state, { orderId }) => state.noteIds[orderId]

export const selectors = {
  getOrderNoteIds
}
