import React from "react";
import PropTypes from "prop-types";
import { autoMessages } from "./auto-messages";
import ReactTooltip from 'react-tooltip';

const AutoMsgList = ({ onMessageSelect }) => {
  return (
    <div className="auto-msg-container">
      <ReactTooltip />
      {autoMessages.map((messageGroup) => (
        <div
          key={messageGroup.category}
          className="auto-msg-category-container"
        >
          <div className="auto-msg-category">{messageGroup.category}</div>
          {messageGroup.messages.map((message) => (
            <div
              key={message.value}
              className="auto-msg-button"
              data-tip={message.value}
              onClick={() => onMessageSelect(message.value)}
            >
              {message.text}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AutoMsgList;

AutoMsgList.propTypes = {
  onMessageSelect: PropTypes.func.isRequired,
};
