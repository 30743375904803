import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { browserHistory } from "browser-history";
import { connect } from "react-redux";
import { InlineWaiting } from "components/ui";
import { Map} from "immutable";
import  VMailProfile  from "../vmail-profile/vmail-profile";
import  VMailPatientTable   from "../vmail-profile/vmail-profile-patient-table";
import PatientProfile from "../patient/patient-profile";
import TextInput from "components/forms/text-input";
import {queryInit, updateQuery, resetQueryApiAndParams} from "../../../features/query/actions"
import { getRouteParam } from "routes";

class VMailWorkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientChosenId: "",
      name: "",
      DOB: "",
      account_number: "",
      from_number_search: "",
      readyToLoadTable: false,
      queryId: "voicemailPatients",
      vmailId: "",
      pagination: {
        per: 5,
        page: 1
      },
      sort: {
        by: 'ID',
        direction: 'asc'
      },
      filters: {
        to_number: "",
        from_number: "",
        name: "",
        DOB: "",
        account_number: "",
        
      }
    };
    this.loadNewVM = this.loadNewVM.bind(this);
    this.loadPatientID = this.loadPatientID.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmitSearch = this.onSubmitSearch.bind(this);        
  }
 
  componentDidMount() {      
    //This will cause the page to reroute to vmails table if a user refreshed the browser, 
    //this component is reliant on the redux store populated at load of vmail table
    const { vmailTable, id} = this.props;
    if (!vmailTable || vmailTable.size < 1) {
      browserHistory.push({
        pathname: `/phones/voicemails`
      });
    }
    else {    
        
      const vmail = vmailTable.get(id);

      let filters = {
        to_number: vmail.get("to_number"),
        from_number: vmail.get("from_number"),
        name: this.state.name,
        DOB: this.state.DOB,
        account_number: this.state.account_number,
        
      }
      //I set readyToLoadTable default false so that the table wouldn't try to load 
      //until I had the coree ct intial query params ready from above
      this.setState({
        ...this.state,
        filters: filters,
        vmailId: this.props.id,
        readyToLoadTable: true
      })
    }    
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.vmailTable.size < 1) {
      browserHistory.push({
        pathname: `/phones/voicemails`
      })
    }
      else {
        return true
      }
  }

  // componentWillUnmount() {
    // const { queryId } = this.state;
    //
    // const { resetQueryApiAndParams } = this.props;
    // //This allows the patient table to be reset, and thus make a new call
    // resetQueryApiAndParams(queryId)
  // }

  loadNewVM(vmailId) {
    //This comes from the VMailProfile function 
    //which will load new vmails using the left and right arrows to move through the vmails table
    const { updateQuery, vmailTable } = this.props;
    const { queryId } = this.state;    
    const vmail = vmailTable.get(vmailId);  //Redux state has indexes of GUIDs 
    
    this.setState({      
      ...this.state,
      patientChosenId: "",
      name: "",
      DOB: "",
      account_number: "",   
      pagination: {
        per: 5,
        page: 1
      },
      sort: {
        by: 'ID',
        direction: 'asc'
      },
      filters: {
        to_number: vmail.get("to_number"),
        from_number: vmail.get("from_number"),
        name: "",
        DOB: "",
        account_number: "",
        
      },      
      vmailId: vmailId
    })

    //This will go to the action to allow for a trigger of the api
    const newQuery = {
      pagination: {
        per: 5,
        page: 1
      },
      sort: {
        by: 'ID',
        direction: 'asc'
      },
      filters: {
        to_number: [vmail.get("to_number")],
        from_number: [vmail.get("from_number")],
        name: [""],
        DOB: [""],
        account_number: [""],
        
      }
    }

    //Maybe rename this later, it obviously doesn't just update filters here
    updateQuery(queryId, newQuery)
  
  }
  
  loadPatientID(id) {
  //This will load a new profile onto the screen 
  //based upon which patient was lcicked in the table
    this.setState({
      ...this.state,
      patientChosenId: id
    })
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  onSubmitSearch() {
    //This will add filters to the table
    //to allow a new api call to be made within the table component
    const { query, updateQuery } = this.props;
    const {queryId } = this.state;
    let filters = {
      to_number: [this.state.filters.to_number],      
      from_number: [this.state.from_number_search == "" ? this.state.filters.from_number : this.state.from_number_search],        
      name: [this.state.name],
      DOB: [this.state.DOB],
      account_number: [this.state.account_number],
      
    }
    const vmailPatientsQuery = query.queryById[queryId];
    const newQuery = {
      ...vmailPatientsQuery,
      filters: filters
    }
    updateQuery(queryId, newQuery);
  }
  
  render() {
    const { user, ready, id, tableRecords, vmailTable, query } = this.props;
    const { readyToLoadTable, queryId, vmailId } = this.state;
    const patientId = this.state.patientChosenId;
    const tableHasRecords = Map.isMap(tableRecords)
      ? (tableRecords.toArray().length > 0)
      : false;
    const tableRecordsLength = query.apiById[queryId] ? query.apiById[queryId].count : 0;
    const isVmailPatientsFetchingData = query.apiById[queryId] && query.apiById[queryId].fetching;   
    const vmail = vmailTable && vmailTable.get(vmailId);    

    return (
      <div>
        {vmail && <div className="vmailProfile">
          <VMailProfile
            id={id}
            vmail={vmail}
            ready={ready}
            user={user}
            loadNewVM={this.loadNewVM}
          />
        </div>}

        {/* Patient Table here pre-populated with patient suggesiton 
        based upon from number */}

        <div className="vmailPatientTable">
          
          <div className="vmailPatientTableHeader">
            <h2>Search For Your Patient</h2>
            <h3>The table will provide potential matches</h3>
          </div> 
          
          <div className="vmailPatientTableSearch">
            <TextInput
              name="name"
              value={this.state.name}
              placeholder="Patient's Name"
              onChange={(e) => this.handleChange(e)}
            />
            <TextInput
              name="DOB"
              value={this.state.DOB}
              placeholder={"Date Of Birth - MM/DD/YYYY"}
              //showOutsideDays             
              onChange={(e) => this.handleChange(e)}
            />
            <TextInput
              name="account_number"
              value={this.state.account_number}
              placeholder="Account Number (optional)"
              onChange={(e) => this.handleChange(e)}
            />
            <TextInput
              name="from_number_search"
              value={this.state.from_number_search}
              placeholder="Phone Number (optional)"
              onChange={(e) => this.handleChange(e)}
            />
            <button
              title="Click Here To Refine Patient Search"
              className="vmailPatientSearchButton"
              onClick={() => this.onSubmitSearch()}
            >Search
            </button>            
          </div>
           
          {readyToLoadTable &&
            <span style={{ visibility: (!isVmailPatientsFetchingData && tableHasRecords && (tableRecordsLength > 0)) ? "visible" : "hidden"}}>
            <VMailPatientTable
              sort={this.state.sort}
              pagination={this.state.pagination}
              filters={this.state.filters}
              loadPatientID={this.loadPatientID}
              queryId={queryId}
            />
            </span>           
          }
          {isVmailPatientsFetchingData &&             
            <div className="vmailWorkPageWaiting">
              <InlineWaiting /> Checking For Best Guess At Who Called
            </div>
        }

        </div>
        {/* Add Patient Profile here based upon which patient was selected within the patient table above */}
        {(patientId == "")
          ? readyToLoadTable && tableHasRecords && (tableRecordsLength > 0) && !isVmailPatientsFetchingData && <h1 className="vmailPatientProfileDefaultHeader">Select From The Table The Patient Which Matches The Voicemail</h1>
          : < div className="vmailPatientProfile">            
              <PatientProfile patientId={patientId} />
            </div>
        }   
      </div>
    )
  }
}

export default
  withRouter(connect(
    (state, props) => ({     
    id: getRouteParam(props, "id"),
    user: state.getIn(["user"]),   
    ready: state.getIn(["vmails", "profileReady"]),
    phoneOpen: state.getIn(["phone", "isOpen"]),
    tableRecords: state.getIn(["entities", "vmailPatientRows"]), //This gets us the patient rows
    query: state.getIn(["query"]),
    vmailTable: state.getIn(["entities", "voicemailRows"])    //This gets us the voicemail rows
  }),
  {
    queryInit,
    updateQuery,
    resetQueryApiAndParams
  },
  )(VMailWorkPage));

VMailWorkPage.propTypes = {
  id: PropTypes.string.isRequired,
  resetQueryApiAndParams: PropTypes.func,  
  query: PropTypes.object,
  queryInit: PropTypes.func,
  updateQuery: PropTypes.func,
  ready: PropTypes.bool,
  vmail: PropTypes.object,
  user: PropTypes.object,
  phoneOpen: PropTypes.bool,
  tableHasRecords: PropTypes.object,
  vmailTable: PropTypes.object,
  params: PropTypes.object,
  tableRecords: PropTypes.object
};
