import React from "react";
import PropTypes from "prop-types";

const ResizePanel = ({ isMinimized, setContainerHeight }) => {
  const handleDragEnd = e => {
    e.persist();
    setContainerHeight(() => (e.clientY - 100 > 0 ? e.clientY - 100 : 0));
  };
  const handleDragStart = e => {
    e.persist();
    e.dataTransfer.setDragImage(new Image(), 0, 0);
  };
  return (
    <div
      draggable={!isMinimized}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      style={{
        cursor: isMinimized ? "default" : "ns-resize",
        zIndex: 100,
        height: "5px",
        position: "relative",
        bottom: -5
      }}
    />
  );
};

ResizePanel.propTypes = {
  isMinimized: PropTypes.bool,
  setContainerHeight: PropTypes.func
};

export default ResizePanel;
