import { createReducer } from "utils/reducer";
// import { createSelector } from "reselect";

const scDashboardSelected = createReducer(
  { patients: [], orders: [] },
  {
    TOGGLE_ITEM: (state, { patientId, orderId }) => {
      // could have paitent with more than one order
      const isInList = state.orders.includes(orderId);
      if (!isInList)
        return {
          patients: [...state.patients, patientId],
          orders: [...state.orders, orderId]
        };
      else
        return {
          patients: state.patients.filter(id => id !== patientId),
          orders: state.orders.filter(id => id !== orderId)
        };
    },
    CLEAR_SELECTED: () => ({
      patients: [],
      orders: []
    }),
    SELECT_ALL: (state, { orders, patients }) => ({ orders, patients })
  }
);

export default scDashboardSelected;

export const selectors = {};
