import { useEffect } from "react";

function useKeyboardEvent(key, callback) {
  function handler(event) {
    if (event.key === key) {
      callback();
    }
  }
  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [callback, key]);
}

export default useKeyboardEvent;
