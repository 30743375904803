import React from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { get } from "utils/api";
import { path } from "ramda";
import { Link } from "react-router-dom";
import { formatDate } from "utils/misc";

const TrackingInfoTab = ({ patientId }) => {
  const { response, isFetching, fetchData } = useFetch({
    apiFn: patientId => get(`patients/${patientId}/tracking-info`),
    defaultValue: [],
    // transformResponse: prop("cmn_notes"),
    transformError: path(["response", "body", "status"])
  });
  React.useEffect(() => {
    fetchData(patientId);
  }, [patientId]);
  const { rows, query, queryDispatcher } = useLocalQuery(response, {
    sort: {},
    filters: {}
  });
  return (
    <div>
      <Table
        query={query}
        containerClass="query-table patient-tab-table dynamic-columns"
        queryDispatcher={queryDispatcher}
        rows={rows}
        isFetching={isFetching}
        rowKey="tracking_number"
        columns={[
          {
            field: "tracking_number",
            header: "Tracking Number",
            style: { minWidth: 150 }
          },
          {
            field: "order_invoice_number",
            header: "Invoice Number",
            style: { minWidth: 150 }
          },
          {
            field: "carrier",
            header: "Carrier"
          },
          {
            field: "ship_date",
            header: "Ship Date"
          }
        ]}
        TableRow={({
          tracking_number,
          order_id,
          invoice_number,
          carrier,
          ship_date
        }) => (
          <tr key={tracking_number} className="tracking-info">
            <td>{tracking_number}</td>
            <td>
              <Link to={`/orders/${order_id}`}>{invoice_number}</Link>
            </td>
            <td>{carrier}</td>
            <td>{formatDate(ship_date)}</td>
          </tr>
        )}
      />
    </div>
  );
};

TrackingInfoTab.propTypes = {
  patientId: PropTypes.string.isRequired
};

export default TrackingInfoTab;