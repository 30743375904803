import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";

const AddTaskNoteForm = ({ patientId, createTaskNote, task }) => {
  return (
    <Formik
      onSubmit={async ({ note }, { resetForm }) => {
        await createTaskNote({ patientId, task_id: task.task_id, note });
        resetForm();
      }}
      initialValues={{ note: "" }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              gridGap: 10,
              flex: 1,
              alignItems: "center"
            }}
          >
            <FormikField name="note" type="text" style={{ margin: 0 }} />
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <button
                style={{ marginBottom: 10, padding: 7 }}
                type="submit"
                disabled={!values.note}
              >
                Add Comment
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddTaskNoteForm.defaultProps = {};

AddTaskNoteForm.propTypes = {
  patientId: PropTypes.string.isRequired,
  createTaskNote: PropTypes.func.isRequired,
  task: PropTypes.object
};

export default AddTaskNoteForm;
