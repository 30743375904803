import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import SearchableDropdown from "../../../searchable-dropdown/index";
import ToggleSwitch from "components/ui/toggle-switch";
import { Spinner } from "components/ui";
import {
  setAutoDialerFilters,
  enableOutreachAutoDialer
} from "actions/auto-dialer";
import MdCall from "react-icons/lib/md/phone";

const AutodialerFilter = ({
  teams,
  companies,
  user,
  formOfContactOptions,
  autoDialerFilters,
  setAutoDialerFilters,
  enableOutreachAutoDialer,
  autoDialerEnabled,
  isFetchingByButton
}) => {
  useEffect(() => {
    console.log(user);
    setAutoDialerFilters("team", user.team_id, 0);
  }, []);

  const filteredFormOfContactOptions = formOfContactOptions.filter(
    x =>
      x.text !== "Post Card" &&
      x.text !== "Do Not Contact" &&
      x.text !== "Bad Phone Number"
  );
  if (autoDialerEnabled) {
    return (
      <h1
        style={{
          textAlign: "center",
          color: "red",
          fontWeight: "700",
          margin: "200"
        }}
      >
        You must stop your autodialer before applying new filters
      </h1>
    );
  }
  return (
    <>
      {teams && companies && autoDialerFilters ? (
        <div className="autodialer-filters-container">
          <h1 className="autodialer-filters-title">Autodialer Filters</h1>
          <div className="autodialer-filters-row">
            <div className="autodialer-filters-row-group">
              <div className="autodialer-filters-row-top">
                <label className="autodialer-filters-input-top-label">
                  Teams
                </label>
                <SearchableDropdown
                  options={[
                    { text: "", value: "", key: "" },
                    ...teams.map(({ key, text }) => ({ value: key, text, key }))
                  ]}
                  selected={autoDialerFilters[0].team}
                  onSelect={value => {
                    setAutoDialerFilters("team", value, 0);
                  }}
                />
              </div>
              <div className="autodialer-filters-row-bottom">
                <label className="autodialer-filters-input-side-label">
                  Medicare
                </label>
                <div className="autodialer-filters-toggle-right">
                  <ToggleSwitch
                    value={autoDialerFilters[0].medicare}
                    onChange={async value => {
                      setAutoDialerFilters("medicare", value, 0);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="autodialer-filters-row-group">
              <div className="autodialer-filters-row-top">
                <label className="autodialer-filters-input-top-label">
                  Companies
                </label>
                <SearchableDropdown
                  options={[{ text: "", value: "", key: "" }, ...companies]}
                  selected={autoDialerFilters[0].company}
                  onSelect={value => {
                    setAutoDialerFilters("company", value, 0);
                  }}
                />
              </div>
              <div className="autodialer-filters-row-bottom">
                <label className="autodialer-filters-input-side-label">
                  Override Date
                </label>
                <div className="autodialer-filters-toggle-right">
                  <ToggleSwitch
                    value={autoDialerFilters[0].overrideEligibilityDate}
                    onChange={async value => {
                      setAutoDialerFilters("overrideEligibilityDate", value, 0);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="autodialer-filters-row-group">
              <div className="autodialer-filters-row-top">
                <label className="autodialer-filters-input-top-label">
                  Cycle Count
                </label>
                <SearchableDropdown
                  options={[
                    { text: "Any", value: null, key: "" },
                    { text: "1", value: "1", key: "" },
                    { text: "2", value: "2", key: "" }
                  ]}
                  selected={autoDialerFilters[0].cycleCount}
                  onSelect={value => {
                    setAutoDialerFilters("cycleCount", value, 0);
                  }}
                />
              </div>
              <div className="autodialer-filters-row-bottom">
                <label className="autodialer-filters-input-side-label">
                  New Setup
                </label>
                <div className="autodialer-filters-toggle-right">
                  <ToggleSwitch
                    value={autoDialerFilters[0].newSetup}
                    onChange={async value => {
                      setAutoDialerFilters("newSetup", value, 0);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="autodialer-filters-row-group">
              <div className="autodialer-filters-row-top">
                <label className="autodialer-filters-input-top-label">
                  Batch Size
                </label>
                <SearchableDropdown
                  options={[
                    { text: "20", value: "20", key: "" },
                    { text: "30", value: "30", key: "" },
                    { text: "40", value: "40", key: "" }
                  ]}
                  selected={autoDialerFilters[0].batchSize}
                  onSelect={value => {
                    setAutoDialerFilters("batchSize", value, 0);
                  }}
                />
              </div>
              <div className="autodialer-filters-row-bottom">
                <label className="autodialer-filters-input-side-label">
                  Bad Contact
                </label>
                <div className="autodialer-filters-toggle-right">
                  <ToggleSwitch
                    value={autoDialerFilters[0].badContact}
                    onChange={async value => {
                      setAutoDialerFilters("badContact", value, 0);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="autodialer-filters-row-group">
              <div className="autodialer-filters-row-top">
                <label className="autodialer-filters-input-top-label">
                  Form of Contact
                </label>
                <SearchableDropdown
                  options={[
                    { text: "", value: "", key: "" },
                    ...filteredFormOfContactOptions
                  ]}
                  selected={autoDialerFilters[0].formOfContact}
                  onSelect={value => {
                    setAutoDialerFilters("formOfContact", value, 0);
                  }}
                />
              </div>
              <div className="autodialer-filters-row-bottom">
                <label className="autodialer-filters-input-side-label">
                  Spanish
                </label>
                <div className="autodialer-filters-toggle-right">
                  <ToggleSwitch
                    value={autoDialerFilters[0].spanish}
                    onChange={async value => {
                      setAutoDialerFilters("spanish", value, 0);
                    }}
                  />
                </div>
              </div>
            </div>

            {!user.contracted_employee && (
              <div className="autodialer-filters-row-bottom">
                <label className="autodialer-filters-input-side-label">
                  Offshore Limited
                </label>
                <div className="autodialer-filters-toggle-right">
                  <ToggleSwitch
                    value={autoDialerFilters[0].offshoreLimited}
                    onChange={async value => {
                      setAutoDialerFilters("offshoreLimited", value, 0);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {autoDialerFilters[0].team && autoDialerFilters[0].company ? (
            <button
              className={
                isFetchingByButton
                  ? "autodialer-filters-start-fetching"
                  : "autodialer-filters-start"
              }
              title={
                isFetchingByButton
                  ? "Fetching now"
                  : "Start Autodialer with filters applied"
              }
              onClick={() => {
                if (!isFetchingByButton) {
                  enableOutreachAutoDialer();
                }
              }}
            >
              <MdCall />
            </button>
          ) : (
            <h1
              style={{ textAlign: "center", color: "red", fontWeight: "700" }}
            >
              You must select a team and a company to start
            </h1>
          )}
        </div>
      ) : (
        <div style={{ textAlign: "center", margin: "50px" }}>
          <Spinner size="45px" />
        </div>
      )}
    </>
  );
};

AutodialerFilter.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.PropTypes.object.isRequired,
  formOfContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAutoDialerFilters: PropTypes.func.isRequired,
  enableOutreachAutoDialer: PropTypes.func.isRequired,
  isFetchingByButton: PropTypes.bool.isRequired,
  autoDialerEnabled: PropTypes.bool.isRequired,
  autoDialerFilters: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(
  state => ({
    teams: selectors.getTeamNameOptions(state),
    companies: selectors.getFormOptionsCompany(state),
    formOfContactOptions: selectors.getFormOfContactOptions(state),
    autoDialerFilters: selectors.getAutoDialerFilters(state),
    autoDialerEnabled: selectors.getIsAutoDialerEnabled(state),
    isFetchingByButton: selectors.getAutoDialerIsFetchingBatchByButton(state),
    user: selectors.getUser(state)
  }),
  {
    setAutoDialerFilters,
    enableOutreachAutoDialer
  }
)(AutodialerFilter);
