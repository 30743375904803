import React from "react";
import PropTypes from "prop-types";
import { graphColors } from "utils/styles";
import { Pie, Tooltip, PieChart, Legend, ResponsiveContainer } from "recharts";
import { path } from "ramda";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from 'react-tooltip';
import { formatPercantage } from "utils/misc";

const CallDirectionChart = ({ phoneContacts, onExport }) => (
  <div className="graph-container">
    <h4 className="graph-header">Call Direction</h4>
    <ReactTooltip />
    {onExport && (<div className="stat-export-icon" data-tip="Export" onClick={onExport}><FaFileExcelO /></div>)}
    <ResponsiveContainer height={200}>
      <PieChart>
        <Pie
          data={[
            {
              name: "Outgoing IVR",
              value: path(["outgoing_call_ivr", "successes"], phoneContacts),
              fill: graphColors[1]
            },
            {
              name: "Incoming IVR",
              value: path(["incoming_call_ivr", "successes"], phoneContacts),
              fill: graphColors[2]
            },
            {
              name: "Outgoing Sleep Coach",
              value: path(["outgoing_call_sleep_coach", "successes"], phoneContacts),
              fill: graphColors[3]
            },
            {
              name: "Incoming  Sleep Coach",
              value: path(["incoming_call_sleep_coach", "successes"], phoneContacts),
              fill: graphColors[4]
            }
          ]}
          dataKey="value"
          nameKey="name"
          isAnimationActive={false}
        />
        <Legend />
        <Tooltip
          formatter={value =>
            `${value} - ${formatPercantage(value, phoneContacts.success_total)}`
          }
        />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default CallDirectionChart;

CallDirectionChart.propTypes = {
  phoneContacts: PropTypes.shape({
    outgoing_call_ivr: PropTypes.object,
    incoming_call_ivr: PropTypes.object,
    outgoing_call_sleep_coach: PropTypes.object,
    incoming_call_sleep_coach: PropTypes.object,
    outgoing_call: PropTypes.object,
    incoming_call: PropTypes.object,
    success_total: PropTypes.number
  }),
  onExport: PropTypes.func
};
