import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { InlineWaiting } from "components/ui";
import FormikField from "components/forms/formik-field";
import InventoryPhotoEditor from "./inventory-photo-editor";
import { useDispatch } from "react-redux";
import { uploadProductVideo } from "actions/product-media";

const MediaUploadForm = ({ closeModal, inventoryItem }) => {
  const [mediaType, setMediaType] = React.useState("Video");
  const dispatch = useDispatch();
  return (
    <div style={{ padding: 10 }}>
      <h1>Inventory Item - Media Upload</h1>
      <div style={{ display: "flex" }}>
        <label style={{ display: "flex", alignItems: "center", padding: 5 }}>
          <span style={{ paddingRight: 5 }} className="radio-group-option">
            Video
          </span>
          <input
            value="Video"
            type="radio"
            className="input-field"
            checked={mediaType === "Video"}
            onChange={() => {
              setMediaType("Video");
            }}
            style={{ margin: 0 }}
          />
        </label>
        <label style={{ display: "flex", alignItems: "center", padding: 5 }}>
          <span style={{ paddingRight: 5 }} className="radio-group-option">
            Photo
          </span>
          <input
            value="Photo"
            type="radio"
            className="input-field"
            checked={mediaType === "Photo"}
            onChange={() => {
              setMediaType("Photo");
            }}
            style={{ margin: 0 }}
          />
        </label>
      </div>
      {mediaType == "Video" && (
        <ProductVideoForm
          handleSubmit={async ({ link, title, active }) => {
            await dispatch(
              uploadProductVideo(inventoryItem.id, { link, title, active })
            );
            closeModal();
          }}
          handleCancel={closeModal}
        />
      )}
      {mediaType == "Photo" && (
        <InventoryPhotoEditor
          inventoryItem={inventoryItem}
          onFinished={() => closeModal()}
        />
      )}
    </div>
  );
};

MediaUploadForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  inventoryItem: PropTypes.object.isRequired
};

export default MediaUploadForm;

const ProductVideoForm = ({ handleSubmit, handleCancel }) => {
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ title: "", link: "", active: true }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormikField label="Title" name="title" type="text" />
          <FormikField label="Link" name="link" type="text" required />
          <FormikField
            label="Active"
            name="active"
            type="checkbox"
            checked={values.active}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              padding: 10,
              justifyContent: "space-between"
            }}
          >
            <button onClick={handleCancel} type="button" className="warning">
              Cancel
            </button>
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <button type="submit">Save</button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

ProductVideoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};
