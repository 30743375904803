import React, { useState } from "react";
import BulkFirstTimeRegistrationAssignModal from "./bulk-first-time-registration-assign-modal";
import { Spinner } from "components/ui";

const BulkEditRecords = ({
  closeModal,
  allowWork,
  refreshData,
  patientIds,
  clearSelectedRows
}) => {
  const [value, setValue] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const triggerCorrespondingModal = () => {
    if (value == "bulk-assign") {
      setSubmitEnabled(false);
    } else {
      closeModal();
      switch (value) {
        case "bulk-reset": {
          document.getElementById("reset-records-modal").click();
          break;
        }
        case "bulk-work": {
          document.getElementById("work-records-modal").click();
          break;
        }
      }
    }
  };

  return (
    <>
      <div style={{ padding: "1em" }}>
        <h4>Bulk Update Options</h4>
        <select value={value} onChange={e => setValue(e.target.value)}>
          <option value="">Select Option</option>
          <option key={"1"} value={"bulk-assign"}>
            Bulk Assign
          </option>
          <option key={"2"} value={"bulk-reset"}>
            Bulk Reset
          </option>
          {allowWork && (
            <option key={"3"} value={"bulk-work"}>
              Bulk Work
            </option>
          )}
        </select>
      </div>

      {value == "bulk-assign" && (
        <BulkFirstTimeRegistrationAssignModal
          patientIds={patientIds}
          onUpdateError={() => {
            clearSelectedRows();
          }}
          onUpdateSuccess={() => {
            closeModal();
            clearSelectedRows();
            refreshData();
          }}
          setSubmitEnabled={val => {
            setSubmitEnabled(val);
          }}
        />
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={() => {
            closeModal();
          }}
          className="warning select-coach-for-appointment-button"
          style={{ textAlign: "center", display: "inline-block" }}
        >
          Cancel
        </button>
        {!submitting ? (
          <button
            onClick={() => {
              if (value == "bulk-assign") {
                document.getElementById("submit-assign").click();
                setSubmitting(true);
              } else triggerCorrespondingModal();
            }}
            disabled={value == "" || (value == "bulk-assign" && submitEnabled)}
            className="select-coach-for-appointment-button"
            style={{
              marginLeft: "15px",
              textAlign: "center",
              display: "inline-block"
            }}
          >
            Submit
          </button>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default BulkEditRecords;
