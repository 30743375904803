import React from "react";
import PropTypes from "prop-types";
import InlineWaiting from "components/ui/inline-waiting";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import "./style.scss";

const validateNumber = value => {
  let errorMessage;

  if (value < 0)
      errorMessage = 'the field must be greater than zero';
  return errorMessage;
}
const AddEquipmentByIdForm = ({ handleSubmit, isSubmitting, withQuantity, disabled }) => (
  
  <Formik
    initialValues={{
      product_id: "",
      addQuantity: ""
    }}
    validate={values => {
      let errors = {};
      if (!values.product_id) {
        errors.product_id = "Required";
      }
      if (withQuantity && !values.addQuantity) {
        errors.addQuantity = "Required";
      }
      return errors;
    }}
    onSubmit={(values, { resetForm }) => {
      if (values.addQuantity > 9) {
        if (
          window.confirm(
            `Are you sure you want to set quantity to ${values.addQuantity} for this item?`
          )
        ) {
          handleSubmit(values);
          resetForm();
        }
      } else {
        handleSubmit(values);
        resetForm();
      }
    }}
  >
    {({ isValid }) => (
      <Form>
        <div className="add-equipmentById-form">
          <FormikField name="product_id" label="Product Id*" type="text" />
          {withQuantity && (
            <FormikField name="addQuantity" label="Quantity" type="number" validate={validateNumber} />
          )}
          {isSubmitting ? (
            <InlineWaiting />
          ) : (
            <button className="submit-button" type="submit" disabled={disabled || !isValid}>
              Add Item By Id
            </button>
          )}
        </div>
      </Form>
    )}
  </Formik>
);

export default AddEquipmentByIdForm;

AddEquipmentByIdForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  withQuantity: PropTypes.bool,
  disabled: PropTypes.bool,
};

AddEquipmentByIdForm.defaultProps = {
  disabled: false,
}
