import React from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";
import FormikField from "components/forms/formik-field";

const NoMaskReasonInput = ({ requested_equipment, noMaskReasonOptions }) => {
  return !requested_equipment.includes("mask") ? (
    <div
      style={{
        backgroundColor: "#eee",
        margin: "1rem -20px -1rem",
        padding: "5px 15px"
      }}
    >
      <FormikField
        name="reason_for_no_mask"
        component="select"
        label="Reason for no mask"
        validate={value => (value ? undefined : "Required.")}
      >
        <option />
        {noMaskReasonOptions.map(({ text, value }) => (
          <option key={value} value={text}>
            {text}
          </option>
        ))}
      </FormikField>
    </div>
  ) : null;
};

NoMaskReasonInput.propTypes = {
  noMaskReasonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ),
  requested_equipment: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default connect(state => ({
  noMaskReasonOptions: selectors.getNoMaskReasonOptions(state)
}))(NoMaskReasonInput);
