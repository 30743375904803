import { errorMessage } from "actions/message";

const makeApiTypes = name => ({
  [`${name}`]: `${name}`,
  [`${name}_REQUEST`]: `${name}_REQUEST`,
  [`${name}_RESPONSE`]: `${name}_RESPONSE`,
  [`${name}_FAILURE`]: `${name}_FAILURE`
});

export const Types = {
  ...makeApiTypes("GET_COMPANY_CONTACT_LOGIC"),
  ...makeApiTypes("UPDATE_COMPANY_CONTACT_LOGIC_CYCLES"),
  ...makeApiTypes("UPDATE_COMPANY_CONTACT_LOGIC_CYCLE_ACTION_ENABLED"),
  COPY_LAST_COMPANY_CONTACT_LOGIC: "COPY_LAST_COMPANY_CONTACT_LOGIC",
  CLEAR_COMPANY_CONTACT_LOGIC: "CLEAR_COMPANY_CONTACT_LOGIC",
  CLEAR_ALL_COMPANY_CONTACT_LOGIC: "CLEAR_ALL_COMPANY_CONTACT_LOGIC"
};

export const getCompanyContactLogic = (companyId, index, patientTypeId) => ({
  type: Types.GET_COMPANY_CONTACT_LOGIC,
  payload: { companyId, index, patientTypeId }
});

export const updateContactLogicCycleActionEnabled = (
  cycleId,
  actionIds,
  enabled
) => ({
  type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLE_ACTION_ENABLED,
  payload: { cycleId, actionIds, enabled }
});

export const updateContactLogicCycles = (companyId, cycles) => ({
  type: Types.UPDATE_COMPANY_CONTACT_LOGIC_CYCLES,
  payload: { companyId, cycles }
});

export const copyLastCompanContactLogicToNextIndex = () => async dispatch => {
  try {
    dispatch({ type: Types.COPY_LAST_COMPANY_CONTACT_LOGIC });
  } catch (error) {
    dispatch(errorMessage("Something went wrong!"));
  }
};

export const clearContactLogicAtIndex = index => async dispatch => {
  try {
    dispatch({ type: Types.CLEAR_COMPANY_CONTACT_LOGIC, payload: { index } });
  } catch (error) {
    dispatch(errorMessage("Something went wrong!"));
  }
};

export const clearAllContactLogic = () => async dispatch => {
  try {
    dispatch({ type: Types.CLEAR_ALL_COMPANY_CONTACT_LOGIC });
  } catch (error) {
    dispatch(errorMessage("Something went wrong!"));
  }
};
