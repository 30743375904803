import { isDevelopment } from "environment";

function handleCheckUserEnvironement(user) {
  try {
    const { frontend_link, authentication_token } = user;
    const redirectUser = shouldRedirect(
      frontend_link,
      window.location.hostname
    );
    console.debug("Redirect Check", { frontend_link, redirectUser });
    if (redirectUser && !isDevelopment()) {
        
      console.debug(`Redirecting to ${frontend_link}`);
      window.location.replace(
        `${frontend_link}/sso?token=${authentication_token}`,
        true
      );
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

function shouldRedirect(frontend_link, hostname) {
  if (!frontend_link || frontend_link === "localhost") return false;
  if (frontend_link.includes(hostname)) return false;
  else return true;
}

export default handleCheckUserEnvironement;
