import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import {
  AddEquipmentForm,
  AddEquipmentByIdForm,
  PatientCompanyActiveEquipmentContext,
  usePatientCompanyActiveEquipment
} from "components/forms/add-equipment";
import MdInfo from "react-icons/lib/md/info";
import { colors } from "utils/styles";
import Header from "./header";
import { prop } from "ramda";

const PatientsEquipmentTooltip = ({ patientCurrentEquipment }) => (
  <span
    className="information-tooltip no-print"
    data-tooltip
    tabIndex="1"
    title={`Patients Current Equipment\n${patientCurrentEquipment.map(
      eq => `${eq.item_type} - ${eq.item_name}\n`
    )}`}
  >
    <MdInfo
      className="information-icon"
      size="24px"
      color={colors.primaryColor}
    />
  </span>
);
PatientsEquipmentTooltip.propTypes = {
  patientCurrentEquipment: PropTypes.arrayOf(
    PropTypes.shape({
      item_type: PropTypes.string,
      item_name: PropTypes.string
    })
  ).isRequired
};

const AddLineItem = ({
  isSubmitting,
  requestNewLineItem,
  requestNewLineItemById,
  orderLineItems,
  patientCurrentEquipment,
  patientId
}) => {
  const {
    active_products,
    isFetchingCompanyActiveEq
  } = usePatientCompanyActiveEquipment({ patientId });
  return (
    <div className="no-print">
      <Header showSpinner={isSubmitting}>
        Add New Line Item
        <PatientsEquipmentTooltip
          patientCurrentEquipment={patientCurrentEquipment}
        />
      </Header>
      {!!patientId && (
        <div className="add-order-equipment-container">
          <AddEquipmentByIdForm
            withQuantity
            isSubmitting={isSubmitting}
            handleSubmit={({ product_id, addQuantity }) =>
              requestNewLineItemById(product_id, addQuantity)
            }
          />
          <PatientCompanyActiveEquipmentContext.Provider
            value={{ active_products, isFetchingCompanyActiveEq }}
          >
            <AddEquipmentForm
              withQuantity
              handleSubmit={({ id, addQuantity }) =>
                requestNewLineItem(id, addQuantity)
              }
              isSubmitting={isSubmitting}
              current_equip={patientCurrentEquipment.map(
                ({ item_id }) => item_id
              )}
              initialFilter={{ compatibleEquipment: true }}
              line_items={orderLineItems}
              patientId={patientId}
            />
          </PatientCompanyActiveEquipmentContext.Provider>
        </div>
      )}
    </div>
  );
};

AddLineItem.propTypes = {
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
  patientCurrentEquipment: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  isFetchingInventory: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  requestNewLineItem: PropTypes.func.isRequired,
  requestNewLineItemById: PropTypes.func.isRequired,
  patientId: PropTypes.string
};

export default compose(
  connect((state, props) => {
    const {
      data: patientCurrentEquipment,
      isFetching
    } = selectors.getOrderPatientEquipmentInfo(state, props);
    const { data: patientInfo = {} } = selectors.getOrderPatientInfo(
      state,
      props
    );
    const { data: orderLineItems } = selectors.getOrderLineItems(state, props);
    return {
      patientId: prop("patient_id", patientInfo),
      isSubmitting: selectors.getIsSubmittingNewLineItem(state, props),
      isFetching: isFetching || selectors.isFetchingInventory(state),
      patientCurrentEquipment,
      orderLineItems
    };
  }, mapDispatchToProps)
)(AddLineItem);
