import React from "react";
import { InlineWaiting } from "components/ui";
import PropTypes from "prop-types";
import "./styles.scss";

/*
  input components for redux-form.
  avaliable components: select, text
  TODO: add radio, textarea inputs etc...
*/
const FormComponents = {
  input: props => <input {...props} />,
  select: ({ options = [], nullOption = false, ...props }) => (
    <select {...props}>
      {nullOption ? <option /> : null}
      {options.map(({ value, text }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </select>
  ),
  checkbox: props => <input checked={props.value} {...props} type="checkbox" />
};

FormComponents.select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ),
  nullOption: PropTypes.bool
};
FormComponents.checkbox.propTypes = { value: PropTypes.string };

FormComponents.checkbox.propTypes = {
  value: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

/* Redux-Form Field component allows using redux-form for validation instead of handling manually with state */
/* Example:
        <Field
          label="Product *"
          name="id"
          as="select"
          component={FormField}
          validate={[required]}
          disabled={productOptions.length === 1}
          options={productOptions}
        />
 */
const FormField = ({
  input,
  type,
  label,
  placeholder,
  labelStyle,
  meta: { asyncValidating, touched, error },
  as,
  ...props
}) => {
  const hasError = typeof error === "string" && touched;
  const As = typeof as === "string" ? FormComponents[as] : as;
  return (
    <span className="form-field">
      {label && (
        <label className="form-field-label" style={labelStyle}>
          {label}
        </label>
      )}
      {hasError && <span className="form-field-error">{error}</span>}
      <As
        {...props}
        {...input}
        type={type}
        placeholder={placeholder}
        className={`input-field ${hasError ? "has-error" : ""}`}
        title={hasError && !label ? error : ""}
      />
      {asyncValidating && <InlineWaiting />}
    </span>
  );
};

FormField.defaultProps = {
  as: "input",
  type: "text",
  meta: { touched: false, error: null, warning: "" },
  labelStyle: {}
};

FormField.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  labelStyle: PropTypes.object
};

export default FormField;
