import React from "react";
import PropTypes from "prop-types";
import { ExportButton } from "components/ui";

const ExportFulfillmentInventory = (
  { fulfillmentCompanies,
    isFetchingInventory,
    isExportingInventory,
    fulfillmentInventoryExport,
    selectedFulfillmentCompany
  }) => {
  const handleGetFulfillmentInventoryExport = () => {
    fulfillmentInventoryExport();
  };
  const fulfillmentCompanyName = fulfillmentCompanies?.find(x => x.value === selectedFulfillmentCompany)?.text;
  return (
    <div>
      {!isFetchingInventory && !isExportingInventory ? (
        <ExportButton onClick={handleGetFulfillmentInventoryExport} title={`Export ${fulfillmentCompanyName} Inventory`}/>
      ) : (
        ""
        )
      }
    </div>
)};

export default ExportFulfillmentInventory;

ExportFulfillmentInventory.propTypes = {
  isFetchingInventory: PropTypes.bool.isRequired,
  isExportingInventory: PropTypes.bool.isRequired,
  fulfillmentInventoryExport: PropTypes.func.isRequired,
  selectedFulfillmentCompany: PropTypes.string.isRequired,
  fulfillmentCompanies: PropTypes.arrayOf(PropTypes.object).isRequired,
};