import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

export const LoginHistoryModal = ({ data, total, coach }) => {
  const formatTime = time => {
    const hours = Math.floor(moment.duration(time).asHours());
    const minutes = Math.round(moment.duration(time).asMinutes()) % 60;
    return hours + "h" + minutes + "m";
  };

  return (
    <div className="historyContainer">
      <div className="modalTitle">{coach}</div>
      <div className="historyRow modalHeader">
        <div>Start</div> 
        <div>End</div>
        <div>Total</div>
      </div>
      {data.filter(d => Math.round(moment.duration(d.totalTime).asMinutes()) != 0 ).map((d, index) => {
        
        return (
          <div
            key={index}
            className={
              (index + 1) % 2 === 0 ? "historyRow" : "historyRow modalRow"
            }
          >
            <div>{moment(d.start).format("hh:mm")}</div>
            <div>
              {d.end == null ? "Current" : moment(d.end).format("hh:mm")}
            </div>
            <div>{formatTime(d.totalTime)}</div>
          </div>
        );
      })}
      <div className="historyRow borderTop">
        <div>Total:</div>
        <div>{formatTime(total)}</div>
      </div>
    </div>
  );
};

LoginHistoryModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  total: PropTypes.string,
  coach: PropTypes.string
};
