import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { callAPI } from "../redux-middleware/call-api";
import { initApi } from "utils/api";
import messageMiddleware from "../redux-middleware/message.js";
import broadcastChannelMiddleware from "../redux-middleware/broadcast-channel";

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      sagaMiddleware,
      messageMiddleware,
      thunk,
      callAPI,
      broadcastChannelMiddleware()
    )
  );

  initApi(store);
  sagaMiddleware.run(rootSaga);

  return store;
}
