import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";

const ResendPdfButton = ({
  resendPDFTemplate,
  isFetching,
  patient_id,
  template_guid
}) => {
  return isFetching ? (
    <InlineWaiting />
  ) : (
    <button
      style={{ margin: 0, padding: "5px 10px" }}
      className="secondary"
      onClick={async () => {
        await resendPDFTemplate({
          patients: [patient_id],
          signature_document_id: template_guid
        });
      }}
    >
      Re-Send
    </button>
  );
};

ResendPdfButton.propTypes = {
  resendPDFTemplate: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  patient_id: PropTypes.string.isRequired,
  template_guid: PropTypes.string.isRequired
};

export default ResendPdfButton;
