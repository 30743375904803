import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader, TabList } from "components/ui";
import { Switch, Route } from "react-router-dom";
import PdfDashboard from "./pdf-dashboard";
import MangePdfs from "./manage-pdfs";
import AssignPdfs from "./assign-pdfs";
import { getPDFTemplate } from "components/dd_newModules/Redux/Actions/pdftemplate";

const PdfSignatures = ({ getPDFTemplate }) => {
  React.useEffect(() => {
    getPDFTemplate();
  }, []);
  return (
    <div>
      <PageHeader title="PDF Signatures" />
      <div
        style={{
          display: "flex",
          padding: "10px 0px",
          alignItems: "center"
        }}
      >
        <TabList
          routes={[
            {
              exact: true,
              path: `/pdf-signatures`,
              label: "History"
            },
            {
              path: `/pdf-signatures/assign`,
              label: "Assign Patients"
            },
            {
              path: `/pdf-signatures/manage`,
              label: "Manage Templates"
            }
          ]}
        />
      </div>
      <Switch>
        <Route path="/pdf-signatures" component={PdfDashboard} exact />
        <Route path="/pdf-signatures/assign" component={AssignPdfs} />
        <Route path="/pdf-signatures/manage" component={MangePdfs} />
      </Switch>
    </div>
  );
};

export default connect(() => ({}), { getPDFTemplate })(PdfSignatures);

PdfSignatures.propTypes = {
  getPDFTemplate: PropTypes.func.isRequired
};
