import React from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import { getInitials } from "utils/misc";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { MdClose } from "react-icons/lib/md";
import { QueryTableContext } from "hooks/useQuery/api-query";

const ClaimOrder = ({ claimed_by, id, userId, userRole, canClaim }) => {
  const updateOrderClaimedByApi = useFetch({
    apiFn: ({ id, claimed_by_id }) =>
      put(`orders/${id}`, {
        claimed_by: claimed_by_id
      })
  });
  const { fetchData, query } = React.useContext(QueryTableContext);

  const isClaimed = claimed_by && claimed_by.value !== " ";
  const canClaimThis = canClaim && userId !== (claimed_by && claimed_by.key);
  const canUnclaim =
    userRole === "CompanyAdministrator" ||
    userRole === "Administrator" ||
    userRole === "ServiceAdmin";
  return (
    <span
      className={`${isClaimed && "claimed-by"} ${canClaimThis && "claim"}`}
      onClick={
        canClaimThis
          ? async () => {
              await updateOrderClaimedByApi.fetchData({
                id,
                claimed_by_id: userId
              });
              fetchData(query);
            }
          : undefined
      }
      title={`${isClaimed && canClaimThis ? "Claim Order From" : ""} ${
        isClaimed ? claimed_by.value : ""
      }`}
    >
      {isClaimed && canUnclaim && (
        <span
          style={{ paddingRight: 5, cursor: "pointer" }}
          title="Unclaim order"
          onClick={async e => {
            e.stopPropagation();
            await updateOrderClaimedByApi.fetchData({
              id,
              claimed_by_id: null
            });
            fetchData(query);
          }}
        >
          <MdClose color="red" />
        </span>
      )}
      {isClaimed ? getInitials(claimed_by.value) : canClaimThis ? "claim" : ""}
    </span>
  );
};

ClaimOrder.propTypes = {
  canClaim: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  claimed_by: PropTypes.object
};

export default connect(state => ({
  canClaim: selectors.getCanCurrentUserClaimOrder(state),
  userId: selectors.getUserId(state),
  userRole: selectors.getUserRole(state)
}))(ClaimOrder);
