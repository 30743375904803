import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { TableButton } from "components/ui";
import { setVmailBatchIds } from "actions/batch";
import { createTab } from "actions/system-tray";
import { IoRefresh } from "react-icons/lib/io";
import HasRolePermission from "components/shared/has-role-permission";
import { MdVoicemail } from "react-icons/lib/md";
import BulkUpdateVmails from "./bulk-update-voicemails";

const VoicemailTableControls = ({
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows
}) => {
  const dispatch = useDispatch();
  return (
    <div className="query-table-controls">
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoRefresh />
      </TableButton>
      <TableButton
        title={`Process (${selectedRows.length} selected Voicemails)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          dispatch(
            setVmailBatchIds(
              selectedRows.map(x => x.id),
              selectedRows.map(x => x.vmID)
            )
          );
          dispatch(
            createTab({
              type: "vmails",
              key: "vmails",
              label: `Voicemails (${selectedRows.length})`,
              meta: { ids: selectedRows.map(x => x.id) }
            })
          );
          clearSelectedRows();
        }}
      >
        <MdVoicemail size={24} />
      </TableButton>
      <HasRolePermission allowedRoles={["Administrator", "ServiceAdmin", "ContractorAdministrator"]}>
        <BulkUpdateVmails
          selectedRows={selectedRows}
          refreshData={refreshData}
        />
      </HasRolePermission>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} Voicemails</span>
        )}
      </div>
    </div>
  );
};

export default VoicemailTableControls;

VoicemailTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, patient_name: PropTypes.string })
  ).isRequired,
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired
};
