import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const WalkInOrdersTab = ({ walkInOrders }) => (
  <div>
    <table className="large-12">
      <thead>
        <tr>
          <td>Date Created</td>
          <td>User</td>
        </tr>
      </thead>
      <tbody>
        {walkInOrders.map((walkIn, i) => (
          <tr key={i}>
            <td>
              <span
                data-tooltip
                tabIndex={i}
                title={moment(walkIn.order_date).format(
                  "MMM DD, YYYY -  hh:mm A"
                )}
              >
                {moment(walkIn.order_date).format("LL")}
              </span>
            </td>
            <td>{walkIn.user_name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

WalkInOrdersTab.propTypes = {
  walkInOrders: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default WalkInOrdersTab;
