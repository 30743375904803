import React, { Component } from "react";
import Radium from "radium";

class Loader extends Component {
  render() {
    return (
      <div style={[styles.container]}>
        <img src="/assets/spinner.gif" style={styles.image} />
      </div>
    );
  }
}

export default Radium(Loader);

var styles = {
  container: {
    textAlign: "center"
  },
  image: {
    width: "32px",
    height: "32px"
  }
};
