import { makeFetchCreator } from "actions/fetch";
import { put } from "utils/api";
import uniqueId from "lodash/uniqueId";
import partition from "lodash/partition";
// import { errorMessage } from "actions/message";

export const Types = {
  AUTOMATE_PATIENT_CONTACT: "SC_AUTOMATE_PATIENT_CONTACT",
  RECEIVE_AUTOMATE_PATIENT_CONTACT: "RECIEVE_SC_AUTOMATE_PATIENT_CONTACT",
  // SC_SET_SLEEP_COACH: "SC_SET_SLEEP_COACH",
  // SC_SET_TEAM:"SC_SET_TEAM",
  // PATIENTS_TO_CONTACT_REQUEST: "SC_REQUEST_PATIENTS_TO_CONTACT",
  // PATIENTS_TO_CONTACT_RESPONSE: "SC_PATIENTS_TO_CONTACT_RESPONSE",
  // PATIENTS_TO_CONTACT_ERROR: "SC_PATIENTS_TO_CONTACT_ERROR",
  // PATIENTS_ORDER_FOLLOW_UP_REQUEST: "PATIENTS_ORDER_FOLLOW_UP_REQUEST",
  // PATIENTS_ORDER_FOLLOW_UP_RESPONSE: "PATIENTS_ORDER_FOLLOW_UP_RESPONSE",
  // PATIENTS_ORDER_FOLLOW_UP_ERROR: "PATIENTS_ORDER_FOLLOW_UP_ERROR",
  CLEAR_SELECTED: "CLEAR_SELECTED"
};

// export const fetchContactList = (sleepCoach, team) => async dispatch => {
//   try {
//     dispatch({ type: Types.PATIENTS_TO_CONTACT_REQUEST, meta: { sleepCoach } });
//     if (!sleepCoach) {
//       const response = await put(`patients/team_needs_contact?team_id=${team}`);
//       dispatch({ type: Types.PATIENTS_TO_CONTACT_RESPONSE, payload: response });
//     } else {
//       const response = await put(
//         `patients/needs_contact?sleep_coach=${sleepCoach}`
//       );
//       dispatch({ type: Types.PATIENTS_TO_CONTACT_RESPONSE, payload: response });
//     }
//   } catch (error) {
//     dispatch({ type: Types.PATIENTS_TO_CONTACT_ERROR, error });
//     dispatch(
//       errorMessage(
//         "Something went wrong! Failed to get list of eligible patients"
//       )
//     );
//   }
// };
//
//
// export const fetchCoachOrders = (
//   sleepCoach,
//   status = "follow_up"
// ) => async dispatch => {
//   try {
//     dispatch({
//       type: Types.PATIENTS_ORDER_FOLLOW_UP_REQUEST,
//       meta: { sleepCoach }
//     });
//     const response = await put("orders", {
//       search_for_array: [],
//       sort: { by: "submitted", direction: "desc" },
//       pagination: { page: 1, per: 150 },
//       filter_by: [
//         { by: "status", value: status },
//         { by: "sleep_coach", value: sleepCoach }
//       ]
//     });
//     dispatch({
//       type: Types.PATIENTS_ORDER_FOLLOW_UP_RESPONSE,
//       payload: response
//     });
//   } catch (error) {
//     dispatch({ type: Types.PATIENTS_ORDER_FOLLOW_UP_ERROR, error });
//     dispatch(
//       errorMessage(
//         "Something went wrong! Failed to get list of follow up orders"
//       )
//     );
//   }
// };

const contactPatient = makeFetchCreator({
  url: "automation",
  baseType: Types.AUTOMATE_PATIENT_CONTACT,
  method: put,
  mapActionToKeyIn: ({ requestID }) => [requestID],
  transform: ({ automation }) => {
    const [success, failure] = partition(
      automation,
      ({ status }) => status === "success"
    ).map(ids => ids.map(({ id }) => id));
    return { success, failure };
  }
});

export const automatePatientContact = ids => dispatch => {
  const params = {
    payload: { ids },
    requestID: uniqueId()
  };
  return dispatch(contactPatient(params));
};

// export const setSleepCoach = sleepCoach => ({
//   type: Types.SC_SET_SLEEP_COACH,
//   payload: {
//     sleepCoach
//   }
// });
// export const setTeam = team => ({
//   type: Types.SC_SET_TEAM,
//   payload: {
//     team
//   }
// });
export const clearOrderTableSelected = () => ({
  type: Types.CLEAR_SELECTED,
  payload: {}
});
