import React from "react";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import IoMdMinus from "react-icons/lib/io/minus";
import IoMdClose from "react-icons/lib/io/close";
import IoMdCalender from "react-icons/lib/io/calendar";
import CalenderControls from "./calender-controls";

const DatePicker = ({
  css,
  clear,
  containerCss,
  disabled,
  showControls,
  onChange,
  selectedDay,
  ...rest
}) => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <div style={css} className="date-picker container">
      {clear && (
        <i
          onClick={e => {
            e.stopPropagation();
            if (!disabled) onChange("");
          }}
          className="icon clear"
          title="clear"
        >
          <IoMdMinus />
        </i>
      )}
      {!disabled ? (
        <i
          onClick={() => {
            setIsActive(!isActive);
          }}
          className="icon"
          title="set date"
        >
          {isActive ? <IoMdClose /> : <IoMdCalender />}
        </i>
      ) : (
        <i>
          <IoMdCalender color="#ddd" />
        </i>
      )}
      {isActive && (
        <div id={"datePicker"} className="entryContainer">
          <div className="outOfBounds" onClick={() => setIsActive(false)} />
          <div className="pickerContainer" style={containerCss}>
            <DayPicker
              {...rest}
              onDayClick={day => {
                setIsActive(false);
                onChange(day);
              }}
              containerProps={{ style: { padding: 0 } }}
              selectedDays={[selectedDay ? new Date(selectedDay) : selectedDay]}
            />
            {showControls && (
              <CalenderControls
                handleDayChange={day => {
                  onChange(day);
                  setIsActive(false);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  css: {},
  containerCss: {},
  disabled: false,
  clear: true,
  showControls: false,
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  css: PropTypes.object,
  containerCss: PropTypes.object,
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  showControls: PropTypes.bool,
  selectedDay: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ])
};

export default DatePicker;
