import React from "react";
import { useFormikContext } from "formik";
import FormikField from "components/forms/formik-field";
import { PatientProfileContext } from "components/profile/context";
import { uniq } from "lodash";

const AddProduct = () => {
  const { values, setFieldValue } = useFormikContext();

  const { compatibleItems, companyInventory } = React.useContext(
    PatientProfileContext
  );

  const [selectedEqiupmentType, setSelectedEqiupmentType] = React.useState();
  const [selectedManufacturer, setSelectedManufacturer] = React.useState();

  const equipmentTypes = uniq(companyInventory.map(x => x.type));

  const manufacturerOptions = uniq(
    companyInventory
      .filter(x => !selectedEqiupmentType || x.type == selectedEqiupmentType)
      .map(x => x.manufacturer)
  );

  const compatibleItemIds = compatibleItems.map(x => x.item_id);

  const filteredProducts = companyInventory.filter(
    x =>
      (!selectedEqiupmentType || x.type == selectedEqiupmentType) &&
      (!selectedManufacturer || x.manufacturer == selectedManufacturer) &&
      (!values.compatibleEquipment || compatibleItemIds.includes(x.invItem_ID))
  );

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <FormikField
          style={{ display: "flex" }}
          name="compatibleEquipment"
          type="checkbox"
          label="Compatible With Patients Equipment"
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridGap: 20
        }}
      >
        <div>
          <label> Equipment Type </label>
          <select
            value={selectedEqiupmentType}
            onChange={e => {
              setSelectedEqiupmentType(e.target.value);
              setSelectedManufacturer("");
              setFieldValue("product_id", "");
            }}
          >
            <option />
            {equipmentTypes.map(x => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label> Manufacturer </label>
          <select
            value={selectedManufacturer}
            onChange={e => {
              setSelectedManufacturer(e.target.value);
            }}
          >
            <option />
            {manufacturerOptions.map(x => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
      </div>
      <FormikField
        name="product_id"
        label="Product *"
        component="select"
        disabled={filteredProducts <= 0}
      >
        <option />
        {filteredProducts.map(({ invItem_ID, description }) => (
          <option key={invItem_ID} value={invItem_ID}>
            {description}
          </option>
        ))}
      </FormikField>
    </div>
  );
};

AddProduct.propTypes = {};

export default AddProduct;
