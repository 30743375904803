import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { TableButton } from "components/ui";
import moment from "moment";
import { errorMessage } from "actions/message";
import { IoSearch } from "react-icons/lib/io";
import MoveAppointment from "../available-coaches/Index";
import { applicationDateTimeFormat, formatDate } from "utils/misc";
import CreateAppointmentForCoach from "../create-slot-for-coach/Index";
import "./manager-view.css"

const ManagerViewTableControls = ({
  selectedRows,
  queryDispatcher,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  refreshData
}) => {

  const dispatch = useDispatch();

  const doSearch = () => {
    if (dateFrom && dateTo && moment(dateTo).isBefore(moment(dateFrom)) && !moment(dateFrom).isSame(moment(dateTo), 'day')) {
      dispatch(errorMessage("Date From should be before Date To"))
      return
    }
    queryDispatcher(
      { type: "setFilter", by: "FromDate", value: formatDate(dateFrom, applicationDateTimeFormat) }
    )
    queryDispatcher(
      { type: "setFilter", by: "ToDate", value: formatDate(dateTo, applicationDateTimeFormat) }
    )
  };

  return (
    <div className="query-table-controls" style={{ background: "#cccccc" }}>
      <div className="date-search-container">
        <div className="date-from-to-text">
          <span>Appointment Date From</span>
        </div>
        <input type="date" value={formatDate(dateFrom, "yyyy-MM-DD")} onChange={(e) => setDateFrom(e.target.value)} className="date-search-input"/>
        <div className="date-from-to-text">
          <span>To</span>
        </div>
        <input type="date" value={formatDate(dateTo, "yyyy-MM-DD")} onChange={(e) => setDateTo(e.target.value)} className="date-search-input"/>
        <TableButton
          title={`Search by date`}
          onClick={() => doSearch()}
        ><IoSearch color="#0090bc" /></TableButton>
      </div>
      <div className="selected-appointments">
        {selectedRows.length > 0 && (
          <span>{selectedRows.length} {selectedRows.length > 1 ? "Appointments" : "Appointment"} Selected</span>
        )}
      </div>
      {
        selectedRows?.length > 0 && selectedRows.every(sr => sr.Status === 'Confirmation Pending' || sr.Status === 'Confirmed')
        && <MoveAppointment appointments={selectedRows.map(sr => sr.id)} onSubmit={refreshData} />
      }
       <CreateAppointmentForCoach onSubmit={refreshData} />
    </div>
  );
};

export default ManagerViewTableControls;

ManagerViewTableControls.propTypes = {
  selectedRows: PropTypes.array,
  queryDispatcher: PropTypes.func,
  dateFrom: PropTypes.string,
  setDateFrom: PropTypes.func,
  dateTo: PropTypes.string,
  setDateTo: PropTypes.func,
  refreshData: PropTypes.func
};
