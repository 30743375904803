import React from "react";

import PropTypes from "prop-types";
import { PageHeader, Spinner } from "components/ui";
import FormikField from 'components/forms/formik-field';
import { Form, Formik } from 'formik';
import { useFetch } from 'hooks';
import { get, put } from "utils/api";
import { path } from "ramda";

import { useDispatch } from "react-redux";
import { errorMessage} from "actions/message";


export default function  AddPatientForm ({ patientId, closeModal, onDemand}){

    const dispatch = useDispatch();

    const {
        response : assignablefroms,
        fetchData : fetchAssignablefroms,
        isFetching : fetchingAssignablefroms
    } = useFetch({
        apiFn: () => get(`patients/${patientId}/PatientAssignableForms/${onDemand}`),
        defaultValue: null,
        transformError: path(["response", "body", "status"])
    })

    async function initialData() {
        await fetchAssignablefroms();
    }

    React.useEffect(() => {
        initialData();
    }, []);

    const validateNull = value => {
        let errorMessage;
        if (!value) {
            errorMessage = 'You must select an option';
        }
        return errorMessage;
    };

    const uploadFormChanges = useFetch({
        apiFn: ( formId ) => put(`patients/${patientId}/assign-form/${formId}`),
        defaultValue: [],
        transformError: path(["response", "body", "status"]),
        onSuccess: res => !res.success && res.message && dispatch(errorMessage(res.message)),
        onError: err => dispatch(errorMessage(`Sorry, Something went wrong: ${err.message}`))
    })

    return (
        <div>
            <PageHeader title={`Assign new ${onDemand ? "On-Demand" : ""} form`} />
            <div style={{ padding: 5 }}>
                <Formik
                initialValues={{}}
                    enableReinitialize={true}
                    onSubmit={async (values, {setSubmitting, setFieldError}) => {
                        if (!values.selectedForm.trim() || isNaN(Number(values.selectedForm))) {
                            setFieldError("selectedForm",'You must select an option')
                            return;
                        }

                        const result = await uploadFormChanges.fetchData(parseInt(values.selectedForm))
                        if(result){
                            setSubmitting(false)
                            closeModal(values.selectedForm);
                        }
                    }}>
                        {({ handleChange, isSubmitting }) => (

                            <Form className='add-form-data-width'>
                                {!fetchingAssignablefroms?(
                                    <FormikField
                                        name="selectedForm"
                                        component="select"
                                        label="Assignable forms"
                                        validate={validateNull}
                                        disabled={isSubmitting}
                                        onChange={e => {
                                            handleChange(e)
                                        }}
                                    >
                                        <option key={null} value={null}></option>
                                            {
                                                assignablefroms?
                                                assignablefroms.forms.map((val) =>(
                                                    <option key={val.Value} value={val.Value}>{val.Text}</option>
                                                ))
                                                :
                                                null
                                            }
                                    </FormikField>

                                ):(<Spinner />)}

                            <div style={{display: "flex", alignItems: "center"}}>
                                <button style={{marginRight: "5px", marginBottom: "0px"}} type="submit" disabled={isSubmitting}>Submit</button>
                                {isSubmitting && <Spinner />}
                            </div>
                        </Form>
                        )}
                </Formik>
            </div>
        </div>
    )
}

AddPatientForm.propTypes = {
    patientId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    onDemand: PropTypes.bool
}