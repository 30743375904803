import React, { useState } from "react";
import PropTypes from "prop-types";
import { PageHeader, InlineWaiting } from "components/ui";
import { connect } from "react-redux";
import { find, pathOr } from "ramda";
import { getDocumentCategory } from "components/dd_newModules/Redux/Actions/documentCategory";
import { errorMessage } from "actions/message";
import { useFetch } from "hooks";
import { post } from "utils/api";

const UploadPatientDocument = ({
  patientId,
  errorMessage,
  // uploadDocument,
  getDocumentCategory,
  closeModal,
  documentCategoryOptions
}) => {
  const uploadDocumentApi = useFetch({
    apiFn: ({ patientId, formData }) =>
      post(`/dme_portal/patients/${patientId}/documents`, formData)
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setDocument] = useState("");
  const [category, setDocumentCategory] = useState("");
  const [isMediaType, setIsMediaType] = useState(false);
  React.useEffect(() => {
    getDocumentCategory();
  }, []);

  const setSelectedDocument = event => {
    const MBSize = event.target.files[0].size / 1024;
    if (MBSize > 101376) {
      setDocument("");
      errorMessage("File size must be less than 99MB.");
    } else {
      setDocument(event.target.files[0]);
    }
  };

  return (
    <div>
      <PageHeader title="Upload Document" />
      <div style={{ padding: 5 }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: 10,
            alignItems: "center"
          }}
        >
          <div>
            <label>
              Select Document Category
              <select
                value={category}
                onChange={({ target }) => {
                  const documentCategory = find(
                    value => target.value === value,
                    documentCategoryOptions
                  );
                  setDocumentCategory(target.value);
                  setIsMediaType(documentCategory && documentCategory.is_media);
                }}
              >
                <option />
                {documentCategoryOptions.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <label>
            Enter Document Title
            <input
              maxLength={90}
              type="text"
              value={title}
              onChange={({ target }) => setTitle(target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Enter Document Description
            <textarea
              rows={3}
              maxLength={390}
              id="description"
              onChange={({ target: { value } }) => setDescription(value)}
              value={description}
              placeholder="Enter Document Description"
            />
          </label>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 10,
              alignItems: "center"
            }}
          >
            <div>
              <input
                accept={
                  isMediaType
                    ? ".jpg,.jpeg,.png, .gif"
                    : ".xls,.xlsx,.jpg,.jpeg,.png,.mp3,.pdf,.doc,.docx, .txt,.xls,.xlsx, .gif"
                }
                onChange={setSelectedDocument}
                id="file"
                type="file"
              />
              <small>
                <label style={{ color: "red" }}>
                  File size should be less than 99MB
                </label>
              </small>
            </div>
            {uploadDocumentApi.isLoading ? (
              <InlineWaiting />
            ) : (
              <button
                onClick={async () => {
                  let formData = new FormData();
                  formData.append("active", true);
                  formData.append("title", title);
                  formData.append("document_category_id", category);
                  formData.append("description", description);
                  formData.append("file", file);
                  await uploadDocumentApi.fetchData({ formData, patientId });
                  closeModal();
                }}
                disabled={
                  category.trim().length === 0 ||
                  title.trim().length === 0 ||
                  description.trim().length === 0 ||
                  file.length === 0
                }
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UploadPatientDocument.propTypes = {
  patientId: PropTypes.string.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  documentCategoryOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getDocumentCategory: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    documentCategoryOptions: pathOr(
      [],
      ["rdc_documentCategory", "DocumentCategories"],
      state.getIn(["newRootReducer"])
    )
      .filter(({ active }) => active)
      .map(data => ({
        text: data.document_category_name,
        value: data.document_category_id,
        is_media: data.is_media
      }))
  }),
  { getDocumentCategory, errorMessage }
)(UploadPatientDocument);
