import React from "react";
import PropTypes from "prop-types";

const IconButton = ({ icon, text, title, ...restProps }) => {
  return (
    <button
      className="button secondary icon-button"
      title={title}
      {...restProps}
    >
      {icon}
      <span className="button-text">{text}</span>
    </button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string,
  title: PropTypes.string
};

export default IconButton;
